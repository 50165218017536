import * as React from 'react';
import { PageProps, withPageProps } from '../components/Page'
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import { Button, Card, CardContent, CardActions, Typography, CardHeader } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    card: {
        minWidth: 275,
        maxWidth: 300,
        marginBottom: "10px",
        marginLeft: "100px",
        marginRight: "100px",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
    },
})

interface Props extends PageProps, Styles.WithStyles {
}

interface LocalState {
}

class PatientListPage extends React.Component<Props, LocalState> {
    displayName = "PatientListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }

    public componentDidMount = () => {
    }

    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardHeader
                        subheader=""
                        title={"オマハ"}
                    />
                    <CardContent>
                        <Typography>
                            オマハデータを書き出します
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={() => { }}>実行</Button>
                    </CardActions>
                </Card>
            </div>
        );
    }
}

export default withPageProps(Styles.withStyles(styles)(PatientListPage))