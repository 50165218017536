import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import CodeUtil from '../../util/CodeUtil';
import {
    ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import CsvCreator from 'react-csv-creator';
const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];
const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        height: "300px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
//        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    }
});


interface Props extends Styles.WithStyles {
    title: string,
    name: string,
    count: string,
    data: any,
    color: string[]
}
const renderCustomBarLabel = (sum) => {
    return (data) => {
        const { payload, x, y, width, height, value } = data
        if (sum != 0) {
            const a = value * 100.0 / sum
            return <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{`${a.toFixed(0)}%`}</text>
        }
        else {
            return <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>-</text>
        }
    }
};
const CustomizedAxisTick = (props) => {
        const { x, y, stroke, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="start" fill="#666" transform="rotate(30)">{payload.value}</text>
            </g>
        );
    }

class BarGraph2 extends React.PureComponent<Props> { 
	displayName="BarGraph"
    public render() {
        const { classes } = this.props;
        let sum = 0.0;
        let tickct = 1;
        if (this.props.data) {
            this.props.data.forEach(x => {
                if (x[this.props.count]) {
                    sum += x[this.props.count]
                }
            })
            tickct = this.props.data.length
        }
        return <div className={classes.root}>
            <div style={{ position: "relative" }}>
                {this.props.title} 
                <div style={{
                    position: "absolute",
                    right: "10px",
                    top: "-12px", cursor: "pointer"
                }}>
                <CsvCreator
                    filename={this.props.title}
                        rows={CodeUtil.ToSJisList(this.props.data)}
                    text="CSV↓"
                />
                </div>
            </div>
            <ResponsiveContainer width="100%" height="95%">
                <BarChart
                    data={this.props.data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={this.props.name}
                        height={100} tick={<CustomizedAxisTick />} 
                        interval={0}
                        />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => {
                        if (sum != 0) {
                            const a = value * 100.0 / sum
                            return `${value}（${a.toFixed(1)}%）`
                        }
                        else {
                            return `${value}`
                        }
                    }} />
                    <Legend />
                    <Bar label={renderCustomBarLabel(sum)} key={`bg_0`} stackId="a" dataKey={this.props.count} fill={this.props.color[0]} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    }
}

export default Styles.withStyles(styles)(BarGraph2)