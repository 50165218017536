import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import {
    ResponsiveContainer, PieChart, Pie, Tooltip, Cell, Legend
} from 'recharts';
import CsvCreator from 'react-csv-creator';
import CodeUtil from '../../util/CodeUtil';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        height: "500px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
//        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    }
});

interface Props extends Styles.WithStyles {
    title: string,
    name: string,
    count: string,
    data: any,
    height: string
}

function genColor(idx:number)
{
    return `hsl(${idx*63%360}, 60%, 60%)`
}

class PiChart extends React.PureComponent<Props> { 
	displayName="PiChart"
    public render() {
        const { classes } = this.props;
        let sum = 0.0;
        if (this.props.data) {
            this.props.data.forEach(x => {
                if (x[this.props.count]) {
                    sum += x[this.props.count]
                }
            })
        }
        return <div className={classes.root} style={{height: this.props.height}}>
            <div style={{ position: "relative" }}>
                {this.props.title}
                <div style={{
                    position: "absolute",
                    right: "10px",
                    top: "-12px", cursor: "pointer"
                }}>
                    <CsvCreator
                        filename={this.props.title}
                        rows={CodeUtil.ToSJisList(this.props.data)}
                        text="CSV↓"
                    />
                </div>
            </div>
            <ResponsiveContainer width="100%" height="95%">
                <PieChart width="100%" height="100%">
                    <Pie data={this.props.data}
                     nameKey={this.props.name}
                     dataKey={this.props.count}
                     outerRadius="100%"
                     innerRadius="10%"
                     startAngle={90}
                     endAngle={-270}
                     >
                        {
                            this.props.data ?
                            this.props.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={genColor(index)} />
                            ))
                            : null
                        }
                    </Pie>
                    <Tooltip formatter={(value, name, props) => {
                        if (sum != 0) {
                            const a = value * 100.0 / sum
                            return `${value}（${a.toFixed(1)}%）`
                        }
                        else {
                            return `${value}`
                        }
                    }} />
                    <Legend align="left"/>
                </PieChart>
            </ResponsiveContainer>
        </div>
    }
}

export default Styles.withStyles(styles)(PiChart)