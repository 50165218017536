import { Branch, SimpleUser } from './Company'
import { Patient } from './Patient'
import DateUtil from '../util/DateUtil'

export enum HoukokuType {
    None = "未選択",
    K1 = "インシデント",
    K2 = "アクシデント",
    K3 = "苦情",
}

export enum TaishoType {
    None = "未選択",
    K1 = "利用者",
    K2 = "家族",
    K3 = "看護師",
    K4 = "その他",
}

export enum JikoType {
    None = "未選択",
    Iryo = "医療事故",
    Kea = "ケア事故",
    Koutsu = "交通事故",
    Tounan = "盗難・紛失・破損",
    Other = "その他"
}

export enum JikoTypeDetailNotSelected {
    None = "未選択"
}

export enum JikoTypeDetailIryo {
    None = "未選択",
    C1 = "浣腸・摘便時の出血、ショック",
    C2 = "医療機器取り扱い間違い",
    C3 = "内服セットミス",
    C4 = "薬剤投与・種類ミス",
    C5 = "点滴の痛み",
    C6 = "バルーン、胃瘻、胃管、気管カニュレ自己抜去",
    C7 = "麻薬",
    C8 = "家族への感染",
    C9 = "針刺し",
    C10 = "その他"
}

export enum JikoTypeDetailKea {
    None = "未選択",
    C1 = "寝衣、シーツ汚染",
    C2 = "清拭、テープ剥がし、爪切りなどの出血",
    C3 = "入浴・体交・移乗時の転倒転落",
    C4 = "入浴介助中の火傷",
    C5 = "散歩中 / 後のけが",
    C6 = "食事介助中の誤嚥、誤飲、窒息",
    C7 = "HOT使用中の火器使用による火事",
    C8 = "家屋の水浸し",
    C9 = "家屋の火災",
    C10 = "物損",
    C11 = "家族の転倒（看護師と介助中）",
    C12 = "家族の腰痛（看護師と介助中）",
    C13 = "家族の火傷（看護師と介助中）",
    C14 = "看護師の転倒",
    C15 = "看護師の腰痛",
    C16 = "看護師の火傷",
    C17 = "看護師の感染",
    C18 = "その他"
}

export enum JikoTypeDetailKoutsu {
    None = "未選択",
    C1 = "外出リハ中の事故",
    C2 = "患者移送中の事故",
    C3 = "外出リハ中の家族の事故",
    C4 = "看護師の移動中の事故",
    C5 = "看護師の自転車同士の事故",
    C6 = "看護師の自転車転倒",
    C7 = "看護師の通勤中の事故",
    C8 = "その他"
}

export enum JikoTypeDetailTounan {
    None = "未選択",
    C1 = "書類紛失",
    C2 = "利用者宅のカギ紛失",
    C3 = "預かっている薬などの紛失",
    C4 = "利用者宅や用具の破損",
    C5 = "事務所内の盗難",
    C6 = "訪問カバンの盗難",
    C7 = "事務所の現金紛失",
    C8 = "事務所の物品・備品の紛失破損",
    C9 = "個人の現金、私物の紛失",
    C10 = "その他",
}

export enum JikoTypeDetailOther {
    None = "未選択",
    C1 = "虐待",
    C2 = "不審死",
    C3 = "自殺",
    C4 = "家族への虐待",
    C5 = "職員行方不明",
    C6 = "ストーカー行為",
    C7 = "利用者宅への忘れ物",
    C8 = "処置物品の置き忘れ",
    C9 = "携帯の携行忘れ",
    C10 = "ペットによる被害",
    C11 = "本人・家族による看護師へのハラスメント",
    C12 = "その他"
}

export enum EikyoLevel {
    None = "未選択",
    E0 = "0:ヒヤリハット",
    E1 = "1:影響なし",
    E2 = "2:要経過観察",
    E3 = "3:身体への影響・不快あり",
    E4 = "4:身体への重大な影響あり"
}

export enum Priority {
    None = "未選択",
    C0 = "高",
    C1 = "低",
}

export class Incident {
    public id: number = 0
    public write_user: SimpleUser = new SimpleUser()
    public branch: Branch = new Branch()
    public patient: Patient = new Patient()
    public main_user: SimpleUser = new SimpleUser()
    public priority: string = "未選択"
    public case_type: string = "未選択"
    public time_occured: string = DateUtil.currentDateTimeStr()
    public taisho_type: string = "未選択"
    public jiko_type: string = "未選択"
    public jiko_detail: string = "未選択"
    public eikyo_level: string = "未選択"
    public detail: IncidentDetail = new IncidentDetail()

    static load(apiRes: any) {
        let res = new Incident();
        res.id = apiRes.id
        res.write_user = apiRes.write_user
        res.branch = apiRes.branch
        res.patient = apiRes.patient
        res.main_user = apiRes.main_user
        res.priority = apiRes.priority
        res.case_type = apiRes.case_type
        res.time_occured =  apiRes.time_occured
        res.taisho_type = apiRes.taisho_type
        res.jiko_type = apiRes.jiko_type
        res.jiko_detail = apiRes.jiko_detail
        res.eikyo_level = apiRes.eikyo_level
        res.detail = apiRes.detail ? JSON.parse(apiRes.detail) : new IncidentDetail()
        return res;
    }
}

export class IncidentPost {
    public id: number = 0
    public write_user_id: number | null = null
    public branch_id: number | null = null
    public patient_id: number | null = null
    public main_user_id: number | null = null
    public priority: string = "未選択"
    public case_type: string = "未選択"
    public time_occured: string = ""
    public taisho_type: string = "未選択"
    public jiko_type: string = "未選択"
    public jiko_detail: string = "未選択"
    public eikyo_level: string = "未選択"
    public detail: string = "{}"

    public constructor(src: Incident) {
        this.id = src.id
        this.write_user_id = src.write_user && src.write_user.id > 0 ? src.write_user.id : null
        this.branch_id = src.branch && src.branch.id > 0 ? src.branch.id : null
        this.patient_id = src.patient && src.patient.id > 0 ? src.patient.id : null
        this.main_user_id = src.main_user && src.main_user.id > 0 ? src.main_user.id : null
        this.priority = src.priority
        this.case_type = src.case_type
        this.time_occured = src.time_occured
        this.taisho_type = src.taisho_type
        this.jiko_type = src.jiko_type
        this.jiko_detail = src.jiko_detail
        this.eikyo_level = src.eikyo_level
        this.detail = JSON.stringify(src.detail)
    }
}

export class IncidentDetail {
    public content: string = ""
    public content_detail: string = ""
    public content_cause: string = ""
    public content_taisaku: string = ""
}