import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import * as HokanApi from '../../api/hokan'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"

    public getId(): number {
        return 56
    }

    public createContent(): any {
        const res = new FormModel.ACPJinsei2.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }


    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.ACPJinsei2.Core
        if (!content.checks.check_c4_1) {
            content.values.text_4_name = ""
            content.values.text_4_kankei = ""
        }
    }

    public beforeMount = () => {
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        doc.patient = pat;
        const c = doc.content as FormModel.ACPJinsei2.Core
        const setName = (name: string, tanto: Model.OrganizationTanto | null) => {
            c.values[name] = tanto ? tanto.name : ""
        }
        const setNames = (name: string, tantos: Model.OrganizationTanto[]) => {
            let res = ""
            let f = 0
            tantos.forEach(t => {
                if (t && t.id) {
                    res += (f > 0 ? "" : " / ") + t.name
                    ++f
                }
            })
            c.values[name] = res
        }
        setName("kyouyu_2", pat.contact.shujii)
        setName("kyouyu_3", pat.contact.keamane)
        setNames("kyouyu_7", pat.contact.other)
        sa.updateState();

        HokanApi.me().then(res => {
            c.values.write_by = res.data.name
            sa.updateState();
        }).catch((e) => { console.log(e) })
    }

    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>ウィル訪問看護ステーション.WyL株式会社.2019</div>
        </div>
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const saC = sa.generateChild(["content", "checks"])

        const elements_basic = [
            {
                name: "作成者", content: saV.text("write_by")
            }
        ]
        const elements_explain= [
            {
                name: "", content: <div>
                  <b>
                        今後どのように暮らしていきたいかについて、あなたの希望や想いを、家族や大切な人、かかりつけ医をはじめとした関係者と共に話し合ってみませんか。
                  </b>  
                  <div>
                      ※ここに記載したことは法的な意味合いや拘束力はありません。健康状態や生活環境の変化などに合わせいつでも何度でも書き直すことができます
                  </div>
                </div>
            }
        ]
        const elements_1 = [
            {
                name: "", content: <div>
                    <div>
                        <p>●医師からお身体のことや治療についてどのような説明を受けていますか？それについてどう思っていますか？</p>
                        <div>
                            <div>ご本人：</div>
                            {saV.text("text_1_1")}
                        </div>
                        <div>
                            <div>ご本人を支える人：</div>
                            {saV.text("text_1_2")}
                        </div>
                    </div>
                    <br/>
                    <div>
                        <p>●お身体のことや日々の生活を送ることで気がかりや不安なことはありますか？</p>
                        <div>
                            <div>ご本人：</div>
                            {saV.text("text_1_3")}
                        </div>
                        <div>
                            <div>ご本人を支える人：</div>
                            {saV.text("text_1_4")}
                        </div>
                    </div>
                    <br />
                </div>
            }
        ]
        const elements_2 = [
            {
                name: "", content: <div>
                    <div>
                        <p>●あなたの大切にしていることはなんですか？またこれまでの人生で想い深い大切な出来事があれば教えてください</p>
                        <div>
                            {saV.text("text_2_1")}
                        </div>
                    </div>
                    <br />
                </div>
            }
        ]
        const elements_3 = [
            {
                name: "", content: <div>
                    <div>
                        <p>●これからやりたいこと、希望や達成したいことは何かありますか？</p>
                        <div>
                            {saV.text("text_3_1")}
                        </div>
                    </div>
                    <br />
                    <div>
                        <p>●今後体調が変化した時、どこで、どのように過ごしたいと考えていますか？</p>
                        <div>
                            {saV.text("text_3_2")}
                        </div>
                    </div>
                    <br />
                </div>
            }
        ]
        const elements_4 = [
            {
                name: "", content: <div>
                    <div>
                        <p>●ご自身で希望する医療が判断出来なくなった時、意思を代弁してくれる方はいますか？</p>
                        <div>
                            {saC.checkList([
                                { label: "はい", key: "check_c4_1" },
                                { label: "いいえ", key: "check_c4_2" },
                                { label: "わからない", key: "check_c4_3" },
                            ], false, true)}
                            {
                                saC.getValue("check_c4_1") ?
                                    <div>
                                        名前：{saV.text("text_4_name")}
                                        ご関係：{saV.text("text_4_kankei")}
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    <br />
                    <div>
                        <p>●支える方へ＞ご本人様とこれまで上記の内容を話されたことがない場合、ご本人ならどのように考えると思いますか？</p>
                        <div>
                            {saV.text("text_4_1")}
                        </div>
                    </div>
                    <br />
                </div>
            }
        ]
        const elements_5 = [
            {
                name: "ご本人・ご家族", content: saV.text("kyouyu_1")
            },
            {
                name: "往診医", content: saV.text("kyouyu_2")
            },
            {
                name: "ケアマネ", content: saV.text("kyouyu_3")
            },
            {
                name: "福祉用具", content: saV.text("kyouyu_4")
            },
            {
                name: "訪問介護", content: saV.text("kyouyu_5")
            },
            {
                name: "訪問看護", content: saV.text("kyouyu_6")
            },
            {
                name: "その他", content: saV.text("kyouyu_7")
            },
        ]

        const elements = [
            { name: '基本', content: elements_basic },
            { name: '説明', content: elements_explain },
            { name: '①あなたのお身体の事や治療についてお聞かせください', content: elements_1 },
            { name: '②あなたの大切にしていることをお聞かせください', content: elements_2 },
            { name: '③あなたの今後についてお聞かせください', content: elements_3 },
            { name: '④将来、体調が変化し、自分で決められない時についてお聞かせください。', content: elements_4 },
            { name: '話し合ったことを共有した者', content: elements_5 },
        ]

        return elements
    }
}
