import * as React from 'react';
import * as Colors from '../Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Styles from '@material-ui/core/styles';
import { Table, TableHead, TableCell, TableRow, TableBody, Tooltip } from '@material-ui/core';
import LineGraphKbs from './LineGraphKbs';
import CsvCreator from 'react-csv-creator';
import CodeUtil from '../../util/CodeUtil';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        textAlign: "center"
    },
    title: {
    },
    value: {
        fontSize: "70px"
    },
    unit: {

    },
    elm: {
        marginTop: "30px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        display: "inline-table",
        width: "80%"
    },
    tableHead: {
        background: Colors.primaryColor,
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px"
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:hover': {
            borderColor: Colors.primaryColor,
            borderWidth: "2px"
        },
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
        },
    },
    cell: {
        textAlign: "center", padding: "1px 10px",
        border: "solid 1px"
    },
    tooltip: {
        maxWidth: "320px"
    }
});

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Props extends Styles.WithStyles {
    title: string,
    name: string,
    data: any,
    rank: any
}

class TableKbs extends React.PureComponent<Props> {
    displayName = "TableKbs"
    public render() {
        const { classes } = this.props;
        let score = 0
        let negScore = 0
        let sum = 0;
        if (this.props.data) {
            this.props.data.forEach(d => {
                if (d.compare.k > 0) ++score
                else if (d.compare.k < 0) ++negScore
                if (d.compare.b > 0) ++score
                else if (d.compare.b < 0) ++negScore
                if (d.compare.s > 0) ++score
                else if (d.compare.s < 0) ++negScore
                sum += 3
            })
        }
        return <div className={classes.root}>
            <div className={classes.title} style={{ position: "relative" }}>
                {this.props.title}
                <div style={{
                    position: "absolute",
                    right: "10px",
                    top: "-12px", cursor: "pointer"
                }}>
                    <CsvCreator
                        filename={this.props.title}
                        rows={CodeUtil.ToSJisList(this.props.data)}
                        text="CSV↓"
                    />
                </div>
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <CustomTableCell className={classes.cell}>{this.props.name}</CustomTableCell>
                        <CustomTableCell className={classes.cell}>n</CustomTableCell>
                        <CustomTableCell className={classes.cell}>K(知識)<br />前→後</CustomTableCell>
                        <CustomTableCell className={classes.cell}>B(行動)<br />前→後</CustomTableCell>
                        <CustomTableCell className={classes.cell}>S(状態)<br />前→後</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.data ?
                            this.props.data.map((c, ci) => {
                                const rank = ["-", "-", "-"]
                                if (this.props.rank) {
                                    const t = this.props.rank[c.name]
                                    if (t) {
                                        t.forEach((x, xi) => {
                                            if (xi < 3) {
                                                rank[xi] = `${x.problem}(${(parseFloat(x.rate) * 100).toFixed(1)}%)`
                                            }
                                        })
                                    }
                                }
                                const res = <TableRow key={c.id} className={classes.row}>
                                    <CustomTableCell key={`h_${ci}_sn`}
                                        className={classes.cell}
                                        scope="row">
                                        {c.name}
                                    </CustomTableCell>
                                    <CustomTableCell key={`h_${ci}_n`}
                                        className={classes.cell}
                                        scope="row">
                                        {c.n}
                                    </CustomTableCell>
                                    <CustomTableCell key={`h_${ci}_k`}
                                        className={classes.cell}
                                        style={{ backgroundColor: c.color_k }}
                                        scope="row">
                                        {c.score_k}
                                    </CustomTableCell>
                                    <CustomTableCell key={`h_${ci}_b`}
                                        className={classes.cell}
                                        style={{ backgroundColor: c.color_b }}
                                        scope="row">
                                        {c.score_b}
                                    </CustomTableCell>
                                    <CustomTableCell key={`h_${ci}_s`}
                                        className={classes.cell}
                                        style={{ backgroundColor: c.color_s }}
                                        scope="row">
                                        {c.score_s}
                                    </CustomTableCell>
                                </TableRow>
                                return <Tooltip key="kbstt" classes={{ tooltip: classes.tooltip }}
                                    title={
                                        <div style={{
                                            fontSize: "0.9rem", backgroundColor: "white",
                                            color: Colors.primaryColor
                                        }}
                                        >
                                            <div style={{ width: "300px" }}>
                                                <LineGraphKbs
                                                    data={[
                                                        { date: "前", K: c.p_k, B: c.p_b, S: c.p_s },
                                                        { date: "今", K: c.c_k, B: c.c_b, S: c.c_s },
                                                    ]}
                                                    title={`${c.name}`}
                                                    smallMode={true}
                                                />
                                            </div>
                                            {
                                                this.props.rank ?
                                                    <div style={{ margin: "5px" }}>
                                                        <b>[立案問題]</b><br />
                                                        １位：{rank[0]}<br />
                                                        ２位：{rank[1]}<br />
                                                        ３位：{rank[2]}
                                                    </div>
                                                    : null
                                            }
                                        </div>} placement="top">
                                    {res}
                                </Tooltip>
                            })
                            : null
                    }
                </TableBody>
            </Table>

            <div style={{ fontSize: "large", display: "inline-table", width: "20%" }}>
                <div key='sc_pos' style={{ padding: "10px" }}>
                    {
                        sum != 0 ?
                            <div style={{ backgroundColor: "#ffcbb9" }}>
                                改善<br />
                                <span style={{ fontSize: "40px" }}>{(score * 100 / sum).toFixed(0)}%</span><br />
                                ({score}/{sum})
                            </div>
                            : <div key='sch'>
                                -
                            </div>
                    }
                </div>
                <div key='sc_neg' style={{ padding: "10px" }}>
                    {
                        sum != 0 ?
                            <div style={{ backgroundColor: "#b9d2fb" }}>
                                悪化<br />
                                <span style={{ fontSize: "40px" }}>{(negScore * 100 / sum).toFixed(0)}%</span><br />
                                ({negScore}/{sum})
                            </div>
                            : <div key='sch'>
                                -
                            </div>
                    }
                </div>
            </div>
        </div>
    }
}

export default Styles.withStyles(styles)(TableKbs)