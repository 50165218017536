
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""

    public dousuru: string = ""
    public hiki_res: string = ""
    public hikigane: string = ""
    public life1: string = ""
    public life2: string = ""
    public life3: string = ""
    public life4: string = ""
    public life5: string = ""
    public life6: string = ""
    public omoi_f: string = ""
    public omoi_m: string = ""
    public sign: string = ""
    public sign_res: string = ""
    public soudan: string = ""
    public stress: string = ""
    public tool: string = ""
    public tsuyomi: string = ""
}

export class Checks {
}