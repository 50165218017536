import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import { Table, TableHead, TableCell, TableRow, TableBody, Button, Dialog, DialogTitle, DialogContent, Grid, Select, MenuItem } from '@material-ui/core';
import Papa from 'papaparse';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none"
        }
    },
    freshCheckSubtitleRow: {
        border: "solid",
        borderWidth: "1px",
        backgroundColor: Colors.primaryColor,
        borderColor: Colors.backColor,
        color: Colors.white
    },
    freshCheckSubtitle: {
        fontWeight: "bold",
        fontSize: "0.9rem",
        padding: "5px",
        color: Colors.white
    },
    rowContent: {
        paddingLeft: "10px"
    },
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    patients: Model.Patient.Patient[]
    w_customers: Model.Import.WisemanCustomer[]
    w_hokens: Model.Import.WisemanHoken[]
    connectDialogTarget: number | null
    me: Model.User
    loading: boolean
    pastPatIdToWisemanId: { [index: number]: string | null }
    pastWisemanIdToPatId: { [index: string]: number }
    commitResult: string
    branch_id: number
    branches: Model.Branch[]
    fileErrorMessage: string
}



// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "WisemanPage"
    constructor(props: Props) {
        super(props)

        this.state = {
            patients: [],
            me: new Model.User(),
            w_customers: [],
            w_hokens: [],
            connectDialogTarget: null,
            loading: true,
            pastPatIdToWisemanId: {},
            pastWisemanIdToPatId: {},
            commitResult: "",
            branch_id: 0,
            branches: [],
            fileErrorMessage: ""
        }
    }

    private goBack() {
        this.props.history.goBack();
    }

    private commit() {
        this.setState({ commitResult: "処理中" })
        HokanApi.postWisemanCustomer(this.state.branch_id, this.state.w_customers).then(x => {
            this.setState({ commitResult: "OK" })
        }).catch(err => {
            this.setState({ commitResult: "エラー:" + err.statusText })
            console.log(err)
        })
    }

    private loadBranch = (branchId) => {
        this.setState({
            loading: true,
            branch_id: branchId,
        })
        HokanApi.getPatients(branchId)
            .then(async x => {
                const pats = x.data.map(y => Model.Patient.Patient.load(y, false)).
                    filter(y => y.is_shinki == false)
                pats.sort((a, b) => {
                    if (a.name_kana == b.name_kana) return (a.id < b.id ? -1 : 1)
                    return (a.name_kana < b.name_kana) ? -1 : 1
                })
                const wToP: { [index: string]: number } = {}
                const pToW: { [index: number]: string | null } = {}
                pats.forEach(p => {
                    const wid = p.wiseman_id
                    pToW[p.id] = wid
                    if (wid && wid != "") {
                        wToP[wid] = p.id
                    }
                })
                this.setState({
                    patients: pats,
                    pastWisemanIdToPatId: wToP,
                    pastPatIdToWisemanId: pToW,
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    private handleConnectClose() {
        this.setState({ connectDialogTarget: null })
    }

    componentDidMount() {
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
            this.loadBranch(x.data.branch_id)
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })

        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private handleDisconnect() {
        if (this.state.connectDialogTarget == null) return;
        const { patients, w_customers } = this.state
        const p = patients[this.state.connectDialogTarget]
        const w = w_customers.find(w => w.id == p.wiseman_id)
        if (w) {
            w.connected_id = null
        }
        p.wiseman_id = null;
        this.setState({
            patients: patients,
            w_customers: w_customers,
            connectDialogTarget: null
        })
    }

    private openConnectDialog(idx: number) {
        this.setState({ connectDialogTarget: idx })
    }

    private connectWiseman(w: Model.Import.WisemanCustomer) {
        if (this.state.connectDialogTarget == null) return;
        const { patients, w_customers } = this.state
        patients.forEach(p => {
            if (p.wiseman_id == w.id) {
                p.wiseman_id = null;
            }
        })
        const p = patients[this.state.connectDialogTarget]

        w_customers.forEach(x => {
            if (x.id == p.wiseman_id) {
                x.connected_id = null
            }
        })
        p.wiseman_id = w.id;
        w.connected_id = p.id;
        this.setState({
            patients: patients,
            w_customers: w_customers,
            connectDialogTarget: null
        })
    }

    handleReflect(results) {
        console.log(results)
        const ws = results.data.map(r => {
            const w = new Model.Import.WisemanCustomer()
            w.id = r[2]
            w.name = r[3]
            w.name_kana = r[4]
            w.sex = r[5]
            w.birthday = r[7]
            w.zip = r[10]
            w.address1 = r[11]
            w.address2 = r[12]
            w.tel1 = r[13]
            w.tel2 = r[14]
            const p = this.state.patients.find(p => p.wiseman_id == w.id)
            w.connected_id = p ? p.id : null
            return w
        })
        ws.sort((a, b) => {
            if (a.name_kana == b.name_kana) return a.id < b.id ? -1 : 1
            return (a.name_kana < b.name_kana) ? -1 : 1
        })
        this.setState({ w_customers: ws })
    }
    handleReflectHoken(results) {
        console.log(results)
        /*
        const ws = results.data.map(r => {
            const w = new Model.Import.WisemanHoken()
            const p = this.state.patients.find(p => p.wiseman_id == w.id)
            w.connected_id = p ? p.id : null
            return w
        })
        ws.sort((a, b) => {
            if (a.name_kana == b.name_kana) return a.id < b.id ? -1 : 1
            return (a.name_kana < b.name_kana) ? -1 : 1
        })
        this.setState({ w_customers: ws })
        */
    }

    private loadFile(file: File) {
        Papa.parse(file, {
            header: false,
            dynamicTyping: false,
            skipEmptyLines: true,
            complete: (results) => {
                this.handleReflect(results);
            },
        });
    }
    private loadHoken(file: File) {
        Papa.parse(file, {
            header: false,
            dynamicTyping: false,
            skipEmptyLines: true,
            complete: (results) => {
                this.handleReflectHoken(results);
            },
        });
    }

    public render() {
        const { classes } = this.props;
        const { patients, w_customers, connectDialogTarget } = this.state;

        return <div className={classes.root}>

            <SubTitle title="利用者情報紐付け"
                text="利用者情報をレセプトシステムと紐付けします"
            />
            <div>
                <Select
                    value={this.state.branch_id}
                    autoWidth={true}
                    variant='outlined'
                    onChange={(e) => this.loadBranch(e.target.value)}
                    className={classes.input}
                >
                    {
                        this.state.branches.map((t, idx) =>
                            <MenuItem key={`lis+${t.id}`} value={t.id}>{t.name}</MenuItem>
                        )
                    }
                </Select>
            </div>
            <div>
                取り込む「ALL_KIHON_*.WTF」ファイルを選択してください。
                <div>
                    <input type="file" ref="file"
                        accept=".wtf,.csv"
                        onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                if (e.target.files[0].name.indexOf("ALL_KIHON") >= 0) {
                                    this.setState({ fileErrorMessage: "" })
                                    this.loadFile(e.target.files[0])
                                }
                                else {
                                    this.setState({ fileErrorMessage: "エラー：ファイル名を確認してください" })
                                }
                            }
                        }} />
                </div>
                <div style={{ color: "red" }}>{this.state.fileErrorMessage}</div>
            </div>
            {
                false ?
                    <div>
                        取り込む「ALL_HOKEN_*.WTF」ファイルを選択してください。
                        <input type="file" ref="file"
                            accept=".wtf,.csv"
                            onChange={(e) => {
                                if (e.target.files) {
                                    this.loadHoken(e.target.files[0])
                                }
                            }} />
                    </div>
                    : null
            }
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={6} lg={6}>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>利用者</CustomTableCell>
                                <CustomTableCell align="center">紐付け</CustomTableCell>
                                <CustomTableCell align="center">状況</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                patients.map((pat, ui) => {
                                    const pastWisemanId = this.state.pastPatIdToWisemanId[pat.id]
                                    return <TableRow key={`urow_${ui}`} className={classes.row}>
                                        <TableCell align="left" className={classes.rowContent}>{`${pat.name}(${pat.birthday})`}</TableCell>
                                        <TableCell align="center">
                                            {
                                                pat.wiseman_id ?
                                                    <Button color="default" variant="outlined" size="small"
                                                        onClick={(e) => this.openConnectDialog(ui)}
                                                        className={classes.filter_button_d}>
                                                        変更
                                                    </Button>
                                                    :
                                                    <Button color="primary" variant="contained" size="small"
                                                        onClick={(e) => this.openConnectDialog(ui)}
                                                        className={classes.filter_button_d}>
                                                        紐づける
                                                    </Button>
                                            }
                                        </TableCell>
                                        <TableCell align="center" className={classes.rowContent}>
                                            {
                                                pat.wiseman_id ?
                                                    pastWisemanId ?
                                                        pat.wiseman_id == pastWisemanId ?
                                                            `同期(ID=${pat.wiseman_id})`
                                                            : `変更(ID=${pat.wiseman_id})`
                                                        : `新規(ID=${pat.wiseman_id})`
                                                    :
                                                    pastWisemanId ?
                                                        `紐付け解除`
                                                        : `-`
                                            }
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>

                    <Dialog onClose={(e) => this.handleConnectClose()} aria-labelledby="connect-dialog-title" open={this.state.connectDialogTarget != null}>
                        <DialogTitle id="connect-dialog-title">
                            紐づけるワイズマンの利用者を選択してください<br />
                            {
                                connectDialogTarget != null ? <div>
                                    {this.state.patients[connectDialogTarget].name}<br />
                                    {this.state.patients[connectDialogTarget].birthday}
                                </div>
                                    : null
                            }
                        </DialogTitle>
                        <DialogContent>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <CustomTableCell align="center">ID</CustomTableCell>
                                        <CustomTableCell align="center">名前</CustomTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        w_customers.filter(w => w.connected_id == null).map((w, wi) => {
                                            return <TableRow key={`wrow_${wi}`} className={classes.row} style={{ cursor: 'pointer' }}
                                                onClick={(e) => { this.connectWiseman(w) }}
                                            >
                                                <TableCell align="center" className={classes.rowContent}>{`${w.id}`}</TableCell>
                                                <TableCell align="center" className={classes.rowContent}>{`${w.name}(${w.birthday})`}</TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <div>
                                <Button color="default" variant="outlined"
                                    onClick={(e) => this.handleConnectClose()}
                                    className={classes.filter_button_d}>
                                    戻る
                                </Button>
                                {
                                    connectDialogTarget != null && this.state.patients[connectDialogTarget].wiseman_id ?
                                        <Button color="secondary" variant="contained"
                                            onClick={(e) => this.handleDisconnect()}
                                            className={classes.filter_button_d}>
                                            紐付け解除
                                        </Button>
                                        : null
                                }
                            </div>
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item={true} xs={12} md={6} lg={6}>
                    <div>
                        ワイズマンデータ
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell align="center">ID</CustomTableCell>
                                    <CustomTableCell align="center">名前</CustomTableCell>
                                    <CustomTableCell align="center">紐付け</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    w_customers.map((w, wi) => {
                                        return <TableRow key={`wrow_${wi}`} className={classes.row}>
                                            <TableCell align="center" className={classes.rowContent}>{`${w.id}`}</TableCell>
                                            <TableCell align="center" className={classes.rowContent}>{`${w.name}(${w.birthday})`}</TableCell>
                                            <TableCell align="center">
                                                {w.connected_id ? "紐付け済み" : "-"}
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </Grid>
            </Grid>
            <div>
                <Button color="default" variant="outlined"
                    onClick={(e) => this.goBack()}
                    className={classes.filter_button_d}>
                    戻る
                </Button>
                <Button color="primary" variant="contained"
                    onClick={(e) => this.commit()}
                    className={classes.filter_button_d}>
                    実行
                </Button>
            </div>
            <div>{this.state.commitResult}</div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))