
export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();

    public houkoku_saki: { to_name: string, to_fax }[] = []
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public to_name: string = ""
    public to_fax: string = ""
    public tanto_name: string = ""

    public title: string = ""
    public content: string = ""

    public branch_name: string = ""
    public branch_address: string = ""
    public branch_tel: string = ""
    public branch_fax: string = ""
}

export class Checks {
    houkoku: boolean = false
    soudan: boolean = false
    henshin: boolean = false
    other: boolean = false
}