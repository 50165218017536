import * as React from 'react';
import {Link} from 'react-router-dom';
import IconCard from '../components/IconCard'
import * as Icons from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../components/Colors'

const styles = () => Styles.createStyles({
    root: {
    },
    largeIcon: {
        width: "60%",
        height: "60%",
        color: "white",
        marginTop: "10px",
    },
    container: {
        marginTop: "20px",
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    link: {
        textDecoration: 'none'
    },
    info: {
        color: "#EEE",
        backgroundColor: Colors.primaryColor,
        marginBottom: "10px",
        boxShadow: "2px 2px 4px gray"
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    messageIcon: {
        opacity: 0.9,
        marginRight: "10px"
    }
})

interface Props extends Styles.WithStyles {
}

interface LocalState {
}

interface Props extends Styles.WithStyles {
}

class Page extends React.Component<Props, LocalState> { 
	displayName="JimuPage"
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }

    public componentDidMount = () => {
    }

    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container={true} spacing={1} className={classes.container}>
                    <Grid item={true} xs={4}>
                        <Link to="/jimu/jisseki_calendar" className={classes.link}>
                        <IconCard title="実績カレンダー" icon={<Icons.Group className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Styles.withStyles(styles)(Page)