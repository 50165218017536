export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public age: string = "";
  public create_date: string = "";
  public height: string = "";
  public pat_name: string = "";
  public point_a: number = 0;
  public point_b: number = 0;
  public point_c: number = 0;
  public point_d: number = 0;
  public point_e: number = 0;
  public point_f1: number = 0;
  public point_f2: number = 0;
  public screening_point: number = 0;
  public sex: string = "";
  public weight: string = "";
}

export class Checks {}
