import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../components/Colors'

const styles = () => Styles.createStyles({
    root: {
    },
    largeIcon: {
        width: "60%",
        height: "60%",
        color: "white",
        marginTop: "10px",
    },
    container: {
        marginTop: "20px",
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    link: {
        textDecoration: 'none'
    },
    info: {
        color: "#EEE",
        backgroundColor: Colors.primaryColor,
        marginBottom: "10px",
        boxShadow: "2px 2px 4px gray"
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    messageIcon: {
        opacity: 0.9,
        marginRight: "10px"
    },
    img: {
        margin: "10px",
    }
})

interface Props extends Styles.WithStyles {
}

interface LocalState {
}

interface Props extends Styles.WithStyles {
}

class Page extends React.Component<Props, LocalState> { 
	displayName="JimuJissekiCalendarPage"
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }

    public componentDidMount = () => {
    }

    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.img}>
                    <img src="/images/cal2.png" style={{width:"300px"}} />
                </div>
                <div className={classes.img}>
                    <img src="/images/cal1.png" style={{width:"800px"}} />
                </div>
            </div>
        );
    }
}

export default Styles.withStyles(styles)(Page)