import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    textField: {
        width: "90%"
    },
    input: {
        color: Colors.inputColor,
        padding: "0px",
        "& div": {
            padding: "5px 40px 5px 10px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto",
    }
})

interface Props extends Styles.WithStyles {
    editMode: boolean,
    name: string,
    value: any,
    label: string,
    list: { key: string, value: any }[],
    displayEmpty: boolean,
    display: string,
    setData: (name:string, value:any) => void,

    disabled?: boolean
}

class EditableList extends React.Component<Props> {
	displayName="EditableList"
    public static defaultProps = {
        displayEmpty: false,
        display: "block",
        disabled: false,
    }
    public render() {
        const { classes, value, name, list, displayEmpty, disabled } = this.props;
        const cur = list.find(x => x.value == value) || (list.length > 0 ? list[0] : {value:"",key:""})
        if (this.props.editMode==false) {
            return <div key={`edtn_${name}`} className={classes.root} style={{ display: this.props.display }}>{cur.key}</div>
        }
        return <div key={`eds_${name}`} className={classes.root} style={{ display: this.props.display }}>
            <Select
                value={cur.value}
                autoWidth={true}
                displayEmpty={displayEmpty}
                variant='outlined'
                onChange={(e) => this.props.setData(name, (e.target as any).value)}
                className={classes.input}
                disabled={disabled}
            >
                {
                    list.map((t, idx) =>
                        <MenuItem key={`${t.key}_${idx}`} value={t.value}>{t.key}</MenuItem>
                    )
                }
            </Select>
        </div>
    }
}

export default Styles.withStyles(styles)(EditableList)
