import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import * as Colors from './Colors'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import Firebase from '../firebase'
import { IconButton, Snackbar } from '@material-ui/core';
// import { Spring, animated } from 'react-spring/renderprops'
import * as Icons from '@material-ui/icons';
const drawerWidth = 160;


const styles = (theme: any) => ({
    root: {
        display: 'flex',
    },
    title: {
        fontSize: "large"
    },
    appBar: {
        overflow: "hidden"
    },
    toolBar: {
        minHeight: "3em",
        maxHeight: "3em"
    },
    appBarShift: {
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        color: Colors.primaryColor
    },
    drawerPaper: {
        width: drawerWidth,
        color: "white",
        backgroundColor: Colors.primaryColor
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        ...theme.mixins.toolbar,
        color: "white",
        backgroundColor: Colors.borderColor,
        fontSize: "smaller"
    },
    drawerName: {
        marginBlockEnd: 0
    },
    drawerOffice: {
        marginBlockStart: 0,
        fontSize: "1.0em"
    },
    largeIcon: {
        width: 60,
        height: 60,
        marginTop: "10px",
        marginRight: "10px",
        color: Colors.primaryColor
    },
    drawerIcon: {
        color: Colors.drawerIconColor
    },
    content: {
        flexGrow: 1,
        padding: 10,
    },
    contentShift: {
        marginLeft: 0,
    },
    drawerHeaderInfoBlock: {
        justifyContent: "left",
        paddingTop: "15px",
        width: "100%",
        color: "white"
    },
    drawerHeaderInfoElement: {
        float: "left" as any
    },
    link: {
        textDecoration: 'none',
        color: 'white'
    },
    count: {
        color: 'yellow'
    }
});

interface Props extends Styles.WithStyles<typeof styles>, PageProps {
    loginMode: boolean
    open: boolean
    setOpen: (boolean) => void
    setNotifyCount: (number) => void
}


interface LocalState {
    me: Model.User | null
    favoList: { id: number, name: string }[]
    isNew: { [index: number]: boolean }
    mentionList: { id: number, name: string, content:string, wc_number: number, talk_id: number }[]
    myTaskList: { id: number, name: string, content:string, wc_number: number, talk_id: number }[]
    limitTaskList: { id: number, name: string, content:string, wc_number: number, talk_id: number }[]
    mentionIsNew: { [index: number]: boolean }
    openSnack: boolean
    snackMessage: string
    snackId: number
    loaded: boolean
}

class PatientNavigation extends React.Component<Props, LocalState> {
    displayName = "PatientNavigation"
    constructor(props) {
        super(props)
        this.state = {
            me: null,
            favoList: [],
            isNew: {},
            mentionList: [],
            myTaskList: [],
            limitTaskList: [],
            mentionIsNew: {},
            openSnack: false,
            snackMessage: "",
            snackId: 0,
            loaded: false
        };
        this.subscrived = []
    }
    subscrived: any[] = []
    subscrivedMention: any[] = []

    private setNotifyCount(mt: { [index: number]: boolean }, ft: { [index: number]: boolean }) {
        const found = {} as any
        for (let x in ft) {
            if (ft[x]) {
                found[x] = true
            }
        }
        for (let x in mt) {
            if (mt[x]) {
                found[x] = true
            }
        }
        this.props.setNotifyCount(Object.keys(found).length)
    }
    private registMention(me) {
        if (me && me.branch) {
            /*
            const onSnap = (docs: any) => {
                docs.forEach((doc) => {
                    const t = this.state.mentionIsNew
                    const d = doc.data()
                    if (!d || !d.pat_id) return;
                    const patId = d.pat_id
                    const loc = this.props.history.location
                    const matchLocation = (loc.pathname == `/patients/${patId}`)
                    if (d && (d.updated_by_id == me.id || matchLocation)) return;
                    const ml = this.state.mentionList
                    if (!ml.find(x => x.id == patId)) {
                        ml.push({ id: patId, name: d.pat_name })
                    }
                    const head = d.range == "user" ? "(あなた宛)" :
                        d.range == "branch" ? "(事業所全体)" :
                            d.range == "company" ? "(法人全体)" : ""
                    if (d) {
                        t[patId] = true
                        this.setState({
                            mentionIsNew: t,
                            mentionList: ml,
                            openSnack: true,
                            snackMessage: d.option_type == "MessageEm" ? `(緊急コール)${d.pat_name}` : `${head}${d.pat_name}に${d.updated_by_name}がコメントしました`,
                            snackId: patId
                        })
                        this.setNotifyCount(t, this.state.isNew)
                    }
                })
            }
            this.subscrivedMention.forEach(x => x())
            this.subscrivedMention = [
                Firebase.instance.store.collection(`company/${me.branch.company_id}/user/${me.id}/notify`).
                    where("hasRead", "==", false).
                    onSnapshot((doc) => { onSnap(doc) }),
            ]*/
        }
    }
    private registFavo(me, data) {
        if (!data) return
        const favos = (data ? data.favo : [])
        this.setState({ favoList: favos })
        /*
        if (me.branch) {
            this.subscrived.forEach(x => x())
            this.subscrived = favos.map(favo => {
                const patId = favo.id
                return Firebase.instance.store.collection(`company/${me.branch.company_id}/pat_notify`).doc(`${patId}`).onSnapshot((doc) => {
                    const t = this.state.isNew
                    const d = doc.data()
                    if (!d) return;
                    const loc = this.props.history.location
                    const matchLocation = (loc.pathname == `/patients/${patId}`)
                    if (d && d.pat_id && (d.updated_by_id == me.id || matchLocation)) return;

                    if (t[patId] == undefined) {
                        t[patId] = false
                        this.setState({ isNew: t })
                        this.setNotifyCount(this.state.mentionIsNew, t)
                    }
                    else {
                        const pas = JSON.parse(localStorage.getItem("pat_activity_shown") || "{}")
                        const pd = pas[patId]
                        if (d && (!pd || d.updated_at > pd)) {
                            t[patId] = true
                            this.setState({
                                isNew: t,
                                openSnack: true,
                                snackMessage: d.option_type == "MessageEm" ? `(緊急コール)${d.pat_name}` : `${d.pat_name}に${d.updated_by_name}がコメントしました`,
                                snackId: patId
                            })
                            this.setNotifyCount(this.state.mentionIsNew, t)
                        }
                    }
                })
            })
        }*/
    }

    private load() {
        HokanApi.me()
            .then(x => {
                const me = x.data
                this.setState({ me: me })

                if (me.branch) {
                    /*
                    Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).get().then((doc) => {
                        const d = doc.data()
                        if (d && d.favo && me.branch) {
                            this.setState({ loaded: true })
                            const t = this.state.isNew
                            const pas = JSON.parse(localStorage.getItem("pat_activity_shown") || "{}")
                            Promise.all(d.favo.map(async favo => {
                                const patId = favo.id
                                return Firebase.instance.store.collection(`company/${me.branch!.company_id}/pat_notify`).doc(`${patId}`).get().then((doc) => {
                                    const d = doc.data()
                                    if (!d) return;
                                    t[patId] = (d && d.updated_by_id != me.id &&
                                        (!pas[patId] || d.updated_at > pas[patId])
                                    ) ? true : false
                                })
                            })).then(() => {
                                this.setState({ isNew: t })
                                this.setNotifyCount(this.state.mentionIsNew, t)
                            })
                        }
                    }).catch(err => {
                        console.log(err)
                    })*/
                    Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).onSnapshot((doc) => {
                        this.registFavo(me, doc.data())
                    })
                    HokanApi.message.mention_list().then( x => {
                        let mentions = x.data
                        mentions = mentions.map( m => {
                            return {id: m.wc_number, name:m.talk_title, content:m.content, wc_number:m.wc_number, talk_id:m.talk_id}
                        })
                        this.setState({mentionList:mentions})
                        if(mentions.length > 0) {
                            this.props.setNotifyCount(mentions.length)
                        }
                    })
                    HokanApi.message.my_task_list().then( x => {
                        let tasks = x.data
                        tasks = tasks.map( m => {
                            return {id: m.wc_number, name:m.talk_title, content:m.content, wc_number:m.wc_number, talk_id:m.talk_id}
                        })
                        this.setState({myTaskList:tasks})
                    })
                    HokanApi.message.limit_tasks().then( x => {
                        let tasks = x.data
                        tasks = tasks.map( m => {
                            return {id: m.wc_number, name:m.talk_title, content:m.content, wc_number:m.wc_number, talk_id:m.talk_id}
                        })
                        this.setState({limitTaskList:tasks})
                        if(tasks.length > 0) {
                            this.props.setNotifyCount(tasks.length)
                        }
                    })
                    /*
                    const register = () => {
                        if (me && me.branch) {
                            Firebase.instance.store.collection(`company/${me.branch.company_id}/user/${me.id}/notify`).where("hasRead", "==", false).get().then((docs) => {
                                const t = this.state.mentionIsNew
                                const ml = this.state.mentionList
                                docs.forEach((doc) => {
                                    const d = doc.data()
                                    if (!d || !d.pat_id) return;
                                    let head = ""
                                    const patId = d.pat_id
                                    if (d && patId && me.branch) {
                                        const idx = ml.findIndex(x => x.id == patId)
                                        if (d.option_type == "MessageEm") {
                                            head = "緊急コール"
                                        }
                                        if (idx >= 0) {
                                            ml[idx].name = ml[idx].name + head;
                                        }
                                        else {
                                            ml.push({
                                                id: patId,
                                                name: d.pat_name + head
                                            })
                                        }
                                    }
                                })
                                this.setState({ mentionIsNew: t, mentionList: ml })
                                this.setNotifyCount(t, this.state.isNew)
                            }).catch(err => {
                                console.log(err)
                            })

                            this.registMention(me)
                        }
                    }

                    register()
                    */
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    public componentDidMount() {
        this.load();
    }

    public componentWillUpdate() {
    }

    public componentWillMount() {
    }

    private doClose = () => {
        this.props.setOpen(false)
    }
    private doOpen = () => {
        if (!this.state.loaded) this.load();
        this.props.setOpen(true)
    }

    private format(content:String) {
        const regex = /\@\[.*\]\(.*\)/i;
        return content.replace(regex, '')
    }

    public render() {
        const { classes } = this.props;

        return [<SwipeableDrawer
            key="drawer"
            className={classes.drawer}
            swipeAreaWidth={1}
            // variant="persistent"
            anchor="right"
            open={this.props.open}
            hysteresis={0.3}
            onClose={() => this.doClose()}
            onOpen={() => {
                this.doOpen();
            }}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                メンションの通知
            </div>
            <Divider />
            <List>
                {
                    this.state.mentionList.map((favo, index) => {
                        const s = <div style={{ fontWeight: "bold" }} key={`li_${index}`}>
                            <div>{favo.name}</div>
                            <div>
                                {this.format(favo.content)}
                            </div>
                        </div>

                        return favo.wc_number != null ? <Link to={`/patients/${favo.id}`} key={`pnv_${index}`}
                            className={classes.link} onClick={(e) => {
                                this.doClose()
                                const t = this.state.mentionIsNew
                                t[favo.id] = false
                                this.setState({ mentionIsNew: t })
                                this.setNotifyCount(t, this.state.isNew)
                            }}>
                            <ListItem button={true} key={favo.id}>
                                <ListItemText disableTypography={true} primary={s} />
                            </ListItem>
                        </Link> :
                        <Link to={`/message/${favo.talk_id}`} key={`pnv_${index}`}
                            className={classes.link} onClick={(e) => {
                                this.doClose()
                                const t = this.state.mentionIsNew
                                t[favo.id] = false
                                this.setState({ mentionIsNew: t })
                                this.setNotifyCount(t, this.state.isNew)
                            }}>
                            <ListItem button={true} key={favo.id}>
                                <ListItemText disableTypography={true} primary={s} />
                            </ListItem>
                        </Link>
                    })
                }
            </List>
            <Divider />
            <div className={classes.drawerHeader}>
                <Link to={`/task`} className={classes.link} >
                    自分宛てタスク <span className={classes.count} >{this.state.myTaskList.length }</span> 件
                </Link>
            </div>
            <Divider />
            <div className={classes.drawerHeader}>
                <Link to={`/task`} className={classes.link} >
                    期限切れタスク <span className={classes.count}>{this.state.limitTaskList.length }</span> 件
                </Link>
            </div>
            <Divider />
            <div className={classes.drawerHeader}>
                お気に入りに登録した利用者がここ表示されます。
            </div>
            <Divider />
            <List>
                {
                    this.state.favoList.map((favo, index) => {
                        const s = <div>{favo.name}</div>
                        return <Link to={`/patients/${favo.id}`} key={`pnv_${index}`}
                            className={classes.link} onClick={(e) => {
                                this.doClose()
                                const t = this.state.isNew
                                t[favo.id] = false
                                this.setState({ isNew: t })
                                this.setNotifyCount(this.state.mentionIsNew, t)
                            }}>
                            <ListItem button={true} key={favo.id}>
                                <ListItemText disableTypography={true} primary={s} />
                            </ListItem>
                        </Link>
                    })
                }
            </List>
        </SwipeableDrawer>,
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={this.state.openSnack}
            autoHideDuration={5000}
            onClose={() => this.setState({ openSnack: false })}
            message={this.state.snackMessage}
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit"
                        onClick={() => this.setState({ openSnack: false })}>
                        <Icons.Close fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
        ]
    }
}

export default withPageProps(Styles.withStyles(styles)(PatientNavigation));
