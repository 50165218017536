import { User } from '.'

export class Comment {
    public id: number = 0
    public patient_id: number = 0
    public comment_type: string = ""
    public message: string = ""
    public updated_at: string = ""
    public created_at: string = ""
    public created_by: User = new User()
    public isNew: boolean = false
    public children: CommentChild[] = []
    public message_dom: any = null
    public image_urls: string[] = []
    public image_ids: string[] = []
}

export class CommentPost {
    public comment_type: string = ""
    public message: string = ""
    public content: string = "{}"
    public images: File[] = []
}

export class CommentChild {
    public id: number = 0
    public comment_id: number = 0
    public message: string = ""
    public updated_at: string = ""
    public created_at: string = ""
    public created_by: User = new User()
    public isNew: boolean = false
    public image_urls: string[] = []
    public image_ids: string[] = []
    public message_dom: any = null
}

export class CommentChildPost {
    public message: string = ""
    public content: string = "{}"
    public images: File[] = []
}
