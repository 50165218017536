import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour   { 
	displayName="Behaviour"
    
    public getId(): number {
        return 22
    }

    public createContent(): any {
        const res = new FormModel.MentalMSE.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }


    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content", "values"])

        const elements_scores = [
            { name: "外観", content: saV.text("gaikan") },
            { name: "意識", content: saV.text("ishiki") },
            { name: "記憶", content: saV.text("kioku") },
            { name: "認知", content: saV.text("ninchi") },
            { name: "感情", content: saV.text("kanjou") },
            { name: "意欲", content: saV.text("iyoku") },
            { name: "思考", content: saV.text("shikou") },
            { name: "知覚", content: saV.text("chikaku") },
            { name: "自我", content: saV.text("jiga") },
        ]

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
