import * as React from 'react';
import {Link} from 'react-router-dom';
import IconCard from '../components/IconCard'
import * as Icons from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import * as Styles from '@material-ui/core/styles';
import { Snackbar, IconButton } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    largeIcon: {
        width: "60%",
        height: "60%",
        color: "white",
        marginTop: "10px",
    },
    container: {
        marginTop: "20px",
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    link: {
        textDecoration: 'none'
    },
    info: {
        color: "#EEE",
        backgroundColor: "#385c88",
        marginBottom: "10px",
        boxShadow: "2px 2px 4px gray"
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    messageIcon: {
        opacity: 0.9,
        marginRight: "10px"
    }
})

interface Props extends Styles.WithStyles {
    isActive: boolean
}

interface LocalState {
    showSnackBar: boolean
}

interface Props extends Styles.WithStyles {
}

class Home extends React.Component<Props, LocalState> { 
	displayName="Home"
    constructor(props: Props) {
        super(props)
        this.state = {
            showSnackBar: false
        }
    }

    public componentDidMount = () => {
        setTimeout(() => {this.setState({showSnackBar:true})}, 2000)
    }

    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container={true} spacing={1} className={classes.container}>
                    <Grid item={true} xs={4}>
                        <Link to="/patients" className={classes.link}>
                        <IconCard title="利用者" icon={<Icons.Group className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <Link to="/document" className={classes.link}>
                        <IconCard title="書類" icon={<Icons.Description className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <Link to="/call" className={classes.link}>
                        <IconCard title="コール" icon={<Icons.Phone className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <Link to="/score" className={classes.link}>
                        <IconCard title="スコア" icon={<Icons.PieChart className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <Link to="/guidline" className={classes.link}>
                        <IconCard title="ガイドライン" icon={<Icons.LibraryBooks className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <Link to="/manage" className={classes.link}>
                        <IconCard title="管理" icon={<Icons.Settings className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                </Grid>
                <Snackbar
                    open={this.state.showSnackBar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={
                        (e, reason) => {
                            this.setState({
                                showSnackBar: false,
                            })
                        }
                    }
                    // autoHideDuration={10000}
                    message={
                        <span className={classes.message}>
                            <Icons.Info className={classes.messageIcon} />
                            こんにちは！
                        </span>
                    }
                    ContentProps={{
                        classes: {
                            root: classes.info
                        }
                    }}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" 
                            onClick={(e) => this.setState({ showSnackBar: false })}>
                            <Icons.Close className={classes.icon} />
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }
}

export default Styles.withStyles(styles)(Home)