
export class WisemanCustomer {
    id: string = ""
    name: string = ""
    name_kana: string = ""
    birthday: number | null = null
    connected_id: number | null = null
    sex: string | null = null
    zip: string | null = null
    address1: string | null = null
    address2: string | null = null
    tel1: string | null = null
    tel2: string | null = null
}
export class WisemanHoken {
    wiseman_id: string = ""
   
}

export class WisemanStaff {
    id: string = ""
    name: string = ""
    connected_id: number | null = null
}

export class WyLStaff {
    public name: string = ""
    public email: string = ""
    public password: string = ""
    public tel1: string = ""
    public tel2: string = ""
    public birthday: string = ""
    public nyusha: string = ""
    public work_style: string = ""
    public shikaku: number = 0
    public role: number = 0
    public wiseman_id: string = ""
    public privilege: string = ""
}

export class WyLPatient {
    public name: string = ""
    public name_kana: string = ""
    public birthday: string = ""
    public tel_home: string = ""
    public tel_cell: string = ""
    public env: string = ""
    public basic: string = ""
    public hoken: string = ""
    public wiseman_id: string = ""
    public youkaigo: string = ""
}