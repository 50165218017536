import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class BodySelfKouryokuBehaviour extends FormBehaviour.PdfBehaviour { 
	displayName="BodySelfKouryokuBehaviour"
    private choice = [
        { key: "未選択", value: -1 },
        { key: "１点", value: 1 },
        { key: "２点", value: 2 },
        { key: "３点", value: 3 },
        { key: "４点", value: 4 },
        { key: "５点", value: 5 }
    ]

    public getId(): number {
        return 19
    }

    public createContent(): any {
        const res = new FormModel.BodySelfKouryoku.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.BodySelfKouryoku.Core
        const setIndexValue = (name: string) => {
            const t = this.choice.find(x => x.value == content.indexes[name])
            content.values[name] = (t&&t.value!=-1) ? t.key : ""
        }
        setIndexValue("case_1")
        setIndexValue("case_2")
        setIndexValue("case_3")
        setIndexValue("case_4")

        content.indexes.sum_score = this.getResult(content)
        content.values.sum_score = `${content.indexes.sum_score} 点`

    }

    public beforeMount = () => {

    }

    private getResult(content: FormModel.BodySelfKouryoku.Core) {
        let sum =
            Math.max(0, content.indexes.case_1) +
            Math.max(0, content.indexes.case_2) +
            Math.max(0, content.indexes.case_3) +
            Math.max(0, content.indexes.case_4) 
        return sum
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "indexes"])
        const elements_scores = [
            { name: "１）少し疲れている時でも運動する自信がある（肉体的疲労）", content: saV.listRaw("case_1", this.choice) },
            { name: "２）あまり気分がのらないときでも運動する自信がある（精神的ストレス）", content: saV.listRaw("case_2", this.choice) },
            { name: "３）忙しくて時間がないときでも運動する自信がる（時間のなさ）", content: saV.listRaw("case_3", this.choice) },
            { name: "４）あまり天気が良くないときでも運動する自信がある（悪天候）", content: saV.listRaw("case_4", this.choice) },
            { name: "合計点", content: <div>{this.getResult(doc.content)}</div> },
        ]

        const elements_explain = [
            {
                name: "方法", content: <div>
                    次のような状況でも、定期的に運動する自信がありますか？<br/>
                    ※五件法で得点化(5〜1点)
                </div>
            }
        ]

        const elements = [
            { name: '評価', content: elements_scores },
            { name: '説明', content: elements_explain },
        ]

        return elements
    }
}
