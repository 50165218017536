
export class BlockCategory {
    public id: number = 0;
    public name: string = "";
    public priority: number = 0;
    public parent_id: number = 0;
    public children: BlockCategory[] = []
    public blocks: Block[] = []
}

export class Block {
    public id: number = 0;
    public title: string = "";
    public block_type: string = "";
    public is_primitive: number = 0;
    public front_text: string = "";
    public back_text: string = "";
    public parent_id: number = 0;
    public children: Block[] = [];
    public block_categories_id: number = 0;
}

export class CategoryAndBlock {
    public category: BlockCategory[] = []
    public block: Block[] = []
}

export function buildBlock(src: CategoryAndBlock) {
    const categories = src.category
    const blocks = src.block

    const idToCategory = {}
    categories.forEach(c => { 
        idToCategory[c.id] = c;
        c.children = []
        c.blocks = []
    });
    const idToBlock = {}
    blocks.forEach(b => {
        idToBlock[b.id] = b
        b.children = []
    });

    const categoryRoot:BlockCategory[] = []
    categories.forEach(c => {
        if (c.parent_id > 0) {
            const parent = idToCategory[c.parent_id]
            parent.children.push(c)
        }
        else {
            categoryRoot.push(c)
        }
    })
    blocks.forEach(b => {
        const cat = idToCategory[b.block_categories_id];
        if (b.parent_id > 0) {
            const parent = idToBlock[b.parent_id]
            parent.children.push(b)
        }
        else {
            cat.blocks.push(b)
        }
    })

    return categoryRoot
}