import { Patient } from './Patient';
import { User, Branch, Company } from '.';
import * as HokanApi from '../api/hokan'
import moment from 'moment';
import Axios from 'axios';
import { promises } from 'dns';

export class DocumentList {
  public id: number = 0
  public documents: Document[] = []
  public per: number = 0
  public page: number = 0
  public count: number = 0
}

export const TempDataName = "temp_data"

export class Document {
  public id: number = 0
  public document_type: DocumentType = new DocumentType
  public title: string = ""
  public comment: string = ""
  public created_by: User = new User()
  public updated_by: User = new User()
  public created_at: Date = new Date()
  public updated_at: Date = new Date()
  public create_date: Date = new Date()
  public patient: Patient | null = null
  public user: User | null = null
  public branch: Branch | null = null
  public company: Company | null = null
  public content: any = {}
  public pdf_url: string | null = null
  public image_urls: string[] = []
  public images: File[] = []
  public image_ids: number[] = []
  public is_temp: boolean = true
  public target_month: number | null = null
  public temp_data: any = {} /* not be posted */
  public cannotCommit: boolean = false
  public canTempSave: boolean | undefined = undefined
  public errorMessage: string = ""
  public file_urls: string[] = []
  public file_sizes: number[] = []
  public files: File[] = []
  public file_ids: number[] = []
  public is_active: boolean = true;
  public version_list: any[] = []

  static Init(doc: any | null) {
    if (!doc) return;
    doc.content = JSON.parse(doc.content)
    doc.created_at = moment(doc.created_at).toDate()
    doc.cannotCommit = false
    doc.canTempSave = undefined
    doc.errorMessage = ""
    if (!doc.temp_data) doc.temp_data = {}
    if (!doc.images) doc.images = []
    if (!doc.image_ids) doc.image_ids = []
    if (!doc.image_urls) doc.image_urls = []
    if (!doc.files) doc.files = []
    if (!doc.file_ids) doc.file_ids = []
    if (!doc.file_urls) doc.file_urls = []
    if (!doc.file_sizes) doc.file_sizes = []
  }

  static async LoadImageAndPdfs(doc: Document | null) {
    if (!doc || !doc.id) return
    try {
      const x = await HokanApi.getDocument(doc.id)
      const d = x.data
      doc.images = d.images
      doc.image_ids = d.image_ids
      doc.image_urls = d.image_urls
      doc.files = d.files
      doc.file_ids = d.file_ids
      doc.file_urls = d.file_urls
      doc.file_sizes = d.file_sizes
    }
    catch (err) {
      console.log(err)
    }
  }

  static removeImage(doc: Document, idx: number) {
    const ft = (arr) => {
      if (idx < arr.length) {
        return arr.filter((x, i) => i != idx)
      }
      return arr
    }
    doc.image_urls = ft(doc.image_urls)
    doc.images = ft(doc.images)
    doc.image_ids = ft(doc.image_ids)
  }
  static removeFile(doc: Document, idx: number) {
    const ft = (arr) => {
      if (idx < arr.length) {
        return arr.filter((x, i) => i != idx)
      }
      return arr
    }
    doc.file_urls = ft(doc.file_urls)
    doc.files = ft(doc.files)
    doc.file_ids = ft(doc.file_ids)
    doc.file_sizes = ft(doc.file_sizes)
  }
}

export class DocumentPost {
  public id: number = 0
  public fd: FormData = new FormData()

  public constructor(src: Document) {
    this.id = src.id
    this.fd = new FormData()
    this.fd.append("comment", src.comment)
    this.fd.append("content", JSON.stringify(src.content))
    this.fd.append("document_type_id", src.document_type.id.toString())
    this.fd.append("is_temp", src.is_temp.toString())
    this.fd.append("is_active", src.is_active.toString())
    this.fd.append("title", src.title)
    if (src.target_month) this.fd.append("target_month", src.target_month.toString())
    if (src.patient) this.fd.append("patient_id", src.patient.id.toString())
    if (src.user) this.fd.append("user_id", src.user.id.toString())
    if (src.branch) this.fd.append("branch_id", src.branch.id.toString())
    if (src.company) this.fd.append("company_id", src.company.id.toString())
    src.images.forEach((f, i) => {
      this.fd.append(`images[]`, f)
    })
    src.image_ids.forEach((f, i) => {
      this.fd.append(`image_ids[]`, f.toString())
    })
    src.files.forEach((f, i) => {
      this.fd.append(`files[]`, f)
    })
    src.file_ids.forEach((f, i) => {
      this.fd.append(`file_ids[]`, f.toString())
    })
  }
}

export class DocumentType {
  public id: number = 0
  public name: string = ""
  public category: string = ""
  public is_deprecated: boolean = false
  public is_template: boolean = false
  public is_download: boolean = false
  public dl_name_excel: string | null = null
  public dl_name_pdf: string | null = null
}

export class MonthlyDocumentList {
  pairs: DocumentPair[] = []
}
export class NewestDocumentList {
  pairs: DocumentPair[] = []
}

export class DocumentPair {
  patient: Patient = new Patient()
  doc: Document = new Document()
  most_resent_assessment_day: string | null = ""
}


export class DocumentChat {
  public id: number = 0
  public document_type: DocumentType = new DocumentType
  public title: string = ""
  public comment: string = ""
  public created_by: User = new User()
  public updated_by: User = new User()
  public created_at: string = ""
  public create_date: string = ""
  public updated_at: string = ""
  public user: User | null = null
  public content: any = {}
  public image_urls: string[] = []
  public file_urls: string[] = []
  public children: DocumentChatChild[] = []
  public isNew: boolean = false;
  public pdf_url: string | null = null
  public is_temp: boolean = false;
}

export class DocumentChatChild {
  public id: number = 0
  public document_id: number = 0
  public message: string = ""
  public updated_at: string = ""
  public created_at: string = ""
  public created_by: User = new User()
  public isNew: boolean = false
  public image_urls: string[] = []
  public image_ids: string[] = []
  public message_dom: any = null
}

export class DocumentChatChildPost {
  public message: string = ""
  public content: string = "{}"
  public images: File[] = []
}
