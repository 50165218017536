import * as React from 'react';
import SubTitle from '../components/SubTitle';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconCard from '../components/IconCard'
import * as Icons from '@material-ui/icons';
import Header from '../components/Header'
import OrgSelectDialog from '../components/OrgSelectDialog';

class ComponentPage extends React.Component<any, any> { 
    
    addressInput: HTMLInputElement | null = null

    constructor(props: any) {
        super(props)
        this.state = {
            dialogs: { org: false }
        }
    }

    public componentDidMount = () => {
    }

    private onClick() {
        /*
        const title = '見出し';
        const options = {
            body: '本文',
            icon: '/icon.png',
            badge: '/icon.png',
            data: {
                foo: '任意のデータ'
            },
        };
        navigator.serviceWorker
            .ready
            .then(registration => {
                registration.showNotification(title, options);
            })*/
    }

    private changeAddress() {
        if (this.addressInput) {
            console.log(this.addressInput.value)
        }
    }

    public render() {
        return (
            <div>
                <Header />
                def
                <button onClick={(e) => this.onClick()}>notificate</button>
                <SubTitle title="基本情報1" />
                <Button variant="contained" color="default">デフォルト</Button>
                <Button variant="contained" color="primary">プライマリ</Button>
                <Button variant="contained" color="secondary">セカンダリ</Button>
                <Fab size="small" color="primary" aria-label="Add">＋</Fab>
                <IconCard title="利用者" icon={<Icons.Group />} />
                <div className="loader">Loading...</div>
                <div>
                    <form className="h-adr">
                        <span className="p-country-name" style={{ display: "none" }}>Japan</span>
                        〒<input type="text" className="p-postal-code" size={7} maxLength={7}
                            onBlur={(e) => { this.changeAddress() }} /><br />
                        <input id="zipCode" type="text" className="p-region p-locality p-street-address p-extended-address"
                            ref={input => { this.addressInput = input }}
                            onChange={(e) => { this.changeAddress() }}
                            onBlur={(e) => { this.changeAddress() }}
                        /><br />
                    </form>
                </div>
                <br/>
                <Button variant="contained" color="primary"
                    onClick={(e) => {
                        this.setState({dialogs:{org:true}})
                    }
                }
                > 組織マスタ選択</Button >
                <OrgSelectDialog 
                    open={this.state.dialogs["org"]}
                    onSelect={(org, tanto) => { console.log([org, tanto]); this.setState({ dialogs: { org: false } })}}
                    onCancel={() => { this.setState({ dialogs: { org: false } })}}
                />
            </div >
        );
    }
}

export default ComponentPage