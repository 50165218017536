import * as React from 'react';
import * as Colors from '../Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Styles from '@material-ui/core/styles';
import { Table, TableHead, TableCell, TableRow, TableBody, Tooltip } from '@material-ui/core';
import CsvCreator from 'react-csv-creator';
import CodeUtil from '../../util/CodeUtil';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        textAlign: "center"
    },
    title: {
    },
    value: {
        fontSize: "70px"
    },
    unit: {

    },
    elm: {
        marginTop: "30px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
    },
    tableHead: {
        background: Colors.primaryColor,
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px"
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
        },
    },
    tiptitle: {
        margin: "5px"
    },
    tiprow: {
        margin: "3px"
    }
});

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Props extends Styles.WithStyles {
    title: string,
    data: any,
}

class GraphTableWorkTime extends React.PureComponent<Props> {
    displayName = "PiChart"
    public render() {
        const { classes } = this.props;
        const heads = [
            "名前",
            "時間(今月)", "件数(今月)", "退院カ(今月)", "他職種カ(今月)",
            "時間(先月)", "件数(先月)", "退院カ(先月)", "他職種カ(先月)"
        ]
        const cols = [
            "name",
            "cur_main", "cur_doukou", "cur_taiin_kanfa", "cur_tashokushu_kanfa",
            "past_main", "past_doukou", "past_taiin_kanfa", "past_tashokushu_kanfa",
        ]
        const heads2 = [
            "名前",
            "単独(今月)", "同行(今月)", "退院カ(今月)", "他職種カ(今月)",
            "単独(先月)", "同行(先月)", "退院カ(先月)", "他職種カ(先月)"
        ]
        const cols2 = [
            "name",
            "cur_main", "cur_doukou", "cur_taiin_kanfa", "cur_tashokushu_kanfa",
            "past_main", "past_doukou", "past_taiin_kanfa", "past_tashokushu_kanfa",
        ]
        return <div className={classes.root}>
            <div className={classes.title} style={{ position: "relative" }}>
                {this.props.title}
                <div style={{
                    position: "absolute",
                    right: "10px",
                    top: "-12px", cursor: "pointer"
                }}>
                    <CsvCreator
                        filename={this.props.title}
                        rows={CodeUtil.ToSJisList(this.props.data)}
                        text="CSV↓"
                    />
                </div>
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {
                            heads.map(h => {
                                return <CustomTableCell key={`h_${h}`}
                                    style={{ textAlign: "center", padding: "1px" }}>
                                    {h}
                                </CustomTableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.data ?
                            this.props.data.map((c, ci) => {
                                const res = <TableRow key={`gt_${c.id}`} className={classes.row}>
                                    {
                                        cols.map((x, xi) => {
                                            return <CustomTableCell key={`h_${ci}_${xi}`}
                                                style={{ textAlign: "center", padding: "1px" }}
                                                scope="row">
                                                {c[x]}
                                            </CustomTableCell>
                                        })
                                    }
                                </TableRow>
                                return <Tooltip key={`wtt_ci`} classes={{ tooltip: classes.tooltip }}
                                    title={
                                        <div style={{
                                            fontSize: "0.9rem",
                                            backgroundColor: "white",
                                            color: Colors.primaryColor,
                                            padding: "10px",
                                            width: "100px",
                                            whiteSpace: "nowrap"
                                        }}>
                                            <div className={classes.tiptitle}>
                                                <b>{c["name"]}</b>
                                            </div>
                                            <div className={classes.tiprow}>単独：{c["cur_main"]}　/　{c["past_main"]}</div>
                                            <div className={classes.tiprow}>同行：{c["cur_doukou"]}　/　{c["past_doukou"]}</div>
                                            <div className={classes.tiprow}>単独：{c["cur_taiin_kanfa"]}　/　{c["cur_tashokushu_kanfa"]}</div>
                                            <div className={classes.tiprow}>退院カ：{c["past_taiin_kanfa"]}　/　{c["past_tashokushu_kanfa"]}</div>
                                        </div>
                                    }
                                    placement="left" >
                                    {res}
                                </Tooltip>
                            })
                            : null
                    }
                </TableBody>
            </Table>
        </div >
    }
}

export default Styles.withStyles(styles)(GraphTableWorkTime)