import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as Colors from './Colors'
import { DialogTitle, Dialog, withStyles, Button, TextField, Grid } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Icons from '@material-ui/icons';
import OrganizationListPage from '../pages/OrganizationListPage';
import OrganizationPage from '../pages/OrganizationPage';
import InputText from './InputText';


const styles = () => Styles.createStyles({
    table: {
        marginBottom: "20px",
        marginTop: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    grid: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    dialog: {
        padding: "10px"
    },
    searchField: {
        maxWidth: "200px"
    },
    dialogHead: {
        position: "sticky",
        top: "0",
        backgroundColor: "white"
    }
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    }
}))(TableCell);


interface Props extends Styles.WithStyles<typeof styles> {
    patientId?: number
    open: boolean
    onSelect: (org: Model.Organization, tanto: Model.OrganizationTanto | null) => void
    onCancel: () => void
}
interface LocalState {
    dialogElement: JSX.Element
    orgs: Model.Organization[]
    selectedOrg: Model.Organization | null
    selectedTanto: Model.OrganizationTanto | null
    suggestTanto: Model.OrganizationTanto[]
    searchText: string
    creating: boolean
    creatingOrgId: number | null
    tantoEdited: boolean
    loading: boolean
    errormsg: string
}

class OrgSelectDialog extends React.Component<Props, LocalState> {
    displayName = "OrgSelectDialog"
    constructor(props: Props) {
        super(props)
        this.state = {
            dialogElement: <div />,
            orgs: [],
            selectedOrg: null,
            selectedTanto: null,
            searchText: "",
            creating: false,
            creatingOrgId: null,
            tantoEdited: false,
            loading: false,
            errormsg: "",
            suggestTanto: []
        }
    }

    public componentDidMount = () => {
        this.loadOrg();
        if (this.props.patientId) {
            HokanApi.getPatient(this.props.patientId).then(async res => {
                const pat = await Model.Patient.Patient.load(res.data)
                await Model.Patient.Patient.reloadOrgs(pat)
                const ct = pat.contact;// JSON.parse(pat.contact as any) as Model.Patient.PatientContact
                const suggests: Model.OrganizationTanto[] = []
                if (ct) {
                    if (ct.keamane) suggests.push(ct.keamane)
                    if (ct.shujii) suggests.push(ct.shujii)
                    if (ct.shujii_2) suggests.push(ct.shujii_2)
                    if (ct.soudan) suggests.push(ct.soudan)
                    if (ct.other) {
                        ct.other.forEach(x => {
                            if (x && x.id > 0) suggests.push(x)
                        })
                    }
                }
                this.setState({ suggestTanto: suggests })
            }).catch(e => {
                console.log(e)
            })
        }
    }

    private loadOrg() {
        HokanApi.organization.list()
            .then(x => {
                this.setState({ orgs: x.data })
            })
            .catch(err => {
                console.log(err)
                this.setState({ orgs: [] })
            })
    }

    private loadTanto(orgId: number) {
        HokanApi.organization.get(orgId)
            .then(x => {
                this.setState({ selectedOrg: x.data })
            })
            .catch(err => {
                console.log(err)
                this.setState({ selectedOrg: null })
            })
    }

    public close = () => {
        this.props.onCancel();
    }

    private selectOrg(org: Model.Organization) {
        this.loadTanto(org.id)
        this.setState({ creatingOrgId: org.id })
    }

    private selectTanto(tanto: Model.OrganizationTanto) {
        if (this.state.selectedOrg) {
            this.setState({ selectedTanto: tanto, tantoEdited: false })
        }
        else {
            this.setState({ selectedOrg: tanto.org, selectedTanto: tanto, tantoEdited: false })
            this.props.onSelect(tanto.org, tanto);
        }
    }

    private setTantoText(name: string, value: string) {
        const tanto = this.state.selectedTanto
        if (tanto) {
            tanto[name] = value;
            this.setState({ selectedTanto: tanto, tantoEdited: true })
        }
    }

    private commitOrg() {
        const org = this.state.selectedOrg
        if (!org) return
        this.setState({ loading: true })
        HokanApi.organization.put(org)
            .then(x => {
                this.setState({ loading: false })
                this.props.onSelect(org, this.state.selectedTanto);
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false, errormsg: "エラー" })
            })
    }

    private doneTanto() {
        if (this.state.selectedOrg && this.state.selectedTanto) {
            if (this.state.tantoEdited) {
                this.commitOrg();
            }
            else {
                this.props.onSelect(this.state.selectedOrg, this.state.selectedTanto);
            }
        }
    }

    public render() {
        const { classes } = this.props
        const { selectedOrg, selectedTanto, creating, creatingOrgId } = this.state
        const st = this.state.searchText
        if (this.props.open == false) return <div />

        const suggest = this.state.suggestTanto.length > 0 ? <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <CustomTableCell style={{ textAlign: "center", padding: "1px" }}>機関名</CustomTableCell>
                    <CustomTableCell style={{ textAlign: "center", padding: "1px" }}>名前</CustomTableCell>
                    <CustomTableCell align="right"></CustomTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    this.state.suggestTanto.map(tanto => {
                        return <TableRow key={tanto.id} className={classes.row}
                            onClick={(e) => { this.selectTanto(tanto) }}>
                            <CustomTableCell style={{ textAlign: "left", padding: "5px" }} component="th" scope="row">{tanto.org.name}</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "left", padding: "5px" }} component="th" scope="row">{tanto.name}</CustomTableCell>
                            <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table> : null


        return <Dialog
            className={classes.dialog}
            onClose={(x) => this.close()}
            fullWidth={true}
            open={true}
            style={{ padding: "10px" }}>
            {
                creating ?
                    creatingOrgId != null ? <OrganizationPage
                        onBack={() => {
                            this.loadOrg()
                            this.loadTanto(creatingOrgId)
                            this.setState({ creating: false, creatingOrgId: null })
                        }}
                        orgId={creatingOrgId}
                    />
                        : <OrganizationListPage
                            onBack={() => {
                                this.setState({ creating: false, creatingOrgId: null });
                                this.loadOrg()
                            }}
                            onNew={() => { this.setState({ creatingOrgId: 0 }) }}
                            onSelect={(org) => { this.setState({ creatingOrgId: org.id }) }}
                        />

                    : selectedTanto ?
                        [
                            <DialogTitle>{selectedTanto.name}｜担当者確認</DialogTitle>,
                            <Button variant="outlined" color="default" style={{ margin: "10px" }}
                                onClick={(e) => this.setState({ selectedTanto: null, tantoEdited: false })}>
                                戻る
                            </Button>,
                            <Grid container spacing={1} className={classes.grid}>
                                <InputText title="名前" value={selectedTanto.name} onChange={(s) => this.setTantoText("name", s)} />
                                <InputText title="フリガナ" value={selectedTanto.kana} onChange={(s) => this.setTantoText("kana", s)} />
                                <InputText title="役職" value={selectedTanto.role} onChange={(s) => this.setTantoText("role", s)} />
                                <InputText title="メール" value={selectedTanto.email} onChange={(s) => this.setTantoText("email", s)} />
                                <InputText title="携帯" value={selectedTanto.tel} onChange={(s) => this.setTantoText("tel", s)} />
                                <InputText title="FAX" value={selectedTanto.fax} onChange={(s) => this.setTantoText("fax", s)} />
                                <InputText title="緊急電話(1)" value={selectedTanto.kinkyu_tel1} onChange={(s) => this.setTantoText("kinkyu_tel1", s)} />
                                <InputText title="緊急電話(1)コメント" value={selectedTanto.kinkyu_tel1_comment} onChange={(s) => this.setTantoText("kinkyu_tel1_comment", s)} />
                                <InputText title="緊急電話(2)" value={selectedTanto.kinkyu_tel2} onChange={(s) => this.setTantoText("kinkyu_tel2", s)} />
                                <InputText title="緊急電話(2)コメント" value={selectedTanto.kinkyu_tel2_comment} onChange={(s) => this.setTantoText("kinkyu_tel2_comment", s)} />
                            </Grid>,
                            <Button variant="contained" color="primary"
                                onClick={(e) => { this.doneTanto() }}
                                disabled={this.state.loading}
                            >
                                {this.state.tantoEdited ? "編集確定＆選択" : "選択"}
                            </Button>
                        ]
                        : selectedOrg ?
                            [
                                <DialogTitle>{selectedOrg.name}｜担当者選択</DialogTitle>,
                                <div id="head-dialog" className={classes.dialogHead}>
                                    <Button variant="outlined" color="default" style={{ margin: "10px" }}
                                        onClick={(e) => this.setState({ selectedOrg: null })}>
                                        戻る
                                    </Button>
                                    {'　　'}
                                    <Button variant="outlined" color="default" style={{ margin: "10px" }}
                                        onClick={(e) => this.setState({ creating: true, creatingOrgId: selectedOrg.id })}>
                                        機関情報の編集 / 担当者の追加
                                    </Button>
                                </div>,
                                suggest,
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell align="center">名前</CustomTableCell>
                                            <CustomTableCell align="center">役職</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            selectedOrg.organization_tantos ? selectedOrg.organization_tantos.map((tanto, ti) => {
                                                return <TableRow key={ti} className={classes.row}
                                                    onClick={(e) => { this.selectTanto(tanto) }}>
                                                    <CustomTableCell style={{ textAlign: "left", padding: "5px" }} component="th" scope="row">{tanto.name}</CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "left" }} component="th" scope="row">{tanto.role}</CustomTableCell>
                                                </TableRow>
                                            })
                                                : null
                                        }
                                    </TableBody>
                                </Table>
                            ]
                            : [
                                <DialogTitle>機関選択</DialogTitle>,
                                <div id="head-dialog" className={classes.dialogHead}>
                                    <TextField
                                        label="検索"
                                        type="search"
                                        fullWidth={true}
                                        value={this.state.searchText}
                                        onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                                        className={classes.searchField}
                                        margin="normal"
                                    />
                                    <Button variant="outlined" color="default" style={{ margin: "10px" }}
                                        onClick={(e) => this.setState({ creating: true, creatingOrgId: 0 })}>
                                        組織の新規作成
                                    </Button>
                                    {'　　'}
                                    <Button variant="outlined" color="default" style={{ margin: "10px" }}
                                        onClick={(e) => this.close()}>
                                        戻る
                                    </Button>
                                </div>
                                ,
                                suggest,
                                <Table className={classes.table}>

                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell style={{ textAlign: "center", padding: "1px" }}>機関名</CustomTableCell>
                                            <CustomTableCell align="right"></CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.orgs.filter(x => { return (!st || st == "" || x.name.indexOf(st) >= 0) }).map(org => {
                                                return <TableRow key={org.id} className={classes.row}
                                                    onClick={(e) => { this.selectOrg(org) }}>
                                                    <CustomTableCell style={{ textAlign: "left", padding: "5px" }} component="th" scope="row">{org.name}</CustomTableCell>
                                                    <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            ]
            }
        </Dialog>
    }
}

export default Styles.withStyles(styles)(OrgSelectDialog)
