export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public create_date: string = "";
  public pat_name: string = "";
  public date_1: string = "";
  public date_2: string = "";
  public date_3: string = "";
  public date_4: string = "";
  public date_5: string = "";
  public date_6: string = "";
  public date_7: string = "";
  public date_8: string = "";
  public date_9: string = "";
  public date_10: string = "";
  public date_11: string = "";
  public date_12: string = "";
  public date_13: string = "";
  public date_14: string = "";
  public environment_1: string = "";
  public environment_2: string = "";
  public environment_3: string = "";
  public environment_4: string = "";
  public environment_5: string = "";
  public environment_6: string = "";
  public environment_7: string = "";
  public environment_8: string = "";
  public environment_9: string = "";
  public environment_10: string = "";
  public environment_11: string = "";
  public environment_12: string = "";
  public environment_13: string = "";
  public environment_14: string = "";
  public health_1: string = "";
  public health_2: string = "";
  public health_3: string = "";
  public health_4: string = "";
  public health_5: string = "";
  public health_6: string = "";
  public health_7: string = "";
  public health_8: string = "";
  public health_9: string = "";
  public health_10: string = "";
  public health_11: string = "";
  public health_12: string = "";
  public health_13: string = "";
  public health_14: string = "";
  public physiologic_1: string = "";
  public physiologic_2: string = "";
  public physiologic_3: string = "";
  public physiologic_4: string = "";
  public physiologic_5: string = "";
  public physiologic_6: string = "";
  public physiologic_7: string = "";
  public physiologic_8: string = "";
  public physiologic_9: string = "";
  public physiologic_10: string = "";
  public physiologic_11: string = "";
  public physiologic_12: string = "";
  public physiologic_13: string = "";
  public physiologic_14: string = "";
  public psychology_1: string = "";
  public psychology_2: string = "";
  public psychology_3: string = "";
  public psychology_4: string = "";
  public psychology_5: string = "";
  public psychology_6: string = "";
  public psychology_7: string = "";
  public psychology_8: string = "";
  public psychology_9: string = "";
  public psychology_10: string = "";
  public psychology_11: string = "";
  public psychology_12: string = "";
  public psychology_13: string = "";
  public psychology_14: string = "";

  public concretely_text: string = "";
  public problem_text: string = "";
  public reason_others: string = "";

  public created_by: string = "";
  public hyouka_date: string = "";
}

export class Checks {
  public check_reason_1: boolean = false;
  public check_reason_2: boolean = false;
  public check_reason_3: boolean = false;
  public check_reason_4: boolean = false;
  public check_reason_5: boolean = false;
  public check_reason_6: boolean = false;
  public check_reason_7: boolean = false;
  public check_reason_8: boolean = false;
  public check_reason_9: boolean = false;
  public check_reason_10: boolean = false;
  public check_reason_11: boolean = false;
}
