import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    textField: {
        width: "90%"
    },
    input: {
        color: Colors.inputColor,
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto",
    }
})

interface Props extends Styles.WithStyles {
    editMode: boolean,
    vertical: boolean,
    onlyOne: boolean,
    list: { key: string, value: boolean, label: string }[],
    setData: (name:string, value:any) => void,
    showOnlyTrue: boolean
}

class EditableCheckList extends React.Component<Props> { 
	displayName="EditableCheckList"
    public static defaultProps = {
        vertical: false,
        onlyOne: false,
        showOnlyTrue: false
    }
    public render() {
        const { classes, list, vertical } = this.props;
        let res: any = null
        if (this.props.editMode==false) {
            let t = (this.props.showOnlyTrue ? list.filter(x=>x.value) : list)
            res = t.map((t, idx) =>
                <div key={`edtn_${t.key}_${idx}`} className={classes.root}>{t.value ? "○" : "×"}：{t.label}</div>
            )
            if (t.length == 0) {
                return null
            }
        }
       else {
            res = list.map((t, idx) =>
                <FormControlLabel
                    key={`edtls_${idx}`}
                    value="end"
                    control={<Checkbox color="primary"
                        checked={!!t.value}
                        style={{ padding: "5px", marginLeft: "5px" }}
                        onChange={(e, f) => {
                            if (this.props.onlyOne && f) {
                            list.forEach(y => {
                                this.props.setData(y.key, y.key==t.key)
                            })
                        }
                        else {
                                this.props.setData(t.key, f)
                        }
                    }} />}
                    style={{ lineHeight: "1rem", marginRight: "3px", width: "fit-content" }}
                    label={<span style={{fontSize:"small", margin:"2px", lineHeight:"1"}}>{t.label}</span>}
                    labelPlacement="end"
                />
            )
       }
       if (vertical) {
           res = <FormGroup>{res}</FormGroup>
       }
        return <div key={`eds_${name}`} className={classes.root}>
            {res}
        </div>
    }
}

export default Styles.withStyles(styles)(EditableCheckList)