import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import {
    ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import { saveAs } from 'file-saver';
import Encoding from 'encoding-japanese';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        height: "300px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
        //        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    }
});


interface Props extends Styles.WithStyles {
    title: string,
    name: string,
    count: string[],
    data: any,
    color: string[],
    formatter: any,

    csvData: string
}

class BarGraph extends React.PureComponent<Props> {
    displayName = "BarGraph"
    public static defaultProps = {
        formatter: undefined,
    }
    public render() {
        const { classes } = this.props;

        const onClick = () => {
            const str = Encoding.stringToCode(this.props.csvData);
            const convert = Encoding.convert(str, 'sjis', 'unicode');
            const u8a = new Uint8Array(convert);

            const blob = new Blob([u8a], { type: 'text/csv;charset=Shift_JIS;' });
            saveAs(blob, `${this.props.title}.csv`);
        }

        return <div className={classes.root}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {this.props.title}
                <Button type="button" variant='text' onClick={onClick} style={{}}>CSV↓(Sjis)</Button>
            </div>

            <ResponsiveContainer width="100%" height="95%">
                <BarChart
                    data={this.props.data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={this.props.name} />
                    <YAxis />
                    <Tooltip formatter={this.props.formatter} />
                    <Legend />
                    {
                        this.props.count.map((c, i) => {
                            return <Bar key={`bg_${i}`} stackId="a" dataKey={this.props.count[i]} fill={this.props.color[i]} />
                        })
                    }
                </BarChart>
            </ResponsiveContainer>
        </div>
    }
}

export default Styles.withStyles(styles)(BarGraph)
