import * as React from 'react';
import { Link } from "react-router-dom";
import { User, toInjector } from '../stores';
import { inject, observer } from 'mobx-react';

class Props extends User { 
}

@inject(...toInjector(Props))
@observer
export default class extends React.Component<Props> {
    public render() {
        const { user } = this.props;
        return (
            <div>
                {user!.name !== "" ? user!.name + "さん" : "ヘッダ" }
                <Link to="/" >Home</Link>
                <Link to="/user" >User</Link>
                <Link to="/counter" >Counter</Link>
                <Link to="/auth" >Auth</Link>
                <Link to="/components" >Components</Link>
            </div>
        );
    }
}

