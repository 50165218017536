
export class Problem {
    public id: number = 0
    public problem_no: number = 0
    public name: string = ""
    public detail: string = ""
    public category: string = ""
    public symptom: Symptom[] = []
}

export class ProblemDetail extends Problem { 
	displayName="ProblemDetail"
    public symptom: Symptom[] = []
    public kbs_example: KbsExample[] = []
    public intervention: Intervention[] = []

    public static getKbsExample(problemDetail:ProblemDetail, kbs:string, score:number) {
        return problemDetail.kbs_example.find(x => x.kbs_type===kbs && x.score===score)
    }
}

export class Symptom {
    public id: number = 0
    public detail: string = ""

    public static clone(s: Symptom): Symptom {
        let res = new Symptom();
        res.id = s.id;
        res.detail = s.detail;
        return res;
    }
}

export class KbsExample {
    public id: number = 0
    public kbs_type: string = ""
    public score: number = 0
    public sentence: string = ""
}

export class Intervention {
    public id: number = 0
    public category: string = ""
    public target_category: string = ""
    public target: string = ""

    public static clone(s: Intervention): Intervention {
        let res = new Intervention();
        res.id = s.id;
        res.category = s.category;
        res.target_category = s.target_category;
        res.target = s.target;
        return res;
    }

    public static sort(lis: Intervention[]) {
        return lis.sort((a, b) => {
            return Intervention.catToNo(a) - Intervention.catToNo(b)
        })
    }

    public static catToNo(i?: Intervention) {
        const cat = i ? i.category : ""
        const w = i ? i.id * 0.001 : 0;
        if (cat == "TGC") return 1 + w
        if (cat == "CM") return 2 + w
        if (cat == "TP") return 3 + w
        if (cat == "S") return 4 + w
        return 5 + w
    }
}