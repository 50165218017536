import { OrganizationTanto } from '../Organization';

export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
    public is_jouhou_teikyo_0: boolean = true
    public is_jouhou_teikyo_1: boolean = false
    public is_jouhou_teikyo_2: boolean = false
    public is_jouhou_teikyo_3: boolean = false
}

export class Values {
    public branch_address: string = ""
    public branch_name: string = ""
    public branch_tel: string = ""
    public kanri_name: string = ""

    public comment: string = "" // その他特記すべき事項
    public create_date: string = ""
    public disease_name: string = "" // 主傷病名
    public disease_status: string = "" // 傷病の経過
    public byoujou_shougai_status: string = "" // 病状、障害等の状態
    public doc_address: string = "" // 住所
    public doc_name: string = "" // 主治医氏名
    public houmon_nissuu: string = "" // 1月当たりの訪問日数（訪問看護療養費明細書の実日数を記入すること）
    public kango_naiyo: string = "" // 看護の内容
    public iryo_care: string = "" // 医療的ケア等の実施方法及び留意事項
    public kango_mondai: string = "" // 看護上の問題等

    public kazoku_info: string = "" // 家族等及び主な介護者に係る情報
    public need_service: string = "" // 必要と考えられる保健福祉サービス
    public pat_address: string = ""
    public pat_age: string = ""
    public pat_birthday: string = ""
    public pat_name: string = ""
    public pat_tel: string = ""
    public pat_work: string = "" // 職業
    public houkoku_saki_1: OrganizationTanto[] | null = [] ;
    public houkoku_saki_2: OrganizationTanto[] | null = [];
    public houkoku_saki_3: OrganizationTanto[] | null = [];
    public houkoku_saki_4: OrganizationTanto[] | null = [];
    public seikatsu_rythm: string = "" // 食生活、清潔、排泄、睡眠、生活リズム等について
    public hukuyaku: string = "" // 服薬等の状況について
    public taijin: string = "" // 作業（仕事）、対人関係等について

    public other_service: string = "" //その他(利用しているサービス等)
    public kiou_reki: string = "" // 既往歴


    public calendar_from: string = ""
    public calendar_to: string = ""
}

export enum HoukokuType {
    None = "未選択",
    Houkan = "訪問看護報告書",
    SeishinHoukan = "精神科訪問看護報告書",
    KaigoYobouHoukan = "介護予防訪問看護報告書",
}

export class Checks {
    public youshiki_1: boolean = true
    public youshiki_2: boolean = false
    public youshiki_3: boolean = false
    public youshiki_4: boolean = false
    public check_female: boolean = false
    public check_haisetsu_1: boolean = true
    public check_haisetsu_2: boolean = false
    public check_haisetsu_3: boolean = false
    public check_ido_1: boolean = true
    public check_ido_2: boolean = false
    public check_ido_3: boolean = false
    public check_kigae_1: boolean = true
    public check_kigae_2: boolean = false
    public check_kigae_3: boolean = false
    public check_male: boolean = true
    public check_nyuyoku_1: boolean = true
    public check_nyuyoku_2: boolean = false
    public check_nyuyoku_3: boolean = false
    public check_seiyou_1: boolean = true
    public check_seiyou_2: boolean = false
    public check_seiyou_3: boolean = false
    public check_shokuji_1: boolean = true
    public check_shokuji_2: boolean = false
    public check_shokuji_3: boolean = false
    public check_youkaiago_jiritsu: boolean = false
    public check_youkaigo_1: boolean = false // 要介護認定の状況
    public check_youkaigo_2: boolean = false
    public check_youkaigo_3: boolean = false
    public check_youkaigo_4: boolean = false
    public check_youkaigo_5: boolean = false
    public check_youshien_1: boolean = false
    public check_youshien_2: boolean = false
}