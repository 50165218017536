import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import * as FormBehaviour from './FormBehaviour'
import { Button, Grid } from '@material-ui/core';
import DateUtil from '../../util/DateUtil';
import * as HokanApi from '../../api/hokan'

class TempData {
    assessments: Model.Assessment.AssessmentListResult[] = []
    exists: boolean = false
}

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"
    constructor() {
        super()
    }

    public getId(): number {
        return 7
    }

    public createContent(): any {
        const res = new FormModel.Keikakusho.Core()
        const cd = DateUtil.currentDateStr()
        res.values.create_date = cd
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        doc.patient = pat;
        const c = doc.content as FormModel.Keikakusho.Core
        if (pat) {
            this.loadFromPatientBasic(doc, sa)
            this.setCreateDate(doc, c.values.create_date, sa)
        }
    }

    public loadFromPatientBasic(doc: Model.Document.Document, sa: StoreAccessor) {
        const c = doc.content as FormModel.Keikakusho.Core
        const pat = doc.patient
        if (pat) {
            c.values.pat_youkaigo = pat.youkaigo;
            c.values.pat_birthday = pat.birthday;
            if (pat.hoken) {
                if (pat.hoken.hoken_type == Model.Patient.HokenType.Seishin) {
                    c.values.title = Model.Forms.Keikakusho.KeikakushoType.SeishinHoukan
                }
                else if (pat.hoken.hoken_type == Model.Patient.HokenType.Kaigo
                    || pat.hoken.hoken_type == Model.Patient.HokenType.Iryo) {
                    c.values.title = Model.Forms.Keikakusho.KeikakushoType.Houkan
                }
                else if (pat.hoken.hoken_type == Model.Patient.HokenType.KaigoYobo) {
                    c.values.title = Model.Forms.Keikakusho.KeikakushoType.KaigoYobouHoukan
                }
            }
            c.values.pat_address = (pat.env.address1 || "") + (pat.env.address2 || "") + (pat.env.address3 || "");
            sa.updateState();
        }
    }


    public setCreateDate(doc: Model.Document.Document, create_date: string, sa: StoreAccessor) {
        const c = doc.content as FormModel.Keikakusho.Core
        c.values.create_date = create_date
        doc.target_month = DateUtil.toTargetMonthNum(create_date)
        if (create_date) {
            this.existsCheck(doc, sa, doc.patient, doc.target_month)
        }
    }

    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount(doc: Model.Document.Document, sa: StoreAccessor) {
        doc.temp_data = new TempData()
        this.existsCheck(doc, sa, doc.patient, doc.target_month)
    }

    private existsCheck(doc: Model.Document.Document, sa: StoreAccessor, pat: Model.Patient.Patient | null, target_month: number | null) {
        if (!pat) {
            return;
        }
        HokanApi.checkDocumentExists(pat.id, doc.document_type.id, target_month, doc.id).then(res => {
            doc.temp_data.exists = res.data
            doc.cannotCommit = res.data
            sa.updateState()
        })
        sa.updateState()
    }

    private loadLatest(doc: Model.Document.Document, sa: StoreAccessor, value_names: string[], check_names: string[]) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const current = doc.content as FormModel.Keikakusho.Core
        HokanApi.getDocumentLatest(doc.patient.id, doc.document_type.id, current.values.create_date ? current.values.create_date : undefined).then(res => {
            const latest = res.data.content as FormModel.Keikakusho.Core
            value_names.forEach(name => current.values[name] = latest.values[name])
            check_names.forEach(name => current.checks[name] = latest.checks[name])
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }
    private catToJp(cat: string) {
        if (cat == "TGC") return "TGC(教育/相談/指導)"
        if (cat == "CM") return "CM(ケアマネジメント)"
        if (cat == "TP") return "TP(直接ケア)"
        if (cat == "S") return "S(観察)"
        return ""
    }
    private intSort(a: Model.Intervention, b: Model.Intervention) {
        let toN = (c: string) => {
            if (c == "TGC") return 1
            if (c == "CM") return 2
            if (c == "TP") return 3
            if (c == "S") return 4
            return 5;
        }
        let ac = toN(a.category)
        let bc = toN(b.category)
        if (ac < bc) return -1;
        if (ac > bc) return +1;
        if (a.id < b.id) return -1;
        if (a.id > b.id) return +1;
        return 0
    }

    private loadFromBranch(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.Keikakusho.Core
        const values = content.values
        HokanApi.branch.get(doc.patient.branch_id).then(res => {
            values.branch_name = res.data.name
            values.kanri_name = res.data.kanri_name
            sa.updateState()
        }).catch(err => { console.log(err) })
        HokanApi.user.get(doc.patient.main_user_id).then(res => {
            values.tanto1_name = res.data.name
            values.tanto1_role = Model.User.getShikakuName(res.data.shikaku)
            sa.updateState()
        }).catch(err => { console.log(err) })
        HokanApi.user.get(doc.patient.sub_user1_id).then(res => {
            values.tanto2_name = res.data.name
            values.tanto2_role = Model.User.getShikakuName(res.data.shikaku)
            sa.updateState()
        }).catch(err => { console.log(err) })
    }

    private async loadFromAssessment(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const current = doc.content as FormModel.Keikakusho.Core
        HokanApi.getPatientAssessments(doc.patient.id).then(async _assList => {
            if (doc.patient == null) return;

            let latestDocDate: string | null = null
            let latestAssessment: Model.Assessment.AssessmentListResult[] = []
            try {
                const latestDoc = await HokanApi.getDocumentLatest(doc.patient.id, this.getId(), current.values.create_date ? current.values.create_date : undefined)
                latestDocDate = latestDoc && latestDoc.data && latestDoc.data.content && latestDoc.data.content.values ? latestDoc.data.content.values.create_date : undefined
                if (latestDocDate) {
                    latestAssessment = (await HokanApi.getPatientAssessments(doc.patient.id, latestDocDate)).data
                }
            }
            catch {
                console.log("no latest doc")
            }

            const content = doc.content as FormModel.Keikakusho.Core
            const values = content.values
            const x = _assList.data
            if (x != null) {
                values.problem_date = "";
                values.problem = "";
                values.problem_ass = "";

                x.sort((a, b) => {
                    if (a.oldest.assessment.assessment_day < b.oldest.assessment.assessment_day) return -1;
                    if (a.oldest.assessment.assessment_day > b.oldest.assessment.assessment_day) return +1;
                    return 0;
                }).forEach(x => {
                    const la = latestAssessment.find(la => la.problem.id == x.problem.id)
                    const closeNow = (x.newest.assessment.closed && la && la.newest.assessment.closed == false)
                    if (x.newest.assessment.priority == 1 &&
                        (x.newest.assessment.closed == false || closeNow)) {
                        values.problem_date +=
                            "# " + x.problem.name + "\n" +
                            x.oldest.assessment.assessment_day + "\n\n\n";
                        let sortedIntv = x.newest.intervention.sort(this.intSort)
                        let prev = ""
                        let intvStr = sortedIntv.map(it => {
                            let t = (prev != it.category ? "\n" + this.catToJp(it.category) + ": " : ", ")
                            prev = it.category;
                            if (it.target == "その他") {
                                let z = x.newest.assessment_intervention.find(ai => ai.intervention_id == it.id)
                                return t + (z && z.comment != "" ? z.comment : "その他")
                            }
                            else {
                                return t + it.target
                            }
                        }).reduce((p, c) => p + c, "")
                        values.problem +=
                            "# " + x.problem.name + "\n" +
                            "[兆候] " + x.newest.symptom.filter(x => x.detail != "その他").map(x => x.detail + ", ").reduce((p, c) => p + c, "") +
                            (x.newest.free_symptoms.length == 0 ? "" :
                                x.newest.free_symptoms.map(x => x + ", ").reduce((p, c) => p + c, ""))
                            + "\n" +
                            "[介入] " + intvStr + "\n\n"

                        let status = ""
                        if (closeNow) {
                            status = "[プラン終了]"
                        }
                        else if (la) {
                            const laids = la.newest.assessment_intervention.map(i => i.intervention_id)
                            const curids = x.newest.assessment_intervention.map(i => i.intervention_id)
                            let changed = false;
                            curids.forEach(ci => {
                                if (laids.findIndex(li => li == ci) < 0) changed = true;
                            })
                            if (changed) status = "[プラン修正]"
                            else status = "[プラン継続]"
                        }
                        else {
                            status = "[プラン立案]"
                        }
                        values.problem_ass += "# " + x.problem.name + status + "\n"
                            + "K) " + x.newest.assessment.kbs_score_k + ":" + Model.Assessment.Assessment.getKBSSentence(x.newest.assessment, "K", x.newest.assessment.kbs_score_k) + "\n"
                            + "B) " + x.newest.assessment.kbs_score_b + ":" + Model.Assessment.Assessment.getKBSSentence(x.newest.assessment, "B", x.newest.assessment.kbs_score_b) + "\n"
                            + "S) " + x.newest.assessment.kbs_score_s + ":" + Model.Assessment.Assessment.getKBSSentence(x.newest.assessment, "S", x.newest.assessment.kbs_score_s) + "\n\n"
                    }
                })
            }
            const pat = doc.patient
            if (pat) {
                const names = Model.Patient.IryoKiguNames
                let saigai = ((typeof pat.saigai) == "string" ? JSON.parse(pat.saigai as any) : pat.saigai)
                if (saigai && saigai.kigus) {
                    Object.keys(names).forEach(key => {
                        if (saigai.kigus[key]) {
                            if (values.comment != "") values.comment += " / "
                            values.comment += names[key];
                        }
                    })
                }
            }
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }
    private async loadFromPatientKigu(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.Keikakusho.Core
        const values = content.values

        const pat = doc.patient
        if (pat) {
            const names = Model.Patient.IryoKiguNames
            let saigai = ((typeof pat.saigai) == "string" ? JSON.parse(pat.saigai as any) : pat.saigai)
            if (saigai && saigai.kigus) {
                Object.keys(names).forEach(key => {
                    if (saigai.kigus[key]) {
                        if (values.comment != "") values.comment += " / "
                        values.comment += names[key];
                    }
                })
            }
            sa.updateState()
        }
    }

    private async loadFromAssessmentMokuhyou(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const current = doc.content as FormModel.Keikakusho.Core
        HokanApi.getPatientAssessments(doc.patient.id).then(async _assList => {
            if (doc.patient == null) return;
            let latestDocDate: string | null = null
            let latestAssessment: Model.Assessment.AssessmentListResult[] = []
            try {
                const latestDoc = await HokanApi.getDocumentLatest(doc.patient.id, this.getId(), current.values.create_date ? current.values.create_date : undefined)
                latestDocDate = latestDoc && latestDoc.data && latestDoc.data.content && latestDoc.data.content.values ? latestDoc.data.content.values.create_date : undefined
                if (latestDocDate) {
                    latestAssessment = (await HokanApi.getPatientAssessments(doc.patient.id, latestDocDate)).data
                }
            }
            catch {
                console.log("no latest doc")
            }
            const content = doc.content as FormModel.Keikakusho.Core
            const values = content.values
            const x = _assList.data
            if (x != null) {
                values.mokuhyo = ""
                x.sort((a, b) => {
                    if (a.oldest.assessment.assessment_day < b.oldest.assessment.assessment_day) return -1;
                    if (a.oldest.assessment.assessment_day > b.oldest.assessment.assessment_day) return +1;
                    return 0;
                }).forEach(y => {
                    const la = latestAssessment.find(la => la.problem.id == y.problem.id)
                    const closeNow = (y.newest.assessment.closed && la && la.newest.assessment.closed == false)
                    if (y.newest.assessment.priority == 1 && (y.newest.assessment.closed == false || closeNow)) {
                        values.mokuhyo += `#${y.problem.name}：${y.newest.assessment.mokuhyou || ""}\n`
                    }
                })
            }
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor, classes: any) {
        const saV = sa.generateChild(["content", "values"])
        const saC = sa.generateChild(["content", "checks"])
        const content = doc.content as FormModel.Keikakusho.Core

        const noHoukokuSaki = (!content.values.houkoku_saki || content.values.houkoku_saki.length == 0)
        const cannotCommit = noHoukokuSaki || doc.temp_data.exists
        if (cannotCommit != doc.cannotCommit) {
            doc.cannotCommit = cannotCommit
            sa.updateState()
        }

        const elements_basic = [
            {
                name: "コピー", content:
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromPatientBasic(doc, sa)}
                        className={classes.loadButton} >記録Iから取得</Button>
            },
            { name: "タイトル", content: saV.list("title", Model.Forms.Keikakusho.KeikakushoType) },
            { name: "生年月日", content: saV.date("pat_birthday") },
            { name: "要介護認定の状況", content: saV.list("pat_youkaigo", Model.Patient.YouKaigoType) },
            { name: "住所", content: saV.text("pat_address") },
            { name: "年月", content: <div>{DateUtil.toTargetMonthStr(content.values.create_date)}{doc.temp_data.exists ? <div style={{ color: "red", fontWeight: "bold" }}>エラー：他に存在します</div> : ""}</div> }
        ]
        const copyButton = (value_names: string[], check_names: string[]) => <Button variant="outlined" size="small"
            onClick={(e) => this.loadLatest(doc, sa, value_names, check_names)}
            className={classes.loadButton} >前回から取得</Button>

        const houkoku_saki = saV.getValue("houkoku_saki")

        if (houkoku_saki) {
            if (houkoku_saki.findIndex(x => x == null) >= 0) {
                const p = houkoku_saki.filter(x => x != null)
                saV.setValue("houkoku_saki", p)
            }
        }

        const elements_houkoku = [
            { name: "コピー", content: copyButton(["houkoku_saki"], []) },
            {
                name: "内容", content: <div>
                    {
                        noHoukokuSaki ?
                            <div style={{ color: "red", fontWeight: "bold" }}>報告先は一つ以上必要です</div>
                            : null
                    }
                    {
                        houkoku_saki.map((o, i) => {
                            return saV.orgTantoC(["houkoku_saki"], i, doc.patient ? doc.patient.id : undefined)
                        })
                    }
                    {
                        sa.isEditing ?
                            <Button variant="outlined" size="small" color="primary"
                                onClick={(e) => {
                                    houkoku_saki.push(new Model.OrganizationTanto());
                                    saV.setValue("houkoku_saki", houkoku_saki)
                                }}>
                                ＋追加
                            </Button>
                            : null
                    }
                </div>
            },
        ]

        const elements_mokuhyo = [
            {
                name: "コピー", content: <div>
                    {copyButton(["mokuhyo"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromAssessmentMokuhyou(doc, sa)}
                        className={classes.loadButton} >オマハから取得</Button>
                </div>
            },
            { name: "内容", content: saV.text("mokuhyo") },
        ]
        const elements_problem = [
            {
                name: "コピー", content: <div>
                    {copyButton(["problem_date", "problem", "problem_ass"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromAssessment(doc, sa)}
                        className={classes.loadButton} >オマハから取得</Button>
                </div>
            },
            {
                name: "内容", content: <div>
                    <Grid container={true} spacing={0}>
                        <Grid item={true} xs={12} sm={4} lg={4}>
                            <div className={classes.colTitle}>日付</div>
                            {saV.text("problem_date")}
                        </Grid>
                        <Grid item={true} xs={12} sm={4} lg={4}>
                            <div className={classes.colTitle}>内容</div>
                            {saV.text("problem")}
                        </Grid>
                        <Grid item={true} xs={12} sm={4} lg={4}>
                            <div className={classes.colTitle}>評価</div>
                            {saV.text("problem_ass")}
                        </Grid>
                    </Grid>
                </div>
            }
        ]

        const checkAri = doc.content.checks.check_ari
        const elements_eisei = [
            { name: "コピー", content: copyButton(["eisei_naiyo", "eisei_zairyo", "eisei_hituyou"], ["check_nashi", "check_ari"]) },
            {
                name: "衛生材料等が必要な処置の有無", content: saC.checkList([
                    { label: "なし", key: "check_nashi" },
                    { label: "あり", key: "check_ari" }
                ], false, true)
            },
            { name: "処置の内容", content: checkAri ? saV.text("eisei_naiyo") : <div>-</div> },
            { name: "衛生材料（サイズ等）", content: checkAri ? saV.text("eisei_zairyo") : <div>-</div> },
            { name: "必要量", content: checkAri ? saV.text("eisei_hituyou") : <div>-</div> },
        ]

        const elements_houmon_yotei = [
            { name: "コピー", content: copyButton(["houmon_yotei"], []) },
            { name: "内容", content: saV.text("houmon_yotei") },
        ]
        const elements_comment = [
            {
                name: "コピー", content: <div>
                    {copyButton(["comment"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromPatientKigu(doc, sa)}
                        className={classes.loadButton} >記録1から取得</Button>
                </div>
            },
            { name: "内容", content: saV.text("comment") },
        ]

        const elements_houkoku_moto = [
            {
                name: "コピー", content: <div>
                    {copyButton(["branch_name", "kanri_name", "tanto1_name", "tanto2_name"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromBranch(doc, sa)}
                        className={classes.loadButton} >事業所情報から取得</Button>
                </div>
            },
            { name: "事業所名", content: saV.text("branch_name") },
            { name: "管理者名", content: saV.text("kanri_name") },
            {
                name: "担当者①", content: <div>
                    {saV.text("tanto1_name", "名前")}
                    {saV.text("tanto1_role", "職種")}
                </div>
            },
            {
                name: "担当者②", content: <div>
                    {saV.text("tanto2_name", "名前")}
                    {saV.text("tanto2_role", "職種")}
                </div>
            },
            {
                name: "担当者③", content: <div>
                    {saV.text("tanto3_name", "名前")}
                    {saV.text("tanto3_role", "職種")}
                </div>
            },
            {
                name: "担当者④", content: <div>
                    {saV.text("tanto4_name", "名前")}
                    {saV.text("tanto4_role", "職種")}
                </div>
            },
        ]

        const elements = [
            { name: '基本', content: elements_basic },
            { name: '報告先', content: elements_houkoku },
            { name: '看護・リハビリテーションの目標', content: elements_mokuhyo },
            { name: '問題点・解決策', content: elements_problem },
            { name: '衛生材料', content: elements_eisei },
            { name: '訪問予定の職種', content: elements_houmon_yotei },
            { name: '備考(特別な管理を要する内容、その他留意すべき事項等)', content: elements_comment },
            { name: '報告元', content: elements_houkoku_moto },
        ]

        return elements
    }
}
