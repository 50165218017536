import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 78;
  }

  public createContent(): any {
    const res = new FormModel.LifeC.Core();
    res.values.create_date = DateUtil.currentDateStr();
    HokanApi.me()
      .then((x) => {
        if (x && x.data) {
          res.values.created_by = x.data.name
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    ["houmon", "tokubetsu", "kango", "sinnryou", "iryou"].forEach((v) => {
      const checked = doc.content.checks[`check_${v}`];

      if (
        checked === true &&
        doc.content.checks[`check_${v}_hospital`] === false &&
        doc.content.checks[`check_${v}_house_call_doctor`] === false
      ) {
        doc.content.checks[`check_${v}_unnecessary`] = true;
        return;
      }

      if (checked === false) {
        doc.content.checks[`check_${v}_hospital`] = false;
        doc.content.checks[`check_${v}_house_call_doctor`] = false;
        doc.content.checks[`check_${v}_unnecessary`] = false;
      }
    });
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const checkList = [
      { text: "訪問看護指示書", value: "houmon" },
      { text: "特別訪問看護指示書", value: "tokubetsu" },
      { text: "看護サマリー", value: "kango" },
      { text: "診療情報提供書", value: "sinnryou" },
      { text: "医療処置管理表", value: "iryou" },
    ];

    const zenpanKetteiList: {
      text: string;
      type: "date" | "datetime" | "text";
    }[] = [
        { text: "退院日", type: "date" },
        { text: "退院後初回受診日", type: "date" },
        { text: "通院手段", type: "text" },
        { text: "訪問看護開始日時", type: "datetime" },
        { text: "訪問看護の頻度", type: "text" },
        { text: "初回診療日時", type: "datetime" },
        { text: "バックベッド", type: "text" },
      ];

    const kankyouList = [
      "経済状況",
      "必要経費",
      "物品調達",
      "住環境",
      "その他",
    ];

    const shinriList = [
      "意思決定・伝達",
      "家族等介護者",
      "緊急時の対応/連絡",
      "その他",
    ];

    const seiriList = [
      "医療機器・器具・処置の調整と指導内容",
      "呼吸・循環",
      "飲食・栄養・水分",
      "排泄",
      "その他",
    ];

    const kenkouList = [
      "睡眠・休息",
      "活動・移動",
      "清潔",
      "薬剤管理",
      "その他",
    ];

    const freeTextList = [
      "●全体を通してのアセスメント",
      "●初回訪問までに残された課題",
      "●開始後2週間の見通し及び訪問看護の目標",
      "●開始2週間に必要と考える訪問頻度",
    ];

    const elements_t = [
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            <label style={{ display: "flex", alignItems: "center", gap: 10 }}>
              作成者
              {saV.text(`created_by`, "")}
            </label>
            <label style={{ display: "flex", alignItems: "center", gap: 10 }}>
              退院前カンファレンス①
              {saV.date(`conference_1`)}
            </label>
            <label style={{ display: "flex", alignItems: "center", gap: 10 }}>
              退院前カンファレンス②
              {saV.date(`conference_2`)}
            </label>
          </div>
        ),
      },
      {
        name: "全般",
        content: (
          <div
            style={{
              display: "block",
              padding: 10,
              gap: 10,
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              調整する内容
              <div>
                {checkList.map((c) => {
                  const checked = saC.getValue(`check_${c.value}`);

                  return (
                    <div
                      style={{ display: "block", alignItems: "center", gap: 10 }}
                    >
                      <label>
                        {saC.check(`check_${c.value}`)}
                        {c.text}
                      </label>
                      {checked && (
                        <label>
                          {saC.radio([
                            {
                              label: "不要",
                              key: `check_${c.value}_unnecessary`,
                            },
                            {
                              label: "病院へ依頼",
                              key: `check_${c.value}_hospital`,
                            },
                            {
                              label: "往診医へ依頼",
                              key: `check_${c.value}_house_call_doctor`,
                            },
                          ])}
                        </label>
                      )}
                    </div>
                  );
                })}
                {saV.text(`zenpan_chosei`, "", "text", "block", true, "100px")}
              </div>
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              決定事項・調整結果
              <div>
                {zenpanKetteiList.map((z, i) => (
                  <div>
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    >
                      {z.text}
                      {z.type === "date" && saV.date(`zenpan_kattei_${i + 1}`)}
                      {z.type === "datetime" &&
                        saV.dateTime(`zenpan_kattei_${i + 1}`)}
                      {z.type === "text" &&
                        saV.text(`zenpan_kattei_${i + 1}`, "")}
                    </label>
                  </div>
                ))}
                <div style={{ marginTop: 10 }}>
                  {saV.text(
                    `zenpan_kettei`,
                    "",
                    "text",
                    "block",
                    true,
                    "100px"
                  )}
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "環境的領域",
        content: (
          <div
            style={{
              display: "block",
              padding: 10,
              gap: 10,
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              調整する内容
              <div>
                {kankyouList.map((text, i) => (
                  <label
                    style={{
                      display: "block",
                      marginTop: 10,
                    }}
                  >
                    {text}
                    {saV.text(
                      `kankyou_chosei_${i + 1}`,
                      "",
                      "text",
                      "block",
                      true,
                      "100px"
                    )}
                  </label>
                ))}
              </div>
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              決定事項・調整結果
              <div>
                {kankyouList.map((text, i) => (
                  <label
                    style={{
                      display: "block",
                      marginTop: 10,
                    }}
                  >
                    {text}
                    {saV.text(
                      `kankyou_kettei_${i + 1}`,
                      "",
                      "text",
                      "block",
                      true,
                      "100px"
                    )}
                  </label>
                ))}
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "心理社会的領域",
        content: (
          <div
            style={{
              display: "block",
              padding: 10,
              gap: 10,
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              調整する内容
              <div>
                {shinriList.map((text, i) => (
                  <label
                    style={{
                      display: "block",
                      marginTop: 10,
                    }}
                  >
                    {text}
                    {saV.text(
                      `shinri_chosei_${i + 1}`,
                      "",
                      "text",
                      "block",
                      true,
                      "100px"
                    )}
                  </label>
                ))}
              </div>
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              決定事項・調整結果
              <div>
                {shinriList.map((text, i) => (
                  <label
                    style={{
                      display: "block",
                      marginTop: 10,
                    }}
                  >
                    {text}
                    {saV.text(
                      `shinri_kettei_${i + 1}`,
                      "",
                      "text",
                      "block",
                      true,
                      "100px"
                    )}
                  </label>
                ))}
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "生理的領域",
        content: (
          <div
            style={{
              display: "block",
              padding: 10,
              gap: 10,
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              調整する内容
              <div>
                {seiriList.map((text, i) => (
                  <label
                    style={{
                      display: "block",
                      marginTop: 10,
                    }}
                  >
                    {text}
                    {saV.text(
                      `seiri_chosei_${i + 1}`,
                      "",
                      "text",
                      "block",
                      true,
                      "100px"
                    )}
                  </label>
                ))}
              </div>
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              決定事項・調整結果
              <div>
                {seiriList.map((text, i) => (
                  <label
                    style={{
                      display: "block",
                      marginTop: 10,
                    }}
                  >
                    {text}
                    {saV.text(
                      `seiri_kettei_${i + 1}`,
                      "",
                      "text",
                      "block",
                      true,
                      "100px"
                    )}
                  </label>
                ))}
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "健康関連行動領域",
        content: (
          <div
            style={{
              display: "block",
              padding: 10,
              gap: 10,
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              調整する内容
              <div>
                {kenkouList.map((text, i) => (
                  <label
                    style={{
                      display: "block",
                      marginTop: 10,
                    }}
                  >
                    {text}
                    {saV.text(
                      `kenkou_chosei_${i + 1}`,
                      "",
                      "text",
                      "block",
                      true,
                      "100px"
                    )}
                  </label>
                ))}
              </div>
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              決定事項・調整結果
              <div>
                {kenkouList.map((text, i) => (
                  <label
                    style={{
                      display: "block",
                      marginTop: 10,
                    }}
                  >
                    {text}
                    {saV.text(
                      `kenkou_kettei_${i + 1}`,
                      "",
                      "text",
                      "block",
                      true,
                      "100px"
                    )}
                  </label>
                ))}
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "全体",
        content: (
          <div style={{ padding: 10 }}>
            {freeTextList.map((text, i) => (
              <label
                style={{
                  display: "block",
                  marginTop: 10,
                }}
              >
                {text}
                {saV.text(
                  `free_text_${i + 1}`,
                  "",
                  "text",
                  "block",
                  true,
                  "100px"
                )}
              </label>
            ))}
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
