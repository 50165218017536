export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public create_date: string = "";
  public ikasukoto: string = "";
  public manandakoto: string = "";
  public pat_name: string = "";
  public sakuseisya: string = "";
  public shuryo_riyu: string = "";
  public shuryo_riyu_text: string = "";
}

export class Checks {}
