import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Loading from '../components/Loading'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SubTitle from '../components/SubTitle'
import * as Colors from '../components/Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import ButtonOkCancel from '../components/ButtonOkCancel';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import StandardTable from '../components/StandardTable';
import Col2Table from '../components/Col2Table';
import { Grid } from '@material-ui/core';
import { StoreAccessor } from '../util/StoreUtil';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    onBack?: () => void
    onNew?: () => void
    onSelect?: (org: Model.Organization) => void
}

interface LocalState {
    orgs: Model.Organization[]
    loading: boolean
    query: Query
}


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

class Query {
    text: string = ""
}

// @inject()
// @observer
class OrganizationListPage extends React.Component<Props, LocalState> {
    displayName = "OrganizationListPage"
    public defaultProps = {
        onBack: null,
        onNew: null,
        onSelect: null,
    }

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            orgs: [],
            query: new Query()
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true })

        const params = new URLSearchParams(this.props.location.search);
        const name = params.get("name")
        if (name) {
            const q = this.state.query;
            if (q.text != name) {
                q.text = name
                this.setState({ query: q })
            }
        }
        HokanApi.organization.list()
            .then(x => {
                this.setState({ orgs: x.data, loading: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ orgs: [], loading: false })
            })
    }

    private onNew() {
        if (this.props.onNew) {
            this.props.onNew()
        }
        else {
            this.props.history.push(`organizations/0`)
        }
    }

    private onSelect(org: Model.Organization) {
        if (this.props.onSelect) {
            this.props.onSelect(org)
        }
        else {
            this.props.history.push(`organizations/${org.id}`)
        }
    }

    private onBack() {
        if (this.props.onBack) {
            this.props.onBack()
        }
        else {
            this.props.history.push(`/manage`)
        }
    }

    public render() {
        const { classes } = this.props;
        const { orgs, loading, query } = this.state;
        const saQ = new StoreAccessor(this, true, "query")
        const element_l = [
            { name: "機関名", content: saQ.text("text", "", "text", "block", false) },
        ]
        const orgFiltered = orgs.filter(x => {
            if (query.text != "") {
                if (
                    (x.name.indexOf(query.text) == -1)
                ) return false
            }
            return true
        })
        return <div className={classes.root}>
            <SubTitle title="関係機関マスタ"
                text="機関を選択してください"
            />
            {
                loading ? <Loading /> :
                    <div>
                        <br />
                        <div style={{ display: "block", width: "100%" }}>
                            <Col2Table body={element_l} />
                        </div>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>名前</CustomTableCell>
                                    <CustomTableCell align="center">住所</CustomTableCell>
                                    <CustomTableCell align="center">電話番号</CustomTableCell>
                                    <CustomTableCell align="center">FAX</CustomTableCell>
                                    <CustomTableCell align="right"></CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    orgFiltered.map(org => {
                                        const f = (e) => this.onSelect(org)
                                        return <TableRow key={org.id} className={classes.row}>
                                            <CustomTableCell onClick={f} style={{ textAlign: "left", padding: "10px" }} component="th" scope="row">{org.name}</CustomTableCell>
                                            <CustomTableCell onClick={f} style={{ textAlign: "left" }} component="th" scope="row">{org.address}</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "left", cursor: "default" }} component="th" scope="row">{StoreAccessor.linkTel(org.tel)}</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "left", cursor: "default" }} component="th" scope="row">{StoreAccessor.linkTel(org.fax)}</CustomTableCell>
                                            <CustomTableCell onClick={f} align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>

                        <ButtonOkCancel
                            cancelLabel="戻る" okLabel="追加"
                            onCancel={() => this.onBack()}
                            onOk={() => this.onNew()}
                            disabled={this.state.loading}
                            showBack={true} />
                    </div>
            }
        </div>
    }
}
export default withPageProps(Styles.withStyles(styles)(OrganizationListPage))