
export class UADetector {

    public static isIPhone()
    {
        const ua = navigator.userAgent
        return (ua.indexOf('iPhone') > 0) 
    }

    public static isIPad()
    {
        const ua = navigator.userAgent
        return (ua.indexOf('iPad') > 0) 

    }
}