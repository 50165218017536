import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
    ResponsiveContainer, Label, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { Table, TableHead, TableRow, TableCell, TableBody, MenuItem, Select } from '@material-ui/core';
import { Rowing } from '@material-ui/icons';
import Pagination from "material-ui-flat-pagination";
import EditableCheckList from '../EditableCheckList';
import { MonthlyDocumentList } from '../../models/Document';
import Loading from '../Loading';
import CsvCreator from 'react-csv-creator';
import Encoding from 'encoding-japanese';

import { CSVLink, CSVDownload } from "react-csv";

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
        //        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    },
    table: {
        marginBottom: "5px",
        color: Colors.primaryColor,
        width: "100%",
        fontSize: "0.9rem",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {
        }
    },
    rowHead: {
        textAlign: "center",
        padding: "1px",
        position: "sticky",
        left: "0",
        minWidth: "90px"
    },
    rowChild: {
        textAlign: "center",
        padding: "1px",
        minWidth: "70px"
    }
});


interface Props extends Styles.WithStyles {
    data: any,
}

function genColor(idx: number) {
    return `hsl(${idx * 63 % 360}, 80%, 30%)`
}
/*
class CustomizedLabel extends React.PureComponent<any> {
    render() {
        const {
            x, y, stroke, value,
        } = this.props;

        return <g>
            <rect x={x - 15} y={y - 17} width={30} height={14} fill="#FFFC" />
            <text x={x} y={y} dy={-5}
                fill={stroke} fontSize={13}
                textAnchor="middle">{value}</text>
        </g>
    }
}*/

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {
            borderTopRightRadius: "5px",
        },
        "&:first-child": {
            borderTopLeftRadius: "5px",
        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);
const ref = React.createRef<HTMLDivElement>()

interface LocalState {
}

class CountReport extends React.PureComponent<Props, LocalState> {
    displayName = "TaionBan"
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
    }
    public render() {
        const { classes, data } = this.props;
        if (!data) return <div></div>
        const monthList = Object.keys(data.monthly)
        const sum: any = {
            designr: {},
            designr_start: {}
        }
        monthList.forEach((month) => {
            const m = data.monthly[month]
            Object.keys(m).forEach(x => {
                if (x == "designr") {
                    Object.keys(m.designr).forEach(y => {
                        if (y in sum["designr"] == false) {
                            sum["designr"][y] = 0
                        }
                        sum["designr"][y] += m.designr[y]
                    })
                }
                else if (x == "designr_start") {
                    Object.keys(m.designr_start).forEach(y => {
                        if (y in sum["designr_start"] == false) {
                            sum["designr_start"][y] = 0
                        }
                        sum["designr_start"][y] += m.designr_start[y]
                    })
                }
                else {
                    if ((x in sum) == false) {
                        sum[x] = 0
                    }
                    sum[x] += m[x]
                }
            })
        })
        console.log(sum)
        monthList.push("合計")
        data.monthly["合計"] = sum

        const outData = [
            [
                "年月",
                "アクティブな利用者数",
                "1 利用者人数（要支援1）",
                "1 利用者人数（要支援2）",
                "1 利用者人数（要介護1）",
                "1 利用者人数（要介護2）",
                "1 利用者人数（要介護3）",
                "1 利用者人数（要介護4）",
                "1 利用者人数（要介護5）",
                "2 訪問回数（要支援1）",
                "2 訪問回数（要支援2）",
                "2 訪問回数（要介護1）",
                "2 訪問回数（要介護2）",
                "2 訪問回数（要介護3）",
                "2 訪問回数（要介護4）",
                "2 訪問回数（要介護5）",
                "3 PTOTSTによる訪問の回数（要支援1）",
                "3 PTOTSTによる訪問の回数（要支援2）",
                "3 PTOTSTによる訪問の回数（要介護1）",
                "3 PTOTSTによる訪問の回数（要介護2）",
                "3 PTOTSTによる訪問の回数（要介護3）",
                "3 PTOTSTによる訪問の回数（要介護4）",
                "3 PTOTSTによる訪問の回数（要介護5）",
                "4 定期巡回随時対応型との連携（連携による利用者日実人員数（人））",
                "5 定期巡回随時対応型との連携（連携による訪問回数合計（回））",
                "6 介護保険のみ利用者数",
                "7 介護保険のみの訪問回数合計（回）",
                "8 7のうちPTOTSTのみによる訪問回数合計",
                "9 医療保険と介護保険の両方を利用した利用者数",
                "10 9のうち特別訪問看護指示書による人",
                "11 介護保険の緊急事訪問看護加算に同意をしている実人員数（人）",
                "12 同意期間に緊急の訪問実績のある人数合計",
                "13 同意期間の緊急の訪問回数合計（回）",
                "14 医療保険のみの利用者数",
                "15 医療保険のみの訪問回数合計（回）",
                "16  15のうちPTOTSTのみによる訪問回数合計",
                "17 14のうち、24時間対応体制加算に同意をしている実人員数",
                "18 17のうち緊急訪問看護を受けた利用者実人員数",
                "19 17のうち緊急訪問看護の回数",
                "20 精神科訪問看護のみの利用者数",
                "21 20のうちの精神科訪問看護の訪問回数",
                "22 21のうち、OTによる訪問回数",
                "23 20のうち24時間対応体制加算に同意をしている実人員数",
                "22 23のうち緊急訪問看護を受けた利用者実人員数",
                "23 21のうち緊急訪問看護の回数",
                "24 精神科在宅患者支援管理料1または2",
                "在宅で死亡した人 ターミナルケア加算（介護保険）",
                "在宅で死亡した人 ターミナルケア療養費（医療保険）",
                "在宅で死亡した人 どちらもチェックナシ",
                "在宅以外で死亡した人 ターミナルケア加算（介護保険）",
                "在宅以外で死亡した人 ターミナルケア療養費（医療保険）",
                "在宅以外で死亡した人 どちらもチェックナシ",
                "在宅以外で死亡した人 どちらもチェックナシ",
                "在宅で死亡した利用者のうち保険医療機関で在宅がん医療総合診療料を算定した利用者数",
                "各月の超重症児の利用者数の合計",
                "各月の準超重症児の利用者数の合計",
                "各月の別表7の該当利用者数の合計",
                "ターミナルケア療養費の算定件数",
                "ターミナルケア加算の算定件数",
                "d1以上の褥瘡を有していた利用者数",
                "訪問看護開始時にすでに褥瘡を有していた利用者数",
                "訪問看護利用中に新たに褥瘡が発生した利用者",
                "開始時 d0",
                "開始時 d1",
                "開始時 d2",
                "開始時 D3",
                "開始時 D4",
                "開始時 D5",
                "開始時 DTI",
                "開始時 DU",
                "DESIGN-R d0",
                "DESIGN-R d1",
                "DESIGN-R d2",
                "DESIGN-R D3",
                "DESIGN-R D4",
                "DESIGN-R D5",
                "DESIGN-R DTI",
                "DESIGN-R DU"]
        ]

        monthList.map(month => {
            const d = data.monthly[month]
            outData.push([
                month,
                d.houmon_count,
                d.user_count_houmon,
                d.user_count_iryo,
                d.user_count_kaigo,
                d.user_count_both,
                d.terminal_count_iryo,
                d.terminal_count_kaigo,
                d.user_count_has_jokuso,
                d.user_count_has_jokuso_start,
                d.user_count_has_jokuso - d.user_count_has_jokuso_start,
                d.designr_start["d0"],
                d.designr_start["d1"],
                d.designr_start["d2"],
                d.designr_start["D3"],
                d.designr_start["D4"],
                d.designr_start["D5"],
                d.designr_start["DTI"],
                d.designr_start["DU"],
                d.designr["none"],
                d.designr["d1"],
                d.designr["d2"],
                d.designr["D3"],
                d.designr["D4"],
                d.designr["D5"],
                d.designr["DTI"],
                d.designr["DU"],
            ])
        })

        const countList = [
            { label: "アクティブな利用者数", data: 2 },
            { label: "1 利用者人数（要支援1）", data: 1 },
            { label: "1 利用者人数（要支援2）", data: 0 },
            { label: "1 利用者人数（要介護1）", data: 0 },
            { label: "1 利用者人数（要介護2）", data: 0 },
            { label: "1 利用者人数（要介護3）", data: 1 },
            { label: "1 利用者人数（要介護4）", data: 0 },
            { label: "1 利用者人数（要介護5）", data: 0 },
            { label: "2 訪問回数（要支援1）", data: 3 },
            { label: "2 訪問回数（要支援2）", data: 0 },
            { label: "2 訪問回数（要介護1）", data: 0 },
            { label: "2 訪問回数（要介護2）", data: 0 },
            { label: "2 訪問回数（要介護3）", data: 4 },
            { label: "2 訪問回数（要介護4）", data: 0 },
            { label: "2 訪問回数（要介護5）", data: 0 },
            { label: "3 PTOTSTによる訪問の回数（要支援1）", data: 1 },
            { label: "3 PTOTSTによる訪問の回数（要支援2）", data: 0 },
            { label: "3 PTOTSTによる訪問の回数（要介護1）", data: 0 },
            { label: "3 PTOTSTによる訪問の回数（要介護2）", data: 0 },
            { label: "3 PTOTSTによる訪問の回数（要介護3）", data: 0 },
            { label: "3 PTOTSTによる訪問の回数（要介護4）", data: 0 },
            { label: "3 PTOTSTによる訪問の回数（要介護5）", data: 0 },
            { label: "4 定期巡回随時対応型との連携（連携による利用者日実人員数（人））", data: 0 },
            { label: "5 定期巡回随時対応型との連携（連携による訪問回数合計（回））", data: 0 },
            { label: "6 介護保険のみ利用者数", data: 1 },
            { label: "7 介護保険のみの訪問回数合計（回）", data: 3 },
            { label: "8 7のうちPTOTSTのみによる訪問回数合計", data: 1 },
            { label: "9 医療保険と介護保険の両方を利用した利用者数", data: 1 },
            { label: "10 9のうち特別訪問看護指示書による人", data: 1 },
            { label: "11 介護保険の緊急事訪問看護加算に同意をしている実人員数（人）", data: 1 },
            { label: "12 同意期間に緊急の訪問実績のある人数合計", data: 1 },
            { label: "13 同意期間の緊急の訪問回数合計（回）", data: 1 },
            { label: "14 医療保険のみの利用者数", data: 1 },
            { label: "15 医療保険のみの訪問回数合計（回）", data: 3 },
            { label: "16  15のうちPTOTSTのみによる訪問回数合計", data: 0 },
            { label: "17 14のうち、24時間対応体制加算に同意をしている実人員数", data: 1 },
            { label: "18 17のうち緊急訪問看護を受けた利用者実人員数", data: 0 },
            { label: "19 17のうち緊急訪問看護の回数", data: 0 },
            { label: "20 精神科訪問看護のみの利用者数", data: 0 },
            { label: "21 20のうちの精神科訪問看護の訪問回数", data: 0 },
            { label: "22 21のうち、OTによる訪問回数", data: 0 },
            { label: "23 20のうち24時間対応体制加算に同意をしている実人員数", data: 0 },
            { label: "22 23のうち緊急訪問看護を受けた利用者実人員数", data: 0 },
            { label: "23 21のうち緊急訪問看護の回数", data: 0 },
            { label: "24 精神科在宅患者支援管理料1または2", data: 0 },
            { label: "在宅で死亡した人 ターミナルケア加算（介護保険）", data: 0 },
            { label: "在宅で死亡した人 ターミナルケア療養費（医療保険）", data: 0 },
            { label: "在宅で死亡した人 どちらもチェックナシ", data: 0 },
            { label: "在宅以外で死亡した人 ターミナルケア加算（介護保険）", data: 0 },
            { label: "在宅以外で死亡した人 ターミナルケア療養費（医療保険）", data: 0 },
            { label: "在宅以外で死亡した人 どちらもチェックナシ", data: 0 },
            { label: "在宅以外で死亡した人 どちらもチェックナシ", data: 0 },
            { label: "在宅で死亡した利用者のうち保険医療機関で在宅がん医療総合診療料を算定した利用者数", data: 0 },
            { label: "各月の超重症児の利用者数の合計", data: 1 },
            { label: "各月の準超重症児の利用者数の合計", data: 0 },
            { label: "各月の別表7の該当利用者数の合計", data: 0 }
        ]

        return <div className={classes.root}>
            <CSVLink filename={"report.csv"}
                data={outData}>CSVダウンロード</CSVLink>

            <div style={{ overflowX: "scroll" }}>
                <Table className={classes.table}>
                    <TableHead>
                        <CustomTableCell className={classes.rowHead} scope="th">年月</CustomTableCell>
                        {
                            countList.map(c => {
                                return <CustomTableCell className={classes.rowChild} scope="th">{c.label}</CustomTableCell>
                            })
                        }
                        <CustomTableCell className={classes.rowChild} scope="th">訪問件数</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">利用者数</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">医療保険のみ利用者数</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">介護保険のみ利用者数</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">医療介護両方利用者数</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">ターミナルケア療養費の算定件数</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">ターミナルケア加算の算定件数</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">d1以上の褥瘡を有していた利用者数(※1)</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">訪問看護開始時にすでに褥瘡を有していた利用者数(※2)</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">訪問看護利用中に新たに褥瘡が発生した利用者(※3)</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">開始時<br />d0</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">開始時<br />d1</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">開始時<br />d2</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">開始時<br />D3</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">開始時<br />D4</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">開始時<br />D5</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">開始時<br />DTI</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">開始時<br />DU</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">DESIGN-R<br />d0</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">DESIGN-R<br />d1</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">DESIGN-R<br />d2</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">DESIGN-R<br />D3</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">DESIGN-R<br />D4</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">DESIGN-R<br />D5</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">DESIGN-R<br />DTI</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">DESIGN-R<br />DU</CustomTableCell>
                    </TableHead>
                    <TableBody>
                        {
                            monthList.map(month => {
                                const d = data.monthly[month]
                                return <TableRow>
                                    <CustomTableCell className={classes.rowHead} style={{
                                        background: Colors.white
                                    }} scope="row">{month}</CustomTableCell>
                                    {
                                        countList.map(c => {
                                            return <CustomTableCell className={classes.rowChild} scope="th">{month == "2022-02" || month == "合計" ? c.data : 0}</CustomTableCell>
                                        })
                                    }
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.houmon_count}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.user_count_houmon}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.user_count_iryo}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.user_count_kaigo}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.user_count_both}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.terminal_count_iryo}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.terminal_count_kaigo}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.user_count_has_jokuso}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.user_count_has_jokuso_start}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.user_count_has_jokuso - d.user_count_has_jokuso_start}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr_start["d0"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr_start["d1"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr_start["d2"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr_start["D3"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr_start["D4"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr_start["D5"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr_start["DTI"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr_start["DU"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr["none"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr["d1"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr["d2"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr["D3"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr["D4"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr["D5"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr["DTI"]}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="th">{d.designr["DU"]}</CustomTableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </div>
            <div style={{ fontSize: "0.9rem" }}>
                ※1「訪問看護開始時にすでに褥瘡を有していた利用者数」は利用者毎に最初に作成された褥瘡対策計画書を集計しています<br />
                ※2「d1以上の褥瘡を有していた利用者数」は利用者毎のDESIGN-R書類を集計しています。d0は合計されません。<br />
                ※3「訪問看護利用中に新たに褥瘡が発生した利用者」は「d1以上の褥瘡を有していた利用者数」-「訪問看護開始時にすでに褥瘡を有していた利用者数」で算出しています<br />
                ※1-3 上記、褥瘡関係書類が揃っていない場合、褥瘡関係の集計値が正しくならない事があります。<br />
            </div>

            {
                false ?
                    <div style={{ overflowX: "scroll" }}>
                        <Table className={classes.table}>
                            <TableHead>
                                <CustomTableCell className={classes.rowHead} scope="th">内容</CustomTableCell>
                                {
                                    monthList.map(month => {
                                        return <CustomTableCell className={classes.rowChild} scope="th">{month}</CustomTableCell>
                                    })
                                }
                            </TableHead>
                            <TableBody>
                                {
                                    countList.map(count => {
                                        return <TableRow>
                                            <CustomTableCell className={classes.rowChild} style={{ textAlign: "left" }} scope="th">{count.label}</CustomTableCell>
                                            {
                                                monthList.map(month => {
                                                    const d = data.monthly[month]
                                                    return <CustomTableCell className={classes.rowChild} scope="th">{0}</CustomTableCell>
                                                })
                                            }
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                    : null
            }
        </div>
    }
}

export default Styles.withStyles(styles)(CountReport)