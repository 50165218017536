import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle';
import { Grid } from '@material-ui/core';
import InputText from '../components/InputText';
import ButtonOkCancel from '../components/ButtonOkCancel';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import BranchList from '../components/BranchList';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {

    },
    textField: {
        width: "90%"
    },
    input: {
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        textAlign: "right",
        margin: "auto"
    },
    branchList: {
        width: "80%",
        margin: "auto",
        padding: "50px"
    }
});

interface Params {
    company_id: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    loading: boolean,
    data: Model.Company | null
}

// @inject()
// @observer
class CompanyPage extends React.Component<Props, LocalState> {
    displayName = "CompanyPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            data: null
        }
    }

    private setText(target, str) {
        const d = this.state.data as any
        d[target] = str;
        this.setState({ data: d })
    }

    public componentDidMount = () => {
        this.setState({ loading: true })
        if (this.createMode) {
            this.setState({ data: new Model.Company(), loading: false })
        }
        else {
            HokanApi.company.get(this.getId())
                .then(x => {
                    this.setState({ data: x.data, loading: false })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ data: null, loading: false })
                })
        }
    }

    private getId() {
        return parseInt(this.props.match.params.company_id)
    }

    private get createMode() {
        return this.getId() == 0
    }

    onCommit() {
        this.setState({ loading: true })
        const id = this.getId()
        const createMode = id == 0
        if (!this.state.data) return
        if (createMode) {
            HokanApi.company.post(this.state.data)
                .then(x => {
                    this.setState({ loading: false })
                    this.onBack()
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        } else {
            HokanApi.company.put(this.state.data)
                .then(x => {
                    this.setState({ loading: false })
                    this.onBack()
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
    }

    onBack() {
        this.props.history.goBack()
    }

    public render() {
        const { classes } = this.props
        const { data } = this.state
        return <div className={classes.root}>
            <SubTitle title="法人情報" />
            <Grid container spacing={2} className={classes.grid}>
                <InputText title="法人名" value={data ? data.name : ""} onChange={(s) => this.setText("name", s)} />
            </Grid>

            <ButtonOkCancel
                showBack={true}
                cancelLabel="戻る" okLabel="確定"
                onCancel={() => this.onBack()}
                onOk={() => this.onCommit()}
                disabled={this.state.loading} />

            {
                this.createMode ? null
                    : <div className={classes.branchList}>
                        <SubTitle title="所属事業所" />
                        <BranchList showButton={false} companyId={this.getId()} />
                    </div>
            }
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(CompanyPage))