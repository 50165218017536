import { OrganizationTanto } from '..';

export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
    public target_month = "0000-00"
    public dateInitialized = false;
}

export class Values {
    public branch_name: string = ""
    public comment: string = ""
    public communication: string = ""
    public create_date: string = ""
    public haiben_biko: string = ""
    public hainyou_biko: string = ""
    public hokou_biko: string = ""
    public ijou_biko: string = ""
    public kaidan_biko: string = ""
    public kango_assessment: string = ""
    public kanri_name: string = ""
    public koui_biko: string = ""
    public naiyou: string = ""
    public nyuyoku_biko: string = ""
    public pat_name: string = ""
    public role: string = ""
    public sanka: string = ""
    public sum: string = ""
    public seiyou_biko: string = ""
    public shokuji_biko: string = ""
    public social: string = ""
    public sub_title_1: string = ""
    public sub_title_2: string = ""
    public tanto1_name: string = ""
    public tanto1_role: string = ""
    public tanto2_name: string = ""
    public tanto2_role: string = ""
    public toilet_biko: string = ""
    public yoka: string = ""
    public houkoku_saki: OrganizationTanto[] = [];
}

export class Checks {
    public check_haiben_ichibu: boolean = false
    public check_haiben_jiritsu: boolean = false
    public check_haiben_zen: boolean = false
    public check_hainyou_ichibu: boolean = false
    public check_hainyou_jiritsu: boolean = false
    public check_hainyou_zen: boolean = false
    public check_hokou_jiritsu: boolean = false
    public check_hokou_zen: boolean = false
    public check_hokou_ichibu_1: boolean = false
    public check_hokou_ichibu_2: boolean = false
    public check_ijou_ichibu_1: boolean = false
    public check_ijou_ichibu_2: boolean = false
    public check_ijou_jiritsu: boolean = false
    public check_ijou_zen: boolean = false
    public check_kaidan_ichibu: boolean = false
    public check_kaidan_jiritsu: boolean = false
    public check_kaidan_zen: boolean = false
    public check_koui_ichibu: boolean = false
    public check_koui_jiritsu: boolean = false
    public check_koui_zen: boolean = false
    public check_nichijo_a1: boolean = false
    public check_nichijo_a2: boolean = false
    public check_nichijo_b1: boolean = false
    public check_nichijo_b2: boolean = false
    public check_nichijo_c1: boolean = false
    public check_nichijo_c2: boolean = false
    public check_nichijo_j1: boolean = false
    public check_nichijo_j2: boolean = false
    public check_nichijo_jiritsu: boolean = false
    public check_ninchi_1: boolean = false
    public check_ninchi_2a: boolean = false
    public check_ninchi_2b: boolean = false
    public check_ninchi_3a: boolean = false
    public check_ninchi_3b: boolean = false
    public check_ninchi_4: boolean = false
    public check_ninchi_jiritsu: boolean = false
    public check_ninchi_m: boolean = false
    public check_nyuyoku_ichibu: boolean = false
    public check_nyuyoku_jiritsu: boolean = false
    public check_nyuyoku_zen: boolean = false
    public check_seiyou_ichibu: boolean = false
    public check_seiyou_jiritsu: boolean = false
    public check_seiyou_zen: boolean = false
    public check_shokuji_ichibu: boolean = false
    public check_shokuji_jiritsu: boolean = false
    public check_shokuji_zen: boolean = false
    public check_toilet_ichibu: boolean = false
    public check_toilet_zen: boolean = false
    public check_toilet_jiritsu: boolean = false
}