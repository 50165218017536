import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import CallList from '../components/call/CallList';
import CallNewOrEdit from '../components/call/CallNewOrEdit';
import { CallPageMode } from '../components/call/CallComponent';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        
    }
});

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    isAllMount:boolean,
}

// @inject()
// @observer
class CallPage extends React.Component<Props, LocalState> { 
	displayName="CallPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            isAllMount:false
        }
        console.log(this.getMode())
    }

    private getMode:() => CallPageMode = () => {
        switch(this.props.location.hash){
            case "":
                return "list"
                break;
            case "#new":
                return "new"
                break;
            default:
                return Number.parseInt(this.props.location.hash.slice(1))
            break;
        }
    }

    private transitionToggleMount = (route:string) => {
        setTimeout(() => this.setState({
            isAllMount: false
        }), 400)
        setTimeout(() => this.props.history.push(route), 0)
        this.setState({
            isAllMount:true
        })

    }

    private new = () => {
        this.transitionToggleMount(`/call#new`)
    }

    private edit = (id: number) => {
        this.transitionToggleMount(`/call#${id}`)
    }
    
    private backToList = () => {
        this.transitionToggleMount(`/call`)
    }

    public render() {
        const { classes } = this.props
        const mode = this.getMode()

        return (
            <div className={classes.root} >
                {
                    this.state.isAllMount || mode == "list"  ? <CallList mode={mode} new={() => this.new()} edit={(id: number) => this.edit(id)} /> : null
                }
                {
                    this.state.isAllMount || mode == "new" ? <CallNewOrEdit backToList={() => this.backToList()} isNew={true} mode={mode} /> : null
                }
                {
                    this.state.isAllMount || typeof(mode) == "number" ? <CallNewOrEdit backToList={() => this.backToList()} isNew={false} mode={mode} /> : null
                }
            </div>
        )
    }
}

export default withPageProps(Styles.withStyles(styles)(CallPage))