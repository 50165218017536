import * as React from 'react';
import { Redirect, Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Auth, toInjector } from './stores'
import { inject, observer } from 'mobx-react';
import HeaderNavigation from './components/HeaderNavigation'
import HeaderNavigationMock from './components/HeaderNavigationMock'
import ScrollToTop from './components/ScrollToTop'

import CounterPage from './pages/CounterPage'
import ComponentsPage from './pages/ComponentsPage'
import PatientListPage from './pages/PatientListPage'
import PatientPage from './pages/PatientPage'
import ShinkiIraiPage from './pages/ShinkiIraiPage'
import ScorePage from './pages/ScorePage'
import NotYetPage from './pages/NotYetPage'
import DocumentPage from './pages/DocumentPage'
import DocumentListPage from './pages/DocumentListPage'
import Home from './pages/Home'
import AuthPage from './pages/AuthPage'
import LoginPage from './pages/LoginPage'
import LoginCognitoPage from './pages/LoginCognitoPage'
import GuidlinePage from './pages/GuidlinePage'
import CreateProblemPage from './pages/CreateProblemPage'
import CreateProblemsPage from './pages/CreateProblemsPage'
import GoogleCalendarLoginPage from './pages/GoogleCalendarLoginPage';
import GoogleCalendarRedirectPage from './pages/GoogleCalendarRedirectPage';
import CreateRecordPage from './pages/CreateRecordPage'
import ManagePage from './pages/ManagePage';
import CallPage from './pages/CallPage';
import KangoKirokuPage from './pages/KangoKirokuPage';
import CompanyListPage from './pages/CompanyListPage'
import CompanyPage from './pages/CompanyPage'
import BranchPage from './pages/BranchPage'
import BranchListPage from './pages/BranchListPage'
import UserPage from './pages/UserPage'
import UserListPage from './pages/UserListPage'
import OrganizationListPage from './pages/OrganizationListPage'
import OrganizationPage from './pages/OrganizationPage'
import ImportPage from './pages/ImportPage'
import ExportPage from './pages/ExportPage'
import GenogramPage from './pages/GenogramPage'
import InquiryPage from './pages/InquiryPage'
import KagiAzukariPage from './pages/Forms/KagiAzukariPage'
import JimuPage from './pages/JimuPage'
import JimuJissekiCalendarPage from './pages/JimuJissekiCalendarPage'
import IncidentListPage from './pages/IncidentListPage'
import IncidentPage from './pages/IncidentPage'
import WorkRecordPage from './pages/WorkRecordPage'
import WisemanStaffPage from './pages/WisemanStaffPage'
import WisemanCustomerPage from './pages/WisemanCustomerPage'
import WisemanJissekiPage from './pages/WisemanJissekiPage'
import SukoyakaJissekiPage from './pages/SukoyakaJissekiPage'
import ImportStaffPage from './pages/ImportStaffPage'
import ImportPatientPage from './pages/ImportPatientPage'
import TaskListPage from './pages/TaskListPage'
import ChatPage from './pages/ChatPage'
import MyPage from './pages/MyPage'
import MessageListPage from './pages/MessageListPage'
import MessagePage from './pages/MessagePage'
import MessageCreatePage from './pages/MessageCreatePage'
import PasswordResetPage from './pages/PasswordResetPage'
import SystemPage from './pages/SystemPage'
import KiyakuPage from './pages/KiyakuPage'
import ManageMessagePage from './pages/ManageMessagePage'
import * as HokanApi from './api/hokan'
import StackTrace from "stacktrace-js";



interface Props extends Auth, RouteComponentProps {
}

@inject(...toInjector(Auth))
@observer
class App extends React.Component<Props> { 
	displayName="App"
  public componentDidMount() {
    /*
    try {
      Notification.requestPermission().then(permission => {
        switch (permission) {
          case 'granted':
            console.log("granted");
            break;
          case 'denied':
            console.log("denied")
            break;
          case 'default':
            console.log("default")
            break;
          default:
            break;
        }
      });
    }
    catch (error) {
      if (error instanceof TypeError) {
        Notification.requestPermission((p) => {
          console.log(p);
        }
        );
      } else {
        console.log(error);
        console.log('This browserdoes not support desktop notification');
      }
    }*/

  }

  componentDidCatch(error, info)
  {
    StackTrace.fromError(error).then(err => {
      HokanApi.sendLog({
        path: window.location.href,
        agent: window.navigator.userAgent,
        err: err,
        error: error.toString(),
        info: info
      })
    });
  }

  public render() {
    const currentkey = this.props.location.pathname || ""

    const { auth, location } = this.props
    const isLoginPage = location.pathname === "/login"
    const isPasswordPage = location.pathname === "/password_reset"
    const needLogin = (!isLoginPage && auth!.isLogin === false)

    return <div>
      {
        needLogin || isLoginPage ?
          <HeaderNavigationMock />
          :
          <HeaderNavigation key="head_1" loginMode={false} />
      }
      
      <TransitionGroup>
        <CSSTransition key={currentkey} classNames='fade' timeout={100} unmountOnExit={true}>
          <div style={{
            marginTop: "3em",
            position: "fixed", top: "0", bottom: "0",
            width: "100%",
            overflowY: "scroll",
            WebkitOverflowScrolling: "touch"
          }}>
            <ScrollToTop>
              {
                isPasswordPage ?
                  <Switch location={this.props.location}>
                    <Route exact={true} path="/password_reset" component={PasswordResetPage} />
                  </Switch>
                : needLogin ?
                  <Redirect to={{ pathname: "/login", state: { from: location } }} />
                  :
                  <Switch location={this.props.location}>

                    <Route exact={true} path="/login" component={LoginPage} />
                    <Route exact={true} path="/login_cognito" component={LoginCognitoPage} />
                    <Route exact={true} path="/my" component={MyPage} />
                    <Route exact={true} path="/patients" component={PatientListPage} />
                    <Route exact={true} path="/patients/shinki_irai/:patient_id" component={ShinkiIraiPage} />
                    <Route exact={true} path="/patients/:patient_id" component={PatientPage} />
                    <Route exact={true} path="/patients/:patient_id/kango_kiroku" component={KangoKirokuPage} />
                    <Route exact={true} path="/patients/:patient_id/create_problems" component={CreateProblemsPage} />
                    <Route exact={true} path="/patients/:patient_id/create_problem" component={CreateProblemPage} />
                    <Route exact={true} path="/patients/:patient_id/create_problem/:problem_id" component={CreateProblemPage} />
                    <Route exact={true} path="/patients/:patient_id/create_problem/:problem_id/:ass_id" component={CreateProblemPage} />
                    <Route exact={true} path="/patients/:patient_id/create_record" component={CreateRecordPage} />

                    <Route exact={true} path="/document" component={DocumentListPage} />
                    <Route exact={true} path="/document/genogram" component={GenogramPage} />
                    <Route exact={true} path="/document/:document_id" component={DocumentPage} />
                    <Route exact={true} path="/document/generate/kagi_azukari" component={KagiAzukariPage} />
                    
                    <Route exact={true} path="/jimu" component={JimuPage} />
                    <Route exact={true} path="/jimu/jisseki_calendar" component={JimuJissekiCalendarPage} />

                    <Route exact={true} path="/task" component={TaskListPage} />
                    <Route exact={true} path="/call" component={CallPage} />
                    <Route exact={true} path="/score" component={ScorePage} />
                    <Route exact={true} path="/guidline" component={GuidlinePage} />
                    <Route exact={true} path="/inquiry" component={InquiryPage} />
                    <Route exact={true} path="/incident" component={IncidentListPage} />
                    <Route exact={true} path="/incident/:incident_id" component={IncidentPage} />
                    <Route exact={true} path="/work_record" component={WorkRecordPage} />

                    <Route exact={true} path="/message" component={MessageListPage} />
                    <Route exact={true} path="/message/new" component={MessageCreatePage} />
                    <Route exact={true} path="/message/:message_id" component={MessagePage} />

                    <Route exact={true} path="/manage" component={ManagePage} />
                    <Route exact={true} path="/manage/notyet" component={NotYetPage} />
                    <Route exact={true} path="/manage/company" component={CompanyListPage} />
                    <Route exact={true} path="/manage/company/:company_id" component={CompanyPage} />
                    <Route exact={true} path="/manage/branch" component={BranchListPage} />
                    <Route exact={true} path="/manage/branch/:branch_id" component={BranchPage} />
                    <Route exact={true} path="/manage/user" component={UserListPage} />
                    <Route exact={true} path="/manage/user/:user_id" component={UserPage} />
                    <Route exact={true} path="/manage/user" component={UserPage} />
                    <Route exact={true} path="/manage/organizations" component={OrganizationListPage} />
                    <Route exact={true} path="/manage/organizations/:org_id" component={OrganizationPage} />
                    <Route exact={true} path="/manage/import" component={ImportPage} />
                    <Route exact={true} path="/manage/message" component={ManageMessagePage} />
                    <Route exact={true} path="/manage/import/wiseman_staff" component={WisemanStaffPage} />
                      <Route exact={true} path="/manage/import/wiseman_customer" component={WisemanCustomerPage} />
                      <Route exact={true} path="/manage/import/wiseman_jisseki" component={WisemanJissekiPage} />
                      <Route exact={true} path="/manage/import/sukoyaka_jisseki" component={SukoyakaJissekiPage} />
                      <Route exact={true} path="/manage/import/staff" component={ImportStaffPage} />
                      <Route exact={true} path="/manage/import/patient" component={ImportPatientPage} />
                    <Route exact={true} path="/manage/export" component={ExportPage} />

                    /* system */
                      <Route path="/system" component={SystemPage} />

                    /* dev */
                    <Route exact={true} path="/chat" component={ChatPage} />
                    <Route exact={true} path="/counter" component={CounterPage} />
                    <Route exact={true} path="/user" component={UserPage} />
                      <Route exact={true} path="/auth" component={AuthPage} />
                      <Route exact={true} path="/components" component={ComponentsPage} />
                      <Route exact={true} path="/terms" component={KiyakuPage} />
                    <Route exact={true} path="/google-calendar-oauth" component={GoogleCalendarLoginPage} />
                    <Route exact={true} path="/google-calendar-oauth/redirect" component={GoogleCalendarRedirectPage} />

                    <Route path="/home" component={Home} />
                    <Route path="/" component={PatientListPage} />
                  </Switch>
              }
            </ScrollToTop>
            <div style={{ color: "gray", textAlign: "center", fontSize: "smaller", margin:"10px"}}>
              <img src="/images/icons/fullcolor_logo.png" style={{width:"200px", margin:"5px"}} />
              <div>ⓒ2023 メディカルインフォマティクス株式会社. All Rights Reserved.</div>
              <div>㈳オマハシステムジャパンよりコード提供</div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  }
}

export default withRouter(App);
