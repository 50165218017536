import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as Colors from '../components/Colors'
import * as Icons from '@material-ui/icons';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import SubTitle from '../components/SubTitle'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
const styles = () => Styles.createStyles({
    root: {

    },
    table: {
        margin: "20px",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "1px"
    },
    row: {
        color: Colors.primaryColor,
        border: 'solid',
        borderWidth: "1px",
        marginTop: '-1px',
        borderColor: Colors.borderColor,
        '&:hover': {
            backgroundColor: Colors.backColor
        }
    },
    icon: {
        display: "flex",
        fontSize: "20px"
    }
});

interface Props extends PageProps, Styles.WithStyles {
}


interface LocalState {
    guidlines: Model.Guidline[]
    query: string
    loading: boolean
}

// @inject()
// @observer
class GuidlinePage extends React.Component<Props, LocalState> {
    displayName = "GuidlinePage"
    constructor(props: Props) {
        super(props)
        this.state = {
            query: "",
            guidlines: [],
            loading: true
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true })
        HokanApi.getGuidlines()
            .then(x => {
                this.setState({ guidlines: x.data, loading: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    public render() {
        const { classes } = this.props
        const { query } = this.state
        const guidlines = this.state.guidlines.filter((g) => {
            return query === "" || g.title.indexOf(query) >= 0
        })
        let categoryList: string[] = []
        guidlines.forEach(g => {
            if (categoryList.includes(g.category) == false)
                categoryList.push(g.category);
        })

        return <div className={classes.root}>
            <div className={classes.table}>
                <div>
                    <form className={classes.container} noValidate={true} autoComplete="off">
                        <TextField
                            id="query"
                            label="検索"
                            className={classes.textField}
                            value={this.state.query}
                            onChange={(e) => this.setState({ query: e.target.value })}
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                        />
                    </form>
                </div>
                {
                    this.state.loading ?
                        <div className="loader">Loading...</div>
                        :
                        <div>
                            {categoryList.map((cat, idx) => {
                                return <div>
                                    <ExpansionPanel
                                        expanded={query == "" ? undefined : true}
                                        style={{
                                            border: "solid", borderWidth: "1px", borderRadius: "0px"
                                        }} key={"pr_" + idx}>
                                        <ExpansionPanelSummary expandIcon={<Icons.ExpandMore />}
                                            style={{
                                                fontSize: "0.9rem", padding: "0 10px", verticalAlign: "middle",
                                                backgroundColor: Colors.backColor,
                                                marginBottom: categoryList.length - 1 == idx ? "0px" : "-1px",
                                                height: "40px"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "inline-block", marginTop: "auto", marginBottom: "auto"
                                                }}
                                            >
                                                {cat}
                                            </div>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails style={{ padding: "5px", overflowWrap: "anywhere" }}>
                                            <List style={{ width: "100%" }}>
                                                {
                                                    guidlines.filter(x => x.category == cat).map((row, idx) => (
                                                        <ListItem button={true} key={row.id} className={classes.row}
                                                            onClick={(e) => window.open(row.url)}>
                                                            <ListItemText
                                                                primary={row.title}
                                                                disableTypography={true} />
                                                            <span className={classes.icon}><Icons.OpenInNew /></span>
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            })
                            }
                            <br />
                        </div>
                }
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(GuidlinePage))