import * as React from 'react';
import { Link } from 'react-router-dom';
import IconCard from '../components/IconCard'
import * as Icons from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import * as Styles from '@material-ui/core/styles';

const styles = () => Styles.createStyles({
    root: {
    },
    largeIcon: {
        width: "60%",
        height: "60%",
        color: "white",
        marginTop: "10px",
    },
    container: {
        marginTop: "20px",
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    link: {
        textDecoration: 'none'
    }
})

interface Props extends Styles.WithStyles {
}

class Home extends React.Component<Props> { 
	displayName="Home"
    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container={true} spacing={1} className={classes.container}>
                    <Grid item={true} xs={6}>
                        <Link to="/manage/company" className={classes.link}>
                            <IconCard title="法人情報" icon={<Icons.Group className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <Link to="/manage/branch" className={classes.link}>
                            <IconCard title="事務所情報" icon={<Icons.LocationCity className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <Link to="/manage/user" className={classes.link}>
                            <IconCard title="スタッフ情報" icon={<Icons.AccountCircle className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <Link to="/manage/organizations" className={classes.link}>
                            <IconCard title="関係機関マスタ" icon={<Icons.AccountBalance className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    {
                        false ?
                    <Grid item={true} xs={6}>
                        <Link to="/jimu" className={classes.link}>
                            <IconCard title="事務業務" icon={<Icons.Group className={classes.largeIcon} />} />
                        </Link>
                    </Grid>:null
                    }
                    <Grid item={true} xs={6}>
                        <Link to="/manage/import" className={classes.link}>
                            <IconCard title="データ連携" icon={<Icons.Group className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                    
                     {
                        false ? [
                            <Grid item={true} xs={6}>
                                <Link to="/manage/export" className={classes.link}>
                                    <IconCard title="データ書き出し" icon={<Icons.SaveAlt className={classes.largeIcon} />} />
                                </Link>
                            </Grid>,
                            <Grid item={true} xs={6}>
                                <Link to="/manage/notyet" className={classes.link}>
                                    <IconCard title="会計業務" icon={<Icons.AccountBalanceWallet className={classes.largeIcon} />} />
                                </Link>
                            </Grid>,
                            <Grid item={true} xs={6}>
                                <Link to="/manage/notyet" className={classes.link}>
                                    <IconCard title="操作・ログイン履歴" icon={<Icons.Notes className={classes.largeIcon} />} />
                                </Link>
                            </Grid>
                         ] : null
                     }            

                    <Grid item={true} xs={6}>
                        <Link to="/manage/message" className={classes.link}>
                            <IconCard title="メッセージ" icon={<Icons.Message className={classes.largeIcon} />} />
                        </Link>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Styles.withStyles(styles)(Home)