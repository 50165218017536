import * as React from 'react';
import { PageProps, withPageProps } from '../components/Page'
import * as Styles from '@material-ui/core/styles';
import SubTitle from '../components/SubTitle'
import * as Colors from '../components/Colors'
import Victor from "victor";
import { Button, Grid } from '@material-ui/core';
import InputText from '../components/InputText';
import InputCheck from '../components/InputCheck';
import InputList from '../components/InputList';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1400px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    canvas: {
        border: '1px solid gray',
        backgroundColor: 'white',
    },
    button: {
        margin: "10px"
    }
})

const COLOR_WHITE = 'rgb(250, 250, 250)';
const COLOR_WHITE_ALPHA = 'rgba(250, 250, 250, 100)';
const COLOR_GRAY = 'rgb(200, 200, 220)';
const COLOR_BLACK = 'rgb(20, 20, 20)';
const COLOR_FOCUS = 'rgba(100, 100, 250, 0.3)';
const COLOR_FOCUS_CLEAR = 'rgba(100, 100, 250, 0.8)';
const COLOR_RELATION = 'rgba(250, 50, 50, 0.8)';

class Genogram {
    private idCounter: number = 1
    public root: GenogramNode
    public nodes: GenogramNode[] = []
    public edges: GenogramEdge[] = []
    public lines: GenogramLine[] = []
    public size = new Victor(600, 600);
    public gridSize = 60;
    public gridCount;
    public focus?: GenogramNode | GenogramEdge | GenogramLine

    constructor() {
        this.gridCount = this.size.clone().divide(new Victor(this.gridSize, this.gridSize)).unfloat()
        this.idCounter = 1;
        const r = new GenogramNode(this.getId());
        r.isCentral = true;
        r.pos = this.gridCount.clone().divide(new Victor(2, 2));
        this.focus = r;
        this.nodes.push(r);
        this.root = r;
    }

    public getId() {
        return this.idCounter++;
    }

    public load() {

    }

    public clearFocus() {
        this.focus = undefined;
    }

    public addNewNode() {
        const r = new GenogramNode(this.getId());
        r.pos = this.gridCount.clone().divide(new Victor(2, 2));
        this.nodes.push(r);
        this.focus = r;
    }

    public deleteFocus() {
        const node = this.getFocusNode();
        const edge = this.getFocusEdge();
        const line = this.getFocusLine();
        if (node) {
            this.deleteNode(node);
        }
        else if (edge) {
            this.deleteEdge(edge);
        }
        else if (line) {
            this.deleteLine(line);
        }
        this.clearFocus();
    }

    public deleteNode(target: GenogramNode) {
        this.nodes = this.nodes.filter(n => n.id != target.id)
        this.edges.filter(e => e.from.id == target.id || e.to.id == target.id).forEach(e => {
            this.deleteEdge(e)
        })
    }

    public deleteEdge(target: GenogramEdge) {
        this.edges = this.edges.filter(e => e.id != target.id && e.from.id != target.id && e.to.id != target.id)
    }

    public deleteLine(target: GenogramLine) {
        this.lines = this.lines.filter(e => e.id != target.id)
    }

    public getMarriedEdgeList(node: GenogramNode) {
        return this.edges.filter(e => (e.from.id == node.id || e.to.id == node.id) && e.isMarriageLR)
    }

    public getFamiryEdgeList(child: GenogramNode) {
        return this.edges.filter(e => (e.from.id == child.id || e.to.id == child.id) && e.isFamilyDU)
    }

    public existsParent(node: GenogramNode) {
        return this.getFamiryEdgeList(node).length > 0
    }

    public getConnectedNodes(edges: GenogramEdge[], visited: Set<GenogramNode> = new Set()) {
        const res = visited;
        edges.forEach(e => {
            const f = this.getAsNode(e.from)
            const t = this.getAsNode(e.to)
            if (f && !res.has(f)) {
                res.add(f)
                this.getConnectedNodes(this.getMarriedEdgeList(f), res)
            }
            if (t && !res.has(t)) {
                res.add(t)
                this.getConnectedNodes(this.getMarriedEdgeList(t), res)
            }
        })
        return res;
    }

    public addRelation(a: GenogramNode, b: GenogramNode) {
        const found = this.edges.findIndex(e => {
            return e.isRelation && (
                (e.from.id == a.id && e.to.id == b.id) ||
                (e.from.id == b.id && e.to.id == a.id)
            )
        }) >= 0
        if (found) return;
        const rel = new GenogramEdge(this.getId(), a, b)
        rel.isRelation = true;
        this.edges.push(rel);
    }

    public addMariage(target?: GenogramNode) {
        if (!this.isNodeFocused()) return;
        const own = this.focus as GenogramNode;
        const marriedList = this.getMarriedEdgeList(own);
        const isFirstMariage = (marriedList.length == 0)
        const marriedCount = marriedList.length
        const other = new GenogramNode(this.getId());
        other.sex = (own.sex == "男性" ? "女性" : "男性")
        let dx = -2
        if (!isFirstMariage) {
            const m0 = (marriedList[0].from.id == own.id ? marriedList[0].to : marriedList[0].from) as GenogramNode
            const delta = (m0.pos.x - own.pos.x)
            dx = (delta > 0 ? -2 : +2) * marriedCount
        }
        other.pos = own.pos.clone().add(new Victor(dx, 0));
        this.nodes.push(other);

        let edge: GenogramEdge
        if (dx < 0) {
            edge = new GenogramEdge(this.getId(), other, own)
        }
        else {
            edge = new GenogramEdge(this.getId(), own, other)
        }
        edge.isMarriageLR = true;
        this.edges.push(edge);
    }

    public addParent(target?: GenogramNode) {
        if (!this.isNodeFocused()) return;
        const own = this.focus as GenogramNode;
        const mother = new GenogramNode(this.getId());
        const father = new GenogramNode(this.getId());
        const ownPos = own.pos.clone()
        mother.pos = own.pos.clone().add(new Victor(-1, -2));
        father.pos = own.pos.clone().add(new Victor(+1, -2));
        mother.sex = "女性"
        father.sex = "男性"
        const edgeMF = new GenogramEdge(this.getId(), mother, father);
        const edgeChild = new GenogramEdge(this.getId(), own, edgeMF)
        edgeMF.isMarriageLR = true;
        edgeChild.isFamilyDU = true;
        //this.slide(mother.pos.x, mother.pos.y, father.pos.x, father.pos.y)
        own.pos = ownPos;
        this.nodes.push(mother);
        this.nodes.push(father);
        this.edges.push(edgeMF);
        this.edges.push(edgeChild);
    }

    private contains(x, min, max) {
        return min <= x && x <= max
    }

    public slide(minX, minY, maxX, maxY) {
        const cx = (minX + maxX) / 2;
        const cy = (minY + maxY) / 2;
        let dx = 0;
        let dy = 0;
        this.nodes.forEach(node => {
            if (this.contains(node.pos.x, minX - 1, maxX + 1) &&
                this.contains(node.pos.y, minY - 1, maxY + 1)) {
                if (node.pos.x >= cx) dx = maxX - node.pos.x + 1;
                else dx = minX - node.pos.x - 1;
                if (node.pos.y > cy) dy = node.pos.y - maxY - 1;
                else if (node.pos.y < cy) dy = minY - node.pos.y + 1;
                console.log(dx, dy)
                this.nodes.forEach(n => {
                    if (dx < 0 && n.pos.x < cx) {
                        n.pos.add(new Victor(dx, 0))
                    }
                    if (dx > 0 && cx < n.pos.x) {
                        n.pos.add(new Victor(dx, 0))
                    }
                    if (minY - 1 <= n.pos.y || n.pos.y <= maxY + 1) {
                        n.pos.add(new Victor(0, dy))
                    }
                })
            }
        })
    }

    public isEdgeFocused() {
        return this.focus instanceof GenogramEdge
    }
    public isNodeFocused() {
        return this.focus instanceof GenogramNode
    }

    public isLineFocused() {
        return this.focus instanceof GenogramLine
    }

    public getFocusNode() {
        return this.getAsNode(this.focus)
    }

    public getFocusEdge() {
        return this.getAsEdge(this.focus)
    }

    public getFocusLine() {
        return this.getAsLine(this.focus)
    }

    public getAsNode(ne: GenogramNode | GenogramEdge | GenogramLine | undefined | null) {
        if (ne instanceof GenogramNode) return ne as GenogramNode
        return null
    }
    public getAsEdge(ne: GenogramNode | GenogramEdge | GenogramLine | undefined | null) {
        if (ne instanceof GenogramEdge) return ne as GenogramEdge
        return null
    }
    public getAsLine(ne: GenogramNode | GenogramEdge | GenogramLine | undefined | null) {
        if (ne instanceof GenogramLine) return ne as GenogramLine
        return null
    }

    public addChild() {
        if (this.isEdgeFocused() == false) return;
        const edge = this.focus as GenogramEdge;
        if (edge.isMarriageLR == false) return;
        const child = new GenogramNode(this.getId());
        const from = edge.from as GenogramNode
        const to = edge.to as GenogramNode
        child.pos = from.pos.clone().add(to.pos).divide(new Victor(2, 2)).add(new Victor(0, 2))
        this.nodes.push(child);
        const e = new GenogramEdge(this.getId(), child, edge)
        e.isFamilyDU = true;
        this.edges.push(e);
        this.focus = child
    }

    public addBrother() {
        if (this.isEdgeFocused() == false) return;
        const own = this.focus as GenogramEdge;
        if (own.isFamilyDU == false) return;
        const child = new GenogramNode(this.getId());
        let dx = +1
        const mEdge = own.to as GenogramEdge;
        const mMidPos = this.getMidPoint((mEdge.to as GenogramNode).pos, (mEdge.from as GenogramNode).pos)
        const midPos = this.getMidPoint(mMidPos, (own.from as GenogramNode).pos)
        child.pos = midPos.clone().add(new Victor(dx, 1));
        this.nodes.push(child);

        let edge = new GenogramEdge(this.getId(), own, child)
        edge.isBrotherLR = true;
        this.edges.push(edge);
    }

    public focusMoveTo(pos: Victor, prevPos?: Victor) {
        const gs = this.gridSize

        const focus = this.getFocusNode();
        if (focus && prevPos) {
            const delta = pos.clone().subtract(prevPos).divide(new Victor(gs, gs))
            this.marriedMoveDelta(focus, delta)
        }
        const line = this.getFocusLine();
        if (line && prevPos) {
            const delta = pos.clone().subtract(prevPos)
            line.moveDelta(delta)
        }
    }

    private marriedMoveDelta(target: GenogramNode, delta: Victor) {
        const nodes = this.getConnectedNodes(this.getMarriedEdgeList(target))
        nodes.add(target)
        nodes.forEach(node => {
            node.moveDelta(delta)
        });
    }

    public addOther(own: GenogramNode) {

    }

    public onClick(px: number, py: number) {
        const p = new Victor(px, py)
        const gs = this.gridSize;
        this.focus = this.pickNode(px, py)
        if (this.focus) return;
        this.edges.forEach((edge) => {
            const d = this.getEdgeDistance(edge, p)
            if (d < gs / 3) {
                if (this.focus) {
                    if ((this.focus as GenogramEdge).getPastLength() > edge.getPastLength()) {
                        this.focus = edge;
                    }
                }
                else {
                    this.focus = edge;
                }
            }
        })
        if (this.focus) return;
        this.lines.forEach((line) => {
            line.posList.forEach(s => {
                const d = p.clone().subtract(s).length();
                if (d < gs / 2) {
                    this.focus = line
                }
            })
        })
    }

    public pickNode(px: number, py: number) {
        const p = new Victor(px, py)
        const gs = this.gridSize;
        let res: GenogramNode | undefined = undefined
        this.nodes.forEach(node => {
            const d = p.clone().subtract(node.getRealPos(gs)).length();
            if (d < gs / 2) {
                res = node
            }
        })
        return res
    }

    public isCollisionNode(a: GenogramNode, b: GenogramNode) {
        return (a.pos == b.pos)
    }

    public draw(ctx) {
        const s = this.size;
        const gs = this.gridSize;
        ctx.fillStyle = COLOR_GRAY;
        ctx.strokeStyle = COLOR_GRAY;
        ctx.font = "20px serif";
        ctx.lineWidth = 1;
        for (let x = 0; x <= this.gridCount.x; ++x) {
            ctx.beginPath();
            ctx.moveTo(x * gs, 0);
            ctx.lineTo(x * gs, s.y * gs);
            ctx.stroke();
        }
        for (let y = 0; y <= this.gridCount.y; ++y) {
            ctx.beginPath();
            ctx.moveTo(0, y * gs);
            ctx.lineTo(s.x * gs, y * gs);
            ctx.stroke();
        }

        const fid = this.focus ? this.focus.id : -1

        this.lines.forEach(line => {
            this.drawGenoLine(ctx, line, line.id == fid)
        })

        this.edges.forEach(edge => {
            this.drawEdge(ctx, edge);
        })

        this.nodes.forEach(node => {
            this.drawNode(ctx, node, node.id == fid);
        })

    }

    public drawNode(ctx, node: GenogramNode, isFocus: boolean) {
        ctx.setLineDash([]);
        const gs = this.gridSize
        const px = node.pos.x * gs;
        const py = node.pos.y * gs;
        const r = 0.4 * gs;
        const isMale = node.sex == "男性"
        ctx.lineWidth = 1;
        if (isFocus) {
            ctx.strokeStyle = COLOR_FOCUS;
            ctx.fillStyle = COLOR_FOCUS;
            this.drawPerson(ctx, px, py, r + 10, true, isMale);
        }
        ctx.fillStyle = COLOR_WHITE_ALPHA;
        ctx.strokeStyle = COLOR_BLACK;
        this.drawPerson(ctx, px, py, r, true, isMale);
        if (node.isCentral) {
            this.drawPerson(ctx, px, py, r - 10, false, isMale);
        }
        if (node.isStillbirth || node.isRyuzan || node.isJinkoChuzetsu) {
            ctx.fillStyle = COLOR_BLACK;
            this.drawPerson(ctx, px, py, r, true, isMale);
            ctx.fillStyle = COLOR_WHITE_ALPHA;
        }

        this.drawNodeZokusei(ctx, px, py, r, node);

        this.drawCenterText(ctx, node.age, px, py);

        this.drawText(ctx, node.comment, px + r, py);
    }

    private drawNodeZokusei(ctx, px, py, r, node: GenogramNode) {
        ctx.lineWidth = 2;
        if (node.isDeath) {
            this.drawLine(ctx, new Victor(px - r, py - r), new Victor(px + r, py + r));
            this.drawLine(ctx, new Victor(px + r, py - r), new Victor(px - r, py + r));
        }
        if (node.isYoushi) {
            this.drawLine(ctx, new Victor(px, py - r - 2), new Victor(px, py + r + 2));
        }
    }

    private drawPerson(ctx, px, py, r, isFill, isMale) {
        ctx.beginPath();
        if (isMale) {
            this.drawRect(ctx, px, py, r);
        }
        else {
            this.drawCircle(ctx, px, py, r);
        }
        if (isFill) {
            ctx.fill();
        }
        ctx.stroke();
    }

    private drawRect(ctx, px, py, r) {
        ctx.moveTo(px - r, py - r);
        ctx.lineTo(px + r, py - r);
        ctx.lineTo(px + r, py + r);
        ctx.lineTo(px - r, py + r);
        ctx.closePath();
    }

    private drawCircle(ctx, px, py, r) {
        ctx.arc(px, py, r, 0, Math.PI * 2, false);
    }

    private drawGenoLine(ctx, line: GenogramLine, isFocus: boolean) {
        if (isFocus) {
            ctx.lineWidth = 5;
            ctx.strokeStyle = COLOR_FOCUS_CLEAR;
        }
        else {
            ctx.lineWidth = 2;
            ctx.strokeStyle = COLOR_BLACK;
        }

        ctx.setLineDash([4, 4]);
        ctx.beginPath();
        const len = line.posList.length;
        const ps = line.posList;
        for (let i = 0; i < len; ++i) {
            if (i == 0) ctx.moveTo(ps[i].x, ps[i].y);
            else ctx.lineTo(ps[i].x, ps[i].y);
        }
        ctx.stroke();
        ctx.setLineDash([]);
    }

    public drawEdge(ctx, edge: GenogramEdge) {
        const isFromNode = edge.from instanceof GenogramNode
        const gs = this.gridSize
        if (edge.isMarriageLR) {
            const srcNode = edge.from as GenogramNode
            const toNode = edge.to as GenogramNode
            const sx = srcNode.pos.x * gs;
            const sy = srcNode.pos.y * gs;
            //const sr = srcNode.radius;
            const tx = toNode.pos.x * gs;
            const ty = toNode.pos.y * gs;
            if (this.focus && this.focus.id == edge.id) {
                ctx.lineWidth = 5;
                ctx.strokeStyle = COLOR_FOCUS;
                this.drawUnderLine(ctx, sx, sy, tx, ty, gs)
            }
            ctx.lineWidth = 1;
            ctx.strokeStyle = COLOR_BLACK;
            this.drawUnderLine(ctx, sx, sy, tx, ty, gs)
            //const tr = toNode.radius;
            edge.pastSrcPos = new Victor(sx, sy + gs)
            edge.pastDstPos = new Victor(tx, ty + gs)
        }
        else if (edge.isRelation) {
            const a = this.getAsNode(edge.from)
            const b = this.getAsNode(edge.to)
            if (a && b) {
                ctx.lineWidth = 2;
                ctx.strokeStyle = COLOR_RELATION;
                ctx.fillStyle = COLOR_RELATION;
                const sBase = a.pos.clone().multiply(new Victor(gs, gs))
                const tBase = b.pos.clone().multiply(new Victor(gs, gs))
                const margin = gs / 2 - 5
                const vab = tBase.clone().subtract(sBase)
                const nab = vab.clone().norm()
                const nxab = nab.clone().rotateDeg(90)
                let drawed = false
                const sMargin = sBase.clone().subtract(nab.clone().multiply(new Victor(margin, margin)))
                const tMargin = tBase.clone().subtract(nab.clone().multiply(new Victor(margin, margin)))

                if (edge.isYugou || edge.isShinmitsu) {
                    const diffs: number[] = []
                    if (edge.isYugou) diffs.push(-5, 0, +5)
                    if (edge.isShinmitsu) diffs.push(-3, +3)
                    diffs.forEach(diff => {
                        const dv = new Victor(diff, diff)
                        const s = sBase.clone().add(nxab.clone().multiply(dv))
                        const t = tBase.clone().add(nxab.clone().multiply(dv))
                        this.drawLine(ctx, s, t);
                    })
                    drawed = true
                }
                if (edge.isSoen) {
                    ctx.setLineDash([3, 3]);
                    this.drawLine(ctx, sBase, tBase);
                    ctx.setLineDash([]);
                    drawed = true
                }
                if (edge.isShadan) {
                    const mid = this.getMidPoint(sBase, tBase);
                    const lenEmpty = new Victor(5, 5);
                    const lenBar = new Victor(10, 10);
                    const sb = mid.clone().add(nab.clone().multiply(lenEmpty))
                    const tb = mid.clone().subtract(nab.clone().multiply(lenEmpty))
                    const drawBar = (bp) => {
                        const up = bp.clone().add(nxab.clone().multiply(lenBar))
                        const down = bp.clone().subtract(nxab.clone().multiply(lenBar))
                        this.drawLine(ctx, up, down);
                    }
                    drawBar(sb);
                    drawBar(tb);
                    this.drawLine(ctx, sBase, sb);
                    this.drawLine(ctx, tBase, tb);

                    drawed = true
                }
                if (edge.isTsuyoiKanshin || edge.isSeitekiGaykutai || edge.isShintaitekiGyakutai) {
                    // 三角形.
                    const size = new Victor(15, 15)
                    const sizeHalf = new Victor(10, 10)
                    const a = tMargin.clone()
                    const t = a.clone().subtract(nab.clone().multiply(size))
                    const b = t.clone().add(nxab.clone().multiply(sizeHalf))
                    const c = t.clone().subtract(nxab.clone().multiply(sizeHalf))
                    this.drawPolygon(ctx, [a, b, c])
                    ctx.fill();
                }
                if (edge.isTekitai || edge.isSeitekiGaykutai || edge.isShintaitekiGyakutai) {
                    // 波線
                    const points: Victor[] = []
                    const len = tMargin.clone().subtract(sMargin).length()
                    const unit = edge.isTekitai ? 9.0 : (edge.isSeitekiGaykutai ? 6.0 : 2.0);
                    const shinpuku = 10.0;
                    const pc = len / unit + 0.9;
                    const delta = nxab.clone().multiply(new Victor(shinpuku, shinpuku))
                    for (let i = 0; i <= pc; ++i) {
                        const p = sMargin.clone().add(nab.clone().multiply(new Victor(i * unit, i * unit)));
                        points.push();
                        if (i % 2 == 0 || i == pc) points.push(p)
                        else {
                            if (Math.floor(i / 2) % 2 == 0) points.push(p.clone().add(delta))
                            else points.push(p.clone().subtract(delta))
                        }
                    }
                    points.push(tMargin)
                    ctx.beginPath();
                    ctx.moveTo(sMargin.x, sMargin.y)
                    for (let i = 1; i + 1 < points.length; i += 2) {
                        ctx.bezierCurveTo(
                            points[i - 1].x, points[i - 1].y,
                            points[i + 0].x, points[i + 0].y,
                            points[i + 1].x, points[i + 1].y)
                    }
                    ctx.stroke();
                    drawed = true
                }

                if (!drawed) {
                    this.drawLine(ctx, sBase, tBase);
                }

                if (this.focus && this.focus.id == edge.id) {
                    ctx.lineWidth = 5;
                    ctx.strokeStyle = COLOR_FOCUS;
                    this.drawLine(ctx, sBase, tBase)
                }
                edge.pastSrcPos = sBase
                edge.pastDstPos = tBase
            }
        }
        else if (edge.isFamilyDU) {
            const n = (isFromNode ? edge.from : edge.to) as GenogramNode
            const e = (isFromNode ? edge.to : edge.from) as GenogramEdge
            const dst = n.pos.clone().multiply(new Victor(gs, gs))
            const src = this.getMidPoint(e.pastSrcPos, e.pastDstPos) //new Victor(dst.x, e.pastSrcPos.y)

            ctx.setLineDash([]);
            if (this.focus && this.focus.id == edge.id) {
                ctx.lineWidth = 5;
                ctx.strokeStyle = COLOR_FOCUS;
                this.drawLine(ctx, src, dst)
            }
            ctx.lineWidth = 1;
            ctx.strokeStyle = COLOR_BLACK;
            this.drawLine(ctx, src, dst)
            edge.pastSrcPos = src
            edge.pastDstPos = dst
        }
        else if (edge.isBrotherLR) {
            const srcEdge = edge.from as GenogramEdge
            const toNode = edge.to as GenogramNode
            const srcPos = this.getMidPoint(srcEdge.pastSrcPos, srcEdge.pastDstPos)
            const sx = srcPos.x;
            const sy = srcPos.y;
            //const sr = srcNode.radius;
            const tx = toNode.pos.x * gs;
            const ty = toNode.pos.y * gs;
            if (this.focus && this.focus.id == edge.id) {
                ctx.lineWidth = 5;
                ctx.strokeStyle = COLOR_FOCUS;
                this.drawBrotherLine(ctx, sx, sy, tx, ty, gs)
            }
            ctx.lineWidth = 1;
            ctx.strokeStyle = COLOR_BLACK;
            this.drawBrotherLine(ctx, sx, sy, tx, ty, gs)
            //const tr = toNode.radius;
            edge.pastSrcPos = new Victor(sx, sy - gs)
            edge.pastDstPos = new Victor(tx, ty - gs)
        }

        const textPos = this.getMidPoint(edge.pastSrcPos, edge.pastDstPos)
        this.drawEdgeZokusei(ctx, edge)
        this.drawText(ctx, edge.comment, textPos.x, textPos.y + 14);
    }

    private drawEdgeZokusei(ctx, edge: GenogramEdge) {
        ctx.lineWidth = 2;
        const c = this.getMidPoint(edge.pastSrcPos, edge.pastDstPos)
        if (edge.isRikon) {
            this.drawLine(ctx, new Victor(c.x + 6, c.y - 10), new Victor(c.x + 0, c.y + 10));
            this.drawLine(ctx, new Victor(c.x + 0, c.y - 10), new Victor(c.x - 6, c.y + 10));
        }
        else if (edge.isBekkyo) {
            this.drawLine(ctx, new Victor(c.x + 3, c.y - 10), new Victor(c.x - 3, c.y + 10));
        }
    }

    private drawText(ctx, str, x, y) {
        ctx.fillStyle = COLOR_BLACK;
        ctx.fillText(str, x, y)
    }
    private drawCenterText(ctx, str, x, y) {
        ctx.fillStyle = COLOR_BLACK;
        const wh = ctx.measureText(str);
        ctx.fillText(str, x - wh.width / 2, y + 10)
    }

    private getMidPoint(a: Victor, b: Victor) {
        return a.clone().add(b).divide(new Victor(2, 2))
    }

    private drawUnderLine(ctx, sx: number, sy: number, tx: number, ty: number, gs: number) {
        ctx.beginPath();
        ctx.moveTo(sx, sy);
        ctx.lineTo(sx, sy + gs);
        ctx.lineTo(tx, ty + gs);
        ctx.lineTo(tx, ty);
        ctx.stroke();
    }
    private drawBrotherLine(ctx, sx: number, sy: number, tx: number, ty: number, gs: number) {
        ctx.beginPath();
        ctx.moveTo(sx, sy);
        ctx.lineTo(tx, sy);
        ctx.lineTo(tx, ty);
        ctx.stroke();
    }

    private drawLine(ctx, a: Victor, b: Victor, gs: number = 1) {
        ctx.beginPath();
        ctx.moveTo(a.x * gs, a.y * gs);
        ctx.lineTo(b.x * gs, b.y * gs);
        ctx.stroke();
    }
    private drawPolygon(ctx, pList: Victor[], gs: number = 1) {
        ctx.beginPath();
        pList.forEach((p, i) => {
            if (i == 0) ctx.moveTo(p.x * gs, p.y * gs)
            else ctx.lineTo(p.x * gs, p.y * gs)
        })
        ctx.stroke();
    }

    public getEdgeDistance(e: GenogramEdge, p: Victor) {
        const a = e.pastSrcPos;
        const b = e.pastDstPos;
        const va = b.clone().subtract(a)
        const vb = p.clone().subtract(a)
        const dot = va.dot(vb)
        const r = dot / va.lengthSq()
        const nearest = (r < 0 ? a : (r > 1 ? b : a.clone().add(va.clone().multiply(new Victor(r, r)))))
        return nearest.distance(p)
    }
}

class GenogramLine {
    id: number;
    posList: Victor[] = []

    constructor(id: number) {
        this.id = id;
    }

    public moveDelta(delta: Victor) {
        this.posList.forEach(p => p.add(delta))
    }
}

class GenogramNode {
    public id: number;
    public pos: Victor = new Victor(0, 0)
    public sex: string = "男性"
    public age: string = ""
    public kankei: string = ""
    public comment: string = ""
    public birthYear?: number = undefined
    public deathYear?: number = undefined
    public isDeath: boolean = false
    public isCentral: boolean = false
    public isPregnant: boolean = false
    public isStillbirth: boolean = false
    public isRyuzan: boolean = false
    public isJinkoChuzetsu: boolean = false
    public isYoushi: boolean = false

    constructor(id: number) {
        this.id = id;
    }

    public getRealPos(gridSize: number) {
        return this.pos.clone().multiply(new Victor(gridSize, gridSize))
    }

    public moveDelta(delta: Victor) {
        this.pos.add(delta)
    }
}

class GenogramEdge {
    public id: number
    public comment: string = ""
    public from: GenogramNode | GenogramEdge
    public to: GenogramNode | GenogramEdge
    public isMarriageLR: boolean = false
    public isFamilyDU: boolean = false
    public isBrotherLR: boolean = false
    public isRelation: boolean = false

    public isBekkyo: boolean = false
    public isRikon: boolean = false

    public isYugou: boolean = false
    public isShinmitsu: boolean = false
    public isSoen: boolean = false
    public isTekitai: boolean = false
    public isShadan: boolean = false
    public isTsuyoiKanshin: boolean = false
    public isSeitekiGaykutai: boolean = false
    public isShintaitekiGyakutai: boolean = false
    public pastSrcPos = new Victor(0, 0)
    public pastDstPos = new Victor(0, 0)

    constructor(id: number, from: GenogramNode | GenogramEdge, to: GenogramNode | GenogramEdge) {
        this.id = id;
        this.from = from;
        this.to = to;
    }

    public setMode(isMarriage: boolean, isRelation: boolean) {
        this.isMarriageLR = isMarriage;
        this.isRelation = isRelation;
    }

    public getPastLength() {
        return this.pastSrcPos.clone().subtract(this.pastDstPos).length()
    }
}

interface Props extends PageProps, Styles.WithStyles {
}

interface LocalState {
    drawing: boolean
    genogram: Genogram
    changed: number
    penMode: boolean
    relationMode: boolean
    nodeMouseOn?: GenogramNode
    mousePos?: Victor
    drawStartPos?: Victor
}

class GenogramPage extends React.Component<Props, LocalState> {
    displayName = "GenogramPage"
    constructor(props: Props) {
        super(props);
        const g = new Genogram()
        this.state = {
            genogram: g,
            drawing: false,
            changed: 1,
            penMode: false,
            relationMode: false,
            nodeMouseOn: undefined,
            mousePos: undefined,
            drawStartPos: undefined
        };
    }

    getContext() {
        if (this.refs.canvas) {
            return (this.refs.canvas as any).getContext('2d');
        }
        return null
    }

    draw() {
        const ctx = this.getContext();
        if (!ctx) return;
        const g = this.state.genogram;
        const gs = g.gridSize;
        g.draw(ctx);
        ctx.fillStyle = COLOR_WHITE;
        ctx.fillRect(0, 0, g.size.x, g.size.y);
        g.draw(ctx);
        if (this.state.relationMode) {
            const a = g.getFocusNode()
            const b = this.state.nodeMouseOn
            if (a && this.state.mousePos) {
                let dst = (b ? b.pos.clone().multiply(new Victor(gs, gs)) : this.state.mousePos.clone())
                ctx.lineWidth = 2;
                ctx.strokeStyle = COLOR_FOCUS
                ctx.setLineDash([4, 4]);
                ctx.beginPath();
                ctx.moveTo(a.pos.x * gs, a.pos.y * gs);
                ctx.lineTo(dst.x, dst.y);
                ctx.stroke();
                ctx.lineWidth = 1;
                ctx.setLineDash([]);
            }
        }
        this.update();
    }

    componentDidMount() {
        this.draw();
    }

    donwloadImage() {
        this.getContext();
        const canvas = (this.refs.canvas as any)
        this.state.genogram.clearFocus();
        this.draw();
        var a = document.createElement('a');
        a.href = canvas.toDataURL('image/png', 0.85);
        a.download = 'genogram.png';
        a.click();
    }

    private setText(obj: any, name: string, value: any) {
        obj[name] = value;
        this.draw();
    }

    private update() {
        this.setState({ changed: this.state.changed + 1 })
    }

    private onMouseDown(x: number, y: number) {
        if (this.state.penMode) {
            this.penStart(x, y)
        }
        else {
            this.state.genogram.onClick(x, y)
        }
        this.setState({
            drawStartPos: new Victor(x, y),
            drawing: true
        })
        this.draw()
    }

    private onMouseMove(x: number, y: number) {
        if (!this.state.drawing) return;
        const g = this.state.genogram
        const newPos = new Victor(x, y)
        if (this.state.penMode) {
            this.penMove(x, y)
        }
        else if (this.state.relationMode) {
        }
        else {
            g.focusMoveTo(newPos, this.state.mousePos);
        }
        const nodeOnMouse = g.pickNode(x, y)
        if (nodeOnMouse != this.state.nodeMouseOn) {
            this.setState({
                nodeMouseOn: nodeOnMouse
            })
        }
        this.setState({
            mousePos: newPos
        })
        this.draw()
    }

    private onMouseEnd() {
        const g = this.state.genogram;
        const t = this.state.mousePos;
        let delta = 0
        if (t && this.state.drawStartPos) {
            delta = t.distance(this.state.drawStartPos);
        }

        if (this.state.relationMode && delta > 10) {
            const focus = g.getFocusNode()
            const target = this.state.nodeMouseOn
            if (focus && target && target.id != focus.id) {
                this.state.genogram.addRelation(focus, target);
            }
        }
        this.setState({
            drawing: false,
            mousePos: undefined,
            drawStartPos: undefined
        })
    }

    private penStart(x: number, y: number) {
        const g = new GenogramLine(this.state.genogram.getId())
        g.posList.push(new Victor(x, y))
        this.state.genogram.lines.push(g)
    }

    private penMove(x: number, y: number) {
        const len = this.state.genogram.lines.length
        const p = new Victor(x, y)
        const current = this.state.genogram.lines[len - 1].posList
        const dist = p.distance(current[current.length - 1])
        if (dist > 20) current.push(new Victor(x, y))
    }

    private setMode(penMode: boolean, relationMode: boolean) {
        this.setState({
            penMode: penMode,
            relationMode: relationMode
        })
    }

    public render() {
        const { genogram } = this.state;
        const { classes } = this.props;
        //console.log(genogram)
        const focusNode = genogram.getFocusNode()
        const focusEdge = genogram.getFocusEdge()

        const nodeCheckList = focusNode ? [
            { label: "中心", checked: focusNode.isCentral, valueName: "isCentral" },
            { label: "死亡", checked: focusNode.isDeath, valueName: "isDeath" },
            { label: "養子", checked: focusNode.isYoushi, valueName: "isYoushi" },
            { label: "妊娠", checked: focusNode.isPregnant, valueName: "isPregnant" },
            { label: "死産", checked: focusNode.isStillbirth, valueName: "isStillbirth" },
            { label: "流産", checked: focusNode.isRyuzan, valueName: "isRyuzan" },
            { label: "人工中絶", checked: focusNode.isJinkoChuzetsu, valueName: "isJinkoChuzetsu" },
        ] : []

        const edgeCheckList: any[] = []
        if (focusEdge) {
            if (focusEdge.isRelation) {
                edgeCheckList.push({ label: "融合", checked: focusEdge.isYugou, valueName: "isYugou" })
                edgeCheckList.push({ label: "親密", checked: focusEdge.isShinmitsu, valueName: "isShinmitsu" })
                edgeCheckList.push({ label: "疎遠", checked: focusEdge.isSoen, valueName: "isSoen" })
                edgeCheckList.push({ label: "敵対", checked: focusEdge.isTekitai, valueName: "isTekitai" })
                edgeCheckList.push({ label: "遮断", checked: focusEdge.isShadan, valueName: "isShadan" })
                edgeCheckList.push({ label: "強い関心", checked: focusEdge.isTsuyoiKanshin, valueName: "isTsuyoiKanshin" })
                edgeCheckList.push({ label: "性的虐待", checked: focusEdge.isSeitekiGaykutai, valueName: "isSeitekiGaykutai" })
                edgeCheckList.push({ label: "身体的虐待", checked: focusEdge.isShintaitekiGyakutai, valueName: "isShintaitekiGyakutai" })
            }
            else {
                edgeCheckList.push({ label: "離婚", checked: focusEdge.isRikon, valueName: "isRikon" })
                edgeCheckList.push({ label: "別居", checked: focusEdge.isBekkyo, valueName: "isBekkyo" })
            }
        }

        const sexPair = [{ label: "男性", value: "男性" }, { label: "女性", value: "女性" }]
        return (
            <div className={classes.root}>
                <SubTitle title="ジェノグラム作成" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        {
                            genogram ?
                                <canvas
                                    ref="canvas"
                                    width={genogram.size.x}
                                    height={genogram.size.y}
                                    onClick={e => {
                                        this.draw();
                                    }}
                                    onMouseDown={e => this.onMouseDown(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
                                    onMouseUp={() => this.onMouseEnd()}
                                    onMouseLeave={() => this.onMouseEnd()}
                                    onMouseMove={e => this.onMouseMove(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
                                    className={classes.canvas}
                                /> : null
                        }
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div>
                            <div>
                                モード：
                                <Button className={classes.button}
                                    color={this.state.penMode ? "primary" : undefined} variant="contained"
                                    onClick={(e) => { this.setMode(!this.state.penMode, false) }}>
                                    {this.state.penMode ? "ペンモードON" : "ペンモードOFF"}
                                </Button>
                                <Button className={classes.button}
                                    color={this.state.relationMode ? "primary" : undefined} variant="contained"
                                    onClick={(e) => { this.setMode(false, !this.state.relationMode) }}>
                                    {this.state.relationMode ? "関係モードON" : "関係モードOFF"}
                                </Button>
                            </div>
                            <div>
                                操作：
                                <Button className={classes.button}
                                    onClick={(e) => { genogram.deleteFocus(); this.draw(); }} variant="contained" >削除</Button>
                                <Button className={classes.button}
                                    onClick={(e) => { genogram.addNewNode(); this.draw(); }} variant="contained" >新規人物</Button>
                            </div>
                            <div>
                                編集：
                                {
                                    focusNode ? [
                                        <Button key="b_node_1" className={classes.button}
                                            onClick={(e) => { genogram.addMariage(); this.draw(); }} variant="contained" >＋婚姻関係</Button>,
                                        <Button key="b_node_2" className={classes.button}
                                            disabled={genogram.existsParent(focusNode)}
                                            onClick={(e) => { genogram.addParent(); this.draw(); }} variant="contained" >＋親</Button>,
                                        <Grid key="b_grid_1" container spacing={2}>
                                            <InputList title="性別" value={focusNode.sex} onChange={(s) => this.setText(focusNode, "sex", s)} values={sexPair} />
                                            <InputText title="年齢" value={focusNode.age} onChange={(s) => this.setText(focusNode, "age", s)} />
                                            <InputText title="コメント" value={focusNode.comment} onChange={(s) => this.setText(focusNode, "comment", s)} />
                                            <InputCheck title="" values={nodeCheckList} onChange={(s) => this.setText(focusNode, s.valueName, !s.checked)} />
                                        </Grid>
                                    ] : focusEdge ? (
                                        focusEdge.isMarriageLR ? [
                                            // marriage
                                            <Button key="b_edgem_1" className={classes.button}
                                                onClick={(e) => { genogram.addChild(); this.draw(); }} variant="contained"  >＋子供</Button>
                                            ,
                                            <Button key="b_edgem_2" className={classes.button}
                                                onClick={(e) => { focusEdge.setMode(false, true); this.draw(); }} variant="contained"  >関係に変更</Button>,
                                            <Grid key="b_gridm_1" container spacing={2}>
                                                <InputText title="コメント" value={focusEdge.comment} onChange={(s) => this.setText(focusEdge, "comment", s)} />
                                                <InputCheck key="b_icm_1" title="" values={edgeCheckList} onChange={(s) => this.setText(focusEdge, s.valueName, !s.checked)} />
                                            </Grid>
                                        ] :
                                            focusEdge.isRelation ? [
                                                // relation
                                                <Button key="b_edger_1" className={classes.button}
                                                    onClick={(e) => { focusEdge.setMode(true, false); this.draw(); }} variant="contained"  >結婚に変更</Button>
                                                ,
                                                <Grid key="b_gridr_2" container spacing={2}>
                                                    <InputText key="b_itr_2" title="コメント" value={focusEdge.comment} onChange={(s) => this.setText(focusEdge, "comment", s)} />
                                                    <InputCheck key="b_icr_2" title="" values={edgeCheckList} onChange={(s) => this.setText(focusEdge, s.valueName, !s.checked)} />
                                                </Grid>
                                            ] :
                                                focusEdge.isFamilyDU ? [
                                                    // relation
                                                    <Button key="b_edgef_1" className={classes.button}
                                                        onClick={(e) => { genogram.addBrother(); this.draw(); }} variant="contained"  >＋兄弟</Button>
                                                ] : null
                                    ) : null
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div>
                    <Button className={classes.button}
                        onClick={(e) => { this.donwloadImage() }} variant="contained" >ダウンロード</Button>
                </div>
            </div>
        );
    }
}

export default withPageProps(Styles.withStyles(styles)(GenogramPage))