import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import { Button } from '@material-ui/core';
import * as HokanApi from '../../api/hokan'
import UserSelectDialog from '../UserSelectDialog';


class TempData {
    branch: Model.Branch = new Model.Branch()
    branchDialog: boolean = false
}

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"

    public getId(): number {
        return 31
    }

    public createContent(): any {
        const res = new FormModel.TashokushuKanfa.Core()
        res.values.create_date = DateUtil.currentDateStr()
        res.values.time_from = DateUtil.currentDateTimeStr()
        res.values.time_to = DateUtil.currentDateTimeStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.TaiinCanfa.Core
        content.values.create_time = `${DateUtil.toTimeStr(content.values.time_from)}〜${DateUtil.toTimeStr(content.values.time_to)}`
    }

    private loadFromBranch(doc: Model.Document.Document, sa: StoreAccessor) {
        const content = doc.content as FormModel.TashokushuKanfa.Core
        const values = content.values
        HokanApi.me().then(x => {
            const b = x.data.branch
            if (b) {
                values.branch_name = b.name
                values.branch_tel = b.tel
                values.branch_fax = b.fax
                values.branch_address = b.address
            }
            sa.updateState()
        }).catch(err => { console.log(err) })
    }

    public setUser(doc: Model.Document.Document, user: Model.User) {
        const content = doc.content as FormModel.TashokushuKanfa.Core
        content.values.tanto_name = user ? user.name : ""
    }

    public beforeMount = (doc: Model.Document.Document) => {
        doc.temp_data = new TempData()
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor, classes: any) {
        const saV = sa.generateChild(["content", "values"])
        const saD = sa.generateChild(["content"])
        const content = doc.content as FormModel.TashokushuKanfa.Core
        const values = content.values
        const temp_data = doc.temp_data as TempData
        const elements_t = [
            { name: "開始時間", content: saV.dateTime("time_from", "time_to") },
            { name: "終了時間", content: saV.dateTime("time_to") },
            { name: "場所", content: saV.text("location") },
            { name: "出席者", content: saV.text("shusseki") },
            { name: "文責", content: saV.text("tanto_name") },
            { name: "在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ加算", content: saD.check("is_zaitaku_kinkyu_kanfa") }, // 在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ加算,
            { name: "本文", content: saV.text("content", "", "text", "block", true, "300px") }
        ]
        const elements_j = [
            {
                name: "コピー", content:
                    <div>
                        <Button variant="outlined" size="small"
                            onClick={(e) => this.loadFromBranch(doc, sa)}
                            className={classes.loadButton} >自分の事業所</Button>
                        <Button variant="outlined" size="small"
                            onClick={(e) => {
                                temp_data.branchDialog = true
                                sa.updateState();
                            }}
                            className={classes.loadButton} >事業所一覧から取得</Button>
                        {
                            doc.temp_data.branchDialog ?
                                <UserSelectDialog
                                    open={true}
                                    onlyBranch={true}
                                    onSelect={(b: Model.Branch, u: Model.SimpleUser | null) => {
                                        values.branch_name = b.name
                                        values.branch_tel = b.tel
                                        values.branch_fax = b.fax
                                        values.branch_address = b.address
                                        temp_data.branchDialog = false
                                        sa.updateState();
                                    }}
                                    onCancel={() => {
                                        temp_data.branchDialog = false
                                        sa.updateState();
                                    }}
                                />
                                : null
                        }
                    </div>
            },
            { name: "事業所名", content: saV.text("branch_name") },
            { name: "事業所住所", content: saV.text("branch_address") },
            { name: "事業所TEL", content: saV.text("branch_tel") },
            { name: "事業所FAX", content: saV.text("branch_fax") }
        ]

        const elements = [
            { name: '内容', content: elements_t },
            { name: '事業所', content: elements_j }
        ]


        return elements
    }
}
