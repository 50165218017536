export class Organization {
    public id: number = 0
    public name: string = ""
    public kana: string = ""
    public zip: string = ""
    public address: string = ""
    public tel: string = ""
    public fax: string = ""
    public email: string = ""
    public organization_tantos: OrganizationTanto[] = []
    public kinkyu_tel1: string = ""
    public kinkyu_tel2: string = ""
    public kinkyu_tel1_comment: string = ""
    public kinkyu_tel2_comment: string = ""
}

export class OrganizationTanto {
    public id: number = 0
    public org: Organization = new Organization()
    public name: string = ""
    public kana: string = ""
    public role: string = ""
    public email: string = ""
    public tel: string = ""
    public fax: string = ""
    public kinkyu_tel1: string = ""
    public kinkyu_tel2: string = ""
    public kinkyu_tel1_comment: string = ""
    public kinkyu_tel2_comment: string = ""
}
