import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
    ResponsiveContainer, Label, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { Table, TableHead, TableRow, TableCell, TableBody, MenuItem, Select } from '@material-ui/core';
import { Rowing } from '@material-ui/icons';
import Pagination from "material-ui-flat-pagination";
import EditableCheckList from '../EditableCheckList';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import DateUtil from '../../util/DateUtil';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
        //        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    },
    table: {
        marginBottom: "5px",
        color: Colors.primaryColor,
        width: "100%",
        fontSize: "0.9rem",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {
        }
    },
    rowHead: {
        textAlign: "center",
        padding: "1px",
        position: "sticky",
        left: "0",
        minWidth: "90px"
    },
    rowChild: {
        textAlign: "center",
        padding: "1px",
        minWidth: "70px"
    }
});


interface Props extends Styles.WithStyles {
    docs: Model.Document.Document[]
}

interface LocalState {
    per: number
    page: number
    hideFlag: any
}

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {
            borderTopRightRadius: "5px",
        },
        "&:first-child": {
            borderTopLeftRadius: "5px",
        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

class DesignRTable extends React.PureComponent<Props, LocalState> {
    displayName = "TaionBan"
    constructor(props: Props) {
        super(props)
        this.state = {
            per: 5,
            page: 0,
            hideFlag: {}
        }
    }
    componentDidMount() {
    }
    public render() {
        const { classes } = this.props;
        const isFirst = this.state.page == 0

        return <div className={classes.root}>
            <div style={{ overflowX: "scroll" }}>
                <Table className={classes.table}>
                    <TableHead>
                        <CustomTableCell className={classes.rowHead} scope="th">日付</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">部位</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">D</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">E</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">S</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">I</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">G</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">N</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">P</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">合計</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">備考</CustomTableCell>
                        <CustomTableCell className={classes.rowChild} scope="th">画像</CustomTableCell>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.docs && this.props.docs.map(row => {
                                const c = row.content as FormModel.DesignR.Core
                                const v = c.values
                                return <TableRow>
                                    <CustomTableCell className={classes.rowHead} scope="row">{DateUtil.toJpDateString(v.create_date)}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{v.bui}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{Model.KirokuII.SkinJokuso.toCode(v.d)}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{Model.KirokuII.SkinJokuso.toCode(v.e)}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{Model.KirokuII.SkinJokuso.toCode(v.s)}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{Model.KirokuII.SkinJokuso.toCode(v.i)}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{Model.KirokuII.SkinJokuso.toCode(v.g)}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{Model.KirokuII.SkinJokuso.toCode(v.n)}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{Model.KirokuII.SkinJokuso.toCode(v.p)}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{v.sum_score}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">{v.comment}</CustomTableCell>
                                    <CustomTableCell className={classes.rowChild} scope="row">
                                        {row.image_urls ? row.image_urls.map((url, i) => {
                                            return <a key={i} href={url} target="_blank"><img src={url} width={100} /></a>
                                        }) : null}</CustomTableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div>
    }
}

export default Styles.withStyles(styles)(DesignRTable)