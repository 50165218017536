
export class Core {
    public values: Values = new Values();
    public indexes: Indexes = new Indexes();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public case_1: string = ""
    public case_2: string = ""
    public case_3: string = ""
    public case_4: string = ""
    public sum_score: string = ""
}

export class Indexes {
    public case_1: number = -1
    public case_2: number = -1
    public case_3: number = -1
    public case_4: number = -1
    public sum_score: number = 0
}

export class Checks {
}