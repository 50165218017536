export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public create_date: string = "";
  public hyoukasya: string = "";
}

export class Checks {
  public check_1: boolean = true;
  public check_2: boolean = false;
  public check_3: boolean = true;
  public check_4: boolean = false;
  public check_5: boolean = true;
  public check_6: boolean = false;
  public check_7: boolean = true;
  public check_8: boolean = false;
  public check_9: boolean = true;
  public check_10: boolean = false;
}
