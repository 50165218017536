export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public conference_1: string = "";
  public conference_2: string = "";
  public created_by: string = "";
  public create_date: string = "";
  public kankyou_chosei_1: string = "";
  public kankyou_chosei_2: string = "";
  public kankyou_chosei_3: string = "";
  public kankyou_chosei_4: string = "";
  public kankyou_chosei_5: string = "";
  public kankyou_kettei_1: string = "";
  public kankyou_kettei_2: string = "";
  public kankyou_kettei_3: string = "";
  public kankyou_kettei_4: string = "";
  public kankyou_kettei_5: string = "";
  public pat_name: string = "";
  public shinri_chosei_1: string = "";
  public shinri_chosei_2: string = "";
  public shinri_chosei_3: string = "";
  public shinri_chosei_4: string = "";
  public shinri_kettei_2: string = "";
  public shinri_kettei_3: string = "";
  public shinri_kettei_4: string = "";
  public shinri_ketteii_1: string = "";
  public zenpan_chosei: string = "";
  public zenpan_kattei_1: string = "";
  public zenpan_kattei_2: string = "";
  public zenpan_kattei_3: string = "";
  public zenpan_kattei_4: string = "";
  public zenpan_kattei_5: string = "";
  public zenpan_kattei_6: string = "";
  public zenpan_kattei_7: string = "";
  public zenpan_kettei: string = "";
  public free_text_1: string = "";
  public free_text_2: string = "";
  public free_text_3: string = "";
  public free_text_4: string = "";
  public kenkou_chosei_1: string = "";
  public kenkou_chosei_2: string = "";
  public kenkou_chosei_3: string = "";
  public kenkou_chosei_4: string = "";
  public kenkou_chosei_5: string = "";
  public kenkou_kettei_1: string = "";
  public kenkou_kettei_2: string = "";
  public kenkou_kettei_3: string = "";
  public kenkou_kettei_4: string = "";
  public kenkou_kettei_5: string = "";
  public seiri_chosei_1: string = "";
  public seiri_chosei_2: string = "";
  public seiri_chosei_3: string = "";
  public seiri_chosei_4: string = "";
  public seiri_chosei_5: string = "";
  public seiri_kettei_1: string = "";
  public seiri_kettei_2: string = "";
  public seiri_kettei_3: string = "";
  public seiri_kettei_4: string = "";
  public seiri_kettei_5: string = "";
}

export class Checks {
  public check_houmon: boolean = false;
  public check_houmon_hospital: boolean = false;
  public check_houmon_house_call_doctor: boolean = false;
  public check_houmon_unnecessary: boolean = false;
  public check_iryou: boolean = false;
  public check_iryou_hospital: boolean = false;
  public check_iryou_house_call_doctor: boolean = false;
  public check_iryou_unnecessary: boolean = false;
  public check_kango: boolean = false;
  public check_kango_hospital: boolean = false;
  public check_kango_house_call_doctor: boolean = false;
  public check_kango_unnecessary: boolean = false;
  public check_sinnryou: boolean = false;
  public check_sinnryou_hospital: boolean = false;
  public check_sinnryou_house_call_doctor: boolean = false;
  public check_sinnryou_unnecessary: boolean = false;
  public check_tokubetsu: boolean = false;
  public check_tokubetsu_hospital: boolean = false;
  public check_tokubetsu_house_call_doctor: boolean = false;
  public check_tokubetsu_unnecessary: boolean = false;
}
