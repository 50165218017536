
export class Core {
    public values: Values = new Values();
    public indexes: Indexes = new Indexes();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""

    public bui: string = ""
    public d: string = ""
    public e: string = ""
    public s: string = ""
    public i: string = ""
    public g: string = ""
    public n: string = ""
    public p: string = ""

    public sum_score: string = ""
    public comment: string = ""

    public d_1: string = ""
    public d_2: string = ""
    public d_3: string = ""
    public d_4: string = ""
    public d_5: string = ""
    public d_6: string = ""
    public date_1: string = ""
    public date_2: string = ""
    public date_3: string = ""
    public date_4: string = ""
    public date_5: string = ""
    public date_6: string = ""
    public date_md_1: string = ""
    public date_md_2: string = ""
    public date_md_3: string = ""
    public date_md_4: string = ""
    public date_md_5: string = ""
    public date_md_6: string = ""
    public e_1: string = ""
    public e_2: string = ""
    public e_3: string = ""
    public e_4: string = ""
    public e_5: string = ""
    public e_6: string = ""
    public g_1: string = ""
    public g_2: string = ""
    public g_3: string = ""
    public g_4: string = ""
    public g_5: string = ""
    public g_6: string = ""
    public i_1: string = ""
    public i_2: string = ""
    public i_3: string = ""
    public i_4: string = ""
    public i_5: string = ""
    public i_6: string = ""
    public n_1: string = ""
    public n_2: string = ""
    public n_3: string = ""
    public n_4: string = ""
    public n_5: string = ""
    public n_6: string = ""
    public p_1: string = ""
    public p_2: string = ""
    public p_3: string = ""
    public p_4: string = ""
    public p_5: string = ""
    public p_6: string = ""
    public s_1: string = ""
    public s_2: string = ""
    public s_3: string = ""
    public s_4: string = ""
    public s_5: string = ""
    public s_6: string = ""
    public sum_1: string = ""
    public sum_2: string = ""
    public sum_3: string = ""
    public sum_4: string = ""
    public sum_5: string = ""
    public sum_6: string = ""
    public page_count: number = 1

    public image_id_0: string = ""
    public image_id_1: string = ""
    public image_id_2: string = ""
    public image_id_3: string = ""
    public image_id_4: string = ""
    public image_id_5: string = ""
    public image_id_6: string = ""
    public image_id_7: string = ""
    public image_id_8: string = ""
    public image_id_9: string = ""
    public image_id_10: string = ""
    public image_id_11: string = ""
}

export class Indexes {
}

export class Checks {
}