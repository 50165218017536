import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle';
import { Grid, Button } from '@material-ui/core';
import InputText from '../components/InputText';
import InputList from '../components/InputList';
import ButtonOkCancel from '../components/ButtonOkCancel';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import * as Colors from '../components/Colors'
import SimpleDialog from '../components/SimpleDialog';
import { StoreAccessor } from '../util/StoreUtil';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {

    },
    textField: {
        width: "90%"
    },
    input: {
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto"
    }
});

interface Params {
    user_id: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    me: Model.User
    loading: boolean,
    branches: Model.Branch[],
    data: Model.User | null,
    error: string | null,
    openPassword: boolean,
    openSendDone: boolean,
    passwordSendError: string,
    sendingMail: boolean,
    openDelete: boolean
}

// @inject()
// @observer
class UserPage extends React.Component<Props, LocalState> {
    displayName = "UserPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            me: new Model.User(),
            loading: false,
            branches: [],
            data: null,
            error: null,
            openPassword: false,
            openSendDone: false,
            passwordSendError: "",
            sendingMail: false,
            openDelete: false
        }
    }

    private setText(target, str) {
        const d = this.state.data as any
        d[target] = str;
        this.setState({ data: d })
    }

    public componentDidMount = () => {
        this.setState({ loading: true })

        HokanApi.me().then(mex => {
            const me = mex.data
            this.setState({ me: me })
            HokanApi.branch.list()
                .then(x => {
                    this.setState({
                        branches: x.data,
                        loading: false
                    })
                })
                .catch(err => {
                    console.log(err)
                })
            if (this.createMode) {
                const d = new Model.User()
                d.branch_id = me.branch_id
                d.privilege = "Normal"
                d.work_style = "常勤専従"
                d.shikaku = "0"
                d.role = "0"
                this.setState({ data: d, loading: false })
            }
            else {
                HokanApi.user.get(this.getId())
                    .then(x => {
                        this.setState({ data: x.data, loading: false })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ data: null, loading: false })
                    })
            }
        })
            .catch(err => {
                console.log(err)
            })
    }

    private getId() {
        return parseInt(this.props.match.params.user_id)
    }

    private get createMode() {
        return this.getId() == 0
    }

    onCommit() {
        this.setState({ loading: true })
        const id = this.getId()
        const createMode = id == 0
        if (!this.state.data) return
        if (createMode) {
            HokanApi.user.post(this.state.data)
                .then(x => {
                    HokanApi.message.user_update(x.data.id)
                    this.setState({ loading: false })
                    this.onBack()
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.status == 422) {
                        this.setState({
                            //error: "パスワードは６文字以上にしてください",
                            error: "エラー入力内容を確認してください",
                            loading: false
                        })
                    }
                    else {
                        this.setState({
                            error: "不明",
                            loading: false
                        })
                    }
                })
        } else {
            HokanApi.user.put(this.state.data)
                .then(x => {
                    HokanApi.message.user_update(x.data.id)
                    this.setState({ loading: false })
                    this.onBack()
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.status == 422) {
                        this.setState({
                            error: "エラー",
                            loading: false
                        })
                    }
                    else {
                        this.setState({
                            error: "不明",
                            loading: false
                        })
                    }
                })
        }
    }

    onBack() {
        this.props.history.goBack()
    }

    private commitDelete() {
        const id = this.getId()
        const createMode = id == 0
        if (createMode == false) {
            this.setState({ loading: true })
            HokanApi.user.delete(id).then(x => {
                HokanApi.message.user_delete(id).then(() => {
                    this.setState({ loading: false })
                    this.props.history.push(`/manage/user`)
                })
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
    changePassword() {
        this.setState({
            passwordSendError: "",
            sendingMail: true
        })
        HokanApi.sendChangePasswordMail(this.getId()).then(() => {
            this.setState({
                openPassword: false,
                openSendDone: true,
                passwordSendError: "",
                sendingMail: false
            })
        }).catch(err => {
            this.setState({
                openPassword: false,
                openSendDone: true,
                passwordSendError: "エラーが発生しました",
                sendingMail: false
            })
            console.log(err)
        })
    }

    public render() {
        const { classes } = this.props
        const { data } = this.state
        const id = this.getId()
        const createMode = id == 0
        const isAdmin = this.state.me.privilege == "Admin"
        const isMe = this.state.me.id == id
        const sa = new StoreAccessor(this, (isAdmin || isMe), "data")
        const editable = (isAdmin || isMe)
        //const sexList = [
        //    {label:'男性', value:"Male"},
        //    { label: '女性', value: "Female" },
        //    { label: '未選択', value: "" }
        //]
        const priList = [
            { label: '一般', value: "Normal" },
            { label: '管理者', value: "Admin" }
        ]

        const shikakuList = [
            { label: '未選択', value: "0" },
            { label: '一時停止', value: "98" },
            { label: '看護師', value: "3" },
            { label: '准看護師', value: "4" },
            { label: '理学療法士(PT)', value: "11" },
            { label: '作業療法士(OT)', value: "12" },
            { label: '言語聴覚士(ST)', value: "13" },
            { label: '薬剤師', value: "80" },
            { label: '事務など', value: "99" },
            { label: '医師', value: "1" },
            { label: '歯科医師', value: "2" },
            { label: '介護支援専門員', value: "5" },
            { label: '訪問介護員', value: "6" },
            { label: '介護福祉士(CW)', value: "7" },
            { label: '社会福祉士(SW)', value: "8" },
            { label: '精神保健福祉士(PSW)', value: "9" },
            { label: '医療ソーシャルワーカー(MSW)', value: "10" },
            { label: '視能訓練士(ORT)', value: "14" },
            { label: '居宅介護従業者', value: "15" },
            { label: '重度訪問介護従業者', value: "16" },
            { label: '行動支援従業者', value: "17" },
            { label: '外出介護従業者', value: "18" },
            { label: '栄養士', value: "19" },
            { label: '管理栄養士', value: "20" },
            { label: '薬剤師', value: "21" },
        ]
        const workStyleList = [
            { label: '常勤専従', value: "常勤専従" },
            { label: '常勤兼務', value: "常勤兼務" },
            { label: '非常勤専従', value: "非常勤専従" },
            { label: '非常勤兼務', value: "非常勤兼務" }
        ]
        const branchList = this.state.branches.map(b => {
            return { label: b.name, value: "" + b.id }
        })

        return <div className={classes.root}>
            <SubTitle title="スタッフ情報"
            />
            <Grid container spacing={2} className={classes.grid}>
                <InputList editable={isAdmin} title="所属事業所(※)" value={data ? "" + data.branch_id : ""} onChange={(s) => this.setText("branch_id", parseInt(s))} values={branchList} />
                <InputList editable={isAdmin} title="権限(※)" value={data ? data.privilege : "一般"} onChange={(s) => this.setText("privilege", s)} values={priList} />
                <InputText editable={editable} title="名前(※)" value={data ? data.name : ""} onChange={(s) => this.setText("name", s)} />
                <InputText editable={editable} title="ヨミガナ" value={data ? data.kana : ""} onChange={(s) => this.setText("kana", s)} />
                <InputText editable={isAdmin} title="メールアドレス(※)" value={data ? data.email : ""} onChange={(s) => this.setText("email", s)} />
                {
                    (isAdmin || isMe) ?
                        createMode ?
                            null : [
                                <Grid key="pass_1" item xs={3} className={classes.row}>
                                    パスワード
                                </Grid>,
                                <Grid key="pass_2" item xs={9}>
                                    <Button variant="outlined"
                                        onClick={(e) => this.setState({ openPassword: true })}
                                    >パスワード変更</Button>
                                </Grid>
                            ]
                        : null
                }
                <InputText editable={editable} title="携帯電話（仕事用）" value={data ? data.tel1 : ""} onChange={(s) => this.setText("tel1", s)} />
                <InputText editable={editable} title="携帯電話（私用）" value={data ? data.tel2 : ""} onChange={(s) => this.setText("tel2", s)} />
                <InputText editable={editable} inputType="date" title="生年月日" value={data ? data.birthday : ""} onChange={(s) => this.setText("birthday", s)} />
                <InputText editable={editable} inputType="date" title="入社日" value={data ? data.nyusha : ""} onChange={(s) => this.setText("nyusha", s)} />
                <InputList editable={editable} title="勤務形態" value={data ? "" + data.work_style : ""} onChange={(s) => this.setText("work_style", s)} values={workStyleList} />
                <InputList editable={editable} title="職種(※)" value={data ? "" + data.shikaku : ""} onChange={(s) => this.setText("shikaku", s)} values={shikakuList} />
                <InputText editable={editable} title="ワイズマンID" value={data && data.wiseman_id ? data.wiseman_id : ""} onChange={(s) => this.setText("wiseman_id", s)} />
                <Grid key="pass_1" item xs={3} className={classes.row}>
                    二段階認証
                </Grid>
                <Grid key="pass_2" item xs={9}>
                    {
                        data ?
                            sa.isEditing ? sa.check("use_mfa", "有効")
                                : data.use_mfa ? "有効" : "無効"
                            : null
                    }
                </Grid>
                {
                    createMode ?
                        null :
                        isAdmin ?
                            [
                                <Grid key="pass_1" item xs={3} className={classes.row}>
                                    削除
                                </Grid>,
                                <Grid key="pass_2" item xs={9}>
                                    <Button className={classes.deleteButton} variant="contained" color="secondary"
                                        onClick={(e) => this.setState({ openDelete: true })} disabled={this.state.loading}>
                                        アカウントを削除する
                                    </Button>
                                </Grid>
                            ]
                            : null
                }
            </Grid>

            {
                data ?
                    <SimpleDialog
                        title="削除確認"
                        open={this.state.openDelete}
                        deleteText="削除"
                        hideOk={true}
                        hideDelete={false}
                        onCancel={() => this.setState({ openDelete: false })}
                        onDelete={() => this.commitDelete()}
                    >
                        こちらのアカウント({data.name})を削除してよろしいでしょうか？<br />
                        （この操作は戻せません）
                    </SimpleDialog>
                    : null
            }
            {
                this.state.error ?
                    <div style={{ color: Colors.dangerColor, textAlign: "center" }}>
                        エラー：{this.state.error}
                    </div>
                    : null
            }
            {
                this.state.data && (!this.state.data.email || this.state.data.email == "") ?
                    <div style={{ color: Colors.dangerColor, textAlign: "center" }}>
                        メールアドレスは必須です。
                    </div>
                    : null
            }
            {
                this.state.data && (!this.state.data.shikaku || this.state.data.shikaku == "0" || this.state.data.shikaku == "") ?
                    <div style={{ color: Colors.dangerColor, textAlign: "center" }}>
                        職種を選択してください。
                    </div>
                    : null
            }
            <ButtonOkCancel
                showBack={true}
                cancelLabel="戻る" okLabel="確定"
                onCancel={() => this.onBack()}
                onOk={() => this.onCommit()}
                disabled={this.state.loading || (
                    !this.state.data || !this.state.data.email || this.state.data.email == "" ||
                    !this.state.data.shikaku || this.state.data.shikaku == "0" || this.state.data.shikaku == ""
                )} />

            <SimpleDialog
                title="確認"
                open={this.state.openPassword}
                hideOk={false}
                hideDelete={true}
                onCancel={() => this.setState({ openPassword: false, openSendDone: false, passwordSendError: "" })}
                onOk={() => this.changePassword()}
                disableButtons={this.state.sendingMail}
            >
                <div style={{ textAlign: "center" }}>
                    {data ? data.email : null}
                </div>
                パスワード変更メールを送信してよろしいですか？
            </SimpleDialog>
            <SimpleDialog
                title={this.state.passwordSendError != "" ? "エラー" : "送信完了"}
                open={this.state.openSendDone}
                hideCancel={true}
                hideDelete={true}
                onOk={() => this.setState({ openSendDone: false })}
            >
                <div style={{ textAlign: "center" }}>
                    {data ? data.email : null}
                </div>
                {
                    this.state.passwordSendError != "" ?
                        <div style={{ color: "red", textAlign: "center" }}>
                            {this.state.passwordSendError}
                        </div>
                        : <div style={{ textAlign: "center" }}>
                            メールを送信しました。確認してください。
                        </div>
                }

            </SimpleDialog>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(UserPage))
