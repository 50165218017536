export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public ben_2: string = "";
  public ben_3: string = "";
  public ben_1: string = "";
  public chia_1: string = "";
  public chia_2: string = "";
  public chia_3: string = "";
  public create_date: string = "";
  public date_1: string = "";
  public date_2: string = "";
  public date_3: string = "";
  public eat_1: string = "";
  public eat_2: string = "";
  public eat_3: string = "";
  public hot_1: string = "";
  public hot_2: string = "";
  public hot_3: string = "";
  public in_other_1: string = "";
  public in_other_2: string = "";
  public in_other_3: string = "";
  public in_other_free: string = "";
  public jcs_1: string = "";
  public jcs_2: string = "";
  public jcs_3: string = "";
  public katsudou_1: string = "";
  public katsudou_2: string = "";
  public katsudou_3: string = "";
  public mitooshi_1: string = "";
  public mitooshi_2: string = "";
  public mitooshi_3: string = "";
  public mukumi_1: string = "";
  public mukumi_2: string = "";
  public mukumi_3: string = "";
  public nrs_1: string = "";
  public nrs_2: string = "";
  public nrs_3: string = "";
  public nyou_1: string = "";
  public nyou_2: string = "";
  public nyou_3: string = "";
  public other_free_1: string = "";
  public other_free_1_1: string = "";
  public other_free_1_2: string = "";
  public other_free_1_3: string = "";
  public other_free_2: string = "";
  public other_free_2_1: string = "";
  public other_free_2_2: string = "";
  public other_free_2_3: string = "";
  public other_free_3: string = "";
  public other_free_3_1: string = "";
  public other_free_3_2: string = "";
  public other_free_3_3: string = "";
  public out_other_1: string = "";
  public out_other_2: string = "";
  public out_other_3: string = "";
  public out_other_free: string = "";
  public pat_name: string = "";
  public rei_1: string = "";
  public rei_2: string = "";
  public rei_3: string = "";
  public sign_1: string = "";
  public sign_2: string = "";
  public sign_3: string = "";
  public sou_1: string = "";
  public sou_2: string = "";
  public sou_3: string = "";
  public water_1: string = "";
  public water_2: string = "";
  public water_3: string = "";
  public yakku_3: string = "";
  public yaku_1: string = "";
  public yaku_2: string = "";
  public zen_1: string = "";
  public zen_2: string = "";
  public zen_3: string = "";

  public care_mane_1: string = "";
  public care_mane_2: string = "";
  public care_mane_3: string = "";
  public care_team_1: string = "";
  public care_team_2: string = "";
  public care_team_3: string = "";
  public date_moku_1: string = "";
  public date_moku_2: string = "";
  public date_moku_3: string = "";
  public free_text_1: string = "";
  public free_text_2: string = "";
  public free_text_3: string = "";
  public free_text_4: string = "";
  public free_text_5: string = "";
  public free_text_6: string = "";
  public free_text_7: string = "";
  public free_text_8: string = "";
  public ishi_1: string = "";
  public ishi_2: string = "";
  public ishi_3: string = "";
}

export class Checks {
  public check_hon_10_1: boolean = false;
  public check_hon_10_2: boolean = false;
  public check_hon_10_3: boolean = false;
  public check_hon_11_1: boolean = false;
  public check_hon_11_2: boolean = false;
  public check_hon_11_3: boolean = false;
  public check_hon_12_1: boolean = false;
  public check_hon_12_2: boolean = false;
  public check_hon_12_3: boolean = false;
  public check_hon_13_1: boolean = false;
  public check_hon_13_2: boolean = false;
  public check_hon_13_3: boolean = false;
  public check_hon_14_1: boolean = false;
  public check_hon_14_2: boolean = false;
  public check_hon_14_3: boolean = false;
  public check_hon_15_1: boolean = false;
  public check_hon_15_2: boolean = false;
  public check_hon_15_3: boolean = false;
  public check_hon_16_1: boolean = false;
  public check_hon_16_2: boolean = false;
  public check_hon_16_3: boolean = false;
  public check_hon_17_1: boolean = false;
  public check_hon_17_2: boolean = false;
  public check_hon_17_3: boolean = false;
  public check_hon_18_1: boolean = false;
  public check_hon_18_2: boolean = false;
  public check_hon_18_3: boolean = false;
  public check_hon_19_1: boolean = false;
  public check_hon_19_2: boolean = false;
  public check_hon_19_3: boolean = false;
  public check_hon_1_1: boolean = false;
  public check_hon_1_2: boolean = false;
  public check_hon_1_3: boolean = false;
  public check_hon_20_1: boolean = false;
  public check_hon_20_2: boolean = false;
  public check_hon_20_3: boolean = false;
  public check_hon_21_1: boolean = false;
  public check_hon_21_2: boolean = false;
  public check_hon_21_3: boolean = false;
  public check_hon_22_1: boolean = false;
  public check_hon_22_2: boolean = false;
  public check_hon_22_3: boolean = false;
  public check_hon_23_1: boolean = false;
  public check_hon_23_2: boolean = false;
  public check_hon_23_3: boolean = false;
  public check_hon_24_1: boolean = false;
  public check_hon_24_2: boolean = false;
  public check_hon_24_3: boolean = false;
  public check_hon_25_1: boolean = false;
  public check_hon_25_2: boolean = false;
  public check_hon_25_3: boolean = false;
  public check_hon_26_1: boolean = false;
  public check_hon_26_2: boolean = false;
  public check_hon_26_3: boolean = false;
  public check_hon_2_1: boolean = false;
  public check_hon_2_2: boolean = false;
  public check_hon_2_3: boolean = false;
  public check_hon_3_1: boolean = false;
  public check_hon_3_2: boolean = false;
  public check_hon_3_3: boolean = false;
  public check_hon_4_1: boolean = false;
  public check_hon_4_2: boolean = false;
  public check_hon_4_3: boolean = false;
  public check_hon_5_1: boolean = false;
  public check_hon_5_2: boolean = false;
  public check_hon_5_3: boolean = false;
  public check_hon_6_1: boolean = false;
  public check_hon_6_2: boolean = false;
  public check_hon_6_3: boolean = false;
  public check_hon_7_1: boolean = false;
  public check_hon_7_2: boolean = false;
  public check_hon_7_3: boolean = false;
  public check_hon_8_1: boolean = false;
  public check_hon_8_2: boolean = false;
  public check_hon_8_3: boolean = false;
  public check_hon_9_1: boolean = false;
  public check_hon_9_2: boolean = false;
  public check_hon_9_3: boolean = false;
  public check_kazoku_1_1: boolean = false;
  public check_kazoku_1_2: boolean = false;
  public check_kazoku_1_3: boolean = false;
  public check_kazoku_2_1: boolean = false;
  public check_kazoku_2_2: boolean = false;
  public check_kazoku_2_3: boolean = false;
  public check_kazoku_3_1: boolean = false;
  public check_kazoku_3_2: boolean = false;
  public check_kazoku_3_3: boolean = false;
  public check_kazoku_4_1: boolean = false;
  public check_kazoku_4_2: boolean = false;
  public check_kazoku_4_3: boolean = false;
}

