import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
import TalkList from '../components/TalkList';
import { Select, MenuItem } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    branchId: number | null
    talks: Model.Talk[]
    loading: boolean
    me: Model.User
}

// @inject()
// @observer
class MessageListPage extends React.Component<Props, LocalState> {
    displayName = "UserListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            branchId: null,
            talks: [],
            loading: false,
            me: new Model.User()
        }
    }

    componentDidMount() {
        HokanApi.me().then(mex => {
            this.setState({ me: mex.data })

            HokanApi.message.list()
                .then(x => {
                    //this.setState({ branches: x.data, branchId: this.state.me.branch_id })
                    this.setState({ talks: x.data, branchId: this.state.me.branch_id })
                })
                .catch(err => {
                    console.log(err)
                })
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    public render() {
        const { classes } = this.props;
        return <div className={classes.root}>
            <SubTitle title="トーク一覧"
                text="トークを選択してください"
            />
            <TalkList showButton={true} branchId={this.state.branchId} />
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(MessageListPage))