import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import { Select, MenuItem, ListItem, List, Button } from '@material-ui/core';
import * as Colors from '../components/Colors'
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import StandardTable from '../components/StandardTable';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import { StoreAccessor } from '../util/StoreUtil'
import Pagination from "material-ui-flat-pagination";
import Firebase from '../firebase'
import * as firebase from 'firebase/app';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1100px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    activity_task_table: {
        textAlign: "center",
    },
    activity_task_item: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        padding: "5px",
        cursor: "pointer",
        fontWeight: 700,
        '&:hover': {
            backgroundColor: Colors.backColor
        },
        whiteSpace: "pre-line"
    },
    activity_task_list: {
        padding: "0px"
    },
    activity_task_item_text: {
        paddingRight: "0px",
        width: "80%",
        fontSize: '1.1em'
    },
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

class Message {
    public id?: string = undefined
    public created_at: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now()
    public message: string = ""
    public user_name: string = ""
}

interface LocalState {
    branchId: number | null
    branches: Model.Branch[]
    loading: boolean
    me: Model.User
    message: string
    name: string
    messageList: Message[]
}

// @inject()
// @observer
class ChatPage extends React.Component<Props, LocalState> {
    displayName = "ChatPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            branchId: null,
            branches: [],
            loading: false,
            me: new Model.User(),
            name: "yuki",
            message: "",
            messageList: []
        }
        this.sendMessage = this.sendMessage.bind(this)
    }

    componentDidMount() {
        HokanApi.me().then(x => {
            this.setState({ me: x.data, branchId: x.data.branch_id })
        }).catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })

        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })

        Firebase.instance.store.collection("chat").get().then((querySnapshot) => {
            const t:Message[] =[]
            querySnapshot.forEach((doc) => {
                console.log(doc.data());
                t.push(doc.data() as Message)
            });
            this.setState({messageList: t})
        });
        Firebase.instance.store.collection("chat").orderBy("created_at").onSnapshot((querySnapshot) => {
            const t:Message[] = []
            querySnapshot.forEach((doc) => {
                console.log(doc.data());
                t.push(doc.data() as Message)
            });
            this.setState({ messageList: t })
        })
    }

    private sendMessage()
    {
        const s = this.state.message
        console.log(s)
        Firebase.instance.store.collection("chat").add({
            message: s,
            user_name: this.state.name,
            created_at: firebase.firestore.Timestamp.now()
        }).then(res => {
            this.setState({ message: "" })
        }).catch(err => {
            console.log(err)
        })
    }

    public render() {
        const { classes } = this.props;
        const sa = new StoreAccessor(this, true)

        return <div className={classes.root}>
            <div>
                名前：{sa.text("name")}
                メッセージ：{sa.text("message")}
            <Button variant="contained" onClick={this.sendMessage}>送信</Button>
            </div>
            <div>
                {
                    this.state.messageList.map((msg,mi) => {
                        return <div key={`msg_${mi}`}>
                            [{msg.created_at.toDate().toLocaleString()}]
                            {msg.user_name}:{msg.message}
                        </div>
                    })
                }
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(ChatPage))