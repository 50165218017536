import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";
import { Button, Grid } from '@material-ui/core';

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 80;
  }

  public createContent(): any {
    const res = new FormModel.LifeE.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    ["houmon", "tokubetsu", "kango", "sinnryou", "iryou"].forEach((v) => {
      const checked = doc.content.checks[`check_${v}`];

      if (
        checked === true &&
        doc.content.checks[`check_${v}_hospital`] === false &&
        doc.content.checks[`check_${v}_house_call_doctor`] === false
      ) {
        doc.content.checks[`check_${v}_unnecessary`] = true;
        return;
      }

      if (checked === false) {
        doc.content.checks[`check_${v}_hospital`] = false;
        doc.content.checks[`check_${v}_house_call_doctor`] = false;
        doc.content.checks[`check_${v}_unnecessary`] = false;
      }
    });
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);


    enum sumi { none = "未選択", ari = "済み", nashi = "未", huka = "不可" }
    enum teido { none = "未選択", jubun = "十分", bubun = "部分的", aimai = "曖昧" }
    enum misumi { none = "未選択", ari = "済み", nashi = "未" }


    enum umu { none = "未選択", ari = "済", nashi = "未" }

    const pf = ["1", "2", "3"]
    const genText = (name: string) => {
      return <Grid container={true}>
        {pf.map((x, i) =>
          <Grid key={i} item={true} xs={4} sm={4} lg={4}>
            {saV.text(`${name}_${x}`, "", "text", "inline-flex", true, "2")}
          </Grid>
        )}
      </Grid>
    }
    const genList = (name: string, lis: any) => {
      return <Grid container={true}>
        {pf.map((x, i) =>
          <Grid key={i} item={true} xs={4} sm={4} lg={4}>
            {saV.list(`${name}_${x}`, lis)}
          </Grid>
        )}
      </Grid>
    }

    const elements_d = [
      {
        name: "月日",
        content: <div style={{ display: "inline-flex" }}>
          {pf.map(x => saV.date(`date_${x}`))}
        </div>
      },
      { name: "記載者サイン", content: genText("sign") }
    ]
    const elements_h = [
      { name: "意思の確認", content: genList("h_ikou", sumi) },
      { name: "望んでいる医療・\nケア方針や生き方", content: genText("h_nozomi") },
      { name: "受ける医療･\nケアの説明", content: genList("h_setsumei", sumi) },
      { name: "心身の変化の\n見通しの説明", content: genList("h_henka", sumi) },
      { name: "生活上の\n留意点の説明", content: genList("h_ryui", sumi) },
      { name: "理解の印象", content: <div>
        {genList("h_rikai_1", teido)}
        {genText("h_rikai_2")}
      </div> },
      { name: "意思決定", content: genList("h_ishi",sumi) },
      { name: "ケアの決定等", content: genText("h_care") },
    ]
    const elements_k = [
      { name: "対象者", content: genText("k_taishou") },
      { name: "意思の確認", content: genText("k_ikou") },
      { name: "望んでいる医療・\nケア方針や生き方", content: genText("k_nozomi") },
      { name: "受ける医療･\nケアの説明", content: genText("k_setsumei") },
      { name: "心身の変化の\n見通しの説明", content: genText("k_henka") },
      { name: "生活上の\n留意点の説明", content: genText("k_ryui") },
      {
        name: "理解の印象", content: <div>
          {genList("k_rikai_1", teido)}
          {genText("k_rikai_2")}
        </div> },
      { name: "意思決定", content: genText("k_ishi") },
      { name: "ケアの決定等", content: genText("k_care") },
    ]
    const elements_c = [
      { name: "情報共有", content:  genList("c_jouhou", umu) },
      { name: "メンバー", content: genText("c_member") },
      { name: "共有したこと", content: genText("c_share") },
    ]

    const elements = [
      { name: "月日", content: elements_d },
      { name: "本人への支援", content: elements_h },
      { name: "家族等への支援", content: elements_k },
      { name: "ケアチーム", content: elements_c }
    ];

    return elements;
  }
}
