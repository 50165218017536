import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import * as Colors from '../Colors'
import Loading from '../Loading'
import SubTitle from '../SubTitle'
import { toInjector, Problem } from '../../stores';
import { inject, observer } from 'mobx-react';
import { AssessmentComponent, AssessmentProps } from './AssessmentComponent'
import * as Hash from "./Hash"
import { Button, TextField } from '@material-ui/core';
import * as Model from '../../models';
const buttonStyle = {
    width: "120px",
    height: "60px",
    margin: "20px"
}
const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    list: {
        marginBottom: "20px"
    },
    itemHeader: {
        background: Colors.primaryColor,

    },
    headerText: {
        "& span": {
            color: Colors.white,
            fontSize: "1.1em"
        }
    },
    item: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        padding: "5px",
        fontWeight: 700,
        '&:hover': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:first-child": {


        }
    },
    itemText: {
        paddingRight: "0px",
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    nextButton: {
        ...buttonStyle,
    }
});

interface Props extends Styles.WithStyles, AssessmentProps, Problem {
    ass: Model.Assessment.PostAssessment
    selectSymptom: (symptom_ids: number[], free_symptoms: string[]) => void
}

interface LocalState {
    checked: { [index: number]: boolean; }
    free_symptoms: string[]
}

@inject(...toInjector(Problem))
@observer
class SymptomList extends AssessmentComponent<Props, LocalState> {
    displayName = "SymptomList"
    constructor(props: Props) {
        super(props)
        this.state = {
            checked: {},
            free_symptoms: []
        }
    }

    public componentDidMount = () => {
        this.props.problem!.loadProblem(this.props.problem_id)
        const ch: { [index: number]: boolean; } = []
        const ids: number[] = []
        this.props.ass.symptoms.forEach(x => {
            ch[x] = true
            ids.push(x)
        })
        const fs = this.props.ass.free_symptoms
        this.setState({
            checked: ch,
            free_symptoms: fs
        })
        this.props.selectSymptom(ids, fs)
    }

    private toggleCheck = (id: number) => {
        const checked = this.state.checked;
        if (id in checked == false) {
            checked[id] = false
        }
        checked[id] = !checked[id]
        this.setState({
            checked: checked
        })
        this.props.selectSymptom(
            Object.keys(checked).filter(x => checked[x] == true).map(x => parseInt(x)),
            this.state.free_symptoms
        )
    }

    private isChecked = (id: number) => {
        const checked = this.state.checked;
        if (id in checked == false) {
            checked[id] = false
        }
        return checked[id]
    }

    private toggleCheckFS = (idx: number) => {
        const t = this.state.free_symptoms
        t.splice(idx)
        this.setState({
            free_symptoms: t
        })
        this.callbackToParent()
    }

    private callbackToParent() {
        this.props.selectSymptom(
            Object.keys(this.state.checked).filter(x => this.state.checked[x] == true).map(x => parseInt(x)),
            this.state.free_symptoms
        )
    }

    private goNext = () => {
        this.props.gotoPage(Hash.Modifier)
    }
    public render() {
        const { classes } = this.props
        const problem = this.props.problem!.problem
        const loading = (!problem || this.props.problem!.loading)

        return <div className={classes.root}>
            <SubTitle title="症状・兆候の選択"
                text="症状・兆候を選択してください"
                subText="（※複数選択可）"
            />
            {
                loading ? <Loading /> :
                    <List className={classes.list}>
                        {problem!.symptom.filter(s => s.detail != "その他").map(symptom => (
                            <ListItem key={symptom.id} button={true}
                                className={classes.item}
                                onClick={(e) => this.toggleCheck(symptom.id)}>
                                <Checkbox
                                    checked={this.isChecked(symptom.id)}
                                    color="primary"
                                />
                                <ListItemText primary={symptom.detail}
                                    className={classes.itemText} />
                            </ListItem>
                        ))}
                        {
                            this.state.free_symptoms.map((fs, i) => {
                                return <ListItem key={`fs_${i}`} button={true}
                                    className={classes.item}>
                                    <Checkbox
                                        checked={true}
                                        color="primary"
                                        onClick={(e) => this.toggleCheckFS(i)}
                                    />
                                    <TextField
                                        id={`fs_text_${i}`}
                                        className={classes.itemTextField}
                                        value={fs}
                                        fullWidth={true}
                                        onChange={(e) => {
                                            const t = this.state.free_symptoms
                                            t[i] = e.target.value
                                            this.setState({ free_symptoms: t })
                                            this.callbackToParent()
                                        }}
                                        margin="normal"
                                    />
                                </ListItem>
                            })
                        }
                        <ListItem key="add_other" button={true} className={classes.item}
                            onClick={(e) => {
                                let t = this.state.free_symptoms
                                t.push("")
                                this.setState({ free_symptoms: t })
                                this.callbackToParent()
                            }}>
                            <ListItemText primary="＋その他"
                                className={classes.itemText} />
                        </ListItem>
                    </List>
            }
            <Button className={classes.nextButton} variant="contained" color="primary" onClick={(e) => this.goNext()}>
                次へ
            </Button>
        </div>
    }
}

export default Styles.withStyles(styles)(SymptomList)