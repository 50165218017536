import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 73;
  }

  public createContent(): any {
    const res = new FormModel.RespiratorySystemC.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {}

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const items = [
      "保険証や資格証を確認する",
      "人工呼吸器管理についての地域の最新情報を収集し発信する",
      "呼吸器管理が必要な療養者の環境に合わせて住環境を確認する",
      "指示書・医療処置管理票・「事故発生時のフローチャート：利用者用」・「事故発生時のフローチャート：訪問看護師用」が古くなっていないか確認する",
      "利用者家族の病状理解について確認する",
      "利用者家族の希望について確認する",
      "利用者家族の生活スタイルを確認する",
      "担当者を確認する",
      "人工呼吸器等の医療機器についての勉強会を開催する",
      "病状や状況、今後の方針を確認する",
      "訪問診療の際に同行し、情報共有を行う",
      "急変時や事故時の対応方法を確認する",
      "災害時の対応方法を確認する",
      "サービススケジュールを確認する",
      "ケア内容や状況について共有する",
      "担当者会議やケースカンファレンスの開催を依頼する",
      "訪問時チェックリストを参照し漏れがないか、決まりごとが継続されているか確認する",
      "月1回は訪問し利用者の状況把握を行う",
      "ケア内容やの検討と改善を行う",
      "チェックリストの使用が作業になっていないか確認する",
      "必要な訪問件数を検討し、スケジュールを調整する",
      "スタッフの偏りがないようにスケジュールを調整する",
      "訪問者を増やすことができるか検討する",
      "WINCAREに必要な情報が取り込まれ、更新されているか確認する",
      "緊急時フローチャートを参照して連絡・対応方法、蘇生バックの使用について訓練を行う",
      "ステーション内でヒヤリハットを話しやすい雰囲気を作る",
      "ステーション内で病状変化や気になっていることを話すように促す",
      "アクシデントやヒヤリハットから学び、改善策をとる",
      "連携している他事業所に注意喚起している内容をステーション内で共有する",
      "近くに住む他の利用者とその訪問スケジュールを確認する",
      "疾患や看護ケアについて勉強会を行い知識や技術を補う",
      "病状理解を確認する",
      "呼吸器の取り扱いを確認する",
      "訪問に不安を感じていないか確認・対応する",
      "訪問前にケア方法にシミュレーションを行い、技術を確認する",
      "同行訪問を行い安全にケアが行われているか確認する",
      "初めて同行するスタッフには同行訪問を行う",
      "緊急時フローチャートを参照して連絡・対応方法、蘇生バックの使用について訓練を行う",
      "介護の体制や関わる人に変更がないか確認する",
      "医療技術を確認する(蘇生バック・吸引・カフエア・回路交換・気管カニューレ)",
      "ケアや知識が基本からずれていたり、自己流になっていないか確認する",
      "緊急時フローチャートを参照して連絡・対応方法、蘇生バックの使用について訓練を行う",
      "ケアの内容を確認する",
      "ヘルパー事業所責任者に、訪問者の追加や変更、医療技術(特に蘇生バック・吸引)の取得状況、急変時や事故時の対応ができるか確認する",
      "緊急時フローチャートを参照して連絡・対応方法について訓練を行う",
    ];

    const elements_t = [
      {
        name: "内容",
        content: (
          <div>
            <div
              style={{
                padding: 10,
              }}
            >
              定期的に実施
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  1回目
                  {saV.date(`date_1`)}
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  2回目
                  {saV.date(`date_2`)}
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  3回目
                  {saV.date(`date_3`)}
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  4回目
                  {saV.date(`date_4`)}
                </label>
              </div>
            </div>
            <div
              style={{
                padding: 10,
              }}
            >
              {items.map((item, i) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderTop: "1px solid",
                    gap: 10,
                  }}
                >
                  {item}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "nowrap",
                    }}
                  >
                    <label
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      1回目
                      {saC.check(`check_day_1_${i + 1}`)}
                    </label>
                    <label
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      2回目
                      {saC.check(`check_day_2_${i + 1}`)}
                    </label>
                    <label
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      3回目
                      {saC.check(`check_day_3_${i + 1}`)}
                    </label>
                    <label
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      4回目
                      {saC.check(`check_day_4_${i + 1}`)}
                    </label>
                  </div>
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid",
                  gap: 10,
                }}
              >
                <div style={{ flex: 1 }}>
                  その他
                  {saV.text("others_text", "", "text", "block", true, "100px")}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                  }}
                >
                  <label
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    1回目
                    {saC.check(`check_day_1_46`)}
                  </label>
                  <label
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    2回目
                    {saC.check(`check_day_2_46`)}
                  </label>
                  <label
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    3回目
                    {saC.check(`check_day_3_46`)}
                  </label>
                  <label
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    4回目
                    {saC.check(`check_day_4_46`)}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
