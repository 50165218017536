import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as Colors from '../Colors'
import Loading from '../Loading'
import SubTitle from '../SubTitle'
import { toInjector, Problem } from '../../stores';
import { inject, observer } from 'mobx-react';
import { AssessmentComponent, AssessmentProps } from './AssessmentComponent'
import * as Hash from './Hash'
import GoPrevNext from './GoPrevNext'
import Radio from '@material-ui/core/Radio';
import { TextField } from '@material-ui/core';
import { ProblemDetail } from '../../models';
import * as Icons from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import * as Model from '../../models'

const itemStyleBase = {
    border: "solid",
    borderWidth: "1px",
    marginTop: "-1px",
    borderColor: Colors.borderColor,
    padding: "10px 5px",
    transition: "linear 0.15s",
    '&:hover': {
        backgroundColor: Colors.backColor
    },
    "&:last-child": {


    },
    "&:first-child": {
    },
    "& div": {
        "& div": {
            /* input */
            color: Colors.inputColor
        }
    }
}
const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    list: {
        marginBottom: "20px"

    },
    itemHeader: {
        background: Colors.primaryColor,

    },
    headerText: {
        "& span": {
            color: Colors.white,
            fontSize: "1.1em",
            fontWeight: 700,
        }
    },
    headerIcon: {
        color: Colors.white
    },
    item: itemStyleBase,
    itemChecked: {
        ...itemStyleBase,
        borderColor: Colors.primaryColor,
        borderLeftWidth: "10px",
        marginBottom: "1px",
        "& div": {
            "& div": {
                /* input */
                fontWeight: 700,
                color: Colors.inputColor
            }
        }
    },
    itemText: {
        paddingRight: "0px",
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    itemTextField: {
        padding: "0px 10px",
        margin: "0"
    },
    numberLabel: {

    }
});

interface Props extends Styles.WithStyles, AssessmentProps, Problem {
    ass: Model.Assessment.PostAssessment
    changeAssessment: (func: (ass: Model.Assessment.Assessment) => void) => void
}

interface LocalState {
    kScore: number,
    bScore: number,
    sScore: number,
    kSentence: string[],
    bSentence: string[],
    sSentence: string[],
}

@inject(...toInjector(Problem))
@observer
class Kbs extends AssessmentComponent<Props, LocalState> {
    displayName = "Kbs"
    constructor(props: Props) {
        super(props)
        this.props.problem
        this.state = {
            kScore: 3,
            bScore: 3,
            sScore: 3,
            kSentence: ["", "", "", "", ""],
            bSentence: ["", "", "", "", ""],
            sSentence: ["", "", "", "", ""],
        }
    }

    public componentDidMount = () => {
        console.log("kbs")
        this.props.problem!.loadProblem(this.props.problem_id)
        const ass = this.props.ass.assessment
        this.setState({
            kScore: ass.kbs_score_k,
            bScore: ass.kbs_score_b,
            sScore: ass.kbs_score_s,
            kSentence: Model.Assessment.Assessment.getAllKSentence(ass),
            bSentence: Model.Assessment.Assessment.getAllBSentence(ass),
            sSentence: Model.Assessment.Assessment.getAllSSentence(ass)
        })
        window.scrollTo(0, 0)
    }

    private goPrev = () => {
        this.props.gotoPage(Hash.Modifier)
    }

    private goNext = () => {
        this.props.gotoPage(Hash.Intervention)
    }

    private setScore = (kbs: string, value: number) => {
        if (kbs == "K") {
            this.setState({ kScore: value })
        }
        else if (kbs == "B") {
            this.setState({ bScore: value })
        }
        else if (kbs == "S") {
            this.setState({ sScore: value })
        }
    }

    private setSentence = (kbs: string, index: number, value: string) => {
        if (value && value.length > 255) value = value.substr(0, 255)
        if (kbs == "K") {
            const s = this.state.kSentence;
            s[index] = value
            this.setState({ kSentence: s })
        }
        else if (kbs == "B") {
            const s = this.state.bSentence;
            s[index] = value
            this.setState({ bSentence: s })
        }
        else if (kbs == "S") {
            const s = this.state.sSentence;
            s[index] = value
            this.setState({ sSentence: s })
        }
    }

    private isChecked(kbs: string, score: number) {
        if (kbs == "K") {
            return this.state.kScore == score
        }
        else if (kbs == "B") {
            return this.state.bScore == score
        }
        else if (kbs == "S") {
            return this.state.sScore == score
        }
        return false
    }

    private getSentence(kbs: string, index: number) {
        if (kbs == "K") {
            return this.state.kSentence[index]
        }
        else if (kbs == "B") {
            return this.state.bSentence[index]
        }
        else if (kbs == "S") {
            return this.state.sSentence[index]
        }
        return ""
    }


    private setKbs = (ass: Model.Assessment.Assessment) => {
        ass.kbs_score_k = this.state.kScore;
        ass.kbs_score_b = this.state.bScore;
        ass.kbs_score_s = this.state.sScore;
        ass.k_sentence1 = this.state.kSentence[0];
        ass.k_sentence2 = this.state.kSentence[1];
        ass.k_sentence3 = this.state.kSentence[2];
        ass.k_sentence4 = this.state.kSentence[3];
        ass.k_sentence5 = this.state.kSentence[4];
        ass.b_sentence1 = this.state.bSentence[0];
        ass.b_sentence2 = this.state.bSentence[1];
        ass.b_sentence3 = this.state.bSentence[2];
        ass.b_sentence4 = this.state.bSentence[3];
        ass.b_sentence5 = this.state.bSentence[4];
        ass.s_sentence1 = this.state.sSentence[0];
        ass.s_sentence2 = this.state.sSentence[1];
        ass.s_sentence3 = this.state.sSentence[2];
        ass.s_sentence4 = this.state.sSentence[3];
        ass.s_sentence5 = this.state.sSentence[4];
    }

    private changeAss = () => {
        this.props.changeAssessment(ass => this.setKbs(ass))
    }

    public render() {
        const { classes } = this.props
        const problem = this.props.problem!.problem
        const loading = (!problem || this.props.problem!.loading)

        const hanrei = [
            <div style={{ fontSize: "0.9rem" }}>
                - 凡例 -<br />
                1:全く理解できない<br />
                2:少し理解できる<br />
                3:基本的に理解できる<br />
                4:とても理解できる<br />
                5:十分理解できる
            </div>
            ,
            <div style={{ fontSize: "0.9rem" }}>
                - 凡例 -<br />
                1:全く適切な反応がない<br />
                2:少し適切な反応がある<br />
                3:時々適切な反応がある<br />
                4:まあまあ適切な反応がある<br />
                5:いつも適切な反応がある
            </div>
            ,
            <div style={{ fontSize: "0.9rem" }}>
                - 凡例 -<br />
                1:深刻なサインや兆候がある<br />
                2:重症なサインや兆候がある<br />
                3:まあまあサインや兆候がある<br />
                4:ややサインや兆候がある<br />
                5:全くサインや兆候がない
            </div>
        ]

        const kbs = [
            { key: "K", title: "知識(K)", hanrei: hanrei[0] },
            { key: "B", title: "行動(B)", hanrei: hanrei[1] },
            { key: "S", title: "状態(S)", hanrei: hanrei[2] }
        ]
        const score = [1, 2, 3, 4, 5]


        return <div className={classes.root}>
            <SubTitle title="KBS入力"
                text="優先度、知識(K)、行動(B)、状態(S)を入力してください。"
            />
            {
                loading ? <Loading /> :
                    <div>
                        {
                            kbs.map((kbsInfo, i) => {
                                const kbs = kbsInfo.key
                                return <List className={classes.list} key={`${kbs}_${i}`}>
                                    <ListItem className={classes.itemHeader}>
                                        <ListItemText
                                            className={classes.headerText}
                                            primary={kbsInfo.title}
                                        />

                                        <Tooltip enterTouchDelay={0}
                                            leaveTouchDelay={5000}
                                            TransitionComponent={Fade} title={kbsInfo.hanrei}
                                            placement="bottom-start">
                                            <Icons.HelpOutline className={classes.headerIcon} />
                                        </Tooltip>
                                    </ListItem>
                                    {
                                        score.map((s, i) => {
                                            const ex = ProblemDetail.getKbsExample(problem!, kbs, s)
                                            const checked = this.isChecked(kbs, s)
                                            return <ListItem key={`${kbs}_score_${i}`} button={false}
                                                className={checked ? classes.itemChecked : classes.item} >
                                                <Radio color="primary"
                                                    checked={checked}
                                                    onChange={(e) => {
                                                        this.setScore(kbs, s)
                                                        this.changeAss();
                                                    }} />
                                                <span className={classes.numberLabel}>{s}:</span>
                                                <TextField
                                                    id={`${kbs}_text_${i}`}
                                                    className={classes.itemTextField}
                                                    value={this.getSentence(kbs, i)}
                                                    multiline={true}
                                                    fullWidth={true}
                                                    helperText={ex ? `例) ${ex.sentence}` : ""}
                                                    onChange={(e) => {
                                                        this.setSentence(kbs, i, e.target.value)
                                                        this.changeAss();
                                                    }}
                                                    margin="normal"
                                                />
                                            </ListItem>
                                        })
                                    }
                                </List>
                            })
                        }
                    </div>
            }
            <GoPrevNext onPrev={() => { this.changeAss(); this.goPrev() }} onNext={() => { this.changeAss(); this.goNext() }} />
        </div>
    }
}

export default Styles.withStyles(styles)(Kbs)