import * as React from 'react';
import { PageProps, withPageProps } from '../components/Page'
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import { Button, Card, CardContent, CardActions, Typography, CardHeader, Grid } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    card: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
    },
})

interface Props extends PageProps, Styles.WithStyles {
}

interface LocalState {
}

class PatientListPage extends React.Component<Props, LocalState> {
    displayName = "PatientListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }

    public componentDidMount = () => {
    }

    public render() {
        const { classes, history } = this.props;
        return (
            <div className={classes.root}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={6} lg={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                subheader=""
                                title={"スタッフ一括登録"}
                            />
                            <CardContent>
                                <Typography>
                                    csv ファイルからスタッフを一括で登録します
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" variant="contained" size="small" onClick={() => {
                                    history.push("/manage/import/staff")
                                }}>選択</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item={true} xs={12} md={6} lg={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                subheader=""
                                title={"利用者情報一括登録"}
                            />
                            <CardContent>
                                <Typography>
                                    csv ファイルから利用者を一括で登録します
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" variant="contained" size="small" onClick={() => {
                                    history.push("/manage/import/patient")
                                }}>選択</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item={true} xs={12} md={6} lg={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                subheader=""
                                title={"利用者情報紐付け（ワイズマン）"}
                            />
                            <CardContent>
                                <Typography>
                                    外部システム（ワイズマン）と利用者情報を紐付けます
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" variant="contained" size="small" onClick={() => {
                                    history.push("/manage/import/wiseman_customer")
                                }}>選択</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item={true} xs={12} md={6} lg={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                subheader=""
                                title={"スタッフ情報紐付け（ワイズマン）"}
                            />
                            <CardContent>
                                <Typography>
                                    外部システム（ワイズマン）とスタッフ情報を紐付けます
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" variant="contained" size="small" onClick={() => {
                                    history.push("/manage/import/wiseman_staff")
                                }}>選択</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item={true} xs={12} md={6} lg={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                subheader=""
                                title={"ワイズマン実績情報書き出し"}
                            />
                            <CardContent>
                                <Typography>
                                    外部システム（ワイズマン）用の実績データを書き出します
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" variant="contained" size="small" onClick={() => {
                                    history.push("/manage/import/wiseman_jisseki")
                                }}>選択</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item={true} xs={12} md={6} lg={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                subheader=""
                                title={"すこやかサン実績情報書き出し"}
                            />
                            <CardContent>
                                <Typography>
                                    外部システム（すこやかサン）用の実績データを書き出します
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" variant="contained" size="small" onClick={() => {
                                    history.push("/manage/import/sukoyaka_jisseki")
                                }}>選択</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withPageProps(Styles.withStyles(styles)(PatientListPage))