import * as React from 'react';
import * as Colors from './Colors'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core/';
import * as Model from '../models'
import DateUtil from '../util/DateUtil'
import { StoreAccessor } from '../util/StoreUtil';

interface KirokuTableProps {
    name: string
    titles: string[]
    values: any[][]
    align: "right" | "center" | "left"
    nocheck: boolean
}

export function getComponentByName(name, x, problemList) {
    if (name == "基本情報") return <Basic kiroku={x} />
    else if (name == "バイタルサイン") return <Vital kiroku={x} />
    else if (name == "オマハシステム") return <Omaha kiroku={x} problemList={problemList} />
    else if (name == "皮膚") return <Skin kiroku={x} />
    else if (name == "呼吸") return <Breath kiroku={x} />
    else if (name == "疼痛") return <Pain kiroku={x} />
    else if (name == "口腔") return <Oral kiroku={x} />
    else if (name == "栄養・代謝") return <Nutrition kiroku={x} />
    else if (name == "脱水") return <Dehyd kiroku={x} />
    else if (name == "排泄") return <Excre kiroku={x} />
    else if (name == "身体活動") return <Physical kiroku={x} />
    else if (name == "睡眠") return <Sleep kiroku={x} />
    else if (name == "認知・意識") return <Cognition kiroku={x} />
    else if (name == "循環") return <Circ kiroku={x} />
    else if (name == "精神状態") return <Mental kiroku={x} />
    else if (name == "内分泌") return <Metabo kiroku={x} />
    else if (name == "発達・成長") return <Glowth kiroku={x} />
    else if (name == "神経・筋・骨格") return <Neuro kiroku={x} />
    else if (name == "ターミナルケア・エンドオブライフケア" || name=="ACP/EOL") return <EOL kiroku={x} />
    else if (name == "介護・養育") return <Kaigo kiroku={x} />
    else if (name == "言語") return <Lang kiroku={x} />
    else if (name == "その他処置等") return <Other kiroku={x} />
    else if (name == "申し送り事項") return <Moushiokuri kiroku={x} />
    return <div>error</div>
}

function areAllEmptyTable(tables: any) {
    return !tables.find(x => !StoreAccessor.isEmptyValue(x))
}

class KirokuTable extends React.PureComponent<KirokuTableProps> {
    displayName = "KirokuTable"
    public static defaultProps = {
        align: "center",
        nocheck: false
    }
    public render() {
        const classes = {
            activity_table_head: {
                border: "1px solid lightgray",
                color: Colors.primaryColor,
                backgroundColor: Colors.backColor
            },
            activity_table_cell: {
                border: "1px solid lightgray",
                color: Colors.primaryColor,
                whiteSpace: "pre-line"
            },
            root: {
                marginBottom: "5px"
            },
            root_sub: {
                marginBottom: "5px"
            },
            table: {}
        }
        if (this.props.nocheck == false) {
            if (this.props.values.length == 0 ||
                !this.props.values.find(v => StoreAccessor.isEmptyValue(v) == false)) {
                return null;
            }
        }

        const content = <Table style={classes.table}>
            {
                this.props.titles.length > 0 ?
                    <TableHead>
                        <TableRow>
                            {
                                this.props.titles.map((x, xi) => {
                                    return <TableCell key={xi} align="center" style={classes.activity_table_head}>{x}</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    : null
            }
            <TableBody>
                {
                    this.props.values.map((row, rowi) => {
                        return <TableRow key={`ktrow_${rowi}`}>
                            {
                                row.map((x, xi) => {
                                    return <TableCell key={`ktc_${xi}`} align={this.props.align} style={classes.activity_table_cell as any}>{x}</TableCell>
                                })
                            }
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>

        if (this.props.name == "") {
            return <div style={classes.root_sub}>{content}</div>
        }
        else {
            return <div style={classes.root}>
                <div style={{ textAlign: "left" }}>{this.props.name}：</div>
                {content}
            </div>
        }
    }
}

class ViewTitle extends React.Component<{ title: string }> {
    displayName = "ViewTitle"
    public render() {
        return <div style={{
            border: "1px solid white",
            backgroundColor: Colors.primaryColor,
            color: Colors.white,
            marginBottom: "0px",
            padding: "5px",
            fontWeight: "bold"
        }}>
            {this.props.title}
        </div>
    }
}

interface Props {
    kiroku: Model.KirokuII.KangoKirokuII
}
interface OmahaProps extends Props {
    problemList: Model.Problem[]
}

export class Basic extends React.Component<Props> {
    displayName = "Basic"
    public render() {
        const k = this.props.kiroku;
        const titles = ["記録の種類", "開始時間", "終了時間", "スタッフ", "算定コード"]
        const code = Model.KirokuII.KangoKirokuII.getKirokuCodeStr(k)
        const data = [
            [
                k.kiroku_type, DateUtil.toJpDateTimeString(k.time_start),
                DateUtil.toJpDateTimeString(k.time_end),
                k.main_user ? k.main_user.name : "",
                code
            ]]

        return <div>
            <KirokuTable
                name=""
                titles={titles}
                values={data}
            />
        </div>
    }
}

export class Moushiokuri extends React.Component<Props> {
    displayName = "Moushiokuri"
    public render() {
        const k = this.props.kiroku;
        return <div>
            <ViewTitle title="申し送り" />
            <KirokuTable
                name=""
                titles={["申し送り事項"]}
                values={[[k.deal.moushiokuri]]}
                align="left"
            />
            <KirokuTable
                name=""
                titles={["画像"]}
                values={[
                    k.deal.moushiokuri_docImage ? k.deal.moushiokuri_docImage.image_urls.map((im, imi) => {
                        return <a href={im} key={`ms_${imi}`} target="blank" >
                            <img src={im} style={{ width: "100px" }} />
                        </a>
                    }) : ["-"]
                ]}
            />
        </div>
    }
}
export class Other extends React.Component<Props> {
    displayName = "Other"
    public render() {
        const k = this.props.kiroku;
        return <div>
            <ViewTitle title="その他処置" />
            <KirokuTable
                name=""
                titles={["その他処置", "その他備考"]}
                values={[[k.deal.naiyou, k.deal.bikou]]}
                align="left"
            />
            <KirokuTable
                name=""
                titles={["画像"]}
                values={[
                    k.deal.naiyou_docImage ? k.deal.naiyou_docImage.image_urls.map((im, imi) => {
                        return <a href={im} key={`ns_${imi}`} target="blank" >
                            <img src={im} style={{ width: "100px" }} />
                        </a>
                    }) : ["-"]
                ]}
            />
        </div>
    }
}
export class Vital extends React.Component<Props> {
    displayName = "Vital"
    public render() {
        const k = this.props.kiroku;
        const titles = ["体温(℃)", "脈拍(回/分)", "血圧(収縮)", "血圧(拡張)", "SpO2(%)", "呼吸回数"]
        const gen = (v: Model.KirokuII.VitalData) => [
            v.taion, v.myakuhaku,
            v.ketsuatsu_max, v.ketsuatsu_min,
            v.spo2,
            v.kokyu_kaisu
        ]
        const data = [gen(k.vital.first)]
        if (k.vital.saiken) {
            data.push(gen(k.vital.saiken))
        }

        return <KirokuTable
            name=""
            titles={titles}
            values={data}
        />

    }
}
export class Omaha extends React.Component<OmahaProps> {
    displayName = "Omaha"
    private catToJp(cat: string) {
        if (cat == "TGC") return "TGC(教育/相談/指導)"
        if (cat == "CM") return "CM(ケアマネジメント)"
        if (cat == "TP") return "TP(直接ケア)"
        if (cat == "S") return "S(観察)"
        return ""
    }
    public render() {
        const k = this.props.kiroku;
        const titles = ["問題"]
        const intvCats = ["TGC", "CM", "TP", "S"]
        const content = k.omaha.details.map((p, pi) => {
            const problem = this.props.problemList.find(z => z.id == p.problem_id)
            const catFromInterv = {}
            p.intervention.filter(v => v.check).forEach(x => {
                if (x.detail) {
                    if (catFromInterv[x.detail.category]) {
                        catFromInterv[x.detail.category].push(x)
                    }
                    else {
                        catFromInterv[x.detail.category] = [x]
                    }
                }
            })
            return <div key={`omaha_d_${pi}`}>
                <KirokuTable
                    name=""
                    titles={titles}
                    values={[[problem ? problem.name : "..."
                    ]]}
                />
                <div style={{ marginTop: "-6px" }}>
                    <KirokuTable
                        name=""
                        titles={["行なった介入"]}
                        values={[[
                            intvCats.map((ica, icai) => {
                                return catFromInterv[ica] ? <div key={icai} style={{ whiteSpace: "pre-line" }}>
                                    {this.catToJp(ica)}<br />
                                    {
                                        catFromInterv[ica].map(x => {
                                            return "・" + x.detail.target_category + ":" + x.detail.target + x.comment + "\n"
                                        })
                                    }
                                </div>
                                    : null
                            })
                        ]]}
                        align="left"
                    />
                    <KirokuTable
                        name=""
                        titles={["自由記述"]}
                        values={[[p.comment]]}
                        align="left"
                    />
                </div>
            </div>
        })

        return <div>
            <ViewTitle title="オマハシステム" />
            {content}
        </div>
    }
}
export class Skin extends React.Component<Props> {
    displayName = "Skin"
    public render() {
        const k = this.props.kiroku;
        const tables = ([] as any[]).concat.apply([], [
            ...k.skin.jokuso.map((j, ji) => {
                return [<KirokuTable
                    key={`j_${ji}_1`}
                    name="褥瘡"
                    titles={["発見日", "D", "E", "S", "I", "G", "N", "P", "スコア"]}
                    values={[[j.hakken_date, j.d, j.e, j.s, j.i, j.g, j.n, j.p, Model.KirokuII.SkinJokuso.calcScore(j)]]}
                />,
                <KirokuTable
                    key={`j_${ji}_2`}
                    name=""
                    titles={["写真"]}
                    values={[
                        j.docImage ? j.docImage.image_urls.map((im, imi) => {
                            return <a href={im} key={`js_${ji}_${imi}`} target="blank">
                                <img src={im} style={{ width: "100px" }} />
                            </a>
                        }) : ["-"]
                    ]}
                />,
                < KirokuTable
                    key={`jj_${ji}_3`}
                    name=""
                    titles={["軟膏", "皮膜材"]}
                    values={[[j.senjo.nankou, j.senjo.himakuzai]]}
                />
                ]
            })
            ,
            ...k.skin.other.map((j, ji) => {
                return [
                    <KirokuTable
                        key={`oj_${ji}_1`}
                        name="褥瘡以外の皮膚トラブル"
                        titles={["発見日", "タイトル", "備考"]}
                        values={[[j.hakken_date, j.title, j.comment]]}
                    />,
                    < KirokuTable
                        key={`oj_${ji}_2`}
                        name=""
                        titles={["軟膏", "皮膜材"]}
                        values={[[j.senjo.nankou, j.senjo.himakuzai]]}
                    />,
                    <KirokuTable
                        key={`j_${ji}_2`}
                        name=""
                        titles={["写真"]}
                        values={[
                            j.docImage ? j.docImage.image_urls.map((im, imi) => {
                                return <a href={im} key={`js_${ji}_${imi}`} target="blank">
                                    <img src={im} style={{ width: "100px" }} />
                                </a>
                            }) : ["-"]
                        ]}
                    />,
                ]
            })
        ])

        return <div>
            <ViewTitle title="皮膚" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}


export class DocImage extends React.Component<{ doc: Model.Document.Document | null }> {
    displayName = "DocImage"
    public render() {
        const image_style = { width: "50px" }
        const doc = this.props.doc
        if (!doc) return <div />
        return <div>
            <div>{doc.comment}</div>
            {doc.image_urls.map((url, urli) => <img key={`img_${urli}`} src={url} style={image_style} />)}
        </div>
    }
}

function docImage(doc: Model.Document.Document | null) {
    if (!doc || (
        (!doc.comment || doc.comment == "") &&
        (!doc.image_urls || doc.image_urls.length == 0)
    )
    ) {
        return null
    }
    return <DocImage doc={doc} />
}

export class Breath extends React.Component<Props> {
    displayName = "Breath"
    public render() {
        const k = this.props.kiroku;

        const check = (f: boolean) => (f ? "○" : "×")
        const tables = [
            <KirokuTable
                key="1"
                name="痰・分泌"
                titles={["性状、色、量", "排痰ケア"]}
                values={[[k.breath.tan_comment, k.breath.haitan_care]]}
            />,
            <KirokuTable
                key="2"
                name="在宅酸素療法(HOT)"
                titles={["酸素投与量", "フィルタ交換", "水交換", "コンセント確認", "予備ボンベ残量"]}
                values={[[
                    k.breath.hot_sanso_touyo_ryou,
                    check(k.breath.hot_kiki_kakunin_filter),
                    check(k.breath.hot_kiki_kakunin_mizu),
                    check(k.breath.hot_kiki_kakunin_konsento),
                    check(k.breath.hot_kiki_kakunin_yobi_bonbe),
                ]]}
            />,
            <KirokuTable
                key="3"
                name="気管切開部観察"
                titles={["交換日", "カフ圧チェック", "薬剤（軟膏）", "吸引の長さ"]}
                values={[[
                    k.breath.kikan_sekkai_koukan_date,
                    k.breath.kikan_sekkai_kahu_atsu,
                    k.breath.kikan_sekkai_yakuzai,
                    k.breath.kikan_sekkai_kyuin,
                ]]}
            />,
            <KirokuTable
                key="4"
                name=""
                titles={k.breath.kikan_sekkai_hihu_trouble.map(x => x.troubleType)}
                values={[k.breath.kikan_sekkai_hihu_trouble.map(x => docImage(x.docImage))]}
            />,
            <KirokuTable
                key="5"
                name="人工呼吸器"
                titles={["種類", "TV(ml/回)", "MV(l/分)", "RR(回/分)", "PIP(cmH2o)", "リーク(ml/分)", "酸素(L/分)"]}
                values={[[
                    k.breath.jinko_kokyuki_type,
                    k.breath.jinko_kokyuki_tv,
                    k.breath.jinko_kokyuki_mv,
                    k.breath.jinko_kokyuki_rr,
                    k.breath.jinko_kokyuki_pip,
                    k.breath.jinko_kokyuki_leak,
                    k.breath.jinko_kokyuki_sanso,
                ]]}
            />,
            <KirokuTable
                key="6"
                name=""
                titles={["その他", "加温加湿", "回路交換実施", "備考"]}
                values={[[
                    k.breath.jinko_kokyuki_other,
                    k.breath.jinko_kokyuki_kaon_kashitsu,
                    k.breath.jinko_kokyuki_is_kairo_koukan,
                    k.breath.jinko_kokyuki_comment,
                ]]}
            />,
            <KirokuTable
                key="7"
                name="薬剤"
                titles={["吸入薬", "その他"]}
                values={[[
                    k.breath.yakuzai_kyuin_yaku,
                    k.breath.yakuzai_other
                ]]}
            />
        ]
        return <div>
            <ViewTitle title="呼吸" />
            <KirokuTable
                name="呼吸音(聴診)"
                titles={Model.KirokuII.breath_bui_to_prop_name.map(b => b.label)}
                values={[k.breath.kokyuon_ijou ?
                    Model.KirokuII.breath_bui_to_prop_name.map(b => k.breath.kokyuon_ijou[b.key])
                    : []]}
            />
            {tables}
        </div>
    }
}
export class Pain extends React.Component<Props> {
    displayName = "Pain"
    public render() {
        const k = this.props.kiroku;
        const tables = [
            <KirokuTable
                key="1"
                name=""
                titles={["痛みの種類", "痛みのパターン", "本人の表現"]}
                values={[[k.pain.pain_type, k.pain.pain_pattern, k.pain.honnin_said]]}
            />,
            <KirokuTable
                key="2"
                name="薬剤"
                titles={["内服", "貼付薬", "注射", "レスキュー（なにを/頻度)"]}
                values={[[k.pain.yakuzai_naihuku, k.pain.yakuzai_harituke,
                k.pain.yakuzai_chusha, k.pain.yakuzai_rescue]]}
            />,
            <KirokuTable
                key="3"
                name="苦痛の質評価(STAS-J)"
                titles={["痛みのコントロール", "症状が患者に及ぼす影響", "患者の不安",
                    "家族の不安", "患者の病状認識", "家族の病状認識", "患者と家族のコミュニケーション",
                    "職種間のコミュニケーション", "医療スタッフのコミュニケーション"]}
                values={[[k.pain.stasj_s1, k.pain.stasj_s2, k.pain.stasj_s3,
                k.pain.stasj_s4, k.pain.stasj_s5, k.pain.stasj_s6,
                k.pain.stasj_s7, k.pain.stasj_s8, k.pain.stasj_s9]]}
            />
        ]
        return <div>
            <ViewTitle title="疼痛" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}
export class Oral extends React.Component<Props> {
    displayName = "Oral"
    public render() {
        const k = this.props.kiroku;
        const tables = [
            <KirokuTable
                key="1"
                name="口腔衛生"
                titles={["歯列や舌など", "備考"]}
                values={[[k.oral.shita_nado, k.oral.comment]]}
            />,
            <KirokuTable
                key="2"
                name="セルフケア"
                titles={["歯磨きの技術", "すすぎ・うがい", "歯磨き実施状況", "自主リハビリの実施状況"]}
                values={[[k.oral.gijutsu, k.oral.susugi, k.oral.hamigaki_jissi, k.oral.riha_jissi]]}
            />,
            <KirokuTable
                key="3"
                name="嚥下能力"
                titles={["食事量と変化", "主食", "副食",
                    "水分", "食事にかかる時間", "咽の有無"]}
                values={[[k.oral.sesshuryo, k.oral.keitai_shushoku, k.oral.keitai_hukushoku,
                k.oral.keitai_suibun, k.oral.shokuji_min, k.oral.nodo_umu]]}
            />

        ]
        return <div>
            <ViewTitle title="口腔" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}

export class Nutrition extends React.Component<Props> {
    displayName = "Nutrition"
    public render() {
        const k = this.props.kiroku;
        const taiju_show = calcTaijuShow(k.vital.taiju, k.vital.taiju_unit)
        const tables = [
            <KirokuTable
                key="1"
                name="基礎項目"
                titles={["体重(" + k.vital.taiju_unit + ")", "身長", "代謝計算", "皮脂厚", "食事の方針"]}
                values={[[taiju_show, k.vital.shincho, k.nutrition.halis,
                    k.nutrition.kansatsu_hisiatsu, k.nutrition.houshin]]}
            />,
            <KirokuTable
                key="2"
                name="摂取"
                titles={["食事摂取量", "ミルク摂取量", "水分摂取(量)", "水分摂取(内容)"]}
                values={[[k.nutrition.sesshu_shokuji, k.nutrition.sesshu_milk,
                k.nutrition.sesshu_suibun_value, k.nutrition.sesshu_suibun_comment]]}
            />,
            <KirokuTable
                key="3"
                name="栄養の投与"
                titles={["内容", "経路", "量",
                    "水分量", "トラブル", "写真"]}
                values={[[
                    k.nutrition.sesshu_eiyou_naiyou, k.nutrition.sesshu_eiyou_keiro, k.nutrition.sesshu_eiyou_value,
                    k.nutrition.sesshu_eiyou_suibun, k.nutrition.sesshu_eiyou_trouble, docImage(k.nutrition.sesshu_eiyou_image)
                ]]}
            />

        ]
        return <div>
            <ViewTitle title="栄養・代謝" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}

export class Dehyd extends React.Component<Props> {
    displayName = "Dehyd"
    public render() {
        const k = this.props.kiroku;
        const d = k.dehydration
        const tables = [
            <KirokuTable
                key="1"
                name="基礎項目"
                titles={["水分摂取(量)", "水分摂取(内容)", "排泄量(尿量)", "排泄量(備考)"]}
                values={[[k.nutrition.sesshu_suibun_value, k.nutrition.sesshu_suibun_comment, d.output_nyou, d.output_comment]]}
            />,
            <KirokuTable
                key="2"
                name=""
                titles={["乾燥状態(口腔内)", "乾燥状態(腋窩)", "薬剤(内容、量、時間、経路)"]}
                values={[[
                    d.kansou_koukuu,
                    d.kansou_ekika,
                    d.yakuzai_tenteki]]}
            />
        ]
        return <div>
            <ViewTitle title="脱水" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}

        </div>
    }
}
export class Excre extends React.Component<Props> {
    displayName = "Excre"
    public render() {
        const k = this.props.kiroku;
        const d = k.excretion
        const tables = [
            <KirokuTable
                key="1"
                name="排便"
                titles={["排便頻度", "最終排便", "便性状", "薬剤（下剤など）"]}
                values={[[d.hinben_hindo, d.haiben_last, d.haiben_keijou, d.haiben_yakuzai]]}
            />,
            <KirokuTable
                key="2"
                name="ストマ"
                titles={["ストマの見た目", "高さ", "幅", "パウチ・アクセサリ"
                    , "交換", "トラブル", "薬剤（トラブルに対する）", "セルフケア（家族）"]}
                values={[[
                    docImage(d.haiben_stoma_image),
                    d.haiben_stoma_height,
                    d.haiben_stoma_width,
                    d.haiben_stoma_accessory,
                    d.haiben_stoma_koukan_check,
                    d.haiben_stoma_trouble,
                    d.haiben_stoma_yakuzai,
                    d.haiben_stoma_selfcare
                ]]}
            />,
            <KirokuTable
                key="3"
                name="排尿"
                titles={["性状、色、混濁", "血尿スケール", "自己導尿", "水分(量)", "水分(備考)", "薬剤"]}
                values={[[
                    d.hainyou_kansatsu_comment,
                    d.hainyou_kansatsu_scale,
                    d.hainyou_jiko_dounyou_shidou,
                    d.hainyou_jiko_dounyou_ml,
                    d.hainyou_jiko_dounyou_comment,
                    d.hainyou_yakuzai
                ]]}
            />,
            <KirokuTable
                key="4"
                name="排尿カテーテル"
                titles={["カテーテル種類", "交換", "Frメモ", "トラブル", "写真"]}
                values={d.hainyou_katetel.map(x => [
                    x.katetel_type,
                    x.koukan_check ? "○:" + x.koukan_comment : "×",
                    x.fr_memo, x.trouble, docImage(x.image)
                ])
                }
            />,
            <KirokuTable
                key="5"
                name="摂取量"
                titles={["食事量", "水分量", "尿量(ml)", "尿量(備考)"]}
                values={[[
                    d.input_shokuji,
                    d.input_suibun,
                    d.input_nyou_ml,
                    d.input_nyou_comment
                ]]}
            />
        ]
        return <div>
            <ViewTitle title="排泄" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}

        </div>
    }
}
export class Physical extends React.Component<Props> {
    displayName = "Physical"
    public render() {
        const k = this.props.kiroku;
        const d = k.physical
        const tables = [
            <KirokuTable
                key="6"
                name=""
                titles={["移動", "移乗",
                    "転倒の有無", "ポジショニングの実施状況", "自主トレーニングの実施状況"
                ]}
                values={[[
                    d.idou, d.ijou,
                    d.tentou_umu,
                    d.positioning_state, d.jishu_training_state
                ]]}
            />
        ]
        return <div>
            <ViewTitle title="身体活動" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}

        </div>
    }
}
export class Sleep extends React.Component<Props> {
    displayName = "Sleep"
    public render() {
        const k = this.props.kiroku;
        const d = k.sleep
        const tables = [
            <KirokuTable
                key="7"
                name=""
                titles={["不眠のパターン", "備考", "本人の言葉", "薬剤"]}
                values={[[
                    d.humin_pattern, d.humin_comment,
                    d.honnin_said, d.yakuzai
                ]]}
            />
        ]
        return <div>
            <ViewTitle title="睡眠" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}

        </div>
    }
}
export class Cognition extends React.Component<Props> {
    displayName = "Cognition"
    public render() {
        const k = this.props.kiroku;
        const d = k.cognition
        const check = (f: boolean) => (f ? "○" : "×")
        return <div>
            <ViewTitle title="認知・意識" />
            <KirokuTable
                key="8"
                name="中核症状"
                titles={["記憶障害", "判断力低下", "見当識障害", "言語障害", "失行", "失認", "その他"]}
                values={[[
                    check(d.chukakuKioku), check(d.chukakuHandan),
                    check(d.chukakuKentou), check(d.chukakuGengo),
                    check(d.chukakuShikkou), check(d.chukakuShitsunin),
                    d.chukaku_other
                ]]}
            />,
            <KirokuTable
                key="9"
                name="周辺症状"
                titles={["せん妄", "抑うつ", "興奮", "徘徊", "睡眠障害", "妄想", "その他"]}
                values={[[
                    check(d.shuhenSenmou), check(d.shuhenYokuutsu),
                    check(d.shuhenKouhun), check(d.shuhenHaikai),
                    check(d.shuhenSuimin), check(d.shuhenMousou),
                    d.shuhen_other
                ]]}
            />,
            <KirokuTable
                key="10"
                name=""
                titles={["症状発生により困っていること", "本人ができること・強み", "興奮意識レベル(JCS)", "けいれん、てんかん"]}
                values={[[
                    d.komari, d.tsuyomi, d.ishiki,
                    d.keiren
                ]]}
            />
        </div>
    }
}
export class Circ extends React.Component<Props> {
    displayName = "Circ"
    public render() {
        const k = this.props.kiroku;
        const d = k.circulation
        const tables = [
            <KirokuTable
                key="1"
                name=""
                titles={["尿量", "in-outについて", "浮腫", "CVP", "NYHA", "薬剤", "セルフケア"]}
                values={[[
                    d.inout_nyou, d.input_comment, d.mukumi,
                    d.shinhuzen_choukou_cvp, d.shinhuzen_choukou_nyha,
                    d.shinhuzen_yakuzai, d.shinhuzen_selfcare
                ]]}
            />
        ]
        return <div>
            <ViewTitle title="循環" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}

        </div>
    }
}

export class Mental extends React.Component<Props> {
    displayName = "Mental"
    public render() {
        const k = this.props.kiroku;
        const d = k.mental
        const tables = [
            <KirokuTable
                key="1"
                name="症状兆候"
                titles={["気分変調", "陽性症状", "陰性症状", "認知機能障害"]}
                values={[[
                    d.choukou_kibun_henchou, d.choukou_yousei,
                    d.choukou_insei, d.choukou_ninchi
                ]]}
            />,
            <KirokuTable
                key="2"
                name=""
                titles={["対処行動", "サイン", "定時薬・頓服の状況"]}
                values={[[
                    d.taisho, d.sign, d.yakuzai_status
                ]]}
            />,
            <KirokuTable
                key="3"
                name=""
                titles={["本人の意欲", "家族の言葉"]}
                values={[[
                    d.honnin_said, d.kazoku_said
                ]]}
            />
        ]
        return <div>
            <ViewTitle title="精神状態" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}

export class Metabo extends React.Component<Props> {
    displayName = "Metabo"
    public render() {
        const k = this.props.kiroku;
        const d = k.metabolism
        const tables = [
            <KirokuTable
                key="1"
                name="血糖コントロール"
                titles={["血糖値", "最終食事時間", "自己管理ノート", "自己注射・セルフケア"]}
                values={[[
                    d.kettou_value, d.kettou_last_shokuji_time,
                    docImage(d.kettou_note_image), d.kettou_self_care
                ]]}
            />,
            <KirokuTable
                key="2"
                name=""
                titles={["検査データ", "検査結果備考", "薬情"]}
                values={[[
                    docImage(d.kensakekka_image),
                    d.kensakekka_comment,
                    docImage(d.yakuzai_shohou_image)
                ]]}
            />,
            <KirokuTable
                key="3"
                name="透析"
                titles={["透析方法", "管理・観察", "写真"]}
                values={[[
                    d.touseki_houhou,
                    d.touseki_kanri,
                    docImage(d.touseki_image)
                ]]}
            />,
            <KirokuTable
                key="4"
                name=""
                titles={["甲状腺"]}
                values={[[d.koujousen_choukou]]}
            />
        ]
        return <div>
            <ViewTitle title="内分泌" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}

        </div>
    }
}

const calcTaijuShow = (taiju: number | null, unit: string) => {
    if (!taiju) return ""
    return taiju * (unit == "g" ? 1000 : 1)
}

export class Glowth extends React.Component<Props> {
    displayName = "Glowth"
    public render() {
        const k = this.props.kiroku;
        const d = k.growth
        const shincho = k.vital.shincho
        const taiju = k.vital.taiju ? k.vital.taiju : 0
        const taiju_show = calcTaijuShow(k.vital.taiju, k.vital.taiju_unit)
        let kaupu = "--"

        if (shincho && taiju) {
            kaupu = (taiju * 1000 / (shincho * shincho) * 10).toFixed(1).toString()
        }
        const tables = [
            <KirokuTable
                key="1"
                name=""
                titles={["体重(" + k.vital.taiju_unit + ")", "身長(cm)", "カウプ指数", "頭囲(cm)", "胸囲(cm)"]}
                values={[[
                    taiju_show, shincho, kaupu,
                    d.toui, d.kyoui
                ]]}
            />,
            <KirokuTable
                key="2"
                name=""
                titles={["粗大運動", "微細運動", "言語", "認知社会", "食事"]}
                values={[[
                    d.hattatsu_sodai, d.hattatsu_bisai,
                    d.hattatsu_gengo, d.hattatsu_ninchi, d.hattatsu_shokuji
                ]]}
            />
        ]
        return <div>
            <ViewTitle title="発達・成長" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}


export class Neuro extends React.Component<Props> {
    displayName = "Neuro"
    public render() {
        const k = this.props.kiroku;
        const d = k.neuro_muscul
        const tables = [
            <KirokuTable
                key="1"
                name=""
                titles={["筋緊張", "痙攣・てんかん"]}
                values={[[d.kin_kinchou, d.keiren]]}
            />,
            <KirokuTable
                key="2"
                name=""
                titles={["拘縮", "対処・処置・薬剤"]}
                values={[[d.koushuku, d.shochi]]}
            />
        ]
        return <div>
            <ViewTitle title="神経・筋・骨格" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}

export class EOL extends React.Component<Props> {
    displayName = "EOL"
    public render() {
        const k = this.props.kiroku;
        const d = k.eol

        let ppsScore: number | null = null
        if (d) {
            let pps: number | null = null
            if (d.pps) {
                const p = d.pps
                if (p <= 20) pps = 4.0
                else if (p <= 50) pps = 2.5
                else pps = 0
            }
            if (pps != null) {
                ppsScore = pps
                if (d.ppi_keikuu == Model.KirokuII.PPIKeikuu.Keikuu2) ppsScore += 1.0
                else if (d.ppi_keikuu == Model.KirokuII.PPIKeikuu.Keikuu3) ppsScore += 2.5
                if (d.ppi_mukumi == "あり") ppsScore += 1.0
                if (d.ppi_kokyu_konnnan == "あり") ppsScore += 3.5
                if (d.ppi_senmou == "あり") ppsScore += 4.0
            }
        }
        const tables = [
            <KirokuTable
                key="1"
                nocheck={true}
                name=""
                titles={["PPS", "スコア", "口腔摂取量"]}
                values={[[d.pps, ppsScore != null ? ppsScore + " 点" : "--", d.ppi_keikuu]]}
            />,
            <KirokuTable
                key="2"
                name=""
                nocheck={true}
                titles={["むくみ", "呼吸困難", "せん妄"]}
                values={[[d.ppi_mukumi, d.ppi_kokyu_konnnan, d.ppi_senmou]]}
            />,
            <KirokuTable
                key="3"
                name=""
                nocheck={true}
                titles={["本人の言葉", "家族の言葉"]}
                values={[[d.honnin_said, d.kazoku_said]]}
            />
        ]
        return <div>
            <ViewTitle title="ACP/EOL" />
            {tables}
        </div>
    }
}
export class Kaigo extends React.Component<Props> {
    displayName = "Kaigo"
    public render() {
        const k = this.props.kiroku;
        const d = k.kaigo
        const tables = [
            <KirokuTable
                key="1"
                name=""
                titles={["養育上の危機・被養育者の状況", "現在の社家族の認知・行動会資源"]}
                values={[[d.kiki, d.kazoku_ninchi]]}
            />,
            <KirokuTable
                key="2"
                name=""
                titles={["現在の社会資源", "社会資源変更の必要性"]}
                values={[[d.shakai_shigen, d.henkou_need]]}
            />
        ]
        return <div>
            <ViewTitle title="介護・養育" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}
export class Lang extends React.Component<Props> {
    displayName = "Lang"
    public render() {
        const k = this.props.kiroku;
        const d = k.lang
        const tables = [
            <KirokuTable
                key="1"
                name=""
                titles={["構音障害", "種類"]}
                values={[[
                    d.kouon_shougai, d.kouon_type
                ]]}
            />,
            <KirokuTable
                key="2"
                name=""
                titles={["失語", "状況"]}
                values={[[d.shitsugo, d.shitsugo_type]]}
            />
        ]
        return <div>
            <ViewTitle title="言語" />
            {areAllEmptyTable(tables) ? "入力なし" : tables}
        </div>
    }
}


/*
export class Basic extends React.Component<Props> {
	displayName="Basic"
    public render() {
        const k = this.props.kiroku;
        const titles =
        const data =

        return <KirokuTable
                name=""
                titles = {titles}
                values = {data}
                />
    }
}
*/