import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    textField: {
        width: "90%"
    },
    input: {
        color: Colors.inputColor,
        padding: "0px",
        "& div": {
            padding: "5px 40px 5px 10px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto",
    }
})

interface Props extends Styles.WithStyles {
    editMode: boolean,
    list: { label: string, key: string, value: boolean }[],
    displayEmpty: boolean,
    setData: (key:string) => void
}

class EditableList2 extends React.Component<Props> { 
	displayName="EditableList2"
    public static defaultProps = {
        displayEmpty: false
    }
    public render() {
        const { classes, list, displayEmpty } = this.props;
        const cur = list.find(x => x.value == true) || list[0]
        if (this.props.editMode==false) {
            return <div key={`edtn_${name}`} className={classes.root}>{cur.label}</div>
        }
        return <div key={`eds_${name}`} className={classes.root}>
            <Select
                value={cur.key}
                autoWidth={true}
                displayEmpty={displayEmpty}
                variant='outlined'
                onChange={(e) => this.props.setData((e.target as any).value)}
                className={classes.input}
            >
                {
                    list.map((t, idx) =>
                        <MenuItem key={`${t.key}_${idx}`} value={t.key}>{t.label}</MenuItem>
                    )
                }
            </Select>
        </div>
    }
}

export default Styles.withStyles(styles)(EditableList2)