import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import { toInjector, Problem } from '../stores';
import { inject, observer } from 'mobx-react';
import * as Model from '../models';
import * as HokanApi from '../api/hokan'
import * as Colors from '../components/Colors'
import * as Icons from '@material-ui/icons';
import SubTitle from '../components/SubTitle'
import Loading from '../components/Loading'
import StandardTable from '../components/StandardTable';
import {
    ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
    Checkbox, List, ListItem, ListItemText, Radio, Grid, Button, TextField
} from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
        margin: "5px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "5px"
    },
    list: {
    },
    itemHeader: {
        background: Colors.primaryColor
    },
    headerText: {
        "& span": {
            color: Colors.white,
            fontSize: "0.9rem"
        }
    },
    item: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        paddingTop: "0px",
        paddingBottom: "0px",
        borderColor: Colors.borderColor,
        '&:hover': {
            backgroundColor: Colors.backColor
        }
    },
    itemText: {
        paddingRight: "0px",
        "& span": {
            fontSize: "0.9rem",
            fontWeight: 700
        },
        "& p": {
        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    problemTitle: {
        fontSize: "large",
        fontWeight: 700
    },
    problemDetail: {

    },
    summaryOpen: {
        backgroundColor: Colors.orangeColor
    },
    expandOpen: {
        border: "solid",
        borderWidth: "2px",
        borderColor: Colors.orangeColor
    }
});

interface Params {
    patient_id: string
    problem_id: string
}

interface Props extends Problem, PageProps<Params>, Styles.WithStyles {
}


interface LocalState {
    input: Model.Assessment.PostAssessment
    problems: Model.Problem[]
    checkedProblem: Model.Assessment.PostAssessment[]
    loading: boolean
    existProblems: Model.Problem[]
    checked: { [index: number]: boolean; }
}

@inject(...toInjector(Problem))
@observer
class CreateProblemPage extends React.Component<Props, LocalState> { 
	displayName="CreateProblemPage"
    constructor(props: Props) {
        super(props)
        const st = new Model.Assessment.PostAssessment()
        st.assessment.problem_id = this.problemId;
        st.assessment.next_assessment_day = st.assessment.assessment_day;
        this.state = {
            input: st,
            problems: [],
            loading: false,
            checkedProblem: [],
            existProblems: [],
            checked: {}
        }
    }

    private createAssessment(problem: Model.Problem) {
        const res = new Model.Assessment.PostAssessment()
        res.assessment.patient_id = this.patientId
        res.assessment.problem_id = problem.id
        res.assessment.modifiers1 = "個人"
        res.assessment.modifiers2 = "顕在"
        res.assessment.priority = 1
        return res
    }

    public componentDidMount = () => {
        this.setState({ loading: true })
        HokanApi.getProblems()
            .then(x => {
                this.setState({ problems: x.data || [], loading: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
        HokanApi.getPatientProblems(this.patientId)
            .then(x => {
                this.setState({ existProblems: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }


    private get patientId() {
        return parseInt(this.props.match.params.patient_id)
    }

    private get problemId() {
        return parseInt(this.props.match.params.problem_id)
    }

    private commit() {
        HokanApi.postAssessmentList(this.patientId, this.state.checkedProblem)
            .then(x => {
                this.props.history.push(`/patients/${this.patientId}#problem`)
            })
            .catch(err => {
                console.log(err)
            })
    }

    private toggleCheck = (ass: Model.Assessment.PostAssessment, id: number) => {
        let checked = ass.symptoms
        if (this.isChecked(ass, id)) {
            checked = checked.filter(x => x != id)
        }
        else {
            checked.push(id);
        }
        console.log(checked)
        ass.symptoms = checked
        this.updateAss()
    }
    private isChecked = (ass: Model.Assessment.PostAssessment, id: number) => {
        return ass.symptoms.findIndex(x => x == id) >= 0
    }

    private updateAss() {
        this.setState({ checkedProblem: this.state.checkedProblem })
    }

    public render() {
        const { classes } = this.props
        const { loading, problems } = this.state
        var categories = Array.from(new Set(problems.map((x) => { return x.category })));

        const mod1 = ["個人", "家族", "コミュニティ"]
        const mod2 = ["顕在", "潜在", "健増"]

        return <div className={classes.root}>
            <SubTitle title="問題の新規登録" />
            {
                loading ? <Loading /> :
                    categories.map((cat, cat_idx) => {
                        let ps = problems.filter(x => { return x.category == cat })
                        return <div>
                            <StandardTable title={cat}>
                                {
                                    ps.map((problem, problem_idx) => {
                                        const ass = this.state.checkedProblem.find(p => p.assessment.problem_id == problem.id)
                                        const selected = ass != null
                                        const isExists = this.state.existProblems.find(x => problem.id == x.id) != undefined
                                        return <ExpansionPanel defaultExpanded={false}
                                            disabled={isExists}
                                            style={{ backgroundColor: selected ? Colors.backColor : "#FEFEFE" }}
                                            className={selected ? classes.expandOpen : undefined}
                                            onChange={(e, open) => {
                                                let p = this.state.checkedProblem
                                                if (open) {
                                                    p.push(this.createAssessment(problem))
                                                }
                                                else {
                                                    p = p.filter(x => x.assessment.problem_id != problem.id)
                                                }
                                                this.setState({ checkedProblem: p })
                                            }}
                                        >
                                            <ExpansionPanelSummary
                                                className={selected ? classes.summaryOpen : undefined}
                                                expandIcon={<Icons.ExpandMore />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div style={{ display: "block" }}>
                                                    <div className={classes.problemTitle}><Checkbox
                                                        checked={selected || isExists}
                                                        color="primary"
                                                    />{`${problem.problem_no}:${problem.name}`}</div>
                                                    <div className={classes.problemDetail}>{`　${problem.detail}`}</div>
                                                </div>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                {
                                                    ass ? <div style={{ display: "block", width: "100%" }}>
                                                        <Grid container={true} spacing={1}>
                                                            <Grid item={true} xs={12} md={4} lg={4}>
                                                                <List className={classes.list}>
                                                                    <ListItem className={classes.itemHeader}>
                                                                        <ListItemText
                                                                            className={classes.headerText}
                                                                            primary={"優先度"}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem key={`pri_high`} button={true}
                                                                        className={classes.item}
                                                                        onClick={(e) => { }} >
                                                                        <Radio color="primary"
                                                                            checked={ass.assessment.priority == 1}
                                                                            onChange={(e) => {
                                                                                ass.assessment.priority = 1
                                                                                this.updateAss()
                                                                            }} />
                                                                        <ListItemText primary={"高"}
                                                                            className={classes.itemText} />
                                                                    </ListItem>
                                                                    <ListItem key={`pri_low`} button={true}
                                                                        className={classes.item}
                                                                        onClick={(e) => {
                                                                            ass.assessment.priority = 0
                                                                            this.updateAss()
                                                                        }} >
                                                                        <Radio color="primary"
                                                                            checked={ass.assessment.priority == 0}
                                                                            onChange={(e) => {
                                                                                ass.assessment.priority = 0
                                                                                this.updateAss()
                                                                            }
                                                                            } />
                                                                        <ListItemText primary={"低"}
                                                                            className={classes.itemText} />
                                                                    </ListItem>
                                                                </List>
                                                                <List className={classes.list}>
                                                                    <ListItem className={classes.itemHeader}>
                                                                        <ListItemText
                                                                            className={classes.headerText}
                                                                            primary={"修飾因子①"}
                                                                        />
                                                                    </ListItem>
                                                                    {
                                                                        mod1.map((m, i) => {
                                                                            return <ListItem key={`mod1_${i}`} button={true}
                                                                                className={classes.item}
                                                                                onClick={(e) => {
                                                                                    ass.assessment.modifiers1 = m
                                                                                    this.updateAss()
                                                                                }} >
                                                                                <Radio color="primary"
                                                                                    checked={ass.assessment.modifiers1 == m}
                                                                                    onChange={(e) => {
                                                                                        ass.assessment.modifiers1 = m
                                                                                        this.updateAss()
                                                                                    }} />
                                                                                <ListItemText primary={m}
                                                                                    className={classes.itemText} />
                                                                            </ListItem>
                                                                        })
                                                                    }
                                                                </List>
                                                                <List className={classes.list}>
                                                                    <ListItem className={classes.itemHeader}>
                                                                        <ListItemText
                                                                            className={classes.headerText}
                                                                            primary={"修飾因子②"}
                                                                        />
                                                                    </ListItem>
                                                                    {
                                                                        mod2.map((m, i) => {
                                                                            return <ListItem key={`mod2_${i}`} button={true}
                                                                                className={classes.item}
                                                                                onClick={(e) => {
                                                                                    ass.assessment.modifiers2 = m
                                                                                    this.updateAss()
                                                                                }} >
                                                                                <Radio color="primary"
                                                                                    checked={ass.assessment.modifiers2 == m}
                                                                                    onChange={(e) => {
                                                                                        ass.assessment.modifiers2 = m
                                                                                        this.updateAss()
                                                                                    }} />
                                                                                <ListItemText primary={m}
                                                                                    className={classes.itemText} />
                                                                            </ListItem>
                                                                        })
                                                                    }
                                                                </List>
                                                            </Grid>
                                                            <Grid item={true} xs={12} md={8} lg={8}>
                                                                <List className={classes.list}>
                                                                    <ListItem className={classes.itemHeader}>
                                                                        <ListItemText
                                                                            className={classes.headerText}
                                                                            primary={"症状・兆候"}
                                                                        />
                                                                    </ListItem>
                                                                    {problem!.symptom.filter(x=>x.detail!="その他").map(symptom => (
                                                                        <ListItem key={symptom.id} button={true}
                                                                            className={classes.item}
                                                                            onClick={(e) => this.toggleCheck(ass, symptom.id)}>
                                                                            <Checkbox
                                                                                checked={this.isChecked(ass, symptom.id)}
                                                                                color="primary"
                                                                            />
                                                                            <ListItemText primary={symptom.detail}
                                                                                className={classes.itemText} />
                                                                        </ListItem>
                                                                    ))}
                                                                    {
                                                                        ass.free_symptoms.map((fs, i) => {
                                                                            return <ListItem key={`fs_${i}`} button={true}
                                                                                className={classes.item}>
                                                                                <Checkbox
                                                                                    checked={true}
                                                                                    color="primary"
                                                                                    onClick={(e) => {
                                                                                        ass.free_symptoms.splice(i)
                                                                                        this.updateAss()
                                                                                    }}
                                                                                />
                                                                                <TextField
                                                                                    id={`fs_text_${i}`}
                                                                                    className={classes.itemTextField}
                                                                                    value={fs}
                                                                                    fullWidth={true}
                                                                                    onChange={(e) => {
                                                                                        ass.free_symptoms[i] = e.target.value
                                                                                        this.updateAss()
                                                                                    }}
                                                                                    margin="normal"
                                                                                />
                                                                            </ListItem>
                                                                        })
                                                                    }
                                                                    <ListItem key="add_other" button={true} className={classes.item}
                                                                        onClick={(e) => {
                                                                            ass.free_symptoms.push("")
                                                                            this.updateAss()
                                                                        }}>
                                                                        <ListItemText primary="＋その他"
                                                                            className={classes.itemText} />
                                                                    </ListItem>
                                                                </List>
                                                            </Grid>
                                                        </Grid>
                                                    </div> : null
                                                }
                                            </ExpansionPanelDetails>

                                        </ExpansionPanel>
                                    })
                                }
                            </StandardTable>
                        </div>
                    })
            }
            {
                /*
                <SubTitle title="確認" />
                this.state.checkedProblem.map(ass => {
                    const p = problems.find(x => x.id == ass.assessment.problem_id)
                    return <div>
                        問題：{p!.problem_no}:{p!.name} / 
                        優先度:{ass.assessment.priority} / 
                        {ass.assessment.modifiers1} / {ass.assessment.modifiers2}<br />
                        症状・兆候：{
                            ass.symptoms.map(sid => {
                                return <span>{p!.symptom.find(s => s.id == sid)!.detail}</span>
                            })
                        }{
                            ass.free_symptoms.map(fs => {
                                return <span>{fs}</span>
                            })
                        }
                    </div>
                })*/
            }
            <div>
                {
                    this.state.checkedProblem.length == 0 ?
                    <p>追加する問題を選択してください</p>
                    : null
                }
            </div>
            <Button variant="outlined" color="default"
                onClick={(e) => {
                    this.props.history.push(`/patients/${this.patientId}#problem`)
                    }}>
                キャンセル
                            </Button>
            {"　"}
            <Button variant="contained" color="primary"
                disabled={this.state.checkedProblem.length == 0}
                onClick={(e) => { this.commit() }}>
                確定
                            </Button>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(CreateProblemPage))