import * as React from 'react';
import * as Colors from './Colors'
import { Table, Grid, Checkbox, TableHead, Tooltip, TableRow, TableCell, TableBody, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import * as Model from '../models'
import DateUtil from '../util/DateUtil'
import { StoreAccessor } from '../util/StoreUtil';
import Col2Table from './Col2Table';
import * as Icons from '@material-ui/icons';
import EditableCheckList from './EditableCheckList';
import StandardTable from '../components/StandardTable'
import EditableList from '../components/EditableList';


interface KirokuTableProps {
    title: string
    contents: { name: string, content: any }[]
    align: "right" | "center" | "left"
    nocheck: boolean
    parent: any
}

class ViewBase extends React.Component<Props, { kiroku: any }> {
    public static defaultProps = {
        parent: null
    }
    constructor(props) {
        super(props)
        this.state = {
            kiroku: this.props.kiroku
        }
    }
}

const groupStyle = {
    margin: "0px",
    marginTop: "-10px"
}

export function getComponentByName(name, x, problemList) {
    if (name == "基本情報") return <Basic kiroku={x} />
    else if (name == "バイタルサイン") return <Vital kiroku={x} />
    else if (name == "皮膚") return <Skin kiroku={x} />
    else if (name == "呼吸") return <Breath kiroku={x} />
    else if (name == "疼痛") return <Pain kiroku={x} />
    else if (name == "口腔") return <Oral kiroku={x} />
    else if (name == "栄養・代謝") return <Nutrition kiroku={x} />
    else if (name == "排泄") return <Excre kiroku={x} />
    else if (name == "睡眠") return <Sleep kiroku={x} />
    else if (name == "認知・意識") return <Cognition kiroku={x} />
    else if (name == "循環") return <Circ kiroku={x} />
    else if (name == "精神状態") return <Mental kiroku={x} />
    else if (name == "内分泌") return <Metabo kiroku={x} />
    else if (name == "発達・成長") return <Glowth kiroku={x} />
    else if (name == "神経・筋・骨格") return <Neuro kiroku={x} />
    else if (name == "ターミナルケア・エンドオブライフケア" || name == "ACP/EOL") return <EOL kiroku={x} />
    else if (name == "介護・養育") return <Kaigo kiroku={x} />
    else if (name == "言語") return <Lang kiroku={x} />
    else if (name == "オマハシステム") return <Omaha kiroku={x} problemList={problemList} />
    else if (name == "その他処置等") return <Other kiroku={x} />
    else if (name == "申し送り事項") return <Moushiokuri kiroku={x} />
    return <div>error</div>
}

function areAllEmptyTable(tables: any) {
    return !tables.find(x => !StoreAccessor.isEmptyValue(x))
}

class KirokuTable extends React.Component<KirokuTableProps> {
    displayName = "KirokuTable"
    public static defaultProps = {
        align: "center",
        nocheck: false
    }
    public render() {
        if (areAllEmptyTable(this.props.contents)) {

        }
        const t = this.props.parent ? this.props.parent.state.activityKirokuHideFlag : {}
        return <Col2Table
            key={this.props.title}
            shrink={true}
            disableRemain={true}
            forceClose={t[this.props.title]}
            onClick={(i, closed) => { 
               t[this.props.title] = closed
               this.props.parent.setState({ activityKirokuHideFlag: t});
            }}
            body={[{
                name: this.props.title,
                content: <Col2Table disableBGColor={false} body={this.props.contents}
                    noPadding={true}/>
            }]}
        />
    }
}

const calcTaijuShow = (taiju: number | null, unit: string) => {
    if (!taiju) return ""
    return taiju * (unit == "g" ? 1000 : 1)
}
const genSimple = (name: string, element: any) => {
    return { name: name, content: element }
}

class ViewTitle extends React.Component<{ title: string }> {
    displayName = "ViewTitle"
    public render() {
        return <div style={{
            border: "1px solid white",
            backgroundColor: Colors.primaryColor,
            color: Colors.white,
            marginBottom: "0px",
            padding: "5px",
            fontWeight: "bold"
        }}>
            {this.props.title}
        </div>
    }
}

interface Props {
    kiroku: Model.KirokuII.KangoKirokuII
    parent: any
}
interface OmahaProps extends Props {
    problemList: Model.Problem[]
}

export class Basic extends ViewBase {

    public get isKaigoHoken() {
        return Model.KirokuII.KangoKirokuII.isKaigoHoken(this.props.kiroku)
    }

    public get isIryoHoken() {
        return Model.KirokuII.KangoKirokuII.isIryoHoken(this.props.kiroku)
    }
    public get isSeishinHoken() {
        return Model.KirokuII.KangoKirokuII.isSeishinHoken(this.props.kiroku)
    }
    public get isJihi() {
        return Model.KirokuII.KangoKirokuII.isJihi(this.props.kiroku)
    }
    displayName = "Basic"
    public render() {
        const k = this.props.kiroku;
        const is_teiki = k.basic && k.basic.is_teiki
        const pat = k.patient
        const ht = (pat && pat.hoken) ? pat.hoken.hoken_type : null

        const sa = new StoreAccessor(this, false, "kiroku")

        const seishinHukusuuList = [
            { key: "none", label: "複数名加算なし" },
            { key: "is_hukusuu_kangoshi_1", label: "看護師等・１回" },
            { key: "is_hukusuu_kangoshi_2", label: "看護師等・２回" },
            { key: "is_hukusuu_kangoshi_3", label: "看護師等・３回以上" },
            { key: "is_hukusuu_jun_1", label: "准看護師・１回" },
            { key: "is_hukusuu_jun_2", label: "准看護師・２回" },
            { key: "is_hukusuu_jun_3", label: "准看護師・３回以上" },
            { key: "is_hukusuu_hojo", label: "看護補助者" },
            { key: "is_hukusuu_ptot_1", label: "作業療法士・１回" },
            { key: "is_hukusuu_ptot_2", label: "作業療法士・２回" },
            { key: "is_hukusuu_ptot_3", label: "作業療法士・３回以上" },
            { key: "is_seishin_hukushi", label: "精神保健福祉士" }
        ]
        let seishinHukusuuCurrent = "none"
        seishinHukusuuList.forEach(s => {
            if (s.key != "none" && k.basic.kasan.seishin[s.key]) {
                seishinHukusuuCurrent = s.key;
            }
        })

        const basicHukusuuList = [
            { key: "none", label: "複数名加算なし" },
            { key: "is_hukusuu_kangoshi", label: "看護師等" },
            { key: "is_hukusuu_jun", label: "准看護師" },
            { key: "is_hukusuu_hojo_ha", label: "護補助者・ハ" },
            { key: "is_hukusuu_hojo_ni_1", label: "看護補助者・ニ・１回" },
            { key: "is_hukusuu_hojo_ni_2", label: "看護補助者・ニ・２回" },
            { key: "is_hukusuu_hojo_ni_3", label: "看護補助者・ニ・３回以上" },
            { key: "is_hukusuu_ptot", label: "理学療法士等" },
        ]
        let basicHukusuuCurrent = "none"
        basicHukusuuList.forEach(s => {
            if (s.key != "none" && k.basic.kasan.basic[s.key]) {
                basicHukusuuCurrent = s.key;
            }
        })


        const contents1 = [{
            name: "", content: <Col2Table noPadding={true} disableBGColor={false} body={[
                {
                    name: "記録の種類", content: <div>
                        {sa.list("kiroku_type", Model.KirokuII.KirokuType)}
                        {
                            this.isJihi ?
                                sa.textC(["basic"], "jihi_comment", "備考")
                                : null
                        }
                    </div>

                },
                { name: "開始時間", content: sa.dateTime("time_start", "time_end") },
                { name: "終了時間", content: sa.dateTime("time_end") },
                {
                    name: "訪問スタッフ", content: sa.user("main_user")
                },
                {
                    name: "資格", content: sa.listRawC(["basic"], "shikaku", Model.KirokuII.ShikakuList)
                },
                {
                    name: "複数名", content: <div className={classes.innerTable} >
                        <Col2Table disableBGColor={true} body={
                            k.basic.doukou_list.map((doukou, idx) => {
                                return {
                                    name: "名前", content: <div>
                                        {sa.userC(["basic", "doukou_list", idx], "user")}
                                        {sa.listC(["basic", "doukou_list", idx], "step", Model.KirokuII.DoukouStep, "inline-flex")}
                                        {"　"}
                                    </div>
                                }
                            })
                        } />
                        {
                            k.basic.doukou_list.length > 0 ?
                                this.isIryoHoken ?
                                    <div style={{ margin: "10px" }}>
                                        複数名加算：
                                    {
                                            this.isSeishinHoken ?
                                                <EditableList
                                                    label=""
                                                    editMode={false}
                                                    name={"seishin"}
                                                    display="inline-flex"
                                                    list={seishinHukusuuList.map(t => { return { key: t.label, value: t.key } })}
                                                    value={seishinHukusuuCurrent}
                                                    setData={(n, v) => {}}
                                                />
                                                :
                                                <EditableList
                                                    label=""
                                                    editMode={false}
                                                    name={"basic"}
                                                    display="inline-flex"
                                                    list={basicHukusuuList.map(t => { return { key: t.label, value: t.key } })}
                                                    value={basicHukusuuCurrent}
                                                    setData={(n, v) => { }}
                                                />
                                        }
                                    </div>
                                    : null
                                : null
                        }
                    </div>
                }
            ]} />
        }]
        const contents2 = [{
            name: "", content: <Col2Table noPadding={true} disableBGColor={false} body={[
                {
                    name: "算定コード", content: this.isKaigoHoken ?
                        sa.serviceCodeC([], "service_code",
                            !is_teiki && ht == Model.Patient.HokenType.Kaigo,
                            !is_teiki && ht == Model.Patient.HokenType.KaigoYobo,
                            false,
                            is_teiki
                        ) :
                        this.isIryoHoken ?
                            <div>
                                {sa.listC(["basic"], "iryo_code", Model.KirokuII.IryoCodeEnum)}
                                {
                                    sa.serviceCodeC([], "service_code",
                                        false, false,
                                        true,
                                        false
                                    )
                                }
                            </div>
                            : <div>-</div>
                },
                { name: "リハ回数", content: sa.numberC(["basic"], "riha_count") },
                {
                    name: "加算", closeDefault: !Model.KirokuII.Kasan.checkedSomeKasan(k.basic.kasan), content: <Grid container={true} spacing={0}>
                        {
                            this.isKaigoHoken ?
                                <Grid key="glx" item={true} xs={12} md={12} lg={12}>
                                    {sa.checkListC(["basic", "kasan"], [
                                        { key: "is_shokai", label: "初回加算" },
                                        { key: "is_taiin", label: "退院時指導加算" },
                                        { key: "is_renkei", label: "連携強化加算" },
                                        { key: "is_terminal_care", label: "ターミナルケア加算" },
                                    ], true, false, true)}
                                </Grid>
                                : this.isIryoHoken ?
                                      <Grid key="gl1" item={true} xs={12} md={12} lg={12}>
                                            {sa.checkListC(["basic", "kasan", "common"], [
                                                { key: "is_taiin_shidou", label: "退院時共同指導加算" },
                                                { key: "is_taiin_shien", label: "退院支援指導加算" },
                                                { key: "is_zaitaku_renkei_shidou", label: "在宅患者連携指導加算" },
                                                { key: "is_zaitaku_kinkyu_kanfa", label: "在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ加算" },
                                                { key: "is_tokubetu_kanri_shidou", label: "特別管理指導加算" },
                                                { key: "is_renkei_kyouka", label: "看護・介護職員連携強化加算" },
                                                { key: "is_terminal_care_1", label: "訪問看護ターミナルケア療養費１" },
                                                { key: "is_terminal_care_2", label: "訪問看護ターミナルケア療養費２" },
                                            ], true, false, true)}
                                            {sa.checkListC(["basic", "kasan"], [
                                                { key: "is_kanwa_care", label: "緩和ケア" },
                                                { key: "is_jokusou_care", label: "褥瘡ケア" },
                                                { key: "is_smart_care", label: "ストーマケア" },
                                            ], true, false, true)}
                                        {
                                        this.isSeishinHoken ?
                                                sa.checkListC(["basic", "kasan", "seishin"], [
                                                    { key: "is_choujikan", label: "長時間" },
                                                    { key: "is_hukusuu_2", label: "精神科複数回訪問加算(２回目)" },
                                                    { key: "is_hukusuu_3", label: "精神科複数回訪問加算(３回目以降)" },
                                                    { key: "is_tokubetsu_chiiki", label: "特別地域訪問看護加算" },
                                                    { key: "is_kinkyu", label: "緊急訪問看護加算" },
                                                    { key: "is_yakan", label: "夜間・早朝訪問看護加算" },
                                                    { key: "is_shinya", label: "深夜訪問看護加算" },
                                                ], true, false, true)
                                                : sa.checkListC(["basic", "kasan", "basic"], [
                                                    { key: "is_choujikan", label: "長時間" },
                                                    { key: "is_hukusuu_2", label: "難病等複数回訪問加算(２回目)" },
                                                    { key: "is_hukusuu_3", label: "難病等複数回訪問加算(３回目以降)" },
                                                    { key: "is_tokubetsu_chiiki", label: "特別地域訪問看護加算" },
                                                    { key: "is_kinkyu", label: "緊急訪問看護加算" },
                                                    { key: "is_nyuyouji", label: "乳幼児加算" },
                                                    { key: "is_yakan", label: "夜間・早朝訪問看護加算" },
                                                    { key: "is_shinya", label: "深夜訪問看護加算" },
                                                ], true, false, true)
                                        }
                                    </Grid>
                                    : null
                        }
                    </Grid>

                }
            ]} />
        }]

        return [
            <Grid item={true} xs={12} md={6} lg={6} key="b1">
            <KirokuTable
                title=""
                    contents={contents1}
                    parent={this.props.parent}
            />
            </Grid>,
            <Grid item={true} xs={12} md={6} lg={6} key="b2">
                <KirokuTable
                    title=""
                    contents={contents2}
                    parent={this.props.parent}
                />
            </Grid>
        ]
    }
}
export class Moushiokuri extends ViewBase {
    displayName = "Moushiokuri"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [{
            name: "", content: < Col2Table disableBGColor={false} body={[
                { name: "内容", content: sa.textC(["deal"], "moushiokuri") },
                { name: "画像", content: sa.docWithPast(["deal"], "moushiokuri_docImage", 8, k.patient.id, k.pastDocumentPool) }
            ]} />
        }]
        return <Grid item={true} style={{
            ...groupStyle,
            flexBasis: "99%"
        }}  xs={12} md={12} lg={12}>
            <KirokuTable
                parent={this.props.parent}
                title="申し送り"
                contents={contents}
            />
        </Grid>
    }
}
export class Other extends ViewBase {
    displayName = "Other"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [{
            name: "", content: < Col2Table disableBGColor={false} body={
                [
                    { name: "内容", content: sa.textC(["deal"], "naiyou") },
                    { name: "画像", content: sa.docWithPast(["deal"], "naiyou_docImage", 8, k.patient.id, k.pastDocumentPool) }
                ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="その他処置"
                contents={contents}
            />
        </Grid>
    }
}
export class Vital extends ViewBase {
    displayName = "Vital"
    public render() {
        const k = this.props.kiroku;
        const taiju_show = calcTaijuShow(k.vital.taiju, k.vital.taiju_unit)
        const v = k.vital.first
        const contents = [
            genSimple("体温(℃)", v.taion),
            genSimple("血圧(収縮)", v.ketsuatsu_max),
            genSimple("血圧(拡張)", v.ketsuatsu_min),
            genSimple("脈拍(回/分)", v.myakuhaku),
            genSimple("不整脈", v.myakuhaku_husei ? "●" : ""),
            genSimple("SpO2(%)", v.spo2),
            genSimple("呼吸回数", v.kokyu_kaisu),
            genSimple("血糖値", v.kettou_value),
            genSimple("体重(" + k.vital.taiju_unit + ")", taiju_show),
            genSimple("備考", v.comment)
        ]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="バイタル"
                contents={contents}
            />
        </Grid>
    }
}
export class Skin extends ViewBase {
    displayName = "Skin"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const bd = [
            { name: "内容", content: sa.textC(["skin"], "comment") },
            { name: "写真", content: sa.docWithPast(["skin"], "docImage", 8, k.patient.id, k.pastDocumentPool) }
        ]
        if (k.skin.designr_docs) {
            k.skin.designr_docs = k.skin.designr_docs.filter(x => x != null)
        }
        if (!k.skin.designr_docs || k.skin.designr_docs.length == 0) {
            k.skin.designr_docs = [null] as any
        }
        if (k.skin.designr_docs[k.skin.designr_docs.length - 1] != null) {
            k.skin.designr_docs.push(null as any)
        }
        k.skin.designr_docs.forEach((d, di) => {
            bd.push({ name: "褥瘡の評価" + (di > 0 ? (di + 1) : ""), content: sa.docWithPast(["skin", "designr_docs"], di, 61, k.patient.id, k.pastDocumentPool) })
        })

        const contents = [
            {
                name: "", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={false} body={bd} />
                </div>
            }
        ]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="皮膚"
                contents={contents}
            />
        </Grid>
    }
}


export class DocImage extends React.Component<{ doc: Model.Document.Document | null }> {
    displayName = "DocImage"
    public render() {
        const image_style = { width: "50px" }
        const doc = this.props.doc
        if (!doc) return <div />
        return <div>
            <div>{doc.comment}</div>
            {doc.image_urls.map((url, urli) => <img key={`img_${urli}`} src={url} style={image_style} />)}
        </div>
    }
}

function docImage(doc: Model.Document.Document | null) {
    if (!doc || (
        (!doc.comment || doc.comment == "") &&
        (!doc.image_urls || doc.image_urls.length == 0)
    )
    ) {
        return null
    }
    return <DocImage doc={doc} />
}

const taiju_elem = (sa) => {
    return {
        name: "体重", content: sa.numberUnitC(["vital"], "taiju", "taiju_unit", [{ name: "kg", factor: 1 }, { name: "g", factor: 1000 }])
    }
}
const shincho_elem = (sa) => {
    return {
        name: "身長(cm)", content: sa.numberC(["vital"], "shincho")
    }
}
const classes = {} as any
export class Breath extends ViewBase {
    displayName = "Breath"
    public render() {
        const k = this.props.kiroku;

        const sa = new StoreAccessor(this, false, "kiroku")
        const check = (f: boolean) => (f ? "○" : "×")
        const taiju_show = calcTaijuShow(k.vital.taiju, k.vital.taiju_unit)
        const v = k.vital.first
        const contents = [
            {
                name: "呼吸音（聴診）", content: <div className={classes.innerTable}>
                    {
                        k.breath.kokyuon_ijou.ijou_list.length == 0 ?
                            <div key="noijou">異常なし</div>
                            :
                            k.breath.kokyuon_ijou.ijou_list.map((ijou, idx) => {
                                return <div key={`ijou_${idx}`} className={classes.innerTable} style={{ marginBottom: "5px" }}>
                                    <Col2Table disableBGColor={true} body={[
                                        { name: "部位", content: sa.listC(["breath", "kokyuon_ijou", "ijou_list", idx], "bui", Model.KirokuII.BreathIjouBui, "inline-flex") },
                                        {
                                            name: "音", content: <div>
                                                {sa.listC(["breath", "kokyuon_ijou", "ijou_list", idx], "sound", Model.KirokuII.BreathSound, "inline-flex")}
                                                {sa.listC(["breath", "kokyuon_ijou", "ijou_list", idx], "sound_type", Model.KirokuII.BreathSoundType, "inline-flex")}
                                            </div>
                                        },
                                        { name: "備考", content: sa.textC(["breath", "kokyuon_ijou", "ijou_list", idx], "comment", "備考", "text", "inline-flex") }
                                    ]} />
                                </div>
                            })
                    }
                </div>
            },
            {
                name: "排痰", content: sa.textC(["breath"], "haitan_care")
            },
            { name: "在宅酸素", content: sa.textC(["breath"], "hot_sanso_touyo_ryou") },
            {
                name: "機器確認", content: sa.checkListC(["breath"], [
                    { key: "hot_kiki_kakunin_filter", label: "フィルタ交換" },
                    { key: "hot_kiki_kakunin_mizu", label: "水交換" },
                    { key: "hot_kiki_kakunin_konsento", label: "コンセント確認" },
                    { key: "hot_kiki_kakunin_yobi_bonbe", label: "予備ボンベ残量" }
                ])
            },
            { name: "気管切開", content: sa.textC(["breath"], "kikan_sekkai") },
            {
                name: "カフ圧チェック", content: sa.checkC(["breath"], "kikan_sekkai_kahu_atsu_check")
            },
            { name: "人工呼吸器", content: sa.docWithPast(["breath"], "jinko_kokyuki_setting_image", 8, k.patient.id, k.pastDocumentPool) },
            {
                name: "回路交換実施", content: sa.checkC(["breath"], "jinko_kokyuki_kairo_koukan_check")
            }
        ]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="呼吸"
                contents={contents}
            />
        </Grid>
    }
}
export class Pain extends ViewBase {
    displayName = "Pain"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                { name: "痛みの種類", content: sa.listC(["pain"], "pain_type", Model.KirokuII.PainType) },
                { name: "痛みのパターン", content: sa.listC(["pain"], "pain_pattern", Model.KirokuII.PainPattern) },
                {
                    name: "主観的評価", content: <div>
                        {sa.listC(["pain"], "shukan_hyouka_type", Model.KirokuII.PainShukanType)}
                        {
                            k.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.NRS ? <div>
                                {sa.numberC(["pain"], "shukan_hyouka_nrs")}
                            </div> : null
                        }
                        {
                            k.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.VAS ? <div>
                                {sa.sliderC(["pain"], "shukan_hyouka_vas")}
                            </div>
                                : null
                        }
                        {
                            k.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.FaceScale ?
                                sa.numberC(["pain"], "shukan_hyouka_face_scale")
                                : null
                        }
                    </div>
                },
                {
                    name: "本人の表現", content: sa.textC(["pain"], "honnin_said",)
                },
                { name: "苦痛の質評価（STAS-J）", content: sa.docWithPast(["pain"], "stasj_doc", 62, k.patient.id, k.pastDocumentPool) },
                {
                    name: "薬剤", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["pain"], "yakuzai") },
                        ]} />
                    </div>
                },
            ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="疼痛"
                contents={contents}
            />
        </Grid>
    }
}
export class Oral extends ViewBase {
    displayName = "Oral"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents =
            [{
                name: "", content: <Col2Table disableBGColor={false} body={[
                    { name: "O-HAT", content: sa.docWithPast(["oral"], "ohat_document", 12, k.patient.id, k.pastDocumentPool) },
                    {
                        name: "口腔衛生", content: sa.textC(["oral"], "eisei")
                    },
                    {
                        name: "セルフケア", content: sa.textC(["oral"], "self_care")
                    },
                    {
                        name: "嚥下能力", content: sa.textC(["oral"], "enge")
                    }
                ]} />
            }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="口腔"
                contents={contents}
            />
        </Grid>
    }
}

export class Nutrition extends ViewBase {
    displayName = "Nutrition"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const taiju_show = calcTaijuShow(k.vital.taiju, k.vital.taiju_unit)
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                { name: "代謝計算", content: sa.textC(["nutrition"], "halis") },
                taiju_elem(sa),
                shincho_elem(sa),

                {
                    name: "摂取", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={false} body={[
                            { name: "", content: sa.textC(["nutrition"], "sesshu") },
                        ]} />
                    </div>
                },
                {
                    name: "薬剤(内容、量、時間、経路)", content: sa.textC(["nutrition"], "yakuzai")
                }
            ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="栄養・代謝"
                contents={contents}
            />
        </Grid>
    }
}

export class Excre extends ViewBase {
    displayName = "Excre"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [
            {
                name: "", content: sa.textC(["excretion"], "haisetsu")
            }
        ]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="消化・排泄"
                contents={contents}
            />
        </Grid>
    }
}
export class Sleep extends ViewBase {
    displayName = "Sleep"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [
            {
                name: "", content: <Col2Table disableBGColor={false} body={[
                    {
                        name: "睡眠", content: sa.textC(["sleep"], "suimin")
                    },
                    { name: "薬剤", content: sa.textC(["sleep"], "yakuzai") }
                ]} />
            }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="睡眠"
                contents={contents}
            />
        </Grid>
    }
}
export class Cognition extends ViewBase {
    displayName = "Cognition"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                {
                    name: "中核症状", content: sa.checkListC(["cognition"], [
                        { key: "chukakuKioku", label: "記憶障害" },
                        { key: "chukakuHandan", label: "判断力低下" },
                        { key: "chukakuKentou", label: "見当識障害" },
                        { key: "chukakuGengo", label: "言語障害" },
                        { key: "chukakuShikkou", label: "失行" },
                        { key: "chukakuShitsunin", label: "失認" }
                    ])
                },
                { name: "中核症状(その他)", content: sa.textC(["cognition"], "chukaku_other") },
                {
                    name: "周辺症状", content: sa.checkListC(["cognition"], [
                        { key: "shuhenSenmou", label: "せん妄" },
                        { key: "shuhenYokuutsu", label: "抑うつ" },
                        { key: "shuhenKouhun", label: "興奮" },
                        { key: "shuhenHaikai", label: "徘徊" },
                        { key: "shuhenSuimin", label: "睡眠障害" },
                        { key: "shuhenMousou", label: "妄想" }
                    ])
                },
                { name: "周辺症状(その他)", content: sa.textC(["cognition"], "shuhen_other") },
                { name: "症状発生により困っていること", content: sa.textC(["cognition"], "komari") },
                { name: "本人ができること・強み", content: sa.textC(["cognition"], "tsuyomi") },
                {
                    name: "意識レベル(JCS)", content: sa.listRawC(["cognition"], "ishiki", [
                        { value: "", key: "未選択" },
                        { value: "0", key: "0：意識清明" },
                        { value: "1", key: "I-1：だいたい清明であるが、今ひとつはっきりしない" },
                        { value: "2", key: "I-2：見当識障害がある（場所や時間、日付が分からない）" },
                        { value: "3", key: "I-3：自分の名前、生年月日が言えない" },
                        { value: "10", key: "II-10：普通の呼びかけで容易に開眼する" },
                        { value: "20", key: "II-20：大きな声または体を揺さぶることにより開眼する" },
                        { value: "30", key: "II-30：痛み刺激を加えつつ呼びかけを繰り返すことにより開眼する" },
                        { value: "100", key: "III-100：痛み刺激に対し、払いのける動作をする" },
                        { value: "200", key: "III-200：痛み刺激に対し、少し手足を動かしたり、顔をしかめたりする" },
                        { value: "300", key: "III-300：痛み刺激に反応しない" },
                    ])
                }
            ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="認知・意識"
                contents={contents}
            />
        </Grid>
    }
}
export class Circ extends ViewBase {
    displayName = "Circ"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        if (!k.circulation.mukumiList)
            k.circulation.mukumiList = []
        const contents = [{
            name: "", content:
                <Col2Table disableBGColor={false} body={[
                    {
                        name: "血圧右", content: <div>
                            収縮：{sa.numberC(["circulation"], "ketsuatsu_r_max")}
                                拡張：{sa.numberC(["circulation"], "ketsuatsu_r_min")}
                        </div >
                    },
                    {
                        name: "血圧左", content: <div>
                            収縮：{sa.numberC(["circulation"], "ketsuatsu_l_max")}
                                拡張：{sa.numberC(["circulation"], "ketsuatsu_l_min")}
                        </div >
                    },
                    taiju_elem(sa),
                    { name: "循環", content: sa.textC(["circulation"], "junkan") },
                    {
                        name: "浮腫", content: <div className={classes.innerTable}>
                            {
                                k.circulation.mukumiList.map((mukumi, mi) => {
                                    return <div key={`mukumi_${mi}`}>
                                        {sa.listC(["circulation", "mukumiList", mi], "mukumi_type", Model.KirokuII.CirculationMukumi, "inline-flex")}
                                        {sa.textC(["circulation", "mukumiList", mi], "comment", "コメント", "text")}
                                    </div>
                                })
                            }
                        </div>
                    },
                    { name: "薬剤(内容、量、時間、経路)", content: sa.textC(["circulation"], "yakuzai") }
                ]
                } />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="循環"
                contents={contents}
            />
        </Grid>
    }
}

export class Mental extends ViewBase {
    displayName = "Mental"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")

        const doc_gaf = k.mental.doc_gaf
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                {
                    name: "GAF", content: <div>
                        {
                            doc_gaf && doc_gaf.id != 0 ?
                                <div>今回：{doc_gaf.content.values.score}</div>
                                : null
                        }
                        {sa.docWithPast(["mental"], "doc_gaf", 55, k.patient.id, k.pastDocumentPool)}
                    </div>
                },
                {
                    name: "食事、水分、薬", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "shokuji_suibun_kusuri") },
                        ]} />
                    </div>
                },
                {
                    name: "排泄、分泌", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "haisetsu_bunpitsu") },
                        ]} />
                    </div>
                },
                {
                    name: "衛生", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "eisei") },
                        ]} />
                    </div>
                },
                {
                    name: "活動と休息", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "katsudou_kyusok") },
                        ]} />
                    </div>
                },
                {
                    name: "対人関係", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "taijin") },
                        ]} />
                    </div>
                },
                {
                    name: "安全や症状", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "anzen") },
                        ]} />
                    </div>
                }
            ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="精神状態"
                contents={contents}
            />
        </Grid>
    }
}

export class Metabo extends ViewBase {
    displayName = "Metabo"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")

        const contents = [{
            name: "", content:
                <Col2Table disableBGColor={false} body={[
                    { name: "血糖値(mg/dl)", content: sa.numberC(["vital", "first"], "kettou_value") },
                    { name: "内容", content: sa.textC(["metabolism"], "kettou_naiyou") },
                    { name: "自己管理ノート", content: sa.docWithPast(["metabolism"], "kettou_note_image", 8, k.patient.id, k.pastDocumentPool) },
                ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="内分泌"
                contents={contents}
            />
        </Grid>
    }
}

export class Glowth extends ViewBase {
    displayName = "Glowth"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const taiju_show = calcTaijuShow(k.vital.taiju, k.vital.taiju_unit)

        const kaupuTooltip = <div className={classes.tooltipRoot}>
            <Tooltip title={<div className={classes.tooltipDiv}>
                <div className={classes.tooltipTitle}>カウプ指数＝(体重g÷身長cmの2乗）X　10</div>
                <div className={classes.tooltipLine}>２２以上; 肥りすぎ</div>
                <div className={classes.tooltipLine}>２２－１９; 以前は（優良）現在は(少し太り気味）</div>
                <div className={classes.tooltipLine}>１９－１５; 正常</div>
                <div className={classes.tooltipLine}>１５－１３; やせ</div>
                <div className={classes.tooltipLine}>１３－１０; 栄養失調</div>
                <div className={classes.tooltipLine}>１０以下; 消耗症</div>
            </div>}><Icons.HelpOutlineOutlined /></Tooltip>
        </div>
        const shincho = sa.getValueC("shincho", ["vital"])
        const taiju = sa.getValueC("taiju", ["vital"])
        const taiju_unit = sa.getValueC("taiju_unit", ["vital"])
        let kaupu = "--"
        if (shincho && taiju) {
            kaupu = (taiju * 1000 / (shincho * shincho) * 10).toFixed(1).toString()
        }
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                taiju_elem(sa),
                shincho_elem(sa),
                { name: <span>カウプ指数{kaupuTooltip}</span>, content: <span>{kaupu}</span> },
                { name: "頭囲(cm)", content: sa.numberC(["growth"], "toui") },
                { name: "胸囲(cm)", content: sa.numberC(["growth"], "kyoui") },
                { name: "粗大運動", content: sa.textC(["growth"], "hattatsu_sodai") },
                { name: "微細運動", content: sa.textC(["growth"], "hattatsu_bisai") },
                { name: "言語", content: sa.textC(["growth"], "hattatsu_gengo") },
                { name: "認知社会", content: sa.textC(["growth"], "hattatsu_ninchi") },
                { name: "食事", content: sa.textC(["growth"], "hattatsu_shokuji") }
            ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="発達・成長"
                contents={contents}
            />
        </Grid>
    }
}


export class Neuro extends ViewBase {
    displayName = "Neuro"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [
            { name: "", content: sa.textC(["neuro_muscul"], "naiyou") },
        ]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="神経・筋・骨格"
                contents={contents}
            />
        </Grid>
    }
}

export class EOL extends ViewBase {
    displayName = "EOL"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        let ppsScore: number | null = null
        if (k.eol) {
            let pps: number | null = null
            if (k.eol.pps) {
                const p = k.eol.pps
                if (p <= 20) pps = 4.0
                else if (p <= 50) pps = 2.5
                else pps = 0
            }
            if (pps != null) {
                ppsScore = pps
                if (k.eol.ppi_keikuu == Model.KirokuII.PPIKeikuu.Keikuu2) ppsScore += 1.0
                else if (k.eol.ppi_keikuu == Model.KirokuII.PPIKeikuu.Keikuu3) ppsScore += 2.5
                if (k.eol.ppi_mukumi == "あり") ppsScore += 1.0
                if (k.eol.ppi_kokyu_konnnan == "あり") ppsScore += 3.5
                if (k.eol.ppi_senmou == "あり") ppsScore += 4.0
            }
        }
        const contents = [
            { name: "本人の言葉", content: sa.textC(["eol"], "honnin_said") },
            { name: "家族の言葉", content: sa.textC(["eol"], "kazoku_said") },
            { name: "アドバンスケアプランニング", content: sa.docWithPast(["eol"], "acl_document", 56, k.patient.id, k.pastDocumentPool) },
            {
                name: "PPS", content: <div>
                    {sa.numberC(["eol"], "pps")}
                    <a href="/images/pps_ppi.png" target="_blank">
                        <Tooltip title={<div className={classes.tooltipDiv}>
                            <img src="/images/pps_ppi.png" width="100%" />
                        </div>}
                        ><Icons.HelpOutlineOutlined /></Tooltip>
                    </a>
                </div>
            },
            {
                name: "経口摂取量", content: <div>
                    {sa.listC(["eol"], "ppi_keikuu", Model.KirokuII.PPIKeikuu)}
                </div>
            },
            { name: "浮腫", content: sa.listC(["eol"], "ppi_mukumi", Model.KirokuII.AriNashi) },
            { name: "安静時呼吸困難", content: sa.listC(["eol"], "ppi_kokyu_konnnan", Model.KirokuII.AriNashi) },
            {
                name: "せん妄", content: <div>
                    {sa.listC(["eol"], "ppi_senmou", Model.KirokuII.AriNashi)}
                </div>
            },
            {
                name: "スコア", content: <div>{ppsScore != null ? ppsScore + " 点" : "--"}</div>
            },
            {
                name: "予想される予後", content: <div>
                    6.5点以上：21日以下（週単位）の可能性が高い<br />
                                            3.5点以下：42日以上（月単位）の可能性が高い
                                        </div>
            }
        ]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="ACP/EOL"
                contents={contents}
            />
        </Grid>
    }
}
export class Kaigo extends ViewBase {
    displayName = "Kaigo"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [{
            name: "", content: < Col2Table disableBGColor={false} body={
                [
                    { name: "養育・介護の状況", content: sa.textC(["kaigo"], "kiki") },
                    { name: "家族の認知・行動", content: sa.textC(["kaigo"], "kazoku_ninchi") },
                    { name: "社会資源", content: sa.textC(["kaigo"], "shakai_shigen") },
                ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="介護・養育"
                contents={contents}
            />
        </Grid>
    }
}
export class Lang extends ViewBase {
    displayName = "Lang"
    public render() {
        const k = this.props.kiroku;
        const sa = new StoreAccessor(this, false, "kiroku")
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                {
                    name: "コミュニケーション", content: sa.textC(["lang"], "communication"),
                }
            ]} />
        }]
        return <Grid item={true} style={groupStyle} xs={12} md={6} lg={6}>
            <KirokuTable
                parent={this.props.parent}
                title="言語"
                contents={contents}
            />
        </Grid>
    }
}
export class Omaha extends React.Component<OmahaProps> {
    public static defaultProps = {
        parent: null
    }
    displayName = "Omaha"
    private catToJp(cat: string) {
        if (cat == "TGC") return "TGC(教育/相談/指導)"
        if (cat == "CM") return "CM(ケアマネジメント)"
        if (cat == "TP") return "TP(直接ケア)"
        if (cat == "S") return "S(観察)"
        return ""
    }
    public render() {
        const k = this.props.kiroku;
        const titles = ["問題"]
        const intvCats = ["TGC", "CM", "TP", "S"]
        const contents = k.omaha.details.map((p, pi) => {
            const problem = this.props.problemList.find(z => z.id == p.problem_id)
            const catFromInterv = {}
            p.intervention.filter(v => v.check).forEach(x => {
                if (x.detail) {
                    if (catFromInterv[x.detail.category]) {
                        catFromInterv[x.detail.category].push(x)
                    }
                    else {
                        catFromInterv[x.detail.category] = [x]
                    }
                }
            })

            return <Grid item={true} xs={12} md={6} lg={6} key={`pr_${pi}`}>
                <div style={{color:Colors.primaryColor}}>{problem ? problem.name : ""}</div>
                <Col2Table disableBGColor={false} body={[
                            {
                                name: "行なった介入",
                                content: <div>
                                    {
                                        intvCats.map((ica, icai) => {
                                            return catFromInterv[ica] ? <div key={icai} style={{ whiteSpace: "pre-line" }}>
                                                {this.catToJp(ica)}<br />
                                                {
                                                    catFromInterv[ica].map(x => {
                                                        return "・" + x.detail.target_category + ":" + x.detail.target + x.comment + "\n"
                                                    })
                                                }
                                            </div>
                                                : null
                                        })
                                    }
                                </div>
                            },
                            {
                                name: "評価",
                                content: <span>{p.comment}</span>
                            }
                        ]} />
            </Grid>
        })

        const t = this.props.parent ? this.props.parent.state.activityKirokuHideFlag : {}
        return <Grid item={true} style={ {
            ...groupStyle,
            flexBasis: "99%"
        }} xs={12} md={12} lg={12}>
            <Col2Table
                key="オマハシステム"
                shrink={true}
                disableRemain={true}
                forceClose={t["オマハシステム"]}
                onClick={(i, closed) => {
                    t["オマハシステム"] = closed
                    this.props.parent.setState({ activityKirokuHideFlag: t });
                }}
                body={[{
                    name: "オマハシステム",
                    content: <Grid container={true} spacing={1}>
                        {contents}
                    </Grid>
                }]}
            />
        </Grid >
    }
}

/*
export class Basic extends ViewBase {
    displayName="Basic"
    public render() {
        const k = this.props.kiroku;
        const titles =
        const data =

        return <KirokuTable
                name=""
                titles = {titles}
                values = {data}
                />
    }
}
*/