import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";
import { Button, Grid, MenuItem, Select, Tooltip } from '@material-ui/core';
import {
  LivingType,
  BedroomType,
  KaigoHokenType,
  ShuHokenType,
  JuHokenType,
  IshiketteiType,
  FuanType,
  HaisetsuType,
  AriNashiType
} from "../../models/Forms/StartA";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 75;
  }

  public createContent(): any {
    const res = new FormModel.StartA.Core();
    res.values.create_date = DateUtil.currentDateStr();
    HokanApi.me()
      .then((x) => {
        res.values.create_by = x.data.name;
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    const patient = doc.patient;
    if (patient == null) return;

    doc.content.values.age = DateUtil.getAge(patient.birthday);
    doc.content.values.gender = patient.value.sexuality;
    doc.content.values.youkaigo = patient.youkaigo;
    doc.content.values.sick_name = patient.medical.sick_name;
    doc.content.values.nichijo_jiritsu = patient.medical.nichijo_jiritsu;
    doc.content.values.ninchi = patient.medical.ninchi_jiritsu;
    doc.content.values.keii = patient.social.keii;
    doc.content.values.seiiku = patient.social.seiiku;
    doc.content.values.keizai = patient.env.keizai;
    doc.content.values.kibou_own = patient.social.kibou_own;
    doc.content.values.kibou_famiry = patient.social.kibou_famiry;
    doc.content.values.kiou_reki =
      patient.medical.kiou_reki === undefined ? "" : patient.medical.kiou_reki;
    doc.content.values.kansen = (
      Model.Patient.PatientMedicalKansen.toStr(patient.medical.kansen_checks) +
      (patient.medical.kansen || "")
    )
      .split("\n")
      .join(", ");
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    // 詳細な情報を得るためにpatientを引き直す
    HokanApi.getPatient(pat.id).then(async (res) => {
      const pat = await Model.Patient.Patient.load(res.data);
      doc.patient = pat;
    });
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const kihonList = [
      { name: "記載者", value: "create_by", type: "text" },
      { name: "身長", value: "height", type: "number", placeholder: "cm" },
      { name: "体重", value: "weight", type: "number", placeholder: "kg" },
      {
        name: "訪問頻度の目安",
        value: "visit_frequency",
        type: "text",
        placeholder: "週○回など",
      }
    ]
    const shouni = [
      { name: "生年月日", value: "date_of_birth", type: "date" },
      {
        name: "胎在週数",
        value: "gestation_week",
        type: "number",
      },
      {
        name: "身長",
        value: "height_on_child",
        type: "number",
        placeholder: "cm",
      },
      {
        name: "体重",
        value: "weight_on_child",
        type: "number",
        placeholder: "kg",
      },
      { name: "アプガースコア", value: "apger_score", type: "number" },
    ];

    const iryoukikiList = [
      { name: "注射・点滴管理", value: "check_iryou_kiki_1" },
      { name: "経管経腸栄養法管理", value: "check_iryou_kiki_2" },
      { name: "留置カテーテル", value: "check_iryou_kiki_3" },
      { name: "創傷・褥瘡処置", value: "check_iryou_kiki_4" },
      { name: "中心静脈カテーテル", value: "check_iryou_kiki_5" },
      { name: "呼吸管理", value: "check_iryou_kiki_6" },
      { name: "疼痛管理", value: "check_iryou_kiki_7" },
      { name: "ストマ", value: "check_iryou_kiki_8" },
    ];

    const seiriList = [
      { name: "疼痛", value: "jintsu" },
      { name: "オピオイド", value: "opioido" },
      { name: "腹部膨満・嘔気・嘔吐", value: "fukubu" },
      { name: "全身倦怠感・眠気", value: "zenshin" },
      { name: "浮腫", value: "mukumi" },
      { name: "不穏・興奮", value: "huon" },
      { name: "せん妄・認知症", value: "senmou" },
      { name: "その他", value: "others" },
    ];

    const genGrid = (x) => {
      return <div style={{ padding: 10 }}>
        {x.map((item) => {
          return (
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={3} md={4} lg={4}>
                {item.name}
              </Grid>
              <Grid item={true} xs={9} md={8} lg={8}>
                {saV.text(item.value, item.placeholder, item.type)}
              </Grid>
            </Grid>
          );
        })}
      </div>
    }

    const elements_t = [
      {
        name: "基本情報",
        content: <div>
          {genGrid(kihonList)}
          <hr />
          ●小児の場合
          {genGrid(shouni)}
        </div>
      },
      {
        name: "環境的領域",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              住環境
              {saV.list(`living_environment`, LivingType)}
            </label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              {
                saC.checkList([
                  { label: "室内段差", key: `check_living_environment_1` },
                  { label: "室内階段", key: `check_living_environment_2` },
                  { label: "専用居室", key: `check_living_environment_3` }
                ], true)
              }
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                寝室
                {saV.list(`bedroom`, BedroomType)}
              </label>
            </div>
            <label style={{ padding: 10 }}>
              その他の内容
              {saV.text(
                `living_environment_others`,
                "",
                "text",
                "block",
                true,
                "50px"
              )}
            </label>
            <div>
              制度
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                  width: "fit-content"
                }}
              >
                {saC.check(`check_kaigo_hoken`)} 介護保険
                {saV.list(`kaigo_hoken`, KaigoHokenType)}
              </label>

              <hr />
              ●医療保険
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                  width: "fit-content"
                }}
              >
                {saC.check(`check_syuhoken`)} 主保険
                {saV.list(`syuhoken`, ShuHokenType)}
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                  width: "fit-content"
                }}
              >
                {saC.check(`check_juhoken`)} 従保険
                {saV.list(`juhoken`, JuHokenType)}
              </label>
              <label
                style={{
                  display: "block",
                  alignItems: "baseline",
                  gap: 10,
                  marginBottom: 10,
                  width: "fit-content"
                }}
              >
                <label
                  style={{
                    width: "fit-content"}
                    }>
                {saC.check(`check_hoken_others`)} その他
                </label>
              </label>
              <div style={{ flex: 1 }}>
                {saV.text(`hoken_others`, "", "text", "block", true, "100px")}
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "心理社会的領域",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              意思決定 {saV.list(`ishikettei`, IshiketteiType)}
            </label>
            <label
              style={{
                paddingBottom: 10,
                display: "block",
              }}
            >
              コミュニケーション
              {saV.text(`communication`, "", "text", "block", true, "100px")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              不安・抑うつ {saV.list(`fuan`, FuanType)}
            </label>
            <label
              style={{
                paddingBottom: 10,
                display: "block",
              }}
            >
              病状の受け止めと今後の生活のイメージ
              {saV.text(`byojo_impression`, "", "text", "block", true, "100px")}
            </label>
            <div
              style={{
                paddingBottom: 10,
              }}
            >
              ●家族等
            </div>
            <div style={{ marginBottom: 10 }}>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                主介護者 キーパーソン 意思決定代理人
                {saV.list(`has_syukaigo`, FuanType)}
              </label>
              {saV.text(`family`, "", "text", "block")}
            </div>
            <label
              style={{
                paddingBottom: 10,
                display: "block",
              }}
            >
              病状の受け止めと今後の生活のイメージ
              {saV.text(
                `byojo_impression2`,
                "",
                "text",
                "block",
                true,
                "100px"
              )}
            </label>
            <div
              style={{
                paddingBottom: 10,
              }}
            >
              ●社会資源・サービス
            </div>
            <label
              style={{
                paddingBottom: 10,
                display: "block",
              }}
            >
              基本情報以外の情報を記載
              {saV.text(`social_notices`, "", "text", "block", true, "100px")}
            </label>
            <label
              style={{
                paddingBottom: 10,
                display: "block",
              }}
            >
              その他
              {saV.text(`social_others`, "", "text", "block", true, "100px")}
            </label>
          </div>
        ),
      },
      {
        name: "生理的領域",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                paddingBottom: 10,
                display: "block",
              }}
            >
              治療
              {saV.text(`chiryou`, "", "text", "block", true, "100px")}
            </label>
            <label
              style={{
                paddingBottom: 10,
                display: "block",
              }}
            >
              医師からの説明・予後告知
              {saV.text(`setsumei`, "", "text", "block", true, "100px")}
            </label>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              ●医療機器・器具・処置
            </div>
            <div>
            {iryoukikiList.map((i) => {
              return (
                <label
                  style={{
                    display: "block",
                    alignItems: "center",
                    gap: 10,
                    marginBottom: 10,
                    width: "fit-content"
                  }}
                >
                  {saC.check(i.value)} {i.name}
                </label>
              );
            })}
            </div>
            <label
              style={{
                display: "block",
                alignItems: "baseline",
                gap: 10,
                marginBottom: 10,
              }}
            >
              {saC.check("check_iryou_kiki_others")} その他
              <div style={{ flex: 1 }}>
                {saV.text(
                  `iryou_kiki_others`,
                  "",
                  "text",
                  "block",
                  true,
                  "100px"
                )}
              </div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              呼吸
              <div style={{ flex: 1 }}>{saV.text("kokyu", "")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              循環・体温
              <div style={{ flex: 1 }}>{saV.text("junkan", "")}</div>
            </label>
            <div
              style={{
                marginBottom: 10,
                fontWeight: "bold",
              }}
            >
              飲食・栄養・水分
            </div>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              経口摂取
              {saV.list(`has_inshoku`, FuanType)}
              <div style={{ flex: 1 }}>{saV.text("inshoku", "")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              食事制限・指示
              {saV.list(`has_shokuji`, FuanType)}
              <div style={{ flex: 1 }}>{saV.text("shokuji", "")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              水分制限・指示
              {saV.list(`has_suibun`, FuanType)}
              <div style={{ flex: 1 }}>{saV.text("suibun", "")}</div>
            </label>
            <div
              style={{
                marginBottom: 10,
                fontWeight: "bold",
              }}
            >
              排便
            </div>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              尿{saV.list(`nyou`, HaisetsuType)}
              <div style={{ flex: 1 }}>{saV.text("nyou_text", "")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              便{saV.list(`ben`, HaisetsuType)}
              <div style={{ flex: 1 }}>{saV.text("ben_text", "")}</div>
            </label>
            {seiriList.map((i) => {
              return (
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    marginBottom: 10,
                  }}
                >
                  {saC.check(`check_${i.value}`)}
                  {i.name}
                  <div style={{ flex: 1 }}>
                    {saV.text(`${i.value}_text`, "")}
                  </div>
                </label>
              );
            })}
          </div>
        ),
      },
      {
        name: "健康関連行動領域",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              1日の過ごし方
              <div style={{ flex: 1 }}>{saV.text("ichinichi_text", "")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              睡眠・休息
              <div style={{ flex: 1 }}>{saV.text("suimin_text", "")}</div>
            </label>
            <div
              style={{
                marginBottom: 5,
                fontWeight: "bold",
              }}
            >
              活動・移動
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              {
                saC.checkList([
                  "歩行自立",
                  "歩行介助",
                  "訪問入浴",
                  "車いす",
                  "ベッド上(移動不可)"
                ].map((name, i) => {
                  return { label: name, key: `check_katsudou_${i + 1}` }
                }), true)
              }
              {
                saC.checkList([
                  { label: "室内段差", key: `check_living_environment_1` },
                  { label: "室内階段", key: `check_living_environment_2` },
                  { label: "専用居室", key: `check_living_environment_3` }
                ], true)
              }
            </div>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              {saV.text("katsudou_text", "")}
            </div>
            <div
              style={{
                marginBottom: 5,
                fontWeight: "bold",
              }}
            >
              清潔
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              {
                saC.checkList([
                  "入浴",
                  "シャワー浴",
                  "訪問入浴",
                  "清拭",
                  "部分浴",
                  "口腔ケア"
                ].map((name, i) => {
                  return { label: name, key: `check_seiketsu_${i + 1}` }
                }), true)
              }
            </div>
            <div style={{marginBottom:"5px", display:"flex"}}>
            義歯：{saV.list(`gishi`, AriNashiType)}
            </div>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", width:"100%" }}>具体的な方法{saV.text("seiketsu_text", "")}</div>
            </label>
            <div>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              趣味等
                <div style={{ display: "flex", width: "100%" }}>{saV.text("shumi_text", "")}</div>
            </label>
            </div>
            <div
              style={{
                marginBottom: 5,
                fontWeight: "bold",
              }}
            >
              薬剤管理
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              {
                saC.checkList([
                  "本人", "家族", "サービス利用", "その他"
                ].map((name, i) => {
                  return { label: name, key: `check_yakuzai_${i + 1}` }
                }), true)
              }
            </div>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              {saV.text("yakuzai_text", "")}
            </div>
            <div
              style={{
                marginBottom: 5,
                fontWeight: "bold",
              }}
            >
              緊急時の対応と連絡
            </div>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              ドクターコールの目安
              <div style={{ flex: 1 }}>{saV.text("docter_call_text", "")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              バックベッド
              <div style={{ flex: 1 }}>{saV.text("backbed_text", "")}</div>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              その他
              <div style={{ flex: 1 }}>
                {saV.text("kenkou_others_text", "")}
              </div>
            </label>
          </div>
        ),
      },
      {
        name: "今後予想される病態",
        content: (
          <div style={{ padding: 10 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                flexWrap: "wrap",
              }}
            >
              {
                saC.checkList([
                  "心不全",
                  "感染",
                  "腹水貯留",
                  "下肢麻痺",
                  "介護問題",
                  "肺炎",
                  "出血",
                  "胸水貯留",
                  "痙攣",
                  "金銭的破綻",
                  "誤嚥",
                  "褥瘡",
                  "嚥下困難",
                  "腎不全",
                  "転倒",
                  "イレウス"
                ].map((name, i) => {
                  return { label: name, key: `check_kongo_${i + 1}` }
                }), true)
              }
            </div>
            <label>
              その他
              {saV.text(
                `kongo_others_text`,
                "",
                "text",
                "block",
                true,
                "100px"
              )}
            </label>
          </div>
        ),
      },
      {
        name: "看護師が考える予後・病状の見通し",
        content: (
          <div style={{ padding: 10 }}>
            {saV.text(`kango_text`, "", "text", "block", true, "100px")}
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
