
export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""

    public list_1: MMTScore[] = []
}

export class MMTScore {
    bui: string = ""
    houkou: string = "-"
    lr: string = "-"
    score: string = "未選択"
}

export enum MMTScoreEnum {
    None="未選択",
    Score0="0:筋の収縮は全く見られない",
    Score1="1:筋の収縮がわずかに確認されるだけで、関節運動は起こらない",
    Score2="2:重力を除去すれば運動域全体にわたって動かせる",
    Score3="3:抵抗を加えなければ重力に抗して運動域全体にわたって動かせる",
    Score4="4:抵抗を加えても運動域全体にわたって動かせる",
    Score5="5:強い抵抗を加えても運動域全体にわたって動かせる"
}

export enum MMTLrEnumm {
    None = "-",
    Left = "左",
    Right = "右"
}

export enum MMTHoukouEnumm {
    None = "-",
    Kukkyoku = "屈曲",
    Shinten = "伸展"
}

export class Checks {
}