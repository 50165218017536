import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../../components/Page'

const styles = () =>  Styles.createStyles({
    accordionContainer: {
        display: "flex",
        flexDirection: "column"
    },
    accordion: {
        color: "#dddddd",
        background: "#ffffff",
        cursor: "pointer",
        height: "38p",
        outline: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px"
    },
    accordionTitle: {
        fontFamily: "\"Open Sans\", sans-serif",
        fontWeight: 600,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    accordionArrow: {
        width: "10px",
        height: "10px",
        border: "2px solid",
        borderColor: "#565656 #565656 transparent transparent",
        transform: "rotate(-45deg)",
        cursor: "pointer",
        transition: "transform 0.3s ease"
    },
    accordionContent: {
        overflow: "auto",
        transition: "max-height 0.3s ease"
    }
})

interface Params {

}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LoaclState {
    setActive: boolean
}

class Accordion extends React.Component<Props, LoaclState> {

    constructor(props: Props) {
        super(props)
        this.state = {
            setActive: false
        }
    }

    toggleAccordion() {
        const { setActive } = this.state
        this.setState({setActive:!setActive})

    }

    accordionHeight(){
        if(this.state.setActive) {
           return { maxHeight: "800px" }
        }
        else {
            return { maxHeight: "0px" }

        }
    } 

    rotate() {
        if(this.state.setActive) {
            return {
                marginTop: "5px"
            }
        }
        else{
            return {
                marginBottom: "10px",
                transform: "rotate(135deg)"
            }
        }
    }

    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.accordionContainer} >
                <div className={classes.accordionContent} style={this.accordionHeight()}>
                    {this.props.children}
                </div>
                <button className={classes.accordion} onClick={ ev => this.toggleAccordion() }>
                    <div className={classes.accordionTitle}>
                        <label className={classes.accordionArrow} style={this.rotate()} />
                    </div>
                </button>
            </div>
        )
    }
}

export default withPageProps(Styles.withStyles(styles)(Accordion))