import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import * as Colors from '../Colors'

const styles = () => Styles.createStyles({
    root: {
       color: Colors.primaryColor
    },
    step: {
        cursor: "arrow"
    },
    stepActive: {
        cursor: "pointer"
    }
});

export interface Step {
    no: number,
    label: string,
    hash: string
}

interface Props extends Styles.WithStyles {
    step: number,
    steps: Step[],
    isFirstPage: boolean,
    clickStep: (hash:string) => void
}

interface LocalState {

}

class CreateStepper extends React.Component<Props, LocalState> { 
	displayName="CreateStepper"
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }

    public render() {
        const { classes, step, steps, isFirstPage } = this.props
        return <div className={classes.root}>
            <Stepper activeStep={step} alternativeLabel={true}>
                {steps.map(st => (
                    <Step key={st.no} className={isFirstPage ? classes.step : classes.stepActive} 
                        onClick={(e)=>{this.props.clickStep(st.hash)}}>
                        <StepLabel>{st.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    }
}

export default Styles.withStyles(styles)(CreateStepper)