import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as Colors from './Colors'
import { DialogTitle, Dialog, withStyles, Button } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OmahaProblem from './OmahaProblem';
import SimpleDialog from './SimpleDialog';

const styles = () => Styles.createStyles({
    root: {
        margin: "0px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    dialog: {
        margin: "0px",
        padding: "1px"
    },
    searchField: {
        maxWidth: "200px"
    }
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    }
}))(TableCell);


interface Props extends Styles.WithStyles<typeof styles> {
    patient_id: number
    problem: Model.Problem
    onSelect: (ass: Model.Assessment.AssessmentSet) => void
    onCancel: () => void
}
interface LocalState {
    dialogElement: JSX.Element
    assList: Model.Assessment.AssessmentSet[]
    openDelete: boolean,
    deleteTargetId: number
}

class KirokuSelectDialog extends React.Component<Props, LocalState> {
    displayName = "KirokuSelectDialog"
    constructor(props: Props) {
        super(props)
        this.state = {
            dialogElement: <div />,
            assList: [],
            openDelete: false,
            deleteTargetId: 0
        }
    }

    public componentDidMount = () => {
        this.loadKiroku();
    }

    private loadKiroku() {
        HokanApi.getPatientAssessmentsLog(this.props.patient_id, this.props.problem.id)
            .then(x => {
                this.setState({ assList: x.data })
            })
            .catch(err => {
                console.log(err)
                this.setState({ assList: [] })
            })
    }

    public close = () => {
        this.props.onCancel();
    }

    private closeDeleteConfirm() {
        this.setState({ openDelete: false })
    }

    private commitDelete(assId: number) {
        this.setState({ openDelete: false })
        HokanApi.deleteAssessment(this.props.patient_id, this.props.problem.id, assId)
            .then(x => {
                this.loadKiroku();
            })
            .catch(err => {
                console.log(err)
                this.setState({ assList: [] })
            })
    }
    private selectKiroku(ass: Model.Assessment.AssessmentSet) {
        this.props.onSelect(ass)
    }

    public render() {
        const { classes } = this.props

        return <Dialog
            className={classes.dialog}
            onClose={(x) => this.close()}
            fullWidth={true}
            maxWidth="xl"
            open={true}
            style={{ padding: "10px" }}>
            <DialogTitle>選択してください</DialogTitle>
            <Button variant="outlined" color="default"
                onClick={(e) => this.close()}>
                戻る
            </Button>
            {
                this.state.assList.map((asset, ki) => {
                    return <Table key={ki} className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell align="center">{asset.assessment.assessment_day}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <OmahaProblem problem={this.props.problem} assessmentSet={asset} />
                            </TableRow>
                            <TableRow style={{
                                border: "solid",
                                borderWidth: "1px",
                                borderColor: Colors.backColor
                            }}>
                                <Button variant="contained" style={{ margin: "10px" }} color="primary"
                                    onClick={(e) => { this.selectKiroku(asset) }}>
                                    選択
                                </Button>
                                {"　"}
                                <Button variant="contained" style={{ margin: "10px" }} color="secondary"
                                    onClick={(e) => {
                                        this.setState({
                                            openDelete: true,
                                            deleteTargetId: asset.assessment.id
                                        })
                                    }}>
                                    削除
                                </Button>
                            </TableRow>
                        </TableBody>
                    </Table>
                })
            }

            <SimpleDialog
                title="削除確認"
                open={this.state.openDelete}
                deleteText="削除"
                hideOk={true}
                hideDelete={false}
                onCancel={() => this.closeDeleteConfirm()}
                onDelete={() => this.commitDelete(this.state.deleteTargetId)}
            >
                削除してよろしいでしょうか？
            </SimpleDialog>

        </Dialog>
    }
}

export default Styles.withStyles(styles)(KirokuSelectDialog)