import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import InputText from '../components/InputText';
import StandardTable from '../components/StandardTable';
import { PageProps, withPageProps } from '../components/Page'
import ButtonOkCancel from '../components/ButtonOkCancel';
import * as HokanApi from '../api/hokan'

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    largeIcon: {
        width: "60%",
        height: "60%",
        color: "white",
        marginTop: "10px",
    },
    container: {
        marginTop: "20px",
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    link: {
        textDecoration: 'none'
    },
    grid: {
        padding: "10px"
    }
})

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}
interface LocalState {
    data: Model.Inquiry,
    sending: boolean,
    result: string
}


class InquiryPage extends React.Component<Props, LocalState> {
    displayName = "InquiryPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            data: new Model.Inquiry(),
            sending: false,
            result: "",
        }
    }

    private setText(target, str) {
        const d = this.state.data as any
        d[target] = str;
        this.setState({ data: d })
    }

    private sendMail() {
        this.setState({ sending: true, result: "送信中" })
        HokanApi.sendInquiry(this.state.data).then(x => {
            this.setState({
                data: new Model.Inquiry(),
                sending: false, result: "送信完了"
            })
        }).catch(err => {
            this.setState({
                sending: false, result: "エラー"
            })
            console.log(err)
        })
    }

    public render() {
        const { classes } = this.props;
        const { data } = this.state;
        return (
            <div className={classes.root}>
                <StandardTable title="お問い合わせ">
                    <Grid container spacing={1} className={classes.grid}>
                        <InputText title="お名前" value={data.name} onChange={(s) => this.setText("name", s)} />
                        <InputText title="法人名" value={data.company_name} onChange={(s) => this.setText("company_name", s)} />
                        <InputText title="連絡先メールアドレス" value={data.contact_mail} onChange={(s) => this.setText("contact_mail", s)} />
                        <InputText rows={5} multiline={true} title="内容" value={data.content} onChange={(s) => this.setText("content", s)} />
                    </Grid>

                    <ButtonOkCancel
                        showBack={false}
                        cancelLabel="戻る" okLabel="送信"
                        onCancel={() => this.props.history.goBack()}
                        onOk={() => this.sendMail()}
                        disabled={this.state.sending} />
                    <div style={{width:"100%", textAlign:"center"}}>{this.state.result}</div>
                </StandardTable>
            </div>
        );
    }
}

export default withPageProps(Styles.withStyles(styles)(InquiryPage))