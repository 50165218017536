import { Patient } from '.';

export class CallFromApi {
    public id:number = 0;
    public patient:Patient.Patient = {} as any;
    public attending_time = "";
    public call_kind:CallKind = {} as any;
    public detail:string = "";
}

export class CallToApi{
    public patient_id: number = 0;
    public attending_time = "";
    public call_kind_id: number = 0;
    public detail: string = "";
}

export class CallKind {
    public id:number = 0;
    public name:string = "";
    public created_at = "";
    public updated_at = "";
}