import { SimpleUser } from './Company';
import { OrganizationTanto, Branch, Company, ServiceCode, Document } from '.';
import * as HokanApi from '../api/hokan'
import moment from 'moment';

export type SexEnum = "Male" | "Female" | "Other";
export class Sex {
    public static get Male(): SexEnum { return "Male" }
    public static get Female(): SexEnum { return "Female" }
    public static get Other(): SexEnum { return "Other" }
    public static get All(): SexEnum[] {
        return [Sex.Male, Sex.Female, Sex.Other]
    }

    public static toString(sex: SexEnum) {
        if (sex == Sex.Male) return "男性"
        if (sex == Sex.Female) return "女性"
        if (sex == Sex.Other) return "その他"
        return ""
    }

    public static toShortString(sex: SexEnum) {
        if (sex == Sex.Male) return "男"
        if (sex == Sex.Female) return "女"
        if (sex == Sex.Other) return "他"
        return ""
    }
}

export enum StatusEnum {
    Active = "利用中",
    Hospital = "入院中",
    Pause = "休止中",
    End = "終了"
}

export enum SexualityEnum {
    None = "",
    Male = "男性",
    Female = "女性",
    Other = "他",
}

export enum MoushikomiStatus {
    None = "受取未",
    Done = "受取済"
}

export enum HokenType {
    None = "未選択",
    Iryo = "医療",
    Kaigo = "介護",
    KaigoYobo = "介護予防",
    Seishin = "精神"
}
export enum YouKaigoType {
    None = "未選択",
    Shien1 = "要支援1",
    Shien2 = "要支援2",
    Kaigo1 = "要介護1",
    Kaigo2 = "要介護2",
    Kaigo3 = "要介護3",
    Kaigo4 = "要介護4",
    Kaigo5 = "要介護5"
}

export enum ZokuseiType {
    None = "未選択",
    Shouni = "小児",
    Seijin = "成人"
}

export enum ShikkanType {
    None = "未選択",
    S1 = "感染症",
    S2 = "新生物（がん）",
    S3 = "血液及び造血器",
    S4 = "免疫機構障害",
    S5 = "内分泌",
    S6 = "栄養・代謝",
    S7 = "精神・行動障害(認知症等）",
    S8 = "精神・行動障害（統合失調症、その他精神）",
    S9 = "神経系(パーキン、アルツハイマー、レビー、難病）",
    S10 = "脳血管系",
    S11 = "眼及び付属器",
    S12 = "耳及び乳様突起",
    S13 = "循環器系",
    S14 = "呼吸器系",
    S15 = "消化器系",
    S16 = "皮膚・皮下組織",
    S17 = "筋骨格系・結合組織",
    S18 = "尿路性器系",
    S19 = "先天奇形・変形及び染色体異常",
    S20 = "その他",
}

export enum SaigaiPriority {
    None = "未選択",
    A = "優先度A：医療機器を使用、介護力の低い利用者",
    B = "優先度B：A以外の医療機器使用利用者",
    C = "優先度C：精神疾患、認知症、独居 介護力の低い利用者",
    D = "優先度D：上記に属さない利用者",
}

export enum ADLScore {
    None = "未選択",
    S0 = "全介助",
    S5 = "部分介助",
    S10 = "自立"
}
export enum IADLScore {
    None = "未選択",
    S0 = "できない",
    S1 = "できる",
}

export enum NichijoJiristuScore {
    None = "未選択",
    Jiritsu = "自立",
    J1 = "J1",
    J2 = "J2",
    A1 = "A1",
    A2 = "A2",
    B1 = "B1",
    B2 = "B2",
    C1 = "C1",
    C2 = "C2"
}

export enum NinchiJiristuScore {
    None = "未選択",
    Jiritsu = "自立",
    I = "I",
    IIa = "IIa",
    IIb = "IIb",
    IIIa = "IIIa",
    IIIb = "IIIb",
    IV = "IV",
    M = "M"
}

export const SaigaiKiguDocImageIdPath = "doc_img_id"
export class Patient {
    public id: number = 0
    public name: string = ""
    public name_kana: string = ""
    public branch: Branch = new Branch()
    public company: Company = new Company()
    public branch_id: number = 0
    public birthday: string = ""
    public youkaigo: string = ""
    public tag: string = ""
    public main_user: SimpleUser = new SimpleUser()
    public sub_user1: SimpleUser = new SimpleUser()
    public sub_user2: SimpleUser = new SimpleUser()
    public main_user_id: number = 0
    public sub_user1_id: number = 0
    public sub_user2_id: number = 0
    public tel_home: string = ""
    public tel_cell: string = ""

    public env: PatientEnv = new PatientEnv()
    public social: PatientSocial = new PatientSocial()
    public value: PatientValue = new PatientValue()
    public medical: PatientMedical = new PatientMedical()
    public hoken: PatientHoken = new PatientHoken()
    public contact: PatientContact = new PatientContact()
    public basic: PatientBasic = new PatientBasic()

    public avatar_url: string = ""
    public avatar?: File = undefined
    public image_house_url: string = ""
    public image_house?: File = undefined

    public ukeire_check: UkeireCheck = new UkeireCheck()
    public shokai_check: ShokaiCheck = new ShokaiCheck()

    public wiseman_id: string | null = null
    public status: string = ""
    public saigai: Saigai = new Saigai()
    public flag_check: FlagCheck = new FlagCheck()
    public service_code: ServiceCode = new ServiceCode()
    public jouhoteikyo_to: number | null = null
    public is_teiki_junkai: boolean = false
    public need_jouhoteikyo: boolean = false
    public need_jikohutank_kanri: boolean = false
    public is_shinki: boolean = false
    public is_canseled: boolean = false

    public is_koureisha_gyakutai: boolean = false

    public doc_temp: PatientDocTemp = new PatientDocTemp()

    public created_at: string = ""
    public updated_at: string = ""
    public ended_at: string | null = null
    public hihu_doc: Document.Document | null = null
    public hihu_docs: Document.Document[] = []
    public saigaiji_taioutaisei_pdf_url: string | null = null
    public shohosen_pdf_urls: string[] = []
    public shohosen_image_urls: string[] = []
    public is_active: boolean = true
    public terms_confirmed: string | null = null
    public terms_disagree: boolean = false
    public terms_sign_url: string | null = null
    public terms_content: any | null = null
    public same_buildings: PatientSimple[] = []
    public is_visible: boolean = true

    public version_list: any[] = []

    public docs: {
        shijisho: Document.Document[] | null,
        tokushiji: Document.Document[] | null,
        gaf: Document.Document[] | null,
        jokuso: Document.Document[] | null,
        hoken_kaigo: Document.Document[] | null,
        hoken_iryo: Document.Document[] | null,
        hoken_gendo: Document.Document[] | null,
        hoken_kouhi: Document.Document[] | null,
        jouho_teikyo: Document.Document[] | null
    } | null = null

    public static getAge(p: Patient) {
        if (p.birthday == null) return "";
        var values = p.birthday.split('-');
        if (values.length < 3) return "";
        var birthday = new Date(parseInt(values[0]), parseInt(values[1]) - 1, parseInt(values[2]));
        var today = new Date();
        var thisYearBirthday =
            new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate());
        var age = today.getFullYear() - birthday.getFullYear();
        return (today < thisYearBirthday) ? age - 1 : age;
    }

    public static getSex(p: Patient) {
        return Sex.toString(p.value.sexuality)
    }
    public static getSexShort(p: Patient) {
        return Sex.toShortString(p.value.sexuality)
    }

    public static async reloadOrgs(pat: Patient) {
        const pool: Promise<any>[] = []
        const reload = (tanto: OrganizationTanto | null) => {
            if (!tanto || tanto.id == 0) return;
            pool.push(HokanApi.reloadTantoRaw(tanto))
        }
        if (pat.basic) {
            reload(pat.basic.shoukai_from)
        }
        if (pat.contact) {
            reload(pat.contact.shujii)
            reload(pat.contact.shujii_2)
            reload(pat.contact.kinkyu)
            reload(pat.contact.keamane)
            reload(pat.contact.soudan)
            reload(pat.contact.plan)
            if (pat.contact.other) {
                pat.contact.other = pat.contact.other.filter(x => x)
                pat.contact.other.forEach(x => reload(x))
            }
        }
        try {
            await Promise.all(pool)
        }
        catch (err) {
            console.log(err)
        }
        return pat
    }

    static load(apiRes: any, withDocument: boolean = true, documentCallback: (pat: Patient) => void = () => { }) {
        let res = new Patient();
        res.id = apiRes.id;
        res.branch = apiRes.branch
        if (res.branch) res.branch_id = res.branch.id
        res.company = apiRes.company
        res.name = apiRes.name
        res.name_kana = apiRes.name_kana
        res.birthday = apiRes.birthday
        res.ended_at = apiRes.ended_at
        res.youkaigo = apiRes.youkaigo
        res.tag = apiRes.tag
        const tc = apiRes.terms_confirmed ? moment(apiRes.terms_confirmed) : null
        res.terms_confirmed = tc ? tc.format("YYYY-MM-DDTHH:mm") : null
        res.terms_content = apiRes.terms_content ? JSON.parse(apiRes.terms_content) : { address: "" }
        res.main_user = apiRes.main_user
        res.sub_user1 = apiRes.sub_user1
        res.sub_user2 = apiRes.sub_user2
        if (res.main_user) res.main_user_id = res.main_user.id
        if (res.sub_user1) res.sub_user1_id = res.sub_user1.id
        if (res.sub_user2) res.sub_user2_id = res.sub_user2.id
        res.tel_home = apiRes.tel_home
        res.tel_cell = apiRes.tel_cell
        res.hihu_docs = apiRes.hihu_docs || []
        res.saigaiji_taioutaisei_pdf_url = apiRes.saigaiji_taioutaisei_pdf_url
        res.shohosen_pdf_urls = apiRes.shohosen_pdf_urls
        res.shohosen_image_urls = apiRes.shohosen_image_urls
        if (res.hihu_docs.length > 0) {
            res.hihu_doc = res.hihu_docs[0]
            Document.Document.Init(res.hihu_doc)
        }
        else res.hihu_doc = null

        if (apiRes.docs) {
            res.docs = apiRes.docs
            const names = ["shijisho", "tokushiji", "gaf", "jokuso", "hoken_kaigo", "hoken_iryo", "hoken_gendo", "hoken_kouhi", "jouho_teikyo"]
            names.forEach(name => {
                if (res.docs) {
                    if (res.docs[name] && res.docs[name].length == 1) {
                        res.docs[name][0].content = JSON.parse(res.docs[name][0].content as any)
                    }
                    else {
                        res.docs[name] = null
                    }
                }
            })
        }
        else {
            res.docs = {} as any
        }

        res.version_list = apiRes.version_list
        res.same_buildings = apiRes.same_buildings ? apiRes.same_buildings : []
        res.is_visible = apiRes.is_visible

        const loader = (name, defaultObj) => {
            return apiRes[name] ? JSON.parse(apiRes[name]) : defaultObj
        }
        res.env = loader("env", new PatientEnv())
        res.social = loader("social", new PatientSocial())
        if (!res.social.famiry) res.social.famiry = []
        if (!res.social.social_calendar) {
            res.social.social_calendar = new SocialCalendar()
            res.social.social_calendar.comment = res.medical.healthcare_kanri
            res.medical.healthcare_kanri = ""
        }
        res.value = loader("value", new Patient())
        res.medical = loader("medical", new PatientMedical())
        if (!res.medical.adl) res.medical.adl = new ADL();
        if (!res.medical.iadl) res.medical.iadl = new IADL();

        if (!res.medical.arergy_checks) res.medical.arergy_checks = new PatientMedicalArergy()
        if (!res.medical.kansen_checks) res.medical.kansen_checks = new PatientMedicalKansen()
        if (!res.medical.yobousesshu_checks) res.medical.yobousesshu_checks = new PatientMedicalYobouSesshu()

        res.medical.kiou_reki = (res.medical.ourai_reki || "") + res.medical.kiou_reki + (res.medical.nyuin_reki || "")
        res.medical.ourai_reki = res.medical.nyuin_reki = ""
        res.hoken = loader("hoken", new PatientHoken())
        res.contact = loader("contact", new PatientContact())
        if (res.contact.other == null) res.contact.other = []
        res.basic = loader("basic", new PatientBasic())

        if (!res.basic.free_note_ids) res.basic.free_note_ids = []
        if (!res.basic.kasan) res.basic.kasan = new PatientKasan()

        res.avatar_url = apiRes.avatar_url
        res.avatar = apiRes.avatar
        res.image_house_url = apiRes.image_house_url
        res.image_house = apiRes.image_house

        res.ukeire_check = loader("ukeire_check", new UkeireCheck())
        res.shokai_check = loader("shokai_check", new ShokaiCheck())

        res.wiseman_id = apiRes.wiseman_id
        res.status = apiRes.status
        res.saigai = loader("saigai", new Saigai())
        if (!res.saigai.kigus) res.saigai.kigus = new UsingIryoKigu()
        res.flag_check = loader("flag_check", new FlagCheck())
        res.service_code = apiRes.service_code
        res.jouhoteikyo_to = apiRes.jouhoteikyo_to
        res.is_teiki_junkai = apiRes.is_teiki_junkai
        res.need_jouhoteikyo = apiRes.need_jouhouteikyo
        res.need_jikohutank_kanri = apiRes.need_jikohutank_kanri
        res.is_shinki = apiRes.is_shinki
        res.is_canseled = apiRes.is_canseled
        res.created_at = apiRes.created_at
        res.updated_at = apiRes.updated_at

        res.terms_confirmed = apiRes.terms_confirmed
        res.terms_disagree = apiRes.terms_disagree
        res.terms_sign_url = apiRes.terms_sign_url
        const loadDoc = async (id: number | null, name: string) => {
            if (id == null || withDocument == false) return null;
            res.doc_temp[name] = { id: id }
            const x = await HokanApi.getDocument(id)
            res.doc_temp[name] = x.data
            return x.data
        }
        const loadDocLatest = async (typeId: number, obj: any, name: string, id_obj: any, id_obj_param_name: string) => {
            const patId = res.id;
            if (patId == null || withDocument == false) return null;
            const x = await HokanApi.getDocumentLatest(patId, typeId)
            if (id_obj) {
                id_obj[id_obj_param_name] = x.data.id
            }
            if (obj) {
                obj[name] = x.data
            }
            return x.data
        }
        res.doc_temp = new PatientDocTemp()
        Promise.all([
            loadDocLatest(2, res.doc_temp, "doc_kaigo", res.hoken, "doc_kaigo_id"),
            loadDocLatest(3, res.doc_temp, "doc_kaigohutan", res.hoken, "doc_kaigohutan_id"),
            loadDocLatest(4, res.doc_temp, "doc_iryo", res.hoken, "doc_iryo_id"),
            loadDocLatest(5, res.doc_temp, "doc_gendogaku", res.hoken, "doc_gendogaku_id"),
            loadDocLatest(6, res.doc_temp, "doc_kouhi", res.hoken, "doc_kouhi_id"),
            loadDocLatest(59, res.doc_temp, "doc_care_plan", res.contact, "doc_care_plan_id"),
            loadDoc(res.env.doc_img_house_id, "doc_img_house"),
            loadDoc(res.env.doc_img_madori_id, "doc_img_madori"),
            loadDoc(res.env.doc_img_tokucho_id, "doc_img_tokucho"),
            loadDoc(res.env.doc_img_chusha_id, "doc_img_chusha"),
        ]).then(x => {
            documentCallback(res)
        })

        /*
        if (res.saigai.kigu) {
            res.saigai.kigu.forEach(k => {
                const id = k.detail[SaigaiKiguDocImageIdPath]
                if (id == null || withDocument == false) return null;
                res.doc_temp.doc_img_saigai_kigu[k.type] = { id: id } as any
                return HokanApi.getDocument(id).then(x => {
                    res.doc_temp.doc_img_saigai_kigu[k.type] = x.data
                })
            })
        }*/
        if (res.basic && res.basic.free_note_ids) {
            res.basic.free_note_ids.forEach(id => {
                if (!id || withDocument == false) return;
                HokanApi.getDocument(id).then(x => {
                    res.doc_temp.doc_img_free_note.push(x.data)
                })
            })
        }

        if (withDocument) {
            Patient.reloadOrgs(res)
        }

        return res
    }
}

export class SocialCalendar {
    public monday: string = ""
    public tuesday: string = ""
    public wednesday: string = ""
    public thursday: string = ""
    public friday: string = ""
    public saturday: string = ""
    public sunday: string = ""
    public comment: string = ""
}

export class PatientPost {
    public id: number = 0
    public name: string = ""
    public name_kana: string = ""
    public branch_id: number = 0
    public birthday: string = ""
    public youkaigo: string = ""
    public tag: string = ""
    public main_user_id: number | null = null
    public sub_user1_id: number | null = null
    public sub_user2_id: number | null = null
    public tel_home: string = ""
    public tel_cell: string = ""

    public env: string = "{}"
    public social: string = "{}"
    public value: string = "{}"
    public medical: string = "{}"
    public hoken: string = "{}"
    public contact: string = "{}"
    public basic: string = "{}"

    public avatar_url: string = ""
    public avatar?: File = undefined
    public image_house_url: string = ""
    public image_house?: File = undefined

    public ukeire_check: string = "{}"
    public shokai_check: string = "{}"
    public ended_at: string | null = null

    public wiseman_id: string | null = null
    public status: string = ""
    public saigai: string = "{}"
    public flag_check: string = "{}"
    public service_code_id: number | null = null
    public jouhoteikyo_to: number | null = null
    public is_teiki_junkai: boolean = false
    public need_jouhoteikyo: boolean = false
    public need_jikohutank_kanri: boolean = false
    public is_shinki: boolean = false
    public is_canseled: boolean = false
    public is_active: boolean = true
    public is_visible: boolean = true

    public is_gyakutai_bouhi = false;

    public same_buildings: SameBuilding[] = []

    public constructor(src: Patient) {
        this.id = src.id
        this.name = src.name
        this.name_kana = src.name_kana
        this.birthday = src.birthday
        this.branch_id = src.branch.id
        this.youkaigo = src.youkaigo
        this.ended_at = src.ended_at
        this.tag = src.tag
        this.main_user_id = src.main_user && src.main_user.id > 0 ? src.main_user.id : null
        this.sub_user1_id = src.sub_user1 && src.sub_user1.id > 0 ? src.sub_user1.id : null
        this.sub_user2_id = src.sub_user2 && src.sub_user2.id > 0 ? src.sub_user2.id : null
        this.tel_home = src.tel_home
        this.tel_cell = src.tel_cell
        this.service_code_id = (src.service_code && src.service_code.id) ? src.service_code.id : null;
        this.is_active = src.is_active
        this.is_visible = src.is_visible
        this.same_buildings = src.same_buildings ? src.same_buildings.map(x => {
            const res = new SameBuilding()
            res.patient1_id = src.id
            res.patient1_name = src.name
            res.patient2_id = x.id
            res.patient2_name = x.name
            return res;
        }) : []

        const getDocId = (doc) => {
            if (doc) return doc.id
            return null
        }
        src.env.doc_img_house_id = getDocId(src.doc_temp.doc_img_house)
        src.env.doc_img_madori_id = getDocId(src.doc_temp.doc_img_madori)
        src.env.doc_img_tokucho_id = getDocId(src.doc_temp.doc_img_tokucho)
        src.env.doc_img_chusha_id = getDocId(src.doc_temp.doc_img_chusha)
        this.env = JSON.stringify(src.env)

        this.social = JSON.stringify(src.social)
        this.value = JSON.stringify(src.value)
        this.medical = JSON.stringify(src.medical)


        src.hoken.doc_kaigo_id = getDocId(src.doc_temp.doc_kaigo)
        src.hoken.doc_kaigohutan_id = getDocId(src.doc_temp.doc_kaigohutan)
        src.hoken.doc_iryo_id = getDocId(src.doc_temp.doc_iryo)
        src.hoken.doc_gendogaku_id = getDocId(src.doc_temp.doc_gendogaku)
        src.hoken.doc_kouhi_id = getDocId(src.doc_temp.doc_kouhi)

        this.hoken = JSON.stringify(src.hoken)

        src.contact.doc_care_plan_id = getDocId(src.doc_temp.doc_care_plan)
        this.contact = JSON.stringify(src.contact)
        src.basic.free_note_ids = src.doc_temp.doc_img_free_note.filter(x => x).map(x => x.id)
        if (src.basic.zokusei == ZokuseiType.None) src.basic.zokusei = null as any
        this.basic = JSON.stringify(src.basic)

        this.avatar_url = src.avatar_url
        this.avatar = src.avatar
        this.image_house_url = src.image_house_url
        this.image_house = src.image_house

        this.ukeire_check = JSON.stringify(src.ukeire_check)
        this.shokai_check = JSON.stringify(src.shokai_check)

        this.wiseman_id = src.wiseman_id
        this.status = src.status
        /*
        if (src.saigai && src.saigai.kigu) {
            src.saigai.kigu.forEach(x => {
                const k = src.doc_temp.doc_img_saigai_kigu[x.type]
                x.detail[SaigaiKiguDocImageIdPath] = k ? k.id : null
            })
        }*/
        this.saigai = JSON.stringify(src.saigai)
        this.flag_check = JSON.stringify(src.flag_check)
        this.jouhoteikyo_to = src.jouhoteikyo_to
        this.is_teiki_junkai = src.is_teiki_junkai
        this.need_jouhoteikyo = src.need_jouhoteikyo
        this.need_jikohutank_kanri = src.need_jikohutank_kanri
        this.is_shinki = src.is_shinki
        this.is_canseled = src.is_canseled

    }
}
export class PatientBasic {
    public end_reason: EndReason = EndReason.None
    public end_comment: string = ""
    public zokusei: string = ZokuseiType.Seijin
    public shikkan_type: string = ShikkanType.None
    public shoukai_from: OrganizationTanto | null = null
    public team_id: string | null = null
    public free_note_ids: number[] = []
    public other_system_url: string = ""
    public kasan: PatientKasan = new PatientKasan()
    public is_hiwari: boolean = false
    public jimu_tokki: string = ""
    public zaitai_shuusuu: string = ""
    public memo: string = ""
}
export class PatientHoken {
    public hoken_type: string = HokenType.None
    public tokusiji_from: string = ""
    public tokusiji_to: string = ""
    public kouhi_check: boolean = false
    public doc_kaigo_id: number | null = null
    public doc_kaigohutan_id: number | null = null
    public doc_iryo_id: number | null = null
    public doc_gendogaku_id: number | null = null
    public doc_kouhi_id: number | null = null

    public kaigo_hihoban: string = ""
    public kaigo_hokenbangou: string = ""
    public ryouyou_kubun: string = ""
    public ryouyou_bango: string = ""
    public ryouyou_kigou: string = ""
    public ryouyou_edaban: string = ""
}

export class PatientContact {
    public shujii: OrganizationTanto | null = null
    public shujii_2: OrganizationTanto | null = null
    public kinkyu: OrganizationTanto | null = null
    public keamane: OrganizationTanto | null = null
    public soudan: OrganizationTanto | null = null
    public other: OrganizationTanto[] = []
    public plan: OrganizationTanto | null = null
    public doc_care_plan_id: number | null = null
}

export class PatientDocTemp {
    public doc_kaigo: Document.Document | null = null
    public doc_kaigohutan: Document.Document | null = null
    public doc_iryo: Document.Document | null = null
    public doc_gendogaku: Document.Document | null = null
    public doc_kouhi: Document.Document | null = null
    public doc_img_house: Document.Document | null = null
    public doc_img_madori: Document.Document | null = null
    public doc_img_tokucho: Document.Document | null = null
    public doc_img_chusha: Document.Document | null = null
    public doc_img_saigai_kigu: { [index: string]: Document.Document } = {}
    public doc_img_free_note: Document.Document[] = []
    public doc_care_plan: Document.Document | null = null
}

export class PatientEnv {
    public address_zip: string = ""
    public address1: string = ""
    public address2: string = ""
    public address3: string = ""
    public eisei: string = ""
    public keizai: string = ""
    public kinrin: string = ""
    public doc_img_house_id: number | null = null
    public doc_img_madori_id: number | null = null
    public doc_img_tokucho_id: number | null = null
    public doc_img_chusha_id: number | null = null
    public doc_kouhi: Document.Document | null = null
    public tel_cell_comment: string = ""
    public tel_home_comment: string = ""
    public address_comment: string = ""
}
export class PatientSocial {
    public seiiku: string = ""
    public keii: string = ""
    public kibou_own: string = ""
    public kibou_famiry: string = ""
    public kinkyu: string = ""
    public connect_work: string = ""
    public connect_resource: string = ""
    public connect_community: string = ""
    public doukyo: string = ""
    public kiki_family: string = ""
    public family_keikaku: string = ""
    public is_hitori: boolean = false
    public is_kourei: boolean = false
    public famiry: PatientFamiry[] = []


    public social_calendar: SocialCalendar = new SocialCalendar()
}
export class PatientFamiry {
    public name: string = ""
    public relation: string = ""
    public address: string = ""
    public tel: string = ""
    public mail: string = ""
    public job: string = ""
    public good_day: string = ""
}
export class PatientValue {
    public role_henka: string = ""
    public sexuality: SexEnum = "Other"
    public religion: string = ""
}
export class PatientMedical {
    public sick_name: string = ""
    public sick_icd_main_id: number = 0
    public sick_icd_sub1_id: number = 0
    public sick_icd_sub2_id: number = 0
    public ourai_reki: string = ""
    public kiou_reki: string = ""
    public nyuin_reki: string = ""
    public arergy: string = ""
    public arergy_checks: PatientMedicalArergy = new PatientMedicalArergy()
    public kansen: string = ""
    public kansen_checks: PatientMedicalKansen = new PatientMedicalKansen()
    public yobousesshu: string = ""
    public yobousesshu_checks: PatientMedicalYobouSesshu = new PatientMedicalYobouSesshu()
    public mental: string = ""
    public physical_kokkaku: string = ""
    public physical_suimin: string = ""
    public eiyou_shokuji: string = ""
    public eiyou_suibun: string = ""
    public haisetsu_chou: string = ""
    public haisetsu_hainyou: string = ""
    public seiketsu_koukuu_eisei: string = ""
    public seiketsu_nyuuyoku: string = ""
    public seiketsu_koui: string = ""
    public ishisotsu_choukaku: string = ""
    public ishisotsu_shikaku: string = ""
    public ishisotsu_kaiwa: string = ""
    public ishisotsu_ninchi: string = ""
    public ishisotsu_ishiki: string = ""
    public ishisotsu_choukaku_check: boolean = false
    public ishisotsu_shikaku_check: boolean = false
    public ishisotsu_kaiwa_check: boolean = false
    public ishisotsu_ninchi_check: boolean = false
    public ishisotsu_ishiki_check: boolean = false
    public hihu: string = ""
    public kokyu: string = ""
    public junkan: string = ""
    public kansen_kanri: string = ""
    public yakubutsu_shikouhin: string = ""
    public healthcare_kanri: string = ""
    public yakuzai_kanri: string = ""
    public nichijo_jiritsu: string = ""
    public ninchi_jiritsu: string = ""
    public adl: ADL = new ADL()
    public iadl: IADL = new IADL()
}

export class PatientMedicalArergy {
    is_ebi: boolean = false
    is_kani: boolean = false
    is_komugi: boolean = false
    is_soba: boolean = false
    is_tamago: boolean = false
    is_nyuu: boolean = false
    is_rakkasei: boolean = false
    public is_ebi_comment: string = ""
    public is_kani_comment: string = ""
    public is_komugi_comment: string = ""
    public is_soba_comment: string = ""
    public is_tamago_comment: string = ""
    public is_nyuu_comment: string = ""
    public is_rakkasei_comment: string = ""

    public static toStr(src: PatientMedicalArergy) {
        if (!src) return ""
        let res = ""
        if (src.is_ebi) res += "えび " + src.is_ebi_comment + "\n"
        if (src.is_kani) res += "かに " + src.is_ebi_comment + "\n"
        if (src.is_komugi) res += "小麦 " + src.is_ebi_comment + "\n"
        if (src.is_soba) res += "そば " + src.is_ebi_comment + "\n"
        if (src.is_tamago) res += "卵 " + src.is_ebi_comment + "\n"
        if (src.is_nyuu) res += "乳 " + src.is_ebi_comment + "\n"
        if (src.is_rakkasei) res += "落花生 " + src.is_ebi_comment + "\n"
        return res
    }
}
export class PatientMedicalKansen {
    is_mrsa: boolean = false
    is_mdrp: boolean = false
    is_vre: boolean = false
    is_esbl: boolean = false
    is_mbl: boolean = false
    is_hiv: boolean = false
    is_hbs: boolean = false
    is_hcv: boolean = false
    is_rpr: boolean = false
    is_tpha: boolean = false
    public is_mrsa_comment: string = ""
    public is_mdrp_comment: string = ""
    public is_vre_comment: string = ""
    public is_esbl_comment: string = ""
    public is_mbl_comment: string = ""
    public is_hiv_comment: string = ""
    public is_hbs_comment: string = ""
    public is_hcv_comment: string = ""
    public is_rpr_comment: string = ""
    public is_tpha_comment: string = ""
    public static toStr(src: PatientMedicalKansen) {
        if (!src) return ""
        let res = ""
        if (src.is_mrsa) res += "MRSA " + +src.is_mrsa_comment + "\n"
        if (src.is_mdrp) res += "MDRP " + +src.is_mdrp_comment + "\n"
        if (src.is_vre) res += "VRE " + src.is_vre_comment + "\n"
        if (src.is_esbl) res += "ESBL産生菌 " + src.is_esbl_comment + "\n"
        if (src.is_mbl) res += "MBL産生菌 " + src.is_mbl_comment + "\n"
        if (src.is_hiv) res += "HIV抗体 " + src.is_hiv_comment + "\n"
        if (src.is_hbs) res += "HBs抗原 " + src.is_hbs_comment + "\n"
        if (src.is_hcv) res += "HCV抗体 " + src.is_hcv_comment + "\n"
        if (src.is_rpr) res += "RPR " + src.is_rpr_comment + "\n"
        if (src.is_tpha) res += "TPHA " + src.is_tpha_comment + "\n"
        return res
    }
}
export class PatientMedicalYobouSesshu {
    is_hib: boolean = false
    is_shouni_haien: boolean = false
    is_b_kanen: boolean = false
    is_rota: boolean = false
    is_yonshu: boolean = false
    is_bcg: boolean = false
    is_mr: boolean = false
    is_suitou: boolean = false
    is_nouen: boolean = false
    is_hpv: boolean = false
    is_inful: boolean = false
    is_seijin_haien: boolean = false
    public is_hib_comment: string = ""
    public is_shouni_haien_comment: string = ""
    public is_b_kanen_comment: string = ""
    public is_rota_comment: string = ""
    public is_yonshu_comment: string = ""
    public is_bcg_comment: string = ""
    public is_mr_comment: string = ""
    public is_suitou_comment: string = ""
    public is_nouen_comment: string = ""
    public is_hpv_comment: string = ""
    public is_inful_comment: string = ""
    public is_seijin_haien_comment: string = ""
    public static toStr(src: PatientMedicalYobouSesshu) {
        if (!src) return ""
        let res = ""
        if (src.is_hib) res += "Hibワクチン " + src.is_hib_comment + " / "
        if (src.is_shouni_haien) res += "小児用肺炎球菌ワクチン" + src.is_shouni_haien_comment + " / "
        if (src.is_b_kanen) res += "B型肝炎ワクチン" + src.is_b_kanen_comment + " / "
        if (src.is_rota) res += "ロタウイルスワクチン" + src.is_rota_comment + " / "
        if (src.is_yonshu) res += "４種混合ワクチン" + src.is_yonshu_comment + " / "
        if (src.is_bcg) res += "BCG" + src.is_bcg_comment + " / "
        if (src.is_mr) res += "MRワクチン" + src.is_mr_comment + " / "
        if (src.is_suitou) res += "水痘ワクチン" + src.is_suitou_comment + " / "
        if (src.is_nouen) res += "日本脳炎ワクチン" + src.is_nouen_comment + " / "
        if (src.is_hpv) res += "HPVワクチン" + src.is_hpv_comment + " / "
        if (src.is_inful) res += "インフルエンザワクチン" + src.is_inful_comment + " / "
        if (src.is_seijin_haien) res += "成人用肺炎球菌ワクチン" + src.is_seijin_haien_comment + " / "
        return res
    }
}

export class ADL {
    public ijou: ADLScore = ADLScore.None
    public idou: ADLScore = ADLScore.None
    public kaidan: ADLScore = ADLScore.None
    public shokuji: ADLScore = ADLScore.None
    public nyuyoku: ADLScore = ADLScore.None
    public toilet: ADLScore = ADLScore.None
    public hainyou: ADLScore = ADLScore.None
    public haiben: ADLScore = ADLScore.None
    public koui: ADLScore = ADLScore.None
    public seiyou: ADLScore = ADLScore.None
    public ijou_comment: string = ""
    public idou_comment: string = ""
    public kaidan_comment: string = ""
    public shokuji_comment: string = ""
    public nyuyoku_comment: string = ""
    public toilet_comment: string = ""
    public hainyou_comment: string = ""
    public haiben_comment: string = ""
    public koui_comment: string = ""
    public seiyou_comment: string = ""
}

export class IADL {
    public denwa: IADLScore = IADLScore.None
    public kakei: IADLScore = IADLScore.None
    public kaimono: IADLScore = IADLScore.None
    public norimono: IADLScore = IADLScore.None
    public hukuyak: IADLScore = IADLScore.None
    public shokuji: IADLScore = IADLScore.None
    public souji: IADLScore = IADLScore.None
    public sentaku: IADLScore = IADLScore.None
    public denwa_comment: string = ""
    public kakei_comment: string = ""
    public kaimono_comment: string = ""
    public norimono_comment: string = ""
    public hukuyak_comment: string = ""
    public shokuji_comment: string = ""
    public souji_comment: string = ""
    public sentaku_comment: string = ""
}

export class UkeireCheck {
    public irai_name: string = ""
    public irai_org: string = ""
    public name: string = ""
    public name_kana: string = ""
    public sex: string = ""
    public age: string = ""

    public famiry_renraku: string = ""

    public shikkan: string = ""
    public social_keii: string = ""
    public other_service: string = ""
    public kibou_hindo: string = ""
    public kibou_houmonsha: string = ""
    public hoken: PatientHoken = new PatientHoken()

    public moushikomi_irai: MoushikomiStatus = MoushikomiStatus.None
    public schedule_chousei: number = 0
    public kibou_schedule: string = ""
    public service_kaigi_check: number = 0
    public service_kaigi_doc: number | null = null
    public taiin_kanfa_check: number = 0
    public taiin_kanfa_doc: number | null = null

    public shienkeikakusho_check: number = 0
    public next_action: string = ""

    public dates: UkeireDate[] = []

    public memo: UkeireMemo[] = []
}

export class UkeireDate {
    public target_date: string = ""
    public content: string = ""
}

export class UkeireMemo {
    public created_at: string = ""
    public user_id: number = 0
    public content: string = ""
}

export class ShokaiCheck {
    public keiyaku_setsumei: number = 0
    public kojinjouhou: number = 0
    public hokensho_hikae: number = 0 /* 0:していない 1:した -1:不要 */
    public kouza: number = 0 /* 0:していない 1:した -1:不要 */
    public shijisho_irai: number = 0
    public shijisho_touchaku: number = 0
    public iryoken_irai: number = 0
    public jokusho_shorui: number = 0
    public omaha_tenkai: number = 0
    public keikakusho_souhu: number = 0
}

export class FlagCheck {

}

export class Saigai {
    public priority: SaigaiPriority = SaigaiPriority.None
    public hinan_basho: string = ""
    public kyoten_byouin: string = ""
    //public kigu: SaigaiKigu[] = []
    public comment: string = ""
    public kigus: UsingIryoKigu = new UsingIryoKigu()
}

export enum SaigaiKiguType {
    None = "＋追加",
    Kanyure = "気管カニュレ",
    JinkoKokyu = "人工呼吸器",
    Kyuuin = "吸引機",
    ZaitakuSanso = "在宅酸素療法",
    JizokuChusha = "中心静脈栄養/持続注射",
    Irou = "胃瘻/腸瘻/経鼻胃管",
    KeikanEiyou = "経管栄養",
    Katetel = "留置ドレーン/カテーテル",
    JinkoKoumon = "人工肛門/人工膀胱",
    Jokuso = "褥瘡/人工膀胱",
    AirMat = "エアーマット",
    DendoBed = "電動ベッド",
}

// duplicated
export class SaigaiKigu {
    public type: SaigaiKiguType = SaigaiKiguType.None
    public name: string = ""
    public detail: object = {}
}

export const IryoKiguNames = {
    "akuseishuyou": "在宅悪性腫瘍患者指導管理",
    "kikansekkai": "在宅気管切開患者指導管理",
    "kikan_kanyure": "気管カニューレの管理",
    "ryuchi_katetel": "留置カテーテルの管理",
    "jikohukumaku": "在宅自己腹膜灌流指導管理",
    "ketsueki_touseki": "在宅血液透析指導管理",
    "sanso_ryouhou": "在宅酸素療法指導管理",
    "chushin_joumyakuu": "在宅中心静脈栄養法指導管理",
    "seibun_eiyou": "在宅成分栄養経管栄養法指導管理",
    "jiko_dounyou": "在宅自己導尿指導管理",
    "jizoku_youatsu": "在宅持続陽圧呼吸療法指導管理",
    "jiko_toutsuu": "在宅自己疼痛管理指導管理",
    "hai_kouatsu": "在宅肺高血圧症患者指導管理",
    "jinkou_koumon": "人工肛門また人工膀胱留置の管理",
    "jokuso": "真皮を超える褥瘡の状態（MPUAP分類Ⅲ度またはⅣ度、DESIGN分類D3、D4、D5）の管理",
    "tenteki": "点滴注射を週3日以上の管理",
    "jinkou_kokyu": "在宅人工呼吸指導管理",
}

export class UsingIryoKigu {
    public akuseishuyou: boolean = false //在宅悪性腫瘍患者指導管理
    public kikansekkai: boolean = false //在宅気管切開患者指導管理
    public kikan_kanyure: boolean = false //気管カニューレの管理
    public ryuchi_katetel: boolean = false //留置カテーテルの管理
    public jikohukumaku: boolean = false //在宅自己腹膜灌流指導管理
    public ketsueki_touseki: boolean = false //在宅血液透析指導管理
    public sanso_ryouhou: boolean = false //在宅酸素療法指導管理
    public chushin_joumyakuu: boolean = false //在宅中心静脈栄養法指導管理
    public seibun_eiyou: boolean = false //在宅成分栄養経管栄養法指導管理
    public jiko_dounyou: boolean = false //在宅自己導尿指導管理
    public jizoku_youatsu: boolean = false //在宅持続陽圧呼吸療法指導管理
    public jiko_toutsuu: boolean = false //在宅自己疼痛管理指導管理
    public hai_kouatsu: boolean = false //在宅肺高血圧症患者指導管理
    public jinkou_koumon: boolean = false //人工肛門また人工膀胱留置の管理
    public jokuso: boolean = false //真皮を超える褥瘡の状態（MPUAP分類Ⅲ度またはⅣ度、DESIGN分類D3、D4、D5）の管理
    public tenteki: boolean = false //点滴注射を週3日以上の管理
    public jinkou_kokyu: boolean = false //在宅人工呼吸指導管理

    public akuseishuyou_comment: string = "" //在宅悪性腫瘍患者指導管理
    public kikansekkai_comment: string = "" //在宅気管切開患者指導管理
    public kikan_kanyure_comment: string = "" //気管カニューレの管理
    public ryuchi_katetel_comment: string = "" //留置カテーテルの管理
    public jikohukumaku_comment: string = "" //在宅自己腹膜灌流指導管理
    public ketsueki_touseki_comment: string = "" //在宅血液透析指導管理
    public sanso_ryouhou_comment: string = "" //在宅酸素療法指導管理
    public chushin_joumyakuu_comment: string = "" //在宅中心静脈栄養法指導管理
    public seibun_eiyou_comment: string = "" //在宅成分栄養経管栄養法指導管理
    public jiko_dounyou_comment: string = "" //在宅自己導尿指導管理
    public jizoku_youatsu_comment: string = "" //在宅持続陽圧呼吸療法指導管理
    public jiko_toutsuu_comment: string = "" //在宅自己疼痛管理指導管理
    public hai_kouatsu_comment: string = "" //在宅肺高血圧症患者指導管理
    public jinkou_koumon_comment: string = "" //人工肛門また人工膀胱留置の管理
    public jokuso_comment: string = "" //真皮を超える褥瘡の状態（MPUAP分類Ⅲ度またはⅣ度、DESIGN分類D3、D4、D5）の管理
    public tenteki_comment: string = "" //点滴注射を週3日以上の管理
    public jinkou_kokyu_comment: string = "" //在宅人工呼吸指導管理
}

export enum EndReason {
    None = "未選択",
    JitakuMitori = "自宅看取り",
    KikanMitori = "医療機関看取り",
    Nyuin = "入院",
    Nyusho = "入所",
    Sotsugyo = "卒業",
    Other = "その他"
}

export class SameBuilding {
    public id: number = 0;
    public patient1_id: number | null = null
    public patient2_id: number | null = null
    public patient1_name: string = ""
    public patient2_name: string = ""
}

export class PatientSimple {
    public id: number = 0;
    public name: string = ""
}

export class PatientKasan {
    public is_nanbyou_hukusuu_kai: boolean = false //難病等複数回訪問加算
    public is_tokubetsu_chiiki: boolean = false //特別地域訪問看護加算
    public is_dx_kasan: boolean = false
    public is_24h_taisei: boolean = false // ２４時間対応体制加算
    public is_24h_taisei_i: boolean = false // ２４時間対応体制加算イ
    public is_24h_taisei_not_i: boolean = false // ２４時間対応体制加算イ以外
    public is_zaitaku_renkei: boolean = false //在宅患者連携指導加算
    public is_seishin_juushou_shien_1: boolean = false // 精神科重症患者支援管理連携加算１
    public is_seishin_juushou_shien_2: boolean = false // 精神科重症患者支援管理連携加算２
    public is_douitsu_tatemono_gensan_1: boolean = false // 訪問看護同一建物減算１
    public is_douitsu_tatemono_gensan_2: boolean = false // 訪問看護同一建物減算２
    public is_chuuzankan_chiiki_1: boolean = false // 訪問看護中山間地域等提供加算１
    public is_chuuzankan_chiiki_2: boolean = false // 訪問看護中山間地域等提供加算２
    public is_kinkyuji_houmon_kango_1: boolean = false // 緊急時訪問看護加算１
    public is_kinkyuji_houmon_kango_1_2: boolean = false // 緊急時訪問看護加算２
    public is_kinkyuji_houmon_kango_2_1: boolean = false // 緊急時訪問看護加算１
    public is_kinkyuji_houmon_kango_2_2: boolean = false // 緊急時訪問看護加算２
    public is_tokubetsu_chiiki_2div: boolean = false // 特別地域訪問看護加算２日割
    public is_shoukibo_jigyousho_2div: boolean = false // 訪問看護小規模事業所加算２日割
    public is_chuuzankan_chiiki_2div: boolean = false // 訪問看護中山間地域等加算２日割
}
