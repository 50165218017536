
export class Core {
    public values: Values = new Values();
    public indexes: Indexes = new Indexes();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""

    public bed_chair_ijou: string = ""
    public bed_idou: string = ""
    public bed_zai: string = ""
    public idou_chair: string = ""
    public chair_zai: string = ""
    public negaeri: string = ""
    public okiagari: string = ""
    public ritsui_hoji: string = ""
    public tachiagari: string = ""
    public zai_hirou: string = ""
    public sum_score: number = 0
}

export class Indexes {
    public bed_chair_ijou: number = -1
    public bed_idou: number = -1
    public bed_zai: number = -1
    public idou_chair: number = -1
    public chair_zai: number = -1
    public negaeri: number = -1
    public okiagari: number = -1
    public ritsui_hoji: number = -1
    public tachiagari: number = -1
    public zai_hirou: number = -1
    public sum_score: number = 0
}

export class Checks {
}