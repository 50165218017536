import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../../components/Page'
import * as FormModel from '../../models/Forms'
import { TextField, TableRow, TableCell, TableHead, TableBody, Paper, Table, Button, Grid } from '@material-ui/core';
import { KagiAzukariDetail } from '../../models/Forms/KagiAzukari';
import SubTitle from '../../components/SubTitle';
import * as HokanApi from '../../api/hokan'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        textAlign: "center"
    },
    paper: {
        width: "80%",
        maxWidth: "1200px",
        margin: "auto"
    },
    table: {

    },
    cell: {

    },
    field: {

    },
    image: {
        maxWidth: "500px",
        width: "80%"
    },
    button: {
        width: "200px",
        height: "60px",
        margin: "50px"
    }
});

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}


interface LocalState {
    data: FormModel.KagiAzukari
}

// @inject()
// @observer
class KagiAzukariPage extends React.Component<Props, LocalState> { 
	displayName="KagiAzukariPage"
    constructor(props: Props) {
        super(props)
        const initialData = new FormModel.KagiAzukari()
        for (let i = 0; i < 4; ++i) {
            initialData.data.push(new KagiAzukariDetail())
        }
        this.state = {
            data: initialData
        }
    }

    componentDidMount() {
    }

    setValue(idx: number, col: string, value: string) {
        const d = this.state.data
        const c = d.data[idx] as any
        c[col] = value;
        this.setState({ data: d })
    }

    doPost(data: FormModel.KagiAzukari) {
        HokanApi.postFormKagiAzukari(data).then((res: any) => {
            var blob = new Blob([res.data], { type: 'application/pdf' });
            console.log(blob)
            var pdfURL = window.URL.createObjectURL(blob);
            window.open(pdfURL)
        })
    }

    public render() {
        const { classes } = this.props
        const { data } = this.state
        return <div className={classes.root} >
            <SubTitle title="鍵預かり証" />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <img className={classes.image} src="/images/kagi_azukari.png" />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper className={classes.paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">摘要</TableCell>
                                    <TableCell align="center">鍵番号</TableCell>
                                    <TableCell align="center">個数</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.data.map((d, idx) => {
                                        return <TableRow key={idx}>
                                            <TableCell>
                                                <TextField
                                                    id="outlined-dense"
                                                    label="name"
                                                    margin="dense"
                                                    variant="outlined"
                                                    value={d.name}
                                                    onChange={(e) => this.setValue(idx, "name", e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="outlined-dense"
                                                    label="number"
                                                    margin="dense"
                                                    variant="outlined"
                                                    value={d.number}
                                                    onChange={(e) => this.setValue(idx, "number", e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="outlined-dense"
                                                    label="quantity"
                                                    margin="dense"
                                                    variant="outlined"
                                                    value={d.quantity}
                                                    onChange={(e) => this.setValue(idx, "quantity", e.target.value)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                </Grid>
                <Button className={classes.button}
                    variant="contained" color="primary" onClick={(e) => { this.doPost(data) }}>
                    作成
            </Button>
        </div>
            }
        }
        
export default withPageProps(Styles.withStyles(styles)(KagiAzukariPage))