import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { Grid, Select, MenuItem } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    textField: {
        width: "90%"
    },
    input: {
        color: Colors.inputColor,
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto"
    }
})

interface ListPair {
    label: string
    value: string
}


interface Props extends Styles.WithStyles {
    title: string,
    value: string,
    values: ListPair[],
    onChange: (ListPair) => void
    editable: boolean
}

class InputList extends React.Component<Props> { 
    displayName="InputList"

    public static defaultProps = {
        editable: true
    }
    public render() {
        const { classes, title } = this.props;
        const theLabel = this.props.values.find(x => x.value == this.props.value)
        return [
            <Grid key={title+"_1"} item xs={3} className={classes.row}>
                {title}
            </Grid>,
            <Grid key={title + "_2"} item xs={9}>
                {
                    this.props.editable ? 
                    <Select
                        value={this.props.value}
                        onChange={(e) => this.props.onChange((e.target as any).value)}
                    >
                        {
                            this.props.values.map((t, idx) =>
                                <MenuItem key={`${t}_${idx}`} value={t.value}>{t.label}</MenuItem>
                            )
                        }
                    </Select>
                    : <div>
                            {theLabel ? theLabel.label : "-"}
                    </div>
                }
                
            </Grid>
        ]
    }
}

export default Styles.withStyles(styles)(InputList)