import * as React from 'react';
import { Link } from 'react-router-dom';

export default class extends React.Component {
    public render() {
        return (
            <div>
                <h2>TODO</h2>
                <Link to="/">ホームへ</Link>
            </div>
        );
    }
}

