import * as React from 'react';
import * as Model from '../models'
import { StoreAccessor } from '../util/StoreUtil';
import { Grid, Button, Checkbox, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Tooltip, TextField } from '@material-ui/core';
import Col2Table from './Col2Table';
import KirokuPartialSelectDialog from '../components/KirokuPartialSelectDialog'
import * as KirokuIIView from '../components/KirokuIIViews'
import * as Icons from '@material-ui/icons';
import EditableCheckList from './EditableCheckList';
import DateUtil from '../util/DateUtil'
import * as FormModel from '../models/Forms'
import * as HokanApi from '../api/hokan'
import DocumentDialog from '../components/DocumentDialog';

//import EditableList from './EditableList';
interface LocalState {
    kiroku: Model.KirokuII.KangoKirokuII,
    latestKiroku: Model.KirokuII.KangoKirokuII | null,
    latestKirokuList: Model.KirokuII.KangoKirokuII[],
    loading: boolean,
    isEditing: boolean,
    cache: any,
    assessments: Model.Assessment.AssessmentListResult[],
    filterChecked: { name: string, checked: boolean }[],
    dialogOpenFlag: boolean,
    patient: Model.Patient.Patient,
    openDelete: boolean,
    openPastCopy: string,
    serviceCodeFilter: string,
    problemList: Model.Problem[],
    posting: boolean,
    postError: string | null,
    serverSideKasan: Model.KirokuII.ServerSideKasan
}
interface Props {
    kiroku: Model.KirokuII.KangoKirokuII
    sa: StoreAccessor
    saPast: StoreAccessor
    classes: any
    isEditing: boolean
    state: LocalState
    patient_id: number

    onCopyClick: (name: string) => void
    onCopySelect: (res: Model.KirokuII.KangoKirokuII) => void
    panelOnChange: (e: any, f: boolean, c?: { name: string, checked: boolean }) => void
    onLoadServerSideKasan: () => void
}

interface HashState {
    hash: string,
    docTemp: any
}

const list01234 = [
    { key: '0', value: 0 },
    { key: '1', value: 1 },
    { key: '2', value: 2 },
    { key: '3', value: 3 },
    { key: '4', value: 4 },
]

const nrsTooltip = (classes) => <div className={classes.tooltipDiv}>
    <div className={classes.tooltipTitle}>NRS (Numerical Rating Scale)</div>
    <div className={classes.tooltipTitle}>０：なし</div>
    <div className={classes.tooltipLine}>１〜３：軽い</div>
    <div className={classes.tooltipLine}>４〜６：中等度</div>
    <div className={classes.tooltipLine}>７〜１０：強い</div>
</div>

class CatVal {
    public name: any
    public cat: any[]
    public value: any
    constructor(name: any, cat: any[] = []) {
        this.name = name
        this.cat = cat
        this.value = {}
    }

    public isEmpty(sa: StoreAccessor) {
        return sa.isEmptyValue(this.name, this.cat)
    }
}
abstract class EditViewBase extends React.Component<Props, HashState>  {
    displayName = "EditViewBase"
    constructor(props) {
        super(props)
        this.state = {
            hash: "",
            docTemp: {}
        }
    }

    public getHash(props: Props) { return "" }

    public shouldComponentUpdate(prop: Props, state: HashState) {
        if (prop.state.filterChecked[this.getTitle()] == false) {
            return false
        }
        return true
    }

    public get isKaigoHoken() {
        return Model.KirokuII.KangoKirokuII.isKaigoHoken(this.props.kiroku)
    }

    public get isIryoHoken() {
        return Model.KirokuII.KangoKirokuII.isIryoHoken(this.props.kiroku)
    }
    public get isSeishinHoken() {
        return Model.KirokuII.KangoKirokuII.isSeishinHoken(this.props.kiroku)
    }
    public get isJihi() {
        return Model.KirokuII.KangoKirokuII.isJihi(this.props.kiroku)
    }

    public pastNumberC = (cat: any, name: any, step: number = 1) => {
        const { sa, saPast } = this.props
        return <div><div>
            前回：{saPast.hasValue(cat, name) ? saPast.numberC(cat, name, "", step) : "-"}
        </div>
            {sa.numberC(cat, name, "", step)}
        </div>
    }
    public pastTextC = (cat: any, name: any) => {
        const { sa, saPast } = this.props
        return <div><div>
            前回：{saPast.hasValue(cat, name) ? saPast.textC(cat, name) : "-"}
        </div>
            {sa.textC(cat, name)}
        </div>
    }

    public taiju_elem = () => {
        const { sa, saPast } = this.props
        return {
            name: "体重", content: <div><div style={{display:"inlne-flex"}}>前回：{
                saPast.hasValue(["vital"], "taiju") && saPast.hasValue(["vital"], "taiju_unit") ?
                    saPast.numberUnitC(["vital"], "taiju", "taiju_unit", [{ name: "kg", factor: 1 }, { name: "g", factor: 1000 }])
                    : "-"
            }</div>{
                    sa.numberUnitC(["vital"], "taiju", "taiju_unit", [{ name: "kg", factor: 1 }, { name: "g", factor: 1000 }])
                }</div>
        }
    }
    public shincho_elem = () => {
        const { sa, saPast } = this.props
        return {
            name: "身長(cm)", content: <div><div>前回：{
                saPast.hasValue(["vital"], "shincho") ?
                    saPast.numberC(["vital"], "shincho")
                    : "-"
            }</div>{
                    sa.numberC(["vital"], "shincho")
                }</div>
        }
    }

    public abstract getContents(): { name: any, content: any, values?: CatVal[], closeDefault?:boolean }[]
    public abstract getTitle(): string

    public render() {
        const { classes, state } = this.props
        const title = this.getTitle()
        const c = state.filterChecked.find(x => x.name == title)
        const checked = (c != undefined && c.checked == true)
        const copyButton = <Button color="primary" variant="outlined"
            onClick={(e) => this.props.onCopyClick(title)}
            className={classes.filter_button_d}>
            前回確認/コピー
         </Button>
        const dialog = state.openPastCopy == title ?
            <KirokuPartialSelectDialog
                kirokus={state.latestKirokuList}
                onSelect={(res) => this.props.onCopySelect(res)}
                targetComponent={(kiroku) => {
                    return KirokuIIView.getComponentByName(title, kiroku, state.problemList)
                }}
                onCancel={() => { this.props.onCopyClick("") }}
            />
            : null

        const expand = (!c || checked)
        const contents = this.getContents()
        contents.forEach(c => {
            if (!c.values) return;
            if (!c.values.find(cv => cv.isEmpty(this.props.sa)==false)) {
                c.closeDefault = true;
            }
        })
        return [<ExpansionPanel
            key={title}
            expanded={expand}
            onChange={(e, f) => this.props.panelOnChange(e, f, c)}
            style={{
                border: "solid",
                borderWidth: "1px"
            }}>
            <ExpansionPanelSummary
                className={(!c || c.checked) ? classes.panelOpen : classes.panelClose}
                expandIcon={c ? <Icons.ExpandMore /> : null}>
                <Typography>{title}</Typography>
            </ExpansionPanelSummary>
            {
                expand ?
                    <ExpansionPanelDetails
                        style={{
                            display: "block",
                            backgroundColor: "white",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            paddingLeft: "0px",
                            paddingRight: "0px"
                        }} >
                        <div>{copyButton}</div>
                        <Col2Table shrink={true} body={contents} />
                    </ExpansionPanelDetails>
                    : null
            }
        </ExpansionPanel>
            ,
            dialog
        ]
    }
}


export class Basic extends EditViewBase {
    displayName = "Basic"
    public static getTitle(): string { return "基本情報" }
    public getTitle(): string { return Basic.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const is_teiki = kiroku.basic && kiroku.basic.is_teiki
        const pat = this.props.state.patient
        const ht = (pat && pat.hoken) ? pat.hoken.hoken_type : null

        const contents = [
            {
                name: "記録の種類", content: <div>
                    {sa.list("kiroku_type", Model.KirokuII.KirokuType)}
                    {
                        this.isJihi ?
                            sa.textC(["basic"], "jihi_comment", "備考")
                            : null
                    }
                </div>

            },
            { name: "開始時間", content: sa.dateTime("time_start", "time_end") },
            { name: "終了時間", content: sa.dateTime("time_end") },
            { name: "緊急訪問", content: <span><Checkbox
                    color="primary"
                checked={sa.getValueC("is_kinkyu", ["basic"])}
                onChange={(n, v) => {
                    sa.setValueC("is_kinkyu", v, ["basic"])
                    sa.setValueC("is_kinkyu", v, ["basic", "kasan", "seishin"])
                    sa.setValueC("is_kinkyu", v, ["basic", "kasan", "basic"])
                    }} />
                </span>
            },
            {
                name: "訪問スタッフ", content: sa.user("main_user")
            },
            {
                name: "資格", content: sa.listRawC(["basic"], "shikaku", Model.KirokuII.ShikakuList)
            },
            {
                name: "複数名", content: <div>
                    {
                        kiroku.basic.doukou_main ?
                            <Col2Table disableBGColor={true} body={[
                                { name: "スタッフ", content: sa.userC(["basic"], "doukou_main") },
                                { name: "資格", content: sa.listRawC(["basic"], "doukou_shikaku", Model.KirokuII.ShikakuList) },
                                { name: "補助者", content: sa.checkC(["basic"], "doukou_is_hojo") }
                            ]} />
                            :
                            <div>
                                なし：{sa.userC(["basic"], "doukou_main")}
                            </div>
                    }
                </div>

            },
            {
                name: "同行者（教育的）", content: <div className={classes.innerTable} >
                    <Col2Table disableBGColor={true} body={
                        kiroku.basic.doukou_edu.map((doukou, idx) => {
                            return {
                                name: "名前", content: <div>
                                    {sa.userC(["basic", "doukou_edu", idx], "user")}
                                    {sa.listC(["basic", "doukou_edu", idx], "step", Model.KirokuII.DoukouStep)}
                                </div>
                            }
                        })
                    } />
                    <Button variant="outlined" size="small" color="default"
                        onClick={(e) => {
                            kiroku.basic.doukou_edu.push(new Model.KirokuII.DoukouUser())
                            sa.updateState()
                        }}>
                        ＋追加
                    </Button>
                </div>

            },
            {
                name: "同行者（引き継ぎ）", content: <div className={classes.innerTable} >
                    <Col2Table disableBGColor={true} body={
                        kiroku.basic.doukou_hikitsugi.map((doukou, idx) => {
                            return {
                                name: "名前", content: sa.userC(["basic", "doukou_hikitsugi", idx], "user")
                            }
                        })
                    } />
                    <Button variant="outlined" size="small" color="default"
                        onClick={(e) => {
                            kiroku.basic.doukou_hikitsugi.push(new Model.KirokuII.DoukouUser())
                            sa.updateState()
                        }}>
                        ＋追加
                    </Button>
                </div>

            },
            {
                name: "定期巡回", content: <div>
                    {sa.checkC(["basic"], "is_teiki", "定期巡回")}
                    {sa.checkC(["basic"], "is_hiwari", "日割り")}
                 </div>},
            {
                name: "コードと加算の推定", content: <Button
                    variant="outlined" size="small" color="default"
                    disabled={this.isIryoHoken == false && this.isKaigoHoken == false}
                    onClick={(e) => {
                        this.props.onLoadServerSideKasan()
                    }}>
                   　実行
                    </Button>
            },
            {
                name: "算定コード", content: this.isKaigoHoken ?
                    sa.serviceCodeC([], "service_code",
                        !is_teiki && ht == Model.Patient.HokenType.Kaigo,
                        !is_teiki && ht == Model.Patient.HokenType.KaigoYobo,
                        false,
                        is_teiki
                    ) :
                    this.isIryoHoken ?
                        <div>
                            {sa.listC(["basic"], "iryo_code", Model.KirokuII.IryoCodeEnum)}
                            {
                                sa.serviceCodeC([], "service_code",
                                    false, false,
                                    true,
                                    false
                                )
                            }
                        </div>
                        : <div>-</div>
            },
            { name: "リハ回数", content: sa.numberC(["basic"], "riha_count") },
            {
                name: "加算", closeDefault: !Model.KirokuII.Kasan.checkedSomeKasan(kiroku.basic.kasan), content: <Grid container={true} spacing={0}>
                    {
                        this.isKaigoHoken ?
                            <Grid key="glx" item={true} xs={12} md={12} lg={12}>
                                {sa.checkListC(["basic", "kasan"], [
                                    { key: "is_shokai", label: "初回加算" },
                                    { key: "is_taiin", label: "退院時指導加算" },
                                    { key: "is_renkei", label: "連携強化加算" },
                                    { key: "is_terminal_care", label: "ターミナルケア加算" },
                                ], true)}
                            </Grid>
                            : this.isIryoHoken ?
                                [
                                    <Grid key="gl1" item={true} xs={12} md={6} lg={6}>
                                        {sa.checkListC(["basic", "kasan", "common"], [
                                            //{ key: "is_tokubetu_kanri_1", label: "特別管理加算１" },
                                            //{ key: "is_tokubetu_kanri_2", label: "特別管理加算２" },
                                            { key: "is_taiin_shidou", label: "退院時共同指導加算" },
                                            //{ key: "is_24h", label: "２４時間対応体制加算" },
                                            { key: "is_taiin_shien", label: "退院支援指導加算" },
                                            { key: "is_zaitaku_renkei_shidou", label: "在宅患者連携指導加算" },
                                            { key: "is_zaitaku_kinkyu_kanfa", label: "在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ加算" },
                                            { key: "is_tokubetu_kanri_shidou", label: "特別管理指導加算" },
                                            //{ key: "is_seishin_juushou_shien_1", label: "精神科重症患者支援管理連携加算１" },
                                            //{ key: "is_seishin_juushou_shien_2", label: "精神科重症患者支援管理連携加算２" },
                                            { key: "is_renkei_kyouka", label: "看護・介護職員連携強化加算" },
                                            //{ key: "is_jouhou_teikyo_1", label: "訪問看護情報提供療養費１" },
                                            //{ key: "is_jouhou_teikyo_2", label: "訪問看護情報提供療養費２" },
                                            //{ key: "is_jouhou_teikyo_3", label: "訪問看護情報提供療養費３" },
                                            { key: "is_terminal_care_1", label: "訪問看護ターミナルケア療養費１" },
                                            { key: "is_terminal_care_2", label: "訪問看護ターミナルケア療養費２" },
                                        ], true)}
                                    </Grid>,
                                    <Grid key="glx" item={true} xs={12} md={6} lg={6}>
                                        {sa.checkListC(["basic", "kasan"], [
                                            { key: "is_kanwa_care", label: "緩和ケア" },
                                            { key: "is_jokusou_care", label: "褥瘡ケア" },
                                            { key: "is_smart_care", label: "ストーマケア" },
                                        ], true)}
                                    </Grid>,
                                    this.isSeishinHoken ?
                                        <Grid key="gl2" item={true} xs={12} md={6} lg={6}>
                                            {sa.checkListC(["basic", "kasan", "seishin"], [
                                                { key: "is_hukusuu_2", label: "精神科複数回訪問加算(２回目)" },
                                                { key: "is_hukusuu_3", label: "精神科複数回訪問加算(３回目以降)" },
                                                { key: "is_tokubetsu_chiiki", label: "特別地域訪問看護加算" },
                                                { key: "is_kinkyu", label: "緊急訪問看護加算" },
                                                { key: "is_choujikan", label: "長時間訪問看護加算" },
                                                { key: "is_hukusuu_kangoshi_1", label: "複数名訪問看護加算(看護師等・１回)" },
                                                { key: "is_hukusuu_kangoshi_2", label: "複数名訪問看護加算(看護師等・２回)" },
                                                { key: "is_hukusuu_kangoshi_3", label: "複数名訪問看護加算(看護師等・３回以上)" },
                                                { key: "is_hukusuu_jun_1", label: "複数名訪問看護加算(准看護師・１回)" },
                                                { key: "is_hukusuu_jun_2", label: "複数名訪問看護加算(准看護師・２回)" },
                                                { key: "is_hukusuu_jun_3", label: "複数名訪問看護加算(准看護師・３回以上)" },
                                                { key: "is_hukusuu_hojo", label: "複数名訪問看護加算(看護補助者)" },
                                                { key: "is_hukusuu_ptot_1", label: "複数名訪問看護加算(作業療法士・１回)" },
                                                { key: "is_hukusuu_ptot_2", label: "複数名訪問看護加算(作業療法士・２回)" },
                                                { key: "is_hukusuu_ptot_3", label: "複数名訪問看護加算(作業療法士・３回以上)" },
                                                { key: "is_seishin_hukushi", label: "複数名訪問看護加算(精神保健福祉士)" },
                                                { key: "is_yakan", label: "夜間・早朝訪問看護加算" },
                                                { key: "is_shinya", label: "深夜訪問看護加算" },
                                            ], true)}
                                        </Grid>
                                        :
                                        <Grid key="gl3" item={true} xs={12} md={6} lg={6}>
                                            {sa.checkListC(["basic", "kasan", "basic"], [
                                                { key: "is_hukusuu_2", label: "難病等複数回訪問加算(２回目)" },
                                                { key: "is_hukusuu_3", label: "難病等複数回訪問加算(３回目以降)" },
                                                { key: "is_tokubetsu_chiiki", label: "特別地域訪問看護加算" },
                                                { key: "is_kinkyu", label: "緊急訪問看護加算" },
                                                { key: "is_choujikan", label: "長時間訪問看護加算" },
                                                { key: "is_nyuyouji", label: "乳幼児加算" },
                                                { key: "is_hukusuu_kangoshi", label: "複数名訪問看護加算(看護師等)" },
                                                { key: "is_hukusuu_jun", label: "複数名訪問看護加算(准看護師)" },
                                                { key: "is_hukusuu_hojo_ha", label: "複数名訪問看護加算(看護補助者・ハ)" },
                                                { key: "is_hukusuu_hojo_ni_1", label: "複数名訪問看護加算(看護補助者・ニ・１回)" },
                                                { key: "is_hukusuu_hojo_ni_2", label: "複数名訪問看護加算(看護補助者・ニ・２回)" },
                                                { key: "is_hukusuu_hojo_ni_3", label: "複数名訪問看護加算(看護補助者・ニ・３回以上)" },
                                                { key: "is_hukusuu_ptot", label: "複数名訪問看護加算(理学療法士等)" },
                                                { key: "is_yakan", label: "夜間・早朝訪問看護加算" },
                                                { key: "is_shinya", label: "深夜訪問看護加算" },
                                            ], true)}
                                        </Grid>
                                ]
                            /* [
                                <Grid key="gl1" item={true} xs={12} md={6} lg={6}>
                                    {sa.checkListC(["basic", "kasan"], [
                                        { key: "is_taiinji_shidou", label: "退院時共同指導加算" },
                                        { key: "is_tokubetu_kanri_shidou", label: "特別管理指導加算" },
                                        { key: "is_taiin_shien_shidou", label: "退院支援指導加算" },
                                        { key: "is_kinkyu_houmon", label: "緊急訪問看護加算" },
                                        { key: "is_chojikan_houmon_kango", label: "長時間訪問看護加算" },
                                        { key: "is_yakan", label: "夜間・早朝加算" },
                                        { key: "is_shinnya", label: "深夜加算" },
                                        { key: "is_nyuyouji", label: "乳幼児加算" },
                                        //{ key: "is_youji", label: "幼児加算" },
                                    ], true)}
                                </Grid>,

                                <Grid key="gl2" item={true} xs={12} md={6} lg={6}>
                                    {sa.checkListC(["basic", "kasan"], [
                                        { key: "is_hukusuu_kangoshi", label: "複数名訪問看護加算（看護師）" },
                                        { key: "is_hukusuu_ptot", label: "複数名訪問看護加算（PTOT）" },
                                        { key: "is_hukusuu_junkangoshi", label: "複数名訪問看護加算（准看護師）" },
                                        { key: "is_hukusuu_hojo", label: "複数名訪問看護加算（補助者）" },
                                        { key: "is_hukusuu_psw", label: "複数名訪問看護加算（PSW）" },
                                        { key: "is_zaitaku_renkei_shidou", label: "在宅患者連携指導加算" },
                                        { key: "is_zaitaku_kinkyu_kanfa", label: "在宅患者緊急時等カンファレンス加算" },
                                        { key: "is_kanwa_care", label: "緩和ケア" },
                                        { key: "is_jokusou_care", label: "褥瘡ケア" },
                                        { key: "is_smart_care", label: "スマートケア" },
                                        { key: "is_terminal_care", label: "ターミナルケア加算" },
                                    ], true)}
                                </Grid>
                            ]*/
                                : null
                    }
                </Grid>

            },
        ]
        return contents
    }
}


export class Omaha extends EditViewBase {
    displayName = "Omaha"
    public static getTitle(): string { return "オマハシステム" }
    public getTitle(): string { return Omaha.getTitle() }

    private catToJp(cat: string) {
        if (cat == "TGC") return "TGC(教育/相談/指導)"
        if (cat == "CM") return "CM(ケアマネジメント)"
        if (cat == "TP") return "TP(直接ケア)"
        if (cat == "S") return "S(観察)"
        return ""
    }
    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const st = this.props.state
        const assessments = st.assessments.filter(ass => {
            if (kiroku.omaha && kiroku.omaha.details && kiroku.omaha.details.findIndex(x => x.problem_id == ass.problem.id) >= 0) {
                // 既に記録されているものは出す
                return true;
            }
            return ass.newest.assessment.closed == false && ass.newest.assessment.priority == 1
        })
        const contents = assessments.map(ass => {
            const ns = ass.newest
            const omaha = kiroku.omaha.details.find(x => x.problem_id == ass.problem.id)
            const catFromInterv = {}
            ns.intervention.forEach(x => {
                if (catFromInterv[x.category]) {
                    catFromInterv[x.category].push(x)
                }
                else {
                    catFromInterv[x.category] = [x]
                }
            })
            const checkSd = (_n, v) => {
                const n = parseInt(_n)
                const o = omaha
                if (o) {
                    const it = ns.intervention.find(x => x.id == n)
                    const z = ns.assessment_intervention.find(x => x.id == n)
                    if (it) {
                        const t = o.intervention.find(x => x.intervention_id == n)
                        if (t) {
                            t.check = v
                            t.detail = it
                            if (!t.comment || t.comment == "") {
                                t.comment = z ? z.comment : ""
                            }
                        }
                        else {
                            const i = new Model.KirokuII.OmahaInterv()
                            i.intervention_id = it.id
                            i.check = true
                            i.comment = z ? z.comment : ""
                            i.detail = it
                            o.intervention.push(i)
                        }
                        sa.updateState()
                    }
                }
            }
            const checkLis = (x) => {
                const z = omaha ? omaha!.intervention.find(t => t.intervention_id == x.id) : null
                const w = ns.assessment_intervention.find(ai => ai.intervention_id == x.id)
                const cm = (
                    z && z.comment && z.comment != "" ? `(${z.comment})` :
                        w && w.comment && w.comment != "" ? `(${w.comment})`
                            : ""
                )
                return {
                    key: x.id.toFixed(), value: z ? z.check : false,
                    label: x.target_category + ":" + x.target + cm
                }
            }

            const intvCats = ["TGC", "CM", "TP", "S"]
            return {
                name: ass.problem.problem_no + ":" + ass.problem.name,
                closeDefault: true,

                content: <div style={{ padding: "10px" }}> <Col2Table key={`omaha_${ass.problem.problem_no}`} disableBGColor={true} body={[
                    {
                        name: "介入", content: <div>
                            {
                                intvCats.map((ica, icai) => {
                                    return catFromInterv[ica] ? <div key={`ica_${icai}`}>
                                        <div style={{ fontWeight: "bold" }}>{this.catToJp(ica)}</div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <EditableCheckList
                                                editMode={this.props.isEditing}
                                                vertical={true}
                                                list={catFromInterv[ica].map(x => checkLis(x))}
                                                setData={(_n, v) => checkSd(_n, v)} />
                                        </div>
                                    </div> : null
                                })
                            }
                        </div>
                    }
                    ,
                    {
                        name: "自由記述", content: <TextField
                            label=""
                            type="text"
                            fullWidth={true}
                            multiline={true}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                className: classes.input,
                            }}
                            value={omaha ? omaha.comment : ""}
                            margin="none"
                            variant="outlined"
                            onChange={(e) => {
                                if (omaha) {
                                    omaha.comment = e.target.value
                                    sa.updateState()
                                }
                            }}
                        />
                    }
                ]} />
                </div>
            }
        })
        return contents
    }
}
export class Vital extends EditViewBase {
    displayName = "Vital"
    public static getTitle(): string { return "バイタルサイン" }
    public getTitle(): string { return Vital.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku, state } = this.props

        const toDottedValue = (val) => {
            if (val != 0 && !val) return val
            const s = val.toString()
            if (s.length == 3 && s[2] != ".") {
                return s.slice(0, 2) + "." + s.slice(2)
            }
            return val
        }
        /*
        const taionFirst = kiroku.vital.first.taion
        if (taionFirst) {
            const n = toDottedValue(taionFirst.toString())
            if (taionFirst != n) {
                kiroku.vital.first.taion = n
            }
        }
        if (kiroku.vital.saiken) {
            const taionSaiken = kiroku.vital.saiken.taion
            if (taionSaiken) {
                const n = toDottedValue(taionSaiken.toString())
                if (taionSaiken != n) {
                    kiroku.vital.saiken.taion = n
                }
            }
        }*/
        const pastVital = (name) => {
            const getVal = (f, s, name) => {
                return (s && s[name]) ? s[name] : ((f && f[name]) ? f[name] : null)
            }
            const latest = state.latestKiroku
            const pVal = (latest && latest.vital) ? getVal(latest.vital.first, latest.vital.saiken, name) : null
            if (pVal == null) return "--"

            const cur = state.kiroku
            const cVal = (cur && cur.vital) ? getVal(cur.vital.first, cur.vital.saiken, name) : null
            if (cVal == null) return pVal
            const diff = (cVal - pVal)
            return `${pVal} (${diff == 0 ? "±" : diff > 0 ? "+" : ""}${diff.toFixed(2)})`
        }
        const vitals = (cat) => <Col2Table disableBGColor={true} body={[
            {
                name: "血圧", content: <div>
                    収縮：<div>前回：{pastVital("ketsuatsu_max")}</div>{sa.numberC(cat, "ketsuatsu_max")}
                    拡張：<div>前回：{pastVital("ketsuatsu_min")}</div>{sa.numberC(cat, "ketsuatsu_min")}
                </div>
            },
            { name: "体温", content: <div><div>前回：{pastVital("taion")}</div>{sa.numberC(cat, "taion", "", 1, toDottedValue)}</div> },
            { name: "SpO2", content: <div><div>前回：{pastVital("spo2")}</div>{sa.numberC(cat, "spo2")}</div> },
            { name: "呼吸回数", content: <div><div>前回：{pastVital("kokyu_kaisu")}</div>{sa.numberC(cat, "kokyu_kaisu")}</div> },
            { name: "脈拍", content: <div><div>前回：{pastVital("myakuhaku")}</div>{sa.numberC(cat, "myakuhaku")}</div> },
            { name: "不整脈", content: sa.checkC(cat, "myakuhaku_husei") },
            { name: "備考", content: sa.textC(cat, "comment") },
        ]} />
        const contents = [
            {
                name: "訪問時", content: <div className={classes.innerTable}>
                    {vitals(["vital", "first"])}
                </div>
            },
            {
                name: "再検", content:
                    kiroku.vital.saiken ? <div className={classes.innerTable}>
                        {vitals(["vital", "saiken"])}
                        <Button variant="outlined" size="small" color="default"
                            onClick={(e) => { kiroku.vital.saiken = undefined; sa.updateState() }}>
                            削除
                        </Button>
                    </div>
                        : <div className={classes.innerTable}>
                            <Button variant="outlined" size="small" color="default"
                                onClick={(e) => { kiroku.vital.saiken = new Model.KirokuII.VitalData(); sa.updateState() }}>
                                ＋追加
                        </Button>
                        </div>
            },
        ]
        return contents
    }
}

export class Skin extends EditViewBase {
    displayName = "Skin"
    public static getTitle(): string { return "皮膚" }
    public getTitle(): string { return Skin.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku, patient_id } = this.props
        const patId = patient_id
        const contents = [
            {
                name: "アセスメント", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "ブレーデンスケール", content: sa.docWithPast(["skin"], "breden_doc", 11, patId, kiroku.pastDocumentPool) },
                    ]} />
                </div>
            },
            {
                name: "褥瘡の評価", content: <div className={classes.innerTable}>
                    {
                        kiroku.skin.jokuso.map((x, idx) => {
                            const cat = ["skin", "jokuso", idx]
                            return [<Col2Table key={`skin_jokuso_${idx}`} disableBGColor={true} body={[
                                {
                                    name: "DESIGN-R", content: <Col2Table disableBGColor={true} body={[
                                        { name: "D", content: sa.listC(cat, "d", Model.KirokuII.DesignR_D) },
                                        { name: "E", content: sa.listC(cat, "e", Model.KirokuII.DesignR_E) },
                                        { name: "S", content: sa.listC(cat, "s", Model.KirokuII.DesignR_S) },
                                        { name: "I", content: sa.listC(cat, "i", Model.KirokuII.DesignR_I) },
                                        { name: "G", content: sa.listC(cat, "g", Model.KirokuII.DesignR_G) },
                                        { name: "N", content: sa.listC(cat, "n", Model.KirokuII.DesignR_N) },
                                        { name: "P", content: sa.listC(cat, "p", Model.KirokuII.DesignR_P) },
                                    ]} />
                                },
                                { name: "合計点", content: <div>{Model.KirokuII.SkinJokuso.calcScore(x)}</div> },
                                { name: "写真", content: sa.docWithPast(cat, "docImage", 8, patId, kiroku.pastDocumentPool) },
                                {
                                    name: "処置・洗浄の実施", content: <Col2Table disableBGColor={true} body={[
                                        { name: "軟膏", content: sa.textC(["skin", "jokuso", idx, "senjo"], "nankou") },
                                        { name: "被覆材", content: sa.textC(["skin", "jokuso", idx, "senjo"], "himakuzai") },
                                    ]} />
                                },
                                { name: "褥瘡発見日", content: sa.dateC(cat, "hakken_date") },
                            ]} />,
                            <Button variant="outlined" size="small" color="default"
                                onClick={(e) => { kiroku.skin.jokuso = kiroku.skin.jokuso.filter((x, i) => i != idx); sa.updateState() }}>
                                ︎削除
                                </Button>,
                            <br />, <br />]
                        })
                    }
                    <Button variant="outlined" size="small" color="default"
                        onClick={(e) => { kiroku.skin.jokuso.push(new Model.KirokuII.SkinJokuso()); sa.updateState() }}>
                        ＋追加
                        </Button>
                </div>
            },
            {
                name: "褥瘡以外の皮膚トラブル", content: <div className={classes.innerTable}>
                    {
                        kiroku.skin.other.map((x, idx) => {
                            const cat = ["skin", "other", idx]
                            return [<Col2Table disableBGColor={true} body={[
                                {
                                    name: "タイトル", content: <div>
                                        {sa.listC(cat, "title", Model.KirokuII.SkinOtherTitle)}
                                        {sa.getValueC("title", cat) == Model.KirokuII.SkinOtherTitle.Other ?
                                            sa.textC(cat, "title_other", "その他内容") : null}
                                    </div>
                                },
                                { name: "写真", content: sa.docWithPast(cat, "docImage", 8, patId, kiroku.pastDocumentPool) },
                                { name: "備考", content: sa.textC(cat, "comment") },
                                {
                                    name: "処置・洗浄の実施", content: <Col2Table disableBGColor={true} body={[
                                        { name: "軟膏", content: sa.textC(["skin", "other", idx, "senjo"], "nankou") },
                                        { name: "皮膜材", content: sa.textC(["skin", "other", idx, "senjo"], "himakuzai") },
                                    ]} />
                                },
                                { name: "皮膚トラブル発見日", content: sa.dateC(cat, "hakken_date") },
                            ]} />,
                            <Button variant="outlined" size="small" color="default"
                                onClick={(e) => { kiroku.skin.other = kiroku.skin.other.filter((x, i) => i != idx); sa.updateState() }}>
                                ︎削除
                                </Button>,
                            <br />, <br />]
                        })
                    }
                    <Button variant="outlined" size="small" color="default"
                        onClick={(e) => { kiroku.skin.other.push(new Model.KirokuII.SkinOther()); sa.updateState() }}>
                        ＋追加
                        </Button>
                </div>
            },
        ]
        return contents
    }
}
export class Breath extends EditViewBase {
    displayName = "Breath"
    public static getTitle(): string { return "呼吸" }
    public getTitle(): string { return Breath.getTitle() }
    private breath_bui = [
        { label: "気管支", key: "kikanshi" },
        { label: "右上", key: "migi_ue" },
        { label: "右下", key: "migi_shita" },
        { label: "左上", key: "hidari_ue" },
        { label: "左下", key: "hidari_shita" },
        { label: "背の右上", key: "se_migi_ue" },
        { label: "背の右下", key: "se_migi_shita" },
        { label: "背の左上", key: "se_hidari_ue" },
        { label: "背の左下", key: "se_hidari_shita" }
    ]

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        const cat = ["breath", "kokyuon_ijou"]
        const comment_str = "_comment"
        const contents = [
            {
                name: "呼吸音（聴診）", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={
                        this.breath_bui.map((x) => {
                            return {
                                name: x.label, content: <div>
                                    {sa.listC(cat, x.key, Model.KirokuII.BreathSound)}
                                    {sa.textC(cat, x.key + comment_str, "備考")}
                                </div>
                            }
                        })
                    }
                    />
                </div>
            },
            {
                name: "痰・分泌", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "性状、色、量", content: sa.textC(["breath"], "tan_comment") }
                    ]} />
                </div>
            },
            {
                name: "排痰ケア", content: sa.textC(["breath"], "haitan_care")
            },
            {
                name: "在宅酸素療法(HOT)", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "酸素投与量", content: sa.numberC(["breath"], "hot_sanso_touyo_ryou", "", 0.25) },
                        {
                            name: "機器確認", content: sa.checkListC(["breath"], [
                                { key: "hot_kiki_kakunin_filter", label: "フィルタ交換" },
                                { key: "hot_kiki_kakunin_mizu", label: "水交換" },
                                { key: "hot_kiki_kakunin_konsento", label: "コンセント確認" },
                                { key: "hot_kiki_kakunin_yobi_bonbe", label: "予備ボンベ残量" }
                            ])
                        }
                    ]} />
                </div>,
                values: [
                    new CatVal("hot_sanso_touyo_ryou", ["breath"]),
                    new CatVal("hot_kiki_kakunin_filter", ["breath"]),
                    new CatVal("hot_kiki_kakunin_mizu", ["breath"]),
                    new CatVal("hot_kiki_kakunin_konsento", ["breath"]),
                    new CatVal("hot_kiki_kakunin_yobi_bonbe", ["breath"]),
                ]
            },
            {
                name: "気管切開部観察", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "交換日", content: sa.dateC(["breath"], "kikan_sekkai_koukan_date") },
                        { name: "カフ圧チェック", content: sa.numberC(["breath"], "kikan_sekkai_kahu_atsu") },
                        {
                            name: "皮膚トラブル", content: <div className={classes.innerTable}>
                                {
                                    kiroku.breath.kikan_sekkai_hihu_trouble.map((x, idx) => {
                                        const cat = ["breath", "kikan_sekkai_hihu_trouble", idx]
                                        return [<Col2Table disableBGColor={true} body={[
                                            {
                                                name: "種類", content: <div>
                                                    {sa.listC(cat, "troubleType", Model.KirokuII.BreathHihuTroubleType)}
                                                    {sa.getValueC("troubleType", cat) == Model.KirokuII.BreathHihuTroubleType.Other ?
                                                        sa.textC(cat, "troubleOther", "その他内容") : null}
                                                </div>
                                            },
                                            { name: "画像", content: sa.docWithPast(cat, "docImage", 8, patId, kiroku.pastDocumentPool) },
                                        ]} />,
                                        <Button variant="outlined" size="small" color="default"
                                            onClick={(e) => {
                                                kiroku.breath.kikan_sekkai_hihu_trouble = kiroku.breath.kikan_sekkai_hihu_trouble.filter((x, i) => i != idx);
                                                sa.updateState()
                                            }}>
                                            ︎削除
                                        </Button>,
                                        <br />, <br />]
                                    })
                                }
                                <Button variant="outlined" size="small" color="default"
                                    onClick={(e) => { kiroku.breath.kikan_sekkai_hihu_trouble.push(new Model.KirokuII.BreathHihuTrouble()); sa.updateState() }}>
                                    ＋追加
                        </Button>
                            </div>
                        },
                        { name: "薬剤（軟膏）", content: sa.textC(["breath"], "kikan_sekkai_yakuzai") },
                        { name: "吸引の長さ(cm)", content: sa.numberC(["breath"], "kikan_sekkai_kyuin") }
                    ]} />
                </div>,
                values: [
                    new CatVal("kikan_sekkai_koukan_date", ["breath"]),
                    new CatVal("kikan_sekkai_kahu_atsu", ["breath"]),
                    new CatVal("kikan_sekkai_hihu_trouble", ["breath"]),
                    new CatVal("kikan_sekkai_yakuzai", ["breath"]),
                    new CatVal("kikan_sekkai_kyuin", ["breath"])
                ]
            },
            {
                name: "人工呼吸器", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "種類", content: sa.listC(["breath"], "jinko_kokyuki_type", Model.KirokuII.BreathJinkoKokyukiType) },
                        { name: "設定(写真)", content: sa.docWithPast(["breath"], "jinko_kokyuki_setting_image", 8, patId, kiroku.pastDocumentPool) },
                        { name: "TV(ml/回)", content: this.pastNumberC(["breath"], "jinko_kokyuki_tv") },
                        { name: "MV(l/分)", content: this.pastNumberC(["breath"], "jinko_kokyuki_mv") },
                        { name: "RR(回/分)", content: this.pastNumberC(["breath"], "jinko_kokyuki_rr") },
                        { name: "PIP(cmH2o)", content: this.pastNumberC(["breath"], "jinko_kokyuki_pip", 1) },
                        { name: "リーク(ml/分)", content: this.pastNumberC(["breath"], "jinko_kokyuki_leak", 1) },
                        { name: "酸素(L/分)", content: this.pastNumberC(["breath"], "jinko_kokyuki_sanso", 0.25) },
                        { name: "その他", content: this.pastTextC(["breath"], "jinko_kokyuki_other") },
                        { name: "加温加湿", content: this.pastTextC(["breath"], "jinko_kokyuki_kaon_kashitsu") },
                        { name: "回路交換実施", content: sa.listC(["breath"], "jinko_kokyuki_is_kairo_koukan", Model.KirokuII.AriNashi) },
                        { name: "備考", content: sa.textC(["breath"], "jinko_kokyuki_comment") }
                    ]} />
                </div>,
                values: [
                    new CatVal("jinko_kokyuki_type", ["breath"]),
                    new CatVal("jinko_kokyuki_setting_image", ["breath"]),
                    new CatVal("jinko_kokyuki_tv", ["breath"]),
                    new CatVal("jinko_kokyuki_mv", ["breath"]),
                    new CatVal("jinko_kokyuki_rr", ["breath"]),
                    new CatVal("jinko_kokyuki_pip", ["breath"]),
                    new CatVal("jinko_kokyuki_leak", ["breath"]),
                    new CatVal("jinko_kokyuki_sanso", ["breath"]),
                    new CatVal("jinko_kokyuki_other", ["breath"]),
                    new CatVal("jinko_kokyuki_kaon_kashitsu", ["breath"]),
                    new CatVal("jinko_kokyuki_is_kairo_koukan", ["breath"]),
                    new CatVal("jinko_kokyuki_comment", ["breath"])
                ]
            },
            {
                name: "呼吸苦", content: <div className={classes.innerTable}>
                    {sa.listC(["breath"], "kokyu_ku_type", Model.KirokuII.KokyuKuType)}
                    {
                        kiroku.breath.kokyu_ku_type == Model.KirokuII.KokyuKuType.NRS ? <div>
                            <div className={classes.tooltipRoot}>
                                <Tooltip title={nrsTooltip(classes)}><Icons.HelpOutlineOutlined /></Tooltip>
                            </div>
                            {sa.numberC(["breath"], "kokyu_ku_nrs")}
                        </div> : null
                    }
                    {
                        kiroku.breath.kokyu_ku_type == Model.KirokuII.KokyuKuType.VAS ? <div>
                            <div>左：息苦しさはない</div>
                            <div>右：想像しうる最もひどい息苦しさ</div>
                            {sa.sliderC(["breath"], "kokyu_ku_vas")}
                        </div>
                            : null
                    }
                    {
                        kiroku.breath.kokyu_ku_type == Model.KirokuII.KokyuKuType.Borg ?
                            sa.listRawC(["breath"], "kokyu_ku_borg", [
                                { key: "0:何も感じない", value: 0 },
                                { key: "0.5:非常に弱い", value: 0.5 },
                                { key: "1:かなり弱い", value: 1 },
                                { key: "2:弱い", value: 2 },
                                { key: "3:", value: 3 },
                                { key: "4:多少強い", value: 4 },
                                { key: "5:強い", value: 5 },
                                { key: "6:", value: 6 },
                                { key: "7:とても強い", value: 7 },
                                { key: "8:", value: 8 },
                                { key: "9:", value: 9 },
                                { key: "10:非常に強い", value: 10 }
                            ])
                            : null
                    }
                </div>,
                values: [
                    new CatVal("kokyu_ku_type", ["breath"]),
                    new CatVal("kokyu_ku_nrs", ["breath"]),
                    new CatVal("kokyu_ku_nrs", ["breath"]),
                    new CatVal("kokyu_ku_borg", ["breath"])
                ]
            },
            {
                name: "アセスメント（機能評価）", content: <Col2Table disableBGColor={true} body={[
                    { name: "mMRC", content: sa.docWithPast(["breath"], "mmrc_doc", 13, patId, kiroku.pastDocumentPool) },
                ]} />
            },
            { name: "検査結果(写真)", content: sa.docWithPast(["breath"], "kensakekka_image", 8, patId, kiroku.pastDocumentPool) },
            {
                name: "薬剤", content: <Col2Table disableBGColor={true} body={[
                    { name: "吸入薬", content: sa.textC(["breath"], "yakuzai_kyuin_yaku") },
                    { name: "その他", content: sa.textC(["breath"], "yakuzai_other") },
                ]} />
            }
        ]
        return contents
    }
}
export class Pain extends EditViewBase {
    displayName = "Pain"
    public static getTitle(): string { return "疼痛" }
    public getTitle(): string { return Pain.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props

        const starsj_tooltip = [
            <div className={classes.tooltipDiv}>
                <div className={classes.tooltipTitle}>痛みのコントロール：痛みが患者に及ぼす影響</div>
                <div className={classes.tooltipLine}>０=なし</div>
                <div className={classes.tooltipLine}>１=時折の、または断続的な単一の痛みで、患者が今以上の治療を必要としない痛みである。</div>
                <div className={classes.tooltipLine}>２=中程度の痛み。時に調子の悪い日もある。痛みのため、病状からみると可能なはずの日常生活動作に支障をきたす。</div>
                <div className={classes.tooltipLine}>３=しばしばひどい痛みがある。痛みによって日常生活動作や物事への集中力に著しく支障をきたす。</div>
                <div className={classes.tooltipLine}>４=持続的な耐えられない激しい痛み。他のことを考えることができない。 </div>
            </div>,
            <div className={classes.tooltipDiv}>
                <div className={classes.tooltipTitle}>２．症状が患者に及ぼす影響：痛み以外の症状が患者に及ぼす影響</div>
                <div className={classes.tooltipLine}>０= なし</div>
                <div className={classes.tooltipLine}>１= 時折の、または断続的な単一または複数の症状があるが、日常生活を普通に送っており、患者が今以上の治療を必要としない症状である。</div>
                <div className={classes.tooltipLine}>２= 中等度の症状。時に調子の悪い日もある。病状からみると、可能なはずの日常生活動作に支障をきたすことがある。</div>
                <div className={classes.tooltipLine}>３= たびたび強い症状がある。症状によって日常生活動作や物事への集中力に著しく支障をきたす。</div>
                <div className={classes.tooltipLine}>４= 持続的な耐えられない激しい症状。他のことを考えることができない。 </div>
            </div>,
            <div className={classes.tooltipDiv}><div className={classes.tooltipTitle}>３．患者の不安：不安が患者に及ぼす影響</div>
                <div className={classes.tooltipLine}>０= なし</div>
                <div className={classes.tooltipLine}>１= 変化を気にしている。身体面や行動面に不安の兆候は見られない。集中力に影響はない。</div>
                <div className={classes.tooltipLine}>２= 今後の変化や問題に対して張り詰めた気持ちで過ごしている。時々、身体面や行動面に不安の徴候が見られる。</div>
                <div className={classes.tooltipLine}>３= しばしば不安に襲われる。身体面や行動面にその徴候が見られる。物事への集中力に著しく支障をきたす。</div>
                <div className={classes.tooltipLine}>４= 持続的に不安や心配に強くとらわれている。他のことを考えることができない。</div>
            </div>,
            <div className={classes.tooltipDiv}><div className={classes.tooltipTitle}>４．家族の不安：不安が家族に及ぼす影響</div>
                <div className={classes.tooltipLine}>０= なし</div>
                <div className={classes.tooltipLine}>１= 変化を気にしている。身体面や行動面に不安の徴候は見られない。集中力に影響はない。</div>
                <div className={classes.tooltipLine}>２= 今後の変化や問題に対して張り詰めた気持ちで過ごしている。時々、身体面や行動面に不安の徴候が見られる。</div>
                <div className={classes.tooltipLine}>３= しばしば不安に襲われる。身体面や行動面にその徴候が見られる。物事への集中力に著しく支障をきたす。</div>
                <div className={classes.tooltipLine}>４= 持続的に不安や心配に強くとらわれている。他のことを考えることができない。 </div>
            </div>,
            <div className={classes.tooltipDiv}><div className={classes.tooltipTitle}>５．患者の病状認識：患者自身の予後に対する理解</div>
                <div className={classes.tooltipLine}>０= 予後について十分に認識している。</div>
                <div className={classes.tooltipLine}>１= 予後を２倍まで長く、または短く見積もっている。例えば、２－３ヶ月であろう予後を６ヶ月と考えている。</div>
                <div className={classes.tooltipLine}>２= 回復すること、または長生きすることに自信が持てない。例えば「この病気で死ぬ人もいるので、私も 近々そうなるかもしれない」と思っている。</div>
                <div className={classes.tooltipLine}>３= 非現実的に思っている。例えば、予後が３ヶ月しかない時に、１年後には普通の生活や仕事に復帰できると期待している。</div>
                <div className={classes.tooltipLine}>４= 完全に回復すると期待している。</div>
            </div>,
            <div className={classes.tooltipDiv}><div className={classes.tooltipTitle}>６．家族の病状認識：家族の予後に対する理解</div>
                <div className={classes.tooltipLine}>０= 予後について十分に理解している。</div>
                <div className={classes.tooltipLine}>１= 予後を２倍まで長く、または短く見積もっている。例えば、２－３ヶ月であろう予後を６ヶ月と考えている。</div>
                <div className={classes.tooltipLine}>２= 回復すること、または長生きすることに自信が持てない。例えば「この病気で死ぬ人もいるので、本人も近々そうなるかも知れない」と思っている。</div>
                <div className={classes.tooltipLine}>３= 非現実的に思っている。例えば、予後が３ヶ月しかない時に、１年後には普通の生活や仕事に復帰できると期待している。</div>
                <div className={classes.tooltipLine}>４= 患者が完全に回復することを期待している。</div>
            </div>,
            <div className={classes.tooltipDiv}><div className={classes.tooltipTitle}>７．患者と家族とのコミュニケーション：患者と家族とのコミュニケーションの深さと率直さ</div>
                <div className={classes.tooltipLine}>０= 率直かつ誠実なコミュニケーションが、言語的・非言語的になされている。</div>
                <div className={classes.tooltipLine}>１= 時々、または家族の誰かと率直なコミュニケーションがなされている。</div>
                <div className={classes.tooltipLine}>２= 状況を認識してはいるが、その事について話し合いがなされていない。患者も家族も現状に満足していない。あるいは、パートナーとは話し合っても、他の家族とは話し合っていない。</div>
                <div className={classes.tooltipLine}>３= 状況認識が一致せずコミュニケーションがうまくいかないため、気を使いながら会話が行われている</div>
                <div className={classes.tooltipLine}>４= うわべだけのコミュニケーションがなされている。 </div>
            </div>,
            <div className={classes.tooltipDiv}><div className={classes.tooltipTitle}>８．職種間のコミュニケーション：患者と家族の困難な問題についての、スタッフ間での情報交換の早さ、正確さ、充実度</div>
                <div className={classes.tooltipLine}>０= 詳細かつ正確な情報が関係スタッフ全員にその日のうちに伝えられる。</div>
                <div className={classes.tooltipLine}>１= 主要スタッフ間では正確な情報伝達が行われる。その他のスタッフ間では、不正確な情報伝達や遅れが生じることがある。</div>
                <div className={classes.tooltipLine}>２= 管理上の小さな変更は、伝達されない。重要な変更は、主要スタッフ間でも１日以上遅れて伝達される。</div>
                <div className={classes.tooltipLine}>３= 重要な変更が数日から１週間遅れで伝達される。例）退院時の病棟から在宅担当医への申し送りなど。</div>
                <div className={classes.tooltipLine}>４= 情報伝達がさらに遅れるか、全くない。他のどのようなスタッフがいつ訪ねているのかわからない。</div>
            </div>,
            <div className={classes.tooltipDiv}><div className={classes.tooltipTitle}>９．患者・家族に対する医療スタッフのコミュニケーション：患者や家族が求めた時に医療スタッフが提供する情報の充実度</div>
                <div className={classes.tooltipLine}>０= すべての情報が提供されている。患者や家族は気兼ねなく尋ねることができる。</div>
                <div className={classes.tooltipLine}>１= 情報は提供されているが、充分理解されてはいない。</div>
                <div className={classes.tooltipLine}>２= 要求に応じて事実は伝えられるが、患者や家族はそれより多くの情報を望んでいる可能性がある。</div>
                <div className={classes.tooltipLine}>３= 言い逃れをしたり、実際の状況や質問を避けたりする。</div>
                <div className={classes.tooltipLine}>４= 質問への回答を避けたり、訪問を断る。正確な情報が与えられず、患者や家族を悩ませる </div>
            </div>
        ]

        const starstoolTip = (idx, name) => <div>
            <div style={{ display: "inline-block" }}>{sa.listRawC(["pain"], name, list01234)}</div>
            <div className={classes.tooltipRoot}>
                <Tooltip title={starsj_tooltip[idx]}><Icons.HelpOutlineOutlined /></Tooltip>
            </div>
        </div>


        const contents = [
            { name: "痛みの種類", content: sa.listC(["pain"], "pain_type", Model.KirokuII.PainType) },
            { name: "痛みのパターン", content: sa.listC(["pain"], "pain_pattern", Model.KirokuII.PainPattern) },
            {
                name: "主観的評価", content: <div className={classes.innerTable}>
                    {sa.listC(["pain"], "shukan_hyouka_type", Model.KirokuII.PainShukanType)}
                    {
                        kiroku.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.NRS ? <div>
                            <div className={classes.tooltipRoot}>
                                <Tooltip title={nrsTooltip(classes)}><Icons.HelpOutlineOutlined /></Tooltip>
                            </div>
                            {sa.numberC(["pain"], "shukan_hyouka_nrs")}
                        </div> : null
                    }
                    {
                        kiroku.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.VAS ? <div>
                            <div>左：全く痛みがない</div>
                            <div>右：これ以上の強い痛みは考えられない、または最悪の痛み</div>
                            {sa.sliderC(["pain"], "shukan_hyouka_vas")}
                        </div>
                            : null
                    }
                    {
                        kiroku.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.FaceScale ?
                            sa.numberC(["pain"], "shukan_hyouka_face_scale")
                            : null
                    }
                </div>
            },
            {
                name: "苦痛の質評価(STAS-J)", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "1.痛みのコントロール", content: starstoolTip(0, "stasj_s1") },
                        { name: "2.症状が患者に及ぼす影響", content: starstoolTip(1, "stasj_s2") },
                        { name: "3.患者の不安", content: starstoolTip(2, "stasj_s3") },
                        { name: "4.家族の不安", content: starstoolTip(3, "stasj_s4") },
                        { name: "5.患者の病状認識", content: starstoolTip(4, "stasj_s5") },
                        { name: "6.家族の病状認識", content: starstoolTip(5, "stasj_s6") },
                        { name: "7.患者と家族のコミュニケーション", content: starstoolTip(6, "stasj_s7") },
                        { name: "8.職種間のコミュニケーション", content: starstoolTip(7, "stasj_s8") },
                        { name: "9.患者・家族に対する医療スタッフのコミュニケーション", content: starstoolTip(8, "stasj_s9") },
                    ]} /></div>
            },
            { name: "本人の表現", content: sa.textC(["pain"], "honnin_said") },
            {
                name: "薬剤", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "内服", content: sa.textC(["pain"], "yakuzai_naihuku") },
                        { name: "貼付薬", content: sa.textC(["pain"], "yakuzai_harituke") },
                        { name: "注射", content: sa.textC(["pain"], "yakuzai_chusha") },
                        { name: "レスキュー（なにを/頻度)", content: sa.textC(["pain"], "yakuzai_rescue") },
                    ]} />
                </div>
            },

        ]
        return contents
    }
}
export class Oral extends EditViewBase {
    displayName = "Oral"
    public static getTitle(): string { return "口腔" }
    public getTitle(): string { return Oral.getTitle() }
    public getHash(props: Props) { return JSON.stringify(props.kiroku.oral) }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [
            { name: "O-HAT", content: sa.docWithPast(["oral"], "ohat_document", 12, patId, kiroku.pastDocumentPool) },
            { name: "RSST", content: sa.docWithPast(["oral"], "rsst_document", 14, patId, kiroku.pastDocumentPool) },
            { name: "改定水飲みテスト", content: sa.docWithPast(["oral"], "mizunomi_document", 15, patId, kiroku.pastDocumentPool) },
            { name: "フードテスト", content: sa.docWithPast(["oral"], "food_document", 16, patId, kiroku.pastDocumentPool) },
            {
                name: "口腔衛生", content: <Col2Table disableBGColor={true} body={[
                    { name: "歯列や舌など", content: sa.textC(["oral"], "shita_nado") },
                    { name: "備考", content: sa.textC(["oral"], "comment") },
                ]} />
            },
            {
                name: "セルフケア", content: <Col2Table disableBGColor={true} body={[
                    { name: "歯磨きの技術", content: sa.textC(["oral"], "gijutsu") },
                    { name: "すすぎ・うがい", content: sa.textC(["oral"], "susugi") },
                    { name: "歯磨き実施状況", content: sa.textC(["oral"], "hamigaki_jissi") },
                    { name: "自主リハビリの実施状況", content: sa.textC(["oral"], "riha_jissi") },
                ]} />
            },
            {
                name: "嚥下能力", content: <Col2Table disableBGColor={true} body={[
                    { name: "食事量と変化", content: sa.textC(["oral"], "sesshuryo") },
                    { name: "食事形態（主食）", content: sa.textC(["oral"], "keitai_shushoku") },
                    { name: "食事形態（副食）", content: sa.textC(["oral"], "keitai_hukushoku") },
                    { name: "食事形態（水分）", content: sa.textC(["oral"], "keitai_suibun") },
                    { name: "食事にかかる時間", content: sa.textC(["oral"], "shokuji_min") },
                    { name: "咽の有無", content: sa.listC(["oral"], "nodo_umu", Model.KirokuII.AriNashi) },
                ]} />
            }
        ]
        return contents
    }
}
export class Lang extends EditViewBase {
    displayName = "Lang"
    public static getTitle(): string { return "言語" }
    public getTitle(): string { return Lang.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [
            {
                name: "会話明瞭度", content: sa.docWithPast(["lang"], "meiryou_document", 17, patId, kiroku.pastDocumentPool)
            },
            {
                name: "構音障害", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "有無", content: sa.listC(["lang"], "kouon_shougai", Model.KirokuII.AriNashi) },
                        { name: "種類", content: sa.listC(["lang"], "kouon_type", Model.KirokuII.KouonType) },
                    ]} />
                </div>
            },
            {
                name: "失語", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "有無", content: sa.listC(["lang"], "shitsugo", Model.KirokuII.AriNashi) },
                        { name: "状況", content: sa.textC(["lang"], "shitsugo_type") },
                    ]} />
                </div>
            }
        ]
        return contents
    }
}
export class Nutrition extends EditViewBase {
    displayName = "Nutrition"
    public static getTitle(): string { return "栄養・代謝" }
    public getTitle(): string { return Nutrition.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id

        const contents = [
            {
                name: "基礎項目", content: <Col2Table disableBGColor={true} body={[
                    { name: "代謝計算", content: sa.textC(["nutrition"], "halis") },
                    this.taiju_elem(),
                    this.shincho_elem(),
                ]} />
            },
            {
                name: "観察項目", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "皮脂厚(mm)", content: sa.numberC(["nutrition"], "kansatsu_hisiatsu") },
                    ]} />
                </div>
            },
            {
                name: "摂取", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "食事摂取量", content: sa.textC(["nutrition"], "sesshu_shokuji") },
                        { name: "ミルク摂取量", content: sa.textC(["nutrition"], "sesshu_milk") },
                        {
                            name: "水分摂取量", content: <div className={classes.innerTable}>
                                <Col2Table disableBGColor={true} body={[
                                    { name: "量(ml/日)", content: sa.numberC(["nutrition"], "sesshu_suibun_value") },
                                    { name: "内容", content: sa.textC(["nutrition"], "sesshu_suibun_comment") }
                                ]} />
                            </div>
                        },
                        {
                            name: "栄養の投与", content: <div>
                                <Col2Table disableBGColor={true} body={[
                                    { name: "内容", content: sa.textC(["nutrition"], "sesshu_eiyou_naiyou") },
                                    { name: "経路", content: sa.listC(["nutrition"], "sesshu_eiyou_keiro", Model.KirokuII.NutritionKeiro) },
                                    { name: "量(ml)", content: sa.numberC(["nutrition"], "sesshu_eiyou_value") },
                                    { name: "水分量(ml)", content: sa.numberC(["nutrition"], "sesshu_eiyou_suibun") },
                                    { name: "トラブル", content: sa.textC(["nutrition"], "sesshu_eiyou_trouble") },
                                    { name: "写真", content: sa.docWithPast(["nutrition"], "sesshu_eiyou_image", 8, patId, kiroku.pastDocumentPool) },
                                ]} />
                            </div>
                        },
                    ]} />
                </div>
            },
            {
                name: "食事の方針", content: sa.textC(["nutrition"], "houshin")
                /*<div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "栄養補助食品の活用、代替療法の検討", content: sa.textC(["nutrition"], "houshin_hojoshokuhin") },
                        { name: "摂取支援者", content: sa.textC(["nutrition"], "houshin_sesshoku_shien") },
                        { name: "姿勢環境", content: sa.textC(["nutrition"], "houshin_sisei") },
                        { name: "本人の言葉", content: sa.textC(["nutrition"], "houshin_honnin_said") },
                    ]} />
                </div>*/
            },
            { name: "検査結果", content: sa.docWithPast(["nutrition"], "kensa_kekka_image", 8, patId, kiroku.pastDocumentPool) },
        ]
        return contents
    }
}
export class Dehyd extends EditViewBase {
    displayName = "Dehyd"
    public static getTitle(): string { return "脱水" }
    public getTitle(): string { return Dehyd.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes } = this.props
        const contents = [

            {
                name: "水分摂取量", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "量(ml/日)", content: sa.numberC(["nutrition"], "sesshu_suibun_value") },
                        { name: "内容", content: sa.textC(["nutrition"], "sesshu_suibun_comment") }
                    ]} />
                </div>
            },
            {
                name: "排泄量", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "尿量(ml/日)", content: sa.numberC(["dehydration"], "output_nyou") },
                        { name: "備考", content: sa.textC(["dehydration"], "output_comment") },
                    ]} />
                </div>
            },
            {
                name: "乾燥状態", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "口腔内", content: sa.textC(["dehydration"], "kansou_koukuu") },
                        { name: "腋窩", content: sa.textC(["dehydration"], "kansou_ekika") },
                    ]} />
                </div>
            },
            { name: "薬剤(内容、量、時間、経路)", content: sa.textC(["dehydration"], "yakuzai_tenteki") },
        ]
        return contents
    }
}
export class Excre extends EditViewBase {
    displayName = "Excre"
    public static getTitle(): string { return "排泄" }
    public getTitle(): string { return Excre.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [
            {
                name: "排便", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        {
                            name: "排便頻度", content: sa.textC(["excretion"], "hinben_hindo")
                        },
                        {
                            name: "最終排便", content: sa.textC(["excretion"], "hinben_last")
                        },
                        { name: "便性状(ブリストルスケール)", content: sa.listC(["excretion"], "haiben_keijou", Model.KirokuII.ExcretionKeijo) },
                        { name: "薬剤（下剤など）", content: sa.textC(["excretion"], "haiben_yakuzai") },
                    ]} />
                </div>
            },
            {
                name: "ストマ", content: <div>
                    <Col2Table disableBGColor={true} body={[
                        { name: "ストマの見た目", content: sa.docWithPast(["excretion"], "haiben_stoma_image", 8, patId, kiroku.pastDocumentPool) },
                        { name: "高さ(mm)", content: sa.numberC(["excretion"], "haiben_stoma_height") },
                        { name: "幅(mm)", content: sa.numberC(["excretion"], "haiben_stoma_width") },
                        { name: "パウチ・アクセサリ", content: sa.textC(["excretion"], "haiben_stoma_accessory") },
                        { name: "交換", content: sa.checkCommentC(["excretion"], "haiben_stoma_koukan_check", "haiben_stoma_koukan_biko", "次回交換日") },
                        { name: "トラブル", content: sa.textC(["excretion"], "haiben_stoma_trouble") },
                        { name: "薬剤（トラブルに対する）", content: sa.textC(["excretion"], "haiben_stoma_yakuzai") },
                        { name: "セルフケア（家族）", content: sa.textC(["excretion"], "haiben_stoma_selfcare") },
                    ]} />
                </div>,
                values: [
                    new CatVal("haiben_stoma_image", ["excretion"]),
                    new CatVal("haiben_stoma_height", ["excretion"]),
                    new CatVal("haiben_stoma_width", ["excretion"]),
                    new CatVal("haiben_stoma_accessory", ["excretion"]),
                    new CatVal("haiben_stoma_koukan_check", ["excretion"]),
                    new CatVal("haiben_stoma_koukan_biko", ["excretion"]),
                    new CatVal("haiben_stoma_trouble", ["excretion"]),
                    new CatVal("haiben_stoma_yakuzai", ["excretion"]),
                    new CatVal("haiben_stoma_selfcare", ["excretion"])
                ]
            },
            {
                name: "排尿", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        {
                            name: "観察項目", content: <Col2Table disableBGColor={true} body={[
                                { name: "性状、色、混濁", content: sa.textC(["excretion"], "hainyou_kansatsu_comment") },
                                { name: "血尿スケール", content: sa.textC(["excretion"], "hainyou_kansatsu_scale") },
                            ]} />
                        },
                        {
                            name: "排尿カテーテル", content: <div className={classes.innerTable}>
                                {
                                    kiroku.excretion.hainyou_katetel.map((x, idx) => {
                                        const cat = ["excretion", "hainyou_katetel", idx]
                                        return [<Col2Table disableBGColor={true} key={`kat_tb_${idx}`} body={[
                                            { name: "カテーテル種類", content: sa.listC(cat, "katetel_type", Model.KirokuII.ExcretionKatetelType) },
                                            { name: "交換", content: sa.checkCommentC(cat, "koukan_check", "koukan_comment", "次回交換日") },
                                            { name: "Frメモ", content: sa.numberC(cat, "fr_memo") },
                                            { name: "トラブル", content: sa.textC(cat, "trouble") },
                                            { name: "写真", content: sa.docWithPast(cat, "image", 8, patId, kiroku.pastDocumentPool) },
                                        ]} />,
                                        <Button variant="outlined" size="small" color="default"
                                            onClick={(e) => {
                                                kiroku.excretion.hainyou_katetel = kiroku.excretion.hainyou_katetel.filter((x, i) => i != idx);
                                                sa.updateState()
                                            }}>
                                            ︎削除
                                </Button>,
                                        <br />, <br />]
                                    })
                                }
                                <Button variant="outlined" size="small" color="default"
                                    onClick={(e) => { kiroku.excretion.hainyou_katetel.push(new Model.KirokuII.ExcretionKatetel()); sa.updateState() }}>
                                    ＋追加
                        </Button>
                            </div>
                        },
                        { name: "自己導尿", content: sa.textC(["excretion"], "hainyou_jiko_dounyou_shidou") },
                        {
                            name: "水分", content: <div>
                                <Col2Table disableBGColor={true} body={[
                                    { name: "量(記述)", content: sa.textC(["excretion"], "hainyou_jiko_dounyou_ml") },
                                    { name: "備考", content: sa.textC(["excretion"], "hainyou_jiko_dounyou_comment") },
                                ]} />
                            </div>
                        },
                        { name: "薬剤", content: sa.textC(["excretion"], "hainyou_yakuzai") },
                    ]} />
                </div>
            },
            {
                name: "摂取量", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "食事量", content: sa.textC(["excretion"], "input_shokuji") },
                        { name: "水分量", content: sa.textC(["excretion"], "input_suibun") },
                        {
                            name: "尿量", content: <div>
                                <Col2Table disableBGColor={true} body={[
                                    { name: "ml", content: sa.numberC(["excretion"], "input_nyou_ml") },
                                    { name: "備考", content: sa.textC(["excretion"], "input_nyou_comment") },
                                ]} />
                            </div>
                        },
                    ]} />
                </div>
            },
        ]
        return contents
    }
}
export class Physical extends EditViewBase {
    displayName = "Physical"
    public static getTitle(): string { return "身体活動" }
    public getTitle(): string { return Physical.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [
            { name: "移動", content: sa.textC(["physical"], "idou") },
            { name: "移乗", content: sa.textC(["physical"], "ijou") },
            { name: "MMT", content: sa.docWithPast(["physical"], "mmt_document", 29, patId, kiroku.pastDocumentPool) },
            { name: "ショートBBS", content: sa.docWithPast(["physical"], "short_bbs_document", 28, patId, kiroku.pastDocumentPool) },
            { name: "ベッドサイドモビリティスケール", content: sa.docWithPast(["physical"], "bedside_mobility_document", 18, patId, kiroku.pastDocumentPool) },
            { name: "自己効力感", content: sa.docWithPast(["physical"], "jiko_kouryoku_kan_document", 19, patId, kiroku.pastDocumentPool) },
            { name: "転倒の有無", content: sa.listC(["physical"], "tentou_umu", Model.KirokuII.AriNashi) },
            { name: "提案しているポジショニングの実施状況", content: sa.textC(["physical"], "positioning_state") },
            { name: "自主トレーニングの実施状況", content: sa.textC(["physical"], "jishu_training_state") }
        ]
        return contents
    }
}

export class Sleep extends EditViewBase {
    displayName = "Sleep"
    public static getTitle(): string { return "睡眠" }
    public getTitle(): string { return Sleep.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes } = this.props
        const contents = [
            {
                name: "不眠のパターン", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "不眠時", content: sa.listC(["sleep"], "humin_pattern", Model.KirokuII.SleepPattern) },
                        { name: "備考", content: sa.textC(["sleep"], "humin_comment") },
                    ]} />
                </div>
            },
            { name: "本人の言葉", content: sa.textC(["sleep"], "honnin_said") },
            { name: "薬剤", content: sa.textC(["sleep"], "yakuzai") }
        ]
        return contents
    }
}
export class Cognition extends EditViewBase {
    displayName = "Cognition"
    public static getTitle(): string { return "認知・意識" }
    public getTitle(): string { return Cognition.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa } = this.props
        const contents = [
            {
                name: "中核症状", content: sa.checkListC(["cognition"], [
                    { key: "chukakuKioku", label: "記憶障害" },
                    { key: "chukakuHandan", label: "判断力低下" },
                    { key: "chukakuKentou", label: "見当識障害" },
                    { key: "chukakuGengo", label: "言語障害" },
                    { key: "chukakuShikkou", label: "失行" },
                    { key: "chukakuShitsunin", label: "失認" }
                ])
            },
            { name: "中核症状(その他)", content: sa.textC(["cognition"], "chukaku_other") },
            {
                name: "周辺症状", content: sa.checkListC(["cognition"], [
                    { key: "shuhenSenmou", label: "せん妄" },
                    { key: "shuhenYokuutsu", label: "抑うつ" },
                    { key: "shuhenKouhun", label: "興奮" },
                    { key: "shuhenHaikai", label: "徘徊" },
                    { key: "shuhenSuimin", label: "睡眠障害" },
                    { key: "shuhenMousou", label: "妄想" }
                ])
            },
            { name: "周辺症状(その他)", content: sa.textC(["cognition"], "shuhen_other") },
            { name: "症状発生により困っていること", content: sa.textC(["cognition"], "komari") },
            { name: "本人ができること・強み", content: sa.textC(["cognition"], "tsuyomi") },
            {
                name: "意識レベル(JCS)", content: sa.listRawC(["cognition"], "ishiki", [
                    { value: "", key: "未選択" },
                    { value: "0", key: "0：意識清明" },
                    { value: "1", key: "I-1：だいたい清明であるが、今ひとつはっきりしない" },
                    { value: "2", key: "I-2：見当識障害がある（場所や時間、日付が分からない）" },
                    { value: "3", key: "I-3：自分の名前、生年月日が言えない" },
                    { value: "10", key: "II-10：普通の呼びかけで容易に開眼する" },
                    { value: "20", key: "II-20：大きな声または体を揺さぶることにより開眼する" },
                    { value: "30", key: "II-30：痛み刺激を加えつつ呼びかけを繰り返すことにより開眼する" },
                    { value: "100", key: "III-100：痛み刺激に対し、払いのける動作をする" },
                    { value: "200", key: "III-200：痛み刺激に対し、少し手足を動かしたり、顔をしかめたりする" },
                    { value: "300", key: "III-300：痛み刺激に反応しない" },
                ])
            },
            { name: "けいれん、てんかん", content: sa.textC(["cognition"], "keiren") }
        ]
        return contents
    }
} export class Circ extends EditViewBase {
    displayName = "Circ"
    public static getTitle(): string { return "循環" }
    public getTitle(): string { return Circ.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes } = this.props
        const contents = [
            {
                name: "In/Outバランス", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        this.taiju_elem(),
                        { name: "尿量(ml/日)", content: sa.numberC(["circulation"], "inout_nyou") },
                        { name: "in-outについて", content: sa.textC(["circulation"], "input_comment") },
                    ]} />
                </div>
            },
            { name: "浮腫", content: sa.listC(["circulation"], "mukumi", Model.KirokuII.CirculationMukumi) },
            {
                name: "心不全兆候", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "CVP", content: sa.numberC(["circulation"], "shinhuzen_choukou_cvp") },
                        { name: "NYHA", content: sa.listC(["circulation"], "shinhuzen_choukou_nyha", Model.KirokuII.CirculationNYHA) },
                    ]} />
                </div>
            },
            { name: "薬剤", content: sa.textC(["circulation"], "shinhuzen_yakuzai") },
            { name: "セルフケア", content: sa.textC(["circulation"], "shinhuzen_selfcare") }
        ]
        return contents
    }
}

export class Mental extends EditViewBase {
    displayName = "Mental"
    public static getTitle(): string { return "精神状態" }
    public getTitle(): string { return Mental.getTitle() }


    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku} = this.props
        const patId = this.props.patient_id
        const docTemp = this.state.docTemp
        if (!this.state.docTemp.pastGAF || !this.state.docTemp.pastGAF.loaded) {
            this.state.docTemp.pastGAF = {loaded: true}
            HokanApi.getDocumentLatest(patId, 55, this.props.kiroku.time_start || undefined).then(res => {
                const latest = res.data.content as FormModel.GAF.Core
                docTemp.pastGAF = {
                    loaded: true,
                    doc_id: res.data.id,
                    date: DateUtil.toJpDateString(DateUtil.dateToString(res.data.created_at)),
                    score: latest.values.score,
                    open: false
                }
                this.setState({ docTemp: docTemp })
                sa.updateState()
            }).catch(err => {
                this.setState({ docTemp: docTemp })
                console.log(err)
            })
        }
        const doc_gaf = this.props.kiroku.mental.doc_gaf 
        const contents = [
            {
                name: "アセスメント", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "MSE", content: sa.docWithPast(["mental"], "doc_mse", 22, patId, kiroku.pastDocumentPool) },
                        { name: "セルフケアモデル", content: sa.docWithPast(["mental"], "doc_selfcare", 20, patId, kiroku.pastDocumentPool) },
                        { name: "ストレングス整理", content: sa.docWithPast(["mental"], "doc_strength1", 21, patId, kiroku.pastDocumentPool) },
                        { name: "クライシスプラン", content: sa.docWithPast(["mental"], "doc_wrap", 24, patId, kiroku.pastDocumentPool) },
                        {
                            name: "GAF", content: <div>
                                {
                                    doc_gaf && doc_gaf.id != 0 ?
                                        <div>今回：{doc_gaf.content.values.score}</div>
                                    :null
                                }
                                {sa.docWithPast(["mental"], "doc_gaf", 55, patId, kiroku.pastDocumentPool)}
                                {
                                        this.state.docTemp.pastGAF ?
                                        <div>
                                                前回：{docTemp.pastGAF.score}（{docTemp.pastGAF.date}）
                                                <Button color="primary" size="small" variant="outlined"
                                                    onClick={(e) => {
                                                        docTemp.pastGAF.open = true
                                                        this.setState({ docTemp: docTemp})
                                                    }}
                                                    key={`hoken_button_${name}`}
                                                >確認</Button>
                                                {
                                                    docTemp.pastGAF.open ?
                                                        <DocumentDialog
                                                            patientId={patId}
                                                            only={55}
                                                            open={true}
                                                            srcId={this.state.docTemp.pastGAF.doc_id}
                                                            onSelect={(doc) => {
                                                                docTemp.pastGAF.open = false
                                                                this.setState({ docTemp: docTemp })
                                                            }}
                                                            onCansel={() => {
                                                                docTemp.pastGAF.open = false
                                                                this.setState({ docTemp: docTemp })
                                                            }}
                                                        />
                                                        : null
                                                }
                                        </div>
                                        : null
                                }
                            </div> },
                    ]} />
                </div>
            },
            {
                name: "症状兆候", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "気分変調", content: sa.textC(["mental"], "choukou_kibun_henchou") },
                        { name: "陽性症状", content: sa.textC(["mental"], "choukou_yousei") },
                        { name: "陰性症状", content: sa.textC(["mental"], "choukou_insei") },
                        { name: "認知機能障害", content: sa.textC(["mental"], "choukou_ninchi") },
                    ]} />
                </div>
            },
            { name: "対処行動", content: sa.textC(["mental"], "taisho") },
            { name: "サイン", content: sa.textC(["mental"], "sign") },
            {
                name: "薬剤", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "定時薬・頓服の状況", content: sa.textC(["mental"], "yakuzai_status") },
                        { name: "処方(写真)", content: sa.docWithPast(["mental"], "yakuzai_shohou_image", 8, patId, kiroku.pastDocumentPool) },
                    ]} />
                </div>
            },
            { name: "本人の意欲", content: sa.textC(["mental"], "honnin_said") },
            { name: "家族の言葉", content: sa.textC(["mental"], "kazoku_said") }
        ]
        return contents
    }
} 

export class Metabo extends EditViewBase {
    displayName = "Metabo"
    public static getTitle(): string { return "内分泌" }
    public getTitle(): string { return Metabo.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [
            {
                name: "血糖コントロール", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "血糖値(mg/dl)", content: sa.numberC(["metabolism"], "kettou_value") },
                        { name: "最終食事時間", content: sa.textC(["metabolism"], "kettou_last_shokuji_time") },
                        { name: "自己管理ノート", content: sa.docWithPast(["metabolism"], "kettou_note_image", 8, patId, kiroku.pastDocumentPool) },
                        { name: "自己注射・セルフケア", content: sa.textC(["metabolism"], "kettou_self_care") },
                    ]} />
                </div>
            },
            {
                name: "検査結果", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "検査データ", content: sa.docWithPast(["metabolism"], "kensakekka_image", 8, patId, kiroku.pastDocumentPool) },
                        { name: "備考", content: sa.textC(["metabolism"], "kensakekka_comment") },
                    ]} />
                </div>
            },
            { name: "薬情", content: sa.docWithPast(["metabolism"], "yakuzai_shohou_image", 8, patId, kiroku.pastDocumentPool) },
            {
                name: "透析", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "透析方法", content: sa.listC(["metabolism"], "touseki_houhou", Model.KirokuII.MetabolismTousekiHouhou) },
                        { name: "管理・観察", content: sa.textC(["metabolism"], "touseki_kanri") },
                        { name: "写真", content: sa.docWithPast(["metabolism"], "touseki_image", 8, patId, kiroku.pastDocumentPool) },
                    ]} />
                </div>
            },
            { name: "甲状腺", content: sa.textC(["metabolism"], "koujousen_choukou") },
        ]
        return contents
    }
} export class Growth extends EditViewBase {
    displayName = "Growth"
    public static getTitle(): string { return "発達・成長" }
    public getTitle(): string { return Growth.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props

        const kaupuTooltip = <div className={classes.tooltipRoot}>
            <Tooltip title={<div className={classes.tooltipDiv}>
                <div className={classes.tooltipTitle}>カウプ指数＝(体重g÷身長cmの2乗）X　10</div>
                <div className={classes.tooltipLine}>２２以上; 肥りすぎ</div>
                <div className={classes.tooltipLine}>２２－１９; 以前は（優良）現在は(少し太り気味）</div>
                <div className={classes.tooltipLine}>１９－１５; 正常</div>
                <div className={classes.tooltipLine}>１５－１３; やせ</div>
                <div className={classes.tooltipLine}>１３－１０; 栄養失調</div>
                <div className={classes.tooltipLine}>１０以下; 消耗症</div>
            </div>}><Icons.HelpOutlineOutlined /></Tooltip>
        </div>
        const shincho = sa.getValueC("shincho", ["vital"])
        const taiju = sa.getValueC("taiju", ["vital"])
        const taiju_unit = sa.getValueC("taiju_unit", ["vital"])
        let kaupu = "--"
        if (shincho && taiju) {
            kaupu = (taiju * 1000 / (shincho * shincho) * 10).toFixed(1).toString()
        }
        const contents = [
            this.taiju_elem(),
            this.shincho_elem(),
            { name: <span>カウプ指数{kaupuTooltip}</span>, content: kaupu },
            { name: "頭囲(cm)", content: this.pastNumberC(["growth"], "toui") },
            { name: "胸囲(cm)", content: this.pastNumberC(["growth"], "kyoui") },
            {
                name: "発達", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        { name: "粗大運動", content: sa.textC(["growth"], "hattatsu_sodai") },
                        { name: "微細運動", content: sa.textC(["growth"], "hattatsu_bisai") },
                        { name: "言語", content: sa.textC(["growth"], "hattatsu_gengo") },
                        { name: "認知社会", content: sa.textC(["growth"], "hattatsu_ninchi") },
                        { name: "食事", content: sa.textC(["growth"], "hattatsu_shokuji") },
                    ]} />
                </div>
            },
        ]
        return contents
    }
} export class Neuro extends EditViewBase {
    displayName = "Neuro"
    public static getTitle(): string { return "神経・筋・骨格" }
    public getTitle(): string { return Neuro.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa } = this.props
        const contents = [
            { name: "筋緊張", content: sa.textC(["neuro_muscul"], "kin_kinchou") },
            { name: "痙攣・てんかん", content: sa.textC(["neuro_muscul"], "keiren") },
            { name: "拘縮", content: sa.textC(["neuro_muscul"], "koushuku") },
            { name: "対処・処置・薬剤", content: sa.textC(["neuro_muscul"], "shochi") },
        ]
        return contents
    }
} export class EOL extends EditViewBase {
    displayName = "EOL"
    public static getTitle(): string { return "ACP/EOL" }
    public getTitle(): string { return EOL.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        let ppsScore: number | null = null
        if (kiroku.eol) {
            let pps: number | null = null
            if (kiroku.eol.pps) {
                const p = kiroku.eol.pps
                if (p <= 20) pps = 4.0
                else if (p <= 50) pps = 2.5
                else pps = 0
            }
            if (pps != null) {
                ppsScore = pps
                if (kiroku.eol.ppi_keikuu == Model.KirokuII.PPIKeikuu.Keikuu2) ppsScore += 1.0
                else if (kiroku.eol.ppi_keikuu == Model.KirokuII.PPIKeikuu.Keikuu3) ppsScore += 2.5
                if (kiroku.eol.ppi_mukumi == "あり") ppsScore += 1.0
                if (kiroku.eol.ppi_kokyu_konnnan == "あり") ppsScore += 3.5
                if (kiroku.eol.ppi_senmou == "あり") ppsScore += 4.0
            }
        }
        const contents = [
            {
                name: "アセスメント/評価", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={true} body={[
                        {
                            name: "PPI", content: <div className={classes.innerTable}>
                                <Col2Table disableBGColor={true} body={[
                                    {
                                        name: "PPS", content: <div>
                                            {sa.numberC(["eol"], "pps")}
                                            <a href="/images/pps_ppi.png" target="_blank">
                                                <Tooltip title={<div className={classes.tooltipDiv}>
                                                    <img src="/images/pps_ppi.png" width="100%" />
                                                </div>}
                                                ><Icons.HelpOutlineOutlined /></Tooltip>
                                            </a>
                                        </div>
                                    },
                                    {
                                        name: "経口摂取量", content: <div>
                                            {sa.listC(["eol"], "ppi_keikuu", Model.KirokuII.PPIKeikuu)}
                                            ※消化管閉塞のため高カロリー輸液を施行している場合は0点とする
                                        </div>
                                    },
                                    { name: "浮腫", content: sa.listC(["eol"], "ppi_mukumi", Model.KirokuII.AriNashi) },
                                    { name: "安静時呼吸困難", content: sa.listC(["eol"], "ppi_kokyu_konnnan", Model.KirokuII.AriNashi) },
                                    {
                                        name: "せん妄", content: <div>
                                            {sa.listC(["eol"], "ppi_senmou", Model.KirokuII.AriNashi)}
                                            ※「あり」に原因が薬物単独のものは含めない
                                        </div>
                                    },
                                    {
                                        name: "スコア", content: <div>{ppsScore != null ? ppsScore + " 点" : "--"}</div>
                                    },
                                    {
                                        name: "予想される予後", content: <div>
                                            6.5点以上：21日以下（週単位）の可能性が高い<br />
                                            3.5点以下：42日以上（月単位）の可能性が高い
                                        </div>
                                    }
                                ]} />
                            </div>
                        }
                    ]} />
                </div>
            },
            { name: "本人の言葉", content: sa.textC(["eol"], "honnin_said") },
            { name: "家族の言葉", content: sa.textC(["eol"], "kazoku_said") },
            { name: "アドバンスケアプランニング", content: sa.docWithPast(["eol"], "acl_document", 56, patId, kiroku.pastDocumentPool) }
        ]
        return contents
    }
}
export class Kaigo extends EditViewBase {
    displayName = "Kaigo"
    public static getTitle(): string { return "介護・養育" }
    public getTitle(): string { return Kaigo.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa } = this.props
        const contents = [
            { name: "養育上の危機・被養育者の状況", content: sa.textC(["kaigo"], "kiki") },
            { name: "現在の社会資源", content: sa.textC(["kaigo"], "shakai_shigen") },
            { name: "社会資源変更の必要性", content: sa.textC(["kaigo"], "henkou_need") },
            { name: "家族の認知・行動", content: sa.textC(["kaigo"], "kazoku_ninchi") }
        ]
        return contents
    }
}
export class Deal extends EditViewBase {
    displayName = "Deal"
    public static getTitle(): string { return "その他処置等" }
    public getTitle(): string { return Deal.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [
            { name: "処置内容（上記、記載以外）", content: sa.textC(["deal"], "naiyou") },
            { name: "その他備考", content: sa.textC(["deal"], "bikou") },
            { name: "画像", content: sa.docWithPast(["deal"], "naiyou_docImage", 8, patId, kiroku.pastDocumentPool) }
        ]
        return contents
    }
}
export class Moushiokuri extends EditViewBase {
    displayName = "Moushiokuri"
    public static getTitle(): string { return "申し送り事項" }
    public getTitle(): string { return Moushiokuri.getTitle() }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [
            { name: "内容", content: sa.textC(["deal"], "moushiokuri") },
            { name: "画像", content: sa.docWithPast(["deal"], "moushiokuri_docImage", 8, patId, kiroku.pastDocumentPool) }
        ]
        return contents
    }
}
/*
export class Template extends EditViewBase {
	displayName="Template"
    public static getTitle(): string { return "" }
    public getTitle(): string { return .getTitle() }

    public getContents() {
        const { sa, classes, kiroku } = this.props
        const contents = []
        return contents
    }
}
*/