import * as React from 'react';
import * as Colors from '../Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Styles from '@material-ui/core/styles';
import { Table, TableHead, TableCell, TableRow, TableBody, Tooltip, Button } from '@material-ui/core';
import { saveAs } from 'file-saver';
import Encoding from 'encoding-japanese';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        textAlign: "center"
    },
    title: {
    },
    value: {
        fontSize: "70px"
    },
    unit: {

    },
    elm: {
        marginTop: "30px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
    },
    tableHead: {
        background: Colors.primaryColor,
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px"
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
        },
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Props extends Styles.WithStyles {
    title: string,
    data: any,
    heads: string[],
    cols: string[],
    tooltip?: any,
    onClick?: (d: any) => void,
    csvData: string,
}

class GraphTable extends React.PureComponent<Props> {
    displayName = "Table"
    public render() {
        const { classes } = this.props;

        const onClick = () => {
            const str = Encoding.stringToCode(this.props.csvData);
            const convert = Encoding.convert(str, 'sjis', 'unicode');
            const u8a = new Uint8Array(convert);

            const blob = new Blob([u8a], { type: 'text/csv;charset=Shift_JIS;' });
            saveAs(blob, `${this.props.title}.csv`);
        }

        return <div className={classes.root}>
            <div className={classes.title} style={{display: "flex", justifyContent: "center"}}>
                <div style={{alignSelf: "center", marginLeft: "auto"}}>
                    {this.props.title}
                </div>
                <Button type="button" variant='text' onClick={onClick} style={{marginLeft: "auto"}}>CSV↓(Sjis)</Button>
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {
                            this.props.heads.map(h => {
                                return <CustomTableCell key={`h_${h}`}
                                    style={{ textAlign: "center", padding: "1px" }}>
                                    {h}
                                </CustomTableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.data ?
                            this.props.data.map((c, ci) => {
                                const res = <TableRow key={`gt_${ci}`} className={classes.row}
                                    style={this.props.onClick ? { cursor: "pointer" } : {}}
                                    onClick={() => {
                                        if (this.props.onClick) {
                                            this.props.onClick(c)
                                        }
                                    }}>
                                    {
                                        this.props.cols.map((x, xi) => {
                                            return <CustomTableCell key={`h_${ci}_${xi}`}
                                                style={{ textAlign: "center", padding: "1px" }}
                                                scope="row">
                                                {c[x]}
                                            </CustomTableCell>
                                        })
                                    }
                                </TableRow>
                                if (this.props.tooltip) {
                                    return <Tooltip key={`wtt_${ci}`} classes={{ tooltip: classes.tooltip }}
                                        title={this.props.tooltip(c)}
                                        placement="top" >
                                        {res}
                                    </Tooltip>
                                }
                                else {
                                    return res;
                                }
                            })
                            : null
                    }
                </TableBody>
            </Table>
        </div>
    }
}

export default Styles.withStyles(styles)(GraphTable)
