
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public create_time: string = ""
    public time_from: string = ""
    public time_to: string = ""
    public joukyou: string = ""
    public kango: string = ""
    public keii: string = ""
    public location: string = ""
    public omoi: string = ""
    public pat_name: string = ""
    public shusseki: string = ""
    public tanto_name: string = ""
    public kyoudou: string = ""
    public shorui: string = ""
    public taiin_go: string = ""
    public taiin_mae: string = ""
    public yotei: string = ""
}

export class Checks {
}