import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SubTitle from './SubTitle'
import * as Colors from './Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import { Button, Grid, MenuItem, Select, Tooltip } from '@material-ui/core';
import Pagination from "material-ui-flat-pagination";
import DocumentDialog from './DocumentDialog';
import { StoreAccessor } from '../util/StoreUtil';
import StandardTable from './StandardTable';
import Col2Table from './Col2Table';
import Loading from '../components/Loading'
const moment = require("moment-timezone")
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",
        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    bar: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.borderColor,
        backgroundColor: Colors.backColor
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        // background: Colors.primaryColor,
    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    tooltipDiv: {

    }
})

class Query {
    doc_type: number = 0
    comment: string = ""
    patient: Model.Patient.Patient = new Model.Patient.Patient()
}

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    onSelect: (doc: Model.Document.Document) => void
    typeId?: number
}

interface LocalState {
    documentList: Model.Document.DocumentList,
    documentCount: Model.Document.DocumentList,
    loading: boolean,
    page: number,
    openDialog: boolean,
    selectedDocumentId: number | null
    query: Query
    documentTypes: { key: string, value: number }[]
    branches: Model.Branch[]
    branch_id: number | null
    me: Model.User
    loadingCount: boolean
}

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

// @inject()
// @observer
class DocumentList extends React.Component<Props, LocalState> {
    displayName = "DocumentList"
    public static defaultProps = {
        typeId: undefined,
    }

    constructor(props: Props) {
        super(props)
        const q = new Query()
        if (props.typeId) {
            q.doc_type = props.typeId
        }
        this.state = {
            loading: false,
            branches: [],
            branch_id: 0,
            documentList: new Model.Document.DocumentList(),
            documentCount: new Model.Document.DocumentList(),
            page: 1,
            openDialog: false,
            selectedDocumentId: null,
            query: q,
            documentTypes: [],
            me: new Model.User(),
            loadingCount: false
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true, loadingCount: true })
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
            this.loadDocuments(0, 20, true)
        })
        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private loadTypes() {
        HokanApi.getDocumentTypes()
            .then(x => {
                const categories = new Set<string>()
                x.data.forEach(x => {
                    categories.add(x.category)
                })

                const types = Array.from(categories).map(c => {
                    return {
                        cat: c,
                        types: x.data.filter(t => t.category == c)
                    }
                })
                const dts: { key: string, value: number }[] = [{ key: "全書類", value: 0 }]
                types.forEach(t => {
                    t.types.forEach(tt => {
                        dts.push({ key: `[${tt.category}]${tt.name}`, value: tt.id })
                    })
                })
                this.setState({ documentTypes: dts })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private loadDocCount() {

    }

    private loadDocuments(page, per, reloadCount = false) {
        this.setState({ loading: true, loadingCount: reloadCount })
        this.loadTypes();
        const pat = this.state.query.patient
        const option = {
            patientId: (pat && pat.id != 0 ? pat.id : undefined),
            branchId: this.state.branch_id ? this.state.branch_id : undefined,
            typeId: this.state.query.doc_type ? this.state.query.doc_type : undefined,
            title: this.state.query.comment,
            comment: this.state.query.comment,
            is_simple: true
        }

        if (reloadCount) {
            HokanApi.getDocumentsCount(page + 1, per, option).then(res => {
                this.setState({
                    documentCount: res.data,
                    loadingCount: false
                });
            })
                .catch(e => {
                    console.log(e);
                    this.setState({ loadingCount: false })
                })
        }
        else {
            this.setState({ loadingCount: false })
        }

        HokanApi.getDocuments(page + 1, per, option).then(res => {
            res.data.documents.forEach(x => x.content = JSON.parse(x.content))
            this.setState({
                documentList: res.data,
                loading: false
            });
        })
            .catch(e => {
                console.log(e);
                this.setState({ loading: false })
            })
    }

    private reload() {
        const { count } = this.state.documentCount;
        const { page, per } = this.state.documentList;
        const offset = (page - 1) * per
        this.loadDocuments(page - 1, per, true)
    }

    public render() {
        const { classes } = this.props;
        const { documentList, documentCount } = this.state;

        const sa = new StoreAccessor(this, true)
        const saQ = sa.generateChild(["query"])
        const element_l = [
            {
                name: "", content: <div style={{ display: "inline-flex" }}>
                    {saQ.listRaw("doc_type", this.state.documentTypes)}
                    <Select
                        value={this.state.branch_id}
                        autoWidth={true}
                        variant='outlined'
                        onChange={(e) => {
                            this.setState({ branch_id: e.target.value as (number | null) })
                        }}
                        style={{ display: "inline-flex" }}>
                        <MenuItem key='all' value={0}>全事業所</MenuItem>
                        {
                            this.state.branches.map((t, idx) =>
                                <MenuItem key={`lis+${t.id}`} value={t.id}>{t.name}</MenuItem>
                            )
                        }
                    </Select>
                    {saQ.patient("patient", "patient_dialog", "利用者")}
                </div>
            },
            {
                name: "", content: <div>{saQ.text("comment", "書類名、タイトル、コメント、利用者名、更新者名",
                    "text", "block", false, null, () => {
                        if (this.state.loading == false) {
                            this.loadDocuments(0, 20, true)
                        }
                    }
                )}</div>
            },
            {
                name: "", content: <Button color="primary" variant="contained"
                    disabled={this.state.loading}
                    onClick={(e) => { this.loadDocuments(0, 20, true) }} >検索</Button>
            }
        ]

        console.log(documentList)
        console.log(documentCount)

        const paging = this.state.loadingCount ?
            <div style={{ padding: "10px" }}>
                <img src="images/loading.gif" style={{ width: "10px" }} />
            </div>
            :
            <Pagination
                limit={documentList.per}
                offset={(documentList.page - 1) * documentList.per}
                total={documentCount.count}
                disabled={this.state.loadingCount}
                onClick={(e, offset) => {
                    console.log(offset)
                    this.loadDocuments(offset / documentList.per, documentList.per, false)
                }}
            />

        /*    
        <Pagination
            limit={documentCount.count}
            offset={(documentCount.page - 1) * documentCount.per}
            total={documentCount.count}
            disabled={this.state.loadingCount}
            onClick={(e, offset) => {
                this.loadDocuments(offset / documentCount.per, documentCount.per)
            }}
        />*/
        const toShortStr = (s) => {
            if (s.length > 10) return `${s.substr(0, 10)}...`
            return s
        }
        return <div className={classes.root}>
            <StandardTable title=''>
                <Grid container={true} spacing={0}>
                    <Grid item={true} xs={12} md={12} lg={12}>
                        <Col2Table body={element_l} />
                    </Grid>
                </Grid>
            </StandardTable>
            <div>
                <div className={classes.listRoot}>
                    <div style={{ textAlign: "center" }}>
                        <Button variant="contained" color="primary" onClick={(e) => { this.setState({ openDialog: true }) }}>
                            新規作成
                        </Button>
                        <div style={{ marginTop: "10px" }}>
                            {paging}
                        </div>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell style={{ textAlign: "center" }}>利用者</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>書類名</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>タイトル</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>コメント</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>一時保存</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>作成日</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>更新日</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>更新者</CustomTableCell>
                                    <CustomTableCell align="right"></CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.loading ?
                                        <Loading /> :
                                        documentList.documents.map(c => {
                                            const ct = c.content
                                            return <TableRow key={c.id} className={classes.row}
                                                onClick={(e) => {
                                                    this.setState({
                                                        openDialog: true,
                                                        selectedDocumentId: c.id
                                                    })
                                                }}>
                                                <CustomTableCell style={{ textAlign: "left" }} component="th" scope="row">{c.patient ? c.patient.name : ""}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "left" }} component="th" scope="row">{c.document_type.name}{c.target_month ? `(${c.target_month})` : ""}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "left" }} component="th" scope="row">
                                                    <Tooltip title={c.title}>
                                                        <div>{toShortStr(c.title)}{ct.values && ct.values.title ? toShortStr(ct.values.title) : null}</div>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "left" }} component="th" scope="row">
                                                    <Tooltip title={c.comment}>
                                                        <div>{toShortStr(c.comment)} {ct.values && ct.values.comment ? toShortStr(ct.values.comment) : null}</div>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">
                                                    {c.is_temp ? "●" : "　"}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">
                                                    {c.content && c.content.values && c.content.values.create_date ?
                                                        moment(c.content.values.create_date).format("YYYY-MM-DD") :
                                                        moment(c.created_at).tz("Asia/Tokyo").format("YYYY-MM-DD")}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">
                                                    {c.updated_at ?
                                                        moment(c.updated_at).tz("Asia/Tokyo").format("YYYY-MM-DD") : ""}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">
                                                    {c.updated_by ? c.updated_by.name : ""}
                                                </CustomTableCell>
                                                <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                            </TableRow>
                                        })
                                }
                            </TableBody>
                        </Table>
                        {paging}
                        {
                            this.state.openDialog ?
                                <DocumentDialog key={`dialog_${this.state.selectedDocumentId}`}
                                    open={true}
                                    only={null}
                                    srcId={this.state.selectedDocumentId}
                                    onCansel={
                                        (deleted) => {
                                            this.setState({ openDialog: false, selectedDocumentId: null })
                                            if (deleted) {
                                                this.reload()
                                            }
                                        }
                                    }
                                    onSelect={(doc: Model.Document.Document) => {
                                        this.setState({ openDialog: false, selectedDocumentId: null })
                                        this.reload()
                                        this.props.onSelect(doc)
                                    }}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(DocumentList))