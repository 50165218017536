import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import * as HokanApi from '../../api/hokan'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"

    public getId(): number {
        return 58
    }

    public createContent(): any {
        const res = new FormModel.TaiinCanfa.Core()
        res.values.create_date = DateUtil.currentDateStr()
        res.values.time_from = DateUtil.currentDateTimeStr()
        res.values.time_to = DateUtil.currentDateTimeStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.TaiinCanfa.Core
        content.values.create_time = `${DateUtil.toTimeStr(content.values.time_from)}〜${DateUtil.toTimeStr(content.values.time_to)}`
    }

    public beforeMount = () => {

    }
    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        doc.patient = pat;
        const c = doc.content as FormModel.TaiinCanfa.Core
        HokanApi.me().then(res => {
            c.values.tanto_name = res.data.name
            sa.updateState();
        }).catch((e) => { console.log(e) })
    }


    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const saD = sa.generateChild(["content"])

        const elements_t = [
            { name: "開始時間", content: saV.dateTime("time_from", "time_to") },
            { name: "終了時間", content: saV.dateTime("time_to") },
            { name: "場所", content: saV.text("location") },
            { name: "出席者", content: saV.text("shusseki") },
            { name: "担当者", content: saV.text("tanto_name") },
            { name: "指導加算", content: saD.check("is_shidou_kasan") }, // 特別管理指導加算
            {
                name: "内容", content: <div>
                    <div>
                        入院の経緯と治療方針：
                    {saV.text("keii")}
                    </div>
                    <div>
                        本人家族の思い、今一番不安なこと：
                    {saV.text("omoi")}
                    </div>
                    <div>
                        入院中の看護とリハ：
                    {saV.text("kango")}
                    </div>
                    <div>
                        現在の状況と、家での生活に戻った時の課題：
                    {saV.text("joukyou")}
                    </div>
                    <div>
                        退院前の追加指導や準備：
                    {saV.text("taiin_mae")}
                    </div>
                    <div>
                        退院後に必要な指導や準備：
                    {saV.text("taiin_go")}
                    </div>
                    <div>
                        退院前後の予定：
                    {saV.text("yotei")}
                    </div>
                    <div>
                        指示書や公費申請など書類：
                    {saV.text("shorui")}
                    </div>
                    <div>
                        退院時共同指導した内容：
                    {saV.text("kyoudou")}
                    </div>

                </div>
            }
        ]

        const elements = [
            { name: '内容', content: elements_t }
        ]

        return elements
    }
}
