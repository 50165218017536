
export class Core {
    public values: Values = new Values();
    public indexes: Indexes = new Indexes();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""

    public text0: string = ""
    public text1: string = ""
    public text2: string = ""
    public text3: string = ""
    public text4: string = ""
    public text5: string = ""
    public text6: string = ""
    public text7: string = ""
    public text8: string = ""
    public text9: string = ""
    public text10: string = ""
    public text11: string = ""
    public text12: string = ""
    public text13: string = ""

    public sum_score: number = 0
}

export class Indexes {
    public value_0: number = -1
    public value_1: number = -1
    public value_2: number = -1
    public value_3: number = -1
    public value_4: number = -1
    public value_5: number = -1
    public value_6: number = -1
    public value_7: number = -1
    public value_8: number = -1
    public value_9: number = -1
    public value_10: number = -1
    public value_11: number = -1
    public value_12: number = -1
    public value_13: number = -1
}

export class Checks {
}