export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public create_date: string = "";
  public pat_name: string = ""
  public date_1: string = "";
  public date_2: string = "";
  public date_3: string = "";
  public date_4: string = "";
  public date_5: string = "";
  public date_6: string = "";
  public date_7: string = "";
  public date_8: string = "";
  public frequency_1: string = "";
  public frequency_2: string = "";
  public frequency_3: string = "";
  public frequency_4: string = "";
  public frequency_5: string = "";
  public frequency_6: string = "";
  public frequency_7: string = "";
  public frequency_8: string = "";
  public go_out_free_text: string = "";
  public scheduled_date_1: string = "";
  public scheduled_date_2: string = "";
  public scheduled_date_3: string = "";
  public scheduled_date_4: string = "";
  public scheduled_date_5: string = "";
  public scheduled_date_6: string = "";
  public scheduled_date_7: string = "";
  public scheduled_date_8: string = "";
  public sign_1: string = "";
  public sign_2: string = "";
  public sign_3: string = "";
  public sign_4: string = "";
  public sign_5: string = "";
  public sign_6: string = "";
  public sign_7: string = "";
  public sign_8: string = "";
  public material_const_1: string = "";
  public material_const_10: string = "";
  public material_const_11: string = "";
  public material_const_12: string = "";
  public material_const_2: string = "";
  public material_const_3: string = "";
  public material_const_4: string = "";
  public material_const_5: string = "";
  public material_const_6: string = "";
  public material_const_7: string = "";
  public material_const_8: string = "";
  public material_const_9: string = "";
  public material_const_others: string = "";
  public material_others: string = "";
  public emergency_free_text: string = "";
  public sign: string = "";
}

export class Checks {
  public check_emergency_1: boolean = false;
  public check_emergency_2: boolean = false;
  public check_emergency_3: boolean = false;
  public check_emergency_4: boolean = false;
  public check_go_out_1: boolean = false;
  public check_go_out_2: boolean = false;
  public check_material_1: boolean = false;
  public check_material_2: boolean = false;
  public check_material_3: boolean = false;
  public check_material_4: boolean = false;
  public check_material_5: boolean = false;
  public check_material_6: boolean = false;
  public check_material_7: boolean = false;
  public check_material_8: boolean = false;
  public check_material_9: boolean = false;
  public check_material_10: boolean = false;
  public check_material_11: boolean = false;
  public check_material_12: boolean = false;
}

export enum MaterialConstType {
  None = "未選択",
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value19 = 19,
  Value20 = 20,
}
