import * as React from 'react';

export default class extends React.Component {
    public render() {
        return <div style={{
            color: "gray",
            textAlign: "left",
            fontSize: "smaller",
            margin: "10px",
            marginTop: "30px"
        }}>
            <div>=== 出典 ===</div>
            <div>・PPI：Maltoni M, Nanni O, Pirovano M, et al: Successful validation of the palliative prognostic score in terminally ill cancer patients. Italian Multicenter Study Group on Palliative Care. J Pain Symptom Manage 17 (4): 240-247, 1999 をもとに翻訳 </div>
            <div>・PPS：Campos S, Zhang L, Sinclair E, et al: The palliative performance scale: examining its interrater reliability in an outpatient palliative radiation oncology clinic. Support Care Cancer 17(6): 685-690, 2009をもとに翻訳</div>
        </div>
    }
}

