import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import {
    ResponsiveContainer, ComposedChart, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar
} from 'recharts';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        height: "300px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
        //        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    }
});


interface Props extends Styles.WithStyles {
    data: any,
}


function genColor(idx: number) {
    return `hsl(${idx * 63 % 360}, 40%, 60%)`
}
function genColor2(idx: number) {
    return `hsla(${idx * 63 % 360}, 40%, 50%, 0.8)`
}
class HoumonMonthlyKirokuType extends React.PureComponent<Props> {
    displayName = "HoumonMonthlyKirokuType"
    public render() {
        const { classes } = this.props;
        const count = this.props.data.count;
        return <div className={classes.root}>
            <div>
                記録種類別訪問件数
            </div>
            <ResponsiveContainer width="100%" height="95%">
                <ComposedChart
                    data={this.props.data}
                    margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="月" padding={{ left: 30, right: 30 }} />
                    <YAxis yAxisId="right" orientation="right" />
                    <YAxis yAxisId="left" />
                    <Tooltip formatter={(value, name, props) => {
                        const a = value.toFixed(0)
                        const b = value.toFixed(1)
                        return (a + ".0") == b ? a : b
                    }} />
                    <Legend />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='その他' fill={"#555"} />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='精神訪看' fill={genColor2(6)} />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='医療リハ' fill={genColor2(6)} />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='医療訪看' fill={genColor2(6)} />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='介護訪看' fill={genColor2(7)} />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='介護リハ' fill={genColor2(7)} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    }
}

export default Styles.withStyles(styles)(HoumonMonthlyKirokuType)