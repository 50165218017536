export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public backbed_option: string = "";
  public contact_order_option_1: string = "";
  public contact_order_option_2: string = "";
  public contact_order_option_3: string = "";
  public contact_order_option_4: string = "";
  public contact_order_option_5: string = "";
  public contact_others_1: string = "";
  public contact_others_2: string = "";
  public contact_others_3: string = "";
  public contact_shujii: string = "";
  public create_date: string = "";
  public created_by: string = "";
  public enzeru_option: string = "";
  public ishi_option: string = "";
  public ishi_text: string = "";
  public mitori_date: string = "";
  public nozomi_family: string = "";
  public nozomi_own: string = "";
  public panfu_by: string = "";
  public panfu_option: string = "";
  public panfu_text: string = "";
  public panfu_date: string = "";
  public pat_name: string = "";
  public renkei_text_1: string = "";
  public renkei_text_2: string = "";
  public terminal_by: string = "";
  public terminal_date: string = "";
  public terminal_option: string = "";
  public terminal_subject: string = "";
  public terminal_subject_option: string = "";
  public terminal_text: string = "";
  public zaitaku_family: string = "";
  public zaitaku_family_option: string = "";
  public zaitaku_own: string = "";
  public zaitaku_own_option: string = "";
}

export class Checks {
  public check_contact_1: boolean = false;
  public check_contact_2: boolean = false;
  public check_contact_3: boolean = false;
  public check_contact_4: boolean = false;
  public check_renkei_1: boolean = false;
  public check_renkei_2: boolean = false;
}

export enum IshiOptionType {
  None = "未選択",
  Value1 = "確認できる",
  Value2 = "確認できない",
}

export enum ZaitakuOptionType {
  None = "未選択",
  Value1 = "積極的",
  Value2 = "迷っている",
  Value3 = "消極的",
}

export enum RenkeiOptionType {
  None = "未選択",
  Value1 = "なし",
  Value2 = "あり",
}

export enum JisshiOptionType {
  None = "未選択",
  Value1 = "実施済み",
  Value2 = "未実施",
}

export enum TerminalSubjectOptionType {
  None = "未選択",
  Value1 = "本人",
  Value2 = "家族",
  Value3 = "その他",
}

export enum ContactOptionType {
  None = "未選択",
  Value1 = "セコム緊急連絡",
  Value2 = "主治医連絡先",
  Value3 = "その他①",
  Value4 = "その他②",
  Value5 = "その他③",
}
