import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Loading from './Loading'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Colors from './Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import ButtonOkCancel from './ButtonOkCancel';
import DateUtil from '../util/DateUtil'
import { Select, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';


// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",
        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    showButton: boolean,
    isJiko: boolean,
}

interface LocalState {
    incidents: Model.Incident.Incident[]
    loading: boolean
    branch_id: number
    me: Model.User
    branches: Model.Branch[]
}


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

// @inject()
// @observer
class IncidentList extends React.Component<Props, LocalState> {
    displayName = "IncidentList"
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            incidents: [],
            branch_id: 0,
            me: new Model.User(),
            branches: []
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true })
        HokanApi.branch.list()
            .then(x => {
                return x.data
            })
            .then(branch_list => {
                HokanApi.me().then(x => {
                    const b = x.data.branch_id
                    const me = x.data
                    this.setState({ branch_id: 0, me: me, branches: branch_list.concat({ id: 0, name: "全事業所" } as Model.Branch) })
                    this.loadIncidents(0)
                })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private loadIncidents(bid: number): void {
        this.setState({ incidents: [], loading: true })
        HokanApi.getIncidents(bid, this.props.isJiko)
            .then(x => {
                this.setState({ branch_id: bid, incidents: x.data.map(y => Model.Incident.Incident.load(y)), loading: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ incidents: [], loading: false })
            })
    }

    public render() {
        const { classes, history } = this.props;
        const { incidents, loading } = this.state;
        const branch = this.state.branches.find(x => x.id == this.state.branch_id)
        return loading ? <Loading /> :
            <div>
                <div style={{ textAlign: "right", fontSize: "0.9rem" }}>
                    {
                        branch && this.state.branch_id != 0 ?
                            this.props.isJiko ?
                                branch.jiko_pdf_url ?
                                    <a href={branch.jiko_pdf_url} target="_blank">{branch.name}_事故マニュアル</a>
                                    : <div style={{ fontSize: "smaller" }}>「<Link to="manage/branch">事業所</Link>」ページで「事故マニュアル」を<br />登録するとここにリンクされます</div>
                                :
                                branch.claim_pdf_url ?
                                    <a href={branch.claim_pdf_url} target="_blank">{branch.name}_苦情マニュアル</a>
                                    : <div style={{ fontSize: "smaller" }}>「<Link to="manage/branch">事業所</Link>」ページで「事故マニュアル」を<br />登録するとここにリンクされます</div>
                            : null
                    }
                </div>
                <ButtonOkCancel
                    showBack={this.props.showButton}
                    cancelLabel="戻る" okLabel="新規"
                    onCancel={() => history.goBack()}
                    onOk={() => history.push(`/incident/0`)}
                    disabled={this.state.loading} />
                <div>
                    <Select
                        value={this.state.branch_id || 0}
                        autoWidth={true}
                        variant='outlined'
                        onChange={(e) => this.loadIncidents(e.target.value as number)}
                        className={classes.input}
                    >
                        {
                            this.state.branches.map((t, idx) =>
                                <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                            )
                        }
                    </Select>
                </div>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>種別</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>発生日時</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>事業所</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>スタッフ</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>利用者</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>対象者</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>事故分類</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>影響レベル</CustomTableCell>
                            <CustomTableCell align="right"></CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            incidents.map(c => {
                                return <TableRow key={c.id} className={classes.row}
                                    onClick={(e) => history.push(`/incident/${c.id}`)}>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} scope="row">{c.case_type ? c.case_type : "-"}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} scope="row">{DateUtil.toSlashDateStringShort(c.time_occured)}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} scope="row">{c.branch ? c.branch.name : "-"}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} scope="row">{c.main_user ? c.main_user.name : "-"}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} scope="row">{c.patient ? c.patient.name : "-"}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} scope="row">{c.taisho_type}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} scope="row">{c.jiko_type}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} scope="row">{c.eikyo_level}</CustomTableCell>
                                    <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>

            </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(IncidentList))