import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as Colors from './Colors'
import { DialogTitle, Dialog, withStyles, Button } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Icons from '@material-ui/icons';


const styles = () => Styles.createStyles({
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    dialog: {
        padding: "10px"
    },
    searchField: {
        maxWidth: "200px"
    }
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    }
}))(TableCell);


interface Props extends Styles.WithStyles<typeof styles> {
    open: boolean
    onSelect: (branch: Model.Branch, user: Model.SimpleUser | null) => void
    onCancel: () => void
    onlyBranch: boolean
}
interface LocalState {
    dialogElement: JSX.Element
    branches: Model.Branch[]
    selectedBranch: Model.Branch | null
    searchText: string,
    me: Model.User
}

class UserSelectDialog extends React.Component<Props, LocalState> {
    displayName = "UserSelectDialog"
    public static defaultProps = {
        onlyBranch: false
    }
    constructor(props: Props) {
        super(props)
        this.state = {
            dialogElement: <div />,
            branches: [],
            selectedBranch: null,
            searchText: "",
            me: new Model.User()
        }
    }

    public componentDidMount = () => {
        this.loadBranches();
    }

    private loadBranches() {
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
        })
            .catch(err => {
                console.log(err)
            })
        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
                this.setState({ branches: [] })
            })
    }

    private loadUser(branchId: number) {
        HokanApi.branch.get(branchId)
            .then(x => {
                this.setState({ selectedBranch: x.data })
            })
            .catch(err => {
                console.log(err)
                this.setState({ selectedBranch: null })
            })
    }

    public close = () => {
        this.props.onCancel();
    }

    private selectBranch(branch: Model.Branch) {
        if (this.props.onlyBranch == true) {
            this.props.onSelect(branch, null);
            this.setState({ selectedBranch: null })
        }
        else {
            this.loadUser(branch.id)
        }
    }

    private selectUser(user: Model.SimpleUser) {
        if (this.state.selectedBranch) {
            this.props.onSelect(this.state.selectedBranch, user);
            this.setState({ selectedBranch: null })
        }
    }

    private selectOwn() {
        this.props.onSelect(this.state.me.branch ? this.state.me.branch : new Model.Branch(), this.state.me);
        this.setState({ selectedBranch: null })
    }

    public render() {
        const { classes } = this.props
        const { selectedBranch } = this.state
        const st = this.state.searchText
        if (this.props.open == false) return <div />

        return <Dialog
            className={classes.dialog}
            onClose={(x) => this.close()}
            fullWidth={true}
            open={true}
            style={{ padding: "10px" }}>
            {
                selectedBranch ?
                    [
                        <DialogTitle>{selectedBranch.name}｜看護師選択</DialogTitle>,
                        <Button variant="outlined" color="default"
                            onClick={(e) => this.selectOwn()}>
                            自分
                        </Button>,
                        <Table key="4" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell align="center">名前</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    selectedBranch.users ? selectedBranch.users.map((user, ti) => {
                                        return <TableRow key={ti} className={classes.row}
                                            onClick={(e) => { this.selectUser(user) }}>
                                            <CustomTableCell style={{ textAlign: "left", padding: "5px" }} component="th" scope="row">{user.name}</CustomTableCell>
                                        </TableRow>
                                    })
                                        : null
                                }
                            </TableBody>
                        </Table>,
                        <Button variant="outlined" color="default"
                            onClick={(e) => this.setState({ selectedBranch: null })}>
                            戻る
                        </Button>
                    ]
                    : [
                        <DialogTitle>事業所選択</DialogTitle>,
                        <Button variant="outlined" color="default"
                            onClick={(e) => this.selectOwn()}>
                            自分
                        </Button>,
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell style={{ textAlign: "center", padding: "1px" }}>名前</CustomTableCell>
                                    <CustomTableCell align="right"></CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.branches.filter(x => { return (!st || st == "" || x.name.indexOf(st) >= 0) }).map(branch => {
                                        return <TableRow key={branch.id} className={classes.row}
                                            onClick={(e) => { this.selectBranch(branch) }}>
                                            <CustomTableCell style={{ textAlign: "left", padding: "5px" }} component="th" scope="row">{branch.name}</CustomTableCell>
                                            <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                        ,
                        <Button variant="outlined" color="default"
                            onClick={(e) => this.close()}>
                            戻る
                        </Button>
                    ]
            }
        </Dialog>
    }
}

export default Styles.withStyles(styles)(UserSelectDialog)
