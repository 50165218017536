import * as React from 'react';
import Button from '@material-ui/core/Button';
import * as Colors from '../components/Colors'
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import { Link } from 'react-router-dom';
import { DateUtil } from '../util/DateUtil'
import * as Icons from '@material-ui/icons';
import StandardTable from '../components/StandardTable'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import {
    Checkbox, Paper, Tabs, Tab, List, ListItem, TextField
} from '@material-ui/core'

import Grid from '@material-ui/core/Grid';
import Message from '../components/message/Message'
import MessageUserList from '../components/message/MessageUserList'
import SimpleDialog from '../components/SimpleDialog'
import InputText from '../components/InputText'

const styles = () => Styles.createStyles({
    mainContent: {
        margin: "0px 255px 0px 10px",
        display: "table-cell",
        minWidth: "430px",
        boxSizing: "border-box",
        width: "100%",
        verticalAlign: "top"
    },
    rightNav: {
        position: "relative",
        height: "100%",
        margin: "0 10px 100px 5px",
        padding: "1px 0 55px 15px",
        display: "table-cell",
        boxSizing: "border-box",
        verticalAlign: "top",
        minWidth: "200px"
    },
    messageContent: {
    },
    userThumbnail: {
        borderRadius: "40px",
        width: "40px",
        height: "40px"
    },
    userReplyThumbnail: {
        borderRadius: "30px",
        width: "30px",
        height: "30px"
    },
    feedContainer: {
        listStyleType: 'none',
        paddingInlineStart: "0px"
    },
    feedInfoWrapperTable: {
        display: "table",
        width: "100%",
        paddingBottom: "5px"
    },
    feedInfoWrapperCellIcon: {
        width: "55px",
        display: "table-cell",
        verticalAlign: "middle"
    },
    feedInfoWrapperCell: {
        width: "100%",
        paddingLeft: "5px",
        display: "table-cell",
        verticalAlign: "middle"
    },
    commentStatusNow: {
        marginLeft: 0,
        fontSize: "14px",
        color: "#525860",
        lineHeight: "21px"
    },
    commentItem: {
        borderTop: "1px solid #FFF",
        borderBottom: "1px solid #D2D9E7",
        padding: "5px 7px 5px 10px",
        border: "0",
        marginLeft: "0",
        fontSize: "14px",
        color: "#525860",
        lineHeight: "21px"
    },
    commentItemDt: {
        float: "left",
        overflow: "hidden"
    },
    feedComment: {
        resize: "none",
        overflow: "hidden",
        overflowWrap: "break-word",
        minHeight: "28px",
        height: "28px",
        fontSize: "12px",
        lineHeight: "17px",
        width: "100%",
        padding: "5px 0 0 5px",
        color: "#444",
        background: "white none",
        position: "relative",
        boxSizing: "border-box"
    },
    actionItems: {
        display: "table",
        boxSizing: "border-box",
        width: "100%"
    }, 
    actionItem: {
        display: "table-cell",
        width: "140px"
    },
    actionForms: {
        display: "table-cell",
        width: "100%"
    },
    commentIconContainer: {
        display: "table-cell",
        width: "20px",
        paddingRight: "5px"
    },
    fileInner : {
        width: "107px"
    },
    btnFile: {
        display: "block",
        textAlign: "left",
        fontSize: "11px"
    },
    btnFileUpload: {
        textAlign: "left",
        padding: "2px 0px 3px",
        whiteSpace: "nowrap",
        outline: "none",
        cursor: "pointer",
        borderWidth: "initial",
        borderStyle: "none",
        borderColor: "initial",
        borderImage: "initial",
        background: "none"
    },
    btnFileImage: {
        width: "8px"
    },
    btnMentionImage: {
        width: "20px"
    },
    chatModeBox: {
        float: "right",
        width: "auto",
        textAlign: "right"
    },
    headerMenu: {
        listStyle: "none",
        display: "inline"
    },
    headerMenuItem: {
        display: "inline",
        float: "left",
        marginRight: "20px"
    },
    activity_task_item_text: {
        paddingRight: "0px",
        width: "80%",
        fontSize: '1.1em'
    },
});

interface Params {
    message_id: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    me: Model.User
    loading: boolean,
    branches: Model.Branch[],
    error: string | null,
    activityTab: number,
    taskList: any[],
    taskTarget?: any,
    showDoneTask: boolean,
    notifyDocChat: boolean
}

// @inject()
// @observer
class MessagePage extends React.Component<Props, LocalState> {
    displayName = "MessagePage"
    constructor(props: Props) {
        super(props)
        this.state = {
            me: new Model.User(),
            loading: false,
            branches: [],
            error: null,
            activityTab: 0,
            taskList: [],
            showDoneTask: false,
            taskTarget: undefined,
            notifyDocChat: false
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true })

        HokanApi.me()
            .then(mex => {
                const me = mex.data
                this.setState({ me: me })
                this.loadTasks()
            })
            .catch(err => {
                console.log(err)
            })
    }

    private getId() {
        return parseInt(this.props.match.params.message_id)
    }

    onBack() {
        this.props.history.goBack()
    }

    loadTasks() {
        const message_id = this.getId()
        HokanApi.message.task_list(message_id)
        .then( x => {
            this.setState({ taskList: x.data })

            const dateTasks = this.state.taskList.filter(x => !x.done ).filter( t => t.task_date != "" )
            const maybeTask = dateTasks.find( t => {
                let today = new Date().getTime();
                let taskTime = new Date(t.task_date).getTime();
                return today >= taskTime
            })
            if(maybeTask !== undefined) {
                this.setState( {notifyDocChat: true} );
            }
            else{
                this.setState( {notifyDocChat: false} );
            }
        })
        .catch( err => {

        })
    }

    private taskToggle(task: Model.Task) {
        let status = false
        if (task.done) {
            task.done = false
        }
        else {
            task.done = true;
            status = true
        }
        let updateTask = new Model.UpdateTask()
        updateTask.status = status
        updateTask.content = task.content
        updateTask.task_date = task.task_date
        //TODO undefined
        HokanApi.message.update_task(1, task.id, updateTask).then( x => {
            this.loadTasks();
        })
    }

    private taskDialogDelete() {
        const { taskTarget } = this.state
        if (!taskTarget) return;
        //FIXME: 
        HokanApi.deleteTask(1, taskTarget.id).then(x => {
            this.loadTasks();
        });
    }

    private taskDialogClose() {
        this.setState({ taskTarget: undefined })
    }

    private taskDialogSave() {
        const { taskTarget } = this.state
        if (!taskTarget) return;
        if (taskTarget.id == 0) {
            //FIXME: 
            HokanApi.postTask(1, taskTarget).then(x => {
                this.taskDialogClose();
                this.loadTasks();
            });
        }
        else {

            let updateTask = new Model.UpdateTask()
            updateTask.status = taskTarget.done
            updateTask.content = taskTarget.content
            updateTask.task_date= taskTarget.task_date
            //FIXME: 
            HokanApi.message.update_task(1, taskTarget.id, updateTask).then( x => {
                this.taskDialogClose();
                this.loadTasks();
            })
        }
    }

    private setDialogText(name, value) {
        const target = this.state.taskTarget as any;
        if (!target) return;
        target[name] = value;
        this.setState({ taskTarget: target })
    }

    public render() {
        const id = this.getId()
        const { showDoneTask } = this.state
        const tasks = (showDoneTask ? this.state.taskList : this.state.taskList.filter(x => !x.done ))
        const cdStr = DateUtil.currentDateStr()
        const target = this.state.taskTarget;
        const { classes } = this.props

        return <div className={this.props.classes.messageContent}>
            <Paper>
                <Tabs value={this.state.activityTab}
                    onChange={(e, value) => { this.loadTasks(); this.setState({ activityTab: value }) }} >
                    <Tab label="トーク" />
                    <Tab label={<span className={classes.tabTextSub}>
                            タスク
                            {this.state.notifyDocChat ? <span style={{ color: "red" }}>●</span> : null}
                    </span>} />
                </Tabs>
            </Paper>
            { this.state.activityTab == 0 && <Message is_shinki={false} message_id={id} is_wc={false} wc_name={""} wc_number={0} wc_branch_id={0} onUpdate={ () => undefined } /> }
            { this.state.activityTab == 1 && 
                <StandardTable title={<span></span>}>
                    <List>
                        {
                            tasks.map((task, i) => {
                                let today = new Date().getTime();
                                let taskTime = new Date(task.task_date).getTime();
                                let hasExpired = false
                                if (task.task_date != "" && today >= taskTime) {
                                    hasExpired = true;
                                }
                                return <ListItem key={`taks_${i}`}
                                    style={{
                                        borderWidth: "1px", borderRadius: "0px", zIndex: (hasExpired ? 2 : undefined),
                                        borderColor: hasExpired ? Colors.orangeColor : "#e0e0e0",
                                        backgroundColor: hasExpired ? Colors.orangeColorTransparent : undefined,
                                        borderLeft: "none",
                                        borderRight: "none",
                                    }}
                                >
                                    <Checkbox
                                        checked={task.done}
                                        color="primary"
                                        onChange={(e) => {
                                            this.taskToggle(task)
                                        }}
                                    />
                                    <div className={classes.activity_task_item_text}>
                                        {task.content.split(/\n/).map((x, i) => <div key={`tsk_r_${i}`}>{x}</div>)}
                                    </div>
                                    <div style={{ fontWeight: "normal", 
                                    minWidth:"90px", marginRight: "-5px",textAlign: "right" }}>
                                        {
                                            task.task_date ?
                                                task.task_date
                                                : "期限なし"
                                        }
                                    </div>
                                    <Button style={{ marginLeft: "auto", border:"none", marginRight: "-10px" }}
                                        variant="outlined" size="small" color="default"
                                        aria-label="Edit" 
                                        onClick={(e) =>
                                            this.setState({ taskTarget: { ...task } })}
                                    >
                                        <Icons.Edit />
                                    </Button>
                                </ListItem>
                            }
                            )
                        }
                    </List>

                    <Button color="default" variant="outlined"
                        style={{ margin: "5px 0px" }}
                        onClick={(e) => { this.setState({ showDoneTask: !showDoneTask }) }}
                    >
                        {showDoneTask ? "終了済を隠す" : "終了済を表示"}
                    </Button>
                </StandardTable>
            }
            <SimpleDialog
                title={(target && target.id == 0) ? "タスク作成" : "タスク編集"}
                open={target != null}
                onCancel={() => this.taskDialogClose()}
                onOk={() => this.taskDialogSave()}
                hideDelete={true} >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {
                            <TextField
                                id="outlined-number"
                                label={"内容"}
                                value={target ? target.content : ""}
                                onChange={(e) => this.setDialogText("content", e.target.value)}
                                fullWidth={true}
                                InputLabelProps={{ shrink: true }}
                                multiline={true}
                                margin="none"
                                variant="outlined"
                                rows={3}
                            />
                        }
                    </Grid>
                    <InputText title="期限" inputType="date" value={target ? (target.task_date ? target.task_date : "") : ""}
                        onChange={(s) => {
                            if (s) {
                                this.setDialogText("task_date", s)
                            }
                            else {
                                this.setDialogText("task_date", null)
                            }
                        }} />
                </Grid>
            </SimpleDialog>
            
            <div>
                <MessageUserList message_id={id} is_wc={false} wc_name={""} wc_number={0} />
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(MessagePage))