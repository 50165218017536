import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import { Table, TableHead, TableCell, TableRow, TableBody, Button, Dialog, DialogTitle, DialogContent, Grid, Select, MenuItem } from '@material-ui/core';
import Papa from 'papaparse';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import { WSAEMFILE } from 'constants';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1500px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none"
        }
    },
    freshCheckSubtitleRow: {
        border: "solid",
        borderWidth: "1px",
        backgroundColor: Colors.primaryColor,
        borderColor: Colors.backColor,
        color: Colors.white
    },
    freshCheckSubtitle: {
        fontWeight: "bold",
        fontSize: "0.9rem",
        padding: "5px",
        color: Colors.white
    },
    rowContent: {
        paddingLeft: "10px"
    },
    rowContentError: {
        paddingLeft: "10px",
        backgroundColor: "#dda0dd"
    },
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    users: Model.User[]
    w_patients: Model.Patient.Patient[]
    me: Model.User
    loading: boolean
    commitResult: string
    branch_id: number
    branches: Model.Branch[]
    fileErrorMessage: string
    errors: Array<Array<string>>
    posting: boolean
}



// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "ImportPatientPage"
    constructor(props: Props) {
        super(props)

        this.state = {
            users: [],
            me: new Model.User(),
            w_patients: [],
            loading: true,
            commitResult: "",
            branch_id: 0,
            branches: [],
            fileErrorMessage: "",
            errors: [],
            posting: false
        }
    }

    private goBack() {
        this.props.history.goBack();
    }

    private commit() {
        this.setState({ posting: true, commitResult: "送信中" })
        HokanApi.postWyLCustomer(this.state.branch_id, this.state.w_patients.map(p => new Model.Patient.PatientPost(p))).then(x => {
            this.setState({ posting: false, w_patients: [], commitResult: "完了" })
        }).catch(err => {
            console.log(err)
            this.setState({ posting: false, commitResult: "エラーが発生しました" })
        })
    }

    private loadBranch = (branchId) => {
        this.setState({
            loading: true,
            branch_id: branchId,
        })
        HokanApi.getUsers(branchId)
            .then(async x => {
                const users = x.data
                this.setState({
                    users: users,
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    componentDidMount() {
        this.setState({ loading: true })
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
            this.loadBranch(x.data.branch_id)
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })

        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    checkNumberError(name: string, str: string, err: Array<string>, hasError = () => false): number {
        const res = parseInt(str)
        if (isNaN(res) || hasError()) {
            err.push(name)
            return -1
        }
        return res
    }
    checkTextError(name: string, str: string, err: Array<string>, hasError = () => false, ignoreEmpty: boolean = false): string {
        if ((!ignoreEmpty && (!str || str == "")) || hasError()) {
            err.push(name)
        }
        return str
    }
    isValidDate = (text) => {
        const [year, month, day] = text.split(/\/|-/).map(v => parseInt(v, 10));
        if (!year || !month || !day) return false
        const date = new Date(year, month - 1, day);
        if (date.getFullYear() !== year
            || date.getMonth() !== month - 1
            || date.getDate() !== day) return false
        return true
    }
    isValidNumber = (text, low, hi) => {
        const n = parseInt(text)
        if (isNaN(n)) return false
        return (low <= n && n <= hi)
    }

    handleReflect(results) {
        let errors: Array<Array<string>> = []
        const hokenTypes = ["未選択", "医療", "介護", "介護予防", "精神"]
        const kaigoTypes = [
            "未選択", "要支援1", "要支援2",
            "要介護1", "要介護2", "要介護3",
            "要介護4", "要介護5"
        ]
        const zokuseiTypes = ["小児", "成人"]
        const shikkanTypes = [
            "未選択",
            "感染症",
            "新生物（がん）",
            "血液及び造血器",
            "免疫機構障害",
            "内分泌",
            "栄養・代謝",
            "精神・行動障害(認知症等）",
            "精神・行動障害（統合失調症、その他精神）",
            "神経系(パーキン、アルツハイマー、レビー、難病）",
            "脳血管系",
            "眼及び付属器",
            "耳及び乳様突起",
            "循環器系",
            "呼吸器系",
            "消化器系",
            "皮膚・皮下組織",
            "筋骨格系・結合組織",
            "尿路性器系",
            "先天奇形・変形及び染色体異常",
            "その他"
        ]
        const ws = results.data.filter((r, i) => i != 0).map(r => {
            const w = new Model.Patient.Patient()
            let err: Array<string> = []
            w.name = this.checkTextError("name", r[0], err)
            w.name_kana = this.checkTextError("name_kana", r[1], err)
            w.birthday = this.checkTextError("birthday", r[2], err, () => { return !this.isValidDate(r[2]) })
            w.tel_home = r[3]
            w.tel_cell = r[4]
            w.env.address_zip = this.checkTextError("address_zip", r[5], err)
            w.env.address1 = this.checkTextError("address1", r[6], err)
            w.env.address2 = this.checkTextError("address2", r[7], err)
            w.env.address3 = this.checkTextError("address3", r[8], err)
            w.hoken.hoken_type = this.checkTextError("hoken_type", r[9], err, () => { return hokenTypes.findIndex(x => x == r[9]) == -1 })
            w.youkaigo = this.checkTextError("youkaigo", r[10], err, () => { return (r[10] != "" && r[10] != null && kaigoTypes.findIndex(x => x == r[10]) == -1) }, true)
            w.basic.zokusei = r[11] // this.checkTextError("zokusei", r[11], err, () => { return zokuseiTypes.findIndex(x => x == r[11]) == -1 })
            w.basic.shikkan_type = r[12] //this.checkTextError("shikkan_type", r[12], err, () => { return shikkanTypes.findIndex(x => x == r[12]) == -1 })
            w.wiseman_id = r[13]; //this.checkTextError("wiseman_id", r[13], err)
            w.medical.sick_name = r[14]

            w.social.famiry = [];
            if (r[15] != "" && r[16] != "")  {
                let tel = r[15].split(',')
                let memo = r[16].split(',')
                if (tel.length == memo.length) {
                    for (let i = 0; i < tel.length; ++i) {
                        var f = new Model.Patient.PatientFamiry()
                        f.tel = tel[i]
                        f.name = memo[i]
                        w.social.famiry.push(f)
                    }
                }
                else {
                    err.push("家族(電話)と家族(メモ)の個数が違います")
                }
            }
            
            w.medical.kiou_reki = r[17]
            w.social.kiki_family = r[18]
            w.social.seiiku = r[19];
            w.social.keii =  r[20];
            w.social.connect_work = r[21]
            w.saigai.comment = r[22]

            errors.push(err)
            return w
        })
        console.log(errors)
        this.setState({ w_patients: ws, errors: errors })
    }

    private loadFile(file: File) {
        Papa.parse(file, {
            header: false,
            dynamicTyping: false,
            skipEmptyLines: true,
            complete: (results) => {
                this.handleReflect(results);
            },
        });
    }

    private hideStr(str: string) {
        let res = ""
        for (let i = 0; i < str.length; ++i) {
            res += "*"
        }
        return res
    }

    public render() {
        const { classes } = this.props;
        const { users, w_patients, errors } = this.state;

        const selectStyle = (wi, name) => {
            if (errors[wi].findIndex(x => x == name) != -1) {
                return classes.rowContentError
            }
            return classes.rowContent
        }
        let errorCount = 0
        this.state.errors.forEach(e => {
            errorCount += e.length
        })         

        return <div className={classes.root}>

            <SubTitle title="利用者情報インポート"
                text="利用者情報を一括で取り込みます"
            />
            <div>
                <Select
                    value={this.state.branch_id}
                    autoWidth={true}
                    variant='outlined'
                    onChange={(e) => this.loadBranch(e.target.value)}
                    className={classes.input}
                >
                    {
                        this.state.branches.map((t, idx) =>
                            <MenuItem key={`lis+${t.id}`} value={t.id}>{t.name}</MenuItem>
                        )
                    }
                </Select>
            </div>
            <div>
                取り込む .csv ファイルを選択してください。
                <div>
                    <input type="file" ref="file"
                        accept=".csv"
                        onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                this.setState({ fileErrorMessage: "" })
                                this.loadFile(e.target.files[0])
                            }
                        }} />
                </div>
                <div style={{ color: "red" }}>{this.state.fileErrorMessage}</div>
            </div>
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={12} lg={12}>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>氏名</CustomTableCell>
                                <CustomTableCell align="center">カナ</CustomTableCell>
                                <CustomTableCell align="center">生年月日</CustomTableCell>
                                <CustomTableCell align="center">電話番号(自宅)</CustomTableCell>
                                <CustomTableCell align="center">電話番号(携帯)</CustomTableCell>
                                <CustomTableCell align="center">郵便番号</CustomTableCell>
                                <CustomTableCell align="center">住所(都道府県)</CustomTableCell>
                                <CustomTableCell align="center">住所(市区町村)</CustomTableCell>
                                <CustomTableCell align="center">住所(それ以降)</CustomTableCell>
                                <CustomTableCell align="center">保険</CustomTableCell>
                                <CustomTableCell align="center">要介護度</CustomTableCell>
                                <CustomTableCell align="center">属性</CustomTableCell>
                                <CustomTableCell align="center">疾患分類</CustomTableCell>
                                <CustomTableCell align="center">レセコンID</CustomTableCell>                                
                                <CustomTableCell align="center">主な傷病名</CustomTableCell>
                                <CustomTableCell align="center">家族・キーパーソン（電話番号）</CustomTableCell>
                                <CustomTableCell align="center">家族・キーパーソン（メモ）</CustomTableCell>
                                <CustomTableCell align="center">病の軌跡・既往歴・入院歴</CustomTableCell>
                                <CustomTableCell align="center">家族などの介護/療育の状況や危機</CustomTableCell>
                                <CustomTableCell align="center">生育歴</CustomTableCell>
                                <CustomTableCell align="center">訪看導入の経緯</CustomTableCell>
                                <CustomTableCell align="center">社会との繋がり</CustomTableCell>
                                <CustomTableCell align="center">災害備考</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                w_patients.map((w, wi) => {
                                    let family_memo = "";
                                    let family_tel = "";
                                    w.social.famiry.forEach(x => {
                                        family_tel += x.tel + "/";
                                        family_memo += x.name + "/";
                                    })
                                    return <TableRow key={`wrow_${wi}`} className={classes.row}>
                                        <TableCell align="center" className={selectStyle(wi, "name")}>{`${w.name}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "name_kana")}>{`${w.name_kana}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "birthday")}>{`${w.birthday}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "tel_home")}>{`${w.tel_home}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "tel_cell")}>{`${w.tel_cell}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "address_zip")}>{`${w.env.address_zip}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "address1")}>{`${w.env.address1}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "address2")}>{`${w.env.address2}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "address3")}>{`${w.env.address3}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "hoken_type")}>{`${w.hoken.hoken_type}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "youkaigo")}>{`${w.youkaigo}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "zokusei")}>{`${w.basic.zokusei}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "shikkan_type")}>{`${w.basic.shikkan_type}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "wiseman_id")}>{`${w.wiseman_id}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "sick_name")}>{`${w.medical.sick_name}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "family_tel")}>{`${family_tel}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "family_name")}>{`${family_memo}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "kiou_reki")}>{`${w.medical.kiou_reki}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "kiki_family")}>{`${w.social.kiki_family}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "seiiku")}>{`${w.social.seiiku}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "keii")}>{`${w.social.keii}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "connect_work")}>{`${w.social.connect_work}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "saigai_bikou")}>{`${w.saigai.comment}`}</TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            {
                errorCount > 0 ?
                    <div>
                        エラーが{errorCount}個あります。ファイルを確認してください。
                    </div>
                    : null
            }
            <div>
                <Button color="default" variant="outlined"
                    onClick={(e) => this.goBack()}
                    disabled={this.state.posting}
                    className={classes.filter_button_d}>
                    戻る
                </Button>
                <Button color="primary" variant="contained"
                    onClick={(e) => this.commit()}
                    disabled={this.state.posting || errorCount > 0}
                    className={classes.filter_button_d}>
                    実行
                </Button>
            </div>
            <div>{this.state.commitResult}</div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))