import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle';
import { Grid, Button, Paper } from '@material-ui/core';
import InputText from '../components/InputText';
import ButtonOkCancel from '../components/ButtonOkCancel';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import BranchList from '../components/BranchList';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {

    },
    textField: {
        width: "90%"
    },
    input: {
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        textAlign: "right",
        margin: "auto"
    },
    branchList: {
        width: "80%",
        margin: "auto",
        padding: "50px"
    }
});

interface Params {
    company_id: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    loading: boolean,
    me: Model.User,
    result: string,
    cname: string,
    bname: string,
    uname: string,
    pass: string,
    changeMail: string,
    changePass: string,
    passResult: string
}

// @inject()
// @observer
class SystemPage extends React.Component<Props, LocalState> {
    displayName = "SystemPage"
    constructor(props: Props) {
        super(props)
        const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        const N = 12
        const pass = Array.from(Array(N)).map(() => S[Math.floor(Math.random() * S.length)]).join('')
        const pass2 = Array.from(Array(N)).map(() => S[Math.floor(Math.random() * S.length)]).join('')

        this.state = {
            loading: false,
            me: new Model.User(),
            result: "",
            cname: "",
            bname: "",
            uname: "",
            pass: pass,
            changeMail: "@demo.jp",
            changePass: pass2,
            passResult: "",
        }
    }

    public componentDidMount = () => {
        HokanApi.me().then(res => {
            this.setState({ me: res.data })
        }).catch(e => {
            console.log(e)
        })
    }

    onCommit() {
        this.setState({ loading: true, result: "送信中" })
        HokanApi.sendNewCompany({
            company_name: this.state.cname,
            branch_name: this.state.bname,
            user_name: this.state.uname,
            user_pass: this.state.pass
        }).then(res => {
            this.setState({
                loading: false,
                result: res.data
            })
        }).catch(e => {
            console.log(e)
            this.setState({
                loading: false,
                result: "エラー"
            })
        })
    }

    onPasswordCommit() {
        this.setState({ loading: true, passResult: "送信中" })
        HokanApi.systemChangePassword(
            this.state.changeMail,
            this.state.changePass
        ).then(res => {
            this.setState({
                loading: false,
                passResult: res.data
            })
        }).catch(e => {
            console.log(e)
            this.setState({
                loading: false,
                passResult: "エラー"
            })
        })
    }

    onBack() {
        this.props.history.goBack()
    }

    public render() {
        const { classes } = this.props
        if (this.state.me && this.state.me.id == 1) {
            return <div className={classes.root}>
                <Paper>
                    <SubTitle title="法人作成" />
                    <Grid container spacing={2} className={classes.grid}>
                        <InputText title="法人名" value={this.state.cname} onChange={(s) => this.setState({ cname: s })} />
                        <InputText title="事業所名" value={this.state.bname} onChange={(s) => this.setState({ bname: s })} />
                        <InputText title="管理用メールアドレス" value={this.state.uname} onChange={(s) => this.setState({ uname: s })} />
                        <InputText title="管理用パスワード" value={this.state.pass} onChange={(s) => this.setState({ pass: s })} />
                    </Grid>

                    <Button
                        variant="contained"
                        onClick={() => this.onCommit()}
                        disabled={this.state.loading}>
                        送信
                </Button>
                    <div>{this.state.result}</div>
                </Paper>
                <Paper>
                    <SubTitle title="デモパスワード変更" />
                    <Grid container spacing={2} className={classes.grid}>
                        <InputText title="デモメールアドレス" value={this.state.changeMail} onChange={(s) => this.setState({ changeMail: s })} />
                        <InputText title="新パスワード" value={this.state.changePass} onChange={(s) => this.setState({ changePass: s })} />
                    </Grid>
                    <div>
                        ※デモメールアドレスは「@demo.jp」を含む必要があります。
                    </div>
                    <Button
                        variant="contained"
                        onClick={() => this.onPasswordCommit()}
                        disabled={this.state.loading || this.state.changeMail.indexOf("@demo.jp") == -1}>
                        送信
                    </Button>
                    <div>{this.state.passResult}</div>
                </Paper>
            </div>
        }
        else {
            return <div></div>
        }
    }
}

export default withPageProps(Styles.withStyles(styles)(SystemPage))