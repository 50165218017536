
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public score_enge: number = -1
    public comment: string = ""
}

export class Checks {
}