import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as Colors from './Colors'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as Icons from '@material-ui/icons';
import * as Styles from '@material-ui/core/styles';
import { Auth, toInjector } from '../stores';
import { PageProps, withPageProps } from './Page'
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import PatientNavigation from './PatientNavigation';
import { Badge, Avatar, Tooltip } from '@material-ui/core';
import AvatarStore from '../firebase/AvatarStore'
// import { Spring, animated } from 'react-spring/renderprops'
import { ImageUtil } from '../util/ImageUtil'
import Autocomplete from '@material-ui/lab/Autocomplete';

import TextField from '@material-ui/core/TextField';

const drawerWidth = 260;


const styles = (theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: Colors.forthColor
    },
    title: {
        fontSize: "0.9rem",
        display: "inline-block"
    },
    appBar: {
        overflow: "hidden"
    },
    toolBar: {
        minHeight: "3em",
        maxHeight: "3em"
    },
    appBarShift: {
    },
    menuButton: {
        marginLeft: 12,
        marginRight: "5px",
    },
    patientButton: {
        marginRight: 5,
        marginLeft: "auto"
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        color: Colors.primaryColor
    },
    drawerPaper: {
        width: drawerWidth,
        color: "white",
        backgroundColor: Colors.primaryColor
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        color: "white",
        backgroundColor: Colors.primaryColor
    },
    drawerName: {
        marginBlockEnd: 0,
        fontSize: "1.1rem"
    },
    drawerOffice: {
        marginBlockStart: 0,
        fontSize: "1.0em"
    },
    largeIcon: {
        width: 60,
        height: 60,
        marginTop: "10px",
        marginRight: "10px",
        color: Colors.primaryColor
    },
    drawerIcon: {
    },
    content: {
        flexGrow: 1,
        padding: 10,
    },
    contentShift: {
        marginLeft: 0,
    },
    drawerHeaderInfoBlock: {
        justifyContent: "left",
        paddingTop: "0px",
        width: "100%",
        color: "white"
    },
    drawerHeaderInfoElement: {
    },
    link: {
        textDecoration: 'none',
        color: Colors.primaryColor
    },
    loginHeader: {
        backgroundColor: Colors.primaryColor,
        color: "white",
        width: "100%"
    },
    activity_face: {
        width: "40px",
        height: "40px",
        borderRadius: "50px",
        objectFit: "contain" as any,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        display: "inline-flex",
        cursor: "pointer",
        fontSize: "1rem"
    },
    header_logo: {
        height: "30px"
    },
    item: {
        backgroundColor: Colors.white,
        color: Colors.primaryColor,
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.primaryColor,
        marginTop: "-1px",
        fontSize: "1.0rem"
    },
    autocomplete_group: {
        lineHeight: "normal"
    },
    autocomplete_ul: {
        margin: "0px",
        marginTop: "-10px",
        marginBottom: "-10px",
        padding: "0px"
    },
    badge: {
        height: 14,
        minWidth: 14
    }
});

interface Props extends Auth, Styles.WithStyles<typeof styles>, PageProps {
    theme: any,
    loginMode: boolean
}


interface LocalState {
    open: boolean
    openNow: boolean
    openPatient: boolean
    me: Model.User | null
    notifyCount: number
    avatar_url: string
}

@inject(...toInjector(Auth))
@observer
class HeaderNavigation extends React.Component<Props, LocalState> {
    displayName = "HeaderNavigation"
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            openNow: false,
            openPatient: false,
            me: null,
            notifyCount: 0,
            avatar_url: ""
        };
    }

    private handleDrawerOpen = () => {
        this.setState({ open: true, openNow: true });
        HokanApi.me()
            .then(x => {
                this.setState({ me: x.data })
            })
            .catch(err => {
                console.log(err);
            })
    };

    private handleDrawerClose = () => {
        this.setState({ open: false });
    };

    private handlePatientOpen = () => {
        this.setState({ openPatient: true });
    }


    public componentDidMount() {
        HokanApi.me()
            .then(x => {
                const u = x.data
                this.setState({ me: u })
                if (u.branch) {
                    this.loadAvatar();
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    private loadAvatar() {
        const u = this.state.me;
        if (u && u.branch) {
            AvatarStore.getUrl(u.branch.company_id, u.id).then(url => {
                this.setState({ avatar_url: url })
            }).catch(err => {

            })
        }
    }

    private doClose = () => {
        this.setState({ open: false })
    }

    private logout = () => {
        this.props.auth!.logout();
        this.doClose();
        this.props.history.push("/login", { logout: true })
        location.reload();
    }

    private getUserName = () => {
        return this.state.me ? this.state.me.name : ""
    }

    private getBranchName = () => {
        return this.state.me && this.state.me.branch ? this.state.me.branch.name : ""
    }

    private setNotifyCount(c: number) {
        this.setState({ notifyCount: c })
    }

    private setAvatarImage(file: File) {
        const me = this.state.me
        if (me && me.branch && file) {
            ImageUtil.compress(file, 400).then(res => {
                if (me && me.branch) {
                    AvatarStore.put(me.branch.company_id, me.id, res).then(x => {
                        this.loadAvatar();
                    }).catch(err => {
                        console.log(err)
                    })
                }
            })
        }
    }

    public render() {
        const { classes, theme } = this.props;
        const { open, openPatient } = this.state;
        const iconHeight = "25px"
        const icons = this.props.loginMode ? [] : [
            //{ text: 'ホーム', icon: <Icons.Home />, link: "/home" },
            { text: '利用者', icon: <img src="/images/icons/基本_b.png" height="25px" />, link: "/patients" },
            { text: '書類', icon: <img src="/images/icons/書類B_b.png" height="25px" />, link: "/document" },
            { text: '自分', icon: <img src="/images/icons/自分B_b.png" height="25px" />, link: "/my" },
            { text: 'スコア', icon: <img src="/images/icons/スコア_b.png" height="25px" />, link: "/score" },
            { text: 'タスクリスト', icon: <img src="/images/icons/タスクリストB_b.png" height="25px" />, link: "/task" },
            { text: 'ガイドライン', icon: <img src="/images/icons/ガイドラインB_b.png" height="25px" />, link: "/guidline" },
            //{ text: '実績カレンダー', icon: <Icons.LibraryBooks />, link: "/jimu/jisseki_calendar" },
            //{ text: '出退勤管理', icon: <Icons.Settings />, link: "/work_record" },
            { text: '事故・苦情・災害', icon: <img src="/images/icons/インシデントB_b.png" height="25px" />, link: "/incident" },
            { text: '管理', icon: <img src="/images/icons/管理B_b.png" height="25px" />, link: "/manage" },
            //{ text: 'コンポーネント', icon: <Icons.Extension />, link: "/components" },
        ];

        return <div className={classes.root}>
            <CssBaseline key="baseline1" />
            <AppBar
                key="appbar"
                position="fixed"
                style={{
                    color: Colors.primaryColor,
                    backgroundColor: "white"
                }}
                className={classNames(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar disableGutters={!open} className={classes.toolBar}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.handleDrawerOpen}
                        className={classNames(classes.menuButton, open && classes.hide)}
                    >
                        <Icons.Menu />
                    </IconButton>
                    <div className={classes.title}>
                        <SearchForm userName={this.getUserName()} history={this.props.history} classes={classes} />
                    </div>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.handlePatientOpen}
                        edge="end"
                        className={classNames(classes.patientButton, openPatient && classes.hide)}
                    >
                        <Badge badgeContent={this.state.notifyCount > 0 ? " " : 0 }
                            color={"secondary"}
                            classes={{ badge: classes.badge }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                            <Icons.Group />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <PatientNavigation loginMode={this.props.loginMode}
                open={this.state.openPatient}
                setOpen={(f) => this.setState({ openPatient: f })}
                setNotifyCount={(c) => this.setNotifyCount(c)} />
            <SwipeableDrawer
                key="drawer"
                className={classes.drawer}
                swipeAreaWidth={1}
                // variant="persistent"
                anchor="left"
                open={open}
                hysteresis={0.3}
                onClose={() => this.handleDrawerClose()}
                onOpen={() => {
                    this.handleDrawerOpen();
                }}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <div className={classes.drawerHeaderInfoBlock}>
                        <div className={classes.drawerHeaderInfoElement}>
                            <Tooltip title="変更" aria-label="change">
                                <Avatar
                                    onClick={(e) => {
                                        const el = document.getElementById("edit_own_avatar") as any
                                        if (el) el.click()
                                    }}
                                    alt="avatar" src={this.state.avatar_url == "none" ? undefined : this.state.avatar_url}
                                    className={classes.activity_face}
                                    style={{
                                        color: '#fff',
                                        backgroundColor: `#hsl(${this.state.me ? this.state.me.id * 130 : 0}, 50%, 80%)`,
                                    }}
                                >{this.state.avatar_url == "none" && this.state.me ? this.state.me.name.substr(0, 2) : null}</Avatar>
                            </Tooltip>
                            <div style={{ display: "inline-block", marginLeft: "10px", width: "75%" }}>
                                <Link style={{ textDecoration: "none", color: "white" }}
                                    to={`/manage/user/${this.state.me ? this.state.me.id : ""}`}
                                    onClick={() => { this.doClose() }}
                                >
                                    <p className={classes.drawerName}>{
                                        this.getUserName()
                                    }</p>
                                    <p className={classes.drawerOffice}>{
                                        this.getBranchName()
                                    }</p>
                                </Link>
                            </div>
                        </div>

                        <input type="file" id={`edit_own_avatar`} ref="file"
                            hidden={true}
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    this.setAvatarImage(e.target.files[0])
                                }
                            }} />
                    </div>
                    <div>
                        <IconButton onClick={this.handleDrawerClose} className={classes.drawerIcon} style={{ color: Colors.white, padding: "0px" }}>
                            {theme.direction === 'ltr' ? <Icons.ChevronLeft /> : <Icons.ChevronRight />}
                        </IconButton>
                    </div>
                </div>
                <Divider />
                <List>
                    {
                        icons.map((item, index) => {
                            return <div key={"header_list_" + index} className={classes.item}>
                                <Link to={item.link} className={classes.link} onClick={(e) => this.doClose()}>
                                    <ListItem button={true} key={item.text}>
                                        <ListItemIcon className={classes.drawerIcon}>
                                            {(item.icon as any) as JSX.Element}
                                        </ListItemIcon>
                                        <ListItemText disableTypography={true} primary={item.text} />
                                    </ListItem>
                                </Link>
                            </div>

                        })
                    }
                    {
                        this.props.loginMode ?
                            <Link to="/login" className={classes.link} onClick={(e) => this.doClose()}
                                style={{ backgroundColor: Colors.white, color: Colors.primaryColor }}>
                                <ListItem button={true} className={classes.item}>
                                    <ListItemIcon className={classes.drawerIcon}>
                                        <img src="/images/icons/ログアウトB_b.png" height="25px" />
                                    </ListItemIcon>
                                    <ListItemText disableTypography={true} onClick={(e) => this.logout()} primary="ログイン" />
                                </ListItem>
                            </Link>
                            :
                            <ListItem button={true} className={classes.item}
                                style={{ backgroundColor: Colors.white, color: Colors.primaryColor }}>
                                <ListItemIcon >
                                    <img src="/images/icons/ログアウトB_b.png" height="25px" />
                                </ListItemIcon>
                                <ListItemText disableTypography={true} onClick={(e) => this.logout()} primary="ログアウト" />
                            </ListItem>
                    }
                </List>
                <Divider style={{ marginTop: "auto" }} />
                <List className={classes.link} >
                    {
                        false ?
                            <Link to={"/inquiry"} className={classes.link} onClick={(e) => this.doClose()}>
                                <ListItem button={true}>
                                    <ListItemIcon className={classes.drawerIcon}>
                                        <Icons.HelpOutline />
                                    </ListItemIcon>
                                    <ListItemText disableTypography={true} primary="お問い合わせ" />
                                </ListItem>
                            </Link>
                            : null
                    }

                    <Link to={"/terms"} className={classes.link} style={{ backgroundColor: Colors.primaryColor, fontSize: "0.8rem", color: Colors.white }} 
                    onClick={() => { this.doClose() }}>
                        <ListItem button={true}>
                            <ListItemIcon className={classes.drawerIcon}>
                                <img src="/images/icons/white_logo.png" height="25px" />
                            </ListItemIcon>
                            <ListItemText disableTypography={true} primary="お問い合わせ・利用規約" />
                        </ListItem>
                    </Link>
                </List>
            </SwipeableDrawer>
        </div>
    }
}

interface SearchOption {
    cat: string
    catName: string
    name: string
    searchName: string
    id: number
    branchId?: number
}

class SearchForm extends React.Component<
    { userName: string, history: any, classes: any },
    {
        text: string,
        open: boolean,
        list: SearchOption[]
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            text: "",
            open: false,
            list: []
        }
    }

    private clicked(option: SearchOption) {
        if (!option) return;
        this.setState({ text: "", open: false })
        if (option.cat == "利用者") {
            this.props.history.push(`/patients/${option.id}`);
        }
        else if (option.cat == "関係機関") {
            this.props.history.push(`/manage/organizations?name=${option.name}`);
        }
        else if (option.cat == "スタッフ") {
            this.props.history.push(`/manage/user?name=${option.name}&branch=${option.branchId}`);
        }
    }
    private kanaToHira(str) {
        return str.replace(/[\u30a1-\u30f6]/g, function (match) {
            var chr = match.charCodeAt(0) - 0x60;
            return String.fromCharCode(chr);
        });
    }
    async componentDidMount() {
        try {
            const lis: SearchOption[] = []
            var pat = await HokanApi.getPatients("all")
            var org = await HokanApi.organization.list()
            var user = await HokanApi.user.list()
            pat.data.forEach(x => lis.push({
                cat: "利用者",
                catName: `利用者(${x.branch ? x.branch.name : ""})`,
                name: x.name,
                searchName: x.name + " " + this.kanaToHira(x.name_kana) + x.tel_cell + " " + x.tel_home,
                id: x.id
            }))
            user.data.forEach(x => lis.push({
                cat: "スタッフ",
                catName: `スタッフ(${x.branch ? x.branch.name : ""})`,
                name: x.name,
                searchName: x.name + " " + this.kanaToHira(x.kana) + " " + x.tel1 + " " + x.tel2,
                id: x.id,
                branchId: x.branch_id
            }))
            org.data.forEach(x => lis.push({
                cat: "関係機関",
                catName: `関係機関`,
                name: x.name,
                searchName: x.name + " " + this.kanaToHira(x.kana) + " " + x.tel + " " + x.kinkyu_tel1 + " " + x.kinkyu_tel2,
                id: x.id
            }))
            this.setState({ list: lis })
        }
        catch (e) {
            console.log(e)
        }
    }

    public render() {
        const isEmpty = this.state.open == false
        const { classes } = this.props;
        return [
            <Link key="headericon" to="/patients">
                <img src="/images/icons/monocolored_logo.png" height="20px"/>
            </Link>,
            isEmpty ?
                <div key="uname" style={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                    marginLeft: "10px"
                }}>
                    {this.props.userName}
                </div> : null,
            <Autocomplete
                key="search-form"
                id="search-form"
                clearOnEscape={true}
                options={this.state.list}
                size="small"
                style={{
                    display: "inline-flex",
                    marginLeft: isEmpty ? "10px" : "0px",
                    width: isEmpty ? 100 : 180
                }}
                classes={{
                    groupLabel: classes.autocomplete_group,
                    groupUl: classes.autocomplete_ul
                }}
                fullWidth={false}
                groupBy={(option) => option.catName}
                onChange={(e, v) => { this.clicked(v as any) }}
                onOpen={() => this.setState({ open: true, text: "" })}
                onClose={() => this.setState({ open: false, text: "" })}
                renderOption={(option: SearchOption, { selected }) => (
                    <div style={{ marginTop: "-5px", marginBottom: "-5px", fontSize: "smaller" }}>
                        {option.name}
                    </div>
                )}
                inputValue={this.state.text}
                value={null}
                noOptionsText={"なし"}
                onInputChange={(e, s) => this.setState({ text: s })}
                getOptionLabel={(option) => option.searchName}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <input style={{ width: isEmpty ? 100 : 200 }}
                            type="text" placeholder="検索" {...params.inputProps} />
                    </div>
                )}
            />
        ]
    }
}
export default withPageProps(Styles.withStyles(styles, { withTheme: true })(HeaderNavigation));
