import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import * as FormBehaviour from './FormBehaviour'
import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import DateUtil from '../../util/DateUtil';
import moment from 'moment';
import * as HokanApi from '../../api/hokan'
import { truncate } from 'fs';
import { ADLScore } from '../../models/Patient';

class TempData {
    selectedDate: string | null = null
    exists: boolean = false
    kirokus: HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII> = new HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>()
}


// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"

    public getId(): number {
        return 68
    }

    public createContent(): any {
        const res = new FormModel.PTOTST.Core()
        const cd = DateUtil.currentDateStr()
        res.values.create_date = cd
        return res
    }

    public setCreateDate(doc: Model.Document.Document, create_date: string, sa: StoreAccessor) {
        const c = doc.content as FormModel.PTOTST.Core
        c.values.create_date = create_date
        doc.target_month = DateUtil.toTargetMonthNum(create_date)
        if (!c.dateInitialized && !doc.id && create_date) {
            c.dateInitialized = true;
            c.values.create_date = moment(create_date).endOf("month").format("YYYY-MM-DD")
        }
        else {
            c.values.create_date = create_date;
        }
        if (create_date) {
            this.existsCheck(doc, sa, doc.patient, doc.target_month)
        }
    }
    private existsCheck(doc: Model.Document.Document, sa: StoreAccessor, pat: Model.Patient.Patient | null, target_month: number | null) {
        if (!pat) {
            return;
        }
        HokanApi.checkDocumentExists(pat.id, doc.document_type.id, target_month, doc.id).then(res => {
            doc.temp_data.exists = res.data
            doc.cannotCommit = res.data
            sa.updateState()
        })
        sa.updateState()
    }


    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        const c = doc.content as FormModel.PTOTST.Core
        doc.patient = pat;
        if (pat) {
            this.loadFromPatientBasic(doc, sa)
            this.setCreateDate(doc, c.values.create_date, sa)
        }
    }

    public loadFromPatientBasic(doc: Model.Document.Document, sa: StoreAccessor) {
        const c = doc.content as FormModel.PTOTST.Core
        const pat = doc.patient
        if (pat) {
            const nj = pat.medical.nichijo_jiritsu
            c.checks.check_nichijo_jiritsu = (nj == Model.Patient.NichijoJiristuScore.Jiritsu)
            c.checks.check_nichijo_j1 = (nj == Model.Patient.NichijoJiristuScore.J1)
            c.checks.check_nichijo_j2 = (nj == Model.Patient.NichijoJiristuScore.J2)
            c.checks.check_nichijo_a1 = (nj == Model.Patient.NichijoJiristuScore.A1)
            c.checks.check_nichijo_a2 = (nj == Model.Patient.NichijoJiristuScore.A2)
            c.checks.check_nichijo_b1 = (nj == Model.Patient.NichijoJiristuScore.B1)
            c.checks.check_nichijo_b2 = (nj == Model.Patient.NichijoJiristuScore.B2)
            c.checks.check_nichijo_c1 = (nj == Model.Patient.NichijoJiristuScore.C1)
            c.checks.check_nichijo_c2 = (nj == Model.Patient.NichijoJiristuScore.C2)

            const ninchi = pat.medical.ninchi_jiritsu
            c.checks.check_ninchi_jiritsu = (ninchi == Model.Patient.NinchiJiristuScore.Jiritsu)
            c.checks.check_ninchi_1 = (ninchi == Model.Patient.NinchiJiristuScore.I)
            c.checks.check_ninchi_2a = (ninchi == Model.Patient.NinchiJiristuScore.IIa)
            c.checks.check_ninchi_2b = (ninchi == Model.Patient.NinchiJiristuScore.IIb)
            c.checks.check_ninchi_3a = (ninchi == Model.Patient.NinchiJiristuScore.IIIa)
            c.checks.check_ninchi_3b = (ninchi == Model.Patient.NinchiJiristuScore.IIIb)
            c.checks.check_ninchi_4 = (ninchi == Model.Patient.NinchiJiristuScore.IV)
            c.checks.check_ninchi_m = (ninchi == Model.Patient.NinchiJiristuScore.M)


            const s1 = ["shokuji", "toilet", "kaidan", "koui", "haiben", "hainyou", "seiyou", "nyuyoku"]
            const s2 = ["ijou", "hokou"]
            const s2_base = ["ijou", "idou"]
            const adl = pat.medical.adl
            if (adl) {
                s1.forEach(keyBase => {
                    const n = adl[keyBase]
                    const comment = adl[keyBase + "_comment"]
                    c.checks["check_" + keyBase + "_jiritsu"] = (n == Model.Patient.ADLScore.S10)
                    c.checks["check_" + keyBase + "_ichibu"] = (n == Model.Patient.ADLScore.S5)
                    c.checks["check_" + keyBase + "_zen"] = (n == Model.Patient.ADLScore.S0)
                    c.values[keyBase + "_biko"] = comment
                })
                s2.forEach((keyBase, i) => {
                    const n = adl[s2_base[i]]
                    const comment = adl[s2_base[i] + "_comment"]
                    c.checks["check_" + keyBase + "_jiritsu"] = (n == Model.Patient.ADLScore.S10)
                    c.checks["check_" + keyBase + "_ichibu_1"] = (n == Model.Patient.ADLScore.S5)
                    c.checks["check_" + keyBase + "_zen"] = (n == Model.Patient.ADLScore.S0)
                    c.values[keyBase + "_biko"] = comment
                })
            }
            sa.updateState();
        }
    }


    private loadLatest(doc: Model.Document.Document, sa: StoreAccessor, value_names: string[], check_names: string[]) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const current = doc.content as FormModel.PTOTST.Core
        HokanApi.getDocumentLatest(doc.patient.id, doc.document_type.id, current.values.create_date ? current.values.create_date : undefined).then(res => {
            const latest = res.data.content as FormModel.PTOTST.Core
            value_names.forEach(name => current.values[name] = latest.values[name])
            check_names.forEach(name => current.checks[name] = latest.checks[name])
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.PTOTST.Core;
        content.values.sum = this.calcScore(doc).toString()
    }

    public beforeMount(doc: Model.Document.Document, sa: StoreAccessor) {
        doc.temp_data = new TempData()
        const content = doc.content as FormModel.PTOTST.Core
        this.existsCheck(doc, sa, doc.patient, doc.target_month)
    }

    private loadFromBranch(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.PTOTST.Core
        const values = content.values
        HokanApi.branch.get(doc.patient.branch_id).then(res => {
            values.branch_name = res.data.name
            values.kanri_name = res.data.kanri_name
            sa.updateState()
        }).catch(err => { console.log(err) })
        HokanApi.user.get(doc.patient.main_user_id).then(res => {
            values.tanto1_name = res.data.name
            values.tanto1_role = Model.User.getShikakuName(res.data.shikaku)
            sa.updateState()
        }).catch(err => { console.log(err) })
        HokanApi.user.get(doc.patient.sub_user1_id).then(res => {
            values.tanto2_name = res.data.name
            values.tanto2_role = Model.User.getShikakuName(res.data.shikaku)
            sa.updateState()
        }).catch(err => { console.log(err) })
    }

    private calcScore(doc: Model.Document.Document) {
        const content = doc.content as FormModel.PTOTST.Core
        const s1 = ["shokuji", "toilet", "kaidan", "koui", "haiben", "hainyou"]
        const s2 = ["ijou", "hokou"]
        const s3 = ["seiyou", "nyuyoku"]
        let sum = 0;
        s1.forEach(keyBase => {
            if (content.checks["check_" + keyBase + "_jiritsu"]) sum += 10
            else if (content.checks["check_" + keyBase + "_ichibu"]) sum += 5
        })
        s2.forEach(keyBase => {
            if (content.checks["check_" + keyBase + "_jiritsu"]) sum += 15
            else if (content.checks["check_" + keyBase + "_ichibu_1"]) sum += 10
            else if (content.checks["check_" + keyBase + "_ichibu_2"]) sum += 5
        })
        s3.forEach(keyBase => {
            if (content.checks["check_" + keyBase + "_jiritsu"]) sum += 5
        })

        return sum
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor, classes: any) {
        const content = doc.content as FormModel.PTOTST.Core
        const saV = sa.generateChild(["content", "values"])
        const saC = sa.generateChild(["content", "checks"])
        const copyButton = (value_names: string[], check_names: string[]) => <Button variant="outlined" size="small"
            onClick={(e) => this.loadLatest(doc, sa, value_names, check_names)}
            className={classes.loadButton} >前回から取得</Button>

        const noHoukokuSaki = (!content.values.houkoku_saki || content.values.houkoku_saki.length == 0)
        const cannotCommit = noHoukokuSaki || doc.temp_data.exists
        if (cannotCommit != doc.cannotCommit) {
            doc.cannotCommit = cannotCommit
            sa.updateState()
        }

        const elements_basic = [
            {
                name: "コピー", content:
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromPatientBasic(doc, sa)}
                        className={classes.loadButton} >記録Iから取得</Button>
            },
            { name: "年月", content: <div>{DateUtil.toTargetMonthStr(content.values.create_date)}{doc.temp_data.exists ? <div style={{ color: "red", fontWeight: "bold" }}>エラー：他に存在します</div> : ""}</div> }
        ]

        const houkoku_saki = saV.getValue("houkoku_saki")

        if (houkoku_saki) {
            if (houkoku_saki.findIndex(x => x == null) >= 0) {
                const p = houkoku_saki.filter(x => x != null)
                saV.setValue("houkoku_saki", p)
            }
        }

        const patId = doc.patient ? doc.patient.id : undefined
        const elements_houkoku = [
            { name: "コピー", content: copyButton(["houkoku_saki"], []) },
            {
                name: "内容", content: <div>
                    {
                        noHoukokuSaki ?
                            <div style={{ color: "red", fontWeight: "bold" }}>報告先は一つ以上必要です</div>
                            : null
                    }
                    {
                        houkoku_saki.map((o, i) => {
                            return saV.orgTantoC(["houkoku_saki"], i, patId)
                        })
                    }
                    {
                        sa.isEditing ?
                            <Button variant="outlined" size="small" color="primary"
                                onClick={(e) => {
                                    houkoku_saki.push(new Model.OrganizationTanto());
                                    saV.setValue("houkoku_saki", houkoku_saki)
                                }}>
                                ＋追加
                            </Button>
                            : null
                    }
                </div>
            },
        ]

        const checkAri = doc.content.checks.check_ari
        const elements_jiritsudo = [
            {
                name: "コピー", content: copyButton([], [
                    "check_nichijo_jiritsu",
                    "check_nichijo_j1",
                    "check_nichijo_j2",
                    "check_nichijo_a1",
                    "check_nichijo_a2",
                    "check_nichijo_b1",
                    "check_nichijo_b2",
                    "check_nichijo_c1",
                    "check_nichijo_c2",
                    "check_ninchi_jiritsu",
                    "check_ninchi_1",
                    "check_ninchi_2a",
                    "check_ninchi_2b",
                    "check_ninchi_3a",
                    "check_ninchi_3b",
                    "check_ninchi_4",
                    "check_ninchi_m",
                ])
            },
            {
                name: "日常生活自立度", content: saC.checkList([
                    { label: "自立", key: "check_nichijo_jiritsu" },
                    { label: "J1", key: "check_nichijo_j1" },
                    { label: "J2", key: "check_nichijo_j2" },
                    { label: "A1", key: "check_nichijo_a1" },
                    { label: "A2", key: "check_nichijo_a2" },
                    { label: "B1", key: "check_nichijo_b1" },
                    { label: "B2", key: "check_nichijo_b2" },
                    { label: "C1", key: "check_nichijo_c1" },
                    { label: "C2", key: "check_nichijo_c2" },
                ], false, true)
            },
            {
                name: "日常生活自立度", content: saC.checkList([
                    { label: "自立", key: "check_ninchi_jiritsu" },
                    { label: "I", key: "check_ninchi_1" },
                    { label: "IIa", key: "check_ninchi_2a" },
                    { label: "IIb", key: "check_ninchi_2b" },
                    { label: "IIIa", key: "check_ninchi_3a" },
                    { label: "IIIb", key: "check_ninchi_3b" },
                    { label: "IV", key: "check_ninchi_4" },
                    { label: "M", key: "check_ninchi_m" },
                ], false, true)
            }
        ]

        const elements_naiyou = [
            { name: "コピー", content: copyButton(["naiyou"], []) },
            { name: "PT/OT/STが行った訪問看護、家族等への指導、リスク管理等の内容", content: saV.text("naiyou") },
        ]
        const genList = (keyBase: string) => {
            return [
                { label: "自立(10)", key: "check_" + keyBase + "_jiritsu" },
                { label: "一部介助(5)", key: "check_" + keyBase + "_ichibu" },
                { label: "全部介助(0)", key: "check_" + keyBase + "_zen" },
            ]
        }
        const genList3 = (keyBase: string) => {
            return [
                { label: "自立(5)", key: "check_" + keyBase + "_jiritsu" },
                { label: "一部介助(0)", key: "check_" + keyBase + "_ichibu" },
                { label: "全部介助(0)", key: "check_" + keyBase + "_zen" },
            ]
        }
        const genList2 = (keyBase: string, s1: string, s2: string) => {
            return [
                { label: "自立(15)", key: "check_" + keyBase + "_jiritsu" },
                { label: "一部介助(" + s1 + ")(10)", key: "check_" + keyBase + "_ichibu_1" },
                { label: "一部介助(" + s2 + ")(5)", key: "check_" + keyBase + "_ichibu_2" },
                { label: "全部介助(0)", key: "check_" + keyBase + "_zen" },
            ]
        }

        const toKeys = (lis: any[]) => {
            return lis.map(x => x.key)
        }

        const elements_hyouka = [
            {
                name: "コピー", content: copyButton(
                    [
                        "communication",
                        "role",
                        "yoka",
                        "social",
                        "sanka",
                        "kango_assessment",
                        "shokuji_biko",
                        "ijou_biko",
                        "seiyou_biko",
                        "toilet_biko",
                        "nyuyoku_biko",
                        "hokou_biko",
                        "kaidan_biko",
                        "koui_biko",
                        "haiben_biko",
                        "hainyou_biko"
                    ],
                    [
                        ...toKeys(genList("shokuji")),
                        ...toKeys(genList2("ijou", "監視下", "座れるが移れない")),
                        ...toKeys(genList3("seiyou")),
                        ...toKeys(genList("toilet")),
                        ...toKeys(genList3("nyuyoku")),
                        ...toKeys(genList2("hokou", "歩行器等", "車椅子操作が可能")),
                        ...toKeys(genList("kaidan")),
                        ...toKeys(genList("koui")),
                        ...toKeys(genList("haiben")),
                        ...toKeys(genList("hainyou"))
                    ]
                )
            },
            { name: "食事", content: <div>{saC.checkList(genList("shokuji"), false, true)}{saV.text("shokuji_biko")}</div> },
            { name: "イスとベッド間の移乗", content: <div>{saC.checkList(genList2("ijou", "監視下", "座れるが移れない"), false, true)}{saV.text("ijou_biko")}</div> },
            { name: "整容", content: <div>{saC.checkList(genList3("seiyou"), false, true)}{saV.text("seiyou_biko")}</div> },
            { name: "トイレ動作", content: <div>{saC.checkList(genList("toilet"), false, true)}{saV.text("toilet_biko")}</div> },
            { name: "入浴", content: <div>{saC.checkList(genList3("nyuyoku"), false, true)}{saV.text("nyuyoku_biko")}</div> },
            { name: "平地歩行", content: <div>{saC.checkList(genList2("hokou", "歩行器等", "車椅子操作が可能"), false, true)}{saV.text("hokou_biko")}</div> },
            { name: "階段昇降", content: <div>{saC.checkList(genList("kaidan"), false, true)}{saV.text("kaidan_biko")}</div> },
            { name: "更衣", content: <div>{saC.checkList(genList("koui"), false, true)}{saV.text("koui_biko")}</div> },
            { name: "排便", content: <div>{saC.checkList(genList("haiben"), false, true)}{saV.text("haiben_biko")}</div> },
            { name: "排泄", content: <div>{saC.checkList(genList("hainyou"), false, true)}{saV.text("hainyou_biko")}</div> },
            { name: "合計点", content: <div>{this.calcScore(doc)}/100</div> },
            { name: "コミュニケーション", content: saV.text("communication") },
            { name: "家庭内の役割", content: saV.text("role") },
            { name: "余暇活動（内容及び頻度）", content: saV.text("yoka") },
            { name: "社会地域活動（内容及び頻度）", content: saV.text("social") },
            { name: "終了後に行いたい社会活動等の取組", content: saV.text("sanka") },
            { name: "看護職員との連携状況、看護の視点からの利用者の評価", content: saV.text("kango_assessment") },
        ]
        const elements_comment = [
            { name: "コピー", content: copyButton(["comment"], []) },
            { name: "内容", content: saV.text("comment") },
        ]

        const elements_houkoku_moto = [
            {
                name: "コピー", content: <div>
                    {copyButton(["branch_name", "kanri_name", "tanto1_name", "tanto2_name"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromBranch(doc, sa)}
                        className={classes.loadButton} >事業所情報から取得</Button>
                </div>
            },
            { name: "事業所名", content: saV.text("branch_name") },
            { name: "管理者名", content: saV.text("kanri_name") },
            {
                name: "担当者①", content: <div>
                    {saV.text("tanto1_name", "名前")}
                    {saV.text("tanto1_role", "職種")}
                </div>
            },
            {
                name: "担当者②", content: <div>
                    {saV.text("tanto2_name", "名前")}
                    {saV.text("tanto2_role", "職種")}
                </div>
            },
        ]

        const elements = [
            { name: '基本', content: elements_basic },
            { name: '報告先', content: elements_houkoku },
            { name: '自立度', content: elements_jiritsudo },
            { name: '内容', content: elements_naiyou },
            { name: '評価', content: elements_hyouka },
            { name: '特記すべき事項', content: elements_comment },
            { name: '報告元', content: elements_houkoku_moto },
        ]

        return elements
    }
}
