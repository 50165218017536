import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import { Select, MenuItem, ListItem, List, Button, Grid } from '@material-ui/core';
import * as Colors from '../components/Colors'
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import StandardTable from '../components/StandardTable';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import { StoreAccessor } from '../util/StoreUtil'
import Pagination from "material-ui-flat-pagination";
import Col2Table from '../components/Col2Table';
import { DateUtil } from '../util/DateUtil'

const styles = () => Styles.createStyles({
    root: {
        margin: "20px",
        maxWidth: "1100px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0px",
        fontSize: 'small'
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    activity_task_table: {
        textAlign: "center",
    },
    activity_task_item: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        padding: "2px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: Colors.backColor
        },
        whiteSpace: "pre-line"
    },
    activity_task_list: {
        padding: "0px"
    },
    activity_task_item_text: {
        paddingRight: "0px",
    },
    task_kigen: { width: "15%", maxWidth: "100px", marginLeft: "3px" },
    task_name: { width: "15%", maxWidth: "100px" },
    task_tanto: { width: "15%", maxWidth: "100px" },
    task_text: { width: "55%" },
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}


class Query {
    branch_id: number = 0
    word: string = ""
    only_done: boolean = false
    only_tanto: boolean = false
    only_favo: boolean = true
}


interface LocalState {
    branches: Model.Branch[]
    loading: boolean
    me: Model.User
    tasks: HokanApi.PagingResponse<Model.Task>
    query: Query
    is_done_mode: boolean
    tantoPatients: Model.IDName[]
}

// @inject()
// @observer
class TaskListPage extends React.Component<Props, LocalState> {
    displayName = "TaskListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            branches: [],
            loading: false,
            me: new Model.User(),
            tasks: new HokanApi.PagingResponse<Model.Task>(),
            query: new Query(),
            is_done_mode: false,
            tantoPatients: []
        }
    }

    public create_comment(message: string, uidStr: string): string {
        let found = false;
        const r = message.replace(/@\[(.*?)\]\(user:([\-0-9]*?)\)/g, (str, ...args: string[]) => {
            if (uidStr == args[1] || args[1] == "-1" || args[1] == "-2") found = true
            return `@${args[0]} `
        }).replace(/\\n/g, "\n")
        return r
    }

    load(query: Query, page = 1, per = 50) {
        this.setState({ loading: true, tasks: new HokanApi.PagingResponse<Model.Task>() })

        const search = (fpids: number[] | null = null) => {
            HokanApi.message.task_search({
                page: page,
                per: per,
                branch_id: query.branch_id,
                only_tantou: query.only_tanto,
                only_done: query.only_done,
                only_favo: query.only_favo,
                word: query.word,
                pids: query.only_tanto ? this.state.tantoPatients.map(x => x.id) : null,
                fpids: fpids
            }).then(res => {
                const data = res.data
                const tasks = data.data
                Promise.all([
                    HokanApi.getTantoNameList(tasks.filter(t => t.patient_id).map(t => t.patient_id)),
                    HokanApi.getPatientNameList(tasks.filter(t => t.patient_id).map(t => t.patient_id))
                ]).then(r => {
                    const rt = r[0]
                    const rp = r[1]
                    const tantoList = {}
                    const patList = {}
                    rt.data.forEach(x => tantoList[x.id] = x.name)
                    rp.data.forEach(x => patList[x.id] = x.name)
                    tasks.forEach((t, i) => {
                        t.content = this.create_comment(t.content, this.state.me.id.toString())
                        if (t.patient_id in patList) {
                            t.patient_name = patList[t.patient_id]
                        }
                        if (t.patient_id in tantoList) {
                            t.tanto_name = tantoList[t.patient_id]
                        }
                    })
                    this.setState({ tasks: data, is_done_mode: query.only_done, loading: false })
                })
            }).catch(e => {
                console.log(e)
                this.setState({ loading: false })
            })
        }

        if (query.word != "") {
            HokanApi.searchPatientName(query.branch_id, query.word).then(res => {
                const fpids = res.data.map(x => x.id)
                search(fpids)
            }).catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
        }
        else {
            search()
        }

    }

    componentDidMount() {
        HokanApi.me().then(x => {
            const q = this.state.query
            q.branch_id = x.data.branch_id
            this.setState({
                me: x.data,
                query: q
            })

            HokanApi.getTantoPatientIds(x.data.id).then(y => {
                this.setState({ tantoPatients: y.data })
                this.load(this.state.query)
            })
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })

        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    public render() {
        const { classes } = this.props;
        const { tasks } = this.state;
        const sa = new StoreAccessor(this, true)
        const saQ = sa.generateChild(["query"])
        const element_l = [
            {
                name: "", content: <div>
                    <Select
                        value={this.state.query.branch_id || 0}
                        autoWidth={true}
                        variant='outlined'
                        onChange={(e) => {
                            const q = this.state.query
                            q.branch_id = e.target.value as any
                            this.setState({ query: q })
                        }}
                        className={classes.input}
                    >
                        <MenuItem key='all' value={0}>全事業所</MenuItem>
                        {
                            this.state.branches.map((t, idx) =>
                                <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                            )
                        }
                    </Select>
                </div>
            },
            {
                name: "", content: saQ.text("word", "利用者名、タスク内容")
            },
            {
                name: "", content: saQ.checkList(
                    [
                    { label: "お気に入り", key: "only_favo" },
                    { label: "自担当", key: "only_tanto" },
                    { label: "終了済のみ", key: "only_done" },
                    ])
            },
            {
                name: "", content: <Button color="primary" variant="contained"
                    disabled={this.state.loading}
                    onClick={(e) => { this.load(this.state.query) }} >検索</Button>
            }
        ]
        const paging =
            <Pagination
                limit={tasks.per}
                offset={(tasks.page - 1) * tasks.per}
                total={tasks.count}
                onClick={(e, offset) => {
                    this.load(this.state.query, 1 + offset / tasks.per, tasks.per)
                }}
            />
        const cdStr = DateUtil.currentDateStr()
        return <div className={classes.root}>
            <StandardTable title=''>
                <Grid container={true} spacing={0}>
                    <Grid item={true} xs={12} md={12} lg={12}>
                        <Col2Table body={element_l} />
                    </Grid>
                </Grid>
            </StandardTable>
            {paging}
            <StandardTable title="">
                <List className={classes.activity_task_list}>
                    {
                        this.state.tasks.data.map((task, i) => {
                            const nextStr = task.date_limit
                            let hasExpired = false
                            if (nextStr != null && nextStr < cdStr) {
                                hasExpired = true;
                            }
                            if (task.done) {
                                hasExpired = false;
                            }
                            return <ListItem key={`taks_${i}`}
                                onClick={e => this.props.history.push(`/patients/${task.patient_id}`)}
                                className={classes.activity_task_item}
                                style={{
                                    borderWidth: "1px", borderRadius: "0px", zIndex: (hasExpired ? 2 : undefined),
                                    borderColor: hasExpired ? Colors.orangeColor : undefined,
                                    backgroundColor: hasExpired ? Colors.orangeColorTransparent : undefined
                                }}>

                                <div className={classes.task_name}>
                                    {task.patient_name}
                                </div>
                                <div className={classes.task_kigen}>
                                    {
                                        task.date_limit ?
                                            task.date_limit + "　"
                                            : "期限なし"
                                    }
                                </div>
                                <div className={classes.task_tanto}>
                                    {task.tanto_name}
                                </div>
                                <div className={classes.task_text}>
                                    {task.content}
                                </div>
                            </ListItem>
                        })
                    }
                </List>
            </StandardTable>
            {paging}
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(TaskListPage))