import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class KouhiBehaviour implements FormBehaviour {
    public constructor() {
    }

    public isPreviewable(): boolean { return false }
    public getId(): number { return 6 }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient) {
        doc.patient = pat
    }

    public setUser(doc: Model.Document.Document, user: Model.User) {
    }
    public createContent(): any {
        const res = new FormModel.Hoken.Core()
        return res
    }
    public shutten() { return null }
    public isFullWidth() {
        return false;
    }
    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public afterPost(doc: Model.Document.Document): void {
    }
    public createSucceed(doc: Model.Document.Document, reload: any): void {
        if (reload) reload();
    }
    public beforePost(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }

    public buildElements(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content"])
        
        const elements_basic = [
            { name: "利用者", content: sa.patient("patient", "patientOpenDialog") },
            { name: "番号", content: saV.text("bango") },
            { name: "期限", content: <div>{saV.date("kigen_to")}</div> },
            { name: "コメント", content: sa.text("comment") },
        ]

        const elements_images = [
            { name: "画像", content: sa.docImage(10)},
            { name: "動画・PDF", content: sa.docFile(10)} 
        ]


        const elements = [
            { name: '基本', content: elements_basic },
            { name: '画像', content: elements_images },
        ]

        return elements
    }
}
