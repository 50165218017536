
export class KagiAzukari {
    public id: number = 0;
    public data: KagiAzukariDetail[] = [];
}

export class KagiAzukariDetail {
    public name: string = ""
    public number: string = ""
    public quantity: string = ""
}