import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import Col2Table from './Col2Table';
import * as Model from '../models'
const styles = () => Styles.createStyles({
    innerTable: {
        padding: '0px'
    }
})
interface Props extends Styles.WithStyles {
    problem: Model.Problem
    assessmentSet: Model.Assessment.AssessmentSet
}

//@inject(...toInjector(Props))
//@observer
class OmahaProblem extends React.Component<Props> { 
	displayName="OmahaProblem"
    public render() {
        const {classes} = this.props
        const ns = this.props.assessmentSet
        const ass = ns.assessment;

        const nextStr = ass.closed ? "終了済" :
            ass.next_assessment_day == Model.Assessment.NullAssessmentNextDay ? "未設定" :
                ass.next_assessment_day
        const getKbsSentence = (score, kbs) => {
            return Model.Assessment.Assessment.getKBSSentence(ass, kbs, score)
        }
        const elm = [
            { name: "問題", content: <div>{this.props.problem.detail}</div> },
            {
                name: "症状・兆候", content: <div className={classes.innerTable} >
                    <div>
                        {ns.symptom.map((s, si) => <div key={`sym_${si}`}>{s.detail}</div>)}
                        {ns.free_symptoms.map((s, si) => <div key={`fs_${si}`}>{s}</div>)}
                    </div>
                </div>
            },
            { name: "優先度", content: <div>{ass.priority == 1 ? "高" : "低"}</div> },
            { name: "修飾因子①", content: <div>{ass.modifiers1}</div> },
            { name: "修飾因子②", content: <div>{ass.modifiers2}</div> },
            {
                name: "KBS", content: <div className={classes.innerTable} >
                    <Col2Table width="50px" disableBGColor={true} body={[
                        { name: "知識(K)", content: <div>{ass.kbs_score_k}：{getKbsSentence(ass.kbs_score_k, "K")} </div> },
                        { name: "行動(B)", content: <div>{ass.kbs_score_b}：{getKbsSentence(ass.kbs_score_b, "B")}</div> },
                        { name: "状態(S)", content: <div>{ass.kbs_score_s}：{getKbsSentence(ass.kbs_score_s, "S")}</div> },
                    ]} />
                </div>
            },
            {
                name: "介入", content: <div className={classes.innerTable} >
                    <Col2Table disableBGColor={true} width="50px" body={
                        ns.intervention.map(it => {
                            const ait = ns.assessment_intervention.find(x => x.intervention_id == it.id)
                            return { name: it.category, content: <div>{it.target_category}:{it.target} / {ait ? ait.comment : ""}</div> }
                        })
                    } />
                </div>
            },
            {
                name: "短期目標", content: <div>{ass.mokuhyou}</div>
            },
            {
                name: "評価日", content: <div>{ass.assessment_day}</div>
            },
            {
                name: "次回評価日", content: <div>{nextStr}</div>
            }
        ]
        return <Col2Table body={elm} width="50px"  />
    }
}

export default Styles.withStyles(styles)(OmahaProblem)