export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public create_date: string = "";
  public pat_name: string = "";
  public created_by: string = "";
  public free_text: string = "";
}

export class Checks {
  public check_hontai_1: boolean = false;
  public check_hontai_2: boolean = false;
  public check_hontai_3: boolean = false;
  public check_hontai_4: boolean = false;
  public check_hontai_5: boolean = false;
  public check_hontai_6: boolean = false;
  public check_hontai_7: boolean = false;
  public check_hontai_8: boolean = false;
  public check_hontai_9: boolean = false;
  public check_hontai_10: boolean = false;
  public check_hontai_11: boolean = false;
  public check_hontai_12: boolean = false;
  public check_hontai_13: boolean = false;
  public check_kairo_1: boolean = false;
  public check_kairo_2: boolean = false;
  public check_kairo_3: boolean = false;
  public check_kairo_4: boolean = false;
  public check_kairo_5: boolean = false;
  public check_kairo_6: boolean = false;
  public check_kairo_7: boolean = false;
  public check_kairo_8: boolean = false;
  public check_kairo_9: boolean = false;
  public check_kikan_1: boolean = false;
  public check_kikan_2: boolean = false;
  public check_kikan_3: boolean = false;
  public check_kikan_4: boolean = false;
  public check_kikan_5: boolean = false;
  public check_kikan_6: boolean = false;
  public check_kikan_7: boolean = false;
  public check_kikan_8: boolean = false;
  public check_kikan_9: boolean = false;
  public check_kikan_10: boolean = false;
}
