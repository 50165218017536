import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { authorizedFetch } from '../config/auth_axios';
import { ApiUrl, MessageApiUrl, PasswordResetUrl } from '../config/api';
import * as Model from '../models'
import * as FormModel from '../models/Forms'
import { readAndCompressImage } from 'browser-image-resizer';

export class ApiResponse<T> {
    public data?: T = undefined
}

export class PagingResponse<T> {
    public data: T[] = []
    public page: number = 0
    public per: number = 20
    public count: number = 0
}

export function me(): AxiosPromise<Model.User> {
    return authorizedFetch.get(ApiUrl("me"))
}

export function getUsers(branchId: number | null): AxiosPromise<Model.User[]> {
    return authorizedFetch.get(ApiUrl(branchId ? `branches/${branchId}/users` : `users`))
}
export function getUsersSimple(): AxiosPromise<Model.SimpleUser[]> {
    return authorizedFetch.get(ApiUrl(`user_simple`))
}
//export function getIryoKigus(): AxiosPromise<Model.Patient.IryoKigu[]> {
//    return authorizedFetch.get(ApiUrl(`master/tokubetu_kanri_kasan`))
//}

export function getGuidlines(): AxiosPromise<Model.Guidline[]> {
    return authorizedFetch.get(ApiUrl("guidlines"))
}
export function getServiceCodes(): AxiosPromise<Model.ServiceCode[]> {
    return authorizedFetch.get(ApiUrl("service_codes"))
}

export function getProblems(): AxiosPromise<Model.Problem[]> {
    return authorizedFetch.get(ApiUrl("problems"))
}

export function getProblem(id: number): AxiosPromise<Model.ProblemDetail> {
    return authorizedFetch.get(ApiUrl(`problems/${id}`))
}

export function getPatients(branchId: any | null = null): AxiosPromise<Model.Patient.Patient[]> {
    let query = ""
    if (branchId) {
        query = `branch_id=${branchId}`
    }
    if (query != "") query = "?" + query
    return authorizedFetch.get(ApiUrl("patients" + query))
}
export function getPatientsJimu(branchId: any | null = null): AxiosPromise<Model.Patient.Patient[]> {
    let query = ""
    if (branchId) {
        query = `branch_id=${branchId}`
    }
    if (query != "") query = "?" + query
    return authorizedFetch.get(ApiUrl("patients_jimu" + query))
}
export function getPatient(id: number): AxiosPromise<Model.Patient.Patient> {
    return authorizedFetch.get(ApiUrl(`patients/${id}`))
}
export function getPatientLog(id: number, log_id: number): AxiosPromise<Model.Patient.Patient> {
    return authorizedFetch.get(ApiUrl(`patients/${id}/log/${log_id}`))
}
export function getPatientProblems(id: number): AxiosPromise<Model.Problem[]> {
    return authorizedFetch.get(ApiUrl(`patients/${id}/problems`))
}
export function putPatient(id: number, data: Model.Patient.PatientPost): AxiosPromise<void> {
    return authorizedFetch.put(ApiUrl(`patients/${id}`), data)
}
export function postPatient(data: Model.Patient.PatientPost): AxiosPromise<void> {
    return authorizedFetch.post(ApiUrl(`patients`), data)
}

export function setTermConfirm(pid: number, disagree: boolean, sign_image: File | null, content: any = {}): AxiosPromise<any> {
    const fd = new FormData()
    fd.append("disagree", disagree ? "1" : "0")
    fd.append("content", JSON.stringify(content))
    if (sign_image) {
        fd.append("sign_image", sign_image)
    }
    return authorizedFetch.put(ApiUrl(`patients/${pid}/set_terms_confirm`), fd)
}

export function putPatientImage(id: number, data: FormData): AxiosPromise<void> {
    return authorizedFetch.put(ApiUrl(`patients/${id}/image`), data)
}

export function getPatientAssessments(id: number, time_lim?: string): AxiosPromise<Model.Assessment.AssessmentListResult[]> {
    const q = time_lim ? `?time_lim=${time_lim}` : ""
    return authorizedFetch.get(ApiUrl(`patients/${id}/assessments${q}`))
}
export function getPatientAssessmentsLog(pat_id: number, problem_id: number): AxiosPromise<Model.Assessment.AssessmentSet[]> {
    return authorizedFetch.get(ApiUrl(`patients/${pat_id}/assessments_log?problem_id=${problem_id}`))
}
export function getAssessment(pat_id: number, pid: number, ass_id: number): AxiosPromise<Model.Assessment.AssessmentSet> {
    return authorizedFetch.get(ApiUrl(`patients/${pat_id}/assessments/${pid}/${ass_id}`))
}
export function deleteAssessment(pat_id: number, pid: number, ass_id: number): AxiosPromise<Model.Assessment.AssessmentSet> {
    return authorizedFetch.delete(ApiUrl(`patients/${pat_id}/assessments/${pid}/${ass_id}`))
}

export function postFile(fd: FormData): AxiosPromise<void> {
    return authorizedFetch.post(ApiUrl(`files`), fd)
}

export function getFiles(): AxiosPromise<Model.File[]> {
    return authorizedFetch.get(ApiUrl(`files`))
}

export function deleteFile(id: number): AxiosPromise<void> {
    return authorizedFetch.delete(ApiUrl(`files/${id}`))
}

export function getCalls(): AxiosPromise<Model.CallFromApi[]> {
    return authorizedFetch.get(ApiUrl(`calls`))
}

export function getCall(id: number): AxiosPromise<Model.CallFromApi> {
    return authorizedFetch.get(ApiUrl(`calls/${id}`))
}

export function searchTasks(branch_id: number, query: any, page: number, per: number): AxiosPromise<PagingResponse<Model.Task>> {
    let q = `tasks?branch_id=${branch_id}&page=${page}&per=${per}`
    if (query.only_done) q += "&only_done=1"
    if (query.only_tanto) q += "&only_tanto=1"
    if (query.word) q += `&word=${query.word}`
    return authorizedFetch.get(ApiUrl(q))
}

export function getServerSideKasan(patient_id: number,
    time_start: string,
    time_end: string,
    params: {
        is_post: boolean,
        is_jun: boolean,
        is_sen: boolean,
        is_kaigo: boolean,
        is_iryo: boolean,
        is_seishin: boolean,
        is_ryouyou1: boolean,
        is_ryouyou2: boolean,
        is_ryouyou3: boolean,
        is_ryouyou4: boolean,
        is_teiki: boolean,
        is_hiwari: boolean
    }): AxiosPromise<Model.KirokuII.ServerSideKasan> {
    let query = `time_start=${time_start}&time_end=${time_end}`
    Object.keys(params).forEach(key => {
        query += `&${key}=${params[key] ? 1 : 0}`
    })
    return authorizedFetch.get(ApiUrl(`patients/${patient_id}/detect_code?${query}`))
}

export function getTasks(patient_id: number): AxiosPromise<Model.Task[]> {
    return authorizedFetch.get(ApiUrl(`patients/${patient_id}/tasks`))
}
export function postTask(patient_id: number, data: Model.Task): AxiosPromise<Model.Task> {
    return authorizedFetch.post(ApiUrl(`patients/${patient_id}/tasks`), data)
}
export function putTask(patient_id: number, task_id: number, data: Model.Task): AxiosPromise<Model.Task> {
    return authorizedFetch.put(ApiUrl(`patients/${patient_id}/tasks/${task_id}`), data)
}
export function deleteTask(patient_id: number, task_id: number): AxiosPromise<Model.Task> {
    return authorizedFetch.delete(ApiUrl(`patients/${patient_id}/tasks/${task_id}`))
}

export function getComments(patient_id: number): AxiosPromise<Model.Comment.Comment[]> {
    return authorizedFetch.get(ApiUrl(`patients/${patient_id}/comments`))
}
export function postComment(patient_id: number, data: Model.Comment.CommentPost): AxiosPromise<Model.Comment.Comment> {
    const fd = new FormData()
    fd.append("message", data.message)
    fd.append("content", data.content)
    fd.append("comment_type", data.comment_type)
    data.images.forEach((f) => {
        fd.append("images[]", f)
    })
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`patients/${patient_id}/comments`),
        data: fd
    })
}

export function putComment(patient_id: number, comment_id: number, data: Model.Comment.CommentPost): AxiosPromise<Model.Comment.Comment> {
    return authorizedFetch.put(ApiUrl(`patients/${patient_id}/comments/${comment_id}`), data)
}
export function deleteComment(patient_id: number, comment_id: number): AxiosPromise<Model.Comment.Comment> {
    return authorizedFetch.delete(ApiUrl(`patients/${patient_id}/comments/${comment_id}`))
}
export function putSaigaiPDF(branch_id: number, file: File) {
    const fd = new FormData()
    fd.append("saigai_pdf", file)
    return authorizedFetch.request({
        method: 'PUT',
        url: ApiUrl(`branches/${branch_id}/update_saigai_pdf`),
        data: fd
    })
}

export function putClaimPDF(branch_id: number, file: File) {
    const fd = new FormData()
    fd.append("claim_pdf", file)
    return authorizedFetch.request({
        method: 'PUT',
        url: ApiUrl(`branches/${branch_id}/update_claim_pdf`),
        data: fd
    })
}
export function putJikoPDF(branch_id: number, file: File) {
    const fd = new FormData()
    fd.append("jiko_pdf", file)
    return authorizedFetch.request({
        method: 'PUT',
        url: ApiUrl(`branches/${branch_id}/update_jiko_pdf`),
        data: fd
    })
}

export function postCommentChild(patient_id: number, comment_id: number, data: Model.Comment.CommentChildPost): AxiosPromise<Model.Comment.CommentChild> {
    const fd = new FormData()
    fd.append("message", data.message)
    fd.append("content", data.content)
    data.images.forEach((f) => {
        fd.append("images[]", f)
    })
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`patients/${patient_id}/comments/${comment_id}/comment_children`),
        data: fd
    })
}
export function putCommentChild(patient_id: number, comment_id: number, child_id: number, data: Model.Comment.CommentChildPost): AxiosPromise<Model.Comment.CommentChild> {
    return authorizedFetch.put(ApiUrl(`patients/${patient_id}/comments/${comment_id}/comment_children/${child_id}`), data)
}
export function deleteCommentChild(patient_id: number, comment_id: number, child_id: number): AxiosPromise<Model.Comment.CommentChild> {
    return authorizedFetch.delete(ApiUrl(`patients/${patient_id}/comments/${comment_id}/comment_children/${child_id}`))
}

export function getSameBuildings(patient_id: number): AxiosPromise<Model.Patient.SameBuilding> {

    return authorizedFetch.get(ApiUrl(`patients/${patient_id}/same_buildings`))
}
export function putSameBuildings(patient_id: number, data: Model.Patient.SameBuilding): AxiosPromise<void> {

    return authorizedFetch.put(ApiUrl(`patients/${patient_id}/same_buildings`), data)
}

export function postDocChatChild(doc_id: number, data: Model.Document.DocumentChatChildPost): AxiosPromise<Model.Document.DocumentChatChild> {
    const fd = new FormData()
    fd.append("message", data.message)
    fd.append("content", data.content)
    data.images.forEach((f) => {
        fd.append("images[]", f)
    })
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`documents/${doc_id}/document_chat_children`),
        data: fd
    })
}


export function getDoukous(user_id: number, page: number, per: number, st: string, en: string, query: any): AxiosPromise<PagingResponse<Model.Score.KangoKirokuDoukou>> {
    let q = ""
    if (query.is_learn) q += "&is_learn=1"
    if (query.is_teach) q += "&is_teach=1"
    if (query.patient && query.patient.id) q += "&pid=" + query.patient.id
    return authorizedFetch.get(ApiUrl(`scores/user/${user_id}/doukou?page=${page}&per=${per}&start=${st}&end=${en}` + q))
}

export function postDoukou(data: Model.Score.DoukouShinchokuPost): AxiosPromise<Model.Score.DoukouShinchoku> {
    return authorizedFetch.post(ApiUrl(`scores/user/${data.user_id}/doukou/${data.id}`), data)
}
export function putDoukou(data: Model.Score.DoukouShinchokuPost): AxiosPromise<void> {
    return authorizedFetch.put(ApiUrl(`scores/user/${data.user_id}/doukou/${data.id}`), data)
}

export function getCallKinds(): AxiosPromise<Model.CallKind[]> {
    return authorizedFetch.get(ApiUrl(`call_kinds`))
}

export function deleteKirokuII(patient_id: number, kiroku_id: number): AxiosPromise<void> {
    return authorizedFetch.delete(ApiUrl(`patients/${patient_id}/kango_kirokus/${kiroku_id}`))
}
export function postKirokuII(patient_id: number, data: Model.KirokuII.KangoKirokuIIPost): AxiosPromise<void> {
    return authorizedFetch.post(ApiUrl(`patients/${patient_id}/kango_kirokus`), data)
}
export function getKirokuIIs(patient_id: number, page: number, per: number): AxiosPromise<PagingResponse<Model.KirokuII.KangoKirokuII>> {

    return authorizedFetch.get(ApiUrl(`patients/${patient_id}/kango_kirokus?page=${page}&per=${per}`))
}

export function getKirokuIIsForHoukoku(patient_id: number, from: string, to: string): AxiosPromise<PagingResponse<Model.KirokuII.KangoKirokuII>> {
    return authorizedFetch.get(ApiUrl(`patients/${patient_id}/list_houkoku?from=${from}&to=${to}`))
}
export function searchKirokuIIs(branch_id: number, from: string, to: string, option: any): AxiosPromise<PagingResponse<Model.KirokuII.KangoKirokuII>> {
    let query = `?branch_id=${branch_id}&from=${from}&to=${to}`
    if (option.name) query += `&name=${option.name}`
    if (option.name_kana) query += `&name_kana=${option.name_kana}`
    if (option.tanto_name) query += `&tanto_name=${option.tanto_name}`
    if (option.hoken) query += `&hoken=${option.hoken}`
    if (option.page) query += `&page=${option.page}`
    if (option.per) query += `&per=${option.per}`
    if (option.with_temp) query += `&with_temp=${option.with_temp}`
    if (option.only_temp) query += `&only_temp=${option.only_temp}`
    if (option.name_order) query += `&name_order=${option.name_order}`
    return authorizedFetch.get(ApiUrl(`/import/kirokus${query}`))
}
export function searchKirokuIIsUser(user_id: number|null, from: string, to: string, option: any): AxiosPromise<PagingResponse<Model.KirokuII.KangoKirokuII>> {
    let query = `?user_id=${user_id}&from=${from}&to=${to}&with_temp=1`
    if (option.page) query += `&page=${option.page}`
    if (option.per) query += `&per=${option.per}`
    if (option.order) query += `&order=${option.order}`
    return authorizedFetch.get(ApiUrl(`/import/kirokus${query}`))
}

export function createWisemanJisseki(branch_id: number, from: string, to: string, option: any): AxiosPromise<any> {
    let query = `?branch_id=${branch_id}&from=${from}&to=${to}`
    if (option.name) query += `&name=${option.name}`
    if (option.name_kana) query += `&name_kana=${option.name_kana}`
    if (option.hoken) query += `&hoken=${option.hoken}`
    return authorizedFetch.get(ApiUrl(`/import/generate_jisseki${query}`))
}
export function createSukoyakaJisseki(branch_id: number, from: string, to: string, option: any): AxiosPromise<any> {
    let query = `?branch_id=${branch_id}&from=${from}&to=${to}`
    if (option.name) query += `&name=${option.name}`
    if (option.name_kana) query += `&name_kana=${option.name_kana}`
    if (option.hoken) query += `&hoken=${option.hoken}`
    return authorizedFetch.get(ApiUrl(`/import/generate_sukoyaka_jisseki${query}`))
}

export function getKirokuIILatestN(patient_id: number, n: number, time_lim: string | null = null): AxiosPromise<Model.KirokuII.KangoKirokuII[]> {
    let query = `?per=${n}`
    if (time_lim) {
        query += "&time_lim=" + time_lim
    }
    return authorizedFetch.get(ApiUrl(`patients/${patient_id}/kango_kirokus/latest${query}`))
}

export function getKirokuII(patient_id: number, kiroku_id: number): AxiosPromise<Model.KirokuII.KangoKirokuIISimple> {
    return authorizedFetch.get(ApiUrl(`patients/${patient_id}/kango_kirokus/${kiroku_id}`))
}
export function putKirokuII(patient_id: number, kiroku_id: number, data: Model.KirokuII.KangoKirokuIIPost): AxiosPromise<any> {
    return authorizedFetch.put(ApiUrl(`patients/${patient_id}/kango_kirokus/${kiroku_id}`), data)
}


export function postCall(call: Model.CallToApi): AxiosPromise<void> {
    return authorizedFetch.post(ApiUrl(`calls`), call)
}

export function updateCall(call: Model.CallToApi, id: number): AxiosPromise<void> {
    return authorizedFetch.put(ApiUrl(`calls/${id}`), call)
}

export function getBlockCategory(): AxiosPromise<Model.CategoryAndBlock> {
    return authorizedFetch.get(ApiUrl(`blocks/`))
}

export function postAssessment(patient_id: number, data: Model.Assessment.PostAssessment): AxiosPromise<void> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`patients/${patient_id}/assessments`),
        data: data
    })
}

export function putAssessment(pat_id: number, data: Model.Assessment.PostAssessment): AxiosPromise<void> {
    return authorizedFetch.put(ApiUrl(`patients/${pat_id}/assessments/${data.assessment.problem_id}/${data.assessment.id}`), data)
}


export function postAssessmentList(patient_id: number, data: Model.Assessment.PostAssessment[]): AxiosPromise<void> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`patients/${patient_id}/assessments_list`),
        data: { assessments: data }
    })
}

export function postWisemanCustomer(bid: number, data: Model.Import.WisemanCustomer[]): AxiosPromise<void> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`import/wiseman_customer`),
        data: { branch_id: bid, data: data }
    })
}
export function postWisemanStaff(bid: number, data: Model.Import.WisemanStaff[]): AxiosPromise<void> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`import/wiseman_staff`),
        data: { branch_id: bid, data: data }
    })
}

export function postWyLCustomer(bid: number, data: Model.Patient.PatientPost[]): AxiosPromise<void> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`import/customer`),
        data: { branch_id: bid, data: data }
    })
}
export function postWyLStaff(bid: number, data: Model.Import.WyLStaff[]): AxiosPromise<void> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`import/staff`),
        data: { branch_id: bid, data: data }
    })
}


export function reloadTanto(obj: Model.OrganizationTanto | null, callback: () => void): void {
    if (obj == null || obj.id == 0) return;
    authorizedFetch.get<Model.OrganizationTanto>(ApiUrl(`organizations/${obj.org.id}/people/${obj.id}`)).then(res => {
        Object.keys(res.data).forEach(key => {
            obj[key] = res.data[key]
        })
        callback()
    }).catch(err => {
    })
}

export function reloadTantoRaw(obj: Model.OrganizationTanto): Promise<any> {
    return authorizedFetch.get<Model.OrganizationTanto>(ApiUrl(`organizations/${obj.org.id}/people/${obj.id}`)).then(res => {
        Object.keys(res.data).forEach(key => {
            obj[key] = res.data[key]
        })
        return "ok"
    }).catch(err => {
        obj['deleted'] = true
    })
}

export function getScoreBranch(branch_id: number, st: string, en: string): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/branch/${branch_id}?start=${st}&end=${en}`))
}
export function getCountReport(branch_id: number, st: string, en: string): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/branch/count_report/${branch_id}?start=${st}&end=${en}`))
}
export function getOmahaReport(branch_id: number, st: string, en: string): AxiosPromise<any> {
    return authorizedFetch.request({
        method: 'GET',
        url: ApiUrl(`scores/branch/omaha_report/${branch_id}?start=${st}&end=${en}`),
        responseType: 'blob'
    })
}

export function getIncidents(branch_id: number, isJiko: boolean): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`incidents?bid=${branch_id}&isJiko=${isJiko ? 1 : 0}`))
}
export function getScoreBranchOmahaKbs(branch_id: number, term: number, st: string, en: string): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/branch/omaha_kbs/${branch_id}?term=${term}&start=${st}&end=${en}`))
}
export function getScoreUserOmahaKbs(branch_id: number, user_id: number, term: number, st: string, en: string): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/branch/omaha_kbs/${branch_id}?term=${term}&user_id=${user_id}&start=${st}&end=${en}`))
}
export function getScoreUser(user_id: number, st: string, en: string): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/user/${user_id}?start=${st}&end=${en}`))
}
// 時間が非常にかかるやつ。一次対応として退避
export function getScoreUserAsync(user_id: number, st: string, en: string): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/user/async/${user_id}?start=${st}&end=${en}`))
}
export function getScoreRanking(branch_id: number, name: string): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/branch/ranking/${branch_id}?name=${name}`))
}
export function getScorePatient(patient_id: number): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/patient/${patient_id}`))
}

export function getScoreBranchOmaha(branch_id: number): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`scores/branch/${branch_id}/omaha`))
}

export function getDocumentTypes(): AxiosPromise<Model.Document.DocumentType[]> {
    return authorizedFetch.get(ApiUrl(`documents/types`))
}
export function getDocumentType(id: number): AxiosPromise<Model.Document.DocumentType> {
    return authorizedFetch.get(ApiUrl(`documents/types/${id}`))
}
export function getChatDocuments(pid: number, page: number = 0, per: number = 5, q: any = null): AxiosPromise<PagingResponse<Model.Document.DocumentChat>> {
    let query = `patient_id=${pid}&page=${page}&per=${per}`
    if (q) {
        if (q.doc_type) query += `&type_id=${q.doc_type}`
        if (q.title) query += `&title=${q.title}`
    }

    return authorizedFetch.get(ApiUrl(`documents/chat?${query}`))
}

export function getDocumentsCount(page: number = 0, per: number = 20, option: {
    typeId?: number,
    branchId?: number,
    patientId?: number,
    comment?: string,
    title?: string,
    bui?: string,
    asc?: boolean,
    time_lim?: string,
    is_simple?: boolean
} = {}): AxiosPromise<Model.Document.DocumentList> {
    let query = `page=${page}&per=${per}`
    if (option.typeId) query += `&type_id=${option.typeId}`
    if (option.patientId) query += `&patient_id=${option.patientId}`
    if (option.branchId) query += `&branch_id=${option.branchId}`
    if (option.comment) query += `&comment=${option.comment}`
    if (option.title) query += `&title=${option.title}`
    if (option.bui) query += `&bui=${option.bui}`
    if (option.time_lim) query += `&time_lim=${option.time_lim}`
    if (option.is_simple) query += `&is_simple=${option.is_simple}`
    if (option.asc) query += `&asc=1`
    return authorizedFetch.get(ApiUrl(`documents/index_count?${query}`))
}

export function getDocuments(page: number = 0, per: number = 20, option: {
    typeId?: number,
    branchId?: number,
    patientId?: number,
    comment?: string,
    title?: string,
    bui?: string,
    asc?: boolean,
    time_lim?: string,
    is_simple?: boolean
} = {}): AxiosPromise<Model.Document.DocumentList> {
    let query = `page=${page}&per=${per}`
    if (option.typeId) query += `&type_id=${option.typeId}`
    if (option.patientId) query += `&patient_id=${option.patientId}`
    if (option.branchId) query += `&branch_id=${option.branchId}`
    if (option.comment) query += `&comment=${option.comment}`
    if (option.title) query += `&title=${option.title}`
    if (option.bui) query += `&bui=${option.bui}`
    if (option.time_lim) query += `&time_lim=${option.time_lim}`
    if (option.is_simple) query += `&is_simple=${option.is_simple}`
    if (option.asc) query += `&asc=1`
    return authorizedFetch.get(ApiUrl(`documents?${query}`))
}

export function getDesignRBuiList(patient_id: number): AxiosPromise<any[]> {
    let query = `patient_id=${patient_id}`
    return authorizedFetch.get(ApiUrl(`documents/bui_list?${query}`))
}

export function getMonthlyDocuments(typeId: number, option: {
    patientId?: number,
    branchId?: number,
    year?: number,
    month?: number
} = {}): AxiosPromise<Model.Document.MonthlyDocumentList> {
    let query = `type_id=${typeId}`
    if (option.patientId) query += `&patient_id=${option.patientId}`
    if (option.branchId) query += `&branch_id=${option.branchId}`
    if (option.month) query += `&month=${option.month}`
    return authorizedFetch.get(ApiUrl(`documents/keikakushos?${query}`))
}
export function getNewestDocuments(typeId: number, option: {
    patientId?: number,
    branchId?: number,
} = {}): AxiosPromise<Model.Document.NewestDocumentList> {
    let query = `type_id=${typeId}`
    if (option.patientId) query += `&patient_id=${option.patientId}`
    if (option.branchId) query += `&branch_id=${option.branchId}`
    return authorizedFetch.get(ApiUrl(`documents/newests?${query}`))
}
export function getDocument(id: number): AxiosPromise<Model.Document.Document> {
    return authorizedFetch.get(ApiUrl(`documents/${id}`)).then(async res => {
        await Model.Document.Document.Init(res.data)
        return res
    })
}
export function getDocumentLog(id: number, log_id: number): AxiosPromise<Model.Document.Document> {
    return authorizedFetch.get(ApiUrl(`documents/${id}/log/${log_id}`)).then(async res => {
        await Model.Document.Document.Init(res.data)
        return res
    })
}
export function getDocumentPdfUrl(id: number): AxiosPromise<string> {
    return authorizedFetch.get(ApiUrl(`documents/${id}/pdf_url`))
}

export function getDocumentPdfJob(job_id: string): AxiosPromise<any> {
    return authorizedFetch.get(ApiUrl(`documents/job?job_id=${job_id}`))
}



export function getDocumentLatest(patient_id: number, document_type_id: number, time_lim?: string): AxiosPromise<Model.Document.Document> {
    let q = `?patient_id=${patient_id}&document_type_id=${document_type_id}`
    if (time_lim) {
        q += `&time_lim=${time_lim}`
    }
    return authorizedFetch.get(ApiUrl(`documents/latest${q}`)).then(res => {
        Model.Document.Document.Init(res.data)
        return res
    })
}
export function checkDocumentExists(patient_id: number, document_type_id: number, target_month: number | null, own_id: number | null): AxiosPromise<boolean> {
    let q = `?patient_id=${patient_id}&document_type_id=${document_type_id}`
    if (target_month) q += `&target_month=${target_month}`
    if (own_id) q += `&own_id=${own_id}`
    return authorizedFetch.get(ApiUrl(`documents/check_exists${q}`)).then(res => {
        return res
    })
}
export function postDocument(data: Model.Document.DocumentPost): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`documents`),
        data: data.fd
    })
}
export function sendMFA(as_email: boolean): AxiosPromise<any> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`send_mfa`),
        data: { email: as_email }
    })
}
export function confirmMFA(token: string): AxiosPromise<any> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`confirm_mfa`),
        data: { token: token }
    })
}
export function sendLog(data: any): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(`front_log`),
        data: { content: JSON.stringify(data) }
    })
}
export function sendNewCompany(data: any): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.post(ApiUrl('system/company_init'), data)
}
export function systemChangePassword(mail: string, pass: string): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.post(ApiUrl('system/change_password'), {
        mail: mail, pass: pass
    })
}
export function sendInquiry(data: any): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.post(ApiUrl('inquiry'), data)
}
export function putDocument(data: Model.Document.DocumentPost): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.request({
        method: 'PUT',
        url: ApiUrl(`documents/${data.id}`),
        data: data.fd
    })
}
export function generatePdf(data: Model.Document.DocumentPost): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl("documents/generate_pdf"),
        responseType: 'blob',
        data: data.fd
    })
}
export function generatePdfList(ids: number[], sort: boolean, document_type_id: number): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl("documents/generate_pdf_list"),
        responseType: 'json',
        data: { ids: ids, sort: sort, document_type_id: document_type_id }
    })
}
export function generateOmahaScore(bid:number, start_day:string, end_day:string): AxiosPromise<any> {
    //return authorizedFetch.post(ApiUrl(`documents`), data.fd)
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl("scores/omaha_report"),
        responseType: 'json',
        data: { bid:bid, start_day:start_day, end_day:end_day }
    })
}


export function getTantoPatientIds(user_id: number): AxiosPromise<Model.IDName[]> {
    return authorizedFetch.get(ApiUrl(`users/${user_id}/tanto_patients`))
}

function getIDNameListAPI(path: string, ids: number[]): AxiosPromise<Model.IDName[]> {
    let query = "?"
    ids.forEach((id, i) => {
        if (i > 0) query += "&"
        query += `ids[]=${id.toString()}`
    })

    return authorizedFetch.get(ApiUrl(path + query))
}

export function searchPatientName(branch_id: number, name: string): AxiosPromise<Model.IDName[]> {
    let query = `?branch_id=${branch_id}&name=${name}`
    return authorizedFetch.get(ApiUrl("patients/name_search" + query))
}

export function getPatientNameList(patient_ids: number[]): AxiosPromise<Model.IDName[]> {
    return getIDNameListAPI(`patients/name_list`, patient_ids)
}

export function getTantoNameList(patient_ids: number[]): AxiosPromise<Model.IDName[]> {
    return getIDNameListAPI(`patients/tanto_list`, patient_ids)
}

export function getUserNameList(user_ids: number[]): AxiosPromise<Model.IDName[]> {
    return getIDNameListAPI(`users/name_list`, user_ids)
}


//--------------------------------------------------

export function postFormKagiAzukari(data: FormModel.KagiAzukari): AxiosPromise<any> {
    return postPdfForm(`documents/forms/kagi_azukari`, data)
}
export function postPdfForm<T>(path: string, data: T): AxiosPromise<Model.Forms.PdfResponse> {
    return authorizedFetch.request({
        method: 'POST',
        url: ApiUrl(path),
        responseType: 'blob',
        data: data
    })
}
//---------------------------------------------------------


const jsonObj = (name, model) => {
    const res = {}
    res[name] = model
    return res
}

interface HasID {
    id: number;
}

function simpleModelFactory<T extends HasID, P extends HasID = T>(url: string, name: string = url) {
    return {
        list: () => authorizedFetch.get<T[]>(ApiUrl(url)),
        get: (id: number) => authorizedFetch.get<T>(ApiUrl(`${url}/${id}`)),
        put: (model: P) => authorizedFetch.put<T>(ApiUrl(`${url}/${model.id}`), jsonObj(name, model)),
        post: (model: P) => authorizedFetch.post<T>(ApiUrl(url), jsonObj(name, model)),
        delete: (id: number) => authorizedFetch.delete(ApiUrl(`${url}/${id}`))
    }
}

export const organization = simpleModelFactory<Model.Organization>('organizations', 'organization')
export const company = simpleModelFactory<Model.Company>('companies', 'company')
export const branch = simpleModelFactory<Model.Branch>(`branches`, 'branch')
export const user = simpleModelFactory<Model.User>(`users`, 'user')
export const incident = simpleModelFactory<Model.Incident.Incident, Model.Incident.IncidentPost>(`incidents`, 'incident')
export const message = {
    list: (branch_id: number | null = null) => authorizedFetch.get<any[]>(MessageApiUrl('thread' + (branch_id ? `?branch_id=${branch_id}` : ""))),

    create: (model: Model.CreateTalk) => authorizedFetch.post<any>(MessageApiUrl(`thread`), jsonObj("thread", model)),
    get: (id: number) => authorizedFetch.get<any>(MessageApiUrl(`thread/${id}`)),
    update: (id:number, model: Model.UpdateTalk) => authorizedFetch.put<any>(MessageApiUrl(`thread/${id}`), jsonObj("thread", model)),

    create_wc: (model: Model.CreateWcTalk) => authorizedFetch.post<any>(MessageApiUrl(`thread/wc`), jsonObj("thread", model)),
    get_wc: (id: number) => authorizedFetch.get<any>(MessageApiUrl(`thread/${id}/wc`)),
    update_wc: (id:number, model: Model.UpdateWcTalk) => authorizedFetch.put<any>(MessageApiUrl(`thread/${id}/wc`), jsonObj("thread", model)),


    mention_list: () => authorizedFetch.get<any[]>(MessageApiUrl(`message/mention`)),
    my_task_list: () => authorizedFetch.get<any[]>(MessageApiUrl(`message/my_task`)),
    limit_tasks: () => authorizedFetch.get<any[]>(MessageApiUrl(`message/limit_tasks`)),
    message_list: (id: number, page: number) => authorizedFetch.get<any[]>(MessageApiUrl(`thread/${id}/message?page=${page}`)),
    message_list_wc: (id: number, page: number) => authorizedFetch.get<any>(MessageApiUrl(`thread/${id}/wc/message?page=${page}`)),
    task_list: (id: number) => authorizedFetch.get<any[]>(MessageApiUrl(`thread/${id}/task`)),
    task_search: (params: any) => {
        let query = `task_search?page=${params['page']}&per=${params['per']}&branch_id=${params['branch_id']}&only_done=${params['only_done']}&only_tantou=${params['only_tantou']}&only_favo=${params['only_favo']}&word=${params['word']}`
        if (params['pids']) {
            params['pids'].forEach(id => query += `&pids[]=${id}`)
        }
        if (params['fpids']) {
            params['fpids'].forEach(id => query += `&fpids[]=${id}`)
        }
        return authorizedFetch.get<PagingResponse<Model.Task>>(MessageApiUrl(query))
    },
    update_task: (id: number, task_id: number, model: Model.UpdateTask) => authorizedFetch.post<any[]>(MessageApiUrl(`thread/${id}/task/${task_id}`), jsonObj("task", model)),
    update_message: (id: number, message_id: number, model: Model.UpdateMessage) => authorizedFetch.post<any[]>(MessageApiUrl(`thread/${id}/message/${message_id}`), jsonObj("message", model)),
    delete_message: (id: number, message_id: number) => authorizedFetch.delete<any[]>(MessageApiUrl(`thread/${id}/message/${message_id}`)),
    task_list_wc: (id: number) => authorizedFetch.get<any[]>(MessageApiUrl(`thread/${id}/wc/task`)),

    favorite_wc: (id: number) => authorizedFetch.post<any[]>(MessageApiUrl(`thread/${id}/wc/favorite`)),
    delete_favorite_wc: (id: number) => authorizedFetch.delete<any[]>(MessageApiUrl(`thread/${id}/wc/favorite`)),

    message_user_list: (id: number) => authorizedFetch.get<any[]>(MessageApiUrl(`thread/${id}/user`)),
    message_user_list_wc: (id: number) => authorizedFetch.get<any[]>(MessageApiUrl(`thread/${id}/wc/user`)),

    post: (id: number, model: Model.CreateMessage) => authorizedFetch.post<any>(MessageApiUrl(`thread/${id}/message`), jsonObj("message", model)),
    post_wc: (id: number, model: Model.CreateMessage) => authorizedFetch.post<any>(MessageApiUrl(`thread/${id}/wc/message`), jsonObj("message", model)),

    user_update: (id: number) => authorizedFetch.put<any[]>(MessageApiUrl(`user/wc/${id}`)),
    user_delete: (id: number) => authorizedFetch.delete<any[]>(MessageApiUrl(`user/wc/${id}`)),
    user_import: (id: number) => authorizedFetch.post<any[]>(MessageApiUrl(`user/${id}`)),
}

export const webpush = {
    update: (model: Model.WebPushInfo) => authorizedFetch.post<any>(MessageApiUrl(`user/webpush`), jsonObj("webpush", model)),
}

export function sendChangePasswordMail(id: number): AxiosPromise<string> {
    return authorizedFetch.put(ApiUrl(`change_password/${id}`), {})
}

export const changePassword = (id: number, token: string, password: string) => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': `application/json`
        }
    }
    return Axios.create().post(PasswordResetUrl(), {
        "user_id": id,
        "password": password,
        "token": token
    }, config)
}

/*
export function getOrganizations(): AxiosPromise<[]> {
    return authorizedFetch.get(ApiUrl(`organizations`))
}

export function getOrganization(org_id): AxiosPromise<Model.Organization> {
    return authorizedFetch.get(ApiUrl(`organizations/${org_id}`))
}

export function putOrganization(org:Model.Organization): AxiosPromise<void> {
    return authorizedFetch.put(ApiUrl(`organizations/${org.id}`), {organization: org})
}

export function postOrganization(org: Model.Organization): AxiosPromise<void> {
    return authorizedFetch.post(ApiUrl(`organizations`), { organization: org })
}
export function deleteOrganization(org_id: number): AxiosPromise<void> {
    return authorizedFetch.delete(ApiUrl(`organizations/${org_id}`))
}
*/
