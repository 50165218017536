import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 72;
  }

  public createContent(): any {
    const res = new FormModel.StartC.Core();
    res.values.create_date = DateUtil.currentDateStr();
    HokanApi.me()
      .then((x) => {
        if (x && x.data) {
          res.values.created_by = x.data.name
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {}

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);
    const days = Array(14)
      .fill(null)
      .map((_, i) => i + 1);
    const reasons = [
      [
        { title: "本人家族：理解不足", value: 1 },
        { title: "本人家族：介護力", value: 2 },
        { title: "本人家族：身体的変化", value: 3 },
      ],
      [
        { title: "調整：退院調整不足", value: 4 },
        { title: "調整：病院連携不足", value: 5 },
        { title: "調整：サービス連携不足", value: 6 },
        { title: "調整：在宅医連携不足", value: 7 },
      ],
      [
        { title: "看護：サービススケジュールの問題", value: 8 },
        { title: "看護：ケア実施の問題", value: 9 },
        { title: "看護：目標・計画の問題", value: 10 },
      ],
    ];

    const elements_t = [
      {
        name: "内容",
        content: (
          <div>
            {days.map((day) => {
              return (
                <>
                  <hr style={{ margin: 0 }} />
                  <div
                    style={{
                      padding: 10,
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <div>{day}日目</div>
                    <label style={{ marginTop: 10 }}>
                      日付
                      {saV.date(`date_${day}`)}
                    </label>
                    <label style={{ marginTop: 10 }}>
                      環境的領域
                      {saV.text(
                        `environment_${day}`,
                        "",
                        "text",
                        "block",
                        true,
                        "50px"
                      )}
                    </label>
                    <label style={{ marginTop: 10 }}>
                      心理社会的領域
                      {saV.text(
                        `psychology_${day}`,
                        "",
                        "text",
                        "block",
                        true,
                        "50px"
                      )}
                    </label>
                    <label style={{ marginTop: 10 }}>
                      生理的領域
                      {saV.text(
                        `physiologic_${day}`,
                        "",
                        "text",
                        "block",
                        true,
                        "50px"
                      )}
                    </label>
                    <label style={{ marginTop: 10 }}>
                      健康関連行動領域
                      {saV.text(
                        `health_${day}`,
                        "",
                        "text",
                        "block",
                        true,
                        "50px"
                      )}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        ),
      },
      {
        name: "目標に対する評価",
        content: (
          <div
            style={{
              padding: 10,
            }}
          >
            <div>
              <div style={{ padding: 10 }}>
                <label style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  作成者
                  {saV.text(`created_by`, "")}
                </label>
                <label style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  評価日
                  {saV.date(`hyouka_date`)}
                </label>
              </div>
              <div>目標の達成</div>
              <div>理由(複数選択可)</div>
              {reasons.map((rs) => (
                <div>
                  {rs.map((r) => (
                    <label>
                      {saC.check(`check_reason_${r.value}`)}
                      {r.title}
                    </label>
                  ))}
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "baseline",
                }}
              >
                <label>
                  {saC.check(`check_reason_11`)}
                  その他
                </label>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  {saV.text(
                    `reason_others`,
                    "",
                    "text",
                    "block"
                  )}
                </div>
              </div>
            </div>
            <div>
              <label
                style={{
                  marginTop: 10,
                  display: "block",
                }}
              >
                具体的に
                {saV.text(
                  `concretely_text`,
                  "",
                  "text",
                  "block",
                  true,
                  "50px"
                )}
              </label>
            </div>
            <div>
              <label
                style={{
                  marginTop: 10,
                  display: "block",
                }}
              >
                残された課題
                {saV.text(`problem_text`, "", "text", "block", true, "50px")}
              </label>
            </div>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
