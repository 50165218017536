import { OrganizationTanto } from "..";

export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public address: string = "";
  public create_date: string = "";
  public fax: string = "";
  public hassho_month: string = "";
  public hassho_year: string = "";
  public jimusho: string = "";
  public kaigo_text: string = "";
  public kaijohouhou: string = "";
  public kanrisha: string = "";
  public kinyusha: string = "";
  public other_text: string = "";
  public phone_number: string = "";
  public pat_name: string = "";
  public saishu_month: string = "";
  public saishu_year: string = "";
  public shika_text: string = "";
  public shozaichi: string = "";
  public tel: string = "";
  public yakuzaimei: string = "";
  public birthday: string = "";
  public zip_code: string = "";
  public houkoku_saki: OrganizationTanto[] = [];
}

export class Checks {
  public check_1: boolean = false;
  public check_10: boolean = false;
  public check_11: boolean = false;
  public check_13: boolean = false;
  public check_14: boolean = false;
  public check_15: boolean = false;
  public check_16: boolean = false;
  public check_17: boolean = false;
  public check_18: boolean = false;
  public check_19: boolean = false;
  public check_2: boolean = false;
  public check_20: boolean = false;
  public check_21: boolean = false;
  public check_22: boolean = false;
  public check_23: boolean = false;
  public check_24: boolean = false;
  public check_25: boolean = false;
  public check_26: boolean = false;
  public check_27: boolean = false;
  public check_28: boolean = false;
  public check_29: boolean = false;
  public check_3: boolean = false;
  public check_30: boolean = false;
  public check_31: boolean = false;
  public check_32: boolean = false;
  public check_33: boolean = false;
  public check_34: boolean = false;
  public check_35: boolean = true;
  public check_36: boolean = false;
  public check_37: boolean = true;
  public check_38: boolean = false;
  public check_39: boolean = false;
  public check_4: boolean = false;
  public check_40: boolean = true;
  public check_41: boolean = false;
  public check_42: boolean = false;
  public check_43: boolean = false;
  public check_44: boolean = false;
  public check_45: boolean = false;
  public check_46: boolean = true;
  public check_47: boolean = false;
  public check_48: boolean = true;
  public check_49: boolean = false;
  public check_5: boolean = false;
  public check_50: boolean = true;
  public check_51: boolean = false;
  public check_52: boolean = true;
  public check_53: boolean = true;
  public check_54: boolean = false;
  public check_55: boolean = false;
  public check_56: boolean = false;
  public check_57: boolean = true;
  public check_58: boolean = false;
  public check_59: boolean = true;
  public check_6: boolean = false;
  public check_60: boolean = true;
  public check_61: boolean = false;
  public check_62: boolean = true;
  public check_63: boolean = false;
  public check_64: boolean = true;
  public check_65: boolean = false;
  public check_66: boolean = false;
  public check_67: boolean = true;
  public check_68: boolean = true;
  public check_69: boolean = false;
  public check_7: boolean = false;
  public check_70: boolean = false;
  public check_71: boolean = true;
  public check_72: boolean = true;
  public check_73: boolean = false;
  public check_74: boolean = true;
  public check_75: boolean = false;
  public check_8: boolean = false;
  public check_9: boolean = false;
  public female: boolean = false;
  public male: boolean = false;
}
