import { ApiUrl } from '../api';

let clientID = "824001570817-4atc7mj93emejt3v33da43lmntl5h7ql.apps.googleusercontent.com"
let redirect_uri = "http://localhost:4444/google-calendar-oauth/redirect"
const scope = "https://www.googleapis.com/auth/calendar.readonly"

export const googleGetPermissionUri = () => {
    return `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${clientID}&redirect_uri=${redirect_uri}&scope=${scope}&access_type=offline&approval_prompt=force`
}

export const googleOAuthGetTokenUri = () => {
    return ApiUrl("/api/v1/google_calendar/set_token")
}