import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import * as Colors from '../Colors'

// @inject()
// @observer
export default class BodyBBSBehaviour extends FormBehaviour.PdfBehaviour {
    displayName = "BodyBBSBehaviour"

    private choice = [
        {
            title: "立ち上がり（椅子座位からの立ち上がり）",
            shiji: "「手を用いずに立ってください」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:立ち上がり可能", value: 4 },
                { key: "3:手を用いれば一人で立ち上がり可能", value: 3 },
                { key: "2:数回試した後、手を用いて立ち上がり可能", value: 2 },
                { key: "1:立ったり、平衡をとるために最小限の介助が必要", value: 1 },
                { key: "0:立ち上がりに中等度ないし高度な介助が必要", value: 0 },
            ]
        },
        {
            title: "立位保持",
            shiji: "「つかまらずに２分間立って下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:安全に２分間立位保持可能", value: 4 },
                { key: "3:監視下で２分間立位保持可能", value: 3 },
                { key: "2:３０秒間立位保持可能", value: 2 },
                { key: "1:３０秒間立位保持に数回の試行が必要", value: 1 },
                { key: "0:介助なしには３０秒間立っていられない", value: 0 },
            ]
        },
        {
            title: "坐位保持（両足を床につけ、もたれずに坐る）",
            shiji: "「腕を組んで2 分問坐って下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:安全確実に２分間坐位をとることが可能 ", value: 4 },
                { key: "3:監視下で２分間坐位をとることが可能 ", value: 3 },
                { key: "2:３０秒間坐位をとることが可能 ", value: 2 },
                { key: "1:１０秒間坐位をとることが可能 ", value: 1 },
                { key: "0:介助なしでは１０秒間坐位をとることが不可能 ", value: 0 }
            ]
        }, {
            title: "坐り（立位から坐位へ）",
            shiji: "「どうぞお坐り下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:ほとんど手を使用せずに安全に坐ることが可能 ", value: 4 },
                { key: "3:両手でしゃがみ動作を制御する ", value: 3 },
                { key: "2:両下腿背側で椅子を押し、しゃがみ動作を制御する ", value: 2 },
                { key: "1:坐れるがしゃがみ動作の制御ができない ", value: 1 },
                { key: "0:介助しないとしゃがみ動作ができない ", value: 0 }
            ]
        }, {
            title: "トランスファー",
            shiji: "「車椅子からベッドに移り、また車椅子へ戻って下さい」「まず肘掛を使用して移って下さい。次に肘掛を使用しないで移って下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:ほとんど手を使用せず安全にトランスファ一が可能", value: 4 },
                { key: "3:手を十分に用いれぱ安全にトランスファーが可能", value: 3 },
                { key: "2:言葉での誘導もしくは監視でトランスファーが可能", value: 2 },
                { key: "1:トランスファーに介助者１名が必要", value: 1 },
                { key: "0:２名の介助者もしくは安全面での監視が必要 ", value: 0 },
            ]
        }, {
            title: "立位保持（閉眼での立位保持）",
            shiji: "「目を閉じて１０秒間立って下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:安全に１０秒間閉眼立位可能 ", value: 4 },
                { key: "3:監視のもとで１０秒間閉眼立位可能", value: 3 },
                { key: "2:３秒間は立位保持可能 ", value: 2 },
                { key: "1:閉眼で３秒間立位保持できないが、ぐらつかないで立っていられる", value: 1 },
                { key: "0:転倒しないよう介助が必要", value: 0 },
            ]
        }, {
            title: "立位保持（両足を一緒に揃えた立位保持）",
            shiji: "「足を揃え、何もつかまらず立って下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:一人で足を揃えられ、１分間安全に立位可能", value: 4 },
                { key: "3:一人で足を揃えられ、１分間監視立位 ", value: 3 },
                { key: "2:一人で足を揃えられるが、３０秒立位は不可能", value: 2 },
                { key: "1:閉脚立位に介助が必要であるが、足を揃えで１５秒立位可能 ", value: 1 },
                { key: "0:閉脚立位をとるために介助が必要－で、１５秒立位保持不可 ", value: 0 },
            ]
        }, {
            title: "両手前方（上肢を前方へ伸ぱす範囲）",
            shiji: "「両手を９０°上げて下さい。指を伸ばした状態でできるだけ前方に手を伸ぱして下さい」→測定者は、被験者が９０°に上肢を上げたときに指先の先端に定規を当てる。前方に伸ばしている間、定規に指先が触れないようにする。最も前方に傾いた位置で指先が届いた距離を記録する。",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:確実に２５cm以上前方ヘリーチ可能 ", value: 4 },
                { key: "3:１２.５cm以上安全に前方ヘリーチ可能", value: 3 },
                { key: "2:５cm以上安全に前方ヘリーチ可能 ", value: 2 },
                { key: "1:監視があれぱ前方ヘリーチ可能 ", value: 1 },
                { key: "0:転倒しないように介助が必要 ", value: 0 },
            ]
        }, {
            title: "拾い上げ（床から物を拾う）",
            shiji: "「足の前にある靴を拾い上げて下さい",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:安全かつ簡単に靴を拾い上げることが可能", value: 4 },
                { key: "3:監視があれば靴を拾い上げることが可能", value: 3 },
                { key: "2:独カで平衡を保ち2.5〜5 cmのところに置いたスリッパまでリーチできるが、拾い上げることはできない ", value: 2 },
                { key: "1:検査中監視が必要で、拾い上げることもできない", value: 1 },
                { key: "0:転倒しないように介助が必要で、検査ができない", value: 0 }
            ]
        }, {
            title: "振り返り（左右の肩越しに後ろを振り向く）",
            shiji: "「左肩越しに後ろを振り向いて下さい。それができたら今度は右肩越しに後ろを振り向いて下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:上手に体重移動しながら、両方向から振り向ける ", value: 4 },
                { key: "3:一方向からのみ振り向きができる。", value: 3 },
                { key: "2:横を向けるだけだが、バランスは保てる", value: 2 },
                { key: "1:振り向く動作中に監視が必要", value: 1 },
                { key: "0:転倒しないように介助が必要", value: 0 }
            ]
        }, {
            title: "360°方向転換（１回転）",
            shiji: "「円周上を完全に 1 周回って下さい。いったん止まり、その後反対方向に 1 周回って下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:４秒以内に両方向安全に 1 周回ることが可能 ", value: 4 },
                { key: "3:４秒以内に一方向のみ安全に 1 周回ることが可能 ", value: 3 },
                { key: "2:ゆっくりとなら 1 周回ることが可能", value: 2 },
                { key: "1:間近での監視が必要か、言葉での手がかりが必要 ", value: 1 },
                { key: "0:１周するのに介助が必要", value: 0 }
            ]
        }, {
            title: "踏み台昇降",
            shiji: "「足台の上に交互に足をのせて下さい。各足が4回ずつ足台にのるまで続けて下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:支持なしで安全かつ 20 秒以内に8 回足のせが可能 ", value: 4 },
                { key: "3:支持なしで20 秒以上かかるが、8 回足のせが可能 ", value: 3 },
                { key: "2:監視下で、完全に4 回足のせが可能", value: 2 },
                { key: "1:最小限の介助で、完全に2 回以上の足のせが可能 ", value: 1 },
                { key: "0:転倒しないよう介助が必要。または試行不可能", value: 0 }
            ]
        }, {
            title: "タンデム立位（片足を前に出した立位保持）",
            shiji: "（課題を実地で説明）「一方の足をもう片方の足のすぐ前にまっすぐ置いて下さい。もしできないと感じたならば、前になっている足の題を、後ろになっている足のつま先から十分に離れたところに置いてみて下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:単独で継ぎ足位がとれ、30秒保持可能", value: 4 },
                { key: "3:単独で足を別の足の前に置け、30 秒保持可能 ", value: 3 },
                { key: "2:単独で足をわずかにずらし、30 秒保持可能", value: 2 },
                { key: "1:検査姿勢に介助を要するが、15 秒保持可能", value: 1 },
                { key: "0:足を出すとき、または立っているときにバランスを崩してしまう", value: 0 }
            ]
        }, {
            title: "片足立位",
            shiji: "「どこにもつかまらず、できるだけ長く片足で立っていて下さい」",
            choice: [
                { key: "未選択", value: -1 },
                { key: "4:単独で片足を上げ、10 秒以上保持可能", value: 4 },
                { key: "3:単独で片足を上げ、5〜10 秒保持可能", value: 3 },
                { key: "2:単独で片足を上げ、3 秒もしくはそれ以上保持可能 ", value: 2 },
                { key: "1:片足を上げれるが、片足立ちを 3 秒保持できない ", value: 1 },
                { key: "0:試行不可能、もしくは転倒予防に介助が必要", value: 0 }
            ],
        }]

    public getId(): number {
        return 28
    }

    public createContent(): any {
        const res = new FormModel.BodyBBS.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.BodyBBS.Core
        content.values.sum_score = this.getResult(doc.content)
        for (let i = 0; i <= 13; ++i) {
            const t = this.choice[i].choice.find(x => x.value == content.indexes[`value_${i}`])
            content.values[`text${i}`] = (t && t.value != -1) ? t.key : ""
        }
        content.values.sum_score = this.getResult(content)
    }

    public beforeMount = () => {

    }


    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>・Short Form BBS（SFBBS）：Chou CY, Chien CW, Hsueh IP, et al：Developing a short form of the Berg Balance Scale for people with stroke. Phys Ther 86: 195-204，2006をもとに翻訳</div>
        </div>
    }

    private getResult(content: FormModel.BodyBBS.Core) {
        let sum =
            Math.max(0, content.indexes.value_0) +
            Math.max(0, content.indexes.value_1) +
            Math.max(0, content.indexes.value_2) +
            Math.max(0, content.indexes.value_3) +
            Math.max(0, content.indexes.value_4) +
            Math.max(0, content.indexes.value_5) +
            Math.max(0, content.indexes.value_6) +
            Math.max(0, content.indexes.value_7) +
            Math.max(0, content.indexes.value_8) +
            Math.max(0, content.indexes.value_9) +
            Math.max(0, content.indexes.value_10) +
            Math.max(0, content.indexes.value_11) +
            Math.max(0, content.indexes.value_12) +
            Math.max(0, content.indexes.value_13)
        return sum
    }


    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "indexes"])
        const elements_scores =
            this.choice.map((ch, chi) => {
                return {
                    name: ch.title,
                    content: <div>
                        {saV.listRaw("value_" + chi, ch.choice)}
                        <div style={{ margin: "5px", fontSize: "0.9rem", color: Colors.primaryColor }}>
                            指示：{ch.shiji}
                        </div>
                    </div>

                }
            })
        const elements = [
            {
                name: '評価', content: elements_scores.concat([
                    {
                        name: '合計点', content: <div>{this.getResult(doc.content)}</div>
                    },
                    {
                        name: 'カットオフ値', content: <div>
                            < div >
                                <div>・46点以上 病棟内白立判定基準</div>
                                <div>・36点以上 病棟内見守り判定基準</div>
                            </div >
                        </div >
                    }
                ])
            }
        ]

        return elements
    }
}
