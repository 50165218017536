import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import FooterNavigation from '../components/FooterNavigation'
import { PageProps, withPageProps } from '../components/Page';
import Loading from '../components/Loading'
import Button from '@material-ui/core/Button';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import * as Colors from '../components/Colors'
import StandardTable from '../components/StandardTable'
import Col2Table from '../components/Col2Table'
import Col2Table2 from '../components/Col2Table2'
import Col2TableRow from '../components/Col2TableRow'
import EditableText from '../components/EditableText'
import Grid from '@material-ui/core/Grid';
// import ColoredPaper from '../components/ColoredPaper'
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import {
    ExpansionPanel, ExpansionPanelSummary, Paper, ExpansionPanelDetails,
    Badge, Dialog, AppBar, Toolbar, IconButton, Zoom, Fab,
    FormControlLabel, ListItem, Checkbox, List, Menu, MenuItem, Avatar, DialogTitle, Tooltip, Tabs, Tab, TextField, Table, TableHead,
    TableBody, TableCell, TableRow, Select
} from '@material-ui/core'
import { MentionsInput, Mention } from 'react-mentions'
import SubTitle from '../components/SubTitle';
import { UADetector } from '../util/UAUtil'

import { ImageUtil } from '../util/ImageUtil'
import KangoKirokuPage from './KangoKirokuPage'
import Calendar from 'react-calendar';
import EditableImage from '../components/EditableImage';
import SimpleDialog from '../components/SimpleDialog';
import InputText from '../components/InputText';
import { DateUtil } from '../util/DateUtil'
import ShinkiIraiCheck from '../components/ShinkiIraiCheck'
import { StoreAccessor } from '../util/StoreUtil'
import EditableList from '../components/EditableList';
import EditableCheckList from '../components/EditableCheckList';
import DocumentList2 from '../components/DocumentList2';
import * as FormModel from '../models/Forms'
import * as KirokuIIView from '../components/KirokuIIViews'
import * as KirokuIIView2 from '../components/KirokuIIViews2'
import * as KirokuIIView3 from '../components/KirokuIIViews3'
import OmahaProblem from '../components/OmahaProblem'
import OmahaSelectDialog from '../components/OmahaSelectDialog';
import Pagination from "material-ui-flat-pagination";
import ShuttenAll from '../components/ShuttenAll'
import * as Graph from '../components/graph';
import DocumentDialog from '../components/DocumentDialog';
import TermConfirmDialog from '../components/TermConfirmDialog';
import * as KirokuIIEditViews2 from '../components/KirokuIIEditViews2'

import Message from '../components/message/Message'
import MessageUserList from '../components/message/MessageUserList'
import TaskList from '../components/message/TaskList'

import Firebase from '../firebase'
import { REFRESH_TOKEN_KEY } from '../config/googleCalendar/axios';

import Resizer from 'react-image-file-resizer';
import AvatarStore from '../firebase/AvatarStore';
import { DocumentChat } from '../models/Document';
import { Document, Page } from 'react-pdf';
const moment = require("moment")

const createObjectURL = (window.URL || (window as any).webkitURL).createObjectURL || (window as any).createObjectURL;


const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    childRoot: {
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: "100px",
        whiteSpace: "pre-line"
    },
    item: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:hover': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        }
    },
    itemText: {
        paddingRight: "0px",
        "& span": {
            fontSize: "large",
            fontWeight: 700
        },
        "& p": {
        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    grid: {
        padding: "10px"
    },
    patientIcon: {
        fontSize: "80px"
    },
    swipableView: {
        paddingBottom: "50px"
    },
    innerTable: {
        padding: '5px'
    },
    calendarTable: {
        marginTop: "10px",
        margin: "auto",
        maxWidth: "400px"
    },
    calendarRow: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none"
        }
    },
    tabButtonGroup: {
        position: "fixed",
        right: "0px",
        bottom: "60px"
    },
    tabButton: {
        margin: "5px",
        backgroundColor: "#fa8724",
        boxShadow: "#00000087 3px 3px 8px",
        animation: "idle-tab-anim 1000ms infinite 0ms alternate"
    },
    tabIcon: {
        margin: "5px",
        animation: "rotate-anim 200ms infinite 0ms alternate"
    },
    calendar: {
        marginTop: "20px",
        maxWidth: "400px",
        width: "98%",
        margin: "auto"
    },
    calendarLabel: {
        margin: 0,
        borderRadius: "5px",
        backgroundColor: Colors.primaryColor,
        color: "white"
    },
    activity_patient: {
        padding: "0px",
        border: "none",
        marginTop: "-15px",
        borderWidth: "1px",
        borderColor: Colors.secondaryColor,
        backgroundColor: Colors.white,
        width: "100%",
        maxWidth: "1190px",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative"
    },
    activity_face: {
        width: "50px",
        height: "50px",
        borderRadius: "100px",
        objectFit: "contain",
        margin: "5px",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        display: "inline-flex",
        fontSize: "1rem"
    },
    activity_names: {
        display: "inline-block",
        verticalAlign: "top",
        margin: "5px",
        marginRight: "20px"
    },
    activity_name: {
        fontSize: "20px"
    },
    activity_tanto: {
        display: "inline-block",
    },
    filter_button: {
    },
    filter_button_d: {
        color: "gray"
    },
    filter_add: {
        width: "35px",
        height: "10px",
        margin: "5px",
        marginLeft: "10px"
    },
    activity_task_table: {
        textAlign: "center",
    },
    activity_task_item: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        padding: "0px",
        paddingRight: "5px",
        fontWeight: 700,
        '&:hover': {
            backgroundColor: Colors.backColor
        }
    },
    activity_task_list: {
        padding: "0px"
    },
    activity_task_item_text: {
        paddingRight: "0px",
        width: "80%",
        fontSize: '1.1em'
    },
    activity: {
        margin: "5px",
        border: "solid",
        borderWidth: "1px",
        padding: "5px",
    },
    activity_icon: {
        width: "40px",
        height: "40px",
        display: "inline-flex"
    },
    activity_summary: {
        display: "inline-block",
        verticalAlign: "top",
        marginLeft: "10px",
        marginTop: "5px",
        width: "90%"
    },
    activity_add_menu: {
        border: "1px solid gray",
        borderRadius: "10px"
    },
    input: {
        color: Colors.inputColor,
        padding: "5px",
        "& input": {
            padding: "5px"
        }
    },
    activity_table_head: {
        border: "1px solid lightgray",
        color: Colors.primaryColor,
        backgroundColor: Colors.backColor,
    },
    activity_table_cell: {
        border: "1px solid lightgray",
        color: Colors.primaryColor
    },
    activity_new: {
        color: "#f50057",
        fontSize: "0.9rem"
    },
    tabWrapper: {
        width: "100%",
        zIndex: 999
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        marginLeft: "5px"
        // background: Colors.primaryColor,
    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
        backgroundColor: Colors.forthColor
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700,
    },
    tabTextSub: {

    },
    activity_detail: {},
    bold: { fontWeight: "bold" },
    chat_message: {
        whiteSpace: "pre-line"
    },
    chat_image: {
        display: "inline-block",
        margin: "5px"
    },
    filter_button_primary: {
        color: "white"
    },
    emerge: {
        color: "white",
        border: "1px solid",
        borderRadius: "10px",
        backgroundColor: "#b94575",
        padding: "5px 10px",
        marginBottom: "10px",
        fontWeight: "bold"
    }
})

interface Params {
    patient_id: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    index: number
    loading: boolean
    patient?: Model.Patient.Patient
    categoryRoot: Model.BlockCategory[]
    checkedCategory: object
    openTab: boolean
    tabList: any[]
    clickedDate: Date | Date[]
    assessments: Model.Assessment.AssessmentListResult[]
    isEditing: boolean
    taskList: Model.Task[],
    taskTarget?: Model.Task,
    showDoneTask: boolean,
    openAddMenu: boolean,
    currentKirokuId: number,
    calendarKiroku: Model.KirokuII.KangoKirokuII[],
    statusEndDialog: boolean,
    statusEndCheck: object,
    openTantoDialog: boolean,
    openUserDialog: boolean,
    openHokenDialog: boolean,
    me: Model.User,
    openDelete: boolean,
    posting: boolean,
    targetProblem: Model.Problem,
    openOmahaLogDialog: boolean,
    openKeikakushoDialog: boolean,
    loadingActivity: boolean,
    errmsg_kiroku1: string,
    patientScore: any,
    favoList: any[],
    showNotifyDocChat: boolean,
    showNotifyComment: boolean,
    commentList: Model.Comment.Comment[],
    kirokuList: HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>,
    docChatList: HokanApi.PagingResponse<Model.Document.DocumentChat>,
    simpleUsers: Model.SimpleUser[],
    //    iryoKigus: Model.Patient.IryoKigu[],
    kiguTmp: string,
    docChatQuery: any,
    activityKirokuHideFlag: any,
    cur_log: number | null,
    cur_patient: Model.Patient.Patient | null,

    designrDocs: Model.Document.Document[],
    designrBuiList: string[]
}

class PatientPage extends React.Component<Props, LocalState> {
    displayName = "PatienPage"

    constructor(props: Props) {
        super(props)
        let index = 0
        if (props.location.hash == "#basic") {
            index = 1
        }
        else if (props.location.hash == "#activity") {
            index = 0
        }
        else if (props.location.hash == "#score") {
            index = 2
        }
        else if (props.location.hash == "#problem") {
            index = 3
        }
        else if (props.location.hash == "#document") {
            index = 4
        }
        this.state = {
            loading: false,
            index: index,
            patient: undefined,
            categoryRoot: [],
            checkedCategory: {},
            openTab: false,
            tabList: [],
            clickedDate: new Date(),
            assessments: [],
            isEditing: false,
            taskList: [],
            taskTarget: undefined,
            showDoneTask: false,
            openAddMenu: false,
            currentKirokuId: 0,
            commentList: [],
            kirokuList: new HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>(),
            calendarKiroku: [],
            statusEndDialog: false,
            statusEndCheck: {},
            openTantoDialog: false,
            openUserDialog: false,
            openHokenDialog: false,
            me: new Model.User(),
            openDelete: false,
            posting: false,
            targetProblem: new Model.Problem(),
            openOmahaLogDialog: false,
            openKeikakushoDialog: false,
            loadingActivity: false,
            errmsg_kiroku1: "",
            patientScore: {},
            favoList: [],
            showNotifyDocChat: false,
            showNotifyComment: false,
            docChatList: new HokanApi.PagingResponse<Model.Document.DocumentChat>(),
            simpleUsers: [],
            //            iryoKigus: [],
            kiguTmp: "",
            docChatQuery: null,
            activityKirokuHideFlag: {},
            cur_log: null,
            cur_patient: null,
            designrDocs: [],
            designrBuiList: []
        }
    }

    public loadScore = () => {
        if (this.state.patient && this.state.patient.id) {
            HokanApi.getScorePatient(this.state.patient.id).then(res => {
                res.data.vital = res.data.vital.map(v => {
                    const t = JSON.parse(v.vital)
                    return {
                        time_start: v.time_start,
                        ...t
                    }
                })
                this.setState({ patientScore: res.data })
            }).catch(err => {
                console.log(err)
            })

            HokanApi.getDesignRBuiList(this.state.patient.id).then(res => {
                const buis = res.data.filter(x => x.bui != "").map(x => x.bui as string)
                this.setState({ designrBuiList: buis })
                if (buis.length > 0) {
                    this.loadDesignR(buis[0]);
                }
            })
        }
    }

    private loadDesignR(bui) {
        if (!this.state.patient) return
        HokanApi.getDocuments(0, 5, {
            patientId: this.state.patient.id,
            typeId: 61,
            bui: bui
        }).then(res => {
            res.data.documents.forEach(x => {
                Model.Document.Document.Init(x)
            })
            this.setState({ designrDocs: res.data.documents })
        })
            .catch(e => {
                console.log(e);
            })

    }

    create_comment_dom(message: string, uidStr: string) {
        let found = false;
        const r = message.replace(/@\[(.*?)\]\(user:([\-0-9]*?)\)/g, (str, ...args: string[]) => {
            if (uidStr == args[1] || args[1] == "-1" || args[1] == "-2") found = true
            return `@${args[0]} `
        }).replace(/\\n/g, "\n")
        if (found) {
            return <div style={{ backgroundColor: "#ffdcdc" }}>
                {r}
            </div>
        }
        return <div>
            {r}
        </div>
    }

    public loadComments() {
        if (this.state.patient && this.state.patient.id) {
            const pas = JSON.parse(localStorage.getItem("pat_activity_comment_shown") || "{}")
            const d = pas[this.state.patient.id]
            const uidStr = this.state.me.id.toString()
            HokanApi.getComments(this.state.patient.id).then(x => {
                let sna = (!d) && x.data.length != 0
                x.data.forEach(y => {
                    const s = moment(y.updated_at).toDate().toISOString()
                    y.isNew = (s > d)
                    y.message_dom = this.create_comment_dom(y.message, uidStr)
                    if (y.isNew) sna = true
                    y.children.forEach(z => {
                        const s = moment(z.created_at).toDate().toISOString()
                        z.message_dom = this.create_comment_dom(z.message, uidStr)
                        z.isNew = (s > d)
                        if (z.isNew) sna = true
                    })
                })
                this.setState({
                    commentList: x.data,
                    showNotifyComment: sna
                })
            }).catch(e => {
                console.log(e)
            })
        }
    }

    public getPatientId() {
        return parseInt(this.props.match.params.patient_id)
    }
    public componentDidMount = () => {
        this.setState({ loading: true })
        const patient_id = parseInt(this.props.match.params.patient_id)
        HokanApi.me().then(x => {
            const me = x.data
            this.setState({ me: me })
            if (me.branch) {
                Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).get().then((doc) => {
                    const d = doc.data()
                    this.setState({ favoList: (d ? d.favo : []) })
                    this.forceUpdate()
                }).catch(err => {
                    console.log(err)
                })
                Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).onSnapshot((doc) => {
                    const d = doc.data()
                    this.setState({ favoList: (d ? d.favo : []) })
                })
            }
        })
        HokanApi.getUsersSimple().then(x => {
            this.setState({ simpleUsers: x.data })
        }).catch(err => {
            console.log(err)
        })
        //HokanApi.getIryoKigus().then(x => {
        //    this.setState({ iryoKigus: x.data })
        //}).catch(err => {
        //   console.log(err)
        //})
        HokanApi.getPatient(patient_id)
            .then(x => {
                const p = Model.Patient.Patient.load(x.data)
                this.setState({ patient: p })
                this.loadComments();
                this.loadTasks();
                this.loadKirokus(0, 10);
                this.loadKirokusForCalendar(DateUtil.currentMonthFirstDateStr(), DateUtil.currentMonthLastDateStr())
                this.loadScore()
                this.loadDocChat(0, 5);
                this.setState({ loading: false })

                HokanApi.me().then(x2 => {
                    let me = x2.data
                    if (!me || !me.branch || !me.id || !this.state.patient) return

                    const pid = this.state.patient.id
                    const favo = this.state.favoList.findIndex(x => x.id == pid) >= 0
                    // メッセージとAPIサーバーのズレ解消
                    if (favo) {
                        HokanApi.message.favorite_wc(p.id).then(x => {})
                    }
                    else {
                        HokanApi.message.delete_favorite_wc(p.id).then(x => {})

                        // 担当者なら自動でお気に入り(firestoreは重複を排除しないのでお気に入りじゃない場合のみ)
                        if (this.state.patient.main_user_id == me.id) {
                            /* stateが空でここにくる場合があって、そうするとお気に入りが消えるのでなし
                            Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).set({
                                favo: (this.state.favoList || []).concat([{ id: p.id, name: p.name }])
                            }).catch(err => {
                                console.log(err)
                            })
                            */
                            // メッセージではお気に入りに担当も含める
                            HokanApi.message.favorite_wc(p.id).then(x => {})
                        }
                    }
                })
            }).then(r => {
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
        HokanApi.getBlockCategory()
            .then(x => {
                const cat = {}
                x.data.category.forEach(c => cat[c.id] = true)
                this.setState({
                    categoryRoot: Model.buildBlock(x.data),
                    checkedCategory: cat,
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
        this.loadAssessments();
        /* for dev */
        //this.onCreateTab()
        const params = new URLSearchParams(this.props.location.search);
        const kid = params.get("kid")
        if (kid) {
            this.onCreateTab(parseInt(kid))
        }
    }


    private loadAssessments() {
        const patient_id = parseInt(this.props.match.params.patient_id)
        HokanApi.getPatientAssessments(patient_id)
            .then(x => {
                x.data.forEach(y => {
                    y.newest.intervention = Model.Intervention.sort(y.newest.intervention)
                })
                this.setState({
                    assessments: x.data
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    public loadTasks() {
        const patient_id = parseInt(this.props.match.params.patient_id)
        HokanApi.getTasks(patient_id)
            .then(x => {
                this.setState({
                    taskList: x.data
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    taskList: []
                })
            })
    }

    private onFooterChange = (e: any, value: number) => {
        this.setState({
            index: value,
            openTab: false,
        });
    };

    /*
    private onSwipeChange = (index: number) => {
        this.setState({
            index: index,
            openTab: false,
        })
    }*/

    private setFavo(f: boolean) {
        const me = this.state.me
        if (!me || !me.branch || !me.id || !this.state.patient) return
        const patName = this.state.patient.name
        const patId = this.getPatientId()
        if (f) {
            Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).set({
                favo: (this.state.favoList || []).concat([{ id: patId, name: patName }])
            }).catch(err => {
                console.log(err)
            })

            HokanApi.message.favorite_wc(patId).then(x => {
            })
        }
        else {
            Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).set({
                favo: (this.state.favoList || []).filter(x => x.id != patId)
            }).catch(err => {
                console.log(err)
            })

            HokanApi.message.delete_favorite_wc(patId).then(x => {
            })
        }
    }

    private loadLog(logId: any | null) {
        if (!this.state.patient) return;
        const k = (this.state.cur_patient ? this.state.cur_patient : this.state.patient).version_list.find(x => x.id == logId)
        this.setState({
            loading: true
        })
        if (!logId || !k) {
            setTimeout(() =>
                this.setState({
                    cur_log: null,
                    patient: this.state.cur_patient ? this.state.cur_patient : this.state.patient,
                    cur_patient: null,
                    loading: false
                }), 100);
            return;
        }

        HokanApi.getPatientLog(this.getPatientId(), logId)
            .then(x => {
                this.setState({
                    cur_log: logId,
                    patient: Model.Patient.Patient.load(x.data),
                    cur_patient: this.state.cur_patient ? this.state.cur_patient : this.state.patient as any,
                    loading: false
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    public render() {
        const { index, loading, patient } = this.state
        const { classes } = this.props
        if (loading || !patient) {
            return <Loading />
        }
        const patId = this.getPatientId()
        const favo = this.state.favoList.findIndex(x => x.id == patId) >= 0

        const sa = new StoreAccessor(this, this.state.isEditing, "patient")
        return (
            <div>
                <div>
                    <div style={{
                        backgroundColor: Colors.white,
                        padding: "10px",
                        marginBottom: "0px",
                        marginRight: "auto",
                        marginLeft: "auto",
                        top: 0,
                        zIndex: 999,
                        width: "100%"
                    }}>

                        <div className={classes.activity_patient}>
                            <div style={{ display: "inline-block" }}>
                                <div style={{ display: "inline-block" }}>
                                    <Tooltip title="変更" aria-label="change">
                                        <Avatar
                                            onClick={(e) => {
                                                const el = document.getElementById("etid_avatar") as any
                                                if (el) el.click()
                                            }}
                                            alt="avatar" src={patient.avatar_url} className={classes.activity_face}
                                        />
                                    </Tooltip>
                                </div>
                                <div className={classes.activity_names}>
                                    <span style={{ fontSize: "1.5rem" }}>{patient.name}</span>
                                    <Tooltip title="お気に入り" aria-label="favo">
                                        <div
                                            onClick={() => { this.setFavo(!favo) }}
                                            style={{
                                                cursor: "pointer",
                                                display: "inline",
                                                marginLeft: "5px",
                                                color: favo ? Colors.primaryColor : "#BBB"
                                            }}>
                                            <Icons.Star />
                                        </div>
                                    </Tooltip>
                                    <div>{
                                        moment(patient.birthday).isValid() ?
                                            new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
                                                era: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            }).format(moment(patient.birthday).toDate())
                                            : ""
                                    }
                                        ({DateUtil.getAge(patient.birthday)}歳
                                        {DateUtil.getAge(patient.birthday) == 0 ? ` ${DateUtil.getAgeMonth(patient.birthday)}ヶ月` : ""})
                                    </div>
                                </div>
                                <div style={{ margin: "0px", marginLeft: "65px", display: "inline-block" }}>
                                    <div className={classes.activity_tanto}>
                                        <div style={{ display: "inline-block", verticalAlign: "top" }}>担当：</div>
                                        <div style={{ display: "inline-block" }}>
                                            {patient.main_user ? patient.main_user.name : "-"}
                                            {patient.sub_user1 ? <span><br />{patient.sub_user1.name}</span> : null}
                                            {patient.sub_user2 ? <span><br />{patient.sub_user2.name}</span> : null}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: "65px", marginTop: "0px" }}>
                                    {
                                        patient.basic.other_system_url && patient.basic.other_system_url != "" ?
                                            <div>
                                                <a href={patient.basic.other_system_url} target="_blank">他システムリンク</a>
                                            </div>
                                            : null
                                    }
                                    <div style={{ display: "inline-block", verticalAlign: "top" }}>特記事項：</div>
                                    <div style={{ display: "inline-block" }}>
                                        {

                                            this.state.isEditing || (this.state.patient && this.state.patient.basic.memo && this.state.patient.basic.memo != "") ?
                                                sa.textC(["basic"], "memo")
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Button variant="contained" color="primary"
                                    style={{
                                        position: "absolute",
                                        right: "5px",
                                        top: "5px",
                                        borderRadius: "120px",
                                        width: "75px",
                                        height: "75px"
                                    }}
                                    disabled={this.state.openTab}
                                    onClick={() => this.onCreateTab()}>
                                    記録Ⅱ<br />作成
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            {index == 0 ? this.activity(index == 0) : null}
                            {index == 1 ? this.patientPage2(index == 1) : null}
                            {index == 2 ? this.score(index == 2) : null}
                            {index == 3 ? this.problem(index == 3) : null}
                            {index == 4 ? this.calendar(index == 4) : null}
                        </div>
                        {this.kangoKirokuManage()}
                        <FooterNavigation index={index}
                            handleChange={this.onFooterChange}
                            showNotifyActivity={this.state.showNotifyDocChat || this.state.showNotifyComment} />
                    </div>
                </div >
            </div >
        );
    }

    public document = (isActive: boolean) => {
        const { patient } = this.state
        return isActive ? <div style={{
            textAlign: "center",
            fontWeight: "bold",
            margin: "30px"
        }}>
            <div>「アクティビティ」の「書類」タブ内に移行しました</div>
        </div>
            : null
    }

    public page = () => {
        return <div>
            TODO
        </div>
    }

    public onCreateTab(kiroku_id: number = 0) {
        const tabs = this.state.tabList
        //tabs.push(tabs.length + 1)
        this.setState({
            currentKirokuId: kiroku_id,
            openTab: true,
            tabList: tabs
        })
    }
    public onRemoveTab() {
        this.setState({
            tabList: [],
            openTab: false
        })
        this.loadKirokus();
        this.loadScore();
        HokanApi.getPatient(this.getPatientId())
            .then(x => {
                const p = Model.Patient.Patient.load(x.data)
                this.setState({ patient: p })
            })
    }
    public onStoreTab() {
        this.setState({ openTab: false })
        this.loadKirokus();
        this.loadScore();
        HokanApi.getPatient(this.getPatientId())
            .then(x => {
                const p = Model.Patient.Patient.load(x.data)
                this.setState({ patient: p })
            })
    }
    public onOpenTab() {
        this.setState({ openTab: true })
    }

    public score(isActive: boolean) {
        if (!isActive) return <div />
        const { classes } = this.props

        const vitalScore: any[] = []
        const taijuScore: any[] = []

        const calcTaijuShow = (taiju: number | null, unit: string) => {
            if (!taiju) return ""
            return taiju * (unit == "g" ? 1000 : 1) + unit
        }

        if (this.state.patientScore.vital) {
            this.state.patientScore.vital.forEach(vital => {
                const f = vital.first;
                const v = vital.saiken ? vital.saiken : vital.first;
                const d = {}
                d["日付"] = moment(vital.time_start).format("YY-MM-DD")
                d["SpO2(%)"] = v.spo2 ? v.spo2 : (f.spo2 ? f.spo2 : null)
                d["体温(℃)"] = v.taion ? v.taion : (f.taion ? f.taion : null)
                d["脈拍(回/分)"] = v.myakuhaku ? v.myakuhaku : (f.myakuhaku ? f.myakuhaku : null)
                d["呼吸回数"] = v.kokyu_kaisu ? v.kokyu_kaisu : (f.kokyu_kaisu ? f.kokyu_kaisu : null)
                d["血圧(収縮)"] = v.ketsuatsu_max ? v.ketsuatsu_max : (f.ketsuatsu_max ? f.ketsuatsu_max : null)
                d["血圧(拡張)"] = v.ketsuatsu_min ? v.ketsuatsu_min : (f.ketsuatsu_min ? f.ketsuatsu_min : null)
                vitalScore.push(d)

                const t = {}
                t["体重"] = calcTaijuShow(vital.taiju, vital.taiju_unit)
                t["血糖値"] = v.kettou_value ? v.kettou_value : (f.kettou_value ? v.kettou_value : null)

                taijuScore.push(t)
            })
        }

        const kbsScore: { [index: string]: { date: string, K: number, B: number, S: number }[] } = {}
        if (this.state.patientScore.kbs) {
            this.state.patientScore.kbs.forEach(kbs => {
                const d = {
                    date: kbs.ass_day, K: kbs.score_k, B: kbs.score_b, S: kbs.score_s,
                    KS: [kbs.k_st1, kbs.k_st2, kbs.k_st3, kbs.k_st4, kbs.k_st5],
                    BS: [kbs.b_st1, kbs.b_st2, kbs.b_st3, kbs.b_st4, kbs.b_st5],
                    SS: [kbs.s_st1, kbs.s_st2, kbs.s_st3, kbs.s_st4, kbs.s_st5]
                }
                if (kbsScore[kbs.name]) {
                    if (kbsScore[kbs.name].findIndex(x => x.date == kbs.ass_day) < 0) {
                        kbsScore[kbs.name].push(d)
                    }
                }
                else {
                    kbsScore[kbs.name] = [d]
                }
            })
        }

        return <div className={classes.childRoot}>
            <Grid container={true} spacing={2}>
                {
                    this.state.assessments.map((asset, idx) => {
                        return <Grid item={true} xs={12} md={6} lg={6} className={classes.graph_paper} style={{ marginBottom: "10px" }}>
                            <Graph.LineGraphKbs
                                data={kbsScore[asset.problem.name]}
                                title={`${asset.problem.name}`}
                                smallMode={false}
                            />
                        </Grid>
                    })
                }
                <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper} style={{ marginBottom: "10px" }}>
                    <Graph.TaionBan
                        data={vitalScore}
                        onlyGridData={taijuScore}
                        onlyGridCount={["血糖値", "体重"]}
                        title="体温板"
                        name="日付"
                        count={[
                            "体温(℃)",
                            "SpO2(%)",
                            "脈拍(回/分)", "呼吸回数", "血圧(収縮)",
                            "血圧(拡張)"]}
                        color={null}
                    />
                </Grid>
            </Grid>
            <div>
                <SubTitle title="DESIGN-R" />
                {
                    this.state.designrBuiList.length > 0 ?
                        <div key="dsr">
                            <Select
                                onChange={(e) => {
                                    const s = e.target.value as string
                                    this.loadDesignR(s)
                                }}
                            >
                                {
                                    this.state.designrBuiList.map((x, i) => {
                                        return <MenuItem key={x} value={x}>{x}</MenuItem>
                                    })
                                }
                            </Select>
                            <Graph.DesignRTable docs={this.state.designrDocs} />
                        </div>
                        :
                        <div key="dsr-none">
                            データなし
                        </div>
                }
            </div>
        </div>
    }

    public kangoKirokuManage() {
        //const Transition = React.forwardRef(function Transition(props, ref) {
        //    return <Zoom ref={ref} {...props} />;
        //});
        const { classes } = this.props
        const { patient } = this.state
        return <div>
            <div className={classes.tabButtonGroup}>
                {
                    this.state.openTab == false ?
                        this.state.tabList.map((t, i) => {
                            return <div>
                                <Fab className={classes.tabButton}
                                    color="primary" aria-label="Edit" onClick={() => this.onOpenTab()}>
                                    <Icons.Edit className={classes.tabIcon} />
                                </Fab>
                            </div>
                        })
                        : null
                }
            </div>
            <Dialog
                TransitionComponent={Zoom}
                open={this.state.openTab}
                fullScreen={true}
                onClose={() => this.onStoreTab()} >
                <AppBar position="fixed">
                    <Toolbar>
                        {
                            false ?
                                <IconButton edge="start" color="inherit" onClick={() => this.onStoreTab()}>
                                    <Icons.KeyboardArrowDown />
                                </IconButton>
                                : null
                        }
                        {this.state.currentKirokuId > 0 ? "記録書II編集" : "記録書II作成"}
                        {this.state.patient ? `（${this.state.patient.name}）` : ""}
                        <IconButton edge="end" color="inherit" onClick={() => this.onRemoveTab()}>
                            <Icons.Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div style={{
                    padding: "0px", maxWidth: "100%",
                    //overscrollBehavior: "none",
                    //WebkitOverflowScrolling: "touch",
                    overflowX: "hidden"
                }}>
                    <KangoKirokuPage
                        onRemoveTab={() => this.onRemoveTab()}
                        patient_id={patient!.id}
                        kiroku_id={this.state.currentKirokuId}
                        isActive={this.state.openTab} />
                </div>
            </Dialog>
        </div>
    }

    public problem = (isActive: boolean) => {
        const { patient } = this.state
        const { history, classes } = this.props
        if (!isActive || !patient) return <div />

        const cdStr = DateUtil.currentDateStr()
        return <div className={classes.childRoot} style={{ textAlign: "left" }}>
            <SubTitle title="問題一覧" />
            <div style={{ margin: "5px" }}>
                <Button variant="contained" color="primary" onClick={(e) => { history.push(`/patients/${patient!.id}/create_problems`) }}>
                    新規問題作成
                </Button>
                {"　"}
                <Button variant="contained" style={{ margin: "5px" }} color="default"
                    onClick={(e) => {
                        this.setState({
                            openKeikakushoDialog: true
                        })
                    }}>
                    計画書作成
                </Button>
            </div>
            <br />
            <Paper style={{ margin: "5px" }}>
                {
                    this.state.assessments.map((asset, idx) => {
                        const ns = asset.newest;
                        const ass = ns.assessment;
                        let nextStr = ""
                        let hasExpired = false;

                        const isFirstAssessment = (
                            ass.id == asset.oldest.assessment.id &&
                            (!ass.mokuhyou || ass.mokuhyou == "")
                        )
                        if (ass.closed) nextStr = "終了済"
                        else if (ass.next_assessment_day == Model.Assessment.NullAssessmentNextDay) nextStr = "未設定"
                        else {
                            nextStr = ass.next_assessment_day
                            if (nextStr < cdStr) {
                                hasExpired = true;
                            }
                        }

                        return <ExpansionPanel style={{
                            border: "solid", borderWidth: "1px", borderRadius: "0px",
                            borderColor: hasExpired ? Colors.orangeColor : undefined
                        }} key={"pr_" + idx}>
                            <ExpansionPanelSummary expandIcon={<Icons.ExpandMore />}
                                style={{
                                    fontSize: "0.9rem", padding: "0 10px", verticalAlign: "middle",
                                    backgroundColor: hasExpired ? Colors.orangeColorTransparent : undefined
                                }}
                            >
                                <div
                                    style={{
                                        display: "inline-block", marginTop: "auto", marginBottom: "auto",
                                        color: hasExpired ? Colors.dangerColor : undefined
                                    }}
                                >
                                    {`${asset.problem.problem_no}:${asset.problem.name}${asset.newest.assessment.priority == 1 ? "" : "（優先度低）"}`}
                                </div>
                                <div style={{ marginLeft: "auto", fontSize: "smaller", color: hasExpired ? Colors.dangerColor : undefined }}>次回評価<br />{nextStr}</div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ padding: "5px", overflowWrap: "anywhere" }}>
                                <Grid container={true} spacing={0}>
                                    <Grid item={true} xs={12} md={12} lg={12}>
                                        <OmahaProblem problem={asset.problem} assessmentSet={ns} />
                                    </Grid>
                                    <div>
                                        <Button variant="contained" style={{ margin: "5px" }} color="primary"
                                            onClick={(e) => {
                                                if (isFirstAssessment) {
                                                    history.push(`/patients/${patient.id}/create_problem/${asset.problem.id}/${ass.id}#symptom`)
                                                }
                                                else {
                                                    history.push(`/patients/${patient!.id}/create_problem/${asset.problem.id}`)
                                                }
                                            }}>
                                            評価
                                        </Button>
                                        <Button variant="outlined" style={{ margin: "5px" }} color="default"
                                            onClick={(e) => {
                                                this.setState({
                                                    targetProblem: asset.problem,
                                                    openOmahaLogDialog: true
                                                })
                                            }}>
                                            編集
                                        </Button>
                                    </div>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                    })
                }
            </Paper>
            {
                this.state.openOmahaLogDialog ?
                    <OmahaSelectDialog patient_id={patient.id} problem={this.state.targetProblem}
                        onCancel={() => {
                            this.setState({ openOmahaLogDialog: false })
                            this.loadAssessments()
                        }}
                        onSelect={(ass) => {
                            this.setState({ openOmahaLogDialog: false })
                            history.push(`/patients/${patient.id}/create_problem/${this.state.targetProblem.id}/${ass.assessment.id}#symptom`)
                        }}
                    />
                    : null
            }
            {
                this.state.openKeikakushoDialog && this.state.patient ?
                    <DocumentDialog key={`dialog_keikakusho`}
                        open={true}
                        patientId={this.state.patient.id}
                        only={7}
                        srcId={null}
                        onCansel={(deleted) => {
                            this.setState({ openKeikakushoDialog: false })
                        }}
                        onSelect={(doc: Model.Document.Document) => {
                            this.setState({ openKeikakushoDialog: false })
                        }}
                    />
                    : null
            }
        </div>
    }

    public onChangeCategory(id: number) {
        const c = this.state.checkedCategory
        c[id] = !c[id]
        this.setState({
            checkedCategory: c
        })
    }

    private taskDialogDelete() {
        const { patient, taskTarget } = this.state
        if (!taskTarget || !patient) return;
        HokanApi.deleteTask(patient.id, taskTarget.id).then(x => {
            this.loadTasks();
        });
    }

    private taskDialogClose() {
        this.setState({ taskTarget: undefined })
    }

    private taskDialogSave() {
        const { patient, taskTarget } = this.state
        if (!taskTarget || !patient) return;
        if (taskTarget.id == 0) {
            HokanApi.postTask(patient.id, taskTarget).then(x => {
                this.taskDialogClose();
                this.loadTasks();
            });
        }
        else {
            HokanApi.putTask(patient.id, taskTarget.id, taskTarget).then(x => {
                this.taskDialogClose();
                this.loadTasks();
            });
        }
    }
    private taskToggle(task: Model.Task) {
        const { patient } = this.state
        if (!patient) return;
        if (task.done_time) {
            task.done_time = null
        }
        else {
            task.done_time = new Date();
        }
        HokanApi.putTask(patient.id, task.id, task).then(x => {
            this.loadTasks();
        });
    }

    private setDialogText(name, value) {
        const target = this.state.taskTarget as any;
        if (!target) return;
        target[name] = value;
        this.setState({ taskTarget: target })
    }

    private setActivityAdd(open) {
        this.setState({ openAddMenu: open })
    }

    private loadKirokus(page: number | null = null, per: number | null = null) {
        if (page == null) page = this.state.kirokuList.page - 1
        if (per == null) per = this.state.kirokuList.per
        if (this.state.patient) {
            this.setState({ loadingActivity: true })
            HokanApi.getKirokuIIs(this.state.patient.id, page + 1, per).then(async x => {
                x.data.data = await Promise.all(x.data.data.map(async kiroku => {
                    const r = await Model.KirokuII.KangoKirokuII.load(kiroku)
                    await Model.KirokuII.KangoKirokuII.LoadImages(r)
                    return r
                }))
                this.setState({ kirokuList: x.data, loadingActivity: false })
            }).catch(e => {
                console.log(e)
                this.setState({ loadingActivity: false })
            })
        }
    }
    public loadDocChat(page: number | null = null, per: number | null = null, query: any = null) {
        if (page == null) page = this.state.docChatList.page - 1
        if (per == null) per = 5
        if (query == null) query = this.state.docChatQuery
        if (this.state.patient) {
            this.setState({ loadingActivity: true, docChatQuery: query, docChatList: new HokanApi.PagingResponse<Model.Document.DocumentChat>() })
            const pas = JSON.parse(localStorage.getItem("pat_activity_doc_chat_shown") || "{}")
            const d = pas[this.state.patient.id]
            const uidStr = this.state.me.id.toString()
            HokanApi.getChatDocuments(this.state.patient.id, page + 1, per, query).then(async x => {
                let sna = (!d) && x.data.data.length != 0
                x.data.data.forEach(y => {
                    const s = moment(y.updated_at).toDate().toISOString()
                    y.isNew = (s > d)
                    if (y.isNew) sna = true
                    y.children.forEach(z => {
                        const s = moment(z.created_at).toDate().toISOString()
                        z.message_dom = this.create_comment_dom(z.message, uidStr)
                        z.isNew = (s > d)
                        if (z.isNew) sna = true
                    })
                })
                this.setState({
                    docChatList: x.data,
                    loadingActivity: false,
                    showNotifyDocChat: sna
                })
            }).catch(e => {
                console.log(e)
                this.setState({ loadingActivity: false })
            })
        }
    }

    private loadKirokusForCalendar(from: string, to: string) {
        if (this.state.patient) {
            HokanApi.getKirokuIIsForHoukoku(this.state.patient.id, from, to).then(x => {
                const k = x.data.data.map(kiroku => {
                    return Model.KirokuII.KangoKirokuII.load(kiroku)
                })
                this.setState({ calendarKiroku: k })
            }).catch(e => {
                console.log(e)
            })
        }
    }

    private readComment(isDocChild: boolean) {
        if (this.state.me && this.state.me.branch && this.state.patient && isDocChild) {
            Firebase.instance.store.collection(`company/${this.state.me.branch.company_id}/user/${this.state.me.id}/notify`).
                where("hasRead", "==", false).
                where("pat_id", "==", this.state.patient.id).
                where("option_type", "in", isDocChild ? ["doc_child"] : ["talk_child", "Message", "MessageEm"]).
                get().then((docs) => {
                    docs.forEach((doc) => {
                        doc.ref.delete()
                    })
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    public resetNotifyDocChat() {
        this.readComment(true);
        this.setState({ showNotifyDocChat: false })
    }

    public resetNotifyComment() {
        this.readComment(false);
        this.setState({ showNotifyComment: false })
    }

    public calendar = (isActive: boolean) => {
        const { classes } = this.props
        const { patient } = this.state
        if (!patient || !isActive) {
            return <div />
        }
        const cds = Array.isArray(this.state.clickedDate) ? this.state.clickedDate : [this.state.clickedDate]
        let cd: string = "--"
        cds.forEach(x => {
            x.setHours(15)
            cd = DateUtil.toSlashDateString(x.toISOString())
        })
        return <div>
            <Calendar
                className={classes.calendar}
                locale="ja"
                calendarType="US"
                tileContent={({ date, view }) => {
                    date.setHours(15)
                    const d = DateUtil.toSlashDateString(date.toISOString())
                    if (view === 'month') {
                        const n = this.state.calendarKiroku.filter(x => DateUtil.toSlashDateString(x.time_start) == d).length
                        if (n == 0) return null;
                        else if (n == 1) return <p className={classes.calendarLabel}>訪問</p>
                        else return <p className={classes.calendarLabel}>訪問x{n}</p>
                    }
                    else {
                        return null
                    }
                }}
                onActiveDateChange={(value) => {
                    const m = moment(value.activeStartDate)
                    this.loadKirokusForCalendar(
                        DateUtil.monthFirstDateStr(m),
                        DateUtil.monthLastDateStr(m)
                    )
                }}
                onChange={(date) => this.setState({ clickedDate: date })}
                value={this.state.clickedDate}
            />
            <div>
                <Table className={classes.calendarTable}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>{cd} の記録</CustomTableCell>
                            <CustomTableCell align="right"></CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.calendarKiroku.filter(x => DateUtil.toSlashDateString(x.time_start) == cd).map(c => {
                                return <TableRow key={`crow_${c.id}`} className={classes.calendarRow}
                                    onClick={(e) => {
                                        if (c.id) {
                                            this.onCreateTab(c.id)
                                        }
                                    }}>
                                    <CustomTableCell style={{ textAlign: "left", padding: "10px" }} component="th" scope="row">
                                        {DateUtil.toTimeStr(c.time_start)}〜{DateUtil.toTimeStr(c.time_end)}
                                        ：{c.main_user && c.main_user.name}
                                        {
                                            c.is_temp ? "(一時保存)" : ""
                                        }
                                    </CustomTableCell>
                                    <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div>
    }
    public activity = (isActive: boolean) => {
        const { patient, showDoneTask } = this.state
        if (!patient || !isActive) {
            return <div>
                <CommentArea parent={this} disabled={true}
                    classes={this.props.classes}
                    simpleUsers={this.state.simpleUsers} />
            </div>
        }
        const { classes } = this.props;
        const openAddMenu = this.state.openAddMenu;
        const target = this.state.taskTarget;
        const tasks = (showDoneTask ? this.state.taskList : this.state.taskList.filter(x => x.done_time == null))

        const cdStr = DateUtil.currentDateStr()
        const sa = new StoreAccessor(this, true)
        return <div className={classes.childRoot}>
            <ActivityComponent
                me={this.state.me}
                parent={this} patient={patient}
                kirokuList={this.state.kirokuList}
                commentList={this.state.commentList}
                loadingActivity={this.state.loadingActivity}
                docChatList={this.state.docChatList}
                notifyDocChat={this.state.showNotifyDocChat}
                notifyComment={this.state.showNotifyComment}
                classes={classes}
            />
            <ShuttenAll />
        </div >
    }

    private kirokuEditDone() {
        this.setState({ isEditing: false, posting: true, errmsg_kiroku1: "" })
        const pat = this.state.patient;
        if (!pat) return;
        Model.Patient.Patient.reloadOrgs(pat).then(yyy => {
            const patPost = new Model.Patient.PatientPost(pat)
            HokanApi.putPatient(pat.id, patPost).then(x => {
                // TODO Message API同期
                let m = new Model.UpdateWcTalk();
                m.is_visible = patPost.is_visible
                m.wc_patient_branch_id = patPost.branch_id
                HokanApi.message.update_wc(pat.id, m).then(y => {
                    console.log("tmp")
                });
                this.setState({ isEditing: false, posting: false })
            }).catch(err => {
                this.setState({ isEditing: true, posting: false, errmsg_kiroku1: "更新に失敗しました(1)" })
            })
        }).catch(err => {
            this.setState({ isEditing: true, posting: false, errmsg_kiroku1: "更新に失敗しました(2)" })
        })
    }

    private updateAvatar() {
        const pat = this.state.patient;
        if (!pat) return;
        if (pat.avatar) {
            const fd = new FormData()
            if (pat.avatar) fd.append('avatar', pat.avatar);
            HokanApi.putPatientImage(pat.id, fd)
                .then((response) => {
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    private getPatValue(name: string, cat: any[]) {
        let res = this.state.patient as any;
        for (const x in cat) {
            res = res[cat[x]]
        }
        return res[name]
    }
    private setPatValue(name: string, val: any, cat: any[]) {
        let res = this.state.patient as any;
        for (const x in cat) {
            res = res[cat[x]]
        }
        res[name] = val
        this.setState({ patient: this.state.patient })
    }

    private setPatImage(name: string, selectedFiles: FileList) {
        const createObjectURL = (window.URL || (window as any).webkitURL).createObjectURL || (window as any).createObjectURL;
        for (let i = 0; i < selectedFiles.length; ++i) {
            const item = selectedFiles.item(i)
            if (item) {
                const pat = this.state.patient as any;
                if (pat) {
                    ImageUtil.compress(item, 500).then(res => {
                        pat[name] = new File([res], "avatar.jpg");
                        pat[name + "_url"] = createObjectURL(res)
                        this.setState({ patient: pat })
                        this.updateAvatar();
                    })
                }
            }
        }
    }

    private commitDelete() {
        this.setState({ isEditing: false, posting: true })
        const pat = this.state.patient;
        if (!pat) return;
        pat.is_active = false
        const patPost = new Model.Patient.PatientPost(pat)
        HokanApi.putPatient(pat.id, patPost).then(x => {
            this.setState({ posting: false })
            this.props.history.push(`/patients`)
        })
            .catch(err => {
                console.log(err)
            })
    }

    private patientEndCheckList = [
        "指示書の確認",
        "計画書・報告書関連の記載の確認",
        "サービス提供表有無の確認",
        "契約書の確認 / 介護と医療両方あるか",
        "記録書１がしっかりかけているか",
        "褥瘡関連のシートが作成されているか",
        "ターミナルケア加算の算定項目が網羅されているか"
    ]

    private setPatientStatus(st: string) {
        if (st == Model.Patient.StatusEnum.End) {
            this.setState({ statusEndDialog: true, statusEndCheck: {} })
        }
        else {
            this.setPatValue("status", st, [])
        }
    }

    private statusEndCommit() {
        this.setPatValue("status", Model.Patient.StatusEnum.End, [])
        this.handleStatusEndClose()
    }

    private handleStatusEndClose() {
        this.setState({ statusEndDialog: false })
    }

    private setStatusEndCheck(name: string, value: boolean) {
        const sec = this.state.statusEndCheck
        sec[name] = value;
        this.setState({ statusEndCheck: sec })
    }
    private getStatusEndCheck(name: string) {
        return this.state.statusEndCheck[name]
    }
    private statusEndDone() {
        for (let i = 0; i < this.patientEndCheckList.length; ++i) {
            if (!this.getStatusEndCheck(`${i}`)) {
                return false;
            }
        }
        return true;
    }

    public patientPage2 = (isActive: boolean) => {
        const pat = this.state.patient;
        if (!pat) {
            return <div />
        }
        const { classes } = this.props
        const editMode = this.state.isEditing

        const saR = new StoreAccessor(this, editMode)
        const sa = new StoreAccessor(this, editMode, "patient")
        const saU = sa.generateChild(["ukeire_check"])
        const saS = sa.generateChild(["shokai_check"])
        const saDocTemp = sa.generateChild(["doc_temp"])

        //const set_data = (name, val, cat) => { this.setPatData(name, val, cat) }
        const set_image = (name, file) => { this.setPatImage(name, file); this.updateAvatar(); }

        const editableAvatar = (name) => <EditableImage
            key={`et_${name}`}
            editMode={true}
            name={name}
            value={pat[name + "_url"]}
            setData={set_image} />

        const ht = pat.hoken ? pat.hoken.hoken_type : null

        const isEndDone = this.statusEndDone()
        const vl = (this.state.cur_patient ? this.state.cur_patient : pat).version_list
        return <div className={classes.childRoot}
            style={{
                overflowX: "hidden"
            }}>
            <div style={{
                position: "fixed",
                left: "10px",
                bottom: "90px",
                zIndex: 10
            }}>
                {
                    this.state.cur_log ?
                        <div>履歴は修正できません</div> :
                        this.state.isEditing ?
                            <Button color="primary" variant="contained"
                                disabled={this.state.posting}
                                onClick={(e) => this.kirokuEditDone()}>
                                確定
                            </Button>
                            :
                            <Button color="primary" variant="contained"
                                onClick={(e) => { this.setState({ isEditing: true }) }}>
                                編集
                            </Button>
                }
                <div>{this.state.errmsg_kiroku1}</div>
            </div>
            <div style={{ display: "none" }}>
                {editableAvatar("avatar")}
            </div>
            <Dialog onClose={(e) => this.handleStatusEndClose()} aria-labelledby="status-end-dialog-title" open={this.state.statusEndDialog}>
                <DialogTitle id="status-end-dialog-title">終了確認</DialogTitle>
                日付：{sa.dateC([], "ended_at")}
                理由：{sa.listC(["basic"], "end_reason", Model.Patient.EndReason)}
                <br />
                <div>
                    以下の項目を確認しましたか？
                    <EditableCheckList
                        editMode={editMode}
                        vertical={true}
                        list={this.patientEndCheckList.map((x, i) => { return { key: `${i}`, value: this.getStatusEndCheck(`${i}`), label: x } })}
                        setData={(n, v) => this.setStatusEndCheck(n, v)} />
                </div>
                <br />
                <div>
                    備考：
                    {sa.textC(["basic"], "end_comment")}
                </div>
                <br />
                <Button onClick={(e) => this.statusEndCommit()} variant="contained" color="secondary"
                    disabled={false}>
                    この利用者を終了
                </Button>
            </Dialog>

            <PatientElementTop name="" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementBasic name="基本情報" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementRenraku name=" " parent={this} classes={classes} isEditing={editMode} />
            <PatientElementContact name="地域社会資源との繋がり" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementEnv name="環境的側面" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementSocial name="心理社会的側面" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementSocialCalendar name="ソーシャルカレンダー（生活習慣など）" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementIryo name="医療情報" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementIryoKigu name="特別管理加算・別表８" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementNote name="自由ノート" parent={this} classes={classes} isEditing={editMode} />
            <PatientElementJimu name="事務情報" parent={this} classes={classes} isEditing={editMode} />


            <ExpansionPanel
                defaultExpanded={false}
                style={{ backgroundColor: Colors.backColor }}>
                <ExpansionPanelSummary
                    expandIcon={<Icons.ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    新規受け入れ時情報
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div style={{ display: "block", width: "100%" }}>
                        <ShinkiIraiCheck
                            isKettei={true}
                            accessor={new StoreAccessor(this, false, "patient")}
                        />
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <div style={{ marginBottom: "10px", textAlign: "right" }}>
                <Select
                    value={this.state.cur_log ? this.state.cur_log : 0}
                    autoWidth={true}
                    displayEmpty={true}
                    variant='outlined'
                    onChange={(e) => this.loadLog(e.target.value as any)}
                    className={classes.input}
                >
                    <MenuItem key={`cur_log_null`} value={0}>編集履歴</MenuItem>
                    {vl ?
                        vl.map((t, idx) =>
                            <MenuItem key={`${t.id}_${idx}`} value={t.id}>{DateUtil.toJpDateTimeString(t.created_at)}{t.user}</MenuItem>
                        )
                        : vl
                    }
                </Select>
            </div>

            <StandardTable title="利用者の削除">
                <Grid container={true} spacing={0}>
                    <Grid item={true} xs={12} md={12} lg={12}>
                        <Col2Table body={[
                            {
                                name: "", content: <div>
                                    {
                                        sa.isEditing ?
                                            <Button className={classes.deleteButton} variant="contained" color="default"
                                                onClick={(e) => this.setState({ openDelete: true })} disabled={this.state.loading}>
                                                利用者を削除する
                                            </Button>
                                            : null
                                    }
                                    <SimpleDialog
                                        title="削除確認"
                                        open={this.state.openDelete}
                                        deleteText="削除"
                                        hideOk={true}
                                        hideDelete={false}
                                        onCancel={() => this.setState({ openDelete: false })}
                                        onDelete={() => this.commitDelete()}
                                    >
                                        こちらの利用者[{pat.name}]を削除してよろしいでしょうか？<br />
                                        （この操作は戻せません）
                                    </SimpleDialog>
                                </div>
                            },
                        ]} />
                    </Grid>
                </Grid>
            </StandardTable>
        </div>
    }
}

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface CommentAreaProps extends Styles.WithStyles<typeof styles> {
    parent: PatientPage
    disabled?: boolean
    simpleUsers: Model.SimpleUser[]
}
interface CommentAreaStore {
    message: string,
    comment_type: string,
    date_limit: string | null,
    sending: boolean,
    me: Model.User,
    image_urls: string[]
    images: File[]
    mentionInput: string
    autoCompleteUsers: { id: number, display: string }[]
    errorMessage: string
}

function send_to_firebase(me: Model.User, pat: Model.Patient.Patient, message: string, option_type: string,
    branch_users: Model.SimpleUser[], company_users: Model.SimpleUser[]) {
    const str = message.toString();

    if (me && me.branch && pat) {
        const data = {
            pat_id: pat.id,
            pat_name: pat.name,
            updated_at: new Date().toISOString(),
            updated_by_name: me.name,
            updated_by_id: me.id,
            option_type: option_type,
            hasRead: false,
            range: "None"
        }

        // for notify
        str.replace(/@\[(.*?)\]\(user:([\-0-9]*?)\)/g, (str, ...args: string[]) => {
            if (!me || !me.branch || !pat) return str
            const cid = me.branch.company_id;
            if (args[1] == "-1") { // 事業所全体
                branch_users.forEach(user => {
                    Firebase.instance.store.collection(`company/${cid}/user/${user.id}/notify`).add({
                        ...data,
                        range: "branch"
                    }).catch(err => {
                        console.log(err)
                    })
                })
            }
            else if (args[1] == "-2") { // 法人全体
                company_users.forEach(user => {
                    Firebase.instance.store.collection(`company/${cid}/user/${user.id}/notify`).add({
                        ...data,
                        range: "company"
                    }).catch(err => {
                        console.log(err)
                    })
                })
            }
            else {
                Firebase.instance.store.collection(`company/${cid}/user/${args[1]}/notify`).add({
                    ...data,
                    range: "user"
                }).catch(err => {
                    console.log(err)
                })
            }
            return str;
        })

        // for favo
        Firebase.instance.store.collection(`company/${me.branch.company_id}/pat_notify`).doc(`${pat.id}`).set(
            data
        ).catch(err => {
            console.log(err)
        })
    }
}

class CommentArea extends React.Component<CommentAreaProps, CommentAreaStore> {
    constructor(props) {
        super(props)
        this.state = {
            message: "",
            comment_type: "トーク",
            date_limit: null,
            sending: false,
            me: new Model.User(),
            images: [],
            image_urls: [],
            mentionInput: "",
            autoCompleteUsers: [],
            errorMessage: ""
        }
        this.subscrived = null
    }

    private subscrived

    public componentDidMount() {
        HokanApi.me().then(res => {
            const me = res.data
            this.setState({ me: me })
            const patId = this.props.parent.getPatientId()
            if (me.branch) {
                if (this.subscrived) this.subscrived()
                this.subscrived = Firebase.instance.store.collection(`company/${me.branch.company_id}/pat_notify`).doc(`${patId}`).onSnapshot((querySnapshot) => {
                    this.props.parent.loadComments();
                    this.props.parent.loadDocChat();
                })
            }
        })

        this.setState({
            autoCompleteUsers: this.props.simpleUsers.map(x => {
                return {
                    id: x.id,
                    display: x.name
                }
            })
        })
    }

    public componentWillUnmount() {
        if (this.subscrived) this.subscrived()
    }

    private commit() {
        if (this.state.comment_type == "タスク") {
            this.postTask()
        }
        else {
            this.postComment()
        }
    }

    private postComment() {
        this.setState({ sending: true, errorMessage: "" })
        const t = new Model.Comment.CommentPost()
        t.message = this.state.message
        t.comment_type = "Message"
        if (this.state.comment_type == "緊急コール") {
            t.comment_type = "MessageEm"
        }
        t.content = "{}"
        const patId = this.props.parent.getPatientId()
        const me = this.state.me
        const pat = this.props.parent.state.patient
        t.images = this.state.images
        HokanApi.postComment(patId, t).then(res => {
            this.props.parent.loadComments()
            if (me && me.branch && pat) {
                send_to_firebase(me, pat, this.state.message, t.comment_type,
                    this.props.simpleUsers.filter(x => x.branch_id == me.branch_id),
                    this.props.simpleUsers)
            }
            this.setState({
                sending: false,
                message: "",
                errorMessage: ""
            })
        }).catch(err => {
            this.setState({ sending: false, errorMessage: "エラー" })
        })
    }

    private postTask() {
        this.setState({ sending: true, errorMessage: "" })
        const patId = this.props.parent.getPatientId()
        const t = new Model.Task()
        t.date_limit = this.state.date_limit
        t.content = this.state.message
        HokanApi.postTask(patId, t).then(x => {
            this.props.parent.loadTasks();
            this.setState({
                message: "",
                date_limit: null,
                sending: false,
                errorMessage: "",
                images: [],
                image_urls: [],
            });
        }).catch(err => {
            this.setState({ sending: false, errorMessage: "エラー" })
        })
    }

    public render() {
        if (this.props.disabled) return <div />
        const sa = new StoreAccessor(this, true)
        sa.callBack = (cat, name, v) => {
            if (name == "comment_type" && v == "緊急コール") {
                sa.setValue("message", "@[事業所全体](user:-1) ")
            }
        }

        return <div style={{ display: "block", width: "100%" }}>
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12} md={12} lg={12}>
                    <ExpansionPanel
                        key={"トーク"}
                        defaultExpanded={false}
                        style={{ margin: "5px", border: "none", borderWidth: "1px", borderRadius: "0px" }}
                    ><ExpansionPanelSummary
                        style={{ backgroundColor: "#ececec" }}
                        expandIcon={<Icons.ExpandMore />}>
                            <div>
                                <span style={{ marginLeft: "20px" }}>トーク</span></div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            style={{
                                display: "block",
                                padding: "5px"
                            }} >
                            <div>
                                {
                                    sa.listRawC([], "comment_type", [
                                        { key: "トーク", value: "トーク" },
                                        { key: "緊急コール", value: "緊急コール" }
                                    ], true, false)
                                }
                            </div>
                            <div>
                                <div >
                                    <MentionsInput
                                        value={this.state.message}
                                        onChange={(event, newValue, newPlainTextValue, mentions) => {
                                            this.setState({ message: newValue })
                                        }}
                                        placeholder={"@でメンションができます"}
                                        allowSpaceInQuery={true}
                                        style={defaultStyle}
                                        displayTransform={(id, display) => `@${display}`}
                                    >
                                        <Mention
                                            markup="@[__display__](user:__id__)"
                                            trigger="@"
                                            data={this.state.autoCompleteUsers}
                                            renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                                <div>{highlightedDisplay}</div>
                                            )}
                                            onAdd={e => {}}
                                            style={{
                                                backgroundColor: '#cee4e5',
                                            }}
                                        />
                                    </MentionsInput>
                                </div>
                                <div>
                                    <Button color="default" size="small" variant="outlined"
                                        style={{ margin: "5px" }}
                                        onClick={(e) => {
                                            this.setState({ message: this.state.message + " @" })
                                        }}
                                    >@</Button>
                                    <Button color="default" size="small" variant="outlined"
                                        style={{ margin: "5px" }}
                                        onClick={(e) => {
                                            this.setState({ message: this.state.message + " @[事業所全体](user:-1) " })
                                        }}
                                    >@事業所</Button>
                                    <Button color="default" size="small" variant="outlined"
                                        style={{ margin: "5px" }}
                                        onClick={(e) => {
                                            this.setState({ message: this.state.message + " @[法人全体](user:-2) " })
                                        }}
                                    >@法人全体</Button>
                                </div>
                                <div style={{ textAlign: "right" }}>
                                    <div>
                                        {
                                            this.state.image_urls.map((url, idx) => <div key={`img_${idx}`} className={this.props.classes.chat_image}>
                                                <div>
                                                    <img src={url} width="100px" />
                                                </div>
                                                <Button color="secondary" size="small" variant="outlined"
                                                    onClick={(e) => {
                                                        const u = this.state.image_urls.filter((x, i) => i != idx)
                                                        const f = this.state.images.filter((x, i) => i != idx)
                                                        this.setState({ image_urls: u, images: f })
                                                    }}
                                                >削除</Button>
                                            </div>)
                                        }
                                    </div>
                                    <div>
                                        {
                                            this.state.image_urls.length < 5 ?
                                                <input type="file" id="" ref="file" accept="image/*"
                                                    onChange={(e) => {
                                                        const selectedFiles = e.target.files
                                                        if (selectedFiles && selectedFiles.length > 0) {
                                                            const item = selectedFiles[0]
                                                            const len = selectedFiles.length
                                                            const u = this.state.image_urls
                                                            const f = this.state.images
                                                            Resizer.imageFileResizer(
                                                                item,
                                                                1024, 1024,
                                                                'JPEG',
                                                                100, 0,
                                                                res => {
                                                                    f.push(new File([res], item.name));
                                                                    u.push(createObjectURL(res))
                                                                    this.setState({ image_urls: u, images: f })
                                                                },
                                                                'blob'
                                                            );
                                                        }
                                                    }} />
                                                : <div>これ以上追加できません</div>
                                        }
                                    </div>
                                    <br />
                                    <Button color="primary" variant="contained"
                                        disabled={this.state.sending}
                                        onClick={(e) => {
                                            this.commit()
                                        }} >{this.state.sending ? "送信中" : "送信"}</Button>
                                    <div>{this.state.errorMessage}</div>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                </Grid>
            </Grid>
        </div>
    }
}

interface ElementProps extends Styles.WithStyles<typeof styles> {
    parent: any
    isEditing: boolean
    name: string
}

class CatVal {
    public name: any
    public cat: any[]
    public value: any
    constructor(name: any, cat: any[] = ["patient"]) {
        this.name = name
        this.cat = cat
        this.value = {}
    }

    public getValue(sa: StoreAccessor, saR: StoreAccessor) {
        return saR.getValueC(this.name, this.cat)
    }
}

interface CatValFunc { (): CatVal[] };
abstract class PatientElement extends React.Component<ElementProps> {
    protected elements: { name: string, content: any, values: CatVal[] | CatValFunc | null, helpContent?: JSX.Element }[] = []
    protected shouldUpdate: { [index: string]: boolean } = {}

    constructor(props) {
        super(props)
        this.buildElements(props.isEditing)
    }

    public abstract buildElements(isEditing: boolean);

    protected splitPositions(): number[] {
        return [] as number[]
    }

    private cloneObject(obj: any, depth: number) {
        if (depth >= 2 || obj == null || obj == undefined || typeof obj !== 'object') return obj
        const ro = {}
        const keys = Object.keys(obj)
        for (let ki in keys) {
            const key = keys[ki]
            ro[key] = this.cloneObject(obj[key], depth + 1)
        }
        return ro
    }
    private diffObject(o1: any, o2: any, depth: number) {
        if (depth >= 2 || o1 == null || o1 == undefined || typeof o1 !== 'object') {
            return o1 !== o2
        }
        const keys = Object.keys(o1)
        for (let ki in keys) {
            const key = keys[ki]
            if (this.diffObject(o1[key], o2[key], depth + 1)) {
                return true
            }
        }
        return false
    }

    checkValues = (sa: StoreAccessor, saR: StoreAccessor, lis: CatVal[] | CatValFunc | null) => {
        if (!lis) return false
        let res = false
        const t = (Array.isArray(lis) ? lis : lis())
        t.forEach(x => {
            const v = x.getValue(sa, saR)
            let r = false

            if (Array.isArray(v)) {
                if (Array.isArray(x.value) == false) x.value = []
                const v1 = v
                const v2 = x.value
                if (v1.length != v2.length) {
                    r = true
                }
                else {
                    for (let i = 0; i < v1.length; ++i) {
                        const o1 = v1[i]
                        const o2 = v2[i]
                        if (this.diffObject(o1, o2, 0)) {
                            r = true;
                            break;
                        }
                    }
                }
                if (r) {
                    const ra: any[] = []
                    for (let i = 0; i < v1.length; ++i) {
                        const o1 = v1[i]
                        ra.push(this.cloneObject(o1, 0))
                    }
                    x.value = ra
                }
            }
            else {
                if (v != x.value) {
                    r = true
                    x.value = v
                }
            }
            if (r) {
                res = true
            }
        })
        return res
    }

    public genCatValForDialog(name: any, cat: any[] = ["patient"], flagNameCat: any[] = []) {
        return [new CatVal(name, cat), new CatVal(StoreAccessor.getFlagNameC(flagNameCat, name), [])]
    }

    shouldComponentUpdate(np, ns) {
        const t = (this.props.isEditing != np.isEditing)
        const saR = new StoreAccessor(np.parent, true)
        const sa = new StoreAccessor(np.parent, true, "patient")
        if (t) this.buildElements(np.isEditing)
        let res = t
        this.elements.forEach(e => {
            if (e.values != null) {
                const cv = this.checkValues(sa, saR, e.values) || t
                this.shouldUpdate[e.name] = cv
                if (cv) res = true
            }
        })
        return res
    }

    componentDidMount() {
        this.elements.forEach(e => {
            this.shouldUpdate[e.name] = true
        })
        this.forceUpdate()
    }

    public render() {
        const { classes } = this.props;
        if (this.elements.length == 0) return <div />
        let splitPos = this.splitPositions();
        let splited: any[] = [] as any[]
        let cur: any[] = [] as any[]
        this.elements.forEach((y, yi) => {
            cur.push(y)
            if (splitPos.findIndex(t => t == yi) != -1) {
                splited.push(
                    <Col2Table2 key={`patelbr_${splited.length}`}>
                        {
                            cur.map((x, xi) => {
                                return <Col2TableRow
                                    key={`patelb_${splited.length}_${xi}`}
                                    elm={x}
                                    shouldUpdate={this.shouldUpdate}
                                />
                            })
                        }
                    </Col2Table2>
                )
                cur = [] as any[]
            }
        })
        if (cur.length > 0) {
            splited.push(
                <Col2Table2 key={`patelbr_${splited.length}`}>
                    {
                        cur.map((x, xi) => {
                            return <Col2TableRow
                                key={`patelb_${splited.length}_${xi}`}
                                elm={x}
                                shouldUpdate={this.shouldUpdate}
                            />
                        })
                    }
                </Col2Table2>
            )
        }

        return <StandardTable key={`pat_${this.props.name}`} title={this.props.name}>
            <Grid container={true} spacing={0}>
                {
                    splited.map((x, xi) => {
                        return <Grid key={`gr_${xi}`} item={true} xs={12} md={6} lg={6}>
                            {x}
                        </Grid>
                    })
                }
            </Grid>
        </StandardTable>
    }
}

class PatientElementBasic extends PatientElement {

    protected splitPositions(): number[] {
        return [6]
    }

    private splitTel(src: string) {
        return src ? src.split('\n') : []
    }

    public buildElements(isEditing: boolean) {
        const { classes, parent } = this.props;
        const pat = parent.state.patient;
        const editMode = isEditing

        const saR = new StoreAccessor(parent, editMode)
        const sa = new StoreAccessor(parent, editMode, "patient")
        const hasWisemanConnected = (pat && pat.wiseman_id && pat.wiseman_id != "")
        const saFixed = new StoreAccessor(parent, editMode && !hasWisemanConnected, "patient")
        const saDocTemp = sa.generateChild(["doc_temp"])
        const ht = pat.hoken ? pat.hoken.hoken_type : null
        const pathEnv = ["patient", "env"]
        const pathBasic = ["patient", "basic"]
        const pathHoken = ["patient", "hoken"]
        const pathSaigai = ["patient", "saigai"]

        const termsConfirm = StoreAccessor.getFlagNameC([], "terms_confirmed")

        this.elements = [
            { name: "事業所", content: () => sa.branch("branch", false), values: this.genCatValForDialog("branch") },
            { name: "氏名", content: () => saFixed.text("name"), values: [new CatVal("name")] },
            { name: "ヨミガナ", content: () => saFixed.text("name_kana"), values: [new CatVal("name_kana")] },
            {
                name: "生年月日", content: () => <div>
                    {saFixed.date("birthday", true)}
                    （{DateUtil.getAge(pat.birthday)}歳
                    {DateUtil.getAge(pat.birthday) == 0 ? ` ${DateUtil.getAgeMonth(pat.birthday)}ヶ月` : ""}）
                    {sa.textC(["basic"], "zaitai_shuusuu", "在胎週数")}
                </div>,
                values: [new CatVal("birthday"), new CatVal("zaitai_shuusuu", pathBasic)]
            },
            {
                name: "住所", content: () => <div>
                    {saFixed.textC(["env"], "address_zip", "郵便番号")}
                    {saFixed.textC(["env"], "address1", "都道府県")}
                    {saFixed.textC(["env"], "address2", "市区町村")}
                    {saFixed.textC(["env"], "address3", "それ以降")}

                    <Button color="default" variant="outlined" size="small"
                        onClick={(e) => {
                            const url = (`https://www.google.co.jp/maps/search/${parent.getPatValue("address1", ["env"]) +
                                parent.getPatValue("address2", ["env"]) +
                                parent.getPatValue("address3", ["env"])
                                }`)
                            if (UADetector.isIPhone()) {
                                window.location.href = url
                            }
                            else {
                                window.open(url)
                            }
                        }}
                        className={classes.filter_button_d}>
                        地図
                    </Button>
                    <div>
                        <br />
                        <div>{sa.textC(["env"], "address_comment", "備考")}</div>
                        <Button color="default" variant="outlined" size="small"
                            disabled={parent.getPatValue("address_comment", ["env"]) == ""}
                            onClick={(e) => {
                                const url = (`https://www.google.co.jp/maps/search/${parent.getPatValue("address_comment", ["env"])
                                    }`)
                                if (UADetector.isIPhone()) {
                                    window.location.href = url
                                }
                                else {
                                    window.open(url)
                                }
                            }}
                            className={classes.filter_button_d}>
                            地図(備考)
                        </Button>
                    </div>
                </div>
                , values: [
                    new CatVal("address1", pathEnv),
                    new CatVal("address2", pathEnv),
                    new CatVal("address3", pathEnv),
                    new CatVal("address_comment", pathEnv),
                    new CatVal("address_zip", pathEnv)
                ]
            },
            {
                name: "電話番号", content: () => <div className={classes.innerTable} >
                    <Col2Table disableBGColor={true} body={[
                        {
                            name: "自宅", content: <div>
                                <div>
                                    {
                                        editMode ? sa.text("tel_home") :
                                            this.splitTel(parent.getPatValue("tel_home", [])).map((tel, teli) => {
                                                return <div key={"th_" + teli}><a href={`tel:${tel}`}>{tel}</a></div>
                                            })
                                    }
                                </div>
                                <div>{sa.textC(["env"], "tel_home_comment", "備考")}</div>
                            </div>
                        },
                        {
                            name: "携帯", content: <div>
                                <div>
                                    {
                                        editMode ? sa.text("tel_cell") :
                                            this.splitTel(parent.getPatValue("tel_cell", [])).map((tel, teli) => {
                                                return <div key={"tc_" + teli}><a href={`tel:${tel}`}>{tel}</a></div>
                                            })
                                    }
                                </div>
                                <div>{sa.textC(["env"], "tel_cell_comment", "備考")}</div>
                            </div>
                        }
                    ]} />
                </div>
                , values: [new CatVal("tel_home"), new CatVal("tel_cell"), new CatVal("tel_home_comment", pathEnv), new CatVal("tel_cell_comment", pathEnv)]
            },
            {
                name: "紹介元", content: () => sa.orgTantoC(["basic"], "shoukai_from"),
                values: this.genCatValForDialog("shoukai_from", pathBasic, ["basic"])
            },
            {
                name: "担当", content: () => <div>
                    主担当：{sa.user("main_user")}
                    副担当１：{sa.user("sub_user1")}
                    副担当２：{sa.user("sub_user2")}
                </div>
                , values: [
                    ...this.genCatValForDialog("main_user"),
                    ...this.genCatValForDialog("sub_user1"),
                    ...this.genCatValForDialog("sub_user2")
                ]
            },
            {
                name: "同意書", content: () => <div>
                    {
                        pat.terms_disagree ?
                            <div>拒否済み</div>
                            : pat.terms_confirmed ?
                                <div>{DateUtil.toJpDateString(pat.terms_confirmed)}:同意済み</div>
                                : null
                    }
                    {
                        pat.terms_confirmed || pat.terms_disagree ?
                            <Button color="default" variant="outlined" size="small"
                                onClick={(e) => { saR.setValueR(termsConfirm, true) }}
                                className={classes.filter_button_d}>
                                確認
                            </Button>
                            :
                            pat.terms_disagree ?
                                null :
                                < Button color="primary" variant="contained" size="small"
                                    onClick={(e) => { saR.setValueR(termsConfirm, true) }}
                                    className={classes.filter_button_primary}>
                                    同意を取ってください
                                </Button>
                    }

                    <TermConfirmDialog
                        patient={pat}
                        open={saR.getValueR(termsConfirm) || false}
                        timeStr={sa.getValue("terms_confirmed")}
                        onCancel={() => { saR.setValueR(termsConfirm, false) }}
                        onOk={(value, url) => {
                            sa.setValue("terms_confirmed", value)
                            sa.setValue("terms_disagree", false)
                            sa.setValue("terms_sign_url", url)
                            saR.setValue(termsConfirm, false)
                        }}
                        onDisagree={() => {
                            sa.setValue("terms_confirmed", null)
                            sa.setValue("terms_disagree", true)
                            saR.setValue(termsConfirm, false)
                        }}
                    />
                </div>, values: [...this.genCatValForDialog("terms_confirmed"),
                new CatVal("terms_disagree", ["patient"]), new CatVal("terms_sign_url", ["patient"])]
            },
            { name: "優先順位", content: () => sa.listC(["saigai"], "priority", Model.Patient.SaigaiPriority), values: [new CatVal("priority", pathSaigai)] },
            { name: "災害備考", content: () => sa.textC(["saigai"], "comment"), values: [new CatVal("comment", pathSaigai)] },
            { name: "近隣の避難場所", content: () => sa.textC(["saigai"], "hinan_basho"), values: [new CatVal("hinan_basho", pathSaigai)] },
            { name: "近隣の災害拠点病院", content: () => sa.textC(["saigai"], "kyoten_byouin"), values: [new CatVal("kyoten_byouin", pathSaigai)] },
            { name: "災害時個別支援計画書", content: () => <>{pat.saigaiji_taioutaisei_pdf_url ? <a target='_blank' href={pat.saigaiji_taioutaisei_pdf_url}>書類を開く</a> : <div />}</>, values: [] as CatVal[] }
        ]
    }
}

class PatientElementRenraku extends PatientElement {

    protected splitPositions(): number[] {
        return [2]
    }
    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const pat = parent.state.patient;
        const editMode = isEditing
        const saR = new StoreAccessor(parent, editMode)
        const sa = new StoreAccessor(parent, editMode, "patient")
        const hasWisemanConnected = (pat && pat.wiseman_id && pat.wiseman_id != "")
        const saFixed = new StoreAccessor(parent, editMode && !hasWisemanConnected, "patient")
        const saDocTemp = sa.generateChild(["doc_temp"])
        const pathDoc = ["patient", "doc_temp"]
        const pathSocial = ["patient", "social"]
        const pathEnv = ["patient", "env"]
        this.elements = [
            { name: "本人の気持ちや希望", content: () => sa.textC(["social"], "kibou_own"), values: [new CatVal("kibou_own", pathSocial)] },
            { name: "家族の気持ちや希望", content: () => sa.textC(["social"], "kibou_famiry"), values: [new CatVal("kibou_famiry", pathSocial)] },
            { name: "家族・キーパーソン", content: () => sa.famiryC(["social"], "famiry"), values: [new CatVal("famiry", pathSocial)] },
        ]
    }
}

class PatientElementTop extends PatientElement {
    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const pat = parent.state.patient;
        const editMode = isEditing
        const sa = new StoreAccessor(parent, editMode, "patient")
        const pathSocial = ["patient", "social"]
        this.elements = [
            { name: "緊急時の方針・連絡系統", content: () => sa.textC(["social"], "kinkyu"), values: [new CatVal("kinkyu", pathSocial)] },
        ]
    }
}

class PatientElementSocialCalendar extends PatientElement {

    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const pat = parent.state.patient;
        const editMode = isEditing
        const saR = new StoreAccessor(parent, editMode)
        const sa = new StoreAccessor(parent, editMode, "patient")

        const pathSocial = ["patient", "social", "social_calendar"]
        this.elements = [
            { name: "月", content: () => sa.textC(["social", "social_calendar"], "monday"), values: [new CatVal("monday", pathSocial)] },
            { name: "火", content: () => sa.textC(["social", "social_calendar"], "tuesday"), values: [new CatVal("tuesday", pathSocial)] },
            { name: "水", content: () => sa.textC(["social", "social_calendar"], "wednesday"), values: [new CatVal("wednesday", pathSocial)] },
            { name: "木", content: () => sa.textC(["social", "social_calendar"], "thursday"), values: [new CatVal("thursday", pathSocial)] },
            { name: "金", content: () => sa.textC(["social", "social_calendar"], "friday"), values: [new CatVal("friday", pathSocial)] },
            { name: "土", content: () => sa.textC(["social", "social_calendar"], "saturday"), values: [new CatVal("saturday", pathSocial)] },
            { name: "日", content: () => sa.textC(["social", "social_calendar"], "sunday"), values: [new CatVal("sunday", pathSocial)] },
            { name: "備考", content: () => sa.textC(["social", "social_calendar"], "comment"), values: [new CatVal("comment", pathSocial)] },
        ]
    }
}

class PatientElementEnv extends PatientElement {

    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const pat = parent.state.patient;
        const editMode = isEditing
        const saR = new StoreAccessor(parent, editMode)
        const sa = new StoreAccessor(parent, editMode, "patient")
        const saReadOnly = new StoreAccessor(parent, false, "patient")
        const saDocTemp = sa.generateChild(["doc_temp"])
        const saReadOnlyDocTemp = saReadOnly.generateChild(["doc_temp"])
        const path = ["patient", "doc_temp"]
        this.elements = [
            { name: "家の写真", content: () => saDocTemp.doc("doc_img_house", 8, pat.id), values: () => this.genCatValForDialog("doc_img_house", path) },
            { name: "家の特徴", content: () => saDocTemp.doc("doc_img_tokucho", 8, pat.id), values: () => this.genCatValForDialog("doc_img_tokucho", path) },
            { name: "収入・経済状況", content: () => sa.textC(["env"], "keizai"), values: [new CatVal("keizai", ["patient", "env"])] },
        ]
    }
}

class PatientElementSocial extends PatientElement {

    protected splitPositions(): number[] {
        return [3]
    }
    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const editMode = isEditing
        const sa = new StoreAccessor(parent, editMode, "patient")
        const path = ["patient", "social"]
        const pathValue = ["patient", "value"]
        this.elements = [
            { name: "生育歴", content: () => sa.textC(["social"], "seiiku"), values: [new CatVal("seiiku", path)] },
            { name: "訪看導入の経緯", content: () => sa.textC(["social"], "keii"), values: [new CatVal("keii", path)] },
            { name: "社会との繋がり", content: () => sa.textC(["social"], "connect_work"), values: [new CatVal("connect_work", path)] },
            { name: "同居の状況", content: () => sa.textC(["social"], "doukyo"), values: [new CatVal("doukyo", path)] },
            /*
            {
                name: "ジェノグラム", content: () => <Button color="default" variant="outlined"
                    onClick={(e) => parent.props.history.push('/document/genogram')}
                    className={classes.filter_button_d}>
                    作成
                    </Button>
                , values: []
            },*/
            { name: "家族などの介護 / 養育の状況や危機", content: () => sa.textC(["social"], "kiki_family"), values: [new CatVal("kiki_family", path)] },
            { name: "家族計画・妊娠出産", content: () => sa.textC(["social"], "family_keikaku"), values: [new CatVal("family_keikaku", path)] },
            { name: "セクシャリティ", content: () => sa.listC(["value"], "sexuality", Model.Patient.SexualityEnum), values: [new CatVal("sexuality", pathValue)] },
            { name: "宗教や信仰", content: () => sa.textC(["value"], "religion"), values: [new CatVal("religion", pathValue)] },
        ]
    }
}

class PatientElementContact extends PatientElement {

    protected splitPositions(): number[] {
        return [3]
    }
    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const editMode = isEditing
        const sa = new StoreAccessor(parent, editMode, "patient")
        const saDocTemp = sa.generateChild(["doc_temp"])
        const path = ["patient", "contact"]
        const patId = parent.getPatientId()
        this.elements = [
            { name: "指示書の医師", content: () => sa.orgTantoC(["contact"], "shujii"), values: this.genCatValForDialog("shujii", path, ["contact"]) },
            { name: "医師２", content: () => sa.orgTantoC(["contact"], "shujii_2"), values: this.genCatValForDialog("shujii_2", path, ["contact"]) },
            //{name: "緊急時の医師連絡先", content: ()=>sa.orgTantoC(["contact"], "kinkyu", "openTantoDialog") },
            { name: "ケアマネ", content: () => sa.orgTantoC(["contact"], "keamane"), values: this.genCatValForDialog("keamane", path, ["contact"]) },
            { name: "計画相談員", content: () => sa.orgTantoC(["contact"], "soudan"), values: this.genCatValForDialog("soudan", path, ["contact"]) },
            {
                name: "その他", content: () => {
                    console.log(sa.getValueC("other", ["contact"]))
                    return <div>
                        {
                            sa.getValueC("other", ["contact"]).map((o, i) => {
                                return o ? <div key={`patec_${i}`}>
                                    {i > 0 ? <hr /> : null}
                                    {sa.orgTantoC(["contact", "other"], i)}
                                </div> : null
                            })
                        }
                        {
                            editMode ?
                                <Button variant="outlined" size="small" color="primary"
                                    onClick={(e) => {
                                        const t: Model.OrganizationTanto[] = sa.getValueC("other", ["contact"]).filter(x => x)
                                        t.push(new Model.OrganizationTanto());
                                        sa.setValueC("other", t, ["contact"])
                                    }}>
                                    ＋追加
                                </Button>
                                : null
                        }
                    </div>
                }
                , values: () => [].concat.apply([], [
                    ...sa.getValueC("other", ["contact"]).map((x, i) =>
                        new CatVal(i, ["patient", "contact", "other"])),
                    new CatVal("other", ["patient", "contact"]),
                    ...sa.getValueC("other", ["contact"]).map((x, i) => this.genCatValForDialog(i, ["patient", "contact", "other"], ["contact", "other"]))
                ])
            }
        ]
    }
}

class PatientElementIryo extends PatientElement {

    protected splitPositions(): number[] {
        return [11]
    }
    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const editMode = isEditing
        const sa = new StoreAccessor(parent, editMode, "patient")
        const pat = parent.state.patient;
        console.log(pat.shohosen_pdf_urls)
        console.log(pat.shohosen_image_urls)
        const path = ["patient", "medical"]
        const pathADL = [...path, "adl"]
        const pathIADL = [...path, "iadl"]
        const genCheck = (name, cat, ch) => {
            const cm = ch + "_comment"
            return {
                key: ch,
                label: name,
                //name: name,
                //content: sa.checkCommentC(["medical", cat], ch, cm, "備考："),
                values: [new CatVal(ch, ["patient", "medical", cat])],
                check: sa.getValueC(ch, ["medical", cat])
            }
        }
        const arergy = [
            genCheck("えび", "arergy_checks", "is_ebi"),
            genCheck("かに", "arergy_checks", "is_kani"),
            genCheck("小麦", "arergy_checks", "is_komugi"),
            genCheck("そば", "arergy_checks", "is_soba"),
            genCheck("卵", "arergy_checks", "is_tamago"),
            genCheck("乳", "arergy_checks", "is_nyuu"),
            genCheck("落花生", "arergy_checks", "is_rakkasei")
        ].filter(x => editMode || x.check)
        const kansen = [
            genCheck("MRSA", "kansen_checks", "is_mrsa"),
            genCheck("MDRP", "kansen_checks", "is_mdrp"),
            genCheck("VRE", "kansen_checks", "is_vre"),
            genCheck("ESBL産生菌", "kansen_checks", "is_esbl"),
            genCheck("MBL産生菌", "kansen_checks", "is_mbl"),
            genCheck("HIV抗体", "kansen_checks", "is_hiv"),
            genCheck("HBs抗原", "kansen_checks", "is_hbs"),
            genCheck("HCV抗体", "kansen_checks", "is_hcv"),
            genCheck("RPR", "kansen_checks", "is_rpr"),
            genCheck("TPHA", "kansen_checks", "is_tpha")
        ].filter(x => editMode || x.check)
        const yobousesshu = [
            genCheck("Hibワクチン", "yobousesshu_checks", "is_hib"),
            genCheck("小児用肺炎球菌ワクチン", "yobousesshu_checks", "is_shouni_haien"),
            genCheck("B型肝炎ワクチン", "yobousesshu_checks", "is_b_kanen"),
            genCheck("ロタウイルスワクチン", "yobousesshu_checks", "is_rota"),
            genCheck("４種混合ワクチン", "yobousesshu_checks", "is_yonshu"),
            genCheck("BCG", "yobousesshu_checks", "is_bcg"),
            genCheck("MRワクチン", "yobousesshu_checks", "is_mr"),
            genCheck("水痘ワクチン", "yobousesshu_checks", "is_suitou"),
            genCheck("日本脳炎ワクチン", "yobousesshu_checks", "is_nouen"),
            genCheck("HPVワクチン", "yobousesshu_checks", "is_hpv"),
            genCheck("インフルエンザワクチン", "yobousesshu_checks", "is_ful"),
            genCheck("成人用肺炎球菌ワクチン", "yobousesshu_checks", "is_seijin_haien")
        ].filter(x => editMode || x.check)

        this.elements = [
            { name: "主な傷病名", content: () => sa.textC(["medical"], "sick_name"), values: [new CatVal("sick_name", path)] },
            { name: "病の軌跡・既往歴・入院歴", content: () => sa.textC(["medical"], "kiou_reki"), values: [new CatVal("kiou_reki", path)] },
            {
                name: "アレルギー",
                content: () => <div>
                    {sa.checkListC(["medical", "arergy_checks"], arergy, false, false, true)}
                    その他：{sa.textC(["medical"], "arergy", "その他")}
                </div>,
                values: () => [new CatVal("arergy", path), ...([] as any[]).concat.apply([], arergy.map(x => x.values))]
            },
            {
                name: "感染症",
                content: () => <div>
                    {sa.checkListC(["medical", "kansen_checks"], kansen, false, false, true)}
                    その他：{sa.textC(["medical"], "kansen", "その他")}
                </div>,
                values: () => [new CatVal("kansen", path), ...([] as any[]).concat.apply([], kansen.map(x => x.values))]
            },
            {
                name: "予防接種",
                content: () => <div>
                    {sa.checkListC(["medical", "yobousesshu_checks"], yobousesshu, false, false, true)}
                    その他：{sa.textC(["medical"], "yobousesshu", "その他")}
                </div>,
                values: () => [new CatVal("yobousesshu", path), ...([] as any[]).concat.apply([], yobousesshu.map(x => x.values))]
            },
            {
                name: "皮膚", content: () => <div>
                    <div>
                        {sa.textC(["medical"], "hihu")}
                    </div>
                    <div>
                        褥瘡対策計画書：{pat.hihu_doc ?
                            (pat.hihu_doc.content as FormModel.JokusoKeikaku.Core).values.create_date
                            : null}
                        {sa.docC([], "hihu_doc", 10, pat.id)}
                    </div>
                </div>
                , values: [new CatVal("hihu", path), ...this.genCatValForDialog("hihu_doc")]
            },
            { name: "聴覚", content: () => sa.checkCommentC2(["medical"], "ishisotsu_choukaku_check", "ishisotsu_choukaku"), values: [new CatVal("ishisotsu_choukaku_check", path), new CatVal("ishisotsu_choukaku", path)] },
            { name: "視覚", content: () => sa.checkCommentC2(["medical"], "ishisotsu_shikaku_check", "ishisotsu_shikaku"), values: [new CatVal("ishisotsu_shikaku_check", path), new CatVal("ishisotsu_shikaku", path)] },
            { name: "会話言語", content: () => sa.checkCommentC2(["medical"], "ishisotsu_kaiwa_check", "ishisotsu_kaiwa"), values: [new CatVal("ishisotsu_kaiwa_check", path), new CatVal("ishisotsu_kaiwa", path)] },
            { name: "認知", content: () => sa.checkCommentC2(["medical"], "ishisotsu_ninchi_check", "ishisotsu_ninchi"), values: [new CatVal("ishisotsu_ninchi_check", path), new CatVal("ishisotsu_ninchi", path)] },
            { name: "ドラッグ・嗜好品などの使用", content: () => sa.textC(["medical"], "yakubutsu_shikouhin"), values: [new CatVal("yakubutsu_shikouhin", path)] },
            {
                name: "処方された薬剤の管理",
                content: () => (
                    <div>
                        <div>{sa.textC(["medical"], "yakuzai_kanri")}</div>
                        <div>{pat.shohosen_pdf_urls.map((url, i) => (
                            <a target='_blank' href={url}>pdf{i + 1}</a>
                        ))}</div>
                        <div>{pat.shohosen_image_urls.map((url, i) => (
                            <a target='_blank' href={url}>写真{i + 1}</a>
                        ))}</div>
                    </div>
                ),
                values: [new CatVal("yakuzai_kanri", path)]
            },
            {
                name: "日常生活自立度",
                content: () => sa.listC(["medical"], "nichijo_jiritsu", Model.Patient.NichijoJiristuScore), values: [new CatVal("nichijo_jiritsu", path)],
                helpContent: (
                    <Table>
                        <TableHead style={{ background: Colors.primaryColor }}>
                            <TableRow>
                                <TableCell style={{ color: "white", whiteSpace: 'nowrap', padding: "5px" }}>ランク</TableCell>
                                <TableCell style={{ color: "white", whiteSpace: 'nowrap', padding: "5px" }}>判定基準</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>J1</TableCell>
                                <TableCell>介助により外出し、日中はほとんどベッドから離れて生活する</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>J2</TableCell>
                                <TableCell>隣近所へなら外出する</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>A1</TableCell>
                                <TableCell>介助により外出し、日中はほとんどベッドから離れて生活する</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>A2</TableCell>
                                <TableCell>外出の頻度が少なく、日中も寝たり起きたりの生活をしている</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>B1</TableCell>
                                <TableCell>車いすに移乗し、食事、排泄はベッドから離れて行う</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>B2</TableCell>
                                <TableCell>介助により車いすに移乗する</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>C1</TableCell>
                                <TableCell>自力で寝返りをうつ</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>C2</TableCell>
                                <TableCell>自力では寝返りもうてない</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                ),
            },
            {
                name: "認知症高齢者の日常生活自立度",
                content: () => sa.listC(["medical"], "ninchi_jiritsu", Model.Patient.NinchiJiristuScore), values: [new CatVal("ninchi_jiritsu", path)],
                helpContent: (
                    <Table>
                        <TableHead style={{ background: Colors.primaryColor }}>
                            <TableRow>
                                <TableCell style={{ color: "white", whiteSpace: 'nowrap', padding: "5px" }}>ランク</TableCell>
                                <TableCell style={{ color: "white", whiteSpace: 'nowrap', padding: "5px" }}>判定基準</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Ⅰ</TableCell>
                                <TableCell>何らかの認知症を有するが、日常生活は家庭内及び社会的にほぼ自立している。</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ⅱ</TableCell>
                                <TableCell>日常生活に支障を来すような症状・行動や意志疎通の困難さが多少見られても、誰かが注意していれば自立できる。</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ⅱa</TableCell>
                                <TableCell>家庭外で上記Ⅱの状態が見られる。</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ⅱb</TableCell>
                                <TableCell>家庭内でも上記Ⅱの状態が見られる。</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ⅲ</TableCell>
                                <TableCell>日常生活に支障を来すような症状・行動や意志疎通の困難さがときどき見られ、介護を必要とする。</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ⅲa</TableCell>
                                <TableCell>日中を中心として上記Ⅲの状態が見られる。</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ⅲb</TableCell>
                                <TableCell>夜間を中心として上記Ⅲの状態が見られる。</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ⅳ</TableCell>
                                <TableCell>日常生活に支障を来すような症状・行動や意志疎通の困難さが頻繁に見られ、常に介護を必要とする。</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>M</TableCell>
                                <TableCell>著しい精神症状や問題行動あるいは重篤な身体疾患が見られ、専門医療を必要とする。</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                ),
            },
            {
                name: "ADL", content: () => <Col2Table disableBGColor={true} body={[
                    { name: "移乗", content: <div>{sa.listC(["medical", "adl"], "ijou", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "ijou_comment", "備考")}</div> },
                    { name: "移動", content: <div>{sa.listC(["medical", "adl"], "idou", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "idou_comment", "備考")}</div> },
                    { name: "階段昇降", content: <div>{sa.listC(["medical", "adl"], "kaidan", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "kaidan_comment", "備考")}</div> },
                    { name: "食事", content: <div>{sa.listC(["medical", "adl"], "shokuji", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "shokuji_comment", "備考")}</div> },
                    { name: "入浴", content: <div>{sa.listC(["medical", "adl"], "nyuyoku", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "nyuyoku_comment", "備考")}</div> },
                    { name: "トイレ", content: <div>{sa.listC(["medical", "adl"], "toilet", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "toilet_comment", "備考")}</div> },
                    { name: "排尿", content: <div>{sa.listC(["medical", "adl"], "hainyou", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "hainyou_comment", "備考")}</div> },
                    { name: "排便", content: <div>{sa.listC(["medical", "adl"], "haiben", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "haiben_comment", "備考")}</div> },
                    { name: "更衣", content: <div>{sa.listC(["medical", "adl"], "koui", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "koui_comment", "備考")}</div> },
                    { name: "整容", content: <div>{sa.listC(["medical", "adl"], "seiyou", Model.Patient.ADLScore)}{sa.textC(["medical", "adl"], "seiyou_comment", "備考")}</div> }
                ]} />
                , values: [
                    new CatVal("ijou", pathADL), new CatVal("ijou_comment", pathADL),
                    new CatVal("idou", pathADL), new CatVal("idou_comment", pathADL),
                    new CatVal("kaidan", pathADL), new CatVal("kaidan_comment", pathADL),
                    new CatVal("shokuji", pathADL), new CatVal("shokuji_comment", pathADL),
                    new CatVal("nyuyoku", pathADL), new CatVal("nyuyoku_comment", pathADL),
                    new CatVal("toilet", pathADL), new CatVal("toilet_comment", pathADL),
                    new CatVal("hainyou", pathADL), new CatVal("hainyou_comment", pathADL),
                    new CatVal("haiben", pathADL), new CatVal("haiben_comment", pathADL),
                    new CatVal("koui", pathADL), new CatVal("koui_comment", pathADL),
                    new CatVal("seiyou", pathADL), new CatVal("seiyou_comment", pathADL)
                ]
            },
            {
                name: "IADL", content: () => <Col2Table disableBGColor={true} body={[
                    { name: "電話の使用", content: <div>{sa.listC(["medical", "iadl"], "denwa", Model.Patient.IADLScore)}{sa.textC(["medical", "iadl"], "denwa_comment", "備考")}</div> },
                    { name: "家計管理", content: <div>{sa.listC(["medical", "iadl"], "kakei", Model.Patient.IADLScore)}{sa.textC(["medical", "iadl"], "kakei_comment", "備考")}</div> },
                    { name: "買い物", content: <div>{sa.listC(["medical", "iadl"], "kaimono", Model.Patient.IADLScore)}{sa.textC(["medical", "iadl"], "kaimono_comment", "備考")}</div> },
                    { name: "乗り物の利用", content: <div>{sa.listC(["medical", "iadl"], "norimono", Model.Patient.IADLScore)}{sa.textC(["medical", "iadl"], "norimono_comment", "備考")}</div> },
                    { name: "服薬管理", content: <div>{sa.listC(["medical", "iadl"], "hukuyak", Model.Patient.IADLScore)}{sa.textC(["medical", "iadl"], "hukuyak_comment", "備考")}</div> },
                    { name: "食事の準備", content: <div>{sa.listC(["medical", "iadl"], "shokuji", Model.Patient.IADLScore)}{sa.textC(["medical", "iadl"], "shokuji_comment", "備考")}</div> },
                    { name: "掃除などの家事", content: <div>{sa.listC(["medical", "iadl"], "souji", Model.Patient.IADLScore)}{sa.textC(["medical", "iadl"], "souji_comment", "備考")}</div> },
                    { name: "洗濯", content: <div>{sa.listC(["medical", "iadl"], "sentaku", Model.Patient.IADLScore)}{sa.textC(["medical", "iadl"], "sentaku_comment", "備考")}</div> }
                ]} />
                , values: [
                    new CatVal("denwa", pathIADL), new CatVal("denwa_comment", pathIADL),
                    new CatVal("kakei", pathIADL), new CatVal("kakei_comment", pathIADL),
                    new CatVal("kaimono", pathIADL), new CatVal("kaimono_comment", pathIADL),
                    new CatVal("norimono", pathIADL), new CatVal("norimono_comment", pathIADL),
                    new CatVal("hukuyak", pathIADL), new CatVal("hukuyak_comment", pathIADL),
                    new CatVal("shokuji", pathIADL), new CatVal("shokuji_comment", pathIADL),
                    new CatVal("souji", pathIADL), new CatVal("souji_comment", pathIADL),
                    new CatVal("sentaku", pathIADL), new CatVal("sentaku_comment", pathIADL)
                ]
            },
        ]
    }
}
class PatientElementIryoKigu extends PatientElement {

    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const editMode = isEditing
        const sa = new StoreAccessor(parent, editMode, "patient")
        const pat = parent.state.patient as Model.Patient.Patient;
        const path = ["patient", "saigai", 'kigus']
        const genKigu = (name, ch) => {
            return {
                name: name,
                ch: ch,
                cm: ch + "_comment"
            }
        }
        const genValues = (k) => {
            return {
                values: [new CatVal(k.ch, path), new CatVal(k.cm, path)],

            }
        }
        const genContents = (k) => {
            return {
                name: k.name,
                content: sa.checkCommentC(["saigai", "kigus"], k.ch, k.cm, "備考："),
                check: sa.getValueC(k.ch, ["saigai", "kigus"])
            }

        }

        const ks = [
            genKigu("在宅悪性腫瘍患者指導管理", "akuseishuyou"),
            genKigu("在宅気管切開患者指導管理", "kikansekkai"),
            genKigu("気管カニューレの管理", "kikan_kanyure"),
            genKigu("留置カテーテルの管理", "ryuchi_katetel"),
            genKigu("在宅自己腹膜灌流指導管理", "jikohukumaku"),
            genKigu("在宅血液透析指導管理", "ketsueki_touseki"),
            genKigu("在宅酸素療法指導管理", "sanso_ryouhou"),
            genKigu("在宅中心静脈栄養法指導管理", "chushin_joumyakuu"),
            genKigu("在宅成分栄養経管栄養法指導管理", "seibun_eiyou"),
            genKigu("在宅自己導尿指導管理", "jiko_dounyou"),
            genKigu("在宅持続陽圧呼吸療法指導管理", "jizoku_youatsu"),
            genKigu("在宅自己疼痛管理指導管理", "jiko_toutsuu"),
            genKigu("在宅肺高血圧症患者指導管理", "hai_kouatsu"),
            genKigu("人工肛門また人工膀胱留置の管理", "jinkou_koumon"),
            genKigu("真皮を超える褥瘡の状態（MPUAP分類Ⅲ度またはⅣ度、DESIGN分類D3、D4、D5）の管理", "jokuso"),
            genKigu("点滴注射を週3日以上の管理", "tenteki"),
            genKigu("在宅人工呼吸指導管理", "jinkou_kokyu")
        ]

        this.elements = [
            {
                name: "",
                content: () => {
                    const t = ks.map(k => genContents(k)).filter(x => editMode || x.check)
                    return <Col2Table disableBGColor={false} body={t as any} />
                },
                values: ([] as CatVal[]).concat.apply([], ks.map(k => genValues(k).values))
            }
        ]
    }
}
/*
class PatientElementHoken extends PatientElement {

    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const editMode = isEditing
        const sa = new StoreAccessor(parent, editMode, "patient")
        const saDocTemp = sa.generateChild(["doc_temp"])
        const pat = parent.state.patient;
        const pathHokenImage = ["patient", "doc_temp"]
        this.elements = [
            { name: "介護保険証", content: () => saDocTemp.hoken(2, "doc_kaigo", pat.id), values: this.genCatValForDialog("doc_kaigo", pathHokenImage) },
            { name: "介護保険負担割合証", content: () => saDocTemp.hoken(3, "doc_kaigohutan", pat.id), values: this.genCatValForDialog("doc_kaigohutan", pathHokenImage) },
            { name: "医療保険証", content: () => saDocTemp.hoken(4, "doc_iryo", pat.id), values: this.genCatValForDialog("doc_iryo", pathHokenImage) },
            { name: "限度額適用証", content: () => saDocTemp.hoken(5, "doc_gendogaku", pat.id), values: this.genCatValForDialog("doc_gendogaku", pathHokenImage) },
            { name: "公費受給者証", content: () => saDocTemp.hoken(6, "doc_kouhi", pat.id), values: this.genCatValForDialog("doc_kouhi", pathHokenImage) }
        ]
    }
}*/
class PatientElementNote extends PatientElement {

    protected splitPositions(): number[] {
        return this.props.parent.state.patient.doc_temp.doc_img_free_note.map((x, i) => i)
    }
    note_length = 0

    shouldComponentUpdate(np, ns): boolean {
        if (np.parent.state.patient && np.parent.state.patient.doc_temp) {
            if (this.note_length != np.parent.state.patient.doc_temp.doc_img_free_note.length) {
                this.note_length = np.parent.state.patient.doc_temp.doc_img_free_note.length
                this.buildElements(np.isEditing)
            }
        }
        return super.shouldComponentUpdate(np, ns)
    }
    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const editMode = isEditing
        const sa = new StoreAccessor(parent, editMode, "patient")
        const saDocTemp = sa.generateChild(["doc_temp"])
        const pat = parent.state.patient;

        if (!pat || !pat.doc_temp) {
            this.elements = []
            return;
        }

        this.elements = [
            ...pat.doc_temp.doc_img_free_note.map((doc, idx) => {
                return {
                    name: "", content: () => <div>
                        {saDocTemp.docC(["doc_img_free_note"], idx, 8, pat.id)}
                    </div>,
                    values: () => [].concat.apply([], [
                        this.genCatValForDialog("doc_img_free_note", ["patient", "doc_temp"]),
                        ...sa.getValueC("doc_img_free_note", ["doc_temp"]).map((x, i) => this.genCatValForDialog(i, ["patient", "doc_temp", "doc_img_free_note"], ["doc_img_free_note"]))
                    ])
                }
            }),
            {
                name: "",
                content: () => <div style={{ marginTop: "15px" }}>
                    {
                        editMode ?
                            <Button variant="outlined" size="medium" color="primary"
                                onClick={(e) => {
                                    if (!pat || !pat.doc_temp) return;
                                    pat.doc_temp.doc_img_free_note.push(new Model.Document.Document());
                                    this.setState({ patient: pat })
                                }}>
                                ＋追加
                            </Button>
                            : null
                    }
                </div>,
                values: () => [].concat.apply([], [
                    this.genCatValForDialog("doc_img_free_note", ["patient", "doc_temp"]),
                    ...sa.getValueC("doc_img_free_note", ["doc_temp"]).map((x, i) => this.genCatValForDialog(i, ["patient", "doc_temp", "doc_img_free_note"], ["doc_img_free_note"]))
                ])
            }
        ]
    }
}
class PatientElementJimu extends PatientElement {

    protected splitPositions(): number[] {
        return [23]
    }
    public buildElements(isEditing) {
        const { classes, parent } = this.props;
        const editMode = isEditing
        const sa = new StoreAccessor(parent, editMode, "patient")
        const saDocTemp = sa.generateChild(["doc_temp"])
        const pat = parent.state.patient;
        const path = ["patient", "hoken"]
        const pathBasic = ["patient", "basic"]
        const pathSocial = ["patient", "social"]
        const pathKasan = ["patient", "basic", "kasan"]
        const hoken_kubun_list = [
            { key: "", value: 0 },
            { key: "社保", value: 1 },
            { key: "国保", value: 2 },
            { key: "公費", value: 3 },
            { key: "後期高齢者", value: 4 }
        ]
        const ht = pat.hoken ? pat.hoken.hoken_type : null
        const pathHoken = ["patient", "hoken"]

        const pathHokenImage = ["patient", "doc_temp"]

        this.elements = [
            { name: "事務の特記事項", content: () => sa.textC(["basic"], "jimu_tokki"), values: [new CatVal("jimu_tokki", pathBasic)] },
            {
                name: "ステータス", content: () => <div>
                    <EditableList
                        label=""
                        editMode={editMode}
                        name={"status"}
                        list={DateUtil.enumToArray(Model.Patient.StatusEnum)}
                        value={parent.getPatValue("status", [])}
                        setData={(n, v) => parent.setPatientStatus(v)} />
                    {
                        sa.getValueC("status", []) == Model.Patient.StatusEnum.End ?
                            <div>
                                <div>日付：{DateUtil.toJpDateString(sa.getValueC("ended_at", []))}</div>
                                <div>理由：{sa.getValueC("end_reason", ["basic"])}</div>
                                <div>備考：{sa.getValueC("end_comment", ["basic"])}</div>
                            </div>
                            : null
                    }
                </div>
                , values: [new CatVal("status"),
                new CatVal("end_reason", pathBasic),
                new CatVal("end_comment", pathBasic)]
            },
            { name: "終了しても計画書一覧等には表示", content: () => sa.check("is_visible"), values: [new CatVal("is_visible")] },
            {
                name: "基本となる保険", content: () => sa.listC(["hoken"], "hoken_type", Model.Patient.HokenType)
                , values: [new CatVal("hoken_type", pathHoken)]
            },
            {
                name: "基本となるコード", content: () => sa.serviceCodeC([], "service_code",
                    !pat.is_teiki_junkai && ht == Model.Patient.HokenType.Kaigo,
                    !pat.is_teiki_junkai && ht == Model.Patient.HokenType.KaigoYobo,
                    ht == Model.Patient.HokenType.Iryo || ht == Model.Patient.HokenType.Seishin,
                    pat.is_teiki_junkai
                )
                , values: this.genCatValForDialog("service_code")
            },
            {
                name: "他システム連携URL", content: () => sa.textC(["basic"], "other_system_url")
                , values: [new CatVal("other_system_url", ["patient", "basic"])]
            },
            { name: "レセコンID", content: () => sa.text("wiseman_id", "", "text", "block", false), values: [new CatVal("wiseman_id")] },

            { name: "介護度", content: () => sa.list("youkaigo", Model.Patient.YouKaigoType), values: [new CatVal("youkaigo")] },
            { name: "疾患分類", content: () => sa.listC(["basic"], "shikkan_type", Model.Patient.ShikkanType), values: [new CatVal("shikkan_type", pathBasic)] },

            { name: "本人属性", content: () => sa.listC(["basic"], "zokusei", Model.Patient.ZokuseiType), values: [new CatVal("zokusei", pathBasic)] },
            { name: "高齢世帯", content: () => sa.checkC(["social"], "is_kourei"), values: [new CatVal("is_kourei", pathSocial)] },
            { name: "一人暮らし", content: () => sa.checkC(["social"], "is_hitori"), values: [new CatVal("is_hitori", pathSocial)] },
            /*
            {
                name: "同一建物", content: () => {
                    return <div>
                    {
                        sa.getValueC("same_buildings", []).filter(x => x).map((o, i) => {
                            return <div key={`sb_${i}`}>
                                {i > 0 ? <hr /> : null}
                                {sa.patientC(["same_buildings"], i, "kk")}
                            </div>
                        })
                    }
                    {
                        editMode ?
                            <Button variant="outlined" size="small" color="primary"
                                onClick={(e) => {
                                    const t: Model.Patient.PatientSimple[] = sa.getValueC("same_buildings", []).filter(x => x)
                                    t.push(new Model.Patient.PatientSimple());
                                    sa.setValueC("same_buildings", t, [])
                                }}>
                                ＋追加
                            </Button>
                            : null
                    }
                    </div>
                }
                , values: () => [].concat.apply([], [
                    new CatVal("same_buildings", ["patient"]),
                    ...sa.getValueC("same_buildings", []).map((x, i) => this.genCatValForDialog(i, ["patient"], ["same_buildings"]))
                ])
            },*/
            { name: "定期巡回", content: () => sa.check("is_teiki_junkai"), values: [new CatVal("is_teiki_junkai")] },
            { name: "定期巡回日割", content: () => sa.checkC(["basic"], "is_hiwari"), values: [new CatVal("is_hiwari", pathBasic)] },
            { name: "在宅がん医療総合診療料", content: () => sa.checkC(["basic"], "zaitaku_gan"), values: [new CatVal("zaitaku_gan", path)] },
            { name: "超重症児", content: () => sa.checkC(["basic"], "chou_jushou_ji"), values: [new CatVal("chou_jushou_ji", path)] },
            { name: "準超重症児", content: () => sa.checkC(["basic"], "jushou_ji"), values: [new CatVal("jushou_ji", path)] },
            { name: "介護保険証(画像)", content: () => saDocTemp.hoken(2, "doc_kaigo", pat.id), values: this.genCatValForDialog("doc_kaigo", pathHokenImage) },
            { name: "介護保険負担割合証(画像)", content: () => saDocTemp.hoken(3, "doc_kaigohutan", pat.id), values: this.genCatValForDialog("doc_kaigohutan", pathHokenImage) },
            { name: "介護保険者番号", content: () => sa.textC(["hoken"], "kaigo_hokenbangou", "", "text", "block", false), values: [new CatVal("kaigo_hokenbangou", path)] },
            { name: "介護被保険者番号", content: () => sa.textC(["hoken"], "kaigo_hihoban", "", "text", "block", false), values: [new CatVal("kaigo_hihoban", path)] },
            { name: "医療保険証(画像)", content: () => saDocTemp.hoken(4, "doc_iryo", pat.id), values: this.genCatValForDialog("doc_iryo", pathHokenImage) },
            { name: "限度額適用証(画像)", content: () => saDocTemp.hoken(5, "doc_gendogaku", pat.id), values: this.genCatValForDialog("doc_gendogaku", pathHokenImage) },
            { name: "公費受給者証(画像)", content: () => saDocTemp.hoken(6, "doc_kouhi", pat.id), values: this.genCatValForDialog("doc_kouhi", pathHokenImage) },
            { name: "療養保険区分", content: () => sa.listRawC(["hoken"], "ryouyou_kubun", hoken_kubun_list), values: [new CatVal("ryouyou_kubun", path)] },
            { name: "療養保険者番号(公費負担者番号)", content: () => sa.textC(["hoken"], "ryouyou_bango", "", "text", "block", false), values: [new CatVal("ryouyou_bango", path)] },
            { name: "療養記号番号(公費受給者番号）", content: () => sa.textC(["hoken"], "ryouyou_kigou", "", "text", "block", false), values: [new CatVal("ryouyou_kigou", path)] },
            { name: "保険証枝番", content: () => sa.textC(["hoken"], "ryouyou_edaban", "", "text", "block", false), values: [new CatVal("ryouyou_edaban", path)] },
            {
                name: "加算(医療)", content: () =>
                    sa.checkListC(["basic", "kasan"], [
                        // 医療
                        //{ key: "is_nanbyou_hukusuu_kai", label: "難病等複数回訪問加算" },
                        { key: "is_tokubetsu_kanri_1", label: "特別管理加算(5000)" },
                        { key: "is_tokubetsu_kanri_2", label: "特別管理加算(2500)" },
                        { key: "is_zaitaku_renkei", label: "在宅患者連携指導加算" },
                        { key: "is_renkei_kyouka", label: "看護・介護職員連携強化加算" },
                        { key: "is_dx_kasan", label: "訪問看護医療DX情報活用加算" },
                        { key: "is_tokubetsu_chiiki", label: "特別地域訪問看護加算" },
                        { key: "is_24h_taisei_i", label: "２４時間対応体制加算イ" },
                        { key: "is_24h_taisei", label: "２４時間対応体制加算イ以外" },
                        { key: "is_seishin_juushou_shien_1", label: "精神科重症患者支援管理連携加算１" },
                        { key: "is_seishin_juushou_shien_2", label: "精神科重症患者支援管理連携加算２" }
                    ], true, false, true)
                , values: [
                    //new CatVal("is_nanbyou_hukusuu_kai", pathKasan),
                    new CatVal("is_tokubetsu_kanri_1", pathKasan),
                    new CatVal("is_tokubetsu_kanri_2", pathKasan),
                    new CatVal("is_zaitaku_renkei", pathKasan),
                    new CatVal("is_renkei_kyouka", pathKasan),
                    new CatVal("is_dx_kasan", pathKasan),
                    new CatVal("is_tokubetsu_chiiki", pathKasan),
                    new CatVal("is_24h_taisei", pathKasan),
                    new CatVal("is_24h_taisei_i", pathKasan),
                    new CatVal("is_24h_taisei_not_i", pathKasan),
                    new CatVal("is_seishin_juushou_shien_1", pathKasan),
                    new CatVal("is_seishin_juushou_shien_2", pathKasan)
                ]
            },
            {
                name: "加算(介護）", content: () =>
                    sa.checkListC(["basic", "kasan"], [
                        // 介護
                        { key: "is_douitsu_tatemono_gensan_1", label: "訪問看護同一建物減算１" },
                        { key: "is_douitsu_tatemono_gensan_2", label: "訪問看護同一建物減算２" },
                        { key: "is_chuuzankan_chiiki_1", label: "訪問看護中山間地域等提供加算１" },
                        { key: "is_chuuzankan_chiiki_2", label: "訪問看護中山間地域等提供加算２" },
                        { key: "is_kinkyuji_houmon_kango1_1", label: "緊急時訪問看護加算I１" },
                        { key: "is_kinkyuji_houmon_kango1_2", label: "緊急時訪問看護加算I２" },
                        { key: "is_kinkyuji_houmon_kango2_1", label: "緊急時訪問看護加算II１" },
                        { key: "is_kinkyuji_houmon_kango2_2", label: "緊急時訪問看護加算II２" },
                        { key: "is_houmon_kanri_1", label: "訪問看護特別管理加算Ⅰ" },
                        { key: "is_houmon_kanri_2", label: "訪問看護特別管理加算II" },
                        { key: "is_houmon_senmon_kanri_1", label: "訪問看護専門管理加算1" },
                        { key: "is_houmon_senmon_kanri_2", label: "訪問看護専門管理加算2" },
                        { key: "is_koukuu_kyouka", label: "訪問看護口腔連携強化加算" },
                        //{ key: "is_tokubetsu_chiiki_2div", label: "特別地域訪問看護加算２日割" },
                        //{ key: "is_shoukibo_jigyousho_2div", label: "訪問看護小規模事業所加算２日割" },
                        //{ key: "is_chuuzankan_chiiki_2div", label: "訪問看護中山間地域等加算２日割" },
                    ], true, false, true)
                , values: [
                    //new CatVal("is_nanbyou_hukusuu_kai", pathKasan),
                    new CatVal("is_douitsu_tatemono_gensan_1", pathKasan),
                    new CatVal("is_douitsu_tatemono_gensan_2", pathKasan),
                    new CatVal("is_chuuzankan_chiiki_1", pathKasan),
                    new CatVal("is_chuuzankan_chiiki_2", pathKasan),
                    new CatVal("is_renkei_kyouka", pathKasan),
                    new CatVal("is_kinkyuji_houmon_kango1_1", pathKasan),
                    new CatVal("is_kinkyuji_houmon_kango1_2", pathKasan),
                    new CatVal("is_kinkyuji_houmon_kango2_1", pathKasan),
                    new CatVal("is_kinkyuji_houmon_kango2_2", pathKasan),
                    new CatVal("is_houmon_kanri_1", pathKasan),
                    new CatVal("is_houmon_kanri_2", pathKasan),
                    new CatVal("is_houmon_senmon_kanri_1", pathKasan),
                    new CatVal("is_houmon_senmon_kanri_2", pathKasan),
                    new CatVal("is_koukuu_kyouka", pathKasan)
                ]
            },
        ]
    }
}

interface ActivityStore {
    activityTab: number,
    problemList: Model.Problem[],
    simpleUsers: Model.SimpleUser[],
    //taskList: Model.Task[],
    taskList: any[],
    taskTarget?: any,
    showDoneTask: boolean,
    openAddMenu: boolean,
    notifyDocChat: boolean
}

interface ActivityProps extends Styles.WithStyles {
    me: Model.User;
    parent: any,
    patient: Model.Patient.Patient
    commentList: Model.Comment.Comment[],
    kirokuList: HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>
    loadingActivity: boolean
    docChatList: HokanApi.PagingResponse<Model.Document.DocumentChat>
    notifyComment: boolean
    notifyDocChat: boolean
}

class ActivityComponent extends React.Component<ActivityProps, ActivityStore> {
    constructor(props) {
        super(props)
        this.state = {
            activityTab: 0,
            problemList: [],
            simpleUsers: [],
            taskList: [],
            taskTarget: undefined,
            showDoneTask: false,
            openAddMenu: false,
            notifyDocChat: false
        }
    }

    componentDidMount() {
        HokanApi.getProblems()
            .then(x => {
                this.setState({ problemList: x.data })
                this.loadTasks();
            })
            .catch(err => {
                console.log(err)
            })
        HokanApi.getUsersSimple().then(x => {
            this.setState({ simpleUsers: x.data })
        }).catch(err => {
            console.log(err)
        })
        const pas = JSON.parse(localStorage.getItem("pat_activity_shown") || "{}")
        pas[this.props.patient.id] = new Date().toISOString()
        localStorage.setItem("pat_activity_shown", JSON.stringify(pas))
    }

    public loadTasks() {
        const { patient } = this.props
        const patient_id = patient.id
        HokanApi.message.task_list_wc(patient_id)
            .then(x => {
                this.setState({
                    taskList: x.data
                })
                const dateTasks = this.state.taskList.filter(x => !x.done).filter(t => t.task_date != "")
                const maybeTask = dateTasks.find(t => {
                    let today = new Date().getTime();
                    let taskTime = new Date(t.task_date).getTime();
                    console.log(today);
                    console.log(taskTime);
                    return today >= taskTime
                })
                if (maybeTask !== undefined) {
                    this.setState({ notifyDocChat: true });
                }
                else {
                    this.setState({ notifyDocChat: false });
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    taskList: []
                })
            })
    }

    private taskDialogDelete() {
        const { patient } = this.props
        const { taskTarget } = this.state
        if (!taskTarget || !patient) return;
        HokanApi.message.delete_message(patient.id, taskTarget.id).then(x => {
            this.taskDialogClose();
            this.loadTasks();
        })
    }

    private taskDialogClose() {
        this.setState({ taskTarget: undefined })
    }

    private taskDialogSave() {
        const { patient } = this.props
        const { taskTarget } = this.state
        if (!taskTarget || !patient) return;
        if (taskTarget.id == 0) {
            HokanApi.postTask(patient.id, taskTarget).then(x => {
                this.taskDialogClose();
                this.loadTasks();
            });
        }
        else {

            let updateTask = new Model.UpdateTask()
            updateTask.status = taskTarget.done
            updateTask.content = taskTarget.content
            updateTask.task_date = taskTarget.task_date
            HokanApi.message.update_task(patient.id, taskTarget.id, updateTask).then(x => {
                this.taskDialogClose();
                this.loadTasks();
            })
        }
    }
    private taskToggle(task: Model.Task) {
        const { patient } = this.props
        if (!patient) return;

        let status = false
        if (task.done) {
            task.done = false
        }
        else {
            task.done = true;
            status = true
        }
        let updateTask = new Model.UpdateTask()
        updateTask.status = status
        updateTask.content = task.content
        updateTask.task_date = task.task_date
        HokanApi.message.update_task(patient.id, task.id, updateTask).then(x => {
            this.loadTasks();
        })
    }

    private setDialogText(name, value) {
        const target = this.state.taskTarget as any;
        if (!target) return;
        target[name] = value;
        this.setState({ taskTarget: target })
    }




    public loadComments() {
        this.props.parent.loadComments();
    }
    public loadDocChat(q: any = null) {
        this.props.parent.loadDocChat(null, null, q);
    }
    public create_comment_dom(message: string, uidStr: string) {
        let found = false;
        const r = message.replace(/@\[(.*?)\]\(user:([\-0-9]*?)\)/g, (str, ...args: string[]) => {
            if (uidStr == args[1] || args[1] == "-1" || args[1] == "-2") found = true
            return `<span  style="background-color:#d2e6ff;color:#001781;">@${args[0]} </span>`
        }).replace(/\\n/g, "\n")

        return <div dangerouslySetInnerHTML={{ __html: r }} />
    }
    public create_comment_dom_plane(message: string) {
        const r = message.replace(/\\n/g, "\n")

        return <div dangerouslySetInnerHTML={{ __html: r }} />
    }
    public taskPanel = (isActive: boolean) => {
        const { patient } = this.props
        const { showDoneTask } = this.state
        const { classes } = this.props;
        const openAddMenu = this.state.openAddMenu;
        const target = this.state.taskTarget;
        //const tasks = (showDoneTask ? this.state.taskList : this.state.taskList.filter(x => x.done_time == null))
        const tasks = (showDoneTask ? this.state.taskList : this.state.taskList.filter(x => !x.done))

        const cdStr = DateUtil.currentDateStr()
        const sa = new StoreAccessor(this, true)
        return <div className={classes.childRoot} style={{ paddingBottom: "0px" }}>
            <div className={classes.activity_task_table}>
                <StandardTable title="">
                    <List className={classes.activity_task_list}>
                        {
                            tasks.map((task, i) => {
                                let today = new Date().getTime();
                                let taskTime = new Date(task.task_date).getTime();
                                let hasExpired = false
                                if (task.task_date != "" && today >= taskTime) {
                                    hasExpired = true;
                                }
                                return <ListItem key={`taks_${i}`}
                                    className={classes.activity_task_item}
                                    style={{
                                        borderWidth: "1px", borderRadius: "0px", zIndex: (hasExpired ? 2 : undefined),
                                        borderColor: hasExpired ? Colors.orangeColor : "#e0e0e0",
                                        backgroundColor: hasExpired ? Colors.orangeColorTransparent : undefined,
                                        borderLeft: "none",
                                        borderRight: "none",
                                    }}
                                >
                                    <Checkbox
                                        checked={task.done}
                                        color="primary"
                                        onChange={(e) => {
                                            this.taskToggle(task)
                                        }}
                                    />
                                    <div className={classes.activity_task_item_text}>
                                        {this.create_comment_dom(task.content, this.props.me.id.toString())}
                                    </div>
                                    <div style={{
                                        fontWeight: "normal",
                                        minWidth: "90px", marginRight: "-5px", textAlign: "right"
                                    }}>
                                        {
                                            task.task_date ?
                                                task.task_date
                                                : "期限なし"
                                        }
                                    </div>
                                    <Button style={{ marginLeft: "auto", border: "none", marginRight: "-10px" }}
                                        variant="outlined" size="small" color="default"
                                        aria-label="Edit" className={classes.fab}
                                        onClick={(e) =>
                                            this.setState({ taskTarget: { ...task } })}
                                    >
                                        <Icons.Edit />
                                    </Button>
                                </ListItem>
                            }
                            )
                        }
                    </List>

                    <Button color="default" variant="outlined" className={classes.filter_button_d}
                        style={{ margin: "5px 0px" }}
                        onClick={(e) => { this.setState({ showDoneTask: !showDoneTask }) }}
                    >
                        {showDoneTask ? "終了済を隠す" : "終了済を表示"}
                    </Button>
                </StandardTable>
            </div>
            <SimpleDialog
                title={(target && target.id == 0) ? "タスク作成" : "タスク編集"}
                open={target != null}
                onDelete={() => this.taskDialogDelete()}
                onCancel={() => this.taskDialogClose()}
                onOk={() => this.taskDialogSave()}
                hideDelete={(target && target.id == 0)}
            >
                <Grid container spacing={1} className={classes.grid}>
                    <Grid item xs={12}>
                        {
                            <TextField
                                id="outlined-number"
                                label={"内容"}
                                value={target ? target.content : ""}
                                onChange={(e) => this.setDialogText("content", e.target.value)}
                                className={classes.textField}
                                fullWidth={true}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    className: classes.input,
                                }}
                                multiline={true}
                                margin="none"
                                variant="outlined"
                                rows={3}
                            />
                        }
                    </Grid>
                    <InputText title="期限" inputType="date" value={target ? (target.task_date ? target.task_date : "") : ""}
                        onChange={(s) => {
                            if (s) {
                                this.setDialogText("task_date", s)
                            }
                            else {
                                this.setDialogText("task_date", null)
                            }
                        }} />
                </Grid>
            </SimpleDialog>
        </div >
    }


    public render() {
        const { classes } = this.props
        const docPaging = <Pagination
            limit={this.props.docChatList.per}
            offset={(this.props.docChatList.page - 1) * this.props.docChatList.per}
            total={this.props.docChatList.count}
            onClick={(e, offset) => {
                this.props.parent.loadDocChat(offset / this.props.docChatList.per, this.props.docChatList.per)
            }}
        />
        if (this.state.activityTab == 1 && this.props.notifyComment) {
            this.props.parent.resetNotifyComment()
            const pas = JSON.parse(localStorage.getItem("pat_activity_comment_shown") || "{}")
            pas[this.props.patient.id] = new Date().toISOString()
            localStorage.setItem("pat_activity_comment_shown", JSON.stringify(pas))
        }

        if (this.state.activityTab == 2 && this.props.notifyDocChat) {
            this.props.parent.resetNotifyDocChat()
            const pas = JSON.parse(localStorage.getItem("pat_activity_doc_chat_shown") || "{}")
            pas[this.props.patient.id] = new Date().toISOString()
            localStorage.setItem("pat_activity_doc_chat_shown", JSON.stringify(pas))
        }
        return <div>
            <Paper>
                <Tabs value={0}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab
                        label={<span className={classes.tabTextSub}>
                            タスク
                        </span>} />
                </Tabs>
            </Paper>
            {this.taskPanel(true)}
            <Paper>
                <Tabs value={this.state.activityTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, value) => { this.loadTasks(); this.setState({ activityTab: value }) }}>
                    <Tab
                        label={<span className={classes.tabTextSub}>
                            トーク
                        </span>} />
                    <Tab
                        label={<span className={classes.tabTextSub}>記録</span>} />
                    <Tab
                        label={<span className={classes.tabTextSub}>
                            書類
                            {this.props.notifyDocChat ? <span style={{ color: "red" }}>●</span> : null}
                        </span>} />
                </Tabs>
            </Paper>
            <div style={{ marginTop: "0px" }}>
                {
                    <div style={{ display: this.state.activityTab == 0 ? "block" : "none" }}>
                        <Message
                            is_shinki={false}
                            onUpdate={() => this.loadTasks()}
                            message_id={0}
                            is_wc={true}
                            wc_name={this.props.patient.name}
                            wc_number={this.props.patient.id}
                            wc_branch_id={this.props.patient.branch_id} />
                        <div style={{ display: "table-cell" }}>
                            <MessageUserList message_id={0} is_wc={true} wc_name={this.props.patient.name} wc_number={this.props.patient.id} />
                        </div>
                    </div>
                }
                {
                    <div style={{ display: this.state.activityTab == 1 ? "block" : "none" }}>
                        {
                            this.props.loadingActivity ?
                                <Loading /> :
                                <ActivityKirokuComponent parent={this.props.parent}
                                    kirokuList={this.props.kirokuList}
                                    classes={this.props.classes}
                                    problemList={this.state.problemList}
                                />
                        }
                    </div>
                }
                {
                    < div style={{ display: this.state.activityTab == 2 ? "block" : "none" }}>
                        <div>
                            <DocumentList2
                                patientId={this.props.patient ? this.props.patient.id : undefined}
                                onSearch={(q) => {
                                    this.loadDocChat(q)
                                    this.loadTasks()
                                }} />
                        </div>
                        {docPaging}
                        {
                            this.props.docChatList.data.map((x, i) => {
                                return <div key={`dc_${i}`}>
                                    <ActivityDocComponent me={this.props.me}
                                        doc={x}
                                        onDelete={() => { this.loadDocChat() }}
                                        onUpdate={() => { this.loadDocChat() }}
                                        classes={this.props.classes}
                                        parent={this}
                                        simpleUsers={this.state.simpleUsers}
                                        patient={this.props.patient} />
                                </div>
                            })
                        }
                        {docPaging}
                    </div>
                }
            </div>
        </div>
    }
}

interface ActivityCommentProps extends Styles.WithStyles<typeof styles> {
    me: Model.User;
    parent: any;
    comment: Model.Comment.Comment;
    patient: Model.Patient.Patient;
    simpleUsers: Model.SimpleUser[]
}
interface ActivityCommentState {
    message: string
    sending: boolean
    errorMessage: string
    image_urls: string[]
    images: File[]
    mentionInput: string
    autoCompleteUsers: { id: number, display: string }[]
}

const defaultStyle = {
    control: {
        backgroundColor: '#fff',

        fontSize: 14,
        fontWeight: 'normal',
    },

    highlighter: {
        overflow: 'hidden',
    },

    input: {
        margin: 0,
        overflow: 'auto',
        height: 70,
    },

    '&singleLine': {
        control: {
            display: 'inline-block',

            width: 130,
        },

        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },

        input: {
            padding: 1,

            border: '2px inset',
        },
    },

    '&multiLine': {
        control: {
            fontFamily: 'monospace',
            border: '1px solid silver',
        },

        highlighter: {
            padding: 9,
        },

        input: {
            padding: 9,
            minHeight: 63,
            outline: 0,
            border: 0,
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    }
}
class ActivityCommentComponent extends React.Component<ActivityCommentProps, ActivityCommentState> {
    constructor(props) {
        super(props)
        this.state = {
            message: "",
            sending: false,
            errorMessage: "",
            images: [],
            image_urls: [],
            mentionInput: "",
            autoCompleteUsers: []
        }
    }

    public componentDidMount() {
        this.setState({
            autoCompleteUsers: this.props.simpleUsers.map(x => {
                return {
                    id: x.id,
                    display: x.name
                }
            })
        })
    }


    public commit() {
        const postData = new Model.Comment.CommentChildPost()
        postData.message = this.state.message
        postData.images = this.state.images
        HokanApi.postCommentChild(this.props.patient.id, this.props.comment.id, postData).then(res => {
            this.props.parent.loadComments()
            const me = this.props.me
            const pat = this.props.patient
            if (me && me.branch && pat) {
                send_to_firebase(me, pat, this.state.message, "talk_child",
                    this.props.simpleUsers.filter(x => x.branch_id == me.branch_id),
                    this.props.simpleUsers)
            }
            this.setState({
                sending: false,
                message: "",
                errorMessage: "",
                images: [],
                image_urls: [],
            })
        }).catch(err => {
            this.setState({ sending: false, errorMessage: "エラー" })
            console.log(err)
        })
    }

    public render() {
        if (!this.props.me.branch) return <div />
        const { comment, classes } = this.props
        const sa = new StoreAccessor(this, true)
        const cid = this.props.me.branch.company_id
        return <div className={classes.activity}>
            {
                comment.isNew ?
                    <div className={classes.activity_new}>
                        新着
                    </div>
                    : null
            }
            {
                comment.comment_type == "MessageEm" ?
                    <div className={classes.emerge}>
                        緊急コール
                    </div>
                    :
                    null
            }
            <AvatarFace classes={classes} cid={cid} user={comment.created_by} />
            <div className={classes.activity_summary}>
                <div><span className={classes.bold}>{comment.created_by.name}</span>:{DateUtil.toJpDateTimeString(comment.created_at)}</div>
                <div className={classes.chat_message}>
                    {comment.message_dom}
                </div>
                <div >
                    {
                        comment.image_urls.map((url, idx) => <div key={`img_${idx}`} className={classes.chat_image} >
                            <a href={url} target="_blank"><img src={url} width="100px" /></a>
                        </div>)
                    }
                </div>
            </div>
            <div style={{ marginLeft: "20px" }}>
                {comment.children.map(c => {
                    return <div>
                        {
                            c.isNew ?
                                <div className={classes.activity_new}>
                                    新着
                                </div>
                                : null
                        }
                        <AvatarFace classes={classes} cid={cid} user={c.created_by} />
                        <div className={classes.activity_summary}>
                            <div><span className={classes.bold}>{c.created_by.name}</span>:{DateUtil.toJpDateTimeString(c.created_at)}</div>
                            <div className={classes.chat_message}>
                                {c.message_dom}
                            </div>
                            <div >
                                {
                                    c.image_urls.map((url, idx) => <div key={`img_${idx}`} className={classes.chat_image} >
                                        <a href={url} target="_blank"><img src={url} width="100px" /></a>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                })}
                <br />
                <div>
                    <div>
                        <MentionsInput
                            value={this.state.message}
                            onChange={(event, newValue, newPlainTextValue, mentions) => {
                                this.setState({ message: newValue })
                            }}
                            placeholder={"@でメンションができます"}
                            allowSpaceInQuery={true}
                            style={defaultStyle}
                            displayTransform={(id, display) => `@${display}`}
                        >
                            <Mention
                                markup="@[__display__](user:__id__)"
                                trigger="@"
                                data={this.state.autoCompleteUsers}
                                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                    <div>{highlightedDisplay}</div>
                                )}
                                onAdd={e => console.log(e)}
                                style={{
                                    backgroundColor: '#cee4e5',
                                }}
                            />
                        </MentionsInput>
                    </div>
                    <div>
                        <Button color="default" size="small" variant="outlined"
                            style={{ margin: "5px" }}
                            onClick={(e) => {
                                this.setState({ message: this.state.message + " @" })
                            }}
                        >@</Button>
                        <Button color="default" size="small" variant="outlined"
                            style={{ margin: "5px" }}
                            onClick={(e) => {
                                this.setState({ message: this.state.message + " @[事業所全体](user:-1) " })
                            }}
                        >@事業所</Button>
                        <Button color="default" size="small" variant="outlined"
                            style={{ margin: "5px" }}
                            onClick={(e) => {
                                this.setState({ message: this.state.message + " @[法人全体](user:-2) " })
                            }}
                        >@法人全体</Button>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <div>
                            {
                                this.state.image_urls.map((url, idx) => <div key={`img_${idx}`} className={classes.chat_image}>
                                    <div>
                                        <img src={url} width="100px" />
                                    </div>
                                    <Button color="secondary" size="small" variant="outlined"
                                        onClick={(e) => {
                                            const u = this.state.image_urls.filter((x, i) => i != idx)
                                            const f = this.state.images.filter((x, i) => i != idx)
                                            this.setState({ image_urls: u, images: f })
                                        }}
                                    >削除</Button>
                                </div>)
                            }
                        </div>
                        <div>
                            {
                                this.state.image_urls.length < 5 ?
                                    <input type="file" id="" ref="file" accept="image/*"
                                        onChange={(e) => {
                                            const selectedFiles = e.target.files
                                            if (selectedFiles && selectedFiles.length > 0) {
                                                const item = selectedFiles[0]
                                                const len = selectedFiles.length
                                                const u = this.state.image_urls
                                                const f = this.state.images
                                                Resizer.imageFileResizer(
                                                    item,
                                                    1024, 1024,
                                                    'JPEG',
                                                    100, 0,
                                                    res => {
                                                        f.push(new File([res], item.name));
                                                        u.push(createObjectURL(res))
                                                        this.setState({ image_urls: u, images: f })
                                                    },
                                                    'blob'
                                                );
                                            }
                                        }} />
                                    : <div>これ以上追加できません</div>
                            }</div>
                        <div>
                            <Button color="primary" variant="contained"
                                disabled={this.state.sending || (this.state.message == "" && this.state.images.length == 0)}
                                onClick={(e) => {
                                    this.commit()
                                }} >{this.state.sending ? "送信中" : "送信"}</Button>
                            <div>{this.state.errorMessage}</div>
                        </div>
                    </div >
                </div>
            </div>
        </div >
    }
}

interface AvatarFaceProps extends Styles.WithStyles<typeof styles> {
    cid: number,
    user: Model.User
}
interface AvatarFaceState {
    url: string
}

class AvatarFace extends React.PureComponent<AvatarFaceProps, AvatarFaceState> {
    constructor(props) {
        super(props)
        this.state = {
            url: "none"
        }
    }

    componentDidMount() {
        AvatarStore.getUrl(this.props.cid, this.props.user.id).then(url => {
            this.setState({ url: url })
        })
    }

    public render() {
        const { classes } = this.props
        return <Avatar className={classes.activity_icon}
            src={this.state.url == "none" ? undefined : this.state.url}
            style={{
                color: '#fff',
                backgroundColor: `hsl(${this.props.user.id * 130 % 360}, 50%, 80%)`,
                textAlign: "center"
            }}>{this.state.url == "none" ? this.props.user.name.substr(0, 2) : null}</Avatar>
    }
}


interface ActivityKirokuProps extends Styles.WithStyles<typeof styles> {
    parent: any,
    kirokuList: HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>,
    problemList: Model.Problem[]
}

class ActivityKirokuComponent extends React.Component<ActivityKirokuProps> {
    public render() {
        const { classes } = this.props
        const paging = <Pagination
            limit={this.props.kirokuList.per}
            offset={(this.props.kirokuList.page - 1) * this.props.kirokuList.per}
            total={this.props.kirokuList.count}
            onClick={(e, offset) => {
                this.props.parent.loadKirokus(offset / this.props.kirokuList.per, this.props.kirokuList.per)
            }}
        />
        return <div>
            {paging}
            {
                this.props.kirokuList.data.map((x, i) => {
                    const filter = {}
                    x.basic.checks.forEach(y => filter[y.name] = y.checked)

                    return <div key={i}><StandardTable
                        isOrange={x.is_temp}
                        hasBorder={true}
                        sticky={true}
                        title={<div>
                            <img src="/images/icons/書類_w.png" height="25px" />
                            <div style={{ display: "inline-flex", verticalAlign: "text-bottom", color: x.is_temp ? Colors.primaryColor : undefined }}>
                                {DateUtil.toJpDateString(x.time_start)} {x.is_temp ? "(一時保存)" : ""}
                            </div>
                        </div>} key={"kiroku_" + x.id}>

                        <div className={classes.activity_detail}>
                            <Button color="primary" variant="contained" style={{ margin: "10px" }}
                                onClick={(e) => { if (x.id) this.props.parent.onCreateTab(x.id) }}
                            >
                                確認・編集
                            </Button>
                        </div>
                        {
                            x.basic.version == Model.KirokuII.VERSION_3_0 ?
                                <Grid container={true} spacing={1}>
                                    <KirokuIIView3.Basic kiroku={x} parent={this.props.parent} />
                                    <KirokuIIView3.Deal kiroku={x} parent={this.props.parent} />
                                    <KirokuIIView3.Vital kiroku={x} parent={this.props.parent} />
                                    {filter["皮膚"] ? <KirokuIIView3.Skin kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["呼吸"] ? <KirokuIIView3.Breath kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["痛み"] ? <KirokuIIView3.Pain kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["口腔衛生"] ? <KirokuIIView3.Oral kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["栄養・代謝"] ? <KirokuIIView3.Nutrition kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["排泄"] ? <KirokuIIView3.Excre kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["睡眠"] ? <KirokuIIView3.Sleep kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["認知・意識"] ? <KirokuIIView3.Cognition kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["循環"] ? <KirokuIIView3.Circ kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["メンタルヘルス"] ? <KirokuIIView3.Mental kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["成長と発達"] ? <KirokuIIView3.Glowth kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["神経・筋・骨格"] ? <KirokuIIView3.Neuro kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["ACP/EOL"] ? <KirokuIIView3.EOL kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["介護・養育"] ? <KirokuIIView3.Kaigo kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["言語"] ? <KirokuIIView3.Lang kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["感染管理"] ? <KirokuIIView3.Simple title="感染管理" kiroku={x} category="infection" parent={this.props.parent} /> : null}
                                    {filter["清潔整容"] ? <KirokuIIView3.Simple title="清潔整容" kiroku={x} category="clean" parent={this.props.parent} /> : null}
                                    {filter["薬剤管理や受診"] ? <KirokuIIView3.Simple title="薬剤管理や受診" kiroku={x} category="medicine" parent={this.props.parent} /> : null}
                                    {filter["ドラッグ・嗜好品など"] ? <KirokuIIView3.Simple title="ドラッグ・嗜好品など" kiroku={x} category="drug" parent={this.props.parent} /> : null}
                                    {filter["社会"] ? <KirokuIIView3.Simple title="社会" kiroku={x} category="social" parent={this.props.parent} /> : null}
                                    {filter["役割・セクシャリティ"] ? <KirokuIIView3.Simple title="役割・セクシャリティ" kiroku={x} category="role" parent={this.props.parent} /> : null}
                                    {filter["環境・経済"] ? <KirokuIIView3.Simple title="環境・経済" category="environment" kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["感覚"] ? <KirokuIIView3.Simple title="感覚" category="sense" kiroku={x} parent={this.props.parent} /> : null}
                                    {filter["周産期・生殖"] ? <KirokuIIView3.Simple title="周産期・生殖" kiroku={x} category="perinatal" parent={this.props.parent} /> : null}
                                    <KirokuIIView3.Omaha kiroku={x} problemList={this.props.problemList} parent={this.props.parent} />
                                </Grid>
                                :
                                x.basic.version == Model.KirokuII.VERSION_2_0 ?
                                    <Grid container={true} spacing={1}>
                                        <KirokuIIView2.Basic kiroku={x} parent={this.props.parent} />
                                        <KirokuIIView2.Vital kiroku={x} parent={this.props.parent} />
                                        {filter["皮膚"] ? <KirokuIIView2.Skin kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["呼吸"] ? <KirokuIIView2.Breath kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["疼痛"] ? <KirokuIIView2.Pain kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["口腔"] ? <KirokuIIView2.Oral kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["栄養・代謝"] ? <KirokuIIView2.Nutrition kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["排泄"] ? <KirokuIIView2.Excre kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["睡眠"] ? <KirokuIIView2.Sleep kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["認知・意識"] ? <KirokuIIView2.Cognition kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["循環"] ? <KirokuIIView2.Circ kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["精神状態"] ? <KirokuIIView2.Mental kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["内分泌"] ? <KirokuIIView2.Metabo kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["発達・成長"] ? <KirokuIIView2.Glowth kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["神経・筋・骨格"] ? <KirokuIIView2.Neuro kiroku={x} parent={this.props.parent} /> : null}
                                        {(filter["ターミナルケア・エンドオブライフケア"] || filter["ACP/EOL"]) ? <KirokuIIView2.EOL kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["介護・養育"] ? <KirokuIIView2.Kaigo kiroku={x} parent={this.props.parent} /> : null}
                                        {filter["言語"] ? <KirokuIIView2.Lang kiroku={x} parent={this.props.parent} /> : null}
                                        <KirokuIIView2.Other kiroku={x} parent={this.props.parent} />
                                        <KirokuIIView2.Omaha kiroku={x} problemList={this.props.problemList} parent={this.props.parent} />
                                        <KirokuIIView2.Moushiokuri kiroku={x} parent={this.props.parent} />
                                    </Grid>
                                    :
                                    <div>
                                        <KirokuIIView.Basic kiroku={x} />
                                        <KirokuIIView.Vital kiroku={x} />
                                        {filter["皮膚"] ? <KirokuIIView.Skin kiroku={x} /> : null}
                                        {filter["呼吸"] ? <KirokuIIView.Breath kiroku={x} /> : null}
                                        {filter["疼痛"] ? <KirokuIIView.Pain kiroku={x} /> : null}
                                        {filter["口腔"] ? <KirokuIIView.Oral kiroku={x} /> : null}
                                        {filter["栄養・代謝"] ? <KirokuIIView.Nutrition kiroku={x} /> : null}
                                        {filter["脱水"] ? <KirokuIIView.Dehyd kiroku={x} /> : null}
                                        {filter["排泄"] ? <KirokuIIView.Excre kiroku={x} /> : null}
                                        {filter["身体活動"] ? <KirokuIIView.Physical kiroku={x} /> : null}
                                        {filter["睡眠"] ? <KirokuIIView.Sleep kiroku={x} /> : null}
                                        {filter["認知・意識"] ? <KirokuIIView.Cognition kiroku={x} /> : null}
                                        {filter["循環"] ? <KirokuIIView.Circ kiroku={x} /> : null}
                                        {filter["精神状態"] ? <KirokuIIView.Mental kiroku={x} /> : null}
                                        {filter["内分泌"] ? <KirokuIIView.Metabo kiroku={x} /> : null}
                                        {filter["発達・成長"] ? <KirokuIIView.Glowth kiroku={x} /> : null}
                                        {filter["神経・筋・骨格"] ? <KirokuIIView.Neuro kiroku={x} /> : null}
                                        {(filter["ターミナルケア・エンドオブライフケア"] || filter["ACP/EOL"]) ? <KirokuIIView.EOL kiroku={x} /> : null}
                                        {filter["介護・養育"] ? <KirokuIIView.Kaigo kiroku={x} /> : null}
                                        {filter["言語"] ? <KirokuIIView.Lang kiroku={x} /> : null}
                                        <KirokuIIView.Other kiroku={x} />
                                        <KirokuIIView.Omaha kiroku={x} problemList={this.props.problemList} />
                                        <KirokuIIView.Moushiokuri kiroku={x} />
                                    </div>
                        }
                        <div className={classes.activity_detail}>
                            <Button color="primary" variant="contained" style={{ margin: "10px" }}
                                onClick={(e) => { if (x.id) this.props.parent.onCreateTab(x.id) }}
                            >
                                確認・編集
                            </Button>
                        </div>
                    </StandardTable>
                        <div style={{ paddingTop: "100px", paddingBottom: "100px", textAlign: "center" }}>
                            <img src="/icon.png" width="50px" />
                        </div>
                    </div>
                })
            }
            {paging}
        </div>
    }
}



interface ActivityDocProps extends Styles.WithStyles<typeof styles> {
    me: Model.User;
    parent: any;
    doc: Model.Document.DocumentChat;
    patient: Model.Patient.Patient;
    onDelete: () => void
    onUpdate: () => void
    simpleUsers: Model.SimpleUser[]
}
interface ActivityDocState {
    message: string
    sending: boolean
    errorMessage: string
    image_urls: string[]
    images: File[]
    mentionInput: string
    autoCompleteUsers: { id: number, display: string }[]
    openDialog: boolean
    selectedDocumentId: number | null
    pdf_pages: number
    pdf_shown: boolean
}
class ActivityDocComponent extends React.Component<ActivityDocProps, ActivityDocState> {
    constructor(props) {
        super(props)
        this.state = {
            message: "",
            sending: false,
            errorMessage: "",
            images: [],
            image_urls: [],
            openDialog: false,
            selectedDocumentId: null,
            mentionInput: "",
            autoCompleteUsers: [],
            pdf_pages: 0,
            pdf_shown: false
        }
    }

    public componentDidMount() {
        this.setState({
            autoCompleteUsers: this.props.simpleUsers.map(x => {
                return {
                    id: x.id,
                    display: x.name
                }
            })
        })

        // setIntervalthis.props.onUpdate(), 3000);
        //setInterval(() => {
        //     //this.setState({pdf_shown: true });
        //     this.props.onUpdate()
        //     console.log("hi")
        //}, 20000);
        //setTimeout(this.forceUpdate, 9000);
        /*
        if (this.state.pdf_shown == false) {
            setTimeout(() => {
                this.setState({pdf_shown: true})
                this.props.onUpdate()
            }, 20000);
        }*/
    }

    public commit() {
        const postData = new Model.Document.DocumentChatChildPost()
        postData.message = this.state.message
        postData.images = this.state.images
        HokanApi.postDocChatChild(this.props.doc.id, postData).then(res => {

            const me = this.props.me
            const pat = this.props.patient
            if (me && me.branch && pat) {
                const pat = this.props.patient
                if (me && me.branch && pat) {
                    send_to_firebase(me, pat, this.state.message, "doc_child",
                        this.props.simpleUsers.filter(x => x.branch_id == me.branch_id),
                        this.props.simpleUsers)
                }
            }
            this.props.parent.loadDocChat()
            this.setState({
                sending: false,
                message: "",
                errorMessage: "",
                images: [],
                image_urls: [],
            })
        }).catch(err => {
            this.setState({ sending: false, errorMessage: "エラー" })
            console.log(err)
        })
    }

    showEmbed(url: string | string[] | null) {
        return url !== null // && !UADetector.isMobile();
    }
    showLink(url: string | string[] | null) {
        return url !== null  // && UADetector.isMobile();
    }

    public render() {
        if (!this.props.me.branch) return <div />
        const { doc, classes } = this.props
        const sa = new StoreAccessor(this, true)
        const cid = this.props.me.branch.company_id
        const onDocumentLoadSuccess = ({ numPages }) => {
            this.setState({ pdf_pages: numPages })
        }

        const isPDF = (x) => x.indexOf(".pdf") >= 0 || x.indexOf(".PDF") >= 0
        const isMovie = (x) => x.indexOf(".mp4") >= 0 || x.indexOf(".MP4") >= 0

        return <div className={classes.activity}
            style={{ backgroundColor: doc.is_temp ? "#ffE" : "#FFF" }}>
            {
                doc.isNew ?
                    <div className={classes.activity_new}>
                        新着
                    </div>
                    : null
            }
            <AvatarFace classes={classes} cid={cid} user={doc.created_by} />
            <div className={classes.activity_summary}>
                <div><span className={classes.bold}>{doc.created_by.name}</span>:{DateUtil.toJpDateString(doc.create_date)}
                    (実作成日：{DateUtil.toJpDateTimeString(doc.created_at)})</div>

                <div className={classes.chat_message}>
                    {
                        doc.is_temp ?
                            <span style={{ color: "#E55", fontWeight: "bold" }}>[一時保存]</span>
                            : null
                    }
                    <div>{doc.document_type.name}</div>
                    <div>
                        {doc.title}<br />
                        {doc.comment}
                    </div>
                </div>
                {
                    this.showEmbed(doc.pdf_url) ?
                        <div>
                            {doc.pdf_url ?
                                <iframe id="doc_id"
                                    src={doc.pdf_url}
                                    width="100%" height="600px" />
                                : null
                            }
                        </div>
                        : null
                }
                {
                    doc.image_urls ?
                        <div>
                            {doc.image_urls.map((url, ui) => {
                                return <a href={url} key={`js_${ui}`} target="blank">
                                    <img src={url} style={{ width: "200px" }} />
                                </a>
                            })}
                        </div>
                        : null
                }
                {
                    doc.file_urls.map((x, xi) => {
                        return (isPDF(x)) ?
                            <div>
                                <iframe
                                    src={x}
                                    width="100%" height="600px" />
                            </div> : null
                    })
                }
                {
                    doc.file_urls.map((x, xi) => {
                        return (isMovie(x)) ?
                            <a style={{ margin: "20px" }} target="blank" id={`file_${xi}`} href={x} >動画ファイル{xi + 1}</a>
                            : null
                    })
                }
                {
                    doc.file_urls.map((x, xi) => {
                        return (isPDF(x) == false && isMovie(x) == false) ?
                            <a style={{ margin: "20px" }} target="blank" id={`file_${xi}`} href={x} >ファイル{xi + 1}</a>
                            : null
                    })
                }
                <br />
                <br />
                <div>
                    <Button variant="contained" color="default" size="small"
                        onClick={() =>
                            this.setState({
                                openDialog: true,
                                selectedDocumentId: doc.id
                            })
                        }>編集</Button>
                    {"　"}
                    <Button variant="contained" color="default" size="small"
                        onClick={() => {
                            const len = doc.file_urls
                            if (window && doc && doc.file_urls.length > 0) {
                                return window.open(doc.file_urls[0], "_blank")
                            }
                            else if (window && doc && doc.pdf_url) {
                                return window.open(doc.pdf_url, "_blank")
                            }
                            return null
                        }}>ダウンロード
                    </Button>
                </div>
            </div>
            {" "}
            <div style={{ margin: "10px" }}>
                {doc.children.map((c, ci) => {
                    return <div key={`dc_${ci}`} >
                        {
                            c.isNew ?
                                <div className={classes.activity_new}>
                                    新着
                                </div>
                                : null
                        }
                        <AvatarFace classes={classes} cid={cid} user={c.created_by} />
                        <div className={classes.activity_summary}>
                            <div><span className={classes.bold}>{c.created_by.name}</span>:{DateUtil.toJpDateTimeString(c.created_at)}</div>
                            <div className={classes.chat_message}>
                                {c.message_dom}
                            </div>
                            <div >
                                {
                                    c.image_urls.map((url, idx) => <div key={`img_${idx}`} className={classes.chat_image} >
                                        <a href={url} target="_blank"><img src={url} width="100px" /></a>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                })}
            </div>
            {
                this.state.openDialog ?
                    <DocumentDialog key={`dialog_${this.state.selectedDocumentId}`}
                        open={true}
                        only={null}
                        patientId={this.props.patient.id}
                        srcId={this.state.selectedDocumentId}
                        onCansel={(deleted) => {
                            this.setState({ openDialog: false, selectedDocumentId: null })
                            if (deleted) {
                                this.props.onDelete()
                            }
                        }}
                        onSelect={(doc: Model.Document.Document) => {
                            this.setState({ openDialog: false, selectedDocumentId: null })
                            this.props.onUpdate()
                        }}
                    />
                    : null
            }
        </div >
    }
}

export default withPageProps(Styles.withStyles(styles)(PatientPage))
