import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
import IncidentList from '../components/IncidentList'
import * as Colors from '../components/Colors'
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import {
    Tabs, Tab
} from '@material-ui/core'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        marginLeft: "5px"
        // background: Colors.primaryColor,
    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
        backgroundColor: Colors.forthColor
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700,
    },
    tabTextSub: {

    },
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    tabIndex: number
    branches: Model.Branch[]
}

// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "IncidentListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            tabIndex: 0,
            branches: []
        }
    }

    public componentDidMount = () => {
        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
    }

    public render() {
        const { classes } = this.props;
        return <div className={classes.root}>
            <Tabs value={this.state.tabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, value) => { this.setState({ tabIndex: value }) }}>
                <Tab label={<span className={classes.tabTextSub}>事故</span>} />
                <Tab label={<span className={classes.tabTextSub}>苦情</span>} />
                <Tab label={<span className={classes.tabTextSub}>災害</span>} />
            </Tabs>
            {
                this.state.tabIndex == 0 ?
                    <div>
                        <IncidentList showButton={false} isJiko={true} />
                    </div>
                    : null
            }
            {
                this.state.tabIndex == 1 ?
                    <div>
                        <IncidentList showButton={false} isJiko={false} />
                    </div>
                    : null
            }
            {
                this.state.tabIndex == 2 ?
                    <div style={{ margin: "10px" }}>
                        <a href="https://www.gsi.go.jp/bousaichiri/hinanbasho.html" target="_blank">
                            災害時避難場所マップ
                        </a>
                        <div>
                            {
                                this.state.branches.filter(x => x.saigai_pdf_url).map(x => {
                                    return <div><a href={x.saigai_pdf_url} target="_blank">
                                        災害時マニュアルPDF（{x.name})
                                    </a></div>
                                })
                            }
                        </div>
                    </div>
                    : null
            }
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))