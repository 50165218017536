
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public nichijo_1: string = ""
    public nichijo_2: string = ""
    public nichijo_3: string = ""
    public keizai_1: string = ""
    public keizai_2: string = ""
    public keizai_3: string = ""
    public shigoto_1: string = ""
    public shigoto_2: string = ""
    public shigoto_3: string = ""
    public shien_1: string = ""
    public shien_2: string = ""
    public shien_3: string = ""
    public kenkou_1: string = ""
    public kenkou_2: string = ""
    public kenkou_3: string = ""
    public shumi_1: string = ""
    public shumi_2: string = ""
    public shumi_3: string = ""
    public seikaku_1: string = ""
    public seikaku_2: string = ""
    public seikaku_3: string = ""
}

export class Checks {
}