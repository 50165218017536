import { User, SimpleUser, Document, ServiceCode, Problem, Intervention } from '.';
import DateUtil from '../util/DateUtil'
import moment from 'moment';
import { Patient } from './Patient';
import * as Model from '../models'

import * as KirokuIIEditViews from '../components/KirokuIIEditViews'
import { FormatListNumberedRtlOutlined } from '@material-ui/icons';

export class KangoKirokuII {
    public id: number | null = null
    public kiroku_type = KirokuType.None
    public patient: Patient = new Patient()
    public time_start: string = DateUtil.currentDateTimeStr()
    public time_end: string = DateUtil.currentDateTimeStr()
    public main_user: User = new User()
    public basic: Basic = new Basic()
    public omaha: Omaha = new Omaha()
    public service_code: ServiceCode = new ServiceCode()
    public created_at: string = DateUtil.currentDateTimeStr()
    public updated_at: string = DateUtil.currentDateTimeStr()

    public vital = new Vital()
    public skin = new Skin()
    public breath = new Breath()
    public pain = new Pain()
    public oral = new Oral()
    public nutrition = new Nutrition()
    public dehydration = new Dehydration()
    public excretion = new Excretion()
    public physical = new Physical()
    public sleep = new Sleep()
    public cognition = new Cognition()
    public circulation = new Circulation()
    public mental = new Mental()
    public metabolism = new Metabolism()
    public growth = new Growth()
    public eol = new EOL()
    public kaigo = new Kaigo()
    public lang = new Lang()
    public deal = new Deal()
    public neuro_muscul = new NeuroMuscul()
    public version_list: any[] = []
    public is_temp: boolean = false

    static load(apiRes: any) {
        let res = new KangoKirokuII();
        res.id = apiRes.id;
        res.kiroku_type = apiRes.kiroku_type;
        res.patient = apiRes.patient ? Patient.load(apiRes.patient, false) : new Patient()
        const s = moment(apiRes.time_start)
        const t = moment(apiRes.time_end)
        res.time_start = s ? s.format("YYYY-MM-DDTHH:mm") : ""
        res.time_end = t ? t.format("YYYY-MM-DDTHH:mm") : ""
        res.created_at = apiRes.created_at
        res.updated_at = apiRes.updated_at
        res.main_user = apiRes.main_user;
        const parse = (ar, def) => {
            if (ar) return JSON.parse(ar) || def
            return def
        }
        res.basic = parse(apiRes.basic, new Basic());
        if (!res.basic.kasan) res.basic.kasan = new Kasan();

        if (!res.basic.kasan.basic) res.basic.kasan.basic = new KasanBasic()
        if (!res.basic.kasan.seishin) res.basic.kasan.seishin = new KasanSeishin()
        if (!res.basic.kasan.common) res.basic.kasan.common = new KasanCommon()
        if (!res.basic.checks) res.basic.checks = [];
        if (!res.basic.doukou_list) res.basic.doukou_list = [];

        res.omaha = parse(apiRes.omaha, new Omaha());
        res.service_code = apiRes.service_code;
        res.vital = parse(apiRes.vital, new Vital());
        res.skin = parse(apiRes.skin, new Skin());
        res.breath = parse(apiRes.breath, new Breath());
        if (res.breath.kokyuon_ijou instanceof Array) res.breath.kokyuon_ijou = new BreathIjou()
        res.pain = parse(apiRes.pain, new Pain());
        res.oral = parse(apiRes.oral, new Oral());
        res.nutrition = parse(apiRes.nutrition, new Nutrition());
        res.dehydration = parse(apiRes.dehydration, new Dehydration());
        res.excretion = parse(apiRes.excretion, new Excretion());
        res.physical = parse(apiRes.physical, new Physical());
        res.sleep = parse(apiRes.sleep, new Sleep());
        res.cognition = parse(apiRes.cognition, new Cognition());
        res.circulation = parse(apiRes.circulation, new Circulation());
        res.mental = parse(apiRes.mental, new Mental());
        res.metabolism = parse(apiRes.metabolism, new Metabolism());
        res.growth = parse(apiRes.growth, new Growth());
        res.eol = parse(apiRes.eol, new EOL());
        res.kaigo = parse(apiRes.kaigo, new Kaigo());
        res.lang = parse(apiRes.lang, new Lang());
        res.deal = parse(apiRes.deal, new Deal());
        res.neuro_muscul = parse(apiRes.neuro_muscul, new NeuroMuscul())
        res.version_list = apiRes.version_list
        res.is_temp = apiRes.is_temp
        return res;
    }

    public pastDocumentPool = new PastDocumentPool();

    public static resetDocuments(k: KangoKirokuII, elm_name: string) {
        const res = k.pastDocumentPool
        const allCopy = elm_name = "all"
        if (allCopy || elm_name == KirokuIIEditViews.Skin.getTitle()) {
            res.set(["skin"], "breden_doc", k.skin.breden_doc);
            res.set(["skin"], "docImage", k.skin.docImage)
            k.skin.docImage = null;
            k.skin.breden_doc = null;

            if (k.skin.designr_doc) {
                k.skin.designr_docs.push(k.skin.designr_doc)
            }
            if (k.skin.designr_docs) {
                k.skin.designr_docs.map((d, di) => {
                    res.set(["skin", "designr_docs"], di, k.skin.designr_docs[di]);
                })
            }
            k.skin.designr_docs = [];
            k.skin.designr_doc = null;

            if (k.skin.jokuso) {
                k.skin.jokuso.forEach((x, xi) => {
                    res.set(["skin", "jokuso", xi], "docImage", x.docImage);
                    x.docImage = null
                })
            }
            if (k.skin.other) {
                k.skin.other.forEach((x, xi) => {
                    res.set(["skin", "other", xi], "docImage", x.docImage);
                    x.docImage = null
                })
            }
        }

        if (allCopy || elm_name == KirokuIIEditViews.Breath.getTitle()) {
            res.set(["breath"], "jinko_kokyuki_setting_image", k.breath.jinko_kokyuki_setting_image);
            res.set(["breath"], "mmrc_doc", k.breath.mmrc_doc);
            res.set(["breath"], "kensakekka_image", k.breath.jinko_kokyuki_setting_image);
            res.set(["breath"], "jinko_kokyuki_setting_image", k.breath.jinko_kokyuki_setting_image);
            k.breath.jinko_kokyuki_setting_image = null;
            k.breath.mmrc_doc = null
            k.breath.kensakekka_image = null
            k.breath.kikan_sekkai_hihu_trouble.forEach((x, xi) => {
                res.set(["breath", "kikan_sekkai_hihu_trouble", xi], "docImage", x.docImage);
                x.docImage = null
            })
        }

        if (allCopy || elm_name == KirokuIIEditViews.Oral.getTitle()) {
            res.set(["oral"], "ohat_document", k.oral.ohat_document);
            res.set(["oral"], "rsst_document", k.oral.ohat_document);
            res.set(["oral"], "mizunomi_document", k.oral.mizunomi_document);
            res.set(["oral"], "food_document", k.oral.food_document);
            k.oral.ohat_document = null
            k.oral.rsst_document = null
            k.oral.mizunomi_document = null
            k.oral.food_document = null
        }


        if (allCopy || elm_name == KirokuIIEditViews.Pain.getTitle()) {
            res.set(["pain"], "stasj_doc", k.pain.stasj_doc);
            k.pain.stasj_doc = null
        }


        if (allCopy || elm_name == KirokuIIEditViews.Nutrition.getTitle()) {
            res.set(["nutrition"], "sesshu_eiyou_image", k.nutrition.sesshu_eiyou_image);
            res.set(["nutrition"], "kensa_kekka_image", k.nutrition.kensa_kekka_image);
            k.nutrition.sesshu_eiyou_image = null
            k.nutrition.kensa_kekka_image = null
        }

        if (allCopy || elm_name == KirokuIIEditViews.Excre.getTitle()) {
            res.set(["excretion"], "haiben_stoma_image", k.excretion.haiben_stoma_image);
            k.excretion.haiben_stoma_image = null
            k.excretion.hainyou_katetel.forEach((x, xi) => {
                res.set(["excretion", "hainyou_katetel", xi], "image", x.image);
                x.image = null
            })
        }

        if (allCopy || elm_name == KirokuIIEditViews.Physical.getTitle()) {
            res.set(["physical"], "mmt_document", k.physical.mmt_document);
            res.set(["physical"], "short_bbs_document", k.physical.short_bbs_document);
            res.set(["physical"], "bedside_mobility_document", k.physical.bedside_mobility_document);
            res.set(["physical"], "jiko_kouryoku_kan_document", k.physical.jiko_kouryoku_kan_document);
            k.physical.mmt_document = undefined
            k.physical.short_bbs_document = undefined
            k.physical.bedside_mobility_document = undefined
            k.physical.jiko_kouryoku_kan_document = undefined
        }

        if (allCopy || elm_name == KirokuIIEditViews.Mental.getTitle()) {
            res.set(["mental"], "doc_mse", k.mental.doc_mse);
            res.set(["mental"], "doc_selfcare", k.mental.doc_selfcare);
            res.set(["mental"], "doc_strength1", k.mental.doc_strength1);
            res.set(["mental"], "doc_wrap", k.mental.doc_wrap);
            res.set(["mental"], "doc_gaf", k.mental.doc_gaf);
            res.set(["mental"], "yakuzai_shohou_image", k.mental.yakuzai_shohou_image);
            k.mental.doc_mse = null
            k.mental.doc_selfcare = null
            k.mental.doc_strength1 = null
            k.mental.doc_wrap = null
            k.mental.doc_gaf = null
            k.mental.yakuzai_shohou_image = null
        }

        if (allCopy || elm_name == KirokuIIEditViews.Metabo.getTitle()) {
            res.set(["metabolism"], "kettou_note_image", k.metabolism.kettou_note_image);
            res.set(["metabolism"], "kensakekka_image", k.metabolism.kensakekka_image);
            res.set(["metabolism"], "yakuzai_shohou_image", k.metabolism.yakuzai_shohou_image);
            res.set(["metabolism"], "touseki_image", k.metabolism.touseki_image);
            k.metabolism.kettou_note_image = null
            k.metabolism.kensakekka_image = null
            k.metabolism.yakuzai_shohou_image = null
            k.metabolism.touseki_image = null
        }

        if (allCopy || elm_name == KirokuIIEditViews.Growth.getTitle()) {
            res.set(["growth"], "assessment_document", k.growth.assessment_document);
            k.growth.assessment_document = undefined
        }

        if (allCopy || elm_name == KirokuIIEditViews.EOL.getTitle()) {
            res.set(["eol"], "acl_document", k.eol.acl_document);
            k.eol.acl_document = undefined
        }

        if (allCopy || elm_name == KirokuIIEditViews.Deal.getTitle()) {
            res.set(["deal"], "naiyou_docImage", k.deal.naiyou_docImage);
            k.deal.naiyou_docImage = null
        }
        if (allCopy || elm_name == KirokuIIEditViews.Moushiokuri.getTitle()) {
            res.set(["deal"], "moushiokuri_docImage", k.deal.moushiokuri_docImage);
            k.deal.moushiokuri_docImage = null
        }

        if (allCopy || elm_name == KirokuIIEditViews.Lang.getTitle()) {
            res.set(["lang"], "meiryou_document", k.lang.meiryou_document);
            k.lang.meiryou_document = null
        }

        k.pastDocumentPool = res;
    }

    static async LoadImages(kiroku: KangoKirokuII | null): Promise<void> {
        if (!kiroku) {
            return
        }
        if (kiroku.deal) {
            if (kiroku.deal.moushiokuri_docImage) {
                await Document.Document.LoadImageAndPdfs(kiroku.deal.moushiokuri_docImage)
            }
            if (kiroku.deal.naiyou_docImage) {
                await Document.Document.LoadImageAndPdfs(kiroku.deal.naiyou_docImage)
            }
        }
    }

    static isKaigoHoken(k: KangoKirokuII) {
        if (!k) return false
        return (k.kiroku_type == KirokuType.KaigoHoukan
            || k.kiroku_type == KirokuType.KaigoRiha)
    }

    static isIryoHoken(k: KangoKirokuII) {
        if (!k) return false
        return (k.kiroku_type == KirokuType.IryoHoukan
            || k.kiroku_type == KirokuType.IryoRiha
            || k.kiroku_type == KirokuType.SeishinHoukan)
    }

    static isSeishinHoken(k: KangoKirokuII) {
        if (!k) return false
        return (k.kiroku_type == KirokuType.SeishinHoukan)
    }

    static isJihi(k: KangoKirokuII) {
        if (!k) return false
        return (k.kiroku_type == KirokuType.Jihi)
    }

    static getKirokuCodeStr(k: KangoKirokuII) {
        if (KangoKirokuII.isKaigoHoken(k)) {
            return k.service_code ? k.service_code.name : ""
        }
        if (KangoKirokuII.isJihi(k)) {
            return k.basic ? k.basic.jihi_comment : ""
        }
        if (KangoKirokuII.isIryoHoken(k)) {
            return (k.service_code ? k.service_code.name : k.basic ? k.basic.iryo_code : "")
        }
        return ""
    }

    public static getKasanText(kiroku: Model.KirokuII.KangoKirokuII) {
        var kasan = kiroku.basic.kasan;
        let res = ""
        if (kasan.is_shokai) res += "初回 "
        if (kasan.is_shokai_2) res += "初回II "
        if (kasan.is_koukuu_kyouka) res += "口腔連携 "
        if (kasan.is_taiin) res += "退院時指導 "
        if (kiroku.basic.is_kinkyu) res += "緊急 "
        if (kiroku.basic.is_kinkyu_15) res += "緊急(15) "
        if (kasan.encho_time) res += `延長時間(${kasan.encho_time}) `
        if (kasan.is_taiinji_shidou) res += "退院時指導 "
        if (kasan.is_tokubetu_kanri_shidou) res += "特別管理指導 "
        if (kasan.is_taiin_shien_shidou) res += "退院支援指導 "
        if (kasan.is_chojikan_houmon_kango) res += "長時間(週1回対象) "
        if (kasan.is_chojikan_houmon_kango_2) res += "長時間(週3回対象)  "
        if (kasan.is_yakan) res += "夜間・早朝 "
        if (kasan.is_shinnya) res += "深夜 "
        if (kasan.is_hukusuu_kangoshi) res += "複数名(看護師) "
        if (kasan.is_hukusuu_ptot) res += "複数名(PTOT) "
        if (kasan.is_hukusuu_junkangoshi) res += "複数名(准看護師) "
        if (kasan.is_hukusuu_hojo) res += "複数名(補助者) "
        if (kasan.is_hukusuu_psw) res += "複数名(PSW) "
        if (kasan.is_nyuyouji) res += "乳幼児 "
        if (kasan.is_youji) res += "幼児 "
        if (kasan.is_zaitaku_renkei_shidou) res += "在宅患者連携指導 "
        if (kasan.is_zaitaku_kinkyu_kanfa) res += "在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ "
        if (kasan.is_kanwa_care) res += "緩和ｹｱ "
        if (kasan.is_jokusou_care) res += "褥瘡ｹｱ "
        if (kasan.is_smart_care) res += "ｽﾄｰﾏｹｱ "
        if (kasan.is_taiin_shien_chou) res += "退院支援指導加算(長)"
        if (kasan.is_tokutei_soushou) res += "特定行為"
        
        if (kasan.is_terminal_care) res += "ﾀｰﾐﾅﾙｹｱ "


        if (kasan.basic.is_hukusuu_2) res += "難病等複数(２回目) "
        if (kasan.basic.is_hukusuu_3) res += "難病等複数(３回目以降) "
        if (kasan.basic.is_tokubetsu_chiiki) res += "特別地域 "
        if (kasan.basic.is_choujikan) res += "長時間(週1回対象)  "
        if (kasan.basic.is_choujikan_2) res += "長時間(週3回対象)  "
        if (kasan.basic.is_nyuyouji) res += "乳幼児加算 "
        if (kasan.basic.is_hukusuu_kangoshi) res += "複数名(看) "
        if (kasan.basic.is_hukusuu_jun) res += "複数名(准) "
        if (kasan.basic.is_hukusuu_hojo_ha) res += "複数名(補・ハ) "
        if (kasan.basic.is_hukusuu_hojo_ni_1) res += "複数名(補・ニ・１回) "
        if (kasan.basic.is_hukusuu_hojo_ni_2) res += "複数名(補・ニ・２回) "
        if (kasan.basic.is_hukusuu_hojo_ni_3) res += "複数名(補・ニ・３回以上) "
        if (kasan.basic.is_hukusuu_ptot) res += "複数名(理) "
        if (kasan.basic.is_yakan) res += "夜間・早朝 "
        if (kasan.basic.is_shinya) res += "深夜 "

        if (kasan.seishin.is_hukusuu_2) res += "精神複数(２回目) "
        if (kasan.seishin.is_hukusuu_3) res += "精神複数(３回目以降) "
        if (kasan.seishin.is_tokubetsu_chiiki) res += "特別地域 "
        if (kasan.seishin.is_choujikan) res += "長時間(週1回対象) "
        if (kasan.seishin.is_choujikan_2) res += "長時間(週3回対象)  "
        if (kasan.seishin.is_hukusuu_kangoshi_1) res += "複数名(看・１回) "
        if (kasan.seishin.is_hukusuu_kangoshi_2) res += "複数名(看・２回) "
        if (kasan.seishin.is_hukusuu_kangoshi_3) res += "複数名(看・３回以上) "
        if (kasan.seishin.is_hukusuu_jun_1) res += "複数名(准・１回) "
        if (kasan.seishin.is_hukusuu_jun_2) res += "複数名(准・２回) "
        if (kasan.seishin.is_hukusuu_jun_3) res += "複数名(准・３回以上) "
        if (kasan.seishin.is_hukusuu_hojo) res += "複数名(補) "
        if (kasan.seishin.is_hukusuu_ptot_1) res += "複数名(作・１回) "
        if (kasan.seishin.is_hukusuu_ptot_2) res += "複数名(作・２回) "
        if (kasan.seishin.is_hukusuu_ptot_3) res += "複数名(作・３回以上) "
        if (kasan.seishin.is_seishin_hukushi) res += "複数名(精福) "
        if (kasan.seishin.is_yakan) res += "夜間・早朝算 "
        if (kasan.seishin.is_shinya) res += "深夜 "


        if (kasan.common.is_senmon_tokubetsu_kanri_1) res += "訪問看護専門管理加算１"
        if (kasan.common.is_senmon_tokubetsu_kanri_2) res += "訪問看護専門管理加算２"
        if (kasan.common.is_taiin_shien) res += "退院支援指導加算"
        if (kasan.common.is_taiinshidou_chou) res += "退院支援指導加算(長)"
        if (kasan.common.is_zaitaku_renkei_shidou) res += "在宅患者連携指導加算"
        if (kasan.common.is_tokubetu_kanri_shidou) res += "特別管理指導加算"
        if (kasan.common.is_renkei_kyouka) res += "看護・介護職員連携強化加算"
        if (kasan.is_tokutei_soushou) res += "特定行為"

        return res;
    }
}

export class PastDocumentPool {
    _pool: any = {}

    public set(cat, name, value) {
        this._pool[PastDocumentPool.getPath(cat, name)] = value
    }

    public has(cat, name) {
        const t = PastDocumentPool.getPath(cat, name)
        if (Object.keys(this._pool).findIndex(x => x == t) < 0) return false
        return this._pool[t] != null && this._pool != undefined
    }

    public get(cat, name) {
        if (this.has(cat, name)) {
            return this._pool[PastDocumentPool.getPath(cat, name)]
        }
        return null;
    }

    public static getPath(cat, name) {
        return cat.join("/") + "/" + name
    }
}

export class KangoKirokuIISimple {
    public id: number = 0
    public time_start: string = DateUtil.currentDateStr()
    public time_end: string = DateUtil.currentDateStr()
    public created_at: string = DateUtil.currentDateStr()
    public updated_at: string = DateUtil.currentDateStr()
}

export class KangoKirokuIIPost {
    public id: number | null = null
    public kiroku_type = KirokuType.None
    public time_start: Date = new Date()
    public time_end: Date = new Date()
    public main_user_id: number | null = null
    //public doukou_edu: DoukouUser[] = []
    //public doukou_hikitsugi: DoukouUser[] = []
    public omaha: string = "{}"
    public service_code_id: number | null = null
    public vital: string = "{}"
    public skin: string = "{}"
    public breath: string = "{}"
    public pain: string = "{}"
    public oral: string = "{}"
    public nutrition: string = "{}"
    public dehydration: string = "{}"
    public excretion: string = "{}"
    public physical: string = "{}"
    public sleep: string = "{}"
    public cognition: string = "{}"
    public circulation: string = "{}"
    public mental: string = "{}"
    public metabolism: string = "{}"
    public growth: string = "{}"
    public eol: string = "{}"
    public kaigo: string = "{}"
    public deal: string = "{}"
    public lang: string = "{}"
    public basic: string = "{}"
    public neuro_muscul: string = "{}"
    public is_temp: boolean = false

    public constructor(src: KangoKirokuII) {
        this.id = src.id;
        this.omaha = JSON.stringify(src.omaha);
        this.basic = JSON.stringify(src.basic);
        this.kiroku_type = src.kiroku_type;
        this.main_user_id = src.main_user ? src.main_user.id : null
        this.time_start = moment(src.time_start).toDate();
        this.time_end = moment(src.time_end).toDate();
        this.service_code_id = (src.service_code && src.service_code.id) ? src.service_code.id : null;
        this.vital = JSON.stringify(src.vital);
        this.skin = JSON.stringify(src.skin);
        this.breath = JSON.stringify(src.breath);
        this.pain = JSON.stringify(src.pain);
        this.oral = JSON.stringify(src.oral);
        this.nutrition = JSON.stringify(src.nutrition);
        this.dehydration = JSON.stringify(src.dehydration);
        this.excretion = JSON.stringify(src.excretion);
        this.physical = JSON.stringify(src.physical);
        this.sleep = JSON.stringify(src.sleep);
        this.cognition = JSON.stringify(src.cognition);
        this.circulation = JSON.stringify(src.circulation);
        this.mental = JSON.stringify(src.mental);
        this.metabolism = JSON.stringify(src.metabolism);
        this.growth = JSON.stringify(src.growth);
        this.eol = JSON.stringify(src.eol);
        this.kaigo = JSON.stringify(src.kaigo);
        this.lang = JSON.stringify(src.lang);
        this.deal = JSON.stringify(src.deal);
        this.neuro_muscul = JSON.stringify(src.neuro_muscul);
        this.is_temp = src.is_temp
    }
}

export enum KirokuType {
    None = "未選択",
    KaigoHoukan = "介護保険/訪問看護",
    IryoHoukan = "医療保険/訪問看護",
    SeishinHoukan = "精神/訪問看護",
    KaigoRiha = "介護保険/リハビリ",
    IryoRiha = "医療保険/リハビリ",
    Jihi = "自費"
}

export class DoukouUser {
    public step: DoukouStep = DoukouStep.Other
    public user: SimpleUser = new SimpleUser
}

export enum DoukouStep {
    Other = "教育以外",
    Kengaku = "見学",
    Bubun = "一部実施",
    Zenbu = "全部実施",
    Kakunin = "確認"
}

export enum IryoCodeEnum {
    None = "未選択",
    IryoI = "療養費I",
    IryoII = "療養費II",
    IryoIII = "療養費III",
    IryoIV = "療養費IV",
}

export const ShikakuList = [
    { key: "保健師、助産師、看護師", value: "0" },
    { key: "准看護師", value: "1" },
    { key: "PT、OT、ST", value: "2" },
    { key: "緩和ケアに係る専門の研修を受けた看護師", value: "3" },
    { key: "褥瘡ケアに係る専門の研修を受けた看護師", value: "4" },
    { key: "人工肛門・人工膀胱ケアに係る専門の研修を受けた看護師", value: "5" },
    { key: "精神保健福祉士", value: "6" }
]


export const VERSION_1_0 = "v1.0"
export const VERSION_2_0 = "v2.0"
export const VERSION_3_0 = "v3.0"
export const VERSION_CURRENT = VERSION_3_0

export class Basic {
    public version: string = VERSION_CURRENT
    public doukou_main: SimpleUser | null = null
    public doukou_shikaku: string = "0"
    public doukou_is_hojo: boolean = false
    public jihi_comment: string = ""

    public doukou_list: DoukouUser[] = []
    public doukou_edu: DoukouUser[] = []
    public doukou_hikitsugi: DoukouUser[] = []
    public checks: { name: string, checked: boolean }[] = []
    public shikaku: string = "0" //資格
    public riha_count: number | null = null //訪問ﾘﾊ回数
    public kasan: Kasan = new Kasan()

    public iryo_code: string = IryoCodeEnum.IryoI

    public is_teiki: boolean = false
    public is_hiwari: boolean = false
    public is_kinkyu: boolean = false
    public is_kinkyu_15: boolean = false
}

export class Kasan {
    public is_shokai: boolean = false // (介)初回加算
    public is_shokai_2: boolean = false
    public is_koukuu_kyouka: boolean = false
    public is_taiin: boolean = false // (介)退院時指導
    public is_renkei: boolean = false // (介)訪問看護介護連携強化加算
    public encho_time: number | null = null //    (医)延長時間
    public is_taiinji_shidou: boolean = false // (医)退院時指導加算
    public is_tokubetu_kanri_shidou: boolean = false // (医)特別管理指導加算
    public is_taiin_shien_shidou: boolean = false // (医)退院支援指導加算
    public is_taiin_shien_chou: boolean = false // 退院支援指導加算(長)
    public is_kinkyu_houmon: boolean = false // (医)緊急訪問看護加算
    public is_kinkyu_houmon_15: boolean = false // (医)緊急訪問看護加算
    public is_chojikan_houmon_kango: boolean = false // (医)長時間
    public is_chojikan_houmon_kango_2: boolean = false // (医)長時間
    public is_yakan: boolean = false // (医)夜間・早朝加算
    public is_shinnya: boolean = false // (医)深夜加算
    public is_hukusuu_kangoshi: boolean = false // (医)複数名訪問看護加算（看護師）	
    public is_hukusuu_ptot: boolean = false // (医)複数名訪問看護加算（PTOT）
    public is_hukusuu_junkangoshi: boolean = false // (医)複数名訪問看護加算（准看護師）
    public is_hukusuu_hojo: boolean = false // (医)複数名訪問看護加算（補助者）
    public is_hukusuu_psw: boolean = false // (医)複数名訪問看護加算（PSW）
    public is_nyuyouji: boolean = false // (医)乳幼児加算
    public is_youji: boolean = false // (医)幼児加算
    public is_zaitaku_renkei_shidou: boolean = false // (医)在宅患者連携指導加算
    public is_zaitaku_kinkyu_kanfa: boolean = false // (医)在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ加算
    public is_kanwa_care: boolean = false // (医)緩和ｹｱ
    public is_jokusou_care: boolean = false // (医)褥瘡ｹｱ
    public is_smart_care: boolean = false // (医)ｽﾄｰﾏｹｱ	
    public is_tokutei_soushou: boolean = false // 特定行為創傷管理
    public is_terminal_care: boolean = false
    public is_kourei_gensan :boolean = false

    public basic: KasanBasic = new KasanBasic()
    public seishin: KasanSeishin = new KasanSeishin()
    public common: KasanCommon = new KasanCommon()

    static checkedSomeKasan(k: Kasan | null) {
        if (!k) return false

        return (k.is_shokai || k.is_taiin ||
            k.is_taiinji_shidou ||  // (医)退院時指導加算
            k.is_tokubetu_kanri_shidou ||  // (医)特別管理指導加算
            k.is_taiin_shien_shidou ||  // (医)退院支援指導加算
            k.is_kinkyu_houmon ||  // (医)緊急訪問看護加算
            k.basic.is_kinkyu ||
            k.basic.is_kinkyu_15 ||
            k.is_chojikan_houmon_kango ||  // (医)長時間
            k.is_chojikan_houmon_kango_2 ||  // (医)長時間
            k.is_yakan ||  // (医)夜間・早朝加算
            k.is_shinnya ||  // (医)深夜加算
            k.is_hukusuu_kangoshi ||  // (医)複数名訪問看護加算（看護師）	
            k.is_hukusuu_ptot ||  // (医)複数名訪問看護加算（PTOT）
            k.is_hukusuu_junkangoshi ||  // (医)複数名訪問看護加算（准看護師）
            k.is_hukusuu_hojo ||  // (医)複数名訪問看護加算（補助者）
            k.is_hukusuu_psw ||  // (医)複数名訪問看護加算（PSW）
            k.is_nyuyouji ||  // (医)乳幼児加算
            k.is_youji ||  // (医)幼児加算
            k.is_zaitaku_renkei_shidou ||  // (医)在宅患者連携指導加算
            k.is_zaitaku_kinkyu_kanfa ||  // (医)在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ加算
            k.is_kanwa_care ||  // (医)緩和ｹｱ
            k.is_jokusou_care ||  // (医)褥瘡ｹｱ
            k.is_smart_care ||  // (医)ｽﾄｰﾏｹｱ	
            k.is_terminal_care ||
            k.is_tokutei_soushou
        )
    }
}

export class KasanBasic {
    public is_hukusuu_2: boolean = false // 難病等複数回訪問加算(２回目)
    public is_hukusuu_3: boolean = false // 難病等複数回訪問加算(３回目以降)
    public is_tokubetsu_chiiki: boolean = false // 特別地域訪問看護加算
    public is_kinkyu: boolean = false // 緊急訪問看護加算
    public is_kinkyu_15: boolean = false // 緊急訪問看護加算
    public is_choujikan: boolean = false // 長時間
    public is_choujikan_2: boolean = false // 長時間
    public is_nyuyouji: boolean = false // 乳幼児加算
    public is_hukusuu_kangoshi: boolean = false // 複数名訪問看護加算(看護師等)
    public is_hukusuu_jun: boolean = false // 複数名訪問看護加算(准看護師)
    public is_hukusuu_hojo_ha: boolean = false // 複数名訪問看護加算(看護補助者・ハ)
    public is_hukusuu_hojo_ni_1: boolean = false // 複数名訪問看護加算(看護補助者・ニ・１回)
    public is_hukusuu_hojo_ni_2: boolean = false // 複数名訪問看護加算(看護補助者・ニ・２回)
    public is_hukusuu_hojo_ni_3: boolean = false // 複数名訪問看護加算(看護補助者・ニ・３回以上)
    public is_hukusuu_ptot: boolean = false // 複数名訪問看護加算(理学療法士等)
    public is_yakan: boolean = false // 夜間・早朝訪問看護加算
    public is_shinya: boolean = false // 深夜訪問看護加算
}

export class KasanSeishin {
    public is_hukusuu_2: boolean = false // 精神科複数回訪問加算(２回目)
    public is_hukusuu_3: boolean = false // 精神科複数回訪問加算(３回目以降)
    public is_tokubetsu_chiiki: boolean = false // 特別地域訪問看護加算
    public is_kinkyu: boolean = false // 緊急訪問看護加算

    public is_kinkyu_15: boolean = false // 緊急訪問看護加算
    public is_choujikan: boolean = false // 長時間
    public is_choujikan_2: boolean = false // 長時間
    public is_hukusuu_kangoshi_1: boolean = false // 複数名訪問看護加算(看護師等・１回)
    public is_hukusuu_kangoshi_2: boolean = false // 複数名訪問看護加算(看護師等・２回)
    public is_hukusuu_kangoshi_3: boolean = false // 複数名訪問看護加算(看護師等・３回以上)
    public is_hukusuu_jun_1: boolean = false // 複数名訪問看護加算(准看護師・１回)
    public is_hukusuu_jun_2: boolean = false // 複数名訪問看護加算(准看護師・２回)
    public is_hukusuu_jun_3: boolean = false // 複数名訪問看護加算(准看護師・３回以上)
    public is_hukusuu_hojo: boolean = false // 複数名訪問看護加算(看護補助者)
    public is_hukusuu_ptot_1: boolean = false // 複数名訪問看護加算(作業療法士・１回)
    public is_hukusuu_ptot_2: boolean = false // 複数名訪問看護加算(作業療法士・２回)
    public is_hukusuu_ptot_3: boolean = false // 複数名訪問看護加算(作業療法士・３回以上)
    public is_seishin_hukushi: boolean = false // 複数名訪問看護加算(精神保健福祉士)
    public is_yakan: boolean = false // 夜間・早朝訪問看護加算
    public is_shinya: boolean = false // 深夜訪問看護加算
}

export class KasanCommon {
    // public is_tokubetu_kanri_1: boolean = false // 特別管理加算１
    // public is_tokubetu_kanri_2: boolean = false // 特別管理加算２
    public is_taiin_shidou: boolean = false // 退院時共同指導加算
    public is_houmon_kaisuu_chouka: boolean = false
    // public is_24h: boolean = false // ２４時間対応体制加算
    public is_taiin_shien: boolean = false // 退院支援指導加算
    // public is_zaitaku_renkei_shidou: boolean = false // 在宅患者連携指導加算
    public is_tokubetu_kanri_shidou: boolean = false // 特別管理指導加算
    // public is_seishin_juushou_shien_1: boolean = false // 精神科重症患者支援管理連携加算１
    // public is_seishin_juushou_shien_2: boolean = false // 精神科重症患者支援管理連携加算２
    public is_renkei_kyouka: boolean = false // 看護・介護職員連携強化加算
    // public is_jouhou_teikyo_1: boolean = false // 訪問看護情報提供療養費１
    // public is_jouhou_teikyo_2: boolean = false // 訪問看護情報提供療養費２
    // public is_jouhou_teikyo_3: boolean = false // 訪問看護情報提供療養費３
    public is_terminal_care_1: boolean = false // 訪問看護ターミナルケア療養費１
    public is_terminal_care_2: boolean = false // 訪問看護ターミナルケア療養費２
    public is_senmon_tokubetsu_kanri_1: boolean = false
    public is_senmon_tokubetsu_kanri_2: boolean = false
    public is_taiinshidou_chou: boolean = false
    public is_zaitaku_renkei_shidou: boolean = false
}

export class ServerSideKasan {
    public service_code: ServiceCode = new ServiceCode()
    public month_coun: number = -1
    public week_count: number = -1
    public day_count: number = -1
    public kasan_common: KasanCommon = new KasanCommon()
    public kasan_basic: KasanBasic = new KasanBasic()
    public kasan_seishin: KasanSeishin = new KasanSeishin()
}

export class Omaha {
    public details: OmahaDetail[] = []

    public infection: string = ""
    public clean: string = ""
    public medicine: string = ""
    public drug: string = ""
    public social: string = ""
    public role: string = ""
    public environment: string = ""
    public sense: string = ""
    public perinatal: string = ""
} // outer

export class OmahaDetail {
    public problem_id: number = 0
    public intervention: OmahaInterv[] = []
    public score_k: number = 3
    public score_b: number = 3
    public score_s: number = 3
    public comment: string = ""
}

export class OmahaInterv {
    public intervention_id: number = 0
    public check: boolean = false
    public comment: string = ""
    public detail: Intervention | null = null
}

//バイタルサイン
export class Vital {
    public first: VitalData = new VitalData()
    public saiken?: VitalData = undefined

    public taiju: number | null = null
    public taiju_unit: string = "kg"
    public shincho: number | null = null
}

export class VitalData {
    public ketsuatsu_min: number | null = null
    public ketsuatsu_max: number | null = null
    public taion: number | null = null
    public spo2: number | null = null
    public kokyu_kaisu: number | null = null
    public myakuhaku: number | null = null
    public myakuhaku_husei: boolean = false
    public kettou_value: number | null = null
    public comment: string = ""
}

// 皮膚
export class Skin {
    public comment: string = ""
    public docImage: Document.Document | null = null
    public breden_doc: Document.Document | null = null
    public designr_doc: Document.Document | null = null
    public designr_docs: Document.Document[] = []
    public jokuso: SkinJokuso[] = []
    public other: SkinOther[] = []

}

export class SkinSenjo {
    public nankou: string = ""
    public himakuzai: string = ""
}
export class SkinJokuso {
    public d: DesignR_D = DesignR_D.None
    public e: DesignR_E = DesignR_E.None
    public s: DesignR_S = DesignR_S.None
    public i: DesignR_I = DesignR_I.None
    public g: DesignR_G = DesignR_G.None
    public n: DesignR_N = DesignR_N.None
    public p: DesignR_P = DesignR_P.None
    public score: number = 0
    public docImage: Document.Document | null = null
    public senjo: SkinSenjo = new SkinSenjo()
    public hakken_date: string | null = null

    static calcScore(score: SkinJokuso) {
        const toi = (x) => {
            if (!x || x == "") return 0;
            const r = x.match(/[0-9]+/)
            if (!r || r.length == 0) return 0
            const p = parseInt(r[0])
            if (p) return p
            return 0;
        }

        return toi(score.e) +
            toi(score.s) + toi(score.i) +
            toi(score.g) + toi(score.n) +
            toi(score.p)
    }
    static toCode(x: string) {
        if (!x || x == "") return ""
        const r = x.match(/^.*(?=:)/)
        if (!r || r.length == 0) return ""
        return r[0];
    }
}

export class SkinOther {
    public title = SkinOtherTitle.None
    public title_other: string = ""
    public docImage: Document.Document | null = null
    public comment: string = ""
    public senjo: SkinSenjo = new SkinSenjo()
    public hakken_date: string | null = null
}

export enum SkinOtherTitle {
    None = "未選択",
    Shisshin = "湿疹",
    Sukintea = "スキンテア",
    Suihou = "水泡",
    Kaiyou = "潰瘍",
    Biran = "びらん",
    Jinmashin = "蕁麻疹",
    TenjouShukketsu = "点状出血",
    Soubu = "創部",
    Kansen = "感染",
    Hasseki = "発赤",
    Other = "その他"
}



export enum DesignR_D {
    None = "未選択",
    D0 = "d0: 皮膚損傷・発赤なし",
    D1 = "d1: 持続する発赤",
    D2 = "d2: 真皮までの損傷",
    D3 = "D3: 皮下組織までの損傷",
    D4 = "D4: 皮下組織を越える損傷",
    D5 = "D5: 関節腔，体腔に至る損傷",
    DTI = "DTI: 深部損傷褥瘡(DTI)の疑い※2",
    D6 = "DU: 深さが判定不能の場合"
}

export enum DesignR_E {
    None = "未選択",
    E0 = "e0: なし",
    E1 = "e1: 少量(毎日のドレッシング交換を要しない)",
    E3 = "e3: 中等量(1回 / 日ドレッシング交換)",
    E6 = "E6: 多量(2回 / 日以上ドレッシング交換)"
}

export enum DesignR_S {
    None = "未選択",
    S0 = "s0: 皮膚損傷なし",
    S3 = "s3: 4未満",
    S6 = "s6: 4以上16未満",
    S8 = "s8: 16以上36未満",
    S9 = "s9: 36以上64未満",
    S12 = "s12: 64以上100未満",
    S15 = "S15: 100以上"
}

export enum DesignR_I {
    None = "未選択",
    I0 = "i0: 局所の炎症兆候なし",
    I1 = "i1: 局所の炎症兆候あり(創周囲の発赤、腫脹、熱感、疼痛)",
    I3C = "I3C: 臨界的定着疑い（創面にぬめりがあり、滲出液が多い。肉芽があれば、浮腫性で脆弱など）※5",
    I3 = "I3: 局所の明らかな感染兆候あり(炎症兆候、膿、悪臭など)※5",
    I9 = "I9: 全身的影響あり(発熱あり)",
}

export enum DesignR_G {
    None = "未選択",
    G0 = "g0: 評価不可: 治癒あるいは創が浅い",
    G1 = "g1: 良性肉芽が創面の90%以上を占める",
    G3 = "g3: 良性肉芽が創面の50%以上90%未満を占める",
    G4 = "G4: 良性肉芽が創面の10%以上50%未満を占める",
    G5 = "G5: 良性肉芽が創面の10%未満を占める",
    G6 = "G6: 良性肉芽が全く形成されない"
}

export enum DesignR_N {
    None = "未選択",
    N0 = "n0: 壊死組織なし",
    N3 = "N3: 柔らかい壊死組織あり",
    N6 = "N6: 硬く厚い密着した壊死組織あり"
}

export enum DesignR_P {
    None = "未選択",
    P0 = "p0: ポケットなし",
    P6 = "P6: 4未満",
    P9 = "P9: 4以上16未満",
    P12 = "P12: 16以上36未満",
    P24 = "P24: 36以上"
}
export enum AriNashi {
    Nashi = "なし",
    Ari = "あり"
}

// 呼吸
export class Breath {
    public basic_is_gaisou: AriNashi = AriNashi.Nashi
    public basic_is_zenmei: AriNashi = AriNashi.Nashi
    public kokyuon_ijou: BreathIjou = new BreathIjou()

    public tan_comment: string = ""
    public haitan_care: string = ""

    public kikan_sekkai: string = ""
    public kikan_sekkai_kahu_atsu_check: boolean = false

    public hot_sanso_touyo_ryou: string | null = null
    public hot_kiki_kakunin_filter: boolean = false
    public hot_kiki_kakunin_mizu: boolean = false
    public hot_kiki_kakunin_konsento: boolean = false
    public hot_kiki_kakunin_yobi_bonbe: boolean = false


    public kikan_sekkai_koukan_date: string | null = null
    public kikan_sekkai_kahu_atsu: number | null = null
    public kikan_sekkai_hihu_trouble: BreathHihuTrouble[] = []
    public kikan_sekkai_yakuzai: string = ""
    public kikan_sekkai_kyuin: number | null = null

    public jinko_kokyuki_kairo_koukan_check: boolean = false

    public jinko_kokyuki_type = BreathJinkoKokyukiType.None
    public jinko_kokyuki_setting_image: Document.Document | null = null
    public jinko_kokyuki_tv: number | null = null
    public jinko_kokyuki_mv: number | null = null
    public jinko_kokyuki_rr: number | null = null
    public jinko_kokyuki_pip: number | null = null
    public jinko_kokyuki_leak: number | null = null
    public jinko_kokyuki_sanso: number | null = null
    public jinko_kokyuki_other: string = ""
    public jinko_kokyuki_kaon_kashitsu: string = ""
    public jinko_kokyuki_is_kairo_koukan: AriNashi = AriNashi.Nashi
    public jinko_kokyuki_comment: string = ""

    public kokyu_ku_type: string = KokyuKuType.NRS
    public kokyu_ku_borg: number | null = null
    public kokyu_ku_nrs: number | null = null
    public kokyu_ku_vas: number | null = null
    public kokyu_ku_honnin_said: string = ""

    public mmrc_doc: Document.Document | null = null
    public kensakekka_image: Document.Document | null = null

    public yakuzai: string = ""
    public yakuzai_kyuin_yaku: string = ""
    public yakuzai_other: string = ""
}

export enum BreathJinkoKokyukiType {
    None = "未選択",
    CPAP = "CPAP",
    BiPAP = "BiPAP",
    IPPV = "人工呼吸器(IPPV)"
}

export enum BreathIjouBui {
    Kikanshi = "気管支",
    Zenya = "全野",
    MigiUe = "右上",
    MigiShita = "右下",
    HidariUe = "左上",
    HidariShita = "左下",
    SenoMigiUe = "背の右上",
    SenoMigiShita = "背の右下",
    SenoHidariue = "背の左上",
    SenoHidariShita = "背の左下"
}

export const breath_bui_to_prop_name = [
    { label: "気管支", key: "kikanshi" },
    { label: "全野", key: "zenya" },
    { label: "右上", key: "migi_ue" },
    { label: "右下", key: "migi_shita" },
    { label: "左上", key: "hidari_ue" },
    { label: "左下", key: "hidari_shita" },
    { label: "背の右上", key: "se_migi_ue" },
    { label: "背の右下", key: "se_migi_shita" },
    { label: "背の左上", key: "se_hidari_ue" },
    { label: "背の左下", key: "se_hidari_shita" }
]

export enum BreathSound {
    None = "清明",
    Weeds = "ウィーズ（気管支狭窄音）",
    Ronkai = "ロンカイ（いびき音）",
    FineKurakkuru = "ファインクラックル（捻髪音）",
    CourseKurakkuru = "コースクラックル（水泡音）"
}
export enum BreathSoundType {
    Yokobou = "—",
    Genjaku = "減弱",
    shoushitsu = "消失"
}

export class BreathIjou {
    public kikanshi: string = BreathSound.None
    public zenya: string = BreathSound.None
    public migi_ue: string = BreathSound.None
    public migi_shita: string = BreathSound.None
    public hidari_ue: string = BreathSound.None
    public hidari_shita: string = BreathSound.None
    public se_migi_ue: string = BreathSound.None
    public se_migi_shita: string = BreathSound.None
    public se_hidari_ue: string = BreathSound.None
    public se_hidari_shita: string = BreathSound.None
    public kikanshi_comment: string = ""
    public migi_ue_comment: string = ""
    public migi_shita_comment: string = ""
    public hidari_ue_comment: string = ""
    public hidari_shita_comment: string = ""
    public se_migi_ue_comment: string = ""
    public se_migi_shita_comment: string = ""
    public se_hidari_ue_comment: string = ""
    public se_hidari_shita_comment: string = ""

    public ijou_list: BreathIjouData[] = []
}

export class BreathIjouData {
    public bui: string = BreathIjouBui.Kikanshi
    public sound: string = BreathSound.None
    public sound_type: string = BreathSoundType.Yokobou
    public comment: string = ""
}

export class BreathHihuTrouble {
    public troubleType: BreathHihuTroubleType = BreathHihuTroubleType.None
    public troubleOther: string = ""
    public docImage: Document.Document | null = null
}

export enum BreathHihuTroubleType {
    None = "未選択",
    Nikuga = "肉芽",
    Biran = "びらん",
    Other = "その他"
}

export enum PainShukanType {
    NRS = "NRS",
    VAS = "VAS",
    FaceScale = "FaceScale"
}
export enum KokyuKuType {
    NRS = "NRS",
    VAS = "VAS",
    Borg = "Borg"
}

// 疼痛
export class Pain {
    public pain_type = PainType.None
    public pain_pattern = PainPattern.None

    public shukan_hyouka_type: string = PainShukanType.NRS
    public shukan_hyouka_nrs: number | null = null
    public shukan_hyouka_vas: number | null = null
    public shukan_hyouka_face_scale: number | null = null

    public stasj_doc: Document.Document | null = null
    public stasj_s1: number = 0
    public stasj_s2: number = 0
    public stasj_s3: number = 0
    public stasj_s4: number = 0
    public stasj_s5: number = 0
    public stasj_s6: number = 0
    public stasj_s7: number = 0
    public stasj_s8: number = 0
    public stasj_s9: number = 0

    public honnin_said: string = ""

    public yakuzai: string = ""
    public yakuzai_naihuku: string = ""
    public yakuzai_harituke: string = ""
    public yakuzai_chusha: string = ""
    public yakuzai_rescue: string = ""
}

export enum PainType {
    None = "未選択",
    ShinkeiSHougai = "神経障害性疼痛",
    Taisei = "体性痛（侵害受容性疼痛）",
    Naizou = "内臓痛（侵害受容性疼痛）"
}
export enum PainPattern {
    None = "未選択",
    Jizoku = "持続痛",
    Tosshutsu = "突出痛"
}


// 口腔
export class Oral {
    public ohat_document: Document.Document | null = null
    public rsst_document: Document.Document | null = null
    public mizunomi_document: Document.Document | null = null
    public food_document: Document.Document | null = null

    public eisei: string = ""
    public self_care: string = ""
    public enge: string = ""

    public shita_nado: string = ""
    public gijutsu: string = ""
    public susugi: string = ""
    public hamigaki_jissi: string = ""
    public riha_jissi: string = ""

    public sesshuryo: string = ""
    public keitai_shushoku: string = ""
    public keitai_hukushoku: string = ""
    public keitai_suibun: string = ""
    public shokuji_min: number | null = null
    public nodo_umu: AriNashi = AriNashi.Nashi

    public comment: string = ""
}

// 栄養
export class Nutrition {
    public halis: number | null = null
    public kansatsu_hisiatsu: number | null = null

    public sesshu: string = ""
    public yakuzai: string = ""

    public sesshu_shokuji: string = ""
    public sesshu_milk: string = ""
    public sesshu_suibun_value: number | null = null
    public sesshu_suibun_comment: string = ""
    public sesshu_eiyou_naiyou: string = ""
    public sesshu_eiyou_keiro = NutritionKeiro.None
    public sesshu_eiyou_value: number | null = null
    public sesshu_eiyou_suibun: number | null = null
    public sesshu_eiyou_trouble: string = ""
    public sesshu_eiyou_image: Document.Document | null = null
    public houshin: string = ""
    //public houshin_hojoshokuhin: string = ""
    //public houshin_sesshoku_shien: string = ""
    //public houshin_sisei: string = ""
    //public houshin_honnin_said: string = ""
    public kensa_kekka_image: Document.Document | null = null
}

export enum NutritionKeiro {
    None = "未選択",
    CVPort = "CVポート",
    CVCat = "CVカテーテル",
    Hana = "経鼻経管",
    Irou = "胃瘻",
    Keikou = "経口"
}

// 脱水
export class Dehydration {
    //public sesshu_ml: number | null = null // use nutrition
    //public sesshu_naiyou: string = "" // use nutrition
    public output_nyou: number | null = null
    public output_comment: string = ""
    public kansou_koukuu: string = ""
    public kansou_ekika: string = ""
    public yakuzai_tenteki: string = ""
}

// 排泄
export class Excretion {
    public haisetsu: string = ""
    public haiben: number | null = null
    public hainyou: number | null = null
    public hinben_hindo: string | null = ""
    public haiben_last: string = ""
    public haiben_keijou = ExcretionKeijo.None
    public haiben_ryou_scale = ExcretionKeijo.None
    public haiben_yakuzai: string = ""
    public haiben_stoma_image: Document.Document | null = null
    public haiben_stoma_height: number | null = null
    public haiben_stoma_width: number | null = null
    public haiben_stoma_accessory: string = ""
    public haiben_stoma_koukan_check: boolean = false
    public haiben_stoma_koukan_biko: string = ""
    public haiben_stoma_trouble: string = ""
    public haiben_stoma_yakuzai: string = ""
    public haiben_stoma_selfcare: string = ""
    public hainyou_bss: string = ""
    public haiben_scale: string = ""
    public hainyou_kansatsu_comment: string = ""
    public hainyou_kansatsu_scale: string = ""
    public hainyou_katetel: ExcretionKatetel[] = []
    public hainyou_jiko_dounyou_shidou: string = ""
    public hainyou_jiko_dounyou_ml: number | null = null
    public hainyou_jiko_dounyou_comment: string = ""
    public hainyou_yakuzai: string = ""
    public input_shokuji: string = ""
    public input_suibun: string = ""
    public input_nyou_ml: number | null = null
    public input_nyou_comment: string = ""
}
export class ExcretionKatetel {
    public katetel_type = ExcretionKatetelType.None
    public koukan_check: boolean = false
    public koukan_comment: string = ""
    public fr_memo: number | null = null
    public trouble: string = ""
    public image: Document.Document | null = null
}
export enum ExcretionKatetelType {
    None = "未選択",
    BoukouKat = "膀胱留置カテーテル",
    BoukouRou = "膀胱瘻",
    ZinRou = "腎瘻"
}

export enum ExcretionKeijo {
    None = "未選択",
    K1 = "1:コロコロ便",
    K2 = "2:硬い便",
    K3 = "3:やや硬い便",
    K4 = "4:普通便",
    K5 = "5:やや軟らかい便",
    K6 = "6:泥状便",
    K7 = "7:水様便"
}

export enum ExcretionScale {
    None = "未選択",
    S1 = "1:付着",
    S2 = "2:ウサギの糞",
    S3 = "3:ウズラの卵",
    S4 = "4:鶏卵",
    S5 = "5:バナナ１本分",
    S6 = "6:バナナ２本分以上（自由記載蘭へ）",
}

// 身体活動
export class Physical {
    public idou: string = ""
    public ijou: string = ""
    public mmt_document?: Document.Document = undefined
    public short_bbs_document?: Document.Document = undefined
    public bedside_mobility_document?: Document.Document = undefined
    public jiko_kouryoku_kan_document?: Document.Document = undefined
    public tentou_umu: AriNashi = AriNashi.Nashi
    public tentou_comment: string = ""
    public positioning_state: string = ""
    public jishu_training_state: string = ""
}

// 睡眠
export class Sleep {
    public suimin: string = ""
    public humin_pattern = SleepPattern.None
    public humin_comment: string = ""
    public honnin_said: string = ""
    public yakuzai: string = ""
}
export enum SleepPattern {
    None = "未選択",
    Souki = "早朝覚醒",
    Danmin = "断眠",
    Nyumin = "入眠障害",
    Senmin = "浅眠",
    Kamin = "過眠"
}

// 認知・意識
export class Cognition {
    public chukakuKioku: boolean = false
    public chukakuHandan: boolean = false
    public chukakuKentou: boolean = false
    public chukakuGengo: boolean = false
    public chukakuShikkou: boolean = false
    public chukakuShitsunin: boolean = false
    public chukaku_other: string = ""

    public shuhenSenmou: boolean = false
    public shuhenYokuutsu: boolean = false
    public shuhenKouhun: boolean = false
    public shuhenHaikai: boolean = false
    public shuhenSuimin: boolean = false
    public shuhenMousou: boolean = false
    public shuhen_other = ""

    public komari: string = ""
    public tsuyomi: string = ""
    public ishiki: string = ""
    public keiren: string = ""
}
export enum CognitionChukaku {
    Kioku = "記憶障害",
    Handan = "判断力低下",
    Kentou = "見当識障害",
    Gengo = "言語障害",
    Shikkou = "失行",
    Shitsunin = "失認",
    Other = "その他"
}

export enum CognitionShuhen {
    Senmou = "せん妄",
    Yokuutsu = "抑うつ",
    Kouhun = "興奮",
    Haikai = "徘徊",
    Suimin = "睡眠障害",
    Mousou = "妄想",
    Other = "その他"
}

// 循環
export class Circulation {
    public ketsuatsu_l_min: number | null = null
    public ketsuatsu_l_max: number | null = null
    public ketsuatsu_r_min: number | null = null
    public ketsuatsu_r_max: number | null = null
    public junkan: string = ""
    public yakuzai: string = ""
    public selfcare: string = ""

    public inout_nyou: number | null = null
    public input_comment: string = ""
    public mukumi = CirculationMukumi.None
    public mukumiList: CirculationMukumiData[] = []
    public shinhuzen_choukou_cvp: number | null = null
    public shinhuzen_choukou_nyha = CirculationNYHA.None
    public shinhuzen_yakuzai: string = ""
    public shinhuzen_selfcare: string = ""
}

export class CirculationMukumiData {
    public mukumi_type = CirculationMukumi.None
    public comment: string = ""
}

export enum CirculationMukumi {
    None = "未選択",
    Mukumi0 = "無し",
    Mukumi1 = "＋1",
    Mukumi2 = "＋２",
    Mukumi3 = "＋３",
    Mukumi4 = "＋４",
}
export enum CirculationNYHA {
    None = "未選択",
    NYHA1 = "Ⅰ度",
    NYHA2 = "Ⅱ度",
    NYHA3 = "Ⅲ度",
    NYHA4 = "Ⅳ度"
}
export enum PPIKeikuu {
    Keikuu1 = "正常",
    Keikuu2 = "中程度減少（減少しているが数口よりは多い）",
    Keikuu3 = "著明に減少（数口以下）"
}

// 精神状態
export class Mental {
    public shokuji_suibun_kusuri: string = ""
    public haisetsu_bunpitsu: string = ""
    public eisei: string = ""
    public katsudou_kyusok: string = ""
    public taijin: string = ""
    public anzen: string = ""

    public doc_mse: Document.Document | null = null
    public doc_selfcare: Document.Document | null = null
    public doc_strength1: Document.Document | null = null
    public doc_wrap: Document.Document | null = null
    public doc_gaf: Document.Document | null = null

    public choukou_kibun_henchou: string = ""
    public choukou_yousei: string = ""
    public choukou_insei: string = ""
    public choukou_ninchi: string = ""
    public taisho: string = ""
    public sign: string = ""
    public yakuzai_status: string = ""
    public yakuzai_shohou_image: Document.Document | null = null
    public honnin_said: string = ""
    public kazoku_said: string = ""
}

// 代謝・内分泌
export class Metabolism {
    public kettou_naiyou: string = ""
    public kettou_value: number | null = null //ded
    public kettou_last_shokuji_time: string = ""
    public kettou_note_image: Document.Document | null = null
    public kettou_self_care: string = ""
    public kensakekka_image: Document.Document | null = null
    public kensakekka_comment: string = ""
    public yakuzai_shohou_image: Document.Document | null = null
    public touseki_houhou: MetabolismTousekiHouhou = MetabolismTousekiHouhou.None
    public touseki_kanri: string = ""
    public touseki_image: Document.Document | null = null
    public koujousen_choukou: string = ""
}

export enum MetabolismTousekiHouhou {
    None = "未選択",
    Katetel = "カテーテル",
    Shanto = "内シャント",
    PD = "PD"
}

// 発達・成長
export class Growth {
    assessment_document?: Document.Document = undefined
    public toui: number | null = null
    public kyoui: number | null = null
    public hattatsu_sodai: string = ""
    public hattatsu_bisai: string = ""
    public hattatsu_gengo: string = ""
    public hattatsu_ninchi: string = ""
    public hattatsu_shokuji: string = ""
}

// エンドオブライフケア
export class EOL {
    public ppi: number | null = null
    public pps: number | null = null
    public ppi_keikuu: string = PPIKeikuu.Keikuu1
    public ppi_mukumi: string = "なし"
    public ppi_kokyu_konnnan: string = "なし"
    public ppi_senmou: string = "なし"
    public honnin_said: string = ""
    public kazoku_said: string = ""
    public acl_document?: Document.Document = undefined
}

// 介護・養育
export class Kaigo {
    kiki: string = ""
    shakai_shigen: string = ""
    henkou_need: string = ""
    kazoku_ninchi: string = ""
}

// 処置・点滴
export class Deal {
    public naiyou: string = ""
    public bikou: string = ""
    public naiyou_docImage: Document.Document | null = null
    public moushiokuri: string = ""
    public moushiokuri_docImage: Document.Document | null = null
    public assessment: string = ""
}

// 言語
export class Lang {
    communication: string = ""
    meiryou_document: Document.Document | null = null
    kouon_shougai: AriNashi = AriNashi.Nashi
    kouon_type: KouonType = KouonType.None
    shitsugo: AriNashi = AriNashi.Nashi
    shitsugo_type: string = ""
}

export enum KouonType {
    None = "未選択",
    Undou = "運動障害生",
    Kishitsu = "器質性",
    Kinou = "機能性"
}

export class NeuroMuscul {
    naiyou: string = ""
    kin_kinchou = ""
    keiren = ""
    koushuku = ""
    shochi = ""
}