import React, { FC, useState, ChangeEvent } from "react";
import { Select, MenuItem, TextField } from "@material-ui/core";

type JapaneseCalendarDateInputProps = {
  value?: string;
  setData: (value: any) => void;
};

type YearType = "westernYear" | "taisyou" | "syouwa" | "heisei" | "reiwa";

const convertToWesternYear = (year: number, type: YearType): number => {
  if (type === "taisyou") return year + 1911;
  else if (type === "syouwa") return year + 1925;
  else if (type === "heisei") return year + 1988;
  else if (type === "reiwa") return year + 2018;
  return year;
};

const isValidYear = (year: number, type: YearType): boolean => {
  if (year < 1) return false;

  if (type === "westernYear" && year > new Date().getFullYear()) return false;

  if (type === "taisyou" && year > 15) return false;

  if (type === "syouwa" && year > 64) return false;

  if (type === "heisei" && year > 31) return false;

  if (type === "reiwa" && year + 2018 > new Date().getFullYear()) return false;

  return true;
};

const getLastDate = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

export const JapaneseCalendarDateInput: FC<JapaneseCalendarDateInputProps> = ({
  value,
  setData,
}) => {
  const [yearType, setYearType] = useState<YearType>("westernYear");
  const today = new Date();
  const [initialYear, initialMonth, initialDay] =
    (value !== undefined && value !== null)
      ? value.split("-").map((v) => parseInt(v))
      : [today.getFullYear(), today.getMonth() + 1, today.getDate()];

  const [year, setYear] = useState(initialYear);
  const [month, setMonth] = useState(initialMonth);
  const [day, setDay] = useState(initialDay);

  const onYearTypeChange = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setYearType(e.target.value as YearType);
    const restedYear =
      e.target.value === "westernYear" ? today.getFullYear() : 1;
    setYear(restedYear);

    const westernYear = convertToWesternYear(restedYear, yearType);
    setData(
      `${westernYear}-${String(month).padStart(2, "0")}-${String(day).padStart(
        2,
        "0"
      )}`
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <div>
        <Select variant="outlined" value={yearType} onChange={onYearTypeChange}>
          <MenuItem value="westernYear">西暦</MenuItem>
          <MenuItem value="taisyou">大正</MenuItem>
          <MenuItem value="syouwa">昭和</MenuItem>
          <MenuItem value="heisei">平成</MenuItem>
          <MenuItem value="reiwa">令和</MenuItem>
        </Select>
      </div>
      <label style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <TextField
          type="number"
          size="small"
          value={String(year)}
          onChange={(e) => {
            if (!e.target.value) {
              setData(null)
              return;
            }
            const num = parseInt(e.target.value);
            if (!isValidYear(num, yearType)) return;
            setYear(num);
            const westernYear = convertToWesternYear(num, yearType);
            setData(
              `${westernYear}-${String(month).padStart(2, "0")}-${String(
                day
              ).padStart(2, "0")}`
            );
          }}
          margin="none"
          variant="outlined"
          multiline={false}
          style={{ width: "90px" }}
        />
        <span>年</span>
      </label>
      <label style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <TextField
          type="number"
          size="small"
          value={String(month)}
          onChange={(e) => {
            if (!e.target.value) {
              setData(null)
              return;
            }
            const num = parseInt(e.target.value);
            if (num < 1 || num > 12) return;
            setMonth(num);
            const westernYear = convertToWesternYear(year, yearType);
            setData(
              `${westernYear}-${String(num).padStart(2, "0")}-${String(
                day
              ).padStart(2, "0")}`
            );
          }}
          margin="none"
          variant="outlined"
          multiline={false}
          style={{ width: "80px" }}
        />
        <span>月</span>
      </label>
      <label style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <TextField
          type="number"
          size="small"
          value={String(day)}
          onChange={(e) => {
            if (!e.target.value) {
              setData(null)
              return;
            }
            const num = parseInt(e.target.value);
            const lastDate = getLastDate(year, month);
            if (num < 1 || num > lastDate) return;
            setDay(num);
            const westernYear = convertToWesternYear(year, yearType);
            setData(
              `${westernYear}-${String(month).padStart(2, "0")}-${String(
                num
              ).padStart(2, "0")}`
            );
          }}
          margin="none"
          variant="outlined"
          multiline={false}
          style={{ width: "80px" }}
        />
        <span>日</span>
      </label>
    </div>
  );
};
