export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public c_jouhou_1: string = "";
  public c_jouhou_2: string = "";
  public c_jouhou_3: string = "";
  public c_member_1: string = "";
  public c_member_2: string = "";
  public c_member_3: string = "";
  public c_share_1: string = "";
  public c_share_2: string = "";
  public c_share_3: string = "";
  public create_date: string = "";
  public date_1: string = "";
  public date_2: string = "";
  public date_3: string = "";
  public h_care_1: string = "";
  public h_care_2: string = "";
  public h_care_3: string = "";
  public h_henka_1: string = "";
  public h_henka_2: string = "";
  public h_henka_3: string = "";
  public h_ikou_1: string = "";
  public h_ikou_2: string = "";
  public h_ikou_3: string = "";
  public h_ishi_1: string = "";
  public h_ishi_2: string = "";
  public h_ishi_3: string = "";
  public h_nozomi_1: string = "";
  public h_nozomi_2: string = "";
  public h_nozomi_3: string = "";
  public h_rikai_1_1: string = "";
  public h_rikai_1_2: string = "";
  public h_rikai_1_3: string = "";
  public h_rikai_2_1: string = "";
  public h_rikai_2_2: string = "";
  public h_rikai_2_3: string = "";
  public h_ryui_1: string = "";
  public h_ryui_2: string = "";
  public h_ryui_3: string = "";
  public h_setsumei_1: string = "";
  public h_setsumei_2: string = "";
  public h_setsumei_3: string = "";
  public k_care_1: string = "";
  public k_care_2: string = "";
  public k_care_3: string = "";
  public k_henka_1: string = "";
  public k_henka_2: string = "";
  public k_henka_3: string = "";
  public k_ikou_1: string = "";
  public k_ikou_2: string = "";
  public k_ikou_3: string = "";
  public k_ishi_1: string = "";
  public k_ishi_2: string = "";
  public k_ishi_3: string = "";
  public k_nozomi_1: string = "";
  public k_nozomi_2: string = "";
  public k_nozomi_3: string = "";
  public k_rikai_1_1: string = "";
  public k_rikai_1_2: string = "";
  public k_rikai_1_3: string = "";
  public k_rikai_2_1: string = "";
  public k_rikai_2_2: string = "";
  public k_rikai_2_3: string = "";
  public k_ryui_1: string = "";
  public k_ryui_2: string = "";
  public k_ryui_3: string = "";
  public k_setsumei_1: string = "";
  public k_setsumei_2: string = "";
  public k_setsumei_3: string = "";
  public k_taishou_1: string = "";
  public k_taishou_2: string = "";
  public k_taishou_3: string = "";
  public pat_name: string = "";
  public sign_1: string = "";
  public sign_2: string = "";
  public sign_3: string = "";
}

export class Checks {

}
