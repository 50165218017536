import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import * as Model from '../models'
import Loading from '../components/Loading';
import * as HokanApi from '../api/hokan'
import {
    Button, MenuItem, Select
} from '@material-ui/core';
//import * as Icons from '@material-ui/icons';
import { KirokuType } from '../models/KangoKirokuII';
import * as Colors from '../components/Colors'
import { StoreAccessor } from '../util/StoreUtil';
import SimpleDialog from '../components/SimpleDialog';
import KirokuSelectDialog from '../components/KirokuSelectDialog'
import * as KirokuIIEditViews from '../components/KirokuIIEditViews'
import * as KirokuIIEditViews2 from '../components/KirokuIIEditViews2'
import * as KirokuIIEditViews3 from '../components/KirokuIIEditViews3'
import ShuttenAll from '../components/ShuttenAll'
import TermConfirmDialog from '../components/TermConfirmDialog';
import DateUtil from '../util/DateUtil';


const styles = () => Styles.createStyles({
    root: {
        margin: "0px",
        width: "100%",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "100px"
    },
    innerTable: {
        padding: '0px'
    },
    tooltipRoot: {
        display: "inline-block",
        marginLeft: "10px",
        verticalAlign: "middle",
    },
    tooltipDiv: {
        fontSize: "0.9rem",
        color: "white"
    },
    tooltipTitle: {
        fontWeight: "bold",
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    tooltipLine: {
        borderTop: "solid",
        borderWidth: "1px",
        borderColor: "lightgray",
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    panelOpen: {
        margin: "0px",
        color: Colors.primaryColor,
        background: Colors.orangeColor,
        position: "sticky",
        top: "55px",
        zIndex: 2,
        padding: "5px",
        fontSize: "1.0rem",
        minHeight: "2rem !important"
    },
    panelClose: {
        margin: "0px",
        color: Colors.white,
        background: Colors.primaryColor,
        padding: "5px",
        marginTop: "5px",
        fontSize: "1.0rem",
        minHeight: "2rem !important"
    },
    panelContent: {
        margin: "0px !important"
    },
    panelIcon: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    filter_button_d: {
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    copy_button: {
        marginBottom: "15px",
        marginLeft: "5px"
    }
});

interface Props extends PageProps, Styles.WithStyles {
    isActive: boolean,
    patient_id: number,
    kiroku_id: number,
    onRemoveTab: any
}

interface LocalState {
    kiroku: Model.KirokuII.KangoKirokuII,
    latestKiroku: Model.KirokuII.KangoKirokuII | null,
    latestKirokuList: Model.KirokuII.KangoKirokuII[],
    loading: boolean,
    isEditing: boolean,
    cache: any,
    assessments: Model.Assessment.AssessmentListResult[],
    filterChecked: { name: string, checked: boolean }[],
    dialogOpenFlag: boolean,
    patient: Model.Patient.Patient,
    openDelete: boolean,
    openPastCopy: string,
    serviceCodeFilter: string,
    problemList: Model.Problem[],
    posting: boolean,
    tempPosted: boolean,
    postError: string | null,
    openConfirm: boolean,
    serverSideKasan: Model.KirokuII.ServerSideKasan,
    cur_log: number | null,
    cur_kiroku: Model.KirokuII.KangoKirokuII | null,
}

// @inject()
// @observer
class KangoKirokuPage extends React.Component<Props, LocalState> {
    displayName = "KangoKirokuPage"
    private getDefaultChecks(checked: boolean) {
        return [
            '皮膚', '呼吸', '痛み', '口腔',
            '言語', '栄養・代謝', '脱水', '排泄',
            '身体活動', '睡眠', '認知・意識', '循環',
            '精神状態', '内分泌', '発達・成長',
            "神経・筋・骨格", 'ACP/EOL',
            '介護・養育', "申し送り事項", "その他処置等",
            '感染管理', '清潔整容', "薬剤管理や受診", "口腔衛生", "メンタルヘルス",
            'ドラッグ・嗜好品など', '社会', '成長と発達', '役割・セクシャリティ',
            '環境・経済', '感覚', '周産期・生殖'
        ].map(x => { return { name: x, checked: checked } })
    }

    constructor(props: Props) {
        super(props)
        const kiroku = new Model.KirokuII.KangoKirokuII()
        this.state = {
            kiroku: kiroku,
            latestKiroku: null,
            latestKirokuList: [],
            cache: {},
            loading: true,
            isEditing: true,
            assessments: [],
            dialogOpenFlag: false,
            filterChecked: this.getDefaultChecks(false),
            openDelete: false,
            openPastCopy: "",
            patient: new Model.Patient.Patient(),
            serviceCodeFilter: "",
            problemList: [],
            posting: false,
            postError: null,
            tempPosted: false,
            openConfirm: false,
            serverSideKasan: new Model.KirokuII.ServerSideKasan(),
            cur_log: null,
            cur_kiroku: null
        }
    }

    public loadPastKiroku = (kiroku_id) => {
        const kiroku = this.state.kiroku
        this.setState({ loading: true })
        HokanApi.getKirokuII(this.props.patient_id, kiroku_id)
            .then(x => {
                let k = Model.KirokuII.KangoKirokuII.load(x.data)
                k.time_start = kiroku.time_start
                k.time_end = kiroku.time_end
                k.main_user = kiroku.main_user
                k.vital = kiroku.vital
                k.is_temp = kiroku.is_temp
                k.basic.doukou_main = kiroku.basic.doukou_main;
                k.basic.doukou_edu = kiroku.basic.doukou_edu;
                k.basic.doukou_hikitsugi = kiroku.basic.doukou_hikitsugi;
                k.basic.doukou_list = kiroku.basic.doukou_list
                k.basic.version = kiroku.basic.version;
                k.basic.kasan = new Model.KirokuII.Kasan()
                k.basic.is_kinkyu = k.basic.is_teiki = k.basic.is_hiwari = k.basic.doukou_is_hojo = false;
                k.id = kiroku.id
                Model.KirokuII.KangoKirokuII.resetDocuments(k, "all")
                let checks = this.getDefaultChecks(false)
                if (k.basic.checks) {
                    for (let i in checks) {
                        const t = k.basic.checks.find(x => x.name == checks[i].name)
                        checks[i].checked = (t && t.checked) ? true : false
                    }
                }
                k.basic.checks = checks

                if (kiroku.basic.version == Model.KirokuII.VERSION_1_0 || kiroku.basic.version == Model.KirokuII.VERSION_2_0) {
                    k.omaha.details.forEach(o => {
                        o.intervention.forEach(intv => {
                            intv.check = false
                        })
                        //o.comment = ""
                    })
                }
                else {
                    k.omaha.details = kiroku.omaha.details
                }
                this.setState({
                    openPastCopy: "",
                    kiroku: k,
                    loading: false,
                    filterChecked: checks
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    public loadServerSideKasan = () => {
        const kiroku = this.state.kiroku
        const shikaku = kiroku.basic.shikaku.toString()
        const iryoCode = kiroku.basic.iryo_code
        HokanApi.getServerSideKasan(this.props.patient_id,
            kiroku.time_start, kiroku.time_end,
            {
                is_post: shikaku == "2",
                is_jun: shikaku == "1",
                is_sen: (shikaku == "3" || shikaku == "4" || shikaku == "5"),
                is_kaigo: Model.KirokuII.KangoKirokuII.isKaigoHoken(kiroku),
                is_iryo: Model.KirokuII.KangoKirokuII.isIryoHoken(kiroku),
                is_seishin: Model.KirokuII.KangoKirokuII.isSeishinHoken(kiroku),
                is_ryouyou1: iryoCode == Model.KirokuII.IryoCodeEnum.IryoI,
                is_ryouyou2: iryoCode == Model.KirokuII.IryoCodeEnum.IryoII,
                is_ryouyou3: iryoCode == Model.KirokuII.IryoCodeEnum.IryoIII,
                is_ryouyou4: iryoCode == Model.KirokuII.IryoCodeEnum.IryoIV,
                is_teiki: kiroku.basic.is_teiki,
                is_hiwari: kiroku.basic.is_hiwari
            }).then(res => {
                const d = res.data
                if (Model.KirokuII.KangoKirokuII.isKaigoHoken(kiroku)) {
                    if (d.service_code.code_type == "13") {
                        kiroku.service_code = d.service_code
                    }
                }
                else {
                    //Object.keys(kiroku.basic.kasan.common).forEach(x => kiroku.basic.kasan.common[x] = false)
                    //Object.keys(kiroku.basic.kasan.basic).forEach(x => kiroku.basic.kasan.basic[x] = false)
                    //Object.keys(kiroku.basic.kasan.seishin).forEach(x => kiroku.basic.kasan.seishin[x] = false)
                    if (d.kasan_common) {
                        Object.keys(d.kasan_common).forEach(x => {
                            if (!kiroku.basic.kasan.common[x]) {
                                kiroku.basic.kasan.common[x] = (d.kasan_common[x] == true)
                            }
                        })
                    }
                    if (d.kasan_basic) {
                        Object.keys(d.kasan_basic).forEach(x => {
                            if (!kiroku.basic.kasan.basic[x]) {
                                kiroku.basic.kasan.basic[x] = (d.kasan_basic[x] == true)
                            }
                        })
                        kiroku.basic.kasan.basic.is_kinkyu = kiroku.basic.is_kinkyu
                    }
                    if (d.kasan_seishin) {
                        Object.keys(d.kasan_seishin).forEach(x => {
                            if (!kiroku.basic.kasan.seishin[x]) {
                                kiroku.basic.kasan.seishin[x] = (d.kasan_seishin[x] == true)
                            }
                        })
                        kiroku.basic.kasan.basic.is_kinkyu = kiroku.basic.is_kinkyu
                    }
                    kiroku.service_code = d.service_code
                }
                this.setState({ kiroku: kiroku })
            }).catch(err => {
                console.log("err")
            })
    }

    public componentDidMount = () => {
        this.setState({ loading: true })

        HokanApi.getProblems()
            .then(x => {
                this.setState({ problemList: x.data })
            })
            .catch(err => {
                console.log(err)
            })

        HokanApi.getPatient(this.props.patient_id)
            .then(x => {
                const p = Model.Patient.Patient.load(x.data)
                const openConfirm = (this.props.kiroku_id == 0 && !(p.terms_confirmed || p.terms_disagree))
                this.setState({ patient: p, openConfirm: openConfirm })
            }).then(() => {
                const getAsslist = async (time_lim?: string) => {
                    try {
                        const _assList = await HokanApi.getPatientAssessments(this.props.patient_id, time_lim)
                        const ass = _assList.data
                        const k = this.state.kiroku
                        k.omaha.details = ass.filter(a => a.newest.assessment.closed == false && a.newest.assessment.priority == 1).map(a => {
                            const res = new Model.KirokuII.OmahaDetail()
                            res.problem_id = a.problem.id
                            res.comment = ""

                            a.newest.intervention = Model.Intervention.sort(a.newest.intervention)
                            a.newest.assessment_intervention.forEach(y => {
                                const t = new Model.KirokuII.OmahaInterv()
                                t.intervention_id = y.intervention_id
                                t.check = false
                                t.comment = y.comment
                                res.intervention.push(t)
                            })
                            res.score_k = a.newest.assessment.kbs_score_k
                            res.score_b = a.newest.assessment.kbs_score_b
                            res.score_s = a.newest.assessment.kbs_score_s
                            return res
                        })

                        this.setState({
                            assessments: ass,
                            kiroku: k
                        })
                    }
                    catch (err) {
                        console.log(err)
                    }
                }

                if (this.props.kiroku_id > 0) {
                    // Load
                    this.setState({ loading: true })
                    HokanApi.getKirokuII(this.props.patient_id, this.props.kiroku_id)
                        .then(async _k => {
                            let lk = Model.KirokuII.KangoKirokuII.load(_k.data)
                            await Model.KirokuII.KangoKirokuII.LoadImages(lk)
                            await getAsslist(lk.time_start)
                            const k = this.state.kiroku
                            let checks = this.getDefaultChecks(false)
                            if (lk.basic.checks) {
                                for (let i in checks) {
                                    const t = lk.basic.checks.find(y => y.name == checks[i].name)
                                    checks[i].checked = (t && t.checked) ? true : false
                                }
                            }
                            lk.basic.checks = checks
                            k.omaha.details.forEach(od => {
                                if (!lk.omaha.details.find(lod => od.problem_id == lod.problem_id)) {
                                    lk.omaha.details.push(od)
                                }
                            })
                            console.log(lk.omaha.details)
                            this.setState({
                                kiroku: lk,
                                loading: false,
                                filterChecked: checks
                            })

                            HokanApi.getKirokuIILatestN(this.props.patient_id, 5, lk.time_start)
                                .then(_lks => {
                                    let lks = _lks.data.map(y => Model.KirokuII.KangoKirokuII.load(y))
                                    this.setState({
                                        latestKiroku: lks.length > 0 ? lks[0] : null,
                                        latestKirokuList: lks,
                                    })
                                })
                                .catch(err => {
                                    console.log(err)
                                })
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                else {
                    // 新規作成
                    this.setState({ loading: true })
                    HokanApi.getKirokuIILatestN(this.props.patient_id, 5, null)
                        .then(async _lks => {
                            await getAsslist()
                            let k = this.state.kiroku
                            let lks = _lks.data.map(y => Model.KirokuII.KangoKirokuII.load(y))
                            let lk = lks.length > 0 ? lks[0] : null
                            const checks = this.getDefaultChecks(false)
                            if (lk && lk.basic.checks) {
                                for (let i in checks) {
                                    const t = lk.basic.checks.find(y => y.name == checks[i].name)
                                    checks[i].checked = (t && t.checked) ? true : false
                                }
                            }
                            k.basic.checks = checks
                            this.setState({
                                latestKiroku: lk,
                                latestKirokuList: lks,
                                kiroku: k,
                                filterChecked: checks
                            })
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    HokanApi.me().then(me => {
                        let k = this.state.kiroku
                        k.main_user = me.data
                        k.basic.shikaku = "0"
                        const ms = k.main_user.shikaku
                        if (ms == "4") k.basic.shikaku = "1"
                        else if (ms == "11" || ms == "12" || ms == "13") k.basic.shikaku = "2"
                        else if (ms == "9") k.basic.shikaku = "6"

                        k.basic.is_hiwari = this.state.patient.basic.is_hiwari
                        k.basic.is_teiki = this.state.patient.is_teiki_junkai
                        const ht = this.state.patient.hoken ? this.state.patient.hoken.hoken_type : Model.Patient.HokenType.None
                        k.kiroku_type = KirokuType.None
                        if (ht == Model.Patient.HokenType.Iryo) {
                            k.kiroku_type = KirokuType.IryoHoukan
                        } else if (ht == Model.Patient.HokenType.Kaigo) {
                            k.kiroku_type = KirokuType.KaigoHoukan
                        } else if (ht == Model.Patient.HokenType.KaigoYobo) {
                            k.kiroku_type = KirokuType.KaigoHoukan
                        } else if (ht == Model.Patient.HokenType.Seishin) {
                            k.kiroku_type = KirokuType.SeishinHoukan
                        }
                        k.service_code = this.state.patient.service_code
                        k.basic.iryo_code = Model.KirokuII.IryoCodeEnum.IryoI
                        this.setState({
                            kiroku: k,
                            loading: false,
                        })
                    }).catch(e => {
                        console.log(e)
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    public shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.isActive) {
            return true;
        }
        else {
            return false;
        }
    }

    public commitKiroku(is_temp = false) {
        const { kiroku } = this.state
        const { patient_id, kiroku_id } = this.props
        kiroku.is_temp = is_temp

        // clear unused kasan.
        if (Model.KirokuII.KangoKirokuII.isIryoHoken(kiroku)) {
            if (Model.KirokuII.KangoKirokuII.isSeishinHoken(kiroku)) {
                Object.keys(kiroku.basic.kasan.basic).forEach(x => kiroku.basic.kasan.basic[x] = false)
            }
            else {
                Object.keys(kiroku.basic.kasan.seishin).forEach(x => kiroku.basic.kasan.seishin[x] = false)
            }
        }
        else {
            Object.keys(kiroku.basic.kasan.common).forEach(x => kiroku.basic.kasan.common[x] = false)
            Object.keys(kiroku.basic.kasan.basic).forEach(x => kiroku.basic.kasan.basic[x] = false)
            Object.keys(kiroku.basic.kasan.seishin).forEach(x => kiroku.basic.kasan.seishin[x] = false)
        }

        const data = new Model.KirokuII.KangoKirokuIIPost(kiroku);
        this.setState({ posting: true, postError: "", tempPosted: false })
        if (kiroku_id > 0) {
            HokanApi.putKirokuII(patient_id, kiroku_id, data).then(x => {
                this.setState({ posting: false })
                this.props.onRemoveTab()

            }).catch(err => {
                this.setState({ posting: false, postError: "エラー" })
            })
        }
        else {
            HokanApi.postKirokuII(patient_id, data).then(x => {
                this.setState({ posting: false })
                this.props.onRemoveTab()
            }).catch(err => {
                this.setState({ posting: false, postError: "エラー" })
            })
        }
    }

    public deleteKiroku() {
        const { patient_id, kiroku_id } = this.props
        this.setState({ posting: true, postError: "" })
        if (kiroku_id > 0) {
            HokanApi.deleteKirokuII(patient_id, kiroku_id).then(x => {
                this.setState({ openDelete: false, posting: false })
                this.props.onRemoveTab()
            }).catch(err => {
                this.setState({ posting: false, postError: "エラー" })
            })
            this.setState({ isEditing: false, cache: {} })
        }
    }

    private copyTarget(src: Model.KirokuII.KangoKirokuII, target: Model.KirokuII.KangoKirokuII, elm_name: string) {
        const copyShinchoTaiju = () => {
            target.vital.taiju = src.vital.taiju
            target.vital.taiju_unit = src.vital.taiju_unit
            target.vital.shincho = src.vital.shincho
        }

        if (target.basic.version == Model.KirokuII.VERSION_2_0) {
            if (elm_name == KirokuIIEditViews2.Basic.getTitle()) {
                target.basic = src.basic
                target.basic.doukou_main = null;
                target.basic.doukou_edu = [];
                target.basic.doukou_hikitsugi = [];
            }
            else if (elm_name == KirokuIIEditViews2.Omaha.getTitle()) {
                src.omaha.details.forEach(d => {
                    const t = target.omaha.details.find(x => x.problem_id == d.problem_id)
                    if (t) {
                        t.comment = d.comment
                        t.score_k = d.score_k
                        t.score_b = d.score_b
                        t.score_s = d.score_s
                    }
                })
            }
            else if (elm_name == KirokuIIEditViews2.Vital.getTitle()) {
                target.vital.first = src.vital.first
                target.vital.saiken = src.vital.saiken
            }
            else if (elm_name == KirokuIIEditViews2.Skin.getTitle()) target.skin = src.skin
            else if (elm_name == KirokuIIEditViews2.Breath.getTitle()) target.breath = src.breath
            else if (elm_name == KirokuIIEditViews2.Pain.getTitle()) target.pain = src.pain
            else if (elm_name == KirokuIIEditViews2.Oral.getTitle()) target.oral = src.oral
            else if (elm_name == KirokuIIEditViews2.Lang.getTitle()) target.lang = src.lang
            else if (elm_name == KirokuIIEditViews2.Nutrition.getTitle()) {
                target.nutrition = src.nutrition
                copyShinchoTaiju()
            }
            else if (elm_name == KirokuIIEditViews2.Excre.getTitle()) target.excretion = src.excretion
            else if (elm_name == KirokuIIEditViews2.Sleep.getTitle()) target.sleep = src.sleep
            else if (elm_name == KirokuIIEditViews2.Cognition.getTitle()) {
                target.cognition = src.cognition
                target.vital.taiju = src.vital.taiju
                target.vital.taiju_unit = src.vital.taiju_unit
            }
            else if (elm_name == KirokuIIEditViews2.Circ.getTitle()) {
                target.circulation = src.circulation
            }
            else if (elm_name == KirokuIIEditViews2.Mental.getTitle()) target.mental = src.mental
            else if (elm_name == KirokuIIEditViews2.Metabo.getTitle()) target.metabolism = src.metabolism
            else if (elm_name == KirokuIIEditViews2.Growth.getTitle()) {
                target.growth = src.growth
                copyShinchoTaiju()
            } else if (elm_name == KirokuIIEditViews2.Neuro.getTitle()) {
                target.neuro_muscul = src.neuro_muscul
                target.physical = src.physical
            }
            else if (elm_name == KirokuIIEditViews2.EOL.getTitle()) target.eol = src.eol
            else if (elm_name == KirokuIIEditViews2.Kaigo.getTitle()) target.kaigo = src.kaigo
            else if (elm_name == KirokuIIEditViews2.Deal.getTitle()) {
                target.deal.naiyou = src.deal.naiyou
                target.deal.bikou = src.deal.bikou
            }
            else if (elm_name == KirokuIIEditViews2.Moushiokuri.getTitle()) {
                target.deal.moushiokuri = src.deal.moushiokuri
            }
        }
        else {
            if (elm_name == KirokuIIEditViews.Basic.getTitle()) {
                target.basic = src.basic
                target.basic.doukou_main = null;
                target.basic.doukou_edu = [];
                target.basic.doukou_hikitsugi = [];
            }
            else if (elm_name == KirokuIIEditViews.Omaha.getTitle()) {
                src.omaha.details.forEach(d => {
                    const t = target.omaha.details.find(x => x.problem_id == d.problem_id)
                    if (t) {
                        t.comment = d.comment
                        t.score_k = d.score_k
                        t.score_b = d.score_b
                        t.score_s = d.score_s
                    }
                })
            }
            else if (elm_name == KirokuIIEditViews.Vital.getTitle()) {
                target.vital.first = src.vital.first
                target.vital.saiken = src.vital.saiken
            }
            else if (elm_name == KirokuIIEditViews.Skin.getTitle()) target.skin = src.skin
            else if (elm_name == KirokuIIEditViews.Breath.getTitle()) target.breath = src.breath
            else if (elm_name == KirokuIIEditViews.Pain.getTitle()) target.pain = src.pain
            else if (elm_name == KirokuIIEditViews.Oral.getTitle()) target.oral = src.oral
            else if (elm_name == KirokuIIEditViews.Lang.getTitle()) target.lang = src.lang
            else if (elm_name == KirokuIIEditViews.Nutrition.getTitle()) {
                target.nutrition = src.nutrition
                copyShinchoTaiju()
            } else if (elm_name == KirokuIIEditViews.Dehyd.getTitle()) {
                target.dehydration = src.dehydration
                target.nutrition.sesshu_suibun_value = src.nutrition.sesshu_suibun_value
                target.nutrition.sesshu_suibun_comment = src.nutrition.sesshu_suibun_comment
            }
            else if (elm_name == KirokuIIEditViews.Excre.getTitle()) target.excretion = src.excretion
            else if (elm_name == KirokuIIEditViews.Physical.getTitle()) target.physical = src.physical
            else if (elm_name == KirokuIIEditViews.Sleep.getTitle()) target.sleep = src.sleep
            else if (elm_name == KirokuIIEditViews.Cognition.getTitle()) {
                target.cognition = src.cognition
                target.vital.taiju = src.vital.taiju
                target.vital.taiju_unit = src.vital.taiju_unit
            }
            else if (elm_name == KirokuIIEditViews.Circ.getTitle()) target.circulation = src.circulation
            else if (elm_name == KirokuIIEditViews.Mental.getTitle()) target.mental = src.mental
            else if (elm_name == KirokuIIEditViews.Metabo.getTitle()) target.metabolism = src.metabolism
            else if (elm_name == KirokuIIEditViews.Growth.getTitle()) {
                target.growth = src.growth
                copyShinchoTaiju()
            } else if (elm_name == KirokuIIEditViews.Neuro.getTitle()) target.neuro_muscul = src.neuro_muscul
            else if (elm_name == KirokuIIEditViews.EOL.getTitle()) target.eol = src.eol
            else if (elm_name == KirokuIIEditViews.Kaigo.getTitle()) target.kaigo = src.kaigo
            else if (elm_name == KirokuIIEditViews.Deal.getTitle()) {
                target.deal.naiyou = src.deal.naiyou
                target.deal.bikou = src.deal.bikou
            }
            else if (elm_name == KirokuIIEditViews.Moushiokuri.getTitle()) {
                target.deal.moushiokuri = src.deal.moushiokuri
            }
        }

        Model.KirokuII.KangoKirokuII.resetDocuments(target, elm_name)
    }
    private loadLog(logId: any | null) {
        const k = (this.state.cur_kiroku ? this.state.cur_kiroku : this.state.kiroku).version_list.find(x => x.id == logId)
        if (!logId || !k) {
            this.setState({
                cur_log: null,
                kiroku: this.state.cur_kiroku ? this.state.cur_kiroku : this.state.kiroku,
                cur_kiroku: null
            })
            return;
        }
        this.setState({
            cur_log: logId,
            kiroku: Model.KirokuII.KangoKirokuII.load(k.data),
            cur_kiroku: this.state.cur_kiroku ? this.state.cur_kiroku : this.state.kiroku
        });
    }
    public render() {
        if (!this.props.isActive) {
            return <div />
        }
        if (this.state.loading) {
            return <div style={{ marginTop: "150px" }}>
                <Loading />
            </div>
        }

        const { classes } = this.props
        const { kiroku } = this.state

        const sa = new StoreAccessor(this, this.state.cur_kiroku == null, "kiroku")
        const saPast = new StoreAccessor(this, false, "latestKiroku")

        const onCopyClick = (name) => {
            this.setState({ openPastCopy: name })
        }
        const onCopySelect = (res) => {
            const k = this.state.kiroku
            this.copyTarget(res, k, this.state.openPastCopy)
            this.setState({ openPastCopy: "", kiroku: k })
        }
        const panelOnChange = (e, f, c) => {
            if (!c) return
            c.checked = !c.checked
            const kiroku = this.state.kiroku
            kiroku.basic.checks = this.state.filterChecked
            this.setState({ filterChecked: this.state.filterChecked, kiroku: kiroku })
        }
        const view_props = {
            kiroku: kiroku,
            sa: sa,
            saPast: saPast,
            classes: classes,
            state: this.state,
            onCopyClick: (name) => onCopyClick(name),
            onCopySelect: (res) => onCopySelect(res),
            panelOnChange: (e, f, c) => panelOnChange(e, f, c),
            isEditing: this.state.isEditing,
            patient_id: this.props.patient_id,
            onLoadServerSideKasan: () => { this.loadServerSideKasan() }
        }
        let errorMessage = ""

        if (!kiroku.kiroku_type || kiroku.kiroku_type == Model.KirokuII.KirokuType.None) {
            errorMessage += "記録の種類を選択する必要があります。\n"
        }
        if (Model.KirokuII.KangoKirokuII.isJihi(kiroku) == false &&
            (Model.KirokuII.KangoKirokuII.isKaigoHoken(kiroku) && !kiroku.service_code) ||
            (Model.KirokuII.KangoKirokuII.isIryoHoken(kiroku) &&
            (!kiroku.basic.iryo_code || kiroku.basic.iryo_code == "未選択"))) {
            errorMessage += "算定コードを選択する必要があります。\n"
        }
        if (!kiroku.time_start || !kiroku.time_end || kiroku.time_start >= kiroku.time_end) {
            errorMessage += "終了時間は開始時間よりも後にする必要があります。\n"
        }
        if (!kiroku.main_user || kiroku.main_user.id == 0) {
            errorMessage += "スタッフを選択してください。\n"
        }

        let vl = (this.state.cur_kiroku ? this.state.cur_kiroku : this.state.kiroku).version_list
        return <div className={classes.root}>
            {
                <Button color="primary" variant="outlined"
                    onClick={(e) => this.setState({ openPastCopy: "all" })}
                    className={classes.copy_button}>
                    前回コピー
                </Button>
            }
            {
                this.state.openPastCopy == "all" ?
                    <KirokuSelectDialog
                        patient_id={this.props.patient_id}
                        problemList={this.state.problemList}
                        onSelect={(kiroku) => {
                            this.loadPastKiroku(kiroku.id)
                        }}
                        onCancel={() => { this.setState({ openPastCopy: "" }) }}
                    />
                    : null
            }
            {
                kiroku.basic.version == Model.KirokuII.VERSION_3_0 ?
                    <div key="ve">
                        <KirokuIIEditViews3.Basic {...view_props} />
                        <KirokuIIEditViews3.Deal {...view_props} />
                        <KirokuIIEditViews3.Vital {...view_props} />
                        <KirokuIIEditViews3.Skin {...view_props} />
                        <KirokuIIEditViews3.Breath {...view_props} />
                        <KirokuIIEditViews3.Circ {...view_props} />
                        <KirokuIIEditViews3.Pain {...view_props} />
                        <KirokuIIEditViews3.EOL {...view_props} />
                        <KirokuIIEditViews3.Kaigo {...view_props} />
                        <KirokuIIEditViews3.Simple {...view_props} title="感染管理" category="infection" omahaIDs={[42]} />
                        <KirokuIIEditViews3.Neuro {...view_props} />
                        <KirokuIIEditViews3.Simple {...view_props} title="清潔整容" category="clean" omahaIDs={[33]} />
                        <KirokuIIEditViews3.Excre {...view_props} />
                        <KirokuIIEditViews3.Simple {...view_props} title="薬剤管理や受診" category="medicine" omahaIDs={[36, 37]} />
                        <KirokuIIEditViews3.Nutrition {...view_props} />
                        <KirokuIIEditViews3.Oral {...view_props} />
                        <KirokuIIEditViews3.Lang {...view_props} />
                        <KirokuIIEditViews3.Mental {...view_props} />
                        <KirokuIIEditViews3.Sleep {...view_props} />
                        <KirokuIIEditViews3.Cognition {...view_props} />
                        <KirokuIIEditViews3.Simple {...view_props} title="ドラッグ・嗜好品など" category="drug" omahaIDs={[34]} />
                        <KirokuIIEditViews3.Simple {...view_props} title="社会" category="social" omahaIDs={[5, 6]} />
                        <KirokuIIEditViews3.Growth {...view_props} />
                        <KirokuIIEditViews3.Simple {...view_props} title="役割・セクシャリティ" category="role" omahaIDs={[7, 12]} />
                        <KirokuIIEditViews3.Simple {...view_props} title="環境・経済" category="environment" omahaIDs={[1, 2, 3, 4]} />
                        <KirokuIIEditViews3.Simple {...view_props} title="感覚" category="sense" omahaIDs={[17, 18]} />
                        <KirokuIIEditViews3.Simple {...view_props} title="周産期・生殖" category="perinatal" omahaIDs={[39, 40, 41, 35]} />
                    </div>
                    :
                    kiroku.basic.version == Model.KirokuII.VERSION_2_0 ?
                        <div key="ve">
                            <KirokuIIEditViews2.Basic {...view_props} />
                            <KirokuIIEditViews2.Vital {...view_props} />
                            <KirokuIIEditViews2.Skin {...view_props} />
                            <KirokuIIEditViews2.Breath {...view_props} />
                            <KirokuIIEditViews2.Pain {...view_props} />
                            <KirokuIIEditViews2.Oral {...view_props} />
                            <KirokuIIEditViews2.Lang {...view_props} />
                            <KirokuIIEditViews2.Nutrition {...view_props} />
                            <KirokuIIEditViews2.Excre {...view_props} />
                            <KirokuIIEditViews2.Sleep {...view_props} />
                            <KirokuIIEditViews2.Cognition {...view_props} />
                            <KirokuIIEditViews2.Circ {...view_props} />
                            <KirokuIIEditViews2.Mental {...view_props} />
                            <KirokuIIEditViews2.Metabo {...view_props} />
                            <KirokuIIEditViews2.Growth {...view_props} />
                            <KirokuIIEditViews2.Neuro {...view_props} />
                            <KirokuIIEditViews2.EOL {...view_props} />
                            <KirokuIIEditViews2.Kaigo {...view_props} />
                            <KirokuIIEditViews2.Omaha {...view_props} />
                            <KirokuIIEditViews2.Deal {...view_props} />
                            <KirokuIIEditViews2.Moushiokuri {...view_props} />
                        </div>
                        : <div key="ve">
                            <KirokuIIEditViews.Basic {...view_props} />
                            <KirokuIIEditViews.Vital {...view_props} />
                            <KirokuIIEditViews.Skin {...view_props} />
                            <KirokuIIEditViews.Breath {...view_props} />
                            <KirokuIIEditViews.Pain {...view_props} />
                            <KirokuIIEditViews.Oral {...view_props} />
                            <KirokuIIEditViews.Lang {...view_props} />
                            <KirokuIIEditViews.Nutrition {...view_props} />
                            <KirokuIIEditViews.Dehyd {...view_props} />
                            <KirokuIIEditViews.Excre {...view_props} />
                            <KirokuIIEditViews.Physical {...view_props} />
                            <KirokuIIEditViews.Sleep {...view_props} />
                            <KirokuIIEditViews.Cognition {...view_props} />
                            <KirokuIIEditViews.Circ {...view_props} />
                            <KirokuIIEditViews.Mental {...view_props} />
                            <KirokuIIEditViews.Metabo {...view_props} />
                            <KirokuIIEditViews.Growth {...view_props} />
                            <KirokuIIEditViews.Neuro {...view_props} />
                            <KirokuIIEditViews.EOL {...view_props} />
                            <KirokuIIEditViews.Kaigo {...view_props} />
                            <KirokuIIEditViews.Omaha {...view_props} />
                            <KirokuIIEditViews.Deal {...view_props} />
                            <KirokuIIEditViews.Moushiokuri {...view_props} />
                        </div>


            }

            <br />

            <div style={{ marginBottom: "10px", textAlign: "right" }}>
                <Select
                    value={this.state.cur_log ? this.state.cur_log : 0}
                    autoWidth={true}
                    displayEmpty={true}
                    variant='outlined'
                    onChange={(e) => this.loadLog(e.target.value as any)}
                    className={classes.input}
                >
                    <MenuItem key={`cur_log_null`} value={0}>編集履歴</MenuItem>
                    {
                        vl ?
                            vl.map((t, idx) =>
                                <MenuItem key={`${t.id}_${idx}`} value={t.id}>{DateUtil.toJpDateTimeString(t.created_at)}{t.user}</MenuItem>
                            )
                            : null
                    }
                </Select>
            </div>
            <div>
                {
                    errorMessage != "" ?
                        <span key="errmsg" style={{ color: "red" }}>{errorMessage}</span>
                        : null
                }
            </div>
            {
                this.state.cur_log ?
                    <div>履歴は修正できません</div> :
                    this.state.kiroku.id != null && this.state.kiroku.id > 0 ?
                        <div>
                            <div>
                                {
                                    this.state.kiroku.is_temp ?
                                        <Button color="primary" variant="contained"
                                            onClick={(e) => this.commitKiroku(true)}
                                            disabled={this.state.posting}
                                            className={classes.filter_button_d}
                                            style={{
                                                position: "fixed",
                                                left: "10px",
                                                bottom: "50px",
                                                zIndex: 10,
                                            }}>
                                            一時保存{this.state.postError}
                                        </Button>
                                        : null
                                }
                                {"　"}
                                <Button color="primary" variant="contained"
                                    onClick={(e) => this.commitKiroku()}
                                    disabled={this.state.posting || errorMessage != ""}
                                    className={classes.filter_button_d}>
                                    確定
                                </Button>
                            </div>
                            <br /><br />
                            <div>
                                <Button color="secondary" variant="outlined"
                                    onClick={(e) => this.setState({ openDelete: true })}
                                    disabled={this.state.posting}
                                    className={classes.filter_button_d}>
                                    削除
                                </Button>
                            </div>
                            <SimpleDialog
                                title="確認"
                                open={this.state.openDelete}
                                onDelete={() => this.deleteKiroku()}
                                onCancel={() => this.setState({ openDelete: false })}
                                hideDelete={false}
                                hideOk={true}
                            >
                                削除してよろしいですか？
                            </SimpleDialog>
                        </div>
                        :
                        <div>
                            <Button color="primary" variant="contained"
                                onClick={(e) => this.commitKiroku(true)}
                                disabled={this.state.posting}
                                className={classes.filter_button_d}
                                style={{
                                    position: "fixed",
                                    right: "10px",
                                    top: "70px",
                                    zIndex: 10,
                                    color: "white",
                                    borderRadius: "500px",
                                    backgroundColor: Colors.orangeColor,
                                    border: "solid",
                                    borderWidth: "1px",
                                    height: "65px",
                                    width: "60px",
                                    fontSize: "0.8rem !important",
                                    lineHeight: "1.2rem"
                                }}>
                                一時<br />保存{this.state.postError}
                            </Button>
                            {"　"}
                            <Button color="primary" variant="contained"
                                disabled={this.state.posting || errorMessage != ""}
                                onClick={(e) => this.commitKiroku()}
                                className={classes.filter_button_d}>
                                確定
                            </Button>
                        </div>
            }
            <div style={{ color: "red" }}>
                {this.state.postError}
            </div>

            <TermConfirmDialog
                patient={this.state.patient}
                open={this.state.openConfirm}
                timeStr={this.state.patient.terms_confirmed}
                onCancel={() => { this.setState({ openConfirm: false }) }}
                onOk={(value, url) => {
                    this.setState({ openConfirm: false })
                }}
                onDisagree={() => {
                    this.setState({ openConfirm: false })
                }}
            />
            <ShuttenAll />
            <div style={{ color: "gray", textAlign: "center", fontSize: "smaller", margin: "10px" }}>
                <div>© 2023 メディカルインフォマティクス株式会社 All Rights Reserved.</div>
                <div>㈳オマハシステムジャパンよりコード提供</div>
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(KangoKirokuPage))