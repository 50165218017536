import { readAndCompressImage } from 'browser-image-resizer';
export class ImageUtil {

    public static compress(file:File, maxWidth=2048)
    {
        if (file && (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg"))
        {
            return readAndCompressImage(file, {
                quality: 1.0,
                maxWidth: maxWidth,
                maxHeight: maxWidth,
                autoRotate: false,
                debug: true
            })
        }
        else {
            return new Promise((resolve)=>resolve(file))
        }
    }
}