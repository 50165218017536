import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { toInjector, GoogleCalendar } from '../stores';

const styles = () => Styles.createStyles({
})

interface Props extends GoogleCalendar, Styles.WithStyles {
}

@inject(...toInjector(GoogleCalendar))
@observer
class GoogleCalendarLoginPage extends React.Component<Props> { 
	displayName="GoogleCalendarLoginPage"
    componentDidMount = () => {
        this.props.googleCalendar!.initialize()
    }

    public state = {
        index: 0,
    };

    public render() {
        const googleCalendar = this.props.googleCalendar!
        return (
            <div>
                <button onClick={() => this.props.googleCalendar!.getPermission()}>google-oauth</button>
                <div>
                    isAuth:{googleCalendar.hasPermission.toString()}
                </div>
                <div>
                </div>
                <div>
                    <span>カレンダーリスト</span>
                    <select onChange={(e) => {
                        if(e.currentTarget.value == "null"){
                            googleCalendar.calendarEvents = undefined
                            return
                        }
                        googleCalendar.getCalendarEvents(e.currentTarget.value)
                    }} name ="list">
                        <option value="null">選択してください</option>
                        {
                            (() => {
                                const calendarList = googleCalendar.calendarList
                                if(!calendarList) return null
                                const items = calendarList.items
                                if(!items) return null
                                return items.map(x => {
                                    return <option key={x.id} value={x.id}>{x.summary}</option>
                                })
                            })()
                            
                        }
                    </select>
                </div>
                <div>
                    <ul>
                    {
                        (() => {
                            const events = googleCalendar.calendarEvents
                            if(!events) return null
                            const items = events.items
                            if(!items) return null
                            return items.map(x => <li>{x.summary}</li>)
                        })()
                    }
                    </ul>
                    <ul>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Styles.withStyles(styles)(GoogleCalendarLoginPage)