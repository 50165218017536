import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Loading from '../components/Loading'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SubTitle from '../components/SubTitle'
import * as Colors from '../components/Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import ButtonOkCancel from '../components/ButtonOkCancel';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    companies: Model.Company[]
    loading: boolean
}


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

// @inject()
// @observer
class CompanyListPage extends React.Component<Props, LocalState> {
    displayName = "CompanyListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            companies: []
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true })
        HokanApi.company.list()
            .then(x => {
                this.setState({ companies: x.data, loading: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ companies: [], loading: false })
            })
    }

    public render() {
        const { classes, history } = this.props;
        const { companies, loading } = this.state;
        return <div className={classes.root}>
            <SubTitle title="法人情報"
                text="法人を選択してください"
            />
            {
                loading ? <Loading /> :
                    <div>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>名前</CustomTableCell>
                                    <CustomTableCell align="right"></CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    companies.map(c => {
                                        return <TableRow key={c.id} className={classes.row}
                                            onClick={(e) => history.push(`company/${c.id}`)}>
                                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }} component="th" scope="row">{c.name}</CustomTableCell>
                                            <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>

                        <ButtonOkCancel
                            showBack={true}
                            cancelLabel="戻る" okLabel="追加"
                            showOk={false}
                            onCancel={() => history.goBack()}
                            onOk={() => { }}
                            disabled={this.state.loading} />
                    </div>
            }
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(CompanyListPage))