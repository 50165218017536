import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Loading from './Loading'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Colors from '../components/Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import ButtonOkCancel from '../components/ButtonOkCancel';
import { StoreAccessor } from '../util/StoreUtil';
import Col2Table from '../components/Col2Table';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    branchId: number | null,
    showButton: boolean
}

interface LocalState {
    loadBranchId: number | null
    users: Model.User[]
    loading: boolean
    me: Model.User
    query: Query
}


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

class Query {
    text: string = ""
}

// @inject()
// @observer
class UserListPage extends React.Component<Props, LocalState> {
    displayName = "UserListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            me: new Model.User(),
            loadBranchId: null,
            loading: false,
            users: [],
            query: new Query()
        }
    }

    public componentDidMount = () => {
        const params = new URLSearchParams(this.props.location.search);
        const name = params.get("name")
        if (name) {
            const q = this.state.query;
            if (q.text != name) {
                q.text = name
                this.setState({ query: q })
            }
        }
        this.load()
    }

    private load() {
        HokanApi.me().then(mex => {
            const me = mex.data
            this.setState({ me: me })
        }).catch(err => { console.log(err) })
        this.setState({ loading: true, loadBranchId: this.props.branchId })

        HokanApi.getUsers(this.props.branchId)
            .then(x => {
                this.setState({ users: x.data, loading: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ users: [], loading: false })
            })
    }

    public render() {
        const { classes, history } = this.props;
        const { users, loading, query } = this.state;
        if (this.props.branchId != this.state.loadBranchId) {
            this.load();
        }
        const saQ = new StoreAccessor(this, true, "query")
        const element_l = [
            { name: "スタッフ名", content: saQ.text("text", "", "text", "block", false) },
        ]
        const userFiltered = users.filter(x => {
            if (query.text != "") {
                if (
                    (x.name.indexOf(query.text) == -1)
                ) return false
            }
            return true
        })
        const isAdmin = this.state.me.privilege == "Admin"
        return loading ? <Loading /> :
            <div>
                <div style={{ display: "block", width: "100%" }}>
                    <Col2Table body={element_l} />
                </div>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>名前</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>電話(仕事)</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>電話(私用)</CustomTableCell>
                            <CustomTableCell align="right"></CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            userFiltered.map(c => {
                                return <TableRow key={c.id} className={classes.row}>
                                    <CustomTableCell style={{ textAlign: "left", padding: "10px" }} onClick={(e) => history.push(`/manage/user/${c.id}`)} component="th" scope="row">{c.name}{c.privilege == "Admin" ? "(管理者)" : ""}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "left", padding: "10px", cursor: "default" }} component="th" scope="row">{StoreAccessor.linkTel(c.tel1)}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "left", padding: "10px", cursor: "default" }} component="th" scope="row">{StoreAccessor.linkTel(c.tel2)}</CustomTableCell>
                                    <CustomTableCell align="right" onClick={(e) => history.push(`/manage/user/${c.id}`)} className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>

                <ButtonOkCancel
                    cancelLabel="戻る" okLabel="追加"
                    onCancel={() => history.goBack()}
                    onOk={() => history.push(`/manage/user/0`)}
                    showBack={this.props.showButton}
                    showOk={isAdmin}
                    disabled={this.state.loading} />
            </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(UserListPage))