import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';

const styles = () => Styles.createStyles({
    root: {
        margin: "5px",
        marginTop: "5px"
    },
    subTitleDiv: {
        textAlign: "center",
        //border: "solid 1px",
        //borderRadius: "2em",
        borderColor: Colors.borderColor,
        //paddingTop: "5px",
        //paddingBottom: "5px"    
        fontSize: "1.2rem",
        margin: "20px"
    },
    subTitleText: {
        color: Colors.primaryColor,
        fontWeight: 700
    },
    subTitleDiv_flip: {
        textAlign: "center",
        border: "solid 1px",
        borderRadius: "2em",
        backgroundColor: Colors.primaryColor,
        margin: "10px 20px",
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    subTitleText_flip: {
        color: Colors.white,
        fontWeight: 700
    },

    explain: {
        color: Colors.primaryColor,
        textAlign: "center"
    },
    explainSub: {
        fontSize: "0.9rem"
    },
})

interface Props extends Styles.WithStyles {
    title: string,
    text?: string,
    subText?: string,
    flipColor?: boolean
}

class SubTitle extends React.Component<Props> {
    displayName = "SubTitle"
    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={this.props.flipColor ? classes.subTitleDiv_flip : classes.subTitleDiv}>
                    <span className={this.props.flipColor ? classes.subTitleText_flip : classes.subTitleText}>
                        {this.props.title}
                    </span>
                </div>
                {
                    this.props.text ?
                        <div className={classes.explain}>
                            {this.props.text}
                            {
                                this.props.subText ?
                                    <div className={classes.explainSub}>
                                        {this.props.subText}
                                    </div>
                                    : null
                            }
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default Styles.withStyles(styles)(SubTitle)