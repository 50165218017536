import * as React from 'react';
import { Auth, toInjector } from '../stores';
import { inject, observer } from 'mobx-react';
import { PageProps, withPageProps } from '../components/Page'

interface Props extends Auth, PageProps {
    message: string
}

@inject(...toInjector(Auth))
@observer
class AuthPage extends React.Component<Props> { 
	displayName="AuthPage"
    public componentDidMount = () => {
        const {auth} = this.props;
        auth!.initialize()
    }

    private login()
    {
        const { auth, history, location } = this.props;
        const redirect = location.state.from || "/";
        auth!.login(() => {
            history.push(redirect)
        })
    }

    public render() {
        const { auth, location } = this.props;
        const outed = location.state.logout || false
        return (
            <div>
                <div>
                    {
                        outed ?
                            "ログアウトしました"
                            : "ログインしてください"
                    }
                </div>
                <div>
                    <span>名前</span>
                    <input type="input"
                     value={auth!.username} 
                     onChange = {(e) => auth!.username = e.target.value}
                     />
                </div>
                <div>
                    <span>パスワード</span>
                    <input type="input" 
                     value={auth!.password} 
                    onChange={(e) => auth!.password = e.target.value}
                    />
                </div>
                <div>
                    <button onClick={(e)=>{this.login()}} >ログイン</button>
                </div>
                <div>
                    <button onClick={() => auth!.getData()} >要認証データの取得</button>
                </div>
                <div>
                    <span>アクセストークン:{auth!.accessToken}</span>
                </div>
                <div>
                    <span>リフレッシュトークン:{auth!.refreshToken}</span>
                </div>
                <div>
                    <span>要認証データ取得:{auth!.exampleData}</span>
                </div>
                <div>
                    <span>ログインエラー:{auth!.loginError}</span>
                </div>
            </div>
        );
    }
}

export default withPageProps(AuthPage)