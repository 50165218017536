
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    
    public chikaku: string = ""
    public gaikan: string = ""
    public ishiki: string = ""
    public iyoku: string = ""
    public jiga: string = ""
    public kanjou: string = ""
    public kioku: string = ""
    public ninchi: string = ""
    public shikou: string = ""
}

export class Checks {
}