
export class Task {
    public id: number = 0
    public content: string = ""
    public done: boolean = false
    public date_limit: string | null = null
    public done_time: Date | null = null
    public task_date: string = ""
    public patient_name: string = ""
    public patient_id: number = 0
    public tanto_name: string = ""
}
