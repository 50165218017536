import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Loading from './Loading'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Colors from './Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import ButtonOkCancel from './ButtonOkCancel';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "medium",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    branchId: number | null,
    showButton: boolean
}

interface LocalState {
    loadBranchId: number | null
    talks: Model.Talk[]
    loading: boolean
    me: Model.User
    branches: Model.Branch[]
}


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "medium",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "medium",
    },
}))(TableCell);

// @inject()
// @observer
class TalkListPage extends React.Component<Props, LocalState> {
    displayName = "UserListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            me: new Model.User(),
            loadBranchId: null,
            loading: false,
            talks: [],
            branches: []
        }
    }

    public componentDidMount = () => {
        HokanApi.branch.list()
        .then(branch_list => {
            let bl = branch_list.data.concat({ id: 0, name: "全事業所" } as Model.Branch )
            this.setState({branches: bl})
            this.load()
        })
    }

    private load() {
        HokanApi.me().then(mex => {
            const me = mex.data
            this.setState({ me: me })
        }).catch(err => { console.log(err) })

        this.setState({ loading: true, loadBranchId: this.props.branchId })
        //HokanApi.getUsers(this.props.branchId)
        HokanApi.message.list(this.props.branchId)
        .then(x => {
            this.setState({ talks: x.data, loading: false })
        })
        .catch(err => {
            console.log(err)
            this.setState({ talks: [], loading: false })
        })
    }

    private get_talk_name(talk:Model.Talk) {
        if(talk.wc_number == null && talk.wc_branch_id != null && talk.wc_branch_id != null && talk.user_id == null) {
            let branch = this.state.branches.find( b => { return b.id == talk.wc_branch_id })
            if (branch != null) {
                return "業務連絡(" + branch.name +")"
            }
            else{
                return "業務連絡"
            }

        }
        else {
            return talk.name
        }
    }

    public render() {
        const { classes, history } = this.props;
        const { talks, loading } = this.state;
        if (this.props.branchId != this.state.loadBranchId) {
            this.load();
        }
        const isAdmin = this.state.me.privilege == "Admin"
        return loading ? <Loading /> :
            <div>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>名前</CustomTableCell>
                            <CustomTableCell align="right"></CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            talks.map(c => {
                                return <TableRow key={c.id} className={classes.row}
                                    onClick={(e) => history.push(`/message/${c.id}`)}>
                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }} component="th" scope="row">{this.get_talk_name(c)}</CustomTableCell>
                                    <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>

                <ButtonOkCancel
                    cancelLabel="戻る" okLabel="追加"
                    onCancel={() => history.goBack()}
                    onOk={() => history.push(`/message/new`)}
                    showBack={this.props.showButton}
                    showOk={isAdmin}
                    disabled={this.state.loading} />
            </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(TalkListPage))