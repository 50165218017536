import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
import UserList from '../components/UserList';
import { Select, MenuItem } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    branchId: number | null
    branches: Model.Branch[]
    loading: boolean
    me: Model.User
}

// @inject()
// @observer
class UserListPage extends React.Component<Props, LocalState> {
    displayName = "UserListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            branchId: null,
            branches: [],
            loading: false,
            me: new Model.User()
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        let branchId = params.get("branch")

        if (!branchId && branchId != "0") {
            const qls = localStorage.getItem("manage_user_query")
            if (qls) {
                const jps = JSON.parse(qls)
                if (jps.branch_id) {
                    branchId = jps.branch_id
                }
            }
        }

        HokanApi.me().then(mex => {
            const me = mex.data
            const br = branchId ? parseInt(branchId) : me.branch_id
            this.setState({ me: me, branchId: br })

            HokanApi.branch.list()
                .then(x => {
                    this.setState({ branches: x.data })
                })
                .catch(err => {
                    console.log(err)
                })
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    public render() {
        const { classes } = this.props;
        if (!this.state.loading && this.state.branchId) {
            const query_item = JSON.stringify({
                branch_id: this.state.branchId
            })
            localStorage.setItem("manage_user_query", query_item)
        }
        return <div className={classes.root}>
            <Select
                value={this.state.branchId != null ? this.state.branchId : ''}
                autoWidth={true}
                variant='outlined'
                onChange={(e) => this.setState({ branchId: e.target.value as number })}
                className={classes.input}
            >
                <MenuItem key='all' value={0}>全事業所</MenuItem>
                {
                    this.state.branches.map((t, idx) =>
                        <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                    )
                }
            </Select>
            <SubTitle title="スタッフ情報"
                text="スタッフを選択してください"
            />
            <UserList showButton={true} branchId={this.state.branchId} />
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(UserListPage))