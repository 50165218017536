import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { Button, Card, CardContent, CardActions, Typography, Grid, CardHeader } from '@material-ui/core';
import * as Colors from '../Colors'
import { CallProps } from './CallComponent';
import * as Model from '../../models';
import * as HokanApi from '../../api/hokan'
import Loading from '../Loading';
const moment = require("moment-timezone")

// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const transitionBase = {
    position: "absolute" as "absolute",
    width: "100%",
    height: "100%",
    transition: "0.4s"
}

const styles = () => Styles.createStyles({
    root: {
        overflow: "hidden",
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px",
        height: "calc(100% - 48px)"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",
        }
    },
    transitionList: {
        ...transitionBase,
        transform: "translate(0%,0%)"
        //background: "#999"
    },
    transitionNew: {
        ...transitionBase,
        transform: "translate(0%,0%)",
    },
    transitionId: {
        ...transitionBase,
        transform: "translate(0%,0%)",
    },
    newButton: {
        position: "fixed",
        left: 0,
        right: 0,
        bottom: "5%",
        margin: "auto"
    },
    card: {
        minWidth: 275,
        maxWidth: 300,
        marginBottom: "10px",
        marginLeft: "100px",
        marginRight: "100px",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
    },
    cardDiv: {
        overflow: "auto",
        width: "100%",
        height: "calc(100% - 90px)"
    }
});


interface Props extends CallProps, Styles.WithStyles<typeof styles> {
    new: () => void
    edit: (id: number) => void
}

interface LocalState {
    isLoading: boolean
    calls: Model.CallFromApi[]
    callKinds: Model.CallKind[]
}


// @inject()
// @observer
class TemplatePage extends React.Component<Props, LocalState> {
    displayName = "TemplatePage"
    constructor(props: Props) {
        super(props)
        this.state = {
            isLoading: true,
            calls: [],
            callKinds: []
        }
    }

    public componentDidMount = () => {
        HokanApi.getCalls().then(
            res => {
                const calls = res.data
                const isLoading = false
                console.log("calls--")
                console.log(calls)
                this.setState({ calls, isLoading })
            }
        )
    }

    public render() {

        const { classes } = this.props
        const transitionClass = (() => {
            switch (this.props.mode) {
                case "new":
                    return classes.transitionNew;
                    break;
                case "list":
                    return classes.transitionList
                    break;
                default:
                    return classes.transitionId
            }
        })()

        const list = (<div className={classes.root}>
            <div className={classes.cardDiv}>
                <Grid container justify="center" alignItems="center">
                    {this.state.calls.map(x => {
                        return (
                            <Card key={`${x.id}`} className={classes.card}>
                                <CardHeader
                                    subheader={x.patient.name}
                                    title={moment(x.attending_time).tz("Asia/Tokyo").format("YYYY-MM-DD")}
                                />
                                <CardContent>
                                    <Typography>
                                        {x.detail}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => this.props.edit(x.id)}>編集</Button>
                                </CardActions>
                            </Card>
                        )
                    })}
                </Grid>
            </div>
            <Grid className={classes.newButton} container justify="center" alignItems="center">
                <Button variant="contained" color="primary" onClick={this.props.new}>
                    新規コール作成
                </Button>
            </Grid>
        </div>)

        return (
            <div className={transitionClass}>
                {this.state.isLoading ? <Loading /> : list}
            </div>
        )
    }
}

export default Styles.withStyles(styles)(TemplatePage)