import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class HanpukuEngeBehaviour extends FormBehaviour.PdfBehaviour { 
	displayName="HanpukuEngeBehaviour"

    public getId(): number {
        return 14
    }

    public createContent(): any {
        const res = new FormModel.MouseHanpukuEnge.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])

        const elements_scores = [
            {
                name: "実施の結果", content: saV.listRaw("score_enge", [
                    { key: "未選択", value: -1 },
                    { key: "30秒間3回以上で正常", value: 0 },
                    { key: "2回以下で要注意、嚥下障害の可能性あり", value: 1 },
                ], true, true)
            },
            { name: "備考", content: saV.text("comment") }
        ]

        const elements_explain = [
            {
                name: "目的", content: <div>
                    嚥下運動を繰り返してもらい、誤嚥の有無を簡易的に評価する。
                </div>
            },
            {
                name: "方法", content: <div>
                    <ol>
                        <li>対象者に、肩の力を抜いて自然に座ってもらう。</li>
                        <li>対象者の喉頭隆起（喉仏）・舌骨に人差指と中指の
       腹を軽くあて、３０秒間　唾液を飲み込んでもらう。<br/>
       対象者には「できるだけ、何回もゴクンとつばを
 飲み込んでください。」と指示する。</li>
                        <li>30秒間に嚥下できた回数を記録する。</li>
                        <li>喉頭隆起・舌骨は嚥下運動に伴って、指腹を乗越えて上方に移動し、また元に戻る。<br/>
 この下降運動を確認できたら嚥下完了とする。</li>
                        <li>口腔内が著しく乾燥している場合は、最初に１～２ccの水を含ませてから行う。</li >
                    </ol>
                </div>
            },
            {
                name: "注意点", content: <ul>
                    　<li>喉頭隆起・舌骨に指をあてる時、強くおさえると嚥下しにくくなり正確に測れなくなる。<br/>
 対象者に行う前に自分でやってみると、どの程度おさえればいいかわかりやすい。</li>
<li>嚥下障害者では、１回目はスムーズにできても、2回目以降、喉頭挙上が完了せず、途中で下降してしまう場合があるため、鑑別に注意を要する。</li>
                </ul>
            },
            {
                name: "指導", content:<div>
                    口腔ケアや口腔機能訓練を行うことで、RSSTが3回以上できるようになれば、誤嚥のリスクは減少できる。
                </div>
            }
        ]

        const elements = [
            { name: '評価', content: elements_scores },
            { name: '説明', content: elements_explain },
        ]

        return elements
    }
}
