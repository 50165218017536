import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
    },
});

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
}

// @inject()
// @observer
class CreateRecordPage extends React.Component<Props, LocalState> { 
	displayName="CreateRecordPage"
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }

    public render() {
        const { classes } = this.props

        return <div className={classes.root}>
           create record
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(CreateRecordPage))