import Firebase from './index'
import Resizer from 'react-image-file-resizer'
import { resolveCname } from 'dns'

export default class MessageFileStore {
    static cache: { [index: string]: string } = {}
    static cachePromise: { [index: string]: Promise<string> } = {}

    private static genPath(cid:number, filename: string) {
        return `/company/${cid}/message/${filename}`
    }

    public static getUrl(cid:number, filename: string):Promise<string> {
        let id = `${cid}-${filename}`
        if(MessageFileStore.cache[id]) {
            return new Promise( (resolve, reject) => {
                resolve(MessageFileStore.cache[`${cid}-${filename}`])
            })
        }
        if(MessageFileStore.cachePromise[id]) {
            return MessageFileStore.cachePromise[id]
        }

        const storageRef = Firebase.instance.storage.ref()
        return MessageFileStore.cachePromise[id] = storageRef.child(this.genPath(cid, filename)).getDownloadURL().then( (x:string) => {
            MessageFileStore.cache[id] = x
            return new Promise<string>((resolve, reject) => {
                resolve(x)
            })
        }).catch(err => {
            MessageFileStore.cache[id] = "none"
            return new Promise<string>((resolve, reject) => {
                resolve("none")
            })
        })

    }

    public static put(cid:number, fn:string, file: File) {
        
        const storageRef = Firebase.instance.storage.ref()
        delete MessageFileStore.cache[`${cid}-${fn}`]
        delete MessageFileStore.cachePromise[`${cid}-${fn}`]
        return storageRef.child(this.genPath(cid, fn)).put(file)
    }
}