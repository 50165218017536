import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const styles = () => Styles.createStyles({
    card: {
        backgroundColor: Colors.backColor,
        textAlign: "center",
        verticalAlign: "middle",
        padding: "20px 10px",
        color: Colors.primaryColor,
        border: "solid",
        borderColor: "#39E0",
        borderWidth: "1px",
        transition: ".5s",
        '&:hover': {
            borderRadius: "10%",
            border: "solid",
            borderColor: "#39ED",
            borderWidth: "1px"
        }
    },
    circle: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        background: Colors.primaryColor,
        display: "inline-block"
    }
})

interface Props extends Styles.WithStyles {
    title: string,
    icon: JSX.Element
}

class SubTitle extends React.Component<Props> { 
	displayName="SubTitle"
    public render() {
        const { classes } = this.props;
        return (
            <Card className={classes.card}>
                <div className={classes.circle}>
                    {this.props.icon}
                </div>
                <div>
                    {this.props.title}
                </div>
            </Card>
        );
    }
}

export default Styles.withStyles(styles)(SubTitle)