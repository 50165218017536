import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
import TalkList from '../components/TalkList';
import InputText from '../components/InputText';
import InputList from '../components/InputList';
import { Grid, Button } from '@material-ui/core';
import ButtonOkCancel from '../components/ButtonOkCancel';
import { Select, MenuItem } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import { SelectAllRounded } from '@material-ui/icons';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    branchId: number | null
    users: Model.User[],
    branches: Model.Branch[],
    loading: boolean
    me: Model.User,
    selectedBranch: {
        label: string;
        value: string;
    } | null,
    selected: {
        label: string;
        value: string;
    }[],
    title: string
}

// @inject()
// @observer
class MessageCreatePage extends React.Component<Props, LocalState> {
    displayName = "UserListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            branchId: null,
            users: [],
            branches: [],
            loading: false,
            me: new Model.User(),
            selectedBranch: null,
            selected: [],
            title: ""
        }
    }

    componentDidMount() {
        HokanApi.me()
        .then(mex => {
            this.setState({ me: mex.data, branchId: mex.data.branch_id })
            HokanApi.branch.list()
            .then( x => {
                this.setState({
                    branches:x.data,
                    selectedBranch: {label:x.data[0].name, value:x.data[0].id.toString()}
                })
            })
            HokanApi.getUsers(this.state.branchId)
            .then(x => {
                this.setState({users:x.data})
            })
        })
        .catch(err => {
            console.log(err)
            this.setState({ loading: false })
        })
    }
    onBranchSelect(s) {
        let found = this.state.branches.find(b => b.id == s )

        if(found!=null) {
            this.setState({
                selectedBranch: {
                    label: found.name,
                    value: found.id.toString()
                }
            })
            HokanApi.getUsers(found.id)
            .then(x => {
                this.setState({users:x.data})
            })
        }
    }

    onSelect(s) {
        let found = this.state.users.find(u => u.id == s )
        let exist = this.state.selected.find(u => u.value == s)
        let t = this.state.selected

        if(found!==undefined && exist===undefined) {
            t.push({label:found.name, value:found.id.toString()})
            this.setState({selected: t})
        }
    }

    onDeleteButtonClick(s) {
        let newSelected = this.state.selected.filter( t => t.value != s)
        this.setState({selected: newSelected})
    }

    onSubmitButtonClick() {
        let d = new Model.CreateTalk();
        d.name = this.state.title
        d.users = this.state.selected.map(u => u.value)
        HokanApi.message.create(d)
            .then( x => {
                this.setState({loading: false})
                this.props.history.goBack()
            })
            .catch( err => {
                console.log(err)
            })
    }
    changeTitle(input) {
        this.setState({title: input})
    }

    public render() {
        const { classes } = this.props;
        const userList = this.state.users.map( u => {
            return {label: u.name, value: u.id.toString()}
        })
        const branchList = this.state.branches.map( b => {
            return {label: b.name, value: b.id.toString()}
        })
        let selectedBranch = "0"
        if(this.state.selectedBranch!=null) {
            selectedBranch = this.state.selectedBranch.value
        }
        const notSelected = { label:"選択してください", value:"" }
        userList.unshift(notSelected)

        

        return <div className={classes.root}>
            <SubTitle title="トーク作成" text="" />
            <Grid container spacing={2} className={classes.grid}>
                <InputText editable={true} title="トーク名" value={this.state.title} onChange={(s) =>  this.changeTitle(s) } />
                <InputList editable={true} title="事業所" value={selectedBranch} onChange={(s) => this.onBranchSelect(s) } values={branchList} />
                <InputList editable={true} title="追加するユーザ" value="0" onChange={(s) => this.onSelect(s) } values={userList} />
                <div>
                    <p>選択されたユーザ</p>
                    <ul>
                        { this.state.selected.map( u => {
                            return <li>
                                {u.label}
                                <span>
                                    <button onClick={ () => this.onDeleteButtonClick(u.value)}>削除</button>
                                </span>
                            </li>
                        })}
                    </ul>
                </div>
            </Grid>
            <ButtonOkCancel
                showBack={true}
                cancelLabel="戻る" okLabel="確定"
                onCancel={() => this.props.history.goBack() }
                onOk={() =>  this.onSubmitButtonClick() }
                disabled={false} 
            />
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(MessageCreatePage))