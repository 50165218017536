import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import { Table, TableHead, TableCell, TableRow, TableBody, Button, Grid, Select, MenuItem } from '@material-ui/core';
import * as Colors from './Colors'
import { withStyles } from '@material-ui/core/styles';
import Pagination from "material-ui-flat-pagination";
import { StoreAccessor } from '../util/StoreUtil';
import Col2Table from './Col2Table';
import DateUtil from '../util/DateUtil';
import StandardTable from './StandardTable';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",
        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    bar: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.borderColor,
        backgroundColor: Colors.backColor
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        // background: Colors.primaryColor,

    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
})


class Query {
    date_from: string = DateUtil.currentMonthFirstDateStr()
    date_to: string = DateUtil.currentMonthLastDateStr()
    name: string = ""
    name_kana: string = ""
    user_name: string = ""
    user_name_kana: string = ""
    hoken: string = "all"
    page: number = 1
    per: number = 20
    with_temp: boolean = true
}

interface LocalState {
    me: Model.User
    loading: boolean
    branch_id: number
    branches: Model.Branch[]
    query: Query
    kirokus: HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>
}


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Props extends Styles.WithStyles {
}


// @inject()
// @observer
class WorkLog extends React.Component<Props, LocalState> {
    displayName = "Worklog"

    constructor(props: Props) {
        super(props)

        this.state = {
            me: new Model.User(),
            loading: true,
            branch_id: 0,
            branches: [],
            query: new Query(),
            kirokus: new HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>()
        }
    }

    private loadBranch = (branchId) => {
        this.setState({ branch_id: branchId })
        this.loadKirokus(branchId);
    }

    private hiraToKana(str) {
        return str.replace(/[\u3041-\u3096]/g, function(match) {
            var chr = match.charCodeAt(0) + 0x60;
            return String.fromCharCode(chr);
        });
    }

    private loadKirokus = (branchId: number, _offset?: number) => {
        this.setState({ loading: true })
        const q = this.state.query
        const offset = _offset == undefined ? (this.state.kirokus.page - 1) * this.state.kirokus.per : _offset
        q.page = offset / this.state.kirokus.per + 1
        q.per = this.state.kirokus.per
        q.name = q.name
        q.name_kana = this.hiraToKana(q.name)
        q.user_name = q.name
        q.user_name_kana = q.user_name_kana
        HokanApi.searchKirokuIIs(branchId, q.date_from, q.date_to, q).then(x => {
            const d = x.data
            d.data = d.data.map(y => Model.KirokuII.KangoKirokuII.load(y))
            this.setState({ kirokus: d, loading: false })
        })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidMount() {
        this.setState({ loading: true })
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
            this.loadBranch(x.data.branch_id)
            //this.loadKirokus()
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })

        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private getKasanText(kiroku: Model.KirokuII.KangoKirokuII) {
        var kasan = kiroku.basic.kasan;
        let res = ""

        if (kasan.is_shokai) res += "初回 "
        if (kasan.is_taiin) res += "退院時指導 "
        if (kasan.encho_time) res += `延長時間(${kasan.encho_time}) `
        if (kasan.is_taiinji_shidou) res += "退院時指導 "
        if (kasan.is_tokubetu_kanri_shidou) res += "特別管理指導 "
        if (kasan.is_taiin_shien_shidou) res += "退院支援指導 "
        if (kiroku.basic.is_kinkyu) res += "緊急 "
        if (kasan.is_chojikan_houmon_kango) res += "長時間訪問看護 "
        if (kasan.is_yakan) res += "夜間・早朝 "
        if (kasan.is_shinnya) res += "深夜 "
        if (kasan.is_hukusuu_kangoshi) res += "複数名(看護師) "
        if (kasan.is_hukusuu_ptot) res += "複数名(PTOT) "
        if (kasan.is_hukusuu_junkangoshi) res += "複数名(准看護師) "
        if (kasan.is_hukusuu_hojo) res += "複数名(補助者) "
        if (kasan.is_hukusuu_psw) res += "複数名(PSW) "
        if (kasan.is_nyuyouji) res += "乳幼児 "
        if (kasan.is_youji) res += "幼児 "
        if (kasan.is_zaitaku_renkei_shidou) res += "在宅患者連携指導 "
        if (kasan.is_zaitaku_kinkyu_kanfa) res += "在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ "
        if (kasan.is_kanwa_care) res += "緩和ｹｱ "
        if (kasan.is_jokusou_care) res += "褥瘡ｹｱ "
        if (kasan.is_smart_care) res += "ｽﾄｰﾏｹｱ "
        if (kasan.is_terminal_care) res += "ﾀｰﾐﾅﾙｹｱ "
        return res;
    }

    public render() {
        const { classes } = this.props;
        const sa = new StoreAccessor(this, true)
        const saQ = sa.generateChild(["query"])
        const element_l = [
            {
                name: "", content: <div style={{ display: "inline-flex" }}>
                    {
                        saQ.listRaw("hoken", [
                            { key: "全種別", value: "all" },
                            { key: "介護", value: "kaigo" },
                            { key: "医療", value: "iryo" },
                            { key: "保険以外（自費等）", value: "jihi" }
                        ])
                    }
                    {
                        <Select
                            value={this.state.branch_id}
                            autoWidth={true}
                            variant='outlined'
                            onChange={(e) => this.loadBranch(e.target.value)}
                            className={classes.input}
                        >
                            <MenuItem key={`lis+0`} value={0}>全事業所</MenuItem>
                            {
                                this.state.branches.map((t, idx) =>
                                    <MenuItem key={`lis+${t.id}`} value={t.id}>{t.name}</MenuItem>
                                )
                            }
                        </Select>
                    }
                </div>
            },
            { name: "", content: <div style={{ display: "inline-flex" }}>{saQ.date("date_from")}〜{saQ.date("date_to")}</div> },
            {
                name: "", content: saQ.text("name", "利用者名、スタッフ名", "text", "block", false, null, () => {
                    if (this.state.loading == false) {
                        this.loadKirokus(this.state.branch_id, 0)
                    }
                })
            },
            {
                name: "", content: <Button color="primary" variant="contained"
                    disabled={this.state.loading}
                    onClick={(e) => {
                        this.loadKirokus(this.state.branch_id, 0)
                    }} >検索</Button>
            }
        ]

        const paging =
            <Pagination
                limit={this.state.kirokus.per}
                offset={(this.state.kirokus.page - 1) * this.state.kirokus.per}
                total={this.state.kirokus.count}
                disabled={this.state.loading}
                onClick={(e, offset) => {
                    this.loadKirokus(this.state.branch_id, offset)
                }}
            />

        return <div className={classes.root}>
            <div>
                <div style={{ display: "block", width: "100%" }}>
                    <StandardTable title=''>
                        <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12} md={12} lg={12}>
                                <Col2Table body={element_l} />
                            </Grid>
                        </Grid>
                    </StandardTable>
                </div>
            </div>
            <div>件数：{this.state.kirokus.count}</div>
            {paging}
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <CustomTableCell align="center" >提供日時</CustomTableCell>
                        <CustomTableCell align="center" >記録の種類</CustomTableCell>
                        <CustomTableCell align="center" >サービス</CustomTableCell>
                        <CustomTableCell align="center" >要介護</CustomTableCell>
                        <CustomTableCell align="center" >加算項目</CustomTableCell>
                        <CustomTableCell align="center" >利用者名</CustomTableCell>
                        <CustomTableCell align="center" >対応職員名</CustomTableCell>
                        <CustomTableCell align="right"></CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.state.kirokus.data.map(kiroku => {
                            const dis = kiroku.is_temp
                            return <TableRow key={"kiroku_" + kiroku.id} className={classes.row}
                                style={dis ? { backgroundColor: "#DDD" } : {}} >
                                <CustomTableCell align="center">
                                    <div>
                                        {DateUtil.toSlashDateStringShort(kiroku.time_start)}<br />
                                        {DateUtil.toTimeStr(kiroku.time_start)}
                                        〜{DateUtil.toTimeStr(kiroku.time_end)}
                                    </div>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {kiroku.kiroku_type}
                                    {
                                        kiroku.is_temp ? <div>(一時保存)</div> : ""
                                    }
                                </CustomTableCell>
                                <CustomTableCell align="center">{Model.KirokuII.KangoKirokuII.getKirokuCodeStr(kiroku)}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.patient.youkaigo}</CustomTableCell>
                                <CustomTableCell align="center" style={{ fontSize: "0.9rem" }}>{kiroku.basic.kasan ? Model.KirokuII.KangoKirokuII.getKasanText(kiroku) : ""}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.patient.name}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.main_user ? kiroku.main_user.name : ""}</CustomTableCell>
                                <CustomTableCell align="center"><a href={`/patients/${kiroku.patient.id}?kid=${kiroku.id}#activity`} target="_blank">確認</a></CustomTableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
            {paging}
        </div>
    }
}

export default withStyles(styles)(WorkLog)