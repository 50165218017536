import { OrganizationTanto } from '..';
export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public houkoku_saki: OrganizationTanto = new OrganizationTanto()
    public house_tokucho: string = ""
    public kankeisha: string = ""
    public kazoku: string = ""
    public kibou_honnin: string = ""
    public kibou_kazoku: string = ""
    public kiou: string = ""
    public pat_kouhi: string = ""
    public main_disease: string = ""
    public naihuku: string = ""
    public org_name: string = ""
    public pat_address: string = ""
    public pat_birthday: string = ""
    public pat_name: string = ""
    public pat_sex: string = ""
    public pat_youkaigo: string = ""
    public seiiku: string = ""
    public shujii: string = ""
    public to_name: string = ""
    public yami_kiseki: string = ""
    public adl: string = ""
    public branch_address: string = ""
    public branch_fax: string = ""
    public branch_name: string = ""
    public branch_tel: string = ""
    public choukaku: string = ""
    public comment: string = ""
    public create_date: string = ""
    public create_user: string = ""
    public drug: string = ""
    public hihu: string = ""
    public iadl: string = ""
    public iryo_kiki: string = ""
    public ishiki: string = ""
    public junkan: string = ""
    public kaiwa: string = ""
    public kango_mondai: string = ""
    public kokyu: string = ""
    public ninchi: string = ""
    public nyuin_keii: string = ""
    public shikaku: string = ""
    public shinkei: string = ""
    public suimin: string = ""

    public arergy: string = ""
    public kansen: string = ""
    public yobou: string = ""
    public social_monday: string = ""
    public social_tuesday: string = ""
    public social_wednesday: string = ""
    public social_thursday: string = ""
    public social_friday: string = ""
    public social_saturday: string = ""
    public social_sunday: string = ""
    public social_coment: string = ""
    public kanri_name: string = ""
}

export class Checks {
}
