
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public level_1: string = "　"
    public content_1: string = ""
    public level_2: string = "　"
    public content_2: string = ""
    public level_3: string = "　"
    public content_3: string = ""
    public level_4: string = "　"
    public content_4: string = ""
    public level_5: string = "　"
    public content_5: string = ""
    public level_6: string = "　"
    public content_6: string = ""
}

export class Checks {
}