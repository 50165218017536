import * as React from 'react';
import { PageProps, withPageProps } from '../components/Page'
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import { Button, Card, CardContent, CardActions, Typography, CardHeader, Grid } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "medium",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,
        
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {
            
            
        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    card: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
    },
})

interface Props extends PageProps, Styles.WithStyles {
}

interface LocalState {
    me: Model.User,
    users: Model.User[],
    loading: boolean
}

class ManageMessagePage extends React.Component<Props, LocalState> {
    displayName = "ManageMessagePage"
    constructor(props: Props) {
        super(props)
        this.state = {
            me: new Model.User(),
            users: [],
            loading: false
        }
    }

    public componentDidMount = () => {
        this.load()
    }

    private load() {
        this.setState({loading: true})
        HokanApi.me().then( mex => {
            this.setState({me: mex.data })
            console.log(mex.data)

            HokanApi.getUsers(this.state.me.branch_id)
                .then( x => {
                    this.setState({users:x.data, loading: false})
                    console.log(x.data)
                })
        })
    }

    public onClick = () => {
        const { users, loading } = this.state;
        this.setState({loading: true})
        Promise.all(users.map(u => {
            return HokanApi.message.user_import(u.id)
        }))
        .then( () => {
            alert('完了しました')
        })
    }

    public render() {
        const { classes, history } = this.props;
        return (
            <div className={classes.root}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={6} lg={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                subheader=""
                                title={"メッセージユーザ取り込み"}
                            />
                            <CardContent>
                                <Typography>
                                    事業所内のユーザを一括でメッセージ利用可能にします。
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" variant="contained" size="small" onClick={ () => this.onClick()}>選択</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withPageProps(Styles.withStyles(styles)(ManageMessagePage))