import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 85;
  }

  public createContent(): any {
    const res = new FormModel.Bi.Core();
    res.values.create_date = DateUtil.currentDateStr();
    HokanApi.me()
    .then((x) => {
      if (x && x.data) {
        res.values.hyoukasya = x.data.name;
      }
    })
    .catch((e) => {
      console.log(e);
    });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    const pointMap = {
      check_1: 10,
      check_2: 5,
      check_3: 0,
      check_4: 15,
      check_5: 10,
      check_6: 5,
      check_7: 0,
      check_8: 5,
      check_9: 0,
      check_10: 10,
      check_11: 5,
      check_12: 0,
      check_13: 5,
      check_14: 0,
      check_15: 15,
      check_16: 10,
      check_17: 5,
      check_18: 0,
      check_19: 10,
      check_20: 5,
      check_21: 0,
      check_22: 10,
      check_23: 5,
      check_24: 0,
      check_25: 10,
      check_26: 5,
      check_27: 0,
      check_28: 10,
      check_29: 5,
      check_30: 0,
    };

    let total = 0;
    const checks = Array(30)
      .fill(0)
      .map((_, i) => `check_${i + 1}`);

    checks.forEach((check) => {
      if (doc.content.checks[check]) {
        total += pointMap[check];
      }
    });

    doc.content.values.total_point = total;
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const questions = [
      {
        question: "1 食事",
        options: [
          {
            label: "自立、自助具などの装着可、標準的時間内に食べ終える",
            key: "check_1",
          },
          {
            label: "部分介助(たとえば、おかずを切って細かくしてもらう)",
            key: "check_2",
          },
          {
            label: "全介助",
            key: "check_3",
          },
        ],
      },
      {
        question: "2 車椅子からベッドへの移動",
        options: [
          {
            label: "自立、ブレーキ、フットレストの操作も含む(非行自立も含む)",
            key: "check_4",
          },
          {
            label: "軽度の部分介助または監視を要する",
            key: "check_5",
          },
          {
            label: "座ることは可能であるがほぼ全介助",
            key: "check_6",
          },
          {
            label: "全介助または不可能",
            key: "check_7",
          },
        ],
      },
      {
        question: "3 整容",
        options: [
          {
            label: "自立(洗面、整髪、歯磨き、ひげ剃り)",
            key: "check_8",
          },
          {
            label: "部分介助または不可能",
            key: "check_9",
          },
        ],
      },
      {
        question: "4 トイレ動作",
        options: [
          {
            label:
              "自立(衣服の操作、後始末を含む、ポータブル便器などを使用している場合はその洗浄も含む)",
            key: "check_10",
          },
          {
            label: "部分介助、体を支える、衣服、後始末に介助を要する",
            key: "check_11",
          },
          {
            label: "全介助または不可能",
            key: "check_12",
          },
        ],
      },
      {
        question: "5 入浴",
        options: [
          {
            label: "自立",
            key: "check_13",
          },
          {
            label: "部分介助または不可能",
            key: "check_14",
          },
        ],
      },
      {
        question: "6 歩行",
        options: [
          {
            label:
              "45m以上の歩行、補装具(車椅子、歩行器は除く)の使用有無は問わず",
            key: "check_15",
          },
          {
            label: "45m以上の介助歩行、歩行器の使用を含む",
            key: "check_16",
          },
          {
            label: "歩行不能の場合、車椅子にて45m以上の操作可能",
            key: "check_17",
          },
          {
            label: "上記以外",
            key: "check_18",
          },
        ],
      },
      {
        question: "7 階段昇降",
        options: [
          {
            label: "自立、手すりなどの使用の有無は問わない",
            key: "check_19",
          },
          {
            label: "介助または監視を要する",
            key: "check_20",
          },
          {
            label: "不能",
            key: "check_21",
          },
        ],
      },
      {
        question: "8 着替え",
        options: [
          {
            label: "自立、靴、ファスナー、装具の着脱を含む",
            key: "check_22",
          },
          {
            label: "部分介助、標準的な時間内、半分以上は自分で行える",
            key: "check_23",
          },
          {
            label: "上記以外",
            key: "check_24",
          },
        ],
      },
      {
        question: "9 排便コントロール",
        options: [
          {
            label: "失禁なし、浣腸、坐薬の取り扱いも可能",
            key: "check_25",
          },
          {
            label: "ときに失禁あり、浣腸、坐薬の取り扱いに介助を要する者も含む",
            key: "check_26",
          },
          {
            label: "上記以外",
            key: "check_27",
          },
        ],
      },
      {
        question: "10 排尿コントロール",
        options: [
          {
            label: "失禁なし、収尿器の取り扱いも可能",
            key: "check_28",
          },
          {
            label: "ときに失禁あり、収尿器の取り扱いに介助を要する者も含む",
            key: "check_29",
          },
          {
            label: "上記以外",
            key: "check_30",
          },
        ],
      },
    ];

    const elements_t = [
      {
        name: "評価者",
        content: <div style={{ padding: 10 }}>{saV.text("hyoukasya")}</div>,
      },
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            {questions.map((q) => {
              return (
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 10,
                    marginBottom: 10,
                    flexWrap: "wrap",
                  }}
                >
                  {q.question}
                  {saC.radio(q.options)}
                </label>
              );
            })}
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
