import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import InputText from '../components/InputText';
import ButtonOkCancel from '../components/ButtonOkCancel';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'

import { StoreAccessor } from '../util/StoreUtil';
import * as Colors from '../components/Colors'
import UserList from '../components/UserList';
import InputList from '../components/InputList';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {

    },
    textField: {
        width: "90%"
    },
    input: {
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto"
    },
    userList: {
        width: "80%",
        margin: "auto",
        padding: "50px"
    }
});

interface Params {
    branch_id: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    loading: boolean,
    companies: Model.Company[],
    data: Model.Branch | null,
    error: string | null,
    saigai_pdf: File | null,
    claim_pdf: File | null,
    jiko_pdf: File | null
    message: string
}

// @inject()
// @observer
class BranchPage extends React.Component<Props, LocalState> {
    displayName = "BranchPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            companies: [],
            data: null,
            error: null,
            saigai_pdf: null,
            claim_pdf: null,
            jiko_pdf: null,
            message: ""
        }
    }

    private setText(target, str) {
        const d = this.state.data as any
        d[target] = str;
        this.setState({ data: d })
    }

    public componentDidMount = () => {
        this.setState({ loading: true, error: null })
        HokanApi.company.list()
            .then(x => {
                this.setState({ companies: x.data })
            })

        if (this.createMode) {
            this.setState({ data: new Model.Branch(), loading: false })
        }
        else {
            HokanApi.branch.get(this.getId())
                .then(x => {
                    if (!x.data.kasan) x.data.kasan_obj = new Model.BranchKasan()
                    else x.data.kasan_obj = JSON.parse(x.data.kasan)
                    this.setState({ data: x.data, loading: false })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ data: null, loading: false })
                })
        }
    }

    private getId() {
        return parseInt(this.props.match.params.branch_id)
    }

    private get createMode() {
        return this.getId() == 0
    }

    onCommit() {
        this.setState({ loading: true, error: null })
        const id = this.getId()
        const createMode = id == 0
        if (!this.state.data) return
        this.state.data.kasan = JSON.stringify(this.state.data.kasan_obj)
        if (createMode) {
            HokanApi.branch.post(this.state.data)
                .then(x => {
                    this.setState({ loading: false })
                    this.onBack()
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.status == 422) {
                        this.setState({
                            error: "422",
                            loading: false
                        })
                    }
                    else {
                        this.setState({
                            error: "不明",
                            loading: false
                        })
                    }
                })
        } else {
            HokanApi.branch.put(this.state.data)
                .then(x => {
                    if (this.state.saigai_pdf) {
                        HokanApi.putSaigaiPDF(id, this.state.saigai_pdf)
                    }
                    if (this.state.claim_pdf) {
                        HokanApi.putClaimPDF(id, this.state.claim_pdf)
                    }
                    if (this.state.jiko_pdf) {
                        HokanApi.putJikoPDF(id, this.state.jiko_pdf)
                    }
                    this.setState({
                        loading: false,
                        error: "",
                        message: "更新しました"
                    })
                    this.onBack()
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.status == 422) {
                        this.setState({
                            error: "422",
                            loading: false
                        })
                    }
                    else {
                        this.setState({
                            error: "不明",
                            loading: false
                        })
                    }
                })
        }
    }

    onBack() {
        this.props.history.goBack()
    }

    public render() {
        const { classes } = this.props
        const { data } = this.state
        const companyList = this.state.companies.map(b => {
            return { label: b.name, value: "" + b.id }
        })
        const sa = new StoreAccessor(this, true, "data")
        console.log(sa)
        if (this.state.loading || !this.state.data) return <div>loading..</div>
        return <div className={classes.root}>
            <SubTitle title="事業所情報"
            />
            <Grid container spacing={2} className={classes.grid}>
                <InputList title="所属法人" value={data ? "" + data.company_id : ""} onChange={(s) => this.setText("company_id", parseInt(s))} values={companyList} />
                <InputText title="事業所名" value={data ? data.name : ""} onChange={(s) => this.setText("name", s)} />
                <InputText title="電話番号" value={data ? data.tel : ""} onChange={(s) => this.setText("tel", s)} />
                <InputText title="FAX番号" value={data ? data.fax : ""} onChange={(s) => this.setText("fax", s)} />
                <InputText title="郵便番号" value={data ? data.zip : ""} onChange={(s) => this.setText("zip", s)} />
                <InputText title="住所" value={data ? data.address : ""} onChange={(s) => this.setText("address", s)} />
                <InputText title="管理者名" value={data ? data.kanri_name : ""} onChange={(s) => this.setText("kanri_name", s)} />
                <InputText title="介護保険指定事業者番号" value={data ? data.hoken_jigyosha_no : ""} onChange={(s) => this.setText("hoken_jigyosha_no", s)} />
                <InputText title="レセコンID" value={data ? data.wiseman_id : ""} onChange={(s) => this.setText("wiseman_id", s)} />

                <Grid key="jiko" item xs={3} className={classes.row}>
                    事故マニュアルPDF
                </Grid>
                <Grid key="jiko_pdf" item xs={9}>
                    {(!data || data.id == 0) ? <div>事業所作成後に設定してください</div> : null}
                    {
                        data && data.jiko_pdf_url ?
                            <div><a href={data.jiko_pdf_url} target="_blank">確認する</a></div>
                            : <div>未設定</div>
                    }
                    <input type="file" id="" ref="file2"
                        accept=".pdf"
                        disabled={!data || data.id == 0}
                        multiple={false}
                        onChange={(e) => {
                            const selectedFiles = e.target.files
                            if (selectedFiles) {
                                this.setState({ jiko_pdf: selectedFiles[0] })
                            }
                        }} />
                </Grid>
                <Grid key="claim" item xs={3} className={classes.row}>
                    苦情マニュアルPDF
                </Grid>
                <Grid key="claim_pdf" item xs={9}>
                    {(!data || data.id == 0) ? <div>事業所作成後に設定してください</div> : null}
                    {
                        data && data.claim_pdf_url ?
                            <div><a href={data.claim_pdf_url} target="_blank">確認する</a></div>
                            : <div>未設定</div>
                    }
                    <input type="file" id="" ref="file2"
                        accept=".pdf"
                        disabled={!data || data.id == 0}
                        multiple={false}
                        onChange={(e) => {
                            const selectedFiles = e.target.files
                            if (selectedFiles) {
                                this.setState({ claim_pdf: selectedFiles[0] })
                            }
                        }} />
                </Grid>
                <Grid key="saigai" item xs={3} className={classes.row}>
                    災害時マニュアルPDF
                </Grid>
                <Grid key="saigai_pdf" item xs={9}>
                    {(!data || data.id == 0) ? <div>事業所作成後に設定してください</div> : null}
                    {
                        data && data.saigai_pdf_url ?
                            <div><a href={data.saigai_pdf_url} target="_blank">確認する</a></div>
                            : <div>未設定</div>
                    }
                    <input type="file" id="" ref="file"
                        accept=".pdf"
                        disabled={!data || data.id == 0}
                        multiple={false}
                        onChange={(e) => {
                            const selectedFiles = e.target.files
                            if (selectedFiles) {
                                this.setState({ saigai_pdf: selectedFiles[0] })
                            }
                        }} />
                </Grid>

                <Grid key="kasan_1" item xs={3} className={classes.row}>
                    加算
                </Grid>
                <Grid key="kasan_2" item xs={9}>
                    {sa.checkListC(["kasan_obj"], [
                        { key: "is_kanri_ryouyou", label: "管理療養費" },
                        { key: "is_kanri_ryouyou_kinou1", label: "機能強化型訪問看護管理療養費１" },
                        { key: "is_kanri_ryouyou_kinou2", label: "機能強化型訪問看護管理療養費２" },
                        { key: "is_kanri_ryouyou_kinou3", label: "機能強化型訪問看護管理療養費３" },
                        { key: "is_minashi", label: "みなし訪問看護" },
                        //{ key: "is_24h", label: "２４時間対応体制加算" },
                        { key: "is_tokubetsu_chiiki1", label: "特別地域訪問看護加算１" },
                        { key: "is_tokubetsu_chiiki2", label: "特別地域訪問看護加算２" },
                        { key: "is_shoukibo1", label: "訪問看護小規模事業所加算１" },
                        { key: "is_shoukibo2", label: "訪問看護小規模事業所加算２" },
                        { key: "is_taisei_kyouka1", label: "訪問看護体制強化加算Ⅰ" },
                        { key: "is_taisei_kyouka2", label: "訪問看護体制強化加算Ⅱ" },
                        { key: "is_teikyou_taisei1", label: "訪問看護サービス提供体制加算１" },
                        { key: "is_teikyou_taisei2", label: "訪問看護サービス提供体制加算２" }
                    ], true)}
                </Grid>
            </Grid>

            {
                this.state.error ?
                    <div style={{ color: Colors.dangerColor }}>
                        エラー：{this.state.error}
                    </div>
                    : null
            }
            <div>
                {this.state.message}
            </div>
            <ButtonOkCancel
                cancelLabel="戻る" okLabel="確定"
                onCancel={() => this.onBack()}
                onOk={() => this.onCommit()}
                showBack={true}
                disabled={this.state.loading} />

            {
                this.createMode ? null
                    : <div className={classes.userList}>
                        <SubTitle title="所属スタッフ" />
                        <UserList showButton={false} branchId={this.getId()} />
                    </div>
            }
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(BranchPage))