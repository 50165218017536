import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import * as FormBehaviour from './FormBehaviour'
import { DateUtil } from '../../util/DateUtil';
import { Button } from '@material-ui/core';
import * as HokanApi from '../../api/hokan'
import moment from 'moment';
import OrgSelectDialog from '../OrgSelectDialog';
import { OrganizationTanto } from '../../models';
class TempData {
    exists: boolean = false
    openDocDialog: boolean = false
}
// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"

    public getId(): number {
        return 27
    }

    public createContent(): any {
        const res = new FormModel.Teikyosho.Core()
        res.values.create_date = DateUtil.currentDateStr()
        res.values.calendar_from = DateUtil.currentMonthFirstDateStr();
        res.values.calendar_to = DateUtil.currentMonthLastDateStr();
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        doc.patient = pat;
        const c = doc.content as FormModel.Teikyosho.Core
        if (doc.id == 0) {
            this.setPatientData(doc, pat)
            this.setCreateDate(doc, c.values.create_date, sa)
        }
    }
    public setCreateDate(doc: Model.Document.Document, create_date: string, sa: StoreAccessor) {
        const c = doc.content as FormModel.Keikakusho.Core
        c.values.create_date = create_date
        doc.target_month = DateUtil.toTargetMonthNum(create_date)
        if (create_date) {
            this.existsCheck(doc, sa, doc.patient, doc.target_month)
        }

        if (!create_date) {
            c.values.create_date = moment(create_date).endOf("month").format("YYYY-MM-DD")
        }
    }

    public setPatientData(doc: Model.Document.Document, pat: Model.Patient.Patient) {
        const c = doc.content as FormModel.Teikyosho.Core
        if (!pat) return;
        if (pat.youkaigo == Model.Patient.YouKaigoType.None) c.checks.check_youkaiago_jiritsu = true
        else if (pat.youkaigo == Model.Patient.YouKaigoType.Shien1) c.checks.check_youshien_1 = true
        else if (pat.youkaigo == Model.Patient.YouKaigoType.Shien2) c.checks.check_youshien_2 = true
        else if (pat.youkaigo == Model.Patient.YouKaigoType.Kaigo1) c.checks.check_youkaigo_1 = true
        else if (pat.youkaigo == Model.Patient.YouKaigoType.Kaigo2) c.checks.check_youkaigo_2 = true
        else if (pat.youkaigo == Model.Patient.YouKaigoType.Kaigo3) c.checks.check_youkaigo_3 = true
        else if (pat.youkaigo == Model.Patient.YouKaigoType.Kaigo4) c.checks.check_youkaigo_4 = true
        else if (pat.youkaigo == Model.Patient.YouKaigoType.Kaigo5) c.checks.check_youkaigo_5 = true


        c.values.pat_birthday = pat.birthday;
        if (pat.env) {
            c.values.pat_address = pat.env.address1 + pat.env.address2 + pat.env.address3;
        }
        c.values.pat_tel = pat.tel_home
        c.values.pat_name = pat.name
        if (pat.value && pat.value.sexuality) {
            c.checks.check_female = (pat.value.sexuality == "Female" || pat.value.sexuality.indexOf("女") >= 0)
            c.checks.check_male = (pat.value.sexuality == "Male" || pat.value.sexuality.indexOf("男") >= 0)
        }

        if (pat.contact) {
            if (pat.contact.shujii) {
                c.values.doc_name = pat.contact.shujii.name
                if (pat.contact.shujii.org) {
                    c.values.doc_address = pat.contact.shujii.org.address + " " + pat.contact.shujii.org.name
                }
            }
        }

        if (pat.medical) {
            c.values.disease_name = pat.medical.sick_name
            c.values.disease_status = pat.medical.ourai_reki
            c.values.kiou_reki = pat.medical.kiou_reki
            if (pat.medical.adl) {
                const setADLScore = (name, score) => {
                    c.checks[name + "_1"] = score == Model.Patient.ADLScore.S10
                    c.checks[name + "_2"] = score == Model.Patient.ADLScore.S5
                    c.checks[name + "_3"] = score == Model.Patient.ADLScore.S0
                }
                const adl = pat.medical.adl
                setADLScore("check_ido", adl.idou)
                setADLScore("check_haisetsu", adl.haiben)
                setADLScore("check_kigae", adl.koui)
                setADLScore("check_shokuji", adl.shokuji)
                setADLScore("check_nyuyoku", adl.nyuyoku)
                setADLScore("check_seiyou", adl.seiyou)
            }
        }
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const c = doc.content as FormModel.Teikyosho.Core
        c.values.pat_age = DateUtil.getAge(c.values.pat_birthday).toString()
    }

    public beforeMount = (doc: Model.Document.Document, sa: StoreAccessor) => {
        doc.temp_data = new TempData()
        this.existsCheck(doc, sa, doc.patient, doc.target_month)
    }
    private existsCheck(doc: Model.Document.Document, sa: StoreAccessor, pat: Model.Patient.Patient | null, target_month: number | null) {
        if (!pat) {
            return;
        }
        HokanApi.checkDocumentExists(pat.id, doc.document_type.id, target_month, doc.id).then(res => {
            doc.temp_data.exists = res.data
            doc.cannotCommit = res.data
            sa.updateState()
        })
        sa.updateState()
    }

    private loadFromKirokuI(doc: Model.Document.Document, sa: StoreAccessor, callback: (ct: FormModel.Teikyosho.Core, p: Model.Patient.Patient) => void) {
        if (!doc.patient || doc.patient.id == 0) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.Teikyosho.Core
        HokanApi.getPatient(doc.patient.id).then(res => {
            const p = Model.Patient.Patient.load(res.data, false)
            callback(content, p)
            sa.updateState()
        }).catch(err => { console.log(err) })
    }

    private shukeiNissuu(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.Teikyosho.Core
        if (!content.values.calendar_from || !content.values.calendar_to) {
            alert("日付を選択してください")
            return;
        }

        HokanApi.getKirokuIIsForHoukoku(doc.patient.id, content.values.calendar_from, content.values.calendar_to).then(x => {
            const d = x.data
            d.data = d.data.map(y => Model.KirokuII.KangoKirokuII.load(y))
            let count = 0;
            const ss = {}
            d.data.forEach(x => {
                if (x.kiroku_type == Model.KirokuII.KirokuType.Jihi) return;
                ++count
                const t = moment(x.time_start)
                let ts = ""
                if (t) {
                    ts = t.format("YYYY-MM-DD")
                }
                ss[ts] = true;
            })
            content.values.houmon_nissuu = `${Object.keys(ss).length}日/(${count}回）`
            sa.updateState();
        })
    }

    private intSort(a: Model.Intervention, b: Model.Intervention) {
        let toN = (c: string) => {
            if (c == "TGC") return 1
            if (c == "CM") return 2
            if (c == "TP") return 3
            if (c == "S") return 4
            return 5;
        }
        let ac = toN(a.category)
        let bc = toN(b.category)
        if (ac < bc) return -1;
        if (ac > bc) return +1;
        if (a.id < b.id) return -1;
        if (a.id > b.id) return +1;
        return 0
    }

    private catToJp(cat: string) {
        if (cat == "TGC") return "TGC(教育/相談/指導)"
        if (cat == "CM") return "CM(ケアマネジメント)"
        if (cat == "TP") return "TP(直接ケア)"
        if (cat == "S") return "S(観察)"
        return ""
    }
    private async loadFromAssessment(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        HokanApi.getPatientAssessments(doc.patient.id).then(async _assList => {
            if (doc.patient == null) return;

            const content = doc.content as FormModel.Teikyosho.Core
            const values = content.values
            const x = _assList.data
            if (x != null) {
                values.kango_mondai = "";
                values.kango_naiyo = "";

                x.sort((a, b) => {
                    if (a.oldest.assessment.assessment_day < b.oldest.assessment.assessment_day) return -1;
                    if (a.oldest.assessment.assessment_day > b.oldest.assessment.assessment_day) return +1;
                    return 0;
                }).forEach(x => {
                    if (x.newest.assessment.priority == 1 && (x.newest.assessment.closed == false)) {
                        values.kango_mondai += x.problem.name + " / "
                        let sortedIntv = x.newest.intervention.sort(this.intSort)
                        let prev = ""
                        let intvStr = sortedIntv.map(x => {
                            let t = (prev != x.category ? "\n" + this.catToJp(x.category) + ": " : ", ")
                            prev = x.category;
                            return t + x.target
                        }).reduce((p, c) => p + c, "")
                        values.kango_naiyo += "# " + x.problem.name
                        values.kango_naiyo += intvStr + "\n"
                    }
                })
            }
            const pat = doc.patient
            if (pat) {
                const names = Model.Patient.IryoKiguNames
                let saigai = ((typeof pat.saigai) == "string" ? JSON.parse(pat.saigai as any) : pat.saigai)
                if (saigai && saigai.kigus) {
                    const names = Model.Patient.IryoKiguNames
                    Object.keys(names).forEach(key => {
                        if (saigai.kigus[key]) {
                            if (values.kango_naiyo != "") values.kango_naiyo += " / "
                            values.kango_naiyo += names[key];
                        }
                    })
                }
            }
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }

    private loadFromBranch(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const content = doc.content as FormModel.Teikyosho.Core
        const values = content.values
        HokanApi.branch.get(doc.patient.branch_id).then(res => {
            values.branch_name = res.data.name
            values.kanri_name = res.data.kanri_name
            values.branch_address = res.data.address
            values.branch_tel = res.data.tel
            sa.updateState()
        }).catch(err => { console.log(err) })
    }
    private loadLatest(doc: Model.Document.Document, sa: StoreAccessor, value_names: string[], check_names: string[]) {
        if (!doc.patient) {
            alert("利用者を選択してください")
            return;
        }
        const current = doc.content as FormModel.Teikyosho.Core
        HokanApi.getDocumentLatest(doc.patient.id, doc.document_type.id, current.values.create_date ? current.values.create_date : undefined).then(res => {
            const latest = res.data.content as FormModel.Teikyosho.Core
            value_names.forEach(name => current.values[name] = latest.values[name])
            check_names.forEach(name => current.checks[name] = latest.checks[name])
            sa.updateState()
        }).catch(err => {
            console.log(err)
        })
    }


    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor, classes: any) {
        const saV = sa.generateChild(["content", "values"])
        const saC = sa.generateChild(["content", "checks"])
        const saD = sa.generateChild(["content"])
        const content = doc.content as FormModel.Teikyosho.Core
        const copyButton = (value_names: string[], check_names: string[]) => <Button variant="outlined" size="small"
            onClick={(e) => this.loadLatest(doc, sa, value_names, check_names)}
            className={classes.loadButton} >前回から取得</Button>


        const YS1 = 1
        const YS2 = 2
        const YS3 = 4
        const YS4 = 8
        const flag = (
            (content.checks.youshiki_1 ? YS1 : 0) +
            (content.checks.youshiki_2 ? YS2 : 0) +
            (content.checks.youshiki_3 ? YS3 : 0) +
            (content.checks.youshiki_4 ? YS4 : 0)
        )
        const onFlag = (value, content) => {
            return (flag & value) > 0 ? content : null
        }

        const initHoukokuSaki = (name:string)=> {
            let t = content.values[name]
            if (!t) t = []
            else if (Array.isArray(content.values[name]) == false) t = [t]
            content.values[name] = t;
        }
        initHoukokuSaki("houkoku_saki_1")
        initHoukokuSaki("houkoku_saki_2")
        initHoukokuSaki("houkoku_saki_3")
        initHoukokuSaki("houkoku_saki_4")

        const noYoushiki = flag == 0
        const noHoukokuSaki = (
            (content.checks.youshiki_1 && (content.values.houkoku_saki_1 && content.values.houkoku_saki_1.length === 0)) ||
            (content.checks.youshiki_2 && (content.values.houkoku_saki_2 && content.values.houkoku_saki_2.length === 0)) ||
            (content.checks.youshiki_3 && (content.values.houkoku_saki_3 && content.values.houkoku_saki_3.length === 0)) ||
            (content.checks.youshiki_4 && (content.values.houkoku_saki_4 && content.values.houkoku_saki_4.length === 0))
        )
        const cannotCommit = noHoukokuSaki || doc.temp_data.exists || noHoukokuSaki
        if (cannotCommit != doc.cannotCommit) {
            doc.cannotCommit = cannotCommit
            doc.canTempSave = true
            sa.updateState()
        }


        const elements_basic = [
            {
                name: "コピー", content: <div>
                    {copyButton(["pat_name", "pat_birthday", "pat_tel", "pat_work", "pat_address"],
                        ["youshiki_1", "youshiki_2", "youshiki_3", "youshiki_4",
                            "check_male", "check_female"])}
                    <Button variant="outlined" size="small"
                        disabled={!doc.patient}
                        onClick={(e) => {
                            if (doc.patient) {
                                this.setPatientData(doc, doc.patient)
                                sa.updateState()
                            }
                        }}
                        className={classes.loadButton} >基本情報反映（全てに）</Button>
                </div>
            },
            {
                name: "様式", content: <div>
                    {
                        noYoushiki ?
                            <div style={{ color: "red", fontWeight: "bold" }}>様式は一つ以上必要です</div>
                            : null
                    }
                    {saC.checkList([
                        { label: "様式１\n(市区町村)", key: "youshiki_1" },
                        { label: "様式２\n(市区町村：精神科訪問看護)", key: "youshiki_2" },
                        { label: "様式３\n(学校・保育園等)", key: "youshiki_3" },
                        { label: "様式４\n(保険医療機関等)", key: "youshiki_4" }
                    ], false)}
                </div>
            },
            { name: "生年月日", content: saV.date("pat_birthday") },
            {
                name: "性別", content: saC.checkList([
                    { label: "男性", key: "check_male" },
                    { label: "女性", key: "check_female" },
                ], false, true)
            },
            { name: "電話番号", content: saV.text("pat_tel") },
            onFlag(YS1 + YS2 + YS4, { name: "職業", content: saV.text("pat_work") }),
            { name: "住所", content: saV.text("pat_address") },
            { name: "年月", content: <div>{DateUtil.toTargetMonthStr(content.values.create_date)}{doc.temp_data.exists ? <div style={{ color: "red", fontWeight: "bold" }}>エラー：他に存在します</div> : ""}</div> },
            {
                name: "訪問看護情報提供療養費", content: saD.checkList([
                    { key: "is_jouhou_teikyo_0", label: "なし" },
                    { key: "is_jouhou_teikyo_1", label: "訪問看護情報提供療養費１" },
                    { key: "is_jouhou_teikyo_2", label: "訪問看護情報提供療養費２" },
                    { key: "is_jouhou_teikyo_3", label: "訪問看護情報提供療養費３" }
                ], true, true)
            },
        ].filter(x => x)

        const adl_pair = [
            [
                { label: "自立", key: "check_ido_1" },
                { label: "一部介助", key: "check_ido_2" },
                { label: "全面介助", key: "check_ido_3" },
            ],
            [
                { label: "自立", key: "check_haisetsu_1" },
                { label: "一部介助", key: "check_haisetsu_2" },
                { label: "全面介助", key: "check_haisetsu_3" },
            ],
            [
                { label: "自立", key: "check_kigae_1" },
                { label: "一部介助", key: "check_kigae_2" },
                { label: "全面介助", key: "check_kigae_3" },
            ],
            [
                { label: "自立", key: "check_shokuji_1" },
                { label: "一部介助", key: "check_shokuji_2" },
                { label: "全面介助", key: "check_shokuji_3" },
            ],
            [
                { label: "自立", key: "check_nyuyoku_1" },
                { label: "一部介助", key: "check_nyuyoku_2" },
                { label: "全面介助", key: "check_nyuyoku_3" },
            ],
            [
                { label: "自立", key: "check_seiyou_1" },
                { label: "一部介助", key: "check_seiyou_2" },
                { label: "全面介助", key: "check_seiyou_3" },
            ],
        ]
        const patId = doc.patient ? doc.patient.id : undefined
        const genHoukokusaki = (name:string) => {
            return <div>
                    {
                        content.values[name].map((o, i) => {
                            return saV.orgTantoC([name], i, patId)
                        })
                    }
                    {
                        sa.isEditing ?
                            <Button variant="outlined" size="small" color="primary"
                                onClick={(e) => {
                                    const t = content.values[name] || [] as Model.OrganizationTanto[]
                                    t.push(new Model.OrganizationTanto());
                                    saV.setValue(name, t)
                                }}>
                                ＋追加
                            </Button>
                            : null
                    }
            </div>
        }
        const elements_houkoku = [
            noHoukokuSaki ?
                {
                    name: " ", content: <div>
                        <div style={{ color: "red", fontWeight: "bold" }}>
                            報告先の指定されていない様式があります
                        </div>
                    </div>
                }
                : null,
            { name: "コピー", content: copyButton(["houkoku_saki_1", "houkoku_saki_2", "houkoku_saki_3", "houkoku_saki_4"], []) },
            onFlag(YS1, { name: "様式１", content: genHoukokusaki("houkoku_saki_1") }),
            onFlag(YS2, { name: "様式２", content: genHoukokusaki("houkoku_saki_2") }),
            onFlag(YS3, { name: "様式３", content: genHoukokusaki("houkoku_saki_3") }),
            onFlag(YS4, { name: "様式４", content: genHoukokusaki("houkoku_saki_4") }),
        ].filter(x => x)

        const elements_nichijo = [
            {
                name: "コピー", content: copyButton(
                    ["seikatsu_rythm", "hukuyaku", "taijin"], adl_pair.reduce(function (ys, x) {
                        return ys.concat(x.map(y => y.key))
                    }, [] as string[])
                )
            },
            onFlag(YS1, { name: "移動", content: saC.checkList(adl_pair[0], false, true) }),
            onFlag(YS1, { name: "排泄", content: saC.checkList(adl_pair[1], false, true) }),
            onFlag(YS1, { name: "着替え", content: saC.checkList(adl_pair[2], false, true) }),
            onFlag(YS1, { name: "食事", content: saC.checkList(adl_pair[3], false, true) }),
            onFlag(YS1, { name: "入浴", content: saC.checkList(adl_pair[4], false, true) }),
            onFlag(YS1, { name: "整容", content: saC.checkList(adl_pair[5], false, true) }),
            onFlag(YS2 + YS3 + YS4, { name: "食生活、清潔、排泄、睡眠、生活リズム等について", content: saV.text("seikatsu_rythm") }),
            onFlag(YS2 + YS3 + YS4, { name: "服薬等の状況について", content: saV.text("hukuyaku") }),
            onFlag(YS2, { name: "作業(仕事)、対人関係等について", content: saV.text("taijin") }),
        ].filter(x => x)

        const elements_doctor = [
            {
                name: "コピー", content: <div>
                    {copyButton(["doc_name", "doc_address"], [])}
                    <Button variant="outlined" size="small" color="primary"
                        onClick={(e) => {
                            doc.temp_data.openDocDialog = true;
                            sa.updateState();
                        }}>
                        機関マスタから選択
                    </Button>
                    {
                        doc.temp_data.openDocDialog ?
                            <OrgSelectDialog
                                key={`orgTanto_dialog_doc`}
                                open={true}
                                patientId={doc.patient ? doc.patient.id : undefined}
                                onSelect={(org, tanto) => {
                                    if (tanto) {
                                        doc.content.values.doc_name = tanto.name
                                    }
                                    doc.content.values.doc_address = org.address + " " + org.name
                                    sa.updateState();
                                }}
                                onCancel={() => {
                                    doc.temp_data.openDocDialog = false;
                                    sa.updateState();
                                }}
                            />
                            : null
                    }
                </div>
            },
            { name: "主治医氏名", content: saV.text("doc_name") },
            { name: "主治医住所", content: saV.text("doc_address") }
        ]

        const elements_disease = [
            {
                name: "コピー", content: <div>
                    {copyButton(["disease_name", "disease_status", "kiou_reki"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromKirokuI(doc, sa, (ct, p) => {
                            if (p.medical) {
                                ct.values.disease_name = p.medical.sick_name
                                ct.values.disease_status = p.medical.ourai_reki
                                ct.values.kiou_reki = p.medical.kiou_reki
                            }
                        })}
                        className={classes.loadButton} >記録Iから取得</Button>
                </div>
            },
            { name: "主傷病名", content: saV.text("disease_name") },
            onFlag(YS3, { name: "傷病の経過", content: saV.text("disease_status") }),
            onFlag(YS4, { name: "既往歴", content: saV.text("kiou_reki") }),
        ].filter(x => x)

        const elements_youkaigo = [
            {
                name: "コピー", content: copyButton(["byoujou_shougai_status", "other_service"],
                    ["check_youkaiago_jiritsu", "check_youshien_1", "check_youshien_2",
                        "check_youkaigo_1", "check_youkaigo_2", "check_youkaigo_3", "check_youkaigo_4", "check_youkaigo_5"])
            },
            {
                name: "要介護度", content: saC.checkList([
                    { label: "自立", key: "check_youkaiago_jiritsu" },
                    { label: "要支援1", key: "check_youshien_1" },
                    { label: "要支援2", key: "check_youshien_2" },
                    { label: "要介護1", key: "check_youkaigo_1" },
                    { label: "要介護2", key: "check_youkaigo_2" },
                    { label: "要介護3", key: "check_youkaigo_3" },
                    { label: "要介護4", key: "check_youkaigo_4" },
                    { label: "要介護5", key: "check_youkaigo_5" },
                ], false, true)
            },
            onFlag(YS1, { name: "病状、障害等の状態", content: saV.text("byoujou_shougai_status") }),
            onFlag(YS4, { name: "その他(利用しているサービス等)", content: saV.text("other_service") })
        ].filter(x => x)

        const elements_kazoku = [
            { name: "コピー", content: copyButton(["kazoku_info"], []) },
            { name: "内容", content: saV.text("kazoku_info") },
        ]
        const elements_nissu = [
            {
                name: "対象期間", content: <div><div>
                    {saV.date("calendar_from")}〜
                    {saV.date("calendar_to")}
                </div>
                    <Button variant="outlined" size="small" color="primary"
                        onClick={(e) => {
                            this.shukeiNissuu(doc, sa);
                        }}>
                        集計
                    </Button>
                </div>
            },
            {
                name: "内容", content: <div>{content.values.houmon_nissuu}</div>
            },
        ]
        const elements_kijutsu = [
            {
                name: "コピー", content: <div>
                    {copyButton(["kango_mondai", "kango_naiyo", "iryo_care", "need_service"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromAssessment(doc, sa)}
                        className={classes.loadButton} >オマハから取得</Button>
                    { }
                </div>
            },
            onFlag(YS4, { name: "看護上の問題等", content: saV.text("kango_mondai") }),
            onFlag(YS1 + YS2 + YS3 + YS4, { name: "看護の内容", content: saV.text("kango_naiyo") }),
            onFlag(YS3 + YS4, { name: "医療的ケア等の実施方法及び留意事項", content: saV.text("iryo_care") }),
            onFlag(YS1 + YS2, { name: "必要と考えられる保健福祉サービス", content: saV.text("need_service") }),
        ].filter(x => x)
        const elements_comment = [
            { name: "コピー", content: copyButton(["comment"], []) },
            { name: "内容", content: saV.text("comment") },
        ]
        const elements_houkoku_moto = [
            {
                name: "コピー", content: <div>
                    {copyButton(["branch_name", "kanri_name", "branch_address", "branch_tel"], [])}
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromBranch(doc, sa)}
                        className={classes.loadButton} >事業所情報から取得</Button>
                </div>
            },
            { name: "事業所名", content: saV.text("branch_name") },
            { name: "管理者名", content: saV.text("kanri_name") },
            { name: "事業所住所", content: saV.text("branch_address") },
            { name: "事業所TEL", content: saV.text("branch_tel") }
        ]

        const group = [
            { name: '', content: elements_basic },
            { name: '報告先', content: elements_houkoku },
            { name: '主治医情報', content: elements_doctor },
            { name: '主傷病名', content: elements_disease },
            { name: '日常生活活動(ADL)の状況', content: elements_nichijo },
            onFlag(YS1 + YS2 + YS4, { name: '要介護認定の状況', content: elements_youkaigo }),
            onFlag(YS1 + YS2 + YS3, { name: '１月当たりの訪問日数（訪問看護療養費明細書の実日数を記入すること）', content: elements_nissu }),
            { name: '家族等及び主な介護者に係る情報', content: elements_kazoku },
            { name: '記述項目', content: elements_kijutsu },
            { name: 'その他特記すべき事項', content: elements_comment },
            { name: '報告元', content: elements_houkoku_moto },
        ].filter(x => x)

        return group
    }
}
