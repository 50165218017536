import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class BodyBMSBehaviour extends FormBehaviour.PdfBehaviour { 
	displayName="BodyBMSBehaviour"
    private choice = [
        { key: "未選択", value: -1 },
        { key: "0：動作不可能", value: 0 },
        { key: "1：動作一部可能（協力動作量＜介助量）", value: 1 },
        { key: "2：ほぼ動作可能（協力動作量＞介助量）", value: 2 },
        { key: "3：動作可能（監視や誘導またはセッティングが必要）", value: 3 },
        { key: "4：完全に動作可能", value: 4 }
    ]

    public getId(): number {
        return 18
    }

    public createContent(): any {
        const res = new FormModel.BodyBMS.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>・ベッドサイドモビリティスケール：牧迫飛雄馬，阿部　勉，島田裕之，他：要介護者のためのBedside Mobility Scaleの開発─信頼性およ び妥当性の検討．理学療法学35（3）：81-88，2008</div>
        </div>
    }
    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.BodyBMS.Core
        content.values.sum_score = this.getResult(doc.content)
        const setIndexValue = (name: string) => {
            const t = this.choice.find(x => x.value == content.indexes[name])
            content.values[name] = (t&&t.value!=-1) ? t.key : ""
        }
        setIndexValue("bed_chair_ijou")
        setIndexValue("bed_idou")
        setIndexValue("bed_zai")
        setIndexValue("idou_chair")
        setIndexValue("chair_zai")
        setIndexValue("negaeri")
        setIndexValue("okiagari")
        setIndexValue("ritsui_hoji")
        setIndexValue("tachiagari")
        setIndexValue("zai_hirou")
        content.values.sum_score = this.getResult(content)
    }

    public beforeMount = () => {

    }

    private getResult(content: FormModel.BodyBMS.Core) {
        let sum =
            Math.max(0, content.indexes.bed_chair_ijou) +
            Math.max(0, content.indexes.bed_idou) +
            Math.max(0, content.indexes.bed_zai) +
            Math.max(0, content.indexes.idou_chair) +
            Math.max(0, content.indexes.chair_zai) +
            Math.max(0, content.indexes.negaeri) +
            Math.max(0, content.indexes.okiagari) +
            Math.max(0, content.indexes.ritsui_hoji) +
            Math.max(0, content.indexes.tachiagari) +
            Math.max(0, content.indexes.zai_hirou)
        return sum
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "indexes"])
        const elements_scores = [
            { name: "寝返り", content: saV.listRaw("negaeri", this.choice) },
            { name: "ベッド上での移動", content: saV.listRaw("bed_idou", this.choice) },
            { name: "起き上がり", content: saV.listRaw("okiagari", this.choice) },
            { name: "ベッド上での座位保持", content: saV.listRaw("bed_zai", this.choice) },
            { name: "座位で物を拾う", content: saV.listRaw("zai_hirou", this.choice) },
            { name: "立ち上がり", content: saV.listRaw("tachiagari", this.choice) },
            { name: "立位保持", content: saV.listRaw("ritsui_hoji", this.choice) },
            { name: "ベッド⇔いす（車いす）移乗", content: saV.listRaw("bed_chair_ijou", this.choice) },
            { name: "いす（車いす）上での座位保持", content: saV.listRaw("chair_zai", this.choice) },
            { name: "移動（車いす駆動）", content: saV.listRaw("idou_chair", this.choice) },
            { name: "合計点", content: <div>{this.getResult(doc.content)}</div> },
        ]

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
