
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public score: string = ""
}

export class Checks {
    public check_maru_0: boolean = false
    public check_maru_10: boolean = false
    public check_maru_100: boolean = false
    public check_maru_20: boolean = false
    public check_maru_30: boolean = false
    public check_maru_40: boolean = false
    public check_maru_50: boolean = false
    public check_maru_60: boolean = false
    public check_maru_70: boolean = false
    public check_maru_80: boolean = false
    public check_maru_90: boolean = false
    public check_waku_0: boolean = false
    public check_waku_10: boolean = false
    public check_waku_100: boolean = false
    public check_waku_20: boolean = false
    public check_waku_30: boolean = false
    public check_waku_40: boolean = false
    public check_waku_50: boolean = false
    public check_waku_60: boolean = false
    public check_waku_70: boolean = false
    public check_waku_80: boolean = false
    public check_waku_90: boolean = false
}