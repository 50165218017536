import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle';
import { Grid, Table, TableHead, TableRow, TableBody, TableCell, Button } from '@material-ui/core';
import InputText from '../components/InputText';
import ButtonOkCancel from '../components/ButtonOkCancel';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import * as Icons from '@material-ui/icons';
import * as Colors from '../components/Colors'
import { withStyles } from '@material-ui/core/styles';
import SimpleDialog from '../components/SimpleDialog';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import { StoreAccessor } from '../util/StoreUtil';

const buttonStyle = {
    width: "120px",
    height: "60px",
    margin: "20px"
}
const styles = () => Styles.createStyles({
    root: {

    },
    textField: {
        width: "90%"
    },
    input: {
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    group: {
        margin: "10px",
        marginTop: "30px"
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    table: {
        width: "80%",
        margin: "auto",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px",
        margin: "10px"
    },
    deleteButton: {
        ...buttonStyle,
    },
});

interface Params {
    org_id: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    onBack?: () => void
    orgId?: number
}

interface LocalState {
    org: Model.Organization | null
    loading: boolean,
    dialogTarget: Model.OrganizationTanto | null,
    targetIdx: number,
    openDelete: boolean
}

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

// @inject()
// @observer
class OrganizationPage extends React.Component<Props, LocalState> {
    displayName = "OrganizationPage"
    public defaultProps = {
        onBack: null,
        orgId: null
    }
    constructor(props: Props) {
        super(props)
        this.state = {
            org: null,
            loading: false,
            dialogTarget: null,
            targetIdx: -1,
            openDelete: false
        }
    }

    onBack() {
        if (this.props.onBack) {
            this.props.onBack()
        }
        else {
            this.props.history.push("/manage/organizations")
        }
    }

    onCommit() {
        this.setState({ loading: true })
        const id = this.orgId
        const createMode = id == 0
        if (!this.state.org) return
        if (createMode) {
            HokanApi.organization.post(this.state.org)
                .then(x => {
                    this.setState({ loading: false })
                    this.onBack();
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        } else {
            HokanApi.organization.put(this.state.org)
                .then(x => {
                    this.setState({ loading: false })
                    this.onBack();
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
    }

    private get orgId() {
        if (this.props.orgId != null) return this.props.orgId
        return parseInt(this.props.match.params.org_id)
    }

    private get createMode() {
        return this.orgId == 0
    }


    public componentDidMount = () => {
        this.setState({ loading: true })
        if (this.createMode) {
            this.setState({ org: new Model.Organization(), loading: false })
        }
        else {
            HokanApi.organization.get(this.orgId)
                .then(x => {
                    this.setState({ org: x.data, loading: false })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ org: null, loading: false })
                })
        }
    }

    private setText(target, str) {
        const org = this.state.org as any
        org[target] = str;
        this.setState({ org: org })
    }
    private setDialogText(target, str) {
        const tanto = this.state.dialogTarget as any
        tanto[target] = str;
        this.setState({ dialogTarget: tanto })
    }
    private dialogClose() {
        this.setState({ dialogTarget: null })
    }
    private dialogDelete() {
        const t = this.state.org
        if (!t) return
        t.organization_tantos.splice(this.state.targetIdx, 1)
        this.setState({ org: t })
        this.dialogClose()
    }
    private dialogSave() {
        const org = this.state.org
        const target = this.state.dialogTarget
        if (!org || !target) return
        if (this.state.targetIdx < 0) {
            org.organization_tantos.push(target)
        }
        else {
            const t = org.organization_tantos[this.state.targetIdx]
            t.name = target.name
            t.kana = target.kana
            t.email = target.email
            t.role = target.role
            t.tel = target.tel
            t.fax = target.fax
            t.kinkyu_tel1 = target.kinkyu_tel1
            t.kinkyu_tel2 = target.kinkyu_tel2
            t.kinkyu_tel1_comment = target.kinkyu_tel1_comment
            t.kinkyu_tel2_comment = target.kinkyu_tel2_comment
        }
        this.setState({ org: org })
        this.dialogClose()
    }

    private openDeleteConfirm() {
        this.setState({ openDelete: true })
    }

    private closeDeleteConfirm() {
        this.setState({ openDelete: false })
    }

    private commitDelete() {
        this.setState({ openDelete: false })
        HokanApi.organization.delete(this.orgId)
            .then(x => {
                this.setState({ loading: false })
                this.onBack();
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    private clickTanto(idx: number) {
        if (this.state.org) {
            if (idx >= 0) {
                this.setState({
                    targetIdx: idx,
                    dialogTarget: { ...this.state.org.organization_tantos[idx] }
                })
            }
            else {
                this.setState({
                    targetIdx: idx,
                    dialogTarget: new Model.OrganizationTanto()
                })
            }
        }
    }

    public render() {
        const { classes } = this.props
        const { org } = this.state
        const target = this.state.dialogTarget
        return <div className={classes.root}>
            <div className={classes.group}>
                <SubTitle title={this.createMode ? "機関情報登録" : "機関情報マスタ"} />
                <Grid container spacing={1} className={classes.grid}>
                    <InputText title="機関名" value={org ? org.name : ""} onChange={(s) => this.setText("name", s)} />
                    <InputText title="ヨミガナ" value={org ? org.kana : ""} onChange={(s) => this.setText("kana", s)} />
                    <InputText title="郵便番号" value={org ? org.zip : ""} onChange={(s) => this.setText("zip", s)} />
                    <InputText title="住所" value={org ? org.address : ""} onChange={(s) => this.setText("address", s)} />
                    <InputText title="メール" value={org ? org.email : ""} onChange={(s) => this.setText("email", s)} />
                    <InputText title="電話番号" value={org ? org.tel : ""} onChange={(s) => this.setText("tel", s)} />
                    <InputText title="FAX番号" value={org ? org.fax : ""} onChange={(s) => this.setText("fax", s)} />
                    <InputText title="緊急TEL(1)" value={org ? org.kinkyu_tel1 : ""} onChange={(s) => this.setText("kinkyu_tel1", s)} />
                    <InputText title="緊急TEL(1)コメント" value={org ? org.kinkyu_tel1_comment : ""} onChange={(s) => this.setText("kinkyu_tel1_comment", s)} />
                    <InputText title="緊急TEL(2)" value={org ? org.kinkyu_tel2 : ""} onChange={(s) => this.setText("kinkyu_tel2", s)} />
                    <InputText title="緊急TEL(2)コメント" value={org ? org.kinkyu_tel2_comment : ""} onChange={(s) => this.setText("kinkyu_tel2_comment", s)} />
                </Grid>
            </div>
            <div className={classes.group}>
                <SubTitle title={"担当者一覧"} />
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>名前</CustomTableCell>
                            <CustomTableCell align="center">役職</CustomTableCell>
                            <CustomTableCell align="center">メール</CustomTableCell>
                            <CustomTableCell align="center">電話番号</CustomTableCell>
                            <CustomTableCell align="center">FAX</CustomTableCell>
                            <CustomTableCell align="right"></CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            org && org.organization_tantos ? org.organization_tantos.map((tanto, ti) => {
                                return <TableRow key={ti} className={classes.row}>
                                    <CustomTableCell onClick={(e) => { this.clickTanto(ti) }} style={{ textAlign: "left", padding: "10px" }} component="th" scope="row">{tanto.name}</CustomTableCell>
                                    <CustomTableCell onClick={(e) => { this.clickTanto(ti) }} style={{ textAlign: "left" }} component="th" scope="row">{tanto.role}</CustomTableCell>
                                    <CustomTableCell onClick={(e) => { this.clickTanto(ti) }} style={{ textAlign: "left" }} component="th" scope="row">{tanto.email}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "left", cursor: "default" }} component="th" scope="row">{StoreAccessor.linkTel(tanto.tel)}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "left", cursor: "default" }} component="th" scope="row">{StoreAccessor.linkTel(tanto.fax)}</CustomTableCell>
                                    <CustomTableCell onClick={(e) => { this.clickTanto(ti) }} align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                </TableRow>
                            }) : null
                        }
                    </TableBody>
                </Table>
                <div className={classes.center}>
                    <Button onClick={(e) => this.clickTanto(-1)} color="default" className={classes.button}>
                        追加
                    </Button>
                </div>
                <SimpleDialog
                    title={this.state.targetIdx == -1 ? "担当者作成" : "担当者編集"}
                    open={this.state.dialogTarget != null}
                    onDelete={() => this.dialogDelete()}
                    onCancel={() => this.dialogClose()}
                    onOk={() => this.dialogSave()}
                    hideDelete={this.state.targetIdx == -1}
                >
                    <Grid container spacing={1} className={classes.grid}>
                        <InputText title="名前" value={target ? target.name : ""} onChange={(s) => this.setDialogText("name", s)} />
                        <InputText title="フリガナ" value={target ? target.kana: ""} onChange={(s) => this.setDialogText("kana", s)} />
                        <InputText title="役職" value={target ? target.role : ""} onChange={(s) => this.setDialogText("role", s)} />
                        <InputText title="メール" value={target ? target.email : ""} onChange={(s) => this.setDialogText("email", s)} />
                        <InputText title="携帯" value={target ? target.tel : ""} onChange={(s) => this.setDialogText("tel", s)} />
                        <InputText title="FAX" value={target ? target.fax : ""} onChange={(s) => this.setDialogText("fax", s)} />
                        <InputText title="緊急TEL(1)" value={target ? target.kinkyu_tel1 : ""} onChange={(s) => this.setDialogText("kinkyu_tel1", s)} />
                        <InputText title="緊急TEL(1)コメント" value={target ? target.kinkyu_tel1_comment : ""} onChange={(s) => this.setDialogText("kinkyu_tel1_comment", s)} />
                        <InputText title="緊急TEL(2)" value={target ? target.kinkyu_tel2 : ""} onChange={(s) => this.setDialogText("kinkyu_tel2", s)} />
                        <InputText title="緊急TEL(2)コメント" value={target ? target.kinkyu_tel2_comment : ""} onChange={(s) => this.setDialogText("kinkyu_tel2_comment", s)} />
                    </Grid>
                </SimpleDialog>

                <SimpleDialog
                    title="削除確認"
                    open={this.state.openDelete}
                    deleteText="削除"
                    hideOk={true}
                    hideDelete={false}
                    onCancel={() => this.closeDeleteConfirm()}
                    onDelete={() => this.commitDelete()}
                >
                    こちらの機関を削除してよろしいでしょうか？
                </SimpleDialog>

                <ButtonOkCancel
                    cancelLabel="戻る" okLabel={this.createMode ? "作成" : "更新"}
                    onCancel={() => this.onBack()}
                    onOk={() => this.onCommit()}
                    disabled={this.state.loading}
                    showBack={true} />
                {
                    this.createMode ? null :
                        <Button className={classes.deleteButton} variant="contained" color="secondary"
                            onClick={(e) => this.openDeleteConfirm()} disabled={this.state.loading}>
                            削除
                        </Button>
                }
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(OrganizationPage))
