import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 83;
  }

  public createContent(): any {
    const res = new FormModel.Kaigofutansyakudo.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    let total = 0;
    [0, 1, 2, 3, 4, 5, 6, 7].forEach((i) => {
      if (doc.content.checks[`check_${i * 5 + 4}`]) {
        total += 1;
      }
      if (doc.content.checks[`check_${i * 5 + 5}`]) {
        total += 2;
      }
      if (doc.content.checks[`check_${i * 5 + 6}`]) {
        total += 3;
      }
      if (doc.content.checks[`check_${i * 5 + 7}`]) {
        total += 4;
      }
    });

    doc.content.values.total_point = total;
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const questionList = [
      "患者さんの行動に対し、困ってしまうと思うことがありますか。",
      "患者さんのそばにいると腹が立つことはありますか。",
      "介護があるので家族や友人とつきあいづらくなっていると思いますか。",
      "患者さんのそばにいると、気が休まらないと思いますか。",
      "介護があるので自分の社会参加の機会が減ったと思いますか。",
      "患者さんが家にいるので、友達を自宅に呼びたくても呼べないと思ったことはありますか。",
      "介護を誰かにまかせてしまいたいと思ったことはありますか。",
      "患者さんに対して、どうしていいかわからないと思うことはありますか。",
    ];

    const elements_t = [
      {
        name: "基本情報",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              回答者氏名
              {saV.text("kaitousya_name")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              本人との続柄
              {saV.text("relational")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              記入者
              {saV.text("kinyuusya_name")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              独居・同居の有無
              {saC.radio([
                {
                  label: "独居",
                  key: `check_1`,
                },
                {
                  label: "同居",
                  key: `check_2`,
                },
              ])}
            </label>
          </div>
        ),
      },
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            {questionList.map((q, i) => {
              return (
                <fieldset>
                  <legend>{q}</legend>
                  <label
                    style={{
                      marginBottom: 10,
                      display: "block",
                    }}
                  >
                    {saC.radio([
                      {
                        label: "0点 思わない",
                        key: `check_${i * 5 + 3}`,
                      },
                      {
                        label: "1点 たまに思う",
                        key: `check_${i * 5 + 4}`,
                      },
                      {
                        label: "2点 時々思う",
                        key: `check_${i * 5 + 5}`,
                      },
                      {
                        label: "3点 よく思う",
                        key: `check_${i * 5 + 6}`,
                      },
                      {
                        label: "4点 いつも思う",
                        key: `check_${i * 5 + 7}`,
                      },
                    ])}
                  </label>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    備考
                    <div style={{ flex: 1 }}>
                      {saV.text(`comment_${i + 1}`)}
                    </div>
                  </label>
                </fieldset>
              );
            })}
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
