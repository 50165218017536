import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Colors from './Colors'
import * as Model from '../models'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, TextField, Select, MenuItem } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import moment from 'moment';
import { DateUtil } from '../util/DateUtil'

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxHeight: "90%",
        maxWidth: "90%"
    },
    title: {
        backgroundColor: Colors.primaryColor,
        color: Colors.backColor
    },
    dialogPaper: {
        margin: "0px",
        height: "90%",
        width: "90%",
        maxHeight: "95%",
        overflowY: "hidden",
        maxWidth: "95%"
    },
    nooverflow: {
        overflowY: "hidden"
    },
    canvasDiv: {
        margin: "0px",
        height: "100%",
        width: "100%",
        overflowY: "hidden"
    },
    canvas: {
        border: "1px solid",
        width: "100%",
        height: "100%",
        webkitTouchCallout: "none",
        webkitUserSelect: "none",
        userSelect: "none"
    },
    input: {
        color: Colors.inputColor,
        padding: "5px",
        "& input": {
            padding: "5px"
        }
    },
    textField: {
    }
})

interface Props extends Styles.WithStyles {
    open: boolean
    onOk: (time: string, url: string) => void
    onDisagree: () => void
    onCancel: () => void
    patient: Model.Patient.Patient
    timeStr: string | null
}

interface LocalStore {
    disableButtons: boolean
    check: boolean
    drawing: boolean
    signMode: boolean
    cw: number
    ch: number
    tuduki: string
    tuduki_other: string
    tuduki_content_address: string
    disagree: boolean
    sizeModified: boolean
    resign: boolean
}

class TermConfirmDialog extends React.Component<Props, LocalStore> {
    displayName = "TermConfirmDialog"
    signImage = new Image();
    signImageRef = React.createRef<HTMLCanvasElement>();
    signRef = React.createRef<HTMLDivElement>();
    prevPos: { x: number, y: number } = { x: 0, y: 0 }
    public static defaultProps = {
    }
    constructor(props) {
        super(props)
        let add = this.props.patient.terms_content ?
            (this.props.patient.terms_content.address || "")
            : ""
        if (this.props.timeStr == null && this.props.patient.terms_disagree == false) {
            add = this.props.patient.env.address1 +
                this.props.patient.env.address2 +
                this.props.patient.env.address3
        }
        this.state = {
            disableButtons: this.props.patient.terms_disagree,
            check: props.timeStr != null,
            drawing: false,
            signMode: false,
            cw: 1200,
            ch: 1250,
            tuduki: "本人",
            tuduki_other: "",
            disagree: this.props.patient.terms_disagree,
            tuduki_content_address: add,
            sizeModified: false,
            resign: false
        }
    }


    private toBinary = (canvas) => {
        let base64 = canvas.toDataURL('image/png'),
            bin = atob(base64.replace(/^.*,/, '')),
            buffer = new Uint8Array(bin.length);
        for (var i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
        }
        return buffer;
    }
    public beforePost_pdf(doc: Model.Document.Document) {
        if (this.signImageRef.current) {
            let blob = new Blob([this.toBinary(this.signImageRef.current)], { type: 'image/png' })
            doc.images = [new File([blob], "sign.png")]
        }
    }

    private signDone() {
        this.setState({ disableButtons: true })
        if (this.signImageRef.current) {
            let blob = new Blob([this.toBinary(this.signImageRef.current)], { type: 'image/png' })
            const image = new File([blob], "sign.png")
            const content = {
                address: this.state.tuduki_content_address
            }
            HokanApi.setTermConfirm(this.props.patient.id, false, image, content).then(x => {
                this.setState({ disableButtons: false, signMode: false, resign: false })
                this.props.onOk(moment(x.data.time).format("YYYY-MM-DDTHH:mm"), x.data.url)
            }).catch(err => {
                this.setState({ disableButtons: false, signMode: false })
                console.log(err)
            })
        }
    }

    public commitDisagree() {
        this.setState({ disableButtons: true })
        HokanApi.setTermConfirm(this.props.patient.id, true, null).then(x => {
            this.setState({ disableButtons: false, resign: false })
            this.props.onDisagree()
        }).catch(err => {
            this.setState({ disableButtons: false })
            console.log(err)
        })
    }
    componentDidMount() {
    }

    public drawStart = (px: number, py: number) => {
        this.setState({ drawing: true })
        var container = this.signRef.current
        if (container) {
            const bb = container.getBoundingClientRect()
            px -= bb.x;
            py -= bb.y;
            const cw = bb.width
            const ch = bb.height
            if (this.state.sizeModified == false && (Math.abs(cw - this.state.cw) > 10 || Math.abs(ch - this.state.ch) > 10)) {
                this.setState({ cw: cw, ch: ch, sizeModified: true })
            }
        }
        this.prevPos = { x: px, y: py }
    }
    public clearCanvas() {
        if (!this.signImageRef.current) return
        const ctx = this.signImageRef.current.getContext('2d');
        if (!ctx) return
        ctx.clearRect(0, 0, this.signImageRef.current.width, this.signImageRef.current.height);
    }
    public addCircle = (px: number, py: number) => {
        var container = this.signRef.current
        if (container) {
            const bb = container.getBoundingClientRect()
            px -= bb.x;
            py -= bb.y;
        }
        if (!this.state.drawing) return;
        if (!this.signImageRef.current) return
        const ctx = this.signImageRef.current.getContext('2d');
        if (!ctx) return
        ctx.lineCap = 'round';
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 5;
        ctx.beginPath();
        ctx.moveTo(this.prevPos.x, this.prevPos.y)
        ctx.lineTo(px, py)
        ctx.stroke()
        ctx.closePath();
        this.prevPos = { x: px, y: py }
    }
    public openSign() {
        this.setState({ signMode: true })
        var container = this.signRef.current
        if (container) {
            const bb = container.getBoundingClientRect()
            const cw = bb.width
            const ch = bb.height
            if (Math.abs(cw - this.state.cw) > 10 || Math.abs(ch - this.state.ch) > 10) {
                this.setState({ cw: cw, ch: ch })
            }
        }
    }
    public render() {
        const p = this.props;
        const { classes } = this.props;
        const confirm_date = this.props.timeStr && !this.state.resign ?
            DateUtil.toJpDateString(this.props.timeStr)
            : DateUtil.toJpDateString(DateUtil.currentDateStr())
        const fixed = this.state.resign == false && (this.props.timeStr != null || this.props.patient.terms_disagree)
        return (
            <div className={classes.root} >
                {
                    this.state.signMode ?
                        <Dialog open={p.open} onClose={(e) => p.onCancel()} aria-labelledby={`dialog_term_confirm`}
                            PaperProps={{ className: classes.dialogPaper }}
                            fullWidth={true}
                        >
                            <DialogTitle id={`dialog_term_confirm`} className={classes.title}>
                                {`${this.props.patient.name} ${this.state.tuduki != "本人" ? "(" + this.state.tuduki_other + ")" : ""} 様 サイン`}
                            </DialogTitle>
                            <DialogContent style={{ overflowY: 'hidden' }}>
                                <div ref={this.signRef} className={classes.canvasDiv}>
                                    <canvas
                                        className={classes.canvas}
                                        ref={this.signImageRef}
                                        width={this.state.cw}
                                        height={this.state.ch}
                                        onMouseDown={e => { this.drawStart(e.nativeEvent.clientX, e.nativeEvent.clientY) }}
                                        onTouchStart={e => { console.log(e.touches); if (e.touches.length > 0) this.drawStart(e.touches[0].clientX, e.touches[0].clientY) }}
                                        onMouseMove={e => { this.addCircle(e.nativeEvent.clientX, e.nativeEvent.clientY); }}
                                        onTouchMove={e => { if (e.touches.length > 0) this.addCircle(e.touches[0].clientX, e.touches[0].clientY) }}
                                        onMouseUp={e => this.setState({ drawing: false })}
                                        onTouchEnd={e => this.setState({ drawing: false })}
                                        onMouseLeave={e => this.setState({ drawing: false })}
                                        style={{ border: "solid 1px", height: this.state.ch, width: this.state.cw }}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div style={{ width: "100%" }}>
                                    <div style={{ float: "left", textAlign: "left" }}>
                                        <Button onClick={(e) => this.setState({ drawing: false, signMode: false })} variant="contained" color="default"
                                            disabled={this.state.disableButtons} >
                                            戻る
                                        </Button>
                                    </div>
                                    <div style={{ float: "right" }}>
                                        <Button
                                            style={{ marginLeft: "10px", marginRight: "10px" }}
                                            onClick={(e) => this.clearCanvas()} variant="contained" color="default">
                                            リセット
                                        </Button>
                                        {"　"}
                                        <Button onClick={(e) => this.signDone()} variant="contained" color="primary">
                                            完了
                                        </Button>
                                    </div>
                                </div>
                            </DialogActions>
                        </Dialog>
                        :
                        <Dialog open={p.open} onClose={(e) => p.onCancel()} aria-labelledby={`dialog_term_confirm`}
                            PaperProps={{ className: classes.dialogPaper }}
                        >
                            <DialogTitle id={`dialog_term_confirm`} className={classes.title}>
                                <span style={{ fontSize: "0.9rem" }}>
                                    {`個人情報の第三者提供に関する同意書`}
                                </span>
                            </DialogTitle>
                            <DialogContent>
                                <div>
                                    {this.props.patient.company.name}　御中<br />
                                    <br />
                                    私は、貴社が提供する訪問看護サービス（以下「訪問看護」といいます。）を利用するにあたり、
                                    貴社が採用している訪問看護記録システム「homis Nursee」（以下「homis Nursee」といいます。）
                                    に登録されるサービス利用者に関する下記の情報について、homis Nurseeのサービス開発・品質向上、
                                    及び医療の発展に資する研究を目的として、homis Nurseeの開発・運営会社である
                                    メディカルインフォマティクス株式会社（第三者）に提供することに同意いたします。<br />
                                    <br />
                                    [記]<br />
                                    <br />
                                    ① 氏名・住所連絡先を除く疾患歴、現病歴、家族に関する情報、
                                    その他homis Nurseeに登録されるサービス利用者の健康状態に関する全ての情報（訪問看護の利用開始後に変更があった場合には、変更後の情報を含む。）<br/>
                                    ② 血液等の検査データ、バイタルサイン、看護師の記録、その他訪問看護サービスの提供にあたり貴社が取得するサービス利用者の健康状態やケアに関する一切の情報
                                    （訪問看護の利用開始後、本同意書を提出するまでの間に貴社が取得した情報を含む。）<br />
                                    <br />
                                    <b>３．提供する方法</b><br />
                                    homis Nursee への入力<br /><br />
                                    <br/>
                                    [図](クリックで別タブで開きます) <br />
                                    <a href="/images/doui_pic_3.png" target="_blank">
                                    <img src="/images/doui_pic_3.png" style={{ width: "100%" }} />
                                    </a><br/>
                                    <br />
                                    <a href="https://www.hlm.tokyo/privacypolicy" target="_blank">
                                        プライバシーポリシー詳細はこちら
                                    </a><br />
                                    <br />
                                    以上<br />
                                </div>
                                {
                                    this.props.timeStr && this.props.patient.terms_sign_url ?
                                        <div>
                                            同意サイン：
                                            <div>
                                                <a href={this.props.patient.terms_sign_url} target="_blank">
                                                    <img width="50%" src={this.props.patient.terms_sign_url} />
                                                </a>
                                            </div>
                                        </div>
                                        : null
                                }
                            </DialogContent>
                            <DialogActions style={{ display: "block" }}>
                                <div style={{ display: "block" }}>
                                    <div>
                                        同意者：
                                        <Select
                                            style={{ marginRight: "10px" }}
                                            value={this.state.tuduki}
                                            disabled={fixed}
                                            onChange={(e) => this.setState({ tuduki: (e.target as any).value })}
                                        >
                                            <MenuItem value="本人">{this.props.patient.name}(本人)</MenuItem>
                                            <MenuItem value="本人以外">本人以外</MenuItem>
                                        </Select>
                                        {
                                            this.state.tuduki == "本人以外"
                                                ?
                                                <TextField
                                                    id="outlined-number"
                                                    label="続柄"
                                                    disabled={fixed}
                                                    value={this.state.tuduki_other}
                                                    onChange={(e) => this.setState({ tuduki_other: e.target.value })}
                                                    className={classes.textField}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        className: classes.input,
                                                    }}
                                                    multiline={false}
                                                    margin="none"
                                                    variant="outlined"
                                                />
                                                : null
                                        }
                                    </div>
                                    <div>
                                        <TextField
                                            id="outlined-number"
                                            label="住所"
                                            disabled={fixed}
                                            value={this.state.tuduki_content_address}
                                            onChange={(e) => this.setState({ tuduki_content_address: e.target.value })}
                                            className={classes.textField}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                className: classes.input,
                                            }}
                                            fullWidth={true}
                                            multiline={false}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    </div>
                                    <FormControlLabel
                                        label={`${confirm_date} 同意します`}
                                        control={<Checkbox
                                            checked={this.state.check}
                                            onChange={(e) => this.setState({
                                                check: !this.state.check,
                                                disagree: false
                                            })}
                                            color="primary"
                                            disabled={fixed || this.state.disableButtons}
                                        />}
                                    />

                                    <FormControlLabel
                                        label={`拒否します`}
                                        control={<Checkbox
                                            checked={this.state.disagree}
                                            onChange={(e) => this.setState({
                                                disagree: !this.state.disagree,
                                                check: false
                                            })}
                                            color="primary"
                                            disabled={fixed || this.state.disableButtons}
                                        />}
                                    />
                                    {
                                        fixed && this.state.check && !this.state.resign ?
                                            <Button onClick={(e) => this.setState({
                                                disagree: true,
                                                check: false,
                                                resign: true,
                                                disableButtons: false
                                            })}
                                                variant="contained" color="default" >
                                                拒否に変更
                                            </Button>
                                            : null
                                    }
                                    {
                                        this.props.patient.terms_disagree && !this.state.resign ?
                                            <Button onClick={(e) => this.setState({
                                                disagree: false,
                                                check: true,
                                                resign: true,
                                                disableButtons: false
                                            })} variant="contained" color="default" >
                                                同意に変更
                                            </Button>
                                            : null
                                    }
                                </div>

                                <div>
                                    {
                                        fixed ?
                                            <div>
                                                <Button onClick={(e) => p.onCancel()} variant="contained" color="default"
                                                    disabled={this.state.disableButtons} >
                                                    閉じる
                                                </Button>
                                            </div> :
                                            [
                                                <div style={{ display: "flex", float: "left" }}>
                                                    <Button onClick={(e) => p.onCancel()} variant="contained" color="default"
                                                        disabled={this.state.disableButtons} >
                                                        後で行う
                                                    </Button>
                                                </div>,
                                                <div style={{ float: "right" }}>
                                                    {
                                                        this.state.disagree ?
                                                            <Button onClick={(e) => this.commitDisagree()} variant="contained" color="primary"
                                                                disabled={this.state.disableButtons} >
                                                                確定
                                                            </Button>
                                                            :
                                                            <Button onClick={(e) => this.openSign()} variant="contained" color="primary"
                                                                disabled={this.state.disableButtons || !this.state.check} >
                                                                同意サインをもらう
                                                            </Button>
                                                    }
                                                </div>
                                            ]
                                    }
                                </div>
                            </DialogActions>
                        </Dialog>
                }
            </div>
        );
    }
}

export default Styles.withStyles(styles)(TermConfirmDialog)