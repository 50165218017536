import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        height: "220px",
        textAlign: "center",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
//        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    },
    title: {
    },
    value: {
        fontSize: "70px"
    },
    unit: {
        fontSize: "30px"
    },
    elm: {        
        marginTop: "30px"
    }
});

interface Props extends Styles.WithStyles {
    title: string,
    value: string,
    unit: string
}

class Text extends React.PureComponent<Props> { 
	displayName="PiChart"
    public render() {
        const { classes } = this.props;
        return <div className={classes.root}>
            <div className={classes.elm}>
                <div className={classes.title}>
                    {this.props.title}
                </div>
                <div className={classes.value}>
                    {this.props.value}
                </div>
                <div className={classes.unit}>
                    {this.props.unit}
                </div>
            </div>
        </div>
    }
}

export default Styles.withStyles(styles)(Text)