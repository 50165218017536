import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import * as HokanApi from '../../api/hokan'
import { Select, MenuItem } from '@material-ui/core';
import * as Graph from '../graph'
import { GraphicEqSharp } from '@material-ui/icons';
class TempData {
    docs: Model.Document.Document[] = []
    bui_list: string[] = []
    currentImages: File[] = []
}
// @inject()
// @observer
export default class DesignRBehaviour extends FormBehaviour.PdfBehaviour {
    displayName = "DesignRBehaviour"

    public getId(): number {
        return 61
    }

    public createContent(): any {
        const res = new FormModel.DesignR.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>・DESIGN-R 2020：日本褥瘡学会（2020）</div>
        </div>
    }

    private setDesignRValues(src: FormModel.DesignR.Core, srcDoc: Model.Document.Document, dst: FormModel.DesignR.Core, idx: number, isCurrent:boolean) {
        dst.values[`d_${idx}`] = Model.KirokuII.SkinJokuso.toCode(src.values.d)
        dst.values[`e_${idx}`] = Model.KirokuII.SkinJokuso.toCode(src.values.e)
        dst.values[`s_${idx}`] = Model.KirokuII.SkinJokuso.toCode(src.values.s)
        dst.values[`i_${idx}`] = Model.KirokuII.SkinJokuso.toCode(src.values.i)
        dst.values[`g_${idx}`] = Model.KirokuII.SkinJokuso.toCode(src.values.g)
        dst.values[`n_${idx}`] = Model.KirokuII.SkinJokuso.toCode(src.values.n)
        dst.values[`p_${idx}`] = Model.KirokuII.SkinJokuso.toCode(src.values.p)
        dst.values[`sum_${idx}`] = src.values.sum_score
        dst.values[`date_${idx}`] = src.values.create_date
        dst.values[`date_md_${idx}`] = DateUtil.toSlashDateStringMD(src.values.create_date)
        if (isCurrent) {
            dst.values[`image_id_${(idx - 1) * 2 + 0}`] = (srcDoc.image_ids.length > 0 ? srcDoc.image_ids[0] : "img1")
            dst.values[`image_id_${(idx - 1) * 2 + 1}`] = (srcDoc.image_ids.length > 1 ? srcDoc.image_ids[1] : "img2")
        }
        else {
            dst.values[`image_id_${(idx - 1) * 2 + 0}`] = (srcDoc.image_ids.length > 0 ? srcDoc.image_ids[0] : "")
            dst.values[`image_id_${(idx - 1) * 2 + 1}`] = (srcDoc.image_ids.length > 1 ? srcDoc.image_ids[1] : "")
        }
    }
    private clearDesignRValues(dst: FormModel.DesignR.Core, idx: number) {
        dst.values[`d_${idx}`] = ""
        dst.values[`e_${idx}`] = ""
        dst.values[`s_${idx}`] = ""
        dst.values[`i_${idx}`] = ""
        dst.values[`g_${idx}`] = ""
        dst.values[`n_${idx}`] = ""
        dst.values[`p_${idx}`] = ""
        dst.values[`sum_${idx}`] = ""
        dst.values[`date_${idx}`] = ""
        dst.values[`date_md_${idx}`] = ""
        dst.values[`image_id_${(idx - 1) * 2 + 0}`] = ""
        dst.values[`image_id_${(idx - 1) * 2 + 1}`] = ""
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.DesignR.Core
        content.values.sum_score = `${Model.KirokuII.SkinJokuso.calcScore(content.values as any)}`
        content.values.page_count = 2
        let idx = 1;
        for (let i=1; i <= 6; ++i) {
            this.clearDesignRValues(content, i)
        }
        if (doc.temp_data && doc.temp_data.docs) {
            doc.temp_data.docs.forEach((x: Model.Document.Document, i:number) => {
                const c = x.content as FormModel.DesignR.Core
                this.setDesignRValues(c, x, content, idx++, false);
            })
            if (doc.temp_data.docs.length > 2) {
                content.values.page_count = 3
            }
        }
        this.setDesignRValues(content, doc, content, idx++, true);
    }

    public afterPost(doc: Model.Document.Document) {
    }
    public createSucceed(doc: Model.Document.Document, reload: any): void {
        if (reload) reload();
    }
    public beforeMount = (doc: Model.Document.Document, sa: StoreAccessor) => {
        doc.temp_data = new TempData()
        this.loadDocuments(doc, sa)
    }

    private loadDocuments(doc: Model.Document.Document, sa: StoreAccessor) {
        const pat = doc.patient
        if (!pat) return;

        doc.cannotCommit = true;
        sa.updateState();
        HokanApi.getDesignRBuiList(pat.id).then(res => {
            doc.temp_data.bui_list = res.data.filter(x=>x.bui != "").map(x => x.bui)
            sa.updateState();
        })

        HokanApi.getDocuments(0, 5, {
            patientId: pat.id,
            typeId: this.getId(),
            bui: doc.content.values.bui,
            time_lim: doc.content.values.create_date
        }).then(res => {
            res.data.documents.forEach(x => {
                Model.Document.Document.Init(x)
            })
            doc.temp_data.docs = res.data.documents.reverse()
            doc.cannotCommit = false;
            sa.updateState();
        })
            .catch(e => {
                doc.cannotCommit = false;
                sa.updateState();
            console.log(e);
        })
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        doc.patient = pat;
        const c = doc.content as FormModel.DesignR.Core
        if (pat) {
            this.loadDocuments(doc, sa)
        }
    }

    public setCreateDate(doc: Model.Document.Document, create_date: string, sa: StoreAccessor) {
        const c = doc.content as FormModel.DesignR.Core
        c.values.create_date = create_date
        this.loadDocuments(doc, sa)
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const comStyle = { fontSize: "smaller", margin: "5px" }
        saV.callBack = (cat, name, v) => {
            if (name == "bui") {
                this.loadDocuments(doc, sa)
            }
        }

        const elements_scores = [
            { name: "部位", content: <div>
                <Select
                    value={"候補選択"}
                    onChange={(e) => {
                        const s = e.target.value as string
                        if (s != "") {
                            doc.content.values.bui = s
                        }
                        this.loadDocuments(doc, sa);
                    }}
                >
                    <MenuItem value={"候補選択"}>候補選択</MenuItem>
                    <MenuItem value={"仙骨部"}>仙骨部</MenuItem>
                    <MenuItem value={"坐骨部"}>坐骨部</MenuItem>
                    <MenuItem value={"大転子部"}>大転子部</MenuItem>
                    <MenuItem value={"踵骨部"}>踵骨部</MenuItem>
                    {
                        doc.temp_data && doc.temp_data.bui_list ? 
                        doc.temp_data.bui_list.map((x, i) => {
                            return <MenuItem key={x} value={x}>{x}</MenuItem>
                        })
                        : null
                    }
                </Select>
                {saV.text("bui")}
            </div> },
            {
                name: "深さ※1", content: <div>
                    {saV.list("d", Model.KirokuII.DesignR_D)}
                    (創内の一番深い部分で評価し、改善に伴い創底が浅くなった場合、これと相応の深さとして評価する)
                </div>
            },
            {
                name: "滲出液", content: <div>
                    {saV.list("e", Model.KirokuII.DesignR_E)}
                </div>
            },
            { name: "大きさ※4", content: <div>
                {saV.list("s", Model.KirokuII.DesignR_S)}
                （皮膚損傷範囲を測定：[長径(cm)✕短径※3(cm)]※4）<br/>
            </div> },
            { name: "炎症/感染", content: saV.list("i", Model.KirokuII.DesignR_I) },
            { name: "肉芽組織", content: saV.list("g", Model.KirokuII.DesignR_G) },
            {
                name: "壊死組織", content: <div>
                    {saV.list("n", Model.KirokuII.DesignR_N)}
                    （混在している場合は全体的に多い病態をもって評価する）<br />
                </div>
            },
            {
                name: "ポケット", content: <div>
                    {saV.list("p", Model.KirokuII.DesignR_P)}
                    （毎回同じ体位で、ポケット全周（潰瘍面も含め）<br />
                    　[長径(cm)✕短径※3(cm)]から潰瘍の大きさを差し引いたもの）<br />
                </div>
            },
            { name: "合計点※1", content: <div>{Model.KirokuII.SkinJokuso.calcScore(doc.content.values)}</div> },
            { name: "備考", content: saV.text("comment") },
            {
                name: "　", content:
                    <div style={comStyle}>
                        ※１：深さ（Depth：d/D）の点数は合計には加えない<br/>
                        ※２：深部損傷褥瘡（DTI）の疑いは、視診・触診、補助データ（発生経緯、血液検査、画像診断等）から判断する<br />
                        ※３："短径"とは"長径と直交する最大径"である<br />
                        ※４：持続する発赤の場合も皮膚損傷に準じて評価する<br />
                        ※５：「３C」あるいは「３」のいずれかを記載する。いずれの場合も点数は３点とする<br />
                </div>
            },
        ]
        const elements_images = [
            {
                name: "画像(最大２枚)", content: sa.docImage(2)
            }
        ]

        const elements_log_table = [
            {
                name: "",
                content: <Graph.DesignRTable docs={doc.temp_data.docs} />
            }
        ]

        const elements = [
            { name: '評価', content: elements_scores },
            { name: '画像', content: elements_images },
            { name: 'これまでの記録', content: elements_log_table }
        ]

        return elements
    }
}
