import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import Col2Table from '../components/Col2Table';
import StandardTable from '../components/StandardTable';
import { Button, Grid } from '@material-ui/core';
import { StoreAccessor } from '../util/StoreUtil';
import * as Model from '../models'


const styles = () => Styles.createStyles({
    innerTable: {
        padding: '5px'
    },
})

interface Props extends Styles.WithStyles<typeof styles> {
    isKettei: boolean
    accessor: StoreAccessor
}

class ShinkiIraiCheck extends React.Component<Props> { 
	displayName="ShinkiIraiCheck"
    public render() {
        const { classes } = this.props;
        const accessor = this.props.accessor

        const saU = accessor.generateChild(["ukeire_check"])
        const saR = accessor
        const sa = saR
        
        const elements_shinki = [
            { name: "事業所", content: saR.branch("branch", false) },
            {
                name: "紹介元", content: sa.orgTantoC(["basic"], "shoukai_from")
            },
            { name: "名前", content: saR.text("name") },
            { name: "ヨミガナ", content: saR.text("name_kana") },
            { name: "性別", content: saR.listC(["value"], "sexuality", Model.Patient.SexualityEnum) },
            { name: "年齢", content: saU.text("age") },
            {
                name: "電話", content: <div className={classes.innerTable} >
                    <Col2Table disableBGColor={true} body={[
                        { name: "自宅", content: saR.text("tel_home") },
                        { name: "携帯", content: saR.text("tel_cell") },
                    ]} />
                </div>
            },
            { name: "家族・キーパーソン", content: saR.famiryC(["social"], "famiry") },
            {
                name: "住所", content: <div>
                    {saR.textC(["env"], "address1", "都道府県")}
                    {saR.textC(["env"], "address2", "市区町村")}
                    {saR.textC(["env"], "address3", "それ以降")}</div>
            },
            { name: "疾患分類", content: sa.listC(["basic"], "shikkan_type", Model.Patient.ShikkanType) },
            { name: "看護の理由", content: saR.textC(["social"], "keii") },
            { name: "他のサービスの利用状況", content: saU.text("other_service") },
            { name: "希望の訪問看護の頻度", content: saU.text("kibou_hindo") },
            { name: "訪問者の希望", content: saU.text("kibou_houmonsha") },
            { name: "基本となる保険選択", content: saR.listC(["hoken"], "hoken_type", Model.Patient.HokenType) },
            { name: "公費受給者証チェック", content: saR.checkC(["hoken"], "kouhi_check") },
                { name: "指示書の医師", content: sa.orgTantoC(["contact"], "shujii")  },
                { name: "医師２", content: sa.orgTantoC(["contact"], "shujii_2") },
                //{name: "緊急時の医師連絡先", content: ()=>sa.orgTantoC(["contact"], "kinkyu", "openTantoDialog") },
                { name: "ケアマネ", content: sa.orgTantoC(["contact"], "keamane") },
                { name: "計画相談員", content: sa.orgTantoC(["contact"], "soudan") },
                {
                    name: "その他連絡先", content: <div>
                        {
                            sa.getValueC("other", ["contact"]).filter(x => x).map((o, i) => {
                                return <div key={`patec_${i}`}>
                                    {i > 0 ? <hr /> : null}
                                    {sa.orgTantoC(["contact", "other"], i)}
                                </div>
                            })
                        }
                        {
                            sa.isEditing ?
                                <Button variant="outlined" size="small" color="primary"
                                    onClick={(e) => {
                                        const t: Model.OrganizationTanto[] = sa.getValueC("other", ["contact"]).filter(x => x)
                                        t.push(new Model.OrganizationTanto());
                                        sa.setValueC("other", t, ["contact"])
                                    }}>
                                    ＋追加
                            </Button>
                                : null
                        }
                    </div>
                },
            { name: "次のアクション", content: saU.text("next_action") },
        ]


//        if (this.props.isKettei) {
//            elements.push({ name: '受入れ決定者チェック', content: elements_check })
//            elements.push({ name: '初回訪問チェック', content: shokai_check })
//        }

        return <div>
            <StandardTable title='新規受け入れ時情報'>
                <Grid container={true} spacing={0}>
                    <Grid item={true} xs={12} md={12} lg={12}>
                        <Col2Table body={elements_shinki} />
                    </Grid>
                </Grid>
            </StandardTable>
        </div>
    }
}

export default Styles.withStyles(styles)(ShinkiIraiCheck)