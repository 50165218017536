import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 95;
  }

  public createContent(): any {
    const res = new FormModel.HomonKangoShuryoSummary.Core();
    res.values.create_date = DateUtil.currentDateStr();

    HokanApi.me()
      .then((x) => {
        if (x && x.data) {
          res.values.sakuseisya = x.data.name;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {}

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;

    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(
    doc: Model.Document.Document,
    sa: StoreAccessor,
    classes: any
  ) {
    const saV = sa.generateChild(["content", "values"]);

    enum ReasonType {
      None = "未選択",
      Value1 = "入院",
      Value2 = "入所",
      Value3 = "在宅死",
      Value4 = "軽快",
      Value5 = "転居",
      Value6 = "クレーム",
      Value7 = "移行（自）",
      Value8 = "移行（他）",
      Value9 = "その他",
    }

    const elements_t = [
      {
        name: "終了理由",
        content: (
          <div style={{ padding: 10 }}>
            <div style={{ marginBottom: 5 }}>
              {saV.list("shuryo_riyu", ReasonType)}
            </div>
            <label>
              <span style={{ marginBottom: 5 }}>自由記載</span>
              <div style={{}}>{saV.text("shuryo_riyu_text")}</div>
            </label>
          </div>
        ),
      },
      {
        name: "この事例を通して学んだこと",
        content: (
          <div style={{ padding: 10 }}>
            {saV.text("manandakoto", "", "text", "block", true, "80px")}
          </div>
        ),
      },
      {
        name: "今後に生かすこと",
        content: (
          <div style={{ padding: 10 }}>
            {saV.text("ikasukoto", "", "text", "block", true, "80px")}
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
