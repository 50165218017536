import * as Moment from 'moment';

export class DateUtil {
    public static toJpDateString(date: string | null) {
        if (date == null) return "-";
        var values = date.split('-');
        if (values.length < 3) return "-";
        if (values[2].length > 2) values[2] = values[2].split('T')[0];
        return `${values[0]}年${values[1]}月${values[2]}日${DateUtil.getDayJp(values[0], values[1], values[2])}`;
    }
    public static toJpMonthString(date: string | null) {
        if (date == null) return "-";
        var values = date.split('-');
        if (values.length < 2) return "-";
        return `${values[0]}年${values[1]}月`;
    }
    public static toJpDateTimeString(date: string | null) {
        if (date == null) return "-";
        var values = date.split('-');
        if (values.length < 3) return "-";
        if (values[2].length > 2) values[2] = values[2].split('T')[0];
        var times = date.split('T')
        var hh  =  "--"
        var mm = "--"
        if (times.length >= 2) {
            var tt = times[1].split(':')
            hh = tt.length > 0  ? tt[0] : "--"
            mm = tt.length > 1 ?  tt[1] : "--"
        }
        return `${values[0]}年${values[1]}月${values[2]}日${DateUtil.getDayJp(values[0], values[1], values[2])} ${hh}:${mm}`;
    }

    public static getDayJp(y, m, d) {
        const day = new Date(y, m - 1, d)
        if (!day) return ""
        return `(${["日", "月", "火", "水", "木", "金", "土"][day.getDay()]})`
    }

    public static isValidDate(date) {
        return Moment.default(date).isValid()
    }

    public static toSlashDateString(date: string) {
        if (date == null) return "";
        var values = date.split('-');
        if (values.length < 3) return "-";
        var t = values[2].split('T')
        return `${values[0]}/${values[1]}/${t[0]}`;
    }

    public static toSlashDateStringShort(date: string) {
        let dateStr = DateUtil.toSlashDateString(date)
        return dateStr.slice(2);
    }
    public static toSlashDateStringMD(date: string) {
        let dateStr = DateUtil.toSlashDateString(date)
        return dateStr.slice(5);
    }
    public static toTimeStr(date: string) {
        let values = date.split('T')
        if (values.length < 2) return ""
        const t = values[1].split(':')
        if (t.length < 2) return ""
        return `${t[0]}:${t[1]}`
    }
    public static toTargetMonthStr(date: string): string {
        if (date == null) return "";
        var values = date.split('-');
        if (values.length < 3) return "-";
        return `${values[0]}${values[1]}`;
    }
    public static toTargetMonthNum(date: string): number | null {
        if (date == null) return null;
        var values = date.split('-');
        if (values.length < 2) return null;
        return parseInt(values[0]) * 100 + parseInt(values[1]);
    }
    public static fromTargetMonthNum(target_month: number): string {
        let mm = (target_month % 100).toFixed()
        if (mm.length == 1) mm = '0'+mm
        return (target_month / 100).toFixed() + "-" + mm + "-01"
    }

    public static dateToString(date: Date){
        if (!date) return "";
        return Moment.default(date).format("YYYY-MM-DDTHH:mm")
    }

    public static getAge(date: string) {
        if (date == null) return "";
        var values = date.split('-');
        if (values.length < 3) return "";
        var birthday = new Date(parseInt(values[0]), parseInt(values[1]) - 1, parseInt(values[2]));
        var today = new Date();
        var thisYearBirthday =
            new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate());
        var age = today.getFullYear() - birthday.getFullYear();
        return (today < thisYearBirthday) ? age - 1 : age;
    }

    public static getAgeMonth(date: string) {
        if (date == null) return "";
        var values = date.split('-');
        if (values.length < 3) return "";
        var birthday = new Date(parseInt(values[0]), parseInt(values[1]) - 1, parseInt(values[2]));
        var today = new Date();
        var timeTillNow = today.getTime() - birthday.getTime();
        var daysTillNow = timeTillNow / (1000 * 3600 * 24);
        const DAYS_PER_MONTH = 365 / 12;
        var ageY = Math.floor(daysTillNow / 365);
        var ageM = Math.floor((daysTillNow - 365 * ageY) / DAYS_PER_MONTH);
        //var ageD = Math.floor((daysTillNow - 365 * ageY - DAYS_PER_MONTH * ageM));
        return ageY * 12 + ageM
        //var ageY = today.getFullYear() - birthday.getFullYear();
        //var ageM = today.getMonth() - birthday.getMonth()
        //return ageY*12 + ageM
    }

    public static toJpSexString(sex:string) {
        if (sex == null) return "";
        var low = sex.toLowerCase();
        if (low == "male") return "男";
        else if (low == "female") return "女";
        else return "その他";
    }

    public static currentDateStr(): string {
        return Moment.default().format("YYYY-MM-DD")
    }

    public static currentMonthFirstDateStr(): string {
        return DateUtil.monthFirstDateStr(Moment.default())
    }
    public static currentMonthLastDateStr(): string {
        return Moment.default().endOf("month").format("YYYY-MM-DD")
    }

    public static monthFirstDateStr(date: Moment.Moment): string {
        return date.format("YYYY-MM") + "-01"
    }
    public static monthLastDateStr(date: Moment.Moment): string {
        return date.endOf("month").format("YYYY-MM-DD")
    }

    public static currentMonthStr(): string {
        return Moment.default().format("YYYY-MM")
    }
    public static pastMonthStr(): string {
        return Moment.default().add('month', -1).format("YYYY-MM")
    }
    public static pastDateStr(month:number): string {
        return Moment.default().add('month', -1 * month).format("YYYY-MM-DD")
    }
    public static currentDateTimeStr(): string {
        return Moment.default().format("YYYY-MM-DDTHH:mm")
    }

    public static isOlderThanToday(date: string): boolean {
        return date > this.currentDateStr();
    }

    public static isOlderThan(date: string, dateTarget: string): boolean {
        return date > dateTarget;
    }

    public static genMonthRangeArray(startDateString: string, endDateString: string): string[] {
        const startDate = Moment.default(startDateString)
        const endDate = Moment.default(endDateString)

        const months: string[] = []

        let currentDate = startDate
        while(currentDate.isSameOrBefore(endDate, "month")) {
            months.push(currentDate.format("YYYY-MM"))
            currentDate.add(1, 'month');
        }

        return months
    }


    public static enumToArray(targetEnum: any): {key:string, value:string}[] {
        return Object.keys(targetEnum).filter((item) => {
            return isNaN(Number(item));
        }).map(x => {
            return {
                key: targetEnum[x],
                value: targetEnum[x]
            }
        });
    }

    public static toFileSizeString(the_byte: number) {
        if (!the_byte) return "";
        if (the_byte < 1024) return `${the_byte} B`
        if (the_byte < 1024 * 1024) return `${(the_byte / 1024).toFixed(1)} KB`
        if (the_byte < 1024 * 1024 * 1024) return `${(the_byte / (1024 * 1024)).toFixed(1)} MB`
        return `${(the_byte / (1024 * 1024 * 1024)).toFixed(1)} GB`
    }

    public static isBigFile(the_byte: number)
    {
        return (the_byte >= (1024 * 1024 * 100))
    }
}

export default DateUtil;
