import { action, observable } from 'mobx';
import { getCalendarPermission, setCalendarAccessToken } from '../config/googleCalendar/axios';
import { authorizedFetch } from '../config/auth_axios';
import { ApiUrl } from '../config/api';
import { AxiosRequestConfig } from 'axios';
import * as gapi from "googleapis"
import { GaxiosPromise } from 'gaxios';


export default class GoogleCalendarStore {
    @observable public hasPermission:boolean|"loading" = "loading";
    @observable public calendarList?:GoogleCalendarList = undefined
    @observable public calendarEvents?:GoogleCalendarEvents = undefined

    @action.bound public initialize(){
        this.checkIsAuth().then(x => this.hasPermission = x)
        this.getCalendarList()
    }

    //Googleアカウントにカレンダーのパーミッションを取りに行く
    @action.bound public getPermission() {
        getCalendarPermission()
    }

    //Redirectページで叩く。
    @action.bound public setAccessToken(codeFromGoogle:string){
        return setCalendarAccessToken({
            codeFromGoogle
        })
    }
    @action.bound public getCalendarList(){
        authorizedFetch.get(ApiUrl("/api/v1/google_calendar/calendars")).then(x => {
            return x.data as GoogleCalendarList
        }).then(x => this.calendarList = x)
    }

    @action.bound public getCalendarEvents(calendarId:string){
        const config = {
            "headers":{
                "CalendarID": calendarId
            }
        } as AxiosRequestConfig
        authorizedFetch.get(ApiUrl(`/api/v1/google_calendar/calendar`), config).then(x => {this.calendarEvents = x.data;console.log(x.data)})
    }

    private checkIsAuth(){
        return authorizedFetch.get(ApiUrl("/api/v1/google_calendar/is_auth")).then(x => {
            return x.data
        })
    }
}

//以下型定義は右URLを参照https://developers.google.com/calendar/v3/reference/calendarList
type GoogleCalendarList = Lists<typeof gapi.calendar_v3.Calendar.prototype.calendarList>
type GoogleCalendarEvents = Lists<typeof gapi.calendar_v3.Resource$Events.prototype>

type Lists<T> = T extends {
    list(params?: any): GaxiosPromise<infer I>;
    list(params: any, options: any): void;
    list(params: any, callback: any): void;
    list(callback: any): void;
} ? I : never