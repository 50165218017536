import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle';
import { Grid } from '@material-ui/core';
import InputText from '../components/InputText';
import ButtonOkCancel from '../components/ButtonOkCancel';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import BranchList from '../components/BranchList';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        textAlign: "center"
    },
    textField: {
        width: "90%"
    },
    input: {
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        textAlign: "right",
        margin: "auto"
    },
    branchList: {
        width: "80%",
        margin: "auto",
        padding: "50px"
    }
});

interface Params {
    id: string
    token: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    sending: boolean
    pass: string
    passConfirm: string
    error: string
}

// @inject()
// @observer
class PasswordResetPage extends React.Component<Props, LocalState> { 
    displayName ="PasswordResetPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            sending: false,
            pass: "",
            passConfirm: "",
            error: ""
        }
    }

    public componentDidMount = () => {
    }

    onCommit() {
        const params = new URLSearchParams(this.props.location.search);
        const uid = params.get("id")
        const token = params.get("token")
        if (!uid || !token) {
            this.setState({ sending: true, error: "パラメータが不正です。メールのリンクから開き直してください" })
            return;
        }
        this.setState({ sending: true, error: "" })
        HokanApi.changePassword(parseInt(uid), token, this.state.pass)
            .then(x => {
                this.setState({ sending: false })
                window.location.href = "/login"
            })
            .catch(err => {
                console.log(err)
                this.setState({ sending:false, error: "エラー：再度お試しください" })
            })
    }

    onBack() {
        this.props.history.goBack()
    }

    public render() {
        const { classes } = this.props
        const passLengthOk = this.state.pass.length >= 8
        const passConfirmOk = this.state.pass == this.state.passConfirm
        return <div className={classes.root}>
            <SubTitle title="パスワードの設定" />
            <Grid container spacing={2} className={classes.grid}>
                <InputText title="パスワード(大文字小文字数字を含む8文字以上)"
                inputType="password"
                 value={this.state.pass}
                 onChange={(s) => this.setState({pass: s})} />
                <InputText title="パスワードの確認"
                    inputType="password"
                    value={this.state.passConfirm}
                    onChange={(s) => this.setState({ passConfirm: s })} />
            </Grid>
            <br/>
            <div style={{fontSize:"small"}}>
                {
                    passLengthOk ?
                        passConfirmOk ?
                        null:
                        "パスワードが確認と一致しません"
                    :  "パスワードは大文字小文字数字を含む8文字以上必要です"
                }
            </div>
            <ButtonOkCancel
                showBack={false}
                cancelLabel="戻る" okLabel="確定"
                onCancel={()=>{}}
                onOk={()=>this.onCommit()}
                disabled={this.state.sending || !passLengthOk || !passConfirmOk}/>
            <div style={{color: "red"}}>
                {this.state.error}
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(PasswordResetPage))