import { Problem } from '.';
import { Symptom, Intervention } from './Problem';
import DateUtil from '../util/DateUtil';

export class AssessmentListResult {
    problem: Problem = new Problem();
    newest: AssessmentSet = new AssessmentSet();
    oldest: AssessmentSet = new AssessmentSet();;
}

export const NullAssessmentNextDay = "5000-01-01"

export class AssessmentIntervention {
    id: number = 0;
    assessmnet_id: number = 0;
    intervention_id: number = 0;
    comment: string = "";

    static toPost(src: AssessmentIntervention): PostIntervention {
        let res = new PostIntervention();
        res.id = src.intervention_id;
        res.comment = src.comment;
        return res;
    }

    static clone(src: AssessmentIntervention): AssessmentIntervention {
        let res = new AssessmentIntervention();
        res.id = src.id;
        res.assessmnet_id = src.assessmnet_id;
        res.intervention_id = src.intervention_id;
        res.comment = src.comment;
        return res;
    }
}

export class AssessmentSet {
    assessment: Assessment = new Assessment();
    symptom: Symptom[] = [];
    free_symptoms: string[] = [];
    intervention: Intervention[] = [];
    assessment_intervention: AssessmentIntervention[] = [];

    public static clone(ass: AssessmentSet): AssessmentSet {
        let res = new AssessmentSet();
        res.assessment = new Assessment();
        Assessment.fromJSON(res.assessment, ass.assessment)
        res.symptom = ass.symptom.map(x => Symptom.clone(x))
        res.free_symptoms = ass.free_symptoms.map(x => x)
        res.intervention = ass.intervention.map(x => Intervention.clone(x))
        res.assessment_intervention = ass.assessment_intervention.map(x => AssessmentIntervention.clone(x))
        return res;
    }
}


export class Assessment {
    id: number = 0;
    user_id: number = 0;
    patient_id: number = 0;
    problem_id: number = 0;
    modifiers1: string = "";
    modifiers2: string = "";
    priority: number = 0;
    priority_reason: string = "";
    kbs_score_k: number = 3;
    kbs_score_b: number = 3;
    kbs_score_s: number = 3;
    k_sentence1: string = "";
    k_sentence2: string = "";
    k_sentence3: string = "";
    k_sentence4: string = "";
    k_sentence5: string = "";
    b_sentence1: string = "";
    b_sentence2: string = "";
    b_sentence3: string = "";
    b_sentence4: string = "";
    b_sentence5: string = "";
    s_sentence1: string = "";
    s_sentence2: string = "";
    s_sentence3: string = "";
    s_sentence4: string = "";
    s_sentence5: string = "";
    team_score: number = 0;
    next_assessment_day: string = "";
    mokuhyou: string = "";
    closed: boolean = false;
    assessment_day: string = DateUtil.currentDateStr();
    created_at: string = "";
    updated_at: string = "";

    static setKBSScore(src: Assessment, kbs: string, value: number): void {
        if (kbs == "K") src.kbs_score_k = value;
        else if (kbs == "B") src.kbs_score_b = value;
        else if (kbs == "S") src.kbs_score_s = value;
    }

    static getKBSScore(src: Assessment, kbs: string): number {
        if (kbs == "K") return src.kbs_score_k;
        else if (kbs == "B") return src.kbs_score_b;
        else if (kbs == "S") return src.kbs_score_s;
        return 0;
    }

    static setKBSSentence(src: Assessment, kbs: string, score: number, value: string): void {
        if (kbs == "K") {
            if (score == 1) src.k_sentence1 = value;
            else if (score == 2) src.k_sentence2 = value;
            else if (score == 3) src.k_sentence3 = value;
            else if (score == 4) src.k_sentence4 = value;
            else if (score == 5) src.k_sentence5 = value;
        }
        else if (kbs == "B") {
            if (score == 1) src.b_sentence1 = value;
            else if (score == 2) src.b_sentence2 = value;
            else if (score == 3) src.b_sentence3 = value;
            else if (score == 4) src.b_sentence4 = value;
            else if (score == 5) src.b_sentence5 = value;
        }
        else if (kbs == "S") {
            if (score == 1) src.s_sentence1 = value;
            else if (score == 2) src.s_sentence2 = value;
            else if (score == 3) src.s_sentence3 = value;
            else if (score == 4) src.s_sentence4 = value;
            else if (score == 5) src.s_sentence5 = value;
        }
    }

    static getKBSSentence(src: Assessment, kbs: string, score: number): string {
        if (kbs == "K") {
            if (score == 1) return src.k_sentence1;
            else if (score == 2) return src.k_sentence2;
            else if (score == 3) return src.k_sentence3;
            else if (score == 4) return src.k_sentence4;
            else if (score == 5) return src.k_sentence5;
        }
        else if (kbs == "B") {
            if (score == 1) return src.b_sentence1;
            else if (score == 2) return src.b_sentence2;
            else if (score == 3) return src.b_sentence3;
            else if (score == 4) return src.b_sentence4;
            else if (score == 5) return src.b_sentence5;
        }
        else if (kbs == "S") {
            if (score == 1) return src.s_sentence1;
            else if (score == 2) return src.s_sentence2;
            else if (score == 3) return src.s_sentence3;
            else if (score == 4) return src.s_sentence4;
            else if (score == 5) return src.s_sentence5;
        }
        return "";
    }

    static getAllKSentence(src: Assessment): string[] {
        return [
            src.k_sentence1,
            src.k_sentence2,
            src.k_sentence3,
            src.k_sentence4,
            src.k_sentence5
        ]
    }
    static getAllBSentence(src: Assessment): string[] {
        return [
            src.b_sentence1,
            src.b_sentence2,
            src.b_sentence3,
            src.b_sentence4,
            src.b_sentence5
        ]
    }
    static getAllSSentence(src: Assessment): string[] {
        return [
            src.s_sentence1,
            src.s_sentence2,
            src.s_sentence3,
            src.s_sentence4,
            src.s_sentence5
        ]
    }

    static fromJSON(dst: Assessment, json: any) {
        dst.id = json.id
        dst.user_id = json.user_id
        dst.patient_id = json.patient_id
        dst.problem_id = json.problem_id
        dst.modifiers1 = json.modifiers1
        dst.modifiers2 = json.modifiers2
        dst.priority = json.priority
        dst.priority_reason = json.priority_reason
        dst.kbs_score_k = json.kbs_score_k
        dst.kbs_score_b = json.kbs_score_b
        dst.kbs_score_s = json.kbs_score_s
        dst.k_sentence1 = json.k_sentence1
        dst.k_sentence2 = json.k_sentence2
        dst.k_sentence3 = json.k_sentence3
        dst.k_sentence4 = json.k_sentence4
        dst.k_sentence5 = json.k_sentence5
        dst.b_sentence1 = json.b_sentence1
        dst.b_sentence2 = json.b_sentence2
        dst.b_sentence3 = json.b_sentence3
        dst.b_sentence4 = json.b_sentence4
        dst.b_sentence5 = json.b_sentence5
        dst.s_sentence1 = json.s_sentence1
        dst.s_sentence2 = json.s_sentence2
        dst.s_sentence3 = json.s_sentence3
        dst.s_sentence4 = json.s_sentence4
        dst.s_sentence5 = json.s_sentence5
        dst.team_score = json.team_score
        dst.next_assessment_day = json.next_assessment_day
        dst.closed = json.closed
        dst.assessment_day = json.assessment_day
        dst.created_at = json.created_at
        dst.updated_at = json.updated_at
        dst.mokuhyou = json.mokuhyou || ""
    }
}

export class PostIntervention {
    id: number = 0;
    comment: string = "";

    constructor(id: number = 0, comment: string = "") {
        this.id = id;
        this.comment = comment;
    }
}

export class PostAssessment {
    assessment: Assessment = new Assessment();
    symptoms: number[] = [];
    free_symptoms: string[] = [];
    interventions: PostIntervention[] = [];

    public containsIntervention(id: number): boolean {
        return this.interventions.find(x => x.id == id) != null;
    }

    public static copy(src:AssessmentSet): PostAssessment {
        const res = new PostAssessment();
        res.assessment = src.assessment
        res.symptoms = src.symptom.map(x => x.id)
        res.free_symptoms = src.free_symptoms.concat([])
        res.interventions = src.assessment_intervention.map(x => {
            return new PostIntervention(x.intervention_id, x.comment)
        })
        return res
    }
}