import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'

const drawerWidth = 260;


const styles = (theme: any) => ({
    root: {
        display: 'flex',
    },
    title: {
        fontSize: "0.9rem"
    },
    appBar: {
        overflow: "hidden"
    },
    toolBar: {
        minHeight: "3em",
        maxHeight: "3em"
    },
    appBarShift: {
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    patientButton: {
        marginRight: 5,
        marginLeft: "auto"
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        color: Colors.primaryColor
    },
    drawerPaper: {
        width: drawerWidth,
        color: "white",
        backgroundColor: Colors.primaryColor
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        color: "white",
        backgroundColor: Colors.borderColor
    },
    drawerName: {
        marginBlockEnd: 0
    },
    drawerOffice: {
        marginBlockStart: 0,
        fontSize: "1.0em"
    },
    largeIcon: {
        width: 60,
        height: 60,
        marginTop: "10px",
        marginRight: "10px",
        color: Colors.primaryColor
    },
    drawerIcon: {
        color: Colors.drawerIconColor
    },
    content: {
        flexGrow: 1,
        padding: 10,
    },
    contentShift: {
        marginLeft: 0,
    },
    drawerHeaderInfoBlock: {
        justifyContent: "left",
        paddingTop: "15px",
        width: "100%",
        color: "white"
    },
    drawerHeaderInfoElement: {
        float: "left" as any
    },
    link: {
        textDecoration: 'none',
        color: 'white'
    },
    wave: {
        background: 'url(/images/wave.svg) repeat-x',
        position: "absolute" as any,
        backgroundSize: 'contain',
        width: '6400px',
        height: '100%',
        opacity: 0.6,
        animation: 'wave 61s ease-in-out infinite',
        transform: 'translate3d(0, 0, 0)',
        backgroundColor: Colors.primaryColor,
        mixBlendMode: 'overlay' as any
    },
    wave2: {
        background: 'url(/images/wave.svg) repeat-x',
        position: "absolute" as any,
        backgroundSize: 'contain',
        width: '6400px',
        height: '100%',
        opacity: 0.5,
        animation: 'swell 46s ease-in-out infinite',
        mixBlendMode: 'screen' as any
    },
    wave3: {
        background: 'url(/images/wave.svg) repeat-x',
        position: "absolute" as any,
        backgroundSize: 'contain',
        width: '6400px',
        height: '100%',
        opacity: 0.5,
        animation: 'swell2 52s ease-in-out infinite',
        mixBlendMode: 'overlay' as any
    },
    loginHeader: {
        backgroundColor: Colors.primaryColor,
        color: "white",
        width: "100%"
    },
    activity_face: {
        width: "40px",
        height: "40px",
        borderRadius: "50px",
        objectFit: "contain" as any,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        display: "inline-flex",
        cursor: "pointer"
    }
});

interface Props extends Styles.WithStyles<typeof styles>, PageProps {
    theme: any
}


interface LocalState {
}

class HeaderNavigationMock extends React.Component<Props, LocalState> {
    displayName = "HeaderNavigationMock"
    constructor(props) {
        super(props)
        this.state = {
        };
    }


    public componentDidMount() {
    }

    public render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            <CssBaseline key="baseline1" />
            <AppBar
                key="appbar"
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar className={classes.toolBar}>
                    <span className={classes.title}>
                        homis Nursee
                    </span>
                </Toolbar>
            </AppBar>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles, { withTheme: true })(HeaderNavigationMock));
