import Firebase from './index'

import Resizer from 'react-image-file-resizer';

export default class AvatarStore {
    static cache: { [index: number]: string } = {}
    static cachePromise: { [index: number]: Promise<string> } = {}
    private static genPath(cid: number, uid: number) {
        return `/company/${cid}/avatar/${uid}.jpg`
    }
    public static getUrl(cid:number, uid:number):Promise<string> {
        if (AvatarStore.cache[uid]) {
            return new Promise((resolve, reject) => {
                resolve(AvatarStore.cache[uid])
            });
        }
        if (AvatarStore.cachePromise[uid]) {
            return AvatarStore.cachePromise[uid]
        }
        const storageRef = Firebase.instance.storage.ref()
        return AvatarStore.cachePromise[uid] = storageRef.child(this.genPath(cid, uid)).getDownloadURL().then((x:string) => {
            AvatarStore.cache[uid] = x
            return new Promise<string>((resolve, reject) => {
                resolve(x)
            })
        }).catch(err => {
            AvatarStore.cache[uid] = "none"
            return new Promise<string>((resolve, reject) => {
                resolve("none")
            })
        })
    }

    public static put(cid: number, uid: number, file: File) {
        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                400, 400,
                'JPEG',
                100, 0,
                async uri => {
                    const storageRef = Firebase.instance.storage.ref()
                    delete AvatarStore.cache[uid]
                    delete AvatarStore.cachePromise[uid]
                    resolve(await storageRef.child(this.genPath(cid, uid)).putString(uri.substring(23), 'base64'))
                },
                'base64'
            );
        })
    }
}
