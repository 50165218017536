import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as Colors from '../components/Colors'
import * as Icons from '@material-ui/icons';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'

const styles = () => Styles.createStyles({
    root: {
        margin: "20px",
        textAlign: "left",
        fontSize: "0.9rem",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        whiteSpace: "pre-line"
    },
    child: {
        height: "500px",
        overflowY: "scroll",
        border: "solid 1px",
        //borderRadius: "10px",
        padding: "10px"
    }
});

interface Props extends PageProps, Styles.WithStyles {
}


interface LocalState {
    loading: boolean
}

// @inject()
// @observer
class KiyakuPage extends React.Component<Props, LocalState> {
    displayName = "KiyakuPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    public componentDidMount = () => {

    }
    

    public render() {
        const { classes } = this.props
        return <div className={classes.root}>
            お問合せ先：<br />
            <br />
            {"　"}電話：0120-939-488 <br />
            {"　"}メール：homisnursee-support@mics.tokyo <br />
            {"　"}受付時間　平日９：００～１７：００<br/>
            {"　"}携帯電話・スマートフォンからもご利用いただけます<br/>
            <br />
            <br />
            利用規約：<br />
            <iframe src="/images/nurseekiyaku_1.html" width="90%" height="500" style={{maxWidth:"1200px"}}/>
            <br />
            <br />
            プライバシーポリシー：<br />
            <a href="https://www.hlm.tokyo/privacypolicy" target="_blank">プライバシーポリシーはこちら</a><br />
            <br />
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(KiyakuPage))