import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const styles = () => Styles.createStyles({
    table: {
        margin: "0px",
        fontSize: "0.95rem",
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.white,
    },
    leftCell: {
        textAlign: "center",
        width: "15%",
        //padding: "0.5rem",
        backgroundColor: Colors.secondaryColor,
        color: Colors.textColor,
        fontSize: "0.9rem",
        minWidth: "100px",
        whiteSpace: "pre-line"
    },
    leftCellShrink: {
        backgroundColor: Colors.forthColor,
        color: Colors.primaryColor,
        //padding: "10px",
        position: "sticky",
        top: "90px",
        zIndex: 1,
        marginTop: "5px",
        margin: "0px",
        fontSize: "0.9rem", 
        padding: "5px",
        paddingLeft: "30px",
        whiteSpace: "pre-line"
    },
    leftCellNoBG: {
        maxWidth: "50px",
        textAlign: "left",
        color: Colors.thirdColor, 
        width: "120px", 
        //paddingTop: "2px",
        fontSize: "0.9rem",
        verticalAlign: "top",
        whiteSpace: "pre-line"
    },
    rightCell: {
        padding: "1px",
        color: Colors.inputColor,
        fontSize: "0.9rem",
        whiteSpace: "pre-line"
        //paddingBottom: "10px"
    },
    rightCellShrink: {
        padding: "1px",
        color: Colors.inputColor,
        //paddingLeft: "10px",
        fontSize: "0.9rem",
        borderTop: "none",
        whiteSpace: "pre-line"
    },
    floatRight: {
        position: "absolute",
        right: "5px"
    },
})

interface Props extends Styles.WithStyles<typeof styles> {
    body: {
        name: string | React.ReactElement,
        content: React.ReactElement,
        closeDefault?: boolean
    }[],
    shrink?: boolean
    disableBGColor?: boolean
    omahaMode?: boolean
    alwaysOpen?:boolean
    width?:string
    disableRemain?:boolean
    noPadding?: boolean
    forceClose?: boolean
    onClick?: (idx: number, closed:boolean) => void
}


interface LocalState {
    closed: {}
}

class Col2Table extends React.Component<Props, LocalState> { 
	displayName="Col2Table"
    constructor(props) {
        super(props)
        this.state = {
            closed: {}
        }
    }
    public static defaultPorps = {
        omahaMode: false,
        alwaysOpen: false,
        disableRemain: false,
        noPadding: false,
        forceClose: undefined,
        onClick: (idx, c) => {}
    }
    public render() {
        const { classes } = this.props;
        return (this.props.shrink ?
                this.props.body.map((row, idx) => {
                    const closed = this.state.closed
                    let cls = false
                    if (idx in closed) {
                        cls = closed[idx]
                    }
                    else {
                        cls = row.closeDefault ? true : false
                    }
                    if (this.props.forceClose != undefined){
                        cls = this.props.forceClose;
                    }
                    return <div key={`r_${idx}`} >
                        {
                            row.name != "" ?
                                <div className={classes.leftCellShrink} 
                                    style={
                                        this.props.omahaMode ? 
                                            { backgroundColor: Colors.orangeColor, color: Colors.darkBlueColor } : {
                                                position: this.props.disableRemain ? "relative" : undefined,
                                                top: this.props.disableRemain ? "0px" : undefined
                                        }
                                    }
                                onClick={(e) => {
                                    closed[idx] = !cls
                                    this.setState({ closed: closed })
                                    if (this.props.onClick) {
                                        this.props.onClick(idx, closed[idx])
                                    }
                                }}>
                                    <span>{row.name}</span> {this.props.alwaysOpen ? null : <span className={classes.floatRight}>{cls  ? "∧" : "∨"}　</span>}
                                </div>
                                : null
                        }
                        {
                            cls ?
                                null :
                                <div className={classes.rightCellShrink} >
                                    {row.content}
                                </div>
                        }
                    </div>
                }) :
                <Table className={classes.table}>
                    <TableBody>
                        {
                            this.props.body.map((row, idx) => {
                                const c = this.props.disableBGColor ? classes.leftCellNoBG : classes.leftCell
                                return <TableRow key={`row_${idx}`} className={classes.row}>
                                    {
                                        row.name != "" ?
                                            <TableCell className={c} component="th" scope="row" style={{ width: this.props.width, minWidth: this.props.width }}>
                                                {row.name}
                                            </TableCell>
                                            : null
                                    }
                                    <TableCell style={this.props.noPadding ? {padding:"0px 5px"} : {}} className={classes.rightCell} align="left">
                                        {row.content}
                                    </TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
                )
    }
}

export default Styles.withStyles(styles)(Col2Table)