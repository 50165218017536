export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public address: string = "";
  public adl_haiben: string = "";
  public adl_hainyou: string = "";
  public adl_idou: string = "";
  public adl_ijyou: string = "";
  public adl_kaidansyoukou: string = "";
  public adl_koui: string = "";
  public adl_nyuyoku: string = "";
  public adl_seiyou: string = "";
  public adl_syokuji: string = "";
  public adl_toire: string = "";
  public age: string = "";
  public asesumento: string = "";
  public birthday: string = "";
  public create_date: string = "";
  public gender: string = "";
  public honninnokimochi: string = "";
  public iadl_denwa: string = "";
  public iadl_hukuyaku: string = "";
  public iadl_kaimono: string = "";
  public iadl_kakei: string = "";
  public iadl_norimono: string = "";
  public iadl_sentaku: string = "";
  public iadl_souji: string = "";
  public iadl_syokuji_junbi: string = "";
  public iryoukiki: string = "";
  public jigyousyo: string = "";
  public jigyousyo_address: string = "";
  public jigyousyo_fax: string = "";
  public jigyousyo_phone: string = "";
  public jikai_date: string = "";
  public problem_date: string = "";
  public problem: string = "";
  public problem_ass: string = "";
  public kankeisya: string = "";
  public kazoku: string = "";
  public kazokunokimochi: string = "";
  public kea_houkousei: string = "";
  public mitoosi: string = "";
  public pat_name: string = "";
  public sakuseisya: string = "";
  public seiikureki: string = "";
  public shubyoumei: string = "";
  public shuhoken: string = "";
  public sijisyo: string = "";
  public yakuzai: string = "";
  public yaminokiseki: string = "";
  public youkaigo: string = "";
}

export class Checks {}
