import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import DateUtil from '../../util/DateUtil'

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        height: "200px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
//        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    }
});

class CustomizedLabel extends React.PureComponent<any> {
    render() {
        const {
            x, y, stroke, value, name
        } = this.props;

        return <g>
            <rect x={x - 15} y={y - 17} width={30} height={14} fill="#FFFC" />
            <text x={x} y={y} dy={-5}
                fill={stroke} fontSize={13}
                textAnchor="middle">{value}</text>
        </g>
    }
}
const CustomTooltip = (data: any) => {
    const { active, payload, label } = data
    if (active && payload.length>0) {
        console.log(data)
        const d = payload[0].payload
        if (!d) return null
        const gen = (col, slist, target) => {
            return <div style={{ background:"white" }} >
                {slist ? slist.map((s, si) => <span key={"tc"+si} style={{color: si==target ? col : Colors.primaryColor}}>{`${si + 1}:${s}`}<br /></span>) : null}
            </div>
        }
        return (
            <div className="custom-tooltip" style={{background:"white", lineHeight:"1", zIndex:1, padding:"5px"}}>
                <p>{`日付：${DateUtil.toJpDateString(label)}`}</p>
                <p>
                    <span style={{ color: payload[0].color }}>{`知識(K) : ${d.K} `}</span>
                    <span style={{ color: payload[1].color }}>{`行動(B) : ${d.B} `}</span>
                    <span style={{ color: payload[2].color }}>{`状態(S) : ${d.S} `}</span>
                </p>
                <div style={{ marginLeft: "5px", fontSize: "smaller" }}>
                    <hr/>
                    {gen(payload[0].color, d.KS, d.K - 1)}
                    <hr />
                    {gen(payload[1].color, d.BS, d.B - 1)}
                    <hr />
                    {gen(payload[2].color, d.SS, d.S-1)}
                </div>
            </div>
        );
            
    }

    return null;
};
interface Props extends Styles.WithStyles {
    title: string,
    data: any,
    smallMode: boolean
}

class LineGraph extends React.PureComponent<Props> { 
	displayName="LineGraph"
    public render() {
        const { classes } = this.props;

        const count = ["K", "B", "S"]
        const label = this.props.smallMode ? ["K", "B", "S"] : ["K(知識)", "B(行動)", "S(状態)"]
        const color = ["#3a73d9", "#2a9953", "#d97373"]
        const ticks = [1, 2, 3, 4, 5]

        return <div className={classes.root}>
            <div>
                {this.props.title}
            </div>
            <ResponsiveContainer width="100%" height="95%">
            <LineChart
                data={this.props.data}
                margin={{top: 5, right: 10, left: 10, bottom: 5}}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis hide={this.props.smallMode} ticks={ticks} domain={[1,5]}/>
                    <Tooltip content={<CustomTooltip />} />
                <Legend 
                formatter={(value, entry, index) => label[index]}/>
                {
                    count.map((c, i) => {
                        return <Line key={`lg_${i}`} dataKey={count[i]} stroke={color[i]}
                            label={<CustomizedLabel />} 
                            isAnimationActive={this.props.smallMode==false}/>
                    })
                }
            </LineChart>
            </ResponsiveContainer>
        </div>
    }
}

export default Styles.withStyles(styles)(LineGraph)