import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    input: {
        color: Colors.inputColor,
        padding: "0px",
        "& input": {
            padding: "5px",
            width: "80px"
        },
        "& textarea": {
            padding: "5px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto",
    }
})

interface Props extends Styles.WithStyles {
    editMode: boolean,
    name: string,
    value: any,
    label: string,
    step: number,
    display: string,
    setData: (name: string, value: any) => void
    converter: ((name: string, value: any) => void) | null
}

class EditableValue extends React.Component<Props> { 
	displayName="EditableValue"
    public static defaultProps = {
        display: "block",
        converter: null
    }
    public render() {
        const { classes, value, name } = this.props;
        if (this.props.editMode==false) {
            if (value) {
                return <div key={`edth_${name}`} style={{ display: this.props.display }}>{value}</div>
            }
            else return <div key={`edtn_${name}`}/>
        }
        return <div key={`edt_${name}`} style={{display:this.props.display}}>
                <TextField
                    label=""
                    placeholder={this.props.label}
                    type="number"
                    fullWidth={false}
                    multiline={false}
                    value={value == null ? "" : value}
                    onChange={(e) => this.props.setData(name, e.target.value)}
                    onPointerLeave={(e) => {
                        if (this.props.converter) {
                            this.props.converter(name, value == null ? "" : value)
                        }
                    }}
                    className={classes.textField}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        className: classes.input,
                        inputProps: { step: this.props.step } 
                    }}
        margin="none"
        variant="outlined"
    />
        </div>
    }
}

export default Styles.withStyles(styles)(EditableValue)