import React, { FC } from "react";
import * as Colors from "../Colors";
import * as Styles from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import {
  ResponsiveContainer,
  ComposedChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { saveAs } from "file-saver";
import Encoding from "encoding-japanese";

type Props = {
  data: {
    [x: string]: string | number;
  }[];
  months: string[];
};

function genColor(idx: number) {
  return `hsl(${(idx * 63) % 360}, 40%, 60%)`;
}

const transpose = (a: (string | number)[][]): (string | number)[][] =>
  a[0].map((_, c) => a.map((r) => r[c]));

const HoumonMonthlyByBranch: FC<Props> = ({ data, months }) => {
  const headers = data[0] == null ? [] : Object.keys(data[0]);
  const lineKeys = headers.filter((h) => {
    if (h === "合計(時間)") return true;
    return h.includes("(件)");
  });
  const barKeys = headers.filter((h) => {
    if (h === "合計(時間)") return false;
    return h.includes("(時間)");
  });

  const csvData = (() => {
    if (data == null) return "";
    if (data[0] == null) return "";
    const headers = Object.keys(data[0]);

    const rows: (string | number)[][] = [headers];

    months.forEach((month) => {
      const monthData = data.find((d) => d["月"] === month);
      const row: (string | number)[] = [];
      headers.forEach((header) => {
        if (header === "月") {
          row.push(month);
        } else if (monthData == null) {
          row.push(0);
        } else {
          row.push(monthData[header]);
        }
      });
      rows.push(row);
    });

    let result = "";

    transpose(rows).forEach((row) => {
      result += row.join(",") + "\n";
    });

    return result;
  })();

  const onClick = () => {
    const str = Encoding.stringToCode(csvData);
    const convert = Encoding.convert(str, "sjis", "unicode");
    const u8a = new Uint8Array(convert);

    const blob = new Blob([u8a], { type: "text/csv;charset=Shift_JIS;" });
    saveAs(blob, `月別訪問件数／時間(事業所ごと).csv`);
  };

  return (
    <div
      style={{
        color: Colors.primaryColor,
        height: "400px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>月別訪問件数/時間(事業所ごと)</div>
        <Button
          type="button"
          variant="text"
          onClick={onClick}
          style={{ marginLeft: "auto" }}
        >
          CSV↓(Sjis)
        </Button>
      </div>
      <ResponsiveContainer width="100%" height="90%">
        <ComposedChart
          data={data}
          margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="月" padding={{ left: 30, right: 30 }} />
          <YAxis yAxisId="right" orientation="right" />
          <YAxis yAxisId="left" />
          <Legend />
          <Tooltip
            formatter={(value) => {
              return value;
            }}
          />
          {lineKeys.map((key, i) => (
            <Line
              yAxisId={key === "合計(時間)" ? "right" : "left"}
              dataKey={key}
              stroke={genColor(i)}
            />
          ))}
          {barKeys.map((key, i) => (
            <Bar
              yAxisId="right"
              barSize={15}
              stackId="a"
              dataKey={key}
              fill={genColor(i + 7)}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HoumonMonthlyByBranch;
