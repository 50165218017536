
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public score_daeki: number = -1
    public score_gishi: number = -1
    public score_koushin: number = -1
    public score_seisou: number = -1
    public score_shiniku: number = -1
    public score_shita: number = -1
    public score_shitsuu: number = -1
    public score_zanzon: number = -1
    public sum_score: number = 0
}

export class Checks {
    public gishi_nashi: boolean = true
    public zanzon_nashi: boolean = true
}