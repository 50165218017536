import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    textField: {
        width: "90%"
    },
    input: {
        color: Colors.inputColor,
        padding: "5px",
        "& input": {
            padding: "5px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto",
    }
})

interface Props extends Styles.WithStyles {
    title: string,
    value: string,
    onChange: (any) => void,
    inputType: string,
    multiline: boolean,
    label?: string,
    rows: number,
    editable: boolean
}

class InputText extends React.Component<Props> { 
	displayName="InputText"
    public static defaultProps = {
        inputType: "text",
        multiline: false,
        label: undefined,
        rows: 1,
        editable: true
    }
    public render() {
        const { classes, title } = this.props;
        return [
            <Grid key={title+"_1"} item xs={3} className={classes.row}>
                {title}
            </Grid>,
            <Grid key={title + "_2"} item xs={9}>
                {
                    this.props.editable ?
                        <TextField
                            id="outlined-number"
                            label={this.props.label}
                            type={this.props.inputType}
                            value={this.props.value}
                            onChange={(e) => this.props.onChange(e.target.value)}
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                className: classes.input,
                            }}
                            multiline={this.props.multiline}
                            margin="none"
                            variant="outlined"
                            rows={this.props.rows}
                        />
                        : <div>
                            {this.props.value && this.props.value!="" ? this.props.value : "-"}
                        </div>
                }
            </Grid>
        ]
    }
}

export default Styles.withStyles(styles)(InputText)