//import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class LangMeiryouBehaviour extends FormBehaviour.PdfBehaviour  { 
	displayName="LangMeiryouBehaviour"

    public getId(): number {
        return 17
    }

    public createContent(): any {
        const res = new FormModel.LangMeiryou.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {

    }


    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const elements_scores = [
            {
                name: "実施の結果", content: saV.listRaw("score_lang", [
                    { key: "未選択", value: -1 },
                    { key: "１：よくわかる", value: 1 },
                    { key: "２：時々わからない語がある程度", value: 2 },
                    { key: "３：聞き手が話題を知っているとどうやらわかる程度", value: 3 },
                    { key: "４：時々わかる語があるという程度", value: 4 },
                    { key: "５：全く了解不能", value: 5 }
                ], true, true)
            },
            { name: "備考", content: saV.text("comment") }
        ]

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
