import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';

const styles = () => Styles.createStyles({
    root: {
    },
    textField: {
        width: "90%"
    },
    input: {
        color: Colors.inputColor,
        padding: "10px",
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto",
    },
    thumbnail: {
        width: "100px",
        margin: "5px"
    }
})

interface Props extends Styles.WithStyles {
    editMode: boolean,
    name: string,
    value: any,
    setData: (name: string, selectedFiles: FileList) => void
}

class EditableText extends React.Component<Props> { 
	displayName="EditableText"
    public render() {
        const { classes, value, name } = this.props;

        return <div key={`edi_${name}`}>
            <a href={value} target="_blank">
            <img src={value} className={classes.thumbnail}/>
            </a>
            {
                this.props.editMode ?
                    <input type="file" id={`etid_${name}`} ref="file"
                        onChange={(e) => {
                            if (e.target.files) {
                                this.props.setData(name, e.target.files)
                            }
                        }} />
                        : null
            }
        </div>
    }
}

export default Styles.withStyles(styles)(EditableText)