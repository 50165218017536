import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 89;
  }

  public createContent(): any {
    const res = new FormModel.Cam.Core();
    res.values.create_date = DateUtil.currentDateStr();
    HokanApi.me()
      .then((x) => {
        if (x && x.data) {
          res.values.hyoukasya = x.data.name;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {}

  public beforeMount = () => {};
  public async setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {}

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const elements_t = [
      {
        name: "評価者",
        content: <div style={{ padding: 10 }}>{saV.text("hyoukasya")}</div>,
      },
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            <fieldset
              style={{
                marginBottom: 10,
              }}
            >
              <legend>Ⅰ.急性発症及び症状の変動</legend>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                急激に変化する兆候が認められたか？
                {saC.radio([
                  {
                    label: "いいえ",
                    key: `check_1`,
                  },
                  {
                    label: "はい",
                    key: `check_2`,
                  },
                ])}
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                当該（異常）行動は、生じたり消えたり、また重症度が軽くなったり、ひどくなる等、症状のレベルに変化があったか？
                {saC.radio([
                  {
                    label: "いいえ",
                    key: `check_3`,
                  },
                  {
                    label: "はい",
                    key: `check_4`,
                  },
                ])}
              </label>
            </fieldset>
            <fieldset
              style={{
                marginBottom: 10,
              }}
            >
              <legend>Ⅱ.注意力の欠如</legend>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                すぐにほかのことに気を取られる、会話中、話の内容を覚えていられない等、患者は意識を集中させることに困難を感じていたか？
                {saC.radio([
                  {
                    label: "いいえ",
                    key: `check_5`,
                  },
                  {
                    label: "はい",
                    key: `check_6`,
                  },
                ])}
              </label>
            </fieldset>
            <fieldset
              style={{
                marginBottom: 10,
              }}
            >
              <legend>Ⅲ.思考の解体</legend>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                的外れ又は取りとめのない会話、不明瞭又は非論理的な思考、突飛な話題の転換のように、患者の思考が支離滅裂であったり、一貫していなかったりしたか？
                {saC.radio([
                  {
                    label: "いいえ",
                    key: `check_7`,
                  },
                  {
                    label: "はい",
                    key: `check_8`,
                  },
                ])}
              </label>
            </fieldset>
            <fieldset
              style={{
                marginBottom: 10,
              }}
            >
              <legend>Ⅳ.意識レベルの変容</legend>
              <p>
                以上を総合して、この患者の意識レベルをどのように評価するか？
              </p>
              <div>
                <div>覚醒 （通常状態）</div>
                <div
                  style={{
                    border: "1px solid",
                    padding: 5,
                  }}
                >
                  <div>緊張 （過覚醒）</div>
                  <div>傾眠 （眠気、易興奮性）</div>
                  <div>昏迷 （難興奮性）</div>
                  <div>昏睡 （不覚醒）</div>
                </div>
              </div>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                上記の四角内のいずれかにチェックが付いたか？
                {saC.radio([
                  {
                    label: "いいえ",
                    key: `check_9`,
                  },
                  {
                    label: "はい",
                    key: `check_10`,
                  },
                ])}
              </label>
            </fieldset>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
