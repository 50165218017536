import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour   { 
	displayName="Behaviour"
    
    public getId(): number {
        return 24
    }

    public createContent(): any {
        const res = new FormModel.MentalWrap.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }


    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content", "values"])

        const elements_scores = [
            {
                name: "私の思い", content: saV.text("omoi_m")
            },
            {
                name: "家族・支援者の想い", content: saV.text("omoi_f")
            },
            {
                name: "普段の生活", content: <div>
                    食事：{saV.text("life1")}
                    清潔：{saV.text("life2")}
                    日中の行動：{saV.text("life3")}
                    睡眠：{saV.text("life4")}
                    人との関係：{saV.text("life5")}
                    薬と治療に対しての思い：{saV.text("life6")}
                </div>
            },
            {
                name: "元気に役立つ道具箱", content: saV.text("tool")
            },
            {
                name: "私の強み", content: saV.text("tsuyomi")
            },
            {
                name: "気を付けた方がいいストレス", content: saV.text("stress")
            },
            {
                name: "引き金となること", content: saV.text("hikigane")
            },
            {
                name: "引き金に対する行動", content: saV.text("hiki_res")
            },
            {
                name: "注意サイン", content: saV.text("sign")
            },
            {
                name: "注意サインに対する行動", content: saV.text("sign_res")
            },
            {
                name: "その時に周りはどうする・どうしてほしい", content: saV.text("dousuru")
            },
            {
                name: "私の相談窓口・支援者", content: saV.text("soudan")
            },
        ]

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
