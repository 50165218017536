
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""

    public text_1: string = ""
    public text_2: string = ""
    public text_3: string = ""
    public text_4: string = ""
    public text_5: string = ""
}

export class Checks {
}