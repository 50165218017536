import * as React from 'react';
import { Auth, toInjector } from '../stores';
import { inject, observer } from 'mobx-react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Colors from '../components/Colors'
import Fab from '@material-ui/core/Fab';
import * as Icons from '@material-ui/icons';
import * as HokanApi from '../api/hokan'
import { Button } from '@material-ui/core';

import { AuthenticateServer } from '../config/api';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
import { CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';

const AWS = require('aws-sdk');

const styles = () => Styles.createStyles({
    root: {
        padding: "10px",
        textAlign: "center"
    },
    container: {
        maxWidth: "500px",
        display: "inline-block"
    },
    textField: {
        width: "100%",
        "& label": {
            color: Colors.primaryColor,
        },
        "& div": {
            "& fieldset": {
                borderRadius: "10px",
                borderColor: `${Colors.borderColor} !important`
            },
            "& input": {
                color: Colors.inputColor,
                padding: "18px 18px"
            }
        }
    },
    button: {
        width: "100%",
        marginTop: "50px"
    },
    message: {
        color: Colors.primaryColor
    },
    logo: {
        width: "50%",
        maxWidth: "200px",
        margin: "20px"
    }
});

interface Props extends Auth, PageProps, Styles.WithStyles {
    message: string
}

@inject(...toInjector(Auth))
@observer
class LoginCognitoPage extends React.Component<Props, {
    showPassword: boolean, errmsg: string,
    mfa: boolean, mfa_to: string,
    token: string
}> {
    displayName = "LoginPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            showPassword: false,
            errmsg: "",
            mfa: false,
            mfa_to: "",
            token: ""
        }
    }

    private clear() {
        const { auth } = this.props;
        localStorage.removeItem("mypage_query")
        localStorage.removeItem("patients_query")
        auth!.initialize()
        this.setState({ errmsg: "", mfa: false })
    }

    public componentDidMount = () => {
        const params = new URLSearchParams(this.props.location.search);
        const mfa = params.get("mfa")
        if (mfa=="1") {
            HokanApi.sendMFA(false).then(res => {
                if (res.data.to) {
                    this.setState({ errmsg: "", mfa: true, mfa_to: res.data.to, token: "" })
                }
                else {
                    this.setState({ errmsg: "", mfa: true, mfa_to: res.data.to, token: "" })
                    this.clear();
                }
            }).catch(err => {
                this.clear();
                window.location.href = "/login"
            })
        }
        else {
            // this.clear();
        }
    }

    private login() {
        const { auth, history, location } = this.props;
        const redirect = (location.state && location.state.from) || "/";

        // ユーザープールの設定
        const poolData = {
            UserPoolId: AuthenticateServer.UserPoolId,
            ClientId: AuthenticateServer.ClientId
        };
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        // Amazon Cognito 認証情報プロバイダーを初期化します
        AWS.config.region = AuthenticateServer.Region; // リージョン
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: AuthenticateServer.IdentityPoolId,
        });

        var email = auth!.username;
        var password = auth!.password;

        // 認証データの作成
        const authenticationData = {
            Username: email,
            Password: password
        };
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );

        const userData = {
            Username: email,
            Pool: userPool
        };
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        // 認証処理
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: result => {
                console.dir(result);
                const idToken = result.getIdToken().getJwtToken(); // IDトークン
                const accessToken = result.getAccessToken().getJwtToken(); // アクセストークン
                const refreshToken = result.getRefreshToken().getToken(); // 更新トークン

                console.log("idToken : " + idToken);
                console.log("accessToken : " + accessToken);
                console.log("refreshToken : " + refreshToken);

                // サインイン成功の場合、次の画面へ遷移
                //location.href = "index.html";
                alert("OK!!!");
                history.push(redirect)
            },
            onFailure: err => {
                // サインイン失敗の場合、エラーメッセージを画面に表示
                console.dir(err);
                this.setState({ errmsg: "ログインに失敗しました" })
            }
        });

        /*
        localStorage.removeItem("mypage_query")
        localStorage.removeItem("patients_query")
        auth!.login(() => {
            HokanApi.me().then(x => {
                history.push(redirect)
            }).catch(err => {
                if (err.response.status === 412) {
                    this.send_mfa(false)
                }
                else history.push(redirect)
            })
        }, (err) => {
            this.setState({ errmsg: "ログインに失敗しました" })
        })
        */
    }

    private send_mfa(as_email: boolean = false) {
        const { auth, history, location } = this.props;
        const redirect = (location.state && location.state.from) || "/";
        HokanApi.sendMFA(as_email).then(res => {
            if (res.data.to) {
                this.setState({ errmsg: "", mfa: true, mfa_to: res.data.to, token: "" })
            }
            else if (res.data.result == "not_needed") {
                history.push(redirect)
            }
            else {
                this.setState({ errmsg: "エラーが発生しました" })
            }
        }).catch(err => {
            console.log(err)
            this.setState({ errmsg: "エラーが発生しました" })
        })
    }

    private confirm_mfa() {
        HokanApi.confirmMFA(this.state.token).then(res => {
            const { auth, history, location } = this.props;
            const redirect = (location.state && location.state.from) || "/";
            history.push(redirect)
        }).catch(err => {
            this.setState({ errmsg: "認証に失敗しました" })
        })
    }

    public render() {
        const { auth, location, classes } = this.props;
        const outed = (location.state && location.state.logout) || false
        return (
            <div className={classes.root}>
                {
                    this.state.mfa ?
                        <div>
                            <div className={classes.message}>
                                <div>
                                    {this.state.mfa_to} に
                                </div>
                                認証コードが送信されました。(10分以内に認証してください)
                            </div>

                            <form className={classes.container} noValidate={true} autoComplete="off">
                                <TextField
                                    id="token"
                                    className={classes.textField}
                                    value={this.state.token}
                                    onChange={(e) => this.setState({ token: e.target.value })}
                                    margin="normal"
                                    variant="outlined"
                                    label="認証コード"
                                />
                                <div>
                                    <span style={{ color: "red" }}>{this.state.errmsg}</span>
                                </div>
                                <div>
                                    <Fab variant="extended" color="primary" aria-label="login"
                                        className={classes.button}
                                        onClick={(e) => { this.confirm_mfa() }}
                                    >
                                        <Icons.Forward />
                                        確定
                                    </Fab>
                                </div>
                            </form>
                            <div style={{margin:"20px"}}>
                                届かない場合、携帯が使用できない場合：
                                <div>
                                    <Button variant="outlined" size="small" color="default"
                                    onClick={(e)=>this.send_mfa(true)}>
                                    {auth!.username} にメールで送信
                                </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className={classes.message}>
                                {
                                    outed ?
                                        "ログアウトしました"
                                        : "ログイン(Cognito)してください"
                                }
                            </div>
                            <form className={classes.container} noValidate={true} autoComplete="on">
                                <TextField
                                    id="mail"
                                    className={classes.textField}
                                    value={auth!.username}
                                    onChange={(e) => auth!.username = e.target.value}
                                    margin="normal"
                                    variant="outlined"
                                    label="メールアドレス"
                                />
                                <TextField
                                    id="pass"
                                    label="パスワード"
                                    className={classes.textField}
                                    value={auth!.password}
                                    onChange={(e) => auth!.password = e.target.value}
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => this.setState({ showPassword: !this.state.showPassword })}
                                                >
                                                    {this.state.showPassword ? <Icons.VisibilityOff /> : <Icons.Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div>
                                    <span style={{ color: "red" }}>{this.state.errmsg}</span>
                                </div>
                                <div>
                                    <Fab variant="extended" color="primary" aria-label="login"
                                        className={classes.button}
                                        onClick={(e) => { this.login() }}
                                    >
                                        <Icons.Forward />
                                        ログイン
                                    </Fab>
                                </div>
                            </form>
                        </div>
                }
                </div>
                );
            }
        }
        
export default withPageProps(Styles.withStyles(styles)(LoginCognitoPage))