
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
    public positions: {px:number, py:number}[] = []
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public after_nrs: string = ""
    public before_nrs: string = ""
    public chintsuu: string = ""
    public create_by: string = ""
    public hi_opioid: string = ""
    public itami_times: string = ""
    public keikai_other: string = ""
    public opioid: string = ""
    public shiyou_kaisuu: string = ""
    public shiyou_yakuzai: string = ""
    public zouaku_other: string = ""
}

export class Checks {
    public check_dose_hakike_1: boolean = false
    public check_dose_hakike_2: boolean = false
    public check_dose_hakike_3: boolean = false
    public chheck_dose_nemuke_1: boolean = false
    public check_dose_nemuke_2: boolean = false
    public check_dose_nemuke_3: boolean = false
    public check_benpi_1: boolean = false
    public check_benpi_2: boolean = false
    public check_benpi_2_a: boolean = false
    public check_benpi_2_b: boolean = false
    public check_benpi_2_c: boolean = false
    public check_chiryo_1: boolean = false
    public check_chiryo_2: boolean = false
    public check_chiryo_3: boolean = false
    public check_chiryo_4: boolean = false
    public check_hakike_1: boolean = false
    public check_hakike_2: boolean = false
    public check_hakike_3: boolean = false
    public check_itami_1: boolean = false
    public check_itami_2: boolean = false
    public check_itami_3: boolean = false
    public check_itami_4: boolean = false
    public check_itami_a_0: boolean = false
    public check_itami_a_1: boolean = false
    public check_itami_a_10: boolean = false
    public check_itami_a_2: boolean = false
    public check_itami_a_3: boolean = false
    public check_itami_a_4: boolean = false
    public check_itami_a_5: boolean = false
    public check_itami_a_6: boolean = false
    public check_itami_a_7: boolean = false
    public check_itami_a_8: boolean = false
    public check_itami_a_9: boolean = false
    public check_itami_b_0: boolean = false
    public check_itami_b_1: boolean = false
    public check_itami_b_10: boolean = false
    public check_itami_b_2: boolean = false
    public check_itami_b_3: boolean = false
    public check_itami_b_4: boolean = false
    public check_itami_b_5: boolean = false
    public check_itami_b_6: boolean = false
    public check_itami_b_7: boolean = false
    public check_itami_b_8: boolean = false
    public check_itami_b_9: boolean = false
    public check_itami_c_0: boolean = false
    public check_itami_c_1: boolean = false
    public check_itami_c_10: boolean = false
    public check_itami_c_2: boolean = false
    public check_itami_c_3: boolean = false
    public check_itami_c_4: boolean = false
    public check_itami_c_5: boolean = false
    public check_itami_c_6: boolean = false
    public check_itami_c_7: boolean = false
    public check_itami_c_8: boolean = false
    public check_itami_c_9: boolean = false
    public check_keikai_1: boolean = false
    public check_keikai_2: boolean = false
    public check_keikai_3: boolean = false
    public check_keikai_4: boolean = false
    public check_keikai_5: boolean = false
    public check_kentoushiki_1: boolean = false
    public check_kentoushiki_2: boolean = false
    public check_kouka_1: boolean = false
    public check_kouka_2: boolean = false
    public check_kouka_3: boolean = false
    public check_kouka_4: boolean = false
    public check_nemuke_1: boolean = false
    public check_nemuke_2: boolean = false
    public check_nemuke_3: boolean = false
    public check_nichijo_0: boolean = false
    public check_nichijo_1: boolean = false
    public check_nichijo_2: boolean = false
    public check_nichijo_3: boolean = false
    public check_nichijo_4: boolean = false
    public check_seijo_1: boolean = false
    public check_seijo_2: boolean = false
    public check_seijo_3: boolean = false
    public check_seijo_4: boolean = false
    public check_seijo_5: boolean = false
    public check_seijo_6: boolean = false
    public check_seijo_7: boolean = false
    public check_zouaku_1: boolean = false
    public check_zouaku_2: boolean = false
    public check_zouaku_3: boolean = false
    public check_zouaku_4: boolean = false
    public check_zouaku_5: boolean = false
    public check_zouaku_6: boolean = false
}