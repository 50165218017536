import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";
import { Button, Grid, MenuItem, Select, Tooltip } from '@material-ui/core';

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 81;
  }

  public createContent(): any {
    const res = new FormModel.Eiyou.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    ["houmon", "tokubetsu", "kango", "sinnryou", "iryou"].forEach((v) => {
      const checked = doc.content.checks[`check_${v}`];

      if (
        checked === true &&
        doc.content.checks[`check_${v}_hospital`] === false &&
        doc.content.checks[`check_${v}_house_call_doctor`] === false
      ) {
        doc.content.checks[`check_${v}_unnecessary`] = true;
        return;
      }

      if (checked === false) {
        doc.content.checks[`check_${v}_hospital`] = false;
        doc.content.checks[`check_${v}_house_call_doctor`] = false;
        doc.content.checks[`check_${v}_unnecessary`] = false;
      }
    });
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const n4list = [1,2,3,4]
    const n15list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
    const st = {maxWidth:"600px"}
    const st2 = {display: "flex", margin: "5px" }
    const ylist = [
      2024, 2025, 2026, 2027, 2028,2029,
      2030, 2031, 2032, 2032, 2033, 2034, 2035
    ].map(x => {
      return {key: x + "年", value: x}
    })
    const element_y = [{
      name: "年",
      content: <div style={st}><Grid container={true} spacing={0}>
          {n4list.map(x => {
            return <Grid item={true} xs={3} md={3} lg={3}>
                  {saV.listRaw(`year_${x}`, ylist)}
             </Grid>
          })}
        </Grid>
      </div>
    }]
    const element_b = [{
      name: "部位",
      content: <div style={{...st, display:"block"}}>
        <Grid container={true} spacing={0}>
          {
            n4list.map(x => {
              return <Grid item={true} xs={3} md={3} lg={3}>
                {saV.text(`bui_${x}`)}
              </Grid>
            }
            )}
        </Grid>
      </div>
    }]
    const element_f = [{
      name: "要介護状態になる前",
      content: <div style={{display:"block"
      , maxWidth:"100px"}}>
        <div style={{display: "flex"}}>{saV.text(`first_height`)} cm</div>
        <div style={{ display: "flex" }}>{saV.text(`first_weight`)} Kg</div>
      </div>
    }]
    const element_n = n15list.map(month => {
      return {
        name:`${month}月`,  content :<div style={st}>
          <Grid container={true} spacing={0}>
          {n4list.map(z => {
            return <Grid item={true} xs={3} md={3} lg={3}>
              <div style={{margin:"5px", display:"flex"}}>{saV.text(`w_${z}_${month}`)} Kg</div>
              <div style={{ margin: "5px", display: "flex" }}>{saV.text(`h_${z}_${month}`)} cm</div>
              </Grid>
          })}
          </Grid>
      </div>
    }})

    const elements = [
      {name:"　", content: element_f},
      { name: "年", content: element_y },
      { name: "部位", content: element_b },
      { name: "内容", content: element_n }
    ];

    return elements;
  }
}
