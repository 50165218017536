export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public comment_1: string = "";
  public comment_2: string = "";
  public comment_3: string = "";
  public comment_4: string = "";
  public comment_5: string = "";
  public comment_6: string = "";
  public comment_7: string = "";
  public comment_8: string = "";
  public create_date: string = "";
  public kaitousya_name: string = "";
  public kinyuusya_name: string = "";
  public pat_name: string = "";
  public relational: string = "";
  public total_point: string = "";
}

export class Checks {
  public check_1: boolean = true;
  public check_10: boolean = false;
  public check_11: boolean = false;
  public check_12: boolean = false;
  public check_13: boolean = true;
  public check_14: boolean = false;
  public check_15: boolean = false;
  public check_16: boolean = false;
  public check_17: boolean = false;
  public check_18: boolean = true;
  public check_19: boolean = false;
  public check_2: boolean = false;
  public check_20: boolean = false;
  public check_21: boolean = false;
  public check_22: boolean = false;
  public check_23: boolean = true;
  public check_24: boolean = false;
  public check_25: boolean = false;
  public check_26: boolean = false;
  public check_27: boolean = false;
  public check_28: boolean = true;
  public check_29: boolean = false;
  public check_3: boolean = true;
  public check_30: boolean = false;
  public check_31: boolean = false;
  public check_32: boolean = false;
  public check_33: boolean = true;
  public check_34: boolean = false;
  public check_35: boolean = false;
  public check_36: boolean = false;
  public check_37: boolean = false;
  public check_38: boolean = true;
  public check_39: boolean = false;
  public check_4: boolean = false;
  public check_40: boolean = false;
  public check_41: boolean = false;
  public check_42: boolean = false;
  public check_5: boolean = false;
  public check_6: boolean = false;
  public check_7: boolean = false;
  public check_8: boolean = true;
  public check_9: boolean = false;
}
