import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import {
    ResponsiveContainer, ComposedChart, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar
} from 'recharts';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        height: "300px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
        //        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    }
});


interface Props extends Styles.WithStyles {
    data: any,
}


function genColor(idx: number) {
    return `hsl(${idx * 43 % 360}, 40%, 60%)`
}
function genColor2(idx: number) {
    return `hsla(${idx * 43 % 360}, 40%, 50%, 0.8)`
}

class HoumonMonthlyUserTime extends React.PureComponent<Props> {
    displayName = "HoumonMonthlyUT"
    public render() {
        const { classes } = this.props;
        const count = this.props.data.count;
        const bs = 10
        return <div className={classes.root}>
            <div>
                月別訪問時間
            </div>
            <ResponsiveContainer width="100%" height="95%">
                <ComposedChart
                    data={this.props.data}
                    margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="月" padding={{ left: 30, right: 30 }} />
                    <YAxis yAxisId="right" orientation="right" />
                    <YAxis yAxisId="left" />
                    <Tooltip formatter={(value, name, props) => {
                        const a = value.toFixed(0)
                        const b = value.toFixed(1)
                        return (a + ".0")==b ? a : b
                    }}
                    wrapperStyle={{zIndex: 1000}}
                    />
                    <Legend />
                    <Line yAxisId="right" dataKey='合計(時間)' stroke={"#555"} />

                    <Bar yAxisId="left" barSize={bs} stackId="a" dataKey='メイン(時間)' fill={"#c93a40"} />
                    <Bar yAxisId="left" barSize={bs} stackId="a" dataKey='教育以外(時間)' fill={"#a0c238"} />
                    <Bar yAxisId="left" barSize={bs} stackId="a" dataKey='見学(時間)' fill={"#65ace4"} />
                    <Bar yAxisId="left" barSize={bs} stackId="a" dataKey='一部実施(時間)' fill={genColor2(4)} />
                    <Bar yAxisId="left" barSize={bs} stackId="a" dataKey='全部実施(時間)' fill={genColor2(5)} />
                    <Bar yAxisId="left" barSize={bs} stackId="a" dataKey='確認(時間)' fill={genColor2(6)} />
                    <Bar yAxisId="left" barSize={bs} stackId="a" dataKey='退院カンファ' fill={genColor2(10)} />
                     <Bar yAxisId="left" barSize={bs} stackId="a" dataKey='多職種カンファ' fill={genColor2(11)} />
                    
                    <Line yAxisId="right" dataKey='医療(時間)' stroke={genColor2(7)} />
                    <Line yAxisId="right" dataKey='介護(時間)' stroke={genColor2(8)} />
                    <Line yAxisId="right" dataKey='その他(時間)' stroke={genColor2(9)} />

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    }
}

export default Styles.withStyles(styles)(HoumonMonthlyUserTime)