import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
import BranchList from '../components/BranchList'

// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
}

// @inject()
// @observer
class BranchListPage extends React.Component<Props, LocalState> {
    displayName = "BranchListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }

    public componentDidMount = () => {
    }

    public render() {
        const { classes } = this.props;
        return <div className={classes.root}>
            <SubTitle title="事業所情報"
                text="事業所を選択してください"
            />
            <BranchList showButton={true}
                companyId={null} />
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(BranchListPage))