import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as Colors from './Colors'
import { DialogTitle, Dialog, withStyles, Button, TextField, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Icons from '@material-ui/icons';


const styles = () => Styles.createStyles({
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    dialog: {
        padding: "10px"
    },
    searchField: {
        maxWidth: "200px"
    }
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    }
}))(TableCell);


interface Props extends Styles.WithStyles<typeof styles> {
    open: boolean
    onSelect: (code: Model.ServiceCode) => void
    onCancel: () => void
    default13: boolean
    default63: boolean
    default93: boolean
    defaultTeiki: boolean
}
interface LocalState {
    dialogElement: JSX.Element
    codes: Model.ServiceCode[]
    searchText: string
    check13: boolean
    check63: boolean
    check93: boolean
    checkTeiki: boolean
}

class ServiceCodeSelectDialog extends React.Component<Props, LocalState> {
    displayName = "ServiceCodeSelectDialog"
    constructor(props: Props) {
        super(props)
        this.state = {
            dialogElement: <div />,
            codes: [],
            searchText: "",
            check13: false,
            check63: false,
            check93: false,
            checkTeiki: false,
        }
    }

    public componentDidMount = () => {
        this.load();
        this.setState({
            check13: this.props.default13,
            check63: this.props.default63,
            check93: this.props.default93,
            checkTeiki: this.props.defaultTeiki
        })
    }

    private load() {
        HokanApi.getServiceCodes()
            .then(x => {
                this.setState({ codes: x.data })
            })
            .catch(err => {
                console.log(err)
                this.setState({ codes: [] })
            })
    }

    public close = () => {
        this.props.onCancel();
    }

    private selectCode(code: Model.ServiceCode) {
        this.props.onSelect(code);
    }

    private setCategory(check: string,) {
        const checks = {
            check13: false,
            check63: false,
            check93: false,
            checkTeiki: false
        }
        if (check == "13") {
            checks.check13 = true;
        }
        else if (check == "63") {
            checks.check63 = true;
        }
        else if (check == "93") {
            checks.check93 = true;
        }
        else if (check == "teiki") {
            checks.checkTeiki = true;
        }
        this.setState(checks)
    }

    public render() {
        const { classes } = this.props
        const st = this.state.searchText
        if (this.props.open == false) return <div />

        return <Dialog
            className={classes.dialog}
            onClose={(x) => this.close()}
            fullWidth={true}
            open={true}
            style={{ padding: "10px" }}>
            <DialogTitle>サービスコード選択</DialogTitle>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            value="13"
                            onChange={(e) => { this.setCategory("13") }}
                            checked={this.state.check13}
                            color="primary"
                        />
                    }
                    label="13:介護"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            value="63"
                            checked={this.state.check63}
                            onChange={(e) => { this.setCategory("63") }}
                            color="primary"
                        />
                    }
                    label="63:予防"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            value="定期"
                            onChange={(e) => { this.setCategory("teiki") }}
                            checked={this.state.checkTeiki}
                            color="primary"
                        />
                    }
                    label="定期"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            value="93"
                            checked={this.state.check93}
                            onChange={(e) => { this.setCategory("93") }}
                            color="primary"
                        />
                    }
                    label="93:療養費"
                />
            </FormGroup>
            <TextField
                id="standard-search"
                label="検索"
                type="search"
                value={this.state.searchText}
                onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                className={classes.searchField}
                margin="normal"
            />

            <Button variant="outlined" color="default"
                onClick={(e) => this.close()}>
                戻る
            </Button>
            <Table className={classes.table}>

                <TableHead>
                    <TableRow>
                        <CustomTableCell style={{ textAlign: "left", padding: "1px" }}>番号</CustomTableCell>
                        <CustomTableCell style={{ textAlign: "center", padding: "1px" }}>名前</CustomTableCell>
                        <CustomTableCell align="right"></CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.state.codes.filter(x => {
                            const hasTeiki = (x.name.indexOf("定期") >= 0)
                            const has13 = x.code_type == "13"
                            const has63 = x.code_type == "63"
                            const has93 = x.code_type == "93"

                            let f = false
                            if (this.state.checkTeiki && hasTeiki) {
                                f = true
                            }
                            else if (this.state.check13 && has13) {
                                f = true
                            }
                            else if (this.state.check63 && has63) {
                                f = true
                            }
                            else if (this.state.check93 && has93) {
                                f = true
                            }
                            if (!f) return false
                            return (!st || st == "" ||
                                x.name.indexOf(st) >= 0 ||
                                `${x.code_type}${x.code_no}`.indexOf(st) >= 0)
                        }).map(code => {
                            return <TableRow key={code.id} className={classes.row}
                                onClick={(e) => { this.selectCode(code) }}>
                                <CustomTableCell style={{ textAlign: "center", padding: "5px" }} component="th" scope="row">
                                    {code.code_type}{code.code_no}
                                </CustomTableCell>
                                <CustomTableCell style={{ textAlign: "left", padding: "5px" }} component="th" scope="row">
                                    {code.name}
                                </CustomTableCell>
                                <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </Dialog>
    }
}

export default Styles.withStyles(styles)(ServiceCodeSelectDialog)