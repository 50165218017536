import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../../components/Page'
import { Link } from 'react-router-dom';
import * as Model from '../../models'
import * as HokanApi from '../../api/hokan'
import { StoreAccessor } from '../../util/StoreUtil';
import { Badge, Avatar, Tooltip } from '@material-ui/core';
import AvatarStore from '../../firebase/AvatarStore'
import Accordion from '../elements/Accordion'

const styles = () => Styles.createStyles({
    rightNav: {
        position: "relative",
        height: "100%",
        margin: "0 10px 100px 5px",
        padding: "1px 0 55px 15px",
        boxSizing: "border-box",
        verticalAlign: "top",
        minWidth: "200px",
    },
    userReplyThumbnail: {
        borderRadius: "30px",
        width: "30px",
        height: "30px"
    },
    commentItemDt: {
        float: "left",
        overflow: "hidden"
    },
    activity_face: {
        width: "35px",
        height: "35px",
        borderRadius: "50px",
        objectFit: "contain" as any,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        display: "inline-flex",
        cursor: "pointer",
        fontSize: "1rem"
    }
});

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    message_id: number;
    is_wc: boolean;
    wc_number: number;
    wc_name: string;
}

interface LocalState {
    loading: boolean,
    branches: Model.Branch[],
    error: string | null,
    users: any[]
}

class MessageUserList extends React.Component<Props, LocalState> {

    displayName = "MessageUserList"
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            branches: [],
            error: null,
            users: []
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true })

        if(this.props.is_wc) {
            HokanApi.message.message_user_list_wc(this.props.wc_number)
            .then( x => {
                this.setState({ users: x.data, loading: false })
            })
            .catch ( err => {
                console.log(err)
            })
        }
        else {
            HokanApi.message.message_user_list(this.getId())
            .then( x => {
                this.setState({ users: x.data, loading: false })
            })
            .catch ( err => {
                console.log(err)
            })
        }
    }

    private getId() {
        return this.props.message_id
    }

    private loadAvatar(company_id, user_id) {
        AvatarStore.getUrl(company_id, user_id).then(url => {
            return url
        }).catch(err => {
            return undefined
        })
    }

    public render() {
        const {classes } = this.props
        const { users } = this.state
        return <div className={this.props.classes.rightNav}>
            <h3>スレッドのユーザ</h3>
            <Accordion>
            <ul style={{listStyle:"none", paddingLeft:"10px"}}>
                { users.map( (x) => {
                    return <li key={x.id}>
                    <dt className={this.props.classes.commentItemDt}>
                        <Avatar
                            onClick={(e) => {
                                const el = document.getElementById("edit_own_avatar") as any
                                if (el) el.click()
                            }}
                            alt="avatar" src={undefined}
                            className={classes.activity_face}
                            style={{
                                color: '#fff',
                                backgroundColor: `#hsl(${x ? x.id * 130 : 0}, 50%, 80%)`,
                            }}
                        >{ x ? x.name.substr(0, 2) : null}</Avatar>
                    </dt>
                    <dd>
                        <div className="comment_status_txt">
                            <div className="comment_header">
                                <div className="cs_user_name">
                                    <a>{x.name != "" ? x.name : "No Name" }</a>
                                </div>
                                <span style={{fontSize:"11px"}} className="user_title">{x.role}</span>
                            </div>
                        </div>
                    </dd>
                </li>
                })}
            </ul>
            </Accordion>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(MessageUserList))