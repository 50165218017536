import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class HihuBredenBehaviour extends FormBehaviour.PdfBehaviour { 
	displayName="HihuBredenBehaviour"

    public getId(): number {
        return 11
    }

    public createContent(): any {
        const res = new FormModel.HihuBreden.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    private calcSum(data) {
        return Math.max(data.values.score_ninchi, 0) +
            Math.max(data.values.score_shitsujun, 0) +
            Math.max(data.values.score_katsudou, 0) +
            Math.max(data.values.score_kadou, 0) +
            Math.max(data.values.score_eiyou, 0) +
            Math.max(data.values.score_zure, 0)
    }

    public beforePost_pdf(doc:Model.Document.Document) {
        doc.content.values.sum_score = this.calcSum(doc.content)
    }

    public beforeMount = () => {
    }

    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>・ブレーデンスケール：Copyright：Braden and Bergstrom. 1988, 訳：真田弘美（東京大学大学院医学系研究科）/大岡みち子（North West Community Hospital. IL. USA）</div>
                       </div>
    }
    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content", "values"])


        const elements_scores = [
            {
                name: "知覚の認知", content: saV.listRaw("score_ninchi", [
                    { key: "未選択", value: -1 },
                    { key: "１：全く知覚なし", value: 1 },
                    { key: "２：重度の障害あり", value: 2 },
                    { key: "３：軽度の障害あり", value: 3 },
                    { key: "４：障害なし", value: 4 },
                ])
            },
            {
                name: "湿潤", content: saV.listRaw("score_shitsujun", [
                    { key: "未選択", value: -1 },
                    { key: "１：常に湿っている", value: 1 },
                    { key: "２：大抵湿っている", value: 2 },
                    { key: "３：時々湿っている", value: 3 },
                    { key: "４：滅多に湿っていない", value: 4 },
                ])
            },
            {
                name: "活動性", content: saV.listRaw("score_katsudou", [
                    { key: "未選択", value: -1 },
                    { key: "１：臥床", value: 1 },
                    { key: "２：座位可能", value: 2 },
                    { key: "３：時々歩行", value: 3 },
                    { key: "４：歩行可能", value: 4 },
                ])
            },
            {
                name: "可動性", content: saV.listRaw("score_kadou", [
                    { key: "未選択", value: -1 },
                    { key: "１：全く体動なし", value: 1 },
                    { key: "２：非常に限られる", value: 2 },
                    { key: "３：やや限られる", value: 3 },
                    { key: "４：自由に体動する", value: 4 },
                ])
            },
            {
                name: "栄養状態", content: saV.listRaw("score_eiyou", [
                    { key: "未選択", value: -1 },
                    { key: "１：不良", value: 1 },
                    { key: "２：やや不良", value: 2 },
                    { key: "３：良好", value: 3 },
                    { key: "４：非常に良好", value: 4 },
                ])
            },
            {
                name: "摩擦とずれ", content: saV.listRaw("score_zure", [
                    { key: "未選択", value: -1 },
                    { key: "１：問題あり", value: 1 },
                    { key: "２：潜在的に問題あり", value: 2 },
                    { key: "３：問題なし", value: 3 },
                ])
            },
            {
                name: "合計点", content: <div>{this.calcSum(doc.content)}</div>
            }
        ]

        const elements_images = [
            {
                name: "画像(最大４枚)", content: sa.docImage(4)
            }
        ]


        const elements = [
            { name: '評価', content: elements_scores },
            { name: '画像', content: elements_images }
        ]

        return elements
    }
}
