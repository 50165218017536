import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class BodyBMSBehaviour extends FormBehaviour.PdfBehaviour {
    displayName = "StasJBehaviour"

    private stasj_list = [
        [
            "0:なし",
            "1:時折の、または断続的な単一の痛みで、患者が今以上の治療を必要としない痛みである。",
            "2:中程度の痛み。時に調子の悪い日もある。痛みのため、病状からみると可能なはずの日常生活動作に支障をきたす。",
            "3:しばしばひどい痛みがある。痛みによって日常生活動作や物事への集中力に著しく支障をきたす。",
            "4:持続的な耐えられない激しい痛み。他のことを考えることができない。 ",
        ],
        [
            "0:なし",
            "1:時折の、または断続的な単一または複数の症状があるが、日常生活を普通に送っており、患者が今以上の治療を必要としない症状である。",
            "2:中等度の症状。時に調子の悪い日もある。病状からみると、可能なはずの日常生活動作に支障をきたすことがある。",
            "3:たびたび強い症状がある。症状によって日常生活動作や物事への集中力に著しく支障をきたす。",
            "4:持続的な耐えられない激しい症状。他のことを考えることができない。 ",
        ],
        [
            "0:なし",
            "1:変化を気にしている。身体面や行動面に不安の兆候は見られない。集中力に影響はない。",
            "2:今後の変化や問題に対して張り詰めた気持ちで過ごしている。時々、身体面や行動面に不安の徴候が見られる。",
            "3:しばしば不安に襲われる。身体面や行動面にその徴候が見られる。物事への集中力に著しく支障をきたす。",
            "4:持続的に不安や心配に強くとらわれている。他のことを考えることができない。",
        ],
        [
            "0:なし",
            "1:変化を気にしている。身体面や行動面に不安の徴候は見られない。集中力に影響はない。",
            "2:今後の変化や問題に対して張り詰めた気持ちで過ごしている。時々、身体面や行動面に不安の徴候が見られる。",
            "3:しばしば不安に襲われる。身体面や行動面にその徴候が見られる。物事への集中力に著しく支障をきたす。",
            "4:持続的に不安や心配に強くとらわれている。他のことを考えることができない。 ",
        ],
        [
            "0:予後について十分に認識している。",
            "1:予後を２倍まで長く、または短く見積もっている。例えば、２－３ヶ月であろう予後を６ヶ月と考えている。",
            "2:回復すること、または長生きすることに自信が持てない。例えば「この病気で死ぬ人もいるので、私も 近々そうなるかもしれない」と思っている。",
            "3:非現実的に思っている。例えば、予後が３ヶ月しかない時に、１年後には普通の生活や仕事に復帰できると期待している。",
            "4:完全に回復すると期待している。",
        ],
        [
            "0:予後について十分に理解している。",
            "1:予後を２倍まで長く、または短く見積もっている。例えば、２－３ヶ月であろう予後を６ヶ月と考えている。",
            "2:回復すること、または長生きすることに自信が持てない。例えば「この病気で死ぬ人もいるので、本人も近々そうなるかも知れない」と思っている。",
            "3:非現実的に思っている。例えば、予後が３ヶ月しかない時に、１年後には普通の生活や仕事に復帰できると期待している。",
            "4:患者が完全に回復することを期待している。",
        ],
        [
            "0:率直かつ誠実なコミュニケーションが、言語的・非言語的になされている。",
            "1:時々、または家族の誰かと率直なコミュニケーションがなされている。",
            "2:状況を認識してはいるが、その事について話し合いがなされていない。患者も家族も現状に満足していない。あるいは、パートナーとは話し合っても、他の家族とは話し合っていない。",
            "3:状況認識が一致せずコミュニケーションがうまくいかないため、気を使いながら会話が行われている",
            "4:うわべだけのコミュニケーションがなされている。 ",
        ],
        [
            "0:詳細かつ正確な情報が関係スタッフ全員にその日のうちに伝えられる。",
            "1:主要スタッフ間では正確な情報伝達が行われる。その他のスタッフ間では、不正確な情報伝達や遅れが生じることがある。",
            "2:管理上の小さな変更は、伝達されない。重要な変更は、主要スタッフ間でも１日以上遅れて伝達される。",
            "3:重要な変更が数日から１週間遅れで伝達される。例）退院時の病棟から在宅担当医への申し送りなど。",
            "4:情報伝達がさらに遅れるか、全くない。他のどのようなスタッフがいつ訪ねているのかわからない。",
        ],
        [
            "0:すべての情報が提供されている。患者や家族は気兼ねなく尋ねることができる。",
            "1:情報は提供されているが、充分理解されてはいない。",
            "2:要求に応じて事実は伝えられるが、患者や家族はそれより多くの情報を望んでいる可能性がある。",
            "3:言い逃れをしたり、実際の状況や質問を避けたりする。",
            "4:質問への回答を避けたり、訪問を断る。正確な情報が与えられず、患者や家族を悩ませる "
        ]
    ].map(s => { return s.map(t => { return {key:t, value:t}})})

    public getId(): number {
        return 62
    }

    public createContent(): any {
        const res = new FormModel.StasJ.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>・STAS-J：日本緩和医療学会：がん疼痛の薬物療法に関するガイドライン（2014年版） ．STAS-J．p31，金原出版，2014</div>
        </div>
    }
    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.StasJ.Core
    }

    public beforeMount = () => {

    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const elements_scores = [
            {
                name: "1.痛みのコントロール", content:
                    saV.listRaw("stasj_s1", this.stasj_list[0], true, true)
            },
            {
                name: "2.症状が患者に及ぼす影響", content:
                    saV.listRaw("stasj_s2", this.stasj_list[1], true, true)
            },
            {
                name: "3.患者の不安", content:
                    saV.listRaw("stasj_s3", this.stasj_list[2], true, true)
            },
            {
                name: "4.家族の不安", content:
                    saV.listRaw("stasj_s4", this.stasj_list[3], true, true)
            },
            {
                name: "5.患者の病状認識", content:
                    saV.listRaw("stasj_s5", this.stasj_list[4], true, true)
            },
            {
                name: "6.家族の病状認識", content:
                    saV.listRaw("stasj_s6", this.stasj_list[5], true, true)
            },
            {
                name: "7.患者と家族のコミュニケーション", content:
                    saV.listRaw("stasj_s7", this.stasj_list[6], true, true)
            },
            {
                name: "8.職種間のコミュニケーション", content:
                    saV.listRaw("stasj_s8", this.stasj_list[7], true, true)
            },
            {
                name: "9.患者・家族に対する医療スタッフのコミュニケーション", content:
                    saV.listRaw("stasj_s9", this.stasj_list[8], true, true)
            },
            { name: "備考", content: saV.text("comment") }
        ]

        const elements = [
            { name: '評価', content: elements_scores }
        ]

        return elements
    }
}
