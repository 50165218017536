import { Patient } from './Patient';
import { KirokuType, Basic, DoukouUser } from './KangoKirokuII';
import DateUtil from '../util/DateUtil'
import { SimpleUser } from './Company';
import moment from 'moment';

export class KangoKirokuDoukou {
    public id: number = 0;
    public patient: Patient = new Patient()
    public kiroku_type = KirokuType.None
    public time_start: string = DateUtil.currentDateTimeStr()
    public time_end: string = DateUtil.currentDateTimeStr()
    public main_user: SimpleUser = new SimpleUser()
    public basic: Basic = new Basic()
    public doukou: DoukouShinchoku[] = []

    public static Load(apiRes: any) {
        const parse = (ar, def) => {
            if (ar) return JSON.parse(ar) || def
            return def
        }

        let res = new KangoKirokuDoukou();
        res.id = apiRes.id;
        res.patient = apiRes.patient ? Patient.load(apiRes.patient, false) : new Patient()
        res.kiroku_type = apiRes.kiroku_type;
        const s = moment(apiRes.time_start)
        const t = moment(apiRes.time_end)
        res.time_start = s ? s.format("YYYY-MM-DDTHH:mm") : ""
        res.time_end = t ? t.format("YYYY-MM-DDTHH:mm") : ""
        res.main_user = apiRes.main_user;
        res.basic = parse(apiRes.basic, new Basic());
        res.doukou = apiRes.doukou.map(d => {
            d.content = parse(d.content, new DoukouShinchoku())
            if (!d.content.times) d.content.times = null
            return d;
        })

        const createDefault = (u:SimpleUser) => {
            if (!u || !u.id) return;
            const ed = res.doukou.find(x => x.user.id == u.id)
            if (!ed) {
                const nd = new DoukouShinchoku()
                nd.user = u;
                nd.kango_kiroku_id = res.id;
                res.doukou.push(nd)
            }
        }
        if (res.basic) {
            if (res.basic.doukou_list) {
                res.basic.doukou_list.forEach(d => {
                    if (d.user && d.step != "教育以外") createDefault(d.user)
                })
            }
            if (res.basic.doukou_edu) {
                res.basic.doukou_edu.forEach(d => {
                    if (d.user) createDefault(d.user)
                })
            }
        }

        res.doukou.forEach(d => {
            let ed:any = null;
            if (res.basic.doukou_list) {
                ed = res.basic.doukou_list.find(x => x.user.id == d.user.id && d.step != "教育以外")
            }
            if (!ed && res.basic.doukou_edu) {
                ed = res.basic.doukou_edu.find(x => x.user.id == d.user.id)
            }
            if (ed) {
                d.step = ed.step
            }
        })

        return res;
    }
}

export class DoukouShinchoku {
    public id: number = 0;
    public step: string = ""
    public kango_kiroku_id: number = 0;
    public user: SimpleUser = new SimpleUser()
    public times: number | null = null
    public content: string | DoukouShinchokuContent = new DoukouShinchokuContent()
    public tandoku_ok: boolean = false
}

export class DoukouShinchokuContent {
    public naiyou: string = ""
    public shugi: string = ""
    public kadai: string = ""
    public hurikaeri: boolean = false
    public doukou_hyouka: string = ""
    public doukou_comment: string = ""
}

export class DoukouShinchokuPost {
    public id: number | null = null;
    public user_id: number = 0;
    public kango_kiroku_id: number = 0;
    public times: number = 0
    public content: string = "{}"
    public tandoku_ok: boolean = false

    public constructor(src:DoukouShinchoku) {
        this.id = src.id
        this.user_id = src.user.id
        this.kango_kiroku_id = src.kango_kiroku_id
        this.times = src.times || 0
        this.tandoku_ok = src.tandoku_ok
        this.content = JSON.stringify(src.content)
    }
}
