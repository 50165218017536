import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SymptomList from '../components/assessment/SymptomList'
import CreateStepper from '../components/assessment/CreateStepper'
import Kbs from '../components/assessment/Kbs'
import Intervention from '../components/assessment/Intervention'
import Confirm from '../components/assessment/Confirm'
import Modifier from '../components/assessment/Modifier'
import { toInjector, Problem } from '../stores';
import { inject, observer } from 'mobx-react';
import * as Hash from '../components/assessment/Hash'
import * as Model from '../models';
import * as HokanApi from '../api/hokan'
import Loading from '../components/Loading';
import { StoreAccessor } from '../util/StoreUtil';

const styles = () => Styles.createStyles({
    root: {

    },
    info: {
        margin: "10px",
        fontSize: "0.9rem"
    }
});

interface Params {
    patient_id: string
    problem_id: string
    ass_id: string
}

interface Props extends Problem, PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    input: Model.Assessment.PostAssessment
    loading: boolean
    patient: Model.Patient.Patient
}

@inject(...toInjector(Problem))
@observer
class CreateProblemPage extends React.Component<Props, LocalState> {
    displayName = "CreateProblemPage"
    constructor(props: Props) {
        super(props)
        const st = new Model.Assessment.PostAssessment()
        st.assessment.problem_id = this.problemId;
        st.assessment.next_assessment_day = st.assessment.assessment_day;
        this.state = {
            input: st,
            loading: true,
            patient: new Model.Patient.Patient()
        }
    }

    public componentDidMount() {
        if (this.assId) {
            HokanApi.getAssessment(this.patientId, this.problemId, this.assId).then(x => {
                const inp = Model.Assessment.PostAssessment.copy(x.data)
                this.setState({ loading: false, input: inp });
            })
        }
        else {
            HokanApi.getPatientAssessments(this.patientId).then(x => {
                const problem_id = this.problemId
                const ass = x.data.find(y => y.problem.id == problem_id)!.newest
                const inp = Model.Assessment.PostAssessment.copy(ass)
                this.setState({ loading: false, input: inp });
            })
        }

        HokanApi.getPatient(this.patientId).then(x => {
            this.setState({ patient: x.data })
        })
    }

    private selectSymptom(symptom_ids: number[], free_symptoms: string[]) {
        const inp = this.state.input
        inp.symptoms = symptom_ids;
        inp.free_symptoms = free_symptoms;
        this.setState({ input: inp });
    }

    private selectIntervention(ids: { id: number, comment: string }[]) {
        const inp = this.state.input
        inp.interventions = ids.map(id => new Model.Assessment.PostIntervention(id.id, id.comment));
        this.setState({ input: inp });
    }
    private changeIntervention(id: number, check: boolean, comment: string) {
        const inp = this.state.input
        if (check) {
            const oi = inp.interventions.findIndex(x => x.id == id)
            if (oi == -1) inp.interventions.push(new Model.Assessment.PostIntervention(id, comment))
            else inp.interventions[oi].comment = comment
        }
        else {
            inp.interventions = inp.interventions.filter(x => x.id != id)
        }

        this.setState({ input: inp });
    }

    private changeAssessment(func: (ass: Model.Assessment.Assessment) => void) {
        const inp = this.state.input
        func(inp.assessment);
        this.setState({ input: inp });
    }

    private gotoPage(hash: string) {
        const t = "create_problem"
        if (this.assId) {
            this.props.history.push(`/patients/${this.patientId}/${t}/${this.problemId}/${this.assId}${hash}`)
        }
        else {
            this.props.history.push(`/patients/${this.patientId}/${t}/${this.problemId}${hash}`)
        }
    }

    private get assId() {
        console.log(this.props.match.params)
        if (!this.props.match.params.ass_id) return 0
        return parseInt(this.props.match.params.ass_id)
    }

    private get patientId() {
        return parseInt(this.props.match.params.patient_id)
    }

    private get problemId() {
        return parseInt(this.props.match.params.problem_id)
    }

    private get hasNoProblemId() {
        return isNaN(this.problemId)
    }

    private clickStep(hash: string) {
        const t = "create_problem"
        if (this.assId) {
            this.props.history.push(`/patients/${this.patientId}/${t}/${this.problemId}/${this.assId}${hash}`)
        }
        else {
            this.props.history.push(`/patients/${this.patientId}/${t}/${this.problemId}${hash}`)
        }
    }

    private commit() {
        const ass = this.state.input.assessment
        if (!ass.next_assessment_day || ass.closed) {
            ass.next_assessment_day = Model.Assessment.NullAssessmentNextDay
        }
        if (this.assId) {
            HokanApi.putAssessment(this.patientId, this.state.input)
                .then(x => {
                    this.props.history.push(`/patients/${this.patientId}#problem`)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        else {
            HokanApi.postAssessment(this.patientId, this.state.input)
                .then(x => {
                    this.props.history.push(`/patients/${this.patientId}#problem`)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    public render() {
        const { classes, location } = this.props

        if (this.state.loading) return <Loading />

        const problem_id = this.problemId
        const isFirstPage = this.hasNoProblemId
        const sa = new StoreAccessor(this, true, "input")
        //const problemComponent = <ProblemList patient_id={this.patientId} selectProblem={(pid) => this.selectProblem(pid)} />
        const symptomComponent = <SymptomList ass={this.state.input} problem_id={problem_id} gotoPage={(h) => this.gotoPage(h)} selectSymptom={(ids: number[], fs: string[]) => this.selectSymptom(ids, fs)} />
        const modifierComponent = <Modifier ass={this.state.input} problem_id={problem_id} gotoPage={(h) => this.gotoPage(h)} changeAssessment={(func) => this.changeAssessment(func)} />
        const kbsComponent = <Kbs ass={this.state.input} problem_id={problem_id} gotoPage={(h) => this.gotoPage(h)} changeAssessment={(func) => this.changeAssessment(func)} />
        const interventionComponent = <Intervention ass={this.state.input} problem_id={problem_id} gotoPage={(h) => this.gotoPage(h)}
            selectedInterventions={this.state.input.interventions} selectIntervention={(ids) => this.selectIntervention(ids)}
            changeIntervention={(i, ch, cm) => this.changeIntervention(i, ch, cm)} />
        const confirmComponent = <Confirm problem_id={problem_id} gotoPage={(h) => this.gotoPage(h)} postAssessment={this.state.input} commit={() => this.commit()} sa={sa} />

        let steps = [
            { no: 0, label: "症状兆候", hash: Hash.Symptom, component: symptomComponent },
            { no: 1, label: "修飾因子", hash: Hash.Modifier, component: modifierComponent },
            { no: 2, label: "KBS", hash: Hash.Kbs, component: kbsComponent },
            { no: 3, label: "計画介入", hash: Hash.Intervention, component: interventionComponent },
            { no: 4, label: "確認", hash: Hash.Confirm, component: confirmComponent },
        ]

        const current = (isFirstPage) ? steps[0] : (steps.find(st => (location.hash == st.hash)) || steps[0])

        return <div className={classes.root}>
            <div className={classes.info}>
                <div>
                    {this.state.patient.name}
                </div>
                <div>
                    {this.props.problem!.problem ? this.props.problem!.problem.name : ""}
                </div>
            </div>
            <CreateStepper step={current.no} steps={steps}
                isFirstPage={isFirstPage}
                clickStep={(hash: string) => this.clickStep(hash)} />
            <div>
                {current.component}
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(CreateProblemPage))