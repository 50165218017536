export class Talk {
    public id: number = 0
    public name: string = ""
    public talk_type: number = 0
    public wc_number: number = 0
    public wc_company_id: number = 0
    public wc_branch_id: number = 0
    public user_id:number = 0
    public wc_user_id:number = 0
}

export class CreateTalk {
    public name: string = ""
    public users: string[] = []
}

export class CreateWcTalk {
    public name: string = ""
    public wc_number: number = 0
    public branch_id: number = 0
}

export class UpdateTalk {
    public is_visible: boolean = true;
}

export class UpdateWcTalk {
    public is_visible: boolean = true;
    public wc_patient_branch_id: number = 0;
}

export class Message {
    public id: number = 0
    public content: string = ""
    public user_id: number = 0
}

export class CreateMessage {
    public content: string = ""
    public reply: string = ""
    public is_task: boolean = false
    public task_datetime: string = ""
    public task_date: string = ""
    public attachments: Attachment[] = []
    public emergency_flg: boolean = false
}

export class UpdateTask {
    public status: boolean = false
    public content: string = ""
    public task_date: string = ""
}
export class UpdateMessage {
    public content: string = ""
}

export class Attachment {
    public name: string = ""
    public content_type: string = ""
    public src_name: string = ""
}

export class WebPushInfo {
    public endpoint: string = ""
    public auth: string = ""
    public p256dh: string = ""
}
