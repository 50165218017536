import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour   { 
	displayName="Behaviour"
    
    public getId(): number {
        return 13
    }

    public createContent(): any {
        const res = new FormModel.KokyuMRC.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }


    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }

    public shutten() {
    return <div className="shutten">
        <div>=== 出典 ===</div>
        <div>・mMRC：The Global Strategy for the Diagnosis, Managementand Prevention of COPD, Global Initiative for Chronic ObstructiveLung Disease (GOLD)をもとに翻訳</div>
 </div>
}
    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content", "values"])

        const elements_scores = [
            {
                name: "mMRC", content: saV.listRaw("score_mrc", [
                    { key: "未選択", value: -1 },
                    { key: "０：激しい運動をしたときだけに息切れがある", value: 0 },
                    { key: "１：平坦な道を足早で歩く、あるいは緩やかな上り坂を歩く時に息切れがある。", value: 1 },
                    { key: "２：息切れがあるので、同年代の人より平坦な道を歩くのが遅いあるいは、平坦な道を自分のペースで歩いている時、息切れのために立ち止まることがある", value: 2 },
                    { key: "３：平坦な道を約100m、あるいは数分歩くと息切れのために立ち止まる。", value: 3 },
                    { key: "４：息切れがひどく家から出られない。あるいは、衣服の着替えをする時にも息切れがある。", value: 4 },
                ], true, true)
            }
        ]

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
