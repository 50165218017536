
export let APIHost = "http://localhost:3000"
export let MessageAPIHost = "http://localhost:3030"

// AWS Cognit関連の情報
// TODO: 下記swtchで環境ごとに値を切り替える
export let AuthenticateServer = {
    UserPoolId: 'ap-northeast-1_n79khk9NI',
    ClientId: '79m83ok91dca9lq2a12ctd4fn',
    IdentityPoolId: 'ap-northeast-1:703e8b9a-c36e-493b-b17e-584a990990be',
    Region : 'ap-northeast-1'
}

switch (process.env.NODE_ENV) {
    case 'production':
        if (process.env.REACT_APP_ENV == "staging") {
            APIHost = 'https://api-test.homisnursee.com/';
            AuthenticateServer = {
                UserPoolId: 'ap-northeast-1_aMn0Vy54P',
                ClientId: '1pemlm60m3e1hia9b1es9carh8',
                IdentityPoolId: 'ap-northeast-1:703e8b9a-c36e-493b-b17e-584a990990be',
                Region : 'ap-northeast-1'
            };
            MessageAPIHost = 'https://msg-test.homisnursee.com';
        } else {
            APIHost = 'https://api.homisnursee.com/';
            AuthenticateServer = {
                UserPoolId: 'ap-northeast-1_ucPsAWlBs',
                ClientId: '72pecg9ec6m770b6otgbdm7u40',
                IdentityPoolId: 'ap-northeast-1:703e8b9a-c36e-493b-b17e-584a990990be',
                Region : 'ap-northeast-1'
            };
            MessageAPIHost = 'https://msg.homisnursee.com';
        }
        break;
    default:
        APIHost = 'http://localhost:3000';
        MessageAPIHost = 'http://localhost:3030';
}

export const CurrentApiVersion = "v1"
export const ApiUrl = (str: string, version: string = CurrentApiVersion) => {
    return `${APIHost}/api/${version}/` + str;
}

export const MessageApiUrl = (str: string, version: string = CurrentApiVersion) => {
    return `${MessageAPIHost}/api/${version}/${str}`;
}

export const ApiUrlRaw = (str: string) => {
    return `${APIHost}/` + str;
}

export const AuthUrl = () => {
    return ApiUrlRaw("oauth/token")
}
export const CustomTokenhUrl = () => {
    return ApiUrl("custom_token")
}

export const PasswordResetUrl = () => {
    return ApiUrlRaw("password_reset/reset")
}