import * as React from 'react';
import * as Colors from './Colors'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import * as Icons from '@material-ui/icons';
import * as Styles from '@material-ui/core/styles';
import { Spring } from 'react-spring/renderprops'
import { Badge } from '@material-ui/core';

const styles = () => Styles.createStyles({
    footerNavigation: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        //backgroundColor: Colors.barColor,
        backgroundColor: Colors.white,
        height: "50px",
        zIndex: 5
    },
    footerNavigationAction: {
        minWidth: "30px",
        color: Colors.white,
    },
    footerSelectedAction: {
        minWidth: "30px",
        color: Colors.orangeColor + " !important",
    }
})

interface Props extends Styles.WithStyles<typeof styles> {
    index: number
    handleChange: (e: any, value: number) => void
    showNotifyActivity: boolean
}

class FooterNavigation extends React.Component<Props> {
    displayName = "FooterNavigation"
    /*public state = {
        value: 0,
    };*/

    /*private handleChange = (e:any, value:number) => {
        this.setState({ value });
    };*/

    public render() {
        const index = this.props.index;
        const { classes } = this.props;
        const cs = {
            root: classes.footerNavigationAction,
            selected: classes.footerSelectedAction
        }
        const st = {
            padding: "3px"
        }
        return <BottomNavigation
            value={index}
            onChange={this.props.handleChange}
            showLabels={true}
            className={classes.footerNavigation}
        >
            <BottomNavigationAction classes={cs} label="" style={st} icon={
                <Badge badgeContent={this.props.showNotifyActivity ? ' ' : 0}
                    color={"secondary"}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <img width="35px" height="35px" src={`/images/icons/activ_icon${index == 0 ? "_o" : "_bl"}.png`} />
                </Badge>
            } />
            <BottomNavigationAction classes={cs} label="" style={st} icon={<img width="25px" height="25px" src={`/images/icons/基本${index==1?"_o":"_bl"}.png`}/>} />
            <BottomNavigationAction classes={cs} label="" style={st} icon={<img width="25px" height="25px" src={`/images/icons/スコア${index == 2 ? "_o" : "_bl"}.png`} />} />
            <BottomNavigationAction classes={cs} label="" style={st} icon={<img width="35px" height="35px" src={`/images/icons/omaha_2${index == 3 ? "_o" : "_bl"}.png`} />} />
            <BottomNavigationAction classes={cs} label="" style={st} icon={<img width="25px" height="25px" src={`/images/icons/カレンダー${index == 4 ? "_o" : "_bl"}.png`} />} />
        </BottomNavigation>
    }
}

export default Styles.withStyles(styles)(FooterNavigation);