import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 70;
  }

  public createContent(): any {
    const res = new FormModel.RespiratorySystemA.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {}

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);
    const hontai_q_list = [
      "電源はコンセントからとり、電源コードはタコ足配線になっていない",
      "（呼吸器・酸素濃縮器・加温加湿器）プラグがコンセントに確実に入っている",
      "（呼吸器・酸素濃縮器・加温加湿器）電源コードに余裕があり、ベットやワゴンではさまれていない",
      "呼吸器本体が専用で安定した場所に設置している",
      "酸素チューブに接続や異常がない",
      "呼吸器本体の電源が入っており、モニターが作動している",
      "呼吸器本体の設定にロックがかかっている",
      "外部バッテリーが充電されている",
      "加温加湿器のポートがはまっている",
      "加温加湿器ポートに「蒸留水のみを入れる」注意書きが明示されている",
      "加温加湿器ポートに蒸留水が補充されている",
      "指示書、医療処置管理票、メーカーのアラーム対応表がワゴンにかけられている",
      "（呼吸器・酸素濃縮器・加温加湿器）設定が指示通りである",
    ];

    const kairo_q_list = [
      "回路は決められた方法で固定している",
      "回路に圧迫・屈曲・ねじれ・ゆるみ・くせがない",
      "回路に亀裂や穴が開いていない・欠陥がない",
      "衣類・掛け物などで呼気弁がふさがれていない",
      "圧ラインチューブやフローセンサーが上向きに置かれている",
      "回路に水滴・分泌物が付着していない",
      "ウォータートラップの水がたまっていない",
      "加温加湿の設定が指示通りである",
      "ベットやその周囲が乱雑となっていない",
    ];

    const kikan_q_list = [
      "回路より気管カニューレが上方に位置している",
      "気管カニューレが回路で引っぱられていない",
      "カフエアが指示通りの量である",
      "カフエアの確認が決められたとおり行われている",
      "吸引カテーテル挿入時の違和感や困難感、出血等の異常がない",
      "肉芽による出血や、気管孔からの狭窄音がない",
      "気管カニューレが決められた方法で固定されている",
      "気管カニューレの固定方法・固定物品が適している",
      "【カニューレホルダーの場合】マジックバンドがネックバンドに確実に貼りついている",
      "【綿ひもの場合】綿ひもの結び目が緩んでおらず固結びになっている",
    ];

    const elements_t = [
      { name: "作成者", content: saV.text("created_by", "") },
      {
        name: "呼吸器本体",
        content: (
          <div>
            {hontai_q_list.map((q, i) => {
              return (
                <>
                  <hr style={{ margin: 0 }} />
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content"
                    }}
                  >
                    {saC.check(`check_hontai_${i + 1}`)}
                    {q}
                  </label>
                </>
              );
            })}
          </div>
        ),
      },
      {
        name: "呼吸器回路",
        content: (
          <div>
            {kairo_q_list.map((q, i) => {
              return (
                <>
                  <hr style={{ margin: 0 }} />
                  <label
                    key={q}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content"
                    }}
                  >
                    {saC.check(`check_kairo_${i + 1}`)}
                    {q}
                  </label>
                </>
              );
            })}
          </div>
        ),
      },
      {
        name: "気管カニューレ",
        content: (
          <div>
            {kikan_q_list.map((q, i) => {
              return (
                <>
                  <hr style={{ margin: 0 }} />
                  <label
                    key={q}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content"
                    }}
                  >
                    {saC.check(`check_kikan_${i + 1}`)}
                    {q}
                  </label>
                </>
              );
            })}
          </div>
        ),
      },
      {
        name: "自由記載",
        content: saV.text("free_text", "", "text", "block", true, "100px"),
      }
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
