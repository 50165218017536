
import * as React from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

export interface PatientMapProps {
    branch_id: number | null,
    score: any,
    google: any
}
class PatientMapMarker extends React.Component<any> {
    render() {
        return (
            <div>hello</div>
        )
    }
}
class GMap extends React.Component<PatientMapProps> {

    render() {
        if (!this.props.score || !this.props.score || !this.props.score.length) return <div />
        const sc = this.props.score.filter(x => x.lat != null)
        if (!sc.length) return <div />
        const initCenter = {lat: sc[0].lat, lng:sc[0].lon}
        return (
            <div style={{height:"300px", width: "100%", margin:"30px"}}>
                <Map google={this.props.google}  style={{
                    width: '80%',
                    height: '600px'
                }} 
                    initialCenter={initCenter}
                >
                    {
                        sc.map((g,gi) => {
                            return <Marker
                                key={`marker_${gi}`}
                                title={g.name}
                                opacity={g.branch_id == this.props.branch_id ? 1.0 : 0.3}
                                position={{lat:g.lat, lng:g.lon}}
                            />

                        })
                    }
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyCwmRJF6k4_Cw4kNmCAvncRefEWapjHJR4"),
    language: "ja"
})(GMap)
