import 'reflect-metadata';
import UserStore from './UserStore'
import CountStore from './CountStore'
import AuthStore from './AuthStore';
import ProblemStore from './ProblemStore'
import GoogleCalendarStore from './GoogleCalendarStore';

// ------------------------------------
// props definition
// ------------------------------------
// Store と一対一.
// 各 props は結合されて使われるので、
// 相当意図的でない限り、名前はユニークに.
//
// interface Props extends Count, User { }
// みたいに使われる
// ------------------------------------
export class Count {
    public count?: CountStore = new CountStore()
}

export class User {
    public user?: UserStore = new UserStore()
}
export class Auth {
    public auth?: AuthStore = new AuthStore()
}
export class Problem {
    public problem?: ProblemStore = new ProblemStore()
}

export class GoogleCalendar{
    public googleCalendar?:GoogleCalendarStore = new GoogleCalendarStore()
}

// ------------------------------------
// stores list
// ------------------------------------
// store に追加したらここに
// 閉じて使う分には追加不要
// ------------------------------------

export const AllStore = CreateStores(
    User,
    Count,
    Auth,
    Problem,
    GoogleCalendar
)




// *********************************
// これより下は普段は見なくていい
// *********************************


// -----------------------------------------
// Util
// -----------------------------------------

// -----------------------------------------
// ex. @injcetor(...toInjector(User,Count))
// -----------------------------------------
export function toInjector(...list: any[]) {
    const res: string[] = []
    list.forEach(c => {
        const x = new c()
        const names = Object.getOwnPropertyNames(x)
        names.forEach(name => {
            res.push(name)
        })
    })
    return res
}


// ------------------------------------
// 実装用
// ------------------------------------

function CreateStores(...list: any[]) {
    const res = {}
    list.forEach(c => {
        const x = new c()
        const names = Object.getOwnPropertyNames(x)
        names.forEach(name => {
            const desc = Object.getOwnPropertyDescriptor(x, name)
            res[name] = desc!.value
        })
    })
    return res
}
