import Encoding from 'encoding-japanese';

export class CodeUtil {

    public static ToSJisList(data: string[]) {
        return data
        if (!data) return null
        return data.map(d => CodeUtil.ToSJis(d))
    }
    public static ToSJis(data: string) {
        return data
        const codes = new TextEncoder().encode(data);
        const encoding = Encoding.detect(codes);
        const unicodeString = Encoding.convert(codes, {
            to: 'sjis',
            from: encoding,
            type: 'string',
        });
        return unicodeString
    }
}
export default CodeUtil;