import * as React from 'react';
import { Auth, toInjector } from '../stores';
import { inject, observer } from 'mobx-react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Colors from '../components/Colors'
import Fab from '@material-ui/core/Fab';
import * as Icons from '@material-ui/icons';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import { Button } from '@material-ui/core';
const styles = () => Styles.createStyles({
    root: {
        padding: "10px",
        textAlign: "center"
    },
    container: {
        maxWidth: "500px",
        display: "inline-block"
    },
    textField: {
        width: "100%",
        "& label": {
            color: Colors.primaryColor,
        },
        "& div": {
            "& fieldset": {
                borderRadius: "10px",
                borderColor: `${Colors.borderColor} !important`
            },
            "& input": {
                color: Colors.inputColor,
                padding: "18px 18px"
            }
        }
    },
    button: {
        width: "100%",
        marginTop: "50px"
    },
    message: {
        color: Colors.primaryColor
    },
    logo: {
        width: "50%",
        maxWidth: "200px",
        margin: "20px"
    }
});

interface Props extends Auth, PageProps, Styles.WithStyles {
    message: string
}

@inject(...toInjector(Auth))
@observer
class LoginPage extends React.Component<Props, {
    showPassword: boolean, errmsg: string,
    mfa: boolean, mfa_to: string,
    token: string
}> {
    displayName = "LoginPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            showPassword: false,
            errmsg: "",
            mfa: false,
            mfa_to: "",
            token: ""
        }
    }

    private clear() {
        const { auth } = this.props;
        this.clearLS()
        auth!.initialize()
        this.setState({ errmsg: "", mfa: false })
    }

    private clearLS()
    {
        [
            "mypage_query",
            "patients_query",
            "manage_user_query"
        ].forEach(x => localStorage.removeItem(x))
    }

    public componentDidMount = () => {
        const params = new URLSearchParams(this.props.location.search);
        const mfa = params.get("mfa")
        if (mfa=="1") {
            HokanApi.sendMFA(false).then(res => {
                if (res.data.to) {
                    this.setState({ errmsg: "", mfa: true, mfa_to: res.data.to, token: "" })
                }
                else {
                    this.setState({ errmsg: "", mfa: true, mfa_to: res.data.to, token: "" })
                    this.clear();
                }
            }).catch(err => {
                this.clear();
                window.location.href = "/login"
            })
        }
        else {
            this.clearLS()
        }
    }

    urlB64ToUint8Array(base64String):Uint8Array {
        const padding = "=".repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    isEnableWebPushBrowser() {
        // SWが使えないブラウザ
        if (!navigator.serviceWorker) {
            return false;
        }

        var ua = navigator.userAgent.toLowerCase();
        // UAにChromeが含まれている為、明示的にEdgeとOpera(Webkit)を弾く
        if (ua.indexOf("edge") >= 0 || ua.indexOf("opr") >= 0) {
            return false;
        }
        // Chrome 52 以降OK
        if (ua.match(/chrom(e|ium)/)) {
            var raw = ua.match(/chrom(e|ium)\/([0-9]+)\./);
            if (raw && parseInt(raw[2], 10) >= 52) {
                return true;
            }
        }
        // Firefox 48 以降OK
        if (ua.indexOf("firefox") >= 0) {
            var raw = ua.match(/firefox\/([0-9]+)\./);
            if (raw && parseInt(raw[1], 10) >= 48) {
                return true;
            }
        }
        return false;
    };

    private login() {
        const { auth, history, location } = this.props;
        const redirect = (location.state && location.state.from) || "/";
        this.clearLS()
        auth!.login(() => {
            HokanApi.me().then(x => {
                // Web Push登録
                if(this.isEnableWebPushBrowser()) {
                    Notification.requestPermission(function(result) {
                        if (result !== "granted") {
                            console.log("No notification permission granted!");
                        } else {
                            console.log("notification permission granted!");
                        }
                    });
                    var _registration:ServiceWorkerRegistration;
                    navigator.serviceWorker.register('/serviceworker.js').then(registration => {
                        _registration = registration;
                        var key: Uint8Array = this.urlB64ToUint8Array("BKOnaOoipZPD90YAAK08IJo5Vfpa5YPYANzIbF7NkAB0uk2l3kLPFeh3-SaW2M-uD_3y35EMtHQsHD-a-NvIQJ8=")
                        _registration!.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: key,
                        }).then(subscription => {
                            console.log("Subscribe OK:", subscription);
                            console.log(subscription.toJSON());
                            //TODO: サーバーにWebPush情報登録
                            /*
                            return fetch("/register", {
                                method: "POST",
                                body: JSON.stringify(subscription.toJSON()),
                            });
                            */
                        }).then(() => {
                            console.log("Server Stored Subscription.");
                            // 画面遷移
                            history.push(redirect)
                        }).catch(err => {
                            console.log("Subscribe Error:", err);
                            console.error("[main.js][Subscription]", err);
                            // 画面遷移
                            history.push(redirect)
                        });
                    }).catch(err => {
                        console.log("Register Error:", err);
                        // 画面遷移
                        history.push(redirect)
                    });;
                }
                else {
                    history.push(redirect)
                }
            }).catch(err => {
                if (err.response.status === 412) {
                    this.send_mfa(false)
                }
                else history.push(redirect)
            })
        }, (err) => {
            this.setState({ errmsg: "ログインに失敗しました" })
        })
    }

    private loginByCognito() {
        const { auth, history, location } = this.props;
        const redirect = (location.state && location.state.from) || "/";
        localStorage.removeItem("mypage_query")
        localStorage.removeItem("patients_query")
        auth!.loginCognito(() => {
            HokanApi.me().then(x => {
                // Web Push登録
                if(this.isEnableWebPushBrowser()) {
                    Notification.requestPermission(function(result) {
                        if (result !== "granted") {
                            console.log("No notification permission granted!");
                        } else {
                            console.log("notification permission granted!");
                        }
                    });
                    var _registration:ServiceWorkerRegistration;
                    navigator.serviceWorker.register('/serviceworker.js').then(registration => {
                        _registration = registration;
                        var key: Uint8Array = this.urlB64ToUint8Array("BKOnaOoipZPD90YAAK08IJo5Vfpa5YPYANzIbF7NkAB0uk2l3kLPFeh3-SaW2M-uD_3y35EMtHQsHD-a-NvIQJ8=")
                        _registration!.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: key,
                        }).then(subscription => {
                            console.log("Subscribe OK:", subscription);
                            //サーバーにWebPush情報登録
                            let j = subscription.toJSON()
                            let d = new Model.WebPushInfo()
                            d.endpoint = j.endpoint!
                            d.auth = j.keys!.auth
                            d.p256dh = j.keys!.p256dh
                            HokanApi.webpush.update(d).then( x => {
                                history.push(redirect)
                            }).catch( err => {
                                console.log("Server Err", err);
                                history.push(redirect)
                            })
                        }).then(() => {
                            console.log("Server Stored Subscription.");
                            // 画面遷移
                        }).catch(err => {
                            console.log("Subscribe Error:", err);
                            console.error("[main.js][Subscription]", err);
                            // 画面遷移
                            history.push(redirect)
                        });
                    }).catch(err => {
                        console.log("Register Error:", err);
                        // 画面遷移
                        history.push(redirect)
                    });;
                }
                else {
                    history.push(redirect)
                }
            }).catch(err => {
                if (err.response.status === 412) {
                    this.send_mfa(false)
                }
                else history.push(redirect)
            })
        }, (err) => {
            this.setState({ errmsg: "ログインに失敗しました" })
        })
    }

    private send_mfa(as_email: boolean = false) {
        const { auth, history, location } = this.props;
        const redirect = (location.state && location.state.from) || "/";
        HokanApi.sendMFA(as_email).then(res => {
            if (res.data.to) {
                this.setState({ errmsg: "", mfa: true, mfa_to: res.data.to, token: "" })
            }
            else if (res.data.result == "not_needed") {
                history.push(redirect)
            }
            else {
                this.setState({ errmsg: "エラーが発生しました" })
            }
        }).catch(err => {
            console.log(err)
            this.setState({ errmsg: "エラーが発生しました" })
        })
    }

    private confirm_mfa() {
        HokanApi.confirmMFA(this.state.token).then(res => {
            const { auth, history, location } = this.props;
            const redirect = (location.state && location.state.from) || "/";
            history.push(redirect)
        }).catch(err => {
            this.setState({ errmsg: "認証に失敗しました" })
        })
    }

    public render() {
        const { auth, location, classes } = this.props;
        const outed = (location.state && location.state.logout) || false
        return (
            <div className={classes.root}>
                {
                    this.state.mfa ?
                        <div>
                            <div className={classes.message}>
                                <div>
                                    {this.state.mfa_to} に
                                </div>
                                認証コードが送信されました。(10分以内に認証してください)
                            </div>

                            <form className={classes.container} noValidate={true} autoComplete="off">
                                <TextField
                                    id="token"
                                    className={classes.textField}
                                    value={this.state.token}
                                    onChange={(e) => this.setState({ token: e.target.value })}
                                    margin="normal"
                                    variant="outlined"
                                    label="認証コード"
                                />
                                <div>
                                    <span style={{ color: "red" }}>{this.state.errmsg}</span>
                                </div>
                                <div>
                                    <Fab variant="extended" color="primary" aria-label="login"
                                        className={classes.button}
                                        onClick={(e) => { this.confirm_mfa() }}
                                    >
                                        <Icons.Forward />
                                        確定
                                    </Fab>
                                </div>
                            </form>
                            <div style={{margin:"20px"}}>
                                届かない場合、携帯が使用できない場合：
                                <div>
                                    <Button variant="outlined" size="small" color="default"
                                    onClick={(e)=>this.send_mfa(true)}>
                                    {auth!.username} にメールで送信
                                </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className={classes.message}>
                                {
                                    outed ?
                                        "ログアウトしました"
                                        : "ログインしてください"
                                }
                            </div>
                            <form className={classes.container} noValidate={true} autoComplete="on">
                                <TextField
                                    id="mail"
                                    className={classes.textField}
                                    value={auth!.username}
                                    onChange={(e) => auth!.username = e.target.value}
                                    margin="normal"
                                    variant="outlined"
                                    label="メールアドレス"
                                />
                                <TextField
                                    id="pass"
                                    label="パスワード"
                                    className={classes.textField}
                                    value={auth!.password}
                                    onChange={(e) => auth!.password = e.target.value}
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => this.setState({ showPassword: !this.state.showPassword })}
                                                >
                                                    {this.state.showPassword ? <Icons.VisibilityOff /> : <Icons.Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div>
                                    <span style={{ color: "red" }}>{this.state.errmsg}</span>
                                    {
                                        this.state.errmsg != "" ?
                                            <div style={{ color: "red" }}>認証に失敗しました</div>
                                            : null
                                    }
                                </div>
                                <div>
                                    <Fab variant="extended" color="primary" aria-label="login"
                                        className={classes.button}
                                        onClick={(e) => { this.loginByCognito() }}
                                    >
                                        <Icons.Forward />
                                        ログイン
                                    </Fab>
                                </div>
                                {
                                    false ?
                                        <div style={{
                                            margin: "10px", border: "solid", borderWidth: "1px",
                                            borderColor: "red", fontSize: "smaller", padding: "10px", textAlign: "left"
                                        }}>
                                            -- お知らせ (2021/07/21 4:00) --<br />
                                    7月20日（火）24：30〜28：00に、セキュリティ向上と機能拡張のため認証基盤をアップデートしました。<br />
                                    そのため、現在のパスワードがリセットされ皆様のIDのメールアドレスへパスワード再設定のメールが届きます。<br />
                                    届いたらお手すきの時にパスワードを再設定してください。今までと同じパスワードで登録可能です。ただし今回から「8文字以上英数字」が条件になります。<br />
                                    ※この時間帯も普段通り記録など利用可能です。リセットメールがきたら新しいパスワード再設定してログインすることですぐに利用継続できます。<br />
                                    ※もし、うまくメールがこない・再設定がうまくいかない、というトラブルの場合は各法人の管理アカウントから、そのうまくいかないアカウントの”パスワード変更”ボタンを押して再設定を再度進めてください。それでもダメな場合は、岩本まで直接ご連絡ください。<br />
                                        </div>
                                        : null
                                }
                            </form>
                        </div>
                }
                </div>
                );
            }
        }
        
export default withPageProps(Styles.withStyles(styles)(LoginPage))