import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import SubTitle from '../components/SubTitle';
import { Select, MenuItem, Paper, Tabs, Tab, Table, TableHead, TableCell, TableRow, TableBody, Checkbox, Grid, Button } from '@material-ui/core';
import Loading from '../components/Loading';
import * as Colors from '../components/Colors'
import { withStyles } from '@material-ui/core/styles';
import { StoreAccessor } from '../util/StoreUtil';
import StandardTable from '../components/StandardTable';
import Col2Table from '../components/Col2Table';
import * as Graph from '../components/graph';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import { DateUtil } from '../util/DateUtil'
import Pagination from "material-ui-flat-pagination";
import PatientMap from "../components/PatientMap"
import EditableText from '../components/EditableText';
import * as Moment from 'moment';

const styles = () => Styles.createStyles({
    graphAria: {
        width: "100%",
        height: "400px",
        margin: "0px"
    },
    graph_paper: {
    },
    root: {
        margin: "10px",
        maxWidth: "1920px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none"
        }
    },
    freshCheckSubtitleRow: {
        border: "solid",
        borderWidth: "1px",
        backgroundColor: Colors.primaryColor,
        borderColor: Colors.backColor,
        color: Colors.white
    },
    freshCheckSubtitle: {
        fontWeight: "bold",
        fontSize: "0.9rem",
        padding: "5px",
        color: Colors.white
    },
    rowContent: {
        paddingLeft: "10px"
    },
    heatmapCell: {
        width: "10px",
        height: "10px",
        textAlign: "center"
    }
})

const transpose = (a: (string | number)[][]): (string | number)[][] => a[0].map((_, c) => a.map(r => r[c]));

interface Props extends Styles.WithStyles {
}
interface LocalState {
    tabIndex: number
    freshCheckGroups1: FreshCheckGroup[]
    freshCheckGroups2: FreshCheckGroup[]
    branchScore: any
    branchOmahaKbsScore: any
    userOmahaKbsScore: any
    branches: Model.Branch[]
    branch_id: number | null
    userScore: any
    houmonMonthly: any
    users: Model.SimpleUser[]
    user_id: number | null
    compareTerm: string
    tabIndexUser: number
    userLoading: boolean
    branchLoading: boolean
    countReportLoading: boolean
    startDate: string
    endDate: string
    omahaStartDate: string
    omahaEndDate: string
    rankingShikkan: string
    loadingKBS: boolean
    user_filter: string
    countReport: any
    reportMonth: string
    omahaReportLoading: boolean
    omahaReport: any
    omahaErrorMsg: string
    omahaReportUrl: string | null
}

class FreshCheck {
    public content: string = ""
    public score: boolean[] = [false, false, false]
}

class FreshCheckGroup {
    public title: string = ""
    public checks: FreshCheck[] = []
}

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
        whiteSpace: "pre-line"
    },
}))(TableCell);

class ScorePage extends React.Component<Props, LocalState> {
    displayName = "ScorePage"
    constructor(props: Props) {
        super(props)
        this.state = {
            tabIndex: 0,
            freshCheckGroups1: [],
            freshCheckGroups2: [],
            branchScore: [],
            branches: [],
            branch_id: null,
            userScore: [],
            houmonMonthly: [],
            users: [],
            user_id: null,
            branchOmahaKbsScore: [],
            userOmahaKbsScore: [],
            compareTerm: "１ヶ月",
            tabIndexUser: 0,
            userLoading: false,
            branchLoading: false,
            countReportLoading: false,
            countReport: null,
            omahaReportLoading: false,
            omahaReport: null,
            startDate: Moment.default().add('month', -4).format("YYYY-MM") + "-01",
            endDate: DateUtil.currentMonthLastDateStr(),
            omahaStartDate: Moment.default().add('month', -4).format("YYYY-MM") + "-01",
            omahaEndDate: DateUtil.currentMonthLastDateStr(),
            rankingShikkan: "全て",
            loadingKBS: false,
            user_filter: "",
            reportMonth: Moment.default().add('month', -1).format("YYYY-MM"),
            omahaErrorMsg: "",
            omahaReportUrl: null
        }
    }

    public loadScore = (branch_id: number) => {
        this.setState({ branchLoading: true, branch_id: branch_id })
        HokanApi.getScoreBranch(branch_id, this.state.startDate, this.state.endDate).then(res => {
            const bs = res.data;
            if (bs.youkaigo) {
                const emp = bs.youkaigo.find(x => x["区分"] == "")
                const notselect = bs.youkaigo.find(x => x["区分"] == "未選択")
                if (emp && notselect) {
                    notselect["人数"] += emp["人数"]
                }
                bs.youkaigo = bs.youkaigo.filter(x => x["区分"] != "")
            }
            this.setState({ branch_id: branch_id, branchScore: bs, branchLoading: false })
        }).catch(err => {
            console.log(err)
            this.setState({ branchLoading: false })
        })
        this.loadScoreBranchKbs(branch_id, this.state.compareTerm)
    }

    public loadScoreBranchKbs = (branch_id: number, term: string) => {
        this.setState({ compareTerm: term, loadingKBS: true })
        let term_month = 1
        if (term == "１ヶ月") term_month = 1
        else if (term == "３ヶ月") term_month = 3
        else if (term == "６ヶ月") term_month = 6
        else if (term == "１２ヶ月") term_month = 6
        const getColor = (c: number) => {
            if (c < 0) return "#b9d2fb"
            if (c > 0) return "#ffcbb9"
            return "#EEE"
        }
        const compareScore = (c: string, p: string | null) => {
            if (!p || !c) return 0
            const cn = parseFloat(c).toFixed(1)
            const pn = parseFloat(p).toFixed(1)
            if (cn < pn) return -1
            if (cn > pn) return 1
            return 0
        }
        const genData = (src: any, cur_name: string, past_name: string) => {
            const cur = src[cur_name]
            const past = src[past_name]
            const score: any[] = []
            cur.forEach(c => {
                const p = past.find(y => y.name == c.name)
                const compare = {
                    k: compareScore(c ? c.score_k : null, p ? p.score_k : null),
                    b: compareScore(c ? c.score_b : null, p ? p.score_b : null),
                    s: compareScore(c ? c.score_s : null, p ? p.score_s : null)
                }
                score.push({
                    n: c.n,
                    name: c.name,
                    score_k: `${p ? parseFloat(p.score_k).toFixed(1) : "無"}→${c ? parseFloat(c.score_k).toFixed(1) : "無"}`,
                    score_b: `${p ? parseFloat(p.score_b).toFixed(1) : "無"}→${c ? parseFloat(c.score_b).toFixed(1) : "無"}`,
                    score_s: `${p ? parseFloat(p.score_s).toFixed(1) : "無"}→${c ? parseFloat(c.score_s).toFixed(1) : "無"}`,
                    p_k: p ? parseFloat(p.score_k).toFixed(1) : null,
                    p_b: p ? parseFloat(p.score_b).toFixed(1) : null,
                    p_s: p ? parseFloat(p.score_s).toFixed(1) : null,
                    c_k: c ? parseFloat(c.score_k).toFixed(1) : null,
                    c_b: c ? parseFloat(c.score_b).toFixed(1) : null,
                    c_s: c ? parseFloat(c.score_s).toFixed(1) : null,
                    color_k: getColor(compare.k),
                    color_b: getColor(compare.b),
                    color_s: getColor(compare.s),
                    compare: compare
                })
            })
            return score
        }
        HokanApi.getScoreBranchOmahaKbs(branch_id, term_month, this.state.startDate, this.state.endDate).then(res => {
            this.setState({
                branchOmahaKbsScore: {
                    shikkan: genData(res.data, "shikkan_current", "shikkan_past"),
                    shikkan_ranking: res.data.shikkan_ranking,
                    problem: genData(res.data, "problem_current", "problem_past")
                },
                loadingKBS: false
            })
        }).catch(err => {
            console.log(err)
        })
        /*
        n: 224
        sick_name: null
        score_k: "3.1116"
        score_b: "3.3348"
        score_s: "3.183"
        */
    }


    public loadScoreUserKbs = (user_id: number, term: string) => {
        if (!this.state.branch_id) return;
        this.setState({
            compareTerm: term,
            loadingKBS: true
        })
        let term_month = 1
        if (term == "１ヶ月") term_month = 1
        else if (term == "３ヶ月") term_month = 3
        else if (term == "６ヶ月") term_month = 6
        else if (term == "１２ヶ月") term_month = 6
        const getColor = (c: number) => {
            if (c < 0) return "#b9d2fb"
            if (c > 0) return "#ffcbb9"
            return "#EEE"
        }
        const compareScore = (c: string, p: string | null) => {
            if (!p || !c) return 0
            const cn = parseFloat(c).toFixed(1)
            const pn = parseFloat(p).toFixed(1)
            if (cn < pn) return -1
            if (cn > pn) return 1
            return 0
        }
        const genData = (src: any, cur_name: string, past_name: string) => {
            const cur = src[cur_name]
            const past = src[past_name]
            const score: any[] = []
            cur.forEach(c => {
                const p = past.find(y => y.name == c.name)
                const compare = {
                    k: compareScore(c ? c.score_k : null, p ? p.score_k : null),
                    b: compareScore(c ? c.score_b : null, p ? p.score_b : null),
                    s: compareScore(c ? c.score_s : null, p ? p.score_s : null)
                }
                score.push({
                    n: c.n,
                    name: c.name,
                    score_k: `${p ? parseFloat(p.score_k).toFixed(1) : "無"}→${c ? parseFloat(c.score_k).toFixed(1) : "無"}`,
                    score_b: `${p ? parseFloat(p.score_b).toFixed(1) : "無"}→${c ? parseFloat(c.score_b).toFixed(1) : "無"}`,
                    score_s: `${p ? parseFloat(p.score_s).toFixed(1) : "無"}→${c ? parseFloat(c.score_s).toFixed(1) : "無"}`,
                    p_k: p ? parseFloat(p.score_k).toFixed(1) : null,
                    p_b: p ? parseFloat(p.score_b).toFixed(1) : null,
                    p_s: p ? parseFloat(p.score_s).toFixed(1) : null,
                    c_k: c ? parseFloat(c.score_k).toFixed(1) : null,
                    c_b: c ? parseFloat(c.score_b).toFixed(1) : null,
                    c_s: c ? parseFloat(c.score_s).toFixed(1) : null,
                    color_k: getColor(compare.k),
                    color_b: getColor(compare.b),
                    color_s: getColor(compare.s),
                    compare: compare
                })
            })
            return score
        }

        HokanApi.getScoreUserOmahaKbs(this.state.branch_id, user_id, term_month, this.state.startDate, this.state.endDate).then(res => {
            this.setState({
                userOmahaKbsScore: {
                    shikkan: genData(res.data, "shikkan_current", "shikkan_past"),
                    shikkan_ranking: res.data.shikkan_ranking,
                    problem: genData(res.data, "problem_current", "problem_past")
                },
                loadingKBS: false
            })
        }).catch(err => {
            console.log(err)
        })
        /*
        n: 224
        sick_name: null
        score_k: "3.1116"
        score_b: "3.3348"
        score_s: "3.183"
        */
    }

    public loadUserScore = (user_id: number) => {
        this.setState({ userLoading: true, user_id: user_id })
        HokanApi.getScoreUser(user_id, this.state.startDate, this.state.endDate).then(res => {
            this.setState({ userScore: res.data, userLoading: false })
        }).catch(err => {
            console.log(err)
            this.setState({ userLoading: false })
        })
        /*
        HokanApi.getScoreUserAsync(user_id, this.state.startDate, this.state.endDate).then(res => {
            console.log(res.data)
        }).catch(err => {
            console.log(err)
            this.setState({ userLoading: false })
        })*/
        this.loadScoreUserKbs(user_id, this.state.compareTerm)
    }

    public loadRanking = (name: string) => {
        if (!this.state.branch_id) return;
        HokanApi.getScoreRanking(this.state.branch_id, name).then(res => {
            this.setState({
                branchScore: {
                    ...this.state.branchScore,
                    omaha_problem_x_intervention: res.data.omaha_problem_x_intervention
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    public componentDidMount = () => {
        HokanApi.branch.list().then(x => {
            this.setState({ branches: x.data.concat({ id: 0, name: "全事業所" } as Model.Branch) })
        })
        HokanApi.getUsersSimple().then(x => {
            this.setState({ users: x.data })
        }).catch(err => console.log(err))
        HokanApi.me().then(res => {
            this.loadUserScore(res.data.id)
            this.loadScore(res.data.branch_id)
        }).catch(err => {
            console.log(err)
        })

        const g1: FreshCheckGroup[] = []
        /*
            {
                title: "情報の取り扱い",
                checks: [
                    { content: "個人情報の取り扱い", score: [false, false, false] },
                ]
            },
            {
                title: "接遇", checks: [
                    { content: "名刺の交換方法", score: [false, false, false] },
                    { content: "電話対応の受付方法", score: [false, false, false] }
                ]
            },
            {
                title: "訪問に行く実務", checks: [
                    { content: "介護保険医療保険の理解", score: [false, false, false] },
                    { content: "　　介護保険の訪問コード", score: [false, false, false] },
                    { content: "　　介護保険の加算（初回、退院共同、夜間等、特別管理、緊急時）", score: [false, false, false] },
                    { content: "　　医療保険の訪問コード", score: [false, false, false] },
                    { content: "　　医療保険の加算（退院共同、退院支援、夜間等、特別管理、24時間対応、緊急）", score: [false, false, false] },
                    { content: "看護記録Ⅰの作成", score: [false, false, false] },
                    { content: "看護記録Ⅱの作成", score: [false, false, false] },
                    { content: "　　医療保険の記録", score: [false, false, false] },
                    { content: "　　介護保険の記録", score: [false, false, false] },
                    { content: "計画書報告書の作成", score: [false, false, false] },
                    { content: "計画書の同意を得る", score: [false, false, false] },
                    { content: "情報提供書の作成", score: [false, false, false] },
                    { content: "褥瘡対策計画書の作成", score: [false, false, false] },
                    { content: "看護サマリーの作成", score: [false, false, false] },
                    { content: "訪問看護指示書の理解", score: [false, false, false] },
                    { content: "特別訪問看護指示書の理解", score: [false, false, false] },
                    { content: "点滴注射指示書の理解", score: [false, false, false] },
                    { content: "居宅介護支援計画の理解", score: [false, false, false] },
                    { content: "提供表の理解と毎月の確認", score: [false, false, false] },
                    { content: "提供表の修正が必要な時の対応", score: [false, false, false] },
                    { content: "訪問スケジュールの確認", score: [false, false, false] },
                    { content: "訪問スケジュールの変更に関わる対応", score: [false, false, false] }
                ]
            },
            {
                title: "利用者の事務に係ること", checks: [
                    { content: "保険証の理解", score: [false, false, false] },
                    { content: "介護保険証", score: [false, false, false] },
                    { content: "介護保険負担割合証", score: [false, false, false] },
                    { content: "健康（後期、国保）保険証", score: [false, false, false] },
                    { content: "限度額認定証", score: [false, false, false] },
                    { content: "公費の理解", score: [false, false, false] },
                    { content: "特定医療費、〇都", score: [false, false, false] },
                    { content: "〇障など", score: [false, false, false] },
                    { content: "生活保護医療券の理解", score: [false, false, false] }
                ]
            },
            {
                title: "新規契約する実務", checks: [
                    { content: "新規利用者導入", score: [false, false, false] },
                    { content: "申し込み用紙の依頼", score: [false, false, false] },
                    { content: "契約日のアポイント", score: [false, false, false] },
                    { content: "契約書類の取り交わし", score: [false, false, false] },
                    { content: "引き落とし口座登録申し込み書の取り交わし", score: [false, false, false] },
                    { content: "適正な訪問看護回数の理解", score: [false, false, false] },
                ]
            },
            {
                title: "その他間接業務", checks: [
                    { content: "インシデント　　クレームの記録", score: [false, false, false] },
                    { content: "事故発生時の対応", score: [false, false, false] },
                    { content: "訪問スケジュールの作成、変更時の修正", score: [false, false, false] },
                    { content: "サービス担当者会議の出席", score: [false, false, false] },
                    { content: "　　照会用紙の記載", score: [false, false, false] },
                    { content: "　　議事録の作成", score: [false, false, false] },
                    { content: "　　在宅の他職種の役割、業務範囲が理解できる。", score: [false, false, false] },
                    { content: "　　会議の目的を明確に出来る。", score: [false, false, false] },
                    { content: "　　１週間の生活全体のイメージして生活への課題を抽出できる", score: [false, false, false] },
                    { content: "退院カンファレンスの参加", score: [false, false, false] },
                    { content: "　　退院カンファレンス記録の作成、同意を得る", score: [false, false, false] },
                    { content: "　　退院窓口との連携ができる", score: [false, false, false] },
                    { content: "　　在宅移行導入にあたり制度がある程度理解できる", score: [false, false, false] },
                    { content: "　　訪問看護の組み立てが初回導入から出来る", score: [false, false, false] },
                    { content: "　　家に帰った後の生活イメージができ課題を抽出できる。", score: [false, false, false] },
                    { content: "夜間オンコール対応", score: [false, false, false] },
                    { content: "　　緊急コールの対応", score: [false, false, false] },
                    { content: "　　緊急訪問の実施", score: [false, false, false] },
                    { content: "　　緊急訪問時の記録", score: [false, false, false] },
                    { content: "　　緊急訪問時の報連相", score: [false, false, false] },
                    { content: "ターミナルケア", score: [false, false, false] },
                    { content: "　　ターミナルケア加算について", score: [false, false, false] },
                    { content: "　　必要な事前準備、プロセス", score: [false, false, false] },
                    { content: "　　記録について", score: [false, false, false] },
                    { content: "　　看取り時の対応と記録", score: [false, false, false] },
                    { content: "多職種、他サービスの理解", score: [false, false, false] },
                    { content: "　　医療関連", score: [false, false, false] },
                    { content: "　　介護関連", score: [false, false, false] },
                    { content: "　　障害福祉関連（精神科）", score: [false, false, false] },
                    { content: "　　障害福祉関連（小児/成人）", score: [false, false, false] },
                    { content: "施設への訪問看護の理解", score: [false, false, false] },
                    { content: "　　老健", score: [false, false, false] },
                    { content: "　　特養", score: [false, false, false] },
                    { content: "　　サ高住・軽費老人ホーム・有料", score: [false, false, false] },
                    { content: "　　認知症デイ", score: [false, false, false] }
                ]
            }
        ]*/
        const g2: FreshCheckGroup[] = []
        /*
        {
            title: "食事介助", checks: [
                { content: "誤嚥予防する食事介助", score: [false, false, false] },
                { content: "嚥下の評価", score: [false, false, false] },
                { content: "口腔ケア", score: [false, false, false] },
                { content: "アイスマッサージ", score: [false, false, false] },
                { content: "嚥下訓練", score: [false, false, false] },
                { content: "経口栄養剤や食形態の選択", score: [false, false, false] },
                { content: "経管栄養の選択", score: [false, false, false] },
            ]
        },
        {
            title: "経管栄養", checks: [
                { content: "胃瘻の管理・固定水交換", score: [false, false, false] },
                { content: "経鼻経管の管理", score: [false, false, false] },
                { content: "経管栄養投与実際", score: [false, false, false] },
            ]
        },
        {
            title: "吸引", checks: [
                { content: "吸引器の種類・管理", score: [false, false, false] },
                { content: "口鼻吸引", score: [false, false, false] },
                { content: "気切吸引", score: [false, false, false] },
            ]
        },
        {
            title: "排泄", checks: [
                { content: "浣腸", score: [false, false, false] },
                { content: "座薬挿入", score: [false, false, false] },
                { content: "導尿：男性", score: [false, false, false] },
                { content: "導尿：女性", score: [false, false, false] },
                { content: "バルーン管理、交換", score: [false, false, false] },
                { content: "ストマ管理", score: [false, false, false] },
                { content: "オムツ交換・オムツの選択", score: [false, false, false] },
            ]
        },
        {
            title: "活動と休息", checks: [
                { content: "ベッドの種類", score: [false, false, false] },
                { content: "体位変換", score: [false, false, false] },
                { content: "移動・移乗", score: [false, false, false] },
                { content: "車いす・歩行器の種類", score: [false, false, false] },
                { content: "歩行介助", score: [false, false, false] },
                { content: "リフト種類", score: [false, false, false] },
            ]
        },
        {
            title: "与薬", checks: [
                { content: "内服管理", score: [false, false, false] },
                { content: "点眼", score: [false, false, false] },
                { content: "座薬", score: [false, false, false] },
                { content: "静脈点滴注射", score: [false, false, false] },
                { content: "皮下点滴注射", score: [false, false, false] },
                { content: "インシュリン注射", score: [false, false, false] },
                { content: "血糖測定", score: [false, false, false] },
                { content: "筋肉注射", score: [false, false, false] },
                { content: "CVポート管理・穿刺", score: [false, false, false] },
                { content: "CVカテーテル管理", score: [false, false, false] },
                { content: "シリンジポンプ", score: [false, false, false] },
                { content: "輸液ポンプ", score: [false, false, false] },
                { content: "CADD", score: [false, false, false] },
                { content: "PCAポンプ", score: [false, false, false] },
                { content: "麻薬：内服", score: [false, false, false] },
                { content: "麻薬：レスキュー", score: [false, false, false] },
                { content: "麻薬：貼付", score: [false, false, false] },
                { content: "麻薬：点滴", score: [false, false, false] },
                { content: "貼付剤", score: [false, false, false] },
            ]
        },
        {
            title: "透析", checks: [
                { content: "PD管理", score: [false, false, false] },
                { content: "HDシャント管理", score: [false, false, false] },
                { content: "バスキャス管理", score: [false, false, false] },
            ]
        },
        {
            title: "清潔", checks: [
                { content: "寝衣交換", score: [false, false, false] },
                { content: "清拭", score: [false, false, false] },
                { content: "陰部洗浄", score: [false, false, false] },
                { content: "洗髪", score: [false, false, false] },
                { content: "足浴・手浴", score: [false, false, false] },
                { content: "爪切り", score: [false, false, false] },
                { content: "入浴介助", score: [false, false, false] },
                { content: "ひげそり（刃）", score: [false, false, false] },
            ]
        },
        {
            title: "感染防止", checks: [
                { content: "スタンダードプリコーション", score: [false, false, false] },
                { content: "針刺し時", score: [false, false, false] },
            ]
        },
        {
            title: "バイタルサイン", checks: [
                { content: "成人の測定", score: [false, false, false] },
                { content: "小児の測定", score: [false, false, false] },
            ]
        },
        {
            title: "創傷管理", checks: [
                { content: "皮膚トラブル予防", score: [false, false, false] },
                { content: "軟膏種類と選択", score: [false, false, false] },
                { content: "スキンテア予防", score: [false, false, false] },
                { content: "スキンテア処置", score: [false, false, false] },
                { content: "褥瘡の予防", score: [false, false, false] },
                { content: "褥瘡の評価と処置", score: [false, false, false] },
                { content: "包帯", score: [false, false, false] },
            ]
        },
        {
            title: "呼吸", checks: [
                { content: "酸素ボンベの使い方", score: [false, false, false] },
                { content: "ＨＯＴ管理", score: [false, false, false] },
                { content: "ＣＰＡＰ管理", score: [false, false, false] },
                { content: "人工呼吸器管理", score: [false, false, false] },
                { content: "カフアシスト", score: [false, false, false] },
                { content: "肺理学療法", score: [false, false, false] },
                { content: "吸入", score: [false, false, false] },
            ]
        },
        {
            title: "死後の処置", checks: [
                { content: "エンゼルケア", score: [false, false, false] },
            ]
        },
        {
            title: "リハビリ", checks: [
                { content: "筋力訓練", score: [false, false, false] },
                { content: "ＲＯＭ訓練", score: [false, false, false] },
            ]
        },
        {
            title: "小児", checks: [
                { content: "沐浴・入浴", score: [false, false, false] },
                { content: "栄養（ミルク量、吸啜嚥下、離乳食）", score: [false, false, false] },
                { content: "気切管理", score: [false, false, false] },
                { content: "吸引", score: [false, false, false] },
                { content: "経鼻経管交換", score: [false, false, false] },
                { content: "胃瘻管理", score: [false, false, false] },
                { content: "発達の評価", score: [false, false, false] },
                { content: "遊びを通した発達支援", score: [false, false, false] },
                { content: "導尿", score: [false, false, false] },
                { content: "浣腸", score: [false, false, false] },
                { content: "ブジ―", score: [false, false, false] },
                { content: "オムツ交換", score: [false, false, false] },
                { content: "装具や座位保持椅子の理解", score: [false, false, false] },
                { content: "マッサージ・緊張緩和", score: [false, false, false] },
                { content: "声かけタイミング", score: [false, false, false] }
            ]
        }]*/

        this.setState({
            freshCheckGroups1: g1,
            freshCheckGroups2: g2
        })
    }

    private tabChange(v) {
        this.setState({ tabIndex: v })
    }

    public render() {
        const { classes } = this.props
        const { tabIndex } = this.state
        return (
            <div className={classes.root}>

                <div style={{ margin: "10px" }}>
                    期間：
                    <EditableText
                        label=""
                        editMode={this.state.branchLoading == false}
                        name={"startDate"}
                        fullwidth={false}
                        display="inline-block"
                        inputType="date"
                        multiline={false}
                        value={this.state.startDate}
                        setData={(n, v) => {
                            this.setState({ startDate: v })
                        }} />
                    〜
                    <EditableText
                        label=""
                        editMode={this.state.branchLoading == false}
                        name={"endDate"}
                        fullwidth={false}
                        display="inline-block"
                        inputType="date"
                        multiline={false}
                        value={this.state.endDate}
                        setData={(n, v) => {
                            this.setState({ endDate: v })
                        }} />
                    <Button onClick={(e) => {
                        if (this.state.branch_id) {
                            this.loadScore(this.state.branch_id)
                        }
                        if (this.state.user_id) {
                            this.loadUserScore(this.state.user_id)
                        }
                    }}>読込</Button>
                </div>
                <Paper square>
                    <Tabs value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(e, v) => this.tabChange(v)}
                        aria-label="tab">
                        <Tab label="事業所スコア" />
                        <Tab label="個人スコア" />
                        <Tab label="集計" />
                    </Tabs>
                </Paper>
                <br />
                <br />
                {
                    <div>
                        {tabIndex == 0 ?
                            this.branchScore()
                            : null
                        }
                        {tabIndex == 1 ? <div>
                            {this.userScore()}
                        </div> : null
                        }
                        {tabIndex == 2 ? <div>
                            {this.countReport()}
                        </div> : null
                        }
                    </div>

                }
            </div>
        );
    }

    private setFreshCheck1(gi, ci, score) {
        const g = this.state.freshCheckGroups1
        g[gi].checks[ci].score[score] = !g[gi].checks[ci].score[score];
        this.setState({ freshCheckGroups1: g })
    }
    private setFreshCheck2(gi, ci, score) {
        const g = this.state.freshCheckGroups2
        g[gi].checks[ci].score[score] = !g[gi].checks[ci].score[score];
        this.setState({ freshCheckGroups2: g })
    }

    private userScore() {
        const { classes } = this.props
        const { freshCheckGroups1, freshCheckGroups2 } = this.state
        //if (!this.state.userScore.tanto_patient || this.state.userLoading) return <Loading />


        const getCount = (data: any, name: any) => {
            if (data && data[0]) return data[0][name]
            return ""
        }

        const houmon_time: any = [] as any
        const month_dic: any = {} as any

        const addNew = (r) => {
            if (r["月"] in month_dic) return;
            month_dic[r["月"]] = houmon_time.length;
            houmon_time.push({
                "月": r["月"],

                "合計(時間)": 0.0,
                "メイン(時間)": 0.0,
                "教育以外(時間)": 0.0,
                "見学(時間)": 0.0,
                "一部実施(時間)": 0.0,
                "全部実施(時間)": 0.0,
                "確認(時間)": 0.0,

                "合計(件)": 0.0,
                "メイン(件)": 0.0,
                "教育以外(件)": 0.0,
                "見学(件)": 0.0,
                "一部実施(件)": 0.0,
                "全部実施(件)": 0.0,
                "確認(件)": 0.0,

                "退院カンファ": 0.0,
                "多職種カンファ": 0.0,


                '医療(時間)': 0,
                '介護(時間)': 0,
                'その他(時間)': 0,

                '医療(件)': 0,
                '介護(件)': 0,
                'その他(件)': 0,
            })
        }
        console.log(this.state.userScore.houmon_monthly)
        if (this.state.tabIndexUser == 0 && this.state.userScore.houmon_monthly) {
            for (let i = 0; i < this.state.userScore.houmon_monthly.length; ++i) {
                const r = this.state.userScore.houmon_monthly[i]
                addNew(r)
                const idx = month_dic[r["月"]]
                const hours = parseFloat(r["訪問時間"]) || 0
                const hokenStr = r["保険"] || ""
                const basic: Model.KirokuII.Basic = JSON.parse(r.basic);
                const hoken = (
                    (hokenStr == Model.KirokuII.KirokuType.Jihi
                        || hokenStr == Model.KirokuII.KirokuType.None
                        || hokenStr == "") ? "その他"
                        : hokenStr.includes("介護") ? "介護" : "医療"
                )
                houmon_time[idx][hoken + "(時間)"] += hours
                houmon_time[idx][hoken + "(件)"] += 1
                if (r.id == this.state.user_id) {
                    houmon_time[idx]["メイン" + "(時間)"] += hours
                    houmon_time[idx]["メイン(件)"] += 1
                }
                else if (r.basic) {
                    const doukou_list = basic.doukou_list;
                    for (let j = 0; j < doukou_list.length; ++j) {
                        const d = doukou_list[j]
                        if (d.user.id == this.state.user_id) {
                            houmon_time[idx][d.step + "(時間)"] += hours
                            houmon_time[idx][d.step + "(件)"] += 1
                            break;
                        }
                    }
                }
                houmon_time[idx]["合計(時間)"] += hours
                houmon_time[idx]["合計(件)"] += 1
            }

            for (let i = 0; i < this.state.userScore.houmon_monthly_time_kanfa.length; ++i) {
                const r = this.state.userScore.houmon_monthly_time_kanfa[i]
                addNew(r)
                const idx = month_dic[r["月"]]
                const ac = parseFloat(r["退院カンファ"]) || 0
                const bc = parseFloat(r["多職種カンファ"]) || 0
                houmon_time[idx]["退院カンファ"] = ac
                houmon_time[idx]["多職種カンファ"] = bc
                houmon_time[idx]["合計(時間)"] += ac + bc
            }
            console.log(houmon_time)
        }

        const kbsScores: any = {}
        if (this.state.tabIndexUser == 1 && this.state.userScore.kbs) {
            this.state.userScore.tanto_patient.forEach(pat => {
                kbsScores[pat["id"]] = {}
                this.state.userScore.kbs.forEach(kbs => {
                    if (kbs.pat_id in kbsScores) {
                        const kbsScore = kbsScores[kbs.pat_id]
                        const d = { date: kbs.ass_day, K: kbs.score_k, B: kbs.score_b, S: kbs.score_s }
                        if (kbsScore[kbs.name]) {
                            if (kbsScore[kbs.name].findIndex(x => x.date == kbs.ass_day) < 0) {
                                kbsScore[kbs.name].push(d)
                            }
                        }
                        else {
                            kbsScore[kbs.name] = [d]
                        }
                        kbsScores[kbs.pat_id] = kbsScore;
                    }
                })
            })
        }

        const zokusei_counter = {
            zokusei: {},
            dokkyo: {},
            kourei: {}
        }
        const zokusei_shikkan: any[] = []
        const shikkans: any = {}
        const registCount = (dic, name) => {
            if (!name || name == "") name = "未選択"
            if (!(name in dic)) {
                dic[name] = 0;
            }
            return name;
        }

        if (this.state.userScore.tanto_patient) {
            this.state.userScore.tanto_patient.forEach(pat => {
                shikkans[registCount(shikkans, pat["疾患分類"])]++
                zokusei_counter.zokusei[registCount(zokusei_counter.zokusei, pat["属性"])]++;
                zokusei_counter.dokkyo[registCount(zokusei_counter.dokkyo, pat["独居"])]++;
                zokusei_counter.kourei[registCount(zokusei_counter.kourei, pat["高齢世帯"])]++;
            })
        }

        Object.keys(shikkans).forEach(key => {
            zokusei_shikkan.push({
                "疾患分類": key,
                "人数": shikkans[key]
            })
        })

        const getZokuseiCount = (dic, name) => {
            if (name in dic) return dic[name]
            return 0
        }

        const user_types = {
            zokusei: [
                { "属性": "小児", "人数": getZokuseiCount(zokusei_counter.zokusei, "小児") },
                { "属性": "成人", "人数": getZokuseiCount(zokusei_counter.zokusei, "成人") },
                { "属性": "未入力", "人数": getZokuseiCount(zokusei_counter.zokusei, "未選択") }
            ],
            dokkyo: [
                { "独居": "独居", "人数": getZokuseiCount(zokusei_counter.dokkyo, "●") },
                { "独居": "独居ではない", "人数": getZokuseiCount(zokusei_counter.dokkyo, "-") },
                { "独居": "未入力", "人数": getZokuseiCount(zokusei_counter.dokkyo, "未選択") }
            ],
            kourei: [
                { "高齢世帯": "高齢世帯", "人数": getZokuseiCount(zokusei_counter.kourei, "●") },
                { "高齢世帯": "高齢世帯ではない", "人数": getZokuseiCount(zokusei_counter.kourei, "-") },
                { "高齢世帯": "未入力", "人数": getZokuseiCount(zokusei_counter.kourei, "未選択") }
            ]
        }


        const user_filter = this.state.user_filter
        const sa = new StoreAccessor(this, true)

        return <div>
            <div style={{ margin: "10px" }}>
                スタッフ：
                <Select
                    value={this.state.user_id || ''}
                    autoWidth={true}
                    variant='outlined'
                    onChange={(e) => this.loadUserScore(e.target.value as any)}
                    className={classes.input}
                >
                    {
                        this.state.users.filter(u => {
                            if (user_filter == "") return true;
                            return (u.name.indexOf(user_filter) != -1)
                        }).map((t, idx) =>
                            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                        )
                    }
                </Select>
                {" "}
                {sa.text("user_filter", "名前をフィルタ", "text", "inline-flex")}
            </div>
            <Paper square>
                <Tabs value={this.state.tabIndexUser}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, v) => this.setState({ tabIndexUser: v })}
                    aria-label="tab2">
                    <Tab label="個人スコア" />
                    <Tab label="担当KBSスコア" />
                    <Tab label="OJT" />
                </Tabs>
            </Paper>
            <br />
            <div>
                {
                    this.state.tabIndexUser == 0 ?
                        (!this.state.userScore.tanto_patient || this.state.userLoading) ?
                            <Loading /> :
                            <div>
                                <div>
                                    <SubTitle title="個人スコア" flipColor={true} />
                                    <Grid container={true} spacing={2}>
                                        <Grid item={true} xs={12} md={3} lg={3} className={classes.graph_paper}>
                                            <Grid container={true} spacing={2}>
                                                <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                                    <Graph.Text
                                                        value={`${this.state.userScore.tanto_patient.length}`}
                                                        title="担当数"
                                                        unit="人"
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                                    {
                                                        false ?
                                                            <Graph.Table
                                                                data={this.state.userScore.tanto_patient}
                                                                title="担当利用者"
                                                                heads={["名前", "主担当", "疾患分類", "属性", "独居", "高齢世帯"]}
                                                                cols={["名前", "主担当", "疾患分類", "属性", "独居", "高齢世帯"]}
                                                                csvData=""
                                                            />
                                                            :
                                                            <Graph.Table
                                                                data={this.state.userScore.tanto_patient}
                                                                title="担当利用者"
                                                                heads={["名前", "主担当"]}
                                                                cols={["名前", "主担当"]}
                                                                csvData={(() => {
                                                                    const headers = ["名前", "主担当"]
                                                                    const cols = headers.slice()
                                                                    const data = this.state.userScore.tanto_patient

                                                                    let result = headers.join(',') + "\n"
                                                                    if (data == null) return result
                                                                    data.forEach(d => {
                                                                        const arr: string[] = []
                                                                        cols.forEach(col => {
                                                                            arr.push(d[col])
                                                                        })
                                                                        result += arr.join(',') + "\n"
                                                                    })
                                                                    return result
                                                                })()}
                                                                tooltip={(d) => {
                                                                    return <div style={{
                                                                        fontSize: "0.9rem", backgroundColor: "white",
                                                                        color: Colors.primaryColor,
                                                                        padding: "10px"
                                                                    }}
                                                                    >
                                                                        <div>
                                                                            <div style={{ margin: "5px" }}>
                                                                                <div><b>{d["名前"]}</b></div>
                                                                                <b>疾患分類：</b>{d["疾患分類"]}<br />
                                                                                <b>属性：</b>{d["属性"]}<br />
                                                                                <b>独居：</b>{d["独居"]}<br />
                                                                                <b>高齢世帯：</b>{d["高齢世帯"]}<br />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }}
                                                            />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} xs={12} md={9} lg={9} className={classes.graph_paper}>
                                            <Grid container={true} spacing={2}>
                                                <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                                    <Graph.HoumonMonthlyUser
                                                        data={houmon_time}
                                                    />
                                                    <Graph.HoumonMonthlyUserTime
                                                        data={houmon_time}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6} lg={6} className={classes.graph_paper}>
                                            <Graph.BarGraph2
                                                data={zokusei_shikkan}
                                                title="利用者数（疾患分類別）"
                                                name="疾患分類"
                                                count={"人数"}
                                                color={["#de9610"]}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={6} lg={6} className={classes.graph_paper}>
                                            <Graph.BarGraph2
                                                data={this.state.userScore.omaha_problem}
                                                title="担当利用者のオマハ問題"
                                                name="問題名"
                                                count={"立案数"}
                                                color={["#444"]}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                            <Graph.PiChart
                                                data={user_types.zokusei}
                                                title="利用者数（属性別）"
                                                name="属性"
                                                count="人数"
                                                height="300px"
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                            <Graph.PiChart
                                                data={user_types.dokkyo}
                                                title="利用者数（独居）"
                                                name="独居"
                                                count="人数"
                                                height="300px"
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                            <Graph.PiChart
                                                data={user_types.kourei}
                                                title="利用者数（高齢世帯）"
                                                name="高齢世帯"
                                                count="人数"
                                                height="300px"
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} md={8} lg={8} className={classes.graph_paper}>
                                            <div style={{ padding: "10px", border: "1px solid", borderRadius: "5px" }}>
                                                期間：
                                                <Select
                                                    value={this.state.compareTerm}
                                                    autoWidth={true}
                                                    variant='outlined'
                                                    disabled={this.state.loadingKBS}
                                                    onChange={(e) => {
                                                        if (this.state.branch_id && this.state.user_id) {
                                                            this.loadScoreUserKbs(this.state.user_id, e.target.value as any)
                                                        }
                                                    }
                                                    }
                                                    className={classes.input}
                                                >
                                                    <MenuItem value="１ヶ月">１ヶ月</MenuItem>
                                                    <MenuItem value="３ヶ月">３ヶ月</MenuItem>
                                                    <MenuItem value="６ヶ月">６ヶ月</MenuItem>
                                                    <MenuItem value="１２ヶ月">１２ヶ月</MenuItem>
                                                </Select>
                                                <Grid container={true} spacing={2}>
                                                    <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                                        <Graph.TableKbs
                                                            data={this.state.userOmahaKbsScore.shikkan}
                                                            title="KBS遷移（疾患分類毎）"
                                                            rank={this.state.userOmahaKbsScore.shikkan_ranking}
                                                            name="疾患分類"
                                                        />
                                                    </Grid>
                                                    <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                                        <Graph.TableKbs
                                                            data={this.state.userOmahaKbsScore.problem}
                                                            title="KBS遷移（問題毎)"
                                                            rank={null}
                                                            name="問題"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                {/*
                            false ?
                                <Grid container={true} spacing={0}>
                                    <Grid item={true} xs={12} md={12} lg={6}>
                                        <SubTitle flipColor={true} title="新人チェック(知識)" />
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>内容</CustomTableCell>
                                                    <CustomTableCell align="center">学ぶ</CustomTableCell>
                                                    <CustomTableCell align="center">経験</CustomTableCell>
                                                    <CustomTableCell align="center">自立</CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    freshCheckGroups1.map((group, gi) => {
                                                        return [
                                                            <TableRow key={`fchk1_${gi}`} className={classes.freshCheckSubtitleRow}>
                                                                <TableCell colSpan={4} align="left">
                                                                    <div className={classes.freshCheckSubtitle}>
                                                                        {group.title}
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ].concat(
                                                            group.checks.map((check, ci) => {
                                                                return <TableRow key={`fchk1_${gi}_${ci}`} className={classes.row}>
                                                                    <TableCell align="left" className={classes.rowContent}>{check.content}</TableCell>
                                                                    <TableCell align="center">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={check.score[0]}
                                                                            onChange={(e, f) => this.setFreshCheck1(gi, ci, 0)} />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={check.score[1]}
                                                                            onChange={(e, f) => this.setFreshCheck1(gi, ci, 1)} />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={check.score[2]}
                                                                            onChange={(e, f) => this.setFreshCheck1(gi, ci, 2)} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            })
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={6}>
                                        <SubTitle title="在宅あるある技術の確認"
                                            subText="※病棟経験のあるものは自立にチェック"
                                        />
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>内容</CustomTableCell>
                                                    <CustomTableCell align="center">学ぶ</CustomTableCell>
                                                    <CustomTableCell align="center">経験</CustomTableCell>
                                                    <CustomTableCell align="center">自立</CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    freshCheckGroups2.map((group, gi) => {
                                                        return [
                                                            <TableRow key={`fchk2_${gi}`} className={classes.freshCheckSubtitleRow}>
                                                                <TableCell colSpan={4} align="left">
                                                                    <div className={classes.freshCheckSubtitle}>
                                                                        {group.title}
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ].concat(
                                                            group.checks.map((check, ci) => {
                                                                return <TableRow key={`fchk2_${gi}_${ci}`} className={classes.row}>
                                                                    <TableCell align="left" className={classes.rowContent}>{check.content}</TableCell>
                                                                    <TableCell align="center">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={check.score[0]}
                                                                            onChange={(e, f) => this.setFreshCheck2(gi, ci, 0)} />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={check.score[1]}
                                                                            onChange={(e, f) => this.setFreshCheck2(gi, ci, 1)} />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={check.score[2]}
                                                                            onChange={(e, f) => this.setFreshCheck2(gi, ci, 2)} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            })
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                                : null
                                            */}
                            </div>
                        : null
                }
                {this.state.tabIndexUser == 1 ?
                    (!this.state.userScore.tanto_patient || this.state.userLoading) ?
                        <Loading /> :
                        <div>
                            {
                                this.state.userScore.tanto_patient.map((pat, pi) => {
                                    return [
                                        <SubTitle key={`kbs_ti_${pi}`} title={pat["名前"] + " さんの KBS"} flipColor={true} />,
                                        <Grid key={`kbs_cn_${pi}`} container={true} spacing={2}>
                                            {
                                                Object.keys(kbsScores[pat["id"]]).map((pname, pni) => {
                                                    return <Grid key={`kbs_it_${pi}_${pni}`} item={true} xs={12} md={6} lg={6} className={classes.graph_paper} style={{ marginBottom: "10px" }}>
                                                        <Graph.LineGraphKbs
                                                            data={kbsScores[pat["id"]][pname]}
                                                            title={`${pname}`}
                                                            smallMode={false}
                                                        />
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    ]
                                })
                            }
                        </div>
                    : null
                }
                {this.state.tabIndexUser == 2 ?
                    <div>
                        <SubTitle flipColor={true} title="同行進捗" />
                        {
                            this.state.user_id ?
                                <DoukouShinchoku user_id={this.state.user_id}
                                    start={this.state.startDate}
                                    end={this.state.endDate}
                                />
                                : null
                        }
                    </div>
                    : null
                }
            </div>

        </div>
    }

    private branchScore() {
        if (!this.state.branchScore || this.state.branchLoading) return <Loading />
        const months = DateUtil.genMonthRangeArray(this.state.startDate, this.state.endDate)
        const getCount = (data: any, name: any, fixed: number = 0) => {
            if (data && data[0]) return parseFloat(data[0][name]).toFixed(fixed)
            return ""
        }
        const { classes } = this.props

        const houmon_count_time_by_branch = (() => {
            if (this.state.branches == null) return null
            if (this.state.branchScore.houmon_count_monthly_patient_by_branch == null) return null

            // 全事業者を除く
            const branches = this.state.branches.filter(b => b.id !== 0);
            const headers = (() => {
                const result: string[] = []
                result.push("月")
                result.push("合計(件)")
                branches.forEach((b) => {
                    result.push(`${b.name}(件)`)
                })
                result.push("合計(時間)")
                branches.forEach((b) => {
                    result.push(`${b.name}(時間)`)
                })
                return result
            })()

            const result: { [key in typeof headers[number]]: (number | string) }[] = []

            months.forEach((month) => {
                const filteredByMonth = this.state.branchScore.houmon_count_monthly_patient_by_branch.filter((d) => d["月"] == month)
                if (filteredByMonth.length === 0) return;

                const tmp: typeof result[number] = Object.fromEntries(headers.map(key => [key, 0]))
                headers.forEach((header) => {
                    if (header === "月") {
                        tmp["月"] = month
                    } else if (header === "合計(件)") {
                        tmp[header] = filteredByMonth.reduce((accu, curr) => accu + curr["訪問数"], 0)
                    } else if (header === "合計(時間)") {
                        tmp[header] = filteredByMonth.reduce((accu, curr) => accu + parseFloat(curr["訪問時間"]), 0)
                    } else {
                        const branchName = header.split("(")[0]
                        const isCount = header.split("(")[1] == "件)"
                        const data = filteredByMonth.find((f) => f["事業所名"] == branchName)
                        if (data == null) {
                            tmp[header] = 0
                            return
                        }
                        if (isCount) {
                            tmp[header] = data["訪問数"]
                        } else {
                            tmp[header] = data["訪問時間"]
                        }
                    }
                })
                result.push(tmp)
            })
            return result
        })()

        const houmon_count_time: any[] = []

        if (this.state.branchScore.houmon_count_monthly_patient) {
            this.state.branchScore.houmon_count_monthly_patient.forEach(hcm => {
                let target = houmon_count_time.find(x => x['月'] == hcm["月"])
                if (!target) {
                    houmon_count_time.push({
                        '月': hcm["月"],
                        '合計（件）': 0,
                        '医療（件）': 0,
                        '介護（件）': 0,
                        'その他（件）': 0,
                        '合計（時間）': 0,
                        '医療（時間）': 0,
                        '介護（時間）': 0,
                        'その他（時間）': 0,
                    })
                    target = houmon_count_time[houmon_count_time.length - 1]
                }
                const hokenStr = hcm["保険"]
                const hoken = (
                    (hokenStr == Model.KirokuII.KirokuType.Jihi
                        || hokenStr == Model.KirokuII.KirokuType.None) ? "その他"
                        : hokenStr.includes("介護") ? "介護" : "医療"
                )
                target["合計（件）"] += hcm["訪問数"]
                target[hoken + "（件）"] += hcm["訪問数"]
                const t = parseFloat(hcm["訪問時間"]) || 0
                target["合計（時間）"] += t
                target[hoken + "（時間）"] += t
            })
        }

        if (this.state.branchScore.user_count_zokusei_shikkan) {
            let t = -1;
            let s = -1;
            for (let i = 0; i < this.state.branchScore.user_count_zokusei_shikkan.length; ++i) {
                if (this.state.branchScore.user_count_zokusei_shikkan[i]["疾患分類"] == null) {
                    t = i;
                }
                else if (this.state.branchScore.user_count_zokusei_shikkan[i]["疾患分類"] == "未選択") {
                    s = i;
                }
            }
            if (t != -1 && s != -1) {
                this.state.branchScore.user_count_zokusei_shikkan[s]["人数"] += this.state.branchScore.user_count_zokusei_shikkan[t]["人数"]
                this.state.branchScore.user_count_zokusei_shikkan = this.state.branchScore.user_count_zokusei_shikkan.filter((x, i) => i != t);
            }
        }

        const user_types = this.state.branchScore.user_count_zokusei && this.state.branchScore.user_count_zokusei[0] ? {
            zokusei: [
                { "属性": "小児", "人数": this.state.branchScore.user_count_zokusei[0]["小児"] },
                { "属性": "成人", "人数": this.state.branchScore.user_count_zokusei[0]["成人"] },
                { "属性": "未入力", "人数": this.state.branchScore.user_count_zokusei[0]["属性未入力"] },
            ],
            dokkyo: [
                { "独居": "独居", "人数": this.state.branchScore.user_count_zokusei[0]["独居YES"] },
                { "独居": "独居ではない", "人数": this.state.branchScore.user_count_zokusei[0]["独居NO"] },
                { "独居": "未入力", "人数": this.state.branchScore.user_count_zokusei[0]["独居NULL"] },
            ],
            kourei: [
                { "高齢世帯": "高齢世帯", "人数": this.state.branchScore.user_count_zokusei[0]["高齢世帯YES"] },
                { "高齢世帯": "高齢世帯ではない", "人数": this.state.branchScore.user_count_zokusei[0]["高齢世帯NO"] },
                { "高齢世帯": "未入力", "人数": this.state.branchScore.user_count_zokusei[0]["高齢世帯NULL"] },
            ]
        } : {
            zokusei: [],
            dokkyo: [],
            kourei: []
        }


        const end_reason: any[] = [] as any[]
        // ０でも表示したいので仮で値を入れておく
        months.forEach((month) => {
            let score = {} as any
            const row: (number | string)[] = []
            score["月"] = month;
            score["未選択"] = 0;
            end_reason.push(score);
        })
        if (this.state.branchScore.user_end_reason) {
            let currentMonth = "";
            let score = {} as any
            for (let i = 0; i < this.state.branchScore.user_end_reason.length; ++i) {
                const r = this.state.branchScore.user_end_reason[i]
                if (currentMonth != r["月"]) {
                    if (currentMonth != "") {
                        end_reason.push(score);
                        score = {} as any
                    }
                    currentMonth = r["月"];
                    score["月"] = r["月"];
                }
                if (r["理由"] == null || r["理由"] == "未選択") {
                    if (!("未選択" in score)) {
                        score["未選択"] = 0;
                    }
                    score["未選択"] += r["数"]
                }
                else {
                    score[r["理由"]] = r["数"];
                }
            }
            if (currentMonth != "") {
                end_reason.push(score);
                score = {} as any
            }
        }
        const incidents_data: any[] = [] as any[]
        if (this.state.branchScore.incidents) {
            let currentMonth = "";
            let score = {} as any
            for (let i = 0; i < this.state.branchScore.incidents.length; ++i) {
                const r = this.state.branchScore.incidents[i]
                if (currentMonth != r["月"]) {
                    if (currentMonth != "") {
                        incidents_data.push(score);
                        score = {} as any
                    }
                    currentMonth = r["月"];
                    score["月"] = r["月"];
                }
                if (r["種別"] == null || r["種別"] == "未選択") {
                    if (!("未選択" in score)) {
                        score["未選択"] = 0;
                    }
                    score["未選択"] += r["件数"]
                }
                else {
                    score[r["種別"]] = r["件数"];
                }
            }
            if (currentMonth != "") {
                incidents_data.push(score);
                score = {} as any
            }
        }

        const patients_hoken_data: any[] = [] as any[]
        if (this.state.branchScore.patient_count_hoken) {
            let currentMonth = "";
            let score = {} as any
            for (let i = 0; i < this.state.branchScore.patient_count_hoken.length; ++i) {
                const r = this.state.branchScore.patient_count_hoken[i]
                if (currentMonth != r["月"]) {
                    if (currentMonth != "") {
                        patients_hoken_data.push(score);
                        score = {} as any
                    }
                    currentMonth = r["月"];
                    score["月"] = r["月"];
                }
                if (r["種別"] == null || r["種別"] == "未選択") {
                    if (!("未選択" in score)) {
                        score["未選択"] = 0;
                    }
                    score["未選択"] += r["件数"]
                }
                else {
                    score[r["種別"]] = r["件数"];
                }
            }
            if (currentMonth != "") {
                patients_hoken_data.push(score);
                score = {} as any
            }
        }

        const houmon_count_sum_type: any[] = []
        const nameTable = {
            '医療保険/訪問看護': '医療訪看',
            '医療保険/リハビリ': '医療リハ',
            '精神/訪問看護': '精神訪看',
            '介護保険/訪問看護': '介護訪看',
            '介護保険/リハビリ': '介護リハ',
            'その他': 'その他'
        }
        if (this.state.branchScore.houmon_count_sum_type) {
            this.state.branchScore.houmon_count_sum_type.forEach(hcm => {
                let target = houmon_count_sum_type.find(x => x['月'] == hcm["月"])
                if (!target) {
                    houmon_count_sum_type.push({
                        '月': hcm["月"]
                    })
                    target = houmon_count_sum_type[houmon_count_sum_type.length - 1]
                }
                const name = (hcm["保険"] in nameTable) ? nameTable[hcm["保険"]] : "その他"
                if (name in target == false) {
                    target[name] = 0;
                }
                target[name] += hcm["訪問数"]
            })
        }

        const houmon_time: any = [] as any
        const user_dic: any = {} as any
        const houmon_time_avg = {
            cur_main: 0.0,
            cur_doukou: 0.0,
            cur_taiin_kanfa: 0.0,
            cur_tashokushu_kanfa: 0.0,
            past_main: 0.0,
            past_doukou: 0.0,
            past_taiin_kanfa: 0.0,
            past_tashokushu_kanfa: 0.0,
            cur_care_time: 0.0,
            past_care_time: 0.0,
            cur_care_count: 0.0,
            past_care_count: 0.0
        }

        if (this.state.branchScore.houmon_monthly_time_main) {
            const currentMonth = DateUtil.currentMonthStr();
            const pastMonth = DateUtil.pastMonthStr();
            const addNew = (r) => {
                if (r["id"] in user_dic) return;
                user_dic[r["id"]] = houmon_time.length;
                houmon_time.push({
                    id: r["id"],
                    name: r["名前"],
                    cur_main: 0.0,
                    cur_doukou: 0.0,
                    cur_taiin_kanfa: 0.0,
                    cur_tashokushu_kanfa: 0.0,
                    past_main: 0.0,
                    past_doukou: 0.0,
                    past_taiin_kanfa: 0.0,
                    past_tashokushu_kanfa: 0.0,
                    cur_care_time: 0.0,
                    past_care_time: 0.0,
                    cur_care_main_count: 0.0,
                    cur_care_doukou_count: 0.0,
                    past_care_main_count: 0.0,
                    past_care_doukou_count: 0.0
                })
            }

            for (let i = 0; i < this.state.branchScore.houmon_monthly_time_main.length; ++i) {
                const r = this.state.branchScore.houmon_monthly_time_main[i]
                addNew(r);
                //console.log(r)
                const idx = user_dic[r["id"]]
                if (r["月"] == currentMonth) houmon_time[idx].cur_main = parseFloat(r["訪問時間"]) || 0
                else if (r["月"] == pastMonth) houmon_time[idx].past_main = parseFloat(r["訪問時間"]) || 0
                if (r["月"] == currentMonth) houmon_time[idx].cur_care_main_count = parseFloat(r["訪問件数"]) || 0
                else if (r["月"] == pastMonth) houmon_time[idx].past_care_main_count = parseFloat(r["訪問件数"]) || 0
            }
            /*
            for (let j = 0; j < this.state.branchScore.houmon_monthly_time_kanfa.length; ++j) {
                const r = this.state.branchScore.houmon_monthly_time_kanfa[j]
                addNew(r);
                //console.log(r)
                const idx = user_dic[r["id"]]
                if (r["月"] == currentMonth) {
                    houmon_time[idx].cur_taiin_kanfa = parseFloat(r["退院カンファ"]) || 0
                    houmon_time[idx].cur_tashokushu_kanfa = parseFloat(r["多職種カンファ"]) || 0
                }
                else if (r["月"] == pastMonth) {
                    houmon_time[idx].past_taiin_kanfa = parseFloat(r["退院カンファ"]) || 0
                    houmon_time[idx].past_tashokushu_kanfa = parseFloat(r["多職種カンファ"]) || 0
                }
            }*/
            houmon_time.sort((a, b) => {
                if (a.cur_main < b.cur_main) return +1
                if (a.cur_main > b.cur_main) return -1
                return 0
            })
            for (let i = 0; i < houmon_time.length; ++i) {
                const h = houmon_time[i]
                houmon_time_avg.cur_main += h.cur_main
                houmon_time_avg.cur_doukou += h.cur_doukou
                houmon_time_avg.cur_taiin_kanfa += h.cur_tashokushu_kanfa
                houmon_time_avg.cur_tashokushu_kanfa += h.cur_tashokushu_kanfa
                houmon_time_avg.past_main += h.past_main
                houmon_time_avg.past_doukou += h.past_doukou
                houmon_time_avg.past_taiin_kanfa += h.past_taiin_kanfa
                houmon_time_avg.past_tashokushu_kanfa += h.past_tashokushu_kanfa
            }
            const n = houmon_time.length;
            if (n != 0) {
                houmon_time_avg.cur_main /= n
                houmon_time_avg.cur_doukou /= n
                houmon_time_avg.cur_taiin_kanfa /= n
                houmon_time_avg.cur_tashokushu_kanfa /= n
                houmon_time_avg.past_main /= n
                houmon_time_avg.past_doukou /= n
                houmon_time_avg.past_taiin_kanfa /= n
                houmon_time_avg.past_tashokushu_kanfa /= n
            }
            const tos = (x: number, y: number) => {
                return x.toFixed(1)
            }
            const toc = (x: number) => {
                return x.toFixed(0)
            }
            for (let i = 0; i < houmon_time.length; ++i) {
                const h = houmon_time[i]
                h.cur_care_time = toc(h.cur_main + h.cur_doukou)
                h.past_care_time = toc(h.past_main + h.past_doukou)
                h.cur_care_count = toc(h.cur_care_main_count + h.cur_care_doukou_count)
                h.past_care_count = toc(h.past_care_main_count + h.past_care_doukou_count)
                h.cur_main = tos(h.cur_main, houmon_time_avg.cur_main)
                h.cur_doukou = tos(h.cur_doukou, houmon_time_avg.cur_doukou)
                h.cur_taiin_kanfa = tos(h.cur_taiin_kanfa, houmon_time_avg.cur_taiin_kanfa)
                h.cur_tashokushu_kanfa = tos(h.cur_tashokushu_kanfa, houmon_time_avg.cur_tashokushu_kanfa)
                h.past_main = tos(h.past_main, houmon_time_avg.past_main)
                h.past_doukou = tos(h.past_doukou, houmon_time_avg.past_doukou)
                h.past_taiin_kanfa = tos(h.past_taiin_kanfa, houmon_time_avg.past_taiin_kanfa)
                h.past_tashokushu_kanfa = tos(h.past_tashokushu_kanfa, houmon_time_avg.past_tashokushu_kanfa)
            }

        }

        const kinkyu_data: any[] = []
        let kinkyu_rate = "0"
        let kinkyu_yakan_rate = "0"
        let kinkyu_count = 0
        let kinkyu_yakan_count = 0

        if (this.state.branchScore &&
            this.state.branchScore.kinkyu &&
            this.state.branchScore.kinkyu_yakan &&
            this.state.branchScore.houmon_count_monthly) {
            console.log(this.state.branchScore.kinkyu)
            console.log(this.state.branchScore.kinkyu_yakan)
            const collect = (d, name) => d.forEach(k => {
                let target = kinkyu_data.find(x => x['月'] == k["月"])
                if (!target) {
                    kinkyu_data.push({
                        '月': k["月"],
                        "日中": 0,
                        "夜間": 0
                    })
                    target = kinkyu_data[kinkyu_data.length - 1]
                }
                target[name] += k["件数"]
            })
            collect(this.state.branchScore.kinkyu, "日中")
            collect(this.state.branchScore.kinkyu_yakan, "夜間")

            kinkyu_data.forEach(x => {
                let sum = 0;
                this.state.branchScore.houmon_count_monthly_patient.filter(y => y["月"] == x["月"]).forEach(z => sum += z["訪問数"])
                x[`日中_label`] = `${x["日中"]}/${sum}件 (${(x["日中"] / sum * 100).toFixed(1)})%`
                x[`夜間_label`] = `${x["夜間"]}/${sum}件 (${(x["夜間"] / sum * 100).toFixed(1)})%`
                x["日中"] = (x["日中"] / sum * 100).toFixed(2);
                x["夜間"] = (x["夜間"] / sum * 100).toFixed(2);
            })
        }

        const kinkyu_contents = (value, name, props) => {
            return props.payload[name + "_label"]
        }


        //user_end_reason: [{ 月: "2019-12", 理由: null, 数: 83 }]
        //0: { 月: "2019-12", 理由: null, 数: 83 }
        return <div>
            <SubTitle title="事業所スコア" flipColor={true} />
            <div style={{ margin: "10px" }}>
                事業所：
                <Select
                    value={this.state.branch_id || 0}
                    autoWidth={true}
                    variant='outlined'

                    onChange={(e) => this.loadScore(e.target.value as any)}
                    className={classes.input}
                >
                    {false ? <MenuItem key={0} value={0}>全事業所</MenuItem> : null}
                    {
                        this.state.branches.map((t, idx) =>
                            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                        )
                    }
                </Select>
            </div>
            {
                false ?
                    <div style={{ cursor: "pointer" }}
                    >
                        <Button variant="contained" onClick={(e) => {
                            if (this.state.branch_id) {
                                this.loadScore(this.state.branch_id)
                            }
                        }}>出すことが求められるCSVデータ</Button>
                        <Button variant="contained" onClick={(e) => {
                            if (this.state.branch_id) {
                                this.loadScore(this.state.branch_id)
                            }
                        }}>期間で出したいデータ</Button>
                    </div>
                    : null
            }
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={12} lg={12}>
                    <div>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={6} md={2} lg={2} className={classes.graph_paper}>
                                <Graph.Text
                                    value={getCount(this.state.branchScore.patients_count, "人数")}
                                    title="利用者数"
                                    unit="人"
                                />
                            </Grid>
                            {
                                false ?
                                    <Grid item={true} xs={6} md={2} lg={2} className={classes.graph_paper}>
                                        <Graph.Text
                                            value={getCount(this.state.branchScore.staff_count, "人数")}
                                            title="スタッフ数"
                                            unit="人"
                                        />
                                    </Grid>
                                    : null
                            }
                            {this.state.branch_id === 0 &&
                                <Grid item={true} xs={12} md={10} lg={10} className={classes.graph_paper}>
                                    <Graph.HoumonMonthlyByBranch
                                        months={months}
                                        data={houmon_count_time_by_branch || []}
                                    />
                                </Grid>
                            }
                            <Grid item={true} xs={12} md={10} lg={10} className={classes.graph_paper}
                                style={{ marginLeft: this.state.branch_id === 0 ? "auto" : 0 }}
                            >
                                <Graph.HoumonMonthly
                                    months={months}
                                    data={houmon_count_time}
                                />
                            </Grid>

                            <Grid item={true} xs={12} md={6} lg={6} className={classes.graph_paper}>
                                <Graph.Table
                                    data={houmon_time}
                                    title="スタッフ別訪問時間数(h)"
                                    heads={[
                                        "名前",
                                        "直接ケア時間(先月)", "訪問件数(先月)",
                                        "直接ケア時間(今月)", "訪問件数(今月)"]}
                                    cols={[
                                        "name",
                                        "past_care_time", "past_care_count",
                                        "cur_care_time", "cur_care_count"
                                    ]}
                                    csvData={(() => {
                                        // NOTE: 表には今月先月のデータを表示するが、csvは指定期間のデータを出力する
                                        // ↓のような形式で出力する
                                        //      |       |2023-10|2023-11
                                        // name1|訪問時間|4.0     |3.0
                                        // name1|訪問件数|2       |1
                                        // name2|訪問時間|0       |2.0
                                        // name2|訪問件数|0       |1
                                        const data = this.state.branchScore.houmon_monthly_time_main
                                        if (data == null) return ""

                                        const rows: (string | number)[][] = []
                                        const tmp: { [name: string]: { [date: string]: { careTime: number, careCount: number } } } = {}

                                        data.forEach((d) => {
                                            if (tmp[d["名前"]]) {
                                                tmp[d["名前"]][d["月"]] = { careTime: d["訪問時間"], careCount: d["訪問件数"] }
                                            } else {
                                                tmp[d["名前"]] = { [d["月"]]: { careTime: d["訪問時間"], careCount: d["訪問件数"] } }
                                            }
                                        })
                                        months.sort((a, b) => (new Date(a)).getTime() - (new Date(b)).getTime())

                                        Object.keys(tmp).forEach((name) => {
                                            const row1: (string | number)[] = [name, "訪問時間"]
                                            months.forEach((month) => {
                                                if (tmp[name][month]) {
                                                    row1.push(tmp[name][month]["careTime"])
                                                } else {
                                                    row1.push(0)
                                                }
                                            })
                                            rows.push(row1)

                                            const row2: (string | number)[] = [name, "訪問件数"]
                                            months.forEach((month) => {
                                                if (tmp[name][month]) {
                                                    row2.push(tmp[name][month]["careCount"])
                                                } else {
                                                    row2.push(0)
                                                }
                                            })
                                            rows.push(row2)
                                        })

                                        const headers: string[] = ["", ""].concat(months)

                                        let result = headers.join(',') + "\n"
                                        rows.forEach((row) => {
                                            result += row.join(",") + "\n"
                                        })

                                        return result
                                    })()}
                                    onClick={(d) => {
                                        if (d && d.id) {
                                            this.loadUserScore(d.id)
                                            this.setState({ tabIndex: 1 })
                                        }
                                    }
                                    }
                                />
                            </Grid >
                            <Grid item={true} xs={12} md={6} lg={6} className={classes.graph_paper}>
                                <Graph.BarGraph2
                                    data={this.state.branchScore.youkaigo}
                                    title="要介護区分"
                                    name="区分"
                                    count={"人数"}
                                    color={["#969696", "#de9610", "#a0c238", "#65ace4", "#d06d8c", "#9460a0", "#0074bf", "#3838c2"]}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={8} lg={8} className={classes.graph_paper}>
                                <Graph.BarGraph2
                                    data={this.state.branchScore.user_count_zokusei_shikkan}
                                    title="利用者数（疾患分類別）"
                                    name="疾患分類"
                                    count={"人数"}
                                    color={["#de9610"]}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.PiChart
                                    data={user_types.zokusei}
                                    title="利用者数（属性別）"
                                    name="属性"
                                    count="人数"
                                    height="300px"
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.PiChart
                                    data={user_types.dokkyo}
                                    title="利用者数（独居）"
                                    name="独居"
                                    count="人数"
                                    height="300px"
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.PiChart
                                    data={user_types.kourei}
                                    title="利用者数（高齢世帯）"
                                    name="高齢世帯"
                                    count="人数"
                                    height="300px"
                                />
                            </Grid>

                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.BarGraph
                                    data={end_reason}
                                    title="終了利用者数"
                                    name="月"
                                    count={["未選択", "自宅看取り", "医療機関看取り", "入院", "入所", "卒業", "その他"]}
                                    color={["#969696", "#c93a40", "#a0c238", "#65ace4", "#d06d8c", "#9460a0", "#0074bf"]}
                                    csvData={(() => {
                                        const headers = ["月", "未選択", "自宅看取り", "医療機関看取り", "入院", "入所", "卒業", "その他"]

                                        if (end_reason == null) return ""

                                        const rows: (string | number)[][] = [headers]

                                        months.forEach((month) => {
                                            const data = end_reason.find(d => d["月"] === month)
                                            const row: (number | string)[] = []
                                            headers.forEach((header) => {
                                                if (header === "月") {
                                                    row.push(month)
                                                } else if (data == null) {
                                                    row.push(0)
                                                } else {
                                                    row.push(data[header] == null ? 0 : data[header])
                                                }
                                            })
                                            rows.push(row)
                                        })

                                        let result = ""

                                        transpose(rows).forEach(row => {
                                            result += row.join(',') + '\n'
                                        })

                                        return result
                                    })()}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.LineGraph
                                    data={this.state.branchScore.patients_add_monthly}
                                    title="利用者の受入数（月別）"
                                    name="月"
                                    count={["人数"]}
                                    color={["#d06d8c"]}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.BarGraph
                                    data={this.state.branchScore.patient_age}
                                    title="利用者数（年代別）"
                                    name="年代"
                                    count={["人数"]}
                                    color={["#Ea8923"]}
                                    csvData={(() => {
                                        if (this.state.branchScore.patient_age == null) return ''
                                        const data = this.state.branchScore.patient_age.slice()
                                        const headers: string[] = []
                                        const row: string[] = []
                                        data.forEach(d => {
                                            headers.push(d["年代"])
                                            row.push(d["人数"])
                                        })
                                        return headers.join(",") + '\n' + row.join(",") + "\n"
                                    })()}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.BarGraph
                                    data={incidents_data}
                                    title="事故苦情件数"
                                    name="月"
                                    count={["未選択", "インシデント", "アクシデント", "苦情"]}
                                    color={["#969696", "#c93a40", "#Ea8923", "#a0c238"]}
                                    csvData={(() => {
                                        const headers = ["月", "未選択", "インシデント", "アクシデント", "苦情"]

                                        if (incidents_data == null) return ""

                                        const rows: (string | number)[][] = [headers]

                                        months.forEach((month) => {
                                            const data = incidents_data.find(d => d["月"] === month)
                                            const row: (number | string)[] = []
                                            headers.forEach((header) => {
                                                if (header === "月") {
                                                    row.push(month)
                                                } else if (data == null) {
                                                    row.push(0)
                                                } else {
                                                    row.push(data[header] == null ? 0 : data[header])
                                                }
                                            })
                                            rows.push(row)
                                        })

                                        let result = ""

                                        transpose(rows).forEach(row => {
                                            result += row.join(',') + '\n'
                                        })

                                        return result
                                    })()}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.PiChart
                                    data={this.state.branchScore.incidents_detail}
                                    title="事故分類"
                                    name="種別"
                                    count="件数"
                                    height="300px"
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.BarGraph
                                    data={patients_hoken_data}
                                    title="基本となる保険別人数（これはバグってそうなので気にせず）"
                                    name="月"
                                    count={["未選択", "医療", "介護", "介護予防", "精神"]}
                                    color={["#969696", "#c93a40", "#Ea8923", "#a0c238", "#65ace4"]}
                                    csvData={(() => {
                                        const headers = ["月", "未選択", "医療", "介護", "介護予防", "精神"]

                                        if (patients_hoken_data == null) return ""

                                        const rows: (string | number)[][] = [headers]

                                        months.forEach((month) => {
                                            const data = patients_hoken_data.find(d => d["月"] === month)
                                            const row: (number | string)[] = []
                                            headers.forEach((header) => {
                                                if (header === "月") {
                                                    row.push(month)
                                                } else if (data == null) {
                                                    row.push(0)
                                                } else {
                                                    row.push(data[header] == null ? 0 : data[header])
                                                }
                                            })
                                            rows.push(row)
                                        })

                                        let result = ""

                                        transpose(rows).forEach(row => {
                                            result += row.join(',') + '\n'
                                        })

                                        return result
                                    })()}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.BarGraph
                                    data={kinkyu_data}
                                    title={`緊急訪問率(%)`}
                                    formatter={kinkyu_contents}
                                    name="月"
                                    count={["夜間", "日中"]}
                                    color={["#c93a40", "#Ea8923"]}
                                    csvData={(() => {
                                        const headers = ["月", "夜間", "日中"]

                                        if (kinkyu_data == null) return ""

                                        const rows: (string | number)[][] = [headers]

                                        months.forEach((month) => {
                                            const data = kinkyu_data.find(d => d["月"] === month)
                                            const row: (number | string)[] = []
                                            headers.forEach((header) => {
                                                if (header === "月") {
                                                    row.push(month)
                                                } else if (data == null) {
                                                    row.push(0)
                                                } else {
                                                    row.push(data[header] == null ? 0 : data[header])
                                                }
                                            })
                                            rows.push(row)
                                        })

                                        let result = ""

                                        transpose(rows).forEach(row => {
                                            result += row.join(',') + '\n'
                                        })

                                        return result
                                    })()}
                                />
                            </Grid>

                            <Grid item={true} xs={12} md={4} lg={4} className={classes.graph_paper}>
                                <Graph.HoumonMonthlyKirokuType
                                    data={houmon_count_sum_type}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                <Graph.BarGraph2
                                    data={this.state.branchScore.omaha_problems}
                                    title="オマハ問題"
                                    name="問題名"
                                    count={"人数"}
                                    color={["#444"]}
                                />
                            </Grid>
                        </Grid >
                    </div >
                </Grid >
            </Grid >
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={8} lg={8}>
                    <div>
                        {
                            false ?

                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={12} md={12} lg={12}>
                                        <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                            <Graph.BarGraph2
                                                data={this.state.branchScore.omaha_problems}
                                                title="オマハ問題"
                                                name="問題名"
                                                count={"人数"}
                                                color={["#444"]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                : null
                        }

                        <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                            <div style={{ padding: "10px", border: "1px solid", borderRadius: "5px" }}>
                                期間：
                                <Select
                                    value={this.state.compareTerm}
                                    autoWidth={true}
                                    disabled={this.state.loadingKBS}
                                    variant='outlined'
                                    onChange={(e) => {
                                        if (this.state.branch_id) {
                                            this.loadScoreBranchKbs(this.state.branch_id, e.target.value as any)
                                        }
                                    }
                                    }
                                    className={classes.input}
                                >
                                    <MenuItem value="１ヶ月">１ヶ月</MenuItem>
                                    <MenuItem value="３ヶ月">３ヶ月</MenuItem>
                                    <MenuItem value="６ヶ月">６ヶ月</MenuItem>
                                    <MenuItem value="１２ヶ月">１２ヶ月</MenuItem>
                                </Select>
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                        <Graph.TableKbs
                                            data={this.state.branchOmahaKbsScore.shikkan}
                                            title="KBS遷移（疾患分類毎）"
                                            rank={this.state.branchOmahaKbsScore.shikkan_ranking}
                                            name="疾患分類"
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                        <Graph.TableKbs
                                            data={this.state.branchOmahaKbsScore.problem}
                                            title="KBS遷移（問題毎)"
                                            rank={null}
                                            name="問題"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                </Grid>

                <Grid item={true} xs={12} md={4} lg={4}>
                    <div>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12} md={12} lg={12} className={classes.graph_paper}>
                                <div style={{ padding: "10px", border: "1px solid", borderRadius: "5px" }}>
                                    疾患分類：
                                    <Select
                                        value={this.state.rankingShikkan}
                                        autoWidth={true}
                                        variant='outlined'
                                        onChange={(e) => {
                                            this.setState({ rankingShikkan: e.target.value as any })
                                            this.loadRanking(e.target.value as any)
                                        }}
                                        className={classes.input}
                                    >
                                        <MenuItem key={0} value="全て">全て</MenuItem>
                                        {
                                            this.state.branchScore.user_count_zokusei_shikkan ?
                                                this.state.branchScore.user_count_zokusei_shikkan.map((z, idx) =>
                                                    <MenuItem key={idx} value={z["疾患分類"]}>{z["疾患分類"]}</MenuItem>
                                                ) : null
                                        }
                                    </Select>
                                    <Graph.Table
                                        data={this.state.branchScore.omaha_problem_x_intervention}
                                        title="オマハ問題-介入ランキング(TOP10)"
                                        heads={["問題名", "カテゴリ", "介入", "人数"]}
                                        cols={["問題名", "カテゴリ", "介入", "人数"]}
                                        csvData={(() => {
                                            const headers = ["問題名", "カテゴリ", "介入", "人数"]
                                            const cols = headers.slice()
                                            const data = this.state.branchScore.omaha_problem_x_intervention

                                            let result = headers.join(',') + "\n"
                                            if (data == null) return result
                                            data.forEach(d => {
                                                const arr: string[] = []
                                                cols.forEach(col => {
                                                    arr.push(d[col])
                                                })
                                                result += arr.join(',') + "\n"
                                            })
                                            return result
                                        })()}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </Grid>
                {
                    false ?
                        <Grid item={true} xs={12} md={12} lg={12}>
                            <PatientMap branch_id={this.state.branch_id} score={this.state.branchScore.geocode} />
                        </Grid>
                        : null
                }
            </Grid>
            {
                false ?

                    <div>
                        事業所毎＋法人全体<br />
                        みんな＝他の人の個人スコアに潜れる<br />
                        <br />
                        利用者数推移（保険別：医療か介護か別）<br />
                        ・上の割合のみ<br />
                        ・単純な推移<br />
                        <br />
                        訪問件数（保険別：医療か介護か別、職種別：記録に紐づくユーザの職種）<br />
                        ・上の割合のみ<br />
                        ・単純な推移<br />
                        <br />
                        件数、時間<br />
                        ・退院カンファ<br />
                        ・サービス担当者会議<br />
                        <br />
                        ・新規依頼数<br />
                        ・「地域社会資源との繋がり」の関係の何か<br />
                        ・疾患分類（割合）<br />
                        ・小児と成人の割合（１８歳未満かどうか）<br />
                        ・終了<br />
                        全体に対する何％、何人、時系列で<br />
                        終了の理由割合<br />
                        ・定期訪問の割合<br />
                        ・緊急訪問の割合<br />
                        全体に対しての割合と、全体に対しての夜間の割合<br />
                        コール数に対して、緊急出動した割合<br />
                        「夜間」の定義は事業所で異なる<br />
                        <br />
                        ・常勤換算数<br />
                        <br />
                        ・労働生産性<br />
                        ー訪問件数/常勤換算数<br />
                        ー総労働時間/訪問時間<br />
                        （訪問時間にはサービス担当者会議、退院カンファも含む）<br />
                        <br />
                        ・残業分布<br />
                        ・オマハ系（成瀬先生と相談）<br />
                        ①計画の内訳の円グラフ<br />
                        ②計画別の改善しやすさとか？<br />
                        ③疾患・問題・計画の組み合わせランキング→トレンド<br />
                        ④問題と計画の組み合わせランキング→トレンド<br />
                        ・KBSのトレンド<br />
                        オマハ系も事業所毎比較がよさそうかも<br />
                        ・一人暮らしかどうか<br />
                        ・インシデント・アクシンデント数、種類<br />
                        <br />
                        ・特別管理加算（件数）<br />
                        ・ターミナルケア加算（件数、介護・医療）<br />
                        <br />
                    </div>
                    : null
            }
        </div >
    }


    private loadCountReport() {
        if (!this.state.branch_id) return;
        this.setState({
            countReportLoading: true
        })
        HokanApi.getCountReport(this.state.branch_id, this.state.reportMonth, this.state.reportMonth).then(res => {
            console.log(res)
            this.setState({ countReport: res.data, countReportLoading: false })
        }).catch(err => {
            console.log(err)
            this.setState({ countReportLoading: false })
        })
    }
    private loadOmahaReport() {
        if (!this.state.branch_id) return;
        /*
        this.setState({
            omahaReportLoading: true
        })
        HokanApi.getOmahaReport(this.state.branch_id, this.state.omahaStartDate, this.state.omahaEndDate).then(res => {
            //console.log(res)
            this.downloadZip(res.data)
            this.setState({ omahaReport: res.data, omahaReportLoading: false })
        }).catch(err => {
            console.log(err)
            this.setState({ omahaReportLoading: false })
        })*/
        HokanApi.generateOmahaScore(this.state.branch_id, this.state.omahaStartDate, this.state.omahaEndDate).then(res => {
            console.log(res)
            //this.downloadZip(res.data)
            this.setState({ omahaReportLoading: true, omahaReportUrl: "" })
            let i = 1;
            let iid = window.setInterval(() => {
                HokanApi.getDocumentPdfJob(res.data.job_id).then(r => {
                    const d = r.data
                    if (d.has_error) {
                        this.setState({ omahaReportLoading: false, omahaErrorMsg: "エラー" + d.message })
                        clearInterval(iid);
                    }
                    else if (d.done_at && d.file_urls) {
                        this.setState({ omahaReportLoading: false, omahaReportUrl: d.file_urls[0], omahaErrorMsg: "" })
                        clearInterval(iid);
                    }
                    else this.setState({ omahaReportLoading: true, omahaErrorMsg: d.message })
                }).catch(err => {
                    console.log(err)
                    this.setState({ omahaReportLoading: false, omahaErrorMsg: "エラー" })
                    clearInterval(iid);
                })
                i++;
            }, 5000);
        })
            .catch(err => {
                this.setState({ omahaReportLoading: false, omahaErrorMsg: "エラー", omahaReportUrl: null })
            })
    }

    downloadZip(data: any): void {
        const blob = new Blob([data], { 'type': 'application/zip' });
        const url = (window.URL || window.webkitURL);
        const blobURL = url.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = blobURL;
        a.type = 'application/zip';
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob)
        }
        else if (window.URL && window.URL.createObjectURL) {
            // for Firefox
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else {
            // for Chrome / safari
            a.click();
        }
    }

    private countReport() {
        const { classes } = this.props
        return <div>
            <div style={{ margin: "10px" }}>
                事業所：
                <Select
                    value={this.state.branch_id || ''}
                    autoWidth={true}
                    variant='outlined'
                    disabled={this.state.countReportLoading}
                    onChange={(e) => this.setState({ branch_id: e.target.value as any })}
                    className={classes.input}
                >
                    {
                        this.state.branches.map((t, idx) =>
                            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                        )
                    }
                </Select>
            </div>
            <EditableText
                label=""
                editMode={this.state.countReportLoading == false}
                name={"report_month"}
                fullwidth={false}
                inputType="month"
                multiline={false}
                value={this.state.reportMonth}
                setData={(n, v) => this.setState({ reportMonth: v })} />
            <Button
                variant="contained"
                color="primary"
                style={{ margin: "5px" }}
                disabled={this.state.countReportLoading}
                onClick={(e) => this.loadCountReport()}
            >
                {
                    this.state.countReportLoading ?
                        "しばらくお待ち下さい（最大10分程かかります）"
                        : "まで１年分読み込み"
                }
            </Button>
            <br /><br />
            {
                this.state.countReportLoading ?
                    <Loading key="loading" />
                    : <Graph.CountReport key="report" data={this.state.countReport} />
            }

            <div style={{ margin: "10px" }}>
                期間：
                <EditableText
                    label=""
                    editMode={true}
                    name={"omahaStartDate"}
                    fullwidth={false}
                    display="inline-block"
                    inputType="date"
                    multiline={false}
                    value={this.state.omahaStartDate}
                    setData={(n, v) => {
                        this.setState({ omahaStartDate: v })
                    }} />
                〜
                <EditableText
                    label=""
                    editMode={true}
                    name={"omahaEndDate"}
                    fullwidth={false}
                    display="inline-block"
                    inputType="date"
                    multiline={false}
                    value={this.state.omahaEndDate}
                    setData={(n, v) => {
                        this.setState({ omahaEndDate: v })
                    }} />
            </div>
            <Button
                variant="contained"
                color="primary"
                style={{ margin: "5px" }}
                disabled={this.state.omahaReportLoading}
                onClick={(e) => this.loadOmahaReport()}
            >
                {
                    this.state.omahaReportLoading ?
                        "ブラウザを閉じずしばらくお待ち下さい（最大数十分程かかります）" + this.state.omahaErrorMsg
                        : "オマハデータ"
                }
            </Button>
            {
                this.state.omahaReportUrl ?
                    <a href={this.state.omahaReportUrl} target="_blank">{this.state.omahaReportUrl}</a>
                    : null
            }
        </div>
    }

}

interface DoukouProps {
    user_id: number
    start: string
    end: string
}

interface DoukouState {
    loading: boolean
    message: string
    posting: boolean
    posting_idx: number
    doukouList: HokanApi.PagingResponse<Model.Score.KangoKirokuDoukou>
    me: Model.User
    query: Query
}


class Query {
    is_learn: boolean = true
    is_teach: boolean = true
    patient: Model.Patient.Patient = new Model.Patient.Patient()
}

class DoukouShinchoku extends React.Component<DoukouProps, DoukouState>
{
    constructor(props) {
        super(props)
        const q = new Query()
        const dl = new HokanApi.PagingResponse<Model.Score.KangoKirokuDoukou>()
        dl.page = 0;
        this.state = {
            loading: false,
            message: "",
            posting: false,
            posting_idx: -1,
            me: new Model.User(),
            doukouList: dl,
            query: q,
        }
    }

    public componentDidMount() {
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
        })
        this.load(this.props.user_id);
    }

    public componentWillReceiveProps(nextProps) {
        if (nextProps.user_id != this.props.user_id) {
            this.load(nextProps.user_id, 0, 5)
        }
    }

    public load(user_id: number, page: number | null = null, per: number | null = null) {
        this.setState({ loading: true })
        if (page == null) page = this.state.doukouList.page - 1
        if (per == null) per = this.state.doukouList.per
        HokanApi.getDoukous(user_id, page + 1, per, this.props.start, this.props.end, this.state.query).then(res => {
            res.data.data = res.data.data.map(d => {
                d = Model.Score.KangoKirokuDoukou.Load(d)
                return d;
            })
            this.setState({ doukouList: res.data, loading: false })
        }).catch(e => {
            console.log(e)
            this.setState({ loading: false })
        })
    }

    public commit(data: Model.Score.DoukouShinchoku, idx: number) {
        this.setState({ message: "", posting: true, posting_idx: idx })
        const d = new Model.Score.DoukouShinchokuPost(data)
        if (data.id > 0) {
            HokanApi.putDoukou(d).then(res => {
                this.setState({ message: "更新しました", posting: false })
            }).catch(e => {
                console.log(e)
                this.setState({ message: "エラー", posting: false })
            })
        }
        else {
            HokanApi.postDoukou(d).then(res => {
                this.setState({ message: "更新しました", posting: false })
                this.load(this.props.user_id)
            }).catch(e => {
                console.log(e)
                this.setState({ message: "エラー：１度ページを更新してください。", posting: false })
            })
        }
    }

    public render() {
        const sa = new StoreAccessor(this, true)
        const saLock = new StoreAccessor(this, false)
        const saQ = sa.generateChild(["query"])
        const paging = <Pagination
            limit={this.state.doukouList.per}
            offset={(this.state.doukouList.page - 1) * this.state.doukouList.per}
            total={this.state.doukouList.count}
            disabled={this.state.loading}
            onClick={(e, offset) => {
                this.load(this.props.user_id, offset / this.state.doukouList.per, this.state.doukouList.per)
            }}
        />
        const element_l = [
            {
                name: "種別", content: saQ.checkListC([], [
                    { key: "is_learn", label: "教育を受けた訪問" },
                    { key: "is_teach", label: "教育をした訪問" }
                ], false, false)
            },
            {
                name: "利用者", content: saQ.patient("patient", "patient_dialog")
            },
            {
                name: "検索", content: <Button color="primary" variant="contained"
                    disabled={this.state.loading}
                    onClick={(e) => { this.load(this.props.user_id) }} >検索</Button>
            }
        ]
        const elements = (src: Model.Score.KangoKirokuDoukou, idx: number) => {
            let dl = src.doukou
            return dl.map((d, di) => {
                const cat = ["doukouList", "data", idx, "doukou", di]
                const cat_content = ["doukouList", "data", idx, "doukou", di, "content"]
                const isMyMain = src.main_user && src.main_user.id == this.state.me.id
                const content = d.content as Model.Score.DoukouShinchokuContent
                const isMyEdu = d.user && d.user.id == this.state.me.id
                return [
                    { name: "教育を受けたスタッフ", content: <div>{d.user.id ? d.user.name : ""}</div> },
                    { name: "利用者", content: <div>{src.patient ? src.patient.name : ""}</div> },
                    { name: "属性", content: <div>{src.patient ? src.patient.basic.zokusei : ""}</div> },
                    { name: "何回目", content: sa.numberC(cat, "times") },
                    { name: "同行ステータス", content: <div>{d.step}</div> },
                    { name: "どこまで、何を実施したか (コミュニケーションも含む)\n課題/不安/上手くいったこと", content: sa.textC(cat_content, "naiyou") },
                    { name: " ", content: saLock.textC(cat_content, "kadai") },
                    //{ name: "対面振り返り有無", content: sa.checkC(cat_content, "hurikaeri") },
                    { name: "同行した先輩", content: <div>{src.main_user ? src.main_user.name : ""}</div> },
                    //{ name: "次の訪問ステップ", content: sa.textC(cat_content, "doukou_hyouka") },
                    { name: "同行者コメント", content: sa.textC(cat_content, "doukou_comment") },
                    //{ name: "単独OK", content: sa.checkC(cat, "tandoku_ok") },
                    { name: "記録書II", content: src.patient ? <a href={`/patients/${src.patient.id}?kid=${d.kango_kiroku_id}#activity`} target="_blank">別タブで開く</a> : <div /> }
                ]
            })
        }

        return <div>
            <StandardTable title=''>
                <Grid container={true} spacing={0}>
                    <Grid item={true} xs={12} md={12} lg={12}>
                        <Col2Table body={element_l} />
                    </Grid>
                </Grid>
            </StandardTable>
            {paging}
            {
                this.state.doukouList.data.map((d, idx) => {
                    return elements(d, idx).map((x, xi) => {
                        const isMyMain = d.main_user && d.main_user.id == this.state.me.id
                        const isMyEdu = d.doukou[xi].user && d.doukou[xi].user.id == this.state.me.id
                        return <StandardTable title={`${d.main_user && d.main_user.id == this.props.user_id ? "教育した" : "教育を受けた"} ${DateUtil.toJpDateString(d.time_start)}`} key={`d_${idx}`}>
                            <Grid container={true} spacing={0}>
                                <Grid item={true} xs={12} md={12} lg={12}>
                                    <Col2Table body={x} />
                                    {
                                        isMyMain || isMyEdu ?
                                            <div style={{ padding: "10px" }}>
                                                <Button color="primary" variant="contained"
                                                    disabled={this.state.posting && xi == this.state.posting_idx}
                                                    onClick={(e) => this.commit(d.doukou[xi], idx)}>
                                                    {idx == this.state.posting_idx && this.state.posting ? "送信中" : "確定"}
                                                </Button>
                                                {
                                                    idx == this.state.posting_idx ?
                                                        <div>{this.state.message}</div>
                                                        : null
                                                }
                                            </div>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </StandardTable>
                    })
                })
            }
            {paging}
        </div>
    }
}


export default Styles.withStyles(styles)(ScorePage)
