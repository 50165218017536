import { OrganizationTanto } from '..';

export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
    public target_month = "0000-00"
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public branch_name: string = ""
    public houmon_yotei: string = ""
    public comment: string = ""
    public eisei_hituyou: string = ""
    public eisei_naiyo: string = ""
    public eisei_zairyo: string = ""
    public kanri_name: string = ""
    public mokuhyo: string = ""
    public pat_address: string = ""
    public pat_birthday: string = ""
    public pat_youkaigo: string = ""
    public problem: string = ""
    public problem_ass: string = ""
    public problem_date: string = ""
    public sub_title_1: string = ""
    public sub_title_2: string = ""
    public tanto1_name: string = ""
    public tanto2_name: string = ""
    public tanto1_role: string = ""
    public tanto2_role: string = ""
    public tanto3_name: string = ""
    public tanto4_name: string = ""
    public tanto3_role: string = ""
    public tanto4_role: string = ""
    public title: KeikakushoType = KeikakushoType.None
    public houkoku_saki: OrganizationTanto[] = [];
}

export enum KeikakushoType {
    None = "未選択",
    Houkan = "訪問看護計画書",
    SeishinHoukan = "精神科訪問看護計画書",
    KaigoYobouHoukan = "介護予防訪問看護計画書",
}

export class Checks {
    public check_ari: boolean = false
    public check_nashi: boolean = true
}