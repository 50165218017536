import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle';
import { Grid, Button } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import * as Colors from '../components/Colors'
import EditableText from '../components/EditableText';
import EditableList from '../components/EditableList';
import StandardTable from '../components/StandardTable';
import Col2Table from '../components/Col2Table';
import DateUtil from '../util/DateUtil';
import { StoreAccessor } from '../util/StoreUtil';
import Loading from '../components/Loading';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {

    },
    textField: {
        width: "90%"
    },
    input: {
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto"
    },
    userList: {
        width: "80%",
        margin: "auto",
        padding: "50px"
    }
});

interface Params {
    incident_id: string
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    loading: boolean,
    data: Model.Incident.Incident,
    isEditing: boolean
}


// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "IncidentPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            data: new Model.Incident.Incident(),
            isEditing: true
        }
    }

    public incidentId = () => {
        return parseInt(this.props.match.params.incident_id)
    }

    public componentDidMount = () => {
        this.setState({ loading: true })
        if (this.incidentId() > 0) {
            HokanApi.incident.get(this.incidentId()).then(x => {
                this.setState({ data: Model.Incident.Incident.load(x.data), loading: false })
            })
                .catch(e => {
                    console.log(e)
                    this.setState({ loading: false })
                })
        }
        else {
            HokanApi.me().then(x => {
                const d = this.state.data;
                d.write_user = x.data;
                if (x.data.branch) d.branch = x.data.branch;
                this.setState({ data: d, loading: false })
            })
                .catch(e => {
                    console.log(e)
                    this.setState({ loading: false })
                })
        }
    }

    onCommit() {
        const d = this.state.data;
        const f = (d.id > 0 ? HokanApi.incident.put : HokanApi.incident.post)
        f.call(this, new Model.Incident.IncidentPost(d)).then(x => {
            this.onBack();
        }).catch(e => {
            console.log(e)
        })
    }

    onBack() {
        this.props.history.goBack();
    }

    onDelete() {
        HokanApi.incident.delete(this.incidentId()).then(x => {
            this.onBack();
        }).catch(e => {
            console.log(e)
        })
    }

    private getCatValue(cat: any[], name: string) {
        let res = this.state.data;
        for (const x in cat) {
            res = res[cat[x]]
        }
        return res[name]
    }

    private setCatValue(cat: any[], name: string, value) {
        let res = this.state.data;
        for (const x in cat) {
            res = res[cat[x]]
        }
        res[name] = value
        this.setState({ data: this.state.data })
    }

    private getJikoDetailEnum() {
        const t = this.state.data.jiko_type
        if (t == Model.Incident.JikoType.Iryo) {
            return Model.Incident.JikoTypeDetailIryo
        }
        if (t == Model.Incident.JikoType.Kea) {
            return Model.Incident.JikoTypeDetailKea
        }
        if (t == Model.Incident.JikoType.Koutsu) {
            return Model.Incident.JikoTypeDetailKoutsu
        }
        if (t == Model.Incident.JikoType.Tounan) {
            return Model.Incident.JikoTypeDetailTounan
        }
        if (t == Model.Incident.JikoType.Other) {
            return Model.Incident.JikoTypeDetailOther
        }
        return Model.Incident.JikoTypeDetailNotSelected;
    }

    public render() {
        const editableText = (cat: any[], name, inputType = "text") => <EditableText
            label=""
            editMode={this.state.isEditing}
            name={name}
            inputType={inputType}
            value={this.getCatValue(cat, name)}
            setData={(n, v) => this.setCatValue(cat, n, v)} />
        const editableListRaw = (cat: any[], name, list: { key: any, value: any }[], callback: (v) => (void) = (v) => { }) => <EditableList
            label=""
            editMode={this.state.isEditing}
            name={name}
            list={list}
            value={this.getCatValue(cat, name)}
            setData={(n, v) => { callback(v); this.setCatValue(cat, n, v) }} />
        const editableList = (cat: any[], name, listEn, callback = (v) => { }) => editableListRaw(cat, name, DateUtil.enumToArray(listEn), callback)

        const editableDate = (cat: any[], name) => <EditableText
            label=""
            editMode={this.state.isEditing}
            name={name}
            inputType="datetime-local"
            multiline={false}
            value={this.getCatValue(cat, name)}
            setData={(n, v) => this.setCatValue(cat, n, v)} />

        const sa = new StoreAccessor(this, true, "data")

        const elements_basic = [
            { name: "記入者", content: <span>{this.state.data.write_user ? this.state.data.write_user.name : "-"}</span> },
            { name: "事業所", content: sa.branch("branch") },
            { name: "発生日時", content: editableDate([], "time_occured") },
            { name: "報告の種類", content: editableList([], "case_type", Model.Incident.HoukokuType) },
            //{ name: "優先度", content: editableList([], "priority", Model.Incident.Priority) },
            { name: "紐づく利用者", content: sa.patient("patient", "open_pat") },
            { name: "主に関与したスタッフ", content: sa.user("main_user") },
            { name: "対象者分類", content: editableList([], "taisho_type", Model.Incident.TaishoType) },
            { name: "苦情の内容と、その対応", content: editableText(["detail"], "content") },
            {
                name: "事故分類", content: editableList([], "jiko_type", Model.Incident.JikoType,
                    (v) => {
                        if (v != this.state.data.jiko_type) {
                            this.state.data.jiko_detail = "未選択"
                            this.setState({ data: this.state.data })
                        }
                    })
            },
            { name: "事故分類詳細", content: editableList([], "jiko_detail", this.getJikoDetailEnum()) },
            { name: "影響レベル", content: editableList([], "eikyo_level", Model.Incident.EikyoLevel) },
            { name: "詳細な状況", content: editableText(["detail"], "content_detail") },
            { name: "要因", content: editableText(["detail"], "content_cause") },
            { name: "今後の対策", content: editableText(["detail"], "content_taisaku") },
            { name: "備考", content: editableText(["detail"], "comment") },
        ]
        const elements = [
            { name: '内容', content: elements_basic },
        ]
        if (this.state.loading) {
            return <Loading />
        }
        return <div>
            <SubTitle title="インシデント等新規登録" />
            {
                elements.map(elm => {
                    return <StandardTable key={elm.name} title={elm.name}>
                        <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12} md={12} lg={12}>
                                <Col2Table body={elm.content} />
                            </Grid>
                        </Grid>
                    </StandardTable>
                })
            }
            <div>
                <Button color="default" variant="outlined"
                    onClick={(e) => this.onBack()} >
                    キャンセル
                </Button>
                {"　　"}
                <Button color="primary" variant="contained"
                    onClick={(e) => this.onCommit()} >
                    確定
                </Button>
            </div>
            <br />
            <br />
            <div>
                {
                    this.incidentId() > 0 ?
                        <Button color="secondary" variant="contained"
                            onClick={(e) => this.onDelete()} >
                            削除
                        </Button>
                        : null
                }
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))