import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import Loading from '../components/Loading';
import { Button } from '@material-ui/core';
import * as Model from '../models';
import * as HokanApi from '../api/hokan'
//import EditableList from '../components/EditableList';
//import DateUtil from '../util/DateUtil';
//import EditableCheckList from '../components/EditableCheckList';
import ShinkiIraiCheck from '../components/ShinkiIraiCheck'
import { StoreAccessor } from '../util/StoreUtil'

import Message from '../components/message/Message'
import MessageUserList from '../components/message/MessageUserList'

const styles = () => Styles.createStyles({
    root: {
        margin: "0px",
        width: "100%",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    innerTable: {
        padding: '5px'
    },
    tooltipRoot: {
        display: "inline-block",
        marginLeft: "10px"
    },
    tooltipDiv: {
        fontSize: "0.9rem",
        color: "white"
    },
    tooltipTitle: {
        fontWeight: "bold",
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    tooltipLine: {
        borderTop: "solid",
        borderWidth: "1px",
        borderColor: "lightgray",
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    filter_button_d: {
        margin: "10px"
    }
});

interface Params {
    patient_id: string
}
interface Props extends PageProps<Params>, Styles.WithStyles {

}

interface LocalState {
    isEditing: boolean,
    loading: boolean,
    data: Model.Patient.Patient
}

// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "ShinkiIraiPage"
    constructor(props: Props) {
        super(props)
        const pat = new Model.Patient.Patient()
        pat.is_shinki = true
        this.state = {
            isEditing: true,
            loading: false,
            data: pat
        }
    }

    public componentDidMount = () => {
        const patient_id = parseInt(this.props.match.params.patient_id)
        this.setState({ loading: true })
        if (isNaN(patient_id) == false && patient_id > 0) {
            HokanApi.getPatient(patient_id)
                .then(x => {
                    const p = Model.Patient.Patient.load(x.data)
                    this.setState({ data: p, loading: false })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
        else {
            HokanApi.me().then(x => {
                const p = this.state.data
                const b = x.data.branch
                if (b) {
                    p.branch = b
                    p.branch_id = b.id
                }
                this.setState({ data: p, loading: false })
            })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
    }

    public goBack() {
        this.props.history.push("/patients#new")
    }

    public doCommit(pat: Model.Patient.PatientPost) {
        if (pat.id == 0) {
            HokanApi.postPatient(pat).then(x => {
                this.goBack();
            })
        }
        else {
            HokanApi.putPatient(pat.id, pat).then(x => {
                this.goBack();
            })
        }
    }


    public commit() {
        const pat = this.state.data;
        const patPost = new Model.Patient.PatientPost(pat)
        pat.status = "利用中"
        this.doCommit(patPost);
    }

    public commitCansel() {
        const pat = this.state.data;
        const patPost = new Model.Patient.PatientPost(pat)
        patPost.is_canseled = true;
        this.doCommit(patPost);
    }
    public commitCanselCansel() {
        const pat = this.state.data;
        const patPost = new Model.Patient.PatientPost(pat)
        patPost.is_canseled = false;
        this.doCommit(patPost);
    }

    public commitUkeire() {
        const pat = this.state.data;
        const patPost = new Model.Patient.PatientPost(pat)
        patPost.is_shinki = false;
        this.doCommit(patPost);
    }

    public render() {
        const { classes } = this.props


        if (this.state.loading) return <Loading />
        return <div className={classes.root}>
            <div>
                <Message
                    is_shinki={true}
                    onUpdate={ () =>  {} }
                    message_id={0}
                    is_wc={true}
                    wc_name={this.state.data.name}
                    wc_number={this.state.data.id}
                    wc_branch_id={this.state.data.branch_id} />
            </div>
            <ShinkiIraiCheck
                isKettei={false}
                accessor={new StoreAccessor(this, true, "data")}
            />
            {
                this.state.data.is_canseled ?
                    <div>この新規依頼はキャンセルされています</div>
                    : null
            }
            <div>
                <Button color="primary" variant="contained"
                    onClick={(e) => this.commit()}
                    className={classes.filter_button_d}>
                    保存
                </Button>
                {
                    this.state.data.is_canseled ?
                        <Button color="secondary" variant="outlined"
                            onClick={(e) => this.commitCanselCansel()}
                            className={classes.filter_button_d}>
                            キャンセルの取り消し
                        </Button> :
                        [
                            <Button color="secondary" variant="outlined"
                                onClick={(e) => this.commitCansel()}
                                className={classes.filter_button_d}>
                                削除
                            </Button>,
                            <Button color="default" variant="outlined"
                                onClick={(e) => this.commitUkeire()}
                                className={classes.filter_button_d}>
                                受入決定
                            </Button>
                        ]
                }
            </div>
            <Button color="default" variant="outlined"
                onClick={(e) => this.goBack()}
                className={classes.filter_button_d}>
                戻る
            </Button>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))