import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";
import { MaterialConstType } from "../../models/Forms/RespiratorySystemB";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 71;
  }

  public createContent(): any {
    const res = new FormModel.RespiratorySystemB.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {}

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const qList = [
      "呼吸器のメンテナンス",
      "回路交換",
      "回路の補充",
      "（呼吸器・酸素濃縮器）フィルター洗浄や交換",
      "気管カニューレの交換",
      "マジックバンドや綿ひもの交換",
      "衛生材料等の残数・使用期限チェック",
      "家族などが蘇生バックを正しく使用",
    ];

    const materialList = [
      "呼吸器回路",
      "気管カニューレ",
      "潤滑油",
      "シリンジ",
      "Yガーゼ",
      "フレックスチューブ",
      "蒸留水",
      "アルコール",
      "カット綿",
      "吸引チューブ",
      "人工鼻",
      "マジックバンド",
    ];

    const emergencyList = [
      "蘇生バック",
      "気管カニューレ",
      "シリンジ",
      "潤滑油",
    ];

    const goOutList = ["呼吸器回路", "ガムテープ"];

    const elements_t = [
      {
        name: "定期的に確認する内容",
        content: (
          <div>
            {qList.map((q, i) => {
              return (
                <>
                  <hr style={{ margin: 0 }} />
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <div>{q}</div>
                    <div
                      style={{
                        display: "inline",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 5,
                      }}
                    >
                      <label
                        style={{
                          display: "inline",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        定期
                        {saV.text(`frequency_${i + 1}`, "")}
                      </label>
                      <label
                        style={{
                          display: "inline",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        予定日
                        {saV.date(`scheduled_date_${i + 1}`)}
                      </label>
                      <label
                        style={{
                          display: "inline",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        実施日{saV.date(`date_${i + 1}`)}
                      </label>
                      <label
                        style={{
                          display: "inline",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        署名{saV.text(`sign_${i + 1}`, "")}
                      </label>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ),
      },
      {
        name: "衛生材料",
        content: (
          <div>
            {materialList.map((m, i) => (
              <>
                <hr style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    {saC.check(`check_material_${i + 1}`)}
                    {m}
                  </label>
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    定数
                    {saV.text(`material_const_${i + 1}`)}
                  </label>
                </div>
              </>
            ))}
            <div>
              <hr style={{ marginTop: 0, marginBottom: 10 }} />
              <label>
                その他
                {saV.text(
                  "material_others",
                  "",
                  "text",
                  "block",
                  true,
                  "100px"
                )}
              </label>
              <label
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: 4,
                  marginTop: 10,
                }}
              >
                定数
                {saV.text(`material_const_others`)}
              </label>
            </div>
          </div>
        ),
      },
      {
        name: "衛生材料",
        content: (
          <div>
            {emergencyList.map((e, i) => {
              return (
                <>
                  <hr style={{ margin: 0 }} />
                  <label
                    key={e}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                      paddingLeft: 10
                    }}
                  >
                    {saC.check(`check_emergency_${i + 1}`)}
                    {e}
                  </label>
                </>
              );
            })}
            <hr style={{ margin: 0 }} />
            <div
              style={{
                padding: 10,
              }}
            >
              その他
              {saV.text(
                "emergency_free_text",
                "",
                "text",
                "block",
                true,
                "100px"
              )}
            </div>
          </div>
        ),
      },
      {
        name: "外出時物品",
        content: (
          <div>
            {goOutList.map((g, i) => {
              return (
                <>
                  <hr style={{ margin: 0 }} />
                  <label
                    key={g}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content"
                    }}
                  >
                    {saC.check(`check_go_out_${i + 1}`)}
                    {g}
                  </label>
                </>
              );
            })}
            <hr style={{ margin: 0 }} />
            <div
              style={{
                padding: 10,
              }}
            >
              その他
              {saV.text("go_out_free_text", "", "text", "block", true, "100px")}
            </div>
            <div
              style={{
                padding: 10,
              }}
            >
              <hr style={{ margin: 0 }} />
              確認日/サイン
              {saV.text("sign", "", "text", "block")}
            </div>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
