export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public bui_1: string = "";
  public bui_2: string = "";
  public bui_3: string = "";
  public bui_4: string = "";
  public create_date: string = "";
  public first_height: string = "";
  public first_weight: string = "";
  public h_1_1: string = "";
  public h_1_10: string = "";
  public h_1_11: string = "";
  public h_1_12: string = "";
  public h_1_13: string = "";
  public h_1_14: string = "";
  public h_1_15: string = "";
  public h_1_2: string = "";
  public h_1_3: string = "";
  public h_1_4: string = "";
  public h_1_5: string = "";
  public h_1_6: string = "";
  public h_1_7: string = "";
  public h_1_8: string = "";
  public h_1_9: string = "";
  public h_2_1: string = "";
  public h_2_10: string = "";
  public h_2_11: string = "";
  public h_2_12: string = "";
  public h_2_13: string = "";
  public h_2_14: string = "";
  public h_2_15: string = "";
  public h_2_2: string = "";
  public h_2_3: string = "";
  public h_2_4: string = "";
  public h_2_5: string = "";
  public h_2_6: string = "";
  public h_2_7: string = "";
  public h_2_8: string = "";
  public h_2_9: string = "";
  public h_3_1: string = "";
  public h_3_10: string = "";
  public h_3_11: string = "";
  public h_3_12: string = "";
  public h_3_13: string = "";
  public h_3_14: string = "";
  public h_3_15: string = "";
  public h_3_2: string = "";
  public h_3_3: string = "";
  public h_3_4: string = "";
  public h_3_5: string = "";
  public h_3_6: string = "";
  public h_3_7: string = "";
  public h_3_8: string = "";
  public h_3_9: string = "";
  public h_4_1: string = "";
  public h_4_10: string = "";
  public h_4_11: string = "";
  public h_4_12: string = "";
  public h_4_13: string = "";
  public h_4_14: string = "";
  public h_4_15: string = "";
  public h_4_2: string = "";
  public h_4_3: string = "";
  public h_4_4: string = "";
  public h_4_5: string = "";
  public h_4_6: string = "";
  public h_4_7: string = "";
  public h_4_8: string = "";
  public h_4_9: string = "";
  public pat_name: string = "";
  public w_1_1: string = "";
  public w_1_10: string = "";
  public w_1_11: string = "";
  public w_1_12: string = "";
  public w_1_13: string = "";
  public w_1_14: string = "";
  public w_1_15: string = "";
  public w_1_2: string = "";
  public w_1_3: string = "";
  public w_1_4: string = "";
  public w_1_5: string = "";
  public w_1_6: string = "";
  public w_1_7: string = "";
  public w_1_8: string = "";
  public w_1_9: string = "";
  public w_2_1: string = "";
  public w_2_10: string = "";
  public w_2_11: string = "";
  public w_2_12: string = "";
  public w_2_13: string = "";
  public w_2_14: string = "";
  public w_2_15: string = "";
  public w_2_2: string = "";
  public w_2_3: string = "";
  public w_2_4: string = "";
  public w_2_5: string = "";
  public w_2_6: string = "";
  public w_2_7: string = "";
  public w_2_8: string = "";
  public w_2_9: string = "";
  public w_3_1: string = "";
  public w_3_10: string = "";
  public w_3_11: string = "";
  public w_3_12: string = "";
  public w_3_13: string = "";
  public w_3_14: string = "";
  public w_3_15: string = "";
  public w_3_2: string = "";
  public w_3_3: string = "";
  public w_3_4: string = "";
  public w_3_5: string = "";
  public w_3_6: string = "";
  public w_3_7: string = "";
  public w_3_8: string = "";
  public w_3_9: string = "";
  public w_4_1: string = "";
  public w_4_10: string = "";
  public w_4_11: string = "";
  public w_4_12: string = "";
  public w_4_13: string = "";
  public w_4_14: string = "";
  public w_4_15: string = "";
  public w_4_2: string = "";
  public w_4_3: string = "";
  public w_4_4: string = "";
  public w_4_5: string = "";
  public w_4_6: string = "";
  public w_4_7: string = "";
  public w_4_8: string = "";
  public w_4_9: string = "";
  public year_1: string = "";
  public year_2: string = "";
  public year_3: string = "";
  public year_4: string = "";
}

export class Checks {
}
