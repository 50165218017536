import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";
import { Button } from "@material-ui/core";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 91;
  }

  public createContent(): any {
    const res = new FormModel.KokuCare.Core();
    res.values.create_date = DateUtil.currentDateStr();
    HokanApi.me()
      .then((x) => {
        console.log(x.data);
        if (x && x.data) {
          res.values.kinyusha = x.data.name;
          if (x.data.branch) {
            res.values.jimusho = x.data.branch.name;
            res.values.shozaichi = x.data.branch.address;
            res.values.phone_number = x.data.branch.tel;
            res.values.fax = x.data.branch.fax;
            res.values.kanrisha = x.data.branch.kanri_name;
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });

    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    const patient = doc.patient;

    if (patient) {
      doc.content.values.birthday = DateUtil.toJpDateString(patient.birthday);
      doc.content.values.address =
        patient.env.address1 + patient.env.address2 + patient.env.address3;
      doc.content.values.zip_code = patient.env.address_zip;
      doc.content.values.tel = patient.tel_cell;

      if ((patient.value.sexuality as any) === "男性") {
        doc.content.checks.male = true;
      } else if ((patient.value.sexuality as any) === "女性") {
        doc.content.checks.female = true;
      }

      if (patient.youkaigo === Model.Patient.YouKaigoType.Shien1) {
        doc.content.checks.check_1 = true;
        doc.content.checks.check_2 = true;
      } else if (patient.youkaigo === Model.Patient.YouKaigoType.Shien2) {
        doc.content.checks.check_1 = true;
        doc.content.checks.check_3 = true;
      } else if (patient.youkaigo === Model.Patient.YouKaigoType.Kaigo1) {
        doc.content.checks.check_4 = true;
        doc.content.checks.check_5 = true;
      } else if (patient.youkaigo === Model.Patient.YouKaigoType.Kaigo2) {
        doc.content.checks.check_4 = true;
        doc.content.checks.check_6 = true;
      } else if (patient.youkaigo === Model.Patient.YouKaigoType.Kaigo3) {
        doc.content.checks.check_4 = true;
        doc.content.checks.check_7 = true;
      } else if (patient.youkaigo === Model.Patient.YouKaigoType.Kaigo4) {
        doc.content.checks.check_4 = true;
        doc.content.checks.check_8 = true;
      } else if (patient.youkaigo === Model.Patient.YouKaigoType.Kaigo5) {
        doc.content.checks.check_4 = true;
        doc.content.checks.check_9 = true;
      }
    }

    if (doc.content.checks.check_35 === true) {
      doc.content.values.hassho_year = "";
      doc.content.values.hassho_month = "";
    }
    if (doc.content.checks.check_40 === true) {
      doc.content.checks.check_37 = false;
      doc.content.checks.check_38 = false;
      doc.content.checks.check_39 = false;
    }
    if (doc.content.checks.check_48 === true) {
      doc.content.values.saishu_year = "";
      doc.content.values.saishu_month = "";
    }
    if (doc.content.checks.check_52 === true) {
      doc.content.checks.check_50 = false;
      doc.content.checks.check_51 = false;
    }
    if (
      doc.content.checks.check_53 === true ||
      doc.content.checks.check_55 === true
    ) {
      doc.content.values.kaijohouhou = "";
    }
    if (doc.content.checks.check_57 === true) {
      doc.content.values.yakuzaimei = "";
    }
  }

  public beforeMount = () => {};
  public async setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    await HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
    if (pat == null) {
      doc.patient = null;
      return;
    }
    // 詳細な情報を得るためにpatientを引き直す
    await HokanApi.getPatient(pat.id).then(async (res) => {
      const pat = await Model.Patient.Patient.load(res.data);
      doc.patient = pat;
    });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const kisosikkanMap = [
      { name: "脳血管疾患", value: "check_10" },
      { name: "骨折", value: "check_11" },
      { name: "誤嚥性肺炎", value: "check_13" },
      { name: "うっ血性心不全", value: "check_14" },
      { name: "尿路感染症", value: "check_15" },
      { name: "糖尿病", value: "check_16" },
      { name: "高血圧", value: "check_17" },
      { name: "骨粗しょう症", value: "check_18" },
      { name: "関節リウマチ", value: "check_19" },
      { name: "がん", value: "check_20" },
      { name: "うつ病", value: "check_21" },
      { name: "認知症", value: "check_22" },
      { name: "瘡", value: "check_23" },
      { name: "神経疾患", value: "check_24" },
      { name: "運動器疾患", value: "check_25" },
      { name: "呼吸器疾患", value: "check_26" },
      { name: "循環器疾患", value: "check_27" },
      { name: "消化器疾患", value: "check_28" },
      { name: "腎", value: "check_29" },
      { name: "内分泌疾患", value: "check_30" },
      { name: "皮膚疾患", value: "check_31" },
      { name: "精神疾患", value: "check_32" },
      { name: "その他", value: "check_33" },
    ];

    const content = doc.content as FormModel.KokuCare.Core;

    const houkoku_saki = saV.getValue("houkoku_saki");
    const noHoukokuSaki =
      !content.values.houkoku_saki || content.values.houkoku_saki.length == 0;

    if (houkoku_saki) {
      if (houkoku_saki.findIndex((x) => x == null) >= 0) {
        const p = houkoku_saki.filter((x) => x != null);
        saV.setValue("houkoku_saki", p);
      }
    }

    const elements_t = [
      {
        name: "情報提供先",
        content: (
          <div>
            {noHoukokuSaki ? (
              <div style={{ color: "red", fontWeight: "bold" }}>
                情報提供先は一つ以上必要です
              </div>
            ) : null}
            {houkoku_saki.map((o, i) => {
              return saV.orgTantoC(
                ["houkoku_saki"],
                i,
                doc.patient ? doc.patient.id : undefined
              );
            })}
            {sa.isEditing ? (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={(e) => {
                  houkoku_saki.push(new Model.OrganizationTanto());
                  saV.setValue("houkoku_saki", houkoku_saki);
                }}
              >
                ＋追加
              </Button>
            ) : null}
          </div>
        ),
      },
      {
        name: "介護事務所",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              名称
              <span>{saV.text("jimusho")}</span>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              所在地
              <span>{saV.text("shozaichi")}</span>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              電話番号
              <span>{saV.text("phone_number")}</span>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              FAX番号
              <span>{saV.text("fax")}</span>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              管理者氏名
              <span>{saV.text("kanrisha")}</span>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              記入者氏名
              <span>{saV.text("kinyusha")}</span>
            </label>
          </div>
        ),
      },
      {
        name: "基本情報",
        content: (
          <div style={{ padding: 10 }}>
            <fieldset
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              <legend>基礎疾患</legend>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 5,
                }}
              >
                {kisosikkanMap.map((x) => (
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {saC.check(x.value)}
                    {x.name}
                  </label>
                ))}
              </div>
            </fieldset>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              誤嚥性肺炎の発症・既往
              {saC.radio([
                {
                  label: "あり",
                  key: `check_34`,
                },
                {
                  label: "なし",
                  key: `check_35`,
                },
              ])}
            </label>
            {doc.content.checks["check_34"] === true && (
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 10,
                }}
              >
                直近の発症年月
                <span>{saV.text("hassho_year", undefined, "number")}</span>年
                <span>{saV.text("hassho_month", undefined, "number")}</span>月
              </label>
            )}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              麻痺
              {saC.radio([
                {
                  label: "あり",
                  key: `check_36`,
                },
                {
                  label: "なし",
                  key: `check_37`,
                },
              ])}
            </label>
            {doc.content.checks["check_36"] === true && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 10,
                }}
              >
                部位
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {saC.check("check_37")}手
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {saC.check("check_38")}顔
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {saC.check("check_39")}その他
                </label>
              </div>
            )}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              摂食方法
              {saC.radio([
                {
                  label: "経口のみ",
                  key: `check_41`,
                },
                {
                  label: "一部経口",
                  key: `check_42`,
                },
                {
                  label: "経菅栄養",
                  key: `check_43`,
                },
                {
                  label: "静脈栄養",
                  key: `check_44`,
                },
              ])}
            </label>
            <fieldset
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 10,
                marginBottom: 10,
              }}
            >
              <legend>現在の歯科受診について</legend>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 10,
                }}
              >
                かかりつけ歯科医
                {saC.radio([
                  {
                    label: "あり",
                    key: `check_45`,
                  },
                  {
                    label: "なし",
                    key: `check_46`,
                  },
                ])}
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 10,
                }}
              >
                直近一年間の歯科受診
                {saC.radio([
                  {
                    label: "あり",
                    key: `check_47`,
                  },
                  {
                    label: "なし",
                    key: `check_48`,
                  },
                ])}
              </label>
              {doc.content.checks["check_47"] === true && (
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    marginBottom: 10,
                  }}
                >
                  最終受診年月
                  <span>{saV.text("saishu_year", undefined, "number")}</span>年
                  <span>{saV.text("saishu_month", undefined, "number")}</span>月
                </label>
              )}
            </fieldset>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              義歯の使用
              {saC.radio([
                {
                  label: "あり",
                  key: `check_49`,
                },
                {
                  label: "なし",
                  key: `check_52`,
                },
              ])}
            </label>
            {doc.content.checks["check_49"] === true && (
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  marginBottom: 10,
                }}
              >
                {saC.radio([
                  {
                    label: "部分",
                    key: `check_50`,
                  },
                  {
                    label: "全部",
                    key: `check_51`,
                  },
                ])}
              </label>
            )}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              口腔洗浄の自立度
              {saC.radio([
                {
                  label: "自立",
                  key: `check_53`,
                },
                {
                  label: "部分介助",
                  key: `check_54`,
                },
                {
                  label: "全介助",
                  key: `check_55`,
                },
              ])}
            </label>
            {doc.content.checks["check_54"] === true && (
              <div>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    marginBottom: 10,
                  }}
                >
                  介助方法
                  <span>{saV.text("kaijohouhou")}</span>
                </label>
              </div>
            )}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              現在の処方
              {saC.radio([
                {
                  label: "あり",
                  key: `check_56`,
                },
                {
                  label: "なし",
                  key: `check_57`,
                },
              ])}
            </label>
            {doc.content.checks["check_56"] === true && (
              <div>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    marginBottom: 10,
                  }}
                >
                  薬剤名
                  <span>{saV.text("yakuzaimei")}</span>
                </label>
              </div>
            )}
          </div>
        ),
      },
      {
        name: "口腔の健康状態の評価",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              1. 開口
              {saC.radio([
                {
                  label: "できる",
                  key: `check_58`,
                },
                {
                  label: "できない",
                  key: `check_59`,
                },
              ])}
              <p>
                上下の前歯の間に指2本分(縦)入る程度まで口が開かない場合)(開口量3cm以下)には「できない」とする。
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              2. 歯の汚れ
              {saC.radio([
                {
                  label: "なし",
                  key: `check_60`,
                },
                {
                  label: "あり",
                  key: `check_61`,
                },
              ])}
              <p>
                歯の表面や歯と歯の間に白や黄色の汚れ等がある場合には「あり」とする。
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              3. 舌の汚れ
              {saC.radio([
                {
                  label: "なし",
                  key: `check_62`,
                },
                {
                  label: "あり",
                  key: `check_63`,
                },
              ])}
              <p>
                舌の表面に白や黄色、茶、黒色の汚れ等がある場合には「あり」とする。
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              4. 歯肉の腫れ、出血
              {saC.radio([
                {
                  label: "なし",
                  key: `check_64`,
                },
                {
                  label: "あり",
                  key: `check_65`,
                },
              ])}
              <p>
                歯肉が晴れている場合（反対側の同じ部分の歯肉との比較や周囲の歯肉との比較）や歯磨きや口腔ケアの際に出血する場合は「あり」とする。
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              5. 左右両方の奥歯でしっかりかみしめられる
              {saC.radio([
                {
                  label: "できる",
                  key: `check_66`,
                },
                {
                  label: "できない",
                  key: `check_67`,
                },
              ])}
              <p>
                本人にしっかりとかみしめられないとの認識がある場合または義歯をいれても奥歯がない部分がある場合は「できない」とする。
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              6. むせ
              {saC.radio([
                {
                  label: "なし",
                  key: `check_68`,
                },
                {
                  label: "あり",
                  key: `check_69`,
                },
              ])}
              <p>
                平時や食事時にむせがある場合や明らかな「むせ」はなくても、食後の痰がらみ、声の変化、息が荒くなるなどがある場合は「あり」とする
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              7. ぶくぶくうがい
              {saC.radio([
                {
                  label: "できる",
                  key: `check_70`,
                },
                {
                  label: "できない",
                  key: `check_71`,
                },
              ])}
              <p>
                平時や食事時にむせがある場合や明らかな「むせ」はなくても、食後の痰がらみ、声の変化、息が荒くなるなどがある場合は「あり」とする
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              8. 食物の溜め込み、残留
              {saC.radio([
                {
                  label: "なし",
                  key: `check_72`,
                },
                {
                  label: "あり",
                  key: `check_73`,
                },
              ])}
              <p>
                食事の際に口の中に食物を飲み込まず貯めてしまう場合や飲み込んだ後に口を開けると食物が一部残っている場合は「あり」とする。
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              その他
              {saV.text("other_text")}
              <p>
                歯や粘膜に痛みがある、口の中の乾燥、口臭、義歯の汚れ、義歯がすぐに外れる、口の中に薬が残っている等の気になる点があれば記載する。
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              歯科医師等による口腔内等の確認の必要性
              {saC.radio([
                {
                  label: "低い",
                  key: `check_74`,
                },
                {
                  label: "高い",
                  key: `check_75`,
                },
              ])}
              <p>
                項目1-8について「あり」または「できない」が1つでもある場合は、歯科医師等による口腔内等の確認の必要性「高い」とする。その他の項目等も参考に歯科医師などによる口腔内等の確認の必要性が「高い」「高い」と考えられる場合は「高い」とする。
              </p>
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              歯科医療機関への連絡事項
              {saV.text("shika_text")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                marginBottom: 10,
              }}
            >
              介護支援専門医への連絡事項
              {saV.text("kaigo_text")}
            </label>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
