
export class Core {
    public values: Values = new Values();
    public indexes: Indexes = new Indexes();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""

    public stasj_s1: string = ""
    public stasj_s2: string = ""
    public stasj_s3: string = ""
    public stasj_s4: string = ""
    public stasj_s5: string = ""
    public stasj_s6: string = ""
    public stasj_s7: string = ""
    public stasj_s8: string = ""
    public stasj_s9: string = ""
    public comment: string = ""
}

export class Indexes {
}

export class Checks {
}