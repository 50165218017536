// tslint:disable:no-console
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the 'N+1' visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(
      process.env.PUBLIC_URL!,
      window.location.toString()
    );
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://goo.gl/SC7cgQ'
          );
        });
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl);
      }
    });
  }
}

function registerValidSW(swUrl: string) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the old content will have been purged and
                // the fresh content will have been added to the cache.
                // It's the perfect time to display a 'New content is
                // available; please refresh.' message in your web app.
                console.log('New content is available; please refresh.');
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // 'Content is cached for offline use.' message.
                console.log('Content is cached for offline use.');
              }
            }
          };
        }
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type')!.indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}


/*
// WebPushが使えるか
var isEnableWebPushBrowser = function() {
  // SWが使えないブラウザ
  if (!navigator.serviceWorker) {
    return false;
  }

  var ua = navigator.userAgent.toLowerCase();
  // UAにChromeが含まれている為、明示的にEdgeとOpera(Webkit)を弾く
  if (ua.indexOf("edge") >= 0 || ua.indexOf("opr") >= 0) {
    return false;
  }
  // Chrome 52 以降OK
  if (ua.match(/chrom(e|ium)/)) {
    var raw = ua.match(/chrom(e|ium)\/([0-9]+)\./);
    if (raw && parseInt(raw[2], 10) >= 52) {
      return true;
    }
  }
  // Firefox 48 以降OK
  if (ua.indexOf("firefox") >= 0) {
    var raw = ua.match(/firefox\/([0-9]+)\./);
    if (raw && parseInt(raw[1], 10) >= 48) {
      return true;
    }
  }
  return false;
};
*/

// WebPushが使えるブラウザの場合
// NOTE: 今回はWebPushだけなので。他でSW使いたかったら要変更
//console.log(isEnableWebPushBrowser());

/*
if (isEnableWebPushBrowser()) {
  var convertWebPushSubscriptionJson = function(subscription) {
    var jsonData = {};
    //if (subscription) {
      //jsonData = {
                   //endpoint: subscription.endpoint,
                   //key: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))).replace(/\+/g, '-').replace(/\//g, '_'),
                   //auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))).replace(/\+/g, '-').replace(/\//g, '_')
                 //};
    //}
    return jsonData;
  };

  // ブラウザPush通知を有効にする
  var webPushSubscribe = function() {
    return navigator.serviceWorker.getRegistration().then(function(registration:any) {
      // ブラウザPush通知用VAPID
      var key: string | unknown = decodeURI("BKOnaOoipZPD90YAAK08IJo5Vfpa5YPYANzIbF7NkAB0uk2l3kLPFeh3-SaW2M-uD_3y35EMtHQsHD-a-NvIQJ8=")
      var vapidPublicKey = new Uint8Array(key as ArrayBufferLike);
      return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidPublicKey
      }).then(function(subscription) {
        // API叩いてDBに登録するなどの処理
        console.log(convertWebPushSubscriptionJson(subscription));
        return;
      })["catch"](function(err) {
        return console.log(err);
      });
    });
  };

  // ブラウザPush通知を無効にする
  var webPushUnSubscribe = function() {
    return navigator.serviceWorker.getRegistration().then(function(registration:any) {
      return registration.pushManager.getSubscription().then(function(subscription) {
        if (!subscription) {
          return;
        }
        return subscription.unsubscribe().then(function(success) {
          // API叩いて無効にするなどの処理
          return;
        })["catch"](function(error) {
          return console.log(error);
        });
      });
    });
  };
  */

  /*
  const urlB64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  Notification.requestPermission(function(result) {
    console.log("User choice", result);
    if (result !== "granted") {
      console.log("No notification permission granted!");
    } else {
      console.log("notification permission granted!");
    }
  });

  var _registration:ServiceWorkerRegistration;
  navigator.serviceWorker.register('/serviceworker.js').then(registration => {
    _registration = registration;
    console.log(_registration)
    var key: Uint8Array = urlB64ToUint8Array("BKOnaOoipZPD90YAAK08IJo5Vfpa5YPYANzIbF7NkAB0uk2l3kLPFeh3-SaW2M-uD_3y35EMtHQsHD-a-NvIQJ8=")
    console.log(key)
    _registration!.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: key,
    }).then(subscription => {
      console.log("Subscribe OK:", subscription);
      console.log(subscription.toJSON());
      //return fetch("/register", {
        //method: "POST",
        //body: JSON.stringify(subscription.toJSON()),
      //});
    }).then(() => {
      console.log("Server Stored Subscription.");
    }).catch(err => {
      console.log("Subscribe Error:", err);
      console.error("[main.js][Subscription]", err);
    });
  }).catch(err => {
    console.log("Register Error:", err);
  });;
}
*/