import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

const styles = () => Styles.createStyles({
    title: {
        backgroundColor: Colors.primaryColor,
        color: Colors.backColor
    }
})

interface Props extends Styles.WithStyles {
    title: string
    open: boolean
    deleteText: string
    cancelText: string
    okText: string
    hideDelete: boolean
    hideCancel: boolean
    hideOk: boolean
    onOk: () => void
    onCancel: () => void
    onDelete: () => void
    disableButtons: boolean
}

class SimpleDialog extends React.Component<Props> { 
	displayName="SimpleDialog"
    public static defaultProps = {
        title: "",
        open: false,
        deleteText: "削除",
        okText: "確定",
        cancelText: "キャンセル",
        onDelete: () => { },
        onOk: () => { },
        onCancel: () => { },
        hideDelete: true,
        hideCancel: false,
        hideOk: false,
        disableButtons: false
    }
    public render() {
        const p = this.props;
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Dialog open={p.open} onClose={(e) => p.onCancel()} aria-labelledby={`dialog_${p.title}`}>
                    <DialogTitle id={`dialog_${p.title}`} className={classes.title}>
                        {p.title}
                    </DialogTitle>
                    <DialogContent>
                        {this.props.children}
                    </DialogContent>
                    <DialogActions>
                        {
                            p.hideDelete ? null
                                : <Button onClick={(e) => p.onDelete()} variant="contained" color="secondary"
                                    disabled={this.props.disableButtons} >
                                    {p.deleteText}
                                </Button>
                        }
                        {
                            p.hideCancel ? null :
                                <Button onClick={(e) => p.onCancel()} variant="contained" color="default"
                                    disabled={this.props.disableButtons} >
                                    {p.cancelText}
                                </Button>
                        }
                        {
                            p.hideOk ? null :
                                <Button onClick={(e) => p.onOk()} variant="contained" color="primary"
                                    disabled={this.props.disableButtons} >
                                    {p.okText}
                                </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default Styles.withStyles(styles)(SimpleDialog)