import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";
import {
  IshiOptionType,
  ZaitakuOptionType,
  RenkeiOptionType,
  JisshiOptionType,
  TerminalSubjectOptionType,
  ContactOptionType,
} from "../../models/Forms/LifeB";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 76;
  }

  public createContent(): any {
    const res = new FormModel.LifeB.Core();
    res.values.create_date = DateUtil.currentDateStr();
    HokanApi.me()
      .then((x) => {
        if (x && x.data) {
          res.values.created_by = x.data.name
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    if (doc.content.checks["check_contact_1"] === false) {
      doc.content.values["contact_shujii"] = "";
    }
    if (doc.content.checks["check_contact_2"] === false) {
      doc.content.values["contact_others_1"] = "";
    }
    if (doc.content.checks["check_contact_3"] === false) {
      doc.content.values["contact_others_2"] = "";
    }
    if (doc.content.checks["check_contact_4"] === false) {
      doc.content.values["contact_others_3"] = "";
    }
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    const elements_t = [
      {
        name: "記載者",
        content: (
          <div style={{ padding: 10 }}>
            <label>{saV.text("created_by", "")}</label>
          </div>
        ),
      },
      {
        name: "本人の意思",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              確認できるか
              {saV.list(`ishi_option`, IshiOptionType)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              本人の意思を推定する人
              <div style={{ flex: 1 }}>{saV.text("ishi_text", "")}</div>
            </label>
          </div>
        ),
      },
      {
        name: "望んでいる医療・ケアの方針や生き方",
        content: (
          <div style={{ padding: 10 }}>
            <label style={{ display: "block", marginBottom: 10 }}>
              本人
              {saV.text(`nozomi_own`, "", "text", "block", true, "50px")}
            </label>
            <label>
              家族等
              {saV.text(`nozomi_family`, "", "text", "block", true, "50px")}
            </label>
          </div>
        ),
      },
      {
        name: "在宅希望",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              本人
              {saV.list(`zaitaku_own_option`, ZaitakuOptionType)}
            </label>
            <label style={{ display: "block", marginBottom: 10 }}>
              自由記載
              {saV.text(`zaitaku_own`, "", "text", "block", true, "50px")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              家族等
              {saV.list(`zaitaku_family_option`, ZaitakuOptionType)}
            </label>
            <label>
              自由記載
              {saV.text(`zaitaku_family`, "", "text", "block", true, "50px")}
            </label>
          </div>
        ),
      },
      {
        name: "連携調整",
        content: (
          <div style={{ padding: 10 }}>
            <label 
            style={{display:"block", width: "fit-content"}}>
              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                {saC.check(`check_renkei_1`)}
                これからの過ごし方や方針とその決定方法
              </div>
            </label>
            {saV.text(`renkei_text_1`, "", "text", "block", true, "50px")}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content"
              }}
            >
              看取り時の対応・方法の確認
              {saC.check(`check_renkei_2`)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content",
              }}
            >
              日時
              {saV.date(`mitori_date`)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              エンゼルケアの希望
              {saV.list(`enzeru_option`, RenkeiOptionType)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              バックベッドの希望
              {saV.list(`backbed_option`, RenkeiOptionType)}
            </label>
            <label>
              自由記載
              {saV.text(`renkei_text_2`, "", "text", "block", true, "50px")}
            </label>
          </div>
        ),
      },
      {
        name: "パンフレットを用いての看取りの準備教育",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              実施・未実施{saV.list(`panfu_option`, JisshiOptionType)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              実施日{saV.date(`panfu_date`)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              実施者{saV.text(`panfu_by`)}
            </label>
            <label>
              自由記載
              {saV.text(`panfu_text`, "", "text", "block", true, "50px")}
            </label>
          </div>
        ),
      },
      {
        name: "ターミナルケア加算の説明",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              実施・未実施
              {saV.list(`terminal_option`, JisshiOptionType)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content",
              }}
            >
              実施日
              {saV.date(`terminal_date`)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content",
              }}
            >
              実施者
              {saV.text(`terminal_by`)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content",
              }}
            >
              対象
              {saV.list(`terminal_subject_option`, TerminalSubjectOptionType)}
              {saV.text(`terminal_subject`)}
            </label>
            <label>
              自由記載
              {saV.text(`terminal_text`, "", "text", "block", true, "50px")}
            </label>
          </div>
        ),
      },
      {
        name: "緊急時・死亡時の連絡先の説明と確認",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content",
              }}
            >
              {saC.check(`check_contact_1`)}
              主治医連絡先
              {(() => {
                const checked = saC.getValue(`check_contact_1`);
                if (checked) {
                  return saV.text(`contact_shujii`);
                }
                return null;
              })()}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content",
              }}
            >
              {saC.check(`check_contact_2`)}
              その他①
              {(() => {
                const checked = saC.getValue(`check_contact_2`);
                if (checked) {
                  return saV.text(`contact_others_1`);
                }
                return null;
              })()}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content",
              }}
            >
              {saC.check(`check_contact_3`)}
              その他②
              {(() => {
                const checked = saC.getValue(`check_contact_3`);
                if (checked) {
                  return saV.text(`contact_others_2`);
                }
                return null;
              })()}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
                width: "fit-content",
              }}
            >
              {saC.check(`check_contact_4`)}
              その他③
              {(() => {
                const checked = saC.getValue(`check_contact_4`);
                if (checked) {
                  return saV.text(`contact_others_3`);
                }
                return null;
              })()}
            </label>
            {["連絡順1", "連絡順2", "連絡順3", "連絡順4", "連絡順5"].map(
              (name, i) => {
                return (
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      marginBottom: 10,
                    }}
                  >
                    {name}
                    {saV.list(
                      `contact_order_option_${i + 1}`,
                      ContactOptionType
                    )}
                  </label>
                );
              }
            )}
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
