import * as React from 'react';
import { Count, toInjector } from '../stores';
import { inject, observer } from 'mobx-react';
import Counter from '../components/Counter';

interface Props extends Count { }

@inject(...toInjector(Count))
@observer
export default class extends React.Component<Props> {
    public componentDidMount = () => {
        const { count } = this.props;
        count!.loadTests();
    }
    public render() {
        const { count } = this.props;
        return (
            <div>
                <Counter />
                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>content1</th>
                            <th>content2</th>
                            <th>content3</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            count!.tests.map(t => {
                                return <tr key={`test_row_${t.id}`}>
                                    <td>{t.id}</td>
                                    <td><input type="text" value={t.content1}
                                        onChange={(e) => t.content1 = e.target.value} /></td>
                                    <td><input type="text" value={t.content2}
                                        onChange={(e) => t.content2 = e.target.value} /></td>
                                    <td><input type="text" value={t.content3}
                                        onChange={(e) => t.content3 = e.target.value} /></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                < button onClick={(e) => count!.commitTests()} >確定</button>
                {
                    count!.commitResult === "ok" ? <p>ok</p>
                        : count!.commitResult === "ng" ? <p>ng</p>
                            : null
                }
                <div>
                    <input type="file" name="example2" accept="image/*" multiple={true}
                        onChange={(e) => {
                            if (e.target.files) {
                             count!.onChangeFile(e.target.files)
                            }
                        }} />
                    < button onClick={(e) => count!.uploadImage()} >確定</button>
                </div>
            </div>
        );
    }
}

