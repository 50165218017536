export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public date_from: string = ""
    public date_to: string = ""
}

export class Checks {
}