
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public birthday: string = ""
    public create_by: string = ""
    public device: string = ""
    public care: string = ""
    public vital: string = ""
    public suki: string = ""
    public mokuhyo: string = ""
}

export class Checks {
}