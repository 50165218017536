import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import { Table, TableHead, TableCell, TableRow, TableBody, Button, Grid, Select, MenuItem } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import StandardTable from '../components/StandardTable';
import Col2Table from '../components/Col2Table';
import { StoreAccessor } from '../util/StoreUtil';
import DateUtil from '../util/DateUtil';
import Pagination from "material-ui-flat-pagination";
import Encoding from 'encoding-japanese';
const moment = require("moment-timezone")

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none"
        }
    },
    freshCheckSubtitleRow: {
        border: "solid",
        borderWidth: "1px",
        backgroundColor: Colors.primaryColor,
        borderColor: Colors.backColor,
        color: Colors.white
    },
    freshCheckSubtitle: {
        fontWeight: "bold",
        fontSize: "0.9rem",
        padding: "5px",
        color: Colors.white
    },
    rowContent: {
        paddingLeft: "10px"
    },
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

class Query {
    date_from: string = DateUtil.currentMonthFirstDateStr()
    date_to: string = DateUtil.currentMonthLastDateStr()
    name: string = ""
    name_kana: string = ""
    hoken: string = "all"
    page: number = 1
    per: number = 20
    with_temp: boolean = false
}

interface LocalState {
    me: Model.User
    loading: boolean
    commitResult: any
    branch_id: number
    branches: Model.Branch[]
    query: Query
    sending: boolean
    kirokus: HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>
}



// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "SukoyakanJissekiPage"
    constructor(props: Props) {
        super(props)

        this.state = {
            me: new Model.User(),
            loading: true,
            commitResult: "",
            branch_id: 0,
            branches: [],
            sending: false,
            query: new Query(),
            kirokus: new HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>()
        }
    }

    private goBack() {
        this.props.history.goBack();
    }

    downloadCsv(data: string, fname: string): void {
        //data = data.replace(/\,/g, '","').replace(/^./, '"').replace(/$/, '"');
        //const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

        // utf8 to sjis
        const uint8Array = Encoding.stringToCode(data);
        const codeArray = Encoding.convert(uint8Array, {
            from: "UNICODE",
            to: 'SJIS'
        })
        const u8a = new Uint8Array(codeArray)
        const blob = new Blob([u8a], { 'type': 'text/csv;charset=sjis;' });
        const url = (window.URL || window.webkitURL);
        const blobURL = url.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = decodeURI(fname);
        a.href = blobURL;
        a.type = 'text/csv';
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, fname)
        }
        else if (window.URL && window.URL.createObjectURL) {
            // for Firefox
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else {
            // for Chrome / safari
            a.click();
        }
    }

    private commit() {
        this.setState({ commitResult: "処理中", sending: true })
        const q = this.state.query
        HokanApi.createSukoyakaJisseki(this.state.branch_id, q.date_from, q.date_to, q).then(x => {
            const dt = moment(q.date_from);
            this.downloadCsv(x.data, `S2001${dt.format("YYMM")}.csv`)
            this.setState({ commitResult: '完了', sending: false })
        })
            .catch(err => {
                console.log(err)
                this.setState({
                    commitResult: <div style={{ color: "red" }}>
                        エラー: <br />
                        以下を確認してください。<br />
                        ・各記録の訪問スタッフが設定されているか<br />
                        ・各スタッフ/利用者のレセコンIDが設定されているか<br />
                        ・サービスコードが設定されているか
                    </div>, sending: false
                })
            })
    }

    private loadBranch = (branchId) => {
        this.setState({ branch_id: branchId })
        this.loadKirokus(branchId);
    }

    private loadKirokus = (branchId: number, _offset?: number) => {
        this.setState({ loading: true })
        const q = this.state.query
        const offset = _offset == undefined ? (this.state.kirokus.page - 1) * this.state.kirokus.per : _offset
        q.page = offset / this.state.kirokus.per + 1
        q.per = this.state.kirokus.per
        HokanApi.searchKirokuIIs(branchId, q.date_from, q.date_to, q).then(x => {
            const d = x.data
            d.data = d.data.map(y => Model.KirokuII.KangoKirokuII.load(y))
            this.setState({ kirokus: d, loading: false })
        })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidMount() {
        this.setState({ loading: true })
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
            this.loadBranch(x.data.branch_id)
            //this.loadKirokus()
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })

        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }


    public render() {
        const { classes } = this.props;
        const sa = new StoreAccessor(this, true)
        const saQ = sa.generateChild(["query"])
        const element_l = [
            {
                name: "事業所", content: <Select
                    value={this.state.branch_id}
                    autoWidth={true}
                    variant='outlined'
                    onChange={(e) => this.loadBranch(e.target.value)}
                    className={classes.input}
                >
                    {
                        this.state.branches.map((t, idx) =>
                            <MenuItem key={`lis+${t.id}`} value={t.id}>{t.name}</MenuItem>
                        )
                    }
                </Select>
            },
            { name: "対象日", content: <div>{saQ.date("date_from")}〜{saQ.date("date_to")}</div> },
            {
                name: "保険種類", content: saQ.listRaw("hoken", [
                    { key: "全て", value: "all" },
                    { key: "介護", value: "kaigo" },
                    { key: "医療", value: "iryo" }
                ])
            },
            { name: "名前", content: saQ.text("name") },
            { name: "カナ", content: saQ.text("name_kana") },
            { name: "一時保存も表示", content: saQ.check("with_temp") },
            {
                name: "検索", content: <Button color="primary" variant="contained"
                    onClick={(e) => {
                        this.loadKirokus(this.state.branch_id, 0)
                    }} >検索</Button>
            }
        ]

        const paging =
            <Pagination
                limit={this.state.kirokus.per}
                offset={(this.state.kirokus.page - 1) * this.state.kirokus.per}
                total={this.state.kirokus.count}
                disabled={this.state.loading}
                onClick={(e, offset) => {
                    this.loadKirokus(this.state.branch_id, offset)
                }}
            />

        return <div className={classes.root}>
            <SubTitle title="実績データ書き出し"
                text="すこやかサン用の実績データを書き出します"
            />
            <div>
                <div style={{ display: "block", width: "100%" }}>
                    <StandardTable title='検索'>
                        <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12} md={12} lg={12}>
                                <Col2Table body={element_l} />
                            </Grid>
                        </Grid>
                    </StandardTable>
                </div>
            </div>
            <div>件数：{this.state.kirokus.count}（※自費/一時保存は書き出されません）</div>
            {paging}
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <CustomTableCell align="center" >提供日時</CustomTableCell>
                        <CustomTableCell align="center" >記録の種類</CustomTableCell>
                        <CustomTableCell align="center" >サービス</CustomTableCell>
                        <CustomTableCell align="center" >要介護</CustomTableCell>
                        <CustomTableCell align="center" >加算項目</CustomTableCell>
                        <CustomTableCell align="center" >利用者名</CustomTableCell>
                        <CustomTableCell align="center" >訪問スタッフ</CustomTableCell>
                        <CustomTableCell align="right"></CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.state.kirokus.data.map(kiroku => {
                            const dis = kiroku.is_temp || kiroku.kiroku_type == Model.KirokuII.KirokuType.Jihi
                            return <TableRow key={"kiroku_" + kiroku.id} className={classes.row}
                                style={dis ? { backgroundColor: "#DDD" } : {}} >
                                <CustomTableCell align="center">
                                    <div>
                                        {DateUtil.toSlashDateStringShort(kiroku.time_start)}<br />
                                        {DateUtil.toTimeStr(kiroku.time_start)}
                                        〜{DateUtil.toTimeStr(kiroku.time_end)}
                                    </div>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {kiroku.kiroku_type}
                                    {
                                        kiroku.is_temp ? <div>(一時保存)</div> : ""
                                    }
                                </CustomTableCell>
                                <CustomTableCell align="center">{Model.KirokuII.KangoKirokuII.getKirokuCodeStr(kiroku)}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.patient.youkaigo}</CustomTableCell>
                                <CustomTableCell align="center" style={{ fontSize: "0.9rem" }}>{kiroku.basic.kasan ? Model.KirokuII.KangoKirokuII.getKasanText(kiroku) : ""}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.patient.name}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.main_user ? kiroku.main_user.name : <span style={{ color: "red", fontWeight: "bold" }}>未設定</span>}</CustomTableCell>
                                <CustomTableCell align="center"><a href={`/patients/${kiroku.patient.id}?kid=${kiroku.id}#activity`} target="_blank">確認</a></CustomTableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
            {paging}
            <div>
                <Button color="default" variant="outlined"
                    onClick={(e) => this.goBack()}
                    className={classes.filter_button_d}>
                    戻る
                </Button>
                <Button color="primary" variant="contained"
                    onClick={(e) => this.commit()}
                    disabled={this.state.sending}
                    className={classes.filter_button_d}>
                    実行
                </Button>
            </div>
            <div>{this.state.commitResult}</div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))