import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'


// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour  { 
	displayName="Behaviour"
    private choice = [
        { key: "未選択", value: "　" },
        { key: "全介助", value: "全介助" },
        { key: "部分介助", value: "部分介助" },
        { key: "声かけ指導", value: "声かけ指導" },
        { key: "教育指導・指示", value: "教育指導・指示" },
        { key: "自立", value: "自立" }
    ]
    public getId(): number {
        return 20
    }

    public createContent(): any {
        const res = new FormModel.MentalSelfcare.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {

    }

    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>・セルフケアモデルの枠組み：村瀬智子：オレム-アンダーウッド理論，岩㟢弥生，渡邉博幸（編）：新体系　看護学全書　精神看護学②精 神障害をもつ人の看護．p244，メヂカルフレンド社，2016 </div>
        </div>
    }
    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const elements_scores = [
            {
                name: "空気・水・食物", content: <div>
                    セルフケアレベル：{saV.listRaw("level_1", this.choice)}
                    テキスト：{saV.text("content_1")}
                </div>
            },
            {
                name: "排泄", content: <div>
                    セルフケアレベル：{saV.listRaw("level_2", this.choice)}
                    テキスト：{saV.text("content_2")}
                </div>
            },
            {
                name: "体温と個人衛生", content: <div>
                    セルフケアレベル：{saV.listRaw("level_3", this.choice)}
                    テキスト：{saV.text("content_3")}
                </div>
            },
            {
                name: "活動と休息のバランス", content: <div>
                    セルフケアレベル：{saV.listRaw("level_4", this.choice)}
                    テキスト：{saV.text("content_4")}
                </div>
            },
            {
                name: "孤独と付き合いのバランス", content: <div>
                    セルフケアレベル：{saV.listRaw("level_5", this.choice)}
                    テキスト：{saV.text("content_5")}
                </div>
            },
            {
                name: "安全を保つ能力", content: <div>
                    セルフケアレベル：{saV.listRaw("level_6", this.choice)}
                    テキスト：{saV.text("content_6")}
                </div>
            },
        ]

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
