import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import * as Colors from './Colors'
import * as Behaviour from '../components/form_behaviour'
import FormBehaviour from './form_behaviour/FormBehaviour';
import { Dialog, Zoom, AppBar, Toolbar, IconButton, Table, TableHead, TableRow, TableBody, TableCell, withStyles, Grid, Button } from '@material-ui/core';
import FormBasePage from '../pages/Forms/FormBasePage';
import * as Icons from "@material-ui/icons"
import Loading from './Loading';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",
        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    bar: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.borderColor,
        backgroundColor: Colors.backColor
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        // background: Colors.primaryColor,
    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700
    },
    dialog: {
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    open: boolean
    only: number | null
    srcId: number | null
    patientId?: number
    onCansel: (isDelete: boolean) => void
    onSelect: (doc: Model.Document.Document) => void
    targetMonth: number | null
    readOnly: boolean
}

interface LocalState {
    behaviour: FormBehaviour | null,
    behaviourList: object
    documentTypes: { cat: string, types: Model.Document.DocumentType[] }[]
    loading: boolean
    isSelectingType: boolean
    src: Model.Document.Document | null
}


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
        padding: "5px"
    },
}))(TableCell);

// @inject()
// @observer
class DocumentDialog extends React.Component<Props, LocalState> {
    displayName = "DocumentDialog"
    public static defaultProps = {
        patientId: undefined,
        targetMonth: null,
        readOnly: false
    }


    constructor(props: Props) {
        super(props)
        const lis: FormBehaviour[] = [
            new Behaviour.KeikakushoBehaviour(),
            new Behaviour.HoukokushoBehaviour(),
            new Behaviour.JokusoKeikakuBehaviour(),
            new Behaviour.HihuBredenBehaviour(),
            new Behaviour.MouseOHATBehaviour(),
            new Behaviour.KokyuMRCBehaviour(),
            new Behaviour.MouseHanpukuEngeBehaviour(),
            new Behaviour.MouseMizunomiBehaviour(),
            new Behaviour.MouseFoodBehaviour(),
            new Behaviour.LangMeiryouBehaviour(),
            new Behaviour.BodyBMSBehaviour(),
            new Behaviour.BodyMMTBehaviour(),
            new Behaviour.BodyBBSBehaviour(),
            new Behaviour.BodySelfKouryokuBehaviour(),
            new Behaviour.MentalSelfcareBehaviour(),
            new Behaviour.ACPJinseiBehaviour(),
            new Behaviour.MentalMSEBehaviour(),
            new Behaviour.MentalWrapBehaviour(),
            new Behaviour.MentalStrength1Behaviour(),
            new Behaviour.ImageBehaviour(8),
            new Behaviour.HokenBehaviour(2),
            new Behaviour.HokenBehaviour(3),
            new Behaviour.HokenBehaviour(4),
            new Behaviour.HokenBehaviour(5),
            new Behaviour.KouhiBehaviour(),
            new Behaviour.JouhoTeikyoBehaviour(),
            new Behaviour.FaxTemplateBehaviour(),
            new Behaviour.TashokushuKanfaBehaviour(),
            new Behaviour.KangoSummaryBehaviour(),
            new Behaviour.GAFBehaviour(),
            new Behaviour.ShouniCareBehaviour(),
            new Behaviour.TaiinCanfaBehaviour(),
            new Behaviour.ACPJinsei2Behaviour(),
            new Behaviour.PainBehaviour(),
            new Behaviour.StasJBehaviour(),
            new Behaviour.DesignRBehaviour(),
            new Behaviour.ShijishoBehaviour(46),
            new Behaviour.ShijishoBehaviour(47),
            new Behaviour.ShijishoBehaviour(48),
            new Behaviour.PTOTSTBehaviour(),
            new Behaviour.RespiratorySystemABehaviour(),
            new Behaviour.RespiratorySystemBBehaviour(),
            new Behaviour.RespiratorySystemCBehaviour(),
            new Behaviour.StartCBehaviour(),
            new Behaviour.StartBBehaviour(),
            new Behaviour.StartABehaviour(),
            new Behaviour.LifeBBehaviour(),
            new Behaviour.LifeABehaviour(),
            new Behaviour.LifeCBehaviour(),
            new Behaviour.LifeDBehaviour(),
            new Behaviour.LifeEBehaviour(),
            new Behaviour.EiyouBehaviour(),
            new Behaviour.ABCDStomaBehaviour(),
            new Behaviour.KaigofutansyakudoBehabiour(),
            new Behaviour.IcfBehaviour(),
            new Behaviour.BiBehaviour(),
            new Behaviour.FimBehaviour(),
            new Behaviour.PdOutBehaviour(),
            new Behaviour.MnaBehaviour(),
            new Behaviour.CamBehaviour(),
            new Behaviour.AcpBlankBehaviour(),
            new Behaviour.KokuCareBehaviour(),
            new Behaviour.HomonKangoChukanSummaryBehaviour(),
            new Behaviour.HomonKangoShuryoSummaryBehaviour(),
        ].concat(
            [1, 33, 34, 36, 37, 38, 39, 40, 42, 43, 44, 45, 49, 50, 51, 52, 53, 59, 60,
                63, 64, 65, 66, 67, 69, 92, 93].map(docid => {
                    return new Behaviour.ImageBehaviour(docid)
                })
        )
        const bl = {}
        lis.forEach((x) => {
            bl[x.getId()] = x
        })

        this.state = {
            loading: this.props.srcId != null,
            behaviour: null,
            documentTypes: [],
            behaviourList: bl,
            isSelectingType: this.props.srcId == null,
            src: null
        }
    }

    public componentDidMount = () => {
        this.loadTypes();

        if (this.props.srcId) {
            HokanApi.getDocument(this.props.srcId).then((res) => {
                this.setState({
                    behaviour: this.state.behaviourList[res.data.document_type.id],
                    src: res.data,
                    loading: false
                })
            })
        }
        else if (this.props.only != null) {
            this.setState({
                behaviour: this.state.behaviourList[this.props.only],
                src: null,
                loading: false
            })
        }
    }


    private loadTypes() {
        HokanApi.getDocumentTypes()
            .then(x => {
                const categories = new Set<string>()
                x.data.forEach(x => {
                    categories.add(x.category)
                })

                const types = Array.from(categories).map(c => {
                    return {
                        cat: c,
                        types: x.data.filter(t => t.category == c)
                    }
                })
                this.setState({ documentTypes: types })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private onClose(deleted: boolean) {
        this.setState({ behaviour: null })
        this.props.onCansel(deleted)
    }

    private onCreate(doc: Model.Document.Document) {
        this.setState({ behaviour: null })
        this.props.onSelect(doc)
    }

    public render() {
        const { classes } = this.props;
        const { behaviour, documentTypes } = this.state

        return <Dialog
            className={classes.dialog}
            TransitionComponent={Zoom}
            open={this.props.open}
            fullScreen={behaviour != null && behaviour.isPreviewable()}
            maxWidth="xl"
            onClose={() => this.onClose(false)}
        >
            <AppBar position={behaviour != null && behaviour.isFullWidth() ? "fixed" : "relative"}>
                <Toolbar>
                    <IconButton edge="end" color="inherit" onClick={() => this.onClose(false)}>
                        <Icons.Close />
                    </IconButton>
                    書類
                </Toolbar>
            </AppBar>
            <div style={{ padding: "10px 10px" }}>
                {
                    this.state.loading ? <Loading /> :
                        behaviour == null ?
                            <div key="selecting_mode">
                                <Grid container={true} spacing={1}>
                                    {
                                        documentTypes.map(catList => {
                                            return <Grid key={`cat_${catList.cat}`} item={true} xs={12} md={6} lg={4}>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <CustomTableCell style={{ textAlign: "center" }}>{catList.cat}</CustomTableCell>
                                                            <CustomTableCell align="right"></CustomTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            catList.types.map(t => {
                                                                if (t.is_deprecated) return null
                                                                let name = t.name
                                                                if (t.is_template) name += "(作成)"
                                                                else {
                                                                    if (t.is_download) name += "(雛形)"
                                                                    else name += "(取込)"
                                                                }
                                                                return <TableRow key={t.id} className={classes.row}
                                                                    onClick={(e) => {
                                                                        const b = this.state.behaviourList[t.id] || null
                                                                        this.setState({ behaviour: b })
                                                                    }}>
                                                                    {
                                                                        this.state.behaviourList[t.id] ?
                                                                            <CustomTableCell style={{ textAlign: "left" }} component="th" scope="row">{name}</CustomTableCell>
                                                                            : <s>
                                                                                <CustomTableCell style={{ textAlign: "left" }} component="th" scope="row">{name}</CustomTableCell>
                                                                            </s>
                                                                    }
                                                                    <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                                                </TableRow>
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </div>
                            : <div key="input_mode">
                                {
                                    this.props.srcId == null && this.props.only == null ?
                                        <Button variant="outlined" color="default"
                                            onClick={(e) => { this.setState({ behaviour: null }) }}>
                                            戻る
                                        </Button>
                                        : null
                                }
                                <FormBasePage
                                    canTempSave={true}
                                    behaviour={behaviour}
                                    targetMonth={this.props.targetMonth}
                                    patientId={this.props.patientId}
                                    readOnly={this.props.readOnly}
                                    src={this.state.src}
                                    onCreate={(doc) => this.onCreate(doc)}
                                    onDelete={() => this.onClose(true)}
                                    onCansel={() => this.onClose(true)}
                                />
                            </div>
                }
            </div>
        </Dialog >
    }
}

export default withPageProps(Styles.withStyles(styles)(DocumentDialog))
