import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import * as FormBehaviour from './FormBehaviour'
import Col2Table from '../Col2Table';
import {DateUtil} from '../../util/DateUtil'

const jokuso_lists = [
    [
        "D0: なし",
        "D1: 持続する発赤",
        "D2: 真皮までの損傷",
        "D3: 皮下組織までの損傷",
        "D4: 皮下組織をこえる損傷",
        "D5: 関節腔、体腔に至る損傷",
        "DU: 深さ判定が不能の場合",
    ], [
        "E0: なし",
        "E1: 少量：毎日の交換を要しない",
        "E3: 中等量：1日1回の交換",
        "E6: 多量：1日2回以上の交換",
    ], [
        "S0: 皮膚損傷なし",
        "S3: 4未満",
        "S6: 4以上16未満",
        "S8: 16以上36未満",
        "S9: 36以上64未満",
        "S12: 64以上100未満",
        "S15: 100以上",
    ], [
        "I0: 局所の炎症徴候なし",
        "I1: 局所の炎症徴候あり（創周辺の発赤、腫瘍、熱感、疼痛）",
        "I3: 局所の明らかな感染徴候あり（炎症徴候、膿、悪臭）",
        "I9: 全身的影響あり（発熱など）",
    ], [
        "G0: 創閉鎖又は創が浅い為評価不可能",
        "G1: 創面の90%以上を占める",
        "G3: 創面の50%以上90%未満を占める",
        "G4: 創面の10%以上50%未満を占める",
        "G5: 創面の10%未満を占める",
        "G6: 全く形成されていない",
    ], [
        "N0: なし",
        "N3: 柔らかい壊死組織あり",
        "N6: 硬く厚い密着した壊死組織あり",
    ], [
        "R0: なし",
        "R6: 4未満",
        "R9: 4以上16未満",
        "R12: 16以上36未満",
        "R24: 36以上",
    ]
]
// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour   {
	displayName="Behaviour"

    public getId(): number {
        return 10
    }

    public createContent(): any {
        const res = new FormModel.JokusoKeikaku.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient): void {
        doc.patient = pat;
        const d = doc.content as FormModel.JokusoKeikaku.Core
        d.values.pat_birthday = pat.birthday
    }
    public setUser(doc: Model.Document.Document, user: Model.User): void {
        const d = doc.content as FormModel.JokusoKeikaku.Core
        d.values.user_name = user.name
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }


    public beforePost_pdf(doc: Model.Document.Document) {
        const c = doc.content as FormModel.JokusoKeikaku.Core
        c.checks.kako_ari = (c.checks.kako_1 || c.checks.kako_2 || c.checks.kako_3 || c.checks.kako_4 || c.checks.kako_5 || c.checks.kako_6 || c.checks.kako_7)
        c.checks.kako_nashi = !c.checks.kako_ari
        c.checks.genzai_ari = (c.checks.genzai_1 || c.checks.genzai_2 || c.checks.genzai_3 || c.checks.genzai_4 || c.checks.genzai_5 || c.checks.genzai_6 || c.checks.genzai_7)
        c.checks.genzai_nashi = !c.checks.genzai_ari
    }

    public beforeMount = () => {
    }

    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
        <div>・DESIGN-R：日本褥瘡学会（2013）</div>
        <div>・厚生労働省危険因子評価表：厚生労働省：褥瘡対策に関する診療計画書（平成18年3月6日保医発第0306002 号厚生労働省保険局医療課長通知）をもとに作成</div>
        </div>
    }

    private toDesignRName = (s) => s.match(/(.+)\:/)[1].toLowerCase()

    private calcScore = (data: FormModel.JokusoKeikaku.Core) => {
        let sc = 0
        if (data.checks.e1) sc += 1
        if (data.checks.e3) sc += 3
        if (data.checks.e6) sc += 6

        if (data.checks.s3) sc += 3
        if (data.checks.s6) sc += 6
        if (data.checks.s8) sc += 8
        if (data.checks.s9) sc += 9
        if (data.checks.s12) sc += 12
        if (data.checks.s15) sc += 15

        if (data.checks.i1) sc += 1
        if (data.checks.i3) sc += 3
        if (data.checks.i9) sc += 9

        if (data.checks.g1) sc += 1
        if (data.checks.g3) sc += 3
        if (data.checks.g4) sc += 4
        if (data.checks.g5) sc += 5
        if (data.checks.g6) sc += 6

        if (data.checks.n3) sc += 3
        if (data.checks.n6) sc += 6

        if (data.checks.r6) sc += 6
        if (data.checks.r9) sc += 9
        if (data.checks.r12) sc += 12
        if (data.checks.r24) sc += 24

        data.values.desingr_sum = sc.toString()

        return sc
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content", "values"])
        const saC = sa.generateChild(["content", "checks"])
        const data = doc.content as FormModel.JokusoKeikaku.Core

        const elements_basic = [
            { name: "生年月日", content: saV.date("pat_birthday") },
            { name: "作成者", content: saV.text("user_name") },
        ]

        const umuListGenzai = [
            { label: "仙骨部", key: "genzai_1" },
            { label: "坐骨部", key: "genzai_2" },
            { label: "尾骨部", key: "genzai_3" },
            { label: "腸骨部", key: "genzai_4" },
            { label: "大転子部", key: "genzai_5" },
            { label: "踵部", key: "genzai_6" },
            { label: "その他", key: "genzai_7" }
        ]
        const umuListKako = [
            { label: "仙骨部", key: "kako_1" },
            { label: "坐骨部", key: "kako_2" },
            { label: "尾骨部", key: "kako_3" },
            { label: "腸骨部", key: "kako_4" },
            { label: "大転子部", key: "kako_5" },
            { label: "踵部", key: "kako_6" },
            { label: "その他", key: "kako_7" }
        ]

        const elements_umu = [
            { name: "現在", content: saC.checkList(umuListGenzai) },
            {
                name: "その他(現在)", content:
                    (data.checks.genzai_7 ? saV.text("genzai_other") : <div>-</div>)
            },
            { name: "過去", content: saC.checkList(umuListKako) },
            {
                name: "その他(過去)", content:
                    (data.checks.kako_7 ? saV.text("kako_other") : <div>-</div>)
            },
            { name: "褥瘡発生日", content: saV.date("hassei_date") },
        ]

        const jiritsu_pair = [
            { label: "J1", key: "jiritsu_j1", value: data.checks.jiritsu_j1 },
            { label: "J2", key: "jiritsu_j2", value: data.checks.jiritsu_j2 },
            { label: "A1", key: "jiritsu_a1", value: data.checks.jiritsu_a1 },
            { label: "A2", key: "jiritsu_a2", value: data.checks.jiritsu_a2 },
            { label: "B1", key: "jiritsu_b1", value: data.checks.jiritsu_b1 },
            { label: "B2", key: "jiritsu_b2", value: data.checks.jiritsu_b2 },
            { label: "C1", key: "jiritsu_c1", value: data.checks.jiritsu_c1 },
            { label: "C2", key: "jiritsu_c2", value: data.checks.jiritsu_c2 },
        ]
        const kiken_pairs = [
            [
                { label: "できる", key: "c1_bed_ari", value: data.checks.c1_bed_ari },
                { label: "できない", key: "c1_bed_nashi", value: data.checks.c1_bed_nashi },
            ],
            [
                { label: "できる", key: "c1_isu_ari", value: data.checks.c1_isu_ari },
                { label: "できない", key: "c1_isu_nashi", value: data.checks.c1_isu_nashi },
            ],
            [
                { label: "なし", key: "c2_nashi", value: data.checks.c2_nashi },
                { label: "あり", key: "c2_ari", value: data.checks.c2_ari },
            ],
            [
                { label: "なし", key: "c3_nashi", value: data.checks.c3_nashi },
                { label: "あり", key: "c3_ari", value: data.checks.c3_ari },
            ],
            [
                { label: "なし", key: "c4_nashi", value: data.checks.c4_nashi },
                { label: "あり", key: "c4_ari", value: data.checks.c4_ari },
            ],
            [
                { label: "なし", key: "c5_nashi", value: data.checks.c5_nashi },
                { label: "あり", key: "c5_ari", value: data.checks.c5_ari },
            ],
            [
                { label: "なし", key: "c6_nashi", value: data.checks.c6_nashi },
                { label: "あり", key: "c6_ari", value: data.checks.c6_ari },
            ],
        ]

        const elements_kiken = [
            { name: "日常生活自立度", content: saC.checkList(jiritsu_pair, false, true) },
            {
                name: "基本的動作能力", content: <Col2Table disableBGColor={true} body={
                    [
                        { name: "ベッド上　自力体位変換", content: saC.checkList(kiken_pairs[0], false, true) },
                        { name: "イス上　坐位姿勢の保持、除圧", content: saC.checkList(kiken_pairs[1], false, true) }
                    ]
                } />
            },
            { name: "病的骨突出", content: saC.checkList(kiken_pairs[2], false, true) },
            { name: "関節拘縮", content: saC.checkList(kiken_pairs[3], false, true) },
            { name: "栄養状態低下", content: saC.checkList(kiken_pairs[4], false, true) },
            { name: "皮膚湿潤（多汗、尿失禁、便失禁）", content: saC.checkList(kiken_pairs[5], false, true) },
            { name: "浮腫（局所以外の部位）", content: saC.checkList(kiken_pairs[6], false, true) },
        ]

        const jokuso_pair = jokuso_lists.map(list => {
            return list.map(x => {
                const name = this.toDesignRName(x)
                return { label: x, key: name, value: data.checks[name] }
            })
        })

        const elements_design_r = [
            {
                name: "DESIGN-R",
                content: <Col2Table disableBGColor={true} body={[
                    { name: "深さ", content: saC.checkList(jokuso_pair[0], false, true) },
                    { name: "浸出液", content: saC.checkList(jokuso_pair[1], false, true) },
                    { name: "大きさ(cm^2)", content: saC.checkList(jokuso_pair[2], false, true) },
                    { name: "炎症・感染", content: saC.checkList(jokuso_pair[3], false, true) },
                    { name: "肉芽形成", content: saC.checkList(jokuso_pair[4], false, true) },
                    { name: "壊死組織", content: saC.checkList(jokuso_pair[5], false, true) },
                    { name: "ポケット(cm^2)", content: saC.checkList(jokuso_pair[6], false, true) },
                    { name: "合計点", content: <div>{this.calcScore(data)}</div> }
                ]} />
            }
        ]

        const elements_keikaku = [
            {
                name: "圧迫、ズレ力の排除 （体位変換、体圧分散寝具、頭部挙上方法、車椅子姿勢保持等）",
                content: <Col2Table disableBGColor={true} body={
                    [
                        { name: "ベッド上", content: saV.text("keikaku_bed") },
                        { name: "イス上", content: saV.text("keikaku_isu") }
                    ]
                } />
            },
            { name: "スキンケア", content: saV.text("keikaku_skin") },
            { name: "栄養状態改善", content: saV.text("keikaku_eiyo") },
            { name: "リハビリテーション", content: saV.text("keikaku_riha") }
        ]

        const elements_images = [
            {
                name: "画像(3枚まで)", content: sa.docImage(3)
            }
        ]

        const elements = [
            { name: '基本情報', content: elements_basic },
            { name: '危険因子の評価', content: elements_kiken },
            { name: '褥瘡の有無', content: elements_umu },
            { name: '褥瘡の状態の評価(DESIGN-R)', content: elements_design_r },
            { name: '看護計画', content: elements_keikaku },
            { name: '画像', content: elements_images }
        ]

        return elements
    }
}
