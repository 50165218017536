import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
import * as Colors from '../components/Colors'
import { Tab, Tabs, Paper } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import DocumentList from '../components/DocumentList'
import MonthlyDocumentList from '../components/MonthlyDocumentList';
import NewestDocumentList from '../components/NewestDocumentList';
import WorkLog from '../components/WorkLog';
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    bar: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.borderColor,
        backgroundColor: Colors.backColor
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        // background: Colors.primaryColor,

    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700
    },
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    showButton: boolean,
    isActive: boolean
}

interface LocalState {
    page: number,
    loading: boolean,
    error: string | null
}

// @inject()
// @observer
class DocumentListPage extends React.Component<Props, LocalState> {
    displayName = "DocumentListPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            page: 0,
            error: null
        }
    }

    public componentDidMount = () => {
    }

    public render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            <div>
                <Paper square>
                    <Tabs value={this.state.page}
                        className={classes.tabs}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={(e, value) => { this.setState({ page: value }) }}>
                        <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                            label={<span className={classes.tabTextSub}>全て</span>} />
                        <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                            label={<span className={classes.tabTextSub}>計画書</span>} />
                        <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                            label={<span className={classes.tabTextSub}>報告書</span>} />
                        <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                            label={<span className={classes.tabTextSub}>情報提供書</span>} />
                        <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                            label={<span className={classes.tabTextSub}>PT/OT/ST訪看詳細</span>} />
                        <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                            label={<span className={classes.tabTextSub}>褥瘡対策<br />計画書</span>} />
                        <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                            label={<span className={classes.tabTextSub}>業務日報</span>} />
                    </Tabs>
                </Paper>
                <div className={classes.listRoot}>
                    <SwipeableViews
                        index={this.state.page}
                        onChangeIndex={(index) => this.setState({ page: index })}>
                        <div style={{ textAlign: "center" }}>
                            {
                                this.state.page == 0 ?
                                    <DocumentList
                                        onSelect={() => { }}
                                    /> : null
                            }
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {
                                this.state.page == 1 ? <MonthlyDocumentList
                                    typeId={7}
                                    onSelect={() => { }}
                                    showWholeDownload={true}
                                /> : null
                            }
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {
                                this.state.page == 2 ?
                                    <MonthlyDocumentList
                                        typeId={9}
                                        onSelect={() => { }}
                                        showWholeDownload={true}
                                    /> : null}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {
                                this.state.page == 3 ?
                                    <MonthlyDocumentList
                                        typeId={27}
                                        onSelect={() => { }}
                                        showWholeDownload={true}
                                    /> : null
                            }
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {
                                this.state.page == 4 ?
                                    <MonthlyDocumentList
                                        typeId={68}
                                        onSelect={() => { }}
                                        showWholeDownload={true}
                                    /> : null
                            }
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {
                                this.state.page == 5 ?
                                    <NewestDocumentList
                                        typeId={10}
                                        onSelect={() => { }}
                                    />
                                    : null
                            }
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {
                                this.state.page == 6 ?
                                    <WorkLog />
                                    : null
                            }
                        </div>
                    </SwipeableViews>
                </div>
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(DocumentListPage))