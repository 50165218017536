import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { CallProps } from './CallComponent';
import * as Colors from "../Colors"
import { List, ListItem, TextField, MenuItem, Select, Switch, Collapse, Button, Grid, ListItemText, ListItemIcon } from '@material-ui/core';
import Loading from '../Loading';
const moment = require("moment-timezone") as MomentTimezone
import {MomentTimezone} from 'moment-timezone'
import * as Model from '../../models';
import * as HokanApi from '../../api/hokan'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const itemStyleBase = {
    border: "solid",
    borderWidth: "1px",
    marginTop: "-1px",
    borderColor: Colors.borderColor,
    padding: "10px 5px",
    transition: "linear 0.15s",
    '&:hover': {
        backgroundColor: Colors.backColor
    },
    "&:last-child": {
        
        
    },
    "&:first-child": {
    },
    "& div": {
        "& div": {
            /* input */
            color: Colors.inputColor
        }
    }
}

const transitionBase = {
    position: "absolute" as "absolute",
    width: "100%",
    height: "100%",
    transition: "0.4s",
    backgroundColor: "#FFF"
}

const styles = () => Styles.createStyles({
    
    root: {
        overflow: "hidden",
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    newTransitionList: {
        ...transitionBase,
        transform: "translate(0%,100%)",
    },
    newTransitionNew: {
        ...transitionBase,
        transform: "translate(0%,0%)",
    },
    newTransitionId: {
        ...transitionBase,
        transform: "translate(0%,100%)",
    },
    editTransitionList: {
        ...transitionBase,
        transform: "translate(100%,0%)",
    },
    editTransitionNew: {
        ...transitionBase,
        transform: "translate(100%,0%)",
    },
    editTransitionId: {
        ...transitionBase,
        transform: "translate(0%,0%)",
    },
    list: {
        marginBottom: "20px"

    },
    itemHeader: {
        background: Colors.primaryColor,
        
    },
    itemTitle:{
        width:"70px"
    },
    item: itemStyleBase,
    itemHidden: {
        ...itemStyleBase,
        borderWidth:"0px",
    },
    textField:{

    },
    textFieldDetail:{
        
    },
    loadingDiv:{
        position:"absolute",
        height:"100%",
        width:"100%",
        background:"rgba(255,255,255,0.5)",
        zIndex:2
    },
    loadingDivElement: {
        position: "absolute",
        margin : "auto",
        top : "50%",
        bottom :0,
        left : 0,
        right : 0
    }
});


interface Props extends CallProps,Styles.WithStyles<typeof styles> {
    isNew : boolean
    backToList : () => void
}

interface LocalState {
    patientId:number,
    callKindId:number,
    isHomon:boolean,
    patients:Model.Patient.Patient[],
    callKinds:Model.CallKind[],
    attendingYear: number,
    attendingMonth: number,
    attendingDay: number,
    attendingHour: number,
    attendingMinuts: number,
    detail:string,
    loading:boolean
}

// @inject()
// @observer
class TemplatePage extends React.Component<Props, LocalState> { 
	displayName="TemplatePage"
    constructor(props: Props) {
        super(props)
        this.state = {
            patientId:1,
            callKindId:1,
            isHomon:false,
            patients:[],
            callKinds:[],
            attendingYear:moment().get("year"),
            attendingMonth:moment().get("month")+1,
            attendingDay:moment().get("date"),
            attendingHour:moment().get("hour"),
            attendingMinuts:moment().get("minutes"),
            detail:"",
            loading:true
        }
    }

    componentDidMount(){
        setTimeout(() => {
            const kindPromise = HokanApi.getCallKinds().then(
                res => {
                    const callKinds = res.data
                    console.log(callKinds)
                    this.setState({ callKinds })
                }
            )
            const patientPromise = HokanApi.getPatients().then(
                res => {
                    const patients = res.data
                    this.setState({ patients })
                }
            )
            const prevDataPromise: Promise<any> = typeof(this.props.mode) !== "number" ? new Promise((resolve) => resolve()) :
                HokanApi.getCall(this.props.mode as number).then(x => {
                    console.log(x)
                    const patientId = x.data.patient.id;
                    const callKindId = x.data.call_kind.id;
                    const attendingYear = moment(x.data.attending_time).get("year")
                    const attendingMonth = moment(x.data.attending_time).get("month") + 1
                    const attendingDay = moment(x.data.attending_time).get("date")
                    const attendingHour = moment(x.data.attending_time).get("hour")
                    const attendingMinuts = moment(x.data.attending_time).get("minutes")
                    const detail = x.data.detail
                    this.setState({
                        patientId,
                        callKindId,
                        attendingYear,
                        attendingDay,
                        attendingHour,
                        attendingMonth,
                        attendingMinuts,
                        detail
                    })
                })
            Promise.all([kindPromise, patientPromise, prevDataPromise]).then(() => {
                const loading = false;
                this.setState({ loading })
            })
        },1)
        
    }

    public send = () => {
        this.setState({loading:true})
        const call = new Model.CallToApi()
        call.patient_id = this.state.patientId
        call.detail = this.state.detail;
        call.call_kind_id = this.state.callKindId;
        call.attending_time = moment(`${this.state.attendingYear}-${this.state.attendingMonth}-${this.state.attendingDay} ${this.state.attendingHour}:${this.state.attendingMinuts}`).format()
        const promise = this.props.isNew ? HokanApi.postCall(call) : HokanApi.updateCall(call,this.props.mode as number)
        promise.then((x) => {
            console.log(x)
            this.setState({loading:false})
            this.props.backToList();
        }).catch((reason) => console.log(reason))
    }

    public render = () => {
        const { classes } = this.props
        const loading = this.state.loading

        const transitionClass = (() => {
            if(this.props.isNew){
                switch (this.props.mode) {
                    case "new":
                        return classes.newTransitionNew
                        break;
                    case "list":
                        return classes.newTransitionList
                        break;
                    default:
                        return classes.newTransitionId
                }
            }else{
                switch (this.props.mode) {
                    case "new":
                        return classes.editTransitionNew
                        break;
                    case "list":
                        return classes.editTransitionList
                        break;
                    default:
                        return classes.editTransitionId
                }
            }
        })()

        const loadingDom = loading == true ? (<div className={classes.loadingDiv}>
            <div className={classes.loadingDivElement}>
                <Loading />
            </div>
        </div>) : null

        return (
            <div>
                {loadingDom}
                <div className={transitionClass}>
                    <div className={classes.root}>
                        
                        <List>
                            <ListItem className={classes.itemHeader}>
                                コール
                            </ListItem>
                            <ListItem className={classes.item}>
                                <ListItemIcon>
                                    <span className={classes.itemTitle}>利用者名:</span>
                                </ListItemIcon>
                                <ListItemText>
                                <Select
                                    disabled={this.state.loading}
                                    value={this.state.patientId}
                                    onChange={(e) => { this.setState({ patientId: Number.parseInt(e.target.value as string) }) }}
                                >
                                {
                                    this.state.patients.map((x,i) => {
                                        return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                                    })
                                }
                                </Select>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes.item}>
                                <ListItemIcon>
                                    <span className={classes.itemTitle}>対応日時:</span>
                                </ListItemIcon>
                                <ListItemText>
                                <Grid container>
                                <Select
                                    disabled={this.state.loading}
                                    value={this.state.attendingYear}
                                    onChange={(e) => { this.setState({ attendingYear: Number.parseInt(e.target.value as string) }) }}
                                >
                                    {
                                        Array.from(new Array(20)).map((v,i) => {
                                            return <MenuItem key={i} value={moment().year() - i}>{moment().year() - i}年</MenuItem>
                                        })
                                    }
                                </Select>
                                <Select
                                    disabled={this.state.loading}
                                    value={this.state.attendingMonth}
                                            onChange={(e) => { this.setState({ attendingMonth: Number.parseInt(e.target.value as string) }) }}
                                >
                                    {
                                        Array.from(new Array(12)).map((v, i) => {
                                            return <MenuItem key={i} value={i+1}>{i+1}月</MenuItem>
                                        })
                                    }
                                </Select>
                                <Select
                                    disabled={this.state.loading}
                                    value={this.state.attendingDay}
                                            onChange={(e) => { this.setState({ attendingDay: Number.parseInt(e.target.value as string) }) }}
                                >
                                    {
                                        Array.from(new Array(30)).map((v, i) => {
                                            return <MenuItem key={i} value={i + 1}>{i + 1}日</MenuItem>
                                        })
                                    }
                                </Select>
                                <Select
                                    disabled={this.state.loading}
                                    value={this.state.attendingHour}
                                            onChange={(e) => { this.setState({ attendingHour: Number.parseInt(e.target.value as string) }) }}
                                >
                                    {
                                        Array.from(new Array(24)).map((v, i) => {
                                            return <MenuItem key={i} value={i}>{i}時</MenuItem>
                                        })
                                    }
                                </Select>
                                <Select
                                    disabled={this.state.loading}
                                    value={this.state.attendingMinuts}
                                            onChange={(e) => { this.setState({ attendingMinuts: Number.parseInt(e.target.value as string) }) }}
                                >
                                    {
                                        Array.from(new Array(60)).map((v, i) => {
                                            return <MenuItem key={i} value={i}>{i}分</MenuItem>
                                        })
                                    }
                                </Select>
                                </Grid>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes.item}>
                                <ListItemIcon>
                                    <span className={classes.itemTitle}>
                                        分類:
                                    </span>
                                </ListItemIcon>
                                <ListItemText>
                                <Select
                                    disabled={this.state.loading}
                                    value={this.state.callKindId}
                                    onChange={(e) => {
                                        this.setState({
                                            callKindId: Number.parseInt(e.target.value as string)
                                    })}}
                                >
                                        {
                                            this.state.callKinds.map((x, i) => {
                                                return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                                            })
                                        }
                                </Select>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes.item}>
                                <ListItemIcon>
                                    <span className={classes.itemTitle}>詳細:</span>
                                </ListItemIcon>
                                <ListItemText>
                                <TextField value = {this.state.detail} onChange={(e) => this.setState({detail:e.target.value})} disabled={this.state.loading} multiline rows="4" className = {classes.textFieldDetail}/>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes.item}>
                                <ListItemIcon>
                                    <span className={classes.itemTitle}>
                                        訪問:
                                    </span>
                                </ListItemIcon>
                                <ListItemText>
                                <Switch disabled={this.state.loading} checked={this.state.isHomon} onClick={() => 
                                    this.setState({
                                        isHomon:!this.state.isHomon
                                    })
                                }></Switch>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={this.state.isHomon ? classes.item : classes.itemHidden}>
                                <Collapse in={this.state.isHomon}>
                                    hello
                                    hello
                                    hello
                                </Collapse>
                            </ListItem>
                            <div style={{textAlign:"center"}}>
                                <Button disabled={this.state.loading} variant="contained" onClick ={() => this.send()}>
                                    登録
                                </Button>
                            </div>
                        </List>
                    </div>
                </div>
            </div>
        )
    }
}

export default Styles.withStyles(styles)(TemplatePage)