import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../Colors'
import Loading from '../Loading'
import SubTitle from '../SubTitle'
import { toInjector, Problem } from '../../stores';
import { inject, observer } from 'mobx-react';
import { AssessmentComponent, AssessmentProps } from './AssessmentComponent'
import * as Hash from './Hash'
import GoPrevNext from './GoPrevNext'
import { AppBar, Tab, Tabs, FormControlLabel, Checkbox, FormControl, TextField } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import * as Model from '../../models'

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    bar: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.borderColor,
        backgroundColor: Colors.backColor
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        // background: Colors.primaryColor,

    },
    itemText: {
        paddingRight: "0px",
    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700
    },
    listRoot: {
        border: "solid",
        borderTop: "none",
        borderWidth: "1px",
        borderColor: Colors.borderColor,
        padding: "10px"
    },
    tabTextSub: {
        fontSize: "smaller"
    },
    subCategory: {
        borderRadius: "10px",
        border: "solid",
        borderColor: Colors.borderColor,
        borderWidth: "1px",
        padding: "0px 10px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        fontWeight: 700,
        boxShadow: "1px 1px 5px 0 #5563"
    },
    list: {
        padding: "10px",
        marginBottom: "10px",
        color: Colors.primaryColor
    },
    b_bar: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.borderColor,


        backgroundColor: Colors.backColor
    },
    b_tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        // background: Colors.primaryColor,

        borderBottomRightRadius: "15px"
    },
    b_itemText: {
        paddingRight: "0px",
    },
    b_tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
    },
    b_tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",


        fontWeight: 700
    },
});

interface Props extends Styles.WithStyles, AssessmentProps, Problem {
    ass: Model.Assessment.PostAssessment
    selectedInterventions: { id: number, comment: string }[]
    selectIntervention: (ids: { id: number, comment: string }[]) => void
    changeIntervention: (id: number, check: boolean, comment: string) => void
}

interface LocalState {
    page: number
}


@inject(...toInjector(Problem))
@observer
class Intervention extends AssessmentComponent<Props, LocalState> {
    displayName = "Intervention"
    constructor(props: Props) {
        super(props)
        this.state = {
            page: 0
        }
    }

    public componentDidMount = () => {

        window.scrollTo(0, 0)
    }

    private getChecked = (id: number) => {
        return this.props.selectedInterventions.findIndex(x => x.id == id) != -1
    }

    private getComment = (id: number) => {
        const i = this.props.selectedInterventions.findIndex(x => x.id == id)
        if (i == -1) return ""
        return this.props.selectedInterventions[i].comment
    }

    private goPrev = () => {
        this.props.gotoPage(Hash.Kbs)
    }

    private goNext = () => {
        this.props.gotoPage(Hash.Confirm)
    }

    public render() {
        const { classes } = this.props
        const problem = this.props.problem!.problem
        const loading = (!problem || this.props.problem!.loading)

        const category = ["TGC", "CM", "TP", "S"]

        return <div className={classes.root}>
            <SubTitle title="計画と介入"
                text="計画と介入を選択し、追加してください"
                subText="（※複数選択、追加可能）"
            />
            {
                loading ? <Loading /> :
                    <div>
                        <AppBar position="static" className={classes.bar}>
                            <Tabs value={this.state.page}
                                className={classes.tabs}
                                indicatorColor="primary"
                                variant="fullWidth"
                                onChange={(e, value) => this.setState({ page: value })}>
                                <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                                    label={<span className={classes.tabTextSub}>教育/相談/指導</span>} icon="TGC" />
                                <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                                    label={<span className={classes.tabTextSub}>ケアマネジメント</span>} icon="CM" />
                                <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                                    label={<span className={classes.tabTextSub}>直接ケア</span>} icon="TP" />
                                <Tab classes={{ root: classes.tab, selected: classes.tabSelected }}
                                    label={<span className={classes.tabTextSub}>観察</span>} icon="S" />
                            </Tabs>
                        </AppBar>
                        <div className={classes.listRoot}>
                            <SwipeableViews
                                index={this.state.page}
                                onChangeIndex={(index) => this.setState({ page: index })}>
                                {
                                    category.map((cat, i) => {
                                        if (this.state.page != i) return <div key={cat} />
                                        const intv = problem!.intervention.filter(i => i.category === cat)
                                        const tcats = Array.from(new Set(intv.map(x => x.target_category)));
                                        return <div key={cat}>
                                            {
                                                tcats.map(c => {
                                                    return <div key={cat + "_sub_" + c}>
                                                        <div className={classes.subCategory}>{c}</div>
                                                        <div className={classes.list}>
                                                            <FormControl key={cat}>
                                                                {
                                                                    intv.filter(x => x.target_category === c).map(i => {
                                                                        const chk = this.getChecked(i.id)
                                                                        const cm = this.getComment(i.id)
                                                                        return [<FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={chk}
                                                                                    onChange={(e) => this.props.changeIntervention(i.id, !chk, cm)}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            key={i.id}
                                                                            label={i.target}
                                                                        />,
                                                                        chk && i.target == "その他" ?
                                                                            <TextField
                                                                                className={classes.inputText}
                                                                                value={cm}
                                                                                fullWidth={true}
                                                                                helperText="その他の内容"
                                                                                onChange={(e) => {
                                                                                    this.props.changeIntervention(i.id, chk, e.target.value)
                                                                                }}
                                                                            />
                                                                            : null
                                                                        ]
                                                                    })
                                                                }
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                            </SwipeableViews>
                        </div>

                        <AppBar position="static" className={classes.b_bar}>
                            <Tabs value={this.state.page}
                                className={classes.b_tabs}
                                indicatorColor="primary"
                                variant="fullWidth"
                                onChange={(e, value) => this.setState({ page: value })}>
                                <Tab classes={{ root: classes.tab, selected: classes.b_tabSelected }}
                                    label={<span className={classes.tabTextSub}>教育/相談/指導</span>} icon="TGC" />
                                <Tab classes={{ root: classes.tab, selected: classes.b_tabSelected }}
                                    label={<span className={classes.tabTextSub}>ケアマネジメント</span>} icon="CM" />
                                <Tab classes={{ root: classes.tab, selected: classes.b_tabSelected }}
                                    label={<span className={classes.tabTextSub}>直接ケア</span>} icon="TP" />
                                <Tab classes={{ root: classes.tab, selected: classes.b_tabSelected }}
                                    label={<span className={classes.tabTextSub}>観察</span>} icon="S" />
                            </Tabs>
                        </AppBar>
                    </div>
            }
            <GoPrevNext onPrev={() => this.goPrev()} onNext={() => this.goNext()} />
        </div>
    }
}

export default Styles.withStyles(styles)(Intervention)