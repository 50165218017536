import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = () => Styles.createStyles({
    table: {
        margin: "0px"
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.white,
    },
    leftCell: {
        textAlign: "center",
        width: "15%",
        padding: "3px", 
        fontSize: "0.95rem",
        backgroundColor: Colors.secondaryColor,
        color: Colors.textColor,
        minWidth: "100px"
    },
    leftCellInner: {
        padding: "0px",
        height: "100%",
        verticalAlign: "bottom"
    },
    leftCellInnerText: {
    },
    rightCell: {
        color: Colors.inputColor,
        //margin: "10px",
        fontSize: "0.95rem",
        paddingLeft: "1px",
        //paddingTop: "15px",
        //paddingBottom: "15px"
    },
})

interface Props extends Styles.WithStyles<typeof styles> {
    elm: any
    shouldUpdate: any
}


class Col2TableRow extends React.Component<Props> {
    displayName = "Col2TableRow"
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(np, ns) {
        return np.shouldUpdate[np.elm.name]
    }

    public render() {
        const { classes } = this.props;
        return <TableRow key={`row_${this.props.elm.name}`} className={classes.row}>
            {
                this.props.elm.name != "" ?
                    <TableCell className={classes.leftCell} component="th" scope="row">
                        <div className={classes.leftCellInner}>
                            <div className={classes.leftCellInnerText}>
                                {this.props.elm.name}
                            </div>
                        </div>
                    </TableCell>
                    : null
            }
            <TableCell className={classes.rightCell} align="left">
                {this.props.elm.content()}
            </TableCell>
        </TableRow>
    }
}

export default Styles.withStyles(styles)(Col2TableRow)