export const primaryColor = "#62688D" //"#50B7AA"
export const primaryColorTransparent = "#E9EEF444"//"#20395744"
export const secondaryColor = "#E6EBF2"//"#78889A"
export const secondaryColorTransparent = "#78889A44"
export const thirdColor = "#808080"
export const thirdTransparent = "#80808044"
export const forthColor = "#F5F6FA"
export const forthColorTransparent = "#E8EbEE44"
export const barColor = "#162944"
export const barTransparent = "#16294444"
export const barIconColor = "#838383"
export const orangeColor = "#f7ae28"
export const orangeColorTransparent = "#f7ae2844"
export const borderColor = "#142946"
export const borderColorTransparent = "#14294655"
export const backColor = "#FFFFFF"
export const white = "#FFFFFF"
export const drawerIconColor = "#FFF6"
export const inputColor = "#000"
export const inputColorTransparent = "#0003"
export const dangerColor = "#c1272d" // "#BA324F"
export const dangerColorTransparent = "#c1272d44"
export const darkBlueColor = "#162944"

export const accentColor = "#4BBf90"
export const textColor = "#1A2253"

export const textSecondaryColor = "#808080"