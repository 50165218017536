import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../Colors'
import Button from '@material-ui/core/Button';

const buttonStyle = {
    width: "120px",
    height: "60px",
    margin: "20px"
}
const styles = () => Styles.createStyles({
    root: {
       color: Colors.primaryColor,
       textAlign: "center"
    },
    prevButton: {
        ...buttonStyle,
    },
    nextButton: {
        ...buttonStyle,
    }
});

interface Props extends Styles.WithStyles {
    onPrev: () => void,
    onNext: () => void,
    disableNext: boolean
}

interface LocalState {

}

class GoPrevNext extends React.Component<Props, LocalState> { 
    displayName="GoPrevNext"
    public static defaultProps = {
        disableNext: false
    }
    public render() {
        const { classes } = this.props
        return <div className={classes.root}>
            <Button className={classes.prevButton} variant="contained" color="default" onClick={(e) => this.props.onPrev()}>
                戻る
            </Button>
            <Button className={classes.nextButton} 
            variant="contained" color="primary"
             disabled={this.props.disableNext}
             onClick={(e) => this.props.onNext()}>
                次へ
            </Button>
        </div>
    }
}

export default Styles.withStyles(styles)(GoPrevNext)