import { observable, action, computed , autorun } from 'mobx';
import { login, cognitoLogin, authorizedFetch, logout, sessionObservable,  } from '../config/auth_axios';
import { ApiUrl } from '../config/api';

export default class AuthStore {
    @observable public username = ""
    @observable public password = ""
    @observable public accessToken = null as string|null
    @observable public refreshToken = null as string|null
    @observable public exampleData = ""
    @observable public loginError = ""
    @observable public backTo = "/"

    @computed public get isLogin() {
        // return sessionObservable.accessToken !== null
        return sessionObservable.cognitoJwt !== null
    }

    @action.bound public initialize() {
        autorun(() => {
            this.accessToken = sessionObservable.accessToken
            this.refreshToken = sessionObservable.refreshToken
        })
    }
    @action.bound public login(onSuccess = () => { ;}, onError = (err:any) => { ; }) {
        login({
            username: this.username,
            password: this.password
        }).then(() => {
            this.loginError = ""
            onSuccess();
        }).catch(err => { 
            this.loginError = err.toString() 
            onError(err)
        })
    }
    @action.bound public loginCognito(onSuccess = () => { ;}, onError = (err:any) => { ; }) {
        cognitoLogin({
            username: this.username,
            password: this.password
        }).then(() => {
            this.loginError = ""
            onSuccess();
        }).catch(err => { 
            this.loginError = err.toString() 
            onError(err)
        })
    }
    @action.bound public logout() {
        logout()
    }
    @action.bound public getData() {
        authorizedFetch.get(ApiUrl("me"))
        .then(x => {
            this.exampleData = x.data
         })
        .catch(err => this.exampleData = err.toString())
    }
}