import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as Colors from './Colors'
import { DialogTitle, Dialog, withStyles, Button, Select, MenuItem, Grid } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StoreAccessor } from '../util/StoreUtil';
import EditableCheckList from './EditableCheckList';
import EditableList from './EditableList';
import StandardTable from './StandardTable';
import Col2Table from './Col2Table';

const styles = () => Styles.createStyles({
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    dialog: {
        padding: "10px"
    },
    searchField: {
        maxWidth: "200px"
    },
    input: {
        color: Colors.inputColor,
        "& div": {
            padding: "10px"
        }
    },
    dialogPaper: {
        margin: "0px",
        width: "100%"
    }
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    }
}))(TableCell);


interface Props extends Styles.WithStyles<typeof styles> {
    open: boolean
    onSelect: (patient: Model.Patient.Patient | null) => void
    onCancel: () => void
}
interface LocalState {
    dialogElement: JSX.Element
    patients: Model.Patient.Patient[]
    branches: Model.Branch[]
    branchId: number | null
    searchName: string
    kanaFirstGyou: string
    kanaFirst: string
}

const akasatana = [
    { head: "あ", charas: "あいうえお" },
    { head: "か", charas: "かきくけこ" },
    { head: "さ", charas: "さしすせそ" },
    { head: "た", charas: "たちつてと" },
    { head: "な", charas: "なにぬねの" },
    { head: "は", charas: "はまやらわ" },
    { head: "ま", charas: "まみむめも" },
    { head: "や", charas: "やゆよ" },
    { head: "ら", charas: "らりるれろ" },
    { head: "わ", charas: "わをん" },
]
const akastanaHead = [{ key: "", label: "無" }].concat(akasatana.map(x => {
    return { key: x.head, label: x.head }
}))

class UserSelectDialog extends React.Component<Props, LocalState> {
    displayName = "UserSelectDialog"
    constructor(props: Props) {
        super(props)
        this.state = {
            dialogElement: <div />,
            patients: [],
            branches: [],
            branchId: null,
            searchName: "",
            kanaFirstGyou: "",
            kanaFirst: ""
        }
    }

    public componentDidMount = () => {
        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
        HokanApi.me().then(res => {
            this.loadPatients(res.data.branch_id)
        }).catch(err => {
            console.log(err)
            this.loadPatients(null)
        })
    }

    private loadPatients(branchId: number | null) {
        this.setState({ branchId: branchId })
        HokanApi.getPatients(branchId)
            .then(x => {
                this.setState({ patients: x.data.filter(p => !p.is_shinki) })
            })
            .catch(err => {
                console.log(err)
                this.setState({ patients: [] })
            })
    }

    public close = () => {
        this.props.onCancel();
    }

    private selectPatient(patient: Model.Patient.Patient) {
        const res = Model.Patient.Patient.load(patient)
        this.props.onSelect(res);
    }

    private notMatch = (filter: string, value: string) => {
        if (filter == "") return false;
        return value.indexOf(filter) == -1
    }
    private kanaToHira = (str: string) => {
        if (!str) return ""
        return str.replace(/[\u30a1-\u30f6]/g, function (match) {
            var chr = match.charCodeAt(0) - 0x60;
            return String.fromCharCode(chr);
        });
    }
    private notNameMatch = (filter: string, value: string, kana: string) => {
        if (value) {
            return this.notMatch(filter, value) &&
                this.notMatch(this.kanaToHira(filter), this.kanaToHira(kana));
        }
        return filter == "" ? false : true;
    }
    private isValidPat = (x: Model.Patient.Patient) => {
        let isOk = true;
        if (x.status == "終了") isOk = false;
        if (this.notNameMatch(this.state.searchName, x.name, x.name_kana)) isOk = false;
        const headGyou = this.state.kanaFirstGyou
        if (headGyou && headGyou != "") {
            const head = this.kanaToHira(x.name_kana)
            const aka = akasatana.find(k => k.head == headGyou)
            if (head.length <= 0) isOk = false
            else if (aka) {
                if (aka.charas.indexOf(head[0]) < 0) isOk = false
                if (this.state.kanaFirst && this.state.kanaFirst != "") {
                    if (head[0] != this.state.kanaFirst) isOk = false
                }
            }
        }
        return isOk
    }

    public render() {
        const { classes } = this.props
        const { patients } = this.state
        if (this.props.open == false) return <div />

        const sa = new StoreAccessor(this, true)

        const akastanaBody = (head: string, target: string) => {
            if (head == "" || !head) return []
            const t = akasatana.find(x => x.head == head)
            if (!t) return []
            const res = [{ key: "", value: "" == target, label: "無" }]
            for (let i = 0; i < t.charas.length; ++i) {
                const c = t.charas[i]
                res.push({ key: c, value: c == target, label: c })
            }
            return res
        }
        const element_l = [
            {
                name: "事業所", content: <div>
                    <Select
                        value={this.state.branchId || ''}
                        autoWidth={true}
                        variant='outlined'
                        onChange={(e) => this.loadPatients(e.target.value as (number | null))}
                        className={classes.input}
                    >
                        {
                            this.state.branches.map((t, idx) =>
                                <MenuItem key={`lis+${t.id}`} value={t.id}>{t.name}</MenuItem>
                            )
                        }
                    </Select>
                </div>
            },
            { name: "利用者名", content: sa.text("searchName") },
            {
                name: "頭文字", content: <div>
                    <EditableList
                        editMode={true}
                        name=""
                        label=""
                        value={this.state.kanaFirstGyou}
                        list={akastanaHead.map(x => { return { key: x.key, value: x.key } })}
                        setData={(n, v) => { this.setState({ kanaFirstGyou: v, kanaFirst: "" }) }}
                    />
                    {
                        this.state.kanaFirstGyou && this.state.kanaFirstGyou != "" ?
                            <EditableCheckList
                                editMode={true}
                                vertical={false}
                                onlyOne={true}
                                list={akastanaBody(this.state.kanaFirstGyou, this.state.kanaFirst)}
                                setData={(n, v) => {
                                    if (v) {
                                        this.setState({ kanaFirst: n })
                                    }
                                }} />
                            : null
                    }
                </div>
            },
        ]

        return <Dialog
            className={classes.dialog}
            onClose={(x) => this.close()}
            fullWidth={true}
            open={true}
            style={{ padding: "10px" }}
            PaperProps={{ classes: { root: classes.dialogPaper } }}
        >
            {
                [
                    <DialogTitle>利用者選択</DialogTitle>,
                    <div style={{ display: "block", width: "100%" }}>
                        <StandardTable title='検索'>
                            <Grid container={true} spacing={0}>
                                <Grid item={true} xs={12} md={12} lg={12}>
                                    <Col2Table body={element_l} />
                                </Grid>
                            </Grid>
                        </StandardTable>
                    </div>,
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell align="center">名前</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                patients.filter(x => this.isValidPat(x)).map((pat, ti) => {
                                    return <TableRow key={ti} className={classes.row}
                                        onClick={(e) => { this.selectPatient(pat) }}>
                                        <CustomTableCell style={{ textAlign: "center", padding: "5px" }} component="th" scope="row">{pat.name}</CustomTableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>,
                    <Button variant="outlined" color="default"
                        onClick={(e) => this.close()}>
                        戻る
                    </Button>
                ]
            }
        </Dialog>
    }
}

export default Styles.withStyles(styles)(UserSelectDialog)