// import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class HokenBehaviour implements FormBehaviour {
    public type_id:number

    public constructor(type_id:number) {
        this.type_id = type_id
    }
    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient) {
        doc.patient = pat
    }

    public setUser(doc: Model.Document.Document, user: Model.User) {
    }

    public shutten() { return null }

    public isFullWidth() {
        return false;
    }
    public isPreviewable(): boolean { return false }
    public getId(): number { return this.type_id }

    public createContent(): any {
        const res = new FormModel.Hoken.Core()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost(doc: Model.Document.Document) {
    }

    public afterPost(doc: Model.Document.Document): void {
    }
    public createSucceed(doc: Model.Document.Document, reload: any): void {
        if (reload) reload();
    }
    public beforeMount = () => {
    }
    public buildElements(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const hoken_kubun_list = [
            { key: "", value: 0 },
            { key: "社保", value: 1 },
            { key: "国保", value: 2 },
            { key: "公費", value: 3 },
            { key: "後期高齢者", value: 4 }
        ]
        const saV = sa.generateChild(["content"])
        const elements_basic = [
            { name: "利用者", content: sa.patient("patient", "patientOpenDialog") },
//            { name: "保険区分", content: saV.listRawC([], "kubun", hoken_kubun_list) },
//            { name: "保険者番号", content: saV.text("hokensha") },
//            { name: "被保険者番号", content: saV.text("bango") },
//            { name: "記号番号", content: saV.text("kigo") },
            { name: "期限", content: saV.date("kigen_to") },
            { name: "メモ", content: sa.text("comment") },
        ]

        const elements_images = [
            {
                name: "画像", content: sa.docImage(10)
            },
            {
                name: "PDF", content: sa.docFile(10)
            }
        ]


        const elements = [
            { name: '基本', content: elements_basic },
            { name: '画像', content: elements_images },
        ]

        return elements
    }
}
