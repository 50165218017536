import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class MouseOHATBehaviour extends FormBehaviour.PdfBehaviour  { 
	displayName="MouseOHATBehaviour"

    public getId(): number {
        return 12
    }

    public createContent(): any {
        const res = new FormModel.MouseOHAT.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    private calcSum(data:FormModel.MouseOHAT.Core) {
        return Math.max(data.values.score_daeki, 0) +
            Math.max(data.values.score_gishi, 0) +
            Math.max(data.values.score_koushin, 0) +
            Math.max(data.values.score_seisou, 0) +
            Math.max(data.values.score_shiniku, 0) +
            Math.max(data.values.score_shita, 0) +
            Math.max(data.values.score_shitsuu, 0) +
            Math.max(data.values.score_zanzon, 0)
    }
    public beforePost_pdf(doc: Model.Document.Document) {
        const content: FormModel.MouseOHAT.Core = doc.content
        content.values.sum_score = this.calcSum(content)
        content.checks.gishi_nashi = (content.values.score_gishi == -1)
        content.checks.zanzon_nashi = (content.values.score_zanzon == -1)
    }

    public beforeMount = () => {
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content", "values"])
        const elements_scores = [
            {
                name: "口唇", content: saV.listRaw("score_koushin", [
                    { key: "未選択", value: -1 },
                    { key: "０：正常、湿潤、ピンク", value: 0 },
                    { key: "１：乾燥、ひび割れ、口角の発赤", value: 1 },
                    { key: "２：腫脹や腫瘤、赤色斑、白色斑、潰瘍性出血、口角からの出血、潰瘍", value: 2 },
                ])
            },
            {
                name: "舌", content: saV.listRaw("score_shita", [
                    { key: "未選択", value: -1 },
                    { key: "０：正常、湿潤、ピンク", value: 0 },
                    { key: "１：不整、亀裂、発赤、舌苔付着", value: 1 },
                    { key: "２：赤色斑、白色斑、潰瘍、腫脹", value: 2 },
                ])
            },
            {
                name: "歯肉・粘膜", content: saV.listRaw("score_shiniku", [
                    { key: "未選択", value: -1 },
                    { key: "０：正常、湿潤、ピンク、出血なし", value: 0 },
                    { key: "１：乾燥、光沢、粗造、発赤、部分的な（1-6歯分）腫脹、義歯下の一部潰瘍", value: 1 },
                    { key: "２：腫脹、出血（7歯分以上）、歯の動揺、潰瘍、白色斑、発赤、圧痛", value: 2 },
                ])
            },
            {
                name: "唾液", content: saV.listRaw("score_daeki", [
                    { key: "未選択", value: -1 },
                    { key: "０：湿潤、漿液性", value: 0 },
                    { key: "１：乾燥、べたつく粘膜、少量の唾液、口渇感若干あり", value: 1 },
                    { key: "２：赤く干からびた状態、唾液ほぼなし、粘性の高い唾液、口渇感あり", value: 2 },
                ])
            },
            {
                name: "残存歯の有無", content: saV.listRaw("score_zanzon", [
                    { key: "なし", value: -1 },
                    { key: "０：歯・歯根のう蝕または破折なし", value: 0 },
                    { key: "１：3本以下のう蝕、歯の破折、残根、咬耗", value: 1 },
                    { key: "２：4本以上のう蝕、歯の破折、残根、非常に強い咬耗、義歯使用なしで3本以下の残存歯", value: 2 },
                ])
            },
            {
                name: "義歯の有無", content: saV.listRaw("score_gishi", [
                    { key: "なし", value: -1 },
                    { key: "０：正常、義歯・人工歯の破折なし、普通に装着できる状態", value: 0 },
                    { key: "１：一部位の義歯・人工歯の破折、毎日1-2時間の装着のみ可能", value: 1 },
                    { key: "２：2部位以上の義歯・人工歯の破折、義歯紛失、義歯不具合のため未装着、義歯接着剤が必要", value: 2 },
                ])
            },
            {
                name: "口腔清掃", content: saV.listRaw("score_seisou", [
                    { key: "未選択", value: -1 },
                    { key: "０：口腔清掃状態良好、食渣、歯石、プラークなし", value: 0 },
                    { key: "１：1-2部位に食渣、歯石、プラークあり、若干口臭あり", value: 1 },
                    { key: "２：多くの部位に食渣、歯石、プラークあり、強い口臭あり", value: 2 },
                ])
            },
            {
                name: "歯痛", content: saV.listRaw("score_shitsuu", [
                    { key: "未選択", value: -1 },
                    { key: "０：疼痛を示す言動的身体的な兆候なし", value: 0 },
                    { key: "１：疼痛を示す言動的な兆候あり、顔を引きつらせる、口唇を噛む、食事をしない、攻撃的になる", value: 1 },
                    { key: "２：疼痛を示す身体的な兆候あり、頬、歯肉の腫脹、歯の破折、潰瘍、歯肉下腫瘍、言動的な兆候もあり", value: 2 },
                ])
            },
            {
                name: "合計点", content: <div>{this.calcSum(doc.content)}</div>
            }
        ]

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
