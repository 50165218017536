import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour   { 
	displayName="Behaviour"
    
    public getId(): number {
        return 21
    }

    public createContent(): any {
        const res = new FormModel.MentalStrength1.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }


    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content", "values"])

        const contents = [
            { title: "日常生活", name: "nichijo" },
            { title: "経済的なこと", name: "keizai" },
            { title: "仕事・教育", name: "shigoto" },
            { title: "社会的支援", name: "shien" },
            { title: "健康", name: "kenkou" },
            { title: "趣味・余暇", name: "shumi" },
            { title: "趣性格や文化", name: "seikaku" },
        ]
        const elements_scores = contents.map(ct => {
             return {
                 name: ct.title, content: <div>
                     <br />
                     現在のストレングス今の私のストレングスは何か？<br/>
                     （才能、スキル、個人的/環境的）：
                     {saV.text(ct.name + "_1")}
                     <br/>
                     個人の希望・願望/何を希望するのか？：
                     {saV.text(ct.name + "_2")}
                     <br />
                     資源：個人的、社会的<br/>
                     過去に私が利用したストレングスは何か？：
                     {saV.text(ct.name + "_3")}
                     <br />
                    </div>
            }
        })

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
