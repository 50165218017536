import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import FormBehaviour from './FormBehaviour'
import DateUtil from '../../util/DateUtil';
import * as HokanApi from '../../api/hokan'

// @inject()
// @observer
export default class ShijishoBehaviour implements FormBehaviour {
    public type_id: number

    public constructor(type_id: number) {
        this.type_id = type_id
    }
    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient) {
        doc.patient = pat
    }

    public setUser(doc: Model.Document.Document, user: Model.User) {
    }

    public shutten() { return null }

    public isFullWidth() {
        return false;
    }
    public isPreviewable(): boolean { return false }
    public getId(): number { return this.type_id }

    public createContent(): any {
        const res = new FormModel.Shijisho.Core()
        return res
    }

    public afterPost(doc: Model.Document.Document): void {

    }

    public createSucceed(doc: Model.Document.Document, reload: any): void {
        const content = doc.content as FormModel.Shijisho.Core
        const t: any = {}
        t.task_date = content["date_from"] ? content["date_from"] : null;
        t.content = doc.document_type.name + "の確認"
        t.is_task = true
        if (doc.patient) {
            HokanApi.message.post_wc(doc.patient.id, t).then(x => {
                reload()
            })
        }
        else {
            reload();
        }
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost(doc: Model.Document.Document) {
        const content = doc.content as FormModel.Shijisho.Core;
        content.values.date_from = content["date_from"] || "";
        content.values.date_to = content["date_to"] || "";
        doc.title = (content.values.date_from + "〜" + content.values.date_to)
    }

    public beforeMount = () => {
    }

    public buildElements(doc: Model.Document.Document, sa: StoreAccessor) {
        if (!doc.content) doc.content = this.createContent()
        if (!doc.content.values) doc.content.values = new FormModel.Shijisho.Values;
        if (!doc.content.checks) doc.content.checks = new FormModel.Shijisho.Checks;
        const saV = sa.generateChild(["content"])
        const elements_basic = [
            { name: "利用者", content: sa.patient("patient", "patientOpenDialog") },
            { name: "期間開始", content: saV.date("date_from") },
            { name: "期間終了", content: saV.date("date_to") },
            { name: "タイトル(自動)", content: <div>{doc.title}</div> },
            { name: "メモ", content: sa.text("comment") },
        ]

        const elements_images = [
            {
                name: "画像", content: sa.docImage(10)
            },
            {
                name: "PDF等", content: sa.docFile(10)
            }
        ]

        const elements = [
            { name: '基本', content: elements_basic },
            { name: '画像', content: elements_images },
        ]

        return elements
    }
}
