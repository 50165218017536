import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Colors from './Colors'
import { withStyles } from '@material-ui/core/styles';
import DocumentDialog from './DocumentDialog';
import { StoreAccessor } from '../util/StoreUtil';
import DateUtil from '../util/DateUtil'
import { Select, MenuItem, Grid, Checkbox, Button } from '@material-ui/core';
import StandardTable from './StandardTable';
import Col2Table from './Col2Table';
import Pagination from "material-ui-flat-pagination";
import Loading from './Loading'
// const moment = require("moment-timezone")
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        //cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    bar: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.borderColor,
        backgroundColor: Colors.backColor
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        // background: Colors.primaryColor,

    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700
    },
    itemCheck: {
        textAlign: "center",
        "& span": {
            padding: "0px"
        }
    }
})


interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    onSelect: (doc: Model.Document.Document) => void
    typeId: number
    patientId?: number
}

interface LocalState {
    documentList: Model.Document.NewestDocumentList,
    branches: Model.Branch[],
    branch_id: number | null,
    loading: boolean,
    page: number,
    query: Query,
    openDialog: boolean,
    me: Model.User,
    selectedPatientId?: number,
    selectedDocumentId: number | null,
    sortKey: string,
    sortDir: number,
    checked: any,
    errormsg: string,
    posting: boolean,
    wholeSorted: boolean,
    selectAll: boolean,
    pageOffset: number
}


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

class Query {
    name: string = ""
    onlyMe: boolean = false
}
// @inject()
// @observer
class NewestDocumentList extends React.Component<Props, LocalState> {
    displayName = "NewestDocumentList"
    public static defaultProps = {
        typeId: undefined
    }

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            branches: [],
            branch_id: null,
            query: new Query(),
            me: new Model.User(),
            documentList: new Model.Document.NewestDocumentList(),
            page: 0,
            openDialog: false,
            selectedPatientId: undefined,
            selectedDocumentId: null,
            sortKey: "name_kana",
            sortDir: 1,
            checked: {},
            posting: false,
            errormsg: "",
            wholeSorted: false,
            selectAll: false,
            pageOffset: 0
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true })
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
            this.loadDocuments(x.data.branch_id)
        })
        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private loadDocuments(branch_id: number | null) {
        if (branch_id == null) return;
        this.setState({ loading: true, branch_id: branch_id })
        const option = { patientId: this.props.patientId, branchId: branch_id }
        HokanApi.getNewestDocuments(this.props.typeId, option).then(res => {
            res.data.pairs.forEach(x => {
                if (x && x.doc && x.doc.content) {
                    x.doc.content = JSON.parse(x.doc.content as any)
                }
            })
            this.setState({
                documentList: res.data, loading: false, pageOffset: 0
            });
        })
            .catch(e => {
                console.log(e);
                this.setState({ loading: false, pageOffset: 0 })
            })
    }

    private reload() {
        this.loadDocuments(this.state.branch_id)
    }

    private setSortKey(key: string) {
        const dir = (this.state.sortKey == key ? this.state.sortDir * -1 : 1)
        this.setState({ sortKey: key, sortDir: dir })
    }

    private queryCallback(category: string[], name: string, value: any) {
        this.setState({ pageOffset: 0 })
    }

    private docChecked(id: number) {
        return this.state.checked[id] == true
    }

    private hiraToKana(str) {
        return str.replace(/[\u3041-\u3096]/g, function (match) {
            var chr = match.charCodeAt(0) + 0x60;
            return String.fromCharCode(chr);
        });
    }
    private toggleCheck(id: number) {
        const checked = this.state.checked;
        if (checked[id]) checked[id] = false
        else checked[id] = true
        this.setState({ checked: checked })
    }

    private downloadChecked() {
        const ids: number[] = []
        Object.keys(this.state.checked).map(key => {
            if (this.state.checked[key]) {
                ids.push(parseInt(key))
            }
        })

        this.setState({ posting: true, errormsg: "" })
        HokanApi.generatePdfList(ids.sort(), this.state.wholeSorted, this.props.typeId).then(res => {
            this.setState({ posting: false, errormsg: "" })
            var blob = new Blob([res.data], { type: 'application/pdf' });
            var pdfURL = (window.URL || window.webkitURL).createObjectURL(blob);
            window.open(pdfURL, "_blank")
        })
            .catch(err => {
                this.setState({ posting: false, errormsg: "エラー" })
            })
    }

    private downloadPdf(doc: Model.Document.Document) {
        HokanApi.getDocumentPdfUrl(doc.id).then(x => {
            doc.pdf_url = x.data
            window.open(x.data, "_blank")
            this.setState({ documentList: this.state.documentList })
        }).catch(err => {
            console.log(err)
        })
    }

    public render() {
        const { classes } = this.props;
        const { query, documentList } = this.state;
        const saQ = new StoreAccessor(this, true, "query")
        saQ.callBack = (cat, name, v) => this.queryCallback(cat, name, v)

        const element_l = [
            {
                name: "", content: <div>
                    <Select
                        value={this.state.branch_id || '0'}
                        autoWidth={true}
                        variant='outlined'
                        onChange={(e) => this.loadDocuments(e.target.value as (number | null))}
                        className={classes.input}
                    >
                        <MenuItem key={`lis+0`} value={0}>全事業所</MenuItem>
                        {
                            this.state.branches.map((t, idx) =>
                                <MenuItem key={`lis+${t.id}`} value={t.id}>{t.name}</MenuItem>
                            )
                        }
                    </Select>
                </div>
            },
            { name: "", content: saQ.text("name", "利用者名、担当者名") },
            {
                name: "", content: saQ.checkList(
                    [{ label: "自担当のみ", key: "onlyMe" }
                    ])
            }
        ]

        let notMatch = (filter: string, value: string) => {
            if (filter == "") return false;
            return value.indexOf(filter) == -1
        }
        function kanaToHira(str: string) {
            if (!str) return ""
            return str.replace(/[\u30a1-\u30f6]/g, function (match) {
                var chr = match.charCodeAt(0) - 0x60;
                return String.fromCharCode(chr);
            });
        }
        let notNameMatch = (filter: string, value: string, kana: string) => {
            if (value) {
                return notMatch(filter, value) &&
                    notMatch(kanaToHira(filter), kanaToHira(kana));
            }
            return filter == "" ? false : true;
        }
        let notTantoMatch = (filter: string, pat: Model.Patient.Patient) => {
            if (filter == "") return false
            return (!pat.main_user || notMatch(filter, pat.main_user.name))
                && (!pat.sub_user1 || notMatch(filter, pat.sub_user1.name))
                && (!pat.sub_user2 || notMatch(filter, pat.sub_user2.name))
        }

        let getSortValue = (value: Model.Document.DocumentPair, key: string) => {
            if (key == "main_user") return value.patient.main_user ? value.patient.main_user.name : ""
            if (key == "create_date") return value.doc && value.doc.content.values ? value.doc.content.values.create_date : ""
            return value.patient.name_kana
        }

        let sortMark = (key: string) => {
            if (key == this.state.sortKey) {
                return this.state.sortDir > 0 ? "▲" : "▼"
            }
            return ""
        }

        let pairs = documentList.pairs.filter(p => {
            const x = p.patient
            let isOk = true;
            if (notNameMatch(query.name, x.name, x.name_kana) && notTantoMatch(query.name, x)) isOk = false;
            const isMain = (x.main_user != null && this.state.me.id == x.main_user.id)
            const isSub1 = (x.sub_user1 != null && this.state.me.id == x.sub_user1.id)
            const isSub2 = (x.sub_user2 != null && this.state.me.id == x.sub_user2.id)
            if (query.onlyMe && !isMain && !isSub1 && !isSub2) isOk = false;
            return isOk;
        }).sort((a, b) => {
            const key = this.state.sortKey
            const dir = this.state.sortDir
            const namediff = (a['name_kana'] > b['name_kana'] ? 1 : a['name_kana'] < b['name_kana'] ? -1 : 0)
            const av = getSortValue(a, key)
            const bv = getSortValue(b, key)
            if (av == bv) return namediff
            if (av > bv) return dir
            return -dir
        })

        let pairsFiltered: Model.Document.DocumentPair[] = []
        let offLim = Math.min(pairs.length, this.state.pageOffset + 50)
        for (let i = this.state.pageOffset; i < offLim; ++i) {
            pairsFiltered.push(pairs[i])
        }
        const paging = <Pagination
            limit={50}
            offset={(this.state.pageOffset)}
            total={pairs.length}
            onClick={(e, offset) => {
                this.setState({ pageOffset: offset })
            }}
        />

        return <div className={classes.root}>
            <div style={{ display: "block", width: "100%" }}>
                <StandardTable title=''>
                    <Grid container={true} spacing={0}>
                        <Grid item={true} xs={12} md={12} lg={12}>
                            <Col2Table body={element_l} />
                        </Grid>
                    </Grid>
                </StandardTable>
            </div>
            <div>
                <div className={classes.listRoot}>
                    <div style={{ textAlign: "center" }}>
                        {paging}
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell style={{ textAlign: "center", cursor: "pointer" }} onClick={(e) => this.setSortKey("name_kana")}> 利用者{sortMark("name")}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>作成者</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>担当者</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>副担当者</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>一時保存</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center", cursor: "pointer" }} onClick={(e) => this.setSortKey("create_date")}>最新作成日{sortMark("create_date")}</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>更新日</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>作成</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>編集</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>PDF</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.loading ?
                                        <Loading /> :
                                        pairsFiltered.map((c, idx) => {
                                            const clickCreate = (e) => {
                                                this.setState({
                                                    openDialog: true,
                                                    selectedPatientId: c.patient.id,
                                                    selectedDocumentId: null
                                                })
                                            }
                                            const clickView = (e) => {
                                                this.setState({
                                                    openDialog: true,
                                                    selectedPatientId: c.patient.id,
                                                    selectedDocumentId: c.doc ? c.doc.id : null
                                                })
                                            }
                                            return <TableRow key={"keikakusho_" + idx} className={classes.row}>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">{c.patient.name}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">{c.doc && c.doc.created_by ? c.doc.created_by.name : ""}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">{c.patient.main_user ? c.patient.main_user.name : ""}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">{c.patient.sub_user1 ? c.patient.sub_user1.name : ""}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">{c.doc && c.doc.is_temp ? "●" : ""}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center", cursor: "pointer" }} component="th" scope="row">{c.doc && c.doc.content.values ? DateUtil.toSlashDateStringShort(c.doc.content.values.create_date) : ""}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">{c.doc && c.doc.updated_at ? DateUtil.toSlashDateStringShort(c.doc.updated_at.toString()) : ""}</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">
                                                    {
                                                        <Button variant="outlined" size="small"
                                                            onClick={(e) => {
                                                                clickCreate(c.doc)
                                                            }}>新規作成</Button>
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">
                                                    {
                                                        c.doc ?
                                                            <Button variant="outlined" size="small"
                                                                onClick={(e) => {
                                                                    clickView(c.doc)
                                                                }}>編集</Button>
                                                            : null
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }} component="th" scope="row">
                                                    {c.doc && !c.doc.is_temp && c.doc.pdf_url ?
                                                        c.doc.pdf_url == "-" ?
                                                            <Button variant="outlined" size="small"
                                                                onClick={(e) => {
                                                                    this.downloadPdf(c.doc)
                                                                }}>最新DL</Button>
                                                            : <a href={c.doc.pdf_url} target="_blank">開く</a>
                                                        : ""
                                                    }
                                                </CustomTableCell>
                                            </TableRow>
                                        })
                                }
                            </TableBody>
                        </Table>
                        {paging}
                        {
                            this.state.openDialog ?
                                <DocumentDialog key={`dialog_${this.state.selectedPatientId}`}
                                    open={true}
                                    patientId={this.state.selectedPatientId}
                                    targetMonth={null}
                                    only={this.props.typeId}
                                    srcId={this.state.selectedDocumentId}
                                    onCansel={(deleted) => {
                                        this.setState({ openDialog: false, selectedPatientId: undefined })
                                        if (deleted) this.reload()
                                    }}
                                    onSelect={(doc: Model.Document.Document) => {
                                        this.setState({ openDialog: false, selectedPatientId: undefined })
                                        this.reload()
                                        this.props.onSelect(doc)
                                    }}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(NewestDocumentList))