export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    
    public desingr_sum: string = ""
    public genzai_other: string = ""
    public hassei_date: string = ""
    public kako_other: string = ""
    public keikaku_bed: string = ""
    public keikaku_eiyo: string = ""
    public keikaku_isu: string = ""
    public keikaku_riha: string = ""
    public keikaku_skin: string = ""
    public pat_birthday: string = ""
    public user_name: string = ""
}

export class Checks {
    public c1_bed_ari: boolean = true
    public c1_bed_nashi: boolean = false
    public c1_isu_ari: boolean = true
    public c1_isu_nashi: boolean = false
    public c2_ari: boolean = false
    public c2_nashi: boolean = true
    public c3_ari: boolean = false
    public c3_nashi: boolean = true
    public c4_ari: boolean = false
    public c4_nashi: boolean = true
    public c5_ari: boolean = false
    public c5_nashi: boolean = true
    public c6_ari: boolean = false
    public c6_nashi: boolean = true
    public check_female: boolean = false
    public check_male: boolean = false
    public d0: boolean = true
    public d1: boolean = false
    public d2: boolean = false
    public d3: boolean = false
    public d4: boolean = false
    public d5: boolean = false
    public du: boolean = false
    public e0: boolean = true
    public e1: boolean = false
    public e3: boolean = false
    public e6: boolean = false
    public g0: boolean = true
    public g1: boolean = false
    public g3: boolean = false
    public g4: boolean = false
    public g5: boolean = false
    public g6: boolean = false
    public genzai_1: boolean = false
    public genzai_2: boolean = false
    public genzai_3: boolean = false
    public genzai_4: boolean = false
    public genzai_5: boolean = false
    public genzai_6: boolean = false
    public genzai_7: boolean = false
    public genzai_ari: boolean = false
    public genzai_nashi: boolean = false
    public i0: boolean = true
    public i1: boolean = false
    public i3: boolean = false
    public i9: boolean = false
    public jiritsu_a1: boolean = false
    public jiritsu_a2: boolean = false
    public jiritsu_b1: boolean = false
    public jiritsu_b2: boolean = false
    public jiritsu_c1: boolean = false
    public jiritsu_c2: boolean = false
    public jiritsu_j1: boolean = true
    public jiritsu_j2: boolean = false
    public kako_1: boolean = false
    public kako_2: boolean = false
    public kako_3: boolean = false
    public kako_4: boolean = false
    public kako_5: boolean = false
    public kako_6: boolean = false
    public kako_7: boolean = false
    public kako_ari: boolean = false
    public kako_nashi: boolean = true
    public n0: boolean = true
    public n3: boolean = false
    public n6: boolean = false
    public r0: boolean = true
    public r12: boolean = false
    public r24: boolean = false
    public r6: boolean = false
    public r9: boolean = false
    public s0: boolean = true
    public s12: boolean = false
    public s15: boolean = false
    public s3: boolean = false
    public s6: boolean = false
    public s8: boolean = false
    public s9: boolean = false
}