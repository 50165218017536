import { observable, computed, action } from 'mobx';
import axios from 'axios';
import { ApiUrlRaw } from '../config/api'

export default class CountStore {
    @observable public num = 0;
    @observable public tests: Test[] = [];
    @observable public commitResult: string = "";
    @observable public file: TestFile = new TestFile();

    @computed public get getDoubleCount() {
        return this.num * 2;
    }

    @action.bound public onIncrement() {
        this.num = this.num + 1;
    }

    @action.bound public onDecrement() {
        this.num = this.num - 1;
    }

    @action.bound public loadTests() {
        axios.get(ApiUrlRaw('tests'))
            .then((response) => {
                console.log(response)
                this.tests = response.data
                this.num = this.num + 1;
            })
            .catch((error) => console.log(error))
    }

    @action.bound
    public commitTests() {
        const promises:Array<Promise<any>> = [];
        this.tests.forEach(test => {
            promises.push(axios.put(ApiUrlRaw(`tests/${test.id}`), test)
                .then((response) => {
                    console.log(response)
                    this.commitResult = "ok";
                })
                .catch((error) => {
                    console.log(error)
                    this.commitResult = "ng";
                }))
        })
        axios.all(promises).then((results) => {
            this.loadTests()
        });
    }

    public onChangeFile(images:FileList) {
        this.file.images = []
        for (let i=0; i < images.length; ++i) {
            const f = images.item(i) 
            if (f) {
                this.file.images.push(f)
            }
        }
    }

    public uploadImage() {
        const fd = new FormData()
        this.file.content = "hello"
        fd.append('test_file[content]', this.file.content)
        /*
        this.file.images.forEach((f) => {
            fd.append('test_file[images]', f)
        })
        */

        fd.append('test_file[images]', this.file.images[0])
        axios.post(ApiUrlRaw(`test_files`), fd)
            .then((response) => {
                console.log(response)
                this.commitResult = "ok";
            })
            .catch((error) => {
                console.log(error)
                this.commitResult = "ng";
            })
    }
}

class Test {
    @observable public id: number = 0;
    @observable public content1: string = "";
    @observable public content2: string = "";
    @observable public content3: string = "";
}
class TestFile {
    @observable public id: number = 1;
    @observable public content: string = "";
    @observable public images: File[] = [];
}