import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import { Button } from '@material-ui/core';
import * as HokanApi from '../../api/hokan'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"
    painPosImage = new Image();
    painPosImageRef = React.createRef<HTMLCanvasElement>();
    painPosImageDrawed = false

    public getId(): number {
        return 26
    }

    public createContent(): any {
        const res = new FormModel.Pain.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        doc.patient = pat;
        const c = doc.content as FormModel.Pain.Core
        HokanApi.me().then(res => {
            c.values.create_by = res.data.name
            sa.updateState();
        }).catch((e) => { console.log(e) })
    }

    private toBinary = (canvas) => {
        let base64 = canvas.toDataURL('image/png'),
            bin = atob(base64.replace(/^.*,/, '')),
            buffer = new Uint8Array(bin.length);
        for (var i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
        }
        return buffer;
    }
    public beforePost_pdf(doc: Model.Document.Document) {
        if (this.painPosImageRef.current) {
            let blob = new Blob([this.toBinary(this.painPosImageRef.current)], { type: 'image/png' })
            doc.images = [new File([blob], "pain_image.png")]
        }
    }

    public beforeMount = (doc: Model.Document.Document) => {
        this.painPosImage.src = '/images/pain_pos.png'
    }

    public addCircle = (px: number, py: number, doc: Model.Document.Document) => {
        const content = doc.content as FormModel.Pain.Core
        content.positions.push({ px: px, py: py })
        this.draw(doc)
    }

    public clear = (doc: Model.Document.Document) => {
        const content = doc.content as FormModel.Pain.Core
        content.positions = []
        this.draw(doc)
    }
    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>・痛みの評価シート：日本緩和医療学会：がん疼痛の薬物療法に関するガイドライン（2014年版）．痛みの評価シート．p36，金原出版，2014</div>
        </div>
    }

    private draw = (doc: Model.Document.Document) => {
        const content = doc.content as FormModel.Pain.Core
        if (!this.painPosImageRef.current) return
        const ctx = this.painPosImageRef.current.getContext('2d');
        if (!ctx) return
        ctx.drawImage(this.painPosImage, 0, 0, 330, 244, 0, 0, 330, 244)
        ctx.lineWidth = 3;
        content.positions.forEach(pos => {
            ctx.beginPath();
            ctx.arc(pos.px, pos.py, 12, 0, Math.PI * 2, true);
            ctx.stroke()
        })
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const saC = sa.generateChild(["content", "checks"])
        const check10 = (title, name) => {
            return <div style={{ margin: "5px" }}>
                {title}
                {saC.checkList(
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(x => {
                        return { label: `${x}`, key: `${name}_${x}` }
                    })
                    , false, true)}
            </div>
        }

        const elements = [
            {
                name: "基本", content: [{
                    name: "作成者", content: saV.text("create_by")
                }]
            },
            {
                name: "日常生活への影響", content: [{
                    name: "", content: saC.checkList([
                        { label: "0：症状なし", key: "check_nichijo_0" },
                        { label: "1：現在の治療に満足している", key: "check_nichijo_1" },
                        { label: "2：時に悪い日もあり日常生活に支障を来す", key: "check_nichijo_2" },
                        { label: "3：しばしばひどい痛みがあり日常生活に著しく支障を来す", key: "check_nichijo_3" },
                        { label: "4：ひどい痛みが常にある", key: "check_nichijo_4" },
                    ], true, true)
                }]
            },
            {
                name: "痛みのパターン", content: [{
                    name: "", content: <div>
                        <div>
                            <img src="/images/pain_pattern.png" />
                        </div>
                        {saC.checkList([
                            { label: "1：ほとんど痛みがない", key: "check_itami_1" },
                            { label: "2：普段はほとんど痛みがないが、1日に何回か強い痛みがある", key: "check_itami_2" },
                            { label: "3：普段から強い痛みがあり、1日の間に強くなったり弱くなったりする", key: "check_itami_3" },
                            { label: "4：強い痛みが1日中続く", key: "check_itami_4" },
                        ], true, true)}
                        {
                            saC.getValue("check_itami_2") ?
                                <div>1日に何回：{saV.text("itami_times", "回")}</div>
                                : null
                        }
                        <br />
                    </div>
                }]
            },
            {
                name: "痛みの強さ", content: [{
                    name: "", content: <div>
                        <div style={{ margin: "5px", fontWeight: "bold" }}>0:全くなかった 〜 10:これ以上考えられないほどひどかった</div>
                        {check10("一番強い時：", "check_itami_a")}
                        {check10("一番弱い時：", "check_itami_b")}
                        {check10("１日の平均：", "check_itami_c")}
                    </div>
                }]
            },
            {
                name: "痛みの部位", content: [{
                    name: "", content: <div>
                        <div>
                            <canvas
                                ref={this.painPosImageRef}
                                width="330px"
                                height="244px"
                                onMouseDown={e => this.addCircle(e.nativeEvent.offsetX, e.nativeEvent.offsetY, doc)}
                                onTouchStart={e => { if (e.touches.length > 0) this.addCircle(e.touches[0].clientX, e.touches[0].clientY, doc) }}
                                style={{ border: "solid 1px" }}
                            />
                        </div>
                        <Button style={{
                            width: "80px",
                            height: "40px",
                            margin: "10px"
                        }} variant="contained" color="default" onClick={(e) => this.clear(doc)}>
                            クリア
                        </Button>
                    </div>
                }]
            },
            {
                name: "痛みの性状", content: [{
                    name: "", content: saC.checkList([
                        { label: "鈍い", key: "check_seijo_1" },
                        { label: "重苦しい", key: "check_seijo_2" },
                        { label: "鋭い", key: "check_seijo_3" },
                        { label: "うずくような", key: "check_seijo_4" },
                        { label: "灼けるような", key: "check_seijo_5" },
                        { label: "ビーンと走るような", key: "check_seijo_6" },
                        { label: "刺されたような or 刺すような", key: "check_seijo_7" },
                    ], false, false)
                }]
            },
            {
                name: "増悪因子", content: [{
                    name: "", content: <div>
                        {
                            saC.checkList([
                                { label: "夜間", key: "check_zouaku_1" },
                                { label: "体動", key: "check_zouaku_2" },
                                { label: "食事(前・後)", key: "check_zouaku_3" },
                                { label: "排尿・排便", key: "check_zouaku_4" },
                                { label: "不安・抑うつ", key: "check_zouaku_5" },
                                { label: "その他", key: "check_zouaku_6" },
                            ], false, false)
                        }
                        {
                            saC.getValue("check_zouaku_6") ?
                                <div>その他内容：{saV.text("zouaku_other", "その他内容")}</div>
                                : null
                        }
                    </div>
                }]
            },
            {
                name: "軽快因子", content: [{
                    name: "", content: <div>
                        {
                            saC.checkList([
                                { label: "安静", key: "check_keikai_1" },
                                { label: "保温", key: "check_keikai_2" },
                                { label: "冷却", key: "check_keikai_3" },
                                { label: "マッサージ", key: "check_keikai_4" },
                                { label: "その他", key: "check_keikai_5" }
                            ], false, false)
                        }
                        {
                            saC.getValue("check_keikai_5") ?
                                <div>その他内容：{saV.text("keikai_other", "その他内容")}</div>
                                : null
                        }
                    </div>
                }]
            },
            {
                name: "治療の反応", content: [{
                    name: "定期薬剤", content: <div>
                        <div>
                            {
                                saC.check("check_chiryo_1", "あり")
                            }
                            {
                                saC.getValue("check_chiryo_1") ?
                                    <div>
                                        <div>オピオイド{saC.checkComment(saV, "check_chiryo_2", "opioid", "")}</div>
                                        <div>非オピオイド{saC.checkComment(saV, "check_chiryo_3", "hi_opioid", "")}</div>
                                        <div>鎮痛補助薬{saC.checkComment(saV, "check_chiryo_4", "chintsuu", "")}</div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div>
                            {
                                saC.getValue("check_chiryo_1") ? <div>
                                    <div>○副作用</div>
                                    <div>
                                        眠気：{saC.checkList([
                                            { label: "なし", key: "check_nemuke_1" },
                                            { label: "あり（不快ではない）", key: "check_nemuke_2" },
                                            { label: "あり（不快である）", key: "check_nemuke_3" },
                                        ], false, true)}
                                    </div>
                                    <div>
                                        見当識障害：{saC.checkList([
                                            { label: "なし", key: "check_kentoushiki_1" },
                                            { label: "あり", key: "check_kentoushiki_2" },
                                        ], false, true)}
                                    </div>
                                    <div>
                                        便秘：{saC.checkList([
                                            { label: "なし", key: "check_benpi_1" },
                                            { label: "あり", key: "check_benpi_2" },
                                        ], false, true)}
                                        {
                                            saC.getValue("check_benpi_2") ?
                                                saC.checkList([
                                                    { label: "硬", key: "check_benpi_2_a" },
                                                    { label: "普通", key: "check_benpi_2_b" },
                                                    { label: "軟", key: "check_benpi_2_c" }
                                                ], false, true)
                                                : null
                                        }
                                    </div>
                                    <div>
                                        嘔気：{saC.checkList([
                                            { label: "なし", key: "check_hakike_1" },
                                            { label: "あり（経口摂取可能）", key: "check_hakike_2" },
                                            { label: "あり（経口摂取不可能）", key: "check_hakike_3" },
                                        ], false, true)}
                                    </div>
                                </div>
                                    : null
                            }
                        </div>
                    </div>
                },
                {
                    name: "レスキュー・ドーズ", content: <div>
                        <div>
                            {
                                saC.check("check_dose", "あり")
                            }
                            {
                                saC.getValue("check_dose") ?
                                    <div>
                                        使用薬剤と量：{saV.text("shiyou_yakuzai")}
                                    </div>
                                    : null
                            }
                        </div>
                        <div>
                            {
                                saC.getValue("check_dose") ? <div>
                                    <div style={{ margin: "5px" }}>
                                        <div>
                                            使用回数(回/日)：{saV.text("shiyou_kaisuu")}
                                            使用前NRS：{saV.text("before_nrs")}
                                            使用後NRS：{saV.text("after_nrs")}
                                        </div>
                                        <div>
                                            効果：{saC.checkList([
                                                { label: "完全に良くなった", key: "check_kouka_1" },
                                                { label: "だいたい良くなった", key: "check_kouka_2" },
                                                { label: "少しよくなった", key: "check_kouka_3" },
                                                { label: "変わらない", key: "check_kouka_4" }
                                            ], false, true)}
                                        </div>
                                    </div>
                                    <div style={{ margin: "5px" }}>
                                        <p><b>副作用</b></p>
                                        <div>
                                            眠気：{saC.checkList([
                                                { label: "なし", key: "check_dose_nemuke_1" },
                                                { label: "あり（不快ではない）", key: "check_dose_nemuke_2" },
                                                { label: "あり（不快である）", key: "check_dose_nemuke_3" },
                                            ], false, true)}
                                        </div>
                                        <div>
                                            嘔気：{saC.checkList([
                                                { label: "なし", key: "check_dose_hakike_1" },
                                                { label: "あり（経口摂取可能）", key: "check_dose_hakike_2" },
                                                { label: "あり（経口摂取不可能）", key: "check_dose_hakike_3" },
                                            ], false, true)}
                                        </div>
                                    </div>
                                </div>
                                    : null
                            }
                        </div>
                    </div>
                }]
            }
        ]

        if (!this.painPosImageDrawed) {
            this.painPosImageDrawed = false
            this.draw(doc)
        }

        return elements
    }
}
