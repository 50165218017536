import * as React from 'react';
import * as Model from '../models'
import * as Styles from '@material-ui/core/styles';
import { StoreAccessor } from '../util/StoreUtil';
import { Grid, Button, Checkbox, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Tooltip, TextField } from '@material-ui/core';
import Col2Table from './Col2Table';
import KirokuPartialSelectDialog from './KirokuPartialSelectDialog'
import * as KirokuIIView3 from './KirokuIIViews3'
import * as Icons from '@material-ui/icons';
import EditableCheckList from './EditableCheckList';
import DateUtil from '../util/DateUtil'
import * as FormModel from '../models/Forms'
import * as HokanApi from '../api/hokan'
import DocumentDialog from './DocumentDialog';
import EditableList from './EditableList';
import * as Colors from './Colors';

//import EditableList from './EditableList';
interface LocalState {
    kiroku: Model.KirokuII.KangoKirokuII,
    latestKiroku: Model.KirokuII.KangoKirokuII | null,
    latestKirokuList: Model.KirokuII.KangoKirokuII[],
    loading: boolean,
    isEditing: boolean,
    cache: any,
    assessments: Model.Assessment.AssessmentListResult[],
    filterChecked: { name: string, checked: boolean }[],
    dialogOpenFlag: boolean,
    patient: Model.Patient.Patient,
    openDelete: boolean,
    openPastCopy: string,
    serviceCodeFilter: string,
    problemList: Model.Problem[],
    posting: boolean,
    postError: string | null,
    serverSideKasan: Model.KirokuII.ServerSideKasan
}
interface Props {
    kiroku: Model.KirokuII.KangoKirokuII
    sa: StoreAccessor
    saPast: StoreAccessor
    classes: any
    isEditing: boolean
    state: LocalState
    patient_id: number
    title: string
    category: string
    omahaIDs: number[]

    onCopyClick: (name: string) => void
    onCopySelect: (res: Model.KirokuII.KangoKirokuII) => void
    panelOnChange: (e: any, f: boolean, c?: { name: string, checked: boolean }) => void
    onLoadServerSideKasan: () => void
}

interface HashState {
    hash: string,
    docTemp: any
}

const list01234 = [
    { key: '0', value: 0 },
    { key: '1', value: 1 },
    { key: '2', value: 2 },
    { key: '3', value: 3 },
    { key: '4', value: 4 },
]

const nrsTooltip = (classes) => <div className={classes.tooltipDiv}>
    <div className={classes.tooltipTitle}>NRS (Numerical Rating Scale)</div>
    <div className={classes.tooltipTitle}>０：なし</div>
    <div className={classes.tooltipLine}>１〜３：軽い</div>
    <div className={classes.tooltipLine}>４〜６：中等度</div>
    <div className={classes.tooltipLine}>７〜１０：強い</div>
</div>

class CatVal {
    public name: any
    public cat: any[]
    public value: any
    constructor(name: any, cat: any[] = []) {
        this.name = name
        this.cat = cat
        this.value = {}
    }

    public isEmpty(sa: StoreAccessor) {
        return sa.isEmptyValue(this.name, this.cat)
    }
}

abstract class EditViewBase extends React.Component<Props, HashState>  {
    displayName = "EditViewBase"
    constructor(props) {
        super(props)
        this.state = {
            hash: "",
            docTemp: {}
        }
    }
    public static defaultProps = {
        title: "title",
        category: "cat",
        omahaIDs: []
    }

    public getHash(props: Props) { return "" }

    public shouldComponentUpdate(prop: Props, state: HashState) {
        if (prop.state.filterChecked[this.getTitle()] == false) {
            return false
        }
        return true
    }

    public get isKaigoHoken() {
        return Model.KirokuII.KangoKirokuII.isKaigoHoken(this.props.kiroku)
    }

    public get isIryoHoken() {
        return Model.KirokuII.KangoKirokuII.isIryoHoken(this.props.kiroku)
    }
    public get isSeishinHoken() {
        return Model.KirokuII.KangoKirokuII.isSeishinHoken(this.props.kiroku)
    }
    public get isJihi() {
        return Model.KirokuII.KangoKirokuII.isJihi(this.props.kiroku)
    }

    public pastNumberC = (cat: any, name: any, step: number = 1) => {
        const { sa, saPast } = this.props
        return <div><div style={{ display: "inline-flex" }}>
            {sa.numberC(cat, name, "", step)}
        </div>
            <div style={{ display: "inline-flex" }}>
                前回:{saPast.hasValue(cat, name) ? saPast.numberC(cat, name, "", step) : "-"}
            </div>
        </div>
    }
    public pastTextC = (cat: any, name: any) => {
        const { sa, saPast } = this.props
        return <div>
            <div style={{ display: "inline-flex" }}>
                {sa.textC(cat, name)}
            </div>
            <div style={{ display: "inline-flex" }}>
                前回:{saPast.hasValue(cat, name) ? saPast.textC(cat, name) : "-"}
            </div>
        </div>
    }

    public taiju_elem = () => {
        const { sa, saPast } = this.props
        return {
            name: "体重", content: <div>
                <div style={{ display: "inline-flex" }}>{
                    sa.numberUnitC(["vital"], "taiju", "taiju_unit", [{ name: "kg", factor: 1 }, { name: "g", factor: 1000 }])
                }</div><div style={{ display: "inline-flex" }}>前回:{
                    saPast.hasValue(["vital"], "taiju") && saPast.hasValue(["vital"], "taiju_unit") ?
                        saPast.numberUnitC(["vital"], "taiju", "taiju_unit", [{ name: "kg", factor: 1 }, { name: "g", factor: 1000 }])
                        : "-"
                }</div>
            </div>
        }
    }
    public shincho_elem = () => {
        const { sa, saPast } = this.props
        return {
            name: "身長(cm)", content: <div>
                <div style={{ display: "inline-flex" }}>
                    {
                        sa.numberC(["vital"], "shincho")
                    }
                </div>
                <div style={{ display: "inline-flex" }}>前回:{
                    saPast.hasValue(["vital"], "shincho") ?
                        saPast.numberC(["vital"], "shincho")
                        : "-"
                }</div>
            </div>
        }
    }

    public abstract getContents(): { name: any, content: any, values?: CatVal[], closeDefault?: boolean }[]
    public abstract getTitle(): string
    public getShowTitle(): string {
        return this.getTitle();
    }
    public abstract omahaIDs(): number[]

    public render() {
        const { classes, state } = this.props
        const title = this.getTitle()
        const c = state.filterChecked.find(x => x.name == title)
        const checked = (c != undefined && c.checked == true)
        const dialog = state.openPastCopy == title ?
            <KirokuPartialSelectDialog
                kirokus={state.latestKirokuList}
                onSelect={(res) => this.props.onCopySelect(res)}
                targetComponent={(kiroku) => {
                    return KirokuIIView3.getComponentByName(title, kiroku, state.problemList)
                }}
                onCancel={() => { this.props.onCopyClick("") }}
            />
            : null

        const containsOmaha = (state.assessments.findIndex(ass => ass.newest.assessment.closed == false && this.omahaIDs().findIndex(id => id == ass.problem.id) >= 0) >= 0)
        const expand = (!c || checked || containsOmaha)
        if (containsOmaha) {
            if (c) c.checked = true
        }
        const contents = this.getContents()
        contents.forEach(c => {
            if (!c.values) return;
            if (!c.values.find(cv => cv.isEmpty(this.props.sa) == false)) {
                c.closeDefault = true;
            }
        })
        return [<ExpansionPanel
            key={title}
            expanded={expand}
            onChange={(e, f) => this.props.panelOnChange(e, f, c)}
        >
            <ExpansionPanelSummary
                className={(!c || c.checked) ? classes.panelOpen : classes.panelClose}
                classes={{
                    content: classes.panelContent,
                    expandIcon: classes.panelIcon
                } as any}
                style={!c ?
                    { backgroundColor: Colors.primaryColor, color: Colors.white }
                    : {}
                }
                expandIcon={c ? <Icons.ExpandMore style={{ color: "white" }} /> : null}>
                <div>
                    <span style={{ marginLeft: "10px" }}>● {this.getShowTitle()}</span></div>
            </ExpansionPanelSummary>
            {
                expand ?
                    <ExpansionPanelDetails
                        style={{
                            display: "block",
                            padding: "0px",
                            paddingTop: "0px"
                        }} >
                        <Col2Table shrink={true} body={contents} omahaMode={this.getTitle() === "オマハシステム" ? true : false} />
                        <div>
                            {/*
                                this.omahaIDs().map(id => {
                                    return <div>{id}</div>
                                })*/
                            }
                            {
                                Omaha.getContents(this.props.sa, this.props.isEditing, this.props.kiroku, this.props.state, this.omahaIDs())
                            }
                        </div>
                    </ExpansionPanelDetails>
                    : null
            }
        </ExpansionPanel>
            ,
            dialog
        ]
    }
}


export class Basic extends EditViewBase {
    displayName = "Basic"
    public static getTitle(): string { return "基本情報" }
    public getTitle(): string { return Basic.getTitle() }
    public omahaIDs() { return [] as number[] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const is_teiki = kiroku.basic && kiroku.basic.is_teiki
        const pat = this.props.state.patient
        const ht = (pat && pat.hoken) ? pat.hoken.hoken_type : null

        const seishinHukusuuList = [
            { key: "none", label: "複数名加算なし" },
            { key: "is_hukusuu_kangoshi_1", label: "看護師等・１回" },
            { key: "is_hukusuu_kangoshi_2", label: "看護師等・２回" },
            { key: "is_hukusuu_kangoshi_3", label: "看護師等・３回以上" },
            { key: "is_hukusuu_jun_1", label: "准看護師・１回" },
            { key: "is_hukusuu_jun_2", label: "准看護師・２回" },
            { key: "is_hukusuu_jun_3", label: "准看護師・３回以上" },
            { key: "is_hukusuu_hojo", label: "看護補助者" },
            { key: "is_hukusuu_ptot_1", label: "作業療法士・１回" },
            { key: "is_hukusuu_ptot_2", label: "作業療法士・２回" },
            { key: "is_hukusuu_ptot_3", label: "作業療法士・３回以上" },
            { key: "is_seishin_hukushi", label: "精神保健福祉士" }
        ]
        let seishinHukusuuCurrent = "none"
        seishinHukusuuList.forEach(s => {
            if (s.key != "none" && kiroku.basic.kasan.seishin[s.key]) {
                seishinHukusuuCurrent = s.key;
            }
        })

        const basicHukusuuList = [
            { key: "none", label: "複数名加算なし" },
            { key: "is_hukusuu_kangoshi", label: "看護師等" },
            { key: "is_hukusuu_jun", label: "准看護師" },
            { key: "is_hukusuu_hojo_ha", label: "護補助者・ハ" },
            { key: "is_hukusuu_hojo_ni_1", label: "看護補助者・ニ・１回" },
            { key: "is_hukusuu_hojo_ni_2", label: "看護補助者・ニ・２回" },
            { key: "is_hukusuu_hojo_ni_3", label: "看護補助者・ニ・３回以上" },
            { key: "is_hukusuu_ptot", label: "理学療法士等" },
        ]
        let basicHukusuuCurrent = "none"
        basicHukusuuList.forEach(s => {
            if (s.key != "none" && kiroku.basic.kasan.basic[s.key]) {
                basicHukusuuCurrent = s.key;
            }
        })


        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                {
                    name: "記録の種類", content: <div>
                        {sa.list("kiroku_type", Model.KirokuII.KirokuType)}
                        {
                            this.isJihi ?
                                sa.textC(["basic"], "jihi_comment", "備考")
                                : null
                        }
                    </div>

                },
                { name: "開始時間", content: sa.dateTime("time_start", "time_end") },
                { name: "終了時間", content: sa.dateTime("time_end") },
                {
                    name: "　", content: <span>
                        <div style={{ display: "inline-block" }}>
                            <Checkbox
                                color="primary"
                                checked={sa.getValueC("is_kinkyu", ["basic"])}
                                onChange={(n, v) => {
                                    sa.setValueC("is_kinkyu", v, ["basic"])
                                    sa.setValueC("is_kinkyu", v, ["basic", "kasan", "seishin"])
                                    sa.setValueC("is_kinkyu", v, ["basic", "kasan", "basic"])
                                }}
                            />
                            緊急訪問
                        </div>
                        {
                            this.isIryoHoken ?
                                <div key="chojikan_kasan" style={{ display: "inline-block" }}>
                                    <Checkbox
                                        color="primary"
                                        checked={sa.getValueC("is_choujikan", ["basic", "kasan", "seishin"]) || sa.getValueC("is_choujikan", ["basic", "kasan", "basic"])}
                                        onChange={(n, v) => {
                                            sa.setValueC("is_choujikan", v, ["basic", "kasan", "seishin"])
                                            sa.setValueC("is_choujikan", v, ["basic", "kasan", "basic"])
                                        }}
                                    />
                                    長時間加算
                                </div>
                                : null
                        }
                    </span>
                },
                {
                    name: "訪問スタッフ", content: sa.user("main_user")
                },
                {
                    name: "資格", content: sa.listRawC(["basic"], "shikaku", Model.KirokuII.ShikakuList)
                },
                {
                    name: "複数名", content: <div className={classes.innerTable} >
                        <Col2Table disableBGColor={true} body={
                            kiroku.basic.doukou_list.map((doukou, idx) => {
                                return {
                                    name: "名前", content: <div>
                                        {sa.userC(["basic", "doukou_list", idx], "user")}
                                        {sa.listC(["basic", "doukou_list", idx], "step", Model.KirokuII.DoukouStep, "inline-flex")}
                                        {"　"}<Button variant="outlined" size="small" color="secondary"
                                            onClick={(e) => { kiroku.basic.doukou_list = kiroku.basic.doukou_list.filter((x, i) => i != idx); sa.updateState() }}>
                                            ︎削除
                                        </Button>
                                    </div>
                                }
                            })
                        } />
                        <Button variant="outlined" size="small" color="default"
                            onClick={(e) => {
                                kiroku.basic.doukou_list.push(new Model.KirokuII.DoukouUser())
                                sa.updateState()
                            }}>
                            ＋追加
                        </Button>
                        {
                            kiroku.basic.doukou_list.length > 0 ?
                                this.isIryoHoken ?
                                    <div style={{ margin: "10px" }}>
                                        複数名加算：
                                        {
                                            this.isSeishinHoken ?
                                                <EditableList
                                                    label=""
                                                    editMode={true}
                                                    name={"seishin"}
                                                    display="inline-flex"
                                                    list={seishinHukusuuList.map(t => { return { key: t.label, value: t.key } })}
                                                    value={seishinHukusuuCurrent}
                                                    setData={
                                                        (n, v) => {
                                                            seishinHukusuuList.forEach(s => {
                                                                if (s.key != "none") {
                                                                    kiroku.basic.kasan.seishin[s.key] = (v == s.key)
                                                                }
                                                            })
                                                            sa.updateState();
                                                        }}
                                                />
                                                :
                                                <EditableList
                                                    label=""
                                                    editMode={true}
                                                    name={"basic"}
                                                    display="inline-flex"
                                                    list={basicHukusuuList.map(t => { return { key: t.label, value: t.key } })}
                                                    value={basicHukusuuCurrent}
                                                    setData={
                                                        (n, v) => {
                                                            basicHukusuuList.forEach(s => {
                                                                if (s.key != "none") {
                                                                    kiroku.basic.kasan.basic[s.key] = (v == s.key)
                                                                }
                                                            })
                                                            sa.updateState();
                                                        }}
                                                />
                                        }
                                    </div>
                                    : null
                                : null
                        }
                    </div>
                },
                {
                    name: "コードと加算の推定", content: <Button
                        variant="outlined" size="small" color="default"
                        disabled={this.isIryoHoken == false && this.isKaigoHoken == false}
                        onClick={(e) => {
                            this.props.onLoadServerSideKasan()
                        }}>
                        実行
                    </Button>
                },
                {
                    name: "算定コード", content: this.isKaigoHoken ?
                        sa.serviceCodeC([], "service_code",
                            !is_teiki && ht == Model.Patient.HokenType.Kaigo,
                            !is_teiki && ht == Model.Patient.HokenType.KaigoYobo,
                            false,
                            is_teiki
                        ) :
                        this.isIryoHoken ?
                            <div>
                                {sa.listC(["basic"], "iryo_code", Model.KirokuII.IryoCodeEnum)}
                                {
                                    sa.serviceCodeC([], "service_code",
                                        false, false,
                                        true,
                                        false
                                    )
                                }
                            </div>
                            : <div>-</div>
                },
                { name: "リハ回数", content: sa.numberC(["basic"], "riha_count") },
                {
                    name: "加算",
                    closeDefault: !Model.KirokuII.Kasan.checkedSomeKasan(kiroku.basic.kasan),
                    content: <Col2Table shrink={true} body={[{
                        closeDefault: !Model.KirokuII.Kasan.checkedSomeKasan(kiroku.basic.kasan),
                        name: "加算", content:
                            <Grid container={true} spacing={0}>
                                {
                                    this.isKaigoHoken ?
                                        <Grid key="glx" item={true} xs={12} md={12} lg={12}>
                                            {sa.checkListC(["basic", "kasan"], [
                                                { key: "is_shokai", label: "初回加算" },
                                                { key: "is_taiin", label: "退院時指導加算" },
                                                { key: "is_renkei", label: "連携強化加算" },
                                                { key: "is_terminal_care", label: "ターミナルケア加算" },
                                            ], true)}
                                        </Grid>
                                        : this.isIryoHoken ?
                                            [
                                                <Grid key="gl1" item={true} xs={12} md={6} lg={6}>
                                                    {sa.checkListC(["basic", "kasan", "common"], [
                                                        //{ key: "is_tokubetu_kanri_1", label: "特別管理加算１" },
                                                        //{ key: "is_tokubetu_kanri_2", label: "特別管理加算２" },
                                                        { key: "is_taiin_shidou", label: "退院時共同指導加算" },
                                                        //{ key: "is_24h", label: "２４時間対応体制加算" },
                                                        { key: "is_taiin_shien", label: "退院支援指導加算" },
                                                        { key: "is_zaitaku_renkei_shidou", label: "在宅患者連携指導加算" },
                                                        { key: "is_zaitaku_kinkyu_kanfa", label: "在宅患者緊急時等ｶﾝﾌｧﾚﾝｽ加算" },
                                                        { key: "is_tokubetu_kanri_shidou", label: "特別管理指導加算" },
                                                        //{ key: "is_seishin_juushou_shien_1", label: "精神科重症患者支援管理連携加算１" },
                                                        //{ key: "is_seishin_juushou_shien_2", label: "精神科重症患者支援管理連携加算２" },
                                                        { key: "is_renkei_kyouka", label: "看護・介護職員連携強化加算" },
                                                        //{ key: "is_jouhou_teikyo_1", label: "訪問看護情報提供療養費１" },
                                                        //{ key: "is_jouhou_teikyo_2", label: "訪問看護情報提供療養費２" },
                                                        //{ key: "is_jouhou_teikyo_3", label: "訪問看護情報提供療養費３" },
                                                        { key: "is_terminal_care_1", label: "訪問看護ターミナルケア療養費１" },
                                                        { key: "is_terminal_care_2", label: "訪問看護ターミナルケア療養費２" },
                                                    ], true)}
                                                </Grid>,
                                                <Grid key="glx" item={true} xs={12} md={6} lg={6}>
                                                    {sa.checkListC(["basic", "kasan"], [
                                                        { key: "is_kanwa_care", label: "緩和ケア" },
                                                        { key: "is_jokusou_care", label: "褥瘡ケア" },
                                                        { key: "is_smart_care", label: "ストーマケア" },
                                                    ], true)}
                                                </Grid>,
                                                this.isSeishinHoken ?
                                                    <Grid key="gl2" item={true} xs={12} md={6} lg={6}>
                                                        {sa.checkListC(["basic", "kasan", "seishin"], [
                                                            { key: "is_hukusuu_2", label: "精神科複数回訪問加算(２回目)" },
                                                            { key: "is_hukusuu_3", label: "精神科複数回訪問加算(３回目以降)" },
                                                            { key: "is_tokubetsu_chiiki", label: "特別地域訪問看護加算" },
                                                            { key: "is_kinkyu", label: "緊急訪問看護加算" },
                                                            /*
                                                            { key: "is_choujikan", label: "長時間訪問看護加算" },
                                                            { key: "is_hukusuu_kangoshi_1", label: "複数名訪問看護加算(看護師等・１回)" },
                                                            { key: "is_hukusuu_kangoshi_2", label: "複数名訪問看護加算(看護師等・２回)" },
                                                            { key: "is_hukusuu_kangoshi_3", label: "複数名訪問看護加算(看護師等・３回以上)" },
                                                            { key: "is_hukusuu_jun_1", label: "複数名訪問看護加算(准看護師・１回)" },
                                                            { key: "is_hukusuu_jun_2", label: "複数名訪問看護加算(准看護師・２回)" },
                                                            { key: "is_hukusuu_jun_3", label: "複数名訪問看護加算(准看護師・３回以上)" },
                                                            { key: "is_hukusuu_hojo", label: "複数名訪問看護加算(看護補助者)" },
                                                            { key: "is_hukusuu_ptot_1", label: "複数名訪問看護加算(作業療法士・１回)" },
                                                            { key: "is_hukusuu_ptot_2", label: "複数名訪問看護加算(作業療法士・２回)" },
                                                            { key: "is_hukusuu_ptot_3", label: "複数名訪問看護加算(作業療法士・３回以上)" },
                                                            { key: "is_seishin_hukushi", label: "複数名訪問看護加算(精神保健福祉士)" },
                                                            */
                                                            { key: "is_yakan", label: "夜間・早朝訪問看護加算" },
                                                            { key: "is_shinya", label: "深夜訪問看護加算" },
                                                        ], true)}
                                                    </Grid>
                                                    :
                                                    <Grid key="gl3" item={true} xs={12} md={6} lg={6}>
                                                        {sa.checkListC(["basic", "kasan", "basic"], [
                                                            { key: "is_hukusuu_2", label: "難病等複数回訪問加算(２回目)" },
                                                            { key: "is_hukusuu_3", label: "難病等複数回訪問加算(３回目以降)" },
                                                            { key: "is_tokubetsu_chiiki", label: "特別地域訪問看護加算" },
                                                            { key: "is_kinkyu", label: "緊急訪問看護加算" },
                                                            //{ key: "is_choujikan", label: "長時間訪問看護加算" },
                                                            { key: "is_nyuyouji", label: "乳幼児加算" },
                                                            /*
                                                            { key: "is_hukusuu_kangoshi", label: "複数名訪問看護加算(看護師等)" },
                                                            { key: "is_hukusuu_jun", label: "複数名訪問看護加算(准看護師)" },
                                                            { key: "is_hukusuu_hojo_ha", label: "複数名訪問看護加算(看護補助者・ハ)" },
                                                            { key: "is_hukusuu_hojo_ni_1", label: "複数名訪問看護加算(看護補助者・ニ・１回)" },
                                                            { key: "is_hukusuu_hojo_ni_2", label: "複数名訪問看護加算(看護補助者・ニ・２回)" },
                                                            { key: "is_hukusuu_hojo_ni_3", label: "複数名訪問看護加算(看護補助者・ニ・３回以上)" },
                                                            { key: "is_hukusuu_ptot", label: "複数名訪問看護加算(理学療法士等)" },
                                                            */
                                                            { key: "is_yakan", label: "夜間・早朝訪問看護加算" },
                                                            { key: "is_shinya", label: "深夜訪問看護加算" },
                                                        ], true)}
                                                    </Grid>
                                            ]
                                            : null
                                }
                            </Grid>
                    }
                    ]} />
                }
            ]} />
        }]
        return contents
    }
}


class Omaha {
    private static catToJp(cat: string) {
        if (cat == "TGC") return "TGC(教育/相談/指導)"
        if (cat == "CM") return "CM(ケアマネジメント)"
        if (cat == "TP") return "TP(直接ケア)"
        if (cat == "S") return "S(観察)"
        return ""
    }
    public static getContents(sa: StoreAccessor, isEditing: boolean, kiroku: Model.KirokuII.KangoKirokuII, st: LocalState, problemIDs: number[]): any {
        const assessments = st.assessments.filter(ass => {
            if (problemIDs.findIndex(id => id == ass.problem.id) == -1) return false;
            if (kiroku.omaha && kiroku.omaha.details && kiroku.omaha.details.findIndex(x => x.problem_id == ass.problem.id) >= 0) {
                // 既に記録されているものは出す
                return true;
            }
            return ass.newest.assessment.closed == false && ass.newest.assessment.priority == 1
        })
        return <div>
            {
                assessments.map((ass, assId) => {
                    const ns = ass.newest
                    const omaha = kiroku.omaha.details.find(x => x.problem_id == ass.problem.id)
                    const catFromInterv = {}
                    let isEmpty = true
                    ns.intervention.forEach(x => {
                        if (catFromInterv[x.category]) {
                            catFromInterv[x.category].push(x)
                        }
                        else {
                            catFromInterv[x.category] = [x]
                        }
                        isEmpty = false
                    })
                    const checkSd = (_n, v) => {
                        const n = parseInt(_n)
                        const o = omaha
                        if (o) {
                            const it = ns.intervention.find(x => x.id == n)
                            const z = ns.assessment_intervention.find(x => x.id == n)
                            if (it) {
                                const t = o.intervention.find(x => x.intervention_id == n)
                                if (t) {
                                    t.check = v
                                    t.detail = it
                                    if (!t.comment || t.comment == "") {
                                        t.comment = z ? z.comment : ""
                                    }
                                }
                                else {
                                    const i = new Model.KirokuII.OmahaInterv()
                                    i.intervention_id = it.id
                                    i.check = true
                                    i.comment = z ? z.comment : ""
                                    i.detail = it
                                    o.intervention.push(i)
                                }
                                sa.updateState()
                            }
                        }
                    }
                    const checkLis = (x) => {
                        const z = omaha ? omaha!.intervention.find(t => t.intervention_id == x.id) : null
                        const w = ns.assessment_intervention.find(ai => ai.intervention_id == x.id)
                        const cm = (
                            z && z.comment && z.comment != "" ? `(${z.comment})` :
                                w && w.comment && w.comment != "" ? `(${w.comment})`
                                    : ""
                        )
                        return {
                            key: x.id.toFixed(), value: z ? z.check : false,
                            label: x.target_category + ":" + x.target + cm
                        }
                    }

                    const intvCats = ["TGC", "CM", "TP", "S"]
                    return <div key={assId} style={{ padding: "10px" }}>
                        <div style={{
                            borderBottom: "solid",
                            borderWidth: "1px",
                            width: "200px",
                            marginTop: "10px",
                            marginBottom: "10px"
                        }}>
                            {ass.problem.problem_no}:{ass.problem.name}
                        </div>
                        {
                            isEmpty ?
                                <div>介入なし</div>
                                :
                                intvCats.map((ica, icai) => {
                                    return catFromInterv[ica] ? <div key={`ica_${icai}`}>
                                        <div style={{ fontWeight: "bold", color: Colors.primaryColor, fontSize: "14px" }}>{this.catToJp(ica)}</div>
                                        <div style={{ paddingLeft: "10px", borderLeft: "solid", borderWidth: "1px", borderColor: Colors.primaryColor, margin: "5px" }}>
                                            <EditableCheckList
                                                editMode={isEditing}
                                                vertical={true}
                                                list={catFromInterv[ica].map(x => checkLis(x))}
                                                setData={(_n, v) => checkSd(_n, v)} />
                                        </div>
                                    </div> : null
                                })
                        }
                    </div>
                })
            }
        </div>
    }
}
export class Vital extends EditViewBase {
    displayName = "Vital"
    public static getTitle(): string { return "バイタルサイン" }
    public getTitle(): string { return Vital.getTitle() }
    public omahaIDs() { return [] as number[] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku, state } = this.props

        const toDottedValue = (val) => {
            if (val != 0 && !val) return val
            const s = val.toString()
            if (s.length == 3 && s[2] != ".") {
                return s.slice(0, 2) + "." + s.slice(2)
            }
            return val
        }
        /*
        const taionFirst = kiroku.vital.first.taion
        if (taionFirst) {
            const n = toDottedValue(taionFirst.toString())
            if (taionFirst != n) {
                kiroku.vital.first.taion = n
            }
        }
        if (kiroku.vital.saiken) {
            const taionSaiken = kiroku.vital.saiken.taion
            if (taionSaiken) {
                const n = toDottedValue(taionSaiken.toString())
                if (taionSaiken != n) {
                    kiroku.vital.saiken.taion = n
                }
            }
        }*/
        const pastVital = (name) => {
            const getVal = (f, s, name) => {
                return (s && s[name]) ? s[name] : ((f && f[name]) ? f[name] : null)
            }
            const latest = state.latestKiroku
            const pVal = (latest && latest.vital) ? getVal(latest.vital.first, latest.vital.saiken, name) : null
            if (pVal == null) return "--"

            const cur = state.kiroku
            const cVal = (cur && cur.vital) ? getVal(cur.vital.first, cur.vital.saiken, name) : null
            if (cVal == null) return pVal
            const diff = (cVal - pVal)
            return `${pVal} (${diff == 0 ? "±" : diff > 0 ? "+" : ""}${diff.toFixed(2)})`
        }
        const vitals = (cat) => <Col2Table disableBGColor={false} body={[
            {
                name: "体温", content: <div>
                    <div style={{ display: "inline-flex" }}>{sa.numberC(cat, "taion", "", 1, toDottedValue)}</div>
                    <div style={{ display: "inline-flex" }}>前回:{pastVital("taion")}</div>
                </div>
            },
            {
                name: "血圧", content: <div>
                    <div>収縮:<div style={{ display: "inline-flex" }}>{sa.numberC(cat, "ketsuatsu_max")}</div><div style={{ display: "inline-flex" }}>前回:{pastVital("ketsuatsu_max")}</div></div>
                    <div>拡張:<div style={{ display: "inline-flex" }}>{sa.numberC(cat, "ketsuatsu_min")}</div><div style={{ display: "inline-flex" }}>前回:{pastVital("ketsuatsu_min")}</div></div>
                </div>
            },
            {
                name: "脈拍", content: <div>
                    <div>
                        <div style={{ display: "inline-flex" }}>{sa.numberC(cat, "myakuhaku")}</div>
                        <div style={{ display: "inline-flex" }}>前回:{pastVital("myakuhaku")}</div>
                    </div>
                    <div>{sa.checkC(cat, "myakuhaku_husei", "不整脈")}</div>
                </div>
            },
            {
                name: "SpO2", content: <div>
                    <div style={{ display: "inline-flex" }}>{sa.numberC(cat, "spo2")}</div>
                    <div style={{ display: "inline-flex" }}>前回:{pastVital("spo2")}</div>
                </div>
            },
            {
                name: "呼吸回数", content: <div>
                    <div style={{ display: "inline-flex" }}>{sa.numberC(cat, "kokyu_kaisu")}</div>
                    <div style={{ display: "inline-flex" }}>前回:{pastVital("kokyu_kaisu")}</div>
                </div>
            },
            {
                name: "血糖値", content: <div>
                    <div style={{ display: "inline-flex" }}>{sa.numberC(cat, "kettou_value")}</div>
                    <div style={{ display: "inline-flex" }}>前回:{pastVital("kettou_value")}</div>
                </div>
            },
            this.taiju_elem(),
            { name: "備考", content: sa.textC(cat, "comment") },
        ]} />
        const contents = [
            {
                name: "", content: <div className={classes.innerTable}>
                    {vitals(["vital", "first"])}
                </div>
            },
            {
                name: "再検", content:
                    kiroku.vital.saiken ? <div className={classes.innerTable}>
                        {vitals(["vital", "saiken"])}
                        <Button variant="outlined" size="small" color="default"
                            onClick={(e) => { kiroku.vital.saiken = undefined; sa.updateState() }}>
                            削除
                        </Button>
                    </div>
                        : <div className={classes.innerTable}>
                            <Button variant="outlined" size="small" color="default"
                                onClick={(e) => { kiroku.vital.saiken = new Model.KirokuII.VitalData(); sa.updateState() }}>
                                ＋追加
                            </Button>
                        </div>
            },
        ]
        return contents
    }
}

export class Skin extends EditViewBase {
    displayName = "Skin"
    public static getTitle(): string { return "皮膚" }
    public getTitle(): string { return Skin.getTitle() }
    public omahaIDs(): number[] { return [24] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku, patient_id } = this.props
        const patId = patient_id
        const bd = [
            { name: "写真", content: sa.docWithPast(["skin"], "docImage", 8, patId, kiroku.pastDocumentPool) }
        ]
        if (kiroku.skin.designr_docs) {
            kiroku.skin.designr_docs = kiroku.skin.designr_docs.filter(x => x != null)
        }
        if (!kiroku.skin.designr_docs || kiroku.skin.designr_docs.length == 0) {
            kiroku.skin.designr_docs = [null] as any
        }
        if (kiroku.skin.designr_docs[kiroku.skin.designr_docs.length - 1] != null) {
            kiroku.skin.designr_docs.push(null as any)
        }
        kiroku.skin.designr_docs.forEach((d, di) => {
            bd.push({ name: "褥瘡の評価" + (di > 0 ? (di + 1) : ""), content: sa.docWithPast(["skin", "designr_docs"], di, 61, patId, kiroku.pastDocumentPool) })
        })

        const contents = [
            { name: "", content: sa.textC(["skin"], "assessment") },
            {
                name: "", content: <div className={classes.innerTable}>
                    <Col2Table disableBGColor={false} body={bd} />
                </div>
            },
        ]
        return contents
    }
}
export class Breath extends EditViewBase {
    displayName = "Breath"
    public static getTitle(): string { return "呼吸" }
    public getTitle(): string { return Breath.getTitle() }
    public omahaIDs(): number[] { return [26] }
    private breath_bui = [
        { label: "気管支", key: "kikanshi" },
        { label: "全野", key: "zenya" },
        { label: "右上", key: "migi_ue" },
        { label: "右下", key: "migi_shita" },
        { label: "左上", key: "hidari_ue" },
        { label: "左下", key: "hidari_shita" },
        { label: "背の右上", key: "se_migi_ue" },
        { label: "背の右下", key: "se_migi_shita" },
        { label: "背の左上", key: "se_hidari_ue" },
        { label: "背の左下", key: "se_hidari_shita" }
    ]

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        const cat = ["breath", "kokyuon_ijou"]
        const comment_str = "_comment"
        if (!kiroku.breath.kokyuon_ijou.ijou_list) kiroku.breath.kokyuon_ijou.ijou_list = []
        const contents = [
            {
                name: "", content: sa.textC(["breath"], "assessment")
            },
            {
                name: "", content: <Col2Table disableBGColor={false} body={[
                    {
                        name: "呼吸音（聴診）", content: <div className={classes.innerTable}>
                            {
                                kiroku.breath.kokyuon_ijou.ijou_list.length == 0 ?
                                    <div key="noijou">異常なし</div>
                                    :
                                    kiroku.breath.kokyuon_ijou.ijou_list.map((ijou, idx) => {
                                        return <div key={`ijou_${idx}`} className={classes.innerTable} style={{ marginBottom: "5px" }}>
                                            <Col2Table disableBGColor={true} body={[
                                                { name: "部位", content: sa.listC(["breath", "kokyuon_ijou", "ijou_list", idx], "bui", Model.KirokuII.BreathIjouBui, "inline-flex") },
                                                {
                                                    name: "音", content: <div>
                                                        {sa.listC(["breath", "kokyuon_ijou", "ijou_list", idx], "sound", Model.KirokuII.BreathSound, "inline-flex")}
                                                        {sa.listC(["breath", "kokyuon_ijou", "ijou_list", idx], "sound_type", Model.KirokuII.BreathSoundType, "inline-flex")}
                                                    </div>
                                                },
                                                { name: "備考", content: sa.textC(["breath", "kokyuon_ijou", "ijou_list", idx], "comment", "備考", "text", "inline-flex") },
                                                {
                                                    name: "削除", content: <Button variant="outlined" size="small" color="secondary"
                                                        onClick={(e) => { kiroku.breath.kokyuon_ijou.ijou_list = kiroku.breath.kokyuon_ijou.ijou_list.filter((x, i) => i != idx); sa.updateState() }}>
                                                        ︎削除
                                                    </Button>
                                                }
                                            ]} />
                                        </div>
                                    })
                            }
                            <Button variant="outlined" size="small" color="default"
                                onClick={(e) => { kiroku.breath.kokyuon_ijou.ijou_list.push(new Model.KirokuII.BreathIjouData()); sa.updateState() }}>
                                ＋追加
                            </Button>
                        </div>
                    },
                    { name: "人工呼吸器", content: sa.docWithPast(["breath"], "jinko_kokyuki_setting_image", 8, patId, kiroku.pastDocumentPool) }

                ] as any} />
            },
            {
                name: "", content: sa.checkListC(["breath"], [
                    { key: "hot_kiki_kakunin_filter", label: "フィルタ交換" },
                    { key: "hot_kiki_kakunin_mizu", label: "水交換" },
                    { key: "hot_kiki_kakunin_konsento", label: "コンセント確認" },
                    { key: "hot_kiki_kakunin_yobi_bonbe", label: "予備ボンベ残量" },
                    { key: "kikan_sekkai_kahu_atsu_check", label: "カフ圧チェック" },
                    { key: "jinko_kokyuki_kairo_koukan_check", label: "回路交換実施" }
                ])/*,
                values: [
                    new CatVal("hot_sanso_touyo_ryou", ["breath"]),
                    new CatVal("hot_kiki_kakunin_filter", ["breath"]),
                    new CatVal("hot_kiki_kakunin_mizu", ["breath"]),
                    new CatVal("hot_kiki_kakunin_konsento", ["breath"]),
                    new CatVal("hot_kiki_kakunin_yobi_bonbe", ["breath"]),
                ]*/
            }
        ]
        return contents
    }
}
export class Pain extends EditViewBase {
    displayName = "Pain"
    public static getTitle(): string { return "痛み" }
    public getTitle(): string { return Pain.getTitle() }
    public omahaIDs(): number[] { return [22] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku, patient_id } = this.props

        const contents = [
            {
                name: "", content: sa.textC(["pain"], "assessment")
            },
            {
                name: "", content: <Col2Table disableBGColor={false} body={[
                    { name: "痛みの種類", content: sa.listC(["pain"], "pain_type", Model.KirokuII.PainType) },
                    { name: "痛みのパターン", content: sa.listC(["pain"], "pain_pattern", Model.KirokuII.PainPattern) },
                    {
                        name: "主観的評価", content: <div>
                            {sa.listC(["pain"], "shukan_hyouka_type", Model.KirokuII.PainShukanType)}
                            {
                                kiroku.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.NRS ? <div>
                                    <span className={classes.tooltipRoot}>
                                        <Tooltip disableFocusListener disableTouchListener title={nrsTooltip(classes)}><Icons.HelpOutlineOutlined /></Tooltip>
                                    </span>
                                    <div>０：なし</div>
                                    <div>１〜３：軽い</div>
                                    <div>４〜６：中等度</div>
                                    <div>７〜１０：強い</div>
                                    {sa.numberC(["pain"], "shukan_hyouka_nrs")}
                                </div> : null
                            }
                            {
                                kiroku.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.VAS ? <div>
                                    <div>左：全く痛みがない</div>
                                    <div>右：これ以上の強い痛みは考えられない、または最悪の痛み</div>
                                    {sa.sliderC(["pain"], "shukan_hyouka_vas")}
                                </div>
                                    : null
                            }
                            {
                                kiroku.pain.shukan_hyouka_type == Model.KirokuII.PainShukanType.FaceScale ?
                                    <div>
                                        {
                                            [1, 2, 3, 4, 5, 6].map(i => {
                                                return <img key={`face${i}`} src={`/scales/face_${i}.png`}
                                                    style={
                                                        {
                                                            width: "15%",
                                                            border: sa.getValueC("shukan_hyouka_face_scale", ["pain"]) == i ? "solid" : "none",
                                                            borderRadius: "10px",
                                                            borderWidth: "2px",
                                                            marginTop: "5px"
                                                        }
                                                    }
                                                    onClick={() => {
                                                        sa.setValueC("shukan_hyouka_face_scale", i, ["pain"])
                                                    }} />
                                            })
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    }
                ]} />
            }]
        return contents
    }
}
export class Oral extends EditViewBase {
    displayName = "Oral"
    public static getTitle(): string { return "口腔衛生" }
    public getTitle(): string { return Oral.getTitle() }
    public omahaIDs(): number[] { return [20] }
    public getHash(props: Props) { return JSON.stringify(props.kiroku.oral) }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, kiroku } = this.props
        const patId = this.props.patient_id
        const contents =
            [

                { name: "", content: sa.textC(["oral"], "assessment") },
                {
                    name: "", content: <Col2Table disableBGColor={false} body={[
                        { name: "O-HAT", content: sa.docWithPast(["oral"], "ohat_document", 12, patId, kiroku.pastDocumentPool) }
                    ]} />
                }]
        return contents
    }
}
export class Lang extends EditViewBase {
    displayName = "Lang"
    public static getTitle(): string { return "言語" }
    public getTitle(): string { return Lang.getTitle() }
    public omahaIDs(): number[] { return [19] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [{
            name: "", content: sa.textC(["lang"], "assessment")
        }]
        return contents
    }
}
export class Nutrition extends EditViewBase {
    displayName = "Nutrition"
    public static getTitle(): string { return "栄養・代謝" }
    public getTitle(): string { return Nutrition.getTitle() }
    public omahaIDs(): number[] { return [30] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id

        const contents = [
            {
                name: "", content: sa.textC(["nutrition"], "assessment")
            }
            , {
                name: "", content:
                    <Col2Table disableBGColor={false} body={[
                        { name: "血糖値(mg/dl)", content: sa.numberC(["vital", "first"], "kettou_value") },
                        { name: "自己管理ノート", content: sa.docWithPast(["metabolism"], "kettou_note_image", 8, patId, kiroku.pastDocumentPool) },
                    ]} />
            },
            {
                name: "", content: <Col2Table disableBGColor={false} body={[
                    this.taiju_elem(),
                    this.shincho_elem()
                ]} />
            }]
        return contents
    }
}
export class Excre extends EditViewBase {
    displayName = "Excre"
    public static getTitle(): string { return "排泄" }
    public getShowTitle(): string { return "消化・排泄" }
    public getTitle(): string { return Excre.getTitle() }
    public omahaIDs(): number[] { return [29, 38, 28] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [
            { name: "", content: sa.textC(["excretion"], "assessment") },
            {
                name: "", content:
                    <Col2Table disableBGColor={false} body={[
                        { name: "便性状(ブリストルスケール)", content: sa.listC(["excretion"], "haiben_keijo", Model.KirokuII.ExcretionKeijo) },
                        { name: "排便量スケール", content: sa.listC(["excretion"], "haiben_ryou_scale", Model.KirokuII.ExcretionScale) },
                        { name: "排便量", content: sa.numberC(["excretion"], "haiben") },
                        { name: "排尿量", content: sa.numberC(["excretion"], "hainyou") },
                    ]} />
            }
        ]
        return contents
    }
}

export class Sleep extends EditViewBase {
    displayName = "Sleep"
    public static getTitle(): string { return "睡眠" }
    public getTitle(): string { return Sleep.getTitle() }
    public omahaIDs(): number[] { return [31] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes } = this.props
        const contents = [
            {
                name: "", content: sa.textC(["sleep"], "assessment")
            }]

        return contents
    }
}
export class Cognition extends EditViewBase {
    displayName = "Cognition"
    public static getTitle(): string { return "認知・意識" }
    public getTitle(): string { return Cognition.getTitle() }
    public omahaIDs(): number[] { return [21, 23] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa } = this.props
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                {
                    name: "中核症状", content: sa.checkListC(["cognition"], [
                        { key: "chukakuKioku", label: "記憶障害" },
                        { key: "chukakuHandan", label: "判断力低下" },
                        { key: "chukakuKentou", label: "見当識障害" },
                        { key: "chukakuGengo", label: "言語障害" },
                        { key: "chukakuShikkou", label: "失行" },
                        { key: "chukakuShitsunin", label: "失認" }
                    ])
                },
                {
                    name: "周辺症状", content: sa.checkListC(["cognition"], [
                        { key: "shuhenSenmou", label: "せん妄" },
                        { key: "shuhenYokuutsu", label: "抑うつ" },
                        { key: "shuhenKouhun", label: "興奮" },
                        { key: "shuhenHaikai", label: "徘徊" },
                        { key: "shuhenSuimin", label: "睡眠障害" },
                        { key: "shuhenMousou", label: "妄想" }
                    ])
                },
                { name: "症状発生により困っていること", content: sa.textC(["cognition"], "komari") },
                { name: "本人ができること・強み", content: sa.textC(["cognition"], "tsuyomi") },
                {
                    name: "意識レベル(JCS)", content: sa.listRawC(["cognition"], "ishiki", [
                        { value: "", key: "未選択" },
                        { value: "0", key: "0：意識清明" },
                        { value: "1", key: "I-1：だいたい清明であるが、今ひとつはっきりしない" },
                        { value: "2", key: "I-2：見当識障害がある（場所や時間、日付が分からない）" },
                        { value: "3", key: "I-3：自分の名前、生年月日が言えない" },
                        { value: "10", key: "II-10：普通の呼びかけで容易に開眼する" },
                        { value: "20", key: "II-20：大きな声または体を揺さぶることにより開眼する" },
                        { value: "30", key: "II-30：痛み刺激を加えつつ呼びかけを繰り返すことにより開眼する" },
                        { value: "100", key: "III-100：痛み刺激に対し、払いのける動作をする" },
                        { value: "200", key: "III-200：痛み刺激に対し、少し手足を動かしたり、顔をしかめたりする" },
                        { value: "300", key: "III-300：痛み刺激に反応しない" },
                    ])
                }
            ]} />
        }]
        return contents
    }
} export class Circ extends EditViewBase {
    displayName = "Circ"
    public static getTitle(): string { return "循環" }
    public getTitle(): string { return Circ.getTitle() }
    public omahaIDs(): number[] { return [27, 28] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        if (!kiroku.circulation.mukumiList)
            kiroku.circulation.mukumiList = []
        const contents = [
            {
                name: "", content: sa.textC(["circulation"], "assessment")
            }, {
                name: "", content:
                    <Col2Table disableBGColor={false} body={[
                        {
                            name: "血圧右", content: <div>
                                <div style={{ display: "inline-flex" }}>収縮:{sa.numberC(["circulation"], "ketsuatsu_r_max")}</div>
                                <div style={{ display: "inline-flex" }}>拡張:{sa.numberC(["circulation"], "ketsuatsu_r_min")}</div>
                            </div >
                        },
                        {
                            name: "血圧左", content: <div>
                                <div style={{ display: "inline-flex" }}>収縮:{sa.numberC(["circulation"], "ketsuatsu_l_max")}</div>
                                <div style={{ display: "inline-flex" }}>拡張:{sa.numberC(["circulation"], "ketsuatsu_l_min")}</div>
                            </div >
                        },
                        this.taiju_elem(),
                        {
                            name: "浮腫", content: <div className={classes.innerTable}>
                                {
                                    kiroku.circulation.mukumiList.map((mukumi, mi) => {
                                        return <div key={`mukumi_${mi}`}>
                                            {sa.listC(["circulation", "mukumiList", mi], "mukumi_type", Model.KirokuII.CirculationMukumi, "inline-flex")}
                                            {sa.textC(["circulation", "mukumiList", mi], "comment", "コメント", "text")}
                                            <Button variant="outlined" size="small" color="secondary"
                                                onClick={(e) => { kiroku.circulation.mukumiList = kiroku.circulation.mukumiList.filter((x, i) => i != mi); sa.updateState() }}>
                                                ︎削除
                                            </Button>,
                                        </div>
                                    })
                                }
                                <Button variant="outlined" size="small" color="default"
                                    onClick={(e) => { kiroku.circulation.mukumiList.push(new Model.KirokuII.CirculationMukumiData()); sa.updateState() }}>
                                    ＋追加
                                </Button>
                            </div>
                        }
                    ]
                    } />
            }]
        return contents
    }
}

export class Mental extends EditViewBase {
    displayName = "Mental"
    public static getTitle(): string { return "メンタルヘルス" }
    public getTitle(): string { return Mental.getTitle() }
    public omahaIDs(): number[] { return [11, 8] }


    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        const docTemp = this.state.docTemp
        if (!this.state.docTemp.pastGAF || !this.state.docTemp.pastGAF.loaded) {
            this.state.docTemp.pastGAF = { loaded: true }
            HokanApi.getDocumentLatest(patId, 55, this.props.kiroku.time_start || undefined).then(res => {
                const latest = res.data.content as FormModel.GAF.Core
                docTemp.pastGAF = {
                    loaded: true,
                    doc_id: res.data.id,
                    date: DateUtil.toJpDateString(DateUtil.dateToString(res.data.created_at)),
                    score: latest.values.score,
                    open: false
                }
                this.setState({ docTemp: docTemp })
                sa.updateState()
            }).catch(err => {
                this.setState({ docTemp: docTemp })
                console.log(err)
            })
        }
        const doc_gaf = this.props.kiroku.mental.doc_gaf
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                {
                    name: "GAF", content: <div>
                        {
                            doc_gaf && doc_gaf.id != 0 ?
                                <div>今回：{doc_gaf.content.values.score}</div>
                                : null
                        }
                        {sa.docWithPast(["mental"], "doc_gaf", 55, patId, kiroku.pastDocumentPool)}
                        {
                            this.state.docTemp.pastGAF ?
                                <div>
                                    前回:{docTemp.pastGAF.score}（{docTemp.pastGAF.date}）
                                    <Button color="primary" size="small" variant="outlined"
                                        onClick={(e) => {
                                            docTemp.pastGAF.open = true
                                            this.setState({ docTemp: docTemp })
                                        }}
                                        key={`hoken_button_${name}`}
                                    >確認</Button>
                                    {
                                        docTemp.pastGAF.open ?
                                            <DocumentDialog
                                                patientId={patId}
                                                only={55}
                                                open={true}
                                                srcId={this.state.docTemp.pastGAF.doc_id}
                                                onSelect={(doc) => {
                                                    docTemp.pastGAF.open = false
                                                    this.setState({ docTemp: docTemp })
                                                }}
                                                onCansel={() => {
                                                    docTemp.pastGAF.open = false
                                                    this.setState({ docTemp: docTemp })
                                                }}
                                            />
                                            : null
                                    }
                                </div>
                                : null
                        }
                    </div>
                },
                {
                    name: "食事、水分、薬", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "shokuji_suibun_kusuri") },
                        ]} />
                    </div>
                },
                {
                    name: "排泄、分泌", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "haisetsu_bunpitsu") },
                        ]} />
                    </div>
                },
                {
                    name: "衛生", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "eisei") },
                        ]} />
                    </div>
                },
                {
                    name: "活動と休息", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "katsudou_kyusok") },
                        ]} />
                    </div>
                },
                {
                    name: "対人関係", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "taijin") },
                        ]} />
                    </div>
                },
                {
                    name: "安全や症状", content: <div className={classes.innerTable}>
                        <Col2Table disableBGColor={true} body={[
                            { name: "", content: sa.textC(["mental"], "anzen") },
                        ]} />
                    </div>
                }
            ]} />
        }]
        return contents
    }
}

export class Growth extends EditViewBase {
    displayName = "Growth"
    public static getTitle(): string { return "成長と発達" }
    public getTitle(): string { return Growth.getTitle() }
    public omahaIDs(): number[] { return [16] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props

        const kaupuTooltip = <div className={classes.tooltipRoot}>
            <Tooltip disableFocusListener disableTouchListener title={<div className={classes.tooltipDiv}>
                <div className={classes.tooltipTitle}>カウプ指数＝(体重g÷身長cmの2乗）X　10</div>
                <div className={classes.tooltipLine}>２２以上; 肥りすぎ</div>
                <div className={classes.tooltipLine}>２２－１９; 以前は（優良）現在は(少し太り気味）</div>
                <div className={classes.tooltipLine}>１９－１５; 正常</div>
                <div className={classes.tooltipLine}>１５－１３; やせ</div>
                <div className={classes.tooltipLine}>１３－１０; 栄養失調</div>
                <div className={classes.tooltipLine}>１０以下; 消耗症</div>
            </div>}><Icons.HelpOutlineOutlined /></Tooltip>
        </div>
        const shincho = sa.getValueC("shincho", ["vital"])
        const taiju = sa.getValueC("taiju", ["vital"])
        const taiju_unit = sa.getValueC("taiju_unit", ["vital"])
        let kaupu = "--"
        if (shincho && taiju) {
            kaupu = (taiju * 1000 / (shincho * shincho) * 10).toFixed(1).toString()
        }
        const contents = [{
            name: "", content: <Col2Table disableBGColor={false} body={[
                this.taiju_elem(),
                this.shincho_elem(),
                { name: <span>カウプ指数{kaupuTooltip}</span>, content: <span>{kaupu}</span> },
                { name: "頭囲(cm)", content: this.pastNumberC(["growth"], "toui") },
                { name: "胸囲(cm)", content: this.pastNumberC(["growth"], "kyoui") },
                { name: "粗大運動", content: sa.textC(["growth"], "hattatsu_sodai") },
                { name: "微細運動", content: sa.textC(["growth"], "hattatsu_bisai") },
                { name: "言語", content: sa.textC(["growth"], "hattatsu_gengo") },
                { name: "認知社会", content: sa.textC(["growth"], "hattatsu_ninchi") },
                { name: "食事", content: sa.textC(["growth"], "hattatsu_shokuji") }
            ]} />
        }]
        return contents
    }
}

export class Neuro extends EditViewBase {
    displayName = "Neuro"
    public static getTitle(): string { return "神経・筋・骨格" }
    public getTitle(): string { return Neuro.getTitle() }
    public omahaIDs(): number[] { return [25, 32] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa } = this.props
        const contents = [
            { name: "", content: sa.textC(["neuro_muscul"], "assessment") },
        ]
        return contents
    }
}
export class EOL extends EditViewBase {
    displayName = "EOL"
    public static getTitle(): string { return "ACP/EOL" }
    public getTitle(): string { return EOL.getTitle() }
    public omahaIDs(): number[] { return [9, 10] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, classes, kiroku } = this.props
        const patId = this.props.patient_id
        let ppsScore: number | null = null
        if (kiroku.eol) {
            let pps: number | null = null
            if (kiroku.eol.pps) {
                const p = kiroku.eol.pps
                if (p <= 20) pps = 4.0
                else if (p <= 50) pps = 2.5
                else pps = 0
            }
            if (pps != null) {
                ppsScore = pps
                if (kiroku.eol.ppi_keikuu == Model.KirokuII.PPIKeikuu.Keikuu2) ppsScore += 1.0
                else if (kiroku.eol.ppi_keikuu == Model.KirokuII.PPIKeikuu.Keikuu3) ppsScore += 2.5
                if (kiroku.eol.ppi_mukumi == "あり") ppsScore += 1.0
                if (kiroku.eol.ppi_kokyu_konnnan == "あり") ppsScore += 3.5
                if (kiroku.eol.ppi_senmou == "あり") ppsScore += 4.0
            }
        }
        const contents = [
            { name: "", content: sa.textC(["eol"], "assessment") },
            {
                name: "", content: <Col2Table disableBGColor={false} body={[
                    { name: "アドバンスケアプランニング", content: sa.docWithPast(["eol"], "acl_document", 56, patId, kiroku.pastDocumentPool) }
                ]} />
            },
            {
                name: "PPIスコア", content: <Col2Table disableBGColor={false} body={[
                    {
                        name: "PPS", content: <div>
                            {sa.numberC(["eol"], "pps")}
                            <a href="/images/pps_ppi.png" target="_blank">
                                <Tooltip disableFocusListener disableTouchListener title={<div className={classes.tooltipDiv}>
                                    <img src="/images/pps_ppi.png" width="100%" />
                                </div>}
                                ><Icons.HelpOutlineOutlined /></Tooltip>
                            </a>
                        </div>
                    },
                    {
                        name: "経口摂取量", content: <div>
                            {sa.listC(["eol"], "ppi_keikuu", Model.KirokuII.PPIKeikuu)}
                            ※消化管閉塞のため高カロリー輸液を施行している場合は0点とする
                        </div>
                    },
                    { name: "浮腫", content: sa.listC(["eol"], "ppi_mukumi", Model.KirokuII.AriNashi) },
                    { name: "安静時呼吸困難", content: sa.listC(["eol"], "ppi_kokyu_konnnan", Model.KirokuII.AriNashi) },
                    {
                        name: "せん妄", content: <div>
                            {sa.listC(["eol"], "ppi_senmou", Model.KirokuII.AriNashi)}
                            ※「あり」に原因が薬物単独のものは含めない
                        </div>
                    },
                    {
                        name: "スコア", content: <div>{ppsScore != null ? ppsScore + " 点" : "--"}</div>
                    },
                    {
                        name: "予想される予後", content: <div>
                            6.5点以上：21日以下（週単位）の可能性が高い<br />
                            3.5点以下：42日以上（月単位）の可能性が高い
                        </div>
                    }
                ]} />
            }
        ]
        return contents
    }
}
export class Kaigo extends EditViewBase {
    displayName = "Kaigo"
    public static getTitle(): string { return "介護・養育" }
    public getTitle(): string { return Kaigo.getTitle() }
    public omahaIDs(): number[] { return [13, 14, 15] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa } = this.props
        const contents = [{
            name: "", content: < Col2Table disableBGColor={false} body={
                [
                    { name: "養育・介護の状況", content: sa.textC(["kaigo"], "kiki") },
                    { name: "家族の認知・行動", content: sa.textC(["kaigo"], "kazoku_ninchi") },
                    { name: "社会資源", content: sa.textC(["kaigo"], "shakai_shigen") },
                ]} />
        }]
        return contents
    }
}
export class Deal extends EditViewBase {
    displayName = "Deal"
    public static getTitle(): string { return "アセスメントなど" }
    public getTitle(): string { return Deal.getTitle() }
    public omahaIDs(): number[] { return [] as number[] }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [{
            name: "", content: <div>
                {sa.textC(["deal"], "assessment")}
                <Button variant="outlined" size="small" color="default"
                    onClick={(e) => {
                        kiroku.deal.assessment = ""
                        sa.updateState()
                    }
                    }>
                    入力クリア
                </Button>
            </div>
        },
        {
            name: "", content: <Col2Table disableBGColor={false} body={[
                { name: "画像", content: sa.docWithPast(["deal"], "naiyou_docImage", 8, patId, kiroku.pastDocumentPool) }
            ]} />
        }
        ]
        return contents
    }
}

export class Simple extends EditViewBase {
    public getTitle(): string { return this.props.title }
    public omahaIDs(): number[] { return this.props.omahaIDs }

    public getContents(): { name: any, content: any, values?: CatVal[] }[] {
        const { sa, kiroku } = this.props
        const patId = this.props.patient_id
        const contents = [{
            name: "", content: sa.textC(["omaha"], this.props.category)
        }]
        return contents
    }
}
/*
export class Template extends EditViewBase {
    displayName="Template"
    public static getTitle(): string { return "" }
    public getTitle(): string { return .getTitle() }

    public getContents() {
        const { sa, classes, kiroku } = this.props
        const contents = []
        return contents
    }
}
*/