import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as Colors from './Colors'
import { DialogTitle, Dialog, withStyles, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DateUtil from '../util/DateUtil'

const styles = () => Styles.createStyles({
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
        textAlign: "left"
    },
    tableHead: {
    },
    title: {
        background: Colors.primaryColor,
        color: Colors.white
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    dialog: {
        padding: "10px"
    },
    searchField: {
        maxWidth: "200px"
    },
    dialogCustomizedWidth: {
        margin: "0px",
        width: "100%"
    }
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    }
}))(TableCell);


interface Props extends Styles.WithStyles<typeof styles> {
    kirokus: Model.KirokuII.KangoKirokuII[]
    onSelect: (updated: Model.KirokuII.KangoKirokuII) => void
    onCancel: () => void
    targetComponent: (kiroku: Model.KirokuII.KangoKirokuII) => JSX.Element
}
interface LocalState {
    dialogElement: JSX.Element
}

class KirokuSelectDialog extends React.Component<Props, LocalState> {
    displayName = "KirokuSelectDialog"
    constructor(props: Props) {
        super(props)
    }

    public componentDidMount = () => {
    }

    public close = () => {
        this.props.onCancel();
    }

    private selectKiroku(kiroku: Model.KirokuII.KangoKirokuII) {
        this.props.onSelect(kiroku)
    }

    public render() {
        const cls = this.props.classes
        console.log(this.props.kirokus)
        return <Dialog
            className={cls.dialog}
            onClose={(x) => this.close()}
            fullWidth={true}
            maxWidth="xl"
            open={true}
            classes={{ paperFullWidth: cls.dialogCustomizedWidth } as any}
            style={{ padding: "10px" }}>
            <DialogTitle>前回からコピー</DialogTitle>
            <Button variant="outlined" color="default"
                onClick={(e) => this.close()}>
                戻る
            </Button>
            <Table className={cls.table}>
                <TableBody>
                    {
                        this.props.kirokus.map((kiroku, ki) => {
                            return <TableRow key={ki} className={cls.row}
                                onClick={(e) => { this.selectKiroku(kiroku) }}>
                                <CustomTableCell style={{ textAlign: "center", padding: "5px" }} component="th" scope="row">
                                    <div className={cls.title}>
                                        {DateUtil.toJpDateTimeString(kiroku.time_start)}
                                    </div>
                                    {this.props.targetComponent(kiroku)}
                                </CustomTableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </Dialog>
    }
}

export default Styles.withStyles(styles)(KirokuSelectDialog)