import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import CodeUtil from '../../util/CodeUtil';
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import CsvCreator from 'react-csv-creator';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        height: "300px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
//        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    }
});


interface Props extends Styles.WithStyles {
    title: string,
    name: string,
    count: string[],
    data: any,
    color: string[],
    ticks?: number[]
}

class LineGraph extends React.PureComponent<Props> { 
	displayName="LineGraph"
    public render() {
        const { classes } = this.props;
        return <div className={classes.root}>
            <div style={{ position: "relative" }}>
                {this.props.title}
                <div style={{
                    position: "absolute",
                    right: "10px",
                    top: "-12px", cursor: "pointer"
                }}>
                    <CsvCreator
                        filename={this.props.title}
                        rows={CodeUtil.ToSJisList(this.props.data)}
                        text="CSV↓"
                    />
                </div>
            </div>
            <ResponsiveContainer width="100%" height="95%">
            <LineChart
                data={this.props.data}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={this.props.name} />
                <YAxis ticks={this.props.ticks}/>
                <Tooltip />
                <Legend />
                {
                    this.props.count.map((c, i) => {
                        return <Line key={`lg_${i}`} dataKey={this.props.count[i]} stroke={this.props.color[i]} />
                    })
                    }
            </LineChart>
            </ResponsiveContainer>
        </div>
    }
}

export default Styles.withStyles(styles)(LineGraph)