export class Core{
    public values: Values = new Values();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public score_ninchi: number = -1
    public score_shitsujun: number = -1
    public score_katsudou: number = -1
    public score_kadou: number = -1
    public score_eiyou: number = -1
    public score_zure: number = -1
    public sum_score: number = 0
}