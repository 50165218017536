import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import { Button } from '@material-ui/core';
import * as HokanApi from '../../api/hokan'
import OrgSelectDialog from '../OrgSelectDialog';
import UserSelectDialog from '../UserSelectDialog';
import Col2Table from '../Col2Table';

class TempData {
    branch: Model.Branch = new Model.Branch()
    tanto: Model.OrganizationTanto = new Model.OrganizationTanto()
    tantoDialog: boolean = false
    branchDialog: boolean = false
}

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"

    public getId(): number {
        return 30
    }

    public createContent(): any {
        const res = new FormModel.FaxTemplate.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.FaxTemplate.Core
        let s = content.values.pat_name
        let d = ""
        let c = 0
        s = s.replace("𠮷", "吉")
        for (let i=0; i < s.length; ++i) {
            if (s[i] == ' ' || s[i] == '　') {
                d += s[i]
            }
            else {
                if (c%2) d += "*"
                else d += s[i]
                ++c
            }
        }

        content.values.pat_name = d
        content.values.to_name = ""
        content.houkoku_saki.forEach((hs, i) => {
            const f = ((!hs.to_fax || hs.to_fax == "") ? "" : "\n (FAX:" + hs.to_fax + ")")
            if (i > 0) content.values.to_name += '\n'
            content.values.to_name += hs.to_name + f;
        })
    }

    private loadFromBranch(doc: Model.Document.Document, sa: StoreAccessor) {
        const content = doc.content as FormModel.FaxTemplate.Core
        const values = content.values
        HokanApi.me().then(x => {
            const b = x.data.branch
            if (b) {
                values.branch_name = b.name
                values.branch_tel = b.tel
                values.branch_fax = b.fax
                values.branch_address = b.address
            }
            sa.updateState()
        }).catch(err => { console.log(err) })
    }

    public setUser(doc: Model.Document.Document, user: Model.User) {
        const content = doc.content as FormModel.FaxTemplate.Core
        content.values.tanto_name = user ? user.name : ""
    }

    public beforeMount = (doc: Model.Document.Document) => {
        doc.temp_data = new TempData()
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor, classes: any) {
        const saV = sa.generateChild(["content", "values"])
        const saC = sa.generateChild(["content", "checks"])
        const saBase = sa.generateChild(["content"])
        const content = doc.content as FormModel.FaxTemplate.Core
        const values = content.values
        const temp_data = doc.temp_data as TempData

        let houkoku_saki = saBase.getValue("houkoku_saki")

        if (houkoku_saki) {
            if (houkoku_saki.findIndex(x => x == null) >= 0) {
                const p = houkoku_saki.filter(x => x != null)
                saBase.setValue("houkoku_saki", p)
            }
        }
        if (!houkoku_saki || houkoku_saki.length == 0) {
            const t = { to_name: "", to_fax: "" }
            if (values.to_name) t.to_name = values.to_name
            if (values.to_fax) t.to_fax = values.to_fax
            houkoku_saki = [t]
            saBase.setValue("houkoku_saki", houkoku_saki)
        }
        const elements_t = [
            { name: "担当", content: saV.text("tanto_name") },
            {
                name: "宛先", content: <div>
                    {
                        houkoku_saki.map((o, i) => {
                            return <Col2Table key={"to_" + i} disableBGColor={false} body={[
                                {
                                    name: "- 宛先("+(i+1)+") -", content: <div>
                                        <Button color="primary" size="small" variant="outlined"
                                            onClick={(e) => {
                                                doc.temp_data["tantoDialog_" + i] = true;
                                                sa.updateState();
                                            }}
                                        >機関マスタから取得</Button> 
                                        {
                                            doc.temp_data["tantoDialog_" + i] ?
                                                <OrgSelectDialog
                                                    key={`orgTanto_dialog_fax_${i}`}
                                                    patientId={doc.patient ? doc.patient.id : undefined}
                                                    open={true}
                                                    onSelect={(org, tanto) => {
                                                        const s = `${org.name}　${tanto ? tanto.name : ""}`
                                                        doc.temp_data["tantoDialog_"+i] = false;
                                                        saBase.setValueC("to_name", s, ["houkoku_saki", i]);
                                                        if (tanto) {
                                                            saBase.setValueC("to_fax", (!tanto.fax || tanto.fax == "" ? org.fax : tanto.fax), ["houkoku_saki", i]);
                                                        }
                                                    }}
                                                    onCancel={() => {
                                                        doc.temp_data["tantoDialog_" + i] = false;
                                                        sa.updateState();
                                                    }}
                                                />
                                                : null
                                        }
                                        </div>
                                    },
                                { name: "宛先名", content: saBase.textC(["houkoku_saki", i], "to_name") },
                                { name: "FAX", content: saBase.textC(["houkoku_saki", i], "to_fax") },
                                    { name: " ", content: <Button variant="outlined" size="small" color="secondary"
                                        onClick={(e) => {
                                            const p = houkoku_saki.filter((x,j)=>j!=i)
                                            saBase.setValue("houkoku_saki", p)
                                        }}>
                                        {"宛先(" + (i + 1) + ") "}削除
                                    </Button>}
                            ]} />
                        })
                    }
                    <Button variant="outlined" size="small" color="primary"
                        onClick={(e) => {
                            houkoku_saki.push({to_name:"", to_fax:""});
                            saBase.setValue("houkoku_saki", houkoku_saki)
                        }}>
                            ＋追加
                    </Button>
                </div>
            },
            {
                name: "種類", content: saC.checkList([
                    { label: "ご報告", key: "houkoku" },
                    { label: "ご相談", key: "soudan" },
                    { label: "返信依頼", key: "henshin" },
                    { label: "その他", key: "other" }
                ], false, true)
            },
            { name: "タイトル", content: saV.text("title") },
            { name: "本文", content: saV.text("content", "", "text", "block", true, "300px") }
        ]

        const elements_j = [
            {
                name: "コピー", content: <div>
                    <Button variant="outlined" size="small"
                        onClick={(e) => this.loadFromBranch(doc, sa)}
                        className={classes.loadButton} >自分の事業所</Button>
                    <Button variant="outlined" size="small"
                        onClick={(e) => {
                            temp_data.branchDialog = true
                            sa.updateState();
                        }}
                        className={classes.loadButton} >事業所一覧から取得</Button>
                    {
                        doc.temp_data.branchDialog ?
                            <UserSelectDialog
                                open={true}
                                onlyBranch={true}
                                onSelect={(b: Model.Branch, u: Model.SimpleUser | null) => {
                                    values.branch_name = b.name
                                    values.branch_tel = b.tel
                                    values.branch_fax = b.fax
                                    values.branch_address = b.address
                                    temp_data.branchDialog = false
                                    sa.updateState();
                                }}
                                onCancel={() => {
                                    temp_data.branchDialog = false
                                    sa.updateState();
                                }}
                            />
                            : null
                    }
                </div>
            },
            { name: "事業所名", content: saV.text("branch_name") },
            { name: "事業所住所", content: saV.text("branch_address") },
            { name: "事業所TEL", content: saV.text("branch_tel") },
            { name: "事業所FAX", content: saV.text("branch_fax") }
        ]


        const elements = [
            { name: '内容', content: elements_t },
            { name: '事業所', content: elements_j }
        ]
        return elements
    }
}
