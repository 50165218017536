import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import {
    ResponsiveContainer, ComposedChart, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar
} from 'recharts';
import { saveAs } from 'file-saver';
import Encoding from 'encoding-japanese';

const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        // minHeight: "330px",
        height: "400px",
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
    }
});


interface Props extends Styles.WithStyles {
    data: any,
    months: string[],
}


function genColor(idx: number) {
    return `hsl(${idx * 63 % 360}, 40%, 60%)`
}
function genColor2(idx: number) {
    return `hsla(${idx * 63 % 360}, 40%, 50%, 0.8)`
}

const transpose = (a: (string | number)[][]): (string | number)[][] => a[0].map((_, c) => a.map(r => r[c]));
class HoumonMonthly extends React.PureComponent<Props> {
    displayName = "HoumonMonthly"
    public render() {
        const { classes } = this.props;

        const csvData = (() => {
            if(this.props.data == null) return ""
            if(this.props.data[0] == null) return ""
            const headers = Object.keys(this.props.data[0])

            const rows: (string | number)[][] = [headers]

            this.props.months.forEach((month) => {
                const data = this.props.data.find(d => d["月"] === month)
                const row: (string | number)[] = []
                headers.forEach((header) => {
                if(header === "月"){
                    row.push(month)
                }else if (data == null) {
                    row.push(0)
                }else{
                    row.push(data[header])
                }
                })
                rows.push(row)
            })

            let result =""

            transpose(rows).forEach((row) =>{
                result += row.join(",") + "\n"
            })

            return result
        })()

        const onClick = () => {
            const str = Encoding.stringToCode(csvData);
            const convert = Encoding.convert(str, 'sjis', 'unicode');
            const u8a = new Uint8Array(convert);

            const blob = new Blob([u8a], { type: 'text/csv;charset=Shift_JIS;' });
            saveAs(blob, `月別訪問件数／時間.csv`);
        }

        return <div className={classes.root}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                    月別訪問件数/時間
                </div>
                <Button type="button" variant='text' onClick={onClick} style={{marginLeft: "auto"}}>CSV↓(Sjis)</Button>
            </div>
            <ResponsiveContainer width="100%" height="90%">
                <ComposedChart
                    data={this.props.data}
                    margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="月" padding={{ left: 30, right: 30 }} />
                    <YAxis yAxisId="right" orientation="right" />
                    <YAxis yAxisId="left" />
                    <Tooltip formatter={(value, name, props) => {
                        const a = value.toFixed(0)
                        const b = value.toFixed(1)
                        return (a + ".0") == b ? a : b
                    }}/>
                    <Legend />
                    <Line yAxisId="left" dataKey='合計（件）' stroke={genColor(0)} />
                    <Line yAxisId="left" dataKey='医療（件）' stroke={genColor(1)} />
                    <Line yAxisId="left" dataKey='介護（件）' stroke={genColor(2)} />
                    <Line yAxisId="left" dataKey='その他（件）' stroke={genColor(3)} />
                    <Line yAxisId="right" dataKey='合計（時間）' stroke={genColor(4)} />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='医療（時間）' fill={genColor2(7)} />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='介護（時間）' fill={genColor2(8)} />
                    <Bar yAxisId="right" barSize={15} stackId="a" dataKey='その他（時間）' fill={genColor2(9)} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    }
}

export default Styles.withStyles(styles)(HoumonMonthly)
