import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core/';

const styles = () => Styles.createStyles({
    list: {
        margin: "5px",
        paddingTop: "0px",
        paddingBottom: "0px",
        marginTop: "0px"
    },
    itemHeader: {
        background: Colors.primaryColor,
        height: "2.5rem",
        marginBottom: "5px",
        marginTop: "5pxp"
        //
        //
    },
    itemHeaderOrange: {
        background: Colors.orangeColor,
        color: Colors.primaryColor,
        height: "2.5rem",
        marginBottom: "5px",
        marginTop: "5pxp"
        //
        //
    },
     itemHeaderSticky: {
        margin: "0px",
        color: Colors.white,
        height: "40px",
        minHeight: "40px !important",
        position: "sticky",
        top: "60px",
        zIndex: 2,
    },
    headerText: {
        "& span": {
            color: Colors.white,
            fontSize: "1.0rem",
            margin: "1rem"
        }
    },
    row: {
        //border: "solid",
        //borderWidth: "1px",
        margin: "0px",
        width: "100%",
        //marginTop: "-1px",
        //borderColor: Colors.borderColor, 
    },
})

interface Props extends Styles.WithStyles<typeof styles> {
    title: string | any,
    isOrange?: boolean,
    hasBorder?: boolean,
    sticky?:boolean
}

class StandardTable extends React.Component<Props> { 
	displayName="StandardTable"
    public render() {
        const { classes } = this.props;
        return <List className={classes.list}
            style={
                this.props.hasBorder ? {
                border: "solid",
                    borderWidth: "1px",
                    borderColor: this.props.isOrange ? Colors.orangeColor : Colors.primaryColor,
                    color: this.props.isOrange ? Colors.primaryColor : undefined
            } : {}} >
                {
                !this.props.title ?
                null:
                    <ListItem className={this.props.isOrange ? classes.itemHeaderOrange : classes.itemHeader}
                        style={this.props.sticky ? { position: "sticky", top: "0px", zIndex: 2 } : undefined}>
                        <ListItemText
                            className={classes.headerText}
                            primary={this.props.title}
                        />
                    </ListItem>
                }
            <div className={classes.row}>
                {this.props.children}
            </div>
        </List>
    }
}

export default Styles.withStyles(styles)(StandardTable)