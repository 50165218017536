import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import Col2Table from '../../components/Col2Table';
import * as Colors from '../../components/Colors';

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
    displayName = "Behaviour"

    public getId(): number {
        return 55
    }

    public createContent(): any {
        const res = new FormModel.GAF.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        const content = doc.content as FormModel.GAF.Core
        const contains = (minV, maxV):boolean => {
            const s = content.values.score
            return (s && minV <= s && s <= maxV) ? true : false
        }
        content.checks.check_maru_0 = content.checks.check_waku_0 = contains(0, 0)
        content.checks.check_maru_10 = content.checks.check_waku_10 = contains(1, 10)
        content.checks.check_maru_20 = content.checks.check_waku_20 = contains(11, 20)
        content.checks.check_maru_30 = content.checks.check_waku_30 = contains(21, 30)
        content.checks.check_maru_40 = content.checks.check_waku_40 = contains(31, 40)
        content.checks.check_maru_50 = content.checks.check_waku_50 = contains(41, 50)
        content.checks.check_maru_60 = content.checks.check_waku_60 = contains(51, 60)
        content.checks.check_maru_70 = content.checks.check_waku_70 = contains(61, 70)
        content.checks.check_maru_80 = content.checks.check_waku_80 = contains(71, 80)
        content.checks.check_maru_90 = content.checks.check_waku_90 = contains(81, 90)
        content.checks.check_maru_100 = content.checks.check_waku_100 = contains(91, 100)
    }

    public beforeMount = () => {

    }

    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>・機能の全体的評定（GAF）尺度：髙橋三郎，大野裕，染谷俊幸（訳）：DSM-IV-TR 精神疾患の診断・統計マニュアル．機能の全体的評定（GAF）尺度．p47，医学書院，2004</div>
           </div>
    }
    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const elements_naiyo = [
            { name: "スコア", content: saV.numberC([], "score") },
        ]
        const getColor = (minV, maxV) => {
            const s = saV.getValue("score")
            if (s && minV <= s && s <= maxV) {
                return {
                    backgroundColor: Colors.primaryColor,
                    color: "white"
                }
            }
            else return {}
        }
        const elements_explain = [
            {
                name: "注意", content: <div>
                    精神的健康と病気という１つの仮想的な連続体に沿って、心理的、社会的、職業的機能を考慮せよ。<br />
                    身体的（または環境的）制約による機能の障害を含めないこと。
                </div>
            },
            {
                name: "コード", content: <div>
                    <Col2Table body={[
                        {
                            name: <div style={getColor(91,100)}>100-91</div>, content: <div>
                                広範囲の行動にわたって最高に機能しており、生活上の問題で手に負えないものは何もなく、その人の多数の
                                長所があるために他の人々から求められている。症状は何もない。
                            </div>
                        },
                        {
                            name: <div style={getColor(81, 90) }>90-81</div>, content: <div>
                                症状がまったくないか、ほんの少しだけ（例：試験前の軽い不安）、すべての面でよい機能で、広範囲の活動に興
                                味をもち参加し、社交的にはそつがなく、生活に大体満足し、日々のありふれた問題や心配以上のものはない
                                （例：たまに、家族と口論する）。
                            </div>
                        },
                        {
                            name: <div style={getColor(71, 80) }>80-71</div>, content: <div>
                                症状があったとしても、心理的社会的ストレスに対する一過性で予期される反応である（例：家族と口論した後の
                                集中困難）、社会的、職業的または学校の機能にごくわずかな障害以上のものはない（例：学業で一時遅れをとる）。
                            </div>
                        },
                        {
                            name: <div style={getColor(61, 70) }>70-61</div>, content: <div>
                                いくつかの軽い症状がある（例：抑うつ気分と軽い不眠）、または、社会的、職業的または学校の機能に、いくら
                                かの困難はある（例：時にずる休みをしたり、家の金を盗んだりする）が、全般的には、機能はかなり良好であっ
                                て、有意義な対人関係もかなりある。
                            </div>
                        },
                        {
                            name: <div style={getColor(51, 60) }>60-51</div>, content: <div>
                                中等度の症状（例：感情が平板的で、会話がまわりくどい、時に、恐慌発作がある）、または、社会的、職業的、
                                または学校の機能における中等度の障害（例：友達が少ない、仲間や仕事の同僚との葛藤）。
                            </div>
                        },
                        {
                            name: <div style={getColor(41, 50) }>50-41</div>, content: <div>
                                重大な症状（例：自殺の考え、強迫的儀式がひどい、しょっちゅう万引する）、または、社会的、職業的または学
                                校の機能において何か重大な障害（友達がいない、仕事が続かない）。
                            </div>
                        },
                        {
                        name:  <div style={getColor(31, 40)}>40-31</div>, content: <div>
                                現実検討か意思伝達にいくらかの欠陥（例：会話は時々、非論理的、あいまい、または関係性がなくなる）、また
                                は、仕事や学校、家族関係、判断、思考または気分、など多くの面での粗大な欠陥（例：抑うつ的な男が友人を
                                避け家族を無視し、仕事ができない。子どもが年下の子どもを殴り、家で反抗的で、学校では勉強ができない）。
                            </div>
                        },
                        {
                        name:  <div style={getColor(21, 30) }>30-21</div>, content: <div>
                                行動は妄想や幻覚に相当影響されている。または意思伝達か判断に粗大な欠陥がある（例：時々、滅裂、ひど
                                く不適切にふるまう、自殺の考えにとらわれている）、または、ほとんどすべての面で機能することができない
                                （例：一日中床についている、仕事も家庭も友達もない）。
                            </div>
                        },
                        {
                        name:  <div style={getColor(11, 20) }>20-11</div>, content: <div>
                                自己または他者を傷つける危険がかなりあるか（例：死をはっきり予期することなしに自殺企図、しばしば暴力
                                的、躁病性興奮）、または、時には最低限の身辺の清潔維持ができない（例：大便を塗りたくる）、または、意
                                思伝達に粗大な欠陥（例：ひどい滅裂か無言症）。
                            </div>
                        },
                        {
                        name:  <div style={getColor(1, 10) }>10-1</div>, content: <div>
                                自己または他者をひどく傷つける危険が続いている（例：何度も暴力を振るう）、または最低限の身辺の清潔維
                                持が持続的に不可能、または、死をはっきり予測した重大な自殺行為。
                            </div>
                        },
                        {
                        name:  <div style={getColor(0, 0)}>0</div>, content: <div>
                                情報不十分
                            </div>
                        },
                    ]} />
                </div>
            }
        ]

        const elements = [
            { name: '説明', content: elements_explain },
            { name: '内容', content: elements_naiyo }
        ]

        return elements
    }
}
