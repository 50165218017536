import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import Loading from '../components/Loading';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Select, MenuItem } from '@material-ui/core';
//import EditableList from '../components/EditableList';
//import DateUtil from '../util/DateUtil';
//import EditableCheckList from '../components/EditableCheckList';


import Calendar from 'react-calendar';

const styles = () => Styles.createStyles({
    root: {
        margin: "0px",
        width: "100%",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    innerTable: {
        padding: '5px'
    },
    tooltipRoot: {
        display: "inline-block",
        marginLeft: "10px"
    },
    tooltipDiv: {
        fontSize: "0.9rem",
        color: "white"
    },
    tooltipTitle: {
        fontWeight: "bold",
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    tooltipLine: {
        borderTop: "solid",
        borderWidth: "1px",
        borderColor: "lightgray",
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    calendar: {
        margin: "20px"
    },
    calendarLabel: {
        margin: 0,
        borderRadius: "5px",
        backgroundColor: "gray",
        color: "white"
    },
});

interface Params {
    patient_id: string
}
interface Props extends PageProps<Params>, Styles.WithStyles {

}

interface LocalState {
    loading: boolean,
    clickedDate: Date | Date[],
    dateList: Record[]
}

class Kyukei {
    public from: Date = new Date()
    public to: Date = new Date()
}

class Record {
    public record_type: string = "出勤"
    public the_date: Date = new Date()
    public from: string = "09:00"
    public to: string = "18:00"
    public kyukei: Kyukei[] = []
    public is_comitted: boolean = false
    public memo: string = ""
}

// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "WorkRecordPage"
    constructor(props: Props) {
        super(props)
        this.state = {
            clickedDate: new Date(),
            loading: false,
            dateList: []
        }
    }

    public componentDidMount = () => {
        this.setMonth(new Date());
    }

    public recordChange = (r: Record, name: string, value: any) => {
        r[name] = value;
        this.setState({ dateList: this.state.dateList })
    }

    public getMonthDays = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    }

    public setMonth(date: Date) {
        if (this.state.dateList.length > 0) {
            if (date.getFullYear() == this.state.dateList[0].the_date.getFullYear()
                && date.getMonth() == this.state.dateList[0].the_date.getMonth()) {
                this.setState({ clickedDate: date })
                return;
            }
        }
        const y = date.getFullYear()
        const m = date.getMonth()
        const days = this.getMonthDays(y, m);
        const dateList: Record[] = []
        for (let i = 1; i <= days; ++i) {
            const d = new Record()
            d.the_date = new Date(y, m, i, 0, 0, 0, 0);
            dateList.push(d)
        }
        this.setState({
            clickedDate: date,
            dateList: dateList
        })
    }

    public render() {
        const { classes } = this.props

        if (this.state.loading) return <Loading />

        const getDayString = (d: Date) => {
            return ["日", "月", "火", "水", "木", "金", "土"][d.getDay()]
        }
        const toDateString = (d: Date) => {
            return `${d.getMonth() + 1}/${d.getDate()}(${getDayString(d)})`
        }

        return <div className={classes.root}>
            <Calendar
                className={classes.calendar}
                locale="ja"
                calendarType="US"
                tileContent={({ date, view }) => {
                    if (view === 'month' && date.getDate() % 4 != 0) {
                        return <p className={classes.calendarLabel}>未入力</p>
                    }
                    else {
                        return null
                    }
                }}
                onClickMonth={(date) => { this.setMonth(date) }}
                onClickYear={(date) => { this.setMonth(date) }}
                onActiveDateChange={(date) => { this.setMonth(date.activeStartDate) }}
                onChange={(date) => { Array.isArray(date) ? this.setMonth(date[0]) : this.setMonth(date) }}
                value={this.state.clickedDate}
            />
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">日付</TableCell>
                        <TableCell align="center">出勤</TableCell>
                        <TableCell align="center">出勤時間</TableCell>
                        <TableCell align="center">休憩</TableCell>
                        <TableCell align="center">メモ</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.state.dateList.map((d, idx) => {
                            return <TableRow key={idx}>
                                <TableCell align='center'>
                                    {toDateString(d.the_date)}
                                </TableCell>
                                <TableCell align='center'>
                                    <Select
                                        value={d.record_type}
                                        autoWidth={true}
                                        displayEmpty={false}
                                        variant='outlined'
                                        onChange={(e) => this.recordChange(d, "record_type", (e.target as any).value)}
                                        className={classes.input}
                                    >
                                        <MenuItem key='出勤' value="出勤">出勤</MenuItem>
                                        <MenuItem key='欠勤' value="欠勤">欠勤</MenuItem>
                                        <MenuItem key='有給' value="有給">有給</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell align='center'>
                                    <TextField
                                        type="time"
                                        defaultValue="00:00"
                                        value={d.from}
                                        onChange={(e) => this.recordChange(d, "from", e.target.value)}
                                        className={classes.dateField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    〜
                                    <TextField
                                        type="time"
                                        defaultValue="00:00"
                                        value={d.to}
                                        onChange={(e) => this.recordChange(d, "to", e.target.value)}
                                        className={classes.dateField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    +
                                </TableCell>
                                <TableCell align='center'>
                                    <TextField
                                        type="text"
                                        value={d.memo}
                                        onChange={(e) => this.recordChange(d, "memo", e.target.value)}
                                        className={classes.dateField}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <Button size="small" variant="outlined" onClick={() => { }}>更新</Button>
                                </TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))
