import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps } from '../components/Page';
import { GoogleCalendar, toInjector } from '../stores';
import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
})

interface Props extends GoogleCalendar, PageProps, Styles.WithStyles {
}

@inject(...toInjector(GoogleCalendar))
@observer
class GoogleCalendarRedirectPage extends React.Component<Props> { 
	displayName="GoogleCalendarRedirectPage"
    public componentDidMount(){
        const code: string | undefined = getParameters().code
        if(!code){
            this.props.history.push("/google-calendar-oauth")
            return 
        }
        this.props.googleCalendar!.setAccessToken(code).then(
            x => this.props.history.push("/google-calendar-oauth")
        )
    }

    public render() {
        return (
            <div>
                <div>code</div>
                <div>{getParameters().code}</div>
            </div>
        )
    }
}

const getParameters = function () {
    var vars = {};
    var param = location.search.substring(1).split('&');
    for (var i = 0; i < param.length; i++) {
        var keySearch = param[i].search(/=/);
        var key = '';
        if (keySearch != -1) key = param[i].slice(0, keySearch);
        var val = param[i].slice(param[i].indexOf('=', 0) + 1);
        if (key != '') vars[key] = decodeURI(val);
    }
    return vars as any;
}

export default Styles.withStyles(styles)(GoogleCalendarRedirectPage)