import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as Colors from '../Colors'
import Loading from '../Loading'
import SubTitle from '../SubTitle'
import { toInjector, Problem } from '../../stores';
import { inject, observer } from 'mobx-react';
import { AssessmentComponent, AssessmentProps } from './AssessmentComponent'
import * as Hash from './Hash'
import GoPrevNext from './GoPrevNext'
import Radio from '@material-ui/core/Radio';
import * as Model from '../../models';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    list: {
        marginBottom: "20px"
    },
    itemHeader: {
        background: Colors.primaryColor,

    },
    headerText: {
        "& span": {
            color: Colors.white,
            fontSize: "1.1em"
        }
    },
    item: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        padding: "5px",
        fontWeight: 700,
        '&:hover': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:first-child": {


        }
    },
    itemText: {
        paddingRight: "0px",
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    }
});

interface Props extends Styles.WithStyles, AssessmentProps, Problem {
    ass: Model.Assessment.PostAssessment
    changeAssessment: (func: (ass: Model.Assessment.Assessment) => void) => void
}

interface LocalState {
    modifier1: string,
    modifier2: string,
    priority: number
}

@inject(...toInjector(Problem))
@observer
class Modifier extends AssessmentComponent<Props, LocalState> {
    displayName = "Modifier"
    constructor(props: Props) {
        super(props)
        this.state = {
            modifier1: "個人",
            modifier2: "顕在",
            priority: 1
        }
    }

    public componentDidMount = () => {
        this.props.problem!.loadProblem(this.props.problem_id)
        const ass = this.props.ass.assessment
        this.setState({
            modifier1: ass.modifiers1,
            modifier2: ass.modifiers2,
            priority: ass.priority
        })
        window.scrollTo(0, 0)
    }

    private goPrev = () => {
        this.props.gotoPage(Hash.Symptom)
    }

    private goNext = () => {
        this.props.gotoPage(Hash.Kbs)
    }

    private setModifier = (ass: Model.Assessment.Assessment) => {
        ass.modifiers1 = this.state.modifier1;
        ass.modifiers2 = this.state.modifier2;
        ass.priority = this.state.priority;
    }

    private changeAss = () => {
        this.props.changeAssessment((a) => this.setModifier(a))
    }

    public render() {
        const { classes } = this.props
        const problem = this.props.problem!.problem
        const loading = (!problem || this.props.problem!.loading)

        const mod1 = ["個人", "家族", "コミュニティ"]
        const mod2 = ["顕在", "潜在", "健増"]
        return <div className={classes.root}>
            <SubTitle title="修飾因子選択"
                text="修飾因子を選択してください"
            />
            {
                loading ? <Loading /> :
                    <div>
                        <List className={classes.list}>
                            <ListItem className={classes.itemHeader}>
                                <ListItemText
                                    className={classes.headerText}
                                    primary={"優先度"}
                                />
                            </ListItem>
                            <ListItem key={`pri_high`} button={true}
                                className={classes.item}
                                onClick={(e) => {
                                    this.setState({ priority: 1 })
                                    this.changeAss()
                                }} >
                                <Radio color="primary"
                                    checked={this.state.priority == 1}
                                    onChange={(e) => {
                                        this.setState({ priority: 1 })
                                        this.changeAss()
                                    }} />
                                <ListItemText primary={"高"}
                                    className={classes.itemText} />
                            </ListItem>
                            <ListItem key={`pri_low`} button={true}
                                className={classes.item}
                                onClick={(e) => {
                                    this.setState({ priority: 0 })
                                    this.changeAss()
                                }} >
                                <Radio color="primary"
                                    checked={this.state.priority == 0}
                                    onChange={(e) => {
                                        this.setState({ priority: 0 })
                                        this.changeAss()
                                    }
                                    } />
                                <ListItemText primary={"低"}
                                    className={classes.itemText} />
                            </ListItem>
                        </List>
                        <List className={classes.list}>
                            <ListItem className={classes.itemHeader}>
                                <ListItemText
                                    className={classes.headerText}
                                    primary={"修飾因子①"}
                                />
                            </ListItem>
                            {
                                mod1.map((m, i) => {
                                    return <ListItem key={`mod1_${i}`} button={true}
                                        className={classes.item}
                                        onClick={(e) => this.setState({ modifier1: m })} >
                                        <Radio color="primary"
                                            checked={this.state.modifier1 == m}
                                            onChange={(e) => {
                                                this.setState({ modifier1: m })
                                                this.changeAss()
                                            }} />
                                        <ListItemText primary={m}
                                            className={classes.itemText} />
                                    </ListItem>
                                })
                            }
                        </List>
                        <List className={classes.list}>
                            <ListItem className={classes.itemHeader}>
                                <ListItemText
                                    className={classes.headerText}
                                    primary={"修飾因子②"}
                                />
                            </ListItem>
                            {
                                mod2.map((m, i) => {
                                    return <ListItem key={`mod2_${i}`} button={true}
                                        className={classes.item}
                                        onClick={(e) => this.setState({ modifier2: m })} >
                                        <Radio color="primary"
                                            checked={this.state.modifier2 == m}
                                            onChange={(e) => {
                                                this.setState({ modifier2: m })
                                                this.changeAss();
                                            }} />
                                        <ListItemText primary={m}
                                            className={classes.itemText} />
                                    </ListItem>
                                })
                            }
                        </List>
                    </div>
            }
            <GoPrevNext
                onPrev={() => { this.changeAss(); this.goPrev() }}
                onNext={() => { this.changeAss(); this.goNext() }} />
        </div>
    }
}

export default Styles.withStyles(styles)(Modifier)