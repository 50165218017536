import * as firebase from 'firebase/app';
import "firebase/auth";
import 'firebase/firestore';
import 'firebase/storage';


let config: any = {
    apiKey: "AIzaSyDhH6gK1VmZHzqLkWLPj7T0pWziyJVnNc8",
    authDomain: "homis-nursee-stg.firebaseapp.com",
    databaseURL: "https://homis-nursee-stg.firebaseio.com",
    projectId: "homis-nursee-stg",
    storageBucket: "homis-nursee-stg.appspot.com",
    messagingSenderId: "182092196090",
    appId: "1:182092196090:web:de343065f189e2d10f8143"
};

if (process.env.REACT_APP_ENV == 'staging') {

}
else if (process.env.NODE_ENV == 'production') {
        config = {
            apiKey: "AIzaSyBMGOEjlXLCDR8KEwj6cFBZc9ks-2vwR68",
            authDomain: "homis-nursee-prod.firebaseapp.com",
            databaseURL: "https://homis-nursee-prod.firebaseio.com",
            projectId: "homis-nursee-prod",
            storageBucket: "homis-nursee-prod.appspot.com",
            messagingSenderId: "1024693169396",
            appId: "1:1024693169396:web:bed849587a41152da782a1",
            measurementId: "G-JDJLK7SEK5"
  };
}

export default class Firebase {
    private static _instance: Firebase;

    public readonly app: firebase.app.App;
    public readonly store: firebase.firestore.Firestore;
    public readonly storage: firebase.storage.Storage;
    public readonly auth: firebase.auth.Auth;
    public readonly providerGoogle = new firebase.auth.GoogleAuthProvider();

    private constructor() {
        this.app = firebase.initializeApp(config);
        this.store = this.app.firestore();
        this.auth = this.app.auth();
        this.storage = this.app.storage()
    }

    public static get instance(): Firebase {
        if (!this._instance) {
            this._instance = new Firebase();
        }
        return this._instance;
    }
}
