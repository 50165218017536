import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../../components/Page'
import { Link } from 'react-router-dom';
import * as Model from '../../models'
import * as HokanApi from '../../api/hokan'
import { StoreAccessor } from '../../util/StoreUtil';
import { Badge, Avatar, Tooltip, Modal, IconButton } from '@material-ui/core';
import { MentionsInput, Mention } from 'react-mentions'
import MessageFileStore from '../../firebase/MessageFileStore'
import { ImageUtil } from '../../util/ImageUtil'
import Button from '@material-ui/core/Button';
import * as Icons from '@material-ui/icons';
import Firebase from '../../firebase'


const styles = () => Styles.createStyles({
    mainContent: {
        //margin: "5px 255px 5px 10px",
        paddingLeft: "5px",
        paddingTop: "20px",
        paddingRight: "5px",
        //display: "table-cell",
        minWidth: "375px",
        boxSizing: "border-box",
        width: "100%",
        verticalAlign: "top"
    },
    mainActions: {
        marginTop: "10px"
    },
    rightNav: {
        position: "relative",
        height: "100%",
        margin: "0 10px 100px 5px",
        padding: "1px 0 55px 15px",
        display: "table-cell",
        boxSizing: "border-box",
        verticalAlign: "top",
        minWidth: "200px"
    },
    messageContent: {
        padding: "60px"
    },
    userThumbnail: {
        borderRadius: "40px",
        width: "40px",
        height: "40px"
    },
    userReplyThumbnail: {
        borderRadius: "30px",
        width: "30px",
        height: "30px"
    },
    feedContainer: {
        listStyleType: 'none',
        paddingInlineStart: "0px"
    },
    feedContainerItem: {
        borderBottom: "1px solid #CCC",
        marginBottom: "15px",
        padding: "7px"
    },
    feedInfoWrapper: {
        padding: "3px",
    },
    feedInfoWrapperEmg: {
        padding: "3px",
        backgroundColor: "#F009"
    },
    feedInfoWrapperTable: {
        display: "table",
        width: "100%",
        paddingBottom: "5px"
    },
    feedInfoWrapperCellIcon: {
        width: "40px",
        display: "table-cell",
        verticalAlign: "middle"
    },
    feedInfoWrapperCell: {
        width: "100%",
        paddingLeft: "5px",
        display: "table-cell",
        verticalAlign: "middle"
    },
    commentStatusNow: {
        marginLeft: 0,
        fontSize: "14px",
        color: "#525860",
        lineHeight: "21px",
        backgroundColor: "#EEE"
    },
    commentItem: {
        padding: "7px 7px 7px 14px",
        marginLeft: "0",
        fontSize: "14px",
        color: "#525860",
        lineHeight: "21px",
        marginBottom: "0px"
    },
    commentItemDt: {
        float: "left",
        overflow: "hidden"
    },
    feedComment: {
        resize: "none",
        overflow: "hidden",
        overflowWrap: "break-word",
        minHeight: "28px",
        height: "28px",
        fontSize: "12px",
        lineHeight: "17px",
        width: "100%",
        padding: "5px 0 0 5px",
        color: "#444",
        background: "white none",
        position: "relative",
        boxSizing: "border-box"
    },
    actionItems: {
        display: "flex",
        height: "45px",
        boxSizing: "border-box",
    },
    actionItem: {
        flexBasis: "140px",
        display: "flex",
        alignItems: "center"
    },
    actionForms: {
        width: "100%"
    },
    commentIconContainer: {
        display: "table-cell",
        width: "20px",
        paddingRight: "5px"
    },
    fileInner: {
        width: "107px"
    },
    btnFile: {
        display: "block",
        textAlign: "left",
        fontSize: "11px"
    },
    btnFileUpload: {
        textAlign: "left",
        padding: "2px 0px 3px",
        whiteSpace: "nowrap",
        outline: "none",
        cursor: "pointer",
        borderWidth: "initial",
        borderStyle: "none",
        borderColor: "initial",
        borderImage: "initial",
        background: "none",
        display: "flex",
        alignItems: "center"
    },
    btnFileImage: {
        width: "8px"
    },
    btnMentionImage: {
        width: "20px"
    },
    chatModeBox: {
        flex: 1,
        textAlign: "right",
        marginTop: "auto"
    },
    headerMenu: {
        listStyle: "none",
        display: "inline"
    },
    headerMenuItem: {
        display: "inline",
        float: "left",
        marginRight: "20px"
    },
    activityFaceL: {
        width: "35px",
        height: "35px",
        borderRadius: "35px",
        objectFit: "contain" as any,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        display: "inline-flex",
        cursor: "pointer",
        fontSize: "1rem"
    },
    activity_face: {
        width: "25px",
        height: "25px",
        borderRadius: "25px",
        objectFit: "contain" as any,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        display: "inline-flex",
        cursor: "pointer",
        fontSize: "1rem"
    },
    messageText: {
        marginBottom: "10px",
        whiteSpace: "pre-wrap",
        lineHeight: "21px"
    },
    commentText: {

    },
    postTimeContainer: {
        display: "inline-block",
        float: "right"
    },
    postTime: {
        fontSize: "11px",
    },
    userName: {
        fontStyle: "bold",
        fontSize: "15px",
        fontFamily: "Lucida Grande, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, Osaka, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
        paddingRight: "8px"
    },
    userRole: {
        fontSize: "11px",
        color: "#777"
    }
});

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    is_shinki: boolean;
    message_id: number;
    is_wc: boolean;
    wc_number: number;
    wc_name: string;
    wc_branch_id: number;
    onUpdate: () => void
}

interface LocalState {
    me: Model.User
    loading: boolean,
    branches: Model.Branch[],
    error: string | null,
    thread: Model.Talk,
    messages: any[],
    messageFiles: any[],
    inputMessage: string,
    isTask: boolean,
    emergencyFlg: boolean,
    taskDatetime: string,
    attachments: Model.Attachment[],
    reply_attachments: Model.Attachment[],
    reply: any,
    edit: any,
    simpleUsers: { id: number, display: string }[],
    currentPage: number,
    more: boolean,
    isImagePopupOpen: boolean,
    imageSrcDisplayedInPopup: string | undefined
}

// @inject()
// @observer
class Message extends React.Component<Props, LocalState> {
    displayName = "Message"
    fileInputRef: React.RefObject<HTMLInputElement>;
    constructor(props: Props) {
        super(props)
        this.state = {
            me: new Model.User(),
            loading: false,
            branches: [],
            thread: new Model.Talk(),
            error: null,
            messages: [],
            messageFiles: [],
            inputMessage: "",
            isTask: false,
            emergencyFlg: false,
            taskDatetime: "",
            attachments: [],
            reply_attachments: [],
            reply: {
                edit: 0,
                input: "",
                isTask: false,
                taskDatetime: "",
            },
            edit: {
                id: 0,
                input: "",
                isTask: false,
                taskDatetime: ""
            },
            simpleUsers: [],
            currentPage: 1,
            more: true,
            isImagePopupOpen: false,
            imageSrcDisplayedInPopup: undefined
        }
        this.fileInputRef = React.createRef();
    }

    public componentDidMount = () => {
        this.setState({ loading: true })

        HokanApi.me().then(x => {
            const me = x.data
            this.setState({ me: me })

            this.update_user_latest_view()
        })

        HokanApi.getUsersSimple().then(x => {
            let sus = x.data.map(x => {
                return {
                    id: x.id,
                    display: x.name
                }
            })
            if(this.props.is_wc) {

                sus.unshift({
                    id: -2,
                    display: "利用者の事業所"
                })
                sus.unshift({
                    id: -1,
                    display: "全員"
                })
            }
            else {
                sus.unshift({
                    id: -3,
                    display: "参加者全員"
                })
            }

            this.setState({ simpleUsers: sus })
        }).catch(err => {
            console.log(err)
        })

        if (this.props.is_wc) {
            HokanApi.message.get_wc(this.props.wc_number)
            .then(x => {
                this.setState({ thread: x.data, loading: false })
                HokanApi.message.message_list_wc(this.props.wc_number, this.state.currentPage)
                .then( x => {
                    let messageFiles = x.data.map( (msg) => {
                        let files:any[] = msg.attachments.map( (file) => {
                            return {name: file.name, content_type:file.content_type, loaded:false, loading:false, url: "" }
                        })
                        return { id:msg.id, files:files }
                    })
                    // reply's files
                    x.data.forEach( (msg) => {
                        msg.reply_list.forEach( (rep) => {
                            let files: any[] = rep.attachments.map((file) => {
                                return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                            })
                            messageFiles.push({id:rep.id, files:files})
                        })
                    })
                    this.setState({ messages: x.data, messageFiles: messageFiles, loading: false })

                    this.state.messageFiles.forEach( (messageFile, idx1) => {
                        messageFile.files.forEach( (file, idx2) => {
                            this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                        })
                    })
                })
                .catch( err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
            })
            .catch(err => {
                let d = new Model.CreateWcTalk()
                d.name = this.props.wc_name
                d.wc_number = this.props.wc_number
                d.branch_id = this.props.wc_branch_id
                HokanApi.message.create_wc(d)
                .then( x => {
                    this.setState({ thread: x.data, loading: false })
                    HokanApi.message.message_list_wc(this.props.wc_number, this.state.currentPage)
                        .then(x => {
                            let messageFiles = x.data.map((msg) => {
                                let files: any[] = msg.attachments.map((file) => {
                                    return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                })
                                return { id: msg.id, files: files }
                            })
                            // reply's files
                            x.data.forEach( (msg) => {
                                msg.reply_list.forEach( (rep) => {
                                    let files: any[] = rep.attachments.map((file) => {
                                        return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                    })
                                    messageFiles.push({id:rep.id, files:files})
                                })
                            })
                            this.setState({ messages: x.data, messageFiles: messageFiles, loading: false })

                            this.state.messageFiles.forEach((messageFile, idx1) => {
                                messageFile.files.forEach((file, idx2) => {
                                    this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                                })
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ loading: false })
                        })
                })
                .catch(err => {
                    let d = new Model.CreateWcTalk()
                    d.name = this.props.wc_name
                    d.wc_number = this.props.wc_number
                    HokanApi.message.create_wc(d)
                        .then(x => {
                            this.setState({ thread: x.data, loading: false })
                            HokanApi.message.message_list_wc(this.props.wc_number, this.state.currentPage)
                                .then(x => {
                                    let messageFiles = x.data.map((msg) => {
                                        let files: any[] = msg.attachments.map((file) => {
                                            return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                        })
                                        return { id: msg.id, files: files }
                                    })
                                    // reply's files
                                    x.data.forEach( (msg) => {
                                        msg.reply_list.forEach( (rep) => {
                                            let files: any[] = rep.attachments.map((file) => {
                                                return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                            })
                                            messageFiles.push({id:rep.id, files:files})
                                        })
                                    })
                                    this.setState({ messages: x.data, messageFiles: messageFiles, loading: false })

                                    this.state.messageFiles.forEach((messageFile, idx1) => {
                                        messageFile.files.forEach((file, idx2) => {
                                            this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                                        })
                                    })
                                })
                                .catch(err => {
                                    console.log(err)
                                    this.setState({ loading: false })
                                })
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ loading: false })
                        })
                })
            })
        }
        else {
            HokanApi.message.get(this.getId())
                .then(x => {
                    this.setState({ thread: x.data, loading: false })
                    HokanApi.message.message_list(this.getId(), this.state.currentPage)
                        .then(x => {
                            let messageFiles = x.data.map((msg) => {
                                let files: any[] = msg.attachments.map((file) => {
                                    return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                })
                                return { id: msg.id, files: files }
                            })
                            // reply's files
                            x.data.forEach( (msg) => {
                                msg.reply_list.forEach( (rep) => {
                                    let files: any[] = rep.attachments.map((file) => {
                                        return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                    })
                                    messageFiles.push({id:rep.id, files:files})
                                })
                            })
                            this.setState({ messages: x.data, messageFiles: messageFiles, loading: false })

                            this.state.messageFiles.forEach((messageFile, idx1) => {
                                messageFile.files.forEach((file, idx2) => {
                                    this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                                })
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ loading: false })
                        })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
    }

    private getId() {
        return this.props.message_id
    }

    private setText() {

    }

    private handleChange(event) {
        this.setState({ inputMessage: event.target.value });
    }

    private onPostButtonClick() {
        if(this.state.inputMessage === "" && this.state.attachments.length == 0) {
            return;
        }
        console.dir(this.state);
        const m = new Model.CreateMessage()
        m.content = this.state.inputMessage
        m.is_task = this.state.isTask
        m.task_date = this.state.taskDatetime
        m.attachments = this.state.attachments
        m.emergency_flg = this.state.emergencyFlg
        this.setState({ loading: true })


        if (this.props.is_wc) {
            this.update_patient_last_update();
            HokanApi.message.post_wc(this.props.wc_number, m)
                .then(x => {
                    let id = x.data.talk_id
                    HokanApi.message.message_list_wc(this.props.wc_number, this.state.currentPage)
                        .then(x => {
                            let messageFiles = x.data.map((msg) => {
                                let files: any[] = msg.attachments.map((file) => {
                                    return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                })
                                return { id: msg.id, files: files }
                            })
                            // reply's files
                            x.data.forEach( (msg) => {
                                msg.reply_list.forEach( (rep) => {
                                    let files: any[] = rep.attachments.map((file) => {
                                        return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                    })
                                    messageFiles.push({id:rep.id, files:files})
                                })
                            })
                            this.setState({
                                inputMessage: "",
                                isTask: false,
                                emergencyFlg: false,
                                taskDatetime: "",
                                attachments: [],
                                messages: x.data,
                                messageFiles: messageFiles,
                                loading: false
                            })

                            this.state.messageFiles.forEach((messageFile, idx1) => {
                                messageFile.files.forEach((file, idx2) => {
                                    this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                                })
                            })
                            this.props.onUpdate()
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ loading: false })
                        })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
        else {
            this.update_talk_last_update();
            HokanApi.message.post(this.getId(), m)
                .then(x => {
                    let id = x.data.talk_id
                    HokanApi.message.message_list(id, this.state.currentPage)
                        .then(x => {
                            let messageFiles = x.data.map((msg) => {
                                let files: any[] = msg.attachments.map((file) => {
                                    return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                })
                                return { id: msg.id, files: files }
                            })
                            // reply's files
                            x.data.forEach( (msg) => {
                                msg.reply_list.forEach( (rep) => {
                                    let files: any[] = rep.attachments.map((file) => {
                                        return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                    })
                                    messageFiles.push({id:rep.id, files:files})
                                })
                            })
                            this.setState({
                                inputMessage: "",
                                isTask: false,
                                emergencyFlg: false,
                                taskDatetime: "",
                                attachments: [],
                                messages: x.data,
                                messageFiles: messageFiles,
                                loading: false
                            })

                            this.state.messageFiles.forEach((messageFile, idx1) => {
                                messageFile.files.forEach((file, idx2) => {
                                    this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                                })
                            })
                            this.props.onUpdate()
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ loading: false })
                        })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })

        }
    }

    private createReply(message_id: Number) {
        let reply = {
            edit: message_id,
            input: ""
        }
        this.setState({ reply: reply })
    }
    private createEdit(message_id: Number, input: String) {
        let edit = {
            id: message_id,
            input: input
        }
        this.setState({ edit: edit })

    }
    private handleLoading() {
        return this.state.loading ? { display: "block" } : { display: "none" };
    }
    private replyStyle(message_id: Number) {
        return this.state.reply.edit != message_id ? { display: "none" } : { display: "block" }
    }

    private editStyle(message_id: Number) {
        return this.state.edit.id != message_id ? { display: "none" } : { display: "block" }
    }
    private editContentStyle(message_id: Number) {
        return this.state.edit.id == message_id ? { display: "none" } : { display: "block" }
    }
    private editReplyContentStyle(message_id: Number): React.CSSProperties {
        return this.state.edit.id == message_id ? { display: "none", padding:"0px", overflowWrap: "break-word" } : { display: "block" ,padding: "0 0px 0px 16px", overflowWrap: "break-word" }
    }
    private handleChangeReply(event) {
        let { reply } = this.state;
        let newReply = {
            edit: reply.edit,
            input: event.target.value,
            isTask: reply.isTask,
            taskDatetime: reply.taskDatetime
        }
        this.setState({ reply: newReply });
    }
    private handleChangeEdit(event) {
        let { edit } = this.state;
        let newEdit = {
            id: edit.id,
            input: event.target.value,
        }
        this.setState({ edit: newEdit });
    }
    private handleChangeReplyTask(event) {
        let { reply } = this.state;
        let newReply = {
            edit: reply.edit,
            input: reply.input,
            isTask: event.target.checked,
            taskDatetime: reply.taskDatetime
        }
        this.setState({ reply: newReply });
    }
    private handleChangeReplyTaskDatetime(event) {
        let { reply } = this.state;
        let newReply = {
            edit: reply.edit,
            input: reply.input,
            isTask: reply.isTask,
            taskDatetime: event.target.value
        }
        this.setState({ reply: newReply });
    }
    private handleReplyButtonStatus(message_id: Number) {
        let isTarget = this.state.reply.edit != message_id
        let notEmpty = this.state.reply.input != ""
        return isTarget && notEmpty
    }
    private onReplyPostButtonClick() {

        if(this.state.reply.input === "" && this.state.reply_attachments.length == 0) {
            return;
        }
        const m = new Model.CreateMessage()
        m.reply = this.state.reply.edit
        m.content = this.state.reply.input
        m.is_task = false //this.state.reply.isTask
        m.attachments = this.state.reply_attachments
        m.task_datetime = this.state.reply.taskDatetime


        if (this.props.is_wc) {
            this.update_patient_last_update();
            HokanApi.message.post_wc(this.props.wc_number, m)
                .then(x => {
                    let id = x.data.talk_id
                    HokanApi.message.message_list(id, this.state.currentPage)
                        .then(x => {
                            let messageFiles = x.data.map((msg) => {
                                let files: any[] = msg.attachments.map((file) => {
                                    return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                })
                                return { id: msg.id, files: files }
                            })
                            // reply's files
                            x.data.forEach( (msg) => {
                                msg.reply_list.forEach( (rep) => {
                                    let files: any[] = rep.attachments.map((file) => {
                                        return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                    })
                                    messageFiles.push({id:rep.id, files:files})
                                })
                            })
                            let newReply = {
                                edit: 0,
                                input: ""
                            }
                            this.setState({ messages: x.data, messageFiles: messageFiles, reply: newReply, loading: false })

                            this.state.messageFiles.forEach((messageFile, idx1) => {
                                messageFile.files.forEach((file, idx2) => {
                                    this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                                })
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ loading: false })
                        })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
        else {
            this.update_talk_last_update();
            HokanApi.message.post(this.getId(), m)
                .then(x => {
                    let id = x.data.talk_id
                    HokanApi.message.message_list(id, this.state.currentPage)
                        .then(x => {
                            let messageFiles = x.data.map((msg) => {
                                let files: any[] = msg.attachments.map((file) => {
                                    return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                })
                                return { id: msg.id, files: files }
                            })
                            // reply's files
                            x.data.forEach( (msg) => {
                                msg.reply_list.forEach( (rep) => {
                                    let files: any[] = rep.attachments.map((file) => {
                                        return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                    })
                                    messageFiles.push({id:rep.id, files:files})
                                })
                            })
                            let newReply = {
                                edit: 0,
                                input: ""
                            }
                            this.setState({ messages: x.data, messageFiles: messageFiles, reply: newReply, loading: false })

                            this.state.messageFiles.forEach((messageFile, idx1) => {
                                messageFile.files.forEach((file, idx2) => {
                                    this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                                })
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ loading: false })
                        })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
    }

    private read_more() {
        let newReply = { edit: 0, input: "" }
        if (this.props.is_wc) {
            HokanApi.message.message_list_wc(this.props.wc_number, this.state.currentPage + 1)
                .then(x => {
                    if (x.data.length > 0) {
                        let messageFiles = x.data.map((msg) => {
                            let files: any[] = msg.attachments.map((file) => {
                                return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                            })
                            return { id: msg.id, files: files }
                        })
                        // reply's files
                        x.data.forEach( (msg) => {
                            msg.reply_list.forEach( (rep) => {
                                let files: any[] = rep.attachments.map((file) => {
                                    return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                })
                                messageFiles.push({id:rep.id, files:files})
                            })
                        })
                        this.setState({
                            inputMessage: "",
                            isTask: false,
                            taskDatetime: "",
                            attachments: [],
                            messages: this.state.messages.concat(x.data),
                            reply: newReply,
                            messageFiles: this.state.messageFiles.concat(messageFiles),
                            currentPage: this.state.currentPage + 1,
                            loading: false
                        })

                        this.state.messageFiles.forEach((messageFile, idx1) => {
                            messageFile.files.forEach((file, idx2) => {
                                this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                            })
                        })
                    }
                    else {
                        this.setState({ more: false })
                    }

                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
        else {
            HokanApi.message.message_list(this.getId(), this.state.currentPage + 1)
                .then(x => {
                    if (x.data.length > 0) {
                        let messageFiles = x.data.map((msg) => {
                            let files: any[] = msg.attachments.map((file) => {
                                return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                            })
                            return { id: msg.id, files: files }
                        })
                        // reply's files
                        x.data.forEach( (msg) => {
                            msg.reply_list.forEach( (rep) => {
                                let files: any[] = rep.attachments.map((file) => {
                                    return { name: file.name, content_type: file.content_type, loaded: false, loading: false, url: "" }
                                })
                                messageFiles.push({id:rep.id, files:files})
                            })
                        })
                        this.setState({
                            inputMessage: "",
                            isTask: false,
                            taskDatetime: "",
                            attachments: [],
                            messages: this.state.messages.concat(x.data),
                            reply: newReply,
                            messageFiles: this.state.messageFiles.concat(messageFiles),
                            currentPage: this.state.currentPage + 1,
                            loading: false
                        })

                        this.state.messageFiles.forEach((messageFile, idx1) => {
                            messageFile.files.forEach((file, idx2) => {
                                this.updateFileUrl(messageFile.id, file.name, idx1, idx2)
                            })
                        })
                    }
                    else {
                        this.setState({ more: false })
                    }

                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        }
    }

    create_comment_dom(message: string, uidStr: string, emergency:boolean) {
        let found = false;
        const r = message.replace(/@\[(.*?)\]\(user:([\-0-9]*?)\)/g, (str, ...args: string[]) => {
            // 自分のIDか全員（id:-1）か参加者全員(id:-3)
            if (uidStr == args[1] || args[1] == "-1" || args[1] == "-3") found = true
            // TODO: 利用者の事業所(id:-2)かつ、利用者の事業所が自分の事業所と等しい
            if (args[1] == "-2" && this.props.wc_branch_id == this.state.me.branch_id) found = true
            return `<span style="background-color:#d2e6ff;color:#001781;">@${args[0]} </span>`
        })
        .replace(/\\n/g, "\n")

        if (emergency) {
            return <div style={{ overflowWrap:"anywhere", backgroundColor: "#ffdcdc" }} dangerouslySetInnerHTML={{ __html:this.create_hyper_link(r) }} />
        }
        else{
            return <div style={{ overflowWrap:"anywhere"}} dangerouslySetInnerHTML={{__html:this.create_hyper_link(r)}} />
        }
    }

    create_hyper_link(message: string) {
        const r = message.replace(/https?:\/\/[\w!\?/\+\-_~=;\.,\*&@#\$%\(\)'\[\]]+/g, (str, ...args: string[]) => {
            return `<a href=${str} target="_blank">${str}</a>`
        })
        return r
    }

    fmt_datetime(datetime: string) {
        let d = new Date(datetime);
        return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日 ${d.getHours()}:${d.getMinutes()}`;
    }


    update_patient_last_update() {
        const data = {
            patient_id: this.props.wc_number,
            name: this.props.wc_name,
            updated_at: new Date().toISOString(),
            updated_by_name: this.state.me.name,
            updated_by_id: this.state.me.id
        }

        Firebase.instance.store.collection(`patient`).doc(`${this.props.wc_number}`).set(
            data
        ).catch( err => {
            console.log(err)
        })
    }

    update_talk_last_update() {
        const data = {
            updated_at: new Date().toISOString(),
            updated_by_name: this.state.me.name,
            updated_by_id: this.state.me.id
        }

        Firebase.instance.store.collection(`talk`).doc(`${this.getId()}`).set(
            data
        ).catch( err => {
            console.log(err)
        })
    }

    update_user_latest_view() {
        const data = {
            latest_access: new Date().toISOString()
        }

        if(this.props.is_wc) {
            if(this.state.me && this.state.me.branch) {
                Firebase.instance.store.collection(`company/${this.state.me.branch.company_id}/user/${this.state.me.id}/patient`).doc(`${this.props.wc_number}`).set(
                    data
                ).catch( err =>
                    console.log(err)
                )
            }
        }
        else {
            if(this.state.me && this.state.me.branch) {
                Firebase.instance.store.collection(`company/${this.state.me.branch.company_id}/user/${this.state.me.id}/talk`).doc(`${this.getId()}`).set(
                    data
                ).catch( err =>
                    console.log(err)
                )
            }
        }

    }

    private setAvatarImage(file: File, is_reply: Boolean = false) {
        const me = this.state.me
        this.setState({loading:true})
        if (me && me.branch && file) {
            var S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
            var N = 32
            var fn = Array.from(Array(N)).map(() => S[Math.floor(Math.random() * S.length)]).join('')
            MessageFileStore.put(me.branch.company_id, fn, file).then(x => {
                //this.loadAvatar();
                // cidとファイル名が取れればパスを取れる
                if(is_reply) {
                    var attachments = this.state.reply_attachments;
                    let a = new Model.Attachment()
                    a.name = x.metadata.name
                    a.content_type = x.metadata.contentType!
                    a.src_name = file.name
                    attachments.push(a)
                    this.setState({ reply_attachments: attachments, loading:false})
                }
                else{
                    var attachments = this.state.attachments;
                    let a = new Model.Attachment()
                    a.name = x.metadata.name
                    a.content_type = x.metadata.contentType!
                    a.src_name = file.name
                    attachments.push(a)
                    this.setState({ attachments: attachments, loading:false })
                }
                if(this.fileInputRef.current != null) {
                    this.fileInputRef.current.value = "";
                }
            }).catch(err => {
                console.log(err)
                this.setState({loading:false})
            })
        }
        else {
            console.log(me)
            console.log(me.branch)
            console.log(file)
        }
    }

    private updateFileUrl(messageId: number, name: string, index1: number, index2: number) {
        if (!this.state.me) {
            return
        }
        if (!this.state.me.branch) {
            return
        }
        let messageFiles = this.state.messageFiles
        let file = messageFiles[index1].files[index2]
        if (file && !file.loaded && !file.loading) {
            if(file.name.startsWith("http")) {
                messageFiles[index1].files[index2].url = file.name
                messageFiles[index1].files[index2].loaded = true
                this.setState({ messageFiles: messageFiles })
            }
            else {
                MessageFileStore.getUrl(this.state.me.branch!.company_id, file.name).then(url => {
                    messageFiles[index1].files[index2].url = url
                    messageFiles[index1].files[index2].loaded = true
                    this.setState({ messageFiles: messageFiles })
                })
            }
        }
    }

    delete_message(talkId:number, messageId:number) {
        if ( confirm("本当に削除してもよいですか？") ) {
            HokanApi.message.delete_message(talkId, messageId)
            .then(x => {
                let srcMessages = this.state.messages
                srcMessages.forEach( (msg, key) => {
                    if(msg.id == messageId) {
                        srcMessages.splice(key, 1)
                    }
                    else {
                        msg.reply_list.forEach( (rep, key2) => {
                            if(rep.id == messageId) {
                                srcMessages[key].reply_list.splice(key2, 1)

                            }
                        })
                    }
                })
                //let newMessages = srcMessages.filter( x => { return x.id != messageId})
                this.setState( {messages: srcMessages} )
            })
        }
    }

    update_message() {
        const m = new Model.UpdateMessage()
        m.content = this.state.edit.input

        HokanApi.message.update_message(this.state.thread.id, this.state.edit.id, m)
            .then(x => {
                let msgs = this.state.messages
                msgs.forEach( (msg, key) => {
                    if(msg.id == this.state.edit.id) {
                        msgs[key].content = this.state.edit.input
                    }
                    else {
                        msg.reply_list.forEach( (rep, key2) => {
                            if(rep.id == this.state.edit.id) {
                                msg.reply_list[key2].content = this.state.edit.input
                            }
                        })
                    }
                })
                let initEdit =  {
                    id: 0,
                    input: "",
                    isTask: false,
                    taskDatetime: ""
                }
                this.setState({messages: msgs, edit:initEdit})
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    delete_thread() {
        if (confirm("本当に削除してもよいですか？")) {
            var p = new Model.UpdateTalk()
            p.is_visible = false
            HokanApi.message.update(this.state.thread.id, p)
                .then(x => {
                    alert("削除しました")
                    this.props.history.push("/patients#thread")
                })
                .catch(err => {
                    alert("削除に失敗しました。(自分が作成したスレッドのみ削除できます)")
                })
        }
    }

    is_user_thred() {
        return this.state.thread.user_id !== undefined && this.state.thread.user_id !== null;
    }

    is_self_thread() {
        return this.state.thread.wc_user_id == this.state.me.id;
    }

    private getFileUrl(messageId: number, name: string) {
        let files = this.state.messageFiles
        if (files) {
            let target = files.find(f => f.id == messageId)
            if (target) {
                let targetMessageFiles = target.files
                if (targetMessageFiles) {
                    let file = targetMessageFiles.find(f => f.name == name)
                    return file.url
                }
                else {
                    return ""
                }
            }
            else {
                return ""
            }
        }
        else {
            return ""
        }
    }

    public render() {
        const { thread, messages, attachments, reply, reply_attachments } = this.state
        return <div className={this.props.classes.mainContent}>

            {!this.props.is_wc  && (
                <div>
                    <p style={{fontSize:'26px'}}>{ thread.name }</p>
                    <hr />
                </div>
            )}
            {/* 利用者スレッド以外は削除できる */}
            {!this.props.is_wc && this.is_user_thred() && this.is_self_thread() ? (
            <div style={{display:'flex', justifyContent:'flex-end', cursor:'grab', marginBottom:'20px' }}>
                <div onClick={(el) => this.delete_thread()} style={{ display:"flex", alignItems:"center" }}>
                    <Icons.Delete />このスレッドを削除する
                </div>
            </div>
            ): <></>}

            {/* 新規投稿エリア */}
            <div>
                <MentionsInput
                    value={this.state.inputMessage}
                    onChange={(event, newValue, newPlainTextValue, mentions) => {
                        this.setState({ inputMessage: newValue })
                    }}
                    placeholder={"@でメンションができます"}
                    allowSpaceInQuery={true}
                    singleLine={false}
                    style={{height:"45px"}}
                    displayTransform={(id, display) => `@${display}`} >
                    <Mention
                        markup="@[__display__](user:__id__)"
                        trigger="@"
                        data={this.state.simpleUsers}
                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                            <div>{highlightedDisplay}</div>
                        )}
                        onAdd={e => { }}
                        style={{
                            backgroundColor: '#cee4e5',
                        }}
                    />
                    <Mention
                        markup="@[__display__](user:__id__)"
                        trigger="＠"
                        data={this.state.simpleUsers}
                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                            <div>{highlightedDisplay}</div>
                        )}
                        onAdd={e => { }}
                        style={{
                            backgroundColor: '#cee4e5',
                        }}
                    />
                </MentionsInput>
                <div className="task_attachment_container"></div>
                <div className="user_link_preview_container"></div>
                <div className={this.props.classes.mainActions} style={this.state.isTask ? {} : { display: "none" }}>
                    有効期限
                    <input type="date" onChange={event => { this.setState({ taskDatetime: event.target.value }) }} />
                </div>
            </div>
            <div className={this.props.classes.actionItems}>
                { !this.props.is_shinki ? (
                    <div className={this.props.classes.actionItem}>
                        <input type="checkbox" checked={this.state.isTask} onChange={event => { this.setState({ isTask: event.target.checked }) }} />
                        タスク登録
                    </div>
                ) : null }
                <div className={this.props.classes.actionItem}>
                    <input type="checkbox" checked={this.state.emergencyFlg} onChange={event => { this.setState({ emergencyFlg: event.target.checked }) }} />
                    緊急
                </div>
                <div className={this.props.classes.actionItem}>
                    <button className={this.props.classes.btnFileUpload} data-input-file="upfile" data-width="120" data-height="27">
                        <span className="img_cover">
                            <Icons.AttachFile style={{height:"20px", marginTop:"3px"}} />
                        </span>
                        <span className="txt_cover" onClick={(e) => {
                            const el = document.getElementById("messageFile") as any
                            if (el) el.click()
                        }}>ファイル添付</span>
                        <input type="file" id={`messageFile`} ref={this.fileInputRef}
                            hidden={true}
                            onChange={(e) => {
                                console.log(e)
                                if (e.target.files && e.target.files.length > 0) {
                                    this.setAvatarImage(e.target.files[0])
                                }
                            }} />
                    </button>
                    {attachments.map((file) => {
                        return <div>
                            {file.src_name}
                        </div>
                    })}
                </div>
                <div className={this.props.classes.chatModeBox} >
                    <div>
                        <Button color="default" variant="outlined" style={{ margin: "5px" }}
                            onClick={(e) => this.onPostButtonClick()} disabled={this.state.loading}>
                                送信
                        </Button>
                    </div>
                </div>
            </div>
            <div style={this.handleLoading()}>
                <img src="https://d120ogmjvytyob.cloudfront.net/versions/20151105/images/ajax-loader_001.gif" /><span>Loading...</span>
            </div>
            <div>
                <ul className={this.props.classes.feedContainer}>
                    {/* メッセージループ */}
                    {messages.map((data) => {
                        return <li className={this.props.classes.feedContainerItem} key={data.id}>
                            <div className={ this.props.classes.feedInfoWrapper } >
                                {/*  投稿者情報 */}
                                <div className={this.props.classes.feedInfoWrapperTable}>
                                    <div className={this.props.classes.feedInfoWrapperCellIcon}>
                                        <Avatar
                                            alt="avatar" src={undefined}
                                            className={this.props.classes.activityFaceL}
                                            style={{
                                                color: '#fff',
                                                backgroundColor: `#hsl(${data ? data.wc_user_id * 130 : 0}, 50%, 80%)`,
                                            }}
                                        >{data ? data.user_name.substr(0, 2) : null}</Avatar>
                                    </div>
                                    <div className="feed_info_wrapper_cell">
                                        <div className="user_info">
                                            <div className={this.props.classes.userName} style={{display:"flex", padding:"0px"}}>
                                                <div style={{flex:1}}>
                                                    {data.user_name}
                                                </div>

                                                <div style={{flex:1, textAlign:"right", color:"red", fontFamily:"bold"}}>
                                                </div>
                                            </div>
                                            <span className={this.props.classes.userRole}>
                                                {data.user_role}
                                            </span>
                                            <div className={this.props.classes.postTimeContainer}>
                                                <span className={this.props.classes.postTime}>
                                                    <abbr>{this.fmt_datetime(data.created_at)}</abbr>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className={this.props.classes.feedInfoWrapperTable}>
                                    <div className="feed_info_wrapper_cell feed_info_wrapper_cell_message_text">
                                        {/*  投稿内容 */}
                                        <div className={this.props.classes.messageText} style={this.editContentStyle(data.id)} >
                                            { data.emergency_flg ?
                                                <span style={{color:"red", fontFamily:"bold"}}>
                                                    緊急
                                                </span>
                                            : null }
                                            {this.create_comment_dom(data.content, this.state.me.id.toString(), data.emergency_flg)}
                                        </div>

                                        <table cellPadding="0" cellSpacing="0" className="gallery_collection">
                                            <tbody>
                                                <tr>
                                                    {data.attachments.map((file) => {
                                                        if (file.content_type.startsWith("image/")) {
							    const imageSrc = this.getFileUrl(data.id, file.name)
                                                            return <td key={file.name} >
                                                                <button
                                                                    type="button"
                                                                    onClick={() => this.setState({ isImagePopupOpen: true, imageSrcDisplayedInPopup: imageSrc })}
                                                                    style={{padding: 0, border: "none", outline: "none", background: "none"}}
                                                                >
                                                                    <img src={imageSrc} style={{ width: "100%", maxWidth: "200px" }} alt="" />
                                                                </button>
                                                            </td>
                                                        }
                                                        else {
                                                            return <td key={file.name}>
                                                                <a href={this.getFileUrl(data.id, file.name)} target='_blank'>添付ファイル</a>
                                                            </td>
                                                        }
                                                    })}
                                                </tr>
                                            </tbody>
                                        </table>

                                        { /* 編集用テキストボックス */ }
                                        <div className={this.props.classes.actionForm} style={this.editStyle(data.id)} >
                                            <div className="post_comment_container">
                                                <div className="mention_layer"
                                                    style={{ display: "none", overflow: "hidden", width: "1050px", height: "28px" }}>
                                                </div>
                                                <MentionsInput
                                                    value={this.state.edit.input}
                                                    onChange={(event, newValue, newPlainTextValue, mentions) => {
                                                        this.handleChangeEdit(event)
                                                    }}
                                                    placeholder={"コメント"}
                                                    allowSpaceInQuery={true}
                                                    displayTransform={(id, display) => `@${display}`}
                                                    rows="3"
                                                    style={{ height:"45px", position: "relative" }}
                                                >
                                                    <Mention
                                                        markup="@[__display__](user:__id__)"
                                                        trigger="@"
                                                        data={this.state.simpleUsers}
                                                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                                            <div>{highlightedDisplay}</div>
                                                        )}
                                                        onAdd={e => { }}
                                                        style={{
                                                            backgroundColor: '#cee4e5',
                                                        }}
                                                    />
                                                </MentionsInput>
                                                <div className="caret_position_layer"
                                                    style={{ position: "absolute", marginLeft: "-9999px", visibility: "hidden", width: "0px" }}>
                                                </div>
                                                <div className="member_list mention"
                                                    id="mention_member_list_message"></div>
                                                <div className="task_attachment_container"></div>
                                            </div>

                                            <div className="comment_actions">
                                                <div className={this.props.classes.actionItems}>
                                                    <div className={this.props.classes.actionItem}>
                                                    </div>

                                                    <div className={this.props.classes.chatModeBox} >
                                                        <Button color="default" variant="outlined" style={{ margin: "5px" }}
                                                            onClick={(e) => this.update_message()} disabled={this.state.loading}>
                                                                送信
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={this.props.classes.commentStatusNow} >
                                                {/* コメント一覧 */}
                                                {data.reply_list.map((reply) => {
                                                    return <div style={{ "borderBottom": "1px solid #ccc" }} >
                                                        <dl className={this.props.classes.commentItem} >
                                                            {/* コメントループ */}
                                                            <dt className={this.props.classes.commentItemDt}>
                                                                <Avatar
                                                                    alt="avatar" src={undefined}
                                                                    className={this.props.classes.activity_face}
                                                                    style={{
                                                                        color: '#fff',
                                                                        backgroundColor: `#hsl(${data ? data.wc_user_id * 130 : 0}, 50%, 80%)`,
                                                                    }}
                                                                >{data ? data.user_name.substr(0, 2) : null}</Avatar>
                                                            </dt>
                                                            <dd>
                                                                <div className="comment_status_txt">
                                                                    <div className="comment_header">
                                                                        <span className={this.props.classes.userName}>{reply.user_name}</span>
                                                                        <span className={this.props.classes.userRole}>{reply.user_role}</span>
                                                                        <div className={this.props.classes.postTimeContainer}>
                                                                            <span className={this.props.classes.postTime}>
                                                                                <abbr>{this.fmt_datetime(reply.created_at)}</abbr>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <div className={this.props.classes.actionForm} style={this.editStyle(reply.id)} >
                                                            <div className="post_comment_container">
                                                                <div className="mention_layer"
                                                                    style={{ display: "none", overflow: "hidden", width: "1050px", height: "28px" }}>
                                                                </div>
                                                                <MentionsInput
                                                                    value={this.state.edit.input}
                                                                    onChange={(event, newValue, newPlainTextValue, mentions) => {
                                                                        this.handleChangeEdit(event)
                                                                    }}
                                                                    placeholder={"コメント"}
                                                                    allowSpaceInQuery={true}
                                                                    displayTransform={(id, display) => `@${display}`}
                                                                    rows="3"
                                                                    style={{ height:"45px", position: "relative" }}
                                                                >
                                                                    <Mention
                                                                        markup="@[__display__](user:__id__)"
                                                                        trigger="@"
                                                                        data={this.state.simpleUsers}
                                                                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                                                            <div>{highlightedDisplay}</div>
                                                                        )}
                                                                        onAdd={e => { }}
                                                                        style={{
                                                                            backgroundColor: '#cee4e5',
                                                                        }}
                                                                    />
                                                                </MentionsInput>
                                                                <div className="caret_position_layer"
                                                                    style={{ position: "absolute", marginLeft: "-9999px", visibility: "hidden", width: "0px" }}>
                                                                </div>
                                                                <div className="member_list mention"
                                                                    id="mention_member_list_message"></div>
                                                                <div className="task_attachment_container"></div>
                                                            </div>

                                                            <div className="comment_actions">
                                                                <div className={this.props.classes.actionItems}>
                                                                    <div className={this.props.classes.actionItem}>
                                                                    </div>

                                                                    <div className={this.props.classes.chatModeBox} >
                                                                        <Button color="default" variant="outlined" style={{ margin: "5px" }}
                                                                            onClick={(e) => this.update_message()} disabled={this.state.loading}>
                                                                            送信
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cs_message" style={this.editReplyContentStyle(reply.id)}>
                                                            {this.create_comment_dom(reply.content, this.state.me.id.toString(), false)}
                                                        </div>
                                                        <table cellPadding="0" cellSpacing="0" className="gallery_collection">
                                                            <tbody>
                                                                <tr>
                                                                    {reply.attachments.map((file) => {
                                                                        if (file.content_type.startsWith("image/")) {
                                                                            return <td key={file.name} >
                                                                                <img src={this.getFileUrl(reply.id, file.name)} style={{ width: "100%", maxWidth: "200px" }} alt="" />
                                                                            </td>
                                                                        }
                                                                        else {
                                                                            return <td key={file.name}>
                                                                                <a href={this.getFileUrl(reply.id, file.name)} target='_blank'>添付ファイル</a>
                                                                            </td>
                                                                        }
                                                                    })}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div style={ { display:"flex",height:"30px",justifyContent:"center",alignItems:"center" }}>
                                                            <div style={{flex:1, display:"flex", alignItems:"center" }}>
                                                                    　　　
                                                            </div>
                                                            {
                                                                // TODO: 編集はUI考えてから
                                                                this.state.me.id == reply.wc_user_id ? <div onClick={(el) => this.createEdit(reply.id, reply.content)} style={{flexBasis:"30px", display:"flex", alignItems:"center", justifyContent:"right"}}>
                                                                    <Icons.EditOutlined />
                                                                </div> : ""
                                                            }
                                                            {
                                                                this.state.me.id == reply.wc_user_id ? <div  style={{flexBasis:"30px", display:"flex", alignItems:"center", justifyContent:"right" }} onClick={ (el) => this.delete_message(reply.talk_id, reply.id) }>
                                                                    <Icons.DeleteOutline />
                                                                </div> : ""
                                                            }
                                                        </div>

                                                    </div>
                                                })}
                                            </div>
                                            {/* アクションいろいろ */}
                                            <div className="comment_status" style={{ display: "block" }}>
                                                <div className="post_comment_pane with_selector">
                                                    <div className="post_comment_row">
                                                        <div className={this.props.classes.actionForms}>
                                                            <div style={ { display:"flex",height:"30px",justifyContent:"center",alignItems:"center" }}>
                                                                <div onClick={(el) => this.createReply(data.id)} style={{flex:1, display:"flex", alignItems:"center" }}>
                                                                    <Icons.ReplyOutlined />返信する
                                                                </div>
                                                                {
                                                                    this.state.me.id == data.wc_user_id ? <div onClick={(el) => this.createEdit(data.id, data.content)} style={{flexBasis:"70px", display:"flex", alignItems:"center", justifyContent:"right", cursor: "pointer" }}>
                                                                        <Icons.EditOutlined /> 編集
                                                                    </div> : ""
                                                                }
                                                                {
                                                                    this.state.me.id == data.wc_user_id ? <div  style={{flexBasis:"70px", display:"flex", alignItems:"center", justifyContent:"right", cursor:"pointer" }} onClick={ (el) => this.delete_message(data.talk_id, data.id) }>
                                                                        <Icons.DeleteOutline />削除
                                                                    </div> : ""
                                                                }
                                                            </div>
                                                            <div className={this.props.classes.actionForm} style={this.replyStyle(data.id)} >
                                                                <div className="post_comment_container">
                                                                    <div className="mention_layer"
                                                                        style={{ display: "none", overflow: "hidden", width: "1050px", height: "28px" }}>
                                                                    </div>
                                                                    <MentionsInput
                                                                        value={this.state.reply.input}
                                                                        onChange={(event, newValue, newPlainTextValue, mentions) => {
                                                                            this.handleChangeReply(event)
                                                                        }}
                                                                        placeholder={"コメント"}
                                                                        allowSpaceInQuery={true}
                                                                        displayTransform={(id, display) => `@${display}`}
                                                                        rows="3"
                                                                        style={{ height:"45px", position: "relative" }}
                                                                    >
                                                                        <Mention
                                                                            markup="@[__display__](user:__id__)"
                                                                            trigger="@"
                                                                            data={this.state.simpleUsers}
                                                                            renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                                                                <div>{highlightedDisplay}</div>
                                                                            )}
                                                                            onAdd={e => { }}
                                                                            style={{
                                                                                backgroundColor: '#cee4e5',
                                                                            }}
                                                                        />
                                                                    </MentionsInput>
                                                                    <div className="caret_position_layer"
                                                                        style={{ position: "absolute", marginLeft: "-9999px", visibility: "hidden", width: "0px" }}>
                                                                    </div>
                                                                    <div className="member_list mention"
                                                                        id="mention_member_list_message"></div>
                                                                    <div className="task_attachment_container"></div>
                                                                </div>

                                                                <div className="comment_actions">
                                                                    <div className={this.props.classes.actionItems}>
                                                                        <div className={this.props.classes.actionItem}>
                                                                            <div className={this.props.classes.fileInner}>
                                                                                <div className="hover_popup hover_popup_comment_file_upload"
                                                                                    style={{ display: "none" }}>
                                                                                    <span className="hover_popup_arrow_top"></span>
                                                                                    <p>500MBまでのファイルを添付できます(動画は300MBまで)</p>
                                                                                </div>
                                                                                <span className={this.props.classes.btnFile} data-action="file" data-popup-classname="hover_popup_comment_file_upload" data-popup="500MBまでのファイルを添付できます(動画は300MBまで)">
                                                                                    <button className={this.props.classes.btnFileUpload} data-input-file="upfile" data-width="120" data-height="27">
                                                                                        <span className="img_cover">
                                                                                            <Icons.AttachFile style={{height:"20px", marginTop:"3px"}} />
                                                                                        </span>
                                                                                        <span className="txt_cover" onClick={(e) => {
                                                                                            const el = document.getElementById("messageReplyFile") as any
                                                                                            if (el) el.click()
                                                                                        }}>ファイルを添付</span>
                                                                                        <input type="file" id={`messageReplyFile`} ref="file"
                                                                                            hidden={true}
                                                                                            onChange={(e) => {
                                                                                                if (e.target.files && e.target.files.length > 0) {
                                                                                                    this.setAvatarImage(e.target.files[0], true)
                                                                                                }
                                                                                            }} />
                                                                                    </button>
                                                                                    {reply_attachments.map((file) => {
                                                                                        return <div>
                                                                                            {file.src_name}
                                                                                        </div>
                                                                                    })}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <div className={this.props.classes.chatModeBox} >
                                                                            <Button color="default" variant="outlined" style={{ margin: "5px" }}
                                                                                onClick={(e) => this.onReplyPostButtonClick()} disabled={this.state.loading}>
                                                                                    送信
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feed_message_end">
                                            <div className="border-bottom"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    })}
                </ul>
                <Modal
                    open={this.state.isImagePopupOpen}
                    onClose={() => this.setState({ isImagePopupOpen: false, imageSrcDisplayedInPopup: undefined })}
                >
                    <>
                        <div
                            style={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '90%',
                                height: '100%',
                            }}
                        >
                            <img src={this.state.imageSrcDisplayedInPopup} style={{ objectFit: "contain"}} width="100%" height="100%" />
                        </div>
                        <IconButton
                            edge="end"
                            color="inherit"
                            size="medium"
                            onClick={() => this.setState({ isImagePopupOpen: false, imageSrcDisplayedInPopup: undefined })}
                            style={{position: "absolute", top: 20, right: 20, color: "white"}}
                        >
                            <Icons.Close style={{color: "white"}} />
                        </IconButton>
                    </>
                </Modal>
            </div>
            <div style={{textAlign:"center"}}>
                {this.state.more &&
                        <Button color="default" variant="outlined" style={{ margin: "5px", width:"80%" }}
                            onClick={(e) => this.read_more()} >
                                もっと読む
                        </Button>
                }
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Message))
