import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router'

class ScrollToTop extends React.Component<RouteComponentProps> { 
	displayName="ScrollToTop"
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
        const divElement = document.getElementById("scrollToTop");
        if (divElement) {
            divElement.scrollTop = 0;
        }
    }
  }

  render() {
      return <div id="scrollToTop">
          {this.props.children}
      </div>
  }
}

export default withRouter(ScrollToTop)