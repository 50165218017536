import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as Colors from './Colors'
import { DialogTitle, Dialog, withStyles, Button } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateUtil from '../util/DateUtil'
import moment from 'moment';

const styles = () => Styles.createStyles({
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    dialog: {
        padding: "10px"
    },
    searchField: {
        maxWidth: "200px"
    },
    omaha: {
        textAlign: "left"
    },
    omahaHead: {
        fontWeight: "bold",
        fontSize: "0.9rem"
    },
    omahaComment: {
        fontSize: "smaller",
        marginLeft: "5px",
        whiteSpace: "pre-line"
    },
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    }
}))(TableCell);


interface Props extends Styles.WithStyles<typeof styles> {
    patient_id: number
    onSelect: (kiroku: Model.KirokuII.KangoKirokuII) => void
    onCancel: () => void
    problemList: Model.Problem[]
}
interface LocalState {
    dialogElement: JSX.Element
    kirokus: Model.KirokuII.KangoKirokuII[]
}

class KirokuSelectDialog extends React.Component<Props, LocalState> {
    displayName = "KirokuSelectDialog"
    constructor(props: Props) {
        super(props)
        this.state = {
            dialogElement: <div />,
            kirokus: []
        }
    }

    public componentDidMount = () => {
        this.loadKiroku();
    }

    private loadKiroku() {
        HokanApi.getKirokuIILatestN(this.props.patient_id, 14)
            .then(x => {
                this.setState({ kirokus: x.data.map(y => Model.KirokuII.KangoKirokuII.load(y)) })
            })
            .catch(err => {
                console.log(err)
                this.setState({ kirokus: [] })
            })
    }

    public close = () => {
        this.props.onCancel();
    }

    private selectKiroku(kiroku: Model.KirokuII.KangoKirokuII) {
        this.props.onSelect(kiroku)
    }

    public render() {
        const { classes } = this.props

        return <Dialog
            className={classes.dialog}
            onClose={(x) => this.close()}
            fullWidth={true}
            open={true}
            style={{ padding: "10px" }}>
            <DialogTitle>コピー元を選択</DialogTitle>
            <Button variant="outlined" color="default"
                onClick={(e) => this.close()}>
                戻る
            </Button>
            <Table className={classes.table}>
                <TableHead>
                </TableHead>
                <TableBody>
                    {
                        this.state.kirokus.map((kiroku, ki) => {
                            return <TableRow key={ki} className={classes.row}
                                onClick={(e) => { this.selectKiroku(kiroku) }}>
                                <CustomTableCell style={{ textAlign: "center", padding: "5px" }} component="th" scope="row">
                                    <div>
                                        <div style={{ backgroundColor: Colors.primaryColor, color: Colors.white }}>
                                            {DateUtil.toJpDateTimeString(moment(kiroku.time_start).format("YYYY-MM-DDTHH:mm"))}
                                        </div>
                                        <div style={{ whiteSpace: "pre-line", textAlign: "left" }}>
                                            {kiroku.main_user ? kiroku.main_user.name : "-"}：{kiroku.kiroku_type}<br />
                                            {kiroku.deal.assessment}
                                        </div>
                                    </div>
                                </CustomTableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </Dialog>
    }
}

export default Styles.withStyles(styles)(KirokuSelectDialog)