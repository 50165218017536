import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import { Table, TableHead, TableCell, TableRow, TableBody, Button, Dialog, DialogTitle, DialogContent, Grid, Select, MenuItem } from '@material-ui/core';
import Papa from 'papaparse';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import { WSAEMFILE } from 'constants';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none"
        }
    },
    freshCheckSubtitleRow: {
        border: "solid",
        borderWidth: "1px",
        backgroundColor: Colors.primaryColor,
        borderColor: Colors.backColor,
        color: Colors.white
    },
    freshCheckSubtitle: {
        fontWeight: "bold",
        fontSize: "0.9rem",
        padding: "5px",
        color: Colors.white
    },
    rowContent: {
        paddingLeft: "10px"
    },
    rowContentError: {
        paddingLeft: "10px",
        backgroundColor: "#dda0dd"
    },
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

interface LocalState {
    users: Model.User[]
    w_staffs: Model.Import.WyLStaff[]
    me: Model.User
    loading: boolean
    commitResult: string
    branch_id: number
    branches: Model.Branch[]
    fileErrorMessage: string
    errors: Array<Array<string>>
    posting: boolean
}



// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "ImportStaffPage"
    constructor(props: Props) {
        super(props)

        this.state = {
            users: [],
            me: new Model.User(),
            w_staffs: [],
            loading: true,
            commitResult: "",
            branch_id: 0,
            branches: [],
            fileErrorMessage: "",
            errors: [],
            posting: false
        }
    }

    private goBack() {
        this.props.history.goBack();
    }

    private commit() {
        this.setState({ posting: true, commitResult: "送信中" })
        HokanApi.postWyLStaff(this.state.branch_id, this.state.w_staffs).then(x => {
            this.setState({ posting: false, w_staffs: [], commitResult: "完了" })
        }).catch(err => {
            console.log(err)
            this.setState({ posting: false, commitResult: "エラーが発生しました" })
        })
    }

    private loadBranch = (branchId) => {
        this.setState({
            loading: true,
            branch_id: branchId,
        })
        HokanApi.getUsers(branchId)
            .then(async x => {
                const users = x.data
                this.setState({
                    users: users,
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    componentDidMount() {
        this.setState({ loading: true })
        HokanApi.me().then(x => {
            this.setState({ me: x.data })
            this.loadBranch(x.data.branch_id)
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })

        HokanApi.branch.list()
            .then(x => {
                this.setState({ branches: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    checkNumberError(name: string, str: string, err: Array<string>, hasError = () => false): number {
        const res = parseInt(str)
        if (isNaN(res) || hasError()) {
            err.push(name)
            return 0
        }
        return res
    }
    checkTextError(name: string, str: string, err: Array<string>, hasError = () => false): string {
        if (hasError()) {
            err.push(name)
        }
        return str
    }
    isValidDate = (text) => {
        const [year, month, day] = text.split(/\/|-/).map(v => parseInt(v, 10));
        if (!year || !month || !day) return false
        const date = new Date(year, month - 1, day);
        if (date.getFullYear() !== year
            || date.getMonth() !== month - 1
            || date.getDate() !== day) return false
        return true
    }
    isValidNumber = (text, low, hi) => {
        const n = parseInt(text)
        if (isNaN(n)) return false
        return (low <= n && n <= hi)
    }

    handleReflect(results) {
        const workStyles = [
            "常勤専従",
            "常勤兼務",
            "非常勤専従",
            "非常勤兼務"
        ]
        let errors: Array<Array<string>> = []
        const ws = results.data.filter((r, i) => i != 0).map(r => {
            const w = new Model.Import.WyLStaff()
            let err: Array<string> = []
            w.name = this.checkTextError("name", r[0], err, () => { return r[0] == "" || r[0] == null })
            w.email = this.checkTextError("email", r[1], err, () => { return r[1].indexOf('@') == -1 || this.state.users.findIndex(u => u.email == r[1]) != -1 })
            w.password = this.checkTextError("password", r[2], err, () => r[2].length < 6)
            w.tel1 = r[3]
            w.tel2 = r[4]
            w.birthday = r[5]
            w.nyusha = r[6] //this.checkTextError("nyusha", r[6], err, () => { return !this.isValidDate(r[6]) })
            w.work_style = this.checkTextError("work_style", r[7], err, () => { return r[7] != "" && r[7] != null && workStyles.findIndex(x => x == r[7]) == -1 })
            w.shikaku = this.checkNumberError("shikaku", r[8], err, () => { return r[8] != "" && r[8] != null && !this.isValidNumber(r[8], 0, 100) })
            w.role = 0 //this.checkNumberError("role", r[9], err, () => { return !this.isValidNumber(r[9], 0, 25) })
            w.wiseman_id = r[9]
            w.privilege = r[10] == "1" ? "Admin" : "Normal"
            errors.push(err)
            return w
        })
        this.setState({ w_staffs: ws, errors: errors })
    }

    private loadFile(file: File) {
        Papa.parse(file, {
            header: false,
            dynamicTyping: false,
            skipEmptyLines: true,
            complete: (results) => {
                this.handleReflect(results);
            },
        });
    }

    private hideStr(str: string) {
        let res = ""
        if (!str) return "";
        for (let i = 0; i < str.length; ++i) {
            res += "*"
        }
        return res
    }

    public render() {
        const { classes } = this.props;
        const { users, w_staffs, errors } = this.state;

        const selectStyle = (wi, name) => {
            if (errors[wi].findIndex(x => x == name) != -1) {
                return classes.rowContentError
            }
            return classes.rowContent
        }

        let errorCount = 0
        this.state.errors.forEach(e => {
            errorCount += e.length
        })

        return <div className={classes.root}>

            <SubTitle title="スタッフ情報インポート"
                text="スタッフ情報を一括で取り込みます"
            />
            <div>
                <Select
                    value={this.state.branch_id}
                    autoWidth={true}
                    variant='outlined'
                    onChange={(e) => this.loadBranch(e.target.value)}
                    className={classes.input}
                >
                    {
                        this.state.branches.map((t, idx) =>
                            <MenuItem key={`lis+${t.id}`} value={t.id}>{t.name}</MenuItem>
                        )
                    }
                </Select>
            </div>
            <div>
                取り込む .csv ファイルを選択してください。
                <div>
                    <input type="file" ref="file"
                        accept=".csv"
                        onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                this.setState({ fileErrorMessage: "" })
                                this.loadFile(e.target.files[0])
                            }
                        }} />
                </div>
                <div style={{ color: "red" }}>{this.state.fileErrorMessage}</div>
            </div>
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={12} lg={12}>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell style={{ textAlign: "center", padding: "10px" }}>名前</CustomTableCell>
                                <CustomTableCell align="center">メールアドレス</CustomTableCell>
                                <CustomTableCell align="center">パスワード</CustomTableCell>
                                <CustomTableCell align="center">携帯番号（仕事用）</CustomTableCell>
                                <CustomTableCell align="center">携帯電話（私用）</CustomTableCell>
                                <CustomTableCell align="center">生年月日</CustomTableCell>
                                <CustomTableCell align="center">入社日</CustomTableCell>
                                <CustomTableCell align="center">勤務形態</CustomTableCell>
                                <CustomTableCell align="center">職種ID</CustomTableCell>
                                <CustomTableCell align="center">レセコンID</CustomTableCell>
                                <CustomTableCell align="center">管理者</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                w_staffs.map((w, wi) => {
                                    return <TableRow key={`wrow_${wi}`} className={classes.row}>
                                        <TableCell align="center" className={selectStyle(wi, "name")}>{`${w.name}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "email")}>{`${w.email}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "password")}>{`${this.hideStr(w.password)}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "tel1")}>{`${w.tel1}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "tel2")}>{`${w.tel2}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "birthday")}>{`${w.birthday}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "nyusha")}>{`${w.nyusha}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "work_style")}>{`${w.work_style}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "shikaku")}>{`${w.shikaku}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "wiseman_id")}>{`${w.wiseman_id}`}</TableCell>
                                        <TableCell align="center" className={selectStyle(wi, "privilege")}>{`${w.privilege == "Admin" ? "1" : "0"}`}</TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            {
                errorCount > 0 ?
                    <div>
                        エラーが{errorCount}個あります。ファイルを確認してください。
                    </div>
                    : null
            }
            <div>
                <Button color="default" variant="outlined"
                    onClick={(e) => this.goBack()}
                    disabled={this.state.posting}
                    className={classes.filter_button_d}>
                    戻る
                </Button>
                <Button color="primary" variant="contained"
                    onClick={(e) => this.commit()}
                    disabled={this.state.posting || errorCount > 0}
                    className={classes.filter_button_d}>
                    実行
                </Button>
            </div>
            <div>{this.state.commitResult}</div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))