import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const buttonStyle = {
    width: "120px",
    height: "60px",
    margin: "20px"
}
const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        textAlign: "center"
    },
    prevButton: {
        ...buttonStyle,
    },
    nextButton: {
        ...buttonStyle,
    }
});


interface Props extends Styles.WithStyles {
    onCancel: () => void,
    onOk: () => void,
    cancelLabel: string,
    okLabel: string,
    disabled: boolean,
    showBack: boolean,
    showOk: boolean
}

class ButtonOkCancel extends React.Component<Props> { 
    displayName = "ButtonOkCancel"
    public static defaultProps = {
        showOk: true
    }
    
    public render() {
        const { classes } = this.props;
        return <div className={classes.root}>
            {
                this.props.showBack ?
                <Button className={classes.prevButton} variant="contained" color="default" onClick={(e) => this.props.onCancel()} disabled={this.props.disabled}>
                    {this.props.cancelLabel}
                </Button>
                : null
            }
            {
                this.props.showOk ?
            <Button className={classes.nextButton} variant="contained" color="primary" onClick={(e) => this.props.onOk()} disabled={this.props.disabled}>
                {this.props.okLabel}
            </Button>
            : null
            }
        </div>
    }
}

export default Styles.withStyles(styles)(ButtonOkCancel)