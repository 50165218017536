import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour  { 
	displayName="Behaviour"

    public getId(): number {
        return 16
    }

    public createContent(): any {
        const res = new FormModel.MouseFood.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
        doc.content.values.result = this.getResult(doc.content)
    }

    public beforeMount = () => {

    }

    private getResult(content) {
        if (content.values.score_enge == -1) return ""
        if (content.values.score_enge == 0) return "判定不能"
        if (content.values.score_enge <= 3) return "嚥下障害あり"
        if (content.values.score_enge <= 5) return "正常"
        return ""
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const elements_scores = [
            {
                name: "実施の結果", content: saV.listRaw("score_enge", [
                    { key: "未選択", value: -1 },
                    { key: "１：嚥下なし、むせるまたは呼吸変化を伴う", value: 1 },
                    { key: "２：嚥下あり、呼吸変化を伴う", value: 2 },
                    { key: "３：嚥下あり、呼吸変化はないが、むせるあるいは湿性嗄声を伴う", value: 3 },
                    { key: "４：嚥下あり、呼吸変化なし、むせ、湿性嗄声なし", value: 4 },
                    { key: "５：４に加え、追加嚥下運動（空嚥下）が30秒以内に２回以上可能", value: 5 },
                    { key: "判定不能：口から出す、無反応", value: 0 },
                ], true, true)
            },
            { name: "判定結果", content: <div>{this.getResult(doc.content)}</div> },
            { name: "備考", content: saV.text("comment") }
        ]

        const elements_explain = [
            {
                name: "方法", content: <div>
                    ティースプーン１杯（3～4g）のプリンなどを嚥下させてその状態を観察する。<br/>
                    評点が3以下の場合はその場で評価終了、評点が4点以上の場合は、最大3回まで施行し、最も悪い評点を記載する。
                </div>
            }
        ]

        const elements = [
            { name: '評価', content: elements_scores },
            { name: '説明', content: elements_explain },
        ]

        return elements
    }
}
