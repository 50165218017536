import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 82;
  }

  public createContent(): any {
    const res = new FormModel.ABCDStoma.Core();
    res.values.create_date = DateUtil.currentDateStr();

    HokanApi.me()
      .then((x) => {
        if (x && x.data) {
          res.values.hyoukasya = x.data.name;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    if (doc.content.values.damage_a === "") {
      doc.content.values.damage_a = 0;
    }
    if (doc.content.values.damage_b === "") {
      doc.content.values.damage_b = 0;
    }
    if (doc.content.values.damage_c === "") {
      doc.content.values.damage_c = 0;
    }
    if (doc.content.values.color_d === "") {
      doc.content.values.color_d = 0;
    }

    doc.content.values.damage_total =
      Number(doc.content.values.damage_a) +
      Number(doc.content.values.damage_b) +
      Number(doc.content.values.damage_c);
    doc.content.values.stoma_score_a = doc.content.values.damage_a;
    doc.content.values.stoma_score_b = doc.content.values.damage_b;
    doc.content.values.stoma_score_c = doc.content.values.damage_c;
    doc.content.values.stoma_score_total = doc.content.values.damage_total;
    doc.content.values.stoma_score_d = doc.content.values.color_d;
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    enum ScoreType {
      None = 0,
      Value1 = 1,
      Value2 = 2,
      Value3 = 3,
      Value15 = 15,
    }
    enum ScoreDType {
      None = 0,
      Value1 = "P",
      Value2 = "H",
    }

    const elements_t = [
      {
        name: "基本情報",
        content: (
          <div style={{ padding: 10 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              評価者
              <div>{saV.text("hyoukasya")}</div>
            </label>
          </div>
        ),
      },
      {
        name: "内容",
        content: (
          <div style={{ padding: 10 }}>
            <fieldset
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              <legend>ストーマの種類</legend>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {saC.check("check_1")}
                コロストミー
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {saC.check("check_2")}
                イレオストミー
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {saC.check("check_3")}
                ウロストミー
              </label>
            </fieldset>
            <div>
              <img src="/images/abcd-stoma_1.png" style={{ width: "100%" }} />
            </div>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              A{saV.list("damage_a", ScoreType)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              B{saV.list("damage_b", ScoreType)}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              C{saV.list("damage_c", ScoreType)}
            </label>
            <div>
              <img src="/images/abcd-stoma_2.png" style={{ width: "400px" }} />
            </div>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              D{saV.list("color_d", ScoreDType)}
            </label>
          </div>
        ),
      },
    ];

    const elements = [{ name: "内容", content: elements_t }];

    return elements;
  }
}
