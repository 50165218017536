import * as React from 'react';
import { Count, toInjector } from '../stores';
import { inject, observer } from 'mobx-react';

interface Props extends Count { }

@inject(...toInjector(Count))
@observer
export class Counter extends React.Component<Props> { 
	displayName="Counter"
    public render() {
        const { count } = this.props;

        return (
            <div>
                Counter : {count!.num} <br />
                < button onClick={count!.onIncrement} > + </button>
                < button onClick={count!.onDecrement} > - </button>
                < br /> GetDoubleCount: {count!.getDoubleCount}
            </div>
        );
    }
}

export default Counter;