import { observable, action } from 'mobx';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'

export default class ProblemStore {
    @observable public problem?: Model.ProblemDetail = undefined
    @observable public loading?: boolean = false

    @action.bound public async loadProblem(problem_id: number) {
        if (this.problem && this.problem!.id == problem_id) {
            /* already loaded */
            return
        }

        this.loading = true
        await HokanApi.getProblem(problem_id)
            .then((response) => {
                console.log(response.data)
                this.problem = response.data
                this.loading = false
            })
            .catch((error) => {
                console.log(error)
            })
    }
}