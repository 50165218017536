import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const cellCommon = {
    border: "solid",
    borderWidth: "1px",
    borderColor: Colors.borderColor,
    fontSize: "0.95rem",
}

const styles = () => Styles.createStyles({
    table: {
        margin: "0px"
    },
    body: {
    }
})

interface Props extends Styles.WithStyles<typeof styles> {
    shrink?: boolean
    disableBGColor?: boolean
}


interface LocalState {
    closed: {}
}

class Col2Table extends React.Component<Props, LocalState> { 
	displayName="Col2Table"
    constructor(props) {
        super(props)
        this.state = {
            closed: {}
        }
    }
    public render() {
        const { classes } = this.props;
        return <Table className={classes.table}>
                    <TableBody className={classes.body}>
                        {
                            this.props.children
                        }
                    </TableBody>
                </Table>
    }
}

export default Styles.withStyles(styles)(Col2Table)