import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import { Button } from '@material-ui/core';
import Col2Table from '../Col2Table';

// @inject()
// @observer
export default class BodyMMTBehaviour extends FormBehaviour.PdfBehaviour { 
	displayName="BodyMMTBehaviour"
    public getId(): number {
        return 29
    }

    public createContent(): any {
        const res = new FormModel.BodyMMT.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {

    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const content = doc.content as FormModel.BodyMMT.Core
        const elements_scores = content.values.list_1.map((score, scorei) => {
                return {
                    name: score.bui == "" ? " " : score.bui,
                    content: <Col2Table disableBGColor={true} body={[
                            {name: "部位", content: saV.textC(["list_1", scorei], "bui", "自由記載")},
                            { name: "方向", content: saV.listC(["list_1", scorei], "houkou", FormModel.BodyMMT.MMTHoukouEnumm)},
                            { name: "左右", content: saV.listC(["list_1", scorei], "lr", FormModel.BodyMMT.MMTLrEnumm)},
                            { name: "スコア", content: saV.listC(["list_1", scorei], "score", FormModel.BodyMMT.MMTScoreEnum)},
                            { name: "削除", content: <Button variant="outlined" color="secondary" onClick={(e) => {
                                const list = saV.getValueC("list_1", []).filter((x, i) => i != scorei)
                                saV.setValue("list_1", list)
                            }}>
                                削除
                        </Button>
                            }
                        ]} />
                }
            }).concat(
            {
                name: "追加", content: <Button variant="outlined" color="primary" onClick={(e) => {
                    const list = saV.getValueC("list_1", [])
                    list.push(new FormModel.BodyMMT.MMTScore())
                    saV.setValue("list_1", list)
                }}>
                    ＋
                </Button>
            })

        const elements = [
            { name: '評価', content: elements_scores },
        ]

        return elements
    }
}
