
export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public create_time: string = ""
    public time_from: string = ""
    public time_to: string = ""
    public pat_name: string = ""
    public location: string = ""
    public shusseki: string = ""
    public tanto_name: string = ""

    public content: string = ""

    public branch_name: string = ""
    public branch_address: string = ""
    public branch_tel: string = ""
    public branch_fax: string = ""
}

export class Checks {
}