
export class Core{
    public values: Values = new Values();
    public checks: Checks = new Checks();
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    public text_1_1: string = ""
    public text_1_2: string = ""
    public text_1_3: string = ""
    public text_1_4: string = ""
    public text_2_1: string = ""
    public kyouyu_1: string = ""
    public kyouyu_2: string = ""
    public kyouyu_3: string = ""
    public kyouyu_4: string = ""
    public kyouyu_5: string = ""
    public kyouyu_6: string = ""
    public kyouyu_7: string = ""
    public text_3_1: string = ""
    public text_3_2: string = ""
    public text_4_1: string = ""
    public text_4_kankei: string = ""
    public text_4_name: string = ""
    public write_by: string = ""
}

export class Checks {
    public check_c4_1: boolean = false
    public check_c4_2: boolean = false
    public check_c4_3: boolean = false
}