import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
import SubTitle from '../components/SubTitle'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import * as Colors from '../components/Colors'
import { Table, TableHead, TableCell, TableRow, TableBody, Button, Grid } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import StandardTable from '../components/StandardTable';
import Col2Table from '../components/Col2Table';
import { StoreAccessor } from '../util/StoreUtil';
import DateUtil from '../util/DateUtil';
import Pagination from "material-ui-flat-pagination";

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        cursor: "pointer",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none"
        }
    },
    freshCheckSubtitleRow: {
        border: "solid",
        borderWidth: "1px",
        backgroundColor: Colors.primaryColor,
        borderColor: Colors.backColor,
        color: Colors.white
    },
    freshCheckSubtitle: {
        fontWeight: "bold",
        fontSize: "0.9rem",
        padding: "5px",
        color: Colors.white
    },
    rowContent: {
        paddingLeft: "10px"
    },
})


const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
}

class Query {
    user_id: number | null = null
    date_from: string = DateUtil.currentMonthFirstDateStr()
    date_to: string = DateUtil.currentMonthLastDateStr()
    page: number = 1
    per: number = 50
    order: string = "DESC"
}

interface LocalState {
    me: Model.User
    loading: boolean
    commitResult: string
    user: Model.User
    query: Query
    kirokus: HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>
}



// @inject()
// @observer
class Page extends React.Component<Props, LocalState> {
    displayName = "MyPage"
    constructor(props: Props) {
        super(props)
        const k = new HokanApi.PagingResponse<Model.KirokuII.KangoKirokuII>()
        k.per = 50
        this.state = {
            me: new Model.User(),
            loading: true,
            commitResult: "",
            user: new Model.User(),
            query: new Query(),
            kirokus: k
        }
    }

    private loadKirokus = (_offset?: number) => {
        if (!this.state.user) return;
        this.setState({ loading: true })
        const q = this.state.query
        const offset = _offset == undefined ? (this.state.kirokus.page - 1) * this.state.kirokus.per : _offset
        q.page = offset / this.state.kirokus.per + 1
        q.per = this.state.kirokus.per
        HokanApi.searchKirokuIIsUser(this.state.user.id, q.date_from, q.date_to, q).then(x => {
            const d = x.data
            d.data = d.data.map(y => Model.KirokuII.KangoKirokuII.load(y))
            d.data.sort((a, b) => {
                if (a.time_start > b.time_start) return -1
                if (b.time_start > a.time_start) return +1
                return 0
            })
            this.setState({ kirokus: d, loading: false })
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    private setToday = () => {
        const q = this.state.query
        q.date_from = DateUtil.currentDateStr();
        q.date_to = DateUtil.currentDateStr();
        this.setState({ query: q })
    }

    private setThisMonth = () => {
        const q = this.state.query
        q.date_from = DateUtil.currentMonthFirstDateStr()
        q.date_to = DateUtil.currentMonthLastDateStr()
        this.setState({ query: q })
    }

    componentDidMount() {
        const qls = localStorage.getItem("mypage_query")
        const q = this.state.query
        if (qls) {
            const jps = JSON.parse(qls)
            if (jps.query) {
                const lq = jps.query
                q.date_from = lq.date_from || ""
                q.date_to = lq.date_to || false
            }
            if (jps.user_id) {
                q.user_id = jps.user_id
            }
        }

        this.setState({ loading: true })
        HokanApi.me().then(x => {
            this.setState({ me: x.data, user: x.data })
            if (q.user_id) {
                HokanApi.user.get(q.user_id).then(y => {
                    this.setState({ user: y.data, loading: false })
                    this.loadKirokus();
                })
                    .catch(err => {
                        console.log(err)
                        this.setState({ loading: false })
                    })
            }
            else {
                this.setState({ user: x.data })
                this.loadKirokus()
            }
        })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    public render() {
        const { classes } = this.props;
        const sa = new StoreAccessor(this, true)
        const saQ = sa.generateChild(["query"])
        if (!this.state.loading) {
            this.state.query.user_id = this.state.user.id;
            const query_item = JSON.stringify({
                query: this.state.query,
                user_id: this.state.user ? this.state.user.id : null
            })
            localStorage.setItem("mypage_query", query_item)
        }

        const element_l = [
            {
                name: "", content: <div style={{ display: "inline-flex" }}>
                    {sa.user("user", false, "スタッフ")}
                    {"　　"}
                    <Button color="primary" variant="outlined" size="small"
                        onClick={(e) => { this.setToday() }} >今日</Button>
                    <Button color="primary" variant="outlined" size="small"
                        onClick={(e) => { this.setThisMonth() }} >今月</Button>
                </div>
            },
            {
                name: "", content: <div style={{ display: "inline-flex" }}>
                    {saQ.date("date_from")}〜{saQ.date("date_to")}
                </div>
            },
            {
                name: "", content: <Button color="primary" variant="contained"
                    disabled={this.state.loading}
                    onClick={(e) => { this.loadKirokus() }} >検索</Button>
            },
        ]

        const paging =
            <Pagination
                limit={this.state.kirokus.per}
                offset={(this.state.kirokus.page - 1) * this.state.kirokus.per}
                total={this.state.kirokus.count}
                onClick={(e, offset) => {
                    this.loadKirokus(offset)
                }}
            />

        return <div className={classes.root}>
            <SubTitle title="自分の記録" />
            <div>
                <div style={{ display: "block", width: "100%" }}>
                    <StandardTable title=''>
                        <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12} md={12} lg={12}>
                                <Col2Table body={element_l} />
                            </Grid>
                        </Grid>
                    </StandardTable>
                </div>
            </div>
            <div>件数：{this.state.kirokus.count}</div>
            {paging}
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <CustomTableCell align="center" >日時</CustomTableCell>
                        <CustomTableCell align="center" >状況</CustomTableCell>
                        <CustomTableCell align="center" >利用者</CustomTableCell>
                        <CustomTableCell align="center" >保険</CustomTableCell>
                        <CustomTableCell align="center" >コード</CustomTableCell>
                        <CustomTableCell align="center" >主スタッフ</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.state.kirokus.data.map(kiroku => {
                            const code = Model.KirokuII.KangoKirokuII.getKirokuCodeStr(kiroku)
                            return <TableRow key={"kiroku_" + kiroku.id} className={classes.row}
                                onClick={(e) => { this.props.history.push(`/patients/${kiroku.patient.id}?kid=${kiroku.id}#activity`) }}
                                style={{
                                    borderWidth: "1px", borderRadius: "0px", zIndex: (kiroku.is_temp ? 2 : undefined),
                                    borderColor: kiroku.is_temp ? Colors.orangeColor : undefined,
                                    backgroundColor: kiroku.is_temp ? Colors.orangeColorTransparent : undefined
                                }}
                            >
                                <CustomTableCell align="center">
                                    <div>
                                        {DateUtil.toSlashDateStringShort(kiroku.time_start)}<br />
                                        {DateUtil.toTimeStr(kiroku.time_start)}
                                        〜{DateUtil.toTimeStr(kiroku.time_end)}
                                    </div>
                                </CustomTableCell>
                                <CustomTableCell align="center">{kiroku.is_temp ? "仮" : "確定"}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.patient.name}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.kiroku_type}</CustomTableCell>
                                <CustomTableCell align="center">{code}</CustomTableCell>
                                <CustomTableCell align="center">{kiroku.main_user ? kiroku.main_user.name : ""}</CustomTableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
            {paging}
            <div>{this.state.commitResult}</div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(Page))