import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    input: {
        color: Colors.inputColor,
        padding: "0px",
        "& input": {
            padding: "5px"
        },
        "& textarea": {
            padding: "5px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto",
    }
})

interface Props extends Styles.WithStyles {
    editMode: boolean,
    name: string,
    value: any,
    label: string,
    inputType: string,
    multiline: boolean,
    fullwidth: boolean,
    display: string,
    setData: (name: string, value: any) => void
    minHeight: string | null
    onKeyDown: ((e: React.KeyboardEvent<HTMLDivElement>) => void) | null
    path: string
}

class EditableText extends React.Component<Props> {
    displayName = "EditableText"
    public static defaultProps = {
        inputType: "text",
        multiline: true,
        fullwidth: true,
        display: "block",
        minHeight: null,
        onKeyDown: null,
        path: ""
    }
    public render() {
        const { classes, value, name, multiline } = this.props;
        if (this.props.editMode == false) {
            if (value && value != "") {
                const s = value.toString().split('\n');
                return s.map((x, i) => <div key={`edth_${name}_${i}`}
                    style={{ display: this.props.display }}>
                    {x.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) ? <a href={`tel:${x}`}>{x}</a> : x}
                </div>)
            }
            else return <div key={`edtn_${name}`}>-</div>
        }
        return <div key={`edt_${name}`} style={{ display: this.props.display, width: this.props.fullwidth ? "100%" : undefined }}>
            <TextField
                label=""
                placeholder={this.props.label}
                type={this.props.inputType}
                fullWidth={this.props.fullwidth}
                multiline={multiline && this.props.inputType == "text"}
                value={value || ""}
                onChange={(e) => this.props.setData(name, e.target.value)}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    className: classes.input,
                    style: this.props.minHeight ? { minHeight: this.props.minHeight } : {}
                }}
                style={
                    this.props.inputType == "datetime-local" ? { width: "300px" } : {}
                }
                margin="none"
                variant="outlined"
                onKeyDown={e => {
                    if (this.props.onKeyDown && (e.keyCode === 13 || e.key === "Enter")) {
                        this.props.onKeyDown(e)
                    }
                }}
            />
        </div>
    }
}

export default Styles.withStyles(styles)(EditableText)