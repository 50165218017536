import { OrganizationTanto } from '../Organization';

export class Core {
    public values: Values = new Values();
    public checks: Checks = new Checks();
    public selected: {[index:string]:string|null} = {}
    public dateInitialized = false;
}

export class Values {
    public create_date: string = ""
    public pat_name: string = ""
    
    public branch_name: string = ""
    public calendar: string = ""
    public calendar2: string = ""
    public calendar_from: string = ""
    public calendar_to: string = ""
    public comment: string = ""
    public eisei_hindo: string = ""
    public eisei_name: string = ""
    public eisei_siyouryou: string = ""
    public henko_naiyo: string = ""
    public jouho_teikyo_date: string = ""
    public jouho_teikyo_saki: string = ""
    public kanri_name: string = ""
    public katei_kaigo: string = ""
    public keika: string = ""
    public ketsuatsu: string = ""
    public myakuhaku: string = ""
    public naiyou: string = ""
    public pat_address: string = ""
    public pat_birthday: string = ""
    public pat_youkaigo: string = ""
    public spo2: string = ""
    public sub_title_1: string = ""
    public sub_title_2: string = ""
    public taion: string = ""
    public tanto1_name: string = ""
    public tanto2_name: string = ""
    public tanto1_role: string = ""
    public tanto2_role: string = ""
    public title: HoukokuType = HoukokuType.None
    public houkoku_saki: OrganizationTanto[] = [];
    public gaf_score: string = ""
    public gaf_date: string = ""

    public kettou: string = ""
    public taiju: string = ""
}

export enum HoukokuType {
    None = "未選択",
    Houkan = "訪問看護報告書",
    SeishinHoukan = "精神科訪問看護報告書",
    KaigoYobouHoukan = "介護予防訪問看護報告書",
}

export class Checks {
    public check_ari: boolean = false
    public check_nashi: boolean = true
}