
import * as Model from '../../models'
import { StoreAccessor } from '../../util/StoreUtil';

export default interface FormBehaviour {
    getId(): number
    createContent(): any
    createDocumentType(): Model.Document.DocumentType
    buildElements(doc: Model.Document.Document, sa: StoreAccessor, classes: any):
        {name:string, content:{ name: string, content: React.ReactElement }[]}[]

    beforePost(doc: Model.Document.Document): void
    afterPost(doc: Model.Document.Document): void
    beforeMount(doc: Model.Document.Document, sa: StoreAccessor): void

    isPreviewable(): boolean
    setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor): void
    setUser(doc: Model.Document.Document, user: Model.User, sa: StoreAccessor): void
    isFullWidth(): boolean

    shutten(): any
    createSucceed(doc: Model.Document.Document, reload: any): void
}


export abstract class PdfBehaviour implements FormBehaviour {
    public isPreviewable() { return true }
    abstract getId(): number
    abstract createContent(): any
    abstract createDocumentType(): Model.Document.DocumentType

    public getError(): string | null {
        return null
    }

    public shutten():any {
        return null
    }

    public isFullWidth() {
        return true;
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        doc.patient = pat
    }
    public setCreateDate(doc: Model.Document.Document, create_date: string, sa: StoreAccessor): void {
        doc.content.values.create_date = create_date
    }

    public setUser(doc: Model.Document.Document, user: Model.User, sa: StoreAccessor) {
        doc.user = user
    }

    public buildElements(doc: Model.Document.Document, sa: StoreAccessor, classes: any): { name: string, content: { name: string, content: React.ReactElement }[] }[] {
        const saV = sa.generateChild(["content", "values"])
        saV.callBack = (cat, name, value) => {
            if (name == "create_date") {
                this.setCreateDate(doc, value, sa)
            }
        }
        sa.callBack = (cat, name, value) => {
            if (name == "patient") {
                this.setPatient(doc, value, sa)
            }
        }
        const elements_basic = [
            { name: "作成日", content: saV.date("create_date") },
            { name: "利用者", content: sa.patient("patient", "patientOpenDialog") },
        ]
        const elements_common: { name: string, content: { name: string, content: React.ReactElement }[] }[] = [
            { name: '基本', content: elements_basic }
        ]
        return elements_common.concat(this.buildElements_pdf(doc, sa, classes))
    }
    abstract buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor, classes: any): { name: string, content: { name: string, content: React.ReactElement }[] }[]

    public beforePost(doc: Model.Document.Document): void {
        if(doc.patient){
            doc.content.values.pat_name = doc.patient ? doc.patient.name : ""
        }
        this.beforePost_pdf(doc)
    }
    public afterPost(doc: Model.Document.Document): void {
    }
    public createSucceed(doc: Model.Document.Document, reload:any): void {
        if (reload){
            reload()
        }
    }
    abstract beforePost_pdf(doc: Model.Document.Document): void
    abstract beforeMount(doc: Model.Document.Document, sa: StoreAccessor): void
}
