import * as React from 'react';
import * as Colors from '../Colors'
import * as Styles from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
    ResponsiveContainer, Label, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { Table, TableHead, TableRow, TableCell, TableBody, MenuItem, Select } from '@material-ui/core';
import { Rowing } from '@material-ui/icons';
import Pagination from "material-ui-flat-pagination";
import EditableCheckList from '../EditableCheckList';


const styles = () => Styles.createStyles({
    root: {
        color: Colors.primaryColor,
        border: "solid 1px",
        borderRadius: "8px",
        padding: "5px",
        //        boxShadow: "5px 5px 4px rgba(0,0,0,0.1)"
    },
    table: {
        marginBottom: "5px",
        color: Colors.primaryColor,
        width: "100%",
        fontSize: "0.9rem",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {
        }
    },
    rowHead: {
        textAlign: "center",
        padding: "1px",
        position: "sticky",
        left: "0",
        minWidth: "90px"
    },
    rowChild: {
        textAlign: "center",
        padding: "1px",
        minWidth: "70px"
    }
});


interface Props extends Styles.WithStyles {
    title: string,
    name: string,
    count: string[],
    data: any,
    onlyGridData: any,
    onlyGridCount: string[],
    color: string[] | null
}

function genColor(idx: number) {
    return `hsl(${idx * 63 % 360}, 80%, 30%)`
}
/*
class CustomizedLabel extends React.PureComponent<any> {
    render() {
        const {
            x, y, stroke, value,
        } = this.props;

        return <g>
            <rect x={x - 15} y={y - 17} width={30} height={14} fill="#FFFC" />
            <text x={x} y={y} dy={-5}
                fill={stroke} fontSize={13}
                textAnchor="middle">{value}</text>
        </g>
    }
}*/

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {
            borderTopRightRadius: "5px",
        },
        "&:first-child": {
            borderTopLeftRadius: "5px",
        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);
const ref = React.createRef<HTMLDivElement>()

interface LocalState {
    per: number
    page: number
    hideFlag: any
}

class TaionBan extends React.PureComponent<Props, LocalState> {
    displayName = "TaionBan"
    constructor(props: Props) {
        super(props)
        this.state = {
            per: 5,
            page: 0,
            hideFlag: {}
        }
    }
    componentDidMount() {
        ref!.current!.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        })
        this.setState({
            page: Math.floor((this.props.data.length) / this.state.per)
        })
    }
    public render() {
        const { classes } = this.props;
        const isFirst = this.state.page == 0
        let remain = (this.props.data ? this.props.data.length : 0) % this.state.per
        if (remain == 0) {
            remain = this.state.per
        }
        const st = (isFirst ? 0 : this.state.per * (this.state.page - 1) + remain)
        const en = (isFirst ? remain : st + this.state.per)
        const cd = this.props.data.slice(st, en)
        const ogd = this.props.onlyGridData.slice(st, en)
        const tdata = cd.map((row, rowi) => {
            return {
                ...row,
                ...ogd[rowi]
            }
        })

        const getShowFlag = (name) => {
            if (this.state.hideFlag[name] == true) return false;
            return true
        }
        const tcount = [...this.props.count, ...this.props.onlyGridCount]

        return <div className={classes.root}>
            <div>
                {this.props.title}
            </div>
            <div style={{ margin: "10px" }}>
                表示単位：
                <Select
                    value={this.state.per}
                    autoWidth={true}
                    variant='outlined'
                    onChange={(e) => {
                        const per = e.target.value as any
                        this.setState({
                            per: per,
                            page: Math.min(this.state.page, Math.floor((this.props.data.length - 1) / per))
                        })
                    }}
                    className={classes.input}
                >
                    <MenuItem key={5} value={5}>5</MenuItem>
                    <MenuItem key={14} value={14}>14</MenuItem>
                    <MenuItem key={30} value={30}>30</MenuItem>
                    <MenuItem key={60} value={60}>60</MenuItem>
                    <MenuItem key={90} value={90}>90</MenuItem>
                </Select>
            </div>

            <Pagination
                limit={this.state.per}
                offset={this.state.page * this.state.per}
                total={this.props.data ? this.props.data.length : 0}
                onClick={(e, offset) => {
                    this.setState({ page: offset / this.state.per })
                }}
            />

            <EditableCheckList
                editMode={true}
                vertical={false}
                list={this.props.count.map((x, i) => { return { key: `${i}`, value: getShowFlag(`${i}`), label: x } })}
                setData={(n, v) => {
                    this.state.hideFlag[n] = getShowFlag(n)
                    this.setState({
                        hideFlag: { ...this.state.hideFlag }
                    })
                }}
            />

            <div style={{ height: "300px" }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={tdata}
                        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={this.props.name} />
                        <YAxis yAxisId="left" type="number" domain={[40, 'dataMax']}
                            legendType="circle"
                            ticks={[40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 180]}>
                            <Label position="top" />
                        </YAxis>
                        <YAxis label={{ value: "体温" }} orientation="right"
                            yAxisId="right" type="number" domain={[34, 39]}
                            legendType="square"
                            ticks={[34, 35, 36, 37, 38, 39]}
                        >
                        </YAxis>

                        <Tooltip />
                        <Legend />
                        {
                            this.props.count.map((c, i) => {
                                if (getShowFlag(`${i}`) == false) return null
                                return <Line yAxisId={(i == 0) ? "right" : "left"}
                                    key={`lg_${i}`} dataKey={this.props.count[i]}
                                    stroke={this.props.color ? this.props.color[i] : genColor(i)}
                                />
                            })
                        }
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div style={{ overflowX: "scroll" }}>
                <Table className={classes.table}>
                    <TableHead>
                        <CustomTableCell className={classes.rowHead} scope="th">日付</CustomTableCell>
                        {
                            tdata.map((row, rowi) => {
                                return <CustomTableCell className={classes.rowChild} scope="th">{row["日付"]}</CustomTableCell>
                            })
                        }
                    </TableHead>
                    <TableBody>
                        {
                            tcount.map(c => {
                                return <TableRow>
                                    <CustomTableCell className={classes.rowHead} style={{
                                        background: Colors.white
                                    }} scope="row">{c}</CustomTableCell>
                                    {
                                        tdata.map((row, rowi) => {
                                            return <CustomTableCell className={classes.rowChild} scope="row">{row[c]}</CustomTableCell>
                                        })
                                    }
                                    <div id="bottom-of-list" ref={ref} />
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div>
    }
}

export default Styles.withStyles(styles)(TaionBan)