export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public create_date: string = "";
  public date_1: string = "";
  public date_2: string = "";
  public date_3: string = "";
  public date_4: string = "";
  public pat_name: string = "";
  public others_text: string = "";
}

export class Checks {
  public check_day_1_1: boolean = false;
  public check_day_1_10: boolean = false;
  public check_day_1_11: boolean = false;
  public check_day_1_12: boolean = false;
  public check_day_1_13: boolean = false;
  public check_day_1_14: boolean = false;
  public check_day_1_15: boolean = false;
  public check_day_1_16: boolean = false;
  public check_day_1_17: boolean = false;
  public check_day_1_18: boolean = false;
  public check_day_1_19: boolean = false;
  public check_day_1_2: boolean = false;
  public check_day_1_20: boolean = false;
  public check_day_1_21: boolean = false;
  public check_day_1_22: boolean = false;
  public check_day_1_23: boolean = false;
  public check_day_1_24: boolean = false;
  public check_day_1_25: boolean = false;
  public check_day_1_26: boolean = false;
  public check_day_1_27: boolean = false;
  public check_day_1_28: boolean = false;
  public check_day_1_29: boolean = false;
  public check_day_1_3: boolean = false;
  public check_day_1_30: boolean = false;
  public check_day_1_31: boolean = false;
  public check_day_1_32: boolean = false;
  public check_day_1_33: boolean = false;
  public check_day_1_34: boolean = false;
  public check_day_1_35: boolean = false;
  public check_day_1_36: boolean = false;
  public check_day_1_4: boolean = false;
  public check_day_1_5: boolean = false;
  public check_day_1_6: boolean = false;
  public check_day_1_7: boolean = false;
  public check_day_1_8: boolean = false;
  public check_day_1_9: boolean = false;
  public check_day_2_1: boolean = false;
  public check_day_2_10: boolean = false;
  public check_day_2_11: boolean = false;
  public check_day_2_12: boolean = false;
  public check_day_2_13: boolean = false;
  public check_day_2_14: boolean = false;
  public check_day_2_15: boolean = false;
  public check_day_2_16: boolean = false;
  public check_day_2_17: boolean = false;
  public check_day_2_18: boolean = false;
  public check_day_2_19: boolean = false;
  public check_day_2_2: boolean = false;
  public check_day_2_20: boolean = false;
  public check_day_2_21: boolean = false;
  public check_day_2_22: boolean = false;
  public check_day_2_23: boolean = false;
  public check_day_2_24: boolean = false;
  public check_day_2_25: boolean = false;
  public check_day_2_26: boolean = false;
  public check_day_2_27: boolean = false;
  public check_day_2_28: boolean = false;
  public check_day_2_29: boolean = false;
  public check_day_2_3: boolean = false;
  public check_day_2_30: boolean = false;
  public check_day_2_31: boolean = false;
  public check_day_2_32: boolean = false;
  public check_day_2_33: boolean = false;
  public check_day_2_34: boolean = false;
  public check_day_2_35: boolean = false;
  public check_day_2_36: boolean = false;
  public check_day_2_4: boolean = false;
  public check_day_2_5: boolean = false;
  public check_day_2_6: boolean = false;
  public check_day_2_7: boolean = false;
  public check_day_2_8: boolean = false;
  public check_day_2_9: boolean = false;
  public check_day_3_1: boolean = false;
  public check_day_3_10: boolean = false;
  public check_day_3_11: boolean = false;
  public check_day_3_12: boolean = false;
  public check_day_3_13: boolean = false;
  public check_day_3_14: boolean = false;
  public check_day_3_15: boolean = false;
  public check_day_3_16: boolean = false;
  public check_day_3_17: boolean = false;
  public check_day_3_18: boolean = false;
  public check_day_3_19: boolean = false;
  public check_day_3_2: boolean = false;
  public check_day_3_20: boolean = false;
  public check_day_3_21: boolean = false;
  public check_day_3_22: boolean = false;
  public check_day_3_23: boolean = false;
  public check_day_3_24: boolean = false;
  public check_day_3_25: boolean = false;
  public check_day_3_26: boolean = false;
  public check_day_3_27: boolean = false;
  public check_day_3_28: boolean = false;
  public check_day_3_29: boolean = false;
  public check_day_3_3: boolean = false;
  public check_day_3_30: boolean = false;
  public check_day_3_31: boolean = false;
  public check_day_3_32: boolean = false;
  public check_day_3_33: boolean = false;
  public check_day_3_34: boolean = false;
  public check_day_3_35: boolean = false;
  public check_day_3_36: boolean = false;
  public check_day_3_4: boolean = false;
  public check_day_3_5: boolean = false;
  public check_day_3_6: boolean = false;
  public check_day_3_7: boolean = false;
  public check_day_3_8: boolean = false;
  public check_day_3_9: boolean = false;
  public check_day_4_1: boolean = false;
  public check_day_4_10: boolean = false;
  public check_day_4_11: boolean = false;
  public check_day_4_12: boolean = false;
  public check_day_4_13: boolean = false;
  public check_day_4_14: boolean = false;
  public check_day_4_15: boolean = false;
  public check_day_4_16: boolean = false;
  public check_day_4_17: boolean = false;
  public check_day_4_18: boolean = false;
  public check_day_4_19: boolean = false;
  public check_day_4_2: boolean = false;
  public check_day_4_20: boolean = false;
  public check_day_4_21: boolean = false;
  public check_day_4_22: boolean = false;
  public check_day_4_23: boolean = false;
  public check_day_4_24: boolean = false;
  public check_day_4_25: boolean = false;
  public check_day_4_26: boolean = false;
  public check_day_4_27: boolean = false;
  public check_day_4_28: boolean = false;
  public check_day_4_29: boolean = false;
  public check_day_4_3: boolean = false;
  public check_day_4_30: boolean = false;
  public check_day_4_31: boolean = false;
  public check_day_4_32: boolean = false;
  public check_day_4_33: boolean = false;
  public check_day_4_34: boolean = false;
  public check_day_4_35: boolean = false;
  public check_day_4_36: boolean = false;
  public check_day_4_4: boolean = false;
  public check_day_4_5: boolean = false;
  public check_day_4_6: boolean = false;
  public check_day_4_7: boolean = false;
  public check_day_4_8: boolean = false;
  public check_day_4_9: boolean = false;
  public check_day_1_37: boolean = false;
  public check_day_1_38: boolean = false;
  public check_day_1_39: boolean = false;
  public check_day_1_40: boolean = false;
  public check_day_1_41: boolean = false;
  public check_day_1_42: boolean = false;
  public check_day_1_43: boolean = false;
  public check_day_1_44: boolean = false;
  public check_day_1_45: boolean = false;
  public check_day_1_46: boolean = false;
  public check_day_2_37: boolean = false;
  public check_day_2_38: boolean = false;
  public check_day_2_39: boolean = false;
  public check_day_2_40: boolean = false;
  public check_day_2_41: boolean = false;
  public check_day_2_42: boolean = false;
  public check_day_2_43: boolean = false;
  public check_day_2_44: boolean = false;
  public check_day_2_45: boolean = false;
  public check_day_2_46: boolean = false;
  public check_day_3_37: boolean = false;
  public check_day_3_38: boolean = false;
  public check_day_3_39: boolean = false;
  public check_day_3_40: boolean = false;
  public check_day_3_41: boolean = false;
  public check_day_3_42: boolean = false;
  public check_day_3_43: boolean = false;
  public check_day_3_44: boolean = false;
  public check_day_3_45: boolean = false;
  public check_day_3_46: boolean = false;
  public check_day_4_37: boolean = false;
  public check_day_4_38: boolean = false;
  public check_day_4_39: boolean = false;
  public check_day_4_40: boolean = false;
  public check_day_4_41: boolean = false;
  public check_day_4_42: boolean = false;
  public check_day_4_43: boolean = false;
  public check_day_4_44: boolean = false;
  public check_day_4_45: boolean = false;
  public check_day_4_46: boolean = false;
}
