import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class ImageBehaviour implements FormBehaviour {
    private _docId: number

    public constructor(docId:number) {
        this._docId = docId
    }

    public isPreviewable(): boolean { return false }
    public getId(): number { return this._docId }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient) {
        doc.patient = pat
    }

    public isFullWidth() {
        return false;
    }
    public createContent(): any {
        const res = new FormModel.Image.Core()
        return res
    }
    public shutten() { return null }
    public setUser(doc: Model.Document.Document, user: Model.User) {
    }
    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }

    public afterPost(doc: Model.Document.Document): void {
    }

    public createSucceed(doc: Model.Document.Document, reload: any): void {
        if (reload) reload();
    }

    public buildElements(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const elements_basic = [
            { name: "利用者", content: sa.patient("patient", "patientOpenDialog") },
            { name: "タイトル", content: sa.text("title") },
            { name: "メモ", content: sa.text("comment") },
        ]

        const elements_images = [
            {
                name: "写真", content: sa.docImage(10)
            },
            {
                name: "動画・PDF", content: sa.docFile(10)
            }
        ]

        const elements_download = [
            {
                name: "", content: <div>
                    <a href={`/doc_templates/${doc.document_type.dl_name_pdf}`} download>PDF</a>　/　
                    <a href={`/doc_templates/${doc.document_type.dl_name_excel}`} download>エクセル</a>
                </div>
            }
        ]

        let elements = [
            { name: '基本', content: elements_basic },
            { name: 'ファイル', content: elements_images }
        ]
        if (doc.document_type.is_download) {
            elements.push({ name: '雛形ダウンロード', content: elements_download })
        }

        return elements
    }
}
