export class Core {
  public values: Values = new Values();
  public checks: Checks = new Checks();
}

export class Values {
  public age: string = "";
  public apger_score: string = "";
  public bedroom: string = "";
  public byojo_impression: string = "";
  public byojo_impression2: string = "";
  public communication: string = "";
  public create_by: string = "";
  public create_date: string = "";
  public date_of_birth: string = "";
  public family: string = "";
  public fuan: string = "";
  public gender: string = "";
  public gestation_week: string = "";
  public has_syukaigo: string = "";
  public height: string = "";
  public height_on_child: string = "";
  public hoken_others: string = "";
  public ishikettei: string = "";
  public juhoken: string = "";
  public kaigo_hoken: string = "";
  public keii: string = "";
  public keizai: string = "";
  public kibou_own: string = "";
  public kibou_own2: string = "";
  public living_environment: string = "";
  public living_environment_others: string = "";
  public nichijo_jiritsu: string = "";
  public ninchi: string = "";
  public pat_name: string = "";
  public seiiku: string = "";
  public sick_name: string = "";
  public social_notices: string = "";
  public social_others: string = "";
  public syuhoken: string = "";
  public visit_frequency: string = "";
  public weight: string = "";
  public weight_on_child: string = "";
  public youkaigo: string = "";
  public ben: string = "";
  public ben_text: string = "";
  public chiryou: string = "";
  public fukubu_text: string = "";
  public has_inshoku: string = "";
  public has_shokuji: string = "";
  public has_suibun: string = "";
  public huon_text: string = "";
  public ichinichi_text: string = "";
  public inshoku: string = "";
  public iryou_kiki_others: string = "";
  public jintsu_text: string = "";
  public junkan: string = "";
  public kansen: string = "";
  public katsudou_text: string = "";
  public kiou_reki: string = "";
  public kokyu: string = "";
  public mukumi_text: string = "";
  public nyou: string = "";
  public nyou_text: string = "";
  public opioido_text: string = "";
  public others_text: string = "";
  public seiketsu_text: string = "";
  public senmou_text: string = "";
  public setsuemei: string = "";
  public shokuji: string = "";
  public shumi_text: string = "";
  public suibun: string = "";
  public suimin_text: string = "";
  public yakuzai_text: string = "";
  public zenshin_text: string = "";
  public backbed_text: string = "";
  public docter_call_text: string = "";
  public kango_text: string = "";
  public kenkou_others_text: string = "";
  public kongo_others_text: string = "";
  public gishi: string = "";
}

export class Checks {
  public check_hoken_others: boolean = false;
  public check_juhoken: boolean = false;
  public check_kaigo_hoken: boolean = false;
  public check_living_environment_1: boolean = false;
  public check_living_environment_2: boolean = false;
  public check_living_environment_3: boolean = false;
  public check_syuhoken: boolean = false;
  public check_fukubu: boolean = false;
  public check_huon: boolean = false;
  public check_iryou_kiki_1: boolean = false;
  public check_iryou_kiki_2: boolean = false;
  public check_iryou_kiki_3: boolean = false;
  public check_iryou_kiki_4: boolean = false;
  public check_iryou_kiki_5: boolean = false;
  public check_iryou_kiki_6: boolean = false;
  public check_iryou_kiki_7: boolean = false;
  public check_iryou_kiki_8: boolean = false;
  public check_iryou_kiki_others: boolean = false;
  public check_jintsu: boolean = false;
  public check_katsudou_1: boolean = false;
  public check_katsudou_2: boolean = false;
  public check_katsudou_3: boolean = false;
  public check_katsudou_4: boolean = false;
  public check_mukumi: boolean = false;
  public check_opioido: boolean = false;
  public check_others: boolean = false;
  public check_seiketsu_1: boolean = false;
  public check_seiketsu_2: boolean = false;
  public check_seiketsu_3: boolean = false;
  public check_seiketsu_4: boolean = false;
  public check_seiketsu_5: boolean = false;
  public check_seiketsu_7: boolean = false;
  public check_senmou: boolean = false;
  public check_yakuzai_1: boolean = false;
  public check_yakuzai_2: boolean = false;
  public check_yakuzai_3: boolean = false;
  public check_yakuzai_4: boolean = false;
  public check_zenshin: boolean = false;
  public check_kongo_1: boolean = false;
  public check_kongo_10: boolean = false;
  public check_kongo_11: boolean = false;
  public check_kongo_12: boolean = false;
  public check_kongo_13: boolean = false;
  public check_kongo_14: boolean = false;
  public check_kongo_15: boolean = false;
  public check_kongo_16: boolean = false;
  public check_kongo_2: boolean = false;
  public check_kongo_3: boolean = false;
  public check_kongo_4: boolean = false;
  public check_kongo_5: boolean = false;
  public check_kongo_6: boolean = false;
  public check_kongo_7: boolean = false;
  public check_kongo_8: boolean = false;
  public check_kongo_9: boolean = false;
}

export enum LivingType {
  None = "未選択",
  Value1 = "一戸建て",
  Value2 = "集合住宅（エレベーターあり）",
  Value3 = "集合住宅（エレベーターなし）",
}

export enum BedroomType {
  None = "未選択",
  Value1 = "特殊寝台",
  Value2 = "固定・簡易ベッド",
  Value3 = "布団",
  Value4 = "その他",
}
export enum KaigoHokenType {
  None = "未選択",
  Value1 = "1割",
  Value2 = "2割",
  Value3 = "3割",
}

export enum ShuHokenType {
  None = "未選択",
  Value1 = "後期",
  Value2 = "国保",
  Value3 = "社保",
  Value4 = "生保",
}

export enum JuHokenType {
  None = "未選択",
  Value1 = "難病医療費助成",
  Value2 = "障害者助成",
}

export enum IshiketteiType {
  None = "未選択",
  Value1 = "十分",
  Value2 = "部分的",
  Value3 = "あいまい",
}

export enum FuanType {
  None = "未選択",
  Value1 = "なし",
  Value2 = "あり",
}

export enum HaisetsuType {
  None = "未選択",
  Value1 = "トイレ",
  Value2 = "Pトイレ・尿器",
  Value3 = "失禁",
}
