import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';

const styles = () => Styles.createStyles({
    root: {
    },
    textField: {
        width: "90%"
    },
    input: {
        color: Colors.inputColor,
        "& input": {
            padding: "10px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto"
    }
})

interface CheckPair {
    label: string
    checked: boolean
    valueName: string
}

interface Props extends Styles.WithStyles {
    title: string,
    values: CheckPair[],
    onChange: (CheckPair) => void
}

class InputList extends React.Component<Props> { 
	displayName="InputList"
    public render() {
        const { classes, title } = this.props;
        return [
            <Grid key={title + "_1"} item xs={3} className={classes.row}>
                {title}
            </Grid>,
            <Grid key={title + "_2"} item xs={9}>
                {
                    this.props.values.map(c => 
                        <FormControlLabel
                            key={`check_${c.valueName}`}
                            control={
                                <Checkbox
                                    checked={c.checked}
                                    onChange={(e) => this.props.onChange(c)}
                                    value={c.label}
                                    color="primary"
                                />
                            }
                            label={c.label}
                        />
                    )
                }
            </Grid>
        ]
    }
}

export default Styles.withStyles(styles)(InputList)