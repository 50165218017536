import * as React from "react";
import * as Model from "../../models";
import * as FormModel from "../../models/Forms";
import { StoreAccessor } from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import * as FormBehaviour from "./FormBehaviour";
import * as HokanApi from "../../api/hokan";
import { Button, Grid } from '@material-ui/core';

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour {
  displayName = "Behaviour";

  public getId(): number {
    return 79;
  }

  public createContent(): any {
    const res = new FormModel.LifeD.Core();
    res.values.create_date = DateUtil.currentDateStr();
    return res;
  }

  public createDocumentType(): Model.Document.DocumentType {
    const res = new Model.Document.DocumentType();
    res.id = this.getId();
    return res;
  }

  public beforePost_pdf(doc: Model.Document.Document) {
    ["houmon", "tokubetsu", "kango", "sinnryou", "iryou"].forEach((v) => {
      const checked = doc.content.checks[`check_${v}`];

      if (
        checked === true &&
        doc.content.checks[`check_${v}_hospital`] === false &&
        doc.content.checks[`check_${v}_house_call_doctor`] === false
      ) {
        doc.content.checks[`check_${v}_unnecessary`] = true;
        return;
      }

      if (checked === false) {
        doc.content.checks[`check_${v}_hospital`] = false;
        doc.content.checks[`check_${v}_house_call_doctor`] = false;
        doc.content.checks[`check_${v}_unnecessary`] = false;
      }
    });
  }

  public beforeMount = () => {};
  public setPatient(
    doc: Model.Document.Document,
    pat: Model.Patient.Patient,
    sa: StoreAccessor
  ) {
    doc.patient = pat;
    HokanApi.me()
      .then(() => {
        sa.updateState();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
    const saV = sa.generateChild(["content", "values"]);
    const saC = sa.generateChild(["content", "checks"]);

    enum umu { none = "未選択", ari="有り", nashi="無し" }
    enum nrs { none ="未選択", a="1", b="2", c="3", d="4", e="5", f="6", g="7", h="8", i="9", j="10" }
    enum mito { none ="未選択", hi="日単位", shu="週単位", tuki="月単位" }
    const pf = ["1", "2", "3"]
    const genText = (name: string) => {
      return <Grid container={true}>
            {pf.map((x,i) => 
              <Grid key={i} item={true} xs={4} sm={4} lg={4}>
                 {saV.text(`${name}_${x}`, "", "text", "inline-flex", true, "2")}
              </Grid>
          )}
        </Grid>
    }
    const genList = (name: string, lis:any) => {
      return <Grid container={true}>
            {pf.map((x,i) => 
              <Grid key={i} item={true} xs={4} sm={4} lg={4}>
                {saV.list(`${name}_${x}`, lis)}
              </Grid>
          )}
        </Grid>
    }
    const elements_d = [
      {
        name: "月日",
        content: <Grid container={true}>
            {pf.map((x, i) =>
              <Grid key={i} item={true} xs={4} sm={4} lg={4}>
                {saV.date(`date_${x}`)}
              </Grid>
            )}
          </Grid>
      },
      { name: "記入者サイン", content: genText("sign") },
    ]
    const elements_t = [
      { name: "呼吸・HOT", content: genText("hot") },
      { name: "死前喘鳴", content: genList("zen", umu) },
      { name: "意識ﾚﾍﾞﾙ（ＪＣＳ）", content: genText("jcs") },
      { name: "末梢冷感", content: genList("rei", umu) },
      { name: "チアノーゼ", content: genText("chia") },
      { name: "浮腫/部位", content: genText("mukumi") },
      { name: "瘡形成", content: genText("sou") },
      { name: "IN 食事", content: genText("eat") },
      { name: "IN 水分", content: genText("water") },
      {
        name: "IN その他", content: <div>
          <div style={{ width: "200px" }}>{saV.text("in_other_free", "項目名")}</div>
          {genText("in_other")}
        </div>
      },
      { name: "OUT 尿量", content: genText("nyou") },
      { name: "OUT 便", content: genText("ben") },
      {
        name: "OUT その他", content: <div>
          <div style={{ width: "200px" }}>{saV.text("out_other_free", "項目名")}</div>
          {genText("out_other")}
        </div>
      },
      { name: "活動", content: genText("katsudou") },
      { name: "疼痛スケール\n（NRS）", content: genList("nrs", nrs) },
      { name: "薬剤師用", content: genText("yaku") },
      {
        name: "その他１", content: <div>
          <div style={{ width: "200px" }}>{saV.text("other_free_1", "項目名")}</div>
          {genText("other_free_1")}
        </div>
      },
      {
        name: "その他２", content: <div>
          <div style={{ width: "200px" }}>{saV.text("other_free_2", "項目名")}</div>
          {genText("other_free_2")}
        </div>
      },
      {
        name: "その他３", content: <div>
          <div style={{ width: "200px" }}>{saV.text("other_free_3", "項目名")}</div>
          {genText("other_free_3")}
        </div>
      },
      { name: "見通し", content: genList("mitooshi", mito) }
    ]

    const genC = (title:string, name:string) => {
      return [
        <Grid item={true} xs={6} sm={6} lg={6} style={{ margin: "auto", textAlign: "right" }}>
          {title}
        </Grid>,
        <Grid item={true} xs={6} sm={6} lg={6}>
          {saC.check(`${name}_1`)}
          {saC.check(`${name}_2`)}
          {saC.check(`${name}_3`)}
        </Grid>
      ]      
    }

    const genSonota = (title: string, naiyou: string, check: string) => {
      return [
        <Grid item={true} xs={6} sm={6} lg={6} style={{ margin: "auto", textAlign: "right" }}>
          {saV.text(naiyou, "目標")}
        </Grid>,
        <Grid item={true} xs={6} sm={6} lg={6}>
          {saC.check(`${check}_1`)}
          {saC.check(`${check}_2`)}
          {saC.check(`${check}_3`)}
        </Grid>
      ]
    }

    const st = {}

    const element_check_h = [
      {
        name: "疼痛", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("安静時の痛みがない。緩和されている。", "check_hon_1")}
            {genC("体動時の痛みがない。緩和されている。", "check_hon_2")}
            {genC("疼痛で睡眠が障害されない。", "check_hon_3")}
          </Grid>
        </div>
      },
      {
        name: "呼吸困難\n気道分泌", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("呼吸困難が本人にとって苦痛な症状ではない。", "check_hon_30")}
            {genC("過度の気道分泌や咳嗽が本人にとって問題に", "check_hon_4")}
          </Grid>
        </div>
      },
      {
        name: "腹部膨満\n嘔気・嘔吐", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("嘔気が本人にとって苦痛な症状ではない。", "check_hon_5")}
          </Grid>
        </div>
      },
      {
        name: "眠気\n全身倦怠感", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("眠気や全身倦怠感によって苦痛が生じていない。", "check_hon_6")}
          </Grid>
        </div>
      },
      {
        name: "浮腫", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("浮腫による苦痛が生じていない。", "check_hon_7")}
          </Grid>
        </div>
      },
      {
        name: "掻痒感", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("掻痒感が本人にとって苦痛な症状ではない。", "check_hon_8")}
          </Grid>
        </div>
      },
      {
        name: "その他１", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genSonota("free_title_1", "free_text_1", "check_hon_9")}
          </Grid>
        </div>
      },
      {
        name: "その他２", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genSonota("free_title_2", "free_text_2", "check_hon_10")}
          </Grid>
        </div>
      },
      {
        name: "不安・\n抑うつ", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("不安・抑うつ状態で日常生活に支障をきたしていない。", "check_hon_11")}
          </Grid>
        </div>
      },
      {
        name: "不穏・興奮", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("強い不穏、混乱、興奮状態ではない。", "check_hon_12")}
          </Grid>
        </div>
      },
      {
        name: "せん妄\n認知症", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("せん妄で日常生活に支障をきたしていない。", "check_hon_13")}
          </Grid>
        </div>
      },
      {
        name: "睡眠・休息", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("不眠で日常生活に支障をきたしていない。", "check_hon_14")}
          </Grid>
        </div>
      },
      {
        name: "その他３", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genSonota("free_title_3", "free_text_3", "check_hon_15")}
          </Grid>
        </div>
      },
      {
        name: "その他４", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genSonota("free_title_4", "free_text_4", "check_hon_16")}
          </Grid>
        </div>
      },
      {
        name: "活動・移動", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("自由に体を動かせる。\nまた希望する場所に安全に移動できる。", "check_hon_17")}
          </Grid>
        </div>
      },
      {
        name: "身体ケア", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("身体が清潔である。", "check_hon_18")}
          </Grid>
        </div>
      },
      {
        name: "口腔ケア", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("口腔内が清潔である。", "check_hon_19")}
          </Grid>
        </div>
      },
      {
        name: "排尿ケア", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("排尿に関して快適である。", "check_hon_20")}
          </Grid>
        </div>
      },
      {
        name: "排便ケア", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("排便に対して快適である", "check_hon_21")}
          </Grid>
        </div>
      },
      {
        name: "飲食栄養", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("水分や食事が取れていることに満足している。", "check_hon_22")}
          </Grid>
        </div>
      },
      {
        name: "皮膚創傷", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("皮膚トラブル予防や改善に向けて取り", "check_hon_23")}
          </Grid>
        </div>
      },
      {
        name: "投薬", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("すべての投薬が安全、正確に行われている。", "check_hon_24")}
          </Grid>
        </div>
      },
      {
        name: "その他５", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genSonota("free_title_5", "free_text_5", "check_hon_25")}
          </Grid>
        </div>
      },
      {
        name: "その他６", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genSonota("free_title_6", "free_text_6", "check_hon_26")}
          </Grid>
        </div>
      }
    ]
    const element_check_k = [
      {
        name: "家族等の\n病状理解", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("家族等が死が間近であることを知っている。", "check_kazoku_1")}
          </Grid>
        </div>
      },
      {
        name: "家族等の\nケア", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genC("家族等のニーズ（介護疲れや身体的精神的配慮の必要性）が満たされている。", "check_kazoku_2")}
          </Grid>
        </div>
      },
      {
        name: "その他１", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genSonota("free_title_7", "free_text_7", "check_kazoku_3")}
          </Grid>
        </div>
      },
      {
        name: "その他２", content: <div style={st}>
          <Grid container={true} spacing={0}>
            {genSonota("free_title_8", "free_text_8", "check_kazoku_4")}
          </Grid>
        </div>
      }
    ]

    const elements_cont = [
      { name: "医師", content: genList("ishi", umu) },
      { name: "ケアマネ", content: genList("care_mane", umu) },
      { name: "ケアチーム", content: genList("care_team", umu) },
    ]

    const elements = [
      { name: "月日", content: elements_d },
      { name: "内容", content: elements_t },
      { name: "本人への支援", content: element_check_h },
      { name: "家族等への支援", content: element_check_k },
      { name: "連携・調整", content: elements_cont }
    ];

    return elements;
  }
}
