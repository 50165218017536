import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour   { 
	displayName="Behaviour"
    
    public getId(): number {
        return 25
    }

    public createContent(): any {
        const res = new FormModel.ACPJinsei.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }


    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor)
    {
        const saV = sa.generateChild(["content", "values"])

        const elements_scores = [
            {
                name: "１", content: <div>
                    あなたの身体のことや治療内容について医師からどのように説明を受けていますか？<br />
                    またそれを受けてどう思いましたか？
                    {saV.text("text_1")}
                    </div>
            },
            {
                name: "２", content: <div>
                    これまでやこれからについて、不安なことや疑問、気がかりなことはありますか？
                    {saV.text("text_2")}
                    </div>
            },
            {
                name: "３", content: <div>
                    差支えなければ、これまでの人生で思い出深い大切な出来事があれば理由と併せて教えてください。
                    {saV.text("text_3")}
                    </div>
            },
            {
                name: "４", content: <div>
                    あなたが今後の生活で大事にしたいこと、希望、やってみたいことは何かありますか？
                    {saV.text("text_4")}
                    </div>
            },
            {
                name: "５", content: <div>
                    体調に変化があった時も含めて、これからどう過ごしていきたいか、共に考えてみましょう
                    {saV.text("text_5")}
                    </div>
            },
        ]

        const elements = [
            { name: '入力', content: elements_scores },
        ]

        return elements
    }
}
