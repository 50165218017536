import * as React from 'react';
import * as Colors from './Colors'
import * as Styles from '@material-ui/core/styles';

const styles = () => Styles.createStyles({
    root: {
    },
    input: {
        color: Colors.inputColor,
        padding: "0px",
        "& input": {
            padding: "5px",
            width: "80px"
        },
        "& textarea": {
            padding: "5px"
        }
    },
    grid: {

    },
    row: {
        color: Colors.primaryColor,
        textAlign: "right",
        margin: "auto",
    }
})

interface Props extends Styles.WithStyles {
    editMode: boolean,
    name: string,
    value: any,
    label: string,
    step: number,
    display: string,
    setData: (name:string, value:any) => void,
    min: number,
    max: number
}

class EditableSlider extends React.Component<Props> { 
	displayName="EditableSlider"
    public static defaultProps = {
        display: "block"
    }
    public render() {
        const { value, name } = this.props;
        return <div key={`edt_${name}`} style={{width:"95%", display:this.props.display}}>
                <input
                    style={{width:"95%"}}
                    type="range"
                    min={this.props.min} 
                    max={this.props.max}
                    value={value}
                    disabled={this.props.editMode==false}
                    onChange={(e) => this.props.setData(name, e.target.value)}
                    step={this.props.step}
            />
        </div>
    }
}

export default Styles.withStyles(styles)(EditableSlider)