import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import * as Colors from '../Colors'
import Loading from '../Loading'
import SubTitle from '../SubTitle'
import { toInjector, Problem } from '../../stores';
import { inject, observer } from 'mobx-react';
import { AssessmentComponent, AssessmentProps } from './AssessmentComponent'
import * as Hash from './Hash'
import GoPrevNext from './GoPrevNext'
import * as Model from '../../models'
import Col2Table from '../Col2Table';
import StandardTable from '../StandardTable';
import { Grid, Button } from '@material-ui/core';
import { StoreAccessor } from '../../util/StoreUtil';
import { DateUtil } from '../../util/DateUtil';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    list: {
        marginBottom: "20px"
    },
    itemHeader: {
        background: Colors.primaryColor,

    },
    headerText: {
        "& span": {
            color: Colors.white,
            fontSize: "1.1em"
        }
    },
    item: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        padding: "5px",
        fontWeight: 700,
        '&:hover': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:first-child": {


        }
    },
    itemText: {
        paddingRight: "0px",
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
});

interface Props extends Styles.WithStyles, AssessmentProps, Problem {
    postAssessment: Model.Assessment.PostAssessment;
    commit: () => void;
    sa: StoreAccessor;
}

interface LocalState {
    checked: { [index: number]: boolean; }
}

@inject(...toInjector(Problem))
@observer
class Modifier extends AssessmentComponent<Props, LocalState> {
    displayName = "Modifier"
    constructor(props: Props) {
        super(props)
        this.state = {
            checked: {}
        }
    }

    public componentDidMount = async () => {
        this.props.problem!.loadProblem(this.props.problem_id)
        this.props.sa.setValueC("next_assessment_day", DateUtil.currentDateStr(),["assessment"])
        window.scrollTo(0, 0)
    }

    private goPrev = () => {
        this.props.gotoPage(Hash.Intervention)
    }

    public render() {
        const { classes } = this.props
        const problem = this.props.problem!.problem
        const loading = (!problem || this.props.problem!.loading)
        if (loading) {
            return <Loading />
        }
        const ass = this.props.postAssessment;
        const symptoms = problem!.symptom;
        const intv = problem!.intervention;
        const disabled = (!ass.assessment.mokuhyou || ass.assessment.mokuhyou == ""
            || !ass.assessment.assessment_day || ass.assessment.assessment_day == "")
        const getKbsSentence = (score, kbs) => {
            return Model.Assessment.Assessment.getKBSSentence(ass.assessment, kbs, score)
        }

        const elm = [
            { name: "問題名", content: <div>{problem!.name}</div> },
            {
                name: "症状・兆候", content: <div className={classes.innerTable} >
                    <div>
                        {ass.symptoms.map(id => {
                            const t = symptoms.find(x => x.id == id)
                            return t ? t.detail : ""
                        })}
                    </div>
                    <div>
                        {ass.free_symptoms}
                    </div>
                </div>
            },
            { name: "優先度", content: <div>{ass.assessment.priority == 1 ? "高" : "低"}</div> },
            { name: "修飾因子①", content: <div>{ass.assessment.modifiers1}</div> },
            { name: "修飾因子②", content: <div>{ass.assessment.modifiers2}</div> },
            {
                name: "KBS", content: <div className={classes.innerTable} >
                    <Col2Table disableBGColor={true} body={[
                        { name: "知識(K)", content: <div>{ass.assessment.kbs_score_k}：{getKbsSentence(ass.assessment.kbs_score_k, "K")}</div> },
                        { name: "行動(B)", content: <div>{ass.assessment.kbs_score_b}：{getKbsSentence(ass.assessment.kbs_score_b, "B")}</div> },
                        { name: "状態(S)", content: <div>{ass.assessment.kbs_score_s}：{getKbsSentence(ass.assessment.kbs_score_s, "S")}</div> },
                    ]} />
                </div>
            },
            {
                name: "介入", content: <div className={classes.innerTable} >
                    <Col2Table disableBGColor={true} body={
                        ass.interventions.map(it => {
                            const s = intv.find(x => x.id == it.id);
                            return { name: s ? s!.category : "", content: <div>{s ? s!.target_category : ""}:{s ? s!.target : ""} / {it.comment}</div> }
                        })
                    } />
                </div>
            },
            { name: "短期目標", content: this.props.sa.textC(["assessment"], "mokuhyou") },
            {
                name: "評価日", content: <div>
                    {this.props.sa.dateC(["assessment"], "assessment_day")}
                    <Button variant="outlined" color="default"
                        onClick={(e) => { this.props.sa.setValueC("assessment_day", DateUtil.currentDateStr(), ["assessment"]) }}>
                        本日を設定
                    </Button>
                </div>
            },
            {
                name: "次回評価日", content: <div>
                    {ass.assessment.closed ? null : this.props.sa.dateC(["assessment"], "next_assessment_day")}
                    {this.props.sa.checkC(["assessment"], "closed", "終了")}
                </div>
            },
        ]

        return <div className={classes.root}>
            <SubTitle title="確認"
                text="入力内容を確認してください"
            />
            <StandardTable title="入力内容">
                <Grid container={true} spacing={0}>
                    <Grid item={true} xs={12} md={12} lg={12}>
                        <Col2Table body={elm} />
                    </Grid>
                </Grid>
            </StandardTable>
            {
                disabled ?
                    <div style={{ color: "red" }}>
                        「短期目標」と「評価日」は必須です。
                    </div>
                    : null
            }
            <GoPrevNext
                disableNext={disabled}
                onPrev={() => this.goPrev()}
                onNext={() => this.props.commit()} />
        </div>
    }
}

export default Styles.withStyles(styles)(Modifier)