import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../../components/Page'
import * as Model from '../../models'
import Col2Table from '../../components/Col2Table';
import * as HokanApi from '../../api/hokan'
import {
    Grid, Button, Dialog, DialogTitle, Table, TableBody, TableRow, TableCell, withStyles, Select, MenuItem
} from '@material-ui/core';
import { StoreAccessor } from '../../util/StoreUtil';
import SubTitle from '../../components/SubTitle';
import FormBehaviour from '../../components/form_behaviour/FormBehaviour';
import * as Colors from '../../components/Colors'
import DateUtil from '../../util/DateUtil';
import SimpleDialog from '../../components/SimpleDialog';
import Loading from '../../components/Loading';
import classNames from 'classnames';

const styles = () => Styles.createStyles({
    root: {
        margin: "0px",
        width: "100%",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    innerTable: {
        padding: '5px'
    },
    calendar_houkokusho: {
        display: "inline-block"
    },
    tooltipRoot: {
        display: "inline-block",
        marginLeft: "10px"
    },
    tooltipDiv: {
        fontSize: "0.9rem",
        color: "white"
    },
    tooltipTitle: {
        fontWeight: "bold",
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    tooltipLine: {
        borderTop: "solid",
        borderWidth: "1px",
        borderColor: "lightgray",
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none"
        }
    },
    button: {
        margin: "10px"
    },
    calendar: {
        margin: "20px"
    },
    calendarLabel: {
        margin: 0,
        borderRadius: "5px",
        backgroundColor: Colors.primaryColor,
        color: "white"
    },
    colTitle: {
        backgroundColor: Colors.backColor,
        textAlign: "center",
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.secondaryColor
    }
});

interface Props extends PageProps, Styles.WithStyles {
    behaviour: FormBehaviour
    src: Model.Document.Document | null
    onCreate: (doc: Model.Document.Document) => void
    onDelete: () => void
    onCansel: () => void
    canTempSave: boolean
    patientId?: number
    targetMonth: number | null
    readOnly: boolean
}

interface LocalState {
    document: Model.Document.Document
    loading: boolean
    patientOpenDialog: boolean
    posting: boolean
    errormsg: string
    openDelete: boolean
    openPastCopy: boolean
    pastDocs: Model.Document.Document[]
    cur_log: number | null
    cur_doc: Model.Document.Document | null
}

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

// @inject()
// @observer
class FormBasePage extends React.Component<Props, LocalState> {
    displayName = "FormBasePage"
    public static defaultProps = {
        patientId: undefined,
        targetMonth: null,
        readOnly: false,
        onCansel: () => { },
        openPastCopy: false,
        pastDocs: []
    }

    constructor(props: Props) {
        super(props)

        let doc = this.props.src
        if (doc == null) {
            doc = new Model.Document.Document()
            doc.content = this.props.behaviour.createContent()
            doc.document_type = this.props.behaviour.createDocumentType()
            doc.target_month = this.props.targetMonth
            if (this.props.targetMonth) {
                doc.content.values.create_date = DateUtil.fromTargetMonthNum(this.props.targetMonth)
            }
        }
        if (!doc.content) {
            doc.content = this.props.behaviour.createContent()
        }
        this.state = {
            document: doc,
            loading: false,
            patientOpenDialog: false,
            posting: false,
            errormsg: "",
            openDelete: false,
            openPastCopy: false,
            pastDocs: [],
            cur_log: null,
            cur_doc: null
        }
    }

    componentDidMount() {
        const doc = this.state.document
        const sa = new StoreAccessor(this, true, "document")
        this.props.behaviour.beforeMount(this.state.document, sa)

        if (this.props.src == null) {
            if (this.props.patientId) {
                HokanApi.getPatient(this.props.patientId).then(async res => {
                    this.props.behaviour.setPatient(doc, await Model.Patient.Patient.load(res.data, true), sa)
                    this.setState({ document: doc })
                })
            }
            HokanApi.me().then(res => {
                this.props.behaviour.setUser(doc, res.data, sa);
                this.setState({ document: doc })
            })
        }
        else {
            if (this.props.patientId) {
                HokanApi.getPatient(this.props.patientId).then(async res => {
                    doc.patient = await Model.Patient.Patient.load(res.data, true)
                    this.setState({ document: doc })
                })
            }
            this.setState({ document: doc })
        }
        HokanApi.getDocumentType(doc.document_type.id).then(x => {
            doc.document_type = x.data
            this.setState({ document: doc })
        })
    }

    private loadLog(logId: any | null) {
        if (!this.state.document) return;
        const k = (this.state.cur_doc ? this.state.cur_doc : this.state.document).version_list.find(x => x.id == logId)
        this.setState({
            loading: true
        })
        if (!logId || !k) {
            setTimeout(() =>
                this.setState({
                    cur_log: null,
                    document: this.state.cur_doc ? this.state.cur_doc : this.state.document,
                    cur_doc: null,
                    loading: false
                }), 100);
            return;
        }

        HokanApi.getDocumentLog(this.state.document.id, logId)
            .then(x => {
                this.setState({
                    cur_log: logId,
                    document: x.data,
                    cur_doc: this.state.cur_doc ? this.state.cur_doc : this.state.document as any,
                    loading: false
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    private doPreview() {
        this.setState({ posting: true, errormsg: "お待ちください" })
        this.props.behaviour.beforePost(this.state.document)
        HokanApi.generatePdf(new Model.Document.DocumentPost(this.state.document)).then((res: any) => {
            this.setState({ posting: false, errormsg: "" })
            var blob = new Blob([res.data], { type: 'application/pdf' });
            var pdfURL = (window.URL || window.webkitURL).createObjectURL(blob);
            window.open(pdfURL, "_blank")
        }).catch(err => {
            this.setState({ posting: false, errormsg: "エラー" })
        })
        this.props.behaviour.afterPost(this.state.document)
    }

    private doPost(tempFlg: boolean) {
        this.setState({ posting: true, errormsg: "お待ちください" })
        this.props.behaviour.beforePost(this.state.document)
        this.state.document.is_temp = tempFlg
        if (this.props.src == null) {
            HokanApi.postDocument(new Model.Document.DocumentPost(this.state.document)).then((res: any) => {
                this.setState({ posting: false, errormsg: "OK" })
                if (res && res.data) {
                    const r = res.data;
                    Model.Document.Document.Init(r);
                    this.props.behaviour.createSucceed(r, () => {
                        this.props.onCreate(r)
                    })
                }
            }).catch(err => {
                this.setState({ posting: false, errormsg: "エラー：ファイルを確認してください" })
            })
        }
        else {
            HokanApi.putDocument(new Model.Document.DocumentPost(this.state.document)).then((res: any) => {
                this.setState({ posting: false, errormsg: "OK" })
                if (res && res.data) {
                    const r = res.data;
                    Model.Document.Document.Init(r);
                    this.props.onCreate(r)
                }
            }).catch(err => {
                this.setState({ posting: false, errormsg: "エラー：ファイルを確認してください" })
            })
        }
        this.props.behaviour.afterPost(this.state.document)
    }

    private commitDelete() {
        if (this.props.src != null) {
            this.setState({ posting: true, errormsg: "お待ちください" })
            this.props.behaviour.beforePost(this.state.document)
            this.state.document.is_active = false;

            HokanApi.putDocument(new Model.Document.DocumentPost(this.state.document)).then((res: any) => {
                this.setState({ posting: false, errormsg: "OK" })
                this.props.onDelete()
            }).catch(err => {
                this.setState({ posting: false, errormsg: "エラー" })
            })
            this.props.behaviour.afterPost(this.state.document)
        }
    }

    private copyDoc(doc: Model.Document.Document) {
        const c = this.state.document.content
        if (c && c.values && c.values.create_date && c.values.calendar_from && c.values.calendar_to) {
            const cd = c.values.create_date;
            const st = c.values.calendar_from
            const en = c.values.calendar_to
            this.state.document.content = doc.content
            this.state.document.content.values.create_date = cd
            this.state.document.content.values.calendar_from = st
            this.state.document.content.values.calendar_to = en
        }
        else if (c && c.values && c.values.create_date) {
            const cd = c.values.create_date;
            this.state.document.content = doc.content
            this.state.document.content.values.create_date = cd
        }
        else {
            this.state.document.content = doc.content
        }
    }

    private loadPast() {
        HokanApi.getDocuments(0, 5, {
            patientId: this.props.patientId,
            typeId: this.state.document.document_type.id
        }).then(res => {
            res.data.documents.forEach(x => {
                if (x && x.content) {
                    x.content = JSON.parse(x.content as any)
                }
            })
            this.setState({
                pastDocs: res.data.documents
            });
        })
            .catch(e => {
                console.log(e);
            })
    }

    private close() {
        this.setState({ openPastCopy: false })
    }

    public render() {
        const { classes, behaviour } = this.props
        const doc = this.state.document
        if (!doc) return <Loading />
        const sa = new StoreAccessor(this, true, "document")
        const elements = behaviour.buildElements(doc, sa, classes)
        if (this.props.readOnly == false && this.props.src && this.state.cur_log == null) {
            elements.push(
                {
                    name: '削除', content: [
                        {
                            name: "削除", content:
                                <Button variant="contained" style={{ margin: "10px" }} color="secondary"
                                    onClick={(e) => {
                                        this.setState({
                                            openDelete: true
                                        })
                                    }}>
                                    削除
                                </Button>
                        },
                    ]
                },
            )
        }
        const cannnotCommit = doc.cannotCommit
        const canTempSave = doc.canTempSave
        const vl = (this.state.cur_doc ? this.state.cur_doc : this.state.document).version_list

        return < div className={classes.root} style={{ paddingTop: behaviour.isFullWidth() ? "70px" : "0px" }} >
            <SubTitle title={doc.document_type.name} />
            <Button className={classes.button}
                disabled={this.state.posting}
                variant="contained" color="default" onClick={(e) => {
                    this.loadPast()
                    this.setState({ openPastCopy: true })
                }}>
                前回コピー
            </Button >
            {
                this.state.openPastCopy ?
                    <Dialog
                        className={classes.dialog}
                        onClose={(x) => this.close()}
                        fullWidth={true}
                        maxWidth="xl"
                        open={true}
                        classes={{ paperFullWidth: classes.dialogCustomizedWidth } as any}
                        style={{ padding: "10px" }}>
                        <DialogTitle>コピー元を選択</DialogTitle>
                        <Button variant="outlined" color="default"
                            onClick={(e) => this.close()}>
                            戻る
                        </Button>
                        <Table className={classes.table}>
                            <TableBody>
                                {
                                    this.state.pastDocs.map((doc, ki) => {
                                        return <TableRow key={ki} className={classes.row}
                                            onClick={(e) => { this.copyDoc(doc); this.close() }}>
                                            <CustomTableCell style={{ textAlign: "center", padding: "5px" }} component="th" scope="row">
                                                <div className={classes.title}>
                                                    {
                                                        doc.content && doc.content.values && doc.content.values.create_date ?
                                                            DateUtil.toJpDateString(doc.content.values.create_date)
                                                            : DateUtil.toJpDateTimeString(DateUtil.dateToString(doc.created_at))
                                                    }
                                                </div>
                                            </CustomTableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Dialog>
                    : null
            }
            <Grid container={true} spacing={0}>
                {
                    elements.map((elm, elmi) => {
                        return <Grid key={`elm_${elmi}`} item={true} xs={12} md={12} lg={12}>
                            <div style={{ fontWeight: "bold", fontSize: "larger", marginTop: "10px" }}>{elm.name}</div>
                            <div style={{ paddingLeft: "5px", paddingBottom: "5px" }}>
                                <Col2Table body={elm.content} />
                            </div>
                        </Grid>
                    })
                }
            </Grid>
            <div style={{ marginBottom: "10px", textAlign: "right" }}>
                <Select
                    value={this.state.cur_log ? this.state.cur_log : 0}
                    autoWidth={true}
                    displayEmpty={true}
                    variant='outlined'
                    onChange={(e) => this.loadLog(e.target.value as any)}
                    className={classes.input}
                >
                    <MenuItem key={`cur_log_null`} value={0}>編集履歴(現在)</MenuItem>
                    {
                        vl ?
                            vl.map((t, idx) =>
                                t.data ?
                                    <MenuItem key={`${t.id}_${idx}`} value={t.id}>{DateUtil.toJpDateTimeString(t.created_at)}{t.user} 更新前</MenuItem>
                                    :
                                    <MenuItem disabled={true} key={`${t.id}_${idx}`} value={t.id}>{DateUtil.toJpDateTimeString(t.created_at)}{t.user} 作成</MenuItem>
                            ) : null
                    }
                </Select>
            </div>
            {
                this.state.cur_log ? <div>履歴は修正できません</div> :
                    <div>
                        {
                            behaviour.isPreviewable() ?
                                < Button className={classes.button}
                                    disabled={this.state.posting}
                                    variant="contained" color="primary" onClick={(e) => { this.doPreview() }}>
                                    プレビュー
                                </Button > : null
                        }
                        {
                            this.props.readOnly ?
                                <Button className={classes.button}
                                    variant="contained" color="primary" onClick={(e) => { this.props.onCansel() }}>
                                    閉じる
                                </Button >
                                : (
                                    this.props.src == null || this.props.src.is_temp ?
                                        [
                                            this.props.canTempSave ?
                                                < Button className={classes.button}
                                                    disabled={(() => {
                                                        if(canTempSave !== undefined && canTempSave) return false
                                                        return cannnotCommit || this.state.posting
                                                    })()}
                                                    variant="contained" color="primary" onClick={(e) => { this.doPost(true) }}>
                                                    一時保存
                                                </Button >
                                                : null,
                                            < Button className={classes.button}
                                                disabled={cannnotCommit || this.state.posting}
                                                variant="contained" color="primary"
                                                onClick={(e) => { this.doPost(false) }}>
                                                確定
                                            </Button >
                                        ]
                                        :
                                        <Button className={classes.button}
                                            disabled={cannnotCommit || this.state.posting}
                                            variant="contained" color="primary" onClick={(e) => { this.doPost(false) }}>
                                            更新
                                        </Button >
                                )
                        }
                        <div style={{ color: "red" }}>
                            <div>{doc.errorMessage}</div>
                            <div>{this.state.errormsg}</div>
                        </div>
                        <div style={{
                            color: "gray",
                            textAlign: "left",
                            fontSize: "smaller",
                            margin: "10px"
                        }}>
                            {behaviour.shutten()}
                        </div>
                    </div>
            }
            <SimpleDialog
                title="削除確認"
                open={this.state.openDelete}
                deleteText="削除"
                hideOk={true}
                hideDelete={false}
                onCancel={() => this.setState({ openDelete: false })}
                onDelete={() => this.commitDelete()}
            >
                削除してよろしいでしょうか？
            </SimpleDialog>
        </div >
    }
}

export default withPageProps(Styles.withStyles(styles)(FormBasePage))
