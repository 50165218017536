import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import App from './App';
import './index.css';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import './registerServiceWorker';
import { Provider } from 'mobx-react';
import { AllStore as stores } from './stores'
import DevTools from 'mobx-react-devtools';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import * as Colors from './components/Colors'



const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.primaryColor,
      contrastText: 'white'
    },
    background: {
      default: "#fff"
    },
    text: {
      primary: Colors.primaryColor,
      secondary: Colors.textSecondaryColor
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: "none",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        padding: "0px",
        fontSize: "1rem"
      },
    },
    MuiSelect: {
      root: {
        whiteSpace: "pre-line !important" as any,
        padding: "5px 25px 5px 10px",
        fontSize: "0.9rem"
      }
    },
    MuiOutlinedInput: {
      root: {
        marginRight: "3px"
      }
    }
  },
  typography: {
    fontFamily: 'Noto Sans JP'
  },
  shadows: Array(25).fill('none') as any
})

/* ピッチインピッチアウトによる拡大縮小を禁止 */
//document.documentElement.addEventListener('touchstart', function (e) {
//  if (e.touches.length >= 2) { e.preventDefault(); }
//}, { passive: false });

/* ダブルタップによる拡大を禁止 */
//var t = 0;
//document.documentElement.addEventListener('touchend', function (e) {
//  var now = new Date().getTime();
//  if ((now - t) < 350) {
//    e.preventDefault();
//  }
//  t = now;
//}, false);

ReactDOM.render(
  <div>
    {process.env.NODE_ENV === 'development' ? <DevTools /> : null}
    <Provider {...stores}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </MuiThemeProvider>
    </Provider>
  </div>
  ,
  document.getElementById('root') as HTMLElement
);
//unregister();
