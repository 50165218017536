import EditableText from '../components/EditableText';
import DateUtil from './DateUtil';
import EditableCheckList from '../components/EditableCheckList';
import EditableList from '../components/EditableList';
import EditableSlider from '../components/EditableSlider';
import EditableValue from '../components/EditableValue';
import React from 'react';
import EditableList2 from '../components/EditableList2';
import { Checkbox, Button, Grid, Select, MenuItem, TextField } from '@material-ui/core';
import OrgSelectDialog from '../components/OrgSelectDialog';
import ServiceCodeSelectDialog from '../components/ServiceCodeSelectDialog';
import UserSelectDialog from '../components/UserSelectDialog';
import Col2Table from '../components/Col2Table';
import * as Colors from '../components/Colors'
import * as Model from '../models'
import * as FormModel from '../models/Forms'
import PatientSelectDialog from '../components/PatientSelectDialog';
import DocumentDialog from '../components/DocumentDialog';
import { ImageUtil } from '../util/ImageUtil'
import ImgsViewer from 'react-images-viewer'
import InputText from '../components/InputText';
import { JapaneseCalendarDateInput } from '../components/JapaneseCalendarDateInput';
const moment = require("moment")

const createObjectURL = (window.URL || (window as any).webkitURL).createObjectURL || (window as any).createObjectURL;


export class StoreAccessor {
    private parent: StoreAccessor | null = null
    private state: any
    private targetPath: string | null
    private target: object
    private owner: any
    private _callbackFunc?: (category: string[], name: string, value: any) => void = undefined

    private _isEditing: boolean
    private _categoryRoot: string[]

    public constructor(owner: any, isEditing: boolean, targetPath: string | null = null) {
        this.owner = owner
        this.state = this.owner.state
        this.targetPath = targetPath
        this.target = targetPath ? this.state[targetPath] : this.state
        this._isEditing = isEditing
        this._categoryRoot = []
    }

    public generateChild(category: string[]) {
        const res = new StoreAccessor(this.owner, this.isEditing, this.targetPath)
        res.categoryRoot = category
        res.parent = this
        return res;
    }

    public get isEditing() { return this._isEditing }
    public set isEditing(value: boolean) { this._isEditing = value }
    public get categoryRoot() { return this._categoryRoot }
    public set categoryRoot(path: string[]) { this._categoryRoot = path; }

    public set callBack(func: (category: string[], name: string, value: any) => void) { this._callbackFunc = func }

    public static getFlagNameC(cat: any[], name: string) {
        return `_dialog_${cat.reduce((a, b) => a + "_" + b, "")}_${name}`
    }
    public static getFlagName(name: string) {
        return StoreAccessor.getFlagNameC([], name)
    }

    public static getSaigaiFlagNames(target: Model.Patient.SaigaiKigu[]) {
        if (!target) target = []
        return target.map((t, i) => {
            if (t.type.toString() == "カニュレ器官") t.type = Model.Patient.SaigaiKiguType.Kanyure
            const tt = t.type
            return this.getFlagNameC(["doc_img_saigai_kigu"], tt)
        })
    }
    public getCat(obj, cat: string[]) {
        let res = obj;
        for (const x in cat) {
            res = res[cat[x]]
        }
        return res;
    }

    public hasValue(_cat: string[], name: string) {
        let res = this.target;
        if (!res) return false
        const cat = this.categoryRoot.concat(_cat)
        for (const x in cat) {
            if (cat[x] in res == false) return false
            res = res[cat[x]]
        }
        return name in res
    }

    public updateState() {
        let t: any = {}
        if (this.targetPath) {
            t[this.targetPath] = this.target
        }
        else {
            t = this.target
        }
        this.owner.setState(t)
        if (this.parent) this.parent.updateState()
    }

    public isEmptyValue(name: string, cat: any[]) {
        return StoreAccessor.isEmptyValue(this.getCat(this.target, this.categoryRoot.concat(cat))[name])
    }
    public static isEmptyValue(val: any) {
        if (Array.isArray(val)) {
            if (val.length == 0) return true;
            return !val.find(x => StoreAccessor.isEmptyValue(x) == false);
        }
        else {
            return (!val ||
                val == "" || val == "未選択" || val == "なし" ||
                val == "-" || val == "--" || val == "x"
            )
        }
    }

    public getValueC(name: string, cat: any[]) {
        return this.getCat(this.target, this.categoryRoot.concat(cat))[name]
    }

    public getValue(name: string) {
        return this.getValueC(name, [])
    }

    public setValueC(name: string, value: any, cat: any[]) {
        this.getCat(this.target, this.categoryRoot.concat(cat))[name] = value
        if (this._callbackFunc) {
            this._callbackFunc(cat, name, value);
        }
        this.updateState()
    }

    public setValue(name: string, value: any) {
        this.setValueC(name, value, []);
    }

    public selectOne(name: string, nameList: string[]) {
        nameList.forEach(x => {
            this.setValue(x, x == name)
        })
    }

    public getValueR(name: string) {
        return (this.owner.state as any)[name]
    }

    public setValueR(name: string, value: any) {
        let t: any = {}
        t[name] = value
        this.owner.setState(t)
    }
    public sliderC(cat: any[], name: string, label: string = "", step: number = 1, min: number = 0, max: number = 10) {
        return <EditableSlider
            label={label}
            editMode={this.isEditing}
            name={name}
            step={step}
            value={this.getValueC(name, cat)}
            min={min} max={max}
            setData={(n, v) => this.setValueC(n, v, cat)} />
    }

    public numberC(cat: any[], name: string, label: string = "", step: number = 1, converter: (((string) => string) | null) = null) {
        return <EditableValue
            label={label}
            editMode={this.isEditing}
            name={name}
            step={step}
            value={this.getValueC(name, cat)}
            setData={(n, v) => {
                this.setValueC(n, v, cat)
            }}
            converter={(n, v) => {
                if (converter) this.setValueC(n, converter(v), cat)
            }}
        />
    }

    public numberUnitC(cat: any[], name: string, name_unit: string,
        unit: { name: string, factor: number }[], step: number = 1) {
        const unitStr = this.getValueC(name_unit, cat)
        const findUnit = (n) => {
            return unit.find(x => x.name == n) || unit[0]
        }
        const u = findUnit(unitStr)

        const val = this.getValueC(name, cat)
        return <div>
            <EditableValue
                label=""
                editMode={this.isEditing}
                name={name}
                display="inline-flex"
                step={step}
                value={val ? Math.round(val * u.factor * 100) / 100 : null}
                setData={(n, v) => this.setValueC(n, v ? v / u.factor : null, cat)} />
            <EditableList
                label=""
                editMode={this.isEditing}
                name={name}
                display="inline-flex"
                list={unit.map(x => { return { key: x.name, value: x.name } })}
                value={this.getValueC(name_unit, cat)}
                setData={(n, v) => {
                    this.setValueC(name_unit, v, cat)
                }} />
        </div>
    }

    public textC(cat: any[], name: string, label: string = "", inputType: string = "text",
        display: string = "block", multiline: boolean = true, minHeight: string | null = null,
        onKeyDown: any = null) {
        return <EditableText
            label={label}
            display={display}
            editMode={this.isEditing}
            name={name}
            multiline={multiline}
            inputType={inputType}
            minHeight={minHeight}
            value={this.getValueC(name, cat)}
            setData={(n, v) => this.setValueC(n, v, cat)}
            onKeyDown={onKeyDown}
            path = {[this.targetPath].concat(cat.concat(name)).join('.')}
        />
    }
    public telC(cat: any[], name: string, label: string = "", inputType: string = "text",
        display: string = "block", multiline: boolean = true, minHeight: string | null = null) {
        if (this.isEditing) {
            return this.textC(cat, name, label, inputType, display, multiline, minHeight);
        }
        else {
            const tel = this.getValueC(name, cat)
            return StoreAccessor.linkTel(tel)
        }
    }
    public static linkTel(tel: string) {
        const tels = tel ? tel.split('\n') : []
        return tels.map((t, ti) => {
            return <div key={"tel_" + ti}><a href={`tel:${t}`}>{t}</a></div>
        })
    }
    public text(name: string, label: string = "", inputType: string = "text",
        display: string = "block", multiline: boolean = true, minHeight: string | null = null, onKeyDown: any = null) {
        return this.textC([], name, label, inputType, display, multiline, minHeight, onKeyDown)
    }

    public listRaw(name, list: { key: any, value: any }[], isCheck: boolean = false, isVertical: boolean = false, disabled: boolean = false) {
        return this.listRawC([], name, list, isCheck, isVertical, "block" , disabled)
    }

    public listRawC(cat: any[], name, list: { key: any, value: any }[], isCheck: boolean = false, isVertical: boolean = false, display: string = "block", disabled: boolean = false) {
        if (isCheck) {
            return <EditableCheckList
                editMode={this.isEditing}
                vertical={isVertical}
                list={list.map(x => { return { key: x.value, value: (this.getValue(name) == x.value), label: x.key } })}
                setData={(n, v) => this.setValue(name, n)} />
        }
        else {
            return <EditableList
                label=""
                editMode={this.isEditing}
                name={name}
                display={display}
                list={list}
                value={this.getValueC(name, cat)}
                setData={(n, v) => this.setValueC(n, v, cat)}
                disabled={disabled}
                />
        }
    }


    /* assert target is document */
    public docImage(maxN: number = 1) {
        const doc = this.target as Model.Document.Document
        const urls = doc.image_urls
        const files = doc.images
        return <div style={{ padding: "10px" }}>
            <Grid container={true} spacing={1}>
                {
                    urls.map((url, idx) => <Grid key={`img_${idx}`} item={true} xs={12} md={4} lg={4}>
                        <div>

                            <a href={url} target="_blank"><img src={url} width="200px" /></a>
                        </div>
                        <Button color="secondary" size="small" variant="outlined"
                            onClick={(e) => {
                                Model.Document.Document.removeImage(doc, idx)
                                this.updateState()
                            }}
                        >削除</Button>
                    </Grid>)
                }
            </Grid>
            <br />
            {
                urls.length < maxN ?
                    <input type="file" id="" ref="file"
                        accept="image/*"
                        multiple={true}
                        onChange={async (e) => {
                            const selectedFiles = e.target.files
                            if (selectedFiles) {
                                const len = selectedFiles.length
                                const urlList = urls
                                const fileList = files
                                const promiseList: any = []
                                for (let i = 0; i < len; ++i) {
                                    if (urlList.length >= maxN) break;
                                    const item = selectedFiles.item(i)
                                    if (item) {
                                        const f = await ImageUtil.compress(item)
                                        console.log(f)
                                        urlList.push(createObjectURL(f))
                                        fileList.push(f)
                                    }
                                }
                                this.setValue("images", fileList)
                                this.setValue("image_urls", urlList)
                            }
                        }} />
                    : <div>これ以上追加できません</div>
            }

        </div >
    }

    /* assert target is document */
    public docFile(maxN: number = 1) {
        const doc = this.target as Model.Document.Document
        const urls = doc.file_urls
        const files = doc.files
        return <div style={{ padding: "10px" }}>
            <Grid container={true} spacing={1}>
                {
                    urls.map((url, idx) => <div key={`dff_${idx}`}>
                        <div>
                            <a href={url} target="_blank">確認{doc.file_sizes.length > idx ? `（${DateUtil.toFileSizeString(doc.file_sizes[idx])}）` : ""}</a>
                            {doc.file_sizes.length > idx && DateUtil.isBigFile(doc.file_sizes[idx]) ? <span style={{ fontWeight: "bold", color: "#c93a40" }}>注：大きいファイル</span> : ""}
                        </div>
                        <Button color="secondary" size="small" variant="outlined"
                            onClick={(e) => {
                                Model.Document.Document.removeFile(doc, idx)
                                this.updateState()
                            }}
                        >削除</Button>
                    </div>)
                }
            </Grid>
            <br />
            {
                urls.length < maxN ?
                    <input type="file" id="" ref="file"
                        multiple={true}
                        onChange={(e) => {
                            const selectedFiles = e.target.files
                            if (selectedFiles) {
                                const len = selectedFiles.length
                                const urlList = urls
                                const fileList = files
                                for (let i = 0; i < len; ++i) {
                                    if (urlList.length >= maxN) break;
                                    const item = selectedFiles.item(i)
                                    if (item) {
                                        urlList.push(createObjectURL(item))
                                        fileList.push(item)
                                    }
                                }
                                this.setValue("files", fileList)
                                this.setValue("file_sizes", fileList.map(x => x.size))
                                this.setValue("file_urls", urlList)
                            }
                        }} />
                    : <div>これ以上追加できません</div>
            }

        </div >
    }

    public list(name: string, listEn: any, disabled: boolean = false) {
        return this.listC([], name, listEn, undefined, disabled)
    }

    public listC(cat: any[], name: string, listEn: any, display: string = "block", disabled: boolean = false) {
        return this.listRawC(cat, name, DateUtil.enumToArray(listEn), false, false, display, disabled)
    }

    public checkList(list: { label: string, key: string }[], vertical: boolean = false, onlyOne: boolean = false, showOnlyTrue: boolean = false) {
        return this.checkListC([], list, vertical, onlyOne, showOnlyTrue)
    }
    public checkListC(cat: any, list: { label: string, key: string }[], vertical: boolean = false, onlyOne: boolean = false, showOnlyTrue: boolean = false) {
        return <EditableCheckList
            editMode={this.isEditing}
            vertical={vertical}
            onlyOne={onlyOne}
            showOnlyTrue={showOnlyTrue}
            list={list.map(x => { return { key: x.key, value: this.getValueC(x.key, cat), label: x.label } })}
            setData={(n, v) => this.setValueC(n, v, cat)} />
    }


    public check(name: string, label: string = "") {
        return this.checkC([], name, label)
    }
    public checkC(cat: any[], name: string, label: string = "") {
        if (this.isEditing) {
            return <span style={{ width: "fit-content" }}><Checkbox
                color="primary"
                checked={this.getValueC(name, cat)}
                onChange={(n, v) => this.setValueC(name, v, cat)} />{label}</span>

        }
        else {
            return <span>{(label != "" ? label + "：" : "") + (this.getValueC(name, cat) ? "○" : "×")}</span>
        }
    }
    public checkCommentC(cat: any[], nameCheck: string, nameComment: string, title: string) {
        const checked = this.getValueC(nameCheck, cat)
        if (this.isEditing) {
            return <span><Checkbox
                color="primary"
                checked={checked}
                onChange={(n, v) => this.setValueC(nameCheck, v, cat)} />
                {checked ? [title, this.textC(cat, nameComment, "", "text", "inline-flex")] : null}
            </span>
        }
        else {
            return <span>{(checked ? "○:" + title + this.getValueC(nameComment, cat) : "")}</span>
        }
    }

    // for kiroku1 / ishiki
    public checkCommentC2(cat: any[], nameCheck: string, nameComment: string) {
        const checked = !this.getValueC(nameCheck, cat)
        if (this.isEditing) {
            return <span>良<Checkbox
                color="primary"
                checked={checked}
                onChange={(n, v) => this.setValueC(nameCheck, !v, cat)} />
                {checked ? null : this.textC(cat, nameComment, "備考", "text", "inline-flex")}
            </span>
        }
        else {
            return <span>{(checked ? "良" : this.getValueC(nameComment, cat))}</span>
        }
    }

    public checkComment(saV: StoreAccessor, nameCheck: string, nameComment: string, title: string) {
        const checked = this.getValue(nameCheck)
        if (this.isEditing) {
            return <span><Checkbox
                color="primary"
                checked={checked}
                onChange={(n, v) => this.setValue(nameCheck, v)} />
                {checked ? [title, saV.text(nameComment, "", "text", "inline-flex")] : null}
            </span>
        }
        else {
            return <span>{(checked ? "○:" + title + saV.getValue(nameComment) : "")}</span>
        }
    }

    public radio(list: { label: string, key: string }[]) {
        return <EditableList2
            editMode={this.state.isEditing}
            list={list.map(x => { return { ...x, value: this.getValue(x.key) } })}
            setData={(key) => {
                this.selectOne(key, list.map(x => x.key))
            }} />
    }

    public yesNoDontNeed(name: string) {
        const list = [
            { key: "no", value: this.getValue(name) == 0, label: "未完" },
            { key: "yes", value: this.getValue(name) == 1, label: "完了" },
            { key: "dont_need", value: this.getValue(name) == -1, label: "不要" }
        ]
        return <EditableCheckList
            editMode={this.isEditing}
            vertical={false}
            list={list}
            setData={(key, v) => {
                if (key == "no") this.setValue(name, 0)
                else if (key == "yes") this.setValue(name, 1)
                else if (key == "dont_need") this.setValue(name, -1)
            }} />
    }
    public yesNo(name: string) {
        const list = [
            { key: "no", value: this.getValue(name) == 0, label: "未完" },
            { key: "yes", value: this.getValue(name) == 1, label: "完了" }
        ]
        return <EditableCheckList
            editMode={this.isEditing}
            vertical={false}
            list={list}
            setData={(key, v) => {
                if (key == "no") this.setValue(name, 0)
                else if (key == "yes") this.setValue(name, 1)
            }} />
    }

    // aaa
    public date(name, isJapaneseCalendarInput?: boolean) {
        return this.dateC([], name, isJapaneseCalendarInput)
    }
    public dateC(cat: any[], name, isJapaneseCalendarInput?: boolean) {
        const b = this.getValueC(name, cat)
        if (this.isEditing == false) {
            return <div>{
                moment(b).isValid() ?
                    new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
                        era: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    }).format(moment(b).toDate())
                    : ""
            }
            </div>
        }
        else if(isJapaneseCalendarInput) {
            return (
                <JapaneseCalendarDateInput
                    value={this.getValueC(name, cat)}
                    setData={(v) => {
                        if (v == null || v == "") {
                            this.setValueC(name, "", cat)
                        }
                        else if (DateUtil.isValidDate(v)) {
                            this.setValueC(name, v, cat)
                        }
                    }}
                />
            )
        }
        else {
            return <div><EditableText
                label=""
                editMode={this.isEditing}
                name={name}
                inputType="date"
                display="inline-block"
                multiline={false}
                fullwidth={false}
                value={this.getValueC(name, cat)}
                setData={(n, v) => {
                    console.log(n)
                    console.log(v)
                    if (v == "" || v == null) {
                        this.setValueC(n, null, cat)
                        return
                    }
                    if (DateUtil.isValidDate(v)) {
                        this.setValueC(n, v, cat)
                    }
                    else if (v == null || v == "") {
                        this.setValueC(name, "", cat)
                    }
                }} />
            </div>
        }
    }
    private splitDt(datetime: string) {
        if (!datetime) return [0, 0];
        const dt = datetime.split('T')
        if (dt.length != 2) return [0, 0]
        return [dt[0], dt[1]]
    }
    private bindDt(date, time) {
        return `${date}T${time}`
    }
    public dateTime(name, setDateTarget?) {
        return <div>
            <EditableText
                label=""
                editMode={this.isEditing}
                name={name}
                fullwidth={false}
                display="inline-block"
                inputType="date"
                multiline={false}
                value={this.splitDt(this.getValue(name))[0]}
                setData={(n, v) => {
                    this.setValue(n, this.bindDt(
                        v,
                        this.splitDt(this.getValue(name))[1]
                    ))
                    if (setDateTarget) {
                        this.setValue(setDateTarget, this.bindDt(
                            v,
                            //this.splitDt(this.getValue(setDateTarget))[1]
                            this.splitDt(this.getValue(name))[1]
                        ))
                    }
                }} />
            {"　"}
            <EditableText
                label=""
                editMode={this.isEditing}
                name={name}
                fullwidth={false}
                inputType="time"
                display="inline-block"
                multiline={false}
                value={this.splitDt(this.getValue(name))[1]}
                setData={(n, v) => {
                    this.setValue(n, this.bindDt(
                        this.splitDt(this.getValue(name))[0],
                        v
                    ))
                    if (setDateTarget) {
                        this.setValue(setDateTarget, this.bindDt(
                            this.splitDt(this.getValue(name))[0],
                            v
                        ))
                    }
                }} />
        </div>
    }
    public month(name, display = "block") {
        return <EditableText
            label=""
            editMode={this.isEditing}
            name={name}
            fullwidth={false}
            inputType="month"
            multiline={false}
            display={display}
            value={this.getValue(name)}
            setData={(n, v) => this.setValue(n, v)} />
    }
    public orgTanto(name, patient_id?: number) {
        return this.orgTantoC([], name, patient_id)
    }

    public orgTantoC(cat: any[], name, patient_id?: number) {
        const flagName = StoreAccessor.getFlagNameC(cat, name)
        const target = this.getValueC(name, cat) as Model.OrganizationTanto
        const isValid = target != null
        let t: any = null
        let msg = "選択"
        const isNone = (s) => (!s || s == "")
        if (isValid && target.id != 0) {
            t = <span>
                {target['deleted'] ? (<span>[※マスタから削除されています]<br /></span>) : null}
                {target.org.name}<br />
                {target.name}({target.role})<br />
                <Col2Table disableBGColor={true} body={[
                    isNone(target.org.tel) ? null : { name: "代表TEL", content: <a href={`tel:${target.org.tel}`}>{target.org.tel}</a> },
                    isNone(target.org.fax) ? null : { name: "代表FAX", content: <a href={`tel:${target.org.fax}`}>{target.org.fax}</a> },
                    isNone(target.org.kinkyu_tel1) && isNone(target.org.kinkyu_tel1_comment) ? null : { name: "代表緊急1", content: <span><a href={`tel:${target.org.kinkyu_tel1}`}>{target.org.kinkyu_tel1}</a> {target.org.kinkyu_tel1_comment}</span> },
                    isNone(target.org.kinkyu_tel2) && isNone(target.org.kinkyu_tel2_comment) ? null : { name: "代表緊急2", content: <span><a href={`tel:${target.org.kinkyu_tel2}`}>{target.org.kinkyu_tel2}</a> {target.org.kinkyu_tel2_comment}</span> },
                    isNone(target.org.email) ? null : { name: "代表メール", content: <a href={`mailto:${target.org.email}`}>{target.org.email}</a> },
                    isNone(target.tel) ? null : { name: "携帯", content: <a href={`tel:${target.tel}`}>{target.tel}</a> },
                    isNone(target.fax) ? null : { name: "FAX", content: <a href={`tel:${target.fax}`}>{target.fax}</a> },
                    isNone(target.kinkyu_tel1) && isNone(target.kinkyu_tel1_comment) ? null : { name: "緊急1", content: <span><a href={`tel:${target.kinkyu_tel1}`}>{target.kinkyu_tel1}</a> {target.kinkyu_tel1_comment}</span> },
                    isNone(target.kinkyu_tel2) && isNone(target.kinkyu_tel2_comment) ? null : { name: "緊急2", content: <span><a href={`tel:${target.kinkyu_tel2}`}>{target.kinkyu_tel2}</a> {target.kinkyu_tel2_comment}</span> },
                    isNone(target.email) ? null : { name: "メール", content: <a href={`mailto:${target.email}`}>{target.email}</a> }
                ].filter(x => x != null) as any} />
            </span>
            msg = "変更"
        }

        return <div key={flagName}>
            {t}
            {
                this.isEditing ? [
                    '　',
                    <Button color="primary" size="small" variant="outlined"
                        onClick={(e) => this.setValueR(flagName, true)}
                        key={`orgTanto_button_${name}`}
                    >{msg}</Button>,
                    isValid ?
                        <Button color="secondary" size="small" variant="outlined"
                            onClick={(e) => this.setValueC(name, null, cat)}
                            key={`orgTanto_del_${name}`}
                        >削除</Button>
                        : null
                    ,
                    this.getValueR(flagName) ?
                        <OrgSelectDialog
                            key={`orgTanto_dialog_${name}`}
                            patientId={patient_id}
                            open={true}
                            onSelect={(org, tanto) => { this.setValueC(name, tanto, cat); this.setValueR(flagName, false) }}
                            onCancel={() => { this.setValueR(flagName, false) }}
                        />
                        : null
                ] : null
            }
        </div>
    }


    public serviceCodeC(cat: any[], name, check13: boolean, check63: boolean, check93: boolean, checkTeiki: boolean) {
        const flagName = StoreAccessor.getFlagNameC(cat, name)
        const target = this.getValueC(name, cat) as Model.ServiceCode
        const isValid = target != null && target.id != 0
        let t: any = null
        let msg = "選択"
        if (isValid) {
            t = <span>{target.code_type}{target.code_no}:{target.name}</span>
            msg = "変更"
        }

        return <div>
            {t}
            {
                this.isEditing ? [
                    '　',
                    <Button color="primary" size="small" variant="outlined"
                        onClick={(e) => this.setValueR(flagName, true)}
                        key={`orgTanto_button_${name}`}
                    >{msg}</Button>,
                    isValid ?
                        <Button color="secondary" size="small" variant="outlined"
                            onClick={(e) => this.setValueC(name, null, cat)}
                            key={`orgTanto_del_${name}`}
                        >削除</Button>
                        : null
                    ,
                    this.getValueR(flagName) ?
                        <ServiceCodeSelectDialog
                            key={`service_code_dialog_${name}`}
                            open={true}
                            onSelect={(code) => { this.setValueC(name, code, cat); this.setValueR(flagName, false) }}
                            onCancel={() => { this.setValueR(flagName, false) }}
                            default13={check13}
                            default63={check63}
                            default93={check93}
                            defaultTeiki={checkTeiki}
                        />
                        : null
                ] : null
            }
        </div>
    }

    public branch(name, canDelete = true) {
        return this.branchC([], name, canDelete)
    }

    public branchC(cat: any[], name, canDelete = true) {
        const flagName = StoreAccessor.getFlagNameC(cat, name)
        const target = this.getValueC(name, cat) as Model.User
        const isValid = target != null && target.id != 0
        let t: any = null
        let msg = "選択"
        if (isValid) {
            t = <span>{target.name}</span>
            msg = "変更"
        }

        return <span>
            {t}
            {
                this.isEditing ? [
                    '　',
                    <Button color="primary" size="small" variant="outlined"
                        onClick={(e) => this.setValueR(flagName, true)}
                        key={`user_button_${name}`}
                    >{msg}</Button>,
                    isValid && canDelete ?
                        <Button color="secondary" size="small" variant="outlined"
                            onClick={(e) => this.setValueC(name, null, cat)}
                            key={`user_del_${name}`}
                        >削除</Button>
                        : null
                    ,
                    this.getValueR(flagName) ?
                        <UserSelectDialog
                            key={`user_dialog_${name}`}
                            onlyBranch={true}
                            open={true}
                            onSelect={(branch, user) => { this.setValueC(name, branch, cat); this.setValueR(flagName, false) }}
                            onCancel={() => { this.setValueR(flagName, false) }}
                        />
                        : null
                ] : null
            }
            <br />
        </span>
    }
    public user(name, showDelete = true, labelHead = "") {
        return this.userC([], name, showDelete, labelHead)
    }

    public userC(cat: any[], name, showDelete = true, labelHead = "") {
        const flagName = StoreAccessor.getFlagNameC(cat, name)
        const target = this.getValueC(name, cat) as Model.User
        const isValid = target != null && target.id != 0
        let t: any = null
        let msg = labelHead + "選択"
        if (isValid) {
            t = <span>{target.name}</span>
            msg = labelHead + "変更"
        }

        return <span>
            {t}
            {
                this.isEditing ? [
                    '　',
                    <Button color="primary" size="small" variant="outlined"
                        onClick={(e) => this.setValueR(flagName, true)}
                        key={`user_button_${name}`}
                    >{msg}</Button>,
                    isValid && showDelete ?
                        <Button color="secondary" size="small" variant="outlined"
                            onClick={(e) => this.setValueC(name, null, cat)}
                            key={`user_del_${name}`}
                        >削除</Button>
                        : null
                    ,
                    this.getValueR(flagName) ?
                        <UserSelectDialog
                            key={`user_dialog_${name}`}
                            open={true}
                            onSelect={(branch, user) => { this.setValueC(name, user, cat); this.setValueR(flagName, false) }}
                            onCancel={() => { this.setValueR(flagName, false) }}
                        />
                        : null
                ] : null
            }
            <br />
        </span>
    }

    public hoken(doc_id: number, name, patId: number) {
        return this.hokenC(doc_id, [], name, patId)
    }

    public hokenC(doc_id: number, cat: any[], name, patId: number) {
        const flagName = StoreAccessor.getFlagNameC(cat, name)
        const target = this.getValueC(name, cat) as Model.Document.Document
        const isValid = target != null && target.id != 0
        let t: any = null
        let msg = "作成"
        if (isValid) {
            const content = target.content as FormModel.Hoken.Core
            //t = <div>
            //    保険区分：{content ? content.kubun : ""} <br />
            //    保険者番号：{content ? content.hokensha : ""} <br />
            //    被保険者番号：{content ? content.bango : ""} <br />
            //    記号番号：{content ? content.kigo : ""} <br />
            //    期限：{content ? DateUtil.toJpDateString(content.kigen_to) : ""} <br />
            //    コメント：{target.comment}</div>
            t = <div>
                期限：{content ? DateUtil.toJpDateString(content.kigen_to) : ""} <br />
                メモ：{target.comment}
                <div>
                    {target.image_urls ? target.image_urls.map((u, i) => {
                        return <div>
                            <a href={u} key={`file_${i}`} target="_blank"><img src={u} width="300px" /></a>
                        </div>
                    }) : null}
                    {target.file_urls ? target.file_urls.map((u, i) => {
                        return <div>
                            <a href={u} key={`file_${i}`} target="_blank">PDF</a>
                        </div>
                    }) : null}
                </div>
            </div>
            msg = "詳細"
        }

        return <div>
            {t}
            {
                this.isEditing ? [
                    '　',
                    <Button color="primary" size="small" variant="outlined"
                        onClick={(e) => this.setValueR(flagName, true)}
                        key={`hoken_del_${name}`}
                    >新規アップロード</Button>
                    ,
                    this.getValueR(flagName) ?
                        <DocumentDialog
                            patientId={patId}
                            only={doc_id}
                            open={true}
                            srcId={null}
                            onSelect={(doc) => {
                                this.setValueC(name, doc, cat)
                                this.setValueR(flagName, false)
                            }}
                            onCansel={() => { this.setValueR(flagName, false) }}
                        />
                        : null
                ] : null
            }
        </div>
    }

    public docWithPast(cat, name, docId, patId: number, pastPool: Model.KirokuII.PastDocumentPool) {

        return <div style={{ display: docId == 8 ? "block" : "flex" }}>
            {this.docC(cat, name, docId, patId)}
            {
                pastPool.has(cat, name) ?
                    this.docC(["pastDocumentPool", "_pool"], Model.KirokuII.PastDocumentPool.getPath(cat, name), docId, patId, true)
                    : null
            }
        </div>
    }
    public doc(name, docId, patId: number) {
        return this.docC([], name, docId, patId)
    }

    public docC(cat: any[], name, docId, patId: number, readOnly: boolean = false) {
        const flagName = StoreAccessor.getFlagNameC(cat, name)
        const stateImageName = StoreAccessor.getFlagNameC(cat, name + "_state")
        const target = this.getValueC(name, cat) as Model.Document.Document
        const imgs = (target && target.image_urls) ? target.image_urls.map((url, idx) => { return { src: url } }) : [];
        let stateImage = this.getValueR(stateImageName);
        if (!stateImage) {
            stateImage = {
                imgs: imgs,
                pos: 0,
                isOpen: false,
                currImg: 0
            }
        }
        stateImage.onClose = () => {
            this.setValueR(stateImageName, {
                ...stateImage,
                isOpen: false
            })
        }
        stateImage.onClickPrev = () => {
            this.setValueR(stateImageName, {
                ...stateImage,
                pos: (imgs.length + stateImage.pos - 1) % imgs.length
            })
        }
        stateImage.onClickNext = () => {
            this.setValueR(stateImageName, {
                ...stateImage,
                pos: (stateImage.pos + 1) % imgs.length
            })
        }

        const isValid = target != null && target.id != 0
        let t: any = null
        let msg = docId == 8 ? "撮影" : "作成"

        if (isValid) {
            t = target.comment
            if (docId == 8 /* 画像（利用者） */) {
                t = <div style={{
                    flexWrap: "wrap",
                    whiteSpace: "pre-line",
                    margin: "10px",
                    display: "flex"
                }}>
                    <div>
                        <div>
                            <ImgsViewer
                                imgs={stateImage.imgs}
                                currImg={stateImage.pos}
                                isOpen={stateImage.isOpen}
                                onClickPrev={stateImage.onClickPrev}
                                onClickNext={stateImage.onClickNext}
                                onClose={stateImage.onClose}
                            />
                        </div>
                        <div>
                            {target.image_urls && target.image_urls.length > 0 ? target.image_urls.map((u, i) => {
                                return <img key={`img_${i}`}
                                    onClick={(e) => {
                                        this.setValueR(stateImageName, {
                                            ...stateImage,
                                            isOpen: true,
                                            pos: i
                                        })
                                    }}
                                    src={u} width="150px" height="150px" style={{ objectFit: "cover", margin: "10px", cursor: "pointer" }}
                                />
                            }) : null}
                        </div>
                        {
                            target.file_urls && target.file_urls.length > 0 ?
                                <div style={{ margin: "15px" }}>
                                    {target.file_urls ? target.file_urls.map((u, i) => {
                                        return <div>
                                            <a href={u} key={`file_${i}`} target="_blank">動画・PDF等({i + 1}) {target.file_sizes.length > i ? `（${DateUtil.toFileSizeString(target.file_sizes[i])}）` : ""}</a>
                                            {target.file_sizes.length > i && DateUtil.isBigFile(target.file_sizes[i]) ? <span style={{ fontWeight: "bold", color: "#c93a40" }}>注：大きいファイル</span> : ""}
                                        </div>
                                    }) : null}
                                </div>
                                : null
                        }
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <div style={{ fontWeight: "bold", fontSize: "larger" }}>{target.title}</div>
                        <div style={{ marginLeft: "10px" }}>{target.comment}</div>
                    </div>
                </div>
            }
            else if (docId == 61) /* 褥瘡 */ {
                t = <div style={{
                    flexWrap: "wrap",
                    whiteSpace: "pre-line",
                    marginTop: "10px",
                    marginBottom: "10px",
                    borderColor: "gray"
                }}>
                    <div style={{ fontWeight: "bold", fontSize: "larger" }}>{target.content.values.bui}</div>
                    <div style={{ marginLeft: "10px" }}>{target.content.values.comment}</div>
                </div>
            }
            else {
                if ((target.title && target.title != "") || (target.comment && target.comment != "")) {
                    t = <div style={{
                        flexWrap: "wrap",
                        whiteSpace: "pre-line",
                        borderTop: "solid",
                        borderWidth: "1px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        borderColor: "gray"
                    }}>
                        <div style={{ fontWeight: "bold", fontSize: "larger" }}>{target.title}</div>
                        <div style={{ marginLeft: "10px" }}>{target.comment}</div>
                    </div>
                }
            }
            msg = readOnly ? "コピー元データを見る" : "詳細"
        }

        return <div>
            {t}
            {
                this.isEditing ? [
                    '　',
                    <Button color="primary" size="small" variant="outlined"
                        onClick={(e) => this.setValueR(flagName, true)}
                        key={`hoken_button_${name}`}
                    >{msg}</Button>,
                    isValid && !readOnly ?
                        <Button color="secondary" size="small" variant="outlined"
                            onClick={(e) => this.setValueC(name, null, cat)}
                            key={`hoken_del_${name}`}
                        >削除</Button>
                        : null
                    , this.getValueR(flagName) ?
                        <DocumentDialog
                            patientId={patId}
                            only={docId}
                            open={true}
                            readOnly={readOnly}
                            srcId={target ? target.id : null}
                            onSelect={(doc) => {
                                this.setValueC(name, doc, cat)
                                this.setValueR(flagName, false)
                            }}
                            onCansel={() => { this.setValueR(flagName, false) }}
                        />
                        : null
                ] : null
            }
        </div >
    }

    public patient(name, openFlagName, labelHead = "") {
        return this.patientC([], name, openFlagName, labelHead)
    }

    public patientC(cat: any[], name, openFlagName /* always on root */, labelHead = "") {
        const flagName = StoreAccessor.getFlagNameC(cat, name)
        const target = this.getValueC(name, cat) as Model.Patient.Patient

        const isValid = target != null && target.id != 0
        let t: any = null
        let msg = labelHead + "選択"
        if (isValid) {
            t = <span>{target.name}</span>
            msg = labelHead + "変更"
        }

        return <div>
            {t}
            {
                this.isEditing ? [
                    '　',
                    <Button color="primary" size="small" variant="outlined"
                        onClick={(e) => this.setValueR(flagName, true)}
                        key={`user_button_${name}`}
                    >{msg}</Button>,
                    isValid ?
                        <Button color="secondary" size="small" variant="outlined"
                            onClick={(e) => this.setValueC(name, null, cat)}
                            key={`user_del_${name}`}
                        >削除</Button>
                        : null
                    ,
                    this.getValueR(flagName) ?
                        <PatientSelectDialog
                            key={`patient_dialog_${name}`}
                            open={true}
                            onSelect={(patient) => {
                                this.setValueC(name, patient, cat);
                                this.setValueR(flagName, false)
                            }}
                            onCancel={() => { this.setValueR(flagName, false) }}
                        /> : null
                ] : null
            }
        </div>
    }

    public saigaiKiguC(cat: any[], name: string, patId: number, saDocTemp: StoreAccessor) {
        let target = this.getValueC(name, cat) as Model.Patient.SaigaiKigu[]
        if (!target) target = []

        const removeTarget = (idx) => {
            target = target.filter((x, i) => i != idx)
            this.setValueC(name, target, cat)
        }

        return <div>
            <Grid container={true} spacing={1}>
                {
                    target ? target.map((t, i) => {
                        if (t.type.toString() == "カニュレ器官") t.type = Model.Patient.SaigaiKiguType.Kanyure
                        const tt = t.type
                        const dtcat = cat.concat([name, i, "detail"])

                        return <Grid key={`saigai_kigu_t_${i}`} item={true} xs={12} md={6} lg={6}>
                            <div style={{
                                backgroundColor: Colors.primaryColor,
                                color: Colors.white,
                                textAlign: "center",
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px"
                            }}>{tt}</div>
                            <Col2Table disableBGColor={true} body={
                                _kiguDetail[tt].map(dt => {
                                    return { name: dt, content: this.textC(dtcat, dt) }
                                }).concat(
                                    { name: "画像", content: saDocTemp.docC(["doc_img_saigai_kigu"], tt, 8, patId) }
                                ).concat(
                                    this.isEditing ?
                                        [{
                                            name: "削除", content: <Button color="secondary" size="small" variant="outlined"
                                                onClick={(e) => { removeTarget(i) }} key={`target_del_${i}`}
                                            >削除</Button>
                                        }] : []
                                )
                            } />
                        </Grid>
                    }) : null
                }
            </Grid>
            <div style={{ marginTop: "15px" }}>
                {
                    this.isEditing ?
                        <EditableList
                            label=""
                            editMode={this.isEditing}
                            name={name}
                            list={DateUtil.enumToArray(Model.Patient.SaigaiKiguType)}
                            value={Model.Patient.SaigaiKiguType.None}
                            setData={(n, v) => {
                                const kigu = new Model.Patient.SaigaiKigu();
                                kigu.type = v;
                                if (v != Model.Patient.SaigaiKiguType.None) {
                                    target.push(kigu)
                                }
                                this.setValueC(name, target, cat)
                            }}
                        />
                        : null
                }
            </div>
        </div>
    }
    public famiryC(cat: any[], name) {
        let target = this.getValueC(name, cat) as Model.Patient.PatientFamiry[]
        if (!target) target = []

        const removeTarget = (idx) => {
            target = target.filter((x, i) => i != idx)
            this.setValueC(name, target, cat)
        }

        const createLis = (i) => {
            const tel = this.getValueC("tel", cat.concat([name, i]))
            const tels = tel ? tel.split('\n') : []
            const lis = [
                {
                    name: "電話", content: this.isEditing ?
                        this.textC(cat.concat([name, i]), "tel")
                        : tels.map((t, ti) => {
                            return <div key={"tel_" + ti}><a href={`tel:${t}`}>{t}</a></div>
                        })
                },
                { name: "メモ", content: this.textC(cat.concat([name, i]), "name") }
            ]
            if (this.isEditing) {
                lis.push({
                    name: "削除", content: <Button color="secondary" size="small" variant="outlined"
                        onClick={(e) => { removeTarget(i) }} key={`famiry_del_${i}`}
                    >削除</Button>
                })
            }
            return lis;
        }

        return <div>
            <Grid container={true} spacing={2}>
                {
                    target ? target.map((t, i) => {
                        return <Grid key={`famiry_t_${i}`} item={true} xs={12} md={12} lg={12}>
                            <div>家族（No.{i + 1}）</div>
                            <Col2Table disableBGColor={true} body={createLis(i)} />
                        </Grid>
                    }) : null
                }
            </Grid>
            <div style={{ marginTop: "15px" }}>
                {
                    this.isEditing ?
                        <Button color="primary" size="small" variant="outlined"
                            onClick={(e) => {
                                const t = target.filter(x => x)
                                t.push(new Model.Patient.PatientFamiry())
                                this.setValueC(name, t, cat)
                            }}>＋追加</Button>
                        : null
                }
            </div>
        </div>
    }

}


const _kiguDetail = {}
_kiguDetail[Model.Patient.SaigaiKiguType.Kanyure] = [
    "製品名",
    "カフ", "カフエア", "内径(mm)", "内筒",
    "スピーチバルブ", "人工鼻",
    "備考"
]
_kiguDetail[Model.Patient.SaigaiKiguType.JinkoKokyu] = [
    "製品名",
    "設定(FiO2)", "Vt(ml)", "PS(cmH2O)",
    "PEEP(cmH2O)", "Pinsp(cmH2O)",
    "I:E比(f,bpm)",
    "バッテリー(h)", "外部バッテリー",
    "備考"
]
_kiguDetail[Model.Patient.SaigaiKiguType.Kyuuin] = [
    "製品名",
    "バッテリー", "外部バッテリー",
    "マニュアル式", "吸引チューブ(Fr)",
    "備考"
]
_kiguDetail[Model.Patient.SaigaiKiguType.ZaitakuSanso] = [
    "製品名",
    "安静時設定(L/min)",
    "労作時設定(L/min)",
    "O2濃縮器バッテリー", "外部バッテリー",
    "O2ボンベ(L)", "予備ボンベ(本)",
    "レギュレータバッテリー(駆動)",
    "備考"
]
_kiguDetail[Model.Patient.SaigaiKiguType.JizokuChusha] = [
    "種類", "CVポート",
    "留置部分", "固定部分",
    "輸液ポンプ",
    "バッテリー", "予備バッテリー",
    "注射液",
    "備考"
]
_kiguDetail[Model.Patient.SaigaiKiguType.Irou] = [
    "種類", "メーカー",
    "タイプ", "接続チューブ",
    "サイズ(Fr)", "固定水",
    "シャフト長(mm)", "挿入長(cm)",
    "備考"
]
_kiguDetail[Model.Patient.SaigaiKiguType.KeikanEiyou] = [
    "栄養剤", "水分(ml/回)", "水分(回/日)間隔",
    "摂取量(ml/回)", "摂取量(回/日)kcal/日",
    "注入形態", "注入速度(ml/時間)", "注入ポンプ",
    "バッテリー", "予備バッテリー",
    "備考"
]
_kiguDetail[Model.Patient.SaigaiKiguType.Katetel] = [
    "種類", "挿入部位", "固定部位", "サイズ(Fr)", "挿入長(cm)",
    "固定水", "製品名",
    "備考"
]
_kiguDetail[Model.Patient.SaigaiKiguType.JinkoKoumon] = [
    "種類", "交換頻度",
    "スマートサイズ(縦)", "x(横)cm", "x(高)cm",
    "パウチ", "補助材料",
    "備考"
]

_kiguDetail[Model.Patient.SaigaiKiguType.Jokuso] = [
    "部位", "NPUAP", "処置頻度", "外用薬", "ドレッシング",
    "備考"
]

_kiguDetail[Model.Patient.SaigaiKiguType.AirMat] = [
    "メーカー", "製品名", "停電時の対応",
    "備考"
]

_kiguDetail[Model.Patient.SaigaiKiguType.DendoBed] = [
    "メーカー", "製品名", "停電時の対応",
    "備考"
]
