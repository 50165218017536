import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from '../components/Page'
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import * as Colors from '../components/Colors'
import * as Icons from "@material-ui/icons"
import Fab from "@material-ui/core/Fab"
import Grid from "@material-ui/core/Grid"
import { Button, IconButton } from '@material-ui/core';
import * as HokanApi from '../api/hokan'
import * as Model from '../models'
import Loading from '../components/Loading'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import SubTitle from '../components/SubTitle';
import InputText from '../components/InputText';
import FormBehaviour from '../components/form_behaviour/FormBehaviour';
import * as Behaviour from '../components/form_behaviour'


const createObjectURL = (window.URL || (window as any).webkitURL).createObjectURL || (window as any).createObjectURL;

const styles = () => Styles.createStyles({
    root: {

    },
    dropzone: {
        maxWidth: "800px",
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: Colors.backColor,
        textAlign: "center",
        margin: "30px",
        padding: "20px",
        border: "dashed",
        borderWidth: "2px",
        borderColor: Colors.borderColor,
        color: Colors.primaryColor,
        borderRadius: "10px",
        transition: ".5s",
    },
    dropzone_dragin: {
        maxWidth: "800px",
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: Colors.primaryColor,
        textAlign: "center",
        margin: "30px",
        padding: "20px",
        border: "solid",
        borderColor: Colors.primaryColor,
        borderWidth: "2px",
        color: "white",
        borderRadius: "10px",
        transition: ".5s",
    },
    innerDrop: {

    },
    icon: {

    },
    iconDrag: {

    },
    iconClear: {
        top: "0px",
        right: "0px",
        position: "absolute",
        cursor: "pointer",
        border: "solid",
        borderWidth: "2px",
        backgroundColor: Colors.backColor,
        borderRadius: "100%"
    },
    iconDelete: {
        cursor: "pointer",
    },
    imageArea: {
        margin: "20px",
        color: Colors.borderColor,
        width: "90%",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
        border: "solid",
        borderWidth: "2px",
        borderRadius: "10px",
        padding: "10px",
        borderColor: Colors.borderColor,
        textAlign: "center"
    },
    imageAreaTitle: {
        color: Colors.primaryColor
    },
    image: {
        objectFit: "cover",
        width: "100%",
        height: "100%"
    },
    imageOne: {
        color: Colors.primaryColorTransparent,
        margin: "auto",
        padding: "5px",
        transition: ".2s",
        position: "relative",
        width: '150px',
        height: '150px',
        '&:hover': {
            color: Colors.primaryColor
        }
    },
    dropLabel: {
        fontSize: "0.9rem"
    },
    fileLabel: {
        fontSize: "0.9rem"
    },
    gridRoot: {
        margin: "auto"
    },
    grid: {
        margin: "auto",
        animationName: "fadein",
        animationDuration: "1s"
    },
    uploadButton: {
    },
    uploadedImage: {
        objectFit: "cover",
        width: "100%",
        height: "100%",
        cursor: "pointer"
    }
});

interface Props extends PageProps, Styles.WithStyles {
}

interface LocalState {
    files: Model.File[],
    selectedFiles: string[],
    rawSelectedFiles: File[],
    dragin: boolean,
    loading: boolean,
    isOpen: boolean,
    fileIndex: number,
    photoIndex: number,
    comment: string,
    name: string,
    behaviour: FormBehaviour | null,
    behaviourList: object
    documentTypes: Model.Document.DocumentType[]
}

// @inject()
// @observer
class DocumentPage extends React.Component<Props, LocalState> {
    displayName = "DocumentPage"
    constructor(props: Props) {
        super(props)
        const lis: FormBehaviour[] = [
            new Behaviour.HihuBredenBehaviour(),
            new Behaviour.MouseOHATBehaviour(),
            new Behaviour.KokyuMRCBehaviour()
        ]
        const bl = {}
        lis.forEach((x) => {
            bl[x.getId()] = x
        })

        this.state = {
            files: [],
            selectedFiles: [],
            rawSelectedFiles: [],
            dragin: false,
            loading: false,
            isOpen: false,
            fileIndex: 0,
            photoIndex: 0,
            comment: "",
            name: "",
            behaviour: null,
            documentTypes: [],
            behaviourList: bl
        }
    }

    private onDrop = (selectedFiles: File[], event: React.DragEvent<HTMLElement>) => {
        console.log(selectedFiles)
        const list = this.state.selectedFiles
        const rawList = this.state.rawSelectedFiles
        selectedFiles.forEach(f => {
            list.push(createObjectURL(f))
            rawList.push(f)
        })
        this.setState({ selectedFiles: list, rawSelectedFiles: rawList, dragin: false })
    }

    private upload = () => {
        /*
        const fd = new FormData()
        fd.append('name', this.state.name)
        fd.append('comment', this.state.comment)
        fd.append('category', "画像")
        this.state.rawSelectedFiles.forEach((f, i) => {
            fd.append(`files[]`, f)
        })
        HokanApi.postFile(fd)
            .then((response) => {
                this.loadFiles()
                this.setState({ selectedFiles: [], rawSelectedFiles: [] })
            })
            .catch((error) => {
                console.log(error)
            })
        */
        const doc = new Model.Document.Document()
        doc.images = this.state.rawSelectedFiles
        const doctype = new Model.Document.DocumentType();
        doctype.id = 8
        doc.document_type = doctype
        HokanApi.postDocument(new Model.Document.DocumentPost(doc)).then(res => {
            if (res) console.log(res.data)
        })
    }

    private onFileSelect(selectedFiles: FileList) {
        const len = selectedFiles.length
        const list = this.state.selectedFiles
        const rawList = this.state.rawSelectedFiles
        for (let i = 0; i < len; ++i) {
            const item = selectedFiles.item(i)
            if (item) {
                list.push(createObjectURL(item))
                rawList.push(item)
            }
        }
        this.setState({ selectedFiles: list, rawSelectedFiles: rawList, dragin: false })
    }

    private onRemove(idx: number) {
        const selectedFiles = ([] as string[]).concat(this.state.selectedFiles)
        const rawSelectedFiles = ([] as File[]).concat(this.state.rawSelectedFiles)
        selectedFiles.splice(idx, 1)
        rawSelectedFiles.splice(idx, 1)
        this.setState({ selectedFiles: selectedFiles, rawSelectedFiles: rawSelectedFiles })
    }

    public componentDidMount() {
        this.loadTypes();
        this.loadFiles()
    }

    private loadTypes() {
        HokanApi.getDocumentTypes()
            .then(x => {
                this.setState({ documentTypes: x.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private loadFiles() {
        this.setState({ loading: true })
        HokanApi.getFiles()
            .then(x => {
                this.setState({ files: x.data, loading: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    private onDelete(id: number) {
        HokanApi.deleteFile(id)
            .then(x => {
                this.loadFiles()
            })
            .catch(err => {
                console.log(err)
            })
    }

    private onClickImage(fileIndex: number, urlIndex: number) {
        this.setState({
            isOpen: true,
            fileIndex: fileIndex,
            photoIndex: urlIndex
        })
    }

    public render() {
        const { classes, history } = this.props
        const isOpen = this.state.isOpen
        const fileIndex = this.state.fileIndex
        const photoIndex = this.state.photoIndex
        const files = isOpen ? this.state.files[fileIndex] : undefined
        const filesLength = isOpen ? this.state.files[fileIndex].urls.length : 1

        return (
            <div>
                <SubTitle title="基本情報" />
                <Grid container spacing={2} className={classes.grid}>
                    <InputText title="書類名" value={this.state.name} onChange={(s) => this.setState({ name: s })} />
                    <InputText title="コメント" value={this.state.comment} onChange={(s) => this.setState({ comment: s })} />
                </Grid>

                <SubTitle title="テンプレートから作成" />
                <div style={{ textAlign: "center" }}>
                </div>
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary" onClick={(e) => { history.push(`/document/generate/kagi_azukari`) }}>
                        鍵預かり証
                    </Button>
                </div>
                <br />
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary" onClick={(e) => { history.push(`/document/generate/keikakusho`) }}>
                        計画書
                    </Button>
                </div>
                <br />
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary" onClick={(e) => { history.push(`/document/generate/houkokusho`) }}>
                        報告書
                    </Button>
                </div>
                <br />
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary" onClick={(e) => { history.push(`/document/generate/teikyosho`) }}>
                        提供書
                    </Button>
                </div>
                <br />
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary" onClick={(e) => { history.push(`/document/generate/jokuso_keikaku`) }}>
                        褥瘡対策に関する看護計画書
                    </Button>
                </div>
                <br />
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary" onClick={(e) => { history.push(`/document/genogram/jokuso_keikaku`) }}>
                        ジェノグラム
                    </Button>
                </div>
                <SubTitle title="画像アップロード" />
                <Dropzone
                    onDropAccepted={this.onDrop}
                    onDragEnter={(e) => this.setState({ dragin: true })}
                    onDragLeave={(e) => this.setState({ dragin: false })}
                >
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                            <div
                                {...getRootProps()}
                                className={classNames('dropzone', { 'dropzone--isActive': isDragActive }, this.state.dragin ? classes.dropzone_dragin : classes.dropzone)}
                            >
                                <div className={classes.innerDrop}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                            <div>
                                                <p className={classes.dropLabel}>
                                                    ここで離して追加
                                                </p>
                                                <Icons.AddCircleOutline className={classes.iconDrag} />
                                            </div>
                                            :
                                            <div>
                                                <p className={classes.dropLabel}>
                                                    ここにファイルをドラッグしてください
                                                </p>
                                                <Icons.Input className={classes.icon} />
                                            </div>
                                    }
                                    <div>
                                        <Fab variant="extended" size="small" color="primary" className={classes.fab} style={this.state.dragin ? { color: Colors.primaryColor } : {}}>
                                            or ファイルを選択
                                            <input type="file" id="" ref="file"
                                                style={{ display: "none" }}
                                                onChange={(e) => {
                                                    if (e.target.files) {
                                                        this.onFileSelect(e.target.files)
                                                    }
                                                }} />
                                        </Fab>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Dropzone>
                <div className={classes.imageArea}>
                    <div className={classes.imageAreaTitle}>
                        アップロードするファイル
                    </div>
                    {
                        this.state.selectedFiles.length > 0 ?
                            <Grid container={true} spacing={0} className={classes.gridRoot}>
                                {
                                    this.state.selectedFiles.map((f, idx) => {
                                        return <Grid item={true} md={3} sm={4} xs={6} key={idx} className={classes.grid}>
                                            <div className={classes.imageOne}>
                                                <img className={classes.image} src={f} />
                                                <Icons.Remove className={classes.iconClear}
                                                    onClick={(e) => this.onRemove(idx)}
                                                />
                                            </div>
                                        </Grid>
                                    })
                                }
                            </Grid>
                            :
                            <div>無し</div>
                    }
                </div>
                <div>
                    <Button className={classes.uploadButton}
                        onClick={(e) => this.upload()}>アップロードする</Button>
                </div>
                <div>
                    {
                        this.state.loading ?
                            <Loading />
                            :
                            this.state.files.map((file, fi) => {
                                return <div key={`file_${fi}`} >
                                    <IconButton className={classes.iconDelete}
                                        onClick={(e) => this.onDelete(file.id)}>
                                        <Icons.Delete />
                                    </IconButton>
                                    <div>{file.name}:{file.comment}</div>
                                    <Grid container={true} spacing={0} className={classes.gridRoot}>
                                        {
                                            file.urls.map((url, ui) => {
                                                return <Grid item={true} md={3} sm={4} xs={6} key={`image_${fi}_${ui}`} className={classes.grid}>
                                                    <div className={classes.imageOne}>
                                                        <img className={classes.uploadedImage} src={url}
                                                            onClick={(e) => { this.onClickImage(fi, ui) }}
                                                        />
                                                    </div>
                                                </Grid>
                                            })
                                        }
                                    </Grid>
                                </div>
                            })
                    }
                </div>
                {
                    this.state.isOpen ?
                        <Lightbox
                            mainSrc={files!.urls[photoIndex]}
                            nextSrc={files!.urls[(photoIndex + 1) % filesLength]}
                            prevSrc={files!.urls[(photoIndex - 1 + filesLength) % filesLength]}
                            onCloseRequest={() => this.setState({ isOpen: false, fileIndex: 0, photoIndex: 0 })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + filesLength - 1) % filesLength,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % filesLength,
                                })
                            }
                        />
                        : null
                }
            </div>
        );
    }
}

export default withPageProps(Styles.withStyles(styles)(DocumentPage))