import * as React from 'react';
import * as Styles from '@material-ui/core/styles';
import { PageProps, withPageProps } from './Page'
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SubTitle from './SubTitle'
import * as Colors from './Colors'
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import { Button, Grid, Tooltip } from '@material-ui/core';
import Pagination from "material-ui-flat-pagination";
import DocumentDialog from './DocumentDialog';
import { StoreAccessor } from '../util/StoreUtil';
import StandardTable from './StandardTable';
import Col2Table from './Col2Table';
import Loading from './Loading'
const moment = require("moment-timezone")
// import { toInjector } from '../stores';
// import { inject, observer } from 'mobx-react';

const styles = () => Styles.createStyles({
    root: {
        maxWidth: "1000px",
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        borderWidth: "1px",
        marginTop: "-1px",
        borderColor: Colors.borderColor,
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",
        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    },
    center: {
        textAlign: "center"
    },
    button: {
        border: "solid",
        borderWidth: "1px"
    },
    bar: {
        border: "solid",
        borderWidth: "1px",
        borderColor: Colors.borderColor,
        backgroundColor: Colors.backColor
    },
    tabs: {
        backgroundColor: Colors.backColor,
        color: Colors.borderColor,
        // background: Colors.primaryColor,
    },
    tab: {
        color: Colors.primaryColor,
        transition: "linear 0.5s",
        backgroundColor: Colors.forthColor
    },
    tabSelected: {
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        transition: "linear 0.5s",
        fontWeight: 700,
        borderBottom: "solid",
        borderColor: Colors.orangeColor,
        borderWidth: "1px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    tooltipDiv: {

    }
})

class Query {
    doc_type?: number = 0
    title?: string = ""
    patient?: Model.Patient.Patient = new Model.Patient.Patient()
}

interface Params {
}

interface Props extends PageProps<Params>, Styles.WithStyles {
    onSearch: (query: Query | null) => void
    typeId?: number
    patientId?: number
}

interface LocalState {
    documentList: Model.Document.DocumentList,
    loading: boolean,
    page: number,
    openDialog: boolean,
    selectedDocumentId: number | null
    query: Query
    documentTypes: { key: string, value: number }[]
}

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

// @inject()
// @observer
class DocumentList extends React.Component<Props, LocalState> {
    displayName = "DocumentList"
    public static defaultProps = {
        typeId: undefined,
        patientId: undefined
    }

    constructor(props: Props) {
        super(props)
        const q = new Query()
        if (props.typeId) {
            q.doc_type = props.typeId
        }
        this.state = {
            loading: false,
            documentList: new Model.Document.DocumentList(),
            page: 1,
            openDialog: false,
            selectedDocumentId: null,
            query: q,
            documentTypes: []
        }
    }

    public componentDidMount = () => {
        this.setState({ loading: true })
        this.loadTypes();
    }

    private loadTypes() {
        HokanApi.getDocumentTypes()
            .then(x => {
                const categories = new Set<string>()
                x.data.forEach(x => {
                    categories.add(x.category)
                })

                const types = Array.from(categories).map(c => {
                    return {
                        cat: c,
                        types: x.data.filter(t => t.category == c)
                    }
                })
                const dts: { key: string, value: number }[] = [{ key: "全種類", value: 0 }]
                types.forEach(t => {
                    t.types.forEach(tt => {
                        dts.push({ key: `[${tt.category}]${tt.name}`, value: tt.id })
                    })
                })
                this.setState({ documentTypes: dts })
            })
            .catch(err => {
                console.log(err)
            })
    }

    public render() {
        const { classes } = this.props;
        const { documentList } = this.state;
        const showPatient = this.props.patientId ? false : true

        const sa = new StoreAccessor(this, true)
        const saQ = sa.generateChild(["query"])
        const doSearch = () => {
            this.props.onSearch(
                {
                    doc_type: this.state.query.doc_type ? this.state.query.doc_type : undefined,
                    title: this.state.query.title
                }
            )
        }
        const element_l = (
            showPatient ? [{
                name: "", content: saQ.patient("patient", "patient_dialog")
            }] : []
        ).concat([
            {
                name: "", content: saQ.listRaw("doc_type", this.state.documentTypes)
            },
            {
                name: "", content: <div>{
                    saQ.text("title", "書類名、タイトル、コメント、利用者名、更新者名",
                        "text", "block", false, null, () => {
                            /*
                            if (this.state.loading == false) {
                                doSearch()
                            }*/
                        })
                }</div>
            },
            {
                name: "", content: <Button color="primary" variant="contained"
                    onClick={(e) => {
                        doSearch();
                    }} >検索</Button>
            },
        ])

        const toShortStr = (s) => {
            if (s.length > 10) return `${s.substr(0, 10)}...`
            return s
        }

        return <div className={classes.root}>
            <StandardTable title=''>
                <Grid container={true} spacing={0}>
                    <Grid item={true} xs={12} md={12} lg={12}>
                        <Col2Table body={element_l} />
                    </Grid>
                </Grid>
            </StandardTable>
            <div>
                <div className={classes.listRoot}>
                    <div style={{ textAlign: "center" }}>
                        <Button variant="contained" color="primary" onClick={(e) => { this.setState({ openDialog: true }) }}>
                            新規作成
                        </Button>
                        {
                            this.state.openDialog ?
                                <DocumentDialog key={`dialog_${this.state.selectedDocumentId}`}
                                    open={true}
                                    only={null}
                                    patientId={this.props.patientId}
                                    srcId={this.state.selectedDocumentId}
                                    onCansel={
                                        (deleted) => {
                                            this.setState({ openDialog: false, selectedDocumentId: null })
                                            if (deleted) {
                                                this.props.onSearch(null)
                                            }
                                        }
                                    }
                                    onSelect={(doc: Model.Document.Document) => {
                                        this.setState({ openDialog: false, selectedDocumentId: null })
                                        this.props.onSearch(null)
                                    }}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withPageProps(Styles.withStyles(styles)(DocumentList))