import * as React from 'react';
import * as Model from '../../models'
import * as FormModel from '../../models/Forms'
import { StoreAccessor } from '../../util/StoreUtil';
import DateUtil from '../../util/DateUtil';
import * as FormBehaviour from './FormBehaviour'
import * as HokanApi from '../../api/hokan'

// @inject()
// @observer
export default class Behaviour extends FormBehaviour.PdfBehaviour  { 
	displayName="Behaviour"

    public getId(): number {
        return 57
    }

    public createContent(): any {
        const res = new FormModel.ShouniCare.Core()
        res.values.create_date = DateUtil.currentDateStr()
        return res
    }

    public createDocumentType(): Model.Document.DocumentType {
        const res = new Model.Document.DocumentType();
        res.id = this.getId()
        return res
    }

    public beforePost_pdf(doc: Model.Document.Document) {
    }

    public beforeMount = () => {
    }


    public shutten() {
        return <div className="shutten">
            <div>=== 出典 ===</div>
            <div>ウィル訪問看護ステーション.WyL株式会社.2019</div>
        </div>
    }

    public setPatient(doc: Model.Document.Document, pat: Model.Patient.Patient, sa: StoreAccessor) {
        const c = doc.content as FormModel.ShouniCare.Core
        doc.patient = pat;
        if (pat) {
            c.values.birthday = DateUtil.toJpDateString(pat.birthday)
        }
        HokanApi.me().then(res => {
            c.values.create_by = res.data.name
            sa.updateState();
        }).catch((e) => { console.log(e)})
    }

    public buildElements_pdf(doc: Model.Document.Document, sa: StoreAccessor) {
        const saV = sa.generateChild(["content", "values"])
        const elements_basic = [
            {
                name: "作成者", content: <div>{saV.text("create_by")}</div>
            }
        ]
        const elements_naiyo = [
            { name: "デバイス", content: saV.text("device") },
            { name: "ケア方法", content: saV.text("care") },
            { name: "バイタルサイン", content: saV.text("vital") },
            { name: "好きなこと・苦手なこと", content: saV.text("suki") },
            { name: "目標・ご家族のきもち", content: saV.text("mokuhyo") }
        ]

        const elements = [
            { name: '基本', content: elements_basic },
            { name: '内容', content: elements_naiyo }
        ]

        return elements
    }
}
