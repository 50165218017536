import { AxiosRequestConfig } from "axios";
//import { ApiUrl } from '../api';
import { observable, autorun } from 'mobx';
export const ACCESS_TOKEN_KEY = 'accessTokenGoogle'
export const REFRESH_TOKEN_KEY = 'refreshTokenGoogle'
import { googleOAuthGetTokenUri, googleGetPermissionUri, } from './config';
import { authorizedFetch } from '../auth_axios';

const OAUTH_TOKEN_URI = googleOAuthGetTokenUri()

const validate = (val:string|null) => !val ? null : val === "null" ? null : val
export const sessionObservableGoogle = observable({
    accessToken: validate(localStorage.getItem(ACCESS_TOKEN_KEY)),
    refreshToken: validate(localStorage.getItem(REFRESH_TOKEN_KEY))
})

autorun(() => {
    localStorage.setItem(ACCESS_TOKEN_KEY,sessionObservableGoogle.accessToken!)
    localStorage.setItem(REFRESH_TOKEN_KEY, sessionObservableGoogle.refreshToken!)
})

export const getCalendarPermission = () => location.href = googleGetPermissionUri()

export const setCalendarAccessToken = async (param:{
    codeFromGoogle:string
}) => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': `application/json`
        }
    }
    return await authorizedFetch.post(OAUTH_TOKEN_URI, {
        "code" : param.codeFromGoogle
    }, config)
}